import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  applicantIcon: {
    marginRight: theme.spacing(0.75),
    '& > path': {
      fill: theme.palette.common.gray600,
    },
  },
  applicantsCountContainer: {
    color: theme.palette.common.gray600,
    marginTop: theme.spacing(0.75),
    marginBottom: theme.spacing(0.75),
  },
  modalBody: {
    overflow: 'scroll',
  },
  warningSection: {
    backgroundColor: theme.palette.common.orange100,
    padding: theme.spacing(1.75, 4),
  },
  warningText: {
    color: theme.palette.common.orange400,
  },
  warningIcon: {
    marginRight: theme.spacing(0.75),

    '& > path': {
      fill: theme.palette.common.red400,
    },
  },
  warningIconRed: {
    marginRight: theme.spacing(0.75),

    '& > path': {
      fill: theme.palette.common.red400,
    },
  },
  errorText: {
    color: theme.palette.common.red400,
  },
  checkbox: {
    marginLeft: theme.spacing(-1),
  },
  applicantRows: {
    padding: theme.spacing(1, 4),
  },
  applicantRow: {
    cursor: 'not-allowed',
    order: 1,

    '&.allowRedo': {
      cursor: 'pointer',
      order: 0,
    },
  },
  applicantsWithoutRedoContainer: {
    marginTop: theme.spacing(0.5),
  },
  applicantWithoutRedo: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default useStyles;
