import { Grid } from '@material-ui/core';
import React, { FC } from 'react';

import { SharedConditionProps } from '../../types';
import { ComparatorSelect } from '../components/ComparatorSelect';
import { InputField } from '../InputField';

export const AssessmentFormScoreResult: FC<SharedConditionProps> = ({
  condition,
  ruleId,
  setRules,
  errors,
}) => {
  return (
    <>
      <ComparatorSelect
        setRules={setRules}
        ruleId={ruleId}
        condition={condition}
        errors={errors}
        onlyReturnBasicComparators
      />
      <Grid item xs={6}>
        <InputField
          condition={condition}
          ruleId={ruleId}
          setRules={setRules}
          errors={errors}
          valueKey="score"
          inputType="number"
          placeholder="0"
        />
      </Grid>
    </>
  );
};
