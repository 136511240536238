import { WorkflowEditorService } from 'api-clients/monolith';
import { useApiService } from 'hooks';
import React from 'react';

export function useFunnel({ funnelSlug }: { funnelSlug: string }) {
  const fetchFunnel = React.useCallback(
    () => WorkflowEditorService.getInternalApiWorkflowEditorFunnels(funnelSlug),
    [funnelSlug],
  );

  return useApiService(fetchFunnel);
}
