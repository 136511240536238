import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import {
  fetchApplicantDetailsInit,
  setApplicantDetail,
  updateApplicantTableStatuses,
} from 'containers/ApplicantTableV2/actions';
import { addDefaultErrorMessageAction } from 'containers/FlashMessage/actions';
import { apiGet } from 'utils/axios';

export const fetchApplicantData = applicantId => {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}`;
  return apiGet(url);
};

export default function useApplicantDetail(
  applicantId,
  defaultFetching = true,
) {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const [error, setError] = useState('');

  const fetchData = useCallback(() => {
    dispatch(fetchApplicantDetailsInit({ applicantId }));
    dispatch(
      setApplicantDetail({ info: { id: applicantId }, isFetching: true }),
    );
    fetchApplicantData(applicantId)
      .then(res => {
        const { applicant } = res.data;

        setData(applicant);
        dispatch(updateApplicantTableStatuses(applicantId, applicant.status));
        setError('');
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => setIsFetching(false));
  }, [applicantId, dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(addDefaultErrorMessageAction());
    }
  }, [dispatch, error]);

  useEffect(() => {
    if (applicantId && defaultFetching) {
      fetchData();
    }
  }, [applicantId, fetchData, defaultFetching]);

  return { data, error, isFetching, fetchData, setIsFetching };
}
