import { TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import classnames from 'classnames';
import { includes } from 'lodash';
import React, { useState } from 'react';

import { Error } from 'components/Error';

import { HandleSettingChange, SettingKey, SettingValue } from '../types';

const IGNORE_VALIDATION_FOR = [
  'faq_fallback_phone_number',
  'faq_fallback_email',
  'no_of_jobs_for_career_site',
];

const useStyles = makeStyles<Theme, { error: boolean }>(theme => ({
  inputWrapper: {
    marginTop: theme.spacing(2),
  },
  input: {
    '&:invalid': {
      boxShadow: 'none',
    },
  },
  inputDisable: {
    backgroundColor: '#dfdfdf',
  },
}));

export interface InputSettingProps {
  settingKey: SettingKey;
  settingValue: SettingValue;
  onChange: HandleSettingChange;
  pattern?: string;
  label?: string;
  errorMessage?: string;
  placeholder?: string;
  className?: string;
  isrequired?: boolean;
  faqSectionDisabled?: boolean;
}

export const InputSetting: React.VFC<InputSettingProps> = ({
  settingKey,
  settingValue,
  onChange,
  pattern,
  label,
  errorMessage,
  placeholder,
  className,
  isrequired,
  faqSectionDisabled,
}) => {
  const [isValid, setIsValid] = useState(true);
  const [isFocused, setIsFocused] = useState(false);

  const styles = useStyles({ error: !isValid });

  const matchPattern = (value: SettingValue) => {
    if (includes(IGNORE_VALIDATION_FOR, settingKey)) return true;
    if (!pattern || !value) return false;

    const reg = new RegExp(pattern);
    return reg.test(value.toString());
  };

  const handleChange = (value: string) => {
    onChange(settingKey, value);
    if (matchPattern(value)) setIsValid(true);
  };

  const handleFocus = () => {
    setIsValid(matchPattern(settingValue));
    setIsFocused(true);
  };

  return (
    <div className={styles.inputWrapper}>
      <TextField
        fullWidth
        required={isrequired}
        value={settingValue}
        onChange={event => {
          handleChange(event.target.value);
        }}
        onBlur={handleFocus}
        label={label}
        placeholder={placeholder}
        inputProps={{ pattern }}
        error={!isValid && isFocused}
        className={classnames(
          styles.input,
          className,
          faqSectionDisabled ? styles.inputDisable : '',
        )}
        disabled={!!faqSectionDisabled}
        variant="outlined"
      />
      {!isValid && isFocused && errorMessage && (
        <Error error={errorMessage} align="left" />
      )}
    </div>
  );
};
