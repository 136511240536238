import { useEffect } from 'react';

export default function useOnClickOutside(ref, handler, domRoot) {
  useEffect(() => {
    const listener = event => {
      // Do nothing if clicking ref's element or descendent elements
      if (
        ref.current &&
        ref.current.contains &&
        ref.current.contains(event.target)
      ) {
        return;
      }

      handler(event);
    };

    const root = domRoot || document;

    root.addEventListener('click', listener);

    return () => {
      root.removeEventListener('click', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, handler]);
}
