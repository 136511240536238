import { all, takeLatest } from 'redux-saga/effects';

import { removeParamsFromUrl, setParamsToUrl } from 'utils/urlUtils';

import {
  CLOSE_PERSISTED_POPUP,
  CLOSE_POPUP,
  SET_PERSISTED_POPUP,
  SET_SELECTED_POPUP,
} from './constants';

export function* closePopup({ popupType, transitionType }) {
  removeParamsFromUrl({
    transitionType,
    popupType,
    partnerStageId: '',
    templateType: '',
    stageName: '',
    statusLabelTitle: '',
  });
}

export function* closePersistedPopup({ persistedPopupType, transitionType }) {
  removeParamsFromUrl({ transitionType, persistedPopupType });
}

export function* setSelectedPopup(action) {
  setParamsToUrl({ ...action.payload, popupType: action.popupType });
}

export function* setSelectedPersistedPopup(action) {
  setParamsToUrl({
    ...action.payload,
    persistedPopupType: action.persistedPopupType,
  });
}

export const setSelectedPopupSaga = takeLatest(
  SET_SELECTED_POPUP,
  setSelectedPopup,
);

export const setSelectedPersistedPopupSaga = takeLatest(
  SET_PERSISTED_POPUP,
  setSelectedPersistedPopup,
);

export const closePopupSaga = takeLatest(CLOSE_POPUP, closePopup);

export const closePersistedPopupSaga = takeLatest(
  CLOSE_PERSISTED_POPUP,
  closePersistedPopup,
);

export default function* globalPopupSaga() {
  yield all([
    setSelectedPopupSaga,
    closePopupSaga,
    setSelectedPersistedPopupSaga,
    closePersistedPopupSaga,
  ]);
}
