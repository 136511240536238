import loadable from 'utils/loadable';

export { QuickActions } from './QuickActions';
export { QuickActionsContainer } from './QuickActionsContainer';

export const QuickActionsAsync = loadable(() =>
  import('./QuickActions').then(module => ({ default: module.QuickActions })),
);

export const QuickActionsContainerAsync = loadable(() =>
  import('./QuickActionsContainer').then(module => ({
    default: module.QuickActionsContainer,
  })),
);
