import { makeStyles } from '@material-ui/core';
import { compact, startCase, toLower } from 'lodash';
import React, { VFC } from 'react';

export type HistoryItem = {
  [x: string]: string | string[];
};

const useStyles = makeStyles(theme => ({
  historyItem: {
    paddingLeft: theme.spacing(1),
  },
}));

interface HistoryAddressItemProps {
  details: HistoryItem;
}

export const HistoryAddressItem: VFC<HistoryAddressItemProps> = ({
  details,
}) => {
  const classes = useStyles();

  const renderFieldData = (key: string, details: HistoryItem) => {
    let value = details[key];

    switch (key) {
      case 'address': {
        /* eslint-disable camelcase */
        const {
          street_address,
          address_2,
          city,
          country_code,
          state_code,
          postal_code,
        } = details;
        return (
          <>
            {compact([
              street_address,
              address_2,
              city,
              state_code,
              postal_code,
              country_code,
            ]).join(', ')}
          </>
        );
        /* eslint-enable camelcase */
      }
      case 'documents': {
        value = details.files;
        return Array.isArray(value) ? (
          <span>
            <ul>
              {value.map((value: string, index: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={`${key}-document-${index}`}>
                  <a href={value} target="_blank">
                    Download file{index + 1}
                  </a>
                </li>
              ))}
            </ul>
          </span>
        ) : (
          '-'
        );
      }
      default: {
        return value;
      }
    }
  };

  const fieldsToRender = ['address', 'documents'];

  return (
    <ul className={classes.historyItem}>
      {fieldsToRender.map(field => (
        <li key={`field-${field}`}>
          <span>
            {startCase(toLower(field))}: {renderFieldData(field, details)}
          </span>
        </li>
      ))}
    </ul>
  );
};
