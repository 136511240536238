import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { VFC } from 'react';

export const FountainLogo: VFC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 24 22">
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3846 21.3791C21.9336 21.3791 24 19.2982 24 16.7315C24 14.1647 21.9336 12.0838 19.3846 12.0838C16.1411 12.0838 13.747 14.4843 12.6559 18.5906H11.3441C10.2525 14.4843 7.85888 12.0838 4.61542 12.0838C2.06645 12.0838 0 14.1647 0 16.7315C0 19.2982 2.06645 21.3791 4.61542 21.3791H19.3846Z"
        fill="#1260FD"
      />
      <path
        d="M19.3846 0C16.1411 0 13.747 2.4005 12.6559 6.50683H11.3441C10.2525 2.4005 7.85888 0 4.61542 0C2.06645 0 0 2.08088 0 4.64766C0 7.21445 2.06645 9.29533 4.61542 9.29533C7.69632 9.29533 10.2329 10.752 12 13.3359C13.7671 10.752 16.3037 9.29533 19.3846 9.29533C21.9336 9.29533 24 7.21445 24 4.64766C24 2.08088 21.9336 0 19.3846 0V0Z"
        fill="#1260FD"
      />
    </svg>
  </SvgIcon>
);
