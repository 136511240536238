import { MenuItem } from '@fountain/fountain-ui-components';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import useNavItemStyles from '../NavigationMenuItem/styles';
import NavLink from '../NavLink';
import messages from './messages';

export default function AnalyticsNavLink({ permission, menuItem }) {
  const navClasses = useNavItemStyles();
  const { accountSlug } = useParams();
  const analyticsUrl = `/${accountSlug}/analytics_dashboard`;

  const regEx = new RegExp(`/([^/]*)/analytics_dashboard`);
  const active = regEx.test(window.location.pathname);

  const menuItemProps = {
    classes: { root: navClasses.root, selected: navClasses.selected },
    selected: active,
    disabled: active,
    href: analyticsUrl,
    isExternalLink: true,
    className: navClasses.menuListItem,
  };

  return (
    <>
      {menuItem ? (
        <MenuItem {...menuItemProps}>
          <FormattedMessage {...messages.analytics} />
        </MenuItem>
      ) : (
        <NavLink
          active={active}
          display={permission}
          link={analyticsUrl}
          message={messages.analytics}
          dataTestid="analytics_dashboard"
        />
      )}
    </>
  );
}

AnalyticsNavLink.propTypes = {
  permission: PropTypes.bool.isRequired,
  menuItem: PropTypes.bool,
};
