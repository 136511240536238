/**
 *
 * Label
 *
 */

import { CustomTooltip } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useRef, useState } from 'react';
import { classNames } from 'react-extras';

import useApplicantToggleLabelCompletion from 'hooks/useApplicantToggleLabelCompletion';
import GrayCheckmark from 'images/checkmark_gray.svg';
import GreenCheckmark from 'images/checkmark_green.svg';

import useStyles from './styles';

function Label({
  applicant,
  customLabel,
  uniqueKey,
  isOpen,
  isExpanded,
  labelsContainerWidth,
  setCollapsedLabels,
}) {
  const classes = useStyles();
  const node = useRef();

  const [hideLabel, setHideLabel] = useState(false);

  const { patchData, labelCompleted } = useApplicantToggleLabelCompletion(
    applicant.id,
    customLabel.completed,
  );

  useEffect(() => {
    const labelRef = node.current;

    if (labelRef && labelsContainerWidth && setCollapsedLabels) {
      const labelWidth = labelRef.offsetWidth;
      const labelOffsetLeft = labelRef.offsetLeft;
      const shouldLabelBeHidden =
        labelOffsetLeft + labelWidth + 48 > labelsContainerWidth;

      const prevSibling = labelRef.previousElementSibling;
      const isPrevSiblingHidden =
        prevSibling && prevSibling.classList.contains('hidden');

      if (shouldLabelBeHidden || (prevSibling && isPrevSiblingHidden)) {
        setCollapsedLabels(collapsedLabels => ({
          ...collapsedLabels,
          [uniqueKey]: true,
        }));
        setHideLabel(true);
      } else {
        setCollapsedLabels(collapsedLabels => {
          const copyCollapsedLabels = { ...collapsedLabels };
          delete copyCollapsedLabels[uniqueKey];
          return copyCollapsedLabels;
        });
        setHideLabel(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelsContainerWidth, isExpanded]);

  const onClickLabel = e => {
    e.stopPropagation();

    return patchData(customLabel.id);
  };

  return (
    <Grid
      ref={node}
      className={classNames(classes.label, {
        addMarginBottom: isOpen || isExpanded,
        hidden: hideLabel && !isOpen && !isExpanded,
        completed: labelCompleted,
      })}
      onClick={onClickLabel}
    >
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        justify="center"
        variant="caption"
      >
        <CustomTooltip title={customLabel.title} dense>
          {labelCompleted ? (
            <img
              src={GreenCheckmark}
              alt="checkmark"
              className={classNames(classes.checkmark, {
                completed: labelCompleted,
              })}
            />
          ) : (
            <img
              src={GrayCheckmark}
              alt="checkmark"
              className={classNames(classes.checkmark, {
                completed: labelCompleted,
              })}
            />
          )}
        </CustomTooltip>
        <Typography variant="overline" className={classes.statusText}>
          {customLabel.title}
        </Typography>
      </Grid>
    </Grid>
  );
}

Label.propTypes = {
  customLabel: PropTypes.object,
  isOpen: PropTypes.bool,
  isExpanded: PropTypes.bool,
  labelsContainerWidth: PropTypes.number,
  uniqueKey: PropTypes.string,
  setCollapsedLabels: PropTypes.func,
  applicant: PropTypes.object,
};

export default memo(Label);
