/*
 *
 * ApplicantActions actions
 *
 */

import {
  CANCEL_AND_RESEND_HELLOSIGN_REQUEST_ERROR,
  CANCEL_AND_RESEND_HELLOSIGN_REQUEST_INIT,
  CANCEL_AND_RESEND_HELLOSIGN_REQUEST_SUCCESS,
  CHECKR_CANADA_RUN_REPORT_ERROR,
  CHECKR_CANADA_RUN_REPORT_INIT,
  CHECKR_CANADA_RUN_REPORT_SUCCESS,
  CHECKR_RUN_REMAINING_REPORT_ERROR,
  CHECKR_RUN_REMAINING_REPORT_INIT,
  CHECKR_RUN_REMAINING_REPORT_SUCCESS,
  CHECKR_RUN_REPORT_ERROR,
  CHECKR_RUN_REPORT_INIT,
  CHECKR_RUN_REPORT_SUCCESS,
  CHECKR_SEND_CONSENT_MESSAGE_ERROR,
  CHECKR_SEND_CONSENT_MESSAGE_INIT,
  CHECKR_SEND_CONSENT_MESSAGE_SUCCESS,
  ONFIDO_RESUME_ERROR,
  ONFIDO_RESUME_INIT,
  ONFIDO_RESUME_SUCCESS,
  ONFIDO_SEND_MESSAGE_ERROR,
  ONFIDO_SEND_MESSAGE_INIT,
  ONFIDO_SEND_MESSAGE_SUCCESS,
  ONFIDO_VOID_ERROR,
  ONFIDO_VOID_INIT,
  ONFIDO_VOID_SUCCESS,
  RESEND_HELLOSIGN_EMAIL_ERROR,
  RESEND_HELLOSIGN_EMAIL_INIT,
  RESEND_HELLOSIGN_EMAIL_SUCCESS,
  RESUBSCRIBE_EMAIL_ERROR,
  RESUBSCRIBE_EMAIL_START,
  RESUBSCRIBE_EMAIL_SUCCESS,
  RESUBSCRIBE_SMS_ERROR,
  RESUBSCRIBE_SMS_START,
  RESUBSCRIBE_SMS_SUCCESS,
  SCHEDULING_CANCEL_ERROR,
  SCHEDULING_CANCEL_INIT,
  SCHEDULING_CANCEL_SUCCESS,
  SCHEDULING_RESEND_SCHEDULER_EMAIL_ERROR,
  SCHEDULING_RESEND_SCHEDULER_EMAIL_INIT,
  SCHEDULING_RESEND_SCHEDULER_EMAIL_SUCCESS,
  SCHOOLKEEP_ENROLL_ERROR,
  SCHOOLKEEP_ENROLL_INIT,
  SCHOOLKEEP_ENROLL_SUCCESS,
  SEND_AUTOMATED_MESSAGE,
  SEND_AUTOMATED_MESSAGE_ERROR,
  SEND_AUTOMATED_MESSAGE_SUCCESS,
  WX_EXPORT_APPLICANT_ERROR,
  WX_EXPORT_APPLICANT_INIT,
  WX_EXPORT_APPLICANT_SUCCESS,
} from './constants';

export function resubscribeToEmailAction(applicantId, messages) {
  return {
    type: RESUBSCRIBE_EMAIL_START,
    applicantId,
    messages,
  };
}

export function resubscribeToEmailSuccessAction() {
  return {
    type: RESUBSCRIBE_EMAIL_SUCCESS,
  };
}

export function resubscribeToEmailErrorAction() {
  return {
    type: RESUBSCRIBE_EMAIL_ERROR,
  };
}

export function resubscribeToSmsAction(applicantId, messages) {
  return {
    type: RESUBSCRIBE_SMS_START,
    applicantId,
    messages,
  };
}

export function resubscribeToSmsSuccessAction() {
  return {
    type: RESUBSCRIBE_SMS_SUCCESS,
  };
}

export function resubscribeToSmsErrorAction() {
  return {
    type: RESUBSCRIBE_SMS_ERROR,
  };
}

export function checkrSendConsentMessageAction(applicantId, messages) {
  return {
    type: CHECKR_SEND_CONSENT_MESSAGE_INIT,
    applicantId,
    messages,
  };
}

export function checkrSendConsentMessageSuccessAction() {
  return {
    type: CHECKR_SEND_CONSENT_MESSAGE_SUCCESS,
  };
}

export function checkrSendConsentMessageErrorAction() {
  return {
    type: CHECKR_SEND_CONSENT_MESSAGE_ERROR,
  };
}

export function checkrRunReportAction(applicantId, stageId, messages) {
  return {
    type: CHECKR_RUN_REPORT_INIT,
    applicantId,
    stageId,
    messages,
  };
}

export function checkrRunReportSuccessAction() {
  return {
    type: CHECKR_RUN_REPORT_SUCCESS,
  };
}

export function checkrRunReportErrorAction() {
  return {
    type: CHECKR_RUN_REPORT_ERROR,
  };
}
export function checkrRunRemainingReportAction(
  applicantId,
  reportId,
  messages,
) {
  return {
    type: CHECKR_RUN_REMAINING_REPORT_INIT,
    applicantId,
    reportId,
    messages,
  };
}

export function checkrRunRemainingReportSuccessAction() {
  return {
    type: CHECKR_RUN_REMAINING_REPORT_SUCCESS,
  };
}

export function checkrRunRemainingReportErrorAction() {
  return {
    type: CHECKR_RUN_REMAINING_REPORT_ERROR,
  };
}

export function cancelAndResendHellosignRequestAction(
  applicantId,
  requestId,
  messages,
) {
  return {
    type: CANCEL_AND_RESEND_HELLOSIGN_REQUEST_INIT,
    applicantId,
    requestId,
    messages,
  };
}

export function cancelAndResendHellosignRequestSuccessAction() {
  return {
    type: CANCEL_AND_RESEND_HELLOSIGN_REQUEST_SUCCESS,
  };
}

export function cancelAndResendHellosignRequestErrorAction() {
  return {
    type: CANCEL_AND_RESEND_HELLOSIGN_REQUEST_ERROR,
  };
}

export function resendHellosignEmailAction(applicantId, requestId, messages) {
  return {
    type: RESEND_HELLOSIGN_EMAIL_INIT,
    applicantId,
    requestId,
    messages,
  };
}

export function resendHellosignEmailSuccessAction() {
  return {
    type: RESEND_HELLOSIGN_EMAIL_SUCCESS,
  };
}

export function resendHellosignEmailErrorAction() {
  return {
    type: RESEND_HELLOSIGN_EMAIL_ERROR,
  };
}

export function schoolkeepEnrollAction(applicantId, requestId) {
  return {
    type: SCHOOLKEEP_ENROLL_INIT,
    applicantId,
    requestId,
  };
}

export function schoolkeepEnrollSuccessAction() {
  return {
    type: SCHOOLKEEP_ENROLL_SUCCESS,
  };
}

export function schoolkeepEnrollErrorAction() {
  return {
    type: SCHOOLKEEP_ENROLL_ERROR,
  };
}

export function schedulingCancelAction(applicantId, bookedSlotId, messages) {
  return {
    type: SCHEDULING_CANCEL_INIT,
    applicantId,
    bookedSlotId,
    messages,
  };
}

export function schedulingCancelSuccessAction() {
  return {
    type: SCHEDULING_CANCEL_SUCCESS,
  };
}

export function schedulingCancelErrorAction() {
  return {
    type: SCHEDULING_CANCEL_ERROR,
  };
}

export function schedulingResendSchedulerEmailAction(applicantId, messages) {
  return {
    type: SCHEDULING_RESEND_SCHEDULER_EMAIL_INIT,
    applicantId,
    messages,
  };
}

export function schedulingResendSchedulerEmailSuccessAction() {
  return {
    type: SCHEDULING_RESEND_SCHEDULER_EMAIL_SUCCESS,
  };
}

export function schedulingResendSchedulerEmailErrorAction() {
  return {
    type: SCHEDULING_RESEND_SCHEDULER_EMAIL_ERROR,
  };
}

export function checkrCanadaRunReportAction(reportId, messages) {
  return {
    type: CHECKR_CANADA_RUN_REPORT_INIT,
    reportId,
    messages,
  };
}

export function checkrCanadaRunReportSuccessAction() {
  return {
    type: CHECKR_CANADA_RUN_REPORT_SUCCESS,
  };
}

export function checkrCanadaRunReportErrorAction() {
  return {
    type: CHECKR_CANADA_RUN_REPORT_ERROR,
  };
}

export function onfidoResumeAction(checkId, messages) {
  return {
    type: ONFIDO_RESUME_INIT,
    checkId,
    messages,
  };
}

export function onfidoResumeSuccessAction() {
  return {
    type: ONFIDO_RESUME_SUCCESS,
  };
}

export function onfidoResumeErrorAction() {
  return {
    type: ONFIDO_RESUME_ERROR,
  };
}

export function onfidoSendMessageAction(applicantId, stageId, messages) {
  return {
    type: ONFIDO_SEND_MESSAGE_INIT,
    applicantId,
    stageId,
    messages,
  };
}

export function onfidoSendMessageSuccessAction() {
  return {
    type: ONFIDO_SEND_MESSAGE_SUCCESS,
  };
}

export function onfidoSendMessageErrorAction() {
  return {
    type: ONFIDO_SEND_MESSAGE_ERROR,
  };
}

export function onfidoVoidAction(applicantId, stageId, messages) {
  return {
    type: ONFIDO_VOID_INIT,
    applicantId,
    stageId,
    messages,
  };
}

export function onfidoVoidSuccessAction() {
  return {
    type: ONFIDO_VOID_SUCCESS,
  };
}

export function onfidoVoidErrorAction() {
  return {
    type: ONFIDO_VOID_ERROR,
  };
}

export function sendAutomatedMessageAction(applicantId, message) {
  return {
    type: SEND_AUTOMATED_MESSAGE,
    applicantId,
    message,
  };
}

export function sendAutomatedSuccessAction() {
  return {
    type: SEND_AUTOMATED_MESSAGE_SUCCESS,
  };
}

export function sendAutomatedErrorAction() {
  return {
    type: SEND_AUTOMATED_MESSAGE_ERROR,
  };
}

export function wxExportApplicantAction(
  isMasterApplicantsView,
  applicantIds,
  filterSetId,
  tempFilterId,
  duplicateApplicantId,
  stageConditionsFilterSetId,
  selectByCount,
  selectedApplicant,
  selectedFilterStageIdsWithFunnel,
  search,
  messages,
) {
  return {
    type: WX_EXPORT_APPLICANT_INIT,
    isMasterApplicantsView,
    applicantIds,
    filterSetId,
    tempFilterId,
    duplicateApplicantId,
    stageConditionsFilterSetId,
    selectByCount,
    selectedApplicant,
    selectedFilterStageIdsWithFunnel,
    search,
    messages,
  };
}

export function wxExportApplicantSuccessAction() {
  return {
    type: WX_EXPORT_APPLICANT_SUCCESS,
  };
}

export function wxExportApplicantErrorAction() {
  return {
    type: WX_EXPORT_APPLICANT_ERROR,
  };
}
