import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      '&:last-child': {
        marginRight: 0,
      },

      marginRight: theme.spacing(2),
      transition: 'none',
    },
    buttonDisabled: {
      '&:last-child': {
        marginRight: 0,
      },
      transition: 'none',
      color: theme.palette.common.gray300,
    },
    openInNew: {
      fontSize: '1rem',
    },
  }),
);

export default styles;
