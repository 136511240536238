import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Typography } from '@material-ui/core';
import {
  AccurateStageCountryCode,
  WorkflowAccurateStage,
} from 'api-clients/monolith';
import produce from 'immer';
import React, { FC, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { StageSettingCard } from 'containers/WorkflowEditor/components/StageSettingCard';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { messages } from './messages';
import { useStyles } from './styles';
interface CountryCodeOption {
  label: string;
  value: AccurateStageCountryCode;
}

export type JobLocationProps = {
  stage: WorkflowAccurateStage;
};

export const JobLocation: FC<JobLocationProps> = ({ stage }) => {
  const styles = useStyles();
  const intl = useIntl();

  const { setStage } = useContext(StageContext);

  const locationCountryCodeOptions = (
    stage.additional_info.location_country_code_options ?? []
  ).map(code => ({ label: code, value: code }));
  const selectedCountryCode = locationCountryCodeOptions?.find(
    option => option.value === stage.extra.background_check_location_country,
  );

  const onCountryCodeChange = (countryCodeOption: CountryCodeOption) => {
    const { value } = countryCodeOption;

    setStage(
      produce(stage, draftStage => {
        draftStage.extra.background_check_location_country = value;
      }),
    );
  };

  return (
    <StageSettingCard
      variant="default"
      title={intl.formatMessage(messages.jobLocation)}
    >
      <div className={styles.text}>
        <Typography variant="body2">
          <FormattedMessage {...messages.description} />
        </Typography>
      </div>
      <div className={styles.select}>
        <StyledReactSelect
          aria-label={intl.formatMessage(messages.countryCode)}
          options={locationCountryCodeOptions}
          value={selectedCountryCode}
          onChange={onCountryCodeChange}
          label={intl.formatMessage(messages.countryCode)}
        />
      </div>
    </StageSettingCard>
  );
};
