import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { makeStyles } from '@material-ui/core/styles';
import { ChatbotWidgetBrand } from 'api-clients/monolith';
import React from 'react';
import { useIntl } from 'react-intl';

import { messages } from './messages';

const useStyles = makeStyles(theme => ({
  selectInput: {
    marginTop: theme.spacing(2),
  },
}));

export interface BrandSelectorProps {
  brands: ChatbotWidgetBrand[];
  selectedBrand: ChatbotWidgetBrand;
  onChange: (brands: ChatbotWidgetBrand) => void;
}

export const BrandSelector: React.VFC<BrandSelectorProps> = ({
  brands,
  selectedBrand,
  onChange,
}) => {
  const styles = useStyles();
  const intl = useIntl();

  return (
    <StyledReactSelect
      options={brands}
      value={selectedBrand}
      onChange={(brand: ChatbotWidgetBrand) => onChange(brand)}
      placeholder={intl.formatMessage(messages.brandPlaceholder)}
      label={intl.formatMessage(messages.brand)}
      aria-label={intl.formatMessage(messages.brandPlaceholder)}
      getOptionLabel={(brand: ChatbotWidgetBrand) => brand.label}
      getOptionValue={(brand: ChatbotWidgetBrand) => brand.key}
      className={styles.selectInput}
    />
  );
};
