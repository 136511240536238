import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  videoRecordingQuestions: {
    id: `app.containers.WorkflowEditor.components.StageDetail.VideoRecordingStage.videoRecordingQuestions`,
    defaultMessage: 'Video Recording Questions',
  },
  questionsExplanationText: {
    id: `app.containers.WorkflowEditor.components.StageDetail.VideoRecordingStage.questionsExplanationText`,
    defaultMessage:
      'Add questions to ask applicants during video recorded interviews. The applicant will make one video for each question.',
  },
  addQuestion: {
    id: `app.containers.WorkflowEditor.components.StageDetail.VideoRecordingStage.addQuestion`,
    defaultMessage: 'Add Question',
  },
});
