/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessageTemplateDeliverRequest } from '../models/MessageTemplateDeliverRequest';
import type { MesssageTemplateDeliverResponse } from '../models/MesssageTemplateDeliverResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiSelfServeV2DeliverEmailService {

    /**
     * deliver
     * Delivers email to applicant
     * @param requestBody deliver email params
     * @returns MesssageTemplateDeliverResponse success
     * @throws ApiError
     */
    public static postApiSelfServeV2MessageTemplatesDeliver(
        requestBody?: MessageTemplateDeliverRequest,
    ): CancelablePromise<MesssageTemplateDeliverResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api_self_serve/v2/message_templates/deliver',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}