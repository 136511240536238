import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import { addMessageAction } from 'containers/FlashMessage/actions';
import { apiPost } from 'utils/axios';

// can either send one page of applicantIds or all
export default function useApplicantsRequestingRevision() {
  const dispatch = useDispatch();
  const [applicants, setApplicants] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);

  const fetchApplicantsRequestingRevision = async ({
    applicantIds,
    partnerStageId,
    selectAll,
  }) => {
    const data = {
      applicant_ids: applicantIds,
      select_all_applicants: selectAll,
      partner_stage_id: partnerStageId,
    };

    const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/applicants_requesting_revision`;

    setIsFetching(true);
    try {
      const response = await apiPost(url, data);
      setApplicants(response.data.applicants);
      setError(null);
    } catch (e) {
      setError(e);
      dispatch(
        addMessageAction('An error occurred while fetching users', 'error'),
      );
    } finally {
      setIsFetching(false);
    }
  };

  return { fetchApplicantsRequestingRevision, isFetching, error, applicants };
}
