/**
 *
 * BulkActionConfirmationModal
 *
 */

import {
  Button,
  InnerContent,
  Loader,
  SimpleModal,
  SimpleModalFooter,
  SimpleModalHeader,
  SimpleModalHeaderIcon,
  SimpleModalTitle,
} from '@fountain/fountain-ui-components';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Checkmark from 'images/checkmark-blueV2.svg';
import globalMessages from 'shared/global/messages';
import { ESTIMATED_NUMBER_OF_APPLICANTS_PROCESSED_PER_MINUTE_BY_TYPE } from 'utils/constants';
import { calculateDaysHoursMinutes } from 'utils/time';

import messages from './messages';
import useStyles from './styles';

const BulkActionConfirmationModal = ({
  actionType,
  handleClose,
  handleContinue,
  numberOfSelectedApplicants,
}) => {
  const [estimatedTimeToComplete, setEstimatedTimeToComplete] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    calculateEstimatedTimeToComplete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculateEstimatedTimeToComplete = () => {
    const numberOfApplicantsPerMinute =
      ESTIMATED_NUMBER_OF_APPLICANTS_PROCESSED_PER_MINUTE_BY_TYPE[actionType];
    const estimatedSecondsToComplete = Math.ceil(
      (numberOfSelectedApplicants / numberOfApplicantsPerMinute) * 60,
    );
    const daysHoursMinutes = calculateDaysHoursMinutes(
      estimatedSecondsToComplete,
    );
    setEstimatedTimeToComplete(daysHoursMinutes);
  };

  if (!estimatedTimeToComplete) return <Loader fullScreen />;

  return (
    <SimpleModal onClose={handleClose} fullScreenOnMobile={false}>
      <SimpleModalHeader onClose={handleClose}>
        <SimpleModalHeaderIcon Icon={Checkmark} />
        <SimpleModalTitle>
          <FormattedMessage {...messages.modalHeader} />
        </SimpleModalTitle>
      </SimpleModalHeader>
      <InnerContent>
        <Typography color="textPrimary">
          <FormattedMessage {...messages.confirmationMessagePrefix} />
          {!!estimatedTimeToComplete.days && (
            <FormattedMessage
              {...messages.days}
              values={{
                days: estimatedTimeToComplete.days,
              }}
            />
          )}
          {!!estimatedTimeToComplete.hours && (
            <FormattedMessage
              {...messages.hours}
              values={{
                hours: estimatedTimeToComplete.hours,
              }}
            />
          )}
          {!!estimatedTimeToComplete.minutes && (
            <FormattedMessage
              {...messages.minutes}
              values={{
                minutes: estimatedTimeToComplete.minutes,
              }}
            />
          )}
          <FormattedMessage {...messages.confirmationMessageSuffix} />
        </Typography>
      </InnerContent>
      <SimpleModalFooter>
        <Button
          className={classes.cancelButton}
          type="secondary"
          onClick={handleClose}
          size="small"
        >
          <FormattedMessage {...globalMessages.cancel} />
        </Button>
        <Button onClick={handleContinue} size="small" type="primary">
          <FormattedMessage {...globalMessages.continue} />
        </Button>
      </SimpleModalFooter>
    </SimpleModal>
  );
};

BulkActionConfirmationModal.propTypes = {
  actionType: PropTypes.oneOf(['message', 'other']).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleContinue: PropTypes.func.isRequired,
  numberOfSelectedApplicants: PropTypes.number.isRequired,
};

export default BulkActionConfirmationModal;
