import { ToggleButton as Toggle } from '@fountain/fountain-ui-components';
import React, { VFC } from 'react';

export interface FiltersProps {
  toggleAssignedToMe: () => void;
  handleStatusChange: (status: string) => void;
  status: string;
  assignedToMe: boolean;
}

export const Filters: VFC<FiltersProps> = ({
  toggleAssignedToMe,
  handleStatusChange,
  status,
  assignedToMe,
}) => {
  return (
    <div>
      <Toggle
        className="fullstory__openings-active"
        variant="dropdown"
        onSelect={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) =>
          handleStatusChange(e.currentTarget.innerText)
        }
        label=""
        menuList={['Any Status', 'Approved', 'Pending', 'Rejected']}
        defaultSelection={status}
        closeOnSelect
      />
      <Toggle
        variant="default"
        label="My Approvals"
        onToggleOn={toggleAssignedToMe}
        onToggleOff={toggleAssignedToMe}
        selected={assignedToMe}
        defaultSelection=""
      />
    </div>
  );
};
