import {
  Grid,
  IconButton,
  Slide,
  Snackbar,
  SnackbarContent,
  withStyles,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Close from '@material-ui/icons/Close';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';

import Checkmark from 'images/checkmark-greenV2.svg';
import Info from 'images/info-blue.svg';
import WarningOrange from 'images/warning-orange.svg';
import WarningRed from 'images/warning-red.svg';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import { addMessageAction, removeMessageAction, Variant } from './actions';
import reducer from './reducer';
import saga from './saga';
import { makeSelectMessages } from './selectors';
import styles from './styles';

interface Message {
  id: number;
  text: string;
  variant: Variant;
}

interface FlashMessageProps {
  flashMessages: Message[];
  classes: Record<string, string>;
  handleClose: (messageId: Message['id']) => void;
}

export const FlashMessage: React.FC<FlashMessageProps> = ({
  flashMessages,
  classes,
  handleClose,
}) => {
  useInjectReducer({ key: 'flashMessage', reducer });
  // @ts-expect-error this should be resolved alongside converting `utils/injectSaga`
  useInjectSaga({ key: 'flashMessage', saga });

  if (!flashMessages.length) return null;

  const icons = {
    error: WarningRed,
    warning: WarningOrange,
    info: Info,
    success: Checkmark,
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={flashMessages.length > 0}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      TransitionComponent={(props: TransitionProps) => (
        <Slide {...props} direction="down" />
      )}
    >
      <Grid>
        {flashMessages.map(message => (
          <SnackbarContent
            key={message.id}
            message={
              <Grid
                container
                direction="row"
                wrap="nowrap"
                justify="center"
                className={classes.message}
              >
                <img
                  src={icons[message.variant]}
                  alt={`${message.variant} icon`}
                  className={classes.icon}
                />
                {message.text}
              </Grid>
            }
            className={classes[message.variant]}
            classes={{
              root: classes.root,
              action: classes.action,
            }}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.button}
                onClick={() => handleClose(message.id)}
              >
                <Close className={classes.close} />
              </IconButton>,
            ]}
          />
        ))}
      </Grid>
    </Snackbar>
  );
};

const mapStateToProps = createStructuredSelector({
  flashMessages: makeSelectMessages(),
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    addMessage: (type: unknown, text: string) =>
      dispatch(addMessageAction(text)),
    handleClose: (id: number) => dispatch(removeMessageAction(id)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  React.memo,
  // @ts-expect-error this should be resolved alongside converting `styles.js`
  withStyles(styles),
)(FlashMessage);
