export const DETAIL_TYPES = {
  address: 'address',
  availability: 'availability',
  checkboxes: 'checkboxes',
  date_picker: 'datepicker',
  dropdown: 'dropdown',
  radio: 'radio',
  text_area: 'text_area',
  text_field: 'text_field',
  license_data: 'license_data',
  history_employment: 'history_employment',
  history_address: 'history_address',
  file: 'file',
};

export const STRUCTURED_DATA = 'structured_data';
export const SECURE_DATA = 'secure_data';
export const DATA = 'data';
export const FILE = 'file';
export const TECH_CHECK_STATUS_KEY = 'tech_check_result';
