import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import { getConditionErrorMessage } from 'containers/WorkflowEditor/components/Rules/Condition/constants';
import { messages } from 'containers/WorkflowEditor/components/Rules/Condition/messages';
import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';

import { DocumentSigningRuleProps } from '../../../DocumentSigningRules/types';
import { RulesProps, SharedConditionProps } from '../../../types';

export const LabelSelect: FC<SharedConditionProps> = ({
  setRules,
  setDocumentSigningRules,
  ruleId,
  condition,
  errors,
}) => {
  const intl = useIntl();
  const { extra } = condition;

  const { labels } = useContext(RulesEditDataContext);

  const onChangeLabel = (newLabel: { value: string; label: string }) => {
    const rulesSetter = setRules ?? setDocumentSigningRules;

    if (rulesSetter) {
      rulesSetter(
        produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];

          const idx = draftConditions.findIndex(
            cond => cond.id === condition.id,
          );

          draftConditions[idx].extra.key = newLabel.value;
        }),
      );
    }
  };

  const selectedLabel =
    labels?.find(label => label.value === extra?.key) ?? null;

  const errorMessage = !extra?.key && getConditionErrorMessage('key', errors);

  return (
    <Grid item xs={6}>
      <StyledReactSelect
        options={labels}
        value={selectedLabel}
        onChange={onChangeLabel}
        label={intl.formatMessage(messages.label)}
        aria-label={intl.formatMessage(messages.label)}
        isSearchable
        error={errorMessage}
        placeholder=""
        required
      />
    </Grid>
  );
};
