import { Grid, InputLabel, TextField, Typography } from '@material-ui/core';
import { Info as InfoIcon } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { Stage } from 'api-clients/monolith';
import cx from 'classnames';
import invariant from 'invariant';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Error } from 'components/Error';
import { makeSelectWhoami } from 'containers/Auth_old/selectors';
import { useUnscopedStages } from 'hooks/useUnscopedStages';

import { messages } from './messages';
import { useStyles } from './styles';

interface StageMapperProps {
  fromWorkflowTitle: string;
  destinationWorkflowTitle: string;
  destinationWorkflowId: string;
  error?: string;
  sourceStages: Stage[];
  stageMappings: Record<string, Stage>;
  setStageMappings: (stageMappings: Record<string, Stage>) => void;
}

const filteredStages = (stages: Stage[]): Stage[] =>
  stages.filter(
    s =>
      !s.parent_stage_id || (s.parent_stage_id && s.type === 'SchedulerStage'),
  );

export const StageMapper: React.VFC<StageMapperProps> = ({
  fromWorkflowTitle,
  destinationWorkflowTitle,
  destinationWorkflowId,
  error,
  sourceStages,
  stageMappings,
  setStageMappings,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const { result: destinationStagesResult } = useUnscopedStages({
    workflowId: destinationWorkflowId,
  });

  const { calendar_event_creation_enabled: showCancelWarning } = useSelector(
    makeSelectWhoami(),
  );
  const onChangeMapping = (
    sourceStageId: string,
    destinationStageId: Stage,
  ) => {
    const childStages = sourceStages.filter(
      stage => stage.parent_stage_id === sourceStageId,
    );
    const newMappings = { ...stageMappings };
    childStages.forEach(stage => {
      delete newMappings[stage.id];
    });
    setStageMappings({
      ...newMappings,
      [sourceStageId]: destinationStageId,
    });
  };

  useEffect(() => {
    if (destinationStagesResult.status === 'ready') {
      const newMappings = { ...stageMappings };
      filteredStages(sourceStages).forEach(sourceStage => {
        destinationStagesResult.data.stages.forEach(stage => {
          if (
            sourceStage.type === stage.type &&
            sourceStage.title === stage.title
          ) {
            newMappings[sourceStage.id] = stage;
          }
        });
      });
      setStageMappings(newMappings);
    }
    // this explicitly _sets_ stage mappings when the destination workflow stages are loaded
    // therefore we _do not_ want to re-execute this logic when stage mappings change, because well, that would just wipe out changes
    // also eslint is dumb and things destinationStagesResult.data.stages needs to be in here but it's inaccessable until they're loaded
    // we don't take disabling exhaustive deps lightly O:)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    destinationWorkflowId,
    destinationStagesResult.status,
    sourceStages,
    setStageMappings,
  ]);

  return (
    <Grid container spacing={2} className={classes.body}>
      <Grid item xs={12}>
        <Typography
          variant="body2"
          color="inherit"
          className={classes.updateDescripiton}
        >
          <FormattedMessage {...messages.updateWorkflowBody} />
        </Typography>
      </Grid>
      {sourceStages.some(
        stage => !!stage.parent_stage_id && stage.type !== 'SchedulerStage',
      ) && (
        <Grid item className={classes.schedulerWarning}>
          <InfoIcon className={classes.infoIcon} />
          <Typography variant="h4" color="inherit">
            <FormattedMessage {...messages.schedulerSubstageWarning} />
          </Typography>
        </Grid>
      )}
      {showCancelWarning && (
        <Grid item className={classes.schedulerWarning}>
          <InfoIcon className={classes.infoIcon} />
          <Typography variant="h4" color="inherit">
            <FormattedMessage {...messages.schedulerCancelWarning} />
          </Typography>
        </Grid>
      )}
      <Grid item container xs={12} spacing={2}>
        <Grid item container>
          <Grid item xs={6} className={classes.columnSpacing}>
            <Typography
              variant="h5"
              color="inherit"
              className={classes.workflowHeader}
            >
              <FormattedMessage {...messages.sourceStages} />
            </Typography>
            <Typography variant="h4" color="inherit">
              {fromWorkflowTitle}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="h5"
              color="inherit"
              className={classes.workflowHeader}
            >
              <FormattedMessage {...messages.destinationStages} />
            </Typography>
            <Typography variant="h4" color="inherit">
              {destinationWorkflowTitle}
            </Typography>
            {!!error && <Error error={error} />}
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          {destinationStagesResult.isError && (
            <Typography variant="body2" color="error">
              <FormattedMessage {...messages.unableToLoadDestinationStages} />
            </Typography>
          )}
          {destinationStagesResult.status === 'ready' &&
            filteredStages(sourceStages).map(sourceStage => {
              const destinationStages = destinationStagesResult.data.stages;
              const selectedValue =
                destinationStagesResult.data.stages.find(stage => {
                  return stage.id === stageMappings[sourceStage.id]?.id;
                }) ?? null;

              const errorExistsForStage = Boolean(
                !!error && !stageMappings[sourceStage.id],
              );

              const chosenParentStage = () => {
                if (
                  !sourceStage.parent_stage_id ||
                  !stageMappings[sourceStage.parent_stage_id]
                ) {
                  return undefined;
                }
                return stageMappings[sourceStage.parent_stage_id];
              };

              const destinationParentStage = chosenParentStage();

              // don't allow select for sub stage unless the parent stage is mapped
              // and the destination stage is a multi stage too
              const disableSelect = () => {
                if (!sourceStage.parent_stage_id) {
                  return false;
                }
                if (!destinationParentStage) {
                  return true;
                }
                return destinationParentStage.type !== 'MultiStage';
              };

              // if this source is scheduler stage, it can only go into a destination scheduler stage
              // if the destination scheduler hasn't yet been mapped to another scheduler stage.
              const unavailableSchedulerStages = (destinationStage: Stage) => {
                if (sourceStage.type !== 'SchedulerStage') {
                  return false;
                }

                if (destinationStage.type !== 'SchedulerStage') {
                  return false;
                }

                if (selectedValue && selectedValue === destinationStage) {
                  return false;
                }

                const sourceStageIdsForSchedulerStage = Object.keys(
                  stageMappings,
                ).filter(key => stageMappings[key] === destinationStage);

                const sourceSchedulerStagesforDestination =
                  sourceStageIdsForSchedulerStage.filter(sourceId => {
                    const stage = sourceStages.find(s => s.id === sourceId);

                    invariant(
                      stage !== undefined,
                      'stage ID not in source stages',
                    );

                    return stage.type === 'SchedulerStage';
                  });

                return sourceSchedulerStagesforDestination.length > 0;
              };

              // if this source stage is a sub stage and its parent has been mapped
              // only show destination stages that are substages of the parent
              const availableOptions = destinationParentStage
                ? destinationStages.filter(
                    s =>
                      s.parent_stage_id === destinationParentStage.id &&
                      s.type === 'SchedulerStage',
                  )
                : destinationStages.filter(s => !s.parent_stage_id);

              return (
                <React.Fragment key={sourceStage.id}>
                  <Grid
                    item
                    xs={6}
                    className={cx(classes.columnSpacing, {
                      [classes.subStage]: !!sourceStage.parent_stage_id,
                    })}
                  >
                    <Typography variant="h4" color="inherit">
                      {sourceStage.title}
                    </Typography>
                    <InputLabel>
                      <Typography
                        variant="body2"
                        className={classes.sourceType}
                      >
                        <FormattedMessage {...messages[sourceStage.type]} />
                      </Typography>
                    </InputLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      aria-label={intl.formatMessage(
                        messages.destinationStageSelect,
                        { sourceStage: sourceStage.title },
                      )}
                      data-testid={`destination-stage-select-${sourceStage.id}`}
                      disableClearable
                      disabled={disableSelect()}
                      options={availableOptions}
                      getOptionLabel={option => option.title}
                      getOptionDisabled={option =>
                        unavailableSchedulerStages(option)
                      }
                      onChange={(_, option) =>
                        option && onChangeMapping(sourceStage.id, option)
                      }
                      value={selectedValue}
                      renderInput={params => (
                        <TextField
                          {...params}
                          margin="dense"
                          variant="outlined"
                          placeholder={intl.formatMessage(messages.selectStage)}
                          name={intl.formatMessage(
                            messages.destinationStageSelect,
                            { sourceStage: sourceStage.title },
                          )}
                          error={errorExistsForStage}
                        />
                      )}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
        </Grid>
      </Grid>
    </Grid>
  );
};
