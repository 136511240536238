import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { classNames } from 'react-extras';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  REACT_APP_LANDING_PAGE_URL,
  REACT_APP_MONOLITH_BASE_URL,
} from 'runtimeEnvVars';

import FountainLogo from 'images/fountain-logo';
import globalMessages from 'shared/global/messages';

import messages from './messages';
import useStyles from './styles';

function PublicNavbar() {
  const [scrolledLow, setScrolledLow] = useState(false);

  const classes = useStyles({ scrolledLow });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 500) {
      setScrolledLow(true);
    } else {
      setScrolledLow(false);
    }
  };
  return (
    <header
      className={classNames(classes.header, {
        scrollActive: scrolledLow,
      })}
    >
      <Grid
        container
        justify="space-between"
        alignItems="center"
        wrap="nowrap"
        className={classes.navContainer}
      >
        <a
          title="Fountain"
          className={classes.navClickable}
          href={REACT_APP_MONOLITH_BASE_URL}
        >
          <FountainLogo
            className={classes.fountainLogo}
            width={101}
            height={40}
          />
        </a>
        <div className={classes.actionLinks}>
          <div className={classes.actionDemo}>
            <a
              className={classNames(classes.navClickable, {
                white: scrolledLow,
              })}
              href="https://get.fountain.com/signup"
            >
              <FormattedMessage {...messages.requestDemo} />
            </a>
          </div>
          <div
            className={classNames(classes.actionLinkDivider, {
              white: scrolledLow,
            })}
          >
            |
          </div>
          <a
            className={classNames(classes.navClickable, {
              white: scrolledLow,
            })}
            href={REACT_APP_LANDING_PAGE_URL}
          >
            <FormattedMessage {...globalMessages.signIn} />
          </a>
        </div>
      </Grid>
    </header>
  );
}

export default injectIntl(PublicNavbar);
