import { Modal, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { WorkflowBuilderChatbotMessage } from 'api-clients/monolith';
import React, { VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { MediaMessage } from './MediaMessage';
import { messages } from './messages';

const useStyles = makeStyles(theme => ({
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '432px',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3),
    borderRadius: theme.misc.borderRadius,
  },
  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '32px',
    justifyContent: 'space-between',
    '& p': {
      fontWeight: '500',
      fontSize: '20px',
    },
  },
  closeIcon: {
    color: theme.palette.common.gray800,
    fontSize: 16,
    margin: theme.spacing(0.25, 0, 0, 0),
    cursor: 'pointer',
  },
  modalBody: {
    padding: theme.spacing(4, 0, 4, 0),
  },
}));

export interface FileUploadModalProps {
  handleCloseFileUploadModal: () => void;
  updateMessages: (messages: WorkflowBuilderChatbotMessage) => void;
  setShowTypingIndicator: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FileUploadModal: VFC<FileUploadModalProps> = ({
  handleCloseFileUploadModal,
  updateMessages,
  setShowTypingIndicator,
}) => {
  const styles = useStyles();

  return (
    <Modal open>
      <div className={styles.modalBox}>
        <div className={styles.modalHeader}>
          <Typography variant="h3">
            <FormattedMessage {...messages.header} />
          </Typography>
          <Close
            className={styles.closeIcon}
            onClick={handleCloseFileUploadModal}
          />
        </div>
        <MediaMessage
          handleCloseFileUploadModal={handleCloseFileUploadModal}
          updateMessages={updateMessages}
          setShowTypingIndicator={setShowTypingIndicator}
        />
      </div>
    </Modal>
  );
};
