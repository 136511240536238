import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { getLocalStorageItem, setLocalStorageItem } from 'utils/storageUtils';

export function useLocalStorageItem<T>(
  defaultValue: T,
  key: string,
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T>(() => {
    const stickyValue: string | null = getLocalStorageItem(key);
    let initialValue: T = defaultValue;
    if (stickyValue !== null) {
      initialValue = JSON.parse(stickyValue) as T;
    }
    return initialValue;
  });
  useEffect(() => {
    setLocalStorageItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}
