/*
 *
 * SendEmailPopup actions
 *
 */

import {
  DELIVER_EMAIL_ERROR,
  DELIVER_EMAIL_INIT,
  DELIVER_EMAIL_SUCCESS,
  FETCH_MEMBERS_ERROR,
  FETCH_MEMBERS_INIT,
  FETCH_MEMBERS_SUCCESS,
} from './constants';

export function deliverMessageInitAction(params, messages) {
  return {
    type: DELIVER_EMAIL_INIT,
    params,
    messages,
  };
}

export function deliverEmailSuccessAction(payload) {
  return {
    type: DELIVER_EMAIL_SUCCESS,
    payload,
  };
}

export function deliverEmailErrorAction() {
  return {
    type: DELIVER_EMAIL_ERROR,
  };
}

export function fetchMembersInitAction(brandSlug) {
  return {
    type: FETCH_MEMBERS_INIT,
    brandSlug,
  };
}

export function fetchMembersSuccessAction(users) {
  return {
    type: FETCH_MEMBERS_SUCCESS,
    users,
  };
}

export function fetchMembersErrorAction(error) {
  return {
    type: FETCH_MEMBERS_ERROR,
    error,
  };
}
