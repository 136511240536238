import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  advancedSettings: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.VideoRecordingStage.AdvancedSettings.advancedSettings`,
    defaultMessage: 'Advanced Settings',
  },
  automaticallyMoveApplicant: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.VideoRecordingStage.AdvancedSettings.automaticallyMoveApplicant`,
    defaultMessage:
      'Automatically move applicant to next stage after the stage is completed (recommended)',
  },
});
