import { Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState, VFC } from 'react';
import { useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import GlobalNav from 'components/GlobalNav';
import {
  makeSelectAccountSlug,
  makeSelectFeatureChatbotAdminEnabled,
  makeSelectFeatureChatbotAutomatedResponseEnabled,
  makeSelectFeatureChatbotReviewEnabled,
  makeSelectFountainAiCareerSiteEnabled,
  makeSelectFountainAiFaqEnabled,
  makeSelectIsAccountFountainAiEnabled,
} from 'containers/Auth_old/selectors';
import { updateParams } from 'utils/urlUtils';

import { AIJobsDirectoryCareerSite } from './AIJobsDirectoryCareerSite';
import { AutomatedResponses } from './AutomatedResponses';
import {
  ChatbotFeedback,
  SelectedFilters,
  SelectedSortBy,
  SelectedStatus,
  sortOptions,
  statusFilterOptions,
} from './ChatbotFeedback';
import { ChatbotWidget } from './ChatbotWidget';
import {
  AI_JOBS_DIRECTORY_CAREER_SITE,
  ALL,
  ANALYTICS,
  AUTOMATED_RESPONSES,
  CHATBOT_REVIEW,
  CHATBOT_WIDGET,
} from './constants';
import { chatbotFeedbackSubMenus, Sidebar } from './Sidebar';
import { SubMenu, WidgetSubMenu } from './types';
import { WidgetAnalytics } from './WidgetAnalytics';

const useStyles = makeStyles({
  chatbotContainer: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    height: '100%',
    overflow: 'auto',
  },
});

export interface ChatbotProps {
  match: {
    path: string;
  };
}

export const Chatbot: VFC<ChatbotProps> = ({ match: { path } }) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const { search } = useLocation();
  const styles = useStyles();

  const accountSlug = useSelector(makeSelectAccountSlug()) ?? '';
  const isFeatureAutomatedResponseEnabled = useSelector(
    makeSelectFeatureChatbotAutomatedResponseEnabled(),
  );
  const isFountainAiCareerSiteEnabled = useSelector(
    makeSelectFountainAiCareerSiteEnabled(),
  );
  const isFeatureChatbotAdminEnabled = useSelector(
    makeSelectFeatureChatbotAdminEnabled(),
  );
  const isFeatureChatbotReviewEnabled = useSelector(
    makeSelectFeatureChatbotReviewEnabled(),
  );
  const isFeatureFountainAiEnabled = useSelector(
    makeSelectIsAccountFountainAiEnabled(),
  );
  const isFeatureFountainAiFaqEnabled = useSelector(
    makeSelectFountainAiFaqEnabled(),
  );

  const [botType, setBotType] = useState<SubMenu>('All');

  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    status: 'All',
    models: [],
    intents: [],
    sortBy: 'Newest',
  });

  // On component mount, get the filters from query string and set local states
  useEffect(() => {
    const params = new URLSearchParams(search);

    const botTypeParam = params.get('botType') as SubMenu;
    if (
      botTypeParam &&
      chatbotFeedbackSubMenus.some(item => item.value === botTypeParam)
    ) {
      setBotType(botTypeParam);
    }

    const statusParam = params.get('status') as SelectedStatus;
    if (statusParam && statusFilterOptions.includes(statusParam)) {
      setSelectedFilters({ ...selectedFilters, status: statusParam });
    }

    const sortByParam = params.get('sortBy') as SelectedSortBy;
    if (sortByParam && sortOptions.includes(sortByParam)) {
      setSelectedFilters({ ...selectedFilters, sortBy: sortByParam });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSidebarMenuSelection = (
    botType: SubMenu,
    module: string,
    widgetSubMenu?: WidgetSubMenu,
  ) => {
    setBotType(botType);
    let path = `/${accountSlug}/chatbot/${module}`;

    if (module === CHATBOT_REVIEW) {
      const params = updateParams({ botType, ...selectedFilters });
      path += `?${params}`;
    }

    if (module === CHATBOT_WIDGET && widgetSubMenu) {
      path += `/${widgetSubMenu}`;
    }

    history.replace(path);
  };

  return (
    <div className={styles.chatbotContainer}>
      <Hidden smDown>
        <GlobalNav />
      </Hidden>

      <Grid container direction="row" wrap="nowrap" className={styles.content}>
        <Sidebar
          botType={botType}
          handleSidebarMenuSelection={handleSidebarMenuSelection}
        />
        <Switch>
          <Route path={[`${path}/${AUTOMATED_RESPONSES}`]}>
            <>
              {isFeatureAutomatedResponseEnabled ||
              isFeatureChatbotAdminEnabled ? (
                <AutomatedResponses />
              ) : (
                // Redirect to /chatbot_review if user don't have access to /automated_responses
                isFeatureChatbotReviewEnabled && (
                  <Redirect to={`${url}/${CHATBOT_REVIEW}`} />
                )
              )}
            </>
          </Route>
          <Route path={`${path}/${CHATBOT_REVIEW}`}>
            {isFeatureChatbotReviewEnabled || isFeatureChatbotAdminEnabled ? (
              <ChatbotFeedback
                // The botType query param is not passed to the API to fetch all. So, setting it to undefined.
                botType={
                  botType === ALL || botType === null ? undefined : botType
                }
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
              />
            ) : (
              // Redirect to /automated_responses if user don't have access to /chatbot_review
              isFeatureAutomatedResponseEnabled && (
                <Redirect to={`${url}/${AUTOMATED_RESPONSES}`} />
              )
            )}
          </Route>
          {isFeatureFountainAiFaqEnabled && (
            <Route exact path={[`${path}/${CHATBOT_WIDGET}/${ANALYTICS}`]}>
              <WidgetAnalytics />
            </Route>
          )}
          <Route path={[`${path}/${CHATBOT_WIDGET}`]}>
            {(isFeatureFountainAiEnabled || isFeatureFountainAiFaqEnabled) && (
              <ChatbotWidget />
            )}
          </Route>
          <Route path={[`${path}/${AI_JOBS_DIRECTORY_CAREER_SITE}`]}>
            <>
              {isFountainAiCareerSiteEnabled ? (
                <AIJobsDirectoryCareerSite />
              ) : (
                // Redirect to /chatbot_review if user don't have access to /ai_jobs_directory_career_site
                isFeatureChatbotReviewEnabled && (
                  <Redirect to={`${url}/${CHATBOT_REVIEW}`} />
                )
              )}
            </>
          </Route>

          {/* Redirect to /chatbot/chatbot_widget if user tries to access /chatbot */}
          <Redirect to={`${url}/${CHATBOT_WIDGET}`} />
        </Switch>
      </Grid>
    </div>
  );
};
