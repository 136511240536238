import { DropdownArrow } from '@fountain/fountain-ui-components';
import { Button, Link, List, Typography } from '@material-ui/core';
import { FunnelDetails } from 'api-clients/monolith/models/FunnelDetails';
import classNames from 'classnames';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import { makeSelectCountryCode } from 'containers/Auth_old/selectors';
import { JobSearchMenu } from 'containers/JobSearch/JobSearchMenu/JobSearchMenu';
import useAnchorState from 'hooks/useAnchorState';
import { isAmazon } from 'utils/constants';

import { messages } from './messages';
import { useStyles } from './styles';

export interface OpeningNavBarProps {
  applicantCount: number;
  opening: FunnelDetails;
  selected: 'workflowEditor' | 'openingDetails';
}

export const OpeningNavBar: FC<OpeningNavBarProps> = ({
  applicantCount,
  opening,
}) => {
  const intl = useIntl();
  const styles = useStyles();
  const { accountSlug, funnelSlug } = useParams<{
    accountSlug: string;
    funnelSlug: string;
  }>();

  const countryCode = useSelector(makeSelectCountryCode());

  const openingDetailUrl = `${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/openings/${funnelSlug}`;
  const manageLabelUrl = `${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/labels`;

  const shouldShowManageLabels =
    opening.manage_labels && isAmazon(accountSlug, countryCode);

  const {
    anchorEl: jobSearchAnchorEl,
    toggle: toggleJobSearch,
    handleClose: handleJobSearchClose,
  } = useAnchorState();

  return (
    <>
      <nav aria-label={intl.formatMessage(messages.workflowEditorNavigation)}>
        <List disablePadding className={styles.navBar} component="ol">
          <li>
            <Button
              className={styles.openingNameButton}
              disableRipple
              onClick={toggleJobSearch}
            >
              <Typography variant="h4" className={styles.openingNameText}>
                {opening.title}
              </Typography>
              <DropdownArrow
                className={styles.dropdownArrow}
                fontSize="small"
              />
            </Button>
          </li>
          <li>
            <Typography className={styles.text}>
              <FormattedMessage
                values={{ applicantCount }}
                {...messages.applicantCount}
              />
            </Typography>
          </li>
          {shouldShowManageLabels && (
            <li className={styles.marginLeftAuto}>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href={manageLabelUrl}
              >
                <Typography display="inline" variant="h4">
                  {intl.formatMessage(messages.manageLabels)}
                </Typography>
              </Link>
            </li>
          )}
          <li
            className={classNames({
              [styles.marginLeftAuto]: !shouldShowManageLabels,
            })}
          >
            <Link href={openingDetailUrl}>
              <Typography display="inline" variant="h4">
                {intl.formatMessage(messages.openingDetails)}
              </Typography>
            </Link>
          </li>
        </List>
      </nav>
      {jobSearchAnchorEl && (
        <JobSearchMenu
          anchorEl={jobSearchAnchorEl}
          onClose={handleJobSearchClose}
          shouldShowActiveFilter={false}
          jobSearchMenuClassName={styles.jobSearchMenuClassName}
        />
      )}
    </>
  );
};
