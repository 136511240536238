import globalMessages from 'shared/global/messages';

import { SEND_TYPE_OPTIONS } from './constants';
import messages from './messages';

export function generateSendTypeOptions(formatMessage) {
  return SEND_TYPE_OPTIONS.map(opt => ({
    label: formatMessage(globalMessages[opt.label]),
    value: opt.value,
  }));
}

export function generateDefaultTemplate(formatMessage) {
  return {
    email_subject: formatMessage(messages.defaultTemplate),
    id: '__no_template__',
  };
}

export const phonePlatformTabs = {
  SMS: 0,
  WHATSAPP: 1,
};
