/* eslint-disable camelcase */

import queryString from 'query-string';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import {
  bulkActionInit,
  bulkActionSubmitted,
  clearApplicantCache,
  resetSelectedApplicantIds,
} from 'containers/ApplicantTableV2/actions';
import { makeSelectSelectedFilterStageIdsWithFunnel } from 'containers/ApplicantTableV2/selectors';
import { addDefaultErrorMessageAction } from 'containers/FlashMessage/actions';
import { closePopup } from 'containers/GlobalPopup/actions';
import { Transition } from 'containers/MoveApplicantDialog/constants';
import { apiPost } from 'utils/axios';

export interface HandleBulkApplicantsTransitionProps {
  applicantIds?: string | string[];
  selectByCount?: number;
  params?: {
    from_stage?: string;
    from_funnel?: string;
    to_stage?: string;
    to_funnel?: string;
    should_run_when_land?: boolean;
    stage_name?: string | null;
    rejection_reason_id?: string | number;
    archived_reason_id?: string | number;
  };
  runAutomatedActions?: boolean;
  search?: string | string[] | null;
  type: Transition;
  selectedStageId?: string;
  isMasterApplicantsView?: boolean;
}

export default function useBulkApplicantsTransition() {
  const selectedFilterStageIdsWithFunnel = useSelector(
    makeSelectSelectedFilterStageIdsWithFunnel(),
  );
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    filter_set_id,
    temp_filter_id,
    duplicate_applicant_id,
    stage_conditions_filter_set_id,
  } = queryString.parse(location.search);

  if (!REACT_APP_GLOBAL_API_BASE_URL_V2) {
    throw new Error('REACT_APP_GLOBAL_API_BASE_URL_V2 is undefined');
  }

  const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/bulk_actions`;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  const handleBulkApplicantsTransition = useCallback(
    (
      {
        applicantIds,
        selectByCount,
        params,
        search,
        type,
        selectedStageId,
        isMasterApplicantsView,
      }: HandleBulkApplicantsTransitionProps,
      onSuccess: () => void = () => {},
    ) => {
      const bulkMoveToStage = async () => {
        dispatch(bulkActionInit());
        setIsSubmitting(true);
        try {
          dispatch(closePopup());

          const res = await apiPost(url, {
            bulk_action: {
              applicant_ids: Array.isArray(applicantIds)
                ? applicantIds
                : undefined,
              selected_by_count: selectByCount,
              arguments: {
                ...params,
                ...(duplicate_applicant_id && { duplicate_applicant_id }),
              },
              stage_id: selectedStageId,
              filter_set_id: filter_set_id ?? temp_filter_id,
              stage_conditions_filter_set_id:
                stage_conditions_filter_set_id ?? null,
              // only UAV can look at multiple stages at the same time, the normal view
              // cannot filter by multiple stages, so we don't send the UAV filters here.
              stage_ids: isMasterApplicantsView
                ? selectedFilterStageIdsWithFunnel.flatMap(swf => swf.stage_ids)
                : [],
              search,
              type,
            },
          });

          dispatch(bulkActionSubmitted());

          if (res.data) {
            dispatch(resetSelectedApplicantIds());
            dispatch(clearApplicantCache(applicantIds));
          }
          onSuccess();
        } catch (exception) {
          setError(exception as string);
          dispatch(addDefaultErrorMessageAction());
        } finally {
          setIsSubmitting(false);
        }
      };
      void bulkMoveToStage();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return { error, handleBulkApplicantsTransition, isSubmitting };
}
