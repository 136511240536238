/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { VonqContract } from '../models/VonqContract';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SourcingService {

    /**
     * Creates a Vonq contract
     * @param requestBody
     * @returns VonqContract Vonq contract created
     * @throws ApiError
     */
    public static postInternalApiSourcingVonqContracts(
        requestBody: VonqContract,
    ): CancelablePromise<VonqContract> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/sourcing/vonq_contracts',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Deletes a Vonq contract
     * @param vonqContractId ID of the Vonq contract to delete
     * @returns any Contract successfully deleted
     * @throws ApiError
     */
    public static deleteInternalApiSourcingVonqContracts(
        vonqContractId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/internal_api/sourcing/vonq_contracts/{vonq_contract_id}',
            path: {
                'vonq_contract_id': vonqContractId,
            },
            errors: {
                404: `Not found`,
            },
        });
    }

}