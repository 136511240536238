/*
 *
 * SendEmailPopup constants
 *
 */

/* action constants */
export const DELIVER_EMAIL_INIT = 'app/SendEmailPopup/DELIVER_EMAIL_INIT';
export const DELIVER_EMAIL_SUCCESS = 'app/SendEmailPopup/DELIVER_EMAIL_SUCCESS';
export const DELIVER_EMAIL_ERROR = 'app/SendEmailPopup/DELIVER_EMAIL_ERROR';

export const FETCH_MEMBERS_INIT = 'app/SendEmailPopup/FETCH_MEMBERS_INIT';
export const FETCH_MEMBERS_SUCCESS = 'app/SendEmailPopup/FETCH_MEMBERS_SUCCESS';
export const FETCH_MEMBERS_ERROR = 'app/SendEmailPopup/FETCH_MEMBERS_ERROR';

/* etc */

export const TIME_FORMAT = 'hh:mm A z';
export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

export const TEMPLATE_TYPES = {
  I9_REDO_FORM: 'i9_redo_form',
  I9_SECTION_THREE_REMOTE_FORM: 'i9_section_three_remote_form',
  GENERAL: 'general',
  REQUEST_REVISION: 'request_revision',
  W4_FEDERAL_REDO_FORM: 'w4_federal_redo_form',
  W4_STATE_REDO_FORM: 'w4_state_redo_form',
};

export const BULK_PARTNER_STAGE_REDO = 'BulkPartnerStageRedo';

export const MAX_REQUEST_REVISION_RECIPIENTS = 500;

export const MAX_WIDTH = 608;
