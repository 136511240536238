import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { WorkflowBuilderChatbotMessage } from 'api-clients/monolith';
import React, { VFC } from 'react';

import { HandlesubmitApplicantResponse } from 'containers/AIWorkflowBuilder/types';

import { FilePreview } from '../MediaMessage/FileUpload/FilePreview';
import { MessageBubble } from './MessageBubble';

const useStyles = makeStyles(theme => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  stackedButton: {
    marginTop: theme.spacing(1),
    textAlign: 'left',
    width: 'fit-content',
    borderRadius: '10px',
  },
}));

export interface MessageElementProps {
  message: WorkflowBuilderChatbotMessage;
  setMessage: (message: WorkflowBuilderChatbotMessage) => void;
  submitApplicantResponse: HandlesubmitApplicantResponse;
}

export const MessageElement: VFC<MessageElementProps> = ({
  message,
  setMessage,
  submitApplicantResponse,
}) => {
  const styles = useStyles();

  const { element_disable: elementDisable, element_data: elementData } =
    message;

  const { selectedButton } = elementData ?? {};

  const handleClick = (label: string, value: string) => {
    setMessage({
      ...message,
      element_disable: true,
      element_data: {
        ...message.element_data,
        selectedButton: value,
      },
    });
    submitApplicantResponse({
      applicantResponse: label,
    });
  };

  switch (elementData?.key) {
    case 'button':
      return (
        <>
          {message.content && <MessageBubble message={message} />}
          <div className={styles.buttonsContainer}>
            {elementData?.buttons?.map(
              (button: { label: string; value: string }, index) => (
                <Button
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className={styles.stackedButton}
                  disabled={elementDisable}
                  variant={
                    selectedButton === button.value ? 'contained' : 'outlined'
                  }
                  fullWidth
                  onClick={() => handleClick(button.label, button.value)}
                >
                  {button.label}
                </Button>
              ),
            )}
          </div>
        </>
      );
    case 'file':
      return (
        <MessageBubble
          message={{
            ...message,
            content: null,
          }}
        >
          <FilePreview type="document" name={message.content ?? ''} />
        </MessageBubble>
      );
    default:
      return null;
  }
};
