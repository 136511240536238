import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  alertBodyContainer: {
    gap: theme.spacing(0.5),
    display: 'flex',
  },
  assessmentContainer: {
    marginBottom: theme.spacing(2),
  },
  assessmentHeader: {
    marginBottom: theme.spacing(0.5),
    position: 'relative',
  },
  assessmentTitle: {
    color: theme.palette.common.black,
    paddingTop: theme.spacing(1.5),
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  cardBody: {
    marginLeft: theme.spacing(1.5),
    paddingBottom: theme.spacing(3),
  },
  createAssessmentButton: {
    height: theme.spacing(4),
    whiteSpace: 'nowrap',
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
  editMessageButton: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  editMessageIcon: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(1),
    '& path': {
      fill: theme.palette.common.gray600,
    },
  },
  manageAssessmentsButton: {
    height: theme.spacing(4),
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
  questionAlert: {
    height: theme.spacing(4),
    width: 'fit-content',
    marginBottom: theme.spacing(3),
  },
}));
