import { Event } from 'api-clients/monolith';
import { EventQuery, EventStageOption, EventUserOption } from 'Calendar/hooks';
import React from 'react';

export type EventModalVariant = 'create' | 'duplicate' | 'edit';

export interface EventManagerContext {
  pages: Event[][];
  setPages: (page: Event[][]) => void;
  setEventModalVariant: (variant: EventModalVariant) => void;
  eventModalVariant: EventModalVariant;
  stageOptions: EventStageOption[];
  setStageOptions: (options: EventStageOption[]) => void;
  userOptions: EventUserOption[];
  selectedEvent?: Event;
  setSelectedEvent: (event: Event) => void;
  filters: EventQuery;
  canManage: boolean;
}

export const defaultContext: EventManagerContext = {
  pages: [],
  setPages: () => {},
  setEventModalVariant: () => {},
  eventModalVariant: 'create',
  stageOptions: [],
  setStageOptions: () => {},
  userOptions: [],
  setSelectedEvent: () => {},
  filters: { page: 0, date: 'today', stages: [], funnels: [] },
  canManage: false,
};

export const eventManagerContext =
  React.createContext<EventManagerContext>(defaultContext);
