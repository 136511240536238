import { Loader, TextButton } from '@fountain/fountain-ui-components';
import { Button, Grid, Typography, TypographyProps } from '@material-ui/core';
import cx from 'classnames';
import React, { FC } from 'react';

import { useStyles } from './styles';

interface HeaderProps {
  title: React.ReactNode;
}

const Header: FC<HeaderProps> = ({ title, children }) => {
  const styles = useStyles();

  return (
    <Grid className={styles.header}>
      <Typography className={cx(styles.text, styles.title)} variant="h3">
        {title}
      </Typography>
      {children}
    </Grid>
  );
};

const Body: FC = ({ children }) => {
  const styles = useStyles();
  return <Grid className={styles.childrenContainer}>{children}</Grid>;
};

export type BaseStageSettingCardProps = {
  title: React.ReactNode;
  isLoading?: boolean;
  variant: 'default';
  leftButtonText?: string;
  leftButtonOnClick?: () => void;
};

export type ButtonStageSettingCardProps = {
  title: React.ReactNode;
  isLoading?: boolean;
  onClick?: () => void;
  buttonText?: string;
  icon?: React.ReactNode;
  buttonEnabled?: boolean;
  variant: 'button';
  buttonTextColor?: TypographyProps['color'];
  href?: string;
  target?: string;
  leftButtonText?: string;
  leftButtonOnClick?: () => void;
  rel?: string;
};

export type StageSettingCardProps =
  | BaseStageSettingCardProps
  | ButtonStageSettingCardProps;

export const StageSettingCard: FC<StageSettingCardProps> = props => {
  const { title, isLoading, variant, children } = props;
  const styles = useStyles();

  if (isLoading) {
    return (
      <Grid className={styles.stageSettingCardContainer}>
        <Header title={title} />
        <Body>
          <Loader fullSection />
        </Body>
      </Grid>
    );
  }

  let LeftButton = null;

  if (props.leftButtonText && props.leftButtonOnClick) {
    const { leftButtonText, leftButtonOnClick } = props;

    LeftButton = (
      <TextButton
        color="primary"
        onClick={leftButtonOnClick}
        className={styles.leftButton}
      >
        <Typography color="primary" variant="h4">
          {leftButtonText}
        </Typography>
      </TextButton>
    );
  }

  if (variant === 'button') {
    const {
      buttonText,
      icon,
      onClick,
      href,
      target,
      buttonTextColor = 'initial',
      buttonEnabled = true,
      rel,
    } = props;

    return (
      <Grid className={styles.stageSettingCardContainer}>
        <Header title={title}>
          {LeftButton}
          {Boolean(buttonEnabled) && (
            <Button
              className={styles.button}
              onClick={onClick}
              disableRipple
              {...(href ? { href, target, rel } : {})}
            >
              {icon}
              {buttonText && (
                <Typography
                  variant="h4"
                  className={cx({
                    [styles.text]: buttonTextColor === 'initial',
                  })}
                  color={buttonTextColor}
                >
                  {buttonText}
                </Typography>
              )}
            </Button>
          )}
        </Header>
        <Body>{children}</Body>
      </Grid>
    );
  }

  return (
    <Grid className={styles.stageSettingCardContainer}>
      <Header title={title}>{LeftButton}</Header>
      <Body>{children}</Body>
    </Grid>
  );
};
