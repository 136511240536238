import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  automaticallyMoveApplicantToNextStage: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DataCollectionStage.DataCollectionFormCard.automaticallyMoveApplicantToNextStage`,
    defaultMessage:
      'Automatically move applicant to next stage after data is collected (recommended)',
  },
  dataCollectionForm: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DataCollectionStage.DataCollectionFormCard.dataCollectionForm`,
    defaultMessage: 'Data Collection Form',
  },
  editQuestions: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DataCollectionStage.DataCollectionFormCard.editQuestions`,
    defaultMessage: 'Edit questions',
  },
  notifyOwnerWhenDataSubmitted: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DataCollectionStage.DataCollectionFormCard.notifyOwnerWhenDataSubmitted`,
    defaultMessage: 'Notify opening owner when data is submitted',
  },
  missingQuestions: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DataCollectionStage.DataCollectionFormCard.missingQuestions`,
    defaultMessage:
      'At least one question needs to be set up in order to use this stage.',
  },
  removeLogicToEdit: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DataCollectionStage.DataCollectionFormCard.removeLogicToEdit`,
    defaultMessage: 'Remove logic to edit',
  },
  setQuestions: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DataCollectionStage.DataCollectionFormCard.setQuestions`,
    defaultMessage: 'Set questions',
  },
  shouldAutoAdvanceWhenLabelsComplete: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DataCollectionStage.DataCollectionFormCard.shouldAutoAdvanceWhenLabelsComplete`,
    defaultMessage:
      'Automatically move applicant to next stage when all labels are completed',
  },
  showOneQuestionAtATime: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DataCollectionStage.DataCollectionFormCard.showOneQuestionAtATime`,
    defaultMessage: 'Show one question at a time',
  },
  showStageInApplicantPortal: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DataCollectionStage.DataCollectionFormCard.showStageInApplicantPortal`,
    defaultMessage: 'Show stage in applicant portal progress tracker',
  },
  enableDataCollectionBot: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DataCollectionStage.DataCollectionFormCard.enableDataCollectionBot`,
    defaultMessage: 'Enable Data Collection Bot',
  },
});
