import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Stage } from 'api-clients/monolith';
import React from 'react';
import { useIntl } from 'react-intl';

import { Error } from 'components/Error';
import { useUnscopedStages } from 'hooks/useUnscopedStages';

import { messages } from './messages';

interface WorkflowStageSelectProps {
  workflowId: string;
  onChange: (stage: Stage) => void;
  value: string;
  error?: boolean;
}
export const WorkflowStageSelect: React.VFC<WorkflowStageSelectProps> = ({
  workflowId,
  onChange,
  value,
  error,
}) => {
  const { result } = useUnscopedStages({ workflowId });
  const intl = useIntl();

  const options = result.status === 'ready' ? result.data.stages : [];
  const selected = options.find(opt => opt.id === value) ?? null;

  return (
    <>
      <StyledReactSelect
        aria-label={intl.formatMessage(messages.stage)}
        isLoading={result.isLoading}
        options={options}
        label={intl.formatMessage(messages.stage)}
        placeholder={intl.formatMessage(messages.selectAStage)}
        onChange={onChange}
        getOptionLabel={(option: Stage) => option.title}
        getOptionValue={(option: Stage) => option.id}
        value={selected}
        error={!value && error}
        required
      />
      <Error align="right" error={!value && error} />
    </>
  );
};
