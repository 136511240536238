// Use a discriminated union so that we don't have to worry about our reply
// data being null once we know we've successfully loaded.
export type ReadyState<T> = {
  status: 'ready';
  data: T;
  isLoading: false;
  isError: false;
};

export const buildReadyState = <T>(data: T): ReadyState<T> => ({
  status: 'ready',
  data,
  isLoading: false,
  isError: false,
});

export type IdleState = {
  status: 'idle';
  isLoading: false;
  isError: false;
};

export const buildIdleState = (): IdleState => ({
  status: 'idle',
  isLoading: false,
  isError: false,
});

export type ErrorState<T> = {
  status: 'error';
  error?: T;
  isLoading: false;
  isError: true;
};

export const buildErrorState = <T>(error?: T): ErrorState<T> => ({
  status: 'error',
  error,
  isLoading: false,
  isError: true,
});

export type LoadingState = {
  status: 'loading';
  isLoading: true;
  isError: false;
};

export const buildLoadingState = (): LoadingState => ({
  status: 'loading',
  isLoading: true,
  isError: false,
});

export type ReloadingState<T> = {
  status: 'reloading';
  data: T;
  isLoading: true;
  isError: false;
};

export const buildreloadState = <T>(data: T): ReloadingState<T> => ({
  status: 'reloading',
  isLoading: true,
  isError: false,
  data,
});

export type ResultStatus<T, E = unknown> =
  | ReadyState<T>
  | ErrorState<E>
  | IdleState
  | LoadingState;

export type ReloadableResultStatus<T, E = unknown> =
  | ReadyState<T>
  | ErrorState<E>
  | LoadingState
  | ReloadingState<T>;
