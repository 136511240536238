import { createSelector } from 'reselect';

import { initialState } from './reducer';

/**
 * Direct selector to the globalPopup state domain
 */

const selectGlobalPopup = state => state.globalPopup || initialState;

/**
 * Default selector used by GlobalPopup
 */

const makeSelectSelectedPopup = () =>
  createSelector(selectGlobalPopup, substate => substate.selectedPopup);

const makeSelectPrevQueryParams = () =>
  createSelector(selectGlobalPopup, substate => substate.prevQueryParams);

export { makeSelectPrevQueryParams, makeSelectSelectedPopup };
