import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  description: {
    color: theme.palette.common.gray800,
    paddingBottom: theme.spacing(3),
  },
  addAddressButton: {
    color: theme.palette.common.blue400,
  },
}));
