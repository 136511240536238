import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  content: {
    background: theme.palette.common.white,
    padding: 0,
  },
  message: {
    padding: 0,
    width: '100%',
  },
  bulkActionQueueMenuItem: {
    padding: theme.spacing(2),
    order: 0,
    cursor: 'default',

    '&:hover': {
      backgroundColor: 'initial',
    },

    '&.isRunning': {
      order: 1,
    },
  },
  actionDetails: {
    '& > :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  userInfo: {
    flex: 1,
  },
  error: {
    marginLeft: theme.spacing(2),
  },
  errorText: {
    color: theme.palette.common.red600,
    marginLeft: theme.spacing(0.5),
  },
  progressBar: {
    borderBottomLeftRadius: theme.spacing(0.5),
    borderBottomRightRadius: theme.spacing(0.5),
  },
  estimatedTimeAndPause: {
    marginLeft: 'auto',
  },
  bulkActionTextContainer: {
    display: 'flex',
    minWidth: 0,
  },
  bulkActionText: {
    marginRight: theme.spacing(1),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

export default useStyles;
