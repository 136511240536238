import React from 'react';

const ChatSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 0H13C13.552 0 14 0.448 14 1V10C14 10.552 13.552 11 13 11H8L3 14.5V11H1C0.448 11 0 10.552 0 10V1C0 0.448 0.448 0 1 0Z"
      fill="#131E40"
    />
  </svg>
);

export default ChatSvg;
