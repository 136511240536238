/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import { Grid, Link, Typography } from '@material-ui/core';
import React from 'react';

import NotFoundImage from 'images/FourOFour.svg';

import useStyles from './styles';
import Wrapper from './Wrapper';

export default function NotFound() {
  const classes = useStyles();

  return (
    <Wrapper>
      <Grid className={classes.notFoundSection}>
        <Typography align="center" variant="h1">
          Page not found
        </Typography>
      </Grid>
      <Grid className={classes.notFoundSection}>
        <Typography align="center" variant="h3">
          Looks like something went wrong, let&apos;s take you&nbsp;
          <Link href="/">home.</Link>
        </Typography>
      </Grid>
      <img src={NotFoundImage} alt="Not Found" />
    </Wrapper>
  );
}
