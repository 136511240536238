import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  location: {
    defaultMessage: 'Location',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.components.LocationSelect.location`,
  },
  chooseLocation: {
    defaultMessage: 'Choose Location',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.components.LocationSelect.chooseLocation`,
  },
});
export default messages;
