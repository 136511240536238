import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { VFC } from 'react';

export const HumanHandoffIcon: VFC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 46 46">
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="46" height="46" rx="23" fill="#5E44FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1708 29.5511C30.0652 28.7106 30.7778 27.6956 31.2644 26.5688C31.7511 25.442 32.0014 24.2274 32 23C32 18.0292 27.9708 14 23 14C18.0292 14 14 18.0292 14 23C13.9986 24.2274 14.2489 25.442 14.7356 26.5688C15.2222 27.6956 15.9348 28.7106 16.8292 29.5511C18.4972 31.1267 20.7055 32.0031 23 32C25.2945 32.0031 27.5028 31.1267 29.1708 29.5511ZM17.5954 28.3649C18.2435 27.5541 19.0659 26.8997 20.0015 26.4504C20.9372 26.001 21.962 25.7682 23 25.7692C24.038 25.7682 25.0628 26.001 25.9985 26.4504C26.9341 26.8997 27.7565 27.5541 28.4046 28.3649C27.6976 29.0791 26.8557 29.6458 25.928 30.0321C25.0002 30.4184 24.005 30.6167 23 30.6154C21.995 30.6167 20.9998 30.4184 20.072 30.0321C19.1443 29.6458 18.3024 29.0791 17.5954 28.3649ZM26.4615 20.2308C26.4615 21.1488 26.0968 22.0293 25.4477 22.6784C24.7985 23.3276 23.9181 23.6923 23 23.6923C22.0819 23.6923 21.2015 23.3276 20.5523 22.6784C19.9032 22.0293 19.5385 21.1488 19.5385 20.2308C19.5385 19.3127 19.9032 18.4323 20.5523 17.7831C21.2015 17.1339 22.0819 16.7692 23 16.7692C23.9181 16.7692 24.7985 17.1339 25.4477 17.7831C26.0968 18.4323 26.4615 19.3127 26.4615 20.2308Z"
        fill="white"
      />
    </svg>
  </SvgIcon>
);
