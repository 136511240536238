import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  row: {
    padding: theme.spacing(1, 0),
    borderTop: theme.customBorders.gray300,

    '&:last-child': {
      borderBottom: theme.customBorders.gray300,
      marginBottom: theme.spacing(3),
    },
  },
  editMessageButton: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    marginRight: theme.spacing(1),
  },
  deleteMessageButton: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  editMessageIcon: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    '& path': {
      fill: theme.palette.common.gray600,
    },
  },
  deleteMessageIcon: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    '& path': {
      fill: theme.palette.common.gray600,
    },
  },
  locationText: {
    color: theme.palette.common.gray800,
  },
}));
