import { Radio } from '@fountain/fountain-ui-components';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

import FormikCheckbox from 'components/FormikCheckbox';

import {
  RELATIONSHIP_RADIO_OPTIONS,
  REQUEST_TYPE_CHECKBOX_OPTIONS,
} from './constants';
import useStyles from './styles';

function CcpaRequestFields({ values, setFieldValue, errors }) {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item className={classes.section}>
        <FormControl
          error={Boolean(
            errors.ccpa_request &&
              errors.ccpa_request.consumer_relationship_with_fountain,
          )}
        >
          <Box mb={2}>
            <FormLabel>Consumer&#39;s Relationship with Fountain</FormLabel>
          </Box>
          <RadioGroup
            name="ccpa_request.consumer_relationship_with_fountain"
            value={
              (values.ccpa_request &&
                values.ccpa_request.consumer_relationship_with_fountain) ||
              ''
            }
            onChange={e => {
              setFieldValue(
                'ccpa_request.consumer_relationship_with_fountain',
                e.target.value,
              );
            }}
          >
            {RELATIONSHIP_RADIO_OPTIONS.map(option => (
              <Radio
                key={option.value}
                value={option.value}
                label={option.label}
                className={classes.radio}
              />
            ))}
          </RadioGroup>
          <Box mt={1}>
            <Typography variant="body2" color="error">
              {errors.ccpa_request &&
                errors.ccpa_request.consumer_relationship_with_fountain}
            </Typography>
          </Box>
        </FormControl>
      </Grid>

      <Grid item className={classes.section}>
        <FormControl error={Boolean(errors.request_type)}>
          <Box mb={1}>
            <FormLabel>Request Type</FormLabel>
          </Box>
          {REQUEST_TYPE_CHECKBOX_OPTIONS.map(option => (
            <FormikCheckbox
              key={option.value}
              name={`ccpa_request.${option.value}`}
              checked={values.ccpa_request && values.ccpa_request[option.value]}
              label={option.label}
            />
          ))}
          <Box mt={1}>
            <Typography variant="body2" color="error">
              {errors.request_type}
            </Typography>
          </Box>
        </FormControl>
      </Grid>
    </Grid>
  );
}

CcpaRequestFields.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
};

export default CcpaRequestFields;
