import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormBuilder } from 'components/FormBuilder';
import { StageSettingCard } from 'containers/WorkflowEditor/components/StageSettingCard';

import { messages } from './messages';
import { useStyles } from './styles';

export const ReviewQuestionnaire = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Grid>
      <StageSettingCard
        variant="default"
        title={intl.formatMessage(messages.reviewQuestionnaireTitleText)}
      >
        <Typography className={classes.reviewHeader}>
          <FormattedMessage {...messages.reviewQuestionnaireHeaderHelperText} />
        </Typography>
        <FormBuilder />
      </StageSettingCard>
    </Grid>
  );
};
