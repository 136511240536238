import {
  Button,
  InnerContent,
  Input,
  Loader,
  SimpleModal,
  SimpleModalFooter,
  SimpleModalHeader,
  SimpleModalHeaderIcon,
  SimpleModalTitle,
} from '@fountain/fountain-ui-components';
import { MenuItem, OutlinedInput, Select } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { CancelablePromise, ChatbotService } from 'api-clients/monolith';
import React, { SetStateAction, useCallback, useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Error } from 'components/Error';
import { addMessageAction } from 'containers/FlashMessage/actions';
import { useApiService } from 'hooks/useApiService';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';
import Edit from 'images/edit.svg';
import globalMessages from 'shared/global/messages';

import {
  DEFAULT_CONFIDENCE_THRESHOLD,
  DEFAULT_MODE,
  MODES,
} from '../constants';
import messages from './messages';
import { useStyles } from './styles';

import type { AutomatedResponseMode } from 'api-clients/monolith/models/AutomatedResponseMode';
import type { AutomatedResponseModel } from 'api-clients/monolith/models/AutomatedResponseModel';
import type { AutomatedResponseModelUpdatePayload } from 'api-clients/monolith/models/AutomatedResponseModelUpdatePayload';
export interface AutomatedResponseToggleModelProps {
  setIsToggleModePopupOpen: (flag: boolean) => void;
  activeModel: AutomatedResponseModel | null;
  setIsRefetch: React.Dispatch<SetStateAction<boolean>>;
}

export const AutomatedResponseToggleModel: VFC<
  AutomatedResponseToggleModelProps
> = ({ setIsToggleModePopupOpen, activeModel, setIsRefetch }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const intl = useIntl();

  const [modelExternalId, setModelExternalId] = useState<string | null>(
    activeModel?.external_id ?? null,
  );
  const [confidenceThreshold, setConfidenceThreshold] = useState(
    activeModel?.confidence_threshold ?? DEFAULT_CONFIDENCE_THRESHOLD,
  );
  const [mode, setMode] = useState<AutomatedResponseMode | string | null>(
    activeModel?.mode ?? DEFAULT_MODE,
  );
  const [error, setError] = useState<string | null>(null);

  const handleClose = () => {
    setIsToggleModePopupOpen(false);
  };

  const handleConfidenceThreshold = (input: string) => {
    const min = 1;
    const max = 100;
    const value = parseInt(input, 10);
    if (value >= min && value <= max) {
      setError(null);
    } else {
      setError(
        intl.formatMessage(messages.confidenceThresholdErrorMessage, {
          min,
          max,
        }),
      );
    }
    setConfidenceThreshold(value);
  };

  // Patch request to update active model
  const { mutation: updateAutomatedResponseMode } = useApiServiceMutation<
    AutomatedResponseModel,
    (
      externalId: string,
      requestBody: AutomatedResponseModelUpdatePayload,
    ) => CancelablePromise<AutomatedResponseModel>
    // eslint-disable-next-line @typescript-eslint/unbound-method
  >(ChatbotService.patchInternalApiChatbotAutomatedResponseModels, {
    onSuccess: () => {
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.updateModelSuccess),
          'success',
        ),
      );
      setIsRefetch(true);
    },
    onError: () => {
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.updateModelError),
          'error',
          false,
        ),
      );
    },
  });

  const { result } = useApiService(
    useCallback(
      () => ChatbotService.getInternalApiChatbotAutomatedResponseModels(),
      [],
    ),
  );

  const submitUpload = () => {
    if (modelExternalId && mode && confidenceThreshold) {
      updateAutomatedResponseMode(modelExternalId, {
        mode: mode as AutomatedResponseMode,
        confidence_threshold: confidenceThreshold,
      });
    }
    handleClose();
  };

  if (result.isLoading) {
    return <Loader fullScreen />;
  }

  return (
    <SimpleModal
      onClose={handleClose}
      maxWidth={undefined}
      blurOverlay={undefined}
    >
      <SimpleModalHeader onClose={handleClose}>
        <SimpleModalHeaderIcon Icon={Edit} />
        <SimpleModalTitle>
          <FormattedMessage {...messages.header} />
        </SimpleModalTitle>
      </SimpleModalHeader>
      <InnerContent>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Typography variant="h6" className={classes.label}>
            <FormattedMessage {...messages.modelName} />
          </Typography>
          <Select
            value={modelExternalId}
            displayEmpty
            input={<OutlinedInput />}
            onChange={e => setModelExternalId(e.target.value as string)}
            classes={{
              select: classes.selectField,
            }}
          >
            {result.status === 'ready' &&
              result.data.automated_response_models &&
              result.data.automated_response_models.map(item => (
                <MenuItem value={item.external_id} key={item.name}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>
          <Typography variant="h6" className={classes.label}>
            <FormattedMessage {...messages.mode} />
          </Typography>
          <Select
            value={mode}
            input={<OutlinedInput />}
            onChange={e => setMode(e.target.value as AutomatedResponseMode)}
            classes={{
              select: classes.selectField,
            }}
          >
            {MODES.map(item => (
              <MenuItem value={item.value} key={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
          <Typography variant="h6" className={classes.label}>
            <FormattedMessage {...messages.threshold} />
          </Typography>
          <Input
            value={confidenceThreshold}
            type="number"
            onChange={e => handleConfidenceThreshold(e.target.value)}
          />
          {error && <Error error={error} align="right" />}
        </Grid>
      </InnerContent>
      <SimpleModalFooter>
        <Button
          className={classes.footerButton}
          type="secondary"
          onClick={handleClose}
          size="small"
        >
          <FormattedMessage {...globalMessages.cancel} />
        </Button>
        <Button
          className={classes.footerButton}
          onClick={submitUpload}
          size="small"
          disabled={!modelExternalId || !mode || !confidenceThreshold}
        >
          <FormattedMessage {...globalMessages.submit} />
        </Button>
      </SimpleModalFooter>
    </SimpleModal>
  );
};
