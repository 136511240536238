import {
  Action as ActionType,
  Condition as ConditionType,
} from 'api-clients/monolith';

import { MOVE_TO_STAGE } from '../Action/constants';
import { APPLICANT_DATA } from '../Condition/constants';

export const DEFAULT_CONDITION: ConditionType = {
  type: APPLICANT_DATA,
  extra: {},
};

export const DEFAULT_ACTION: ActionType = {
  type: MOVE_TO_STAGE,
  extra: {},
};
