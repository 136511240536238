import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  paddingBottom: {
    paddingBottom: theme.spacing(3),
  },
  paddingTop: {
    paddingTop: theme.spacing(3),
  },
  ruleCardContainer: {
    marginBottom: theme.spacing(3),

    '&:last-child': {
      marginBottom: 0,
    },
  },
  trashcanIcon: {
    fontSize: theme.spacing(1.5),
    marginRight: theme.spacing(1),
  },
}));
