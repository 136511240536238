import { Input } from '@fountain/fountain-ui-components';
import { Condition } from 'api-clients/monolith';
import { produce } from 'immer';
import React, { FC, HTMLInputTypeAttribute, useState } from 'react';
import { useIntl } from 'react-intl';

import { Error } from 'components/Error';
import { getConditionErrorMessage } from 'containers/WorkflowEditor/components/Rules/Condition/constants';

import { DocumentSigningRuleProps } from '../../DocumentSigningRules/types';
import {
  PartnerRuleProps,
  SharedPartnerConditionProps,
} from '../../PartnerRules/types';
import { RulesProps, SharedConditionProps } from '../../types';
import messages from './messages';

type FilteredKeys<T, U> = NonNullable<
  { [K in keyof T]: T[K] extends U | undefined ? K : never }[keyof T]
>;

export type ConditionInputKeys = FilteredKeys<
  Condition['extra'],
  string | number
>;

export type InputFieldProps = (
  | SharedConditionProps
  | SharedPartnerConditionProps
) & {
  valueKey?: ConditionInputKeys;
  inputType?: HTMLInputTypeAttribute;
  placeholder?: string;
};

export const InputField: FC<InputFieldProps> = ({
  condition,
  ruleId,
  setRules,
  setPartnerRules,
  setDocumentSigningRules,
  valueKey = 'value',
  errors,
  inputType = 'text',
  placeholder,
}) => {
  const intl = useIntl();
  const { extra } = condition;

  const [inputValue, setInputValue] = useState(extra[valueKey] ?? '');

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const onBlurInput = () => {
    const rulesSetter = setRules ?? setDocumentSigningRules;

    if (rulesSetter) {
      rulesSetter(
        produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];

          const draftCondition = draftConditions.find(
            cond => cond.id === condition.id,
          );
          if (draftCondition) {
            draftCondition.extra = {
              ...draftCondition.extra,
              [valueKey]: inputValue,
            };
          }
        }),
      );
    }

    if (setPartnerRules) {
      setPartnerRules(
        produce((draftRules: PartnerRuleProps) => {
          const draftCondition = draftRules[ruleId].condition_attributes;

          draftCondition.extra = {
            ...draftCondition.extra,
            [valueKey]: inputValue,
          };
        }),
      );
    }
  };

  const errorMessage =
    !extra[valueKey] && getConditionErrorMessage(valueKey, errors);

  return (
    <>
      <Input
        label={intl.formatMessage(messages.value)}
        aria-label={intl.formatMessage(messages.value)}
        fullWidth
        value={inputValue}
        onChange={onChangeInput}
        onBlur={onBlurInput}
        type={inputType}
        placeholder={placeholder}
        error={Boolean(errorMessage)}
        required
      />
      <Error error={errorMessage} align="right" />
    </>
  );
};
