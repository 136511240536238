import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  actionRow: {
    marginBottom: theme.spacing(3),

    '&:last-child': {
      marginBottom: 0,
    },
  },
  tagContainer: {
    marginBottom: theme.spacing(2.5),
    height: theme.spacing(4.5),
  },
  elseActionContainer: {
    borderTop: theme.customBorders.gray300,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  elseActionHeader: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    color: theme.palette.common.gray800,
  },
  rowTag: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.gray800,
  },
}));
