import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  marginBottom2: {
    marginBottom: theme.spacing(2),
  },
}));
