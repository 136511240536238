import { defineMessages } from 'react-intl';

export const scope = 'app.components.ApplicantActions';

export const messages = defineMessages({
  moreActions: {
    id: `${scope}.moreActions`,
    defaultMessage: 'More actions',
  },
  cancelAndResendHellosignRequestSuccess: {
    id: `${scope}.cancelAndResendHellosignRequestSuccess`,
    defaultMessage: 'Successfully canceled and resent hellosign requests',
  },
  cancelAndResendHellosignRequestError: {
    id: `${scope}.cancelAndResendHellosignRequestError`,
    defaultMessage:
      'An error occurred while canceling and resending hellosign request',
  },
  resendHellosignEmailSuccess: {
    id: `${scope}.resendHellosignEmailSuccess`,
    defaultMessage: 'Successfully resent hellosign request',
  },
  resendHellosignEmailError: {
    id: `${scope}.resendHellosignEmailError`,
    defaultMessage: 'An error occurred while resending hellosign request',
  },
  checkrSendConsentMessageSuccess: {
    id: `${scope}.checkrSendConsentMessageSuccess`,
    defaultMessage: 'Successfully sent consent message',
  },
  checkrSendConsentMessageError: {
    id: `${scope}.checkrSendConsentMessageError`,
    defaultMessage: 'An error occurred while sending consent message',
  },
  schedulingResendSchedulerEmailSuccess: {
    id: `${scope}.schedulingResendSchedulerEmailSuccess`,
    defaultMessage: 'Successfully resent scheduler email',
  },
  schedulingResendSchedulerEmailError: {
    id: `${scope}.schedulingResendSchedulerEmailError`,
    defaultMessage: 'An error occurred while resending scheduler email',
  },
  checkrCanadaRunReportSuccess: {
    id: `${scope}.checkrCanadaRunReportSuccess`,
    defaultMessage: 'Successfully run Checkr Canada report',
  },
  checkrCanadaRunReportError: {
    id: `${scope}.checkrCanadaRunReportError`,
    defaultMessage: 'An error occurred while running Checkr Canada report',
  },
  onfidoResumeSuccess: {
    id: `${scope}.onfidoResumeSuccess`,
    defaultMessage: 'Successfully resumed Onfido check',
  },
  onfidoResumeError: {
    id: `${scope}.onfidoResumeError`,
    defaultMessage: 'An error occurred while resuming Onfido check',
  },
  onfidoSendMessageSuccess: {
    id: `${scope}.onfidoSendMessageSuccess`,
    defaultMessage: 'Successfully sent Onfido message',
  },
  onfidoSendMessageError: {
    id: `${scope}.onfidoSendMessageError`,
    defaultMessage: 'An error occurred while sending Onfido message',
  },
  onfidoVoidSuccess: {
    id: `${scope}.onfidoVoidSuccess`,
    defaultMessage: 'Successfully voided Onfido check',
  },
  onfidoVoidError: {
    id: `${scope}.onfidoVoidError`,
    defaultMessage: 'An error occurred while voiding Onfido check',
  },
  sendAutomatedMessageError: {
    id: `${scope}.sendAutomatedMessageError`,
    defaultMessage: 'An error occurred while resending message',
  },
  reactivateEmailSuccess: {
    id: `${scope}.reactivateEmailSuccess`,
    defaultMessage: 'Successfully reactivated email',
  },
  reactivateEmailError: {
    id: `${scope}.reactivateEmailError`,
    defaultMessage: 'An error occurred while reactivating email',
  },
  reactivateSMSSuccess: {
    id: `${scope}.reactivateSMSSuccess`,
    defaultMessage: 'Successfully reactivated SMS',
  },
  reactivateSMSError: {
    id: `${scope}.reactivateSMSError`,
    defaultMessage: 'An error occurred while reactivating SMS',
  },
  checkrRunReportSuccess: {
    id: `${scope}.checkrRunReportSuccess`,
    defaultMessage: 'Successfully initiated run report',
  },
  checkrRunReportError: {
    id: `${scope}.checkrRunReportError`,
    defaultMessage: 'An error occurred while initiating run report',
  },
  checkrRunRemainingReportSuccess: {
    id: `${scope}.checkrRunRemainingReportSuccess`,
    defaultMessage: 'Successfully initiated run remaining report',
  },
  checkrRunRemainingReportSuccessError: {
    id: `${scope}.checkrRunRemainingReportSuccessError`,
    defaultMessage: 'An error occurred while initiating run remaining report',
  },
  viewInApplicantTable: {
    id: `${scope}.viewInApplicantTable`,
    defaultMessage: 'View in Applicant Table',
  },
});
