import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  generalSettings: {
    id: 'app.containers.ChatbotWidget.generalSettings',
    defaultMessage: 'General Settings',
  },
  brandYourWidget: {
    id: 'app.containers.ChatbotWidget.brandYourWidget',
    defaultMessage: 'Brand your widget',
  },
  brandYourWidgetDesc: {
    id: 'app.containers.ChatbotWidget.brandYourWidgetDesc',
    defaultMessage:
      'Select a brand to apply its logo and color, as well as all the chatbot widget settings configured on this page.',
  },
  brand: {
    id: 'app.containers.ChatbotWidget.brand',
    defaultMessage: 'Brand',
  },
  brandPlaceholder: {
    id: 'app.containers.ChatbotWidget.brandPlaceholder',
    defaultMessage: 'Please select Brand',
  },
  brandSelectorDescription: {
    id: 'app.containers.ChatbotWidget.brandSelectorDescription',
    defaultMessage:
      'To add additional brands or configure the existing ones, go to Company Settings.',
  },
  openWindowSetting: {
    id: 'app.containers.ChatbotWidget.openWindowSetting',
    defaultMessage: 'Open chat window by default',
  },
  enableJobsDirectorywidget: {
    id: 'app.containers.ChatbotWidget.enableJobsDirectorywidget',
    defaultMessage: 'Enable chatbot in Jobs Directory',
  },
  openingWindowDesc: {
    id: 'app.containers.ChatbotWidget.openingWindowDesc',
    defaultMessage:
      'This will automatically open the chatbot window when an applicant lands on a page where the chatbot is enabled.',
  },
});
