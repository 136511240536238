import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  actionRow: {
    marginBottom: theme.spacing(3),

    '&:last-child': {
      marginBottom: 0,
    },
  },
  addItemRows: {
    width: '100%',
  },
  row: {
    marginBottom: theme.spacing(1),

    '&:last-child': {
      marginBottom: 0,
    },
  },
  addButton: {
    color: theme.palette.common.blue400,
    marginTop: theme.spacing(2),
    display: 'inline',
  },
  tagContainer: {
    marginBottom: theme.spacing(2.5),
  },
  errorsAlert: {
    marginBottom: theme.spacing(3),
    '& > div': {
      margin: 'initial', // Have icon & text left aligned as per design
    },
  },
  rowTag: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.gray800,
  },
}));
