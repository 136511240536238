import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  substages: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.SubstagesCard.substages`,
    defaultMessage: 'Substages',
  },
  bodyDescription: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.SubstagesCard.bodyDescription`,
    defaultMessage:
      'Manage the list of substages you want the applicant to go through on this stage. We recommend including 2-3 substages.',
  },
  emptyContentDescription: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.SubstagesCard.emptyContentDescription`,
    defaultMessage: 'No substages added yet.',
  },
  addSubstage: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.SubstagesCard.addSubstage`,
    defaultMessage: 'Add Substage',
  },
  substagesRequired: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.SubstagesCard.substagesRequired`,
    defaultMessage: 'Substages are required, please add.',
  },
  deleteSubstage: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.SubstagesCard.deleteSubstage`,
    defaultMessage: 'Delete Substage',
  },
  deleteSubstageDescription: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.SubstagesCard.deleteSubstageDescription`,
    defaultMessage:
      'The substage will be permanently deleted and can’t be recovered. Are you sure you want to delete stage “{subStageTitle}”?',
  },
  deleteSubstageSuccess: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.SubstagesCard.deleteSubstageSuccess`,
    defaultMessage: 'Substage deleted',
  },
});
