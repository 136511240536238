import { Grid, Typography } from '@material-ui/core';
import { lighten, makeStyles, useTheme } from '@material-ui/core/styles';
import { WorkflowBuilderChatbotMessage } from 'api-clients/monolith';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState, VFC } from 'react';
import { FormattedDate } from 'react-intl';

import { HandlesubmitApplicantResponse } from 'containers/AIWorkflowBuilder/types';

import TypingIndicator from '../images/TypingIndicator';
import { getEpochTime } from '../utils/dateUtils';
import { MessageBubble } from './MessageBubble';
import { MessageElement } from './MessageElement';

const useStyles = makeStyles(theme => ({
  messagesListContainer: {
    width: '100%',
    flex: 1,
    padding: theme.spacing(2, 2, 0, 2),
    background: theme.palette.common.gray50,
    overflowY: 'auto',
    overscrollBehavior: 'contain',
  },
  dateSection: {
    marginBottom: theme.spacing(2),
  },
}));

export interface MessagesListProps {
  messages: WorkflowBuilderChatbotMessage[];
  setMessages: React.Dispatch<
    React.SetStateAction<WorkflowBuilderChatbotMessage[]>
  >;
  showTypingIndicator: boolean;
  submitApplicantResponse: HandlesubmitApplicantResponse;
}

interface HourToIndex {
  [key: string]: string | number;
}

export const MessagesList: VFC<MessagesListProps> = ({
  messages,
  setMessages,
  showTypingIndicator,
  submitApplicantResponse,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const messageEnd = useRef<HTMLDivElement>(null);

  const [messagesGroupedByHour, setMessagesGroupedByHour] =
    useState<Array<Array<WorkflowBuilderChatbotMessage>>>();

  const typingIndicatorProps: WorkflowBuilderChatbotMessage = {
    key: null,
    content: null,
    sender: 'assistant',
    created_at: new Date().toString(),
  };

  const groupByHour = (sortedMessages: WorkflowBuilderChatbotMessage[]) => {
    const hourToIndex: HourToIndex = {};
    const groupedMessages: Array<Array<WorkflowBuilderChatbotMessage>> = [];
    sortedMessages.forEach(message => {
      const date = new Date(message.created_at);
      const hour = date.getHours();
      const index = hourToIndex[hour] as number;

      if (typeof index !== 'undefined') {
        groupedMessages[index].push(message);
      } else {
        const i = groupedMessages.push([message]);
        hourToIndex[hour] = i - 1;
      }
    });
    return groupedMessages;
  };

  useEffect(() => {
    const timeoutID = setTimeout(
      () =>
        messageEnd.current &&
        messageEnd.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        }),
    );

    return () => clearTimeout(timeoutID);
  }, [messages.length, showTypingIndicator]);

  useEffect(() => {
    const widgetMessages = Object.values(messages);

    setMessagesGroupedByHour(groupByHour(widgetMessages));
  }, [messages]);

  const setMessage = (message: WorkflowBuilderChatbotMessage) => {
    setMessages(prev => {
      return { ...prev, [getEpochTime(message.created_at)]: message };
    });
  };

  if (!messagesGroupedByHour) {
    return <></>;
  }

  return (
    <Grid item className={styles.messagesListContainer}>
      {messagesGroupedByHour.map(messages => (
        <div className={styles.dateSection} key={messages[0].created_at}>
          <Typography variant="body2" align="center">
            <FormattedDate
              weekday="long"
              month="short"
              day="numeric"
              hour="numeric"
              minute="numeric"
              hourCycle="h12"
              value={new Date(messages[0].created_at)}
            />
          </Typography>
          {messages.map(message => (
            <React.Fragment key={message.created_at}>
              {isEmpty(message.element_data) && (
                <MessageBubble message={message} />
              )}

              {!isEmpty(message.element_data) && (
                <MessageElement
                  message={message}
                  setMessage={setMessage}
                  submitApplicantResponse={submitApplicantResponse}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      ))}
      {showTypingIndicator && (
        <MessageBubble message={{ ...typingIndicatorProps }}>
          <TypingIndicator color={lighten(theme.palette.primary.main)} />
        </MessageBubble>
      )}
      <div ref={messageEnd} />
    </Grid>
  );
};
