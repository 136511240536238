import omit from 'lodash/omit';
import queryString from 'query-string';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import {
  setApplicantsAction,
  setApplicantsActionError,
  setApplicantsActionSuccess,
} from 'containers/ApplicantTableV2/actions';
import {
  ADDITIONAL_APPLICANT_DATA,
  AMAZON_DSP_TABLE_COLUMNS,
  APPLICANT_TABLE_COLUMNS,
  APPLICATION_LOCALE_COLUMN,
  DEFAULT_ROWS_PER_PAGE,
  MAV_COLUMNS_DATA,
} from 'containers/ApplicantTableV2/constants';
import {
  makeSelectAccountSlug,
  makeSelectClientSettings,
  makeSelectCountryCode,
} from 'containers/Auth_old/selectors';
import { addMessageAction } from 'containers/FlashMessage/actions';
import { apiGet } from 'utils/axios';
import { isAmazon } from 'utils/constants';
import {
  getJobIdFromUrl,
  getStageIdFromUrl,
  setParamsToUrl,
} from 'utils/urlUtils';

export function getQueryParams({ location }) {
  const stageId = getStageIdFromUrl({ location });
  const funnelId = getJobIdFromUrl({ location });

  const {
    filter_set_id: filterId,
    temp_filter_id: tempFilterId,
    query,
    sort_direction: sortDir,
    sort_column: sortKey,
    stage_conditions_filter_set_id: stageConditionsFilterSetId,
    duplicate_applicant_id: duplicateApplicantId,
  } = queryString.parse(location.search);
  let { page, per_page: perPage } = queryString.parse(location.search);

  if (!page) {
    page = 1;
    setParamsToUrl({ page });
  }
  if (!perPage) {
    perPage = DEFAULT_ROWS_PER_PAGE;
    setParamsToUrl({ per_page: perPage });
  }

  return {
    page,
    per_page: perPage,
    sort_column: sortKey,
    sort_direction: sortDir,
    query,
    filter_set_id: filterId || tempFilterId,
    stage_conditions_filter_set_id: stageConditionsFilterSetId,
    stage_id: stageId,
    funnel_id: funnelId,
    duplicate_applicant_id: duplicateApplicantId,
  };
}

export default function useApplicants() {
  const dispatch = useDispatch();

  const accountSlug = useSelector(makeSelectAccountSlug());
  const countryCode = useSelector(makeSelectCountryCode());

  const {
    clientSettings: { applicantTableColumnSettings },
  } = useSelector(makeSelectClientSettings());

  const [isFetching, setIsFetching] = useState(false);

  // Used for Applicant Table and MAV
  const fetchApplicants = async params => {
    let columns;
    if (params.stage_id) {
      // ApplicantsV2
      columns =
        applicantTableColumnSettings && applicantTableColumnSettings.length
          ? applicantTableColumnSettings.map(col => col.key)
          : APPLICANT_TABLE_COLUMNS;

      if (!applicantTableColumnSettings?.length && params.application_locale) {
        columns = [...columns, ...APPLICATION_LOCALE_COLUMN];
      }

      columns = [
        ...columns,
        // [AI-40] If the account is amazon-delivery-service-partner we want to add additional columns
        ...(isAmazon(accountSlug, countryCode) && !applicantTableColumnSettings
          ? AMAZON_DSP_TABLE_COLUMNS
          : []),
        ...ADDITIONAL_APPLICANT_DATA,
      ];
    } else {
      // MAV
      columns = isAmazon(accountSlug, countryCode)
        ? [...MAV_COLUMNS_DATA, ...AMAZON_DSP_TABLE_COLUMNS]
        : MAV_COLUMNS_DATA;
    }

    const queryParams = omit(params, ['application_locale']);

    // TODO do the select columns
    queryParams.c = columns;

    const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants?${queryString.stringify(
      queryParams,
      {
        arrayFormat: 'bracket',
      },
    )}`;

    setIsFetching(true);
    dispatch(setApplicantsAction());
    try {
      const res = await apiGet(url);
      const { data } = res;
      if (data && data.applicants) {
        dispatch(setApplicantsActionSuccess(data.applicants, data.count));
      }
    } catch (e) {
      dispatch(setApplicantsActionError());
      if (typeof e === 'string') {
        dispatch(addMessageAction(e, 'error'));
      } else if (e && e.message) {
        dispatch(addMessageAction(e.message, 'error'));
      }
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    fetchApplicants,
  };
}
