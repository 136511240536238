const styles = theme => ({
  root: {
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
  },
  content: {
    position: 'relative',
    flex: 1,
    minWidth: 0,
    padding: 0,
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&.open': {
      left: theme.navigation.sideBar.width,
      transition: theme.transitions.create('left', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    '&.drawerHidden': {
      marginLeft: 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  appBar: {
    backgroundColor: theme.palette.background.paper,
    width: `100%`,
    [theme.breakpoints.down('sm')]: { left: 0, right: 0 },
    boxShadow: '0 0 0 0 rgba(164,168,179,0.16)',
  },
});

export default styles;
