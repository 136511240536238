import produce from 'immer';

import { SET_VALIDATION_GROUP } from './constants';
import { Action, State } from './types';

export const initialState: State = {};

const dataValidationModalReducer = (state = initialState, action: Action) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_VALIDATION_GROUP:
        draft.validationGroup = action.payload;
        break;
      default:
        break;
    }
  });

export default dataValidationModalReducer;
