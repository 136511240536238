import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  formControlLabel: props => ({
    alignItems: props.alignItems ? props.alignItems : 'center',
    marginLeft: theme.spacing(-1),
  }),
  checkbox: props => ({
    marginTop: props.alignItems === 'flex-start' && theme.spacing(-1),
  }),
  label: {
    marginLeft: theme.spacing(0.5),
  },
}));

export default useStyles;
