import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  dropzoneContainer: {
    marginBottom: theme.spacing(4),
  },
  footerButton: {
    marginTop: theme.spacing(6),
    '&:first-of-type': {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      '&:first-of-type': {
        display: 'none',
      },
      '&:last-of-type': {
        flex: 1,
      },
    },
  },
  input: {
    marginTop: theme.spacing(2),
  },
  label: {
    lineHeight: theme.typography.body2.lineHeight,
    fontSize: theme.typography.body2.fontSize,
    fontFamily: theme.typography.body2.fontFamily,
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(2),
  },
  selectField: {
    padding: `${theme.spacing(1.25)}px ${theme.spacing(2.5)}px`,
    minWidth: theme.spacing(66),
  },
}));
