import faker from '@faker-js/faker/locale/en';
import { ThemeProvider } from '@fountain/fountain-ui-components';
import { CssBaseline } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { render, RenderOptions } from '@testing-library/react';
import { ConnectedRouter } from 'connected-react-router';
import mediaQuery from 'css-mediaquery';
import React, { FC } from 'react';
import { IntlProvider } from 'react-intl';
import { Provider as ReduxProvider } from 'react-redux';
import { REACT_APP_FAKER_SEED } from 'runtimeEnvVars';

import applicantActionsReducer from 'containers/ApplicantActions/reducer';
import applicantsV2Reducer from 'containers/ApplicantsV2/reducer';
import applicantTableV2Reducer from 'containers/ApplicantTableV2/reducer';
import moveApplicantDialogReducer from 'containers/MoveApplicantDialog/reducer';
import sourcingReducer from 'containers/Sourcing/reducer';
import history from 'utils/history';

import configureStore from './configureStore';

interface WrapperProps {
  initialState: { [key: string]: unknown };
  locale: string | undefined;
}
export const CustomWrapper: FC<WrapperProps> = ({
  children,
  initialState = {},
  locale = 'en',
}) => {
  const injectedReducers = {
    applicantActions: applicantActionsReducer,
    applicantTableV2: applicantTableV2Reducer,
    moveApplicantDialog: moveApplicantDialogReducer,
    applicantsV2: applicantsV2Reducer,
    sourcing: sourcingReducer,
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const store = configureStore(initialState, history, injectedReducers);
  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    <ReduxProvider store={store}>
      <ThemeProvider>
        <CssBaseline>
          <ConnectedRouter history={history}>
            <IntlProvider locale={locale}>{children}</IntlProvider>
          </ConnectedRouter>
        </CssBaseline>
      </ThemeProvider>
    </ReduxProvider>
  );
};

export const buildWrapper =
  (props: WrapperProps): React.FC =>
  ({ children }) =>
    <CustomWrapper {...props}>{children}</CustomWrapper>;

const customRender = (
  component: React.ReactElement,
  options: RenderOptions = {},
  // todo: better type information for redux state
  initialState: { [key: string]: unknown } = {},
  locale: string | undefined = 'en',
) => {
  const ComponentContainer = buildWrapper({ initialState, locale });

  return {
    history,
    ...render(component, { wrapper: ComponentContainer, ...options }),
  };
};
export { customRender as render };

export * from '@testing-library/react';
export { default as userEvent } from '@testing-library/user-event';

faker.seed(Number(REACT_APP_FAKER_SEED) || 1235); // use `REACT_APP_FAKER_SEED` to pass in your own seed
export { faker };

/**
 * https://v4.mui.com/components/use-media-query/#testing
 */
const createMatchMedia = (width?: number) => (query: string) =>
  ({
    matches: mediaQuery.match(query, { width }),
    addListener: () => {},
    removeListener: () => {},
  } as unknown as MediaQueryList);

const setWidth = (width: number) => {
  window.matchMedia = createMatchMedia(width);
};

export const windowBreakpointFunctions = {
  set: (breakpoint: Breakpoint) => {
    // https://v4.mui.com/customization/breakpoints/#default-breakpoints
    // we're using the max size for each breakpoint except `xl` where there is no max size
    switch (breakpoint) {
      case 'lg':
        return setWidth(1919);
      case 'md':
        return setWidth(1279);
      case 'sm':
        return setWidth(959);
      case 'xl':
        return setWidth(1920);
      case 'xs':
        return setWidth(599);
      default:
        return setWidth(window.innerWidth);
    }
  },
  reset: () =>
    // desktop breakpoint
    setWidth(960),
};

export const defaultTimezone = process.env.TZ ?? 'America/Los_Angeles';
