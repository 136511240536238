import { Button, CustomTooltip } from '@fountain/fountain-ui-components';
import { Button as MuiButton, Grid, Typography } from '@material-ui/core';
import {
  MessageTemplate,
  ReasonSetting,
  ReminderSetting,
  TriggerSetting,
} from 'api-clients/monolith';
import { Reason } from 'api-clients/monolith/models/Reason';
import cx from 'classnames';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import { makeAvailableSlotsNewAvailabilityMessage } from 'containers/Auth_old/selectors';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';
import InfoGrey from 'images/info-grey.svg';

import { StageSettingCard } from '../StageSettingCard';
import { StageSettingCardEmptyState } from '../StageSettingCardEmptyState';
import { MessageRow } from './components/MessageRow';
import {
  ARCHIVED,
  ASSESSMENT,
  AUTOMATED,
  AVAILABILITY,
  CANCELLATION,
  CANCELLATION_V2,
  CONFIRMATION,
  CONSENT,
  DATA_COLLECTION,
  DOCUMENT_SIGNING,
  HIRED,
  I9FORM,
  JOB_SELECTOR,
  JOB_SWITCHER,
  LEARNING,
  messageTypesThatAllowAddingMoreMessages,
  MULTI_STAGE,
  REJECTION,
  REMINDER,
  SCHEDULER,
  SCHEDULER_V2,
  SCHOOLKEEP,
  TECH_CHECK,
  TRIGGER,
  VIDEO_RECORDING,
  W4_FEDERAL_FORM,
  W4_STATE_FORM,
} from './constants';
import { messages } from './messages';
import { useStyles } from './styles';
import { MessageTypes } from './types';

export type StageSettingMessageCardProps = {
  messageType: MessageTypes;
  reasons?: Reason[];
  stageExternalId: string;
};

type WithTooltipMessage = typeof AVAILABILITY | typeof CONSENT | typeof TRIGGER;

export const StageSettingMessageCard: FC<StageSettingMessageCardProps> =
  React.memo(({ messageType, reasons, stageExternalId }) => {
    const styles = useStyles();
    const intl = useIntl();
    const { accountSlug, funnelSlug } = useParams<{
      accountSlug: string;
      funnelSlug: string;
    }>();

    const { stage, setStage } = useContext(StageContext);
    const availableSlotsNewAvailabilityMessage = useSelector(
      makeAvailableSlotsNewAvailabilityMessage(),
    );

    const {
      reason_settings: reasonSettings,
      trigger_settings: triggerSettings,
      reminder_settings: reminderSettings,
      landing_template: landingTemplate,
      confirmation_template: confirmationTemplate,
      cancellation_template: cancellationTemplate,
      availability_template: availabilityTemplate,
    } = stage;

    const messageRows: Record<
      MessageTypes,
      Array<ReasonSetting | TriggerSetting | ReminderSetting | MessageTemplate>
    > = {
      [ARCHIVED]: reasonSettings ?? [],
      [ASSESSMENT]: landingTemplate ? [landingTemplate] : [],
      [AUTOMATED]: landingTemplate ? [landingTemplate] : [],
      [AVAILABILITY]: availabilityTemplate ? [availabilityTemplate] : [],
      [CANCELLATION]: cancellationTemplate ? [cancellationTemplate] : [],
      [CANCELLATION_V2]: cancellationTemplate ? [cancellationTemplate] : [],
      [CONFIRMATION]: confirmationTemplate ? [confirmationTemplate] : [],
      [CONSENT]: landingTemplate ? [landingTemplate] : [],
      [DATA_COLLECTION]: landingTemplate ? [landingTemplate] : [],
      [DOCUMENT_SIGNING]: landingTemplate ? [landingTemplate] : [],
      [HIRED]: landingTemplate ? [landingTemplate] : [],
      [I9FORM]: landingTemplate ? [landingTemplate] : [],
      [JOB_SELECTOR]: landingTemplate ? [landingTemplate] : [],
      [JOB_SWITCHER]: landingTemplate ? [landingTemplate] : [],
      [MULTI_STAGE]: landingTemplate ? [landingTemplate] : [],
      [REJECTION]: reasonSettings ?? [],
      [REMINDER]: reminderSettings ?? [],
      [SCHEDULER]: landingTemplate ? [landingTemplate] : [],
      [SCHEDULER_V2]: landingTemplate ? [landingTemplate] : [],
      [TECH_CHECK]: landingTemplate ? [landingTemplate] : [],
      [VIDEO_RECORDING]: landingTemplate ? [landingTemplate] : [],
      [TRIGGER]: triggerSettings ?? [],
      [SCHOOLKEEP]: landingTemplate ? [landingTemplate] : [],
      [LEARNING]: landingTemplate ? [landingTemplate] : [],
      [W4_FEDERAL_FORM]: landingTemplate ? [landingTemplate] : [],
      [W4_STATE_FORM]: landingTemplate ? [landingTemplate] : [],
    };

    const cardTitleByType: Record<MessageTypes, string> = {
      [ARCHIVED]: intl.formatMessage(messages.cardTitleOnHold),
      [ASSESSMENT]: intl.formatMessage(messages.cardTitleInitial),
      [AUTOMATED]: intl.formatMessage(messages.cardTitleInitial),
      [AVAILABILITY]: intl.formatMessage(messages.cardTitleAvailability),
      [CANCELLATION]: intl.formatMessage(messages.cardTitleCancellation),
      [CANCELLATION_V2]: intl.formatMessage(messages.cardTitleCancellation),
      [CONFIRMATION]: intl.formatMessage(messages.cardTitleConfirmation),
      [CONSENT]: intl.formatMessage(messages.cardTitleConsent),
      [DATA_COLLECTION]: intl.formatMessage(messages.cardTitleInitial),
      [DOCUMENT_SIGNING]: intl.formatMessage(messages.cardTitleInitial),
      [HIRED]: intl.formatMessage(messages.cardTitleHired),
      [I9FORM]: intl.formatMessage(messages.cardTitleInitial),
      [JOB_SELECTOR]: intl.formatMessage(messages.cardTitleInitial),
      [JOB_SWITCHER]: intl.formatMessage(messages.cardTitleInitial),
      [MULTI_STAGE]: intl.formatMessage(messages.cardTitleInitial),
      [REJECTION]: intl.formatMessage(messages.cardTitleRejection),
      [REMINDER]: intl.formatMessage(messages.cardTitleReminder),
      [SCHEDULER]: intl.formatMessage(messages.cardTitleInitial),
      [SCHEDULER_V2]: intl.formatMessage(messages.cardTitleInitial),
      [TECH_CHECK]: intl.formatMessage(messages.cardTitleInitial),
      [VIDEO_RECORDING]: intl.formatMessage(messages.cardTitleInitial),
      [TRIGGER]: intl.formatMessage(messages.cardTitleTrigger),
      [SCHOOLKEEP]: intl.formatMessage(messages.cardTitleInitial),
      [LEARNING]: intl.formatMessage(messages.cardTitleInitial),
      [W4_FEDERAL_FORM]: intl.formatMessage(messages.cardTitleInitial),
      [W4_STATE_FORM]: intl.formatMessage(messages.cardTitleInitial),
    };

    const cardBodyTextByType: Record<MessageTypes, string> = {
      [ARCHIVED]: intl.formatMessage(messages.bodyTextOnHold),
      [ASSESSMENT]: intl.formatMessage(messages.bodyTextInitial),
      [AUTOMATED]: intl.formatMessage(messages.bodyTextInitial),
      [AVAILABILITY]: intl.formatMessage(messages.bodyTextAvailability),
      [CANCELLATION]: intl.formatMessage(messages.bodyTextCancellation),
      [CANCELLATION_V2]: intl.formatMessage(messages.bodyTextCancellation),
      [CONFIRMATION]: intl.formatMessage(messages.bodyTextConfirmation),
      [CONSENT]: intl.formatMessage(messages.bodyTextConsent),
      [DATA_COLLECTION]: intl.formatMessage(messages.bodyTextInitial),
      [DOCUMENT_SIGNING]: intl.formatMessage(messages.bodyTextInitial),
      [HIRED]: intl.formatMessage(messages.bodyTextInitial),
      [I9FORM]: intl.formatMessage(messages.bodyTextInitial),
      [JOB_SELECTOR]: intl.formatMessage(messages.bodyTextInitial),
      [JOB_SWITCHER]: intl.formatMessage(messages.bodyTextInitial),
      [MULTI_STAGE]: intl.formatMessage(messages.bodyTextInitial),
      [REJECTION]: intl.formatMessage(messages.bodyTextRejection),
      [REMINDER]: intl.formatMessage(messages.bodyTextReminder),
      [SCHEDULER]: intl.formatMessage(messages.bodyTextInitial),
      [SCHEDULER_V2]: intl.formatMessage(messages.bodyTextInitial),
      [TECH_CHECK]: intl.formatMessage(messages.bodyTextInitial),
      [VIDEO_RECORDING]: intl.formatMessage(messages.bodyTextInitial),
      [TRIGGER]: availableSlotsNewAvailabilityMessage
        ? intl.formatMessage(messages.bodyTextTrigger)
        : intl.formatMessage(messages.bodyTextTriggerOld),
      [SCHOOLKEEP]: intl.formatMessage(messages.bodyTextInitial),
      [LEARNING]: intl.formatMessage(messages.bodyTextInitial),
      [W4_FEDERAL_FORM]: intl.formatMessage(messages.bodyTextInitial),
      [W4_STATE_FORM]: intl.formatMessage(messages.bodyTextInitial),
    };

    const tooltipMessages: Record<WithTooltipMessage, string> = {
      [AVAILABILITY]: intl.formatMessage(messages.availabilityTooltip),
      [CONSENT]: intl.formatMessage(messages.consentTooltip),
      [TRIGGER]: intl.formatMessage(messages.followUpTooltip),
    };

    const addMessageButtonHref = `${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/funnels/${funnelSlug}/stages/${stageExternalId}/stage_templates/new?type=${messageType}`;
    const tooltipText =
      messageType === TRIGGER ||
      messageType === CONSENT ||
      messageType === AVAILABILITY
        ? tooltipMessages[messageType]
        : '';

    const rows = messageRows[messageType];

    const messageRowsArray = rows.length
      ? // eslint-disable-next-line no-underscore-dangle
        rows.filter(row => !row._destroy)
      : [];

    if (!messageRowsArray.length) {
      return (
        <StageSettingCardEmptyState
          stageSettingCardProps={{
            title: cardTitleByType[messageType],
            isLoading: false,
            variant: 'default',
          }}
          buttonComponent={
            <Button
              href={addMessageButtonHref}
              type="secondary"
              size="small"
              autoWidth
              disableRipple
              aria-label={intl.formatMessage(messages.addMessage)}
            >
              <Typography variant="h4" color="primary">
                {intl.formatMessage(messages.addMessage)}
              </Typography>
            </Button>
          }
          bodyText={cardBodyTextByType[messageType]}
          tooltipText={tooltipText}
        />
      );
    }

    const canAddMoreMessages =
      messageTypesThatAllowAddingMoreMessages[messageType];

    return (
      <StageSettingCard
        title={cardTitleByType[messageType]}
        isLoading={false}
        variant="default"
      >
        <Grid className={styles.messageCardContent}>
          <Grid className={styles.bodyText}>
            {cardBodyTextByType[messageType]}
            {tooltipText && (
              <CustomTooltip
                className={styles.infoTooltip}
                title={tooltipText}
                dense
              >
                <img
                  src={InfoGrey}
                  alt={intl.formatMessage(messages.infoIcon)}
                />
              </CustomTooltip>
            )}
          </Grid>
          <Grid className={cx({ [styles.bottomBorder]: canAddMoreMessages })}>
            {messageRowsArray.map(messageRow => {
              return (
                <MessageRow
                  friendlyMessageType={cardTitleByType[messageType]}
                  key={messageRow.id}
                  messageRow={messageRow}
                  messageType={messageType}
                  reasons={reasons}
                  setStage={setStage}
                />
              );
            })}
          </Grid>

          {canAddMoreMessages && (
            <Grid>
              <MuiButton
                variant="text"
                disableRipple
                className={styles.addMessageLinkButton}
                href={addMessageButtonHref}
                aria-label={intl.formatMessage(messages.addMessageTextButton)}
              >
                <Typography
                  variant="body2"
                  className={styles.addMessageLinkButtonText}
                >
                  {intl.formatMessage(messages.addMessageTextButton)}
                </Typography>
              </MuiButton>
            </Grid>
          )}
        </Grid>
      </StageSettingCard>
    );
  });
