import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import React, { VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Message } from '../types';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 500,
    lineHeight: '24px',
    color: theme.palette.common.gray800,
    marginTop: theme.spacing(3),
  },
}));

interface SectionTitleProps {
  value: Message;
  className?: string;
}

export const SectionTitle: VFC<SectionTitleProps> = ({ value, className }) => {
  const styles = useStyles();

  return (
    <Typography variant="h3" className={classnames(styles.title, className)}>
      <FormattedMessage {...value} />
    </Typography>
  );
};
