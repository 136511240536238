import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addMessageAction } from 'containers/FlashMessage/actions';
import {
  openMoveApplicantPopup,
  openMoveApplicantToJobPopup,
} from 'containers/GlobalPopup/actions';
import useApplicantDetail from 'hooks/useApplicantDetail';
import { Reducer, useReducer } from 'hooks/useReducer';
import { useStageStatus } from 'hooks/useStageStatus';

import messages from '../shared/global/messages';

export const TransitionType = {
  JOB: 'moveToJob',
  STAGE: 'moveToStage',
  NEXT_STAGE: 'moveToNextStage',
  REJECT: 'moveToReject',
};

const getPayload =
  ({ applicant, stages, stageId }) =>
  transitionType => {
    const payload = {
      applicantId: applicant.info.id,
      fromJobId: applicant.job.id,
      fromStageId: applicant.stage.id,
      transitionType: 'move',
    };

    switch (transitionType) {
      case TransitionType.JOB: {
        const { warn_w4_federal: warnW4Federal, warn_wotc: warnWotc } =
          stages.find(
            ({ is_currentStage: isCurrentStage }) => isCurrentStage,
          ) || {};
        return {
          ...payload,
          warnW4Federal,
          warnWotc,
        };
      }

      case TransitionType.STAGE: {
        const {
          stage_id: toStageId,
          warn_w4_federal: warnW4Federal,
          warn_wotc: warnWotc,
        } = stages.find(({ stage_id: id }) => id === stageId) || {};
        return {
          ...payload,
          toStageId,
          warnW4Federal,
          warnWotc,
        };
      }

      case TransitionType.NEXT_STAGE: {
        const {
          stage_id: toStageId,
          warn_w4_federal: warnW4Federal,
          warn_wotc: warnWotc,
        } = stages.find(({ is_next_stage: isNextStage }) => isNextStage) || {};
        return {
          ...payload,
          toStageId,
          transitionType: 'advance',
          warnW4Federal,
          warnWotc,
        };
      }

      case TransitionType.REJECT: {
        const {
          stage_id: toStageId,
          warn_w4_federal: warnW4Federal,
          warn_wotc: warnWotc,
        } = stages.find(
          ({ is_rejected_stage: isRejectedStage }) => isRejectedStage,
        ) || {};
        return {
          ...payload,
          toStageId,
          warnW4Federal,
          warnWotc,
        };
      }

      default:
        return payload;
    }
  };

export const generateTransitionFunctions = ({
  applicant,
  dispatch,
  intl,
  stages,
}) => {
  if (!applicant || !stages) {
    return {};
  }

  const payload = getPayload({
    applicant,
    stages,
  });

  const moveToStageActions = stages
    .map(({ stage_id: stageId }, _, originalStages) => ({
      [stageId]: () =>
        dispatch(
          getPayload({ applicant, stages: originalStages, stageId })(
            TransitionType.STAGE,
          ),
        ),
    }))
    .reduce((a, b) => ({ ...a, ...b }));

  const updatedTransitionFunctions = {
    moveToJob: () =>
      dispatch(openMoveApplicantToJobPopup(payload(TransitionType.JOB))),
    moveToStage: toStageId => {
      const action = moveToStageActions[toStageId];
      if (action) {
        action();
      }
    },
    moveToNextStage: () => {
      const moveToNextStagePayload = payload(TransitionType.NEXT_STAGE);
      if (moveToNextStagePayload.toStageId)
        dispatch(openMoveApplicantPopup(moveToNextStagePayload));
      else
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.nextStageUnavailable),
            'error',
          ),
        );
    },
    moveToReject: () => {
      const moveToRejectPayload = payload(TransitionType.REJECT);
      if (moveToRejectPayload.fromStageId !== moveToRejectPayload.toStageId)
        dispatch(openMoveApplicantPopup(moveToRejectPayload));
      else
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.rejectStageUnavailable),
            'error',
          ),
        );
    },
  };

  return updatedTransitionFunctions;
};

/**
 * @typedef {object} Action
 * @property {object} Action.data
 * @property {string} Action.data.stage_id
 * @property {boolean} Action.data.warn_w4_federal
 * @property {boolean} Action.data.warn_wotc
 */

/**
 * @typedef {object} Applicant
 * @property {string} id
 * @property {string} opening_id
 * @property {string} stage_id
 */

/**
 * @typedef {object} UseGlobalPopupTransitionFunctionsOptions
 * @property {Action} action
 * @property {Applicant} applicant
 */

/**
 * @typedef {object} GlobalPopupTransitionFunctions
 * @property {@function} moveToJob
 * @property {@function} moveToStage
 * @property {@function} moveToNextStage
 * @property {@function} reject
 */

/**
 * Get the functions for triggering transition dialogs.
 * moveToJob | moveToStage | moveToNextStage | reject
 * @param {UseGlobalPopupTransitionFunctionsOptions} options
 * @returns {GlobalPopupTransitionFunctions}
 */

export const useGlobalPopupTransitionFunctions = ({ applicantId }) => {
  useReducer(Reducer.APPLICANT_ACTIONS)();
  const dispatch = useDispatch();

  const {
    data: stageStatusData,
    error: stageStatusError,
    isLoading: isFetchingStageStatus,
    fetchData: fetchStageStatusData,
  } = useStageStatus(applicantId);
  const {
    data: applicantDetailData,
    error: applicantDetailError,
    isFetching: isFetchingApplicantDetail,
  } = useApplicantDetail(applicantId);

  const intl = useIntl();

  useEffect(() => {
    if (stageStatusError) {
      dispatch(addMessageAction(stageStatusError, 'error'));
    }
    if (applicantDetailError) {
      dispatch(addMessageAction(applicantDetailError, 'error'));
    }
  }, [applicantDetailError, dispatch, stageStatusError]);

  return {
    data: stageStatusData?.stages ?? [],
    getTransitionFunction: async transitionType => {
      const { stages } = await fetchStageStatusData();
      const transitionFunctions = generateTransitionFunctions({
        applicant: applicantDetailData,
        dispatch,
        intl,
        stages,
      });
      return transitionFunctions[transitionType];
    },
    isLoading: isFetchingStageStatus || isFetchingApplicantDetail,
  };
};
