import { Input } from '@fountain/fountain-ui-components';
import { Grid, InputLabel, makeStyles } from '@material-ui/core';
import { LicenseDataField } from 'api-clients/monolith';
import React, { useEffect, useState, VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { isEmptyAddress } from 'utils/checkEmptyAddress';

import { messages } from './messages';

const useStyles = makeStyles(theme => ({
  p: {
    marginTop: 0,
    marginBottom: 0,
  },
  input: {
    marginTop: theme.spacing(2),
  },
  label: {
    lineHeight: theme.typography.body2.lineHeight,
    fontSize: theme.typography.body2.fontSize,
    fontFamily: theme.typography.body2.fontFamily,
    marginBottom: theme.spacing(0.5),
  },
}));

interface LicenseDataProps {
  licenseData: LicenseDataField;
  editDisabled: boolean;
  handleKeyPress: () => void;
  setValue: (newValue: LicenseDataField, index: number) => void;
  index: number;
}

export const LicenseData: VFC<LicenseDataProps> = ({
  licenseData,
  editDisabled,
  handleKeyPress,
  setValue,
  index,
}) => {
  const classes = useStyles();
  const [data, setData] = useState<LicenseDataField>({});

  useEffect(() => {
    setData(licenseData);
  }, [licenseData]);

  type FieldName =
    | 'type'
    | 'number'
    | 'region'
    | 'region_iso'
    | 'country_code'
    | 'country_code_iso';

  const onFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: FieldName,
  ) => {
    setData({
      ...data,
      [fieldName]: e.target.value,
    });

    setValue(
      {
        ...data,
        [fieldName]: e.target.value,
      },
      index,
    );
  };

  const handleChangeField =
    (fieldName: FieldName) => (e: React.ChangeEvent<HTMLInputElement>) => {
      onFieldChange(e, fieldName);
    };

  const renderEditField = () => {
    return (
      <Grid>
        <Grid className={classes.input}>
          <InputLabel className={classes.label} htmlFor="type">
            <FormattedMessage {...messages.typeField} />
          </InputLabel>
          <Input
            value={data.type}
            onChange={handleChangeField('type')}
            onKeyPress={handleKeyPress}
          />
        </Grid>
        <Grid className={classes.input}>
          <InputLabel className={classes.label} htmlFor="number">
            <FormattedMessage {...messages.numberField} />
          </InputLabel>
          <Input
            value={data.number}
            onChange={handleChangeField('number')}
            onKeyPress={handleKeyPress}
          />
        </Grid>
        <Grid className={classes.input}>
          <InputLabel className={classes.label} htmlFor="region">
            <FormattedMessage {...messages.regionField} />
          </InputLabel>
          <Input
            value={data.region}
            onChange={handleChangeField('region')}
            onKeyPress={handleKeyPress}
          />
        </Grid>
        <Grid className={classes.input}>
          <InputLabel className={classes.label} htmlFor="region_iso">
            <FormattedMessage {...messages.regionIsoField} />
          </InputLabel>
          <Input
            value={data.region_iso}
            onChange={handleChangeField('region_iso')}
            onKeyPress={handleKeyPress}
          />
        </Grid>
        <Grid className={classes.input}>
          <InputLabel className={classes.label} htmlFor="country_code">
            <FormattedMessage {...messages.countryCodeField} />
          </InputLabel>
          <Input
            value={data.country_code}
            onChange={handleChangeField('country_code')}
            onKeyPress={handleKeyPress}
          />
        </Grid>
        <Grid className={classes.input}>
          <InputLabel className={classes.label} htmlFor="country_code_iso">
            <FormattedMessage {...messages.countryCodeIsoField} />
          </InputLabel>
          <Input
            value={data.country_code_iso}
            onChange={handleChangeField('country_code_iso')}
            onKeyPress={handleKeyPress}
          />
        </Grid>
      </Grid>
    );
  };

  const renderValue = () => {
    return isEmptyAddress(data) ? (
      <p className={classes.p}>-</p>
    ) : (
      <p className={classes.p}>
        <FormattedMessage {...messages.typeField} />: {data.type} <br />
        <FormattedMessage {...messages.numberField} />: {data.number} <br />
        <FormattedMessage {...messages.regionField} />: {data.region} <br />
        <FormattedMessage {...messages.regionIsoField} />: {data.region_iso}{' '}
        <br />
        <FormattedMessage {...messages.countryCodeField} />: {data.country_code}{' '}
        <br />
        <FormattedMessage {...messages.countryCodeIsoField} />:{' '}
        {data.country_code_iso} <br />
      </p>
    );
  };
  return editDisabled ? renderValue() : renderEditField();
};

export default LicenseData;
