import { datadogRum } from '@datadog/browser-rum';
import { Grid, makeStyles } from '@material-ui/core';
import {
  WorkflowBuilderChatbotInitResponse,
  WorkflowBuilderChatbotMessage,
  WorkflowBuilderFunnelStage,
  WorkflowBuilderService,
} from 'api-clients/monolith';
import { isEmpty } from 'lodash';
import Pusher from 'pusher-js';
import React, { useEffect, useState, VFC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { makeSelectEnvVars } from 'containers/Auth_old/selectors';

import { createWorkflowMessage, USER } from '../constants';
import { HandlesubmitApplicantResponse } from '../types';
import { MessageInput } from './MessageInput';
import { MessagesList } from './MessagesList/MessagesList';
import { getEpochTime } from './utils/dateUtils';

const useStyles = makeStyles(theme => ({
  chatBotContainer: {
    flexGrow: 1,
    borderRight: theme.customBorders.gray200,
    display: 'flex',
    flexDirection: 'column',
  },
}));

type UrlParams = {
  accountSlug: string;
  funnelSlug: string;
};

export interface ChatBotProps {
  initialMessages: WorkflowBuilderChatbotMessage[];
  setStages: React.Dispatch<
    React.SetStateAction<WorkflowBuilderFunnelStage[] | undefined>
  >;
  hasIndicator: boolean;
  funnel: WorkflowBuilderChatbotInitResponse['funnel'];
}

export const ChatBot: VFC<ChatBotProps> = ({
  initialMessages,
  setStages,
  hasIndicator,
  funnel,
}) => {
  const classes = useStyles();
  const { funnelSlug } = useParams<UrlParams>();
  const [showTypingIndicator, setShowTypingIndicator] = useState(
    hasIndicator || false,
  );
  const [currentBotMessage, setCurrentBotMessage] =
    useState<null | WorkflowBuilderChatbotMessage>(null);

  const defaultBotMessage = createWorkflowMessage(
    funnel.position_name,
    funnel.location,
  );

  const [messages, setMessages] = useState<
    Array<WorkflowBuilderChatbotMessage>
  >(
    initialMessages.length > 0
      ? initialMessages
      : ({
          [getEpochTime(defaultBotMessage.created_at)]: defaultBotMessage,
        } as unknown as WorkflowBuilderChatbotMessage[]),
  );

  const { REACT_APP_PUSHER_APP_KEY, REACT_APP_PUSHER_CLUSTER } = useSelector(
    makeSelectEnvVars(),
  );

  // eslint-disable-next-line camelcase
  const chatbotPushMessage = (data: { thread_id: string }) => {
    WorkflowBuilderService.getInternalApiAiBuilderWorkflowGetLatestMessage(
      funnelSlug,
      data.thread_id,
    )
      .then(result => {
        setShowTypingIndicator(false);
        setMessages(prev => {
          return {
            ...prev,
            [getEpochTime(result.message?.created_at as string)]:
              result.message,
          };
        });
        setStages(result.stages);
      })
      .catch(error => {
        datadogRum.addError(error);
      });
  };

  useEffect(() => {
    const pusher = new Pusher(REACT_APP_PUSHER_APP_KEY, {
      cluster: REACT_APP_PUSHER_CLUSTER,
    });

    const workflowAssistantChannel = `${funnelSlug};workflow_assistant_channel`;
    const messageChannel = pusher.subscribe(workflowAssistantChannel);

    messageChannel.bind('assistant_run_completed', chatbotPushMessage);

    return () => {
      messageChannel.unbind();
      pusher.unsubscribe(workflowAssistantChannel);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentBotMessage = (
    currentMessages: WorkflowBuilderChatbotMessage[],
  ) => {
    if (!isEmpty(currentMessages) && currentMessages.length > 0) {
      for (let i = currentMessages.length - 1; i >= 0; i--) {
        if (currentMessages[i].sender === 'assistant')
          return currentMessages[i];
      }
    }

    return null;
  };

  useEffect(() => {
    if (!isEmpty(messages)) {
      setCurrentBotMessage(getCurrentBotMessage(Object.values(messages)));
    }
  }, [messages]);

  const submitApplicantResponse = (applicantResponse: string) => {
    WorkflowBuilderService.putInternalApiAiBuilderWorkflowChat({
      funnel_slug: funnelSlug,
      response: applicantResponse,
    })
      .then(result => {
        setShowTypingIndicator(result.show_typing_indicator as boolean);
        setStages(result.stages);
        const { message } = result;

        if (message) {
          const uniqMessage = { [getEpochTime(message.created_at)]: message };

          setMessages(prev => {
            return { ...prev, ...uniqMessage };
          });
        }
      })
      .catch(error => {
        datadogRum.addError(error);
        setShowTypingIndicator(false);
      });
  };

  const handlesubmitApplicantResponse: HandlesubmitApplicantResponse = ({
    applicantResponse,
  }) => {
    if (!!currentBotMessage && !isEmpty(currentBotMessage.element_data)) {
      const currentQuestionId = getEpochTime(currentBotMessage.created_at);

      // Disable the last question element
      setMessages(prev => {
        return {
          ...prev,
          [currentQuestionId]: {
            ...currentBotMessage,
            element_disable: true,
            element_data: {
              ...currentBotMessage.element_data,
              selectedButton: currentQuestionId.toString(), // This is a hack to disable the buttons when applicant types a message without clicking the buttons
            },
          },
        };
      });
    }

    const createdAt = new Date().toISOString();
    const message = {
      key: null,
      content: applicantResponse,
      sender: USER,
      created_at: createdAt,
    };

    setMessages(prev => {
      return { ...prev, [getEpochTime(message.created_at)]: message };
    });

    void submitApplicantResponse(applicantResponse);

    setShowTypingIndicator(true);
  };

  const updateMessages = (message: WorkflowBuilderChatbotMessage) => {
    const currentQuestionId = getEpochTime(new Date().toISOString());

    setMessages(prev => {
      return { ...prev, [currentQuestionId]: message };
    });
  };

  return (
    <>
      <Grid className={classes.chatBotContainer}>
        <MessagesList
          messages={messages}
          setMessages={setMessages}
          showTypingIndicator={showTypingIndicator}
          submitApplicantResponse={handlesubmitApplicantResponse}
        />

        <MessageInput
          submitApplicantResponse={handlesubmitApplicantResponse}
          updateMessages={updateMessages}
          setShowTypingIndicator={setShowTypingIndicator}
        />
      </Grid>
    </>
  );
};
