import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  #app {
    min-height: 100%;
    min-width: 100%;
  }

  .x-hellosign-embedded--in-modal {
    z-index: 999;
  }

  .cameratag_room {
    height: 600px;
  }

  #request-revision-tooltip {
    z-index: 0;
  }
`;

export default GlobalStyle;
