import { EventAvailableSlot } from 'api-clients/monolith';
import { useEffect } from 'react';

export const useInputLocationEffect = (
  handleChange: (params: Partial<EventAvailableSlot>) => void,
  inputLocation: string,
) => {
  useEffect(() => {
    handleChange({ location: inputLocation });
  }, [handleChange, inputLocation]);
};
