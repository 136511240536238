import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  delete: {
    id: `app.containers.WorkflowEditor.components.Rules.DocumentSigningRules.DocumentSigningRuleCard.delete`,
    defaultMessage: 'Delete',
  },
  addConditions: {
    id: `app.containers.WorkflowEditor.components.Rules.DocumentSigningRules.DocumentSigningRuleCard.addConditions`,
    defaultMessage: 'Add conditions',
  },
  documentSet: {
    id: `app.containers.WorkflowEditor.components.Rules.DocumentSigningRules.DocumentSigningRuleCard.documentSet`,
    defaultMessage: 'Document Set {number}',
  },
});
