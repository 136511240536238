import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { SharedConditionProps } from '../../types';
import { ComparatorSelect } from '../components/ComparatorSelect';
import { InputField } from '../InputField';
import messages from './messages';
import useStyles from './styles';

export const ApplicantAge: FC<SharedConditionProps> = props => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      <Grid item xs={6} className={classes.applicantAgeHint}>
        <Alert severity="info">
          <FormattedMessage {...messages.applicantAgeHint} />
        </Alert>
      </Grid>
      <ComparatorSelect {...props} onlyReturnBasicComparators />
      <Grid item xs={6}>
        <InputField
          {...props}
          placeholder={intl.formatMessage(
            messages.applicantAgeInputPlaceholder,
          )}
        />
      </Grid>
    </>
  );
};
