import { Grid } from '@material-ui/core';
import React, { FC } from 'react';

import { SharedPartnerConditionProps } from '../../PartnerRules/types';
import { SharedConditionProps } from '../../types';
import { ComparatorSelect } from '../components/ComparatorSelect';
import { PartnerLabelSelect } from '../components/PartnerLabelSelect';
import { InputField } from '../InputField';
import { useStyles } from '../styles';

export const PartnerLabelHasKeyWithValue: FC<
  SharedConditionProps | SharedPartnerConditionProps
> = props => {
  const styles = useStyles();

  return (
    <>
      <PartnerLabelSelect {...props} />
      <ComparatorSelect {...props} />
      <Grid xs={6} className={styles.input}>
        <InputField {...props} />
      </Grid>
    </>
  );
};
