/**
 *
 * CustomDropzoneUploadInput Component
 *
 */

import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { formatBytes } from 'react-dropzone-uploader';
import { classNames } from 'react-extras';
import { FormattedMessage } from 'react-intl';

import MobileCustomDropzoneUploadInput from 'components/CustomDropzone/components/MobileCustomDropZoneUploadInput';
import { Error } from 'components/Error';
import UploadIcon from 'images/upload.svg';
import UploadIconWhite from 'images/upload-white.svg';

import messages from './messages';
import useStyles from './styles';

const CustomDropzoneUploadInput = ({
  isMobile,
  isDropzoneActive,
  fileType,
  hasError,
  maxSize,
  error,
}) => {
  const [isDropzoneHovered, setIsDropzoneHovered] = useState(false);
  const classes = useStyles({
    isActive: isDropzoneActive,
    isHovered: isDropzoneHovered,
    hasError,
    error,
  });
  return isMobile ? (
    <MobileCustomDropzoneUploadInput />
  ) : (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.content}
      onMouseEnter={() => setIsDropzoneHovered(true)}
      onMouseLeave={() => setIsDropzoneHovered(false)}
    >
      <Grid item className={classes.uploadContainer}>
        <Grid
          className={classNames(classes.uploadInnerContainer, {
            [classes.ripple]: isDropzoneActive || isDropzoneHovered,
          })}
        >
          {isDropzoneHovered || isDropzoneActive ? (
            <img src={UploadIconWhite} alt="upload" />
          ) : (
            <img src={UploadIcon} alt="upload" />
          )}
        </Grid>
      </Grid>
      <Grid>
        <Typography
          variant="body2"
          display="inline"
          className={classes.blackText}
        >
          <FormattedMessage {...messages.dragDrop} />
        </Typography>
        &nbsp;
        <Typography
          variant="body2"
          display="inline"
          color="primary"
          className={classes.underlinedText}
        >
          <FormattedMessage {...messages.chooseFile} />
        </Typography>
      </Grid>
      <Typography variant="body2">
        {fileType !== '*' && fileType} (max. {formatBytes(maxSize)})
      </Typography>
      <Error error={error} className={classes.errorMessage} align="center" />
    </Grid>
  );
};

CustomDropzoneUploadInput.propTypes = {
  isDropzoneActive: PropTypes.bool,
  fileType: PropTypes.string,
  hasError: PropTypes.bool,
  maxSize: PropTypes.number,
  error: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default CustomDropzoneUploadInput;
