import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  activeIndicator: {
    color: theme.palette.common.gray400,
    gridArea: 'active-indicator',
    height: theme.typography.fontSize,
    marginRight: theme.spacing(0.75),
    width: theme.typography.fontSize,

    '&.active': {
      color: theme.palette.common.green400,
    },
  },
  activeIndicatorSmall: {
    height: theme.typography.fontSize * 0.8,
    width: theme.typography.fontSize * 0.8,
  },
  h100: {
    height: '100%',
  },
  highlight: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.common.orange200,
  },
  jobSearchResultsContainer: {
    display: 'grid',
    gridTemplateAreas: '"controls" "warning" "list"',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridTemplateRows: 'auto auto minmax(148px, 99fr)',
    minHeight: 'calc(100% - 2.5em)',
  },
  jobSearchResultsListContainer: {
    gridArea: 'list',
    marginTop: theme.spacing(2),
  },
  jobSearchResultsListControlsContainer: {
    gridArea: 'controls',
    padding: theme.spacing(3, 3, 1, 3),
  },
  jobSearchResultsLimit: {
    gridArea: 'warning',
    padding: theme.spacing(1),
  },
  listItemContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridTemplateRows: 'auto auto',
    gridTemplateAreas: `"active-indicator list-item-title"
    ". list-item-subtitle"`,
  },
  listItemSubtitle: {
    color: theme.palette.common.gray600,
    fontSize: theme.spacing(1.5),
    gridArea: 'list-item-subtitle',
  },
  listItemTitle: {
    fontSize: '0.875em',
    gridArea: 'list-item-title',
  },
  listSubheaderContainer: {
    backgroundColor: theme.palette.common.gray200,
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
  },
  listSubheaderSubtitle: {
    color: theme.palette.common.gray600,
    fontSize: '0.785em',
  },
  listSubheaderTitle: {
    color: theme.palette.common.gray800,
    fontFamily: theme.typography.h3.fontFamily,
    fontSize: theme.spacing(1.5),
    textTransform: 'uppercase',
  },
  muiList: {
    maxWidth: '100%',
  },
  optionsContainer: {
    padding: 'unset',
  },
  refreshButton: {
    backgroundColor: theme.palette.common.blue100,
    boxShadow: 'unset',
    color: theme.palette.common.blue400,
    '&:hover': {
      backgroundColor: theme.palette.common.blue400,
      color: theme.palette.common.white,
    },
  },
  switch: {
    paddingLeft: theme.spacing(0.5),
  },
  switchLabel: {
    paddingLeft: theme.spacing(1),
  },
  w100: {
    width: '100%',
  },
  listItemLink: {
    textDecoration: 'none',
    color: theme.palette.common.gray800,
    '&:hover': {
      backgroundColor: theme.palette.common.gray100,
    },
  },
}));

export default useStyles;
