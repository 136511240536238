import { Loader } from '@fountain/fountain-ui-components';
import { Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { Action, WorkflowStageDetail } from 'api-clients/monolith';
import { produce } from 'immer';
import invariant from 'invariant';
import React, { useContext, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Error } from 'components/Error';
import { getActionErrorMessage } from 'containers/WorkflowEditor/components/Rules/Action/constants';
import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';
import globalMessages from 'shared/global/messages';

import { RulesProps } from '../../types';
import { messages } from './messages';

export const useStyles = makeStyles<Theme>(theme => ({
  root: {
    height: 40,
  },
  error: {
    marginBottom: theme.spacing(-1.5),
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
  required: {
    '&::after': {
      content: "'*'",
      color: theme.palette.common.red400,
      margin: theme.spacing(0.5),
    },
  },
}));

export type TargetStageType = {
  id: string;
  title: string;
  type: string;
  // eslint-disable-next-line camelcase
  stage_id: number;
  // eslint-disable-next-line camelcase
  funnel_id: number;
  funnelTitle: string;
};

interface MoveToPositionProps {
  setRules?: React.Dispatch<React.SetStateAction<RulesProps>>;
  setStage?: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
  action: Action;
  ruleId?: string | number;
  errors?: {
    [key: string]: string[];
  };
}

export const MoveToPosition: VFC<MoveToPositionProps> = React.memo(props => {
  const intl = useIntl();
  const styles = useStyles();
  const { openings, setFilterToSelectedOnly, openingsResultIsLoading } =
    useContext(RulesEditDataContext);
  const { action, errors } = props;
  const stages: TargetStageType[] = [];

  openings?.forEach(opening => {
    const stagesWithFunnel = opening.stages.map(stage => ({
      ...stage,
      funnelTitle: opening.title,
      funnel_id: opening.funnel_id,
    }));
    stages.push(...stagesWithFunnel);
  });
  const selectedStage = stages.find(
    stage =>
      stage.stage_id === props.action.ref_stage_1_id &&
      (props.action.ref_funnel_1_id
        ? stage.funnel_id === props.action.ref_funnel_1_id
        : true),
  );

  const onChangeStage = (stage: TargetStageType | null) => {
    invariant(stage, 'stage can not be null');

    if (props.setRules && props.ruleId) {
      const { setRules, ruleId } = props;
      setRules(
        produce((draftRules: RulesProps) => {
          const draftActions =
            draftRules[ruleId]?.action_set_attributes?.actions_attributes ?? [];

          const idx = draftActions.findIndex(act => act.id === props.action.id);

          draftActions[idx].ref_stage_1_id = stage?.stage_id;
          draftActions[idx].ref_funnel_1_id = stage?.funnel_id;
        }),
      );
    } else if (props.setStage) {
      const { setStage } = props;
      setStage(
        produce((draftStage: WorkflowStageDetail) => {
          if (
            draftStage.type === 'RuleStage' ||
            draftStage.type === 'CustomStage' ||
            draftStage.type === 'AssessmentStage'
          ) {
            const elseAction =
              draftStage?.additional_info?.else_action_set_attributes
                ?.actions_attributes?.[0];

            if (elseAction) {
              elseAction.ref_stage_1_id = stage.stage_id;
              elseAction.ref_funnel_1_id = stage.funnel_id;
            }
          }
        }),
      );
    }
  };

  const customFilter = (
    options: TargetStageType[],
    { inputValue }: { inputValue: string },
  ): TargetStageType[] =>
    options.filter(option => {
      const value = inputValue.toLowerCase();
      const matchesLabel = option.title.toLowerCase().includes(value);
      const matchesFunnel = option.funnelTitle.toLowerCase().includes(value);
      return matchesLabel || matchesFunnel;
    });

  const errorMessage =
    !action.ref_stage_1_id && getActionErrorMessage('ref_stage_1_id', errors);

  return (
    <Grid item xs={6}>
      <Typography variant="caption" className={styles.required}>
        <FormattedMessage {...messages.openingStage} />
      </Typography>
      <Autocomplete
        filterOptions={customFilter}
        classes={{
          root: styles.root,
        }}
        onOpen={() => {
          setFilterToSelectedOnly(false);
        }}
        getOptionLabel={option => option.title}
        options={stages}
        loading={openingsResultIsLoading}
        loadingText={intl.formatMessage(globalMessages.loading)}
        groupBy={option => option.funnelTitle}
        disableClearable
        onChange={(_, option) => onChangeStage(option)}
        fullWidth
        size="small"
        value={selectedStage ?? null}
        renderInput={params => (
          <TextField
            {...params}
            aria-label={intl.formatMessage(messages.openingStage)}
            variant="outlined"
            error={Boolean(errorMessage)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {openingsResultIsLoading ? <Loader /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      <Error className={styles.error} align="right" error={errorMessage} />
    </Grid>
  );
});
