export const isEmptyAddress = address => {
  let isEmpty = true;
  const addr = address;

  Object.keys(addr).forEach(field => {
    if (field === 'original_address') {
      return;
    }

    if (addr[field]) {
      isEmpty = false;
    }
  });

  return isEmpty;
};
