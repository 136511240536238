import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  actionRow: {
    marginBottom: theme.spacing(3),

    '&:last-child': {
      marginBottom: 0,
    },
  },
  tagContainer: {
    marginBottom: theme.spacing(2.5),
  },
  rowTag: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.gray800,
  },
}));
