/**
 *
 * Material UI styles for MobileSmsPreview
 *
 *
 * @see https://material-ui.com/styles/basics
 *
 */

export const styles = theme => ({
  message: {
    '&:not(:last-of-type)': {
      marginBottom: theme.spacing(1),
    },
  },
});

export const messageBubbleStyles = theme => ({
  messageBubble: {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    display: 'inline-block',
    maxWidth: 160,
    overflowY: 'scroll',
    padding: theme.spacing(1),
    maxHeight: '100%',
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  egressBubble: {
    backgroundColor: theme.palette.common.blue400,
    borderBottomLeftRadius: 10,
    color: theme.palette.common.white,
    marginLeft: 'auto',
  },
  ingressBubble: {
    backgroundColor: theme.palette.common.gray200,
    borderBottomRightRadius: 10,
    color: theme.palette.common.black,
    marginRight: 'auto',
  },
  whatsappGreenBubble: {
    backgroundColor: theme.palette.common.green400,
    borderBottomLeftRadius: 10,
    color: theme.palette.common.white,
    marginLeft: 'auto',
  },
});

export default styles;
