import {
  Button,
  InnerContent,
  Input,
  SimpleModal,
  SimpleModalFooter,
  SimpleModalHeader,
  SimpleModalHeaderIcon,
  SimpleModalTitle,
  TextButton,
} from '@fountain/fountain-ui-components';
import { MenuItem, OutlinedInput, Select } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import fileDownload from 'js-file-download';
import React, { useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import CustomDropzone from 'components/CustomDropzone';
import FilePreview from 'components/CustomDropzone/FilePreview';
import { Error } from 'components/Error';
import SaveIcon from 'images/save.svg';
import globalMessages from 'shared/global/messages';

import {
  DEFAULT_CONFIDENCE_THRESHOLD,
  DEFAULT_MODE,
  DEFAULT_MODEL_TYPE,
  MODEL_TYPES,
  MODES,
} from '../constants';
import useBulkUploadCsv from './hooks/useBulkUploadCsv';
import { useGetSampleContent } from './hooks/useGetSampleContent';
import messages from './messages';
import { useStyles } from './styles';

import type { AutomatedResponseMode } from 'api-clients/monolith/models/AutomatedResponseMode';
import type { AutomatedResponseModelType } from 'api-clients/monolith/models/AutomatedResponseModelType';
export interface AutomatedResponseBulkUploadProps {
  isBulkImportPopupOpen: boolean;
  setIsBulkImportPopupOpen: (flag: boolean) => void;
}

export const AutomatedResponseBulkUpload: VFC<
  AutomatedResponseBulkUploadProps
> = ({ isBulkImportPopupOpen, setIsBulkImportPopupOpen }) => {
  const classes = useStyles();
  const intl = useIntl();

  const [csvFile, setCsvFile] = useState<Blob | null>();
  const [modelName, setModelName] = useState<string>();
  const [modelType, setModelType] =
    useState<AutomatedResponseModelType>(DEFAULT_MODEL_TYPE);
  const [confidenceThreshold, setConfidenceThreshold] = useState(
    DEFAULT_CONFIDENCE_THRESHOLD,
  );
  const [mode, setMode] = useState<AutomatedResponseMode>(DEFAULT_MODE);
  const [error, setError] = useState<string | null>(null);

  const { bulkUploadCsv } = useBulkUploadCsv();

  const handleUpload = (file: Blob) => {
    setCsvFile(file);
  };

  const { result: sampleContentResult } = useGetSampleContent();

  const handleDownload = () => {
    if (sampleContentResult.status === 'ready') {
      fileDownload(
        sampleContentResult.data as string,
        'bulk_import_automated_responses_sample.csv',
      );
    }
  };

  const handleClose = () => {
    setIsBulkImportPopupOpen(false);
  };

  const handleConfidenceThreshold = (input: string) => {
    const min = 1;
    const max = 100;
    const value = parseInt(input, 10);
    if (value >= min && value <= max) {
      setError(null);
    } else {
      setError(
        intl.formatMessage(messages.confidenceThresholdErrorMessage, {
          min,
          max,
        }),
      );
    }
    setConfidenceThreshold(value);
  };

  const submitUpload = () => {
    if (csvFile && modelName && modelType && mode && confidenceThreshold) {
      void bulkUploadCsv(
        csvFile,
        modelName,
        modelType,
        mode,
        confidenceThreshold,
      );
    }
    handleClose();
  };

  if (!isBulkImportPopupOpen) {
    return null;
  }

  return (
    <SimpleModal
      onClose={handleClose}
      maxWidth={undefined}
      blurOverlay={undefined}
    >
      <SimpleModalHeader onClose={handleClose}>
        <SimpleModalHeaderIcon Icon={SaveIcon} />
        <SimpleModalTitle>
          <FormattedMessage {...messages.header} />
        </SimpleModalTitle>
      </SimpleModalHeader>
      <InnerContent>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Typography variant="h6" className={classes.label}>
            <FormattedMessage {...messages.uploadCsvFile} />
          </Typography>
          <TextButton
            color={undefined}
            className={classes.label}
            onClick={handleDownload}
          >
            <FormattedMessage {...messages.downloadSampleFile} />
          </TextButton>
          <CustomDropzone
            fileType=".csv"
            className={classes.dropzoneContainer}
            onChangeStatus={handleUpload}
            PreviewComponent={FilePreview}
          />
          <Typography variant="h6" className={classes.label}>
            <FormattedMessage {...messages.modelName} />
          </Typography>
          <Input onChange={e => setModelName(e.target.value.trim())} />
          <Typography variant="h6" className={classes.label}>
            <FormattedMessage {...messages.modelType} />
          </Typography>
          <Select
            value={modelType}
            input={<OutlinedInput />}
            onChange={e =>
              setModelType(e.target.value as AutomatedResponseModelType)
            }
            classes={{
              select: classes.selectField,
            }}
          >
            {MODEL_TYPES.map(item => (
              <MenuItem value={item.value} key={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
          <Typography variant="h6" className={classes.label}>
            <FormattedMessage {...messages.mode} />
          </Typography>
          <Select
            value={mode}
            input={<OutlinedInput />}
            onChange={e => setMode(e.target.value as AutomatedResponseMode)}
            classes={{
              select: classes.selectField,
            }}
          >
            {MODES.map(item => (
              <MenuItem value={item.value} key={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
          <Typography variant="h6" className={classes.label}>
            <FormattedMessage {...messages.threshold} />
          </Typography>
          <Input
            value={confidenceThreshold}
            type="number"
            onChange={e => handleConfidenceThreshold(e.target.value)}
          />
          {error && <Error error={error} align="right" />}
        </Grid>
      </InnerContent>
      <SimpleModalFooter>
        <Button
          className={classes.footerButton}
          type="secondary"
          onClick={handleClose}
          size="small"
        >
          <FormattedMessage {...globalMessages.cancel} />
        </Button>
        <Button
          className={classes.footerButton}
          onClick={submitUpload}
          size="small"
          disabled={
            !csvFile ||
            !modelName ||
            !modelType ||
            !mode ||
            !confidenceThreshold ||
            !!error
          }
        >
          <FormattedMessage {...globalMessages.submit} />
        </Button>
      </SimpleModalFooter>
    </SimpleModal>
  );
};
