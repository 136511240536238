import {
  IconButton as StyledIconButton,
  theme,
} from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { classNames, If } from 'react-extras';
import { FormattedNumber } from 'react-intl';
import { useSelector } from 'react-redux';
import { generatePath, Link, useLocation, useParams } from 'react-router-dom';
import { REACT_APP_GLOBAL_API_BASE_URL } from 'runtimeEnvVars';

import {
  makeSelectPolicies,
  makeSelectSignedOut,
  makeSelectWhoami,
} from 'containers/Auth_old/selectors';
import { MESSENGER_LOCAL_STORAGE_KEY } from 'containers/Messenger/constants';
import useUnreadMessageThreadCount from 'hooks/useUnreadMessageThreadCount';
import MessengerV1ChatSvg from 'images/MessengerV1ChatSvg';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from 'utils/storageUtils';

import MessageIcon from './MessageIcon';
import makeStyles from './styles';

export default function Messaging() {
  const { accountSlug } = useParams();

  const classes = makeStyles();
  const { view_messaging_thread: viewMessagingThread } = useSelector(
    makeSelectPolicies(),
  );
  const [showUnreadSms, setShowUnreadSms] = useState(false);
  const userSignedOut = useSelector(makeSelectSignedOut());
  const whoami = useSelector(makeSelectWhoami());
  const { pathname, search } = useLocation();

  const {
    getUnreadMessageThreadCount,
    data: { unread_message_thread_count: unreadThreadCount },
  } = useUnreadMessageThreadCount();

  useEffect(() => {
    if (!userSignedOut) {
      const url = `${REACT_APP_GLOBAL_API_BASE_URL}/unread_sms`;
      fetch(url, {
        credentials: 'include',
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Network response was not ok');
        })
        .then(data => {
          setShowUnreadSms(data.unread_sms);
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    }
  }, [userSignedOut]);

  useEffect(() => {
    if (whoami.show_cumulative_unread_message_count)
      getUnreadMessageThreadCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (userSignedOut) removeLocalStorageItem(MESSENGER_LOCAL_STORAGE_KEY);

  return (
    <If condition={viewMessagingThread}>
      <>
        {!whoami.show_cumulative_unread_message_count && showUnreadSms ? (
          <StyledIconButton
            defaultIconColor
            component={Link}
            to={
              /\/messenger\//g.test(pathname)
                ? `${pathname}${search}`
                : getLocalStorageItem(MESSENGER_LOCAL_STORAGE_KEY) ??
                  generatePath('/:accountSlug/messenger', { accountSlug })
            }
            className={classNames(classes.hiddenOnMobile, classes.button)}
            data-testid="messenger-icon"
          >
            <MessengerV1ChatSvg
              color={(showUnreadSms && theme.palette.common.blue400) || 'none'}
            />
          </StyledIconButton>
        ) : (
          <>
            {whoami.show_cumulative_unread_message_count &&
            unreadThreadCount > 0 ? (
              <MessageIcon
                messageClass={classes.messageLockupWithCount}
                showBlueDotIcon={false}
              >
                <Grid item className={classes.threadCountBadge}>
                  <Typography
                    className={classes.threadCountText}
                    variant="body2"
                  >
                    <FormattedNumber
                      style="unit"
                      unitDisplay="narrow"
                      value={
                        unreadThreadCount <= 99 ? unreadThreadCount : '99+'
                      }
                    />
                  </Typography>
                </Grid>
              </MessageIcon>
            ) : (
              <MessageIcon messageClass={classes.messageLockupWithoutCount} />
            )}
          </>
        )}
      </>
    </If>
  );
}
