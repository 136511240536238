import { useEffect, useRef } from 'react';

/**
 * Manage focus on ref. Return ref;
 *
 * @param {bool} condition
 * @param {object|null} currentRef React ref object
 * @returns {object} ref
 */
const useRefFocus = (condition, currentRef = null) => {
  const newRef = useRef(null);
  const ref = currentRef || newRef;

  useEffect(() => {
    if (ref && ref.current && condition) {
      ref.current.focus();
    }
  }, [condition, ref]);

  return ref;
};

export default useRefFocus;
