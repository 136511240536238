/* eslint-disable no-underscore-dangle */
import { Button, IconButton } from '@fountain/fountain-ui-components';
import { Button as MuiButton, Grid, Typography } from '@material-ui/core';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';

import { StageSettingCardEmptyState } from 'containers/WorkflowEditor/components/StageSettingCardEmptyState';
import {
  StageContext,
  StageContextData,
} from 'containers/WorkflowEditor/contexts/stageContext';
import { RulesEditDataContextProvider } from 'containers/WorkflowEditor/RulesEditDataContextProvider/RulesEditDataContextProvider';
import { PlusIcon } from 'images/PlusIcon';

import { DEFAULT_PARTNER_RULE } from './constants';
import { messages } from './messages';
import { PartnerRuleCard } from './PartnerRuleCard';
import { useStyles } from './styles';
import { PartnerRuleProps } from './types';

export const PartnerRules: FC = () => {
  const styles = useStyles();
  const intl = useIntl();

  const {
    stage: { external_id: externalId },
    partnerRules,
    setPartnerRules,
    updateStageResult,
  } = useContext<StageContextData>(StageContext);

  const availableRules = { ...partnerRules };

  // Filter out any partnerRules that have _destroy set to true
  Object.keys(availableRules).forEach(ruleId => {
    if (availableRules[ruleId]._destroy) {
      delete availableRules[ruleId];
    }
  });

  const errors =
    (updateStageResult.isError && updateStageResult?.error?.errors) ||
    undefined;

  const onAddRule = () => {
    setPartnerRules(
      produce((draftRules: PartnerRuleProps) => {
        draftRules[uuid()] = DEFAULT_PARTNER_RULE;
      }),
    );
  };

  const onDeleteRule = (ruleId: string | number) => {
    setPartnerRules(
      produce((draftRules: PartnerRuleProps) => {
        draftRules[ruleId]._destroy = true;
      }),
    );
  };

  if (Object.keys(availableRules).length === 0) {
    return (
      <StageSettingCardEmptyState
        stageSettingCardProps={{
          title: intl.formatMessage(messages.rule),
          variant: 'default',
        }}
        buttonComponent={
          <Button
            type="secondary"
            size="small"
            autoWidth
            onClick={onAddRule}
            disableRipple
            aria-label={intl.formatMessage(messages.addRule)}
          >
            <Typography variant="h4" color="primary">
              <FormattedMessage {...messages.addRule} />
            </Typography>
          </Button>
        }
      />
    );
  }

  return (
    <RulesEditDataContextProvider externalId={externalId}>
      <Grid>
        {Object.keys(availableRules).map((ruleId, index) => {
          const rule = availableRules[ruleId];

          return (
            <PartnerRuleCard
              key={ruleId}
              ruleId={ruleId}
              rule={rule}
              index={index}
              setPartnerRules={setPartnerRules}
              onDeleteRule={onDeleteRule}
              errors={errors}
            />
          );
        })}
        <Grid container justify="center">
          <MuiButton
            onClick={onAddRule}
            aria-label={intl.formatMessage(messages.addRule)}
          >
            <IconButton
              size="small"
              primary
              className={styles.plusButton}
              component={Grid}
            >
              <PlusIcon color="inherit" fontSize="inherit" />
            </IconButton>
            <Typography variant="h4" color="primary" className={styles.addRule}>
              <FormattedMessage {...messages.addRule} />
            </Typography>
          </MuiButton>
        </Grid>
      </Grid>
    </RulesEditDataContextProvider>
  );
};
