import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  conditionRow: {
    marginBottom: theme.spacing(3),

    '&:last-child': {
      marginBottom: 0,
    },
  },
  input: {
    padding: theme.spacing(1),
  },
  tagContainer: {
    marginBottom: theme.spacing(1.75),
    height: theme.spacing(4.5),
  },
  infoAlert: {
    marginTop: theme.spacing(0.5),

    '& > div': {
      margin: 'initial', // Have icon & text left aligned as per design
    },
  },
}));
