/* eslint-disable no-underscore-dangle */
import { IconButton } from '@fountain/fountain-ui-components';
import { Button as MuiButton, Grid, Typography } from '@material-ui/core';
import { WorkflowAssessmentStage } from 'api-clients/monolith';
import { produce } from 'immer';
import React, { useContext, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';

import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';
import { RulesEditDataContextProvider } from 'containers/WorkflowEditor/RulesEditDataContextProvider/RulesEditDataContextProvider';
import { PlusIcon } from 'images/PlusIcon';

import { ElseAction } from '../Action/ElseAction';
import {
  DEFAULT_ASSESSMENT_ELSE_ACTION,
  DEFAULT_ASSESSMENT_RULE,
} from '../constants';
import { messages } from '../messages';
import { RuleCard } from '../RuleCard';
import { useStyles } from '../styles';
import { RulesProps } from '../types';

export interface RulesComponentProps {
  stage: WorkflowAssessmentStage;
}

export const AssessmentRules: VFC<RulesComponentProps> = ({ stage }) => {
  const styles = useStyles();
  const intl = useIntl();

  const { rules, setRules, setStage, updateStageResult } =
    useContext(StageContext);

  const availableRules = { ...rules };

  // Filter out any rules that have _destroy set to true
  Object.keys(availableRules).forEach(ruleId => {
    if (availableRules[ruleId]._destroy) {
      delete availableRules[ruleId];
    }
  });

  const availableElseActionSet =
    stage.additional_info.else_action_set_attributes;

  const errors =
    (updateStageResult.isError && updateStageResult?.error?.errors) ||
    undefined;

  const currentElseAction =
    !availableElseActionSet?._destroy &&
    availableElseActionSet?.actions_attributes?.[0];

  const availableRulesCount = Object.keys(availableRules).length;

  const onAddRule = () => {
    setRules(
      produce((draftRules: RulesProps) => {
        draftRules[uuid()] = DEFAULT_ASSESSMENT_RULE;
      }),
    );
  };

  const onDeleteRule = (ruleId: string | number) => {
    setRules(
      produce((draftRules: RulesProps) => {
        draftRules[ruleId]._destroy = true;
      }),
    );
  };

  if (!currentElseAction) {
    setStage(
      produce(stage, draftStage => {
        const elseActionSet =
          draftStage.additional_info.else_action_set_attributes;

        if (!elseActionSet) {
          // Sets else_action_set_attributes if it doesn't exist yet so that the else action shows up for rules in the UI
          draftStage.additional_info.else_action_set_attributes = {
            actions_attributes: [{ ...DEFAULT_ASSESSMENT_ELSE_ACTION }],
          };
        }
      }),
    );
  }

  return (
    <RulesEditDataContextProvider externalId={stage.external_id}>
      <Grid>
        {Object.keys(availableRules as Record<string, unknown>).map(
          (ruleId, index) => {
            const rule = availableRules[ruleId];

            return (
              <RuleCard
                key={ruleId}
                ruleId={ruleId}
                rule={rule}
                index={index}
                setRules={setRules}
                deleteEnabled={availableRulesCount > 1}
                onDeleteRule={onDeleteRule}
                stageType={stage.type}
                errors={errors}
              />
            );
          },
        )}
        <Grid container justify="center">
          <MuiButton
            onClick={onAddRule}
            aria-label={intl.formatMessage(messages.addRule)}
          >
            <IconButton
              size="small"
              primary
              className={styles.plusButton}
              component={Grid}
            >
              <PlusIcon color="inherit" fontSize="inherit" />
            </IconButton>
            <Typography variant="h4" color="primary" className={styles.addRule}>
              <FormattedMessage {...messages.addRule} />
            </Typography>
          </MuiButton>
        </Grid>
      </Grid>
      {currentElseAction && (
        <ElseAction
          setStage={setStage}
          action={currentElseAction}
          stageType={stage.type}
          errors={errors}
        />
      )}
    </RulesEditDataContextProvider>
  );
};
