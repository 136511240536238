import { Hidden } from '@material-ui/core';
import React, { useEffect, useState, VFC } from 'react';

import { SelectedBrand } from '../types';
import { IPhoneFrame } from './IPhoneFrame';

export interface WidgetProps {
  axAgentId: string;
  selectedBrand: SelectedBrand;
}

export const Widget: VFC<WidgetProps> = ({ axAgentId, selectedBrand }) => {
  const chatAgentWidgetUrl = localStorage.getItem(
    'chatAgentWidgetUrl',
  ) as string;
  const [iframeUrl, setIframeUrl] = useState<string>(
    `${chatAgentWidgetUrl}/ax-agent/${String(
      axAgentId,
    )}/apply/chat?show='true'`,
  );

  useEffect(() => {
    setIframeUrl(
      `${chatAgentWidgetUrl}/ax-agent/${String(
        axAgentId,
      )}/apply/chat?show='true'`,
    );
  }, [axAgentId, chatAgentWidgetUrl, selectedBrand]);

  return (
    <Hidden smDown>
      <IPhoneFrame src={iframeUrl} />
    </Hidden>
  );
};
