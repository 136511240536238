import { createSelector } from 'reselect';

import { initialState } from './reducer';

/**
 * Direct selector to the sendEmailPopup state domain
 */

const selectSendEmailPopup = state => state.sendEmailPopup || initialState;

/**
 * Other specific selectors
 */
const makeSelectSendEmailPopupState = () =>
  createSelector(
    makeSelectSendEmailPopup(),
    sendEmailPopupState => sendEmailPopupState.sendEmailPopup,
  );

/**
 * Default selector used by SendEmailPopup
 */

const makeSelectMembers = () =>
  createSelector(selectSendEmailPopup, substate => substate.users);

const makeSelectSendEmailPopup = () =>
  createSelector(selectSendEmailPopup, substate => substate);

export default makeSelectSendEmailPopup;
export {
  makeSelectMembers,
  makeSelectSendEmailPopupState,
  selectSendEmailPopup,
};
