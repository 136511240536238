import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  course: {
    id: `app.containers.WorkflowEditor.components.Rules.Condition.LearningCondition.course`,
    defaultMessage: 'Course',
  },
  lessonlyIntegrationError: {
    id: `app.containers.WorkflowEditor.components.Rules.Condition.LearningCondition.lessonlyIntegrationError`,
    defaultMessage: 'Failed to load Lessonly resources.',
  },
  lessonlyResource: {
    id: `app.containers.WorkflowEditor.components.Rules.Condition.LearningCondition.lessonlyResource`,
    defaultMessage: 'Lessonly Resource',
  },
  northpassIntegrationError: {
    id: `app.containers.WorkflowEditor.components.Rules.Condition.LearningCondition.northpassIntegrationError`,
    defaultMessage: 'Failed to load Northpass resources.',
  },
  quiz: {
    id: `app.containers.WorkflowEditor.components.Rules.Condition.LearningCondition.quiz`,
    defaultMessage: 'Quiz',
  },
});
