import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React, { FC } from 'react';

export const DataCollectionStageIcon: FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 2C2.44772 2 2 2.44772 2 3V6C2 6.55228 2.44772 7 3 7H6C6.55228 7 7 6.55228 7 6V3C7 2.44772 6.55228 2 6 2H3Z" />
    <path d="M3 9C2.44772 9 2 9.44772 2 10V13C2 13.5523 2.44772 14 3 14H6C6.55228 14 7 13.5523 7 13V10C7 9.44772 6.55228 9 6 9H3Z" />
    <path d="M9 10C9 9.44772 9.44772 9 10 9H13C13.5523 9 14 9.44772 14 10V13C14 13.5523 13.5523 14 13 14H10C9.44772 14 9 13.5523 9 13V10Z" />
    <path d="M13.842 4.09347C14.083 3.81062 14.0439 3.39024 13.7547 3.15454C13.4654 2.91883 13.0354 2.95704 12.7944 3.23989L10.9996 5.34573L10.1639 4.52861C9.89767 4.26826 9.46597 4.26826 9.1997 4.52861C8.93343 4.78895 8.93343 5.21106 9.1997 5.47141L10.5633 6.80474C10.6988 6.93718 10.885 7.0078 11.0763 6.99931C11.2677 6.99083 11.4466 6.90402 11.5692 6.76012L13.842 4.09347Z" />
  </SvgIcon>
);
