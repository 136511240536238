import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  consider: {
    defaultMessage: 'Consider',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.SterlingServiceLevel.consider`,
  },
  sterlingResult: {
    defaultMessage: 'Sterling Result',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.SterlingServiceLevel.sterlingResult`,
  },
  unperformable: {
    defaultMessage: 'Unperformable',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.SterlingServiceLevel.unperformable`,
  },
});
