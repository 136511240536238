/**
 *
 * ApplicantTableV2NameCell
 *
 */

import { CustomTooltip } from '@fountain/fountain-ui-components';
import { Grid, TableCell, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import Highlighter from 'react-highlight-words';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { PHONE_PLATFORM_TYPES } from 'components/SmsMessage/constants';
import { APPLICANT_DRAWER_TABS } from 'containers/ApplicantDrawerPopup/constants';
import { makeSelectTableColumnWidths } from 'containers/ApplicantTableV2/selectors';
import { makeSelectWhoami } from 'containers/Auth_old/selectors';
import { openApplicantDrawerPopup } from 'containers/GlobalPopup/actions';
import { POPUP_TYPES } from 'containers/GlobalPopup/constants';
import DuplicateApplicantIcon from 'images/duplicate-applicant-icon.svg';
import EmailUnsubscribeIcon from 'images/email-unsubscribed-icon.svg';
import SmsUnsubcsribeIcon from 'images/sms-unsubscribed-icon.svg';
import globalMessages from 'shared/global/messages';
import findChunksAtBeginningOfWords from 'utils/findChunksAtBeginningOfWords';
import { updateParams } from 'utils/urlUtils';

import { highlightStyle } from '../../styles';
import styles from './styles';

function ApplicantTableV2NameCell({
  classes,
  applicant,
  query,
  intl,
  columnType,
}) {
  const dispatch = useDispatch();
  const location = useLocation();

  const currentUser = useSelector(makeSelectWhoami());
  const tableColumnWidths = useSelector(makeSelectTableColumnWidths());

  const applicantDrawerUrl = () => {
    const { pathname } = location;

    const newParams = updateParams({
      persistedPopupType: POPUP_TYPES.APPLICANT_DRAWER_POPUP,
      applicantId: applicant.id,
      tabIndex: APPLICANT_DRAWER_TABS.GENERAL,
    });

    return `${pathname}?${newParams}`;
  };

  const openApplicantDrawer = () => {
    dispatch(
      openApplicantDrawerPopup(
        applicant.id,
        APPLICANT_DRAWER_TABS.GENERAL,
        location.search,
      ),
    );
  };

  const renderSmsTooltip = () => {
    if (applicant.sms_unsubscribed && !applicant.can_receive_sms) {
      return (
        <React.Fragment>
          <div>{intl.formatMessage(globalMessages.smsUnsubscribed)}</div>
          <div>{intl.formatMessage(globalMessages.unableToDeliverSms)}</div>
        </React.Fragment>
      );
    }
    if (applicant.sms_unsubscribed) {
      return intl.formatMessage(globalMessages.smsUnsubscribed);
    }
    if (!applicant.can_receive_sms) {
      return intl.formatMessage(globalMessages.unableToDeliverSms);
    }

    return 'Error with applicant SMS.';
  };

  const nameCellWidth = tableColumnWidths[columnType];

  return (
    <TableCell
      role="cell"
      style={{ minWidth: nameCellWidth, maxWidth: nameCellWidth }}
      className={`${classes.nameCell} ${classes.td}`}
    >
      <Grid
        style={{ maxWidth: nameCellWidth }}
        className={classes.content}
        container
        wrap="nowrap"
        justify="center"
        alignItems="center"
      >
        <Link to={applicantDrawerUrl} className={classes.applicantNameLink}>
          <Typography
            variant="h4"
            onClick={openApplicantDrawer}
            className={classes.nameText}
          >
            <Highlighter
              className={classes.nameHighlighter}
              autoEscape
              searchWords={[query]}
              textToHighlight={applicant.name}
              highlightStyle={highlightStyle}
              findChunks={findChunksAtBeginningOfWords(true)}
              title={applicant.name}
            />
            {applicant.is_duplicate && (
              <CustomTooltip
                title={intl.formatMessage(globalMessages.duplicateApplicant)}
                dense
              >
                <img
                  src={DuplicateApplicantIcon}
                  className={classes.icon}
                  alt="duplicate-applicant-icon"
                />
              </CustomTooltip>
            )}
            {applicant.sms_unsubscribed &&
              currentUser.account_sms_enabled &&
              applicant.phone_platform !== PHONE_PLATFORM_TYPES.whats_app && (
                <CustomTooltip title={renderSmsTooltip()} dense>
                  <img
                    src={SmsUnsubcsribeIcon}
                    className={classes.icon}
                    alt="sms-unsubscribe-icon"
                  />
                </CustomTooltip>
              )}
            {applicant.email_unsubscribed && (
              <CustomTooltip
                title={intl.formatMessage(globalMessages.emailUnsubscribed)}
                dense
              >
                <img
                  src={EmailUnsubscribeIcon}
                  className={classes.icon}
                  alt="email-unsubscribe-icon"
                />
              </CustomTooltip>
            )}
          </Typography>
        </Link>
      </Grid>
    </TableCell>
  );
}

ApplicantTableV2NameCell.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  query: PropTypes.string,
  applicant: PropTypes.object,
  columnType: PropTypes.string.isRequired,
};

export default withStyles(theme => ({
  ...styles(theme),
}))(memo(injectIntl(ApplicantTableV2NameCell)));
