import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { AutomatedResponse } from 'api-clients/monolith';
import React, { VFC } from 'react';
import { useIntl } from 'react-intl';

import { messages } from './messages';
import { useStyles } from './styles';

export interface IntentFilterProps {
  intents: AutomatedResponse[];
  handleIntentChange: (intents: AutomatedResponse[]) => void;
}

export const IntentFilter: VFC<IntentFilterProps> = ({
  intents,
  handleIntentChange,
}) => {
  const styles = useStyles();
  const intl = useIntl();

  return (
    <Grid item>
      <StyledReactSelect
        isMulti
        canSelectAll
        options={intents}
        onChange={handleIntentChange}
        label={intl.formatMessage(messages.intentLabel)}
        aria-label={intl.formatMessage(messages.intentLabel)}
        getOptionLabel={(option: AutomatedResponse) => option.intent}
        getOptionValue={(option: AutomatedResponse) => option.intent}
        className={styles.selectIntentInput}
      />
    </Grid>
  );
};
