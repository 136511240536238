import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    marginLeft: theme.spacing(1.5), // MUI FormControlLabels have negative margins by default
  },
  emailI9Switch: {
    paddingBottom: theme.spacing(1.5),
  },
  paddingBottom: {
    paddingBottom: theme.spacing(3),
  },
  i9PaddingBottom: {
    paddingBottom: theme.spacing(2),
  },
  select: {
    width: theme.spacing(37.5),
    paddingLeft: theme.spacing(4),
  },
  switchLabel: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.common.gray800,
  },
}));
