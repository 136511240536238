import { ManageRosterService } from 'api-clients/monolith';
import { EventQuery } from 'Calendar/hooks/useEvents';
import { useApiService } from 'hooks';
import { useCallback } from 'react';

export interface BookedSlotsOptions {
  externalId: string;
  queryFilter: EventQuery;
}

export const useBookedSlots = ({
  externalId,
  queryFilter,
}: BookedSlotsOptions) => {
  const fetch = useCallback(
    () =>
      ManageRosterService.getInternalApiEventsRosters(
        externalId,
        queryFilter.stages.map(f => f.id),
        queryFilter.funnels.map(f => f.id),
        queryFilter.selectedColumns,
      ),
    [externalId, queryFilter],
  );

  return useApiService(fetch);
};
