import { CustomTooltip } from '@fountain/fountain-ui-components';
import { IconButton } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { ChatbotFeedback } from 'api-clients/monolith/models/ChatbotFeedback';
import React, { VFC } from 'react';

import { useStyles } from './styles';

interface FeedbackButtonProps {
  type: ChatbotFeedback;
  Icon: React.ComponentType<SvgIconProps>;
  label: string;
  handleFeedbackButtonClick: (status: ChatbotFeedback) => void;
}

export const FeedbackButton: VFC<FeedbackButtonProps> = ({
  type,
  Icon,
  label,
  handleFeedbackButtonClick,
}) => {
  const styles = useStyles({ type });
  return (
    <CustomTooltip dense title={label} enterTouchDelay={100}>
      <IconButton
        className={styles.feedbackButton}
        onClick={() => handleFeedbackButtonClick(type)}
      >
        <Icon className={styles.feedbackIcon} />
      </IconButton>
    </CustomTooltip>
  );
};
