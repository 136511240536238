import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React, { FC } from 'react';

export const BackgroundCheckStageIcon: FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 12.75C14 12.3358 13.6642 12 13.25 12H8.74947C8.33525 12 7.99947 12.3358 7.99947 12.75C7.99947 13.1642 8.33525 13.5 8.74947 13.5H13.25C13.6642 13.5 14 13.1642 14 12.75Z"
    />
    <path d="M7.45057 3.30533C7.74918 3.00672 7.74918 2.52257 7.45057 2.22396C7.15196 1.92535 6.66781 1.92535 6.3692 2.22396L3.1251 5.46806C2.82649 5.76667 2.82649 6.25082 3.1251 6.54943C3.42371 6.84804 3.90785 6.84804 4.20647 6.54943L4.20992 6.54598C4.30755 6.44835 4.46584 6.44835 4.56347 6.54598L5.29129 7.27379C5.38892 7.37142 5.38892 7.52972 5.29129 7.62735L2.22396 10.6947C1.92535 10.9933 1.92535 11.4774 2.22396 11.776C2.52257 12.0747 3.00672 12.0747 3.30533 11.776L6.37265 8.70871C6.47028 8.61108 6.62858 8.61108 6.72621 8.70871L7.45402 9.43653C7.55165 9.53416 7.54672 9.6917 7.4856 9.81551C7.34455 10.1013 7.39295 10.4568 7.6308 10.6947C7.92941 10.9933 8.41355 10.9933 8.71217 10.6947L11.776 7.6308C12.0747 7.33219 12.0747 6.84804 11.776 6.54943C11.4774 6.25082 10.9933 6.25082 10.6947 6.54943L10.6912 6.55288C10.5936 6.65051 10.4353 6.65051 10.3377 6.55288L7.44712 3.66233C7.34949 3.5647 7.34949 3.40641 7.44712 3.30878L7.45057 3.30533Z" />
  </SvgIcon>
);
