import { Event } from 'api-clients/monolith';
import { FormatDateOptions } from 'react-intl';

import { getTimezone } from 'containers/ScheduleApplicantModal/components/AddAvailabilityModal/utils';

import { FormattedEvent } from './Events';

const getTitle = ({ event }: { event: Event }): string => {
  const { title, opening_name: openingName } = event;
  const multipleOpenings = openingName.split('\n');
  const openingNameUse =
    multipleOpenings.length > 1 ? multipleOpenings[0] : openingName;

  if (title) {
    return `${title} (${openingNameUse})`;
  }
  return openingNameUse;
};

const getTime = ({ event, locale }: { event: Event; locale: string }) => {
  const startTimeDate = new Date(event.start_time);
  const endTimeDate = new Date(event.end_time);
  const timeZone = getTimezone(event.location_time_zone);
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone,
  };
  const dateOptions: FormatDateOptions = {
    month: 'numeric',
    day: 'numeric',
    year: '2-digit',
    timeZone,
  };

  const timeZoneOptions: Intl.DateTimeFormatOptions = {
    timeZone,
    timeZoneName: 'short',
  };

  const date = new Date(event.start_time);
  const formattedDate = date.toLocaleDateString(locale, dateOptions);

  const startTime = new Intl.DateTimeFormat(locale, timeOptions).format(
    startTimeDate,
  );
  const endTime = new Intl.DateTimeFormat(locale, timeOptions).format(
    endTimeDate,
  );
  const formattedTimeZone =
    date.toLocaleTimeString(locale, timeZoneOptions).split(' ')[2] ?? '';

  return `${formattedDate} | ${startTime} - ${endTime} ${formattedTimeZone}`;
};

const getHost = ({
  host,
  defaultHost,
}: {
  host?: string;
  defaultHost: string;
}) => host ?? defaultHost;

export const mapEvent = ({
  event,
  locale,
  defaultHost,
}: {
  event: Event;
  locale: string;
  defaultHost: string;
}): FormattedEvent => ({
  externalId: event.external_id,
  hostName: getHost({ host: event.host_name, defaultHost }),
  time: getTime({ event, locale }),
  title: getTitle({ event }),
  bookedCount: event.booked_slots_count,
  maxCount: event.max_attendees,
  videoUrl: event.live_video_room_url,
});
