import { Button, Grid, Hidden, Typography } from '@material-ui/core';
import classnames from 'classnames';
import React, { useEffect, useState, VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { BackToCompanySettings } from 'components/BackToCompanySettings';

import { AI_AGENT_SETUP, AI_RECOMMENDATIONS } from '../constants';
import { messages } from '../messages';
import { useStyles } from './styles';

export interface SidebarProps {
  handleSidebarMenuSelection: (module: string) => void;
}

export const fountainAiSubMenus: { label: string; value: string }[] = [
  { label: 'AI agent setup', value: AI_AGENT_SETUP },
  { label: 'AI recommendations', value: AI_RECOMMENDATIONS },
];

export const Sidebar: VFC<SidebarProps> = ({ handleSidebarMenuSelection }) => {
  const classes = useStyles();
  const [selectedMenu, setSelectedMenu] = useState<
    | {
        label: string;
        value: string;
      }
    | undefined
  >(fountainAiSubMenus[0]);

  useEffect(() => {
    const currentPath = window.location.pathname;

    const newSelectedMenu = fountainAiSubMenus.find(menu =>
      currentPath.includes(menu.value),
    );

    if (newSelectedMenu) {
      setSelectedMenu(newSelectedMenu);
    }
  }, [handleSidebarMenuSelection]);

  const handleMenuChange = (value: string) => {
    handleSidebarMenuSelection(value);
  };

  return (
    <>
      <Hidden smDown>
        <Grid item className={classes.sidebarContainer}>
          <Grid className={classes.back}>
            <BackToCompanySettings />
          </Grid>
          <Typography variant="h4" className={classes.header}>
            <FormattedMessage {...messages.header} />
          </Typography>
          <Button
            type="button"
            onClick={() => handleMenuChange(AI_AGENT_SETUP)}
            className={classnames(classes.button, {
              [classes.selectedMenu]: selectedMenu?.value === AI_AGENT_SETUP,
            })}
          >
            <Typography variant="body2" className={classes.subHeader}>
              <FormattedMessage {...messages.aiAgentSetup} />
            </Typography>
          </Button>
        </Grid>
      </Hidden>
    </>
  );
};
