/*
 *
 * SendSmsPopup reducer
 *
 */
import produce from 'immer';

import {
  DELIVER_SMS_ERROR,
  DELIVER_SMS_INIT,
  DELIVER_SMS_SUCCESS,
  FETCH_MEMBERS_INIT,
  FETCH_MEMBERS_SUCCESS,
} from './constants';

export const initialState = {
  sender_id: null,
  users: [],
};

const sendSmsPopupReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case DELIVER_SMS_INIT:
        draft.isSendingSms = true;
        break;
      case DELIVER_SMS_SUCCESS:
      case DELIVER_SMS_ERROR:
        draft.isSendingSms = false;
        break;
      case FETCH_MEMBERS_INIT:
        draft.isLoading = true;
        break;
      case FETCH_MEMBERS_SUCCESS:
        draft.users = action.users;
        draft.isLoading = false;
        break;
      default:
        break;
    }
  });

export default sendSmsPopupReducer;
