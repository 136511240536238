import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  sidebar: {
    width: theme.spacing(61.25),
    borderRight: theme.customBorders.gray200,
    display: 'flex',
    flexDirection: 'column',
  },
}));
