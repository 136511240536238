import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  advancedSettings: {
    id: `app.containers.WorkflowEditor.components.StageDetail.TechCheckStage.AdvancedSettings.advancedSettings`,
    defaultMessage: 'Advanced Settings',
  },
  allowApplicantsToAdvance: {
    id: `app.containers.WorkflowEditor.components.StageDetail.TechCheckStage.AdvancedSettings.allowApplicantsToAdvance`,
    defaultMessage:
      'Allow applicants to self advance after Tech Check has been performed',
  },
  showStageInPortal: {
    id: `app.containers.WorkflowEditor.components.StageDetail.TechCheckStage.AdvancedSettings.showStageInPortal`,
    defaultMessage: 'Show stage in applicant portal progress tracker',
  },
});
