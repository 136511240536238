import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  navBar: {
    padding: theme.spacing(2, 3),
    gap: theme.spacing(3),
    width: '100%',
    display: 'flex',
    borderBottom: theme.customBorders.gray200,
    height: theme.spacing(6.25),
  },
  text: {
    fontSize: theme.typography.h4.fontSize,
    lineHeight: theme.typography.h4.lineHeight,
    color: theme.palette.common.gray600,
  },
  marginLeftAuto: {
    marginLeft: 'auto',
  },
  dropdownArrow: {
    fill: theme.palette.common.gray600,
    marginLeft: theme.spacing(1),
  },
  selected: {
    padding: theme.spacing(0, 1),
    borderBottom: `${theme.spacing(0.5)}px solid ${
      theme.palette.common.blue400
    }`,
    marginBottom: theme.spacing(-2),
  },
  openingNameButton: {
    margin: theme.spacing(-1, 0, 0, -1),
  },
  openingNameText: {
    color: theme.palette.common.blue400,
  },
  jobSearchMenuClassName: {
    marginTop: theme.spacing(4.625),
  },
  selectedText: {
    fontFamily: theme.typography.h4.fontFamily,
  },
}));
