import { Grid, Typography } from '@material-ui/core';
import React, { SetStateAction, useState, VFC } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';

import { BackToCompanySettings } from 'components/BackToCompanySettings';

import { ChatbotLog } from './ChatbotLog';
import { Filters, SelectedFilters } from './Filters';
import { LoadMore } from './LoadMore';
import { messages } from './messages';
import { useStyles } from './styles';

import type { BotType } from 'api-clients/monolith';

interface ChatbotFeedbackProps {
  botType?: BotType;
  selectedFilters: SelectedFilters;
  setSelectedFilters: React.Dispatch<SetStateAction<SelectedFilters>>;
}

export const ChatbotFeedback: VFC<ChatbotFeedbackProps> = ({
  botType,
  selectedFilters,
  setSelectedFilters,
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [isRefetch, setIsRefetch] = useState(false);
  const [page, setPage] = useState(1);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage(messages.chatbotFeedback)}</title>
        <meta name="description" content="Chatbot Review" />
      </Helmet>
      <Grid
        item
        container
        direction="column"
        wrap="nowrap"
        className={styles.container}
      >
        <Grid item className={styles.header}>
          <BackToCompanySettings />
          <Typography display="inline" variant="h2">
            <FormattedMessage {...messages.chatbotFeedback} />
          </Typography>
        </Grid>
        <Filters
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          setShowLoadMore={setShowLoadMore}
          botType={botType}
          setPage={setPage}
        />
        <LoadMore
          showLoadMore={showLoadMore}
          setIsRefetch={setIsRefetch}
          setSelectedFilters={setSelectedFilters}
          setShowLoadMore={setShowLoadMore}
        />
        <ChatbotLog
          botType={botType}
          selectedStatus={selectedFilters.status}
          selectedModels={selectedFilters.models}
          selectedIntents={selectedFilters.intents}
          isRefetch={isRefetch}
          setIsRefetch={setIsRefetch}
          setShowLoadMore={setShowLoadMore}
          page={page}
          setPage={setPage}
          sortBy={selectedFilters.sortBy}
        />
      </Grid>
    </>
  );
};
