import { Button } from '@fountain/fountain-ui-components';
import {
  Box,
  CircularProgress,
  FormControl,
  Typography,
} from '@material-ui/core';
import { ElementDataSelectOption } from 'api-clients/monolith';
import React, { useCallback, useEffect, useRef, useState, VFC } from 'react';
import Dropzone, { formatBytes, IFileWithMeta } from 'react-dropzone-uploader';
import { classNames } from 'react-extras';
import { FormattedMessage, useIntl } from 'react-intl';

import { LoadingButton } from 'components/LoadingButton';

import { FilePreview } from './FilePreview';
import UploadIcon from './icons/upload-file.svg';
import { messages } from './messages';
import { dropzoneStyles, useStyles } from './styles';

interface ErrorDetails {
  message: JSX.Element;
  type: string;
}

interface ErrorMessagesMap {
  [key: string]: ErrorDetails;
}

const ERROR_MESSAGES: ErrorMessagesMap = {
  error_required: {
    message: <FormattedMessage {...messages.required} />,
    type: 'required',
  },
  error_file_size: {
    message: <FormattedMessage {...messages.tooLarge} />,
    type: 'size',
  },
  error_upload: {
    message: <FormattedMessage {...messages.failed} />,
    type: 'upload',
  },
  exception_upload: {
    message: <FormattedMessage {...messages.failed} />,
    type: 'upload',
  },
  error_file_type: {
    message: <FormattedMessage {...messages.validImage} />,
    type: 'type',
  },
};

interface InputContentProps {
  maxSizeBytes: number;
  active: boolean;
}

const InputContent: VFC<InputContentProps> = ({ maxSizeBytes, active }) => {
  const styles = useStyles();
  const intl = useIntl();

  return (
    <FormControl
      className={classNames(styles.uploadInputContainer, {
        [styles.active]: active,
      })}
    >
      <Button
        component="span"
        type="secondary"
        variant="outlined"
        startIcon={
          <img
            alt={intl.formatMessage(messages.uploadIcon)}
            className={styles.iconDark}
            src={UploadIcon}
          />
        }
      >
        <FormattedMessage {...messages.inputButton} />
      </Button>
      <Typography variant="body2" className={styles.fileSize}>
        <FormattedMessage {...messages.maxSize} /> {formatBytes(maxSizeBytes)}
      </Typography>
    </FormControl>
  );
};

export interface FileUploadProps {
  handleUpload: (file: File) => void;
  handleSubmit: () => void;
  isUploading: boolean;
  isUploadSuccess: boolean;
  isUploadFailed: boolean;
  uploadedFileData: ElementDataSelectOption | undefined;
  validType?: string;
}

export const FileUpload: VFC<FileUploadProps> = ({
  handleUpload,
  handleSubmit,
  isUploading,
  isUploadSuccess,
  isUploadFailed,
  uploadedFileData,
  validType,
}) => {
  const styles = useStyles();
  const dropzoneWrapper = useRef<HTMLDivElement>(null);
  const [selectedFile, setSelectedFile] = useState<IFileWithMeta | undefined>(
    undefined,
  );
  const [displayLoading, setDisplayLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<ErrorDetails>();
  const [isBlurry, setIsBlurry] = useState(false);

  useEffect(() => {
    if (isUploadFailed) {
      setErrorMessage(ERROR_MESSAGES.upload_error);
    }
  }, [isUploadFailed]);

  const checkErrorDisplay = (): boolean => {
    const fileTypeError = errorMessage?.type === 'type';
    const uploadFailed = errorMessage?.type === 'upload';
    const notSizeError = errorMessage?.type !== 'size';
    const notInErrorState = !errorMessage || displayLoading;

    if (fileTypeError || uploadFailed) {
      return true;
    }
    if (notSizeError && notInErrorState) {
      return false;
    }
    return true;
  };

  const handleChange: (
    file: IFileWithMeta | undefined,
    status: string,
  ) => void = (file, status) => {
    let loading = false;

    switch (status) {
      case 'preparing':
        loading = true;
        selectedFile?.remove();
        setIsBlurry(false);
        setSelectedFile(undefined);
        setErrorMessage(undefined);
        break;
      case 'getting_upload_params':
      case 'uploading':
      case 'headers_received':
        loading = true;
        break;
      case 'done':
        if (file) {
          const invalidFileTypes = ['html', 'x-msdownload'];
          const isInvalidType = validType
            ? file.meta.type !== validType
            : invalidFileTypes.some(
                type => file.meta.type.includes(type) || file.meta.type === '',
              );

          if (isInvalidType) {
            setSelectedFile(undefined);
            file?.remove();
            setErrorMessage(ERROR_MESSAGES.error_file_type);
            break;
          }

          setSelectedFile(file);
          handleUpload(file.file);

          setErrorMessage(undefined);
        }
        break;
      default:
        break;
    }

    if (
      status.split('_')[0] === 'error' ||
      status.split('_')[0] === 'exception'
    ) {
      setSelectedFile(undefined);
      file?.remove();
      setErrorMessage(ERROR_MESSAGES[status]);
    }

    setDisplayLoading(loading);
  };

  const removeSelected = useCallback(() => {
    selectedFile?.remove();
    setSelectedFile(undefined);
    setIsBlurry(false);
  }, [selectedFile]);

  const UploadingState = () => {
    return (
      <Box className={styles.loadingContainer}>
        <CircularProgress className={styles.circularProgress} size={25} />
        <Typography variant="body2">
          <FormattedMessage {...messages.indicator} />
        </Typography>
      </Box>
    );
  };

  return (
    <div ref={dropzoneWrapper} className={styles.uploadWrapper}>
      {!selectedFile && uploadedFileData ? (
        `Uploaded file: ${uploadedFileData.value}`
      ) : (
        <Dropzone
          styles={dropzoneStyles}
          onChangeStatus={handleChange}
          maxSizeBytes={15728640}
          multiple={false}
          inputContent={(_inputProps, dropzoneProps) =>
            selectedFile === undefined && (
              <InputContent key="inputContent" {...dropzoneProps} />
            )
          }
          inputWithFilesContent={null}
          PreviewComponent={
            selectedFile
              ? ({ meta }) => (
                  <FilePreview
                    type={meta.type}
                    url={meta.previewUrl ?? ''}
                    name={meta.name}
                    size={meta.size}
                  />
                )
              : undefined
          }
        />
      )}
      {checkErrorDisplay() && (
        <Typography className={styles.error}>
          {errorMessage?.message}
        </Typography>
      )}
      {isBlurry && !displayLoading && (
        <Typography className={styles.error}>
          <FormattedMessage {...messages.blurry} />
        </Typography>
      )}
      {!selectedFile && displayLoading && <UploadingState />}
      {selectedFile && (
        <div className={styles.actionContainer}>
          <Button
            className={styles.actionButton}
            type="secondary"
            variant="outlined"
            onClick={removeSelected}
            disabled={isUploadSuccess}
          >
            <FormattedMessage {...messages.remove} />
          </Button>
          {isUploading && (
            <LoadingButton
              className={styles.actionButton}
              variant="contained"
            />
          )}
          {!isUploading && (
            <Button
              className={styles.actionButton}
              variant="contained"
              onClick={handleSubmit}
              disabled={isUploadSuccess}
            >
              <FormattedMessage {...messages.submit} />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
