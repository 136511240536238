import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  bulkUploadButton: {
    width: 'unset',
    boxShadow: 'none',
    marginLeft: theme.spacing(1),
    '&:first-child': {
      marginLeft: 0,
    },
  },
}));
