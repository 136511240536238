import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { SharedActionProps } from 'containers/WorkflowEditor/components/Rules/types';

import { DataKeySelect } from '../components/DataKeySelect';
import { messages } from './messages';

export const SwitchApplicantData: FC<SharedActionProps> = React.memo(props => {
  const intl = useIntl();

  return (
    <>
      <DataKeySelect
        {...props}
        label={intl.formatMessage(messages.fromDataKey)}
      />
      <DataKeySelect
        {...props}
        label={intl.formatMessage(messages.toDataKey)}
        isSwitchKey
      />
    </>
  );
});
