import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { VFC } from 'react';

export const QuestionMarkIcon: VFC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 46 46">
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="46" height="46" rx="23" fill="#5E44FF" />
      <g clipPath="url(#clip0_802_2046)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23 32C27.9706 32 32 27.9706 32 23C32 18.0294 27.9706 14 23 14C18.0294 14 14 18.0294 14 23C14 27.9706 18.0294 32 23 32ZM23.9578 24.0561C23.7751 24.3289 23.7278 24.6386 23.7163 24.9654C23.7079 25.2088 23.5046 25.4038 23.2534 25.4038H22.2936C22.0364 25.4038 21.8267 25.2006 21.8285 24.9484C21.832 24.4829 21.8712 23.8898 22.1796 23.3369C22.4136 22.9166 22.8191 22.4905 23.3851 22.057C23.9258 21.6377 24.3021 21.3254 24.5481 21.0139C24.7892 20.7086 24.9051 20.4037 24.9168 19.9946C24.939 19.249 24.2974 18.633 23.5276 18.609C23.1658 18.5976 22.813 18.5962 22.3844 18.6718C21.9555 18.7474 21.4459 18.9009 20.7753 19.204C20.5459 19.3076 20.2667 19.2187 20.1561 18.9905L19.7453 18.1453C19.6384 17.9253 19.7258 17.6502 19.9538 17.5378C21.0203 17.0122 22.1554 16.75 23.3585 16.75C24.4746 16.75 25.3744 17.0183 26.0454 17.5681C26.7197 18.1206 27.0528 18.8827 27.0528 19.8403C27.0528 20.6459 26.8282 21.2948 26.2828 21.9323C25.9645 22.3049 25.5237 22.6534 25.108 22.9821L25.0224 23.0499C24.5748 23.4045 24.1735 23.7328 23.9578 24.0561ZM22.8 29.25C21.97 29.25 21.3 28.6034 21.3 27.8077C21.3 27.012 21.97 26.3654 22.8 26.3654C23.6299 26.3654 24.3 27.012 24.3 27.8077C24.3 28.6034 23.6299 29.25 22.8 29.25Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_802_2046">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(14 14)"
          />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
