import { CustomTooltip as Tooltip } from '@fountain/fountain-ui-components';
import {
  Box,
  Button,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import { useSimpleToggle } from 'hooks';
import React, { useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { messages } from 'containers/Webhooks/messages';
import { useStyles } from 'containers/Webhooks/styles';
import CloseIcon from 'images/close.svg';
import Ellipse from 'images/Ellipse.svg';

export interface EmailAddressProps {
  emails: string[];
  handleUpdate: (emails: string[]) => void;
}

export const EmailAddress: VFC<EmailAddressProps> = ({
  emails,
  handleUpdate,
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const [newEmail, setNewEmail] = useState<string>();
  const [focused, setFocused] = useState(false);
  const { on, off, showContent } = useSimpleToggle();
  const tooltipTitle = emails.map(email => (
    <Typography key={`tooltip--${email}`}>{email}</Typography>
  ));
  const showBox = emails.length > 2;
  const ellipseNames = ['ellipseOne', 'ellipseTwo', 'ellipseThree'] as const;

  const blur = () => {
    if (newEmail) {
      handleUpdate([...emails, newEmail]);
    }
    setNewEmail('');
    setFocused(false);
  };

  const focus = () => {
    off();
    setFocused(true);
  };

  const clickOn = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    on();
  };

  const buttons =
    ((focused && emails.length > 1) || showBox) && !showContent ? (
      <Tooltip dense title={tooltipTitle}>
        <Button className={styles.emailPill} onClick={clickOn}>
          {intl.formatMessage(messages.emailCount, { count: emails.length })}
          {ellipseNames.map(name => (
            <img
              src={Ellipse}
              className={
                name === 'ellipseOne'
                  ? styles.ellipseIcon
                  : styles.ellipseNarrowIcon
              }
              alt={intl.formatMessage(messages.showAllEmails)}
              key={name}
            />
          ))}
        </Button>
      </Tooltip>
    ) : (
      <Box className={showBox ? styles.emailBox : styles.emailLine}>
        {emails.map(email => (
          <Button
            className={styles.emailPill}
            onClick={e => {
              e.preventDefault();
              handleUpdate([
                ...emails.filter(filtEmail => filtEmail !== email),
              ]);
            }}
            key={`button--${email}`}
          >
            {email}
            <img
              src={CloseIcon}
              className={styles.deleteIcon}
              alt={intl.formatMessage(messages.deleteEmail)}
            />
          </Button>
        ))}
      </Box>
    );

  return (
    <FormControlLabel
      className={styles.emailLabel}
      label={
        <Typography display="inline" variant="body2">
          <FormattedMessage {...messages.emailAddress} />
        </Typography>
      }
      labelPlacement="top"
      control={
        <OutlinedInput
          value={(focused && newEmail) || ''}
          onChange={e => setNewEmail(e.target.value)}
          fullWidth
          multiline={showContent}
          onFocus={focus}
          onBlur={blur}
          margin="dense"
          startAdornment={
            <InputAdornment position="start">{buttons}</InputAdornment>
          }
        />
      }
    />
  );
};
