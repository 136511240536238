import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'app.components.WxExportApplicantModal.title',
    defaultMessage: 'Export applicants as workers to WX',
  },
  tooltip: {
    id: 'app.components.WxExportApplicantModal.tooltip',
    defaultMessage:
      'Export the applicants as a workers to WX. New applicants will create new workers, while exporting existing workers will update their data.',
  },
  send: {
    id: 'app.components.WxExportApplicantModal.sendWebhook',
    defaultMessage: 'Send to WX',
  },
  submissionSuccess: {
    id: 'app.components.WxExportApplicantModal.submissionSuccess',
    defaultMessage: 'Successfully submitted applicants to be exported to WX',
  },
  submissionError: {
    id: 'app.components.WxExportApplicantModal.submissionError',
    defaultMessage:
      'An error occurred while submitting applicants to be exported to WX',
  },
});
