import { Button, Menu, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { classNames } from 'react-extras';
import { FormattedMessage } from 'react-intl';

import ArrowDownIcon from 'images/arrow-down-black.svg';

import AnalyticsNavLink from '../AnalyticsNavLink';
import makeNavMenuStyles from '../NavigationMenu/styles';
import PosthireNavLink from '../PosthireNavLink';
import SourcingNavLink from '../SourcingNavLink';
import messages from './messages';

function MoreMenu({ viewAnalytics, viewPosthire, viewSourcing }) {
  const navMenuClasses = makeNavMenuStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const openMoreMenu = e => {
    setAnchorEl(e.currentTarget);
  };
  const closeMoreMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="more-nav-button"
        aria-controls={open ? 'more-nav-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        className={navMenuClasses.topMenu}
        onClick={openMoreMenu}
      >
        <Typography
          id="more-nav-button"
          aria-controls={open ? 'more-nav-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={openMoreMenu}
          className={classNames(
            navMenuClasses.leftSectionTrigger,
            navMenuClasses.menuItem,
          )}
          variant="body2"
        >
          <FormattedMessage {...messages.more} />
        </Typography>
        <img alt="arrow" src={ArrowDownIcon} />
      </Button>
      <Menu
        id="more-nav-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        onClose={closeMoreMenu}
        variant="menu"
      >
        {viewSourcing && <SourcingNavLink menuItem />}
        {viewAnalytics && (
          <AnalyticsNavLink menuItem permission={viewAnalytics} />
        )}
        {viewPosthire && <PosthireNavLink menuItem permission={viewPosthire} />}
      </Menu>
    </>
  );
}

MoreMenu.propTypes = {
  viewSourcing: PropTypes.bool.isRequired,
  viewAnalytics: PropTypes.bool.isRequired,
  viewPosthire: PropTypes.bool.isRequired,
};

export default MoreMenu;
