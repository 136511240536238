/*
 *
 * GlobalPopup constants
 *
 */

/* action constants */

export const SET_SELECTED_POPUP = 'app/GlobalPopup/SET_SELECTED_POPUP';
export const CLOSE_POPUP = 'app/GlobalPopup/CLOSE_POPUP';
export const SET_PERSISTED_POPUP = 'app/GlobalPopup/SET_PERSISTED_POPUP';
export const CLOSE_PERSISTED_POPUP = 'app/GlobalPopup/CLOSE_PERSISTED_POPUP';

/* etc */

export const POPUP_TYPES = {
  APPLICANT_DRAWER_POPUP: 'applicantDrawerPopup',
  APPLICANT_BULK_UPLOAD_POPUP: 'applicantBulkUploadPopup',
  BULK_ACTIONS_UPLOAD: 'bulkActionsUploadModal',
  DELETE_APPLICANT_MODAL: 'deleteApplicantModal',
  REJECT_APPLICANT_POPUP: 'rejectApplicantPopup',
  SEND_EMAIL_POPUP: 'sendEmailPopup',
  SEND_BULK_EMAIL_POPUP: 'sendBulkEmailPopup',
  SEND_SMS_POPUP: 'sendSmsPopup',
  SEND_BULK_SMS_POPUP: 'sendBulkSmsPopup',
  UNSUBSCRIBE_MODAL: 'unsubscribeModal',
  UPLOAD_FILE_MODAL: 'uploadFileModal',
  MOVE_APPLICANT_POPUP: 'moveApplicantPopup',
  APPLICANTS_FILTER: 'applicantsFilter',
  MOVE_APPLICANT_TO_JOB_POPUP: 'moveApplicantToJobPopup',
  REJECT_APPLICANT_MODAL: 'rejectApplicantModal',
  HIRE_APPLICANT_MODAL: 'hireApplicantModal',
  FILE_RECOLLECTION: 'fileRecollectionDrawer',
  FILE_RECOLLECTION_MESSENGER: 'fileRecollectionMessenger',
  JOBS_STAGES_FILTER_POPUP: 'jobsStagesFilterPopup',
  SCHEDULE_APPLICANT: 'scheduleApplicant',
  CANCEL_SCHEDULE_APPLICANT: 'cancelScheduleApplicant',
  CUSTOMIZABLE_COLUMNS_DRAWER: 'customizableColumnsDrawer',
  VIDEO_RECORDING_POPOVER: 'openVideoRecordingPopover',
  ON_HOLD_APPLICANT_MODAL: 'onHoldApplicantModal',
  DATA_VALIDATION_MODAL: 'dataValidationModal',
  REVIEW_ASSESSMENT_MODAL: 'openReviewAssessmentModal',
  WX_EXPORT_APPLICANT_MODAL: 'wxExportApplicantModal',
  IMMEDIATE_HIRING_DECISION: 'immediateHiringDecisionModal',
  EDIT_PARTNER_OPTION_DATA_MODAL: 'editPartnerOptionDataModal',
};
