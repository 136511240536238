import { createSelector } from 'reselect';

import { REDUCER_KEY } from './constants';
import { initialState } from './reducer';

const selectState = state => state[REDUCER_KEY] || initialState;

const makeSelectTemplateMenuState = () =>
  createSelector(selectState, substate => substate);

export default makeSelectTemplateMenuState;
