import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  field: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0),
    },
  },
  fieldContainer: {
    width: '100%',
  },
  input: {
    flex: 1,
    minWidth: 0,
  },
  iconRoot: {
    cursor: 'pointer',
    width: theme.spacing(4.5),
    padding: theme.spacing(1.25),
  },
  textContent: {
    wordWrap: 'break-word',
    wordBreak: 'break-all',
  },
  errorText: {
    float: 'right',
    color: theme.palette.common.red400,
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
    fontWeight: 400,
  },
}));

export default useStyles;
