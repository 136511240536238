import { FIELD_TYPES, STATES } from 'utils/constants';

interface Option {
  label: string;
  value: string;
}

interface ConsumerField {
  name: string;
  label: string;
  type: string;
  options?: Array<Option>;
}

export const CONSUMER_FIELDS: Array<ConsumerField> = [
  {
    name: 'first_name',
    label: 'First name',
    type: FIELD_TYPES.INPUT,
  },
  {
    name: 'last_name',
    label: 'Last name',
    type: FIELD_TYPES.INPUT,
  },
  {
    name: 'email',
    label: 'Email',
    type: FIELD_TYPES.INPUT,
  },
  {
    name: 'phone_number',
    label: 'Phone',
    type: FIELD_TYPES.INPUT,
  },
  {
    name: 'address_1',
    label: 'Address 1',
    type: FIELD_TYPES.INPUT,
  },
  {
    name: 'address_2',
    label: 'Address 2',
    type: FIELD_TYPES.INPUT,
  },
  {
    name: 'city',
    label: 'City',
    type: FIELD_TYPES.INPUT,
  },
  {
    name: 'state',
    label: 'State',
    type: 'dropdown',
    options: Object.keys(STATES).map(key => ({
      label: key,
      // @ts-expect-error This will go away once we convert STATES to Typescript
      value: STATES[key] as string,
    })),
  },
  {
    name: 'zipcode',
    label: 'Zip code',
    type: FIELD_TYPES.INPUT,
  },
];
