import { Button, IconButton } from '@fountain/fountain-ui-components';
import { Box, Grid, Link, Typography } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { EventsService } from 'api-clients/monolith';
import { ManageRoster } from 'Calendar/EventManager/ManageRoster';
import cx from 'classnames';
import { useSimpleToggle } from 'hooks';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { makeSelectWhoami } from 'containers/Auth_old/selectors';
import { useApiService } from 'hooks/useApiService';
import ExternalLinkIcon from 'images/external-link-icon.svg';
import ExpandIcon from 'images/icon-caret-right.svg';

import { DropdownMenu } from './DropdownMenu';
import { messages } from './messages';
import { useStyles } from './styles';

interface CardProps {
  title: string;
  time: string;
  hostName: string;
  bookedCount: string;
  maxCount: string;
  toggleDropdown: (event: React.MouseEvent<HTMLElement>) => void;
  externalId: string;
  videoUrl?: string;
  canManage: boolean;
}

const Card: FC<CardProps> = ({
  title,
  time,
  hostName,
  toggleDropdown,
  maxCount,
  bookedCount,
  externalId,
  videoUrl,
  canManage,
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const { toggle, showContent } = useSimpleToggle();
  const { account_name: accountSlug } = useSelector(makeSelectWhoami());

  const editAvailabilityUrl = `/${accountSlug}/calendar/${externalId}/edit`;

  const getEventService = useCallback(
    () => EventsService.getInternalApiEvents1(externalId),
    [externalId],
  );

  const { result } = useApiService(getEventService);
  const [showEditAvailability, setShowEditAvailability] = useState(false);
  useEffect(() => {
    if (result.status === 'ready') {
      const { start_time: startTime, v2_event: v2Event } = result.data;
      const startTimeInMS = new Date(startTime).getTime();
      const now = Date.now();
      if (v2Event && startTimeInMS > now) {
        setShowEditAvailability(true);
      }
    }
  }, [result]);

  return (
    <div className={styles.card}>
      <div className={styles.textWrapper}>
        <Typography variant="h3" className={styles.cardHeader}>
          {title}
        </Typography>
        <Typography color="textPrimary" variant="h4">
          {time}
        </Typography>
        <Typography variant="body2">
          <FormattedMessage {...messages.hostMessage} values={{ hostName }} />
          {videoUrl && (
            <span>
              <img
                src={ExternalLinkIcon}
                alt="external link icon"
                className={styles.videoLinkIcon}
              />
              <Link
                href={videoUrl}
                target="_blank"
                className={styles.videoLink}
              >
                <FormattedMessage {...messages.videoLink} />
              </Link>
            </span>
          )}
        </Typography>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Typography variant="body2">
              <IconButton className={styles.caretButton} onClick={toggle}>
                <img
                  alt={intl.formatMessage(messages.manageRoster)}
                  className={cx({ [styles.caretIconCollapsed]: showContent })}
                  src={ExpandIcon}
                />
              </IconButton>
              <Button
                type="text"
                onClick={toggle}
                disableUnderline
                className={styles.noUnderline}
              >
                {intl.formatMessage(messages.bookingCounts, {
                  bookedCount,
                  maxCount,
                })}
              </Button>
            </Typography>
          </Grid>
          {showEditAvailability && (
            <Grid item sm={6} xs={12}>
              <Box className={styles.textRightBox}>
                <Link href={editAvailabilityUrl}>
                  <FormattedMessage {...messages.editAvailability} />
                </Link>
              </Box>
            </Grid>
          )}
        </Grid>

        {showContent && <ManageRoster eventExternalId={externalId} />}
      </div>
      {canManage && (
        <IconButton
          onClick={toggleDropdown}
          aria-label={intl.formatMessage(messages.manageEvent)}
        >
          <MoreHoriz />
        </IconButton>
      )}
    </div>
  );
};

export interface EventCardProps {
  title: string;
  time: string;
  hostName: string;
  bookedCount: string;
  maxCount: string;
  externalId: string;
  videoUrl?: string;
  duplicateEvent: (externalId: string) => void;
  deleteEvent: (externalId: string) => void;
  canManage: boolean;
}

export const EventCard: FC<EventCardProps> = ({
  title,
  time,
  hostName,
  bookedCount,
  maxCount,
  externalId,
  videoUrl,
  duplicateEvent,
  deleteEvent,
  canManage,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const toggleDropdown = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Card
        toggleDropdown={toggleDropdown}
        title={title}
        time={time}
        bookedCount={bookedCount}
        maxCount={maxCount}
        hostName={hostName}
        externalId={externalId}
        canManage={canManage}
        videoUrl={videoUrl}
      />
      {Boolean(anchorEl) && (
        <DropdownMenu
          deleteEvent={deleteEvent}
          duplicateEvent={duplicateEvent}
          externalId={externalId}
          anchorEl={anchorEl}
          handleClose={handleClose}
        />
      )}
    </div>
  );
};
