import { IconButton } from '@fountain/fountain-ui-components';
import React, { FC } from 'react';

import CustomDropzone from 'components/CustomDropzone';
import { FileFieldTitle } from 'containers/DataValidationModal/components/FileField/FileFieldTitle';
import useStyles from 'containers/DataValidationModal/components/FileField/styles';
import { SharedFieldProps } from 'containers/DataValidationModal/components/types';
import CloseIcon from 'images/applicant_detail/cancel.svg';

export interface UploadProps {
  detail: SharedFieldProps['detail'];
  error?: SharedFieldProps['error'];
  handleUpload: (file: File) => void;
  isEditable?: boolean;
  isRequired?: boolean;
  isMobile?: boolean;
  setIsEditable: (value: boolean) => void;
}

export const Upload: FC<UploadProps> = ({
  detail,
  error,
  handleUpload,
  isEditable,
  isMobile,
  isRequired,
  setIsEditable,
}) => {
  const styles = useStyles();
  return (
    <>
      <FileFieldTitle
        isRequired={isRequired}
        subtitle={detail.subtitle}
        title={detail.title}
      >
        <IconButton
          className={isEditable ? styles.editCancel : styles.noEditCancel}
          type="button"
          onClick={() => setIsEditable(false)}
        >
          <img className={styles.editIcon} src={CloseIcon} alt="cancel-icon" />
        </IconButton>
      </FileFieldTitle>
      <CustomDropzone
        isMobile={isMobile}
        error={error}
        onChangeStatus={handleUpload}
      />
    </>
  );
};
