import { defineMessages } from 'react-intl';

export const scope = 'app.components.SendSmsPopup';

export default defineMessages({
  defaultTemplate: {
    id: `${scope}.defaultTemplate`,
    defaultMessage: 'No Template selected - Write a new message',
  },
  delaySendingMessage: {
    id: `${scope}.delaySendingMessage`,
    defaultMessage: 'Delay sending message',
  },
  enterYourMessage: {
    id: `${scope}.enterYourMessage`,
    defaultMessage: 'Enter your message',
  },
  sendFrom: {
    id: `${scope}.sendFrom`,
    defaultMessage: 'Send From',
  },
  smsInfo: {
    id: `${scope}.smsInfo`,
    defaultMessage:
      'This message will be broken into 3 segments. Messages may be queued to prevent carriers from blocking.',
  },
  smsScheduled: {
    id: `${scope}.smsScheduled`,
    defaultMessage: 'SMS has been scheduled!',
  },
  sendBulkSms: {
    id: `${scope}.sendBulkSms`,
    defaultMessage: 'Send {applicantCount} SMS',
  },
  sendBulkSmslSelectAllDefault: {
    id: `${scope}.sendBulkSmslSelectAllDefault`,
    defaultMessage: 'Send SMS',
  },
  scheduleMessages: {
    id: `${scope}.scheduleMessages`,
    defaultMessage: 'Schedule Messages',
  },
  sendBulkMessages: {
    id: `${scope}.sendBulkMessages`,
    defaultMessage: 'Bulk send {applicantCount} messages',
  },
  sendBulkMessageslSelectAllDefault: {
    id: `${scope}.sendBulkMessageslSelectAllDefault`,
    defaultMessage: 'Bulk send messages',
  },
  selectWhatsappTemplate: {
    id: `${scope}.selectWhatsappTemplate`,
    defaultMessage: 'Select template',
  },
  noTemplateAvailable: {
    id: `${scope}.noTemplateAvailable`,
    defaultMessage: 'No template available',
  },
  smsTextRequired: {
    id: `${scope}.smsTextRequired`,
    defaultMessage: 'Sms text is required',
  },
  whatsappTemplateRequired: {
    id: `${scope}.whatsappTemplateRequired`,
    defaultMessage: 'WhatsApp template is required',
  },
  smsMessage: {
    id: `${scope}.smsMessage`,
    defaultMessage: 'SMS Message',
  },
  whatsappMessage: {
    id: `${scope}.whatsappMessage`,
    defaultMessage: 'WhatsApp Message',
  },
  selectChannel: {
    id: `${scope}.selectChannel`,
    defaultMessage: 'Select Channel',
  },
});
