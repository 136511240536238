import { useState } from 'react';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import { apiGet } from 'utils/axios';

export default function useUnreadMessageThreadCount() {
  const [data, setData] = useState(0);

  const getUnreadMessageThreadCount = async () => {
    const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/messenger/user_unread_messaging_thread_count`;

    try {
      const res = await apiGet(url);
      if (res.data) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setData(res.data);
      }
    } catch (e) {
      window.console.error(e);
    }
  };

  return { getUnreadMessageThreadCount, data };
}
