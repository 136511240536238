import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  sidebarContainer: {
    minWidth: '250px',
    borderRight: theme.customBorders.gray200,
    padding: theme.spacing(3),
    height: 'calc(100vh - 52px)',
    overflow: 'auto',
    gap: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  subHeader: {
    cursor: 'pointer',
    paddingLeft: theme.spacing(3),
    lineHeight: '40px',
    fontSize: theme.typography.fontSize,
  },
  header: {
    paddingLeft: theme.spacing(2),
    lineHeight: '40px',
    fontSize: theme.typography.h3.fontSize,
    color: theme.palette.common.gray800,
  },
  selectedMenu: {
    backgroundColor: theme.palette.common.blue50,

    '& p': {
      color: theme.palette.common.blue400,
    },
  },
  button: {
    padding: 0,
    justifyContent: 'flex-start',
  },
  back: {
    margin: 0,
    paddingLeft: theme.spacing(2),
    lineHeight: '40px',
    '& > div': {
      margin: 0,
    },
  },
}));
