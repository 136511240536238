import { Loader } from '@fountain/fountain-ui-components';
import {
  JobMatcherConditionSet,
  WorkflowJobMatcherStage,
} from 'api-clients/monolith';
import { produce } from 'immer';
import React, { useContext, VFC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addMessageAction } from 'containers/FlashMessage/actions';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';
import { useJobRouterConditionOptions } from 'containers/WorkflowEditor/hooks/useJobRouterConditionOptions';
import { messages as globalMessages } from 'shared/global/messages';

import { generateDefaultOperand } from './index.fixtures';
import { JobRouterConditionSet } from './JobRouterConditionSet';
import {
  ConditionType,
  JobRouterConditionRow,
} from './JobRouterConditionSet/JobRouterCondition';
import { messages } from './messages';

const DEFAULT_CONDITIONS_SET: JobMatcherConditionSet = {
  bool_operator: 'AND',
  operands: [generateDefaultOperand()],
};
export interface JobRouterConditionsProps {
  conditions: JobRouterConditionRow[];
  stage: WorkflowJobMatcherStage;
}

export const JobRouterConditions: VFC<JobRouterConditionsProps> = ({
  conditions,
  stage,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { result } = useJobRouterConditionOptions();
  const { setStage } = useContext(StageContext);

  React.useEffect(() => {
    if (result.isError) {
      dispatch(
        addMessageAction(
          intl.formatMessage(globalMessages.apiError),
          'error',
          false,
        ),
      );
    }
  }, [dispatch, intl, result.isError]);

  if (result.isError) {
    return null;
  }

  if (result.status === 'loading') {
    return <Loader size="2rem" />;
  }

  const existingConditionTypes = conditions.map(
    condition => condition.resource,
  );

  let conditionTypeOptions: ConditionType[] = [
    {
      label: intl.formatMessage(messages.positionIs),
      value: 'Position',
      isDisabled: existingConditionTypes.includes('Position'),
    },
    {
      label: intl.formatMessage(messages.locationIs),
      value: 'Location',
      isDisabled: existingConditionTypes.includes('Location'),
    },
    {
      label: intl.formatMessage(messages.locationGroupIs),
      value: 'LocationGroup',
      isDisabled: existingConditionTypes.includes('LocationGroup'),
    },
  ];

  if (stage.type === 'JobSwitcherStage') {
    conditionTypeOptions = [
      ...conditionTypeOptions,
      {
        label: intl.formatMessage(messages.workflowIs),
        value: 'Workflow',
        isDisabled: existingConditionTypes.includes('Workflow'),
      },
    ];
  }

  const onAddCondition = () => {
    if (!stage.additional_info.conditions.operands?.length) {
      setStage(
        produce(stage, draft => {
          draft.additional_info.conditions = DEFAULT_CONDITIONS_SET;
        }),
      );
    } else {
      setStage(
        produce(stage, draft => {
          if (!draft.additional_info.conditions.operands) {
            return;
          }
          draft.additional_info.conditions.operands.push(
            generateDefaultOperand(),
          );
        }),
      );
    }
  };

  const onDeleteCondition = (id: string) => () => {
    setStage(
      produce(stage, draft => {
        const { operands } = draft.additional_info.conditions;
        if (!operands) {
          return;
        }
        const newOperands = operands.filter(operand => operand.id !== id);

        if (newOperands.length === 0) {
          draft.additional_info.conditions = {};
        } else {
          draft.additional_info.conditions.operands = newOperands;
        }
      }),
    );
  };

  const onChangeConditionType =
    (id: string) => (type: ConditionType['value']) => {
      setStage(
        produce(stage, draft => {
          const conditionToUpdate =
            draft.additional_info.conditions.operands?.find(
              operand => operand.id === id,
            );
          if (!conditionToUpdate) {
            return;
          }

          conditionToUpdate.resource = type;
          conditionToUpdate.resource_ids = [];
        }),
      );
    };

  // `values` can be `null` when the "clear" button is invoked
  const onChangeConditionValue =
    (id: string) =>
    (values: Array<{ label: string; value: number }> | null) => {
      setStage(
        produce(stage, draft => {
          const conditionToUpdate =
            draft.additional_info.conditions.operands?.find(
              operand => operand.id === id,
            );
          if (!conditionToUpdate) {
            return;
          }

          const newIds = (values ?? []).map(({ value }) => value);

          conditionToUpdate.resource_ids = newIds;
        }),
      );
    };

  return (
    <JobRouterConditionSet
      onAddCondition={onAddCondition}
      onDeleteCondition={onDeleteCondition}
      onChangeConditionType={onChangeConditionType}
      onChangeConditionValue={onChangeConditionValue}
      conditions={conditions}
      conditionTypes={result.data}
      conditionTypeOptions={conditionTypeOptions}
    />
  );
};
