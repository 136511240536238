const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3, 1, 1, 1),
    height: 600,
    overflow: 'hidden',
    width: 400,
    outline: 'none',
  },
  input: {
    minHeight: theme.customHeights.searchDropdownInput,
    display: 'flex',
    padding: 0,
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: theme.typography.fontSize * 1.142,
  },
  placeholder: {
    fontSize: theme.typography.fontSize,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  menuItem: {
    borderRadius: 6,
    ' &:hover': {
      backgroundColor: theme.palette.background.default,
    },
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 0, 2, 0),
    cursor: 'pointer',
  },
  notSelected: {
    paddingLeft: theme.spacing(6.5),
  },
  selected: {
    paddingLeft: theme.spacing(3),
  },
  paper: {
    zIndex: 1,
    boxShadow: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
  suggestionName: {
    fontSize: theme.typography.fontSize,
    color: theme.palette.common.black,
    whiteSpace: 'normal',
  },
  listItem: {
    height: 56,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    '&.notSelected': {
      paddingLeft: theme.spacing(5),
    },
  },
  listItemText: {
    paddingRight: 0,
    paddingLeft: theme.spacing(1),
  },
  checkmarkIconWrapper: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    boxShadow: theme.customShadows.card,
    position: 'relative',
    marginLeft: theme.spacing(1),
  },
  checkmarkIcon: {
    position: 'absolute',
    top: 7,
    left: 7,
  },
  searchIcon: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  navLink: {
    textDecoration: 'none',
    width: '100%',
    padding: theme.spacing(1.25),
  },
});

export default styles;
