import {
  InnerContent,
  SimpleModal,
  SimpleModalHeader,
  SimpleModalHeaderIcon,
  SimpleModalTitle,
} from '@fountain/fountain-ui-components';
import Grid from '@material-ui/core/Grid';
import filter from 'lodash/filter';
import PropTypes from 'prop-types';
import React from 'react';

import InfoBlueIcon from 'images/info-blue.svg';

import PartnerDetail from './components/PartnerDetail';

function PartnerDetailsModal({ partnerDetails, handleClose, title }) {
  const activeDetails = filter(partnerDetails, detail => detail.active);
  const inactiveDetails = filter(partnerDetails, detail => !detail.active);

  return (
    <SimpleModal onClose={handleClose}>
      <SimpleModalHeader onClose={handleClose}>
        <SimpleModalHeaderIcon Icon={InfoBlueIcon} color="blue" />
        <SimpleModalTitle>{title}</SimpleModalTitle>
      </SimpleModalHeader>

      <InnerContent>
        <Grid>
          <PartnerDetail details={activeDetails} />
          <PartnerDetail details={inactiveDetails} requestedRevision />
        </Grid>
      </InnerContent>
    </SimpleModal>
  );
}

PartnerDetailsModal.propTypes = {
  partnerDetails: PropTypes.array,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default PartnerDetailsModal;
