import { makeStyles } from '@material-ui/core/styles';

export const useCreateEditQuestionFormStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    padding: theme.spacing(3),
  },
  footer: {
    marginTop: 'auto',
    background: theme.palette.grey[100],
    padding: `calc(${theme.spacing(3)}px / 2) ${theme.spacing(3)}px`,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
