import {
  Button,
  Input,
  StyledReactSelect,
  Trashcan,
} from '@fountain/fountain-ui-components';
import {
  Grid,
  InputBaseProps,
  InputProps,
  Typography,
} from '@material-ui/core';
import { WorkflowStageDetail, WorkflowW4FormStage } from 'api-clients/monolith';
import { produce } from 'immer';
import React, { useContext, VFC } from 'react';
import InputMask from 'react-input-mask';
import { FormattedMessage, useIntl } from 'react-intl';

import { SelectOption } from 'containers/WorkflowEditor/components/StageColumnHeader/useGetStagePlacementOptions';
import { StageSettingCard } from 'containers/WorkflowEditor/components/StageSettingCard';
import { StageSettingCardEmptyState } from 'containers/WorkflowEditor/components/StageSettingCardEmptyState';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { FULL_STATE_OPTIONS } from '../../constants';
import { EmployerField } from './constants';
import { messages } from './messages';
import { useStyles } from './styles';

export interface EmployerInformationProps {
  stage: WorkflowW4FormStage;
}

export const EmployerInformation: VFC<EmployerInformationProps> = ({
  stage,
}) => {
  const intl = useIntl();
  const styles = useStyles();

  const { setStage } = useContext(StageContext);

  const {
    extra: {
      employer_name: employerName = null,
      employer_identification_number: employerIdentificationNumber = null,
      employer_address: employerAddress = null,
      employer_address2: employerAddress2 = null,
      employer_city: employerCity = null,
      employer_state: employerState = null,
      employer_zip: employerZip = null,
    },
  } = stage;

  const employerInformationPresent =
    employerName !== null &&
    employerIdentificationNumber !== null &&
    employerAddress !== null &&
    employerAddress2 !== null &&
    employerCity !== null &&
    employerState !== null &&
    employerZip !== null;

  const onAddEmployerInformation = () => {
    setStage(currentStage => ({
      ...currentStage,
      extra: {
        ...currentStage.extra,
        employer_name: '',
        employer_identification_number: '',
        employer_address: '',
        employer_address2: '',
        employer_city: '',
        employer_state: '',
        employer_zip: '',
      },
    }));
  };

  const onRemoveEmployerInformation = () => {
    setStage(currentStage => ({
      ...currentStage,
      extra: {
        ...currentStage.extra,
        employer_name: null,
        employer_identification_number: null,
        employer_address: null,
        employer_address2: null,
        employer_city: null,
        employer_state: null,
        employer_zip: null,
      },
    }));
  };

  const onChangeInput =
    (key: EmployerField) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setStage(
        produce((draftStage: WorkflowStageDetail) => {
          if (draftStage.extra) {
            draftStage.extra[key] = value;
          }
        }),
      );
    };

  const onChangeState = (state: SelectOption) => {
    setStage(currentStage => ({
      ...currentStage,
      extra: {
        ...currentStage.extra,
        employer_state: state.value as string,
      },
    }));
  };

  if (!employerInformationPresent) {
    return (
      <StageSettingCardEmptyState
        stageSettingCardProps={{
          title: intl.formatMessage(messages.employerInformation),
          variant: 'default',
        }}
        buttonComponent={
          <Button
            type="secondary"
            onClick={onAddEmployerInformation}
            disableRipple
            aria-label={intl.formatMessage(messages.addEmployerInformation)}
            className={styles.addEmployerInfoButton}
          >
            <Typography variant="h4" color="primary">
              <FormattedMessage {...messages.addEmployerInformation} />
            </Typography>
          </Button>
        }
      />
    );
  }

  return (
    <StageSettingCard
      title={intl.formatMessage(messages.employerInformation)}
      isLoading={false}
      variant="button"
      icon={
        <Trashcan
          color="secondary"
          viewBox="0 0 12 12"
          className={styles.trashcanIcon}
          aria-label={intl.formatMessage(messages.delete)}
        />
      }
      onClick={onRemoveEmployerInformation}
    >
      <Grid container spacing={3}>
        <Grid item>
          <Input
            aria-label={intl.formatMessage(messages.employerName)}
            label={intl.formatMessage(messages.employerName)}
            className={styles.employerName}
            value={employerName}
            onChange={onChangeInput('employer_name')}
          />
        </Grid>
        <Grid item>
          <InputMask
            mask="99-9999999"
            value={employerIdentificationNumber}
            onChange={onChangeInput('employer_identification_number')}
            alwaysShowMask
          >
            {(
              inputProps: JSX.IntrinsicAttributes &
                Partial<InputBaseProps> &
                InputProps & { children?: React.ReactNode },
            ) => (
              <Input
                {...inputProps}
                aria-label={intl.formatMessage(
                  messages.employerIdentificationNumber,
                )}
                label={intl.formatMessage(
                  messages.employerIdentificationNumber,
                )}
                className={styles.employerIdentificationNumber}
                value={employerIdentificationNumber}
              />
            )}
          </InputMask>
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item>
            <Input
              aria-label={intl.formatMessage(messages.employerAddress)}
              label={intl.formatMessage(messages.employerAddress)}
              className={styles.employerAddress}
              value={employerAddress}
              onChange={onChangeInput('employer_address')}
            />
          </Grid>
          <Grid item>
            <Input
              aria-label={intl.formatMessage(messages.employerAddress2)}
              label={intl.formatMessage(messages.employerAddress2)}
              className={styles.employerAddress2}
              value={employerAddress2}
              onChange={onChangeInput('employer_address2')}
            />
          </Grid>
          {/* Floor, Suite, PO Box etc. (Optional) field placeholder */}
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item>
            <Input
              aria-label={intl.formatMessage(messages.city)}
              label={intl.formatMessage(messages.city)}
              className={styles.city}
              value={employerCity}
              onChange={onChangeInput('employer_city')}
            />
          </Grid>
          <Grid item>
            <StyledReactSelect
              className={styles.state}
              aria-label={intl.formatMessage(messages.state)}
              label={intl.formatMessage(messages.state)}
              options={FULL_STATE_OPTIONS}
              value={FULL_STATE_OPTIONS.find(state => {
                return state.value === employerState;
              })}
              onChange={onChangeState}
            />
          </Grid>
          <Grid item>
            <Input
              aria-label={intl.formatMessage(messages.zipcode)}
              label={intl.formatMessage(messages.zipcode)}
              className={styles.zipcode}
              value={employerZip}
              onChange={onChangeInput('employer_zip')}
            />
          </Grid>
        </Grid>
      </Grid>
    </StageSettingCard>
  );
};
