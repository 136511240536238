import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addRule: {
    id: `app.containers.WorkflowEditor.components.Rules.PartnerRules.addRule`,
    defaultMessage: 'Add rule',
  },
  rule: {
    id: `app.containers.WorkflowEditor.components.Rules.PartnerRules.rule`,
    defaultMessage: 'Rule',
  },
  value: {
    id: 'app.containers.WorkflowEditor.components.Rules.PartnerRules.value',
    defaultMessage: 'Value',
  },
});
