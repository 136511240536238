/* eslint-disable camelcase */

import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import {
  bulkActionInit,
  bulkActionSubmitted,
  setSelectedApplicantIds,
} from 'containers/ApplicantTableV2/actions';
import { makeSelectSelectedFilterStageIdsWithFunnel } from 'containers/ApplicantTableV2/selectors';
import { makeSelectWhoami } from 'containers/Auth_old/selectors';
import { addMessageAction } from 'containers/FlashMessage/actions';
import { closePopup } from 'containers/GlobalPopup/actions';
import { apiPost } from 'utils/axios';
import { getJobIdFromUrl, getStageIdFromUrl } from 'utils/urlUtils';

const MESSAGE_TYPES = {
  email: 'emails',
  sms: 'SMSes',
};

// for bulk email - create a temporary message template if not using existing template unchanged
export default function useBulkMessageApplicants({ messageType }) {
  const selectedFilterStageIdsWithFunnel = useSelector(
    makeSelectSelectedFilterStageIdsWithFunnel(),
  );
  const location = useLocation();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const stageId = getStageIdFromUrl({ location });
  const currentUser = useSelector(makeSelectWhoami());

  const {
    filter_set_id,
    temp_filter_id,
    duplicate_applicant_id,
    stage_conditions_filter_set_id,
  } = queryString.parse(location.search);
  const jobId = getJobIdFromUrl({ location });

  useEffect(() => {
    if (error) {
      dispatch(
        addMessageAction(
          `An error occurred while scheduling ${MESSAGE_TYPES[messageType]}. Please try again.`,
          'error',
        ),
      );
    }
  }, [dispatch, error, messageType]);

  const createTemporaryMessageTemplate = async messageTemplateParams => {
    try {
      const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/message_templates`;
      const defaultMessageTemplateParams = {
        name: 'Temporary', // TODO:: must be unique
        type: 'general',
        html_mode: false,
        marked_for_delete: true,
        sender_id: currentUser.external_id,
      };
      const response = await apiPost(url, {
        message_template: {
          ...defaultMessageTemplateParams,
          ...messageTemplateParams,
        },
      });

      setError(null);
      return response.data.message_template.id;
    } catch (e) {
      return setError(e);
    }
  };

  const handleBulkMessage = async ({
    applicantIds,
    search,
    sendStart,
    templateId,
    smsTemplateId,
    whatsappTemplateId,
  }) => {
    dispatch(bulkActionInit());
    setIsSubmitting(true);
    try {
      dispatch(closePopup());

      const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/bulk_actions`;
      const response = await apiPost(url, {
        bulk_action: {
          applicant_ids: applicantIds,
          arguments: {
            message_type: messageType,
            template_id: templateId,
            from_stage: stageId ?? null,
            from_funnel: jobId,
            sms_template_id: smsTemplateId,
            whatsapp_template_id: whatsappTemplateId,
            run_time: sendStart,
            ...(duplicate_applicant_id && { duplicate_applicant_id }),
          },
          stage_id: stageId,
          filter_set_id: filter_set_id || temp_filter_id,
          stage_ids: selectedFilterStageIdsWithFunnel.flatMap(
            swf => swf.stage_ids,
          ), // when job stages filter is applied on MAV
          stage_conditions_filter_set_id:
            stage_conditions_filter_set_id ?? null,
          search,
          type: 'BulkMessage',
        },
      });

      dispatch(bulkActionSubmitted());

      const responseStatus = response.data && response.data.bulk_action.status;

      const STATUSES_TO_CLOSE_POPUP = {
        pending: true,
        complete: true,
        running: true,
      };

      if (STATUSES_TO_CLOSE_POPUP[responseStatus]) {
        dispatch(setSelectedApplicantIds([]));
      }
    } catch (e) {
      setError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    createTemporaryMessageTemplate,
    handleBulkMessage,
    error,
    isSubmitting,
  };
}
