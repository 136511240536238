/*
 *
 * GlobalPopup actions
 *
 */

import {
  CLOSE_PERSISTED_POPUP,
  CLOSE_POPUP,
  POPUP_TYPES,
  SET_PERSISTED_POPUP,
  SET_SELECTED_POPUP,
} from './constants';

export function closePopup(prevQueryParams) {
  return {
    type: CLOSE_POPUP,
    prevQueryParams,
  };
}

export function closePersistedPopup(prevQueryParams) {
  return {
    type: CLOSE_PERSISTED_POPUP,
    prevQueryParams,
  };
}

export function openScheduleApplicantModal({
  applicantId,
  schedulerStageId,
  schedulerStageType,
}) {
  return {
    type: SET_SELECTED_POPUP,
    popupType: POPUP_TYPES.SCHEDULE_APPLICANT,
    payload: {
      applicantId,
      schedulerStageId,
      schedulerStageType,
    },
  };
}

export function openImmediateHiringDecisionModal({
  applicantId,
  attended,
  schedulerStageId,
  schedulerStageType,
}) {
  return {
    type: SET_SELECTED_POPUP,
    popupType: POPUP_TYPES.IMMEDIATE_HIRING_DECISION,
    payload: {
      applicantId,
      attended,
      schedulerStageId,
      schedulerStageType,
    },
  };
}

export function openEditPartnerOptionsDataModal({
  applicantId,
  partnerStageId,
  partnerId,
  partnerOptionId,
  partnerStatusId,
}) {
  return {
    type: SET_SELECTED_POPUP,
    popupType: POPUP_TYPES.EDIT_PARTNER_OPTION_DATA_MODAL,
    payload: {
      applicantId,
      partnerStageId,
      partnerId,
      partnerOptionId,
      partnerStatusId,
    },
  };
}

export function openReviewAssessmentModal({ applicantId, assessmentFormId }) {
  return {
    type: SET_SELECTED_POPUP,
    payload: {
      applicantId,
      assessmentFormId,
    },
    popupType: POPUP_TYPES.REVIEW_ASSESSMENT_MODAL,
  };
}

export function openScheduleApplicantCancelModal({ slotId, applicantId }) {
  return {
    type: SET_SELECTED_POPUP,
    popupType: POPUP_TYPES.CANCEL_SCHEDULE_APPLICANT,
    payload: {
      slotId,
      applicantId,
    },
  };
}

export function openSendEmailPopup(applicantId, templateType, params) {
  return {
    type: SET_SELECTED_POPUP,
    popupType: POPUP_TYPES.SEND_EMAIL_POPUP,
    payload: {
      ...params,
      applicantId,
      templateType,
    },
  };
}

export function openSendSmsPopup(applicantId) {
  return {
    type: SET_SELECTED_POPUP,
    popupType: POPUP_TYPES.SEND_SMS_POPUP,
    payload: {
      applicantId,
    },
  };
}

export function openApplicantDrawerPopup(
  applicantId,
  tabIndex,
  prevQueryParams,
) {
  return {
    type: SET_PERSISTED_POPUP,
    persistedPopupType: POPUP_TYPES.APPLICANT_DRAWER_POPUP,
    prevQueryParams,
    payload: {
      applicantId,
      tabIndex,
    },
  };
}

export function openApplicantsImportModal() {
  return {
    type: SET_SELECTED_POPUP,
    popupType: POPUP_TYPES.APPLICANT_BULK_UPLOAD_POPUP,
  };
}

export function openBulkActionsUploadModal() {
  return {
    type: SET_SELECTED_POPUP,
    popupType: POPUP_TYPES.BULK_ACTIONS_UPLOAD,
  };
}

export function openUnsubscribeModal(applicantId, unsubscribeType) {
  return {
    type: SET_SELECTED_POPUP,
    popupType: POPUP_TYPES.UNSUBSCRIBE_MODAL,
    payload: {
      applicantId,
      unsubscribeType,
    },
  };
}

export function openUploadFileModal(applicantId) {
  return {
    type: SET_SELECTED_POPUP,
    popupType: POPUP_TYPES.UPLOAD_FILE_MODAL,
    payload: {
      applicantId,
    },
  };
}

export function openMoveApplicantPopup({
  applicantId,
  toStageId, // only used when stage is preselected
  transitionType, // 'advance' | 'move'
  warnW4Federal,
  warnWotc,
  isValidationSaved, // only used when submitted from DataValidationmodal
}) {
  return {
    type: SET_SELECTED_POPUP,
    popupType: POPUP_TYPES.MOVE_APPLICANT_POPUP,
    payload: {
      applicantId,
      toStageId,
      transitionType,
      warnW4Federal,
      warnWotc,
      isValidationSaved,
    },
  };
}

export function openDataValidationModal({
  applicantId,
  toStageId,
  validationGroupId,
  selectedJobId, // used when move applicant to other jobs after data validation
  selectedRunAutomatedActions, // To capture the runAutomatedActions while moving through Move to option
}) {
  return {
    type: SET_SELECTED_POPUP,
    popupType: POPUP_TYPES.DATA_VALIDATION_MODAL,
    payload: {
      applicantId,
      toStageId,
      validationGroupId,
      selectedJobId,
      selectedRunAutomatedActions,
    },
  };
}

export function openApplicantsFilter(jobId) {
  return {
    type: SET_SELECTED_POPUP,
    popupType: POPUP_TYPES.APPLICANTS_FILTER,
    payload: {
      job_id: jobId,
    },
  };
}

export function openBulkSendEmail(templateType, params) {
  return {
    type: SET_SELECTED_POPUP,
    popupType: POPUP_TYPES.SEND_BULK_EMAIL_POPUP,
    payload: {
      ...params,
      templateType,
    },
  };
}

export function openBulkSendSmsPopup() {
  return {
    type: SET_SELECTED_POPUP,
    popupType: POPUP_TYPES.SEND_BULK_SMS_POPUP,
  };
}

export function openDeleteApplicantModal({ applicantId, fromStageId }) {
  return {
    type: SET_SELECTED_POPUP,
    popupType: POPUP_TYPES.DELETE_APPLICANT_MODAL,
    payload: { applicantId, fromStageId },
  };
}

export function openMoveApplicantToJobPopup({
  applicantId,
  fromStageId,
  toStageId,
  transitionType,
  fromJobId,
}) {
  return {
    type: SET_SELECTED_POPUP,
    popupType: POPUP_TYPES.MOVE_APPLICANT_TO_JOB_POPUP,
    payload: {
      applicantId,
      fromStageId,
      toStageId,
      transitionType,
      fromJobId,
    },
  };
}

export function openRejectApplicantModal({
  applicantId,
  fromJobId,
  fromStageId,
  reasonId,
}) {
  return {
    type: SET_SELECTED_POPUP,
    popupType: POPUP_TYPES.REJECT_APPLICANT_MODAL,
    payload: { applicantId, fromJobId, fromStageId, reasonId },
  };
}

export function openHireApplicantModal({
  applicantId,
  fromJobId,
  fromStageId,
}) {
  return {
    type: SET_SELECTED_POPUP,
    popupType: POPUP_TYPES.HIRE_APPLICANT_MODAL,
    payload: { applicantId, fromJobId, fromStageId },
  };
}

export function openOnHoldApplicantModal({
  applicantId,
  selectedStageId,
  toStageId,
  toJobId,
  toDestinationStageId,
  fromJobId,
  fromStageId,
  reasonId,
}) {
  return {
    type: SET_SELECTED_POPUP,
    popupType: POPUP_TYPES.ON_HOLD_APPLICANT_MODAL,
    payload: {
      applicantId,
      selectedStageId,
      toStageId,
      toDestinationStageId,
      toJobId,
      fromJobId,
      fromStageId,
      reasonId,
    },
  };
}

export function openFileRecollectionDrawer(applicantId, blobId, stageId) {
  return {
    type: SET_SELECTED_POPUP,
    popupType: POPUP_TYPES.FILE_RECOLLECTION,
    payload: {
      applicantId,
      blobId,
      stageId,
    },
  };
}

export function openJobsStagesFilterPopup() {
  return {
    type: SET_SELECTED_POPUP,
    popupType: POPUP_TYPES.JOBS_STAGES_FILTER_POPUP,
  };
}

export function openCustomizableColumnsDrawer() {
  return {
    type: SET_SELECTED_POPUP,
    popupType: POPUP_TYPES.CUSTOMIZABLE_COLUMNS_DRAWER,
  };
}

export function openVideoRecordingPopover(applicantId, statusLabelTitle) {
  return {
    type: SET_SELECTED_POPUP,
    payload: {
      applicantId,
      statusLabelTitle,
    },
    popupType: POPUP_TYPES.VIDEO_RECORDING_POPOVER,
  };
}

export function openWxExportApplicantModal() {
  return {
    type: SET_SELECTED_POPUP,
    popupType: POPUP_TYPES.WX_EXPORT_APPLICANT_MODAL,
  };
}
