import {
  Button,
  ClickAwayListener,
  List,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import { ArrowDropDown, MoreHoriz } from '@material-ui/icons';
import { FunnelDetails } from 'api-clients/monolith';
import React, { useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import useAnchorState from 'hooks/useAnchorState';

import { messages } from './messages';
import { OpeningsMenu } from './OpeningsMenu';
import { RenameWorkflowModal } from './RenameWorkflowModal';
import { useStyles } from './styles';

type WorkflowTitleHandler = (newTitle: string) => void;

export interface WorkflowNavBarProps {
  applicantCount: number;
  workflowFunnels: FunnelDetails['workflow_funnels'];
  workflowTitle: string;
  workflowId: number;
  workflowExternalId: string;
  onWorkflowTitleUpdate: WorkflowTitleHandler;
}

export const WorkflowNavBar: VFC<WorkflowNavBarProps> = ({
  workflowFunnels,
  workflowExternalId,
  workflowTitle,
  onWorkflowTitleUpdate,
}) => {
  const intl = useIntl();
  const styles = useStyles();
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [renameModalVisible, setRenameModalVisible] = useState(false);
  const openingsMessage =
    workflowFunnels.length === 1
      ? messages.associatedOpening
      : messages.associatedOpenings;

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const {
    anchorEl: openingsAnchorEl,
    toggle: toggleOpenings,
    handleClose: handleOpeningsToggleClose,
  } = useAnchorState();

  const handleClose = () => {
    setOpen(false);
  };

  const handleRenameWorkflow = () => {
    setOpen(false);

    setRenameModalVisible(true);
  };

  return (
    <nav aria-label={intl.formatMessage(messages.workflowEditorNavigation)}>
      <List disablePadding className={styles.navBar} component="ol">
        <li>
          <Button onClick={toggleOpenings} color="inherit" disableRipple>
            <Typography variant="h4">
              <FormattedMessage
                {...openingsMessage}
                values={{ count: workflowFunnels.length }}
              />
            </Typography>
            <ArrowDropDown />
          </Button>
          {openingsAnchorEl && (
            <OpeningsMenu
              anchorEl={openingsAnchorEl}
              onClose={handleOpeningsToggleClose}
              workflowExternalId={workflowExternalId}
            />
          )}
        </li>
        <li>
          <Typography variant="h3" color="inherit">
            {workflowTitle}
          </Typography>
        </li>
        <li>
          <Button
            ref={anchorRef}
            aria-controls={open ? 'workflow-menu' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            color="inherit"
            disableRipple
          >
            <MoreHoriz />
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            className={styles.menu}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  <MenuItem onClick={handleRenameWorkflow}>
                    <FormattedMessage {...messages.renameWorkflowMenu} />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popper>
          <RenameWorkflowModal
            onWorkflowTitleUpdate={onWorkflowTitleUpdate}
            renameModalVisible={renameModalVisible}
            setRenameModalVisible={setRenameModalVisible}
            workflowTitle={workflowTitle}
          />
        </li>
      </List>
    </nav>
  );
};
