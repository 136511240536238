import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React, { FC } from 'react';

export const VideoRecordingStageIcon: FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1826 10.2741C12.0725 10.447 12.0604 10.668 12.1678 10.8425L13.0467 12.2701C13.3285 12.6822 13.3285 13.1628 12.9762 13.5062C12.7649 13.7808 12.4126 13.9868 12.0603 13.9868H11.8489L8.14816 13.3311C8.03274 13.3106 7.91463 13.3106 7.7992 13.3311L4.09847 13.9868C3.67571 14.0555 3.25296 13.8495 2.97113 13.5062C2.6893 13.0942 2.6893 12.6822 2.90067 12.2701L3.77957 10.8425C3.88701 10.668 3.87488 10.447 3.76479 10.2741C3.31472 9.5674 3.04159 8.71369 3.04159 7.80674C3.04159 5.1287 5.2258 3 7.97368 3C10.7216 3 12.9058 5.1287 12.9058 7.80674C12.9058 8.71369 12.6326 9.5674 12.1826 10.2741ZM8 10C9.24264 10 10.25 8.99264 10.25 7.75C10.25 6.50736 9.24264 5.5 8 5.5C6.75736 5.5 5.75 6.50736 5.75 7.75C5.75 8.99264 6.75736 10 8 10Z"
    />
  </SvgIcon>
);
