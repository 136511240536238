import { makeStyles } from '@material-ui/core/styles';
/**
 *
 * Material UI styles for WorkAvailabilityInput
 *
 *
 * @see https://material-ui.com/styles/basics
 *
 */

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.spacing(40),
  },
  row: {
    '&:last-child td': {
      borderBottom: 'none',
    },
  },
  cell: {
    padding: theme.spacing(1, 0),
    textAlign: 'center',
  },
  label: {
    marginLeft: 0,
    marginRight: 0,
  },
  checkbox: {
    marginRight: 0,
  },
  checkboxEditDisabled: {
    cursor: 'default',
  },
}));

export default useStyles;
