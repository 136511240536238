import { ApiSelfServeV2Service, StagesService } from 'api-clients/monolith';
import { useCallback, useEffect, useState } from 'react';

import { Filterable } from 'components/FilterDropdown/util';

import { ParamType } from '../types';

interface ParamValue {
  values: Filterable[];
  type: ParamType;
}

const fetchFunctions: Record<ParamType, (id: string) => Promise<Filterable>> = {
  opening: async id => {
    const res = (await ApiSelfServeV2Service.getApiSelfServeV2Openings1(
      id,
    )) as { funnel: { title: string; id: string } };
    return { id: res.funnel.id, title: res.funnel.title, name: 'opening' };
  },
  stage: async id => {
    const res = await StagesService.getInternalApiStages1(id);
    return { id: res.stage.id, title: res.stage.title, name: 'stage' };
  },
  location_group: async id => {
    const res = await ApiSelfServeV2Service.getApiSelfServeV2LocationGroups1(
      id,
    );
    return {
      id: res.location_group.id,
      title: res.location_group.name,
      name: 'locationGroup',
    };
  },
};

export const useParamValues = (
  search: string,
): { result: ParamValue[]; isLoading: boolean } => {
  const [result, setResult] = useState<ParamValue[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async (type: ParamType, id: string) => {
    const fetchFn = fetchFunctions[type];
    const { id: resultId, title, name } = await fetchFn(id);
    return {
      values: [{ id: resultId, title, name }],
      type,
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(search);

    const fetchParamsData = async () => {
      setIsLoading(true);
      try {
        const results: ParamValue[] = [];

        const stageParam = params.get(ParamType.STAGE);
        const openingParam = params.get(ParamType.OPENING);
        const locationGroupParam = params.get(ParamType.LOCATION_GROUP);

        if (stageParam) {
          const [funnelId, stageId] = stageParam.split(':');
          const [stageData, openingData] = await Promise.all([
            fetchData(ParamType.STAGE, stageId),
            fetchData(ParamType.OPENING, funnelId),
          ]);
          results.push(stageData, openingData);
        } else if (openingParam) {
          results.push(await fetchData(ParamType.OPENING, openingParam));
        } else if (locationGroupParam) {
          results.push(
            await fetchData(ParamType.LOCATION_GROUP, locationGroupParam),
          );
        }

        setResult(results);
      } finally {
        setIsLoading(false);
      }
    };

    if (search) {
      void fetchParamsData();
    }
  }, [search, fetchData]);

  return { result, isLoading };
};
