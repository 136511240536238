import { WebhooksService } from 'api-clients/monolith';
import { useApiService } from 'hooks';
import { useCallback } from 'react';

export const useWebhookNotifications = () => {
  const fetch = useCallback(
    () => WebhooksService.getInternalApiWebhooksNotifications(),
    [],
  );

  return useApiService(fetch);
};
