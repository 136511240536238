import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import {
  generateHashStatusMessages,
  getConditionErrorMessage,
} from 'containers/WorkflowEditor/components/Rules/Condition/constants';
import { messages as conditionMessages } from 'containers/WorkflowEditor/components/Rules/Condition/messages';
import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';

import { DocumentSigningRuleProps } from '../../../DocumentSigningRules/types';
import { RulesProps, SharedConditionProps } from '../../../types';

export const LessonlyStatus: FC<SharedConditionProps> = ({
  condition,
  ruleId,
  setRules,
  setDocumentSigningRules,
  errors,
}) => {
  const intl = useIntl();
  const { extra } = condition;
  const { condition_statuses: conditionStatuses } =
    useContext(RulesEditDataContext);

  const messageByStatus = generateHashStatusMessages(intl);
  const statusOptions = conditionStatuses?.lessonly?.map(status => ({
    label: messageByStatus[status],
    value: status,
  }));
  const selectedStatus =
    statusOptions?.find(option => option.value === extra.status) ?? null;

  const onChangeStatus = (newStatus: { label: string; value: string }) => {
    const rulesSetter = setRules ?? setDocumentSigningRules;

    if (rulesSetter) {
      rulesSetter(
        produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];
          const idx = draftConditions.findIndex(
            cond => cond.id === condition.id,
          );

          draftConditions[idx].extra.status = newStatus.value;
        }),
      );
    }
  };

  const statusErrorMessage =
    !extra?.status && getConditionErrorMessage('status', errors);

  return (
    <StyledReactSelect
      options={statusOptions}
      label={intl.formatMessage(conditionMessages.status)}
      aria-label={intl.formatMessage(conditionMessages.status)}
      onChange={onChangeStatus}
      value={selectedStatus}
      error={statusErrorMessage}
      placeholder=""
      required
    />
  );
};
