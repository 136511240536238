import { Grid, TableCell } from '@material-ui/core';
import React, { FC, memo } from 'react';

import { TYPE_COLUMN_WIDTH } from 'containers/ApplicantTableV2/constants';
import { SourceType } from 'containers/ApplicantTableV2/types';
import BoostLogo from 'images/sourcing/boost-blue.svg';
import IndeedLogo from 'images/sourcing/indeed-logo.svg';
import SpringLogo from 'images/sourcing/spring.svg';
import { assertUnreachable } from 'utils/assertUnreachable';

import useStyles from './styles';

export interface ApplicantTableV2TypeCellProps {
  sourceType: SourceType;
}

export const ApplicantTableV2TypeCell: FC<ApplicantTableV2TypeCellProps> = ({
  sourceType,
}) => {
  const classes = useStyles();

  const typeMap = (sourceType: SourceType) => {
    switch (sourceType) {
      case 'vonq':
        return (
          <img
            src={SpringLogo}
            alt="fountain spring"
            className={classes.logo}
          />
        );
      case 'campaign':
        return <img src={IndeedLogo} alt="indeed" className={classes.logo} />;
      case 'simple':
        return <img src={BoostLogo} alt="boost" className={classes.logo} />;
      case 'advanced':
        return <img src={BoostLogo} alt="boost" className={classes.logo} />;
      case null:
        return null;
      /* istanbul ignore next */
      default:
        return assertUnreachable(sourceType);
    }
  };

  return (
    <TableCell
      role="cell"
      style={{
        minWidth: TYPE_COLUMN_WIDTH,
        maxWidth: TYPE_COLUMN_WIDTH,
      }}
      className={classes.typeCell}
    >
      <Grid container className={classes.sourceTypeCell}>
        <Grid item>{typeMap(sourceType)}</Grid>
      </Grid>
    </TableCell>
  );
};

export default memo(ApplicantTableV2TypeCell);
