import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  section: {
    marginBottom: theme.spacing(3),
  },
  label: {
    marginBottom: theme.spacing(0.5),
  },
  messageTimeZoneInfo: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1.5),
    color: theme.palette.common.gray400,
    fontSize: 12,
  },
}));

export default useStyles;
