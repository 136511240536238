import { Grid, Link, Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { If } from 'react-extras';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import makeStyles from './styles';

export default function NavLink({
  active,
  display,
  external,
  link,
  message,
  dataTestid,
}) {
  const classes = makeStyles();
  const className = classNames(classes.container, active && classes.active);

  return (
    <If condition={display}>
      <Grid container className={className} direction="column" justify="center">
        <Grid item>
          {!external ? (
            <RouterLink
              to={link}
              className={classes.routerLink}
              data-testid={`navbar-${dataTestid}`}
            >
              <Typography
                variant="body2"
                className={classes.leftSectionTrigger}
              >
                <FormattedMessage {...message} />
              </Typography>
            </RouterLink>
          ) : (
            <Link
              href={link}
              underline="none"
              data-testid={`navbar-${dataTestid}`}
            >
              <Typography
                variant="body2"
                className={classes.leftSectionTrigger}
              >
                <FormattedMessage {...message} />
              </Typography>
            </Link>
          )}
        </Grid>
      </Grid>
    </If>
  );
}

NavLink.propTypes = {
  active: PropTypes.bool,
  display: PropTypes.bool,
  external: PropTypes.bool,
  link: PropTypes.string.isRequired,
  message: PropTypes.object.isRequired,
  dataTestid: PropTypes.string.isRequired,
};

NavLink.defaultProps = {
  display: true,
  external: false,
};
