import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  resetSourcingPurchaseAction,
  setJobAction,
  setPostingTitleAction,
} from 'containers/SourcingPurchaseNew/actions';

export default function useSourcingPurchaseSetCurrentJob(job) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (job) {
      dispatch(setPostingTitleAction(job.title));
      dispatch(setJobAction(job));
    }

    return function cleanup() {
      if (job) {
        dispatch(resetSourcingPurchaseAction());
      }
    };
  }, [dispatch, job]);
}
