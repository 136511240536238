import { ExpansionPanelDetails } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const AccordionDetails = withStyles(theme => ({
  root: {
    borderRadius: 5,
    padding: theme.spacing(1.25, 1.25, 1.25, 3.5),
    '&:hover': {
      background: '#131E401F',
    },
    '&:active': {
      background: '#131E4052',
    },
    '& > *:first-child': {
      fontSize: 14,
      width: '70%',
    },
    '& > *:nth-child(2)': {
      width: '30%',
      textAlign: 'right',
    },
  },
}))(ExpansionPanelDetails);

export default AccordionDetails;
