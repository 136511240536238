import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    rowContainer: {
      marginTop: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        minWidth: theme.spacing(87.75),
      },
    },
    label: {
      margin: theme.spacing(0, 0, 0.5, 0),
    },
    switchAligned: {
      margin: theme.spacing(0, 2, 0, 1),
    },
    tooltipIcon: {
      margin: theme.spacing(0, 0, 0, 1),
    },
    createEventWrapper: {
      backgroundColor: theme.palette.common.white,
    },
    createEventContainer: {
      flex: 1,
      boxSizing: 'border-box',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(6, 10),
        maxWidth: '70%',
      },
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(6, 12),
        maxWidth: '60%',
      },
    },
    backToSettings: {
      marginBottom: theme.spacing(1),
    },
    leftArrow: {
      marginRight: theme.spacing(1),
    },
    createEventContent: {
      flex: 1,
    },
    warningContent: {
      backgroundColor: theme.palette.common.blue100,
      borderRadius: theme.spacing(0.625),
      padding: theme.spacing(1),
      marginTop: theme.spacing(6),
      display: 'flex',
      position: 'absolute',
      alignItems: 'center',
    },
    warningIcon: {
      height: theme.spacing(2),
      color: theme.palette.common.blue400,
    },
    warningSpacing: {
      margin: theme.spacing(2, 1),
    },
    warningText: {
      marginLeft: '5px',
      color: theme.palette.common.red800,
    },
  }),
);
