import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { WorkflowStageDetail } from 'api-clients/monolith';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import {
  getActionErrorMessage,
  stageTypeWithReasons,
} from 'containers/WorkflowEditor/components/Rules/Action/constants';
import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';

import {
  PartnerRuleProps,
  SharedPartnerActionProps,
} from '../../PartnerRules/types';
import {
  RulesProps,
  SharedActionProps,
  SharedElseActionProps,
} from '../../types';
import messages from './messages';
import { ReasonSelect } from './ReasonSelect';

type TargetStageType = {
  id: string;
  title: string;
  type: string;
  // eslint-disable-next-line camelcase
  stage_id: number;
};

export type MoveToStageProps =
  | SharedActionProps
  | SharedPartnerActionProps
  | SharedElseActionProps;

export const MoveToStage: FC<MoveToStageProps> = React.memo(
  ({ action, ruleId, setRules, setPartnerRules, setStage, errors }) => {
    const intl = useIntl();

    const { funnel_stages: funnelStages } = useContext(RulesEditDataContext);

    const selectedStage =
      funnelStages?.find(stage => stage.stage_id === action.ref_stage_1_id) ??
      null;

    const onChangeStage = (stage: TargetStageType) => {
      // PartnerStage handling
      if (setPartnerRules && ruleId) {
        setPartnerRules(
          produce((draftRules: PartnerRuleProps) => {
            const draftAction = draftRules[ruleId]?.action_attributes;

            draftAction.ref_stage_1_id = stage.stage_id;
          }),
        );
      }

      if (setRules && ruleId) {
        setRules(
          produce((draftRules: RulesProps) => {
            const draftActions =
              draftRules[ruleId]?.action_set_attributes?.actions_attributes ??
              [];

            const idx = draftActions.findIndex(act => act.id === action.id);

            draftActions[idx].ref_stage_1_id = stage.stage_id;
          }),
        );
      } else if (setStage) {
        setStage(
          produce((draftStage: WorkflowStageDetail) => {
            if (
              draftStage.type !== 'RuleStage' &&
              draftStage.type !== 'CustomStage' &&
              draftStage.type !== 'AssessmentStage'
            ) {
              return;
            }
            const elseAction =
              draftStage.additional_info.else_action_set_attributes
                ?.actions_attributes?.[0];

            if (elseAction) {
              elseAction.ref_stage_1_id = stage.stage_id;
            }
          }),
        );
      }
    };

    const selectedStageType = selectedStage?.type;
    const showReasonsDropdown =
      selectedStageType && stageTypeWithReasons[selectedStageType];

    const errorMessage =
      !action.ref_stage_1_id && getActionErrorMessage('ref_stage_1', errors);

    return (
      <>
        <Grid item xs={6}>
          <StyledReactSelect
            value={selectedStage}
            options={funnelStages}
            getOptionLabel={(option: TargetStageType) => option.title}
            getOptionValue={(option: TargetStageType) => option.stage_id}
            onChange={onChangeStage}
            label={intl.formatMessage(messages.stage)}
            aria-label={intl.formatMessage(messages.stage)}
            error={errorMessage}
            placeholder=""
            required
          />
        </Grid>
        {showReasonsDropdown && (
          <Grid item xs={6}>
            <ReasonSelect
              action={action}
              ruleId={ruleId}
              setRules={setRules}
              setPartnerRules={setPartnerRules}
              setStage={setStage}
            />
          </Grid>
        )}
      </>
    );
  },
);
