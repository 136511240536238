/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OpeningApprovals } from '../models/OpeningApprovals';
import type { OpeningApprovalSettings } from '../models/OpeningApprovalSettings';
import type { OpeningApprovalSettingsParams } from '../models/OpeningApprovalSettingsParams';
import type { OpeningApprovalsParams } from '../models/OpeningApprovalsParams';
import type { OpeningApproverGroupObj } from '../models/OpeningApproverGroupObj';
import type { OpeningApproverGroupParams } from '../models/OpeningApproverGroupParams';
import type { OpeningApproverGroups } from '../models/OpeningApproverGroups';
import type { OpeningApprovers } from '../models/OpeningApprovers';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OpeningApprovalsService {

    /**
     * Lists Opening Approvals
     * Returns a list of request approvals
     * @param status Opening Approval status
     * @param name Opening name
     * @param page The page of results to view
     * @param perPage The number of results to return per page
     * @param openingId The opening external_id that will be returned in the response irrespective of the status
     * @param assignedToMe The filter to select the openings assigned to me
     * @param sortColumn The column to sort
     * @param sortOrder The sort order
     * @returns OpeningApprovals Success
     * @throws ApiError
     */
    public static getInternalApiOpeningApprovalApprovals(
        status?: string,
        name?: string,
        page?: number,
        perPage?: number,
        openingId?: string,
        assignedToMe?: boolean,
        sortColumn?: string,
        sortOrder?: string,
    ): CancelablePromise<OpeningApprovals> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/opening_approval/approvals',
            query: {
                'status': status,
                'name': name,
                'page': page,
                'per_page': perPage,
                'opening_id': openingId,
                'assigned_to_me': assignedToMe,
                'sort_column': sortColumn,
                'sort_order': sortOrder,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }

    /**
     * Update Opening Approvals
     * @param requestBody Update Opening Approvals
     * @returns any successful
     * @throws ApiError
     */
    public static postInternalApiOpeningApprovalApprovalsAction(
        requestBody: OpeningApprovalsParams,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/opening_approval/approvals/action',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
            },
        });
    }

    /**
     * Lists Opening Approver Groups
     * Returns a list of approver groups
     * @param page The page of results to view
     * @param perPage The number of results to return per page
     * @param name Approver Group Name
     * @param sort Sort direction
     * @returns OpeningApproverGroups Success
     * @throws ApiError
     */
    public static getInternalApiOpeningApprovalApproverGroups(
        page?: number,
        perPage?: number,
        name?: string,
        sort?: string,
    ): CancelablePromise<OpeningApproverGroups> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/opening_approval/approver_groups',
            query: {
                'page': page,
                'per_page': perPage,
                'name': name,
                'sort': sort,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }

    /**
     * Create Opening Approver group
     * @param requestBody Create Approver Group
     * @returns any successful
     * @throws ApiError
     */
    public static postInternalApiOpeningApprovalApproverGroups(
        requestBody: OpeningApproverGroupParams,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/opening_approval/approver_groups',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
            },
        });
    }

    /**
     * Fetch Approvers
     * Returns a list of approvers
     * @returns OpeningApprovers Success
     * @throws ApiError
     */
    public static getInternalApiOpeningApprovalApproverGroupsGetApprovers(): CancelablePromise<OpeningApprovers> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/opening_approval/approver_groups/get_approvers',
            errors: {
                401: `unauthorized`,
            },
        });
    }

    /**
     * Fetch Approver Group
     * Returns a approver group object
     * @param id Approver Group ID
     * @returns OpeningApproverGroupObj Success
     * @throws ApiError
     */
    public static getInternalApiOpeningApprovalApproverGroups1(
        id: string,
    ): CancelablePromise<OpeningApproverGroupObj> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/opening_approval/approver_groups/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }

    /**
     * Update Opening Approver group
     * @param id Approver Group ID
     * @param requestBody Update Approver Group
     * @returns any successful
     * @throws ApiError
     */
    public static patchInternalApiOpeningApprovalApproverGroups(
        id: string,
        requestBody: OpeningApproverGroupParams,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/internal_api/opening_approval/approver_groups/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
            },
        });
    }

    /**
     * Delete Opening Approver Group
     * @param id Approver Group ID
     * @returns any successful
     * @throws ApiError
     */
    public static deleteInternalApiOpeningApprovalApproverGroups(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/internal_api/opening_approval/approver_groups/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Opening Approval Settings
     * Returns opening approval settings
     * @returns OpeningApprovalSettings Success
     * @throws ApiError
     */
    public static getInternalApiOpeningApprovalSettings(): CancelablePromise<OpeningApprovalSettings> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/opening_approval/settings',
            errors: {
                401: `unauthorized`,
            },
        });
    }

    /**
     * Update Opening Approval settings
     * @param requestBody Update Opening Approval settings
     * @returns any successful
     * @throws ApiError
     */
    public static postInternalApiOpeningApprovalSettingsUpdateSettings(
        requestBody: OpeningApprovalSettingsParams,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/opening_approval/settings/update_settings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
            },
        });
    }

}