import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  header: {
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar,
    transition: 'background 0.5s ease',
    background: theme.palette.common.white,

    '&.scrollActive': {
      transition: 'background 0.5s ease',
      background: '#22A4F2', // <- Color used in monolith
      color: theme.palette.common.white,
    },
  },
  navContainer: {
    height: 75,
    maxWidth: 1440,
    margin: '0 auto',
    padding: theme.spacing(0, 12.5),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 5),
    },
  },
  navClickable: {
    textDecoration: 'none',
    color: 'rgb(34, 164, 242)',
    fontFamily: theme.typography.h4.fontFamily,

    '&:active,\n  &:hover': {
      textDecoration: 'none',
    },

    '&.white': {
      color: theme.palette.common.white,
    },
  },
  fountainLogo: {
    marginTop: theme.spacing(0.5),

    '& path': {
      fill: props =>
        props.scrolledLow ? theme.palette.common.white : '#22A4F2', // <- Color used in monolith
    },
  },
  actionDemo: {
    paddingRight: theme.spacing(3.75),

    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  actionLinks: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(0.5),

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8125rem',
    },
  },
  actionLinkDivider: {
    display: 'none',

    [theme.breakpoints.down('sm')]: {
      display: 'block',
      padding: theme.spacing(0, 0.875),
      color: theme.palette.primary.main,

      '&.white': {
        color: theme.palette.common.white,
      },
    },
  },
}));

export default useStyles;
