import { Dropdown } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import { Action, StageType, WorkflowStageDetail } from 'api-clients/monolith';
import { produce } from 'immer';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ActionTypeComponentProps } from '../types';
import {
  DO_NOT_MOVE,
  MOVE_TO_NEXT_STAGE,
  MOVE_TO_POSITION,
  MOVE_TO_STAGE,
} from './constants';
import { messages } from './messages';
import { MoveToPosition } from './MoveToPosition';
import { MoveToStage } from './MoveToStage';
import { useStyles } from './styles';

export interface ElseActionProps {
  action: Action;
  setStage: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
  stageType: StageType;
  errors?: {
    [key: string]: string[];
  };
}

export const ElseAction: FC<ElseActionProps> = ({
  action,
  setStage,
  stageType,
  errors,
}) => {
  const intl = useIntl();
  const styles = useStyles();

  const onSelectActionType = (type: string) => {
    setStage(
      produce((draftStage: WorkflowStageDetail) => {
        if (
          draftStage.type === 'RuleStage' ||
          draftStage.type === 'CustomStage' ||
          draftStage.type === 'AssessmentStage'
        ) {
          const elseActions =
            draftStage.additional_info.else_action_set_attributes
              ?.actions_attributes;

          if (elseActions?.[0]) {
            elseActions[0] = {
              id: action.id,
              type,
              extra: {},
              ref_stage_1_id: null,
            };
          }
        }
      }),
    );
  };

  const actionTypeOptions = [
    {
      label: intl.formatMessage(messages.moveApplicantToNextStage),
      value: MOVE_TO_NEXT_STAGE,
    },
    {
      label: intl.formatMessage(messages.moveApplicantToStage),
      value: MOVE_TO_STAGE,
    },
    {
      label: intl.formatMessage(messages.moveApplicantToOpening),
      value: MOVE_TO_POSITION,
    },
  ];

  if (stageType !== 'RuleStage') {
    actionTypeOptions.push({
      label: intl.formatMessage(messages.doNotMoveApplicant),
      value: DO_NOT_MOVE,
    });
  }

  const sharedElseActionProps = {
    setStage,
    action,
    errors,
  };

  const actionComponentsByType: ActionTypeComponentProps = {
    [MOVE_TO_STAGE]: <MoveToStage {...sharedElseActionProps} />,
    [MOVE_TO_NEXT_STAGE]: null,
    [MOVE_TO_POSITION]: <MoveToPosition {...sharedElseActionProps} />,
    [DO_NOT_MOVE]: null,
  };

  const ActionTypeComponent =
    action.type && actionComponentsByType[action.type];

  return (
    <Grid className={styles.elseActionContainer}>
      <Typography variant="body2" className={styles.elseActionHeader}>
        <FormattedMessage {...messages.doesNotMeetAnyRule} />
      </Typography>
      <Grid container wrap="nowrap">
        <Grid container spacing={2}>
          <Grid item xs={ActionTypeComponent ? 6 : 12}>
            <Dropdown
              options={actionTypeOptions}
              value={action.type}
              onChange={onSelectActionType}
              label={intl.formatMessage(messages.actionType)}
              aria-label={intl.formatMessage(messages.actionType)}
              isSearchable
              required
            />
          </Grid>
          {ActionTypeComponent}
        </Grid>
      </Grid>
    </Grid>
  );
};
