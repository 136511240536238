import { CalendarUser } from 'api-clients/monolith';

import { faker } from 'testUtils';

import { SessionHostsDropdownProps } from './SessionHostsDropdown';
export const fakeCalendarUser = ({
  id = faker.datatype.number(),
  name = `${faker.name.firstName()} ${faker.name.lastName()}`,
  calendar = {
    calendar_type: 'external',
    id: faker.datatype.number(),
  },
}: Partial<CalendarUser> = {}): CalendarUser => ({
  id,
  name,
  calendar,
});

export const fakeUser = ({
  id = faker.datatype.number(),
  name = `${faker.name.firstName()} ${faker.name.lastName()}`,
  calendar = undefined,
}: Partial<CalendarUser> = {}): CalendarUser => ({
  id,
  name,
  calendar,
});

export const defaultProps: SessionHostsDropdownProps = {
  options: [fakeUser(), fakeCalendarUser()].map(user => {
    return { title: user.name, value: user.calendar?.id };
  }),
  onChange: () => {},
  disabled: false,
};
