import { ReviewStageDataFieldTypeEnum } from 'api-clients/monolith/models/WorkflowReviewStage';

const QUESTION_TYPE_OPTIONS = [
  {
    value: ReviewStageDataFieldTypeEnum.textField as const,
    label: 'Text',
  },
  {
    value: ReviewStageDataFieldTypeEnum.radioField as const,
    label: 'Multiple Choice',
  },
];
type QuestionTypeOptions = typeof QUESTION_TYPE_OPTIONS;
export type QuestionTypeOption = QuestionTypeOptions[number];

export const PAY_RATE_KEY = 'hiring_manager_confirmed_pay_rate' as const;
export const STANDARD_QUESTION_OPTIONS = [
  {
    value: PAY_RATE_KEY,
    label: 'Pay Rate',
  },
];

export const STANDARD_QUESTION_VALUES = STANDARD_QUESTION_OPTIONS.map(
  ({ value }) => value,
);

export type StandardQuestions = typeof STANDARD_QUESTION_OPTIONS;
export type StandardQuestion = StandardQuestions[number];

export type GroupedQuestionOption = {
  label: string;
  options: QuestionTypeOptions | StandardQuestions;
};

export const GROUPED_QUESTION_OPTIONS = [
  {
    label: 'question types' as const,
    options: QUESTION_TYPE_OPTIONS,
  },
  {
    label: 'custom' as const,
    options: STANDARD_QUESTION_OPTIONS,
  },
];
export const ALL_GROUPED_QUESTIONS = GROUPED_QUESTION_OPTIONS.map(
  ({ options }) => options,
).flat();

export type GroupedQuestionsValues =
  (typeof GROUPED_QUESTION_OPTIONS)[number]['options'][number]['value'];

export const ALL_GROUPED_QUESTIONS_VALUES: Array<GroupedQuestionsValues> =
  GROUPED_QUESTION_OPTIONS.map(({ options }) =>
    options.map(({ value }) => value),
  ).flat();
