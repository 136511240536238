import {
  Input,
  StyledReactSelect,
  Trashcan,
} from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import { WorkflowI9FormStage } from 'api-clients/monolith';
import { produce } from 'immer';
import React, { useContext, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { SelectOption } from 'containers/WorkflowEditor/components/StageColumnHeader/useGetStagePlacementOptions';
import { StageSettingCard } from 'containers/WorkflowEditor/components/StageSettingCard';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { FULL_STATE_OPTIONS } from '../../constants';
import {
  EMPLOYER_ADDRESS,
  EMPLOYER_BUSINESS_NAME,
  EMPLOYER_CITY,
  EMPLOYER_FIRST_NAME,
  EMPLOYER_LAST_NAME,
  EMPLOYER_TITLE,
  EMPLOYER_ZIP,
  EmployerField,
} from './constants';
import { messages } from './messages';
import { useStyles } from './styles';

export interface EmployerInformationProps {
  stage: WorkflowI9FormStage;
}

export const EmployerInformation: VFC<EmployerInformationProps> = ({
  stage,
}) => {
  const intl = useIntl();
  const styles = useStyles();

  const { setStage } = useContext(StageContext);

  const {
    extra: {
      employer_address: employerAddress = null,
      employer_business_name: employerBusinessName = null,
      employer_city: employerCity = null,
      employer_first_name: employerFirstName = null,
      employer_last_name: employerLastName = null,
      employer_state: employerState = null,
      employer_title: employerTitle = null,
      employer_zip: employerZip = null,
    },
  } = stage;

  const onRemoveEmployerInformation = () => {
    setStage(currentStage => ({
      ...currentStage,
      extra: {
        ...currentStage.extra,
        employer_address: '',
        employer_business_name: '',
        employer_city: '',
        employer_first_name: '',
        employer_last_name: '',
        employer_title: '',
        employer_state: '',
        employer_zip: '',
      },
    }));
  };

  const onChangeInput =
    (key: EmployerField) => (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = event.target;
      setStage(
        produce(stage, draftStage => {
          draftStage.extra[key] = value;
        }),
      );
    };

  const onChangeState = (state: SelectOption) => {
    setStage(currentStage => ({
      ...currentStage,
      extra: {
        ...currentStage.extra,
        employer_state: state.value as string,
      },
    }));
  };

  const selectedStateValue = stage.extra.employer_state
    ? FULL_STATE_OPTIONS.find(state => state.value === employerState)
    : '';

  return (
    <StageSettingCard
      title={intl.formatMessage(messages.employerInformation)}
      isLoading={false}
      variant="button"
      icon={
        <Trashcan
          color="secondary"
          viewBox="0 0 12 12"
          className={styles.trashcanIcon}
          aria-label={intl.formatMessage(messages.delete)}
        />
      }
      onClick={onRemoveEmployerInformation}
    >
      <Grid container spacing={3}>
        <Typography variant="body2" className={styles.employerInfoHelperText}>
          <FormattedMessage {...messages.i9BusinessInfoHelperText} />
        </Typography>
        <Grid item xs={12}>
          <Input
            aria-label={intl.formatMessage(messages.businessName)}
            label={intl.formatMessage(messages.businessName)}
            className={styles.businessName}
            value={employerBusinessName}
            onChange={onChangeInput(EMPLOYER_BUSINESS_NAME)}
          />
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item>
            <Input
              aria-label={intl.formatMessage(messages.title)}
              label={intl.formatMessage(messages.title)}
              className={styles.title}
              value={employerTitle}
              onChange={onChangeInput(EMPLOYER_TITLE)}
            />
          </Grid>
          <Grid item>
            <Input
              aria-label={intl.formatMessage(messages.firstName)}
              label={intl.formatMessage(messages.firstName)}
              className={styles.firstName}
              value={employerFirstName}
              onChange={onChangeInput(EMPLOYER_FIRST_NAME)}
            />
          </Grid>
          <Grid item>
            <Input
              aria-label={intl.formatMessage(messages.lastName)}
              label={intl.formatMessage(messages.lastName)}
              className={styles.lastName}
              value={employerLastName}
              onChange={onChangeInput(EMPLOYER_LAST_NAME)}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item>
            <Input
              aria-label={intl.formatMessage(messages.address)}
              label={intl.formatMessage(messages.address)}
              className={styles.address}
              value={employerAddress}
              onChange={onChangeInput(EMPLOYER_ADDRESS)}
            />
          </Grid>
          {/* Floor, Suite, PO Box etc. (Optional) field placeholder */}
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item>
            <Input
              aria-label={intl.formatMessage(messages.city)}
              label={intl.formatMessage(messages.city)}
              className={styles.city}
              value={employerCity}
              onChange={onChangeInput(EMPLOYER_CITY)}
            />
          </Grid>
          <Grid item>
            <StyledReactSelect
              className={styles.state}
              aria-label={intl.formatMessage(messages.state)}
              label={intl.formatMessage(messages.state)}
              options={FULL_STATE_OPTIONS}
              value={selectedStateValue}
              onChange={onChangeState}
            />
          </Grid>
          <Grid item>
            <Input
              aria-label={intl.formatMessage(messages.zipcode)}
              label={intl.formatMessage(messages.zipcode)}
              className={styles.zipcode}
              value={employerZip}
              onChange={onChangeInput(EMPLOYER_ZIP)}
            />
          </Grid>
        </Grid>
      </Grid>
    </StageSettingCard>
  );
};
