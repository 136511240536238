import { WorkflowRuleStage } from 'api-clients/monolith';
import React, { VFC } from 'react';

import { Rules } from 'containers/WorkflowEditor/components/Rules';

export interface RuleStageProps {
  stage: WorkflowRuleStage;
}

export const RuleStage: VFC<RuleStageProps> = ({ stage }) => {
  return (
    <div data-testid="ruleStage">
      <Rules stage={stage} />
    </div>
  );
};
