import { Period } from 'api-clients/monolith';
import { messages } from 'Calendar/AvailabilityRules/messages';
import React from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';

interface labelValue {
  label: string;
  value: string;
}

/**
 *
 * @description generates the next time range for a given period
 * @param lastPeriod '11:00'
 * @returns {Array<string>} tuple of 24hour formatted strings; ['12:00', '13:00]
 *
 */
export const generateNextTimeRange = (lastPeriod: string): [string, string] => {
  const [hours, minutes]: [number, number] = [
    Number(lastPeriod.slice(0, 2)),
    Number(lastPeriod.slice(3)),
  ];
  const startDate = new Date();
  startDate.setHours(hours, minutes);

  const endDate = new Date();
  if (hours === 23) {
    endDate.setHours(24, 0);
  } else {
    endDate.setHours(hours + 1, minutes);
  }

  const start = startDate.toLocaleTimeString('default', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
  const end = endDate.toLocaleTimeString('default', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });

  return [start, end];
};

export const getOption = ({
  options,
  value,
  type = 'value',
}: {
  options: labelValue[];
  value?: FormDataEntryValue;
  type?: keyof labelValue;
}) => {
  switch (type) {
    case 'label': {
      return options.find(option => option.label === value);
    }
    default: {
      return options.find(option => option.value === value);
    }
  }
};

export const errorMessage = (message: string | undefined, intl: IntlShape) => {
  switch (message) {
    case 'unrecognizedDay':
    case 'overlaps':
    case 'orderMatters':
    case 'timeValue':
      return intl.formatMessage(messages[message]);
    default:
      return undefined;
  }
};

const pad = (digits: number): string => {
  return digits.toString().padStart(2, '0');
};

export const availabilityRuleOptions = (interval: 5 | 15): labelValue[] => {
  const options = [];
  for (let hour = 0; hour < 24; hour++) {
    const labelHour = hour % 12 === 0 ? 12 : hour % 12;
    for (let minute = 0; minute < 60; minute += interval) {
      options.push({
        label: `${labelHour}:${pad(minute)}${
          Math.floor(hour / 12) === 1 ? 'pm' : 'am'
        }`,
        value: `${pad(hour)}:${pad(minute)}`,
      });
    }
  }
  options.push({ label: '12:00am', value: '24:00' });
  return options;
};

export const timeOptions = (
  locale: string | undefined,
  moreTimeOptions: boolean,
) => {
  if (locale === 'en-US') {
    return availabilityRuleOptions(moreTimeOptions ? 5 : 15);
  }
  return availabilityRuleOptions(moreTimeOptions ? 5 : 15).map(rule => ({
    label: rule.value,
    value: rule.value,
  }));
};

export type Weekday = Period['day'];

export const weekdayMap: Record<Weekday, React.ReactNode> = {
  sunday: <FormattedMessage {...messages.sun} />,
  monday: <FormattedMessage {...messages.mon} />,
  tuesday: <FormattedMessage {...messages.tues} />,
  wednesday: <FormattedMessage {...messages.wed} />,
  thursday: <FormattedMessage {...messages.thurs} />,
  friday: <FormattedMessage {...messages.fri} />,
  saturday: <FormattedMessage {...messages.sat} />,
};
