import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    sectionHeader: {
      marginBottom: theme.spacing(3),
    },
    fieldContent: {
      marginBottom: theme.spacing(3),
    },
    fieldLabel: {
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(0.75),
    },
    fieldTooltip: {
      marginBottom: theme.spacing(1.75),
    },
    characterCount: {
      marginTop: theme.spacing(0.5),

      '&.error': {
        marginTop: theme.spacing(2),
      },
    },
  }),
);

export default styles;
