import {
  DocumentSigningStagesRuleAttributes,
  PartnerRuleAttributes,
  RuleAttributes,
} from 'api-clients/monolith';
import { v4 as uuid } from 'uuid';

import { MOVE_TO_STAGE } from 'containers/WorkflowEditor/components/Rules/Action/constants';
import {
  APPLICANT_DATA,
  ASSESSMENT_FORM_SCORE,
} from 'containers/WorkflowEditor/components/Rules/Condition/constants';

export const TEXT_AREA = 'text_area';
export const TEXT_FIELD = 'text_field';
export const DROPDOWN = 'dropdown';
export const DATEPICKER = 'datepicker';
export const RADIO = 'radio';
export const CHECKBOXES = 'checkboxes';
export const AVAILABILITY = 'availability';

export const AND = 'AND';
export const OR = 'OR';

export const DEFAULT_RULE: RuleAttributes = {
  action_set_attributes: {
    actions_attributes: [
      {
        id: uuid(),
        type: MOVE_TO_STAGE,
        extra: {},
      },
    ],
  },
  condition_set_attributes: {
    logic: OR,
    conditions_attributes: [
      {
        id: uuid(),
        condition_set_id: undefined,
        extra: {
          comparator: undefined,
          key: undefined,
          value: undefined,
          standard: false,
        },
        type: APPLICANT_DATA,
      },
    ],
  },
};

export const DEFAULT_ASSESSMENT_RULE: RuleAttributes = {
  action_set_attributes: {
    actions_attributes: [
      {
        id: uuid(),
        type: MOVE_TO_STAGE,
        extra: {},
      },
    ],
  },
  condition_set_attributes: {
    logic: OR,
    conditions_attributes: [
      {
        id: uuid(),
        condition_set_id: undefined,
        extra: {
          comparator: undefined,
          key: undefined,
          value: undefined,
          standard: false,
        },
        type: ASSESSMENT_FORM_SCORE,
      },
    ],
  },
};

export const DEFAULT_PARTNER_RULE: PartnerRuleAttributes = {
  action_attributes: {
    id: uuid(),
    type: MOVE_TO_STAGE,
    extra: {},
  },
  condition_attributes: {
    id: uuid(),
    extra: {},
    type: 'PartnerStage::Conditions::PartnerLabelHasKey',
  },
};

export const DEFAULT_DOCUMENT_SIGNING_RULE: DocumentSigningStagesRuleAttributes =
  {
    template_set_attributes: {
      template_settings_attributes: [],
    },
    condition_set_attributes: {
      logic: OR,
      conditions_attributes: [
        {
          id: uuid(),
          condition_set_id: undefined,
          extra: {
            comparator: undefined,
            key: undefined,
            value: undefined,
            standard: false,
          },
          type: APPLICANT_DATA,
        },
      ],
    },
  };

export const DEFAULT_ELSE_ACTION = {
  action_set_type: 'RuleStage::ElseActionSet',
  extra: {},
  type: 'RuleStage::Actions::MoveToStage',
  ref_stage_1_id: null,
};

export const DEFAULT_ASSESSMENT_ELSE_ACTION = {
  action_set_type: 'RuleStage::ElseActionSet',
  extra: {},
  type: 'RuleStage::Actions::DoNotMove',
  ref_stage_1_id: null,
};
