import { WorkflowSchoolkeepStage } from 'api-clients/monolith';
import React, { VFC } from 'react';

import { StageSection } from 'containers/WorkflowEditor/components/StageSection';

import { IdleMoveRule } from '../../../IdleMoveRule';
import { StageSettingMessageCard } from '../../../StageSettingMessageCard';
import { IntegrationCard } from './IntegrationCard';

export interface SchoolkeepStageProps {
  stage: WorkflowSchoolkeepStage;
}

export const SchoolkeepStage: VFC<SchoolkeepStageProps> = ({ stage }) => {
  return (
    <div data-testid="schoolkeepStage">
      <StageSection>
        <IntegrationCard stage={stage} />
      </StageSection>
      <StageSection>
        <IdleMoveRule stage={stage} />
      </StageSection>
      <StageSection>
        <StageSettingMessageCard
          messageType="schoolkeep"
          stageExternalId={stage.external_id}
        />
      </StageSection>
      <StageSection>
        <StageSettingMessageCard
          messageType="trigger"
          stageExternalId={stage.external_id}
        />
      </StageSection>
    </div>
  );
};
