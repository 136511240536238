import { EventsService } from 'api-clients/monolith';
import React, { useCallback, VFC } from 'react';
import { useParams } from 'react-router-dom';

import { useApiService } from 'hooks/useApiService';

import { CreateEvent } from '../CreateEvent';

interface RouteParams {
  eventExternalId: string;
}

export const EditEvent: VFC = () => {
  const { eventExternalId } = useParams<RouteParams>();
  const getEventService = useCallback(
    () => EventsService.getInternalApiEvents1(eventExternalId),
    [eventExternalId],
  );

  const { result } = useApiService(getEventService);

  return (
    <>
      {result.status === 'ready' && (
        <CreateEvent event={result.data} isUpdate />
      )}
    </>
  );
};
