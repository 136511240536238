import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  chatbotFeedback: {
    id: 'app.containers.Chatbot.chatbotFeedback',
    defaultMessage: `Chatbot Review`,
  },
  loadMore: {
    id: 'app.containers.Chatbot.loadMore',
    defaultMessage: `Load More`,
  },
});
