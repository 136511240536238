import React from 'react';

const HtmlSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6998 11.7008L11.2998 10.3008L13.5998 8.00078L11.2998 5.70078L12.6998 4.30078L15.6998 7.30078C16.0998 7.70078 16.0998 8.30078 15.6998 8.70078L12.6998 11.7008Z"
      fill="#131e40"
    />
    <path
      d="M3.3 11.7008L0.3 8.70078C-0.1 8.30078 -0.1 7.70078 0.3 7.30078L3.3 4.30078L4.7 5.70078L2.4 8.00078L4.7 10.3008L3.3 11.7008Z"
      fill="#131e40"
    />
    <path
      d="M5.99976 15.0001C5.89976 15.0001 5.79976 15.0001 5.69976 14.9001C5.19976 14.7001 4.89976 14.2001 5.09976 13.6001L9.09976 1.60015C9.29976 1.10015 9.79976 0.800146 10.3998 1.00015C10.8998 1.20015 11.1998 1.70015 10.9998 2.30015L6.99976 14.3001C6.79976 14.7001 6.39976 15.0001 5.99976 15.0001Z"
      fill="#131e40"
    />
  </svg>
);

export default HtmlSvg;
