import { makeStyles } from '@material-ui/core/styles';
import { ChatbotWidgetSettings } from 'api-clients/monolith';
import React, { useEffect, useState, VFC } from 'react';
import { useIntl } from 'react-intl';

import { Description } from '../Components/Description';
import { InputSetting } from '../Components/InputSetting';
import { SectionTitle } from '../Components/SectionTitle';
import { SettingSwitch } from '../Components/SettingSwitch';
import { SettingTitle } from '../Components/SettingTitle';
import { HandleSettingChange, SelectedBrand } from '../types';
import { DistanceRadiusSelector } from './DistanceRadiusSelector';
import { FunnelSelector } from './FunnelSelector';
import { messages } from './messages';

const useStyles = makeStyles(theme => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
  },
  applyToJobTitle: {
    marginTop: theme.spacing(2),
  },
  viewControlTitle: {
    marginTop: theme.spacing(4),
  },
}));

export interface ApplyToJobSettingsProps {
  settings: ChatbotWidgetSettings;
  selectedBrand: SelectedBrand;
  handleSettingChange: HandleSettingChange;
}

export const ApplyToJobSettings: VFC<ApplyToJobSettingsProps> = ({
  settings,
  selectedBrand,
  handleSettingChange,
}) => {
  const styles = useStyles();
  const intl = useIntl();

  const [showOpeningsSelector, setShowOpeningsSelector] = useState(
    settings.selected_funnels?.length > 0,
  );

  useEffect(() => {
    setShowOpeningsSelector(settings.selected_funnels?.length > 0);
  }, [settings.selected_funnels]);

  const handleOpeningsSelectorToggle = () => {
    setShowOpeningsSelector(!showOpeningsSelector);
  };

  return (
    <section className={styles.section}>
      <SectionTitle value={messages.applyToJobSettings} />
      <SettingTitle
        value={messages.applyToJobSubtitle}
        className={styles.applyToJobTitle}
      />
      <Description value={messages.applyToJobDesc} />
      <DistanceRadiusSelector
        radius={settings.radius}
        onChange={handleSettingChange}
      />
      <InputSetting
        settingKey="location_dropdown_limit"
        settingValue={settings.location_dropdown_limit}
        onChange={handleSettingChange}
        pattern="\b(0|1?\d|20)\b"
        label={intl.formatMessage(messages.locationDropdownLimitLabel)}
        errorMessage={intl.formatMessage(messages.locationDropdownLimitError)}
      />
      <SettingSwitch
        settingKey="show_remote_job"
        label={messages.includeRemoteJobs}
        checked={settings.show_remote_job}
        onChange={handleSettingChange}
      />
      <SettingTitle
        value={messages.viewControl}
        className={styles.viewControlTitle}
      />
      <SettingSwitch
        settingKey="selected_funnels"
        label={messages.selectOpeningsSwitch}
        checked={showOpeningsSelector}
        onChange={handleOpeningsSelectorToggle}
      />
      {showOpeningsSelector && (
        <FunnelSelector
          selectedBrandId={selectedBrand.brandId}
          isAllBrandsSelected={selectedBrand.isAllBrands}
          selectedFunnels={settings.selected_funnels}
          handleSettingChange={handleSettingChange}
        />
      )}
    </section>
  );
};
