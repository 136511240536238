import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  notifyApplicantOnReportCreated: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.BaseSettings.notifyApplicantOnReportCreated`,
    defaultMessage: 'Notify Applicant when report is run',
  },
  notifyOnReportCreated: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.BaseSettings.notifyOnReportCreated`,
    defaultMessage: 'Notify Opening Owner when report is run',
  },
  notifyOnReportClear: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.BaseSettings.notifyOnReportClear`,
    defaultMessage: 'Notify Opening Owner when report status is clear',
  },
  notifyOnReportConsider: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.BaseSettings.notifyOnReportConsider`,
    defaultMessage: 'Notify Opening Owner when report status is consider',
  },
  notifyOnReportSuspended: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.BaseSettings.notifyOnReportSuspended`,
    defaultMessage: 'Notify Opening Owner when report status is suspended',
  },
  notifications: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.BaseSettings.notifications`,
    defaultMessage: 'Notifications',
  },
});
