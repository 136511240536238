import React from 'react';

const MergeKeysSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7 0L6.5 6.3C6 6.1 5.5 6 5 6C2.2 6 0 8.2 0 11C0 13.8 2.2 16 5 16C7.8 16 10 13.8 10 11C10 10.5 9.9 9.9 9.7 9.4L11 8V6H13V4H15L16 3V0H12.7ZM4.5 12C3.7 12 3 11.3 3 10.5C3 9.7 3.7 9 4.5 9C5.3 9 6 9.7 6 10.5C6 11.3 5.3 12 4.5 12Z"
      fill="#131e40"
    />
  </svg>
);

export default MergeKeysSvg;
