import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  deleteEvent: {
    id: 'app.Calendar.EventManager.DeleteEventModal.deleteEvent',
    defaultMessage: 'Delete Event',
  },
  modalBody: {
    id: 'app.Calendar.EventManager.DeleteEventModal.modalBody',
    defaultMessage:
      'Are you sure you want to delete this event? Any previously scheduled appointments that are booked for this event will be cancelled.',
  },
  cancel: {
    id: 'app.Calendar.EventManager.DeleteEventModal.cancel',
    defaultMessage: 'Cancel',
  },
});
