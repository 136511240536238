import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { makeSelectLastFunnelId } from 'containers/Auth_old/selectors';

import NavigationMenu from '../NavigationMenu/index';
import messages from './messages';
import makeStyles from './styles';

function ApplicantsNavMenu({ intl, funnelExternalId }) {
  const { pathname } = useLocation();
  const { accountSlug, jobId } = useParams();
  const isMasterApplicantsView = pathname === `/${accountSlug}/applicants`;
  const classes = makeStyles({ isMasterApplicantsView });
  const lastFunnelId = useSelector(makeSelectLastFunnelId());
  const funnelId = funnelExternalId || jobId || lastFunnelId;

  const at2Url = `/${accountSlug}/jobs/${funnelId}/v2/stages?page=1`;

  const menuItems = {
    label: intl.formatMessage(messages.applicants),
    children: [
      {
        viewable: true,
        isLink: true,
        label: intl.formatMessage(messages.applicantTable),
        to: at2Url,
        partialMatch: true,
        message: messages.applicantTable,
        'data-testid': 'navbar-applicant_table',
      },
      {
        viewable: true,
        isLink: true,
        to: `/${accountSlug}/applicants`,
        label: intl.formatMessage(messages.universalApplicantView),
        message: messages.universalApplicantView,
        'data-testid': 'navbar-universal_applicant_view',
      },
    ],
  };

  const triggerElContents = (
    <>
      <span className={classes.desktopSelectedTableName}>
        <FormattedMessage {...messages.applicants} />
      </span>
      {isMasterApplicantsView && (
        <span className={classes.mobileSelectedTableName}>
          <FormattedMessage {...messages.MAV} />
        </span>
      )}
    </>
  );

  return (
    <NavigationMenu
      menuItems={menuItems}
      id="applicants-menu-trigger"
      triggerElContents={triggerElContents}
      dataTestIds={{
        trigger: 'navbar-applicants',
        popover: 'navbar-applicants-link-container',
      }}
    />
  );
}

ApplicantsNavMenu.propTypes = {
  intl: PropTypes.object.isRequired,
  funnelExternalId: PropTypes.string,
};

export default injectIntl(ApplicantsNavMenu);
