import { CustomTooltip } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';
import React, { VFC } from 'react';
import { useIntl } from 'react-intl';

import { ApplicantAvatar } from './ApplicantAvatar';
import { messages } from './messages';
import { useStyles } from './styles';

export interface ApplicantMessageProps {
  message: string;
}

export const ApplicantMessage: VFC<ApplicantMessageProps> = ({ message }) => {
  const styles = useStyles();
  const intl = useIntl();

  return (
    <Grid item container direction="row" justify="flex-start" wrap="nowrap">
      <Grid item className={styles.avatar}>
        <CustomTooltip
          title={intl.formatMessage(messages.applicantAvatarTooltip)}
          dense
        >
          <ApplicantAvatar />
        </CustomTooltip>
      </Grid>
      <Grid
        item
        className={classNames(styles.messageBubble, styles.applicantBubble)}
      >
        <Typography
          className={classNames(styles.applicantMessage, styles.messageContent)}
        >
          {message}
        </Typography>
      </Grid>
    </Grid>
  );
};
