import { InputLabel, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Workflow } from 'api-clients/monolith';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Error } from 'components/Error';
import { useDebounce } from 'hooks/useDebounce';
import { useWorkflows } from 'hooks/useWorkflows';

import { messages } from './messages';
import { useStyles } from './styles';

interface WorkflowSelectProps {
  onChange: (workflow: Workflow) => void;
  onClear: () => void;
  workflowId: string | null;
  fromWorkflowId: string;
  error?: string;
}

export const WorkflowSelect: React.VFC<WorkflowSelectProps> = ({
  onChange,
  onClear,
  workflowId,
  fromWorkflowId,
  error,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const [workflowTitle, setWorkflowTitle] = useState('');
  const debounceSetWorkflowTitle = useDebounce(setWorkflowTitle, 500);
  const { result: workflowsResult } = useWorkflows({
    title: workflowTitle,
  });

  const handleWorkflowInputChange = useCallback(
    (_event: unknown, value: string) => void debounceSetWorkflowTitle(value),
    [debounceSetWorkflowTitle],
  );

  return (
    <>
      <InputLabel
        className={classes.workflowLabel}
        htmlFor="workflow-search-input"
      >
        {intl.formatMessage(messages.workflow)}
      </InputLabel>
      <Autocomplete
        aria-label={intl.formatMessage(messages.destinationWorkflow)}
        data-testid="destination-workflow-select"
        filterOptions={x => x}
        options={
          workflowsResult.status === 'ready'
            ? workflowsResult.data.workflows
            : []
        }
        getOptionLabel={option => option.title}
        onChange={(_, option) => (option ? onChange(option) : onClear())}
        getOptionDisabled={opt => opt.id === fromWorkflowId}
        onInputChange={handleWorkflowInputChange}
        value={
          workflowsResult.status === 'ready'
            ? workflowsResult.data.workflows.find(wf => wf.id === workflowId) ??
              null
            : null
        }
        renderInput={params => (
          <div>
            <TextField
              {...params}
              margin="dense"
              variant="outlined"
              id="workflow-search-input"
              name={intl.formatMessage(messages.destinationWorkflow)}
              placeholder={intl.formatMessage(messages.destinationWorkflow)}
              error={!!error}
            />
            {error && <Error error={error} />}
          </div>
        )}
      />
      {workflowsResult.isError && (
        <Typography variant="body2" color="error">
          <FormattedMessage {...messages.unableToLoadWorkflows} />
        </Typography>
      )}
    </>
  );
};
