import Grid from '@material-ui/core/Grid';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import GlobalNav from 'components/GlobalNav';

import { messages } from './messages';
import { useStyles } from './styles';

export const BulkLocations = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage(messages.bulkLocationsTitle)}</title>
        <meta name="description" content="Bulk Locations" />
      </Helmet>
      <Grid container direction="column" className={classes.container}>
        <GlobalNav />
        <Grid container direction="column" className={classes.content}>
          <h1>{intl.formatMessage(messages.bulkLocationsTitle)}</h1>
          <p>
            Welcome to the Bulk Locations Beta. Thanks for helping us test this
            feature! As with any beta feature, be careful when using this to
            interact with production data. If you have any questions or
            feedback, please reach out to the OX development team.
          </p>
        </Grid>
      </Grid>
    </>
  );
};

export default BulkLocations;
