import { defineMessages } from 'react-intl';

export default defineMessages({
  question: {
    id: 'app.containers.Chatbot.AutomatedResponse.question',
    defaultMessage: `Question`,
  },
  intent: {
    id: 'app.containers.Chatbot.AutomatedResponse.intent',
    defaultMessage: `Intent`,
  },
  message: {
    id: 'app.containers.Chatbot.AutomatedResponse.message',
    defaultMessage: `Message`,
  },
  label: {
    id: 'app.containers.Chatbot.AutomatedResponse.label',
    defaultMessage: `Label`,
  },
  messageTemplates: {
    id: 'app.containers.Chatbot.AutomatedResponse.messageTemplates',
    defaultMessage: `Message Templates`,
  },
  requiredField: {
    id: 'app.containers.Chatbot.AutomatedResponse.requiredField',
    defaultMessage: `Required Field`,
  },
  create: {
    id: 'app.containers.Chatbot.AutomatedResponse.create',
    defaultMessage: `Create`,
  },
  update: {
    id: 'app.containers.Chatbot.AutomatedResponse.update',
    defaultMessage: `Update`,
  },
  createAutomatedResponseSuccess: {
    id: 'app.containers.Chatbot.AutomatedResponse.createAutomatedResponseSuccess',
    defaultMessage: `Automated Response Created Successfully`,
  },
  updateAutomatedResponseSuccess: {
    id: 'app.containers.Chatbot.AutomatedResponse.updateAutomatedResponseSuccess',
    defaultMessage: `Automated Response Updated Successfully`,
  },
});
