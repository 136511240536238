import { StyledReactSelect } from '@fountain/fountain-ui-components';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import { addDefaultErrorMessageAction } from 'containers/FlashMessage/actions';
import { useDebounce } from 'hooks/useDebounce';
import { apiGet } from 'utils/axios';

import messages from './messages';

const fetchData = async (
  setData,
  setIsFetching,
  dispatchError,
  partialUrl,
  optionBankId,
  searchQuery,
) => {
  const queryParams = {
    option_bank_id: optionBankId,
    search_query: searchQuery,
  };
  const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/${partialUrl}?${queryString.stringify(
    queryParams,
  )}`;

  setIsFetching(true);

  try {
    const res = await apiGet(url);
    if (res && res.data) {
      setData(res.data.options);
    }
  } catch (e) {
    dispatchError(addDefaultErrorMessageAction());
  } finally {
    setIsFetching(false);
  }
};

const OptionBankDropdown = ({
  className,
  error,
  onChange,
  optionBankId,
  partialUrl,
  value,
  ...props
}) => {
  const [searchTerm, setSearchTerm] = useState((value && value.label) || '');
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const dispatchError = useDispatch();

  const debouncedSearchTerm = useDebounce(newValue => {
    if (newValue !== searchTerm) setSearchTerm(newValue);
  }, 100);

  const getNoOptionsMessage = inputValue => {
    if (inputValue.inputValue.length === 0) {
      return <FormattedMessage {...messages.minimumInputWarning} />;
    }
    if (inputValue.inputValue > 0) {
      return <FormattedMessage {...messages.noOptions} />;
    }
    return undefined;
  };

  const handleInputChange = newValue => {
    debouncedSearchTerm(newValue);
  };

  useEffect(() => {
    if (searchTerm.length > 0) {
      fetchData(
        setData,
        setIsFetching,
        dispatchError,
        partialUrl,
        optionBankId,
        searchTerm,
      );
    }
  }, [dispatchError, optionBankId, partialUrl, searchTerm]);

  return (
    <StyledReactSelect
      className={className}
      getOptionLabel={option => option.label}
      getOptionValue={option => option.value}
      isSearchable
      isLoading={isFetching}
      noOptionsMessage={getNoOptionsMessage}
      placeholder={<FormattedMessage {...messages.startTyping} />}
      onInputChange={handleInputChange}
      options={data ?? []}
      onChange={onChange}
      value={value}
      error={error}
      {...props}
    />
  );
};

OptionBankDropdown.propTypes = {
  className: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  optionBankId: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]),
  partialUrl: PropTypes.string.isRequired,
  value: PropTypes.object,
};

export default OptionBankDropdown;
