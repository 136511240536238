import { Grid } from '@material-ui/core';
import React, { FC } from 'react';

import { SharedPartnerConditionProps } from '../../PartnerRules/types';
import { SharedConditionProps } from '../../types';
import { ComparatorSelect } from '../components/ComparatorSelect';
import { PartnerDetailSelect } from '../components/PartnerDetailSelect';
import { PartnerOptionSelect } from '../components/PartnerOptionSelect';
import { PartnerSelect } from '../components/PartnerSelect';
import { InputField } from '../InputField';
import { useStyles } from '../styles';

export const PartnerDetailHasKeyWithValue: FC<
  SharedConditionProps | SharedPartnerConditionProps
> = props => {
  const styles = useStyles();

  return (
    <>
      {!props.setPartnerRules && <PartnerSelect {...props} />}
      <PartnerDetailSelect {...props} />
      <ComparatorSelect {...props} />
      <Grid xs={6} className={styles.input}>
        <InputField {...props} />
      </Grid>
      {!props.setPartnerRules && <PartnerOptionSelect {...props} />}
    </>
  );
};
