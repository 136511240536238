import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  selectOpenings: {
    id: `app.containers.WorkflowEditor.components.StageDetail.JobRouterStages.JobSelectorStage.selectOpenings`,
    defaultMessage: 'Select openings to show',
  },
  selectionConditions: {
    id: `app.containers.WorkflowEditor.components.StageDetail.JobRouterStages.JobSelectorStage.selectionConditions`,
    defaultMessage: 'Selection Conditions',
  },
  selectConditionsDescription: {
    id: `app.containers.WorkflowEditor.components.StageDetail.JobRouterStages.JobSelectorStage.selectConditionsDescription`,
    defaultMessage:
      'Select conditions to create a list of openings that applicants can select between to continue their application. Applicants will automatically be moved to the top of funnel on the opening they selected. If the applicant selects their current opening, they will be moved to the next stage.',
  },
});
