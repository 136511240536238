import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  alertContainer: {
    marginBottom: theme.spacing(1.5),
  },
  switchLabel: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.common.gray800,
  },
  fieldRow: {
    paddingBottom: theme.spacing(3),

    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cardContainer: {
    padding: theme.spacing(3, 2),
    '&:first-child': {
      paddingTop: 0,
    },
    '&:not(:first-child)': {
      borderTop: theme.customBorders.gray300,
    },
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
  switchWithInputField: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  nestedInput: {
    maxWidth: 120,
  },
  nestedInputLabel: {
    marginBottom: theme.spacing(0.5),
  },
  nestedInputRowContainer: {
    display: 'flex',
    flexFlow: 'row',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    maxWidth: 300,
    minWidth: 250,
  },
  advancedBookingOptionsToggle: {
    marginLeft: theme.spacing(-2),
    marginTop: theme.spacing(1),
    color: theme.palette.common.gray800,
  },
  advancedBookingOptionsSpacing: {
    marginBottom: theme.spacing(3.5),
  },
  caretIconOpened: {
    height: theme.spacing(1),
    transform: 'rotate(90deg)',
    transition: 'transform 0.5s',
  },
  caretIconClosed: {
    height: theme.spacing(1),
    transform: 'rotate(-90deg)',
    transition: 'transform 0.5s',
  },
  advancedBookingOptionsContent: {
    marginLeft: theme.spacing(2),
  },
  slotsAvailableContent: {
    backgroundColor: theme.palette.common.gray100,
    border: theme.customBorders.gray300,
    borderRadius: theme.spacing(0.625),
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  scheduleCalendar: {
    marginRight: theme.spacing(0.5),
  },
  slotsAvailableCount: {
    marginBottom: theme.spacing(0.5),
  },
  slotsAvailableText: {
    color: theme.palette.common.gray800,
  },
  setTimeSlotsDescription: {
    color: theme.palette.common.gray800,
  },
  availabilityContainer: {
    display: 'flex',
    paddingBottom: theme.spacing(3),
  },
  setAvailabilityButton: {
    color: theme.palette.common.blue400,
    marginTop: theme.spacing(1.5),
  },
  setAvaibilityContent: {
    marginLeft: theme.spacing(3),
  },
  enableBotHeader: {
    color: theme.palette.common.gray800,
  },
  enableBotItemContainer: {
    padding: theme.spacing(3),
    marginLeft: theme.spacing(4),
  },
  enableBotItem: {
    paddingRight: theme.spacing(2),
  },
}));
