import { useIntl } from 'react-intl';

import globalMessages from 'shared/global/messages';

import messages from './messages';

const useTranslatedConstants = () => {
  const intl = useIntl();

  const SEND_TYPE = {
    NOW: 'now',
    LATER: 'later',
  };

  const SEND_TYPE_OPTIONS = [
    {
      label: intl.formatMessage(globalMessages.now),
      value: SEND_TYPE.NOW,
    },
    {
      label: intl.formatMessage(globalMessages.later),
      value: SEND_TYPE.LATER,
    },
  ];

  const DEFAULT_TEMPLATE = {
    email_subject: intl.formatMessage(messages.noTemplateSelected),
    id: '__no_template__',
  };

  const I9_EMAIL_TEMPLATE = intl.formatMessage(messages.i9EmailTemplate, {
    applicant: '[APPLICANT_NAME]',
    portal: '[PORTAL_I9_FORM_LINK]',
    htmlBreakTag: '<br/><br/>',
  });

  const W4_FEDERAL_EMAIL_TEMPLATE = intl.formatMessage(
    messages.w4FederalEmailTemplate,
    {
      applicant: '[APPLICANT_NAME]',
      portal: '[PORTAL_W4_FEDERAL_FORM_LINK]',
      htmlBreakTag: '<br/><br/>',
    },
  );

  const W4_STATE_EMAIL_TEMPLATE = intl.formatMessage(
    messages.w4StateEmailTemplate,
    {
      applicant: '[APPLICANT_NAME]',
      portal: '[PORTAL_W4_STATE_FORM_LINK]',
      htmlBreakTag: '<br/><br/>',
    },
  );

  const I9_SECTION_THREE_REMOTE_EMAIL_TEMPLATE = intl.formatMessage(
    messages.i9SectionThreeRemoteEmailTemplate,
    {
      applicant: '[APPLICANT_NAME]',
      portal: '[PORTAL_I9_SECTION_THREE_FORM_LINK]',
      htmlBreakTag: '<br/><br/>',
    },
  );

  const I9_SUBJECT = intl.formatMessage(messages.i9Subject);
  const W4_FEDERAL_SUBJECT = intl.formatMessage(messages.w4FederalSubject);
  const W4_STATE_SUBJECT = intl.formatMessage(messages.w4StateSubject);
  const I9_SECTION_THREE_REMOTE_SUBJECT = intl.formatMessage(
    messages.i9SectionThreeRemoteSubject,
  );

  const REQUEST_REVISION_SUBJECT = intl.formatMessage(
    messages.requestRevisionSubject,
  );
  const REQUEST_REVISION_BODY = intl.formatMessage(
    messages.requestRevisionBody,
    { applicant: `[APPLICANT_NAME]`, portal: `[PORTAL_LINK]` },
  );

  return {
    SEND_TYPE,
    SEND_TYPE_OPTIONS,
    DEFAULT_TEMPLATE,
    I9_EMAIL_TEMPLATE,
    I9_SECTION_THREE_REMOTE_EMAIL_TEMPLATE,
    I9_SUBJECT,
    I9_SECTION_THREE_REMOTE_SUBJECT,
    REQUEST_REVISION_SUBJECT,
    REQUEST_REVISION_BODY,
    W4_FEDERAL_SUBJECT,
    W4_FEDERAL_EMAIL_TEMPLATE,
    W4_STATE_SUBJECT,
    W4_STATE_EMAIL_TEMPLATE,
  };
};

export default useTranslatedConstants;
