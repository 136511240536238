import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { WorkflowW4FormStage } from 'api-clients/monolith';
import produce from 'immer';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { StateOption, stateOptions } from '../../constants';
import { W4Form } from '../W4Form';
import { messages } from './messages';
import { useStyles } from './styles';

export interface W4StateFormProps {
  stage: WorkflowW4FormStage;
}

export const W4StateForm: FC<W4StateFormProps> = ({ stage }) => {
  const styles = useStyles();
  const intl = useIntl();

  const { setStage } = useContext(StageContext);
  const {
    extra: { state },
  } = stage;

  const onChangeState = (newState: StateOption) => {
    setStage(
      produce(stage, draftStage => {
        draftStage.extra.state = newState.value;
      }),
    );
  };

  const selectedState = stateOptions.find(option => option.value === state);

  const nonFederalStates = stateOptions.filter(state => {
    return !state.usesFederalForm;
  });

  return (
    <W4Form stage={stage} styles={styles} messages={messages}>
      <Grid item xs={12} sm={10} md={6} lg={4} className={styles.stateDropdown}>
        <StyledReactSelect
          label={intl.formatMessage(messages.selectState)}
          aria-label={intl.formatMessage(messages.selectState)}
          options={nonFederalStates}
          onChange={onChangeState}
          value={selectedState}
          isSearchable
          getOptionLabel={(option: StateOption) => {
            if (option.usesFederalForm) {
              return `${option.label} (Uses Federal form)`;
            }

            return option.label;
          }}
          required
        />
      </Grid>
    </W4Form>
  );
};
