/**
 *
 * AccountChooser
 *
 */

import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Typography,
  withStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Work from '@material-ui/icons/Work';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import AccountsFilterMenu from 'containers/AccountChooser/components/AccountsFilterMenu';
import {
  accountsInitAction,
  setSelectedAccountAction,
  usersInitAction,
} from 'containers/Auth_old/actions';
import { makeSelectAccounts } from 'containers/Auth_old/selectors';

import styles from './styles';

class AccountChooser extends React.PureComponent {
  button = null;

  state = {
    anchorEl: null,
    selectedAccountId: '',
  };

  componentDidMount() {
    const { getAccounts } = this.props;
    getAccounts();
  }

  handleClickListItem = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleAccountChange = accountId => {
    this.setState({ selectedAccountId: accountId, anchorEl: null });
    const { accounts, getUsers, setSelectedAccount } = this.props;
    const selectedaccount = accounts.find(
      account => account.external_id === accountId,
    );
    setSelectedAccount(selectedaccount);
    getUsers(selectedaccount);
  };

  componentWillReceiveProps(newProps) {
    const { accounts } = newProps;
    let selectedAccountId = '';
    for (let i = 0; i < accounts.length; i += 1) {
      const account = accounts[i];
      if (account.isSelected) {
        selectedAccountId = account.external_id;
        this.setState({ selectedAccountId });
        this.props.getUsers(account);
        break;
      }
    }
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  getSelectedAccountName = () => {
    const { accounts } = this.props;
    const { selectedAccountId } = this.state;
    const selectedAccount = accounts.find(
      account => account.external_id === selectedAccountId,
    );
    return selectedAccount ? selectedAccount.name : '';
  };

  render() {
    const { classes, accounts } = this.props;
    const { anchorEl, selectedAccountId } = this.state;
    return (
      <div>
        <List component="nav" className={classes.nav}>
          <ListItem
            button
            aria-haspopup="true"
            aria-controls="lock-menu"
            aria-label="Account"
            onClick={this.handleClickListItem}
            className={classes.listItem}
          >
            <ListItemIcon className={classes.icon}>
              <Work />
            </ListItemIcon>
            <ListItemText
              className={classes.listItemText}
              primary={
                accounts.length ? (
                  <Grid>
                    <Typography className={classes.name} variant="h3">
                      {this.getSelectedAccountName()}
                    </Typography>
                  </Grid>
                ) : (
                  <p />
                )
              }
            />
            <ListItemIcon className={classes.icon}>
              <ExpandMoreIcon />
            </ListItemIcon>
          </ListItem>
        </List>
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <AccountsFilterMenu
            accounts={accounts}
            handleAccountChange={this.handleAccountChange}
            selectedAccountId={selectedAccountId}
            url="/"
            key={selectedAccountId}
          />
        </Menu>
      </div>
    );
  }
}

AccountChooser.propTypes = {
  classes: PropTypes.object.isRequired,
  accounts: PropTypes.array,
  getAccounts: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  setSelectedAccount: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  accounts: makeSelectAccounts(),
});

AccountChooser.defaultProps = {
  accounts: [],
};

function mapDispatchToProps(dispatch) {
  return {
    getAccounts: () => dispatch(accountsInitAction()),
    getUsers: account => dispatch(usersInitAction(account)),
    setSelectedAccount: account => dispatch(setSelectedAccountAction(account)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(styles),
  withConnect,
  withRouter,
)(AccountChooser);
