import { Drawer as DrawerPopup } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { FunnelDetails, SidebarStage } from 'api-clients/monolith';
import React, { FC } from 'react';

import { StageHeader } from 'containers/WorkflowEditor/components/StageHeader';
import { StageContextProvider } from 'containers/WorkflowEditor/StageContextProvider/StageContextProvider';

import { SubstageContainerByType } from '../../SubstageContainerByType';
import { useStyles } from './styles';

export interface SubstageDrawerProps {
  funnelStages: SidebarStage[];
  handleClose: () => void;
  open: boolean;
  opening: FunnelDetails;
  stageExternalId: string | null;
}

export const SubstageDrawer: FC<SubstageDrawerProps> = ({
  opening,
  funnelStages,
  stageExternalId,
  handleClose,
  open,
}) => {
  const styles = useStyles();

  return (
    <DrawerPopup handleClose={handleClose} open={open}>
      {stageExternalId && (
        // Adding an additional, nested `StageContextProvider` here for the drawer to handle updates to a substage independent of the parent StageDetail view in the background
        <Grid className={styles.stageContent}>
          <StageContextProvider
            externalId={stageExternalId}
            funnelStages={funnelStages}
            withSidebar={false}
            opening={opening}
          >
            <StageHeader opening={opening} />
            <SubstageContainerByType opening={opening} />
          </StageContextProvider>
        </Grid>
      )}
    </DrawerPopup>
  );
};
