import { TableCell } from '@material-ui/core';
import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { makeSelectTableColumnWidths } from 'containers/ApplicantTableV2/selectors';
import {
  Applicant,
  TableColumnWidthsKey,
} from 'containers/ApplicantTableV2/types';
import StatusLabels from 'containers/StatusLabels';

import useStyles from './styles';

export interface ApplicantTableV2StatusLabelsCellProps {
  applicant: Applicant;
  columnType: TableColumnWidthsKey;
}

export const ApplicantTableV2StatusLabelsCell: FC<
  ApplicantTableV2StatusLabelsCellProps
> = ({ applicant, columnType }) => {
  const classes = useStyles();
  const tableColumnWidths = useSelector(makeSelectTableColumnWidths());

  return (
    <TableCell
      role="cell"
      style={{
        minWidth: tableColumnWidths[columnType],
        maxWidth: tableColumnWidths[columnType],
      }}
      className={classes.td}
    >
      {Boolean(applicant.status.length) && (
        <StatusLabels applicant={applicant} />
      )}
    </TableCell>
  );
};

export default memo(ApplicantTableV2StatusLabelsCell);
