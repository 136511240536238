import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  moveToButton: {
    display: 'flex',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',
    minWidth: '155px',

    '& .MuiButton-label': {
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  rejectButton: {
    marginLeft: theme.spacing(1),
    width: '73px',
  },
}));
