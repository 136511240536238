import { IconButton, Trashcan } from '@fountain/fountain-ui-components';
import { TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AvailabilityRuleError, Period } from 'api-clients/monolith';
import { messages } from 'Calendar/AvailabilityRules/messages';
import {
  errorMessage,
  getOption,
  timeOptions,
  Weekday,
  weekdayMap,
} from 'Calendar/AvailabilityRules/utils';
import { CalendarContext } from 'Calendar/context';
import React, { useContext, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Error } from 'components/Error';
import { makeSelectWhoami } from 'containers/Auth_old/selectors';
import { PlusIcon } from 'images/PlusIcon';

import { useStyles } from './styles';

export interface RowProps {
  /**
   * @description day of the week that the periods take place
   */
  day: Weekday;
  periods: Period[];
  errors: AvailabilityRuleError[];
  endTime: string;
  onAddRow: () => void;
  onRemoveRow: (key: string | undefined) => void;
  onEditRow: ({
    key,
    start,
    end,
  }: {
    key: string;
    start?: string;
    end?: string;
  }) => void;
}

export const Row: VFC<RowProps> = ({
  periods,
  day,
  errors,
  onAddRow,
  onRemoveRow,
  onEditRow,
  endTime,
}) => {
  const styles = useStyles();
  const { locale } = useSelector(makeSelectWhoami());
  const intl = useIntl();
  const { has_cronofy_slots: moreTimeOptions } = useContext(CalendarContext);
  const startOptions = timeOptions(locale, moreTimeOptions).slice(0, -1);
  const endOptions = timeOptions(locale, moreTimeOptions).slice(1);
  const showAdd = endTime !== '24:00';

  return (
    <>
      <div className={styles.row}>
        <Typography color="textPrimary" className={styles.day}>
          {weekdayMap[day]}
        </Typography>
        {!periods.length && (
          <Typography variant="body2" className={styles.unavailable}>
            <FormattedMessage {...messages.unavailable} />
          </Typography>
        )}
        <div className={styles.periodWrapper}>
          {periods.map(period => {
            const periodErrod = errors?.find(
              error => error.key === period.key,
            )?.message;
            return (
              <>
                <div key={period.key} className={styles.subRow}>
                  <div>
                    <Autocomplete
                      value={getOption({
                        options: startOptions,
                        value: period.start_time,
                      })}
                      key={`${period.key}_start`}
                      onChange={(_, option) =>
                        onEditRow({ key: period.key, start: option?.value })
                      }
                      options={startOptions}
                      disableClearable
                      className={styles.dropDown}
                      getOptionLabel={option => option.label}
                      renderInput={params => (
                        <TextField
                          margin="dense"
                          {...params}
                          variant="outlined"
                          name={`${period.day}.start_time.${period.key}`}
                          className={styles.timeInput}
                          error={Boolean(
                            errors?.find(error => error.key === period.key),
                          )}
                        />
                      )}
                    />
                  </div>
                  -
                  <div>
                    <Autocomplete
                      value={getOption({
                        options: endOptions,
                        value: period.end_time,
                      })}
                      key={`${period.key}_end`}
                      options={endOptions}
                      className={styles.dropDown}
                      disableClearable
                      getOptionLabel={option => option.label}
                      onChange={(_e, option) => {
                        onEditRow({ key: period.key, end: option?.value });
                      }}
                      renderInput={params => (
                        <TextField
                          margin="dense"
                          {...params}
                          variant="outlined"
                          name={`${period.day}.end_time.${period.key}`}
                          className={styles.timeInput}
                          error={Boolean(
                            errors?.find(error => error.key === period.key),
                          )}
                        />
                      )}
                    />
                  </div>
                  <IconButton
                    data-testid="trashcan-icon-button"
                    size="small"
                    onClick={() => onRemoveRow(period.key)}
                  >
                    <Trashcan
                      viewBox="0 0 16 16"
                      className={styles.trashcanIcon}
                    />
                  </IconButton>
                </div>
                <Error
                  error={errorMessage(periodErrod, intl)}
                  className={styles.errRow}
                />
              </>
            );
          })}
        </div>
        {showAdd && (
          <IconButton
            data-testid="plus-icon-button"
            className={styles.iconButton}
            onClick={onAddRow}
          >
            <PlusIcon color="inherit" fontSize="small" />
          </IconButton>
        )}
      </div>
    </>
  );
};
