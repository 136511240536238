import { MenuItem, Trashcan } from '@fountain/fountain-ui-components';
import { Grid, Menu, Typography } from '@material-ui/core';
import classNames from 'classnames';
import React, { VFC } from 'react';
import { useIntl } from 'react-intl';

import { CopyIcon } from './CopyIcon';
import { messages } from './messages';
import { useStyles } from './styles';

interface ListItemProps {
  text: string;
  icon: React.ReactNode;
  onClick: (externalId: string) => void;
  externalId: string;
  className?: string;
}

const ListItem: VFC<ListItemProps> = ({
  text,
  icon,
  onClick,
  className,
  externalId,
}) => {
  const styles = useStyles();

  return (
    <MenuItem
      onClick={() => onClick(externalId)}
      size="small"
      className={classNames(styles.listItem, styles.list)}
    >
      {icon}
      <Grid container justify="space-between" wrap="nowrap">
        <Typography
          className={className}
          align="right"
          variant="body2"
          color="textPrimary"
        >
          {text}
        </Typography>
      </Grid>
    </MenuItem>
  );
};

export interface DropdownMenuProps {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  duplicateEvent: (externalId: string) => void;
  deleteEvent: (externalId: string) => void;
  externalId: string;
}

export const DropdownMenu: VFC<DropdownMenuProps> = ({
  anchorEl,
  handleClose,
  duplicateEvent,
  deleteEvent,
  externalId,
}) => {
  const styles = useStyles();
  const intl = useIntl();

  const onDuplicateEvent = (externalId: string) => {
    duplicateEvent(externalId);
    handleClose();
  };

  const onDeleteEvent = (externalId: string) => {
    deleteEvent(externalId);
    handleClose();
  };

  return (
    <Menu
      id="more-actions-menu"
      role="menu"
      aria-label={intl.formatMessage(messages.moreActionsMenu)}
      classes={{ paper: styles.menuPaper, list: styles.list }}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        style: {
          width: anchorEl?.clientWidth ?? 'initial',
        },
      }}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <ListItem
        onClick={onDuplicateEvent}
        icon={<CopyIcon className={classNames(styles.copy, styles.icon)} />}
        text={intl.formatMessage(messages.duplicateEvent)}
        externalId={externalId}
      />
      <ListItem
        onClick={onDeleteEvent}
        icon={<Trashcan className={classNames(styles.trashcan, styles.icon)} />}
        text={intl.formatMessage(messages.deleteEvent)}
        className={styles.deleteEvent}
        externalId={externalId}
      />
    </Menu>
  );
};
