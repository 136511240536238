import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import React, { VFC } from 'react';
import { useIntl } from 'react-intl';

import { messages } from './messages';
import { useStyles } from './styles';

export const statusFilterOptions = ['All', 'Trained', 'Not Trained'] as const;
export type SelectedStatus = (typeof statusFilterOptions)[number];
export interface StatusFilterOption {
  key: SelectedStatus;
}

export interface StatusFilterProps {
  selectedStatus: SelectedStatus;
  handleStatusChange: (status: SelectedStatus) => void;
}

export const StatusFilter: VFC<StatusFilterProps> = ({
  selectedStatus,
  handleStatusChange,
}) => {
  const styles = useStyles();
  const intl = useIntl();

  return (
    <Grid item>
      <StyledReactSelect
        options={statusFilterOptions.map(item => ({
          key: item,
        }))}
        value={{ key: selectedStatus }}
        onChange={(option: StatusFilterOption) =>
          handleStatusChange(option.key)
        }
        label={intl.formatMessage(messages.statusLabel)}
        aria-label={intl.formatMessage(messages.statusLabel)}
        getOptionLabel={(option: StatusFilterOption) => option.key}
        getOptionValue={(option: StatusFilterOption) => option.key}
        className={styles.selectInput}
      />
    </Grid>
  );
};
