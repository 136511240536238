import { Grid } from '@material-ui/core';
import React, { FC } from 'react';

import { Tag } from 'components/Tag';

export interface RowTagProps {
  classes?: {
    tag: string;
  };
  className?: string;
  label: string;
  tagWidth?: number;
}

export const RowTag: FC<RowTagProps> = ({
  classes,
  className,
  label,
  tagWidth,
}) => {
  return (
    <Grid item className={className} style={{ minWidth: tagWidth }}>
      <Tag className={classes?.tag} label={label} />
    </Grid>
  );
};
