import PropTypes from 'prop-types';
import React from 'react';

const WarningSvg = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M15.2 13.5L8.9 1.7C8.5 1 7.5 1 7.1 1.7L0.799999 13.5C0.399999 14.2 0.899999 15 1.7 15H14.4C15.1 15 15.6 14.2 15.2 13.5ZM8 13C7.5 13 7 12.5 7 12C7 11.5 7.5 11 8 11C8.5 11 9 11.5 9 12C9 12.5 8.5 13 8 13ZM9 9C9 9.5 8.5 10 8 10C7.5 10 7 9.5 7 9V6.3C7 5.8 7.5 5.3 8 5.3C8.5 5.3 9 5.8 9 6.3V9Z"
      fill="#FF721A"
    />
  </svg>
);

WarningSvg.propTypes = {
  className: PropTypes.string,
};

export default WarningSvg;
