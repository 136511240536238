import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  answeredCorrect: {
    id: 'app.components.AssessmentFormModal.answeredCorrect',
    defaultMessage: 'Answered Correct: {score}',
  },
  completionDate: {
    id: 'app.components.AssessmentFormModal.completionDate',
    defaultMessage: 'Completed on {completionDate}',
  },
  correctAnswer: {
    id: 'app.components.AssessmentFormModal.correctAnswer',
    defaultMessage: '(correct answer)',
  },
  weightedScore: {
    id: 'app.components.AssessmentFormModal.weightedScore',
    defaultMessage:
      '{points, plural,=0 {0 points} one {# point} other {# points}}',
  },
  applicantErrorToast: {
    id: 'app.components.AssessmentFormModal.applicantErrorToast',
    defaultMessage: 'Applicant data not found',
  },
  tooltip: {
    id: 'app.components.AssessmentFormModal.tooltip',
    defaultMessage:
      "These are the candidate's recorded answers to this assessment",
  },
  download: {
    id: 'app.components.AssessmentFormModal.download',
    defaultMessage: 'Download',
  },
});
