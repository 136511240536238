import { useFormBuilderContextState } from 'components/FormBuilder/context';
import { RadioErrors } from 'components/FormBuilder/schemas';

import { isRadioDataField } from './helpers';

export const useRadioDataField = () => {
  const { values, errors, ...rest } = useFormBuilderContextState();

  if (!isRadioDataField(values)) {
    throw new Error(
      `useRadioDataField must be used where a radio input is expected, instead input of type: ${values.type} was received.`,
    );
  }

  return {
    ...rest,
    values,
    errors: errors as RadioErrors,
  };
};
