import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  automatedResponses: {
    id: 'app.containers.Chatbot.AutomatedResponse.automatedResponses',
    defaultMessage: `Automated Responses`,
  },
  noAutomatedResponses: {
    id: 'app.containers.Chatbot.AutomatedResponse.noAutomatedResponses',
    defaultMessage: `No automated responses! Please import or add new.`,
  },
  deleteAutomatedResponseSuccess: {
    id: 'app.containers.Chatbot.AutomatedResponse.deleteAutomatedResponseSuccess',
    defaultMessage: `Automated response deleted successfully.`,
  },
  deleteAutomatedResponseError: {
    id: 'app.containers.Chatbot.AutomatedResponse.deleteAutomatedResponseError',
    defaultMessage: `Error deleting automated response.`,
  },
  editMessageTemplate: {
    id: 'app.containers.Chatbot.AutomatedResponse.editMessageTemplate',
    defaultMessage: `Edit message template`,
  },
  deleteConfirmationModalContent: {
    id: 'app.containers.Chatbot.AutomatedResponse.deleteConfirmationModalContent',
    defaultMessage: `Are you sure you want to delete the automated response?`,
  },
  deleteConfirmationModalTitle: {
    id: 'app.containers.Chatbot.AutomatedResponse.deleteConfirmationModalTitle',
    defaultMessage: `Delete automated response`,
  },
  chooseModel: {
    id: 'app.containers.Chatbot.Automatedreponses.chooseModel',
    defaultMessage: 'Choose a model to view the automated responses.',
  },
});
