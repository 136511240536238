import { createSelector } from 'reselect';

import { initialState } from './reducer';

/**
 * Direct selector to the account state domain
 */

const selectAccountDomain = state => state.account || initialState;

const makeSelectAccountDidSave = () =>
  createSelector(selectAccountDomain, substate => substate.saved);

const makeSelectAccountError = () =>
  createSelector(selectAccountDomain, substate => substate.saved);

const makeSelectAccountUpdateLoading = () =>
  createSelector(selectAccountDomain, substate => substate.loading);

const makeSelectMobileDrawerOpen = () =>
  createSelector(selectAccountDomain, substate => substate.mobileDrawerOpen);

/**
 * Other specific selectors
 */

/**
 * Default selector used by Account
 */

const makeSelectAccount = () =>
  createSelector(selectAccountDomain, substate => substate);

export default makeSelectAccount;
export {
  makeSelectAccountDidSave,
  makeSelectAccountError,
  makeSelectAccountUpdateLoading,
  makeSelectMobileDrawerOpen,
  selectAccountDomain,
};
