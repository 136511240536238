import { defineMessages } from 'react-intl';

export const scope = 'app.components.PublicNavbar';

export default defineMessages({
  requestDemo: {
    id: `${scope}.requestDemo`,
    defaultMessage: 'Request Demo',
  },
});
