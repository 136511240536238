import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  REACT_APP_GLOBAL_API_BASE_URL,
  REACT_APP_GLOBAL_API_BASE_URL_V2,
} from 'runtimeEnvVars';

import { resetStageApplicantCountAction } from 'containers/ApplicantsV2/actions';
import {
  deleteApplicantErrorAction,
  deleteApplicantSuccessAction,
  removeApplicantFromStageAction,
  resetSelectedApplicantIds,
  updateApplicantDataFieldColumn,
} from 'containers/ApplicantTableV2/actions';
import { DELETE_APPLICANT_START } from 'containers/ApplicantTableV2/constants';
import {
  addDefaultErrorMessageAction,
  addMessageAction,
} from 'containers/FlashMessage/actions';
import { closePopup } from 'containers/GlobalPopup/actions';
import { apiDelete, apiPatch, apiPost, apiPut } from 'utils/axios';

import {
  cancelAndResendHellosignRequestErrorAction,
  cancelAndResendHellosignRequestSuccessAction,
  checkrCanadaRunReportErrorAction,
  checkrCanadaRunReportSuccessAction,
  checkrRunRemainingReportErrorAction,
  checkrRunRemainingReportSuccessAction,
  checkrRunReportErrorAction,
  checkrRunReportSuccessAction,
  checkrSendConsentMessageErrorAction,
  checkrSendConsentMessageSuccessAction,
  onfidoResumeErrorAction,
  onfidoResumeSuccessAction,
  onfidoSendMessageErrorAction,
  onfidoSendMessageSuccessAction,
  onfidoVoidErrorAction,
  onfidoVoidSuccessAction,
  resendHellosignEmailErrorAction,
  resendHellosignEmailSuccessAction,
  resubscribeToEmailErrorAction,
  resubscribeToEmailSuccessAction,
  resubscribeToSmsErrorAction,
  resubscribeToSmsSuccessAction,
  schedulingCancelErrorAction,
  schedulingCancelSuccessAction,
  schedulingResendSchedulerEmailErrorAction,
  schedulingResendSchedulerEmailSuccessAction,
  schoolkeepEnrollErrorAction,
  schoolkeepEnrollSuccessAction,
  sendAutomatedErrorAction,
  sendAutomatedSuccessAction,
  wxExportApplicantErrorAction,
  wxExportApplicantSuccessAction,
} from './actions';
import {
  CANCEL_AND_RESEND_HELLOSIGN_REQUEST_INIT,
  CHECKR_CANADA_RUN_REPORT_INIT,
  CHECKR_RUN_REMAINING_REPORT_INIT,
  CHECKR_RUN_REPORT_INIT,
  CHECKR_SEND_CONSENT_MESSAGE_INIT,
  ONFIDO_RESUME_INIT,
  ONFIDO_SEND_MESSAGE_INIT,
  ONFIDO_VOID_INIT,
  RESEND_HELLOSIGN_EMAIL_INIT,
  RESUBSCRIBE_EMAIL_START,
  RESUBSCRIBE_SMS_START,
  SCHEDULING_CANCEL_INIT,
  SCHEDULING_RESEND_SCHEDULER_EMAIL_INIT,
  SCHOOLKEEP_ENROLL_INIT,
  SEND_AUTOMATED_MESSAGE,
  WX_EXPORT_APPLICANT_INIT,
} from './constants';

export function* deleteApplicant({ applicantId, fromStageId, message }) {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}`;
  try {
    yield call(apiDelete, url);
    yield put(resetStageApplicantCountAction({ fromStageId }));
    yield put(removeApplicantFromStageAction(fromStageId, [applicantId]));
    yield put(deleteApplicantSuccessAction());
    yield put(addMessageAction(message, 'success'));
    yield put(closePopup());
  } catch (error) {
    yield put(deleteApplicantErrorAction());
    yield put(addDefaultErrorMessageAction());
  }
}

export function* reactivateEmail({ applicantId, messages }) {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/reactivate_email`;

  try {
    const res = yield call(apiPatch, url);
    if (res.data) {
      const dataField = { email_unsubscribed: false };
      yield put(updateApplicantDataFieldColumn({ applicantId, dataField }));
      yield put(resubscribeToEmailSuccessAction());
      yield put(addMessageAction(messages.success, 'success'));
    }
  } catch (error) {
    yield put(resubscribeToEmailErrorAction());
    yield put(addMessageAction(messages.error, 'error'));
  }
}

export function* reactivateSms({ applicantId, messages }) {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/reactivate_sms`;

  try {
    const res = yield call(apiPatch, url);
    if (res.data) {
      const dataField = { sms_unsubscribed: false };
      yield put(updateApplicantDataFieldColumn({ applicantId, dataField }));
      yield put(resubscribeToSmsSuccessAction());
      yield put(addMessageAction(messages.success, 'success'));
    }
  } catch (error) {
    yield put(resubscribeToSmsErrorAction());
    yield put(addMessageAction(messages.error, 'error'));
  }
}

export function* checkrSendConsentMessage({ applicantId, messages }) {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/send_consent_message`;

  try {
    yield call(apiPatch, url);
    yield put(checkrSendConsentMessageSuccessAction());
    yield put(addMessageAction(messages.success, 'success'));
  } catch (error) {
    yield put(checkrSendConsentMessageErrorAction());
    yield put(addMessageAction(messages.error, 'error'));
  }
}

export function* checkrRunReport({ applicantId, stageId, messages }) {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/run_report?stage_id=${stageId}`;

  try {
    yield call(apiPatch, url);
    yield put(checkrRunReportSuccessAction());
    yield put(addMessageAction(messages.success, 'success'));
  } catch (error) {
    yield put(checkrRunReportErrorAction());
    yield put(addMessageAction(messages.error, 'error'));
  }
}

export function* checkrRunRemainingReport({ applicantId, reportId, messages }) {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/run_remaining_report?report_id=${reportId}`;

  try {
    yield call(apiPatch, url);
    yield put(checkrRunRemainingReportSuccessAction());
    yield put(addMessageAction(messages.success, 'success'));
  } catch (error) {
    yield put(checkrRunRemainingReportErrorAction());
    yield put(addMessageAction(messages.error, 'error'));
  }
}

export function* cancelAndResendHellosignRequest({
  applicantId,
  requestId,
  messages,
}) {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/cancel_and_resend_hellosign_request?signature_request_id=${requestId}`;

  try {
    yield call(apiPut, url);
    yield put(cancelAndResendHellosignRequestSuccessAction());
    yield put(addMessageAction(messages.success, 'success'));
  } catch (error) {
    yield put(cancelAndResendHellosignRequestErrorAction());
    yield put(addMessageAction(messages.error, 'error'));
  }
}

export function* resendHellosignEmail({ applicantId, requestId, messages }) {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/resend_hellosign_email?signature_request_id=${requestId}`;

  try {
    yield call(apiPut, url);
    yield put(resendHellosignEmailSuccessAction());
    yield put(addMessageAction(messages.success, 'success'));
  } catch (error) {
    yield put(resendHellosignEmailErrorAction());
    yield put(addMessageAction(messages.error, 'error'));
  }
}

export function* schoolkeepEnroll({ applicantId }) {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/schoolkeep_enroll`;

  try {
    yield call(apiPost, url);
    yield put(schoolkeepEnrollSuccessAction());
    yield put(
      addMessageAction(
        'Successfully enrolled applicant in the course',
        'success',
      ),
    );
  } catch (error) {
    yield put(schoolkeepEnrollErrorAction());
    yield put(
      addMessageAction(
        'An error occurred while enrolling applicant in the course',
        'error',
      ),
    );
  }
}

export function* schedulingCancel({ bookedSlotId, messages }) {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL}/booked_slots/${bookedSlotId}`;
  try {
    yield call(apiDelete, url);
    yield put(schedulingCancelSuccessAction());
    yield put(addMessageAction(messages.success, 'success'));
  } catch (error) {
    yield put(schedulingCancelErrorAction());
    yield put(addMessageAction(messages.error, 'error'));
  }
}

export function* schedulingResendSchedulerEmail({ applicantId, messages }) {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/resend_scheduler_email`;

  try {
    yield call(apiPut, url);
    yield put(schedulingResendSchedulerEmailSuccessAction());
    yield put(addMessageAction(messages.success, 'success'));
  } catch (error) {
    yield put(schedulingResendSchedulerEmailErrorAction());
    yield put(addMessageAction(messages.error, 'error'));
  }
}

export function* checkrCanadaRunReport({ reportId, messages }) {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/reports/${reportId}/run`;

  try {
    yield call(apiPost, url);
    yield put(checkrCanadaRunReportSuccessAction());
    yield put(addMessageAction(messages.success, 'success'));
  } catch (error) {
    yield put(checkrCanadaRunReportErrorAction());
    yield put(addMessageAction(messages.error, 'error'));
  }
}

export function* onfidoResume({ checkId, messages }) {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/checks/${checkId}/resume`;

  try {
    yield call(apiPost, url);
    yield put(onfidoResumeSuccessAction());
    yield put(addMessageAction(messages.success, 'success'));
  } catch (error) {
    yield put(onfidoResumeErrorAction());
    yield put(addMessageAction(messages.error, 'error'));
  }
}

export function* onfidoSendMessage({ applicantId, stageId, messages }) {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/checks`;

  try {
    yield call(apiPost, url, { applicant_id: applicantId, stage_id: stageId });
    yield put(onfidoSendMessageSuccessAction());
    yield put(addMessageAction(messages.success, 'success'));
  } catch (error) {
    yield put(onfidoSendMessageErrorAction());
    yield put(addMessageAction(messages.error, 'error'));
  }
}

export function* onfidoVoid({ applicantId, messages }) {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/checks/void`;

  try {
    yield call(apiDelete, url, { applicant_id: applicantId });
    yield put(onfidoVoidSuccessAction());
    yield put(addMessageAction(messages.success, 'success'));
  } catch (error) {
    yield put(onfidoVoidErrorAction());
    yield put(addMessageAction(messages.error, 'error'));
  }
}

export function* sendAutomatedMessage({ applicantId, message }) {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/send_automated_message`;
  try {
    const response = yield call(apiPatch, url, { applicant_id: applicantId });
    yield put(sendAutomatedSuccessAction());
    yield put(addMessageAction(response.data.message, 'success'));
  } catch (error) {
    yield put(sendAutomatedErrorAction());
    yield put(addMessageAction(message, 'error'));
  }
}

export function* sendWxWebhook({
  isMasterApplicantsView,
  applicantIds,
  filterSetId,
  tempFilterId,
  duplicateApplicantId,
  stageConditionsFilterSetId,
  selectByCount,
  selectedApplicant,
  selectedFilterStageIdsWithFunnel,
  search,
  messages,
}) {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/bulk_actions`;
  try {
    yield call(apiPost, url, {
      bulk_action: {
        applicant_ids: Array.isArray(applicantIds) ? applicantIds : undefined,
        filter_set_id: filterSetId ?? tempFilterId,
        stage_conditions_filter_set_id: stageConditionsFilterSetId ?? null,
        selected_by_count: selectByCount,
        stage_id: !isMasterApplicantsView
          ? selectedApplicant.stage.id
          : undefined,
        arguments: {
          from_stage: !isMasterApplicantsView
            ? selectedApplicant.stage.id
            : undefined,
          from_funnel: !isMasterApplicantsView
            ? selectedApplicant.opening.id
            : undefined,
          ...(duplicateApplicantId && {
            duplicate_applicant_id: duplicateApplicantId,
          }),
        },
        stage_ids: isMasterApplicantsView
          ? selectedFilterStageIdsWithFunnel.flatMap(swf => swf.stage_ids)
          : [],
        search,
        type: 'BulkWxExport',
      },
    });
    yield put(wxExportApplicantSuccessAction());
    yield put(resetSelectedApplicantIds());
    yield put(addMessageAction(messages.success, 'success'));
  } catch (error) {
    yield put(wxExportApplicantErrorAction());
    yield put(addMessageAction(messages.error, 'error'));
  }
}

export const deleteApplicantSaga = takeLatest(
  DELETE_APPLICANT_START,
  deleteApplicant,
);

export const reactivateEmailSaga = takeLatest(
  RESUBSCRIBE_EMAIL_START,
  reactivateEmail,
);

export const reactivateSmsSaga = takeLatest(
  RESUBSCRIBE_SMS_START,
  reactivateSms,
);

export const checkrSendConsentMessageSaga = takeLatest(
  CHECKR_SEND_CONSENT_MESSAGE_INIT,
  checkrSendConsentMessage,
);

export const checkrRunReportSaga = takeLatest(
  CHECKR_RUN_REPORT_INIT,
  checkrRunReport,
);

export const checkrRunRemainingReportSaga = takeLatest(
  CHECKR_RUN_REMAINING_REPORT_INIT,
  checkrRunRemainingReport,
);

export const cancelAndResendHellosignRequestSaga = takeLatest(
  CANCEL_AND_RESEND_HELLOSIGN_REQUEST_INIT,
  cancelAndResendHellosignRequest,
);

export const resendHellosignEmailSaga = takeLatest(
  RESEND_HELLOSIGN_EMAIL_INIT,
  resendHellosignEmail,
);

export const schoolkeepEnrollSaga = takeLatest(
  SCHOOLKEEP_ENROLL_INIT,
  schoolkeepEnroll,
);

export const schedulingCancelSaga = takeLatest(
  SCHEDULING_CANCEL_INIT,
  schedulingCancel,
);

export const schedulingResendSchedulerEmailSaga = takeLatest(
  SCHEDULING_RESEND_SCHEDULER_EMAIL_INIT,
  schedulingResendSchedulerEmail,
);

export const checkrCanadaRunReportSaga = takeLatest(
  CHECKR_CANADA_RUN_REPORT_INIT,
  checkrCanadaRunReport,
);

export const onfidoResumeSaga = takeLatest(ONFIDO_RESUME_INIT, onfidoResume);

export const onfidoSendMessageSaga = takeLatest(
  ONFIDO_SEND_MESSAGE_INIT,
  onfidoSendMessage,
);

export const onfidoVoidSaga = takeLatest(ONFIDO_VOID_INIT, onfidoVoid);

export const sendAutomatedMessageSaga = takeLatest(
  SEND_AUTOMATED_MESSAGE,
  sendAutomatedMessage,
);

export const wxExportApplicantSaga = takeLatest(
  WX_EXPORT_APPLICANT_INIT,
  sendWxWebhook,
);

// Individual exports for testing
export default function* reactivateEmailOnApplicantSaga() {
  yield all([
    checkrRunReportSaga,
    checkrRunRemainingReportSaga,
    checkrSendConsentMessageSaga,
    deleteApplicantSaga,
    reactivateEmailSaga,
    reactivateSmsSaga,
    cancelAndResendHellosignRequestSaga,
    resendHellosignEmailSaga,
    schoolkeepEnrollSaga,
    schedulingCancelSaga,
    schedulingResendSchedulerEmailSaga,
    checkrCanadaRunReportSaga,
    onfidoResumeSaga,
    onfidoSendMessageSaga,
    onfidoVoidSaga,
    sendAutomatedMessageSaga,
    wxExportApplicantSaga,
  ]);
}
