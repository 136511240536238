import { makeStyles } from '@material-ui/core/styles';

export const useMultipleChoiceQuestionFormStyles = makeStyles(theme => ({
  addOptionButtonIcon: {
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  addOptionButtonText: {
    display: 'flex',
    alignItems: 'center',
    gap: `calc(${theme.spacing(1)}px / 2)`,
  },
  optionsContainer: {
    background: theme.palette.grey[200],
    borderRadius: theme.misc.borderRadius,
    width: '100%',
    margin: '0 auto',
  },
  optionList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    listStyleType: 'none',
    margin: 0,
    padding: theme.spacing(1),
  },
  option: {
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(1),
    borderRadius: theme.misc.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  optionInputContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    width: '100%',
  },
  optionInputErrorContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  optionInputErrorContainerHeight: {
    height: '20px',
  },
}));
