import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

import ApplicantIcon from 'images/applicants';
import globalMessages from 'shared/global/messages';

import useStyles from './styles';

function RecipientsCard({ applicantsCount, title, intl }) {
  const classes = useStyles();

  return (
    <Grid className={classes.recipientsCard}>
      <Typography variant="h4">{title}</Typography>
      <Grid
        container
        alignItems="center"
        className={classes.applicantsCountContainer}
      >
        <ApplicantIcon className={classes.applicantIcon} />
        <Typography variant="overline">
          {intl.formatMessage(globalMessages.recipientsCount, {
            count: applicantsCount,
          })}
        </Typography>
      </Grid>
    </Grid>
  );
}

RecipientsCard.propTypes = {
  intl: PropTypes.object.isRequired,
  applicantsCount: PropTypes.number,
  title: PropTypes.string.isRequired,
};

export default RecipientsCard;
