import { StagesService } from 'api-clients/monolith';
import { useApiService } from 'hooks';
import React from 'react';

export function useUnscopedStages({
  workflowId,
  perPage = 300,
}: {
  workflowId?: string;
  perPage?: number;
}) {
  const fetchStages = React.useCallback(
    () => StagesService.getInternalApiStages(workflowId, perPage, true),
    [workflowId, perPage],
  );

  return useApiService(fetchStages);
}
