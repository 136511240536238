import { Grid } from '@material-ui/core';
import { WorkflowStageDetail } from 'api-clients/monolith';
import produce from 'immer';
import React, { FC, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { StageSettingCard } from '../../../../StageSettingCard';
import { SettingSwitch } from '../../../SettingSwitch';
import {
  AutoAdvanceWhenLabelsComplete,
  IsHiddenFromPortal,
} from '../../SettingsSwitches';
import { messages } from './messages';
import { useStyles } from './styles';

export interface AdvancedSettingsProps {
  stage: WorkflowStageDetail;
}

export const AdvancedSettings: FC<AdvancedSettingsProps> = ({ stage }) => {
  const styles = useStyles();
  const intl = useIntl();
  const { setStage } = useContext(StageContext);

  const hasStageLabels = Boolean(stage.has_stage_labels);

  const onToggleChange =
    (key: keyof WorkflowStageDetail['advanced_settings']) => () => {
      setStage(
        produce((draftStage: WorkflowStageDetail) => {
          draftStage.advanced_settings[key] =
            !draftStage.advanced_settings[key];
        }),
      );
    };

  return (
    <StageSettingCard
      variant="default"
      title={intl.formatMessage(messages.advancedSettings)}
    >
      <Grid className={styles.cardBody}>
        <IsHiddenFromPortal stage={stage} setStage={setStage} />
        <SettingSwitch
          dataKey="allow_applicants_to_advance"
          checked={
            stage.advanced_settings.should_applicant_self_advance ?? false
          }
          onChange={onToggleChange('should_applicant_self_advance')}
          label={<FormattedMessage {...messages.automaticallyMoveApplicant} />}
        />
        {hasStageLabels && (
          <AutoAdvanceWhenLabelsComplete stage={stage} setStage={setStage} />
        )}
      </Grid>
    </StageSettingCard>
  );
};
