import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React, { FC } from 'react';

export const TechCheckStageIcon: FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3925 12.8565C14.3531 12.9446 14.2649 13 14.1684 13H1.83164C1.73513 13 1.64688 12.9446 1.60746 12.8565C1.21708 11.9842 1 11.0175 1 10C1 6.13401 4.13401 3 8 3C11.866 3 15 6.13401 15 10C15 11.0175 14.7829 11.9842 14.3925 12.8565ZM13.1042 11.5C13.2161 11.5 13.315 11.4258 13.3416 11.3171C13.445 10.896 13.5 10.4551 13.5 10C13.5 9.784 13.4875 9.57091 13.4633 9.3614C13.4469 9.2197 13.3104 9.12681 13.1708 9.15647L12.4536 9.30892C12.3185 9.33763 12.1858 9.25142 12.1571 9.11636C12.1284 8.98131 12.2146 8.84856 12.3496 8.81985L13.0678 8.66719C13.2072 8.63757 13.2942 8.49743 13.2518 8.36142C12.9773 7.48059 12.4872 6.6948 11.8434 6.06575C11.7414 5.96612 11.577 5.97994 11.4872 6.09073L11.026 6.66033C10.9391 6.76763 10.7817 6.78418 10.6744 6.69728C10.5671 6.61039 10.5505 6.45297 10.6374 6.34567L11.099 5.77562C11.1887 5.66491 11.1681 5.5013 11.0496 5.42221C10.3101 4.92858 9.44426 4.60938 8.51085 4.52341C8.369 4.51034 8.25 4.62456 8.25 4.76701V5.5C8.25 5.63807 8.13807 5.75 8 5.75C7.86193 5.75 7.75 5.63807 7.75 5.5V4.76701C7.75 4.62456 7.631 4.51034 7.48915 4.52341C6.55556 4.6094 5.68955 4.9287 4.94995 5.42249C4.83148 5.50158 4.81093 5.66518 4.90058 5.77588L5.36198 6.34567C5.44887 6.45297 5.43233 6.61039 5.32502 6.69728C5.21772 6.78418 5.0603 6.76763 4.97341 6.66033L4.51243 6.09107C4.4227 5.98027 4.25822 5.96645 4.15625 6.0661C3.51259 6.69509 3.0227 7.48075 2.74822 8.36142C2.70583 8.49743 2.79281 8.63757 2.93216 8.66719L3.65036 8.81985C3.78541 8.84856 3.87163 8.98131 3.84292 9.11636C3.81421 9.25142 3.68146 9.33763 3.5464 9.30892L2.82917 9.15647C2.68964 9.12681 2.55306 9.2197 2.53668 9.3614C2.51245 9.57091 2.5 9.784 2.5 10C2.5 10.4551 2.55497 10.896 2.65836 11.3171C2.68503 11.4258 2.78388 11.5 2.89576 11.5H6.91476C7.0676 11.5 7.18466 11.3641 7.16199 11.2129L6.58145 7.343C6.52779 6.9853 7.03798 6.85893 7.15747 7.20033L8.6038 11.3326C8.6389 11.4329 8.73353 11.5 8.83977 11.5H13.1042Z"
    />
  </SvgIcon>
);
