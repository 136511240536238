import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useIntl } from 'react-intl';

import { RADIUS_OPTIONS } from '../../constants';
import { HandleSettingChange } from '../types';
import { messages } from './messages';

const useStyles = makeStyles(theme => ({
  input: {
    marginTop: theme.spacing(2),
  },
}));

export interface Option {
  label: string;
  value: string;
}

export interface DistanceRadiusSelectorProps {
  radius: string;
  onChange: HandleSettingChange;
}

export const DistanceRadiusSelector: React.VFC<DistanceRadiusSelectorProps> = ({
  radius,
  onChange,
}) => {
  const styles = useStyles();
  const intl = useIntl();

  const handleChange = (selectedOption: Option) => {
    onChange('radius', selectedOption.value);
  };

  return (
    <StyledReactSelect
      options={RADIUS_OPTIONS.map((option: string) => ({
        value: option,
        label: option,
      }))}
      value={{ label: radius, value: radius }}
      onChange={handleChange}
      label={intl.formatMessage(messages.searchRadius)}
      getOptionLabel={(option: Option) => option.label}
      getOptionValue={(option: Option) => option.value}
      className={styles.input}
      isSearchable={false}
    />
  );
};
