export const EMPLOYER_ADDRESS = 'employer_address';
export const EMPLOYER_BUSINESS_NAME = 'employer_business_name';
export const EMPLOYER_CITY = 'employer_city';
export const EMPLOYER_FIRST_NAME = 'employer_first_name';
export const EMPLOYER_LAST_NAME = 'employer_last_name';
export const EMPLOYER_STATE = 'employer_state';
export const EMPLOYER_TITLE = 'employer_title';
export const EMPLOYER_ZIP = 'employer_zip';

export type EmployerField =
  | typeof EMPLOYER_ADDRESS
  | typeof EMPLOYER_BUSINESS_NAME
  | typeof EMPLOYER_CITY
  | typeof EMPLOYER_FIRST_NAME
  | typeof EMPLOYER_LAST_NAME
  | typeof EMPLOYER_STATE
  | typeof EMPLOYER_TITLE
  | typeof EMPLOYER_ZIP;
