import messages from './messages';

export default function validate(values, intl) {
  const { saveEmailAsTemplate, emailText, emailSubject, newEmailTemplateName } =
    values;

  const errors = {};

  if (!emailSubject) {
    errors.emailSubject = intl.formatMessage(messages.emailSubjectRequired);
  }

  // After typing into the React-Quill editor, it will always have <p><br></p>
  // even after deleting all the text so that's why this check was added
  if (!emailText || emailText === '<p><br></p>') {
    errors.emailText = intl.formatMessage(messages.emailTextRequired);
  }

  if (saveEmailAsTemplate && !newEmailTemplateName) {
    errors.newEmailTemplateName = intl.formatMessage(
      messages.templateNameRequired,
    );
  }

  return errors;
}
