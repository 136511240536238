import { Input } from '@fountain/fountain-ui-components';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

// TODO: Shared component: should modify Input's label
const FormikInput = ({ id, label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <Input
      id={id}
      label={label}
      onChange={field.onChange}
      error={meta.error}
      {...props}
    />
  );
};

FormikInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};

export default FormikInput;
