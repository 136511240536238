import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  addButton: {
    color: theme.palette.common.blue400,
  },
  row: {
    marginBottom: theme.spacing(1),

    '&:last-child': {
      marginBottom: 0,
    },
  },
  addDefaultDocumentsSection: {
    marginBottom: theme.spacing(4),
  },
  marginBottom2: {
    marginBottom: theme.spacing(2),
  },
  errorsAlert: {
    marginBottom: theme.spacing(3),
    '& > div': {
      margin: 'initial', // Have icon & text left aligned as per design
    },
  },
}));
