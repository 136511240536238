import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  arrow: {
    color: theme.palette.common.gray500,
    marginRight: theme.spacing(-0.5),
  },
  input: {
    height: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  jobSearchSelectContainer: {
    position: 'relative',
  },
  jobSearchSelectInput: {
    borderColor: `${theme.palette.common.gray300} !important`,
    outline: 'none',
    '&:focus-within': {
      borderColor: `${theme.palette.common.gray400} !important`,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  jobSearchSelectInputArrow: {
    color: theme.palette.gray500,
  },
  paper: {
    marginTop: theme.spacing(1),
    maxHeight: ({ maxHeight }) => `${maxHeight}px`,
    minHeight: ({ minHeight }) => `${minHeight}px`,
    overflowY: 'auto',
    width: ({ inputContainerWidth }) => `${inputContainerWidth}px`,
  },
  popper: {
    zIndex: theme.zIndex.tooltip,
  },
}));

export default useStyles;
