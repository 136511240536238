import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(1),
    position: 'relative',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  errorField: {
    color: theme.palette.common.error,
    width: '100%',
    position: 'absolute',
    bottom: theme.spacing(-3),
  },
  uploadContainer: {
    width: 104,
    height: 104,
    background: theme.palette.background.default,
    borderRadius: 100,
    marginBottom: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadInnerContainer: {
    width: 56,
    height: 56,
    boxShadow: '0px 8px 32px 0px rgba(0, 160, 255, 0.24)',
    borderRadius: 100,
    background: props =>
      props.isActive || props.isHovered
        ? theme.palette.common.blue400
        : 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deleteButton: {
    border: 'none',
    background: 'none',
    '& img': {
      height: '16px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  fileName: {
    color: theme.palette.common.blueAppBarBackground,
  },
  previewContainer: {
    backgroundColor: theme.palette.common.gray100,
    borderRadius: `${theme.misc.borderRadius}px`,
    padding: theme.spacing(2),
    width: '100%',
    height: '100%',
  },
  filePreview: {
    borderRadius: theme.misc.borderRadius,
    maxWidth: 242,
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  previewIconContainer: {
    justifyContent: 'center',
    display: 'flex',
    height: 16,
    width: 16,
    background: theme.palette.common.blue400,
    borderRadius: '50%',
    marginRight: theme.spacing(2),
  },
  previewDetails: {
    flex: '1 1 16px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

export default useStyles;
