import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  questionHeader: {
    id: `app.containers.WorkflowEditor.components.StageDetail.VideoRecordingStage.questionHeader`,
    defaultMessage: 'Video Recording Question {number}',
  },
  question: {
    id: `app.containers.WorkflowEditor.components.StageDetail.VideoRecordingStage.question`,
    defaultMessage: 'Question',
  },
  duration: {
    id: `app.containers.WorkflowEditor.components.StageDetail.VideoRecordingStage.duration`,
    defaultMessage: 'Duration {range}',
  },
  seconds: {
    id: `app.containers.WorkflowEditor.components.StageDetail.VideoRecordingStage.seconds`,
    defaultMessage: 'seconds',
  },
  delete: {
    id: `app.containers.WorkflowEditor.components.StageDetail.VideoRecordingStage.delete`,
    defaultMessage: 'Delete',
  },
  durationTooSmall: {
    id: `app.containers.WorkflowEditor.components.StageDetail.VideoRecordingStage.durationTooSmall`,
    defaultMessage: 'Must be {duration} or greater',
  },
  durationTooLarge: {
    id: `app.containers.WorkflowEditor.components.StageDetail.VideoRecordingStage.durationTooLarge`,
    defaultMessage: 'Must be {duration} or less',
  },
  questionRequired: {
    id: `app.containers.WorkflowEditor.components.StageDetail.VideoRecordingStage.questionRequired`,
    defaultMessage: 'Question required',
  },
  deleteSuccess: {
    id: `app.containers.WorkflowEditor.components.StageDetail.VideoRecordingStage.deleteSuccess`,
    defaultMessage: 'Question deleted successfully',
  },
});
