import { Button, CustomTooltip } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { classNames } from 'react-extras';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';
import { v4 as uuid } from 'uuid';

import { addMessageAction } from 'containers/FlashMessage/actions';
import useFetch from 'hooks/useFetch';
import useLocations from 'hooks/useLocations';
import usePatch from 'hooks/usePatch';
import PlusIcon from 'images/plus-icon.svg';
import PlusIconGray from 'images/plus-icon-gray.svg';

import DistributeRule from './components/DistributeRule';
import messages from './messages';
import useStyles from './styles';

const DEFAULT_RULE = {
  id: null,
  to_location_id: '',
  to_funnel_id: '',
  to_stage_id: '',
  percentage: 0,
  isNew: true,
};

const ROW_LIMIT = 20;

function DistributeApplicantsRuleStage({ intl }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { data: locations, isFetching: isFetchingLocations } = useLocations();
  const [distributeRules, setDistributeRules] = useState([]);
  const [percentageErrorMsg, setPercentageErrorMsg] = useState();
  const [isDirty, setIsDirty] = useState(false);
  const [ruleIdsToDelete, setRuleIdsToDelete] = useState([]);

  const { jobExternalId, stageExternalId } = useParams();

  const {
    data: updatedRulesData,
    patchData: updateRuleStage,
    isLoading: isUpdatingRuleStage,
  } = usePatch({
    uri: `${REACT_APP_GLOBAL_API_BASE_URL_V2}/distribute_applicants_rule_stage/${stageExternalId}`,
  });

  const {
    cancelFetch: cancelFetchRules,
    data: rulesData,
    isLoading: isLoadingRules,
    fetchData: fetchRules,
  } = useFetch({
    uri: `${REACT_APP_GLOBAL_API_BASE_URL_V2}/distribute_applicants_rule_stage/${stageExternalId}`,
  });

  const {
    cancelFetch: cancelFetchLocation,
    data: defaultLocation,
    isLoading: isLoadingLocation,
    fetchData: fetchLocation,
    error: errorFetchingLocation,
  } = useFetch({
    uri: `${REACT_APP_GLOBAL_API_BASE_URL_V2}/locations/get_location?job_external_id=${jobExternalId}`,
  });

  useEffect(() => {
    fetchLocation();
    fetchRules(); // Fetched distributeRules contain an 'extra' attribute

    return () => {
      cancelFetchLocation();
      cancelFetchRules();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (rulesData && rulesData.distribute_rules) {
      setDistributeRules(rulesData.distribute_rules);
    }
  }, [rulesData]);

  useEffect(() => {
    if (updatedRulesData && updatedRulesData.distribute_rules) {
      setDistributeRules(updatedRulesData.distribute_rules);
    }
  }, [updatedRulesData]);

  useEffect(() => {
    if (errorFetchingLocation) {
      dispatch(addMessageAction(errorFetchingLocation, 'error'));
    }
  }, [dispatch, errorFetchingLocation]);

  useEffect(() => {
    if (defaultLocation && distributeRules.length === 0) {
      setDistributeRules([
        ...distributeRules,
        {
          ...DEFAULT_RULE,
          id: uuid(),
          to_location_id: defaultLocation.location.id,
          percentage: 100,
        },
      ]);
    }
  }, [defaultLocation, distributeRules]);

  const distributeRuleLimitReached = distributeRules.length === ROW_LIMIT;
  const addOpeningDisabled = isLoadingRules || distributeRuleLimitReached;

  const handleAddRule = () => {
    if (isLoadingRules || isLoadingLocation || distributeRuleLimitReached) {
      return;
    }

    setIsDirty(true);

    if (addOpeningDisabled) {
      return;
    }

    setDistributeRules([
      ...distributeRules,
      {
        ...DEFAULT_RULE,
        id: uuid(),
        to_location_id: defaultLocation.location.id,
      },
    ]);
  };

  const handleDeleteRule = (idx, ruleId) => {
    setIsDirty(true);

    const copyRules = [...distributeRules];

    copyRules.splice(idx, 1);

    setRuleIdsToDelete([...ruleIdsToDelete, ruleId]);
    setDistributeRules(copyRules);
  };

  const handleUpdateRule = (idx, updatedRule) => {
    setIsDirty(true);

    const copyRules = [...distributeRules];
    copyRules[idx] = updatedRule;

    setDistributeRules(copyRules);
  };

  const validateDistributionTotalPercentage = distributionTotalPercentage => {
    if (distributionTotalPercentage !== 100) {
      const percentageDifference = 100 - distributionTotalPercentage;

      if (percentageDifference > 0) {
        setPercentageErrorMsg(
          intl.formatMessage(messages.percentageSumMessage, {
            percentageDifference: Math.abs(percentageDifference),
          }),
        );
      } else {
        setPercentageErrorMsg(
          intl.formatMessage(messages.percentageDeductMessage, {
            percentageDifference: Math.abs(percentageDifference),
          }),
        );
      }
    } else {
      setPercentageErrorMsg(null);
    }
  };

  const runValidationsOnRules = () => {
    const copyRules = [...distributeRules];
    let hasErrors = false;
    let distributionTotalPercentage = 0;

    // Check if any dropdowns are empty
    for (let i = 0; i < copyRules.length; i += 1) {
      const errors = {};
      const currentRule = copyRules[i];

      if (!currentRule.to_location_id) {
        errors.locationIdMissing = true;
      }

      if (!currentRule.to_funnel_id) {
        errors.openingIdMissing = true;
      }

      if (!currentRule.to_stage_id) {
        errors.stageIdMissing = true;
      }

      if (currentRule.percentage === parseInt(currentRule.percentage, 10)) {
        distributionTotalPercentage += parseInt(currentRule.percentage, 10);
      } else {
        errors.percentageMissing = true;
      }

      if (Object.keys(errors).length > 0) {
        currentRule.errors = errors;
        hasErrors = true;
      } else {
        currentRule.errors = {};
      }
    }

    if (hasErrors) {
      setDistributeRules(copyRules);
    }

    validateDistributionTotalPercentage(distributionTotalPercentage);

    if (distributionTotalPercentage !== 100 || hasErrors) {
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.pleaseProvideValidSelection),
          'error',
        ),
      );
      return null;
    }

    return copyRules;
  };

  const handleSaveSettings = () => {
    const validatedRules = runValidationsOnRules();

    if (validatedRules) {
      // Remove any attributes from each distributeRule that we don't want to save in the
      // database (e.g. removing the 'extra' attribute)
      const cleanedOutRules = validatedRules.map(
        ({ errors, extra, isNew, ...keepAttrs }) => ({
          ...keepAttrs,
          id: isNew ? null : keepAttrs.id,
        }),
      );

      updateRuleStage(null, {
        distribute_applicants_rule_stage: {
          distribute_rules_attributes: cleanedOutRules,
          rule_ids_to_delete: ruleIdsToDelete,
        },
      });
      setRuleIdsToDelete([]);
      setIsDirty(false);
    }
  };

  return (
    <Grid className={classes.distributeApplicantsRuleStage}>
      <Typography className={classes.stageDescription}>
        {intl.formatMessage(messages.distributeApplicantsRuleStageDescription)}
      </Typography>

      <CustomTooltip
        title={
          distributeRuleLimitReached
            ? intl.formatMessage(messages.maxRowsReached)
            : ''
        }
        dense
      >
        <Grid
          onClick={handleAddRule}
          className={classNames(classes.addOpeningContent, {
            disabled: addOpeningDisabled,
          })}
        >
          {addOpeningDisabled ? (
            <img
              src={PlusIconGray}
              alt="plus"
              className={classNames(classes.plusIcon, { disabled: true })}
            />
          ) : (
            <img src={PlusIcon} alt="plus" className={classes.plusIcon} />
          )}
          <Typography
            color={addOpeningDisabled ? 'textSecondary' : 'primary'}
            className={classNames(classes.addOpeningText, {
              disabled: addOpeningDisabled,
            })}
          >
            {intl.formatMessage(messages.addAnotherOpening)}
          </Typography>
        </Grid>
      </CustomTooltip>

      {!isFetchingLocations &&
        !isLoadingRules &&
        distributeRules.map((distributeRule, index) => (
          <DistributeRule
            key={distributeRule.id}
            locations={locations}
            stageExternalId={stageExternalId}
            defaultLocation={
              locations.find(loc => loc.id === distributeRule.to_location_id) ||
              (defaultLocation && defaultLocation.location)
            }
            index={index}
            handleDeleteRule={handleDeleteRule}
            distributeRule={distributeRule}
            handleUpdateRule={handleUpdateRule}
            allowDeletingRule={distributeRules.length > 1}
            percentageErrorMsg={percentageErrorMsg}
            isLastRule={index === distributeRules.length - 1}
            jobExternalId={jobExternalId}
          />
        ))}
      <Grid container justify="flex-end">
        <Button
          disabled={!isDirty || isUpdatingRuleStage}
          color="primary"
          onClick={handleSaveSettings}
        >
          {intl.formatMessage(messages.save)}
        </Button>
      </Grid>
    </Grid>
  );
}

DistributeApplicantsRuleStage.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(DistributeApplicantsRuleStage);
