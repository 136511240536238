import { createSelector } from 'reselect';

import { initialState } from './reducer';

/**
 * Direct selector to the applicantsV2 state domain
 */

const selectApplicantsV2Domain = state => state.applicantsV2 || initialState;

/**
 * Other specific selectors
 */

const makeSelectIsFetchingJob = () =>
  createSelector(selectApplicantsV2Domain, substate => substate.isLoading);

const makeSelectJob = () =>
  createSelector(selectApplicantsV2Domain, substate => substate.job);

const makeSelectStages = () =>
  createSelector([makeSelectJob()], job => job && job.stages);

const findStageIdByStageType = stageType =>
  createSelector([makeSelectStages()], stages => {
    const stageMatch = stages && stages.find(stage => stage.type === stageType);
    return stageMatch && stageMatch.id;
  });

const getCurrentFunnelSlug = () =>
  createSelector([makeSelectJob()], job => job && job.slug);

/**
 * Default selector used by ApplicantsV2
 */

const makeSelectApplicantsV2 = () =>
  createSelector(selectApplicantsV2Domain, substate => substate);

export default makeSelectApplicantsV2;
export {
  findStageIdByStageType,
  getCurrentFunnelSlug,
  makeSelectIsFetchingJob,
  makeSelectJob,
  makeSelectStages,
  selectApplicantsV2Domain,
};
