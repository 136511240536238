import { Button } from '@fountain/fountain-ui-components';
import { Typography } from '@material-ui/core';
import {
  AvailabilityRuleError,
  Period,
  WeeklyPeriods,
} from 'api-clients/monolith';
import {
  generateNextTimeRange,
  Weekday,
} from 'Calendar/AvailabilityRules/utils';
import invariant from 'invariant';
import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from './messages';
import { Row, RowProps } from './Row';
import { useStyles } from './styles';
import { UseCreateAvailabilityRulesOptions } from './useCreateAvailabilityRules';

export interface AvailabilityRulesProps {
  /**
   * @description list of availability set by recruiter
   */
  weeklyPeriods: WeeklyPeriods;
  errors: AvailabilityRuleError[];
  resetChanges: () => void;
  createAvailabilityRules: ({
    weeklyPeriods,
  }: UseCreateAvailabilityRulesOptions) => Promise<void>;
  handleSetDirty: () => void;
  handleUnsetDirty: () => void;
  isDirty: boolean;
}

type PeriodsObject = Record<Weekday, Period[]>;
type EndtimeObject = Record<Weekday, string>;

interface EditRow {
  day: Weekday;
  key: string;
  start?: string;
  end?: string;
}

export const AvailabilityRules: FC<AvailabilityRulesProps> = ({
  weeklyPeriods,
  errors,
  resetChanges,
  createAvailabilityRules,
  handleSetDirty,
  handleUnsetDirty,
  isDirty,
}) => {
  const styles = useStyles();
  const [periods, setPeriods] = useState<PeriodsObject>(weeklyPeriods);
  const days: RowProps['day'][] = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];
  const [endTimes, setEndTimes] = useState(
    days.reduce((obj, day) => {
      // eslint-disable-next-line no-param-reassign
      obj[day] = periods[day][periods[day].length - 1]?.end_time;
      return obj;
    }, {} as EndtimeObject),
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const weeklyPeriods: Period[] = (
      Object.keys(periods) as Array<keyof typeof periods>
    ).reduce((array, day) => array.concat(periods[day]), [] as Period[]);
    await createAvailabilityRules({
      weeklyPeriods,
    });
    handleUnsetDirty();
  };

  const handleRemoveRow = (day: Weekday, key?: string) => {
    const newPeriods = periods[day].filter(r => r.key !== key);

    if (key) {
      setPeriods(prevPeriods => {
        return { ...prevPeriods, [day]: newPeriods };
      });
    }

    if (newPeriods.length) {
      setEndTimes(prevEndTimes => {
        return {
          ...prevEndTimes,
          [day]: newPeriods[newPeriods.length - 1].end_time,
        };
      });
    } else {
      setEndTimes(prevEndTimes => {
        return { ...prevEndTimes, [day]: '17:00' };
      });
    }
    handleSetDirty();
  };

  const handleAddRow = (day: Weekday) => {
    let start = '09:00';
    let end = '17:00';
    const subRows = periods[day];
    if (subRows.length > 0) {
      const lastPeriod = subRows[subRows.length - 1].end_time;
      invariant(lastPeriod, 'value not found');

      [start, end] = generateNextTimeRange(lastPeriod);
    }

    setPeriods(prevPeriods => {
      const prevPeriodDay = prevPeriods[day];
      const newPeriod = {
        start_time: start,
        end_time: end,
        day,
        key: `id${Math.random().toString(16).slice(2)}`,
      };

      return { ...prevPeriods, [day]: prevPeriodDay.concat(newPeriod) };
    });

    setEndTimes(prevEndTimes => {
      return { ...prevEndTimes, [day]: end };
    });
    handleSetDirty();
  };

  const handleEditRow = ({ day, key, start, end }: EditRow) => {
    const index = periods[day].findIndex(period => period.key === key);
    if (end && index === periods[day].length - 1) {
      setEndTimes(prevEndTimes => {
        return { ...prevEndTimes, [day]: end };
      });
    }

    setPeriods(prevPeriods => {
      const updatedPeriods = prevPeriods[day];
      if (start) updatedPeriods[index].start_time = start;
      if (end) updatedPeriods[index].end_time = end;
      return { ...prevPeriods, [day]: updatedPeriods };
    });
    handleSetDirty();
  };

  const handleCancel = () => {
    setPeriods(weeklyPeriods);
    handleUnsetDirty();
    resetChanges();
  };

  return (
    <form onSubmit={handleSubmit} className={styles.wrapper}>
      <div>
        {days.map(day => (
          <Row
            errors={errors}
            day={day}
            key={`day${day}`}
            periods={[...periods[day]]}
            endTime={endTimes[day]}
            onAddRow={() => handleAddRow(day)}
            onRemoveRow={key => handleRemoveRow(day, key)}
            onEditRow={({ key, start, end }: Omit<EditRow, 'day'>) =>
              handleEditRow({ day, key, start, end })
            }
          />
        ))}
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          className={styles.secondary}
          onClick={handleCancel}
          size="small"
          type="secondary"
        >
          <Typography variant="h4">
            <FormattedMessage {...messages.cancel} />
          </Typography>
        </Button>
        <Button
          className={styles.primary}
          size="small"
          type="primary"
          disabled={!isDirty}
          submit
        >
          <Typography variant="h4">
            <FormattedMessage {...messages.save} />
          </Typography>
        </Button>
      </div>
    </form>
  );
};
