import { Dispatch } from 'redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import { setFilterSetsAction } from 'containers/ApplicantsFilter/actions';
import { addMessageAction } from 'containers/FlashMessage/actions';
import { apiGet } from 'utils/axios';

interface CategoryField {
  label: string;
  operators: string[];
  type: string;
  field: string;
  values: string[];
}

interface Category {
  // eslint-disable-next-line camelcase
  category_type: string;
  fields: CategoryField[];
  label: string;
  name: string;
}
interface CategoryContainer {
  categories: Category;
}

interface CategoryApi {
  data: CategoryContainer;
}

async function fetchCategories(dispatch: Dispatch, errorMessage: string) {
  const categoryUrl = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicant_filter_sets/categories`;
  try {
    const response: CategoryApi = await apiGet(categoryUrl);
    dispatch(setFilterSetsAction(response.data.categories));
  } catch {
    dispatch(addMessageAction(errorMessage, 'error'));
  }
}

export { fetchCategories };
