/*
 *
 * ApplicantsFilter constants
 *
 */

import Contains from 'images/contains.svg';
import EndsWith from 'images/ends-with.svg';
import Equals from 'images/equals.svg';
import Assessment from 'images/filter_categories/assessment.svg';
import AssignedUser from 'images/filter_categories/assigned-user.svg';
import BackgroundCheck from 'images/filter_categories/background-check.svg';
import CustomLabel from 'images/filter_categories/custom-label.svg';
import Data from 'images/filter_categories/data.svg';
import DataCollection from 'images/filter_categories/data-collection.svg';
import Document from 'images/filter_categories/document.svg';
import DocumentSigning from 'images/filter_categories/document-signing.svg';
import FileReview from 'images/filter_categories/file-review.svg';
import FountainAi from 'images/filter_categories/fountain-ai.svg';
import Learning from 'images/filter_categories/learning.svg';
import Session from 'images/filter_categories/session.svg';
import SMS from 'images/filter_categories/sms.svg';
import VideoRecording from 'images/filter_categories/video-recording.svg';
import GreaterThan from 'images/greater-than.svg';
import GreaterEqual from 'images/greater-than-or-equal.svg';
import IsNotNull from 'images/is-not-null.svg';
import IsNull from 'images/is-null.svg';
import LessThan from 'images/less-than.svg';
import LessEqual from 'images/less-than-or-equal.svg';
import NotEqual from 'images/not-equal.svg';
import Reasons from 'images/reasons.svg';
import StartsWith from 'images/starts-with.svg';

export const SET_STAGE_CONDITIONS = 'app/ApplicantsFilter/SET_STAGE_CONDITIONS';
export const SET_DATA_CONDITIONS = 'app/ApplicantsFilter/SET_DATA_CONDITIONS';
export const SET_FILTERS = 'app/ApplicantsFilter/SET_FILTERS';
export const SET_FILTER_SETS = 'app/ApplicantsFilter/SET_FILTER_SETS';

export const SET_SELECTED_FILTER = 'app/ApplicantsFilter/SET_SELECTED_FILTER';
export const SET_TEMP_FILTER = 'app/ApplicantsFilter/SET_TEMP_FILTER';
export const CLEAR_FILTERS = 'app/ApplicantsFilter/CLEAR_FILTERS';

export const FETCH_FILTER_SETS_INIT =
  'app/ApplicantsFilter/FETCH_FILTER_SETS_INIT';
export const FETCH_FILTER_SETS_SUCCESS =
  'app/ApplicantsFilter/FETCH_FILTER_SETS_SUCCESS';
export const FETCH_FILTER_SETS_ERROR =
  'app/ApplicantsFilter/FETCH_FILTER_SETS_ERROR';

export const SAVE_FILTER_INIT = 'app/ApplicantsFilter/SAVE_FILTER_INIT';
export const SAVE_FILTER_SUCCESS = 'app/ApplicantsFilter/SAVE_FILTER_SUCCESS';
export const SAVE_FILTER_ERROR = 'app/ApplicantsFilter/SAVE_FILTER_ERROR';

export const FETCH_SAVED_FILTERS_INIT =
  'app/ApplicantsFilter/FETCH_SAVED_FILTERS_INIT';
export const FETCH_SAVED_FILTERS_SUCCESS =
  'app/ApplicantsFilter/FETCH_SAVED_FILTERS_SUCCESS';
export const FETCH_SAVED_FILTERS_ERROR =
  'app/ApplicantsFilter/FETCH_SAVED_FILTERS_ERROR';

export const FETCH_SAVED_FILTER_INIT =
  'app/ApplicantsFilter/FETCH_SAVED_FILTER_INIT';
export const FETCH_SAVED_FILTER_SUCCESS =
  'app/ApplicantsFilter/FETCH_SAVED_FILTER_SUCCESS';
export const FETCH_SAVED_FILTER_ERROR =
  'app/ApplicantsFilter/FETCH_SAVED_FILTER_ERROR';

export const DELETE_SAVED_FILTER_INIT =
  'app/ApplicantsFilter/DELETE_SAVED_FILTER_INIT';
export const DELETE_SAVED_FILTER_SUCCESS =
  'app/ApplicantsFilter/DELETE_SAVED_FILTER_SUCCESS';
export const DELETE_SAVED_FILTER_ERROR =
  'app/ApplicantsFilter/DELETE_SAVED_FILTER_ERROR';
export const UPDATE_SAVED_FILTER_SUCCESS =
  'app/ApplicantsFilter/UPDATE_SAVED_FILTER_SUCCESS';
export const UPDATE_SAVED_FILTER_ERROR =
  'app/ApplicantsFilter/UPDATE_SAVED_FILTER_ERROR';

export const HIDE_VALUE_EDITOR = {
  'IS NOT NULL': true,
  'IS NULL': true,
};

export const VALUE_EDITOR_TYPES = {
  DATE_PICKER: 'datepicker',
  DROPDOWN: 'dropdown',
  FILE: 'file',
  RADIO: 'radio',
  CHECKBOXES: 'checkboxes',
  ADDRESS: 'address',
};

export const MULTI_SELECT_OPERATORS = {
  include: true,
  not_include: true,
  include_any: true,
};

export const CATEGORY_ICONS = {
  'Custom Label': CustomLabel,
  'Checkr Background Check': BackgroundCheck,
  'Accurate Background Check': BackgroundCheck,
  'Everify Background Check': BackgroundCheck,
  'Onfido Background Check': BackgroundCheck,
  'Sterling Background Check': BackgroundCheck,
  'File Review': FileReview,
  Learning,
  'Document Signing': DocumentSigning,
  'Data collection': DataCollection,
  Data,
  Assessment,
  'Has Unread Messages': SMS,
  'Phone Platform': SMS,
  'Fountain AI': FountainAi,
  'Video Recording': VideoRecording,
  'Assigned User': AssignedUser,
  Scheduler: Session,
  Document,
  'On-Hold and Rejection Reasons': Reasons,
  'I-9 Document Check': FileReview,
};

export const DATE_OPERATORS = {
  eq: Equals,
  greater: GreaterThan,
  less: LessThan,
  today: '',
};

export const OPERATOR_ICONS = {
  // TODO: Get new icon for Not Contains
  not_contain: Contains,
  contain: Contains,
  include_any: Contains,
  include: Contains,
  not_include: NotEqual,
  ends_with: EndsWith,
  eq: Equals,
  greater_eq: GreaterEqual,
  greater: GreaterThan,
  less_eq: LessEqual,
  less: LessThan,
  not_eq: NotEqual,
  starts_with: StartsWith,
  is_null: IsNull,
  not_null: IsNotNull,
  exist: IsNotNull,
  not_exist: IsNotNull,
  is_empty: IsNotNull,
  equal_any: Contains,
};

export const OPERATOR_TRANSLATION = {
  'Does not contain': 'not_contain',
  Contains: 'contain',
  'Includes any': 'include_any',
  Includes: 'include',
  'Does not include': 'not_include',
  'Ends with': 'ends_with',
  '=': 'eq',
  '>=': 'greater_eq',
  '>': 'greater',
  '<=': 'less_eq',
  '<': 'less',
  '!=': 'not_eq',
  'Starts with': 'starts_with',
  'IS NULL': 'is_null',
  'IS NOT NULL': 'not_null',
  Exists: 'exist',
  'Does not exist': 'not_exist',
  'Is empty': 'is_empty',
  'Is equal to any': 'equal_any',
  not_contain: 'not_contain',
  contain: 'contain',
  include_any: 'include_any',
  include: 'include',
  not_include: 'not_include',
  ends_with: 'ends_with',
  eq: 'eq',
  greater_eq: 'greater_eq',
  greater: 'greater',
  less_eq: 'less_eq',
  less: 'less',
  not_eq: 'not_eq',
  starts_with: 'starts_with',
  is_null: 'is_null',
  not_null: 'not_null',
  exist: 'exist',
  not_exist: 'not_exist',
  is_empty: 'is_empty',
  equal_any: 'equal_any',
  today: 'today',
};
