import { theme } from '@fountain/fountain-ui-components';
import PropTypes from 'prop-types';
import React from 'react';

const ResetHiringGoalIcon = ({ color }: { color: string }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.25 0C7.275 0 6.3 0.375 5.625 1.125L4.725 1.95C4.425 2.25 4.425 2.7 4.725 3C5.025 3.3 5.475 3.3 5.775 3L6.675 2.1C7.5 1.275 9 1.275 9.825 2.1C10.275 2.55 10.5 3.15 10.5 3.75C10.5 4.35 10.275 4.95 9.825 5.325L9 6.225C8.7 6.525 8.7 6.975 9 7.275C9.15 7.425 9.375 7.5 9.525 7.5C9.675 7.5 9.9 7.425 10.05 7.275L10.95 6.375C11.625 5.7 12 4.725 12 3.75C12 2.775 11.625 1.8 10.875 1.125C10.2 0.375 9.225 0 8.25 0Z"
      fill={color}
    />
    <path
      d="M5.325 9.9C4.5 10.725 3 10.725 2.175 9.9C1.725 9.45 1.5 8.85 1.5 8.25C1.5 7.65 1.725 7.05 2.175 6.675L3 5.775C3.3 5.475 3.3 5.025 3 4.725C2.7 4.425 2.25 4.425 1.95 4.725L1.125 5.625C0.375 6.3 0 7.275 0 8.25C0 9.225 0.375 10.2 1.125 10.875C1.8 11.625 2.775 12 3.75 12C4.725 12 5.7 11.625 6.375 10.875L7.275 9.975C7.575 9.675 7.575 9.225 7.275 8.925C6.975 8.625 6.525 8.7 6.225 9L5.325 9.9Z"
      fill={color}
    />
    <path
      d="M3.90029 7.04993L7.05029 3.89993C7.35029 3.59993 7.80029 3.59993 8.10029 3.89993C8.40029 4.19993 8.40029 4.64993 8.10029 4.94993L4.95029 8.09993C4.80029 8.24993 4.57529 8.32493 4.42529 8.32493C4.27529 8.32493 4.05029 8.24993 3.90029 8.09993C3.60029 7.79993 3.60029 7.34993 3.90029 7.04993Z"
      fill={color}
    />
  </svg>
);

ResetHiringGoalIcon.propTypes = {
  color: PropTypes.string,
};

ResetHiringGoalIcon.defaultProps = {
  color: theme.palette.common.gray600,
};

export default ResetHiringGoalIcon;
