/*
 * ApplicantAge Messages
 *
 * This contains all the text for the ApplicantAge container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.ApplicantAge';

export default defineMessages({
  applicantAgeHint: {
    id: `${scope}.applicantAgeHint`,
    defaultMessage:
      "Age is calculated from 'date_of_birth' standard question. Make sure it is added in the application form or data collection questions before this rule.",
  },
  applicantAgeInputPlaceholder: {
    id: `${scope}.applicantAgeInputPlaceholder`,
    defaultMessage: 'Age in number',
  },
});
