import { useDispatch } from 'react-redux';

import { clearFiltersAction } from 'containers/ApplicantsFilter/actions';
import reducer from 'containers/ApplicantsFilter/reducer';
import { setSelectedFilterStageIdsWithFunnel } from 'containers/ApplicantTableV2/actions';
import { setSelectedFilterAction } from 'containers/JobsStagesFilterPopup/actions';
import jobsStagesFilterPopupReducer from 'containers/JobsStagesFilterPopup/reducer';
import { useInjectReducer } from 'utils/injectReducer';
import { removeParamsFromUrl } from 'utils/urlUtils';

import { useApplicantsFilter } from './useApplicantsFilter';

export default function useClearFilters() {
  const dispatch = useDispatch();
  const { fetchFilters } = useApplicantsFilter();
  useInjectReducer({ key: 'applicantsFilter', reducer });
  useInjectReducer({
    key: 'jobsStagesFilterPopup',
    reducer: jobsStagesFilterPopupReducer,
  });

  const clearFilters = () => {
    dispatch(clearFiltersAction());
    fetchFilters();
    removeParamsFromUrl({ temp_filter_id: null, filter_set_id: null });
  };

  const clearJobStagesFilters = () => {
    dispatch(setSelectedFilterStageIdsWithFunnel([]));
    dispatch(setSelectedFilterAction(null));
    removeParamsFromUrl({ stage_conditions_filter_set_id: null });
  };

  return { clearFilters, clearJobStagesFilters };
}
