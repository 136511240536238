import { Loader, LoadingOverlay } from '@fountain/fountain-ui-components';
import { WeeklyPeriods } from 'api-clients/monolith';
import { AvailabilityRules } from 'Calendar/AvailabilityRules/AvailabilityRules';
import { useCreateAvailabilityRules } from 'Calendar/AvailabilityRules/useCreateAvailabilityRules';
import { useGetAvailabilityRules } from 'Calendar/AvailabilityRules/useGetAvailabilityRules';
import React, { useEffect, useState, VFC } from 'react';

interface AvailabilityRulesContainerProps {
  handleSetDirty: () => void;
  handleUnsetDirty: () => void;
  isDirty: boolean;
}

export const AvailabilityRulesContainer: VFC<
  AvailabilityRulesContainerProps
> = props => {
  const [weeklyPeriods, setWeeklyPeriods] = useState<WeeklyPeriods>();

  const { result: useGetAvailabilityRulesResult } = useGetAvailabilityRules();
  const {
    createAvailabilityRules,
    availabilityRules: useCreateAvailabilityRulesResult,
    errors,
    reset,
  } = useCreateAvailabilityRules();

  useEffect(() => {
    if (useGetAvailabilityRulesResult.status === 'ready' && !weeklyPeriods) {
      setWeeklyPeriods({ ...useGetAvailabilityRulesResult.data });
    }
  }, [useGetAvailabilityRulesResult, weeklyPeriods]);

  useEffect(() => {
    if (useCreateAvailabilityRulesResult.status === 'ready') {
      setWeeklyPeriods({ ...useCreateAvailabilityRulesResult.data });
    }
  }, [useCreateAvailabilityRulesResult]);

  const resetChanges = () => {
    if (useCreateAvailabilityRulesResult.status === 'error') {
      reset();
    }
  };

  return (
    <>
      {useGetAvailabilityRulesResult.isLoading && <Loader fullSection />}
      {weeklyPeriods && (
        <LoadingOverlay
          open={useCreateAvailabilityRulesResult.status === 'loading'}
        >
          <AvailabilityRules
            weeklyPeriods={weeklyPeriods}
            resetChanges={resetChanges}
            errors={errors}
            createAvailabilityRules={createAvailabilityRules}
            {...props}
          />
        </LoadingOverlay>
      )}
    </>
  );
};
