import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  messageContainer: {
    width: '80%',
  },
  messageBubble: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    width: 'fit-content',
  },
  botBubble: {
    borderBottomRightRadius: 'unset',
    backgroundColor: '#8b49ffa6',
  },
  applicantBubble: {
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.common.gray300}`,
    borderBottomLeftRadius: 'unset',
    backgroundColor: theme.palette.common.white,
  },
  botMessage: {
    color: theme.palette.common.white,
  },
  applicantMessage: {
    color: theme.palette.common.gray800,
  },
  avatar: {
    alignSelf: 'flex-end',
    margin: theme.spacing(1, 1, 0, 1),
  },
  editResponseSubText: {
    margin: theme.spacing(0.75, 5, 0, 0),
  },
  messageContent: {
    fontSize: theme.typography.caption.fontSize,
    whiteSpace: 'pre-wrap',
  },
}));
