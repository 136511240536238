import { defineMessages } from 'react-intl';

export const scope = 'app.components.SendEmailPopup';

export default defineMessages({
  attachments: {
    id: `${scope}.attachments`,
    defaultMessage: 'Attachments',
  },
  delayEmail: {
    id: `${scope}.delayEmail`,
    defaultMessage: 'Delay sending message',
  },
  emailSubjectRequired: {
    id: `${scope}.emailSubjectRequired`,
    defaultMessage: 'Email subject is required',
  },
  emailSubjectPlaceholder: {
    id: `${scope}.emailSubjectPlaceholder`,
    defaultMessage: 'ex. Hello [applicant_name]',
  },
  emailTextRequired: {
    id: `${scope}.emailTextRequired`,
    defaultMessage: 'Email text is required',
  },
  includeAttachments: {
    id: `${scope}.includeAttachments`,
    defaultMessage: 'Include attachments',
  },
  searchUsers: {
    id: `${scope}.searchUsers`,
    defaultMessage: 'Search users...',
  },
  sendBulkEmail: {
    id: `${scope}.sendBulkEmail`,
    defaultMessage:
      'Send {applicantCount} {applicantCount, plural, one {Email} other {Emails}}',
  },
  sendBulkEmailSelectAllDefault: {
    id: `${scope}.sendBulkEmailSelectAllDefault`,
    defaultMessage: 'Send Emails',
  },
  templateNameRequired: {
    id: `${scope}.templateNameRequired`,
    defaultMessage: 'Template name is required',
  },
  noTemplateSelected: {
    id: `${scope}.noTemplateSelected`,
    defaultMessage: 'No Template selected - Write a new message',
  },
  i9Subject: {
    id: `${scope}.i9Subject`,
    defaultMessage: 'We found a problem on your I-9',
  },
  requestRevisionSubject: {
    id: `${scope}.requestRevisionSubject`,
    defaultMessage: 'We found a problem with your submission',
  },
  w4FederalSubject: {
    id: `${scope}.w4FederalSubject`,
    defaultMessage: 'We found a problem on your W-4 Federal Form',
  },
  w4FederalEmailTemplate: {
    id: `${scope}.w4FederalEmailTemplate`,
    defaultMessage:
      'Hi {applicant},{htmlBreakTag}It seems that there is an error in your W-4 Federal Form. Could you please redo the form via the link below {htmlBreakTag}{portal}',
  },
  w4StateSubject: {
    id: `${scope}.w4StateSubject`,
    defaultMessage: 'We found a problem on your W-4 State Form',
  },
  w4StateEmailTemplate: {
    id: `${scope}.w4StateEmailTemplate`,
    defaultMessage:
      'Hi {applicant},{htmlBreakTag}It seems that there is an error in your W-4 State Form. Could you please redo the form via the link below {htmlBreakTag}{portal}',
  },
  i9EmailTemplate: {
    id: `${scope}.i9EmailTemplate`,
    defaultMessage:
      'Hi {applicant},{htmlBreakTag}It seems that there is an error in your I-9 Form. Could you please redo the form via the link below {htmlBreakTag}{portal}',
  },
  i9SectionThreeRemoteSubject: {
    id: `${scope}.i9SectionThreeRemoteEmailSubject`,
    defaultMessage: 'I-9 Section Three Remote Authorization',
  },
  i9SectionThreeRemoteEmailTemplate: {
    id: `${scope}.i9SectionThreeRemoteEmailTemplate`,
    defaultMessage:
      'Hi {applicant},{htmlBreakTag}Your employment authorization expires soon. You will need to have an Authorized Representative verify your continued employment authorization.{htmlBreakTag} After completing Section 1 of Form I-9, Section 2 of Form I-9 must be completed no later than the first 3 days of your employment. To do this remotely, you must choose an authorized representative to verify your supporting documents. Use this form to complete your selection so we can provide further instructions to your representative. {htmlBreakTag}{portal}',
  },
  requestRevisionBody: {
    id: `${scope}.requestRevisionBody`,
    defaultMessage:
      'Hi {applicant},\n\nIt seems that there is an error with your submission. Could you please redo and resubmit the form via the link below?\n\n{portal}',
  },
  emailScheduledSuccess: {
    id: `${scope}.emailScheduledSuccess`,
    defaultMessage: 'Email has been scheduled!',
  },
  emailSentSuccess: {
    id: `${scope}.emailSentSuccess`,
    defaultMessage: 'Email has been sent!',
  },
  emailSentError: {
    id: `${scope}.emailSentError`,
    defaultMessage: 'There was an error sending the email',
  },
});
