import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  applicantAgeHint: {
    padding: theme.spacing(2.5, 1, 1, 1),
  },
  applicantAgeHintWrapper: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.gray100,
    borderRadius: 5,
  },
}));

export default useStyles;
