/**
 *
 * BulkSendEmailPopup
 *
 */

import 'react-datepicker/dist/react-datepicker.css';

import {
  Button,
  Drawer as DrawerPopup,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerHeaderIcon,
  IconButton as StyledIconButton,
  InputWithValidations as Input,
  StyledReactSelect,
} from '@fountain/fountain-ui-components';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { classNames } from 'react-extras';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import DesktopMessagePreview from 'components/DesktopMessagePreview';
import FormFactorChooser from 'components/FormFactorChooser';
import MobileMessagePreview from 'components/MobileMessagePreview';
import RequestRevisionSuccessPopup from 'components/RequestRevisionSuccessPopup';
import { makeSelectJob } from 'containers/ApplicantsV2/selectors';
import { SELECT_ALL } from 'containers/ApplicantTableV2/constants';
import {
  makeSelectCurrentBrand,
  makeSelectWhoami,
} from 'containers/Auth_old/selectors';
import MessageTemplateTextEditor from 'containers/MessageTemplateTextEditor';
import useApplicantFileRecollection from 'hooks/useApplicantFileRecollection';
import useBulkApplicantsTransition from 'hooks/useBulkApplicantsTransition';
import useBulkMessageApplicants from 'hooks/useBulkMessageApplicants';
import useForm from 'hooks/useForm';
import useGetStoredUser from 'hooks/useGetStoredUser';
import useKeyPress from 'hooks/useKeyPress';
import useUserPermissions from 'hooks/useUserPermissions';
import CloseIcon from 'images/close.svg';
import LeftArrow from 'images/left-arrow.svg';
import MailIcon from 'images/mail-blue.svg';
import PreviewIcon from 'images/preview-icon.svg';
import RequestRevisionSvg from 'images/RequestRevisionSvg';
import SearchIcon from 'images/search-icon.svg';
import globalMessages from 'shared/global/messages';
import { minDate } from 'utils/date';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import { fetchMembersInitAction } from '../../actions';
import {
  BULK_PARTNER_STAGE_REDO,
  MAX_REQUEST_REVISION_RECIPIENTS,
  TEMPLATE_TYPES,
} from '../../constants';
import useTranslatedConstants from '../../hooks';
import messages from '../../messages';
import reducer from '../../reducer';
import saga from '../../saga';
import { makeSelectMembers } from '../../selectors';
import useStyles from '../../styles';
import validate from '../../validate';
import { DelayMessageOptions } from '../DelayMessageOptions/DelayMessageOptions';
import RecipientsCard from '../RecipientsCard';
import RedoRecipientsCard from '../RedoRecipientsCard';

export function BulkSendEmailPopup({
  accountSlug,
  applicantIds, // HI-862 BulkSelect Support Needed
  fromMav,
  handleClose,
  intl,
  searchQuery,
  open,
  selectedApplicant,
  subject,
  body,
  attachments,
  applicantsCount,
  onSubmitCallback,
  disableSendingEmail,
  stage,
  location: { search },
}) {
  const dispatch = useDispatch();
  const cmd = useKeyPress('Meta');
  const enter = useKeyPress('Enter');
  const control = useKeyPress('Control');
  const selectAll = applicantIds === SELECT_ALL || undefined;

  const {
    SEND_TYPE,
    SEND_TYPE_OPTIONS,
    DEFAULT_TEMPLATE,
    REQUEST_REVISION_SUBJECT,
    REQUEST_REVISION_BODY,
  } = useTranslatedConstants();

  useApplicantFileRecollection(
    selectedApplicant && selectedApplicant.info.id,
    accountSlug,
  );

  const classes = useStyles();

  useInjectReducer({ key: 'sendEmailPopup', reducer });
  useInjectSaga({ key: 'sendEmailPopup', saga });

  const { templateType, partnerStageId, stageName } = queryString.parse(search);

  const currentBrand = useSelector(makeSelectCurrentBrand());
  const { sendMessagesFromUserPermission } = useUserPermissions();

  const currentUser = useSelector(makeSelectWhoami());
  const currentJob = useSelector(makeSelectJob());

  const timeZone = { timeZone: currentJob && currentJob.time_zone };

  const users = useSelector(makeSelectMembers());

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const [selectedUser, setSelectedUser, updateStoredUser] = useGetStoredUser(
    currentUser,
    users,
  );
  // actions
  const fetchMembersInit = useCallback(
    payload => dispatch(fetchMembersInitAction(payload)),
    [dispatch],
  );

  const [devicePreviewType, setDevicePreviewType] = useState('mobile');
  const onClientSelected = type => {
    setDevicePreviewType(type);
  };
  const [previewMessageOpenForMobile, setPreviewMessageOpenForMobile] =
    useState(false);

  const isRequestRevisionTemplate =
    templateType === TEMPLATE_TYPES.REQUEST_REVISION;

  const defaultValues = {
    selectedTemplate: DEFAULT_TEMPLATE,
    saveEmailAsTemplate: false,
    emailAttachments: attachments ?? [],
    includeAttachments: false,
    emailText: isRequestRevisionTemplate ? REQUEST_REVISION_BODY : body,
    emailSubject: isRequestRevisionTemplate
      ? REQUEST_REVISION_SUBJECT
      : subject,
    newEmailTemplateName: '',
    sendType: SEND_TYPE_OPTIONS[0],
    startDate: new Date(),
    sendAt: null,
    delaySendingMessage: false,
    name: undefined,
  };

  const cleanText = text => text.replace(/<\/?[^>]+(>|$)/g, '');

  const { createTemporaryMessageTemplate, handleBulkMessage, isSubmitting } =
    useBulkMessageApplicants({ messageType: 'email' });

  const { handleBulkApplicantsTransition } = useBulkApplicantsTransition();

  const handleDeliverEmail = async () => {
    let templateId =
      selectedTemplate.id !== DEFAULT_TEMPLATE.id && // confirms template is selected
      selectedTemplate.email_subject === emailSubject && // confirms template is unmodified
      selectedTemplate.email_text === cleanText(emailText) && // confirms template is unmodified
      selectedTemplate.id;

    if (!templateId) {
      const messageTemplateParams = {
        email_text: emailText,
        email_subject: emailSubject,
        email_enabled: true,
        attachment_parent_id: includeAttachments ? selectedTemplate.id : null,
        sender_id: selectedUser.id || selectedUser.external_id,
        ...(name && { name }),
      };
      templateId = await createTemporaryMessageTemplate(messageTemplateParams);
    }

    if (!disableSendingEmail) {
      await handleBulkMessage({
        applicantIds: (!selectAll && applicantIds) || undefined,
        templateId,
        search: selectAll && searchQuery,
        sendStart: delaySendingMessage ? sendAt.value : undefined,
      });
    }

    if (isRequestRevisionTemplate) {
      handleBulkApplicantsTransition({
        applicantIds: (!selectAll && applicantIds) || undefined,
        params: {
          to_stage: partnerStageId,
          should_run_when_land: false,
        },
        search: selectAll && searchQuery,
        type: BULK_PARTNER_STAGE_REDO,
      });
    }
    updateStoredUser();
    onSubmitCallback();
  };

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    isSubmitting: isSubmittingForm,
  } = useForm(handleDeliverEmail, validate, defaultValues);

  const {
    selectedTemplate,
    emailText,
    emailSubject,
    emailAttachments,
    includeAttachments,
    sendType,
    startDate,
    sendAt,
    delaySendingMessage,
    name,
  } = values;

  useEffect(() => {
    if (users && users.length < 1) {
      fetchMembersInit(currentBrand.slug);
    }
  }, [currentBrand.slug, fetchMembersInit, users, users.length]);

  useEffect(() => {
    if (currentJob) {
      handleChange({
        startDate: minDate(timeZone),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentJob]);

  const debouncedHandleSubmit = debounce(handleSubmit, 1000);

  // Submit form if cmd + enter keyed
  useEffect(() => {
    if ((cmd || control) && enter) {
      debouncedHandleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmd, control, enter, handleSubmit]);

  useEffect(() => {
    if (subject) {
      handleChange({
        emailSubject: subject,
        emailText: body,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject, body]);

  const handleIncludeAttachmentsChange = () => {
    handleChange({ includeAttachments: !includeAttachments });
  };

  const handleSelectTemplate = template => {
    handleChange({
      selectedTemplate: template,
      emailSubject: template && template.email_subject,
      emailText: template && template.email_text,
      emailAttachments: template && template.email_attachments,
      includeAttachments: template && template.email_attachments.length > 0,
      name: template && template.name,
    });
  };

  const insertEmailMergeKey = key => {
    const wrappedKey = `[${key}]`;
    const newEmailText = emailText ? emailText.concat(wrappedKey) : wrappedKey;
    handleChange({
      emailText: newEmailText,
    });
  };

  const confirmClose = () => {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        intl.formatMessage(globalMessages.closeConfirmationMessage),
      )
    ) {
      return handleClose();
    }

    return null;
  };

  const handleSelectDelaySendingMessage = () => {
    const sendNowOrLater =
      sendType.value === SEND_TYPE.NOW
        ? SEND_TYPE_OPTIONS[1]
        : SEND_TYPE_OPTIONS[0];
    handleChange({
      sendType: sendNowOrLater,
      sendAt: null,
      delaySendingMessage: !delaySendingMessage,
    });
  };

  const tooManyRecipients =
    isRequestRevisionTemplate &&
    applicantIds.length > MAX_REQUEST_REVISION_RECIPIENTS;

  const validateSubmit = () => {
    const selectedApplicantsCount = applicantIds.length;
    if (isRequestRevisionTemplate && selectedApplicantsCount > 50) {
      setIsSuccessModalOpen(true);
    } else {
      handleSubmit();
    }
  };

  const hasAttachments = emailAttachments.length > 0;
  const showExtraOptions = hasAttachments || !fromMav;

  // Another bandaid over select all state and applicant counts
  const headerMessageCount = () => {
    if (!selectAll) {
      return (
        <FormattedMessage
          {...messages.sendBulkEmail}
          values={{
            applicantCount: applicantIds && applicantIds.length,
          }}
        />
      );
    }
    if (applicantsCount) {
      return (
        <FormattedMessage
          {...messages.sendBulkEmail}
          values={{
            applicantCount: applicantsCount,
          }}
        />
      );
    }

    return <FormattedMessage {...messages.sendBulkEmailSelectAllDefault} />;
  };

  return (
    <DrawerPopup
      paperClassName={classes.emailDrawerContainer}
      onEscapeKeyDown={confirmClose}
      disableEscapeKeyDown
      open={open}
      handleClose={confirmClose}
      anchor="right"
      className={classes.sendEmailDrawer}
    >
      <Grid
        container
        direction="row"
        className={classes.sendEmailDrawerContent}
      >
        <Grid
          item
          container
          direction="column"
          wrap="nowrap"
          className={classNames(classes.sendEmailForm, {
            messagePreviewOpenForMobileView: previewMessageOpenForMobile,
          })}
        >
          <DrawerHeader className={classes.header}>
            <Grid
              container
              alignItems="center"
              direction="row"
              wrap="nowrap"
              className={classes.headerContent}
            >
              <DrawerHeaderIcon>
                {isRequestRevisionTemplate ? (
                  <RequestRevisionSvg className={classes.requestRevisionSvg} />
                ) : (
                  <img src={MailIcon} alt="Preview Message" />
                )}
              </DrawerHeaderIcon>
              <Typography variant="h3">
                {delaySendingMessage ? (
                  <FormattedMessage {...globalMessages.scheduleEmail} />
                ) : isRequestRevisionTemplate ? (
                  <FormattedMessage {...globalMessages.requestRevision} />
                ) : (
                  headerMessageCount()
                )}
              </Typography>
              <StyledIconButton
                className={classes.messagePreviewIcon}
                onClick={() =>
                  setPreviewMessageOpenForMobile(!previewMessageOpenForMobile)
                }
                role="presentation"
              >
                <img src={PreviewIcon} alt="preview-icon" />
              </StyledIconButton>
            </Grid>
          </DrawerHeader>
          <DrawerContent>
            {applicantsCount && applicantsCount > 0 && stage && (
              <div className={classes.section}>
                <Typography variant="body2" className={classes.label}>
                  {intl.formatMessage(globalMessages.recipients)}
                </Typography>
                <RecipientsCard
                  title={stage.name}
                  applicantsCount={applicantsCount}
                  intl={intl}
                />
              </div>
            )}
            {isRequestRevisionTemplate && (
              <div className={classes.section}>
                <Typography variant="body2" className={classes.label}>
                  Recipients
                </Typography>
                <RedoRecipientsCard
                  title={stageName}
                  applicantIds={applicantIds}
                  partnerStageId={partnerStageId}
                  maxRecipients={MAX_REQUEST_REVISION_RECIPIENTS}
                />
              </div>
            )}
            <div className={classes.section}>
              <StyledReactSelect
                isDisabled={!sendMessagesFromUserPermission}
                label={intl.formatMessage(globalMessages.sendFrom)}
                options={users}
                value={selectedUser}
                getOptionLabel={option => option.email}
                getOptionValue={option => option.id}
                onChange={setSelectedUser}
                placeholder={
                  <span>
                    <img
                      src={SearchIcon}
                      alt="search"
                      className={classes.searchIcon}
                    />
                    <FormattedMessage {...messages.searchUsers} />
                  </span>
                }
              />
            </div>
            <div className={classes.section}>
              <Input
                label={
                  <Typography variant="body2" className={classes.label}>
                    <FormattedMessage {...globalMessages.emailSubject} />
                  </Typography>
                }
                value={emailSubject}
                onChange={event =>
                  handleChange({ emailSubject: event.target.value })
                }
                autoFocus
                placeholder={intl.formatMessage(
                  messages.emailSubjectPlaceholder,
                )}
                fullWidth
                error={errors.emailSubject ? errors.emailSubject : ''}
              />
            </div>
            <div className={classes.section}>
              <Typography variant="body2" className={classes.label}>
                <FormattedMessage {...globalMessages.emailContent} />
              </Typography>
              <MessageTemplateTextEditor
                value={emailText}
                onChange={event => handleChange({ emailText: event })}
                errorText={errors.emailText}
                hasError={!!errors.emailText}
                required
                enableMessageTemplates
                handleSelectTemplate={handleSelectTemplate}
                handleMergeKeySelect={insertEmailMergeKey}
                enableHtmlModeSwitch
                enableMergeKeys
              />
            </div>
            {hasAttachments && (
              <div className={classes.attachments}>
                <Typography variant="body2" className={classes.label}>
                  <FormattedMessage {...messages.attachments} />
                </Typography>
                {emailAttachments.map(attachment => (
                  <Typography key={attachment.id}>
                    {attachment.filename}
                  </Typography>
                ))}
              </div>
            )}
            {showExtraOptions && (
              <Grid
                container
                direction="column"
                className={classes.checkboxContainer}
              >
                {hasAttachments && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={includeAttachments}
                        onChange={handleIncludeAttachmentsChange}
                      />
                    }
                    label={
                      <Typography variant="body2" color="textPrimary">
                        <FormattedMessage {...messages.includeAttachments} />
                      </Typography>
                    }
                  />
                )}
                {!fromMav && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={delaySendingMessage}
                        onChange={handleSelectDelaySendingMessage}
                      />
                    }
                    label={
                      <Typography variant="body2" color="textPrimary">
                        <FormattedMessage {...messages.delayEmail} />
                      </Typography>
                    }
                  />
                )}
              </Grid>
            )}

            {delaySendingMessage && !fromMav && (
              <div className={classes.section}>
                <DelayMessageOptions
                  handleChange={handleChange}
                  sendType={sendType}
                  startDate={startDate}
                  timeZone={timeZone}
                  sendAt={sendAt}
                />
              </div>
            )}
          </DrawerContent>
          <DrawerFooter>
            <Button
              className={classes.cancelButton}
              type="secondary"
              onClick={handleClose}
            >
              <FormattedMessage {...globalMessages.cancel} />
            </Button>
            <Button
              onClick={validateSubmit}
              disabled={
                !emailText ||
                !emailSubject ||
                isSubmitting ||
                isSubmittingForm ||
                tooManyRecipients
              }
              isLoading={isSubmitting}
              data-testid="send-email-popup"
            >
              {delaySendingMessage ? (
                <FormattedMessage {...globalMessages.schedule} />
              ) : (
                <FormattedMessage {...globalMessages.send} />
              )}
            </Button>
          </DrawerFooter>
        </Grid>

        {/* Preview */}
        <Grid
          item
          container
          direction="column"
          wrap="nowrap"
          className={classNames(classes.messagePreviewContainer, {
            messagePreviewOpenForMobileView: previewMessageOpenForMobile,
          })}
        >
          <DrawerHeader>
            <IconButton
              className={classes.backButton}
              onClick={() =>
                setPreviewMessageOpenForMobile(!previewMessageOpenForMobile)
              }
            >
              <img src={LeftArrow} alt="back-button" />
            </IconButton>
            <IconButton onClick={handleClose} className={classes.closeButton}>
              <img src={CloseIcon} alt="close-icon" role="presentation" />
            </IconButton>
            <FormFactorChooser
              className={classes.formFactorChooser}
              typeSelected={devicePreviewType}
              onClientSelected={onClientSelected}
            />
          </DrawerHeader>
          <DrawerContent className={classes.messagePreviewDeviceView}>
            {devicePreviewType === 'mobile' && (
              <MobileMessagePreview
                type="email"
                fromEmail={selectedUser.email}
                messageSubject={emailSubject}
                messageText={emailText || undefined}
              />
            )}
            {devicePreviewType === 'desktop' && (
              <DesktopMessagePreview
                fromEmail={selectedUser.email}
                messageSubject={emailSubject}
                messageText={emailText}
              />
            )}
          </DrawerContent>
        </Grid>
        {/* Preview END */}
      </Grid>
      {isSuccessModalOpen && (
        <RequestRevisionSuccessPopup
          handleSubmit={handleSubmit}
          setIsSuccessModalOpen={setIsSuccessModalOpen}
        />
      )}
    </DrawerPopup>
  );
}

BulkSendEmailPopup.defaultProps = {
  onSubmitCallback: () => {},
  body: '',
  subject: '',
};

BulkSendEmailPopup.propTypes = {
  accountSlug: PropTypes.string.isRequired,
  applicantIds: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,
  fromMav: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  searchQuery: PropTypes.string,
  selectedApplicant: PropTypes.object,
  body: PropTypes.string,
  subject: PropTypes.string,
  attachments: PropTypes.array,
  applicantsCount: PropTypes.number,
  stage: PropTypes.object,
  onSubmitCallback: PropTypes.func,
  disableSendingEmail: PropTypes.bool,
  location: PropTypes.object.isRequired,
};

export default withRouter(BulkSendEmailPopup);
