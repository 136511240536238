import { Loader } from '@fountain/fountain-ui-components';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChatbotWidgetService } from 'api-clients/monolith';
import React, { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';

import { BackToCompanySettings } from 'components/BackToCompanySettings';
import { useApiService } from 'hooks/useApiService';

import { messages } from './messages';
import { PreviewWidget } from './PreviewWidget';
import { PublishChatbotWidget } from './PublishChatbotWidget';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: theme.spacing(0, 10, 0, 10),
    marginLeft: 'auto',
    marginRight: 'auto',
    gap: theme.spacing(10),
  },
  settingsContainer: {
    width: '452px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // marginRight: theme.spacing(10),
  },
  header: {
    marginTop: theme.spacing(2),
  },
  headerText: {
    lineHeight: '32px',
    fontWeight: 500,
  },
}));

export const ChatbotWidget = () => {
  const intl = useIntl();
  const classes = useStyles();

  const [widgetUrl, setWidgetUrl] = useState({ widgetUrl: '', previewUrl: '' });
  const [isWidgetLoading, setIsWidgetLoading] = useState(true);

  const { result: chatbotWidgetBrands } = useApiService(
    useCallback(
      () => ChatbotWidgetService.getInternalApiChatbotChatbotWidgetBrands(),
      [],
    ),
  );

  const { brands, defaultBrandId } = useMemo(() => {
    if (chatbotWidgetBrands.status === 'ready') {
      const { brands, default_brand_id: defaultBrandId } =
        chatbotWidgetBrands.data;

      if (brands.length === 1)
        return {
          brands,
          defaultBrandId,
        };
      if (brands.length > 1)
        return {
          brands: [{ label: 'All brands', key: '*' }, ...brands],
          defaultBrandId,
        };
    }
    return { brands: [], defaultBrandId: null };
  }, [chatbotWidgetBrands]);

  if (chatbotWidgetBrands.isLoading) {
    return <Loader fullScreen size="2rem" />;
  }

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage(messages.header)}</title>
        <meta name="description" content="Web Widget" />
      </Helmet>
      <div className={classes.container}>
        <div className={classes.settingsContainer}>
          <div className={classes.header}>
            <BackToCompanySettings />
            <Typography
              display="inline"
              variant="h2"
              className={classes.headerText}
            >
              <FormattedMessage {...messages.header} />
            </Typography>
          </div>
          {brands?.length > 0 && defaultBrandId && (
            <PublishChatbotWidget
              brands={brands}
              defaultBrandId={defaultBrandId}
              widgetUrl={widgetUrl.widgetUrl}
              setWidgetUrl={setWidgetUrl}
              setIsWidgetLoading={setIsWidgetLoading}
            />
          )}
        </div>
        <PreviewWidget
          isWidgetLoading={isWidgetLoading}
          setIsWidgetLoading={setIsWidgetLoading}
          widgetUrl={widgetUrl.previewUrl}
        />
      </div>
    </>
  );
};
