import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { makeStyles } from '@material-ui/core/styles';
import { ChatbotService } from 'api-clients/monolith';
import { ChatbotWidgetSettingFunnel } from 'api-clients/monolith/models/ChatbotWidgetSettingFunnel';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { HandleSettingChange } from '../types';
import { messages } from './messages';

const useStyles = makeStyles(theme => ({
  input: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4.5),
  },
}));

export interface FunnelSelectorProps {
  selectedBrandId: string;
  isAllBrandsSelected: boolean;
  selectedFunnels: ChatbotWidgetSettingFunnel[];
  handleSettingChange: HandleSettingChange;
}

export const FunnelSelector: React.VFC<FunnelSelectorProps> = ({
  selectedBrandId,
  selectedFunnels,
  handleSettingChange,
  isAllBrandsSelected,
}) => {
  const [funnelOptions, setFunnelOptions] = useState<
    Array<ChatbotWidgetSettingFunnel>
  >([]);
  const [funnelSearchText, setFunnelSearchText] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getSuggestions = useCallback(
    debounce((query: string) => {
      ChatbotService.getInternalApiChatbotSettingsSearchFunnels(
        selectedBrandId,
        isAllBrandsSelected,
        query,
      )
        .then((response: ChatbotWidgetSettingFunnel[]) =>
          setFunnelOptions(response),
        )
        .catch(() => setIsLoading(false))
        .finally(() => setIsLoading(false));
    }, 500),
    [selectedBrandId, isAllBrandsSelected],
  );

  useEffect(() => {
    setIsLoading(true);
    void getSuggestions(funnelSearchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funnelSearchText, getSuggestions]);

  useEffect(() => {
    setFunnelSearchText('');
    setFunnelOptions([]);
    void getSuggestions(funnelSearchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrandId]);

  const styles = useStyles();
  const intl = useIntl();

  const handleChange = (funnels: ChatbotWidgetSettingFunnel[]) => {
    setFunnelSearchText('');
    handleSettingChange('selected_funnels', funnels);
  };

  return (
    <StyledReactSelect
      isMulti
      options={funnelOptions}
      value={selectedFunnels}
      onChange={handleChange}
      label={intl.formatMessage(messages.selectOpeningsLabel)}
      placeholder={intl.formatMessage(messages.selectOpeningsPlaceholder)}
      aria-label={intl.formatMessage(messages.selectOpeningsPlaceholder)}
      getOptionLabel={(funnel: ChatbotWidgetSettingFunnel) => funnel.title}
      getOptionValue={(funnel: ChatbotWidgetSettingFunnel) =>
        funnel.external_id
      }
      inputValue={funnelSearchText}
      onInputChange={(text: string, action: { action: string }) => {
        if (action.action === 'input-change') setFunnelSearchText(text);
      }}
      isLoading={isLoading}
      className={styles.input}
    />
  );
};
