import { Button } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { formatBytes } from 'react-dropzone-uploader';
import { useDispatch } from 'react-redux';

import { deleteApplicantsUpload } from 'containers/ApplicantsImportModal/actions';
import CheckmarkIcon from 'images/checkmark_white.svg';
import DeleteIcon from 'images/delete-upload.svg';
import UploadIcon from 'images/upload.svg';

import useStyles from './styles';

// TODO:: circular loader
const FilePreview = props => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const removeUploadedFile = () => {
    dispatch(deleteApplicantsUpload());
    props.fileWithMeta.remove();
  };

  const cancelUpload = () => {
    props.fileWithMeta.cancel();
  };

  const { name, percent, previewUrl, size, status } = props.meta;

  return (
    <>
      {status === 'done' ? (
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.previewContainer}
        >
          {previewUrl && (
            <img
              src={previewUrl}
              alt="file preview"
              className={classes.filePreview}
            />
          )}
          <Grid container justify="space-between" alignItems="center">
            <Grid className={classes.previewDetails} title={name}>
              <span className={classes.previewIconContainer}>
                <img src={CheckmarkIcon} alt="active-icon" />
              </span>
              <Typography
                variant="body2"
                className={classes.fileName}
                display="inline"
                noWrap
              >
                {name}&nbsp;
              </Typography>
              <Typography variant="body2" display="inline">
                {formatBytes(size)}
              </Typography>
            </Grid>
            <button
              className={classes.deleteButton}
              type="button"
              onClick={removeUploadedFile}
            >
              <img src={DeleteIcon} alt="delete" />
            </button>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.previewContainer}
        >
          <div className={classes.uploadContainer}>
            <div className={classes.uploadInnerContainer}>
              <img src={UploadIcon} alt="upload" />
            </div>
          </div>
          <Typography color="primary">Uploading ({percent}%)</Typography>
          <Grid container alignItems="center" justify="center">
            <Typography variant="body2">
              {name} ({formatBytes(size * percent)}/{formatBytes(size)})&nbsp;
            </Typography>
            <Button type="text" onClick={cancelUpload} autoWidth>
              Cancel &times;
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

FilePreview.propTypes = {
  fileWithMeta: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default FilePreview;
