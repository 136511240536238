import { TableCell, Typography } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import React, { FC, memo } from 'react';
import { classNames } from 'react-extras';
import { FormattedDate } from 'react-intl';
import { useSelector } from 'react-redux';

import { makeSelectTableColumnWidths } from 'containers/ApplicantTableV2/selectors';
import { TableColumnWidthsKey } from 'containers/ApplicantTableV2/types';

import useStyles from './styles';

export interface ApplicantTableV2DateCellProps {
  date?: string | null;
  columnType: TableColumnWidthsKey;
  textAlign: TypographyProps['align'];
}
export const ApplicantTableV2DateCell: FC<ApplicantTableV2DateCellProps> = ({
  date,
  columnType,
  textAlign,
}) => {
  const styles = useStyles();
  const tableColumnWidths = useSelector(makeSelectTableColumnWidths());

  return (
    <TableCell
      role="cell"
      style={{
        minWidth: tableColumnWidths[columnType],
        maxWidth: tableColumnWidths[columnType],
      }}
      className={classNames(styles.td)}
    >
      {date ? (
        <Typography
          variant="caption"
          className={styles.dateText}
          align={textAlign}
        >
          <FormattedDate dateStyle="short" value={new Date(date)} />
        </Typography>
      ) : null}
    </TableCell>
  );
};

export default memo(ApplicantTableV2DateCell);
