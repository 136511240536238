import { makeStyles } from '@material-ui/core/styles';

import { INITIALS_CONTAINER_WIDTH } from './constants';

const getBackgroundColor = ({ avatarGroup, color, theme }) => {
  switch (color) {
    case 'avatar':
      return theme.palette.avatar[avatarGroup];
    case 'secondary':
      return theme.palette.common.orange100;
    case 'primary':
      return theme.palette.common.blue100;
    case 'mobile':
      return theme.palette.common.white;
    default:
      return theme.palette.common.gray600;
  }
};

const getAdditionalContainerStyles = ({ theme, ...props }) => {
  if (props.variant === 'outlined') {
    return {
      border: theme.customBorders.gray200,
      backgroundColor: theme.palette.common.white,
    };
  }
  return {
    backgroundColor: getBackgroundColor({
      avatarGroup: props.avatarGroup,
      color: props.color,
      theme,
    }),
  };
};

const getTextColor = ({ avatarGroup, color, theme, variant }) => {
  switch (color) {
    case 'avatar':
      if (variant === 'contained') {
        return theme.palette.common.white;
      }
      return theme.palette.avatar[avatarGroup];
    case 'secondary':
      return theme.palette.common.orange400;
    case 'primary':
      return theme.palette.primary.main;
    case 'mobile':
      return theme.palette.common.black;
    default:
      return theme.palette.common.white;
  }
};

const useStyles = makeStyles(theme => ({
  initialsContainer: props => {
    const size = INITIALS_CONTAINER_WIDTH[props.size];
    return {
      height: size,
      width: size,
      minWidth: size,
      borderRadius: '35px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...getAdditionalContainerStyles({ theme, ...props }),
    };
  },
  initialText: {
    color: props =>
      getTextColor({
        avatarGroup: props.avatarGroup,
        color: props.color,
        theme,
        variant: props.variant,
      }),
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
      fontWeight: 500,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      fontWeight: 600,
    },
  },
}));

export default useStyles;
