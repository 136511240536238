import { WorkflowLearningStage } from 'api-clients/monolith';
import React, { VFC } from 'react';

import { StageSection } from 'containers/WorkflowEditor/components/StageSection';

import { IdleMoveRule } from '../../../IdleMoveRule';
import { StageSettingMessageCard } from '../../../StageSettingMessageCard';
import { IntegrationCard } from './IntegrationCard';

export const LearningStage: VFC<{ stage: WorkflowLearningStage }> = ({
  stage,
}) => {
  return (
    <div data-testid="lessonlyStage">
      <StageSection>
        <IntegrationCard stage={stage} />
      </StageSection>
      <StageSection>
        <IdleMoveRule stage={stage} />
      </StageSection>
      <StageSection>
        <StageSettingMessageCard
          messageType="learning"
          stageExternalId={stage.external_id}
        />
      </StageSection>
      <StageSection>
        <StageSettingMessageCard
          messageType="trigger"
          stageExternalId={stage.external_id}
        />
      </StageSection>
    </div>
  );
};
