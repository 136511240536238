import {
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { MobileRow } from 'Calendar/EventManager/ManageRoster/MobileRow';
import {
  Actions,
  QuickActions,
} from 'Calendar/EventManager/ManageRoster/QuickActions';
import { Row } from 'Calendar/EventManager/ManageRoster/Row';
import { messages } from 'Calendar/EventManager/messages';
import cx from 'classnames';
import React, { useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { APPLICANT_DRAWER_TABS } from 'containers/ApplicantDrawerPopup/constants';
import { setSelectedApplicantIds } from 'containers/ApplicantTableV2/actions';
import {
  openApplicantDrawerPopup,
  openMoveApplicantPopup,
  openMoveApplicantToJobPopup,
  openRejectApplicantModal,
} from 'containers/GlobalPopup/actions';
import { BulkSendEmailPopup } from 'containers/SendEmailPopup/components/BulkSendEmail/BulkSendEmailPopup';
import { BulkSendSmsPopup } from 'containers/SendSmsPopup/BulkSendSmsPopup';

import useApplicantDetail from '../../../hooks/useApplicantDetail';
import { useStyles } from './styles';
import { RosterApplicant, rowActions } from './util';
interface RouteParams {
  accountSlug: string;
}

enum drawer {
  sms,
  email,
}

export interface BulkActionTableProps {
  applicants: RosterApplicant[];
  eventExternalId: string;
  manageRoster: (updatePayload: {
    // eslint-disable-next-line camelcase
    available_slot_id: string;
    // eslint-disable-next-line camelcase
    applicant_ids: string[];
    action: 'absent' | 'attended' | 'advanced';
  }) => void;
  applicantsToggle: (checked: boolean) => void;
  checkApplicant: (applicant: RosterApplicant) => void;
  columns: { key: keyof RosterApplicant; title: string; keyType: string }[];
}

export const BulkActionTable: VFC<BulkActionTableProps> = ({
  applicants,
  eventExternalId,
  manageRoster,
  applicantsToggle,
  checkApplicant,
  columns,
}) => {
  const intl = useIntl();
  const styles = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [popup, setPopup] = useState<null | drawer>(null);
  const applicantIds = applicants.filter(a => a.checked).map(a => a.id);

  const perform = (action: Actions) => {
    switch (action) {
      case 'sms':
        setPopup(drawer.sms);
        break;
      case 'email':
        setPopup(drawer.email);
        break;
      case 'move':
        dispatch(setSelectedApplicantIds(applicantIds));
        dispatch(openMoveApplicantToJobPopup({ transitionType: 'move' }));
        break;
      default:
        if (applicantIds.length > 0) {
          manageRoster({
            // eslint-disable-next-line camelcase
            available_slot_id: eventExternalId,
            // eslint-disable-next-line camelcase
            applicant_ids: applicantIds,
            action,
          });
        }
    }
  };

  const location = useLocation();
  const count = applicants.filter(a => a.checked).length;
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const isMega = useMediaQuery<Theme>(theme => theme.breakpoints.up('xl'));
  const applicantsAllChecked = !applicants.some(a => !a.checked);
  const { accountSlug } = useParams<RouteParams>();
  const { data: applicantDetailData } = useApplicantDetail(applicantIds[0]);

  const getWidthByField = (field: string) => {
    switch (field) {
      case 'name':
      case 'opening':
        return theme.spacing(30); // 240px
      case 'stageTitle':
        return isMega ? theme.spacing(50) : theme.spacing(30); // 400px : 240px
      case 'phone':
      case 'attended':
      default:
        return theme.spacing(21); // 168px
    }
  };

  const performOnApplicant = (
    action: rowActions,
    applicant: RosterApplicant,
  ) => {
    switch (action) {
      case 'absent':
      case 'attended':
        manageRoster({
          // eslint-disable-next-line camelcase
          available_slot_id: eventExternalId,
          // eslint-disable-next-line camelcase
          applicant_ids: [applicant.id],
          action,
        });
        break;
      case 'open':
        dispatch(
          openApplicantDrawerPopup(applicant.id, APPLICANT_DRAWER_TABS.GENERAL),
        );
        break;
      case 'reject':
        dispatch(openRejectApplicantModal({ applicantId: applicant.id }));
        break;
      default:
        dispatch(
          openMoveApplicantPopup({
            applicantId: applicant.id,
            transitionType: action,
          }),
        );
    }
  };

  return (
    <TableContainer
      component={Paper}
      className={cx(styles.rosterContainer, {
        [styles.mobileContainer]: isMobile,
      })}
      elevation={0}
    >
      {count > 0 && <QuickActions count={count} perform={perform} />}
      {popup === drawer.sms && applicantDetailData && (
        <BulkSendSmsPopup
          accountSlug={accountSlug}
          applicantIds={applicantIds}
          fromMav
          handleClose={() => setPopup(null)}
          intl={intl}
          open
          selectedApplicant={applicantDetailData}
        />
      )}
      {popup === drawer.email && (
        <BulkSendEmailPopup
          accountSlug={accountSlug}
          applicantIds={applicantIds}
          fromMav
          handleClose={() => setPopup(null)}
          intl={intl}
          open
          selectedApplicant={applicantDetailData}
          location={location}
          onSubmitCallback={() => setPopup(null)}
        />
      )}

      <Table>
        <TableHead>
          {isMobile ? ( // 2 column layout
            <TableRow className={styles.tableHead}>
              <TableCell width="38px">
                <Grid container>
                  <Checkbox
                    checked={applicantsAllChecked}
                    onClick={() => applicantsToggle(applicantsAllChecked)}
                    inputProps={{
                      'aria-label': intl.formatMessage(
                        applicantsAllChecked
                          ? messages.selectNone
                          : messages.selectAll,
                      ),
                    }}
                  />
                </Grid>
              </TableCell>
              <TableCell width="*" />
            </TableRow>
          ) : (
            // 6 Column layout
            // Default checkbox column
            <TableRow className={styles.tableHead}>
              <TableCell width="128px">
                <Grid container>
                  <Checkbox
                    checked={applicantsAllChecked}
                    onClick={() => applicantsToggle(applicantsAllChecked)}
                    inputProps={{
                      'aria-label': intl.formatMessage(
                        applicantsAllChecked
                          ? messages.selectNone
                          : messages.selectAll,
                      ),
                    }}
                  />
                </Grid>
              </TableCell>
              {/* Other columns */}
              {columns.map(({ key, title }, idx) => (
                <TableCell
                  key={key}
                  width={
                    // We want to push the last column to the right
                    idx === columns.length - 2 ? '*' : getWidthByField(key)
                  }
                >
                  <Typography variant="h6">
                    {messages[key as keyof typeof messages] ? (
                      <FormattedMessage
                        {...messages[key as keyof typeof messages]}
                      />
                    ) : (
                      title
                    )}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {applicants.map(applicant =>
            isMobile ? (
              <MobileRow
                key={applicant.id}
                applicant={applicant}
                check={checkApplicant}
                perform={performOnApplicant}
              />
            ) : (
              <Row
                key={applicant.id}
                applicant={applicant}
                check={checkApplicant}
                perform={performOnApplicant}
                columns={columns}
              />
            ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
