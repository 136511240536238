import { ReloadableResultStatus } from 'api/resultStatus';
import React, { useCallback, useEffect, useState } from 'react';

import LocationSelect from '../LocationSelect';
import LocationTextField from '../LocationTextField';
import { AutocompleteOption } from '../types';

const noneOption = {
  key: '',
  value: '',
};

export const useHandleLocationsInput = (
  locations: Array<{
    /* eslint-disable camelcase */
    onsite_address: string;
  }>,
  inputLocation: string,
  setInputLocation: (location: string) => void,
  selectedLocation: { onsite_address: string },
  setSelectedLocation: (location: { onsite_address: string }) => void,
  locationResult: ReloadableResultStatus<
    { onsite_addresses: { onsite_address: string }[] },
    /* eslint-enable camelcase */
    unknown
  >,
  setIsAddingLocationFromInput: (addingNewLocation: boolean) => void,
) => {
  const hasInputLocation = useCallback((): boolean => {
    return inputLocation !== '';
  }, [inputLocation]);
  const defaultValue = hasInputLocation()
    ? { key: inputLocation, value: inputLocation }
    : noneOption;
  const [autoCompleteValue, setAutocompleteValue] =
    useState<AutocompleteOption>(defaultValue);

  const [inputAutocompleteValue, setInputAutocompleteValue] = useState('');
  const [autocompleteOptions, setAutocompleteOptions] = useState<
    Array<AutocompleteOption>
  >([defaultValue]);

  const [isAddingNewLocation, setIsAddingNewLocation] =
    useState<boolean>(false);

  const isLocationIncluded = (
    availableLocations: AutocompleteOption[],
    location: string,
  ): boolean => {
    return availableLocations.some(loc => loc.key === location);
  };

  useEffect(() => {
    const availableLocations: AutocompleteOption[] = hasInputLocation()
      ? [{ key: inputLocation, value: inputLocation }]
      : [];
    if (locationResult.status === 'ready') {
      locationResult.data.onsite_addresses.forEach(l => {
        if (!isLocationIncluded(availableLocations, l.onsite_address)) {
          availableLocations.push({
            key: l.onsite_address,
            value: l.onsite_address,
          });
        }
      });
      if (!isLocationIncluded(availableLocations, autoCompleteValue.key)) {
        setAutocompleteValue(noneOption);
      }
    }
    setAutocompleteOptions(availableLocations);
  }, [autoCompleteValue.key, locationResult, hasInputLocation, inputLocation]);

  return useCallback(() => {
    const handleAddNewLocation = () => {
      if (isAddingNewLocation && inputLocation) {
        const newLocation = {
          key: inputLocation,
          value: inputLocation,
        };
        setAutocompleteOptions([...autocompleteOptions, newLocation]);
        setAutocompleteValue(newLocation);
      }
      setIsAddingNewLocation(false);
    };

    if (locations?.length > 1 && !isAddingNewLocation) {
      return (
        <LocationSelect
          autoCompleteValue={autoCompleteValue}
          setAutocompleteValue={setAutocompleteValue}
          inputAutocompleteValue={inputAutocompleteValue}
          setInputAutocompleteValue={setInputAutocompleteValue}
          autocompleteOptions={autocompleteOptions}
          setSelectedLocation={setSelectedLocation}
          setIsAddingNewLocation={setIsAddingNewLocation}
          setInputLocation={setInputLocation}
          setIsAddingLocationFromInput={setIsAddingLocationFromInput}
        />
      );
    }

    return (
      <LocationTextField
        inputLocation={inputLocation}
        setInputLocation={setInputLocation}
        handleAddNewLocation={handleAddNewLocation}
        setIsAddingLocationFromInput={setIsAddingLocationFromInput}
      />
    );
  }, [
    locations,
    inputLocation,
    setInputLocation,
    setSelectedLocation,
    autoCompleteValue,
    inputAutocompleteValue,
    isAddingNewLocation,
    autocompleteOptions,
    setIsAddingLocationFromInput,
  ]);
};
