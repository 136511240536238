import './styles.css';

import { Button, CalendarSync } from '@fountain/fountain-ui-components';
import { Grid, List, ListItem, Typography } from '@material-ui/core';
import React, { useState, VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { CronofyCalendarSync } from './CronofyElements';
import { messages } from './messages';
import { useStyles } from './styles';

export interface LetsSyncYourCalendarProps {
  connected: boolean;
  elementToken: string | null;
  initialState?: number;
}

export const LetsSyncYourCalendar: VFC<LetsSyncYourCalendarProps> = ({
  connected,
  elementToken,
  initialState,
}) => {
  const styles = useStyles();
  const [flow, setFlow] = useState<number>(initialState ?? 0);

  if (connected) {
    return (
      <Grid container>
        <Grid item md={8} lg={8}>
          <Grid
            container
            direction="column"
            className={styles.availabilityContainer}
          >
            <Grid item>
              <Typography variant="h3">
                <FormattedMessage {...messages.calendarIntegrationTab} />
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                <FormattedMessage {...messages.calendarIntegrationDetail} />
              </Typography>
            </Grid>
            <Grid item>
              <CronofyCalendarSync elementToken={elementToken} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {flow === 0 && (
        <div>
          <div className={styles.syncWrapper}>
            <div className={styles.unconnectedWrapper}>
              <CalendarSync className={styles.centeredCalendarImage} />
              <Typography variant="h2">
                <FormattedMessage {...messages.notConnectedYetTitle} />
              </Typography>
              <Typography variant="body2">
                <FormattedMessage {...messages.notConnectedYetDescription} />
              </Typography>
              <List className={styles.calendarSyncBulletContainer}>
                <ListItem className={styles.calendarSyncBullets}>
                  <Typography variant="body2">
                    <FormattedMessage
                      {...messages.calendarIntegrationBullet1}
                    />
                  </Typography>
                </ListItem>
                <ListItem className={styles.calendarSyncBullets}>
                  <Typography variant="body2">
                    <FormattedMessage
                      {...messages.calendarIntegrationBullet2}
                    />
                  </Typography>
                </ListItem>
              </List>
              <Button
                type="primary"
                className={styles.connectButton}
                onClick={() => setFlow(1)}
              >
                <Typography variant="h4">
                  <FormattedMessage {...messages.startConnectingCalendar} />
                </Typography>
              </Button>
            </div>
          </div>
        </div>
      )}
      {flow === 1 && <CronofyCalendarSync />}
    </>
  );
};
