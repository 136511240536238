import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import { getConditionErrorMessage } from 'containers/WorkflowEditor/components/Rules/Condition/constants';
import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';

import { DocumentSigningRuleProps } from '../../../DocumentSigningRules/types';
import { RulesProps, SharedConditionProps } from '../../../types';
import { messages } from './messages';

export const PartnerOptionSelect: FC<SharedConditionProps> = ({
  condition,
  ruleId,
  setRules,
  setDocumentSigningRules,
  errors,
}) => {
  const intl = useIntl();
  const { partner_options: partnerOptions } = useContext(RulesEditDataContext);
  const { extra } = condition;

  const associatedPartnerOptions = partnerOptions?.filter(
    option => option.partner_id === extra?.partner_id,
  );

  const onChangePartnerOption = (partnerOption: {
    label: string;
    value: string;
  }) => {
    const rulesSetter = setRules ?? setDocumentSigningRules;

    if (rulesSetter) {
      rulesSetter(
        produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];

          const idx = draftConditions.findIndex(
            cond => cond.id === condition.id,
          );

          draftConditions[idx].extra.partner_option = partnerOption.value;
        }),
      );
    }
  };

  const selectedPartnerOption =
    associatedPartnerOptions?.find(
      option => option.value === extra.partner_option,
    ) ?? null;

  const partnerOptionErrorMessage =
    !extra?.partner_option &&
    getConditionErrorMessage('partner_option', errors);

  return (
    <Grid item xs={6}>
      <StyledReactSelect
        options={associatedPartnerOptions}
        value={selectedPartnerOption}
        onChange={onChangePartnerOption}
        label={intl.formatMessage(messages.option)}
        aria-label={intl.formatMessage(messages.option)}
        isSearchable
        error={partnerOptionErrorMessage}
        placeholder=""
        required
      />
    </Grid>
  );
};
