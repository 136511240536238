import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import {
  resetApplicantUnreadMessages,
  setApplicantMessagingThreadAction,
} from 'containers/ApplicantTableV2/actions';
import { addDefaultErrorMessageAction } from 'containers/FlashMessage/actions';
import { apiPatch } from 'utils/axios';

export default function useMarkAsRead(applicantId) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const markAsRead = async () => {
    setIsLoading(true);
    const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/messages/mark_as_read`;

    try {
      const res = await apiPatch(url);
      if (res.data) {
        setApplicantMessagingThreadAction(
          applicantId,
          res.data.messaging_thread,
        );
        dispatch(resetApplicantUnreadMessages(applicantId));
      }
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error) {
      dispatch(addDefaultErrorMessageAction());
    }
  }, [dispatch, error]);

  return { markAsRead, isLoading };
}
