/**
 *
 * UserImpersonateChooser
 *
 */

import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SupervisorAccountRounded from '@material-ui/icons/SupervisorAccountRounded';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import {
  impersonateUserInitAction,
  setSelectedUserAction,
} from 'containers/Auth_old/actions';
import {
  makeSelectEmail,
  makeSelectIsImpersonating,
  makeSelectSuperAdminUserEmail,
  makeSelectUsers,
} from 'containers/Auth_old/selectors';

import styles from './styles';

class UserImpersonateChooser extends React.Component {
  button = null;

  state = {
    anchorEl: null,
    selectedIndex: 0,
  };

  handleClickListItem = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuItemClick = index => {
    this.setState({ selectedIndex: index, anchorEl: null });
    const { users, setSelectedUser, impersonateUser } = this.props;
    setSelectedUser(users[index]);
    impersonateUser(users[index]);
  };

  componentWillReceiveProps(newProps) {
    const { users, impersonateUser, currentUserEmail } = this.props;
    if (newProps.users !== users && newProps.users.length) {
      let selectedIndex = newProps.users.findIndex(
        user => user.email === currentUserEmail,
      );
      if (selectedIndex === -1) {
        selectedIndex = 0;
      }
      this.setState({ selectedIndex });
      if (
        newProps.users[selectedIndex] &&
        newProps.users[selectedIndex].email !== currentUserEmail
      ) {
        impersonateUser(newProps.users[selectedIndex]);
      }
    }
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, users } = this.props;
    const { anchorEl } = this.state;
    return (
      <div>
        <List component="nav" className={classes.nav}>
          <ListItem
            button
            aria-haspopup="true"
            aria-controls="lock-menu"
            aria-label="User"
            onClick={this.handleClickListItem}
            className={classes.listItem}
          >
            <ListItemIcon className={classes.icon}>
              <SupervisorAccountRounded />
            </ListItemIcon>
            <ListItemText
              className={classes.listItemText}
              primary={
                users.length ? (
                  <Grid>
                    <Typography className={classes.name} variant="h3">
                      {users[this.state.selectedIndex].name}
                      {users[this.state.selectedIndex].owner === true
                        ? ' (Owner)'
                        : ''}
                    </Typography>
                  </Grid>
                ) : null
              }
            />
            <ListItemIcon className={classes.icon}>
              <ExpandMoreIcon />
            </ListItemIcon>
          </ListItem>
        </List>
        <Menu
          id="lock-menu"
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          classes={{ paper: classes.menuRoot }}
        >
          <Autocomplete
            value={users[this.state.selectedIndex] ?? null}
            onChange={(event, newValue) => {
              const newIndex = users.findIndex(user => user.id === newValue.id);
              this.handleMenuItemClick(newIndex);
            }}
            options={users}
            getOptionLabel={option =>
              `${option.name}${option.owner ? ' (Owner)' : ''}`
            }
            renderInput={params => (
              <TextField {...params} label="Search users" variant="outlined" />
            )}
          />
        </Menu>
      </div>
    );
  }
}

UserImpersonateChooser.propTypes = {
  classes: PropTypes.object.isRequired,
  users: PropTypes.array,
  setSelectedUser: PropTypes.func.isRequired,
  impersonateUser: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  isImpersonating: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  superAdminEmail: PropTypes.string.isRequired,
  currentUserEmail: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  users: makeSelectUsers(),
  isImpersonating: makeSelectIsImpersonating(),
  currentUserEmail: makeSelectEmail(),
  superAdminEmail: makeSelectSuperAdminUserEmail(),
});

UserImpersonateChooser.defaultProps = {
  users: [],
};

function mapDispatchToProps(dispatch) {
  return {
    setSelectedUser: user => dispatch(setSelectedUserAction(user)),
    impersonateUser: user => dispatch(impersonateUserInitAction(user.id)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withRouter,
  withStyles(styles),
  withConnect,
)(UserImpersonateChooser);
