import { defineMessages } from 'react-intl';

export default defineMessages({
  location: {
    id: `app.containers.WorkflowEditor.components.DistributeApplicantsRuleStage.components.DistributeRule.location`,
    defaultMessage: 'Location',
  },
  locations: {
    id: `app.containers.WorkflowEditor.components.DistributeApplicantsRuleStage.components.DistributeRule.locations`,
    defaultMessage: 'Locations',
  },
  noResultsAvailable: {
    id: `app.containers.WorkflowEditor.components.DistributeApplicantsRuleStage.components.DistributeRule.noResultsAvailable`,
    defaultMessage: 'No {resultType} Available',
  },
  opening: {
    id: `app.containers.WorkflowEditor.components.DistributeApplicantsRuleStage.components.DistributeRule.opening`,
    defaultMessage: 'Opening',
  },
  openings: {
    id: `app.containers.WorkflowEditor.components.DistributeApplicantsRuleStage.components.DistributeRule.openings`,
    defaultMessage: 'Openings',
  },
  requiredFieldMessage: {
    id: `app.containers.WorkflowEditor.components.DistributeApplicantsRuleStage.components.DistributeRule.requiredFieldMessage`,
    defaultMessage: 'Required Field',
  },
  search: {
    id: `app.containers.WorkflowEditor.components.DistributeApplicantsRuleStage.components.DistributeRule.search`,
    defaultMessage: 'Search',
  },
  stage: {
    id: `app.containers.WorkflowEditor.components.DistributeApplicantsRuleStage.components.DistributeRule.stage`,
    defaultMessage: 'Stage',
  },
  stages: {
    id: `app.containers.WorkflowEditor.components.DistributeApplicantsRuleStage.components.DistributeRule.stages`,
    defaultMessage: 'stages',
  },
  startOfWorkflow: {
    id: `app.containers.WorkflowEditor.components.DistributeApplicantsRuleStage.components.DistributeRule.startOfWorkflow`,
    defaultMessage: 'Start of workflow',
  },
  targetDistribution: {
    id: `app.containers.WorkflowEditor.components.DistributeApplicantsRuleStage.components.DistributeRule.targetDistribution`,
    defaultMessage: '% Target Distribution',
  },
});
