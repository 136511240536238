import { Link, theme } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import {
  CancelablePromise,
  EventAvailableSlot,
  EventFormResult,
  EventsService,
} from 'api-clients/monolith';
import { useForm } from 'hooks';
import React, { useCallback, useEffect, useRef, useState, VFC } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import GlobalNav from 'components/GlobalNav';
import { makeSelectWhoami } from 'containers/Auth_old/selectors';
import { addMessageAction } from 'containers/FlashMessage/actions';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';
import LeftArrow from 'images/arrow-left.svg';
import InfoIcon from 'images/InfoIcon';
import globalMessages from 'shared/global/messages';

import { defaultEventData } from './constants';
import { EventDetails } from './EventDetails';
import { EventSettings } from './EventSettings';
import { Footer } from './Footer';
import { LocationDetails } from './LocationDetails/LocationDetails';
import { messages } from './messages';
import { StageSelector } from './StageSelector/StageSelector';
import { useStyles } from './styles';
import { CreateEventProps, UpdateModeType } from './types';
import { initZone } from './utils';

interface RouteParams {
  accountSlug: string;
}

export const CreateEvent: VFC<CreateEventProps> = ({ event, isUpdate }) => {
  const styles = useStyles();
  const { accountSlug } = useParams<RouteParams>();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const series = !!urlSearchParams.get('editSeries');
  const warning = !series && isUpdate && !!event.series_key;
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const updateModeRef = useRef<UpdateModeType>('single');

  const calendarPageUrl = `${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/calendar`;

  const { event_adaptive_enabled: adaptiveEvents, time_zone: zone } =
    useSelector(makeSelectWhoami());

  const isDateTodayOrFuture = (dateString: string) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const givenDate = new Date(dateString);
    givenDate.setHours(0, 0, 0, 0);

    return givenDate >= today;
  };

  const validate = (values: Partial<EventAvailableSlot>) => {
    const errors: Partial<Record<keyof EventAvailableSlot, string>> = {};

    if (values.stage_titles && values.stage_titles.length === 0) {
      errors.stage_titles = intl.formatMessage(messages.requiredField);
    }
    if (values.opening_titles && values.opening_titles.length === 0) {
      errors.opening_titles = intl.formatMessage(messages.requiredField);
    }
    if (
      values.location_group_titles &&
      values.location_group_titles.length === 0
    ) {
      errors.location_group_titles = intl.formatMessage(messages.requiredField);
    }
    if (!values.max_attendees) {
      errors.max_attendees = intl.formatMessage(messages.requiredField);
    }
    if (!values.user_external_id) {
      errors.user_external_id = intl.formatMessage(messages.requiredField);
    }
    if (!values.start_time) {
      errors.start_time = intl.formatMessage(messages.requiredField);
    }
    if (values.start_time && !isDateTodayOrFuture(values.start_time)) {
      errors.start_time = intl.formatMessage(messages.timeErrorMessage);
    }
    if (!values.end_time) {
      errors.end_time = intl.formatMessage(messages.requiredField);
    }
    if (values.end_time && !isDateTodayOrFuture(values.end_time)) {
      errors.end_time = intl.formatMessage(messages.timeErrorMessage);
    }
    if (values.period && !values.frequency) {
      errors.frequency = intl.formatMessage(messages.requiredField);
    }

    return errors;
  };

  const redirectToCalendar = () => {
    window.location.href = calendarPageUrl;
  };
  const { mutation: createEvent } = useApiServiceMutation<
    EventFormResult,
    ({
      // eslint-disable-next-line camelcase
      user_external_id,
      event,
    }: {
      // eslint-disable-next-line camelcase
      user_external_id: string;
      event: EventAvailableSlot;
    }) => CancelablePromise<EventFormResult>
    // eslint-disable-next-line @typescript-eslint/unbound-method
  >(EventsService.postInternalApiEvents, {
    onSuccess: result => {
      setIsLoading(false);
      dispatch(
        addMessageAction(
          intl.formatMessage(messages[result.key], {
            count: result.count,
            conflict: result.conflict,
          }),
          'success',
        ),
      );
      redirectToCalendar();
    },
    onError: () => {
      setIsSaveButtonEnabled(false);
      setIsLoading(false);
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.eventCreateFailed),
          'error',
        ),
      );
    },
  });

  const { mutation: updateEvent } = useApiServiceMutation<
    EventFormResult,
    (
      externalId: string,
      {
        // eslint-disable-next-line camelcase
        user_external_id,
        // eslint-disable-next-line camelcase
        update_mode,
        event,
      }: {
        // eslint-disable-next-line camelcase
        user_external_id: string;
        // eslint-disable-next-line camelcase
        update_mode: 'single' | 'unbooked' | 'all' | undefined;
        event: EventAvailableSlot;
      },
    ) => CancelablePromise<EventFormResult>
    // eslint-disable-next-line @typescript-eslint/unbound-method
  >(EventsService.putInternalApiEvents, {
    onSuccess: result => {
      setIsLoading(false);
      dispatch(
        addMessageAction(
          intl.formatMessage(messages[result.key], {
            count: result.count,
            conflict: result.conflict,
          }),
          'success',
        ),
      );
      redirectToCalendar();
    },
    onError: () => {
      setIsSaveButtonEnabled(false);
      setIsLoading(false);
      const message = series
        ? messages.seriesEditedFailed
        : messages.sessionEditedFailed;
      dispatch(addMessageAction(intl.formatMessage(message), 'error'));
    },
  });

  const onSubmit = (values: EventAvailableSlot) => {
    setIsSaveButtonEnabled(false);
    setIsLoading(true);

    if (isUpdate && values.external_id) {
      updateEvent(values.external_id, {
        user_external_id: values.user ?? '',
        update_mode: updateModeRef.current,
        event: values,
      });
    } else {
      createEvent({
        user_external_id: values.user ?? '',
        event: values,
      });
    }
  };

  const { handleChange, values, errors, runValidations } =
    useForm<EventAvailableSlot>(onSubmit, validate, event ?? defaultEventData);

  const onChange = useCallback((params: Partial<EventAvailableSlot>) => {
    setIsSaveButtonEnabled(true);
    handleChange(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    redirectToCalendar();
  };

  const handleFooterSubmit = (mode: UpdateModeType) => {
    updateModeRef.current = mode;
    onSubmit(values);
  };

  const title = isUpdate
    ? series
      ? messages.updateSeries
      : messages.updateEvent
    : messages.createEvent;
  const formattedTitle = intl.formatMessage(title);

  useEffect(() => {
    initZone(zone);
  }, [zone]);

  return (
    <>
      <Helmet>
        <title>{formattedTitle}</title>
        <meta name="description" content={formattedTitle} />
      </Helmet>

      <Grid container direction="column" className={styles.createEventWrapper}>
        <GlobalNav />

        <Grid
          container
          direction="column"
          className={styles.createEventContainer}
        >
          <Grid item className={styles.backToSettings}>
            <Link color="secondary" href={calendarPageUrl}>
              <img
                src={LeftArrow}
                alt="back arrow"
                className={styles.leftArrow}
              />
              <FormattedMessage {...globalMessages.back} />
            </Link>
          </Grid>

          <Grid
            item
            container
            direction="column"
            className={styles.createEventContent}
          >
            <Typography display="inline" variant="h2">
              {formattedTitle}
            </Typography>
            {warning && (
              <>
                <Grid className={styles.warningContent}>
                  <InfoIcon
                    color={theme.palette.common.blue400}
                    className={styles.warningIcon}
                  />
                  <Typography variant="h5" className={styles.warningText}>
                    <FormattedMessage {...messages.eject} />
                  </Typography>
                </Grid>
                <Grid className={styles.warningSpacing}>&nbsp;</Grid>
              </>
            )}
            <form>
              <EventDetails
                handleChange={onChange}
                availableSlot={values}
                adaptiveEvents={adaptiveEvents}
              />
              <EventSettings
                handleChange={onChange}
                availableSlot={values}
                errors={errors}
                series={series}
              />

              <StageSelector
                handleChange={onChange}
                errors={errors}
                availableSlot={values}
              />
              <LocationDetails handleChange={onChange} availableSlot={values} />
              <Footer
                isUpdatingEvent={isLoading}
                isSaveButtonEnabled={isSaveButtonEnabled}
                resetForm={handleCancel}
                redirectToCalendar={redirectToCalendar}
                availableSlot={values}
                onSubmit={mode => handleFooterSubmit(mode)}
                runValidations={runValidations}
              ></Footer>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
