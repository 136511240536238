import 'react-quill/dist/quill.snow.css';

import { TextButton } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import React, { FC, useContext, useState } from 'react';
import { useIntl } from 'react-intl';

import { InputEditor } from 'components/InputEditor';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';
import useSimpleToggle from 'hooks/useSimpleToggle';

import { CustomizeStageModal } from '../CustomizeStageModal';
import { messages } from './messages';
import useStyles from './styles';

export interface CustomizationBarProps {
  funnelExternalId: string;
  funnelSlug: string;
}

export const CustomizationBar: FC<CustomizationBarProps> = ({
  funnelExternalId,
  funnelSlug,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const { stage, setStage } = useContext(StageContext);

  const {
    portal_title: portalTitle,
    portal_instructions: portalInstructions,
    extra,
  } = stage;

  const [isEditingPortalTitle, setIsEditingPortalTitle] = useState(false);

  const {
    showContent: isCustomizeStageModalOpen,
    on: openCustomizeStageModal,
    off: closeCustomizeStageModal,
  } = useSimpleToggle();

  const onClickPortalTitleEdit = () => {
    setIsEditingPortalTitle(true);
  };

  const onCancelPortalTitleEdit = () => {
    setIsEditingPortalTitle(false);
  };

  const onConfirmPortalTitleEdit = (value: string) => {
    setIsEditingPortalTitle(false);

    if (value !== portalTitle) {
      setStage(currentStage => ({
        ...currentStage,
        portal_title: value,
      }));
    }
  };

  const isCustomPortalInfoSet =
    Boolean(portalTitle) ||
    Boolean(portalInstructions) ||
    Boolean(extra?.success_message) ||
    Boolean(extra?.portal_javascript) ||
    Boolean(extra?.success_message_javascript);

  return (
    <>
      <Grid
        container
        wrap="nowrap"
        justify="space-between"
        alignItems="center"
        className={classes.customizationBar}
        data-testid="customizationBar"
      >
        {!portalTitle && !isEditingPortalTitle ? (
          <Grid container item className={classes.setPortalTitle}>
            <TextButton color="primary" onClick={onClickPortalTitleEdit}>
              <Typography variant="h4" color="primary">
                {intl.formatMessage(messages.setApplicantFacingName)}
              </Typography>
            </TextButton>
          </Grid>
        ) : (
          <InputEditor
            value={portalTitle ?? ''}
            onConfirm={onConfirmPortalTitleEdit}
            onCancel={onCancelPortalTitleEdit}
            isEditing={isEditingPortalTitle}
            size="small"
          />
        )}

        <Grid container item justify="flex-end" alignItems="center">
          {isCustomPortalInfoSet ? (
            <TextButton color="primary" onClick={openCustomizeStageModal}>
              <Typography display="inline" variant="h4">
                {intl.formatMessage(messages.editApplicantPortalInstructions)}
              </Typography>
            </TextButton>
          ) : (
            <>
              <Typography display="inline" variant="h4">
                {intl.formatMessage(messages.instructionsNotSet)}
              </Typography>
              &nbsp;
              <TextButton color="primary" onClick={openCustomizeStageModal}>
                <Typography display="inline" variant="h4">
                  {intl.formatMessage(messages.customize)}
                </Typography>
              </TextButton>
            </>
          )}
        </Grid>
      </Grid>

      {isCustomizeStageModalOpen && (
        <CustomizeStageModal
          isOpen={isCustomizeStageModalOpen}
          onClose={closeCustomizeStageModal}
          funnelExternalId={funnelExternalId}
          funnelSlug={funnelSlug}
        />
      )}
    </>
  );
};
