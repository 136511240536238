import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  setApplicantFacingName: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizationBar.setApplicantFacingName`,
    defaultMessage: 'Set applicant-facing name',
  },
  instructionsNotSet: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizationBar.instructionsNotSet`,
    defaultMessage: 'Applicant portal instructions not set.',
  },
  customize: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizationBar.customize`,
    defaultMessage: 'Customize',
  },
  editApplicantPortalInstructions: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizationBar.editApplicantPortalInstructions`,
    defaultMessage: 'Edit applicant portal instructions',
  },
});
