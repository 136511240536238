/**
 * @typedef {object} FindChunksOptions
 * @property {boolean} autoEscape
 * @property {boolean} caseSensitive
 * @property {function} sanitize
 * @property {array} searchWords
 * @property {string} textToHighlight
 */

/**
 * Find matches only at beginning of words.
 *
 * @param {boolean} isNameHighlighter default value is `false`
 *
 * @returns {function(FindChunksOptions): array}
 */
const findChunksAtBeginningOfWords =
  (isNameHighlighter = false) =>
  /**
   * Find matches only at beginning of words.
   *
   * @param {FindChunksOptions} options
   *
   * @returns {array} chunks
   */
  ({ searchWords, textToHighlight }) => {
    const chunks = [];

    if (!textToHighlight || typeof textToHighlight !== 'string') {
      return chunks;
    }

    const filteredSearchWords = searchWords.filter(
      word => word && typeof word === 'string',
    );

    const textToHighlightLowerCase = textToHighlight.toLowerCase();
    const seperatorExp = isNameHighlighter ? /[-\s|.]+/ : /[-\s]+/;
    const singleTextWords = textToHighlightLowerCase.split(seperatorExp);

    // store the index of each single word with textToHighlight
    let fromIndex = 0;
    const singleTextWordsWithPosition = singleTextWords.map(word => {
      const indexInWord = textToHighlightLowerCase.indexOf(word, fromIndex);
      const firstMatchWordLength =
        textToHighlightLowerCase.split(' ')[0].length;
      if (
        textToHighlightLowerCase.indexOf(word, firstMatchWordLength) !==
        textToHighlightLowerCase.lastIndexOf(word, firstMatchWordLength - 1)
      ) {
        fromIndex = indexInWord + word.length;
      } else {
        fromIndex = indexInWord;
      }
      return {
        word,
        index: indexInWord,
      };
    });

    // Add chunks for every searchWord
    filteredSearchWords.forEach(searchWord => {
      const searchWordLowerCase = searchWord.toLowerCase();
      singleTextWordsWithPosition.forEach(singleText => {
        if (singleText.word.startsWith(searchWordLowerCase)) {
          const start = singleText.index;
          const end = singleText.index + searchWordLowerCase.length;
          chunks.push({
            start,
            end,
          });
        }
      });

      // The complete word including whitespace should also be handled
      if (textToHighlightLowerCase.startsWith(searchWordLowerCase)) {
        const start = 0;
        const end = searchWordLowerCase.length;
        chunks.push({
          start,
          end,
        });
      }
    });
    const dataArray = chunks.map(item => [JSON.stringify(item), item]);
    const mapArray = new Map(dataArray);

    const finalChunks = [...mapArray.values()];
    return finalChunks;
  };

export default findChunksAtBeginningOfWords;
