import {
  ApplicantActionsActionItemKey,
  ApplicantActionsGroupItemKey,
} from '@fountain/types/actionMenu';

import DisabledCancelRescheduleCalendar from './images/disabled/cancel-reschedule-calendar.svg';
import DisabledDeleteApplicant from './images/disabled/delete-applicant.svg';
import DisabledDirectlySchedule from './images/disabled/directly-schedule.svg';
import DisabledEditApplicant from './images/disabled/edit-applicant.svg';
import DisabledEnroll from './images/disabled/enroll.svg';
import DisabledLetter from './images/disabled/letter.svg';
import DisabledMoveToNext from './images/disabled/move-to-next-stage.svg';
import DisabledMoveToOpening from './images/disabled/move-to-opening.svg';
import DisabledMoveToStage from './images/disabled/move-to-stage.svg';
import DisabledPaperReport from './images/disabled/paper-report.svg';
import DisabledPenAndPaper from './images/disabled/pen-and-paper.svg';
import DisabledPrintData from './images/disabled/print-data.svg';
import DisabledReject from './images/disabled/reject.svg';
import DisabledReminder from './images/disabled/reminder.svg';
import DisabledScoreApplicant from './images/disabled/score-applicant.svg';
import DisabledSms from './images/disabled/send-sms.svg';
import DisabledUnsubsribe from './images/disabled/unsubscribe.svg';
import DisabledUploadFile from './images/disabled/upload-file.svg';
import DisabledVoid from './images/disabled/void.svg';
import EnabledCancelRescheduleCalendar from './images/enabled/cancel-reschedule-calendar.svg';
import EnabledDeleteApplicant from './images/enabled/delete-applicant.svg';
import EnabledDirectlySchedule from './images/enabled/directly-schedule.svg';
import EnabledEditApplicant from './images/enabled/edit-applicant.svg';
import EnabledEnroll from './images/enabled/enroll.svg';
import EnabledLetter from './images/enabled/letter.svg';
import EnabledMoveToNext from './images/enabled/move-to-next-stage.svg';
import EnabledMoveToOpening from './images/enabled/move-to-opening.svg';
import EnabledMoveToStage from './images/enabled/move-to-stage.svg';
import EnabledPaperReport from './images/enabled/paper-report.svg';
import EnabledPenAndPaper from './images/enabled/pen-and-paper.svg';
import EnabledPrintData from './images/enabled/print-data.svg';
import EnabledReject from './images/enabled/reject.svg';
import EnabledReminder from './images/enabled/reminder.svg';
import EnabledScoreApplicant from './images/enabled/score-applicant.svg';
import EnabledSms from './images/enabled/send-sms.svg';
import EnabledUnsubsribe from './images/enabled/unsubscribe.svg';
import EnabledUploadFile from './images/enabled/upload-file.svg';
import EnabledVoid from './images/enabled/void.svg';

type IconMapping = Record<
  ApplicantActionsActionItemKey | ApplicantActionsGroupItemKey,
  string | undefined
>;

/**
 * These two sets of mappings for enabled and disabled
 * are a temporary solution until we use new svgs that
 * can be dynamically filled.
 */

export const ENABLED_ICON_MAPPINGS: IconMapping = {
  'data_collection.upload': EnabledUploadFile,
  'manage.delete': EnabledDeleteApplicant,
  'manage.edit': EnabledEditApplicant,
  'messaging.email': EnabledLetter,
  'messaging.sms': EnabledSms,
  'messaging.reactivate_sms': EnabledSms,
  'worker.worker_profile': EnabledScoreApplicant,
  'messaging.unsubscribe': EnabledUnsubsribe,
  'moving.next_stage': EnabledMoveToNext,
  'moving.stages': EnabledMoveToStage,
  'moving.reject': EnabledReject,
  'print.data': EnabledPrintData,
  'print.score_cards': EnabledPrintData,
  'score_cards.score': EnabledScoreApplicant,
  'score_cards.separate_score': EnabledScoreApplicant,
  'checkr_canada.run_report': EnabledPaperReport,
  'new_checkr_canada.run_report': EnabledPaperReport,
  'checkr.run_remaining_report': EnabledPaperReport,
  'checkr.run_report': EnabledPaperReport,
  'checkr.send_consent_message': EnabledLetter,
  'data_collection.send_message': EnabledLetter,
  'doc_sign.cancel': EnabledPenAndPaper,
  'doc_sign.remind': EnabledReminder,
  'messaging.reactivate_email': EnabledLetter,
  'messaging.send_automated_message': EnabledLetter,
  'moving.move_to_opening': EnabledMoveToOpening,
  'onfido.resume': EnabledPaperReport,
  'onfido.send_message': EnabledLetter,
  'onfido.void': EnabledVoid,
  'scheduling.cancel': EnabledCancelRescheduleCalendar,
  'scheduling.schedule': EnabledDirectlySchedule,
  'scheduling.send_message': EnabledLetter,
  'schoolkeep.enroll_to_course': EnabledEnroll,
  'moving.stages.stage': '',
  'print.data.all': '',
  'print.data.form': '',
  'print.data.stage': '',
  'print.score_cards.all': '',
  'print.score_cards.single': '',
  checkr: '',
  checkr_canada: '',
  data_collection: '',
  doc_sign: '',
  manage: '',
  messaging: '',
  moving: '',
  new_checkr_canada: '',
  onfido: '',
  print: '',
  score_cards: '',
  schoolkeep: '',
  scheduling: '',
  worker: '',
};

export const DISABLED_ICON_MAPPINGS: IconMapping = {
  'data_collection.upload': DisabledUploadFile,
  'manage.delete': DisabledDeleteApplicant,
  'manage.edit': DisabledEditApplicant,
  'messaging.email': DisabledLetter,
  'messaging.sms': DisabledSms,
  'messaging.reactivate_sms': DisabledSms,
  'worker.worker_profile': DisabledScoreApplicant,
  'messaging.unsubscribe': DisabledUnsubsribe,
  'moving.next_stage': DisabledMoveToNext,
  'moving.stages': DisabledMoveToStage,
  'moving.reject': DisabledReject,
  'print.data': DisabledPrintData,
  'print.score_cards': DisabledPrintData,
  'score_cards.score': DisabledScoreApplicant,
  'score_cards.separate_score': DisabledScoreApplicant,
  'checkr_canada.run_report': DisabledPaperReport,
  'new_checkr_canada.run_report': DisabledPaperReport,
  'checkr.run_remaining_report': DisabledPaperReport,
  'checkr.run_report': DisabledPaperReport,
  'checkr.send_consent_message': DisabledLetter,
  'data_collection.send_message': DisabledLetter,
  'doc_sign.cancel': DisabledPenAndPaper,
  'doc_sign.remind': DisabledReminder,
  'messaging.reactivate_email': DisabledLetter,
  'messaging.send_automated_message': DisabledLetter,
  'moving.move_to_opening': DisabledMoveToOpening,
  'onfido.resume': DisabledPaperReport,
  'onfido.send_message': DisabledLetter,
  'onfido.void': DisabledVoid,
  'scheduling.cancel': DisabledCancelRescheduleCalendar,
  'scheduling.schedule': DisabledDirectlySchedule,
  'scheduling.send_message': DisabledLetter,
  'schoolkeep.enroll_to_course': DisabledEnroll,
  'moving.stages.stage': '',
  'print.data.all': '',
  'print.data.form': '',
  'print.data.stage': '',
  'print.score_cards.all': '',
  'print.score_cards.single': '',
  checkr: '',
  checkr_canada: '',
  data_collection: '',
  doc_sign: '',
  manage: '',
  messaging: '',
  moving: '',
  new_checkr_canada: '',
  onfido: '',
  print: '',
  score_cards: '',
  schoolkeep: '',
  scheduling: '',
  worker: '',
};

export const EXCLUDED_ITEM_KEYS = new Set(['score_cards.separate_score']);

export const RESUBSCRIBE_EMAIL_START =
  'app/ApplicantActions/RESUBSCRIBE_EMAIL_START';
export const RESUBSCRIBE_EMAIL_SUCCESS =
  'app/ApplicantActions/RESUBSCRIBE_EMAIL_SUCCESS';
export const RESUBSCRIBE_EMAIL_ERROR =
  'app/ApplicantActions/RESUBSCRIBE_EMAIL_ERROR';

export const RESUBSCRIBE_SMS_START =
  'app/ApplicantActions/RESUBSCRIBE_SMS_START';
export const RESUBSCRIBE_SMS_SUCCESS =
  'app/ApplicantActions/RESUBSCRIBE_SMS_SUCCESS';
export const RESUBSCRIBE_SMS_ERROR =
  'app/ApplicantActions/RESUBSCRIBE_SMS_ERROR';
export const CHECKR_SEND_CONSENT_MESSAGE_INIT =
  'app/ApplicantActions/CHECKR_SEND_CONSENT_MESSAGE_INIT';
export const CHECKR_SEND_CONSENT_MESSAGE_SUCCESS =
  'app/ApplicantActions/CHECKR_SEND_CONSENT_MESSAGE_SUCCESS';
export const CHECKR_SEND_CONSENT_MESSAGE_ERROR =
  'app/ApplicantActions/CHECKR_SEND_CONSENT_MESSAGE_ERROR';
export const CHECKR_RUN_REPORT_INIT =
  'app/ApplicantActions/CHECKR_RUN_REPORT_INIT';
export const CHECKR_RUN_REPORT_SUCCESS =
  'app/ApplicantActions/CHECKR_RUN_REPORT_SUCCESS';
export const CHECKR_RUN_REPORT_ERROR =
  'app/ApplicantActions/CHECKR_RUN_REPORT_ERROR';
export const CHECKR_RUN_REMAINING_REPORT_INIT =
  'app/ApplicantActions/CHECKR_RUN_REMAINING_REPORT_INIT';
export const CHECKR_RUN_REMAINING_REPORT_SUCCESS =
  'app/ApplicantActions/CHECKR_RUN_REMAINING_REPORT_SUCCESS';
export const CHECKR_RUN_REMAINING_REPORT_ERROR =
  'app/ApplicantActions/CHECKR_RUN_REMAINING_REPORT_ERROR';
export const CANCEL_AND_RESEND_HELLOSIGN_REQUEST_INIT =
  'app/ApplicantActions/CANCEL_AND_RESEND_HELLOSIGN_REQUEST_INIT';
export const CANCEL_AND_RESEND_HELLOSIGN_REQUEST_SUCCESS =
  'app/ApplicantActions/CANCEL_AND_RESEND_HELLOSIGN_REQUEST_SUCCESS';
export const CANCEL_AND_RESEND_HELLOSIGN_REQUEST_ERROR =
  'app/ApplicantActions/CANCEL_AND_RESEND_HELLOSIGN_REQUEST_ERROR';
export const RESEND_HELLOSIGN_EMAIL_INIT =
  'app/ApplicantActions/RESEND_HELLOSIGN_EMAIL_INIT';
export const RESEND_HELLOSIGN_EMAIL_SUCCESS =
  'app/ApplicantActions/RESEND_HELLOSIGN_EMAIL_SUCCESS';
export const RESEND_HELLOSIGN_EMAIL_ERROR =
  'app/ApplicantActions/RESEND_HELLOSIGN_EMAIL_ERROR';
export const SCHOOLKEEP_ENROLL_INIT =
  'app/ApplicantActions/SCHOOLKEEP_ENROLL_INIT';
export const SCHOOLKEEP_ENROLL_SUCCESS =
  'app/ApplicantActions/SCHOOLKEEP_ENROLL_SUCCESS';
export const SCHOOLKEEP_ENROLL_ERROR =
  'app/ApplicantActions/SCHOOLKEEP_ENROLL_ERROR';
export const SCHEDULING_CANCEL_INIT =
  'app/ApplicantActions/SCHEDULING_CANCEL_INIT';
export const SCHEDULING_CANCEL_SUCCESS =
  'app/ApplicantActions/SCHEDULING_CANCEL_SUCCESS';
export const SCHEDULING_CANCEL_ERROR =
  'app/ApplicantActions/SCHEDULING_CANCEL_ERROR';
export const SCHEDULING_RESEND_SCHEDULER_EMAIL_INIT =
  'app/ApplicantActions/SCHEDULING_RESEND_SCHEDULER_EMAIL_INIT';
export const SCHEDULING_RESEND_SCHEDULER_EMAIL_SUCCESS =
  'app/ApplicantActions/SCHEDULING_RESEND_SCHEDULER_EMAIL_SUCCESS';
export const SCHEDULING_RESEND_SCHEDULER_EMAIL_ERROR =
  'app/ApplicantActions/SCHEDULING_RESEND_SCHEDULER_EMAIL_ERROR';
export const CHECKR_CANADA_RUN_REPORT_INIT =
  'app/ApplicantActions/CHECKR_CANADA_RUN_REPORT_INIT';
export const CHECKR_CANADA_RUN_REPORT_SUCCESS =
  'app/ApplicantActions/CHECKR_CANADA_RUN_REPORT_SUCCESS';
export const CHECKR_CANADA_RUN_REPORT_ERROR =
  'app/ApplicantActions/CHECKR_CANADA_RUN_REPORT_ERROR';
export const ONFIDO_RESUME_INIT = 'app/ApplicantActions/ONFIDO_RESUME_INIT';
export const ONFIDO_RESUME_SUCCESS =
  'app/ApplicantActions/ONFIDO_RESUME_SUCCESS';
export const ONFIDO_RESUME_ERROR = 'app/ApplicantActions/ONFIDO_RESUME_ERROR';
export const ONFIDO_SEND_MESSAGE_INIT =
  'app/ApplicantActions/ONFIDO_SEND_MESSAGE_INIT';
export const ONFIDO_SEND_MESSAGE_SUCCESS =
  'app/ApplicantActions/ONFIDO_SEND_MESSAGE_SUCCESS';
export const ONFIDO_SEND_MESSAGE_ERROR =
  'app/ApplicantActions/ONFIDO_SEND_MESSAGE_ERROR';
export const ONFIDO_VOID_INIT = 'app/ApplicantActions/ONFIDO_VOID_INIT';
export const ONFIDO_VOID_SUCCESS = 'app/ApplicantActions/ONFIDO_VOID_SUCCESS';
export const ONFIDO_VOID_ERROR = 'app/ApplicantActions/ONFIDO_VOID_ERROR';
export const SEND_AUTOMATED_MESSAGE =
  'app/ApplicantActions/SEND_AUTOMATED_MESSAGE';
export const SEND_AUTOMATED_MESSAGE_SUCCESS =
  'app/ApplicantActions/SEND_AUTOMATED_MESSAGE_SUCCESS';
export const SEND_AUTOMATED_MESSAGE_ERROR =
  'app/ApplicantActions/SEND_AUTOMATED_MESSAGE_ERROR';
export const WX_EXPORT_APPLICANT_INIT =
  'app/ApplicantActions/WX_EXPORT_APPLICANT_INIT';
export const WX_EXPORT_APPLICANT_SUCCESS =
  'app/ApplicantActions/WX_EXPORT_APPLICANT_SUCCESS';
export const WX_EXPORT_APPLICANT_ERROR =
  'app/ApplicantActions/WX_EXPORT_APPLICANT_ERROR';
