import { Dropdown } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import produce from 'immer';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import {
  MOVE_TO_NEXT_STAGE,
  MOVE_TO_STAGE,
} from 'containers/WorkflowEditor/components/Rules/Action/constants';
import { MoveToStage } from 'containers/WorkflowEditor/components/Rules/Action/MoveToStage';
import { RowTag } from 'containers/WorkflowEditor/components/Rules/RowTag';

import { PartnerRuleProps, SharedPartnerActionProps } from '../types';
import { messages } from './messages';
import { useStyles } from './styles';

export const PartnerAction: FC<SharedPartnerActionProps> = ({
  action,
  setPartnerRules,
  ruleId,
  errors,
}) => {
  const intl = useIntl();
  const styles = useStyles();

  const onSelectActionType = (type: string) => {
    setPartnerRules(
      produce((draftRules: PartnerRuleProps) => {
        const draftAction = draftRules[ruleId]?.action_attributes ?? [];

        draftAction.id = action.id;
        draftAction.type = type;
        draftAction.extra = {};
        draftAction.ref_stage_1_id = null;
      }),
    );
  };

  const actionTypeOptions = () => {
    return [
      {
        label: intl.formatMessage(messages.moveApplicantToNextStage),
        value: MOVE_TO_NEXT_STAGE,
      },
      {
        label: intl.formatMessage(messages.moveApplicantToStage),
        value: MOVE_TO_STAGE,
      },
    ];
  };

  const sharedActionProps = {
    setPartnerRules,
    action,
    ruleId,
    errors,
  };

  return (
    <Grid container className={styles.actionRow}>
      <Grid
        container
        justify="space-between"
        wrap="nowrap"
        alignItems="center"
        className={styles.tagContainer}
      >
        <RowTag
          classes={{ tag: styles.rowTag }}
          label={intl.formatMessage(messages.action)}
        />
      </Grid>
      <Grid item container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <Dropdown
            options={actionTypeOptions()}
            value={action.type}
            onChange={onSelectActionType}
            label={intl.formatMessage(messages.actionType)}
            aria-label={intl.formatMessage(messages.actionType)}
            isSearchable
            required
          />
        </Grid>
        {action.type &&
          {
            [MOVE_TO_STAGE]: <MoveToStage {...sharedActionProps} />,
            [MOVE_TO_NEXT_STAGE]: null,
          }[action.type]}
      </Grid>
    </Grid>
  );
};
