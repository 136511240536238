import {
  Button,
  ConfirmationModal,
  Loader,
  Modal as Dialog,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@fountain/fountain-ui-components';
import { Grid, Theme, Typography, useMediaQuery } from '@material-ui/core';
import mergeWith from 'lodash/mergeWith';
import React, { FC, memo, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import {
  DATA,
  SECURE_DATA,
  STRUCTURED_DATA,
} from 'components/ApplicantDrawerDetailsList/constants';
import { makeSelectSingleApplicant } from 'containers/ApplicantTableV2/selectors';
import {
  makeSelectAccountSlug,
  makeSelectIsRegularUser,
} from 'containers/Auth_old/selectors';
import { DataValidationField } from 'containers/DataValidationModal/components';
import {
  useDataFields,
  useGetQueryStringValues,
} from 'containers/DataValidationModal/hooks';
import {
  FilePayload,
  Payload,
  ValidationErrors,
} from 'containers/DataValidationModal/types';
import { addMessageAction } from 'containers/FlashMessage/actions';
import {
  openMoveApplicantPopup,
  TransitionType,
} from 'containers/GlobalPopup/actions';
import useApplicantBlobs from 'hooks/useApplicantBlobs';
import useApplicantUpdateDetail from 'hooks/useApplicantUpdateDetail';
import { useReducer } from 'hooks/useReducer';
import useUploadFileToApplicant from 'hooks/useUploadFileToApplicant';
import Checkmark from 'images/checkmark-blueV2.svg';
import ExportIcon from 'images/ExportIcon';
import { removeParamsFromUrl } from 'utils/urlUtils';

import { DATA_VALIDATION_FIELD_ARIA_LABEL } from './constants';
import messages from './messages';
import useStyles from './styles';
import { validatePayload } from './validate';

interface ApplicantFile {
  applicantId: string | null;
  key: string;
  file: unknown;
}

export interface DataValidationModalProps {
  handleClose: () => void;
}

export const DataValidationModal: FC<DataValidationModalProps> = memo(
  ({ handleClose }) => {
    useReducer('dataValidation')();

    const dispatch = useDispatch();
    const styles = useStyles();
    const intl = useIntl();
    const isMobile = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down('sm'),
    );

    const { blobs } = useSelector(makeSelectSingleApplicant());
    const accountSlug = useSelector(makeSelectAccountSlug());
    const isRegularUser = useSelector(makeSelectIsRegularUser());

    const [payload, setPayload] = useState<Payload>({} as Payload);
    const [errors, setErrors] = useState<ValidationErrors>({});
    const [filePayload, setFilePayload] = useState<FilePayload>(
      {} as FilePayload,
    );
    const [isConfirming, setIsConfirming] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [
      isUnsavedChangesConfirmationModalOpen,
      setIsUnsavedChangesConfirmationModalOpen,
    ] = useState(false);

    const {
      validationGroupId,
      applicantId,
      toStageId,
      warnW4Federal,
      warnWotc,
      transitionType,
      selectedJobId,
      selectedRunAutomatedActions,
    } = useGetQueryStringValues();

    const { dataFields: applicantDataFields, isLoading: isLoadingDataFields } =
      useDataFields({ applicantId, validationGroupId });

    const { updateApplicantDetail: updateApplicantDetailData } =
      useApplicantUpdateDetail(applicantId, DATA, true, {
        disableMessage: true,
      });

    const { updateApplicantDetail: updateApplicantDetailStructuredData } =
      useApplicantUpdateDetail(applicantId, STRUCTURED_DATA, true, {
        disableMessage: true,
      });

    const { updateApplicantDetail: updateApplicantDetailSecureData } =
      useApplicantUpdateDetail(applicantId, SECURE_DATA, true, {
        disableMessage: true,
      });

    const { updateFileToApplicant } = useUploadFileToApplicant({
      disableMessage: true,
    });

    const { fetchApplicantBlobs } = useApplicantBlobs(applicantId);

    const handleBeforeunload = (event: BeforeUnloadEvent) => {
      if (!isDirty) {
        return false;
      }

      const msg = intl.formatMessage(messages.unsavedChangesMessage);
      // eslint-disable-next-line no-param-reassign
      event.returnValue = msg;
      return msg;
    };

    useEffect(() => {
      if (isDirty) {
        window.addEventListener('beforeunload', handleBeforeunload);
      }

      return () => {
        if (isDirty) {
          window.removeEventListener('beforeunload', handleBeforeunload);
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDirty]);

    useEffect(() => {
      void fetchApplicantBlobs();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // We are calling updateFileToApplicant serially without a Promise.all
    // because if we execute updateFileToApplicant parallely it leads to a duplicate record creation and
    // will cause an error(UniqueViolation) in PROD env due to parallel execution of upload request
    async function uploadAllFiles(filesToBeUploaded: ApplicantFile[]) {
      const promises = [];
      for (let index = 0; index < filesToBeUploaded.length; index += 1) {
        // eslint-disable-next-line no-await-in-loop
        const response = await updateFileToApplicant(filesToBeUploaded[index]);
        promises.push(response);
      }
      return promises;
    }

    const onConfirmClicked = async () => {
      const saveFilePayload = { ...filePayload };
      delete saveFilePayload.dataType;

      const validationErrors = validatePayload({
        applicantDataFields,
        blobs,
        filePayload,
        payload,
      });

      setIsConfirming(true);
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length > 0) {
        setIsConfirming(false);
        return;
      }

      const filesToBeUploaded = Object.values(saveFilePayload).map(
        (file, index) => ({
          applicantId,
          key: Object.keys(saveFilePayload)[index],
          file,
        }),
      );

      await uploadAllFiles(filesToBeUploaded);

      if (payload?.data)
        await updateApplicantDetailData({ data: payload.data });
      if (payload?.structured_data) {
        await updateApplicantDetailStructuredData({
          structured_data: payload.structured_data,
        });
      }
      if (payload?.secure_data)
        await updateApplicantDetailSecureData({
          secure_data: payload.secure_data,
        });

      setIsConfirming(false);
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.updateApplicantDataSuccess),
          'success',
        ),
      );
      dispatch(
        openMoveApplicantPopup({
          applicantId,
          toStageId,
          transitionType: transitionType as TransitionType,
          warnW4Federal,
          warnWotc,
          isValidationSaved: true,
        }),
      );
    };

    const savePayload = (paramPayload: FilePayload | Partial<Payload>) => {
      if ('dataType' in paramPayload) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const newFilePayload = mergeWith(filePayload, paramPayload);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setFilePayload(newFilePayload);
      } else {
        const newPayload = mergeWith(payload, paramPayload, (_a, b) =>
          Array.isArray(b) ? b : undefined,
        );
        setPayload(newPayload);
      }
    };

    const handleCloseHandler = () => {
      removeParamsFromUrl({ selectedJobId, selectedRunAutomatedActions });
      handleClose();
    };
    const onDiscardChanges = () => {
      setIsUnsavedChangesConfirmationModalOpen(false);
      handleCloseHandler();
    };

    const onKeepEditing = () => {
      setIsUnsavedChangesConfirmationModalOpen(false);
    };

    const onClosePopup = () => {
      if (isDirty) {
        setIsUnsavedChangesConfirmationModalOpen(true);
        return;
      }
      handleCloseHandler();
    };

    const openDataValidations = () => {
      window.open(
        /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
        `${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/validation_groups`,
        '_blank',
        'noopener',
      );
    };

    if (!applicantId) {
      return null;
    }

    return (
      <>
        <Dialog
          ariaLabelledBy={DATA_VALIDATION_FIELD_ARIA_LABEL}
          maxWidth={566}
          open
          onClose={onClosePopup}
          blurOverlay
          disableBackdropClick
        >
          <ModalHeader
            ariaLabelledBy={DATA_VALIDATION_FIELD_ARIA_LABEL}
            Icon={Checkmark}
            onClose={onClosePopup}
          >
            <FormattedMessage {...messages.modalTitle} />
          </ModalHeader>
          <ModalContent>
            {isLoadingDataFields && <Loader fullSection />}
            {!isLoadingDataFields && (
              <>
                <Typography variant="h4" className={styles.instructions}>
                  <FormattedMessage {...messages.dataValidationInstructions} />
                </Typography>
                <>
                  {applicantDataFields.map(dataField => (
                    <DataValidationField
                      detail={dataField}
                      key={dataField.key}
                      applicantId={applicantId}
                      type={dataField.dataFieldType}
                      savePayload={savePayload}
                      preventStoreUpdates
                      updateApplicantDisabled
                      isRequired={dataField.required}
                      error={errors[dataField.key]}
                      setIsDirty={setIsDirty}
                      isMobile={isMobile}
                    />
                  ))}
                </>
              </>
            )}
          </ModalContent>
          <ModalFooter>
            {!isRegularUser && !isMobile && (
              <Grid
                direction="row"
                alignItems="center"
                container
                className={styles.manageDataValidationsWrapper}
              >
                <Typography
                  onClick={openDataValidations}
                  className={styles.manageDataValidations}
                >
                  <FormattedMessage {...messages.manageDataValidations} />
                </Typography>
                <ExportIcon
                  onClick={openDataValidations}
                  className={styles.exportIcon}
                />
              </Grid>
            )}
            <Button
              className={styles.confirmButton}
              onClick={onConfirmClicked}
              isLoading={isConfirming}
              disabled={isConfirming}
              autoWidth
            >
              <FormattedMessage {...messages.confirm} />
            </Button>
          </ModalFooter>
        </Dialog>
        {isUnsavedChangesConfirmationModalOpen && (
          <ConfirmationModal
            negative
            actionButtonText={intl.formatMessage(messages.discardChanges)}
            cancelButtonText={intl.formatMessage(messages.keepEditing)}
            handleClose={onKeepEditing}
            handleSubmit={onDiscardChanges}
            modalContentText={intl.formatMessage(
              messages.unsavedChangesMessage,
            )}
            modalTitle={intl.formatMessage(messages.unsavedChangesTitle)}
          />
        )}
      </>
    );
  },
);
