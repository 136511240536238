import React, { FC } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import messages from '../messages';

export interface SvgProps {
  description?: MessageDescriptor;
  title?: MessageDescriptor;
}

export const TrashcanSvg: FC<SvgProps> = ({ description, title }) => {
  const trashcanIconTitle = title ?? messages.trashcanIconTitle;
  const trashcanIconDescription =
    description ?? messages.trashcanIconDescription;
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby="delete"
    >
      <title />
      <title id="pen-svg-title">
        <FormattedMessage {...trashcanIconTitle} />
      </title>
      <desc id="pen-svg-description">
        <FormattedMessage {...trashcanIconDescription} />
      </desc>{' '}
      <path
        d="M2 5V10.6C2 11.37 2.6 12 3.33333 12H8.66667C9.4 12 10 11.37 10 10.6V5H2Z"
        fill="#969EB3"
      />
      <path
        d="M9 2.4V0.8C9 0.32 8.7 0 8.25 0H3.75C3.3 0 3 0.32 3 0.8V2.4H0V4H12V2.4H9ZM7.5 2.4H4.5V1.6H7.5V2.4Z"
        fill="#969EB3"
      />
    </svg>
  );
};

export default TrashcanSvg;
