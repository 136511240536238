import {
  Button,
  InnerContent,
  SimpleModal,
  SimpleModalFooter,
  SimpleModalHeader,
  SimpleModalHeaderIcon,
  SimpleModalTitle,
} from '@fountain/fountain-ui-components';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import TrashcanIcon from 'images/trashV2';
import globalMessages from 'shared/global/messages';

import messages from '../../messages';
import useStyles from './styles';

const ClearDetailFieldDialog = ({
  onClickCancel,
  onClickClear,
  onCloseDialog,
}) => {
  const styles = useStyles();
  const { formatMessage } = useIntl();

  const body = formatMessage(messages.clearDialogBody);
  const cancel = formatMessage(globalMessages.cancel);
  const clear = formatMessage(globalMessages.clear);
  const title = formatMessage(messages.clearDialogTitle);

  return (
    <SimpleModal
      fullScreenOnMobile={false}
      maxWidth={400}
      onClose={onCloseDialog}
    >
      <SimpleModalHeader onClose={onCloseDialog}>
        <SimpleModalHeaderIcon Icon={TrashcanIcon} color="red" />
        <SimpleModalTitle>{title}</SimpleModalTitle>
      </SimpleModalHeader>
      <InnerContent>
        <Typography>{body}</Typography>
      </InnerContent>
      <SimpleModalFooter>
        <Button onClick={onClickCancel} size="small" type="secondary">
          {cancel}
        </Button>
        <Button
          className={styles.clearDialogButton}
          color="primary"
          negative
          onClick={onClickClear}
          size="small"
        >
          {clear}
        </Button>
      </SimpleModalFooter>
    </SimpleModal>
  );
};

ClearDetailFieldDialog.propTypes = {
  onClickCancel: PropTypes.func.isRequired,
  onClickClear: PropTypes.func.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
};

export default ClearDetailFieldDialog;
