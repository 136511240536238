import {
  Applicants,
  Calendar,
  CustomTooltip,
  Drag,
  IconButton,
  Trashcan,
} from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import {
  CancelablePromise,
  SidebarStage,
  WorkflowEditorService,
} from 'api-clients/monolith';
import classNames from 'classnames';
import { useSimpleToggle } from 'hooks';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { addMessageAction } from 'containers/FlashMessage/actions';
import { terminalStagesHash } from 'containers/WorkflowEditor/constants';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';

import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { messages as idleMoveRuleMessages } from '../../IdleMoveRule/messages';
import { useGetAdditionalInfoText } from './hooks';
import { messages } from './messages';
import { StageIconByType } from './StageIconByType';
import { useStyles } from './styles';

const DeleteStageButton: FC<{
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}> = ({ className, onClick }) => {
  const intl = useIntl();
  const styles = useStyles();

  return (
    <CustomTooltip dense title={intl.formatMessage(messages.delete)}>
      <IconButton className={className} defaultIconColor onClick={onClick}>
        <Trashcan
          aria-label={intl.formatMessage(idleMoveRuleMessages.trashcan)}
          className={styles.trashCan}
        />
      </IconButton>
    </CustomTooltip>
  );
};

const DragIcon: FC = () => {
  const intl = useIntl();
  const styles = useStyles();

  return (
    <CustomTooltip dense title={intl.formatMessage(messages.dragToChange)}>
      <Drag
        aria-label={intl.formatMessage(messages.drag)}
        fontSize="small"
        className={styles.dragIcon}
      />
    </CustomTooltip>
  );
};

export interface StageCardProps {
  onDiscardChanges: () => void;
  refetchStages: () => void;
  scheduledApplicants?: string;
  selected?: boolean;
  stage: SidebarStage;
}

export const StageCard: FC<StageCardProps> = ({
  onDiscardChanges,
  refetchStages,
  scheduledApplicants,
  selected,
  stage: {
    applicants_count: applicantCount,
    title,
    type,
    short_type: shortType,
    additional_info: additionalInfo,
    external_id: externalId,
    slug,
    move_rule: moveRule,
  },
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    accountSlug,
    funnelSlug,
    stageSlug: selectedStageSlug,
  } = useParams<{
    accountSlug: string;
    funnelSlug: string;
    stageSlug: string;
  }>();

  const {
    showContent: isHovering,
    on: setHoverTrue,
    off: setHoverFalse,
  } = useSimpleToggle();

  const {
    showContent: isConfirmDeleteModalOpen,
    on: openConfirmDeleteModal,
    off: closeConfirmDeleteModal,
  } = useSimpleToggle();

  const handleOpenConfirmDeleteModal = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    openConfirmDeleteModal();
  };

  const handleCloseConfirmDeleteModal = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    closeConfirmDeleteModal();
  };

  const { mutation: deleteStage } = useApiServiceMutation<
    void,
    (stageExternalId: string) => CancelablePromise<void>,
    { errors: string[] }
    // eslint-disable-next-line @typescript-eslint/unbound-method
  >(WorkflowEditorService.deleteInternalApiWorkflowEditorStages, {
    onSuccess: () => {
      closeConfirmDeleteModal();
      refetchStages();

      dispatch(
        addMessageAction(intl.formatMessage(messages.deleteSuccess), 'success'),
      );

      if (slug === selectedStageSlug) {
        onDiscardChanges();
        history.push(`/${accountSlug}/openings/${funnelSlug}/workflow`);
      }
    },
    onError: errorBody => {
      const errorMessages = errorBody?.errors?.join('. ') ?? '';

      closeConfirmDeleteModal();
      dispatch(addMessageAction(errorMessages, 'error', false));
    },
  });

  const handleDeleteStage = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    deleteStage(externalId);
  };

  const additionalInfoText = useGetAdditionalInfoText(additionalInfo, type);
  const idleMoveRuleText = moveRule
    ? intl.formatMessage(messages.movingToAfterDays, {
        stageTitle: moveRule.target_stage_title,
        numberOfDays: moveRule.idle_days_count,
      })
    : null;

  const isArchivedOrRejectedStage =
    type === 'ArchivedStage' || type === 'RejectedStage';

  const isTerminalStage = terminalStagesHash[type];

  // This component is currently used with the same props in two places in this JSX.
  // @TODO: Refactor using composition to remove the need for this
  const ConfirmDeleteModal = (
    <ConfirmationModal
      ariaLabelledBy={intl.formatMessage(messages.deleteStage)}
      bodyContent={
        <FormattedMessage
          {...messages.deleteStageConfirmationText}
          values={{ title }}
        />
      }
      cancelButtonContent={<FormattedMessage {...messages.cancel} />}
      confirmButtonContent={<FormattedMessage {...messages.delete} />}
      maxWidth={420}
      negative
      onCancel={handleCloseConfirmDeleteModal}
      onConfirm={handleDeleteStage}
      title={intl.formatMessage(messages.deleteStage)}
    />
  );

  const StageIcon = <StageIconByType type={type} />;

  if (type === 'RuleStage' || type === 'DistributeApplicantsRuleStage') {
    return (
      <>
        <Grid
          alignItems="center"
          aria-label={intl.formatMessage(messages.stageCard)}
          className={classNames(styles.cardWrapper, {
            selected,
          })}
          container
          justify="space-between"
          onMouseEnter={setHoverTrue}
          onMouseLeave={setHoverFalse}
        >
          <div className={styles.ruleCardInnerContents}>
            <div className={styles.iconContainer}>
              {isHovering ? <DragIcon /> : StageIcon}
            </div>
            <Typography variant="body2" className={styles.ruleType}>
              <FormattedMessage {...messages.rule} />
            </Typography>
            <Typography variant="h3">{title}</Typography>
          </div>
          {(selected || isHovering) && (
            <DeleteStageButton
              className={styles.ruleStageTrashIcon}
              onClick={handleOpenConfirmDeleteModal}
            />
          )}
        </Grid>
        {isConfirmDeleteModalOpen && ConfirmDeleteModal}
      </>
    );
  }

  return (
    <>
      <Grid
        className={classNames(styles.cardWrapper, {
          selected,
        })}
        onMouseEnter={setHoverTrue}
        onMouseLeave={setHoverFalse}
        container
        wrap="nowrap"
        aria-label={intl.formatMessage(messages.stageCard)}
      >
        <Grid className={styles.iconContainer}>
          {!isArchivedOrRejectedStage &&
            (isHovering ? (
              <DragIcon aria-label={intl.formatMessage(messages.drag)} />
            ) : (
              StageIcon
            ))}

          {isArchivedOrRejectedStage && StageIcon}
        </Grid>
        <Grid>
          <Grid alignItems="center" className={styles.typeRow} container>
            <Typography variant="body2" className={styles.type}>
              {shortType}
            </Typography>
            <Applicants
              aria-label={intl.formatMessage(messages.applicants)}
              className={styles.applicantIcon}
            />
            <Typography variant="body2" className={styles.text}>
              {applicantCount}
            </Typography>
            {Boolean(scheduledApplicants) && (
              <>
                <Calendar
                  aria-label={intl.formatMessage(messages.calendar)}
                  className={styles.calendar}
                />
                <Typography className={styles.text}>
                  {scheduledApplicants}
                </Typography>
              </>
            )}
          </Grid>
          <Grid justify="space-between" container direction="column">
            <Grid>
              <Typography variant="h3" className={styles.title}>
                {title}
              </Typography>
            </Grid>
            {additionalInfoText && (
              <Grid className={styles.titleWrapper}>
                <Typography variant="overline" data-testid="additionalInfoText">
                  {additionalInfoText}
                </Typography>
              </Grid>
            )}
            {idleMoveRuleText && (
              <Grid className={styles.titleWrapper}>
                <Typography variant="overline" data-testid="idleMoveRuleText">
                  {idleMoveRuleText}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid className={styles.deleteContainer}>
          {(selected || isHovering) && !isTerminalStage && (
            <DeleteStageButton onClick={handleOpenConfirmDeleteModal} />
          )}
        </Grid>
      </Grid>
      {isConfirmDeleteModalOpen && ConfirmDeleteModal}
    </>
  );
};
