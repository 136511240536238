import {
  CLOSE_BULK_ACTION_QUEUE_MENU,
  OPEN_BULK_ACTION_QUEUE_MENU,
} from './constants';

export function openBulkActionQueueMenu() {
  return {
    type: OPEN_BULK_ACTION_QUEUE_MENU,
  };
}

export function closeBulkActionQueueMenu() {
  return {
    type: CLOSE_BULK_ACTION_QUEUE_MENU,
  };
}
