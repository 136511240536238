import {
  CustomTooltip,
  IconButton,
  Input,
  Trashcan,
} from '@fountain/fountain-ui-components';
import { InputAdornment, Typography } from '@material-ui/core';
import {
  VideoRecordingStageQuestion,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import cx from 'classnames';
import { produce } from 'immer';
import React, { ChangeEventHandler, useContext, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Error } from 'components/Error';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { messages } from './messages';
import { useStyles } from './styles';

export interface QuestionProps {
  allowDelete: boolean;
  index: number;
  question: VideoRecordingStageQuestion;
}

export const Question: VFC<QuestionProps> = ({
  allowDelete,
  index,
  question,
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const { setStage } = useContext(StageContext);

  const getDurationValidationError = (duration: number) => {
    if (duration < 10) {
      return intl.formatMessage(messages.durationTooSmall, {
        duration: 10,
      });
    }
    if (duration > 300) {
      return intl.formatMessage(messages.durationTooLarge, {
        duration: 300,
      });
    }
    return '';
  };

  const handleQuestionChange: ChangeEventHandler<HTMLInputElement> = event => {
    event.persist();
    setStage(
      produce((draftStage: WorkflowStageDetail) => {
        if (draftStage.type !== 'VideoRecordingStage') {
          return;
        }
        const questionToUpdate = draftStage.additional_info.questions.find(
          stageQuestion => stageQuestion.id === question.id,
        );
        if (!questionToUpdate) {
          return;
        }
        questionToUpdate.body = event.target.value;
      }),
    );
  };

  const handleDurationChange: ChangeEventHandler<HTMLInputElement> = event => {
    event.persist();
    setStage(
      produce((draftStage: WorkflowStageDetail) => {
        if (draftStage.type !== 'VideoRecordingStage') {
          return;
        }

        const questionToUpdate = draftStage.additional_info.questions.find(
          stageQuestion => stageQuestion.id === question.id,
        );
        if (!questionToUpdate) {
          return;
        }

        const isInteger = /^[0-9]+$/.test(event.target.value);
        const validatedDuration = isInteger
          ? parseInt(event.target.value, 10)
          : 0;
        questionToUpdate.max_length = validatedDuration;
      }),
    );
  };

  const onDelete = (id: string | number) => {
    setStage(
      produce((draftStage: WorkflowStageDetail) => {
        if (draftStage.type !== 'VideoRecordingStage') {
          return;
        }

        const questionToUpdate = draftStage.additional_info.questions.find(
          question => question.id === id,
        );
        if (!questionToUpdate) {
          return;
        }
        // Client-only construct: can delete the object from the array
        if (typeof id === 'string') {
          draftStage.additional_info.questions =
            draftStage.additional_info.questions.filter(
              question => question.id !== questionToUpdate.id,
            );
        } else {
          // DB-persisted; add _destroy to delete via the API
          // eslint-disable-next-line no-underscore-dangle
          questionToUpdate._destroy = true;
        }
      }),
    );
  };

  return (
    <div
      className={styles.questionContainer}
      data-testid="video-question-container"
    >
      <div
        className={cx(styles.questionHeader, {
          [styles.questionHeaderWithDeleteButton]: allowDelete,
        })}
      >
        <Typography color="textPrimary" variant="h4">
          <FormattedMessage
            {...messages.questionHeader}
            values={{
              number: index + 1,
            }}
          />
        </Typography>
        {allowDelete && (
          <CustomTooltip dense title={intl.formatMessage(messages.delete)}>
            <IconButton
              aria-label={intl.formatMessage(messages.delete)}
              className={styles.deleteButton}
              defaultIconColor
              onClick={() => onDelete(question.id)}
            >
              <Trashcan
                aria-label={intl.formatMessage(messages.delete)}
                viewBox="0 0 16 16"
                className={styles.trashcanIcon}
              />
            </IconButton>
          </CustomTooltip>
        )}
      </div>
      <div className={styles.questionInputContainer}>
        <Input
          className={styles.questionInput}
          data-testid="question-body-input"
          error={!question.body}
          fullWidth
          label={intl.formatMessage(messages.question)}
          multiline
          onChange={handleQuestionChange}
          required
          rows={3}
          value={question.body}
        />
        <Error
          className={styles.questionError}
          error={
            !question.body
              ? intl.formatMessage(messages.questionRequired)
              : undefined
          }
          align="right"
        />
      </div>
      <Input
        data-testid="question-duration-input"
        endAdornment={
          <InputAdornment position="end">
            <Typography variant="body2">
              <FormattedMessage {...messages.seconds} />
            </Typography>
          </InputAdornment>
        }
        // @ts-expect-error FUIC Input facade actually allows a string here
        error={getDurationValidationError(question.max_length)}
        label={intl.formatMessage(messages.duration, {
          range: '(10-300 s)',
        })}
        onChange={handleDurationChange}
        required
        rootClass={styles.lengthInput}
        value={question.max_length}
      />
    </div>
  );
};
