import { DrawerContent, DrawerHeader } from '@fountain/fountain-ui-components';
import { Tab, Tabs, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import React, { FC } from 'react';
import SwipeableViews from 'react-swipeable-views';

import MobileMessagePreview from 'components/MobileMessagePreview';
import CloseIcon from 'images/close.svg';
import LeftArrow from 'images/left-arrow.svg';
import SmsIcon from 'images/sms-blue-icon.svg';
import WhatsappIcon from 'images/whatsapp-logo.svg';
import { formatPhoneNumber } from 'utils/formatPhoneNumber';

import useStyles from './styles';

interface MessagePreviewDeviceViewProps {
  isWhatsappEnabled: boolean;
  setPreviewMessageOpenForMobile: (
    previewMessageOpenForMobile: boolean,
  ) => void;
  previewMessageOpenForMobile: boolean;
  handleClose: () => void;
  setMobilePreviewIndex: (mobilePreviewIndex: number) => void;
  mobilePreviewIndex: number;
  smsText: string;
  whatsappText: string;
  twilioNumber: number;
  messageApplicantFromAnyChannel: boolean;
}

const MessagePreviewDeviceView: FC<MessagePreviewDeviceViewProps> = ({
  isWhatsappEnabled,
  setPreviewMessageOpenForMobile,
  previewMessageOpenForMobile,
  handleClose,
  setMobilePreviewIndex,
  mobilePreviewIndex,
  smsText,
  whatsappText,
  twilioNumber,
  messageApplicantFromAnyChannel,
}) => {
  const classes = useStyles();

  const handleMobilePreviewChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setMobilePreviewIndex(value);
  };

  const handleChangeIndex = (index: number) => {
    setMobilePreviewIndex(index);
  };

  return (
    <>
      {isWhatsappEnabled ? (
        <>
          <DrawerHeader className={classes.messagePreviewHeader}>
            <IconButton
              className={classes.backButton}
              onClick={() =>
                setPreviewMessageOpenForMobile(!previewMessageOpenForMobile)
              }
            >
              <img src={LeftArrow} alt="back-button" />
            </IconButton>
            <IconButton onClick={handleClose} className={classes.closeButton}>
              <img src={CloseIcon} alt="close-icon" role="presentation" />
            </IconButton>
          </DrawerHeader>
          <SwipeableViews
            enableMouseEvents
            index={mobilePreviewIndex}
            onChangeIndex={handleChangeIndex}
          >
            <div>
              <DrawerContent className={classes.messagePreviewDeviceView}>
                <MobileMessagePreview
                  type="sms"
                  phoneNumber={formatPhoneNumber(twilioNumber)}
                  messageText={smsText || undefined}
                />
              </DrawerContent>
            </div>
            <div>
              <DrawerContent className={classes.messagePreviewDeviceView}>
                <MobileMessagePreview
                  type="sms"
                  phoneNumber={formatPhoneNumber(twilioNumber)}
                  messageText={whatsappText || undefined}
                  isWhatsappPreview
                />
              </DrawerContent>
            </div>
          </SwipeableViews>
          {!messageApplicantFromAnyChannel && (
            <Tabs
              className={classes.tabs}
              onChange={handleMobilePreviewChange}
              value={mobilePreviewIndex}
              variant="standard"
            >
              <Tab
                label={
                  <Typography className={classes.mobilePreviewIcon}>
                    <img
                      src={SmsIcon}
                      alt="sms preview"
                      className={classes.tabIcon}
                    />{' '}
                    <span>SMS</span>
                  </Typography>
                }
              />
              <Tab
                label={
                  <Typography className={classes.mobilePreviewIcon}>
                    <img
                      src={WhatsappIcon}
                      alt="whatsapp preview"
                      className={classes.tabIcon}
                    />{' '}
                    <span>WhatsApp</span>
                  </Typography>
                }
              />
            </Tabs>
          )}
        </>
      ) : (
        <>
          <DrawerHeader className={classes.messagePreviewHeader}>
            <IconButton
              className={classes.backButton}
              onClick={() =>
                setPreviewMessageOpenForMobile(!previewMessageOpenForMobile)
              }
            >
              <img src={LeftArrow} alt="back-button" />
            </IconButton>
            <IconButton onClick={handleClose} className={classes.closeButton}>
              <img src={CloseIcon} alt="close-icon" role="presentation" />
            </IconButton>
          </DrawerHeader>
          <DrawerContent className={classes.messagePreviewDeviceView}>
            <MobileMessagePreview
              type="sms"
              phoneNumber={formatPhoneNumber(twilioNumber)}
              messageText={smsText || undefined}
            />
          </DrawerContent>
        </>
      )}
    </>
  );
};

export default MessagePreviewDeviceView;
