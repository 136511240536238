/*
 *
 * SendSmsPopup constants
 *
 */

/* action constants */

export const DELIVER_SMS_INIT = 'app/SendSmsPopup/DELIVER_SMS_INIT';
export const DELIVER_SMS_SUCCESS = 'app/SendSmsPopup/DELIVER_SMS_SUCCESS';
export const DELIVER_SMS_ERROR = 'app/SendSmsPopup/DELIVER_SMS_ERROR';

export const FETCH_MEMBERS_INIT = 'app/SendSmsPopup/FETCH_MEMBERS_INIT';
export const FETCH_MEMBERS_SUCCESS = 'app/SendSmsPopup/FETCH_MEMBERS_SUCCESS';
export const FETCH_MEMBERS_ERROR = 'app/SendSmsPopup/FETCH_MEMBERS_ERROR';

/* etc */

export const SEND_TYPE = {
  NOW: 'now',
  LATER: 'later',
};

export const TIME_FORMAT = 'hh:mm A z';
export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

export const SEND_TYPE_OPTIONS = [
  {
    label: SEND_TYPE.NOW,
    value: SEND_TYPE.NOW,
  },
  {
    label: SEND_TYPE.LATER,
    value: SEND_TYPE.LATER,
  },
];

export const MAX_WIDTH = 608;
