import { WorkflowDataCollectionStage } from 'api-clients/monolith';
import React, { VFC } from 'react';

import { AdvancedSettings } from 'containers/WorkflowEditor/components/AdvancedSettings';
import { StageSection } from 'containers/WorkflowEditor/components/StageSection';

import { IdleMoveRule } from '../../../IdleMoveRule';
import { StageSettingMessageCard } from '../../../StageSettingMessageCard';
import { DataCollectionFormCard } from './DataCollectionFormCard';

export interface DataCollectionStageProps {
  stage: WorkflowDataCollectionStage;
  dataCollectionBotSaveError?: string | null;
}

export const DataCollectionStage: VFC<DataCollectionStageProps> = ({
  stage,
  dataCollectionBotSaveError,
}) => {
  const isSubstage = Boolean(stage.parent_stage_id);

  return (
    <div data-testid="dataCollectionStage">
      <StageSection>
        <DataCollectionFormCard
          stage={stage}
          dataCollectionBotSaveError={dataCollectionBotSaveError}
        />
      </StageSection>
      {!isSubstage && (
        <>
          <StageSection>
            <IdleMoveRule stage={stage} />
          </StageSection>
          <StageSection>
            <StageSettingMessageCard
              messageType="data_collection"
              stageExternalId={stage.external_id}
            />
          </StageSection>
          <StageSection>
            <StageSettingMessageCard
              messageType="trigger"
              stageExternalId={stage.external_id}
            />
          </StageSection>
          {stage.account_go_enabled && (
            <StageSection>
              <AdvancedSettings stage={stage} />
            </StageSection>
          )}
        </>
      )}
    </div>
  );
};
