import { Loader, StyledReactSelect } from '@fountain/fountain-ui-components';
import { Action, Reason, WorkflowStageDetail } from 'api-clients/monolith';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addMessageAction } from 'containers/FlashMessage/actions';
import { defaultReason } from 'containers/WorkflowEditor/components/StageSettingMessageCard/components/MessageRow/MessageRow.fixtures';
import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';

import { PartnerRuleProps } from '../../../PartnerRules/types';
import { RulesProps } from '../../../types';
import { messages } from './messages';

interface ReasonSelectProps {
  setRules?: React.Dispatch<React.SetStateAction<RulesProps>>;
  setPartnerRules?: React.Dispatch<React.SetStateAction<PartnerRuleProps>>;
  setStage?: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
  action: Action;
  ruleId?: string | number;
}

// Assumes stageTypeWithReasons[selectedStageType]
export const ReasonSelect: FC<ReasonSelectProps> = ({
  action,
  ruleId,
  setRules,
  setPartnerRules,
  setStage,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    archivedReasonsResult,
    rejectedReasonsResult,
    funnel_stages: funnelStages,
  } = useContext(RulesEditDataContext);

  const targetStage = funnelStages?.find(
    stage => stage.stage_id === action.ref_stage_1_id,
  );

  if (
    (targetStage?.type === 'ArchivedStage' &&
      archivedReasonsResult.status === 'loading') ||
    (targetStage?.type === 'RejectedStage' &&
      rejectedReasonsResult.status === 'loading')
  ) {
    return <Loader size="2rem" />;
  }

  if (
    (targetStage?.type === 'ArchivedStage' &&
      archivedReasonsResult.status === 'error') ||
    (targetStage?.type === 'RejectedStage' &&
      rejectedReasonsResult.status === 'error')
  ) {
    dispatch(
      addMessageAction(
        intl.formatMessage(messages.stageReasonLoadingError),
        'error',
        true,
      ),
    );
    return null;
  }

  // Generating options based on type of stage and request response
  let reasons: Reason[] = [defaultReason];
  if (
    targetStage?.type === 'ArchivedStage' &&
    archivedReasonsResult.status === 'ready'
  ) {
    reasons = reasons.concat(archivedReasonsResult.data.archived_reasons);
  }
  if (
    targetStage?.type === 'RejectedStage' &&
    rejectedReasonsResult.status === 'ready'
  ) {
    reasons = reasons.concat(rejectedReasonsResult.data.rejection_reasons);
  }

  const onChangeReason = (reason: Reason) => {
    if (setRules && ruleId && targetStage) {
      setRules(
        produce((draftRules: RulesProps) => {
          const draftActions =
            draftRules[ruleId]?.action_set_attributes?.actions_attributes ?? [];

          const idx = draftActions.findIndex(act => act.id === action.id);

          if (targetStage.type === 'ArchivedStage') {
            draftActions[idx].archived_reason_id = reason.id;
            draftActions[idx].rejection_reason_id = null;
          }
          if (targetStage.type === 'RejectedStage') {
            draftActions[idx].rejection_reason_id = reason.id;
            draftActions[idx].archived_reason_id = null;
          }
        }),
      );
    } else if (setStage) {
      setStage(
        produce((draftStage: WorkflowStageDetail) => {
          if (
            draftStage.type !== 'RuleStage' &&
            draftStage.type !== 'CustomStage' &&
            draftStage.type !== 'AssessmentStage'
          ) {
            return;
          }
          const elseAction =
            draftStage?.additional_info?.else_action_set_attributes
              ?.actions_attributes?.[0];

          if (elseAction && targetStage) {
            if (targetStage.type === 'ArchivedStage') {
              elseAction.archived_reason_id = reason.id;
              elseAction.rejection_reason_id = null;
            }
            if (targetStage.type === 'RejectedStage') {
              elseAction.rejection_reason_id = reason.id;
              elseAction.archived_reason_id = null;
            }
          }
        }),
      );
    } else if (setPartnerRules && ruleId && targetStage) {
      setPartnerRules(
        produce((draftRules: PartnerRuleProps) => {
          const draftAction = draftRules[ruleId]?.action_attributes;

          if (targetStage.type === 'ArchivedStage') {
            draftAction.archived_reason_id = reason.id;
            draftAction.rejection_reason_id = null;
          }
          if (targetStage.type === 'RejectedStage') {
            draftAction.rejection_reason_id = reason.id;
            draftAction.archived_reason_id = null;
          }
        }),
      );
    }
  };

  return (
    <StyledReactSelect
      aria-label={intl.formatMessage(messages.reason)}
      label={intl.formatMessage(messages.reason)}
      options={reasons}
      value={
        reasons.find(option => {
          return targetStage?.type === 'ArchivedStage'
            ? option.id === action.archived_reason_id
            : option.id === action.rejection_reason_id;
        }) ?? null
      }
      getOptionLabel={(option: Reason) => {
        return option.content;
      }}
      getOptionValue={(option: Reason) => {
        return option.id;
      }}
      onChange={onChangeReason}
      placeholder=""
      required
    />
  );
};
