import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  automaticallyMoveApplicantToNextStage: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.AdvancedSettingsCard.automaticallyMoveApplicantToNextStage`,
    defaultMessage:
      'Automatically move applicant to next stage after the multistage is completed (recommended)',
  },
  settings: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.AdvancedSettingsCard.settings`,
    defaultMessage: 'Settings',
  },
  showStageInApplicantPortal: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.AdvancedSettingsCard.showStageInApplicantPortal`,
    defaultMessage: 'Show stage in applicant portal progress tracker',
  },
});
