import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from '../messages';
import { useConfirmationModalStyles } from './styles';

interface UnsavedChangesModalProps {
  open: boolean;
  handleClose: () => void;
  handleDiscard: () => void;
}

export const UnsavedChangesModal: React.FC<UnsavedChangesModalProps> = ({
  open,
  handleClose,
  handleDiscard,
}) => {
  const classes = useConfirmationModalStyles();
  const [isLoading, setIsLoading] = useState(false);

  const continueEditing = () => {
    setIsLoading(true);
    handleClose();
  };

  if (isLoading) return <CircularProgress size="2rem" />;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paperScrollPaper: classes.modalContainer }}
    >
      <DialogTitle classes={{ root: classes.modalTitle }}>
        <FormattedMessage {...messages.unsavedChanges} />
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <DialogContentText classes={{ root: classes.modalContentText }}>
          <FormattedMessage {...messages.unsavedModalDescription} />
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <Button
          onClick={handleDiscard}
          color="default"
          variant="outlined"
          className={classes.btnCancel}
        >
          <FormattedMessage {...messages.discard} />
        </Button>
        <Button
          onClick={continueEditing}
          color="secondary"
          variant="outlined"
          className={classes.btnContinue}
        >
          <FormattedMessage {...messages.continueEditing} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
