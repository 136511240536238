import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { VFC } from 'react';

export const NewTabIcon: VFC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <svg fill="#000000">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z" />
    </svg>
  </SvgIcon>
);
