import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { STAGE_TYPES } from 'containers/ApplicantsV2/constants';
import { SELECT_ALL } from 'containers/ApplicantTableV2/constants';
import makeSelectApplicantTableV2, {
  makeSelectSelectedApplicantIds,
} from 'containers/ApplicantTableV2/selectors';
import useApplicantDetail from 'hooks/useApplicantDetail';
import { fetchStages } from 'requests/requests';

import useIsMasterApplicantsView from './useIsMasterApplicantsView';

export default function useTargetStageId({
  initialApplicantId,
  initialToStageId,
  actionType,
  isLoading = false,
}) {
  const { applicants, totalRows } = useSelector(makeSelectApplicantTableV2());
  const selectedApplicantIds = useSelector(makeSelectSelectedApplicantIds());
  const [applicantId, setApplicantId] = useState(initialApplicantId);
  const [isFetching, setIsFetching] = useState(true);
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const [toStageId, setToStageId] = useState(initialToStageId);
  const [totalSelectedApplicantCount, setTotalSelectedApplicantCount] =
    useState(undefined);

  const isMasterApplicantsView = useIsMasterApplicantsView();
  const { data: applicantData, error } = useApplicantDetail(
    applicantId,
    isMasterApplicantsView || !isLoading,
  );

  const getRejectedStageId = applicant => {
    const jobId = applicant && applicant.job && applicant.job.id;
    if (jobId && !toStageId) {
      return fetchStages(jobId).then(res => {
        const rejectedStage = res.data.stages.find(
          stage => stage.type === STAGE_TYPES.reject,
        );
        setToStageId(rejectedStage.id);
        return res;
      });
    }
    return null;
  };

  useEffect(() => {
    if (!applicantId && !!selectedApplicantIds.length) {
      const selectAll = selectedApplicantIds === SELECT_ALL;
      const firstSelectedApplicantId = selectAll
        ? Object.keys(applicants)[0]
        : selectedApplicantIds[0];
      setApplicantId(firstSelectedApplicantId);
      setTotalSelectedApplicantCount(
        selectAll ? totalRows : selectedApplicantIds.length,
      );
    }
    if (applicantData) {
      setSelectedApplicant(applicantData);
      setIsFetching(false);
    }
    if (actionType === 'reject' && applicantData) {
      getRejectedStageId(applicantData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionType, applicantData]);

  return {
    error,
    isFetching,
    isMasterApplicantsView,
    selectedApplicant,
    selectedApplicantIds,
    totalSelectedApplicantCount,
    toStageId,
  };
}
