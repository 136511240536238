import { useState } from 'react';

export default function useAnchorState() {
  const [anchorEl, setAnchorEl] = useState(null);
  const toggle = event => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  return { anchorEl, toggle, handleClose };
}
