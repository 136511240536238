import {
  Alert,
  Button,
  ScheduleCalendar,
} from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import {
  WorkflowSchedulingStage,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import { makeSelectWorkflowSystemEnabled } from 'containers/Auth_old/selectors';
import { StageSettingCard } from 'containers/WorkflowEditor/components/StageSettingCard';

import { AdvancedBookingOptions } from './AdvancedBookingOptions';
import { BaseSettings } from './BaseSettings';
import { messages } from './messages';
import { useStyles } from './styles';

export interface SchedulerCardProps {
  stage: WorkflowSchedulingStage;
  setStage: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
  openingExternalId: string;
}

export const SchedulerCard: FC<SchedulerCardProps> = ({
  stage,
  setStage,
  openingExternalId,
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const { accountSlug } = useParams<{ accountSlug: string }>();
  const isSchedulerV2 = stage.type === 'SchedulerV2Stage';
  const cardTitle = isSchedulerV2
    ? intl.formatMessage(messages.advancedSettings)
    : intl.formatMessage(messages.scheduler);

  const workflowSystemEnabled = useSelector(makeSelectWorkflowSystemEnabled());
  return (
    <Grid>
      <StageSettingCard variant="default" title={cardTitle}>
        {!isSchedulerV2 && !workflowSystemEnabled && (
          <Grid className={styles.availabilityContainer}>
            <Grid className={styles.slotsAvailableContent}>
              <Typography variant="h2" className={styles.slotsAvailableCount}>
                {stage.additional_info?.available_slots_count ?? 0}
              </Typography>
              <Grid container alignItems="center" justify="center">
                <ScheduleCalendar className={styles.scheduleCalendar} />
                <Typography variant="h4" className={styles.slotsAvailableText}>
                  {intl.formatMessage(messages.slotsAvailable)}
                </Typography>
              </Grid>
            </Grid>
            <Grid className={styles.setAvaibilityContent}>
              {(!stage.additional_info?.available_slots_count ||
                stage.additional_info?.available_slots_count === 0) && (
                <Alert
                  fullWidth
                  type="danger"
                  className={styles.alertContainer}
                >
                  <FormattedMessage {...messages.noSlotsAvailable} />
                </Alert>
              )}
              <Typography
                variant="body2"
                className={styles.setTimeSlotsDescription}
              >
                {intl.formatMessage(messages.setTimeSlotDescription)}
              </Typography>
              <Button
                variant="text"
                type="secondary"
                size="small"
                className={styles.setAvailabilityButton}
                autoWidth
                href={
                  stage.type === 'SchedulerStage'
                    ? `${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/calendar?stage=${openingExternalId}:${stage.external_id}`
                    : `/${accountSlug}/schedule/events`
                }
                target="_blank"
              >
                {intl.formatMessage(messages.setAvailability)}
              </Button>
            </Grid>
          </Grid>
        )}
        <BaseSettings stage={stage} setStage={setStage} />
        <AdvancedBookingOptions stage={stage} setStage={setStage} />
      </StageSettingCard>
    </Grid>
  );
};
