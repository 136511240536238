/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Pagination } from '../models/Pagination';
import type { Workflow } from '../models/Workflow';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WorkflowsService {

    /**
     * Requests Workflows
     * Returns workflows for the logged in user
     * @param title filter query for title
     * @param stageId filter query for stage external id
     * @param page page number in result set
     * @param sortBy field to sort results by
     * @param sortDirection direction to sort results
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiWorkflows(
        title?: string,
        stageId?: string,
        page?: number,
        sortBy?: string,
        sortDirection?: 'asc' | 'desc',
    ): CancelablePromise<{
        workflows: Array<Workflow>;
        pagination: Pagination;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/workflows',
            query: {
                'title': title,
                'stage_id': stageId,
                'page': page,
                'sort_by': sortBy,
                'sort_direction': sortDirection,
            },
        });
    }

    /**
     * Requests Workflows
     * Returns workflow by ID
     * @param workflowId find workflow by ID
     * @returns Workflow successful
     * @throws ApiError
     */
    public static getInternalApiWorkflows1(
        workflowId: string,
    ): CancelablePromise<Workflow> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/workflows/{workflow_id}',
            path: {
                'workflow_id': workflowId,
            },
        });
    }

}