import { IconButton as StyledIconButton } from '@fountain/fountain-ui-components';
import { Grid, Hidden } from '@material-ui/core';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import SearchInput from 'components/SearchInput';
import useClearFilters from 'hooks/useClearFilters';
import SearchIcon from 'images/icon-search.svg';
import globalMessages from 'shared/global/messages';

import makeStyles from './styles';

function Search({ intl, handleQueryChange, query }) {
  const classes = makeStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const { accountSlug } = useParams();
  const isMasterApplicantsView = pathname === `/${accountSlug}/applicants`;

  const [search, setSearch] = useState(query || '');
  const { clearFilters, clearJobStagesFilters } = useClearFilters();

  useEffect(() => {
    // if search input is removed, update MAV
    if (query && !search.length && !!query.length) handleQueryChange('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const clearSearch = () => {
    setSearch('');
    if (isMasterApplicantsView && handleQueryChange) {
      handleQueryChange('');
    }
  };

  const handleSearch = e => {
    if (e.key === 'Enter') {
      if (isMasterApplicantsView && handleQueryChange) {
        handleQueryChange(search);
      } else {
        clearFilters();
        clearJobStagesFilters();
        history.push({
          pathname: `/${accountSlug}/applicants`,
          search: queryString.stringify({
            query: search,
          }),
        });
      }
    }
  };

  return (
    <Grid item>
      <Hidden smDown>
        <SearchInput
          className={classes.hiddenOnMobile}
          onChange={e => setSearch(e.target.value)}
          onKeyPress={handleSearch}
          value={search}
          placeholder={intl.formatMessage(globalMessages.search)}
          onClear={clearSearch}
        />
      </Hidden>
      <Hidden mdUp>
        <StyledIconButton
          defaultIconColor
          className={classes.hiddenOnDesktop}
          isExternalLink
          href={`${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/applicants`}
        >
          <img src={SearchIcon} alt="search" />
        </StyledIconButton>
      </Hidden>
    </Grid>
  );
}

Search.propTypes = {
  intl: PropTypes.object.isRequired,
  handleQueryChange: PropTypes.func,
  query: PropTypes.string,
};

export default injectIntl(Search);
