import { defineMessages } from 'react-intl';

export const scope = 'app.components.OptionBankDropdown';

export default defineMessages({
  minimumInputWarning: {
    id: `${scope}.minimumInputWarning`,
    defaultMessage: '1 or more characters',
  },
  startTyping: {
    id: `${scope}.startTyping`,
    defaultMessage: 'Start typing...',
  },
  noOptions: {
    id: `${scope}.noOptions`,
    defaultMessage: 'No options',
  },
});
