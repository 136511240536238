/*
 *
 * ApplicantsV2 actions
 *
 */

import {
  FETCH_JOB_DETAIL_FAILED,
  FETCH_JOB_DETAIL_INIT,
  FETCH_JOB_DETAIL_SUCCESS,
  RESET_STAGE_APPLICANTS_COUNT,
  SET_SEARCH_QUERY,
} from './constants';

export function fetchJobDetailSuccessAction(job) {
  return {
    type: FETCH_JOB_DETAIL_SUCCESS,
    job,
  };
}
export function fetchJobDetailFailedAction(error) {
  return {
    type: FETCH_JOB_DETAIL_FAILED,
    error,
  };
}

export function fetchJobDetailInitAction(jobId, query, filterSetId) {
  return {
    type: FETCH_JOB_DETAIL_INIT,
    jobId,
    query,
    filterSetId,
  };
}

export function resetStageApplicantCountAction({
  fromStageId,
  toStageId,
  numberOfApplicants = 1,
}) {
  return {
    type: RESET_STAGE_APPLICANTS_COUNT,
    fromStageId,
    toStageId,
    numberOfApplicants,
  };
}

export function setSearchQueryAction(searchQuery) {
  return {
    type: SET_SEARCH_QUERY,
    searchQuery,
  };
}
