import PropTypes from 'prop-types';
import React from 'react';

const SVG = props => (
  <svg
    className={props.className}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 5C7.38071 5 8.5 3.88071 8.5 2.5C8.5 1.11929 7.38071 0 6 0C4.61929 0 3.5 1.11929 3.5 2.5C3.5 3.88071 4.61929 5 6 5Z"
      fill="#B4B9CC"
    />
    <path
      d="M6 6C5.34339 6 4.69321 6.12933 4.08658 6.3806C3.47995 6.63188 2.92876 7.00017 2.46447 7.46447C1.52678 8.40215 1 9.67392 1 11H11C11 9.67392 10.4732 8.40215 9.53553 7.46447C8.59785 6.52678 7.32608 6 6 6Z"
      fill="#B4B9CC"
    />
  </svg>
);

SVG.propTypes = {
  className: PropTypes.string,
};

export default SVG;
