import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/addon/edit/closetag';

import React, { FC, useState } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';

import useStyles from './styles';

export interface HtmlEditorProps {
  onChange?: (text: string) => void;
  value?: string;
}

export const HtmlEditor: FC<HtmlEditorProps> = ({ value = '', onChange }) => {
  const classes = useStyles();

  const [htmlValue, setHtmlValue] = useState(value);

  const handleHtmlChange = (text: string) => {
    if (onChange) {
      onChange(text);
    }

    setHtmlValue(text);
  };

  return (
    <CodeMirror
      value={htmlValue}
      options={{
        mode: 'htmlmixed',
        lineNumbers: true,
        autoCloseTags: true,
        smartIndent: true,
      }}
      onBeforeChange={(editor, data, text) => {
        handleHtmlChange(text);
      }}
      className={classes.htmlEditor}
    />
  );
};
