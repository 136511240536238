/* eslint-disable no-underscore-dangle */
import { Loader } from '@fountain/fountain-ui-components';
import {
  Action,
  CancelablePromise,
  Condition,
  DistributeRule,
  DocumentSigningStagesRuleAttributes,
  DocumentSigningStagesTemplateSetting,
  FunnelDetails,
  PartnerRuleAttributes,
  ReasonSetting,
  ReminderSetting,
  RuleAttributes,
  SidebarStage,
  StageType,
  StageUpdateParams,
  TechCheckStageSetting,
  TriggerSetting,
  VideoRecordingStageQuestion,
  WorkflowCustomStage,
  WorkflowEditorService,
  WorkflowStageDetail,
  WorkflowStageUpdates,
} from 'api-clients/monolith';
import _isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { addMessageAction } from 'containers/FlashMessage/actions';
import {
  DEFAULT_ASSESSMENT_RULE,
  DEFAULT_ELSE_ACTION,
  DEFAULT_RULE,
} from 'containers/WorkflowEditor/components/Rules/constants';
import { RulesProps } from 'containers/WorkflowEditor/components/Rules/types';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';

import { DocumentSigningRuleProps } from '../components/Rules/DocumentSigningRules/types';
import { PartnerRuleProps } from '../components/Rules/PartnerRules/types';
import { SingleRuleStageTypes } from '../components/Rules/RuleCard/RuleCard.fixtures';
import { UnsavedChangesPrompt } from '../components/UnsavedChangesPrompt';
import { DEFAULT_DISTRIBUTE_RULE } from '../components/WorkflowEditorContent/StageDetail/DistributeApplicantsRuleStage/DistributeApplicantsRuleStage';
import { StagesSidebar } from '../components/WorkflowEditorContent/StagesSidebar';
import { StageContext } from '../contexts/stageContext';
import { useStage } from '../hooks/useStage';
import messages from './messages';

export const transformRules = (
  rules: (
    | RuleAttributes
    | PartnerRuleAttributes
    | DocumentSigningStagesRuleAttributes
  )[] = [],
  stageType: StageType = 'RuleStage',
): RulesProps | PartnerRuleProps | DocumentSigningRuleProps => {
  const rulesHash: RulesProps | PartnerRuleProps | DocumentSigningRuleProps =
    {};
  let initialRules: (
    | RuleAttributes
    | PartnerRuleAttributes
    | DocumentSigningStagesRuleAttributes
  )[] = rules;
  if (rules.length === 0) {
    if (
      stageType === 'PartnerStage' ||
      stageType === 'DocumentSignatureStage' ||
      stageType === 'DocumentSigningStage'
    ) {
      initialRules = [];
    } else if (stageType === 'AssessmentStage') {
      initialRules = [DEFAULT_ASSESSMENT_RULE] as RuleAttributes[];
    } else if (!SingleRuleStageTypes.includes(stageType)) {
      initialRules = [DEFAULT_RULE] as RuleAttributes[];
    }
  }

  initialRules.forEach(rule => {
    // TODO: Have backend send uuids for existing rules rather than set new ones for all.
    rulesHash[rule.id ?? uuid()] = rule;
  });
  return rulesHash;
};

type BaseProps = {
  externalId: string;
  funnelStages: SidebarStage[];
  withSidebar: false;
  opening: FunnelDetails;
};

type WithSidebarProps = {
  externalId: string;
  funnelStages: SidebarStage[];
  unsupportedAxStages: string[];
  opening: FunnelDetails;
  refetchStages: () => void;
  selectedStage: SidebarStage | undefined;
  withSidebar: true;
};

type AccountParams = {
  accountSlug: string;
};

export type StageContextProviderProps = BaseProps | WithSidebarProps;

export const StageContextProvider: React.FC<
  StageContextProviderProps
> = props => {
  const { children, externalId, funnelStages, withSidebar, opening } = props;
  const dispatch = useDispatch();
  const intl = useIntl();

  const { result: stageResult, refetch: refetchStage } = useStage({
    funnelSlug: opening.slug,
    externalId,
  });

  const [stage, setStage] = useState<WorkflowStageDetail>(
    {} as WorkflowStageDetail,
  );
  const [isDirty, setIsDirty] = useState(false);
  const [isStageTitleDirty, setIsStageTitleDirty] = useState(false);
  const [rules, setRules] = useState<RulesProps>({});
  const [partnerRules, setPartnerRules] = useState<PartnerRuleProps>({});
  const [documentSigningRules, setDocumentSigningRules] =
    useState<DocumentSigningRuleProps>({});
  const { accountSlug } = useParams<AccountParams>();
  const history = useHistory();

  const updateStageSuccessCallback = () => {
    dispatch(
      addMessageAction(
        intl.formatMessage(messages.changesSavedSuccessfully),
        'success',
      ),
    );
    setIsDirty(false);

    if (withSidebar) {
      props.refetchStages();
    }
  };

  const {
    result: updateStageResult,
    reset: resetUpdateStageResult,
    mutation: updateStage,
  } = useApiServiceMutation<
    WorkflowStageDetail,
    (
      stageExternalId: string,
      funnelSlug: string,
      requestBody?: WorkflowStageUpdates,
    ) => CancelablePromise<WorkflowStageDetail>,
    { errors: Record<string, Array<string>> }
  >(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    WorkflowEditorService.patchInternalApiWorkflowEditorFunnelsStages,
    {
      onSuccess: updateStageSuccessCallback,
    },
  );

  const setStageAndTransformationStates = (stage: WorkflowStageDetail) => {
    const stageCopy = { ...stage };

    // If the stage is a RuleStage and it doesn't have else_action_set_attributes set yet,
    // we want to manually set that here so the ElseAction component can properly update it
    if (
      stageCopy.type === 'RuleStage' &&
      !stageCopy.additional_info.else_action_set_attributes
    ) {
      stageCopy.additional_info.else_action_set_attributes = {
        actions_attributes: [{ ...DEFAULT_ELSE_ACTION }],
      };
    }

    // If stage is DistributeApplicantsRuleStage and doesn't have any distribute_rules one is created by default
    if (
      stageCopy.type === 'DistributeApplicantsRuleStage' &&
      stageCopy.additional_info.distribute_rules?.length === 0 &&
      stageCopy.additional_info.default_location
    ) {
      stageCopy.additional_info.distribute_rules = [
        {
          ...DEFAULT_DISTRIBUTE_RULE,
          id: uuid(),
          percentage: 100,
          to_location_id: stageCopy.additional_info.default_location.id,
        },
      ];
    }

    setStage(stageCopy);

    if (stageCopy.type === 'PartnerStage') {
      const stageDataRules =
        stageCopy.additional_info.rule_set_attributes?.rules_attributes;

      setPartnerRules(
        transformRules(stageDataRules, stageCopy.type) as PartnerRuleProps,
      );
    } else if (
      stageCopy.type === 'DocumentSignatureStage' ||
      stageCopy.type === 'DocumentSigningStage'
    ) {
      const stageDataRules =
        stageCopy.additional_info.template_rule_set_attributes
          ?.rules_attributes;

      setDocumentSigningRules(
        transformRules(
          stageDataRules,
          stageCopy.type,
        ) as DocumentSigningRuleProps,
      );
    } else if (
      stageCopy.type === 'RuleStage' ||
      stageCopy.type === 'CustomStage' ||
      stageCopy.type === 'AssessmentStage'
    ) {
      const stageDataRules =
        stageCopy.additional_info.rule_set_attributes?.rules_attributes;

      setRules(transformRules(stageDataRules, stageCopy.type) as RulesProps);
    }
  };

  const onDiscardChanges = useCallback(() => {
    // If we've saved changes, our fresh data is `updateStageResult`
    if (updateStageResult.status === 'ready') {
      setStageAndTransformationStates(updateStageResult.data);
      return;
    }

    // If the previous save failed, we might still have had a previously
    // successful save, which means the `stageResult` is potentially stale.
    // Refetch the stage to make sure we have up-to-date data.
    if (updateStageResult.status === 'error') {
      refetchStage();
      resetUpdateStageResult();
      return;
    }

    // Reset updateStageResult because we are working with a clean slate
    resetUpdateStageResult();

    // Reset the stage back to the server's stage data
    if (stageResult.status === 'ready') {
      setStageAndTransformationStates(stageResult.data);
    }
  }, [refetchStage, resetUpdateStageResult, stageResult, updateStageResult]);

  const generateSettingsAttributes = (
    settings:
      | ReasonSetting[]
      | TriggerSetting[]
      | ReminderSetting[]
      | undefined,
    initialSettings:
      | ReasonSetting[]
      | TriggerSetting[]
      | ReminderSetting[]
      | undefined,
  ) => {
    if (!settings || !initialSettings) {
      return undefined;
    }

    const updatedSettingIds = settings.map(s => s.id);

    const copySettings: ReasonSetting[] | TriggerSetting[] | ReminderSetting[] =
      settings.map(setting => {
        const updatedSetting = {
          ...setting,
          message_template_attributes: setting.message_template,
        };

        return updatedSetting;
      });

    initialSettings.forEach(setting => {
      if (!updatedSettingIds.includes(setting.id))
        copySettings.push({ ...setting, _destroy: true });
    });

    return copySettings;
  };

  const transformAttributes = (
    hashAttributes: Partial<{ id: number | string }>,
    shouldDeleteStringIds = true,
  ) => {
    if (!hashAttributes) {
      return null;
    }
    // If a condition or action has an id that's a string, that means
    // it's a temporary uuid so we want to delete them before we send the
    // data through the update request
    if (typeof hashAttributes.id === 'string' && shouldDeleteStringIds) {
      const copyHashAttributes = { ...hashAttributes };

      delete copyHashAttributes.id;

      return copyHashAttributes;
    }

    return hashAttributes;
  };

  const transformRulesToParams = useCallback(
    ({ shouldDeleteStringIds }: { shouldDeleteStringIds: boolean }) => {
      if (
        stage.type === 'RuleStage' ||
        stage.type === 'CustomStage' ||
        stage.type === 'AssessmentStage'
      ) {
        const copyRulesAttributes = Object.values(rules);
        const transformedRulesAttributes: RuleAttributes[] = [];

        copyRulesAttributes.forEach(ruleAttr => {
          transformedRulesAttributes.push({
            ...ruleAttr,
            action_set_attributes: {
              ...ruleAttr.action_set_attributes,
              actions_attributes:
                ruleAttr.action_set_attributes.actions_attributes?.map(
                  action =>
                    transformAttributes(
                      action,
                      shouldDeleteStringIds,
                    ) as Action,
                ),
            },
            condition_set_attributes: {
              ...ruleAttr.condition_set_attributes,
              conditions_attributes:
                ruleAttr.condition_set_attributes.conditions_attributes?.map(
                  condition =>
                    transformAttributes(
                      condition,
                      shouldDeleteStringIds,
                    ) as Condition,
                ),
            },
          });
        });

        const undestroyedRules = transformedRulesAttributes.filter(
          rule => !rule._destroy,
        );
        const shouldDestroyRuleSet = undestroyedRules.length === 0;

        return {
          ...stage.additional_info?.rule_set_attributes,
          ...(shouldDestroyRuleSet && { _destroy: true }),
          rules_attributes: transformedRulesAttributes,
        };
      }

      if (
        stage.type === 'DocumentSigningStage' ||
        stage.type === 'DocumentSignatureStage'
      ) {
        const copyRulesAttributes = Object.values(documentSigningRules);
        const transformedRulesAttributes: DocumentSigningStagesRuleAttributes[] =
          [];

        copyRulesAttributes.forEach(ruleAttr => {
          transformedRulesAttributes.push({
            ...ruleAttr,
            template_set_attributes: {
              ...ruleAttr.template_set_attributes,
              template_settings_attributes:
                ruleAttr.template_set_attributes.template_settings_attributes?.map(
                  templateSetting =>
                    transformAttributes(
                      templateSetting,
                      shouldDeleteStringIds,
                    ) as DocumentSigningStagesTemplateSetting,
                ),
            },
            condition_set_attributes: {
              ...ruleAttr.condition_set_attributes,
              conditions_attributes:
                ruleAttr.condition_set_attributes.conditions_attributes?.map(
                  condition =>
                    transformAttributes(
                      condition,
                      shouldDeleteStringIds,
                    ) as Condition,
                ),
            },
          });
        });

        const undestroyedRules = transformedRulesAttributes.filter(
          rule => !rule._destroy,
        );
        const shouldDestroyRuleSet = undestroyedRules.length === 0;

        return {
          ...stage.additional_info?.template_rule_set_attributes,
          ...(shouldDestroyRuleSet && { _destroy: true }),
          rules_attributes: transformedRulesAttributes,
        };
      }

      if (stage.type === 'PartnerStage') {
        const copyRulesAttributes = Object.values(partnerRules);
        const transformedRulesAttributes: PartnerRuleAttributes[] = [];

        copyRulesAttributes.forEach(partnerRuleAttr => {
          transformedRulesAttributes.push({
            ...partnerRuleAttr,
            action_attributes: transformAttributes(
              partnerRuleAttr.action_attributes,
              shouldDeleteStringIds,
            ) as Action,
            condition_attributes: transformAttributes(
              partnerRuleAttr.condition_attributes,
              shouldDeleteStringIds,
            ) as Condition,
          });
        });

        const undestroyedRules = transformedRulesAttributes.filter(
          rule => !rule._destroy,
        );
        const shouldDestroyRuleSet = undestroyedRules.length === 0;

        return {
          ...stage.additional_info?.rule_set_attributes,
          ...(shouldDestroyRuleSet && { _destroy: true }),
          rules_attributes: transformedRulesAttributes,
        };
      }
      return {
        rules_attributes: [],
      };
    },
    [stage, rules, documentSigningRules, partnerRules],
  );

  const transformQuestions = (questions: VideoRecordingStageQuestion[]) => {
    return questions.map(question => {
      if (typeof question.id === 'string') {
        return {
          body: question.body,
          max_length: question.max_length,
          _destroy: question._destroy,
        };
      }
      return question;
    });
  };

  // Whenever updateStageResult.data is present, we want to start comparing
  // against that instead of stageResult.data
  const currentStageResult =
    updateStageResult.status === 'ready' &&
    updateStageResult.data.external_id === stage.external_id
      ? updateStageResult
      : stageResult.status === 'ready'
      ? stageResult
      : null;

  const onSaveChanges = () => {
    if (stageResult.status !== 'ready') {
      return;
    }

    const ruleSetKey =
      stage.type === 'DocumentSignatureStage' ||
      stage.type === 'DocumentSigningStage'
        ? 'template_rule_set_attributes'
        : 'rule_set_attributes';

    const stageDataUpdateParams: StageUpdateParams = {
      ...stage,
      move_rule_attributes: stage.move_rule,
      ...(stage.landing_template && {
        landing_template_attributes: stage.landing_template,
      }),
      ...(stage.confirmation_template && {
        confirmation_template_attributes: stage.confirmation_template,
      }),
      ...(stage.cancellation_template && {
        cancellation_template_attributes: stage.cancellation_template,
      }),
      ...(stage.availability_template && {
        availability_template_attributes: stage.availability_template,
      }),
      ...(stage.reason_settings?.length && {
        reason_settings_attributes: generateSettingsAttributes(
          stage.reason_settings,
          currentStageResult?.data.reason_settings,
        ),
      }),
      ...(stage.reminder_settings?.length && {
        reminder_settings_attributes: generateSettingsAttributes(
          stage.reminder_settings,
          currentStageResult?.data.reminder_settings,
        ),
      }),
      ...(stage.trigger_settings?.length
        ? {
            trigger_settings_attributes: generateSettingsAttributes(
              stage.trigger_settings,
              currentStageResult?.data.trigger_settings,
            ),
          }
        : {}),
      ...(stage.type === 'ReviewStage' && {
        hiring_manager_instructions:
          stage.additional_info.hiring_manager_instructions,
        // when there has been a delete action its far easier to remap the positions
        // here right before sending to the BE vs trying to manage it through the immer
        // state and account for create's etc that may of occured before/after the deletes
        data_fields_attributes: stage.additional_info.review_questionnaire.map(
          (dataField, idx) => ({
            ...dataField,
            position: idx,
          }),
        ),
      }),
      ...(stage.type === 'TechCheckStage' && {
        customer_support_attributes: stage.additional_info.customer_support,
        settings_attributes: stage.additional_info.settings.map(
          setting => transformAttributes(setting) as TechCheckStageSetting,
        ),
      }),
      ...(stage.type === 'SchedulerV2Stage' &&
        stage.additional_info.session_detail && {
          session_detail_attributes: {
            ...stage.additional_info.session_detail,
            calendar_scheduler_stages_session_details_attributes:
              stage.additional_info.session_detail.calendar_scheduler_stages_session_details?.map(
                calendarSchedulerStagesSessionDetail => ({
                  _destroy: calendarSchedulerStagesSessionDetail._destroy,
                  available: calendarSchedulerStagesSessionDetail.available,
                  calendar_id: calendarSchedulerStagesSessionDetail.calendar_id,
                  id: calendarSchedulerStagesSessionDetail.id,
                }),
              ),
          },
        }),
      // The extra and additional_info can contain the same attributes so order matters.
      // We'll likely need to refactor this on the backend + frontend
      ...stage.extra,
      // Bogus typecast here. W4 stages don't have `additional_info` so we're doing some hand-waving
      ...((stage as WorkflowCustomStage).additional_info ?? {}),
      ...stage.advanced_settings,
      ...(stage.type === 'SchedulerStage' && {
        restricted_locations_attributes:
          stage.additional_info.restricted_locations_attributes?.map(
            restrictedLocation => ({
              ...restrictedLocation,
              id:
                typeof restrictedLocation.id === 'string' // do not set id if it's a string
                  ? undefined
                  : restrictedLocation.id,
            }),
          ),
      }),
      // Handle Partner, Document Signing, and Regular Rules differently with a dynamic ruleSetKey
      [ruleSetKey]: transformRulesToParams({
        shouldDeleteStringIds: true,
      }),
      ...((stage.type === 'DocumentSignatureStage' ||
        stage.type === 'DocumentSigningStage') &&
        stage.additional_info.else_template_set_attributes && {
          else_template_set_attributes: transformAttributes(
            stage.additional_info.else_template_set_attributes,
          ),
        }),
      ...(stage.type === 'VideoRecordingStage' &&
        stage.additional_info.questions && {
          questions_attributes: transformQuestions(
            stage.additional_info.questions,
          ),
        }),
      ...(stage.type === 'DistributeApplicantsRuleStage' &&
        stage.additional_info.distribute_rules && {
          distribute_rules_attributes:
            stage.additional_info.distribute_rules.map(
              distRule => transformAttributes(distRule, true) as DistributeRule,
            ),
        }),
      ...((stage.type === 'JobSelectorStage' ||
        stage.type === 'JobSwitcherStage') &&
        stage.additional_info.conditions && {
          funnel_choice_rule_attributes: {
            conditions: stage.additional_info.conditions,
          },
        }),
      checkr_geo:
        stage.type === 'BackgroundCheckerStage'
          ? JSON.stringify(stage.additional_info?.checkr_geo) // This is saved as a json string on the BE -_-
          : undefined,
    };

    // Explicitly avoid updating the stage#position value on "Save Changes" submit
    // This causes side effects with the sidebar drag-n-drop reordering which already handles this.
    delete stageDataUpdateParams.position;

    updateStage(opening.slug, externalId, {
      workflow_stage: { stage: stageDataUpdateParams },
    });
  };

  useEffect(() => {
    if (updateStageResult.status !== 'idle') {
      resetUpdateStageResult();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalId]);

  // Check if the form is Dirty
  useEffect(() => {
    if (stageResult.status !== 'ready' || !stage.external_id) {
      return;
    }

    const rulesParams = transformRulesToParams({
      shouldDeleteStringIds: false,
    });

    const isStageDirty = !_isEqual(stage, currentStageResult?.data);

    const defaultRulesAttributes = {
      rules_attributes: [DEFAULT_RULE],
    };

    let ruleSetAttributes;

    if (
      stage.type === 'DocumentSigningStage' ||
      stage.type === 'DocumentSignatureStage'
    ) {
      ruleSetAttributes = stage.additional_info.template_rule_set_attributes;
    } else if (
      stage.type === 'RuleStage' ||
      stage.type === 'CustomStage' ||
      stage.type === 'PartnerStage' ||
      stage.type === 'AssessmentStage'
    ) {
      ruleSetAttributes = stage.additional_info.rule_set_attributes;
    }

    const isRulesDirty =
      ruleSetAttributes || rulesParams.rules_attributes.length > 0
        ? !_isEqual(ruleSetAttributes ?? defaultRulesAttributes, rulesParams)
        : false;

    setIsStageTitleDirty(stage.title !== currentStageResult?.data.title);
    setIsDirty(isStageDirty || isRulesDirty);
  }, [currentStageResult?.data, stage, stageResult, transformRulesToParams]);

  // Reset Error Messages if reverting back to clean initial state
  useEffect(() => {
    if (updateStageResult.isError && !isDirty) {
      resetUpdateStageResult();
    }
  }, [updateStageResult, resetUpdateStageResult, isDirty]);

  // Initialize Stage Data
  useEffect(() => {
    if (stageResult.status !== 'ready') {
      return;
    }

    setStageAndTransformationStates(stageResult.data);
  }, [stageResult]);

  // Re-initialize Stage Data after update
  useEffect(() => {
    if (updateStageResult.status !== 'ready') {
      return;
    }

    setStageAndTransformationStates(updateStageResult.data);
  }, [updateStageResult]);

  useEffect(() => {
    if (stageResult.isError && stageResult?.error === 'Forbidden') {
      dispatch(
        addMessageAction(intl.formatMessage(messages.forbiddenError), 'error'),
      );
      history.replace(`/${accountSlug}/openings`);
    } else if (stageResult.isError) {
      dispatch(
        addMessageAction(intl.formatMessage(messages.apiError), 'error', false),
      );
    }
  }, [dispatch, intl, stageResult, history, accountSlug]);

  const hasStagesData =
    stageResult.status === 'ready' || stageResult.status === 'reloading';

  return (
    <>
      {withSidebar && (
        <StagesSidebar
          isStageAdditionBlocked={isDirty}
          onDiscardChanges={onDiscardChanges}
          opening={props.opening}
          refetchStages={props.refetchStages}
          selectedStage={props.selectedStage}
          stages={funnelStages}
          unsupportedAxStages={props.unsupportedAxStages}
        />
      )}

      {stageResult.isError && null}

      {stageResult.status === 'loading' && <Loader fullScreen size="2rem" />}

      {hasStagesData && (
        <StageContext.Provider
          value={{
            isDirty,
            isStageTitleDirty,
            funnelStages,
            onDiscardChanges,
            onSaveChanges,
            partnerRules,
            documentSigningRules,
            refetchStage,
            rules,
            setIsDirty,
            setRules,
            setPartnerRules,
            setDocumentSigningRules,
            setStage,
            stage,
            updateStageResult,
            resetUpdateStageResult,
          }}
        >
          {children}
          {stageResult.status === 'reloading' && (
            <Loader fullScreen size="2rem" />
          )}
          <UnsavedChangesPrompt
            key={stage.external_id}
            isMultiStage={stage.type === 'MultiStage'}
            isNavigationBlocked={isDirty}
          />
        </StageContext.Provider>
      )}
    </>
  );
};
