import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.common.white,
  },
  content: {
    flex: 1,
    padding: theme.spacing(4, 10.5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
}));
