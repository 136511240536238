import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  availability,
  convertDateStringToDateSelect,
  generateAvailabilityTimes,
} from '../../utils';
import {
  DateSelect,
  DateSelectProps,
  useSetAvailabilitiesParams,
} from '../types';

export const useSetAvailabilities = ({
  minDate,
  availableSlot,
  handleChange,
}: useSetAvailabilitiesParams) => {
  const { search } = useLocation();
  const [endTime, setEndTime] = useState<DateSelectProps>({
    label: '',
    value: '',
    hours: '',
  });
  const [startTime, setStartTime] = useState<DateSelectProps>({
    label: '',
    value: '',
    hours: '',
  });
  const initialDate = availableSlot.start_time
    ? DateTime.fromISO(availableSlot.start_time)
        .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone, {
          keepLocalTime: true,
        })
        .toJSDate()
    : minDate;
  const [availDate, setAvailDate] = useState(initialDate);

  const [availableStartTimes, setAvailableStartTimes] = useState<
    DateSelectProps[]
  >([]);
  const [availableEndTimes, setAvailableEndTimes] = useState<DateSelectProps[]>(
    [],
  );

  const updateAvailabilityTimes = useCallback(
    (date: Date, startTime: string) => {
      const { currentAvailabilityTimes, endDateTime } = availability(
        date,
        startTime,
      );
      const currentAvailabilityEndTimes = generateAvailabilityTimes(
        endDateTime,
        15,
      );
      setAvailableStartTimes(currentAvailabilityTimes.slice(0, -1));
      setAvailableEndTimes(currentAvailabilityEndTimes);
    },
    [],
  );

  const setAvailabilityTimes = useCallback(
    (startTime: DateSelectProps, endTime: DateSelectProps) => {
      setStartTime(startTime);
      setEndTime(endTime);

      handleChange({
        start_time: startTime.value,
        end_time: endTime.value,
      });
    },
    [handleChange],
  );

  const getParamsFromSearch = useCallback((search: string) => {
    const params = new URLSearchParams(search);
    return {
      receivedStartTime: params.get('startTime') ?? '',
      receivedEndTime: params.get('endTime') ?? '',
    };
  }, []);

  const calculateReceivedAvailability = useCallback(
    (receivedStartTime: string, receivedEndTime: string) => {
      const receivedStartDateTime = DateTime.fromISO(receivedStartTime);
      const receivedStartDate = receivedStartDateTime.toJSDate();

      const receivedEndDateTime = DateTime.fromISO(receivedEndTime);
      const minuteDiff = receivedEndDateTime
        .diff(receivedStartDateTime, 'minutes')
        .toObject().minutes;
      const currentAvailabilityTimes = generateAvailabilityTimes(
        DateTime.fromISO(receivedStartTime),
        minuteDiff ?? 15,
      );
      const currentStartTime = currentAvailabilityTimes[0];
      const currentEndTime =
        currentAvailabilityTimes[currentAvailabilityTimes.length > 1 ? 1 : 0];

      setAvailDate(receivedStartDate);

      return { currentStartTime, currentEndTime };
    },
    [],
  );

  useEffect(() => {
    updateAvailabilityTimes(availDate, startTime.value);
  }, [availDate, updateAvailabilityTimes, startTime.value]);

  useEffect(() => {
    const { receivedStartTime, receivedEndTime } = getParamsFromSearch(search);

    const setDefaultAvailability = () => {
      const { currentAvailabilityTimes, endDateTime } = availability(
        availDate,
        startTime.value,
      );
      const currentAvailabilityEndTimes = generateAvailabilityTimes(
        endDateTime,
        15,
      );

      const currentStartTime: DateSelect = availableSlot?.start_time
        ? convertDateStringToDateSelect(availableSlot?.start_time)
        : currentAvailabilityTimes[0];
      const currentEndTime: DateSelect = availableSlot?.end_time
        ? convertDateStringToDateSelect(availableSlot?.end_time)
        : currentAvailabilityEndTimes[
            currentAvailabilityEndTimes.length > 1 ? 1 : 0
          ];

      setAvailabilityTimes(currentStartTime, currentEndTime);
    };
    const setReceivedAvailability = () => {
      const { currentStartTime, currentEndTime } =
        calculateReceivedAvailability(receivedStartTime, receivedEndTime);
      setAvailabilityTimes(currentStartTime, currentEndTime);
    };
    if (availDate < minDate) {
      setAvailDate(minDate);
    }

    if (receivedStartTime && receivedEndTime) {
      setReceivedAvailability();
    } else {
      setDefaultAvailability();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return {
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    availDate,
    setAvailDate,
    availableStartTimes,
    setAvailableStartTimes,
    availableEndTimes,
    setAvailableEndTimes,
  };
};
