import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import { getCurrentFunnelSlug } from 'containers/ApplicantsV2/selectors';
import {
  makeSelectLastFunnelSlug,
  makeSelectWhoami,
} from 'containers/Auth_old/selectors';

import NavLink from '../NavLink';
import messages from './messages';

export default function CalendarNavLink() {
  const {
    /* eslint-disable-next-line camelcase */
    feature_flags,
    /* eslint-disable-next-line camelcase */
    any_restricted_source,
  } = useSelector(makeSelectWhoami());
  const { accountSlug, stageExternalId, brandSlug, locationId, jobId } =
    useParams();
  const { pathname } = useLocation();
  const isApplicantTableV2 =
    pathname ===
    (`/${accountSlug}/jobs/${jobId}/v2/stages/${stageExternalId}` ||
      `/${accountSlug}/brands/${brandSlug}/locations/${locationId}/jobs/${jobId}/v2/stages/${stageExternalId}`);

  const currentFunnelSlug = useSelector(getCurrentFunnelSlug());
  const lastFunnelSlug = useSelector(makeSelectLastFunnelSlug());
  const funnelSlug = currentFunnelSlug || lastFunnelSlug;
  /* eslint-disable-next-line camelcase */
  const urlSuffix = feature_flags['calendar-zero-schedule-tab']
    ? 'schedule'
    : 'calendar';

  const calendarUrl = isApplicantTableV2
    ? `/${accountSlug}/${urlSuffix}?funnel=${funnelSlug}`
    : `/${accountSlug}/${urlSuffix}`;

  const regEx = new RegExp(`/([^/]*)/${urlSuffix}$`);
  const active = regEx.test(window.location.pathname);

  /* eslint-disable-next-line camelcase */
  if (any_restricted_source) {
    return null;
  }

  return (
    <>
      {
        /* eslint-disable-next-line camelcase */
        feature_flags['calendar-zero-schedule-tab'] ? (
          <NavLink
            active={active}
            link={calendarUrl}
            message={messages.schedule}
            dataTestid="calendar"
          />
        ) : (
          <NavLink
            active={active}
            link={`${REACT_APP_MONOLITH_BASE_URL}/${calendarUrl}`}
            message={messages.calendar}
            dataTestid="calendar"
            external
          />
        )
      }
    </>
  );
}
