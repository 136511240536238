/*
 *
 * ApplicantUploadFileModal actions
 *
 */

import {
  DELETE_FILE,
  SET_FILE,
  SHOULD_FETCH_DISABLED,
  SHOULD_FETCH_ENABLED,
  SUBMIT_UPLOAD,
  SUBMIT_UPLOAD_ERROR,
  SUBMIT_UPLOAD_SUCCESS,
} from './constants';

export function setFile(file) {
  return {
    type: SET_FILE,
    file,
  };
}

export function submitUploadAction({ applicantId, file, key }) {
  return {
    type: SUBMIT_UPLOAD,
    applicantId,
    file,
    key,
  };
}

export function submitUploadSuccessAction() {
  return {
    type: SUBMIT_UPLOAD_SUCCESS,
  };
}

export function submitUploadErrorAction(error) {
  return {
    type: SUBMIT_UPLOAD_ERROR,
    error,
  };
}

export function deleteFile() {
  return {
    type: DELETE_FILE,
  };
}
export function shouldFetchEnabledAction() {
  return {
    type: SHOULD_FETCH_ENABLED,
  };
}
export function shouldFetchDisabledAction() {
  return {
    type: SHOULD_FETCH_DISABLED,
  };
}
