/*
 *
 * SendEmailPopup reducer
 *
 */
import produce from 'immer';

import { FETCH_MEMBERS_INIT, FETCH_MEMBERS_SUCCESS } from './constants';

export const initialState = {
  sender_id: null,
  users: [],
};

const sendEmailPopupReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case FETCH_MEMBERS_INIT:
        draft.isLoading = true;
        break;
      case FETCH_MEMBERS_SUCCESS:
        draft.users = action.users;
        draft.isLoading = false;
        break;
      default:
        break;
    }
  });

export default sendEmailPopupReducer;
