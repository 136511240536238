import { Environment, subdomainEnvironment } from './environments';

export interface DomainMeta {
  environment: Environment;
  monolithOrigin: string;
  tenant: string;
}

export const inferDomainMeta = (hostname: string): DomainMeta => {
  if (hostname === 'app.fountain.com') {
    return {
      environment: 'prod',
      monolithOrigin: 'https://web.fountain.com',
      tenant: 'fountain',
    };
  }

  if (hostname === 'app.uat-01.fountain.com') {
    return {
      environment: 'stage',
      monolithOrigin: 'https://uat-01.fountain.com',
      tenant: 'uat-01',
    };
  }

  if (
    hostname.startsWith('app.') &&
    (hostname.endsWith('.fountain.com') ||
      hostname.endsWith('.tryfountain.com'))
  ) {
    return {
      environment: subdomainEnvironment(hostname),
      monolithOrigin: `https://${hostname.slice(4)}`,
      tenant: hostname.slice(4).split('.')[0],
    };
  }

  if (hostname.endsWith('.chromatic.com')) {
    return {
      environment: 'stage',
      monolithOrigin: 'http://localhost:3000',
      tenant: 'storybook',
    };
  }

  if (hostname === 'localhost' && process.env.DEV_SERVER_URL) {
    return {
      environment: 'dev',
      monolithOrigin: process.env.DEV_SERVER_URL,
      tenant: 'local',
    };
  }

  if (hostname === 'localhost' && process.env.NODE_ENV === 'development') {
    return {
      environment: 'dev',
      monolithOrigin: 'http://localhost:3000',
      tenant: 'local',
    };
  }

  // TODO: Merge the previous and following if statements and use ENV variables.
  if (hostname === 'localhost' && process.env.NODE_ENV === 'test') {
    return {
      environment: 'dev',
      monolithOrigin: 'http://localhost:3023',
      tenant: 'local',
    };
  }

  throw new Error('Unable to map hostname to a known domain');
};

export const domainMeta = inferDomainMeta(window.location.hostname);
