import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  advancedSettings: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.CustomStage.AdvancedSettings.advancedSettings`,
    defaultMessage: 'Advanced Settings',
  },
  allowApplicantsToAdvance: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.CustomStage.AdvancedSettings.allowApplicantsToAdvance`,
    defaultMessage:
      'Allow applicants to self advance by using the merge key [ADVANCE_URL]',
  },
  recipient: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.CustomStage.AdvancedSettings.recipient`,
    defaultMessage: 'Recipient',
  },
  sendAnEmail: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.CustomStage.AdvancedSettings.sendAnEmail`,
    defaultMessage: 'Send an email',
  },
  accountOwner: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.CustomStage.AdvancedSettings.accountOwner`,
    defaultMessage: 'Account owner',
  },
});
