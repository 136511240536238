/* eslint-disable global-require */

// https://formatjs.io/docs/polyfills
// These polyfills must be in this particular order

// We don't require polyfills in our testing environment, though we should add
// coverage at some point to ensure browsers which _do_ are indeed loading them
/* istanbul ignore if */
if (!Intl.getCanonicalLocales) {
  require('@formatjs/intl-getcanonicallocales/polyfill');
}

/* istanbul ignore if */
if (!Intl.Locale) {
  require('@formatjs/intl-locale/polyfill');
}

/* istanbul ignore if */
if (!Intl.DisplayNames) {
  require('@formatjs/intl-displaynames/polyfill');
  require('@formatjs/intl-displaynames/locale-data/en.js');
  require('@formatjs/intl-displaynames/locale-data/es.js');
  require('@formatjs/intl-displaynames/locale-data/fr.js');
  require('@formatjs/intl-displaynames/locale-data/de.js');
}

/* istanbul ignore if */
if (!Intl.ListFormat) {
  require('@formatjs/intl-listformat/polyfill');
  require('@formatjs/intl-listformat/locale-data/en.js');
  require('@formatjs/intl-listformat/locale-data/es.js');
  require('@formatjs/intl-listformat/locale-data/fr.js');
  require('@formatjs/intl-listformat/locale-data/de.js');
}

/* istanbul ignore if */
if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/locale-data/en.js');
  require('@formatjs/intl-pluralrules/locale-data/es.js');
  require('@formatjs/intl-pluralrules/locale-data/fr.js');
  require('@formatjs/intl-pluralrules/locale-data/de.js');
}

/* istanbul ignore if */
if (!Intl.NumberFormat) {
  require('@formatjs/intl-numberformat/polyfill');
  require('@formatjs/intl-numberformat/locale-data/en.js');
  require('@formatjs/intl-numberformat/locale-data/es.js');
  require('@formatjs/intl-numberformat/locale-data/fr.js');
  require('@formatjs/intl-numberformat/locale-data/de.js');
}

/* istanbul ignore if */
if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/locale-data/en.js');
  require('@formatjs/intl-relativetimeformat/locale-data/es.js');
  require('@formatjs/intl-relativetimeformat/locale-data/fr.js');
  require('@formatjs/intl-relativetimeformat/locale-data/de.js');
}

/* istanbul ignore if */
if (!Intl.DateTimeFormat) {
  require('@formatjs/intl-datetimeformat/polyfill');
  require('@formatjs/intl-datetimeformat/locale-data/en.js');
  require('@formatjs/intl-datetimeformat/locale-data/es.js');
  require('@formatjs/intl-datetimeformat/locale-data/fr.js');
  require('@formatjs/intl-datetimeformat/locale-data/de.js');
  require('@formatjs/intl-datetimeformat/add-golden-tz.js');
}
