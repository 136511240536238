import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import {
  getConditionErrorMessage,
  NORTHPASS_COURSE_PROGRESS,
  NORTHPASS_QUIZ_SCORE,
} from 'containers/WorkflowEditor/components/Rules/Condition/constants';
import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';

import { DocumentSigningRuleProps } from '../../../DocumentSigningRules/types';
import { RulesProps, SharedConditionProps } from '../../../types';
import { ResourceOption } from '../constants';
import { messages } from '../messages';

export const NorthpassSelect: FC<SharedConditionProps> = ({
  condition,
  ruleId,
  setRules,
  setDocumentSigningRules,
  errors,
}) => {
  const intl = useIntl();

  const { extra } = condition;

  const { schoolkeep_options: schoolkeepOptions } =
    useContext(RulesEditDataContext);

  let options: { label: string; value: string }[] | undefined = [];
  let selectValueKey: string | null | undefined = null;
  let errorMessage: string | boolean | undefined;

  if (condition.type === NORTHPASS_COURSE_PROGRESS) {
    options = schoolkeepOptions?.courses.map(course => ({
      label: course.title,
      value: course.id,
    }));

    selectValueKey = extra.course_id;

    errorMessage =
      !extra?.course_id && getConditionErrorMessage('course_id', errors);
  } else {
    options = schoolkeepOptions?.quizzes.map(quiz => ({
      label: quiz.title,
      value: quiz.id,
    }));

    selectValueKey = extra.quiz_id;

    errorMessage =
      !extra?.quiz_id && getConditionErrorMessage('quiz_id', errors);
  }

  const selectValue =
    options?.find(option => option.value === selectValueKey) ?? null;

  const onChangeSelect = (newValue: ResourceOption) => {
    const rulesSetter = setRules ?? setDocumentSigningRules;

    if (rulesSetter) {
      rulesSetter(
        produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];
          const idx = draftConditions.findIndex(
            cond => cond.id === condition.id,
          );

          if (condition.type === NORTHPASS_COURSE_PROGRESS) {
            draftConditions[idx].extra.course_id = newValue.value as string;
          }
          if (condition.type === NORTHPASS_QUIZ_SCORE) {
            draftConditions[idx].extra.quiz_id = newValue.value as string;
          }
        }),
      );
    }
  };

  return (
    <StyledReactSelect
      options={options}
      label={
        condition.type === NORTHPASS_COURSE_PROGRESS
          ? intl.formatMessage(messages.course)
          : intl.formatMessage(messages.quiz)
      }
      aria-label={
        condition.type === NORTHPASS_COURSE_PROGRESS
          ? intl.formatMessage(messages.course)
          : intl.formatMessage(messages.quiz)
      }
      onChange={onChangeSelect}
      value={selectValue}
      error={errorMessage}
      placeholder=""
      required
    />
  );
};
