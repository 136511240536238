import { makeStyles } from '@material-ui/core/styles';
/**
 *
 * Material UI styles for MergeKeysSelect
 *
 *
 * @see https://material-ui.com/styles/basics
 *
 */

const useStyles = makeStyles(theme => ({
  mergeKeysSelect: {
    background: theme.palette.common.white,
    borderRadius: 4,
    boxShadow: theme.customShadows.shadow4,
    border: theme.customBorders.gray400,
    position: 'absolute',
    top: theme.spacing(6.5),
    right: 0,
    zIndex: theme.zIndex.dropdownMenu,
  },
  tabsRoot: {
    width: '100%',
    minHeight: 'unset',
    borderBottom: theme.customBorders.gray200,
  },
  tabsFlexContainer: {
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2),
  },
  tabRoot: {
    minWidth: 'unset',
    minHeight: 'unset',
    padding: theme.spacing(1.5, 0),
    flex: 1,
    textTransform: 'capitalize',
  },
  keysContainer: {
    margin: theme.spacing(1, 0),
    [theme.breakpoints.up('md')]: {
      maxHeight: 261,
      overflowY: 'scroll',
    },
  },
  key: {
    padding: theme.spacing(1.5, 3),
    borderRadius: 3,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.common.gray100,
    },
    '& p': {
      flex: 1,
      minWidth: 0,
    },
  },
}));

export default useStyles;
