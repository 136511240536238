import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  past: {
    id: 'app.Calendar.EventManager.Header.past',
    defaultMessage: 'Past',
  },
  today: {
    id: 'app.Calendar.EventManager.Header.today',
    defaultMessage: 'Today',
  },
  upcoming: {
    id: 'app.Calendar.EventManager.Header.upcoming',
    defaultMessage: 'Upcoming',
  },
  createEvent: {
    id: 'app.Calendar.EventManager.Header.createEvent',
    defaultMessage: 'Create Event',
  },
  filtersApplied: {
    id: 'app.Calendar.EventManager.Header.filtersApplied',
    defaultMessage: 'filters applied',
  },
  openFilters: {
    id: 'app.Calendar.EventManager.Header.openFilters',
    defaultMessage: 'open filters',
  },
  export: {
    id: 'app.Calendar.EventManager.Header.export',
    defaultMessage: 'Export',
  },
  exportSuccess: {
    id: 'app.Calendar.EventManager.Header.exportSuccess',
    defaultMessage: 'Your export will be emailed to you shortly',
  },
});
