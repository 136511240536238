import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

import FountainIcon from 'images/fountain-icon.svg';

import useStyles from '../styles';
import ApplicantsNavMenu from './ApplicantsNavMenu';
import JobsNavMenu from './JobsNavMenu';
import ProfileMenu from './ProfileMenu';
import Search from './Search';

const GlobalNavMobile = ({ handleQueryChange, query }) => {
  const classes = useStyles();
  const { accountSlug } = useParams();
  const openingsPath = `/${accountSlug}/openings`;

  return (
    <Grid
      container
      direction="row"
      // wrap="nowrap"
      alignItems="center"
      justify="space-between"
      className={classes.containerMobile}
    >
      {/* Logo */}
      <Grid item>
        <RouterLink
          to={openingsPath}
          className={classes.fountainLogoMobile}
          data-testid="navbar-logo"
        >
          <img src={FountainIcon} alt="fountain logo" />
        </RouterLink>
      </Grid>

      {/* Middle items */}
      <Grid className={classes.leftSectionMobile} item>
        <JobsNavMenu />
        <ApplicantsNavMenu />
      </Grid>

      {/* Right items */}
      <Grid item container className={classes.rightSectionMobile}>
        <Search handleQueryChange={handleQueryChange} query={query} />
        <ProfileMenu />
      </Grid>
    </Grid>
  );
};

GlobalNavMobile.propTypes = {
  handleQueryChange: PropTypes.func,
  query: PropTypes.string,
};

export default GlobalNavMobile;
