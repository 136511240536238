import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addDownloadSpeed: {
    id: 'app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.TechCheckStage.Requirements.RequirementCard.addDownloadSpeed',
    defaultMessage: `Add download speed`,
  },
  addHeight: {
    id: 'app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.TechCheckStage.Requirements.RequirementCard.addHeight',
    defaultMessage: `Add height`,
  },
  addProcessor: {
    id: 'app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.TechCheckStage.Requirements.RequirementCard.addProcessor',
    defaultMessage: `Add processor`,
  },
  addRam: {
    id: 'app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.TechCheckStage.Requirements.RequirementCard.addRam',
    defaultMessage: `Add RAM`,
  },
  addSpeed: {
    id: 'app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.TechCheckStage.Requirements.RequirementCard.addSpeed',
    defaultMessage: `Add speed`,
  },
  addUploadSpeed: {
    id: 'app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.TechCheckStage.Requirements.RequirementCard.addUploadSpeed',
    defaultMessage: `Add upload speed`,
  },
  addVersion: {
    id: 'app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.TechCheckStage.Requirements.RequirementCard.addVersion',
    defaultMessage: `Add version`,
  },
  addWidth: {
    id: 'app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.TechCheckStage.Requirements.RequirementCard.addWidth',
    defaultMessage: `Add width`,
  },
  baselineTitle: {
    id: 'app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.TechCheckStage.Requirements.RequirementCard.baselineTitle',
    defaultMessage: `Baseline Tier - Minimum Requirements Check`,
  },
  browserHeading: {
    id: 'app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.TechCheckStage.Requirements.RequirementCard.browserHeading',
    defaultMessage: `Browser Version (ex. 89.0.4389.114)`,
  },
  browserVersionMustExist: {
    id: 'app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.TechCheckStage.Requirements.RequirementCard.browserVersionMustExist',
    defaultMessage: `Must define at least one browser version`,
  },
  deleteTier: {
    id: 'app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.TechCheckStage.Requirements.RequirementCard.deleteTier',
    defaultMessage: `Delete Tier`,
  },
  description: {
    id: 'app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.TechCheckStage.Requirements.RequirementCard.description',
    defaultMessage: `The tech check runs an automatic test of the applicant's system based on the defined minimum requirements.`,
  },
  downloadSpeed: {
    id: 'app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.TechCheckStage.Requirements.RequirementCard.downloadSpeed',
    defaultMessage: `Download Speed (Mbps)`,
  },
  internetHeading: {
    id: 'app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.TechCheckStage.Requirements.RequirementCard.internetHeading',
    defaultMessage: `Internet speed`,
  },
  hardwareHeading: {
    id: 'app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.TechCheckStage.Requirements.RequirementCard.hardwareHeading',
    defaultMessage: `Hardware Specs`,
  },
  macOS: {
    id: 'app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.TechCheckStage.Requirements.RequirementCard.macOS',
    defaultMessage: `MacOS`,
  },
  macOSTooltip: {
    id: 'app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.TechCheckStage.Requirements.RequirementCard.macOSTooltip',
    defaultMessage: `Only MacOS up to 10.15.7 can be detected in the applicant's browser.`,
  },
  operatingSystemHeading: {
    id: 'app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.TechCheckStage.Requirements.RequirementCard.operatingSystemHeading',
    defaultMessage: `Operating System Version`,
  },
  operatingSystemVersionMustExist: {
    id: 'app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.TechCheckStage.Requirements.RequirementCard.operatingSystemVersionMustExist',
    defaultMessage: `Must define at least one operating system version`,
  },
  processor: {
    id: 'app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.TechCheckStage.Requirements.RequirementCard.processor',
    defaultMessage: `Processor`,
  },
  ram: {
    id: 'app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.TechCheckStage.Requirements.RequirementCard.ram',
    defaultMessage: `RAM (GB)`,
  },
  screenResolution: {
    id: 'app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.TechCheckStage.Requirements.RequirementCard.screenResolution',
    defaultMessage: `Screen Resolution`,
  },
  screenHeight: {
    id: 'app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.TechCheckStage.Requirements.RequirementCard.screenHeight',
    defaultMessage: `Screen Height`,
  },
  screenWidth: {
    id: 'app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.TechCheckStage.Requirements.RequirementCard.screenWidth',
    defaultMessage: `Screen Width`,
  },
  speed: {
    id: 'app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.TechCheckStage.Requirements.RequirementCard.speed',
    defaultMessage: `Speed (GHz)`,
  },
  uploadSpeed: {
    id: 'app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.TechCheckStage.Requirements.RequirementCard.uploadSpeed',
    defaultMessage: `Upload Speed (Mbps)`,
  },
});
