import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  assessment: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.AssessmentStage.AssessmentCard.assessment',
    defaultMessage: 'Assessment',
  },
  assessmentForm: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.AssessmentStage.AssessmentCard.assessmentForm',
    defaultMessage: 'Assessment Form',
  },
  createOrManageAssessments: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.AssessmentStage.AssessmentCard.createOrManageAssessments',
    defaultMessage: 'Create or manage assessment',
  },
  editAssessment: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.AssessmentStage.AssessmentCard.editAssessment',
    defaultMessage: 'Edit assessment',
  },
  manageAssessments: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.AssessmentStage.AssessmentCard.manageAssessments',
    defaultMessage: 'Manage assessments',
  },
  manageOrAssign: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.AssessmentStage.AssessmentCard.manageOrAssign',
    defaultMessage: 'Manage or assign a different assessment',
  },
  missingAssessment: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.AssessmentStage.AssessmentCard.missingAssessment',
    defaultMessage:
      'An assessment needs to be set up in order to use this stage.',
  },
  shouldAutoAdvanceWhenLabelsComplete: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.AssessmentStage.AssessmentCard.shouldAutoAdvanceWhenLabelsComplete',
    defaultMessage:
      'Automatically move applicant to next stage when all labels are completed',
  },
  showOneQuestionAtATime: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.AssessmentStage.AssessmentCard.showOneQuestionAtATime',
    defaultMessage: 'Show one question at a time',
  },
  showStageInApplicantPortal: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.AssessmentStage.AssessmentCard.showStageInApplicantPortal',
    defaultMessage: 'Show stage in applicant portal progress tracker',
  },
});
