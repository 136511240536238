import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Link, useParams } from 'react-router-dom';

import { StageCard, StageCardProps } from './StageCard';
import { useStyles } from './styles';

type UrlParams = {
  accountSlug: string;
  funnelSlug: string;
};

export const DraggableStageCard: FC<StageCardProps> = ({
  onDiscardChanges,
  refetchStages,
  scheduledApplicants,
  selected,
  stage,
}) => {
  const classes = useStyles();

  const { accountSlug, funnelSlug } = useParams<UrlParams>();

  const isDragDisabled =
    stage.type === 'ArchivedStage' || stage.type === 'RejectedStage';

  return (
    <Draggable
      draggableId={stage.external_id}
      index={stage.position}
      isDragDisabled={isDragDisabled}
    >
      {provided => (
        <Grid
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          component={Link}
          className={classes.stageCardContainer}
          to={`/${accountSlug}/openings/${funnelSlug}/workflow/${stage.slug}`}
        >
          <StageCard
            onDiscardChanges={onDiscardChanges}
            refetchStages={refetchStages}
            scheduledApplicants={scheduledApplicants}
            selected={selected}
            stage={stage}
          />
        </Grid>
      )}
    </Draggable>
  );
};
