import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  dateValue: {
    defaultMessage: 'Date Value',
    id: `app.containers.WorkflowEditor.components.Rules.Action.UpdateApplicantData.FieldByType.dateValue`,
  },
  dropdownValue: {
    defaultMessage: 'Dropdown Value',
    id: `app.containers.WorkflowEditor.components.Rules.Action.UpdateApplicantData.FieldByType.dropdownValue`,
  },
});
