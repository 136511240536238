import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  select: {
    width: theme.spacing(37.5),
  },
  text: {
    paddingBottom: theme.spacing(3),
  },
}));
