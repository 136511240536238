import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import React, { VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Message } from '../types';

const useStyles = makeStyles(theme => ({
  label: {
    fontWeight: 500,
    lineHeight: '20px',
    color: theme.palette.common.gray800,
  },
}));

interface SettingTitleProps {
  value: Message;
  className?: string;
}

export const SettingTitle: VFC<SettingTitleProps> = ({ value, className }) => {
  const styles = useStyles();

  return (
    <Typography variant="h4" className={classnames(styles.label, className)}>
      <FormattedMessage {...value} />
    </Typography>
  );
};
