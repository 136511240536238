import { CustomTooltip } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import classnames from 'classnames';
import React, { VFC } from 'react';
import { useSelector } from 'react-redux';

import {
  makeSelectFeatureChatbotAdminEnabled,
  makeSelectFeatureChatbotReviewEnabled,
} from 'containers/Auth_old/selectors';

import { InterpretationLabel } from './InterpretationLabel';
import { useStyles } from './styles';

export interface BottomLabelsProps {
  intent: string | null;
  interpretation: string | null;
  score?: number;
  confidenceThreshold?: number;
  question?: string;
}

export const BottomLabels: VFC<BottomLabelsProps> = ({
  intent,
  interpretation,
  score,
  confidenceThreshold,
  question,
}) => {
  const styles = useStyles();
  const isFeatureChatbotAdminEnabled = useSelector(
    makeSelectFeatureChatbotAdminEnabled(),
  );

  const isFeatureChatbotReviewEnabled = useSelector(
    makeSelectFeatureChatbotReviewEnabled(),
  );

  const getConfidenceThreshold = (threshold: number) => {
    return threshold / 100;
  };

  return (
    <Grid
      item
      container
      direction="row"
      spacing={1}
      className={styles.labelsWrapper}
    >
      {(intent || question) &&
        (isFeatureChatbotAdminEnabled || isFeatureChatbotReviewEnabled) && (
          <Grid item>
            <Typography
              variant="body2"
              className={classnames(styles.label, styles.classifiedLabel)}
            >
              {isFeatureChatbotAdminEnabled ? intent : question}
            </Typography>
          </Grid>
        )}
      {interpretation && (
        <InterpretationLabel interpretation={interpretation} />
      )}
      {score && confidenceThreshold && (
        <Grid item>
          <CustomTooltip
            title={`Confidence threshold: ${confidenceThreshold}%`}
            dense
          >
            <Typography
              variant="body2"
              className={classnames(styles.label, {
                [styles.lowConfidenceLabel]:
                  score < getConfidenceThreshold(confidenceThreshold),
                [styles.highConfidenceLabel]:
                  score >= getConfidenceThreshold(confidenceThreshold),
              })}
            >
              {(score * 100).toFixed(1)}%
            </Typography>
          </CustomTooltip>
        </Grid>
      )}
    </Grid>
  );
};
