import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    customizationBar: {
      padding: theme.spacing(0, 3),
      borderBottom: theme.customBorders.gray300,
      minHeight: theme.spacing(6),
    },
    setPortalTitle: {
      padding: theme.spacing(1, 0),
    },
  }),
);

export default styles;
