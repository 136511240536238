import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  cancelButton: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
