import 'react-quill/dist/quill.snow.css';
import 'codemirror/lib/codemirror.css';

import { Caption } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import { classNames } from 'react-extras';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import TextEditor from 'components/TextEditor/Loadable';
import { makeIsSmsLengthLimitEnabled } from 'containers/Auth_old/selectors';
import fileRecollectionReducer from 'containers/FileRecollectionDrawer/reducer';
import makeSelectFileRecollectionDrawer from 'containers/FileRecollectionDrawer/selectors';
import EditorActionsToolbar from 'containers/MessageTemplateTextEditor/components/EditorActionsToolbar';
import globalMessages from 'shared/global/messages';
import { useInjectReducer } from 'utils/injectReducer';

import useStyles from './styles';

function MessageTemplateTextEditor({
  enableHtmlModeSwitch,
  enableMergeKeys,
  enableMessageTemplates,
  errorText,
  handleSelectTemplate,
  handleMergeKeySelect,
  hasError,
  onChange,
  placeholder,
  value,
  isSms,
  isWhatsapp,
  templateType,
  hasRequiredTab,
  disabled,
  readOnly,
}) {
  useInjectReducer({
    key: 'fileRecollectionDrawer',
    reducer: fileRecollectionReducer,
  });
  const classes = useStyles();

  const { fileRecollectionData } = useSelector(
    makeSelectFileRecollectionDrawer(),
  );

  const CHAR_LIMIT =
    useSelector(makeIsSmsLengthLimitEnabled()) && isSms ? 450 : 1000;

  const [htmlModeEnabled, setHtmlModeEnabled] = useState(false);
  const renderEditor = () => {
    // only allow regular TextEdit (without HTML mode)
    if (!enableHtmlModeSwitch) {
      return (
        // Regular text editor
        <React.Fragment>
          <textarea
            value={value || ''}
            onChange={event => onChange(event.target.value)}
            className={classNames(classes.textarea, { error: hasError })}
            maxLength={CHAR_LIMIT}
            placeholder={placeholder}
            readOnly={readOnly}
            disabled={disabled}
          />
          <div
            className={classNames(classes.textareaLength, {
              limitReached: value && value.length >= CHAR_LIMIT,
            })}
          >
            <FormattedMessage
              {...globalMessages.charactersLimit}
              values={{
                current: (value && value.length) || 0,
                limit: CHAR_LIMIT,
              }}
            />
          </div>
        </React.Fragment>
      );
    }

    if (htmlModeEnabled) {
      return (
        // HTML editor
        <CodeMirror
          value={value}
          options={{
            mode: 'html',
            theme: 'material',
            lineNumbers: true,
          }}
          style={{ height: 440 }}
          onBeforeChange={(editor, data, text) => {
            onChange(text);
          }}
          className={classes.htmlEditor}
        />
      );
    }

    return (
      <TextEditor
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        hasError={hasError}
      />
    );
  };

  return (
    <React.Fragment>
      <Grid
        className={classNames(classes.textEditorContainer, {
          error: hasError,
        })}
      >
        {renderEditor()}
        <EditorActionsToolbar
          enableMessageTemplates={enableMessageTemplates}
          enableMergeKeys={enableMergeKeys}
          handleSelectTemplate={handleSelectTemplate}
          handleMergeKeySelect={handleMergeKeySelect}
          htmlModeEnabled={htmlModeEnabled}
          mergeKeys={fileRecollectionData.merge_keys}
          setHtmlModeEnabled={setHtmlModeEnabled}
          value={value}
          isSms={isSms}
          isWhatsapp={isWhatsapp}
          templateType={templateType}
          hasRequiredTab={hasRequiredTab}
          enableHtmlModeSwitch={enableHtmlModeSwitch}
        />
        {errorText && (
          <div className={classes.errorMessage}>
            <Caption align="right" color="error">
              {errorText}
            </Caption>
          </div>
        )}
      </Grid>
    </React.Fragment>
  );
}

MessageTemplateTextEditor.defaultProps = {
  enableHtmlModeSwitch: false,
  enableMergeKeys: false,
  enableMessageTemplates: false,
  hasError: false,
  handleMergeKeySelect: () => {},
  isSms: false,
};

MessageTemplateTextEditor.propTypes = {
  enableHtmlModeSwitch: PropTypes.bool,
  enableMergeKeys: PropTypes.bool,
  enableMessageTemplates: PropTypes.bool,
  errorText: PropTypes.string,
  handleSelectTemplate: PropTypes.func.isRequired,
  handleMergeKeySelect: PropTypes.func,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  isSms: PropTypes.bool,
  isWhatsapp: PropTypes.bool,
  templateType: PropTypes.string,
  hasRequiredTab: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default memo(MessageTemplateTextEditor);
