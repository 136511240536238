import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  search: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SessionHosts.search`,
    defaultMessage: 'Search',
  },
  noUsers: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SessionHosts.noUsers`,
    defaultMessage: 'No users found',
  },
  interviewers: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SessionHosts.interviewers`,
    defaultMessage: 'Hosts',
  },
  noInterviewers: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SessionHosts.noInterviewers`,
    defaultMessage: 'No hosts',
  },
  addUsers: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SessionHosts.addUsers`,
    defaultMessage: 'Add users to host events',
  },
  addUserButton: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SessionHosts.addUserButton`,
    defaultMessage: 'Add user',
  },
  addInterviewers: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SessionHosts.addInterviewers`,
    defaultMessage: 'Add hosts',
  },
  searchUsers: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SessionHosts.searchUsers`,
    defaultMessage: 'Search users',
  },
  cancel: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SessionHosts.cancel`,
    defaultMessage: 'Cancel',
  },
  add: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SessionHosts.add`,
    defaultMessage: 'Add',
  },
  ifThereAreMultipleInterviewers: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SessionHosts.ifThereAreMultipleInterviewers`,
    defaultMessage:
      'If there are multiple hosts, booked events will be assigned based on a user’s availability. Max 10 hosts per event.',
  },
  available: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SessionHosts.available`,
    defaultMessage: 'Available',
  },
  interviewer: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SessionHosts.interviewer`,
    defaultMessage: 'Hosts',
  },
});
