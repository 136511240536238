/* eslint-disable camelcase */

import { useEffect, useState } from 'react';

import useUserPermissions from 'hooks/useUserPermissions';
import { getLocalStorageItem, setLocalStorageItem } from 'utils/storageUtils';

interface StoredUser {
  id: string;
  email: string;
}

interface User {
  id?: string;
  external_id?: string;
  email: string;
}

export default function useGetStoredUser(
  currentUser: User,
  users: User[],
): [User | StoredUser, (user: User) => void, () => void] {
  const { sendMessagesFromUserPermission } = useUserPermissions();
  const [selectedUser, setselectedUser] = useState<User>(currentUser);

  const findUser = (targetId: string | undefined, usersList: User[]) =>
    usersList.find(
      user => user.id === targetId || user.external_id === targetId,
    );

  const updateStoredUser = () => {
    setLocalStorageItem(
      'lastSenderEmailUsed',
      JSON.stringify({
        id: selectedUser.id ?? selectedUser.external_id,
        email: selectedUser.email,
      }),
    );
  };

  useEffect(() => {
    const storageUser = JSON.parse(
      /* @ts-expect-error getLocalStorageItems needs typing */
      getLocalStorageItem('lastSenderEmailUsed'),
    ) as StoredUser | null;

    if (users.length) {
      let userId = currentUser.external_id;
      if (storageUser && sendMessagesFromUserPermission) {
        userId = findUser(storageUser.id, users)
          ? storageUser.id
          : currentUser.external_id;
      }
      setselectedUser(findUser(userId, users) ?? currentUser);
      return;
    }

    if (!sendMessagesFromUserPermission) {
      setselectedUser(currentUser);
      return;
    }

    if (storageUser) {
      setselectedUser(storageUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  return [selectedUser, setselectedUser, updateStoredUser];
}
