import { Input, StyledReactSelect } from '@fountain/fountain-ui-components';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { classNames } from 'react-extras';
import { injectIntl } from 'react-intl';

import JobSearch from 'containers/JobSearch';
import useStages from 'hooks/useStages';
import TrashSvg from 'images/trashV2';

import messages from './messages';
import useStyles from './styles';

function DistributeRule({
  locations,
  defaultLocation,
  index,
  handleDeleteRule,
  distributeRule,
  handleUpdateRule,
  allowDeletingRule,
  intl,
  stageExternalId,
  percentageErrorMsg,
  isLastRule,
  jobExternalId,
}) {
  const classes = useStyles();

  const [selectedLocation, setSelectedLocation] = useState(defaultLocation);
  const [selectedOpening, setSelectedOpening] = useState();
  const [selectedStage, setSelectedStage] = useState();
  const [percentage, setPercentage] = useState(distributeRule.percentage);

  const [{ data: stagesData, isFetching: isFetchingStages }, fetchStages] =
    useStages();

  const errors = distributeRule.errors || {};
  const openingId =
    selectedOpening && (selectedOpening.job_id || selectedOpening.id);
  const stages = selectedOpening && stagesData && stagesData[openingId];
  const filteredStages =
    stages && stages.filter(stage => stage.id !== stageExternalId);

  if (filteredStages && distributeRule.to_funnel_id !== jobExternalId) {
    filteredStages.unshift({
      id: 'start_of_workflow',
      name: intl.formatMessage(messages.startOfWorkflow),
    });
  }

  useEffect(() => {
    if (selectedOpening && !selectedOpening.default && !filteredStages) {
      fetchStages(openingId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOpening]);

  useEffect(() => {
    if (filteredStages && !selectedStage) {
      handleClickStage(filteredStages[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredStages]);

  const distributeRuleExtra = distributeRule && distributeRule.extra;

  useEffect(() => {
    if (distributeRuleExtra) {
      if (distributeRule.to_location_id && distributeRuleExtra.location_title) {
        setSelectedLocation({
          id: distributeRule.to_location_id,
          name: distributeRuleExtra.location_title,
          default: true,
        });
      }

      if (distributeRule.to_funnel_id && distributeRuleExtra.funnel_title) {
        setSelectedOpening({
          id: distributeRule.to_funnel_id,
          name: distributeRuleExtra.funnel_title,
          default: true,
        });
      }

      if (distributeRule.to_stage_id === 'start_of_workflow') {
        setSelectedStage({
          id: 'start_of_workflow',
          name: 'Start of workflow',
          default: true,
        });
      } else if (
        distributeRule.to_stage_id &&
        distributeRuleExtra.stage_title
      ) {
        setSelectedStage({
          id: distributeRule.to_stage_id,
          name: distributeRuleExtra.stage_title,
          default: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributeRuleExtra]);

  const handleChangePercentage = e => {
    const intValue = parseInt(e.target.value, 10);

    if (intValue > 100) {
      return;
    }

    setPercentage(intValue);
    handleUpdateRule(index, {
      ...distributeRule,
      percentage: parseInt(e.target.value, 10),
    });
  };

  const onKeyDownPercentage = e => e.key === '.' && e.preventDefault();

  const updatedRuleErrors = errorToDelete => {
    let updatedErrors;

    if (errors && errors.openingIdMissing) {
      updatedErrors = { ...errors };
      delete updatedErrors[errorToDelete];
    }

    return updatedErrors;
  };

  /* eslint-disable-next-line consistent-return */
  const handleClickLocation = location => {
    if (location.id === selectedLocation.id) {
      return null;
    }

    setSelectedOpening(null);
    setSelectedStage(null);

    setSelectedLocation(location);
    handleUpdateRule(index, {
      ...distributeRule,
      to_location_id: location.id,
      to_funnel_id: null,
      to_stage_id: null,
      ...(errors ? { errors: updatedRuleErrors('locationIdMissing') } : {}),
    });
  };

  const handleClickOpening = (_location, opening) => {
    setSelectedStage(null);

    if (opening) {
      setSelectedOpening(opening);
      handleUpdateRule(index, {
        ...distributeRule,
        to_funnel_id: opening.job_id,
        to_stage_id: null,
        ...(errors ? { errors: updatedRuleErrors('openingIdMissing') } : {}),
      });
    }
  };

  const handleClickStage = stage => {
    setSelectedStage(stage);
    handleUpdateRule(index, {
      ...distributeRule,
      to_stage_id: stage.id,
      ...(errors ? { errors: updatedRuleErrors('stageIdMissing') } : {}),
    });
  };

  const handleClickStagesDropdown = () => {
    if (selectedOpening && !filteredStages) {
      fetchStages(openingId);
    }
  };

  const isOpeningsDropdownDisabled = !selectedLocation;
  const isStagesDropdownDisabled = !selectedOpening;
  const isPercentageDisabled = !selectedStage;

  return (
    <Grid
      container
      spacing={2}
      align="flex-end"
      className={classNames(classes.distributeRule, {
        allowDeletingRule,
        hasErrors: Object.keys(errors).length > 0,
      })}
    >
      <Grid item xs={3}>
        <StyledReactSelect
          key={distributeRule.id}
          label={index === 0 ? intl.formatMessage(messages.location) : null}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          noOptionsMessage={() =>
            intl.formatMessage(messages.noResultsAvailable, {
              resultType: intl.formatMessage(messages.locations),
            })
          }
          onChange={handleClickLocation}
          options={locations}
          placeholder={`${intl.formatMessage(
            messages.search,
          )} ${intl.formatMessage(messages.locations)}`}
          value={selectedLocation}
          error={
            errors.locationIdMissing &&
            intl.formatMessage(messages.requiredFieldMessage)
          }
        />
      </Grid>
      <Grid item xs={4}>
        <JobSearch
          disableSearchStringQuery
          locationId={selectedLocation && selectedLocation.id}
          disabled={isOpeningsDropdownDisabled}
          label={intl.formatMessage(messages.opening)}
          placeholder={`${intl.formatMessage(
            messages.search,
          )} ${intl.formatMessage(messages.openings)}`}
          noLabel={index > 0}
          onSelected={handleClickOpening}
          variant="select"
          clearInputOnClick={false}
          value={
            selectedOpening && (selectedOpening.name || selectedOpening.title)
          }
          error={
            errors.openingIdMissing &&
            intl.formatMessage(messages.requiredFieldMessage)
          }
          isUrl={false}
        />
      </Grid>
      <Grid item xs={3} onClick={handleClickStagesDropdown}>
        <StyledReactSelect
          key={distributeRule.id}
          isDisabled={isStagesDropdownDisabled}
          label={index === 0 ? intl.formatMessage(messages.stage) : null}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          isLoading={isFetchingStages}
          noOptionsMessage={() =>
            intl.formatMessage(messages.noResultsAvailable, {
              resultType: intl.formatMessage(messages.stages),
            })
          }
          onChange={handleClickStage}
          options={filteredStages || []}
          placeholder={`${intl.formatMessage(
            messages.search,
          )} ${intl.formatMessage(messages.stages)}`}
          value={selectedStage}
          className={classNames(classes.dropdown, {
            disabled: isStagesDropdownDisabled,
          })}
          error={
            errors.stageIdMissing &&
            intl.formatMessage(messages.requiredFieldMessage)
          }
        />
      </Grid>
      <Grid item xs={2}>
        <Input
          label={
            index === 0 ? intl.formatMessage(messages.targetDistribution) : null
          }
          placeholder="e.g. 100"
          disabled={isPercentageDisabled}
          type="number"
          className={classNames(classes.percentageField, {
            disabled: isPercentageDisabled,
          })}
          onChange={handleChangePercentage}
          onKeyDown={onKeyDownPercentage}
          value={percentage}
          hideErrorMessage
          error={errors.percentageMissing || percentageErrorMsg}
          endAdornment={
            <Typography variant="body2" color="textSecondary">
              %
            </Typography>
          }
        />
        {isLastRule && percentageErrorMsg && (
          <Typography
            variant="caption"
            align="right"
            color="error"
            className={classes.percentageErrorMsg}
          >
            {percentageErrorMsg}
          </Typography>
        )}
      </Grid>
      {allowDeletingRule && (
        <Grid
          className={classNames(classes.deleteRule, {
            isFirstRule: index === 0,
          })}
          onClick={() => handleDeleteRule(index, distributeRule.id)}
        >
          <TrashSvg />
        </Grid>
      )}
    </Grid>
  );
}

DistributeRule.propTypes = {
  locations: PropTypes.array,
  defaultLocation: PropTypes.object,
  index: PropTypes.number,
  distributeRule: PropTypes.object,
  handleDeleteRule: PropTypes.func,
  handleUpdateRule: PropTypes.func,
  allowDeletingRule: PropTypes.bool,
  intl: PropTypes.object,
  stageExternalId: PropTypes.string,
  percentageErrorMsg: PropTypes.string,
  isLastRule: PropTypes.bool,
  jobExternalId: PropTypes.string,
};

export default injectIntl(DistributeRule);
