/* eslint-disable camelcase */
import { Loader } from '@fountain/fountain-ui-components';
import { DataKey } from 'api-clients/monolith';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { makeIsCannotRepeatStandardKeyEnabled } from 'containers/Auth_old/selectors';
import { addMessageAction } from 'containers/FlashMessage/actions';
import { useArchivedReasons } from 'containers/WorkflowEditor/hooks/useArchivedReasons';
import { useRejectionReasons } from 'containers/WorkflowEditor/hooks/useRejectionReasons';
import { useRulesTargetStage } from 'containers/WorkflowEditor/hooks/useRulesTargetStage';

import { RulesEditDataContext } from '../contexts/rulesEditDataContext';
import { useRulesTargetOpenings } from '../hooks/useRulesTargetOpenings';
import messages from './messages';

// Removing `options` on data_keys because we only need the keys themselves.
export const transformDataKeyOptions = (
  dataKeyOptions: DataKey[] = [],
): {
  key: string;
  predefined: boolean;
  secure: boolean;
  type: string;
  identifier: string;
}[] => {
  return dataKeyOptions.map(option => ({
    key: option.key,
    predefined: option.predefined,
    secure: option.secure,
    type: option.type,
    identifier: option.identifier,
  }));
};

export const GetGroupedDataKeys = (
  dataKeys:
    | {
        additional_keys: DataKey[];
        custom_keys: DataKey[];
        standard_keys: DataKey[];
      }
    | undefined,
  options?: {
    onlyReturnFileKeys?: boolean;
  },
) => {
  const intl = useIntl();
  const cannotRepeatStandardKeys = useSelector(
    makeIsCannotRepeatStandardKeyEnabled(),
  );
  if (!dataKeys) {
    return [];
  }

  let standardKeys = dataKeys?.standard_keys ?? [];
  let customKeys = dataKeys?.custom_keys ?? [];

  if (options?.onlyReturnFileKeys) {
    standardKeys = standardKeys.filter(
      standardKey => standardKey.type === 'file',
    );
    customKeys = customKeys.filter(customKey => customKey.type === 'file');
  }

  // NOTE: CustomKeys can collide with standard keys by the #key attribute
  // Prefer the StandardizedKey variety when there is a collision
  if (cannotRepeatStandardKeys) {
    const standardKeyNames = standardKeys.map(sk => sk.key);
    customKeys = customKeys.filter(
      customKey => !standardKeyNames.includes(customKey.key),
    );
  }

  return [
    {
      label: intl.formatMessage(messages.standardKeys),
      // additional_keys contains 'fake' data keys that point to the applicant themselves such as email and phone number
      options: transformDataKeyOptions(
        standardKeys
          .concat(dataKeys?.additional_keys ?? [])
          .sort((a, b) => a.key.localeCompare(b.key)),
      ),
    },
    {
      label: intl.formatMessage(messages.customKeys),
      options: transformDataKeyOptions(customKeys),
    },
  ];
};

export type RulesEditDataContextProviderProps = {
  externalId: string;
};

export const RulesEditDataContextProvider: React.FC<
  RulesEditDataContextProviderProps
> = ({ externalId, children }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [filterToSelectedOnly, setFilterToSelectedOnly] = useState(true);
  const { result } = useRulesTargetStage({ externalId });
  const { result: rulesTargetOpeningsResult } = useRulesTargetOpenings({
    externalId,
    filterToSelectedOnly,
  });
  const { result: archivedReasonsResult } = useArchivedReasons();
  const { result: rejectedReasonsResult } = useRejectionReasons();

  React.useEffect(() => {
    if (result.isError) {
      dispatch(
        addMessageAction(intl.formatMessage(messages.apiError), 'error', false),
      );
    }
  }, [dispatch, intl, result.isError]);

  return (
    <>
      {result.isError && null}

      {result.status === 'loading' && <Loader size="2rem" />}

      {result.status === 'ready' && (
        <RulesEditDataContext.Provider
          value={{
            ...result.data,
            archivedReasonsResult,
            rejectedReasonsResult,
            openings:
              rulesTargetOpeningsResult.status === 'ready'
                ? rulesTargetOpeningsResult.data.openings
                : [],
            openingsResultIsLoading: rulesTargetOpeningsResult.isLoading,
            setFilterToSelectedOnly,
          }}
        >
          {children}
        </RulesEditDataContext.Provider>
      )}
    </>
  );
};
