import { SidebarStage } from 'api-clients/monolith';
import { useIntl } from 'react-intl';

import { messages } from './messages';

export interface SelectOption {
  label: string;
  value: string | number;
  isDisabled?: boolean;
  disabledMessage?: string;
  usesFederalForm?: boolean;
}

export const useGetStagePlacementOptions = ({
  stages,
}: {
  stages: SidebarStage[];
}) => {
  const intl = useIntl();

  const stagesWithoutArchivedOrRejected = stages.filter(
    stage => stage.type !== 'ArchivedStage' && stage.type !== 'RejectedStage',
  );

  return [
    { label: intl.formatMessage(messages.topOfWorkflow), value: -1 },
    {
      label: intl.formatMessage(messages.afterStage),
      options: stagesWithoutArchivedOrRejected.map<SelectOption>(stage => ({
        label: stage.title,
        value: stage.position,
      })),
    },
  ];
};
