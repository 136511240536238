import { EventAvailableSlot } from 'api-clients/monolith';
import { useEffect, useState } from 'react';

export const useErrors = (
  errors: Partial<Record<keyof EventAvailableSlot, string>>,
) => {
  const [stageError, setStageError] = useState<boolean>(!!errors?.stage_titles);
  const [openingError, setOpeningError] = useState<boolean>(
    !!errors?.opening_titles,
  );
  const [locationGroupError, setLocationGroupError] = useState<boolean>(
    !!errors?.location_group_titles,
  );

  useEffect(() => {
    setStageError(!!errors?.stage_titles);
    setOpeningError(!!errors?.opening_titles);
    setLocationGroupError(!!errors?.location_group_titles);
  }, [errors]);

  return {
    stageError,
    openingError,
    locationGroupError,
    setStageError,
    setOpeningError,
    setLocationGroupError,
  };
};
