import React, { useEffect, useRef, useState } from 'react';

export interface UseConditionTooltipOptions {
  ref: React.MutableRefObject<HTMLElement | null>;
  ignoreHeight?: boolean;
}

/**
 * @description Display tooltip only when the referenced element has been truncated.
 */
export const useConditionalTooltip = (
  options?: Partial<UseConditionTooltipOptions>,
) => {
  const [shouldShowTooltip, setShouldShowTooltip] = useState(false);
  const defaultRef = useRef<HTMLElement | null>(null);
  const passedInRef = options?.ref;
  const ref = passedInRef ?? defaultRef;
  const ignoreHeight = options?.ignoreHeight ?? false;

  useEffect(() => {
    if (ref.current) {
      const scrollWidth = Math.ceil(ref.current.scrollWidth);
      const clientWidth = Math.floor(ref.current.clientWidth);
      const scrollHeight = Math.ceil(ref.current.scrollHeight);
      const clientHeight = Math.floor(ref.current.clientHeight);
      const widthOverflow = scrollWidth > clientWidth;
      const heightOverflow = scrollHeight > clientHeight;
      const overflow = ignoreHeight
        ? widthOverflow
        : widthOverflow || heightOverflow;
      setShouldShowTooltip(overflow);
    }
  }, [ignoreHeight, ref]);

  return {
    tooltipProps: {
      disableFocusListener: !shouldShowTooltip,
      disableHoverListener: !shouldShowTooltip,
      disableTouchListener: !shouldShowTooltip,
    },
  };
};
