import { Button } from '@fountain/fountain-ui-components';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import {
  addDefaultErrorMessageAction,
  addMessageAction,
} from 'containers/FlashMessage/actions';

import { LoadingButton } from './Components/LoadingButton';
import { CopyIcon } from './icons/CopyIcon';
import { NewTabIcon } from './icons/NewTabIcon';
import { messages } from './messages';

const useStyles = makeStyles(theme => ({
  footer: {
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    width: '100%',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 0),
  },
  button: {
    borderRadius: '5px',
    width: 'fit-content',
    padding: theme.spacing(1.5, 3),
  },
  saveButton: {
    borderRadius: '5px',
    width: '80px',
    padding: theme.spacing(1.5, 3),
  },
  icon: {
    width: '16px',
    height: '16px',
    marginRight: theme.spacing(1),
  },
}));

export interface FooterProps {
  codeSnippet: string;
  widgetUrl: string;
  isUpdaing: boolean;
  isSaveButtonEnabled: boolean;
}

export const Footer: React.VFC<FooterProps> = ({
  codeSnippet,
  widgetUrl,
  isUpdaing,
  isSaveButtonEnabled,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleCopyCodeClick = () => {
    const handleSuccess = () =>
      dispatch(
        addMessageAction(intl.formatMessage(messages.copied), 'success'),
      );

    const handleError = () => dispatch(addDefaultErrorMessageAction());

    navigator.clipboard.writeText(codeSnippet).then(handleSuccess, handleError);
  };

  const openWidgetInNewTab = () => {
    window.open(widgetUrl, '_blank');
  };

  return (
    <div className={styles.footer}>
      <Button
        type="secondary"
        className={styles.button}
        onClick={openWidgetInNewTab}
      >
        <NewTabIcon className={styles.icon} />
        <FormattedMessage {...messages.previewWidget} />
      </Button>
      <Button
        type="secondary"
        className={styles.button}
        onClick={handleCopyCodeClick}
      >
        <CopyIcon className={styles.icon} />
        <FormattedMessage {...messages.copyCode} />
      </Button>
      {!isUpdaing && (
        <Button
          type="primary"
          submit
          className={styles.saveButton}
          disabled={!isSaveButtonEnabled}
        >
          <FormattedMessage {...messages.saveSetting} />
        </Button>
      )}
      {isUpdaing && (
        <LoadingButton variant="contained" className={styles.saveButton} />
      )}
    </div>
  );
};
