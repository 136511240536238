import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmationMessagePrefix: {
    id: 'app.containers.BulkActionConfirmationModal.clear',
    defaultMessage:
      'Whoa there! That’s a lot of applicants. This will take the system approximately ',
  },
  confirmationMessageSuffix: {
    id: 'app.containers.BulkActionConfirmationModal.confirmationMessageSuffix',
    defaultMessage:
      'to process your bulk action. Would you like to continue with this action?',
  },
  days: {
    id: 'app.containers.BulkActionConfirmationModal.days',
    defaultMessage: '{days, plural, one {{days} day} other {{days} days}} ',
  },
  hours: {
    id: 'app.containers.BulkActionConfirmationModal.hours',
    defaultMessage:
      '{hours, plural, one {{hours} hour} other {{hours} hours}} ',
  },
  minutes: {
    id: 'app.containers.BulkActionConfirmationModal.minutes',
    defaultMessage:
      '{minutes, plural, one {{minutes} minute} other {{minutes} minutes}} ',
  },
  modalHeader: {
    id: 'app.containers.BulkActionConfirmationModal.modalHeader',
    defaultMessage: 'Confirm Bulk Action',
  },
});
