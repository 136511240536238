/*
 *
 * ApplicantsImportModal constants
 *
 */

export const APPLICANTS_UPLOAD = 'app/ApplicantsImportModal/APPLICANTS_UPLOAD';

export const APPLICANTS_SUBMIT = 'app/ApplicantsImportModal/APPLICANTS_SUBMIT';
export const APPLICANTS_SUBMIT_SUCCESS =
  'app/ApplicantsImportModal/APPLICANTS_SUBMIT_SUCCESS';
export const APPLICANTS_SUBMIT_ERROR =
  'app/ApplicantsImportModal/APPLICANTS_SUBMIT_ERROR';

export const APPLICANTS_DELETE_UPLOAD =
  'app/ApplicantsImportModal/APPLICANTS_DELETE_UPLOAD';

export const FETCH_APPLICANTS_AUTOMATED_ACTIONS =
  'app/ApplicantsImportModal/FETCH_APPLICANTS_AUTOMATED_ACTIONS';
export const FETCH_APPLICANTS_AUTOMATED_ACTIONS_SUCCESS =
  'app/ApplicantsImportModal/FETCH_APPLICANTS_AUTOMATED_ACTIONS_SUCCESS';
export const FETCH_APPLICANTS_AUTOMATED_ACTIONS_ERROR =
  'app/ApplicantsImportModal/FETCH_APPLICANTS_AUTOMATED_ACTIONS_ERROR';

export const PHONE_PLATFORMS = [
  { label: 'WhatsApp', value: 'whats_app' },
  { label: 'SMS', value: 'sms' },
];
