import { Loader } from '@fountain/fountain-ui-components';
import { Button } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DownloadIcon from '@material-ui/icons/GetApp';
import UploadIcon from '@material-ui/icons/Publish';
import React, { useEffect, useState, VFC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  makeSelectAccountSlug,
  makeSelectWhoami,
} from 'containers/Auth_old/selectors';
import { downloadFaq, fetchUser, uploadFaq } from 'containers/ChatAgent/api';
import { messages } from 'containers/ChatAgent/messages';
import { KnowledgeBaseResponse } from 'containers/ChatAgent/types';
import { addMessageAction } from 'containers/FlashMessage/actions';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';

import { useListKnowledgeBaseStyles } from './styles';

export interface ListProps {
  knowledgeBase?: KnowledgeBaseResponse;
  accessToken: string;
  setIsKnowledgeBaseUploaded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const List: VFC<ListProps> = ({
  knowledgeBase,
  accessToken,
  setIsKnowledgeBaseUploaded,
}) => {
  const classes = useListKnowledgeBaseStyles();
  const locale = useSelector(makeSelectLocale());
  const dispatch = useDispatch();
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userName, setUserName] = useState('');
  const { name, external_id: userExternalId } = useSelector(makeSelectWhoami());
  const accountSlug = useSelector(makeSelectAccountSlug()) ?? '';

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      const uploadCreatedBy = knowledgeBase?.uploads?.[0]?.createdBy;
      if (uploadCreatedBy === userExternalId) {
        setUserName(name);
        setIsLoading(false);
      } else {
        try {
          const response = await fetchUser(
            accountSlug,
            uploadCreatedBy as string,
          );
          setUserName(response.user.name);
        } finally {
          setIsLoading(false);
        }
      }
    };

    if (knowledgeBase) {
      void fetchUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [knowledgeBase, accountSlug]);

  if (!knowledgeBase) {
    return <div>No knowledge bases available.</div>;
  }

  if (isLoading) return <Loader fullScreen size="2rem" />;

  const handleDownload = async (
    knowledgeBaseUuid: string,
    fileName: string,
  ) => {
    try {
      const blob = await downloadFaq(accessToken, knowledgeBaseUuid);
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();

      dispatch(
        addMessageAction(
          intl.formatMessage(messages.downloadFileSuccess),
          'success',
        ),
      );
    } catch (err) {
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.downloadFileFailed),
          'error',
        ),
      );
    }
  };

  const handleReUpload = async (knowledgeBaseUuid: string, file: File) => {
    setIsLoading(true);
    try {
      await uploadFaq(accessToken, knowledgeBaseUuid, file, userExternalId);
      setIsKnowledgeBaseUploaded(true);
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.uploadFileSuccess),
          'success',
        ),
      );
    } catch (err) {
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.uploadFileFailed),
          'error',
        ),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    knowledgeBaseUuid: string,
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      void handleReUpload(knowledgeBaseUuid, e.target.files[0]);
    }
  };

  const upload = knowledgeBase.uploads?.[0];
  const filename = upload?.filename ?? 'Unknown File';
  const size = upload?.size ?? 0;
  const lastUpdated = new Date(upload.updatedAt).toLocaleString(locale, {
    dateStyle: 'medium',
    timeStyle: 'short',
  });

  return (
    <div className={classes.container}>
      <div className={classes.item}>
        <div className={classes.info}>
          <div className={classes.fileName}>
            <CheckCircleOutlineIcon />
            {filename} &nbsp; {size} KB
          </div>
          <div>
            <div>last updated on {lastUpdated}</div>
            {userName && <div>by {userName}</div>}
          </div>
        </div>
        <div className={classes.buttons}>
          <Button
            variant="text"
            color="secondary"
            startIcon={<DownloadIcon />}
            className={classes.button}
            onClick={() => handleDownload(knowledgeBase.uuid, filename)}
          >
            Download
          </Button>
          <Button
            variant="text"
            color="secondary"
            startIcon={<UploadIcon />}
            className={classes.button}
            component="label"
            disabled={isLoading}
          >
            Re-Upload
            <input
              type="file"
              hidden
              onChange={e => onFileChange(e, knowledgeBase.uuid)}
            />
          </Button>
        </div>
      </div>
    </div>
  );
};
