import queryString from 'query-string';
import { useCallback, useState } from 'react';

import { apiGet } from 'utils/axios';

const useFetch = ({ uri }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  let isCancelled = false;

  const cancelFetch = () => {
    isCancelled = true;
  };

  const fetchData = useCallback(
    async options => {
      setIsLoading(true);

      try {
        let url = uri;
        const params = queryString.stringify(options);
        if (params) {
          url += `?${params}`;
        }
        const res = await apiGet(url);

        if (!isCancelled && res.data) {
          setError(null);
          setData(res.data);
          setIsLoading(false);
        }
      } catch (e) {
        if (!isCancelled) {
          setData(null);
          setError(e);
          setIsLoading(false);
        }
      }
    },
    [uri], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const resetState = () => {
    setData(null);
    setError(null);
  };

  return { data, error, fetchData, cancelFetch, isLoading, resetState };
};

export default useFetch;
