import { List, ListItem, ListSubheader } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  makeSelectAccountSlug,
  makeSelectBrands,
} from 'containers/Auth_old/selectors';
import JobSearchResultsListItem from 'containers/JobSearch/JobSearchResults/JobSearchResultsListItem';
import JobSearchResultsListSubheader from 'containers/JobSearch/JobSearchResults/JobSearchResultsListSubheader';
import { generateLink } from 'utils/urlUtils';

import useStyles from './styles';

const JobSearchResultsList = ({ onSelected, options, searchString, isUrl }) => {
  const accountSlug = useSelector(makeSelectAccountSlug());
  const brands = useSelector(makeSelectBrands());
  const styles = useStyles();

  return (
    <List className={styles.muiList} disablePadding>
      {options.map(option => (
        <List
          className={styles.muiList}
          disablePadding
          key={option.external_id}
        >
          <ListSubheader
            className={styles.listSubheaderContainer}
            disableSticky
          >
            <JobSearchResultsListSubheader
              option={option}
              searchString={searchString}
            />
          </ListSubheader>
          {option.funnels.map(funnel => {
            const jobUrl = generateLink(option, funnel, brands, accountSlug);
            return (
              <ListItem
                button
                component={isUrl ? Link : null}
                to={jobUrl}
                className={styles.listItemContainer}
                key={funnel.job_id}
                onClick={onSelected(option, funnel)}
              >
                <JobSearchResultsListItem
                  option={funnel}
                  searchString={searchString}
                />
              </ListItem>
            );
          })}
        </List>
      ))}
    </List>
  );
};

JobSearchResultsList.propTypes = {
  onSelected: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      external_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      location_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      address: PropTypes.string,
      brand_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      funnels: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          title: PropTypes.string.isRequired,
          job_id: PropTypes.string.isRequired,
          active: PropTypes.bool.isRequired,
          applicants_count: PropTypes.number.isRequired,
        }),
      ),
    }),
  ).isRequired,
  searchString: PropTypes.string.isRequired,
  isUrl: PropTypes.bool,
};

JobSearchResultsList.defaultProps = {
  isUrl: true,
};

export default JobSearchResultsList;
