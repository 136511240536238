import { makeStyles, Typography } from '@material-ui/core';
import { WorkflowBuilderFunnelStage } from 'api-clients/monolith';
import React, { VFC } from 'react';

import { Card } from './Card';

const useStyles = makeStyles(theme => ({
  stageSelectorContainer: {
    minWidth: '240px',
    maxWidth: '400px',
    flexGrow: 1,
    borderRight: theme.customBorders.gray200,
  },
  header: {
    padding: theme.spacing(2.5, 2),
    textAlign: 'center',
  },
  stages: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.common.gray200}`,
    overflowY: 'auto',
    height: 'calc(100% - 64px)',
  },
}));

export interface StageSelectorProps {
  stages: WorkflowBuilderFunnelStage[] | undefined;
  setSelectedStage: React.Dispatch<
    React.SetStateAction<WorkflowBuilderFunnelStage | undefined>
  >;
}

export const StageSelector: VFC<StageSelectorProps> = ({
  stages,
  setSelectedStage,
}) => {
  const classes = useStyles();

  const handleClick = (stage: WorkflowBuilderFunnelStage) => {
    setSelectedStage(stage);
  };

  return (
    <div className={classes.stageSelectorContainer}>
      <Typography variant="h3" className={classes.header}>
        Stages
      </Typography>
      <div className={classes.stages}>
        {stages?.map(stage => (
          <>
            <Card stage={stage} handleClick={handleClick} />
          </>
        ))}
      </div>
    </div>
  );
};
