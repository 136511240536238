import React, { FC } from 'react';

import { SharedPartnerConditionProps } from '../../PartnerRules/types';
import { SharedConditionProps } from '../../types';
import { PartnerOptionSelect } from '../components/PartnerOptionSelect';
import { PartnerSelect } from '../components/PartnerSelect';
import { PartnerTitleInput } from '../components/PartnerTitleInput';

export const PartnerStatusHasTitle: FC<
  SharedConditionProps | SharedPartnerConditionProps
> = props => {
  return (
    <>
      {!props.setPartnerRules && <PartnerSelect {...props} />}
      <PartnerTitleInput {...props} />
      {!props.setPartnerRules && <PartnerOptionSelect {...props} />}
    </>
  );
};
