import { makeStyles } from '@material-ui/core/styles';
/**
 *
 * Material UI styles for StatusLabels
 *
 *
 * @see https://material-ui.com/styles/basics
 *
 */

const useStyles = makeStyles(theme => ({
  customLabelsContainer: {
    position: 'relative',
    whiteSpace: 'nowrap',
    display: 'flex',
    flexWrap: 'nowrap',
    minWidth: 180,

    '&.isOpen': {
      whiteSpace: 'inherit',
      overflow: 'inherit',
      height: 'auto',
    },

    '&.isExpanded': {
      overflow: 'inherit',
      whiteSpace: 'inherit',
      height: 'auto',
    },
  },
  openedCustomLabels: {
    position: 'absolute',
    display: 'flex',
    flexWrap: 'wrap',
    top: 0,
    left: theme.spacing(-1),
    background: theme.palette.common.white,
    width: '100%',
    zIndex: 1,
    padding: theme.spacing(1.75, 0.75, 0.75, 1.75),
    border: theme.customBorders.gray300,
    boxShadow: theme.customShadows.dropdown,
    borderRadius: 4,
  },
  collapsedCountCircle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 32,
    height: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
    border: theme.customBorders.gray200,
    borderRadius: theme.spacing(12.5),
    color: theme.palette.common.gray500,
    right: theme.spacing(1.5),
    zIndex: 0,

    '&:hover': {
      backgroundColor: theme.palette.common.white,
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
