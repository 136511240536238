import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  mobileUpload: {
    width: '100%',
    height: '40px',
    border: `1px solid ${theme.palette.common.gray400}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.error': {
      border: theme.customBorders.error,
    },
  },
  mobileUploadContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  maxSize: {
    color: theme.palette.common.gray600,
    fontFamily: theme.typography.body2.fontFamily,
    lineHeight: theme.typography.body2.lineHeight,
    fontSize: theme.spacing(1.5),
    marginTop: theme.spacing(1),
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
