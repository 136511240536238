/* eslint-disable camelcase */
import {
  CustomTooltip,
  ModalFooter,
  SimpleModal,
  SimpleModalHeader,
  SimpleModalTitle,
} from '@fountain/fountain-ui-components';
import { Button } from '@material-ui/core';
import queryString from 'query-string';
import React, { VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';

import { wxExportApplicantAction } from 'containers/ApplicantActions/actions';
import makeSelectApplicantTableV2 from 'containers/ApplicantTableV2/selectors';
import { Applicant, BulkSelect } from 'containers/ApplicantTableV2/types';
import InfoIcon from 'images/InfoIcon';

import { messages } from './messages';

export interface WxExportApplicantModalProps {
  handleClose: () => void;
}

export const WxExportApplicantModal: VFC<WxExportApplicantModalProps> = ({
  handleClose,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const {
    bulkSelect,
    selectedApplicantIds,
    selectByCount,
    applicants,
    selectedFilterStageIdsWithFunnel,
  } = useSelector(makeSelectApplicantTableV2());

  const location = useLocation();
  const match = matchPath(location.pathname, {
    path: '/:accountSlug/brands/:brandSlug/locations/:locationId/jobs/:jobId/v2/stages/:stageExternalId',
    exact: true,
    strict: false,
  });

  const selectedApplicant: Applicant | undefined = Object.values(applicants)[0];

  const selectAllorByCount =
    bulkSelect === BulkSelect.ALL ||
    bulkSelect === BulkSelect.COUNT ||
    undefined;

  const {
    filter_set_id,
    temp_filter_id,
    duplicate_applicant_id,
    stage_conditions_filter_set_id,
  } = queryString.parse(location.search);
  const { query } = queryString.parse(location.search);

  const isMasterApplicantsView = !match;
  const onSubmit = () => {
    dispatch(
      wxExportApplicantAction(
        isMasterApplicantsView,
        selectedApplicantIds,
        filter_set_id,
        temp_filter_id,
        duplicate_applicant_id,
        stage_conditions_filter_set_id,
        selectByCount,
        selectedApplicant,
        selectedFilterStageIdsWithFunnel,
        selectAllorByCount && query,
        {
          success: intl.formatMessage(messages.submissionSuccess),
          error: intl.formatMessage(messages.submissionError),
        },
      ),
    );
    handleClose();
  };

  return (
    <SimpleModal onClose={handleClose} maxWidth={600} blurOverlay={false}>
      <SimpleModalHeader onClose={handleClose}>
        <SimpleModalTitle>
          {intl.formatMessage(messages.title)}
        </SimpleModalTitle>
        <CustomTooltip
          disableFocusListener
          enterTouchDelay={100}
          title={intl.formatMessage(messages.tooltip)}
          dense
        >
          <InfoIcon />
        </CustomTooltip>
      </SimpleModalHeader>
      <ModalFooter>
        <Button onClick={onSubmit} size="small">
          <FormattedMessage {...messages.send} />
        </Button>
      </ModalFooter>
    </SimpleModal>
  );
};

export default WxExportApplicantModal;
