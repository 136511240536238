import { WorkflowEditorService } from 'api-clients/monolith';
import { useApiService } from 'hooks';
import React from 'react';
export function useCalendarUsers() {
  const fetchCalendarUsers = React.useCallback(
    () => WorkflowEditorService.getInternalApiWorkflowEditorCalendarUsers(),
    [],
  );

  return useApiService(fetchCalendarUsers);
}
