import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  table: {
    width: '100%',
    minWidth: 960,
    marginBottom: theme.spacing(3),
  },
  tableHead: {
    backgroundColor: theme.palette.common.gray100,
    height: 40,
  },
  headerCell: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(0.25),
  },
  tableRow: {
    cursor: 'pointer',
    borderBottom: theme.customBorders.gray200,
    height: 48,
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
    '& > th': {
      padding: theme.spacing(0, 2),
    },
  },
  tableCell: {
    padding: theme.spacing(1, 2),
    borderBottom: 'none',
    '& > svg': {
      fontSize: 'inherit',
    },
    '&:last-child': {
      padding: theme.spacing(1, 2),
    },
  },
  cellContent: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: 450,
    lineHeight: '16px',
  },
  toolTip: {
    marginBottom: theme.spacing(2),
  },
  buttonCell: {
    whiteSpace: 'nowrap',
  },
  actionButton: {
    height: '34px',
    padding: theme.spacing(1, 1.5),
    border: `1px solid ${theme.palette.common.gray300}`,
    '&:hover': {
      backgroundColor: theme.palette.common.blue400,
      color: theme.palette.common.white,
    },
    '&:disabled': {
      backgroundColor: theme.palette.common.gray100,
      color: theme.palette.common.gray500,
      cursor: 'auto',
    },
  },
  approveButton: {
    borderRadius: '5px 0px 0px 5px',
    borderRight: '0px',
  },
  rejectButton: {
    borderRadius: '0px 5px 5px 0px',
  },
  activeIndicator: {
    height: '10px',
    width: '10px',
    margin: theme.spacing(0, 1.25, 0, 0),
    color: theme.palette.common.gray400,
    '&.active': {
      color: theme.palette.common.green400,
    },
    '&.pendingApproval': {
      color: theme.palette.common.orange400,
    },
  },
  emptyItemsContent: {
    backgroundColor: theme.palette.common.gray100,
    paddingBottom: '100px !important',
    paddingTop: '100px !important',
    borderRadius: theme.spacing(0.625),
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  exceptionContainer: {
    height: '70%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
