import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: 'inherit',
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(1),
  },
  menuList: {
    '&.MuiMenu-list': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  menuListItem: {
    borderRadius: theme.misc.borderRadius,
    '&:hover': {
      backgroundColor: '#F2F3F7',
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.common.blue100,
      '&:hover': {
        backgroundColor: theme.palette.common.blue100,
      },
      '& p': {
        color: theme.palette.common.blue400,
      },
    },
  },
}));

export default useStyles;
