import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  header: {
    id: 'app.containers.ChatbotWidget.header',
    defaultMessage: 'Web Widget',
  },
  subHeader: {
    id: 'app.containers.ChatbotWidget.subHeader',
    defaultMessage: 'Insights',
  },
  embedCodeHeader: {
    id: 'app.containers.ChatbotWidget.embedCodeHeader',
    defaultMessage: 'Embed code',
  },
  embedCodeDescription: {
    id: 'app.containers.ChatbotWidget.embedCodeDescription',
    defaultMessage:
      'Copy this code and paste it before the closing {htmlBodyTag} tag on any page of your website where you want to display the chatbot widget.',
  },
  copyCode: {
    id: 'app.containers.ChatbotWidget.copyCode',
    defaultMessage: 'Copy Code',
  },
  copied: {
    id: 'app.containers.ChatbotWidget.copied',
    defaultMessage: 'Copied to clipboard',
  },
  previewWidget: {
    id: 'app.containers.ChatbotWidget.previewWidget',
    defaultMessage: 'Preview widget',
  },
  saveSetting: {
    id: 'app.containers.ChatbotWidget.saveSetting',
    defaultMessage: 'Save',
  },
  settingUpdateSuccess: {
    id: 'app.containers.ChatbotWidget.settingUpdateSuccess',
    defaultMessage: 'Setting updated successfully',
  },
  settingUpdateFailed: {
    id: 'app.containers.ChatbotWidget.settingUpdateFailed',
    defaultMessage: 'Setting update failed',
  },
});
