/**
 *
 * Home
 *
 */

import { Loader } from '@fountain/fountain-ui-components';
import { useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { REACT_APP_LANDING_PAGE_URL } from 'runtimeEnvVars';

import { TRACKING_VISITOR_KEY } from 'utils/constants';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { getSessionItem } from 'utils/storageUtils';

import { whoamiInitAction } from '../Auth_old/actions';
import reducer from '../Auth_old/reducer';
import saga from '../Auth_old/saga';
import {
  makeSelectAccountSlug,
  makeSelectFirstJobId,
  makeSelectIsFetchingWhoami,
  makeSelectIsLoggedIn,
} from '../Auth_old/selectors';

const SIGN_IN_URL = REACT_APP_LANDING_PAGE_URL;
/* eslint-disable-next-line react/prefer-stateless-function */
const LandingPage = () => {
  useInjectReducer({ key: 'auth', reducer });
  useInjectSaga({ key: 'auth', saga });
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(makeSelectIsLoggedIn());
  const accountSlug = useSelector(makeSelectAccountSlug());
  const isLoading = useSelector(makeSelectIsFetchingWhoami());
  const jobId = useSelector(makeSelectFirstJobId());
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const applicantTableV2Url = `/${accountSlug}/jobs/${jobId}/v2/stages?page=1`;

  useEffect(() => {
    dispatch(whoamiInitAction());
  }, [dispatch]);

  const getRedirectUrl = () => {
    if (mobileDevice) return applicantTableV2Url;

    return `/${accountSlug}/openings`;
  };

  if (!isLoading) {
    if (isLoggedIn) return <Redirect to={getRedirectUrl()} />;

    const tracker = getSessionItem(TRACKING_VISITOR_KEY);
    window.location.replace(`${SIGN_IN_URL}?app=${tracker}`);
    return null;
  }
  return <Loader fullScreen size="2rem" />;
};

export default withRouter(LandingPage);
