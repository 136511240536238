import { makeStyles, Theme } from '@material-ui/core/styles';
import { ChatbotFeedback } from 'api-clients/monolith/models/ChatbotFeedback';

import { CORRECT, INCORRECT, PENDING } from 'containers/Chatbot/constants';

interface FeedbackCardProps {
  type: ChatbotFeedback;
}

const iconColor = {
  [CORRECT]: '#009E52',
  [INCORRECT]: '#E51F58',
  [PENDING]: 'white',
};

export const useStyles = makeStyles<Theme, FeedbackCardProps>(theme => ({
  feedbackButton: {
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.common.gray300}`,
    borderRadius: '5px',
    width: '40px',
    height: '40px',
    marginLeft: theme.spacing(1),
  },
  feedbackIcon: {
    width: '14px',
    height: '12px',
    color: props => iconColor[props.type],
  },
}));
