import { Button, Drag, Loader } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import {
  CancelablePromise,
  FunnelDetails,
  WorkflowEditorService,
  WorkflowMultiStage,
} from 'api-clients/monolith';
import { useSimpleToggle } from 'hooks';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addMessageAction } from 'containers/FlashMessage/actions';
import { AddItemRows } from 'containers/WorkflowEditor/components/AddItemRows';
import { ItemRow } from 'containers/WorkflowEditor/components/AddItemRows/ItemRow';
import { reorderStages } from 'containers/WorkflowEditor/components/StageColumn/utils';
import { StageSettingCard } from 'containers/WorkflowEditor/components/StageSettingCard';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';
import { useQueryState } from 'hooks/useQueryState';

import { AddSubstageModal } from './AddSubstageModal';
import { messages } from './messages';
import { useStyles } from './styles';
import { SubstageDrawer } from './SubstageDrawer';

export interface SubstagesCardProps {
  opening: FunnelDetails;
  stage: WorkflowMultiStage;
}

export const SubstagesCard: FC<SubstagesCardProps> = ({ opening, stage }) => {
  const styles = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const { funnelStages, setStage, refetchStage } = useContext(StageContext);
  const { additional_info: additionalInfo } = stage;

  const [selectedSubstageExternalId, setSelectedSubstageExternalId] =
    useQueryState('sub_stage');

  const {
    showContent: isSubstageModalOpen,
    on: openModal,
    off: closeModal,
  } = useSimpleToggle();

  const { mutation: deleteSubstage, result: deleteSubstageResult } =
    useApiServiceMutation<
      void,
      (stageExternalId: string) => CancelablePromise<void>,
      { errors: string[] }
      // eslint-disable-next-line @typescript-eslint/unbound-method
    >(WorkflowEditorService.deleteInternalApiWorkflowEditorStages, {
      onSuccess: () => {
        refetchStage();

        dispatch(
          addMessageAction(
            intl.formatMessage(messages.deleteSubstageSuccess),
            'success',
          ),
        );
      },
      onError: errorBody => {
        const errorMessages = errorBody?.errors?.join('. ') ?? '';

        dispatch(addMessageAction(errorMessages, 'error', false));
      },
    });

  const onEditSubstage = (externalId: string | number) => {
    setSelectedSubstageExternalId(externalId as string);
  };

  const handleCloseDrawer = () => {
    setSelectedSubstageExternalId(null);
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination || !stage.additional_info?.sub_stages) {
      return;
    }
    // No change required
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newStages = reorderStages({
      stages: stage.additional_info.sub_stages,
      startPosition: source.index,
      endPosition: destination.index,
    });
    setStage(
      produce(stage, draftStage => {
        draftStage.additional_info.sub_stages = newStages;
      }),
    );
  };

  return (
    <StageSettingCard
      variant="default"
      title={intl.formatMessage(messages.substages)}
    >
      {deleteSubstageResult.isLoading && <Loader fullScreen size="2rem" />}
      <Typography variant="body2" className={styles.description}>
        <FormattedMessage {...messages.bodyDescription} />
      </Typography>
      <AddItemRows
        emptyContentDescription={intl.formatMessage(
          messages.emptyContentDescription,
        )}
        isEmpty={!additionalInfo?.sub_stages?.length}
        errorText={intl.formatMessage(messages.substagesRequired)}
        addButton={
          <Button
            type="secondary"
            size="small"
            autoWidth
            className={styles.addButton}
            onClick={openModal}
          >
            <Typography variant="h4">
              <FormattedMessage {...messages.addSubstage} />
            </Typography>
          </Button>
        }
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="sub-stage">
            {provided => (
              <Grid
                container
                direction="column"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {additionalInfo?.sub_stages?.map((subStage, index) => (
                  <Grid className={styles.draggable} key={subStage.external_id}>
                    <Draggable
                      draggableId={subStage.external_id}
                      index={index + 1}
                    >
                      {(provided, snapshot) => (
                        <Grid
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ItemRow
                            icon={
                              <Drag
                                className={styles.dragIcon}
                                fontSize="small"
                              />
                            }
                            title={subStage.title}
                            type={subStage.short_type}
                            id={subStage.external_id}
                            onDelete={() =>
                              deleteSubstage(subStage.external_id)
                            }
                            onEdit={onEditSubstage}
                            confirmDeleteModalTitle={intl.formatMessage(
                              messages.deleteSubstage,
                            )}
                            confirmDeleteModalDescription={intl.formatMessage(
                              messages.deleteSubstageDescription,
                              {
                                subStageTitle: subStage.title,
                              },
                            )}
                            hoverable
                            dragging={snapshot.isDragging}
                          />
                        </Grid>
                      )}
                    </Draggable>
                  </Grid>
                ))}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      </AddItemRows>
      <SubstageDrawer
        funnelStages={funnelStages}
        handleClose={handleCloseDrawer}
        open={Boolean(selectedSubstageExternalId)}
        opening={opening}
        stageExternalId={selectedSubstageExternalId}
      />
      {isSubstageModalOpen && (
        <AddSubstageModal onClose={closeModal} stageId={stage.id} />
      )}
    </StageSettingCard>
  );
};
