import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  delete: {
    id: `app.containers.WorkflowEditor.components.Rules.PartnerRules.PartnerRuleCard.delete`,
    defaultMessage: 'Delete',
  },
  deleteRule: {
    id: `app.containers.WorkflowEditor.components.Rules.PartnerRules.PartnerRuleCard.deleteRule`,
    defaultMessage: 'Delete Rule',
  },
  ruleNumber: {
    id: `app.containers.WorkflowEditor.components.Rules.PartnerRules.PartnerRuleCard.ruleNumber`,
    defaultMessage: 'Rule {number}',
  },
});
