import produce from 'immer';

import {
  FETCH_MESSAGE_TEMPLATES_ERROR,
  FETCH_MESSAGE_TEMPLATES_INIT,
  FETCH_MESSAGE_TEMPLATES_SUCCESS,
} from './constants';

export const initialState = {
  isError: false,
  isLoading: false,
  messageTemplates: [],
  whatsAppTemplates: [],
};

const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case FETCH_MESSAGE_TEMPLATES_INIT:
        draft.isLoading = true;
        break;
      case FETCH_MESSAGE_TEMPLATES_SUCCESS:
        draft.isError = false;
        draft.messageTemplates = action.messageTemplates;
        draft.whatsAppTemplates = action.whatsAppTemplates;
        draft.isLoading = false;
        break;
      case FETCH_MESSAGE_TEMPLATES_ERROR:
        draft.isError = true;
        draft.isLoading = false;
        break;
      default:
        break;
    }
  });

export default reducer;
