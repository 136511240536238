import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  switchLabel: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.common.gray800,
  },
  fieldRow: {
    paddingBottom: theme.spacing(3),

    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cardBody: {
    padding: theme.spacing(0, 2),
  },
  description: {
    color: theme.palette.common.gray800,
    paddingBottom: theme.spacing(3),
    display: 'flex',
    gap: theme.spacing(0.5),
  },
}));
