import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  noTemplatesSection: {
    marginBottom: theme.spacing(4),
  },
  bodyTextColor: {
    color: theme.palette.common.gray800,
  },
}));
