import {
  JobMatcherCondition,
  JobMatcherConditionSet,
} from 'api-clients/monolith';
import { v4 as uuid } from 'uuid';

export const generateDefaultOperand: () => JobMatcherCondition = () => ({
  id: uuid(),
  resource: undefined,
  resource_ids: [],
});

export const mockJobRouterConditionOptions = {
  positions: [
    { id: 1, name: 'Align-gineer' },
    { id: 3, name: 'Electioneer' },
  ],
  location_groups: [{ id: 1, name: 'The Wheel' }],
  locations: [
    { id: 1, name: 'St Deborah by the Water' },
    { id: 2, name: 'Pingtree' },
  ],
  workflows: [{ id: 1, name: "Andy's Accountants" }],
};

export const mockJobRouterConditions: JobMatcherConditionSet = {
  bool_operator: 'AND',
  operands: [
    {
      id: 'Location-12',
      resource: 'Location',
      resource_ids: [1, 2],
    },
    {
      id: 'LocationGroup-1',
      resource: 'LocationGroup',
      resource_ids: [1],
    },
  ],
};
