import { IconButton } from '@fountain/fountain-ui-components';
import {
  Button,
  ButtonGroup,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { CancelablePromise, EventsService } from 'api-clients/monolith';
import { eventManagerContext } from 'Calendar/EventManager/context';
import { EventQuery } from 'Calendar/hooks';
import cx from 'classnames';
import { useSimpleToggle } from 'hooks';
import React, { useContext, useEffect, useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { FilterOpeningDropdown } from 'components/FilterDropdown/FilterOpeningDropdown';
import { FilterStageDropdown } from 'components/FilterDropdown/FilterStageDropdown';
import { Filterable } from 'components/FilterDropdown/util';
import { resetSelectedApplicantIds } from 'containers/ApplicantTableV2/actions';
import {
  addDefaultErrorMessageActionI18n,
  addMessageAction,
} from 'containers/FlashMessage/actions';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';
import { Reducer, useReducer } from 'hooks/useReducer';
import Ellipse from 'images/Ellipse.svg';

import Filter from './filter.svg';
import { messages } from './messages';
import { useStyles } from './styles';

export interface HeaderProps {
  onUpdate: (p: EventQuery) => void;
  onHandleCreate: () => void;
}

/* eslint-disable camelcase */
interface ExportBody {
  stage_title: Array<string>;
  funnel_ids: Array<string>;
  date: 'today' | 'upcoming' | 'past';
}

export const Header: VFC<HeaderProps> = ({ onUpdate, onHandleCreate }) => {
  const styles = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  useReducer(Reducer.APPLICANT_TABLE_V2)();

  const { filters, canManage } = useContext(eventManagerContext);
  const [stages, setStages] = useState<Filterable[]>(filters.stages);
  const [funnels, setFunnels] = useState<Filterable[]>(filters.funnels);
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
  const { toggle, showContent, off } = useSimpleToggle();

  const actions = ['past', 'today', 'upcoming'] as const;
  const setDate = (action: EventQuery['date']) =>
    onUpdate({
      ...filters,
      date: action,
      page: 0,
    });

  const { mutation: exportEvents } = useApiServiceMutation<
    void,
    ({ stage_title, funnel_ids, date }: ExportBody) => CancelablePromise<void>
    // eslint-disable-next-line @typescript-eslint/unbound-method
  >(EventsService.postInternalApiEventsExports, {
    onSuccess: () => {
      dispatch(
        addMessageAction(intl.formatMessage(messages.exportSuccess), 'success'),
      );
    },
    onError: () => {
      dispatch(addDefaultErrorMessageActionI18n(intl));
    },
  });

  const onExport = () => {
    exportEvents({
      stage_title: stages.map(stage => stage.id),
      funnel_ids: funnels.map(funnel => funnel.id),
      date: filters.date,
    });
  };
  /* eslint-enable camelcase */

  useEffect(() => {
    onUpdate({
      ...filters,
      stages,
      funnels,
      page: 0,
    });
    // TODO pass filters directly to dropdowns to avoid useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funnels, stages]);

  useEffect(() => {
    dispatch(resetSelectedApplicantIds());
  }, [dispatch]);

  const isFiltersApplied =
    filters.stages.length > 0 || filters.funnels.length > 0;

  return (
    <div className={styles.container}>
      <div className={styles.filters}>
        <div className={styles.date}>
          {actions.map(action => (
            <Button
              key={action}
              variant="text"
              onClick={() => setDate(action)}
              className={cx(styles.tabButton, {
                [styles.selected]: action === filters.date,
              })}
            >
              <Typography
                variant="h4"
                color="textPrimary"
                className={cx(styles.tabName, {
                  selected: action === filters.date,
                })}
              >
                <FormattedMessage {...messages[action]} />
              </Typography>
            </Button>
          ))}
          <Grid container className={styles.filterContainer}>
            {isMobile ? (
              <>
                <IconButton onClick={toggle}>
                  <img
                    src={Filter}
                    alt={intl.formatMessage(messages.openFilters)}
                  />
                  {isFiltersApplied && (
                    <img
                      src={Ellipse}
                      alt={intl.formatMessage(messages.filtersApplied)}
                      className={styles.enabledFilter}
                    />
                  )}
                </IconButton>
                <Drawer anchor="bottom" open={showContent} onClose={off}>
                  <div className={styles.drawer} role="presentation">
                    <List>
                      <ListItem button key="heading" onClick={off}>
                        <ListItemIcon>
                          <img
                            src={Filter}
                            alt={intl.formatMessage(messages.openFilters)}
                          />
                          {isFiltersApplied && (
                            <img
                              src={Ellipse}
                              alt={intl.formatMessage(messages.filtersApplied)}
                              className={styles.enabledFilter}
                            />
                          )}
                        </ListItemIcon>
                        <ListItemText primary="Filters" />
                      </ListItem>
                    </List>
                    <Divider />
                    <ButtonGroup className={styles.buttonGroup}>
                      <FilterOpeningDropdown
                        selected={funnels}
                        setSelected={setFunnels}
                      />
                      <FilterStageDropdown
                        selected={stages}
                        setSelected={setStages}
                      />
                    </ButtonGroup>
                  </div>
                </Drawer>
              </>
            ) : (
              <>
                <Button onClick={onExport}>
                  <FormattedMessage {...messages.export} />
                </Button>
                <Grid item className={styles.imageWrapper}>
                  <img
                    src={Filter}
                    alt={intl.formatMessage(messages.openFilters)}
                  />
                  {isFiltersApplied && (
                    <img
                      src={Ellipse}
                      alt={intl.formatMessage(messages.filtersApplied)}
                      className={styles.enabledFilter}
                    />
                  )}
                </Grid>

                <FilterOpeningDropdown
                  selected={funnels}
                  setSelected={setFunnels}
                />
                <FilterStageDropdown
                  selected={stages}
                  setSelected={setStages}
                />
              </>
            )}
          </Grid>
        </div>
      </div>
      {canManage && (
        <div className={styles.button}>
          <Button onClick={onHandleCreate}>
            <Typography>
              <FormattedMessage {...messages.createEvent} />
            </Typography>
          </Button>
        </div>
      )}
    </div>
  );
};
