import { Radio } from '@fountain/fountain-ui-components';
import { FormControlLabel, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import React, { VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { PHONE_PLATFORM_TYPES } from 'components/SmsMessage/constants';

import messages from './messages';
import useStyles from './styles';

export interface BulkChannelSwitchProps {
  phonePlatform: string;
  handleChannelChange: () => void;
}

const BulkChannelSwitch: VFC<BulkChannelSwitchProps> = ({
  phonePlatform,
  handleChannelChange,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <Typography variant="h3">
        <span>
          <FormattedMessage {...messages.selectChannel} />:{' '}
        </span>
        <FormControl>
          <RadioGroup
            className={classes.radioGroup}
            name="phone_platform"
            value={phonePlatform}
            onChange={handleChannelChange}
            data-testid="phone-platform"
            row
          >
            <FormControlLabel
              control={<Radio />}
              label="SMS"
              value={PHONE_PLATFORM_TYPES.sms}
              className={classes.radioForm}
            />
            &nbsp;
            <FormControlLabel
              control={<Radio />}
              label="WhatsApp"
              value={PHONE_PLATFORM_TYPES.whats_app}
              className={classes.radioForm}
            />
          </RadioGroup>
        </FormControl>
      </Typography>
    </div>
  );
};

export default BulkChannelSwitch;
