import { Grid, TableCell, Typography } from '@material-ui/core';
import React, { FC, memo } from 'react';
import { useIntl } from 'react-intl';

import { APPLICATION_LOCALE_WIDTH } from 'containers/ApplicantTableV2/constants';
import localesMessages from 'shared/global/messages';

import useStyles from './styles';

export interface ApplicantTableV2ApplicationLocaleCellProps {
  applicationLocale?: string | null;
}

export const ApplicantTableV2ApplicationLocaleCell: FC<
  ApplicantTableV2ApplicationLocaleCellProps
> = ({ applicationLocale }) => {
  const classes = useStyles();
  const intl = useIntl();

  let defaultLang = applicationLocale;
  const localeAsKey = applicationLocale?.replace('-', '') ?? 'enUS';
  if (Object.keys(localesMessages).includes(localeAsKey)) {
    defaultLang = `${intl.formatMessage(
      localesMessages[localeAsKey as keyof typeof localesMessages],
    )}`;
  }

  return (
    <TableCell
      role="cell"
      style={{
        minWidth: APPLICATION_LOCALE_WIDTH,
        maxWidth: APPLICATION_LOCALE_WIDTH,
      }}
      className={classes.td}
    >
      <Grid
        container
        justify="center"
        className={classes.applicationLocaleCell}
      >
        <Grid>
          <Typography>{defaultLang}</Typography>
        </Grid>
      </Grid>
    </TableCell>
  );
};

export default memo(ApplicantTableV2ApplicationLocaleCell);
