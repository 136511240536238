import { Location } from 'history';
import { useSimpleToggle } from 'hooks';
import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Prompt, useHistory, useLocation } from 'react-router-dom';

import { ConfirmationModal } from '../../../../components/ConfirmationModal';
import { messages } from './messages';

export interface Props {
  isMultiStage: boolean;
  isNavigationBlocked: boolean;
}

// NB: worth being aware of the console warning:
// "Warning: A history supports only one prompt at a time"
// due to rending this in 2 locations as a result of an additional nested <StageContextProvider />
// in the substage drawer.
// Everything works, so we'll evaluate a more complex solution as the need arises
export const UnsavedChangesPrompt: FC<Props> = ({
  isNavigationBlocked,
  isMultiStage,
}) => {
  const intl = useIntl();
  const location = useLocation();
  const history = useHistory();

  const [pendingLocation, setPendingLocation] = useState<Location>(location);
  const [hasConfirmedNavigation, setHasConfirmedNavigation] = useState(false);
  const [canUnload, setCanUnload] = useState(false);

  const {
    showContent: isModalOpen,
    on: openModal,
    off: closeModal,
  } = useSimpleToggle();

  const onConfirmNavigation = () => {
    closeModal();
    setHasConfirmedNavigation(true);
  };

  const handleOpenPromptModal = (nextLocation: Location) => {
    openModal();
    setPendingLocation(nextLocation);
  };

  const handleBlockedRoute = (nextLocation: Location) => {
    const isOnMultiStageNavigatingToSubstage =
      isMultiStage && nextLocation.search.includes('sub_stage=');
    if (
      !hasConfirmedNavigation &&
      isNavigationBlocked &&
      !isOnMultiStageNavigatingToSubstage
    ) {
      handleOpenPromptModal(nextLocation);
      return false;
    }

    return true;
  };

  const handleCloseModal = () => {
    closeModal();
    setCanUnload(false);
  };

  // Block react-router routes with our custom modal
  useEffect(() => {
    if (hasConfirmedNavigation && pendingLocation) {
      setCanUnload(true);
      history.push(pendingLocation.pathname);
    }
  }, [hasConfirmedNavigation, pendingLocation, history]);

  // Block non-react-router routes with a browser alert
  // (i.e. page refresh, monolith route, or a non-fountain link)
  // As of 08/10/2022:
  // The spec states that we should use `event.preventDefault()` to trigger the dialog,
  // but this does not yet have as widespread browser support as supplying a string in
  // `event.returnValue`. Only older browsers will attempt to display this custom string.
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#browser_compatibility
  useEffect(() => {
    const unload = (event: BeforeUnloadEvent) => {
      if (isNavigationBlocked && !canUnload) {
        // eslint-disable-next-line no-param-reassign
        event.returnValue = intl.formatMessage(messages.bodyText);
      }
      if (canUnload) {
        // eslint-disable-next-line no-param-reassign
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', unload);

    return () => window.removeEventListener('beforeunload', unload);
  }, [isNavigationBlocked, canUnload, intl]);

  return (
    <>
      <Prompt message={handleBlockedRoute} when />

      {isModalOpen && (
        <ConfirmationModal
          ariaLabelledBy={intl.formatMessage(messages.unsavedChangesModal)}
          onCancel={onConfirmNavigation}
          onClose={handleCloseModal}
          onConfirm={handleCloseModal}
          title={intl.formatMessage(messages.unsavedChanges)}
          bodyContent={<FormattedMessage {...messages.bodyText} />}
          cancelButtonContent={<FormattedMessage {...messages.discard} />}
          confirmButtonContent={
            <FormattedMessage {...messages.continueEditing} />
          }
        />
      )}
    </>
  );
};
