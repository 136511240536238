/**
 *
 * Material UI styles for MobileMessagePreview
 *
 *
 * @see https://material-ui.com/styles/basics
 *
 */

const styles = theme => ({
  mobileMessagePreview: {
    background: theme.palette.common.white,
  },
  mobilePhoneContainer: {
    height: 509.6,
    width: 247.4,
    borderRadius: 36,
    boxShadow: theme.customShadows.gray300,
  },
  mobilePhoneScreen: {
    height: 488.5,
    width: 225.6,
    backgroundColor: theme.palette.common.gray200,
    borderRadius: 24,
  },
  screenTopCover: {
    background: theme.palette.common.white,
    borderRadius: '0px 0px 13.6px 13.6px',
    height: 17.6,
    width: 121.2,
    margin: '0 auto',
  },
  messageContent: {
    height: 420.9,
    width: '100%',
  },
  messageTextPreview: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 2,
    height: '100%',
    minHeight: 326,
    borderBottomRightRadius: 22,
    borderBottomLeftRadius: 22,
    width: '100%',

    '&.sms': {
      height: 421,
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
    },
  },
  topGreyBlock: {
    height: 50,
    width: '100%',
    fontSize: theme.typography.fontSize,
    fontWeight: 600,
    color: theme.palette.common.gray800,
  },
});

export default styles;
