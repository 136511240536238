import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { SectionTitle } from './Components/SectionTitle';
import { messages } from './messages';

const useStyles = makeStyles(theme => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
  },
  description: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    lineHeight: '20px',
    fontWeight: 450,
  },
  codeWrapper: {
    border: `1px solid ${theme.palette.common.gray200}`,
    padding: theme.spacing(0, 2),
  },
  codeBlock: {
    backgroundColor: '#F1F1F1',
    overflow: 'scroll',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    height: '200px',
    fontSize: '13px',
  },
}));

export interface EmbedCodeProps {
  codeSnippet: string;
}

export const EmbedCode: React.VFC<EmbedCodeProps> = ({ codeSnippet }) => {
  const styles = useStyles();

  return (
    <section className={styles.section}>
      <SectionTitle value={messages.embedCodeHeader} />
      <Typography variant="body2" className={styles.description}>
        <FormattedMessage
          {...messages.embedCodeDescription}
          values={{ htmlBodyTag: '</body>' }}
        />
      </Typography>
      <Box className={styles.codeWrapper}>
        <pre className={styles.codeBlock}>{codeSnippet}</pre>
      </Box>
    </section>
  );
};
