import PropTypes from 'prop-types';
import { useEffect } from 'react';

import useScript from 'hooks/useScript';

const SCRIPT_SRC =
  'https://static.zdassets.com/ekr/snippet.js?key=580bf74d-4e3b-4d02-9ac5-ee0c0474c54c';

const SCRIPT_WITH_SUPPORT_CHAT_SRC =
  'https://static.zdassets.com/ekr/snippet.js?key=848814a4-c6f3-48fa-8f54-b9ab81a5c8a0';

function ZendeskWidget({ userName, userEmail, zendeskSupportChat }) {
  const scriptToInsert = zendeskSupportChat
    ? SCRIPT_WITH_SUPPORT_CHAT_SRC
    : SCRIPT_SRC;
  const { loading } = useScript(scriptToInsert, 'ze-snippet');

  useEffect(() => {
    if (!loading) {
      window.zE('webWidget', 'open');
      window.zE('webWidget', 'updatePath', {
        url: window.location.href,
      });

      window.zE('webWidget', 'prefill', {
        name: {
          value: userName,
        },
        email: {
          value: userEmail,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return null;
}

ZendeskWidget.propTypes = {
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  zendeskSupportChat: PropTypes.bool,
};

export default ZendeskWidget;
