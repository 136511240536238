import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  loadMore: {
    position: 'absolute',
    borderRadius: 20,
    boxShadow: `1px 1px 0px 0px ${theme.palette.common.blurOverlay}`,
    fontSize: 14,
    left: '54%',
    top: 60,
    height: 30,
  },
  loadMoreIcon: {
    fontSize: 'medium',
  },
}));
