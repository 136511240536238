/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Roster } from '../models/Roster';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ManageRosterService {

    /**
     * update booking and applicants
     * @param requestBody
     * @returns any successful
     * @throws ApiError
     */
    public static postInternalApiEventsRosters(
        requestBody: {
            available_slot_id: string;
            applicant_ids: Array<string>;
            action: 'attended' | 'advanced' | 'absent';
        },
    ): CancelablePromise<{
        available_slot_id: string;
        action: 'attended' | 'advanced' | 'absent';
        roster: Array<Roster>;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/events_rosters',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get Booked Slots for Event
     * Fetches Booked Slots for a given Event
     * @param externalId Event External ID
     * @param stageTitle Orientation Day
     * @param funnelIds abc-124
     * @param selectedColumns applicant_showed,complete
     * @returns Roster successful
     * @throws ApiError
     */
    public static getInternalApiEventsRosters(
        externalId: string,
        stageTitle?: Array<string>,
        funnelIds?: Array<string>,
        selectedColumns?: Array<string>,
    ): CancelablePromise<Array<Roster>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/events_rosters/{external_id}',
            path: {
                'external_id': externalId,
            },
            query: {
                'stage_title[]': stageTitle,
                'funnel_ids[]': funnelIds,
                'selected_columns[]': selectedColumns,
            },
        });
    }

}