import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import { addMessageAction } from 'containers/FlashMessage/actions';
import { MessageTemplatesVariant } from 'containers/TemplateMenu/constants';
import { apiGet } from 'utils/axios';

export default function useFetchMessageTemplates() {
  const dispatch = useDispatch();
  const [templates, setTemplates] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState('');

  const fetchMessageTemplates = async (
    templateType: string,
    templateVariant: string,
    applicantId: string,
  ) => {
    const queryParams = new URLSearchParams();

    switch (templateVariant) {
      case MessageTemplatesVariant.EMAIL:
        queryParams.set('email_enabled', 'true');
        break;
      case MessageTemplatesVariant.SMS:
        queryParams.set('sms_enabled', 'true');
        break;
      case MessageTemplatesVariant.WHATSAPP:
        queryParams.set('whats_app_enabled', 'true');
        break;
      default:
        break;
    }

    if (templateType) {
      queryParams.set('type', templateType);
    }

    if (applicantId) {
      queryParams.set('external_id', applicantId);
    }

    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/message_templates?${queryParams}`;
    setIsFetching(true);

    try {
      const response: {
        // eslint-disable-next-line camelcase
        data: { message_templates: []; whats_app_templates: [] };
      } = await apiGet(url);
      setTemplates(
        templateVariant === MessageTemplatesVariant.WHATSAPP
          ? response.data.whats_app_templates
          : response.data.message_templates,
      );
    } catch (exception) {
      setError(exception as string);
      dispatch(
        addMessageAction(
          'An error occurred while fetching message templates',
          'error',
        ),
      );
      // return { templates };
    } finally {
      setIsFetching(false);
    }
  };

  return { fetchMessageTemplates, isFetching, error, templates };
}
