import { WorkflowEditorService } from 'api-clients/monolith';
import { useApiService } from 'hooks';
import React from 'react';

export function useArchivedReasons() {
  const fetchArchivedReasons = React.useCallback(
    () => WorkflowEditorService.getInternalApiWorkflowEditorArchivedReasons(),
    [],
  );

  return useApiService(fetchArchivedReasons);
}
