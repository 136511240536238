import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  connect: {
    id: `app.components.WorkflowEditorContent.StageDetail.PartnerStage.PartnerIntegrationCard.PartnerIntegrationNotConnectedCard.connect`,
    defaultMessage: 'Connect',
  },
  connectToPartner: {
    id: `app.components.WorkflowEditorContent.StageDetail.PartnerStage.PartnerIntegrationCard.PartnerIntegrationNotConnectedCard.connectToPartner`,
    defaultMessage: 'Connect to {partnerName} to configure options.',
  },
  mustBeConnected: {
    id: `app.components.WorkflowEditorContent.StageDetail.PartnerStage.PartnerIntegrationCard.PartnerIntegrationNotConnectedCard.mustBeConnected`,
    defaultMessage:
      'Must be connected to the partner integration, please connect.',
  },
});
