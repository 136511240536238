import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  table: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  tableHead: {
    backgroundColor: theme.palette.common.gray100,
    height: 40,
  },
  tableRow: {
    cursor: 'pointer',
    borderBottom: theme.customBorders.gray200,
    height: 48,
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },

    '& > th': {
      padding: theme.spacing(0, 2),
    },
  },
  tableCell: {
    padding: theme.spacing(1, 2),
    borderBottom: 'none',
    '& > svg': {
      fontSize: 'inherit',
    },
  },
  actionCell: {
    width: '48px',
    padding: theme.spacing(0),
  },
  actionIcon: {
    height: '16px',
    width: '16px',
    color: theme.palette.common.gray600,
  },
  header: {
    padding: theme.spacing(2, 0, 2),
  },
  messageContainer: {
    flexGrow: 1,
  },
  cellContent: {
    fontSize: theme.typography.caption.fontSize,
    whiteSpace: 'pre-wrap',
  },
}));
