import { getReversedDashedDateUS } from 'utils/stringutils';

import { MergedDetail } from '../types';

export type SetValue = (value?: MergedDetail['value']) => void;
type SetIsDirty = (value: boolean) => void;
type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

const onAvailabilityChange =
  (setValue: SetValue, value: MergedDetail['value'], setIsDirty: SetIsDirty) =>
  (event: ChangeEvent) => {
    if (setIsDirty) {
      setIsDirty(true);
    }
    const selectedAvailability = event.target.value;
    if (Array.isArray(value)) {
      const selectedOptionsCopy = value.slice();
      if (value.includes(selectedAvailability)) {
        const newValue = selectedOptionsCopy.filter(
          option => option !== selectedAvailability,
        );
        setValue(newValue);
      } else {
        selectedOptionsCopy.push(selectedAvailability);
        setValue(selectedOptionsCopy);
      }
    }
  };

const onDateChange =
  (setValue: SetValue, setIsDirty: SetIsDirty) => (date: string) => {
    if (setIsDirty) {
      setIsDirty(true);
    }
    setValue(getReversedDashedDateUS(date) || undefined);
  };

const onCheckboxChange =
  (value: MergedDetail['value'], setValue: SetValue, setIsDirty: SetIsDirty) =>
  (event: ChangeEvent) => {
    if (setIsDirty) {
      setIsDirty(true);
    }
    const clickedBox = event.target.value;
    if (!value) {
      setValue([clickedBox]);
      return;
    }
    if (Array.isArray(value)) {
      if (value.includes(clickedBox)) {
        setValue(value.filter(el => el !== clickedBox));
      } else {
        setValue(value.concat(clickedBox));
      }
    }
  };

const onDropdownChange =
  (setValue: SetValue, setIsDirty: SetIsDirty) => (selectedValue: string) => {
    if (setIsDirty) {
      setIsDirty(true);
    }
    setValue(selectedValue);
  };

const onAddressChange = (
  newValue: MergedDetail['value'],
  setValue: SetValue,
  setIsDirty: SetIsDirty,
) => {
  if (setIsDirty) {
    setIsDirty(true);
  }
  setValue(newValue);
};

const onValueChange =
  (setValue: SetValue, setIsDirty: SetIsDirty) => (event: ChangeEvent) => {
    if (setIsDirty) {
      setIsDirty(true);
    }
    setValue(event.target.value);
  };

const formatValue = (
  detail: MergedDetail,
  value: MergedDetail['value'],
): MergedDetail['value'] => {
  if (Array.isArray(value) || detail.type === 'address') {
    return value;
  }
  if (value && typeof value === 'object' && 'value' in value) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return value.value;
  }
  return value;
};

export {
  formatValue,
  onAddressChange,
  onAvailabilityChange,
  onCheckboxChange,
  onDateChange,
  onDropdownChange,
  onValueChange,
};
