/**
 *
 * ApplicantTableV2UserCell
 *
 */

import { Loader, StyledReactSelect } from '@fountain/fountain-ui-components';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Initials from 'components/Initials';
import useApplicantAssignableUsers from 'hooks/useApplicantAssignableUsers';
import useApplicantAssignUser from 'hooks/useApplicantAssignUser';
import globalMessages from 'shared/global/messages';

import { UNASSIGN_USER_OPTION } from './constants';
import messages from './messages';
import useStyles from './styles';

function ApplicantTableV2UserCell({ applicantId, assignedUser }) {
  const classes = useStyles();
  const intl = useIntl();

  const [shouldFetchAssignableUsers, setShouldFetchAssignableUsers] =
    useState(false);

  const { isFetching, data } = useApplicantAssignableUsers(
    applicantId,
    shouldFetchAssignableUsers,
  );
  const { isLoading, postData, userData } = useApplicantAssignUser(
    applicantId,
    assignedUser,
  );

  const handleSelectUser = selectedUser => {
    setShouldFetchAssignableUsers(false);

    const getSelectedUser =
      selectedUser.id === UNASSIGN_USER_OPTION.id ? userData : selectedUser;

    postData(getSelectedUser);
  };

  const toggleUsersDropdown = () => {
    setShouldFetchAssignableUsers(!shouldFetchAssignableUsers);
  };

  const userCircle = () => (
    <div
      role="presentation"
      className={classes.userSelectCircle}
      onClick={toggleUsersDropdown}
      aria-label={intl.formatMessage(messages.assignUser)}
    >
      {userData ? (
        <Initials name={userData.name} color="avatar" variant="outlined" />
      ) : (
        <Typography className={classes.badgePlus} variant="body1">
          +
        </Typography>
      )}
    </div>
  );

  const userOptions = () => {
    const users = (data && [...data.users]) || [];

    if (userData) {
      users.unshift(UNASSIGN_USER_OPTION);
    }

    return users;
  };

  const getNoOptionsMessage = () => (
    <FormattedMessage {...messages.noOptionsAvailable} />
  );

  const loadingOption = [{ id: 'loading', name: <Loader /> }];

  return (
    <TableCell role="cell" className={classes.td} padding="none">
      <StyledReactSelect
        autoFocus
        className={classes.usersDropdown}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        inlineSearch
        isLoading={isFetching}
        onChange={handleSelectUser}
        options={isFetching ? loadingOption : userOptions()}
        placeholder={
          <span>{intl.formatMessage(globalMessages.searchUsers)}...</span>
        }
        noOptionsMessage={getNoOptionsMessage}
        value={userData}
        target={isLoading ? <Loader block /> : userCircle()}
      />
    </TableCell>
  );
}

ApplicantTableV2UserCell.propTypes = {
  applicantId: PropTypes.string,
  assignedUser: PropTypes.object,
};

export default memo(ApplicantTableV2UserCell);
