import { Dropdown, Input } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import { WorkflowStageDetail } from 'api-clients/monolith';
import cx from 'classnames';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Error } from 'components/Error';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import {
  AVAILABLE_RAM_OPTIONS,
  NO_OF_CORES,
  PROCESSOR_SPEED,
  RequirementBasicInputField,
  SCREEN_HEIGHT,
  SCREEN_WIDTH,
} from './constants';
import { messages } from './messages';
import { useStyles } from './styles';

export interface HardwareInputsProps {
  ram?: string;
  noOfCores?: string;
  processorSpeed?: string;
  screenWidth?: string;
  screenHeight?: string;
  settingId: number | string;
}

export const HardwareInputs: FC<HardwareInputsProps> = React.memo(
  ({
    ram,
    noOfCores,
    processorSpeed,
    screenWidth,
    screenHeight,
    settingId,
  }) => {
    const intl = useIntl();
    const styles = useStyles();

    const { updateStageResult, setStage } = useContext(StageContext);
    const errors =
      (updateStageResult?.isError && updateStageResult.error?.errors) ||
      undefined;

    const onChangeInput =
      (key: RequirementBasicInputField) =>
      (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = event.target;
        setStage(
          produce((draftStage: WorkflowStageDetail) => {
            if (
              draftStage.type === 'TechCheckStage' &&
              draftStage.additional_info?.settings
            ) {
              const draftSetting = draftStage.additional_info.settings.find(
                s => s.id === settingId,
              );
              if (draftSetting) {
                draftSetting[key] = value;
              }
            }
          }),
        );
      };

    const onChangeRam = (ram: string) => {
      setStage(
        produce((draftStage: WorkflowStageDetail) => {
          if (
            draftStage.type === 'TechCheckStage' &&
            draftStage.additional_info?.settings
          ) {
            const draftSetting = draftStage.additional_info.settings.find(
              s => s.id === settingId,
            );
            if (draftSetting) {
              draftSetting.ram = ram;
            }
          }
        }),
      );
    };

    return (
      <>
        <Grid>
          <Typography variant="body2" className={styles.heading}>
            <FormattedMessage {...messages.hardwareHeading} />
          </Typography>
        </Grid>
        <Grid className={styles.hardwareContainer}>
          <Grid
            className={cx(styles.hardwareSpecSelect, {
              [styles.inputErrorPresent]:
                (!ram && errors?.['settings.ram']) ||
                (!noOfCores && errors?.['settings.no_of_cores']) ||
                (!processorSpeed && errors?.['settings.processor_speed']),
            })}
          >
            <Dropdown
              options={AVAILABLE_RAM_OPTIONS}
              value={ram}
              onChange={onChangeRam}
              label={intl.formatMessage(messages.ram)}
              aria-label={intl.formatMessage(messages.ram)}
              isSearchable={false}
              error={Boolean(!ram && errors?.['settings.ram'])}
              required
            />
            <Error
              error={
                !ram &&
                errors?.['settings.ram'] &&
                intl.formatMessage(messages.addRam)
              }
              align="right"
            />
          </Grid>
          <Grid
            className={cx(styles.hardwareSpecSelect, {
              [styles.inputErrorPresent]:
                (!ram && errors?.['settings.ram']) ||
                (!noOfCores && errors?.['settings.no_of_cores']) ||
                (!processorSpeed && errors?.['settings.processor_speed']),
            })}
          >
            <Input
              aria-label={intl.formatMessage(messages.processor)}
              label={intl.formatMessage(messages.processor)}
              value={noOfCores}
              onChange={onChangeInput(NO_OF_CORES)}
              error={Boolean(!noOfCores && errors?.['settings.no_of_cores'])}
              required
            />
            <Error
              error={
                !noOfCores &&
                errors?.['settings.no_of_cores'] &&
                intl.formatMessage(messages.addProcessor)
              }
              align="right"
            />
          </Grid>
          <Grid
            className={cx(styles.hardwareSpecSelect, {
              [styles.inputErrorPresent]:
                (!ram && errors?.['settings.ram']) ||
                (!noOfCores && errors?.['settings.no_of_cores']) ||
                (!processorSpeed && errors?.['settings.processor_speed']),
            })}
          >
            <Input
              aria-label={intl.formatMessage(messages.speed)}
              label={intl.formatMessage(messages.speed)}
              value={processorSpeed}
              onChange={onChangeInput(PROCESSOR_SPEED)}
              error={Boolean(
                !processorSpeed && errors?.['settings.processor_speed'],
              )}
              required
            />
            <Error
              error={
                !processorSpeed &&
                errors?.['settings.processor_speed'] &&
                intl.formatMessage(messages.addSpeed)
              }
              align="right"
            />
          </Grid>
        </Grid>
        <Grid>
          <Grid className={styles.screenResolutionLabel}>
            <Typography variant="body2">
              <FormattedMessage {...messages.screenResolution} />
            </Typography>
            <Typography variant="body2" color="error">
              *
            </Typography>
          </Grid>
          <Grid className={styles.screenResolutionContainer}>
            <Grid
              className={cx(styles.screenResolutionInput, {
                [styles.inputErrorPresent]:
                  (!screenWidth && errors?.['settings.screen_width']) ||
                  (!screenHeight && errors?.['settings.screen_height']),
              })}
            >
              <Input
                aria-label={intl.formatMessage(messages.screenWidth)}
                value={screenWidth}
                onChange={onChangeInput(SCREEN_WIDTH)}
                error={Boolean(
                  !screenWidth && errors?.['settings.screen_width'],
                )}
              />
              <Error
                error={
                  !screenWidth &&
                  errors?.['settings.screen_width'] &&
                  intl.formatMessage(messages.addWidth)
                }
                align="right"
              />
            </Grid>
            <Grid
              className={cx({
                [styles.screenResolutionX]:
                  (!screenWidth && errors?.['settings.screen_width']) ||
                  (!screenHeight && errors?.['settings.screen_height']),
              })}
            >
              X
            </Grid>
            <Grid
              className={cx(styles.screenResolutionInput, {
                [styles.inputErrorPresent]:
                  (!screenWidth && errors?.['settings.screen_width']) ||
                  (!screenHeight && errors?.['settings.screen_height']),
              })}
            >
              <Input
                aria-label={intl.formatMessage(messages.screenHeight)}
                value={screenHeight}
                onChange={onChangeInput(SCREEN_HEIGHT)}
                error={Boolean(
                  !screenHeight && errors?.['settings.screen_height'],
                )}
              />
              <Error
                error={
                  !screenHeight &&
                  errors?.['settings.screen_height'] &&
                  intl.formatMessage(messages.addHeight)
                }
                align="right"
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  },
);
