import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  northpassIntegration: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchoolkeepStage.IntegrationCard.northpassIntegration`,
    defaultMessage: 'Northpass Integration',
  },
  manageIntegration: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchoolkeepStage.IntegrationCard.manageIntegration`,
    defaultMessage: 'Manage Integration',
  },
  course: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchoolkeepStage.IntegrationCard.course`,
    defaultMessage: 'Course',
  },
  courseRequired: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchoolkeepStage.IntegrationCard.courseRequired`,
    defaultMessage: 'Select a course',
  },
  automaticallyMoveAfterLearningCompleted: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchoolkeepStage.IntegrationCard.automaticallyMoveAfterLearningCompleted`,
    defaultMessage:
      'Automatically move applicant to next stage after learning is completed (recommended)',
  },
  automaticallyMoveAfterAQuizCompleted: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchoolkeepStage.IntegrationCard.automaticallyMoveAfterAQuizCompleted`,
    defaultMessage:
      'Automatically move applicant to next stage after a quiz is completed',
  },
});
