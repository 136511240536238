import {
  WorkflowDocumentSignatureStage,
  WorkflowDocumentSigningStage,
} from 'api-clients/monolith';
import React, { useContext, VFC } from 'react';

import { AdvancedSettings } from 'containers/WorkflowEditor/components/AdvancedSettings';
import { StageSection } from 'containers/WorkflowEditor/components/StageSection';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';
import { RulesEditDataContextProvider } from 'containers/WorkflowEditor/RulesEditDataContextProvider/RulesEditDataContextProvider';

import { IdleMoveRule } from '../../../IdleMoveRule';
import { StageSettingMessageCard } from '../../../StageSettingMessageCard';
import { Documents } from './Documents';
import { Settings } from './Settings';

export interface DocumentSigningStageProps {
  stage: WorkflowDocumentSigningStage | WorkflowDocumentSignatureStage;
}

export const DocumentSigningStage: VFC<DocumentSigningStageProps> = ({
  stage,
}) => {
  const {
    setStage,
    documentSigningRules,
    setDocumentSigningRules,
    updateStageResult,
  } = useContext(StageContext);

  const isSubstage = stage.parent_stage_id;

  return (
    <div data-testid="document-signing-stage">
      <StageSection>
        <Settings stage={stage} setStage={setStage} />
      </StageSection>
      <StageSection>
        <RulesEditDataContextProvider externalId={stage.external_id}>
          <Documents
            stage={stage}
            setStage={setStage}
            documentSigningRules={documentSigningRules}
            setDocumentSigningRules={setDocumentSigningRules}
            updateStageResult={updateStageResult}
          />
        </RulesEditDataContextProvider>
      </StageSection>
      {!isSubstage && (
        <>
          <StageSection>
            <IdleMoveRule stage={stage} />
          </StageSection>
          <StageSection>
            <StageSettingMessageCard
              messageType="document_signing"
              stageExternalId={stage.external_id}
            />
          </StageSection>
          <StageSection>
            <StageSettingMessageCard
              messageType="trigger"
              stageExternalId={stage.external_id}
            />
          </StageSection>
        </>
      )}
      {stage.account_go_enabled && (
        <StageSection>
          <AdvancedSettings stage={stage} />
        </StageSection>
      )}
    </div>
  );
};
