import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: 'inherit',
  },
  hiddenOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
      visibility: 'hidden',
    },
  },
  messageLockupWithCount: {
    width: '58px',
    height: '32px',
  },
  messageLockupWithoutCount: {
    width: '32px',
    height: '32px',
  },
  messageLockup: {
    padding: theme.spacing(1),
    borderRadius: '5px',
    display: 'inline-flex',
    '&:hover': {
      backgroundColor: theme.palette.common.gray200,
    },
    '&:active': {
      backgroundColor: theme.palette.common.gray300,
    },
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textDecoration: 'none',
  },
  messageIcon: {
    width: '16px',
    height: '16px',
    paddingTop: '2px',
  },
  threadCountBadge: {
    borderRadius: '5px',
    backgroundColor: theme.palette.common.blue400,
    width: '20px',
    height: '16px',
    padding: '1px',
    textAlign: 'center',
    '&:active': {
      backgroundColor: theme.palette.common.blue600,
    },
  },
  threadCountText: {
    color: theme.palette.common.white,
    fontSize: '10px',
    lineHeight: '14px',
    fontWeight: 500,
  },
}));

export default useStyles;
