import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  checkIcon: {
    height: '18px',
  },
  activeIcon: {
    color: theme.palette.common.green400,
  },
  stealthIcon: {
    color: theme.palette.common.orange400,
  },
  label: {
    width: 'fit-content',
    backgroundColor: theme.palette.common.gray200,
    color: theme.palette.common.gray800,
    borderRadius: '25px',
    padding: theme.spacing(0.5, 1),
    fontSize: '13px',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: theme.spacing(1),
  },
  active: {
    backgroundColor: theme.palette.common.green100,
  },
  stealth: {
    backgroundColor: theme.palette.common.orange100,
  },
  suggestion: {
    backgroundColor: theme.palette.common.blue100,
  },
}));
