import { Typography } from '@material-ui/core';
import { WorkflowStageDetail } from 'api-clients/monolith';
import React, { VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { StageSettingCard } from 'containers/WorkflowEditor/components/StageSettingCard';

import { Header, SchedulerUsersTable, User } from './components';
import { Option } from './components/SessionHostsDropdown/SessionHostsDropdown';
import { messages } from './messages';
import { useStyles } from './styles';

export interface SessionHostsCardProps {
  removeUser: (id?: number) => void;
  toggleUser: (id?: number) => void;
  setStage: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
  addUser: (option: Option | null) => void;
  hasNoUsers: boolean;
  disallowaddUser: boolean;
  schedulerUsers: User[];
  options: Option[];
}

export const SchedulerUsersCard: VFC<SessionHostsCardProps> = ({
  removeUser,
  toggleUser,
  addUser,
  hasNoUsers,
  disallowaddUser,
  schedulerUsers,
  options,
}) => {
  const intl = useIntl();
  const styles = useStyles();

  if (hasNoUsers) {
    return (
      <StageSettingCard
        variant="default"
        title={intl.formatMessage(messages.interviewers)}
      >
        <div className={styles.cardContent}>
          <Header
            options={options}
            addUser={addUser}
            disallowaddUser={disallowaddUser}
          />
          <Typography align="center" variant="h3">
            <FormattedMessage {...messages.noInterviewers} />
          </Typography>
          <Typography align="center" className={styles.text} variant="body2">
            <FormattedMessage {...messages.addUsers} />
          </Typography>
        </div>
      </StageSettingCard>
    );
  }

  return (
    <StageSettingCard
      variant="default"
      title={intl.formatMessage(messages.interviewers)}
    >
      <div className={styles.cardContent}>
        <Header
          options={options}
          addUser={addUser}
          disallowaddUser={disallowaddUser}
        />
        <SchedulerUsersTable
          toggleUser={toggleUser}
          users={schedulerUsers}
          removeUser={removeUser}
        />
      </div>
    </StageSettingCard>
  );
};
