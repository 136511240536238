import { FountainLocation } from '@fountain/types/base';
import { Menu } from '@material-ui/core';
import classNames from 'classnames';
import invariant from 'invariant';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  makeSelectAccountSlug,
  makeSelectBrands,
} from 'containers/Auth_old/selectors';
import { JobSearch } from 'containers/JobSearch';
import { Funnel } from 'containers/Messenger/types';
import { generateLink } from 'utils/urlUtils';

import { useStyles } from './styles';

export interface JobSearchMenuProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  shouldShowActiveFilter?: boolean;
  jobSearchMenuClassName?: string;
}

export const JobSearchMenu: FC<JobSearchMenuProps> = ({
  anchorEl,
  onClose,
  shouldShowActiveFilter,
  jobSearchMenuClassName,
}) => {
  const accountSlug = useSelector(makeSelectAccountSlug());
  const brands = useSelector(makeSelectBrands());
  const history = useHistory();
  const styles = useStyles();

  const handleSelected = (location: FountainLocation, funnel: Funnel) => {
    invariant(accountSlug, 'accountSlug is missing');

    const link = generateLink(location, funnel, brands, accountSlug);
    onClose();
    history.push(link);
  };

  return (
    <Menu
      MenuListProps={{
        disablePadding: true,
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      classes={{
        paper: classNames(styles.jobSearchMenuPaper, jobSearchMenuClassName),
      }}
      elevation={0}
      getContentAnchorEl={null}
      open={!!anchorEl}
      onClose={onClose}
    >
      <JobSearch
        shouldShowActiveFilter={shouldShowActiveFilter}
        focus
        onSelected={handleSelected}
      />
    </Menu>
  );
};
