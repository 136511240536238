import { Applicant } from 'containers/ApplicantTableV2/types';
import {
  DialogType,
  Reason as ReasonType,
} from 'containers/MoveApplicantDialog/constants';
import { AutomatedActions, Reason } from 'hooks/useAutomatedActions';

export const scope = 'app/MoveApplicantDialog';

export const ActionType = {
  RESET_STATE: `${scope}/RESET_STATE`,
  SET_DATA: `${scope}/SET_DATA`,
  SET_AUTOMATED_ACTIONS_RUN_DISABLED: `${scope}/SET_AUTOMATED_ACTIONS_RUN_DISABLED`,
  SET_AUTOMATED_ACTIONS_RUN_ENABLED: `${scope}/SET_AUTOMATED_ACTIONS_RUN_ENABLED`,
  SET_AUTOMATED_ACTIONS_TO_STAGE: `${scope}/SET_AUTOMATED_ACTIONS_TO_STAGE`,
  SET_AUTOMATED_ACTIONS_VISIBLE: `${scope}/SET_AUTOMATED_ACTIONS_VISIBLE`,
  SET_JOB_STAGE_SELECTORS_HIDDEN: `${scope}/SET_JOB_STAGE_SELECTORS_HIDDEN`,
  SET_JOB_STAGE_SELECTORS_JOB: `${scope}/SET_JOB_STAGE_SELECTORS_JOB`,
  SET_JOB_STAGE_SELECTORS_STAGE: `${scope}/SET_JOB_STAGE_SELECTORS_STAGE`,
  SET_JOB_STAGE_SELECTORS_VISIBLE: `${scope}/SET_JOB_STAGE_SELECTORS_VISIBLE`,
  SET_REASON_SELECTOR_DEFAULT_REASON: `${scope}/SET_REASON_SELECTOR_DEFAULT_REASON`,
  SET_REASON_SELECTOR_REASON: `${scope}/SET_REASON_SELECTOR_REASON`,
  SET_REASON_SELECTOR_TYPE: `${scope}/SET_REASON_SELECTOR_TYPE`,
  SET_REASON_SELECTOR_VISIBLE: `${scope}/SET_REASON_SELECTOR_VISIBLE`,
  SET_REASON_SELECTOR_HIDDEN: `${scope}/SET_REASON_SELECTOR_HIDDEN`,
  SET_DIALOG_TYPE: `${scope}/SET_DIALOG_TYPE`,
  SET_DIALOG_OPEN: `${scope}/SET_DIALOG_OPEN`,
  SET_DIALOG_CLOSE: `${scope}/SET_DIALOG_CLOSE`,
  SET_LOADING_DISABLED: `${scope}/SET_LOADING_DISABLED`,
  SET_LOADING_ENABLED: `${scope}/SET_LOADING_ENABLED`,
  SET_SUBMIT_DISABLED: `${scope}/SET_SUBMIT_DISABLED`,
  SET_SUBMIT_ENABLED: `${scope}/SET_SUBMIT_ENABLED`,
  SET_SUBMIT_LOADING_DISABLED: `${scope}/SET_SUBMIT_LOADING_DISABLED`,
  SET_SUBMIT_LOADING_ENABLED: `${scope}/SET_SUBMIT_LOADING_ENABLED`,
};

interface SetDataPayload {
  isValidationSaved?: boolean;
  warnW4Federal?: boolean;
  warnWotc?: boolean;
  rawQueryString?: string;
  applicantCount?: number;
  applicantId?: string;
  applicantName?: string;
  applicants?: Record<string, Applicant | undefined>;
  isBulk?: boolean;
  isMasterApplicantsView?: boolean;
  selectedApplicantIds?: string | string[];
  toStageId?: string;
  fromJobId?: string;
  toJobId?: string;
}

export const ActionCreator = {
  resetState: () => ({ type: ActionType.RESET_STATE }),
  setData: (payload: SetDataPayload) => ({
    payload,
    type: ActionType.SET_DATA,
  }),
  setAutomatedActionsRunDisabled: () => ({
    type: ActionType.SET_AUTOMATED_ACTIONS_RUN_DISABLED,
  }),
  setAutomatedActionsRunEnabled: () => ({
    type: ActionType.SET_AUTOMATED_ACTIONS_RUN_ENABLED,
  }),
  setAutomatedActionsToStage: (payload: Partial<AutomatedActions>) => ({
    payload,
    type: ActionType.SET_AUTOMATED_ACTIONS_TO_STAGE,
  }),
  setAutomatedActionsVisible: () => ({
    type: ActionType.SET_AUTOMATED_ACTIONS_VISIBLE,
  }),
  setJobStageSelectorsHidden: () => ({
    type: ActionType.SET_JOB_STAGE_SELECTORS_HIDDEN,
  }),
  setJobStageSelectorsJob: (payload: { title?: string; id?: string }) => ({
    payload,
    type: ActionType.SET_JOB_STAGE_SELECTORS_JOB,
  }),
  setJobStageSelectorsStage: (payload: { name?: string; id?: string }) => ({
    payload,
    type: ActionType.SET_JOB_STAGE_SELECTORS_STAGE,
  }),
  setJobStageSelectorsVisible: () => ({
    type: ActionType.SET_JOB_STAGE_SELECTORS_VISIBLE,
  }),
  setReasonSelectorReason: (payload: Partial<Reason>) => ({
    payload,
    type: ActionType.SET_REASON_SELECTOR_REASON,
  }),
  setReasonSelectorDefaultReason: (payload: Reason) => ({
    payload,
    type: ActionType.SET_REASON_SELECTOR_DEFAULT_REASON,
  }),
  setReasonSelectorType: (payload: ReasonType) => ({
    payload,
    type: ActionType.SET_REASON_SELECTOR_TYPE,
  }),
  setReasonSelectorVisible: () => ({
    type: ActionType.SET_REASON_SELECTOR_VISIBLE,
  }),
  setReasonSelectorHidden: () => ({
    type: ActionType.SET_REASON_SELECTOR_HIDDEN,
  }),
  setDialogType: (payload: DialogType) => ({
    payload,
    type: ActionType.SET_DIALOG_TYPE,
  }),
  setDialogOpen: () => ({ type: ActionType.SET_DIALOG_OPEN }),
  setDialogClose: () => ({ type: ActionType.SET_DIALOG_CLOSE }),
  setLoadingDisabled: () => ({ type: ActionType.SET_LOADING_DISABLED }),
  setLoadingEnabled: () => ({ type: ActionType.SET_LOADING_ENABLED }),
  setSubmitDisabled: () => ({ type: ActionType.SET_SUBMIT_DISABLED }),
  setSubmitEnabled: () => ({ type: ActionType.SET_SUBMIT_ENABLED }),
  setSubmitLoadingDisabled: () => ({
    type: ActionType.SET_SUBMIT_LOADING_DISABLED,
  }),
  setSubmitLoadingEnabled: () => ({
    type: ActionType.SET_SUBMIT_LOADING_ENABLED,
  }),
};
