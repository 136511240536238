export const DOWNLOAD_SPEED = 'download_speed';
export const NO_OF_CORES = 'no_of_cores';
export const PROCESSOR_SPEED = 'processor_speed';
export const SCREEN_HEIGHT = 'screen_height';
export const SCREEN_WIDTH = 'screen_width';
export const UPLOAD_SPEED = 'upload_speed';

export type RequirementBasicInputField =
  | typeof DOWNLOAD_SPEED
  | typeof NO_OF_CORES
  | typeof PROCESSOR_SPEED
  | typeof SCREEN_HEIGHT
  | typeof SCREEN_WIDTH
  | typeof UPLOAD_SPEED;

export const AVAILABLE_RAM_OPTIONS = [
  { value: '0.25', label: '0.25' },
  { value: '0.5', label: '0.5' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '4', label: '4' },
  { value: '8', label: '8' },
];

export const CHROME = 'Chrome';
export const EDGE = 'Edge';
export const SAFARI = 'Safari';
export const FIREFOX = 'FireFox';
export type SUPPORTED_BROWSER =
  | typeof CHROME
  | typeof EDGE
  | typeof SAFARI
  | typeof FIREFOX;
export const SUPPORTED_BROWSERS_LIST: SUPPORTED_BROWSER[] = [
  CHROME,
  EDGE,
  SAFARI,
  FIREFOX,
];

export const MAC_OS = 'Mac OS';
export const WINDOWS = 'Windows';
export type SUPPORTED_OPERATING_SYSTEM = typeof MAC_OS | typeof WINDOWS;
export const SUPPORTED_OPERATING_SYSTEM_LIST: SUPPORTED_OPERATING_SYSTEM[] = [
  MAC_OS,
  WINDOWS,
];
