import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { Card } from './Card';
import { cardContents } from './constants';
import { Header } from './Header';

const useStyles = makeStyles(theme => ({
  contentSection: {
    width: '60%',
    minWidth: '700px',
    height: 'calc(100vh - (52px + 56px))',
    overflowY: 'auto',
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    width: '674px',
    display: 'flex',
    flexDirection: 'column',
  },
  cardsWrapper: {
    display: 'grid',
    gap: theme.spacing(3),
    gridTemplateColumns: 'repeat(2, 1fr)',
    padding: theme.spacing(2, 0),
  },
}));

export const Content = () => {
  const styles = useStyles();

  return (
    <section className={styles.contentSection}>
      <div className={styles.container}>
        <Header />
        <Box className={styles.cardsWrapper}>
          {cardContents.map(cardContent => (
            <Card
              key={cardContent.header.id}
              Icon={cardContent.Icon}
              header={cardContent.header}
              descriptions={cardContent.descriptions}
            />
          ))}
        </Box>
      </div>
    </section>
  );
};
