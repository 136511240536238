/**
 *
 * Material UI styles for MobileMessagePreview
 *
 *
 * @see https://material-ui.com/styles/basics
 *
 */

const styles = theme => ({
  mobileMessagePreview: {
    background: theme.palette.common.white,
  },
  desktopBrowserContainer: {
    height: 422,
    borderRadius: 8,
    boxShadow: theme.customShadows.gray300,
    minWidth: 400,
    maxWidth: 420,
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      minWidth: 300,
    },
  },
  desktopBrowserScreen: {
    width: '100%',
    height: '100%',
    borderRadius: 8,
    backgroundColor: theme.palette.common.gray200,
  },
  browserTabsSection: {
    height: 34,
    width: '100%',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: theme.palette.common.white,
  },
  browserTabsButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 64,
    height: '100%',
    marginLeft: theme.spacing(1.25),
  },
  browserTabsButton: {
    height: 10,
    width: 10,
    backgroundColor: theme.palette.common.gray200,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
    '&:last-child': {
      marginRight: 0,
    },
  },
  browserTab: {
    width: 64,
    height: 27.5,
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    backgroundColor: theme.palette.common.gray200,
    marginTop: 'auto',
    marginLeft: theme.spacing(1.25),
  },
  messageContent: {
    marginTop: 30,
    height: 400.5,
    width: '100%',
  },
  messageHeader: {
    fontSize: 12,
    borderBottom: `1px solid ${theme.palette.common.gray200}`,
    boxSizing: 'content-box',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    color: theme.palette.common.gray600,
  },
  emailAddress: {
    color: theme.palette.common.blue400,
  },
  fromEmail: {
    fontSize: 11,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  toEmail: {
    fontSize: 10,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  messageSubject: {
    fontSize: 12,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(1),
  },
  fountainBodyHeader: {
    background: '#00A0FF',
    color: theme.palette.common.white,
    textAlign: 'center',
    fontWeight: 'bold',
    height: 70,
    paddingTop: theme.spacing(2),
  },
  messageTextPreview: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 2,
    fontSize: 11,
    height: 270,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,

    '& > iframe': {
      fontFamily: theme.typography.fontFamily,
      backgroundColor: theme.palette.common.white,
      borderRadius: 2,
      fontSize: 11,
      height: 270,
      border: 'none',
      width: '100%',
      borderBottomRightRadius: 8,
      borderBottomLeftRadius: 8,

      '& > html': {
        wordBreak: 'break-all',
      },
    },
  },
  placeholder: {
    color: theme.palette.common.gray400,
  },
  textPlaceholder: {
    padding: `${theme.spacing(2.5)}px ${theme.spacing(1.75)}px`,
  },
});

export default styles;
