import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  applicantsFound: {
    defaultMessage: '{number} applicants',
    id: `app.containers.JobSearch.applicantsFound`,
  },
  searchPlaceholder: {
    defaultMessage: 'Search by Location, Address, or Job',
    id: `app.containers.JobSearch.searchPlaceholder`,
  },
  selectLabel: {
    defaultMessage: 'Job',
    id: `app.containers.JobSearch.selectLabel`,
  },
  selectPlaceholder: {
    defaultMessage: 'Search Job',
    id: `app.containers.JobSearch.selectPlaceholder`,
  },
  switch: {
    defaultMessage: 'Only Active',
    id: `app.containers.JobSearch.switch`,
  },
  empty: {
    id: `app.containers.JobSearch.empty`,
    defaultMessage: 'No jobs',
  },
  emptySearch: {
    id: `app.containers.JobSearch.emptySearch`,
    defaultMessage: 'No match found for {searchString}',
  },
  loading: {
    id: `app.containers.JobSearch.loading`,
    defaultMessage: 'Loading jobs...',
  },
  jobSearchSelectVariant: {
    id: `app.containers.JobSearch.jobSearchSelectVariant`,
    defaultMessage: 'Job Search Select Variant',
  },
  jobSearchInputVariant: {
    id: `app.containers.JobSearch.jobSearchInputVariant`,
    defaultMessage: 'Job Search Input Variant',
  },
  jobSearchLimitWarning: {
    id: `app.containers.JobSearch.jobSearchLimitWarning`,
    defaultMessage: 'Only showing up to 100 results...',
  },
});

export default messages;
