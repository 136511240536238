import { Warning } from '@fountain/fountain-ui-components';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import cx from 'classnames';
import React, { VFC } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  description: {
    color: theme.palette.common.gray800,
    paddingBottom: theme.spacing(2),
  },
  emptyItemsContent: {
    backgroundColor: theme.palette.common.gray100,
    padding: theme.spacing(4),
    borderRadius: theme.spacing(0.625),
    marginBottom: theme.spacing(4),
  },
  errorContent: {
    backgroundColor: theme.palette.common.red100,
    borderRadius: theme.spacing(0.625),
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  errorIcon: {
    height: theme.spacing(2),
    marginRight: theme.spacing(0.25),
  },
  errorText: {
    color: theme.palette.common.red800,
  },
}));

export interface AddItemsCardProps {
  addButton: React.ReactNode;
  className?: string;
  description: string;
  errorText?: string;
}

export const AddItemsCard: VFC<AddItemsCardProps> = ({
  addButton,
  className,
  description,
  errorText,
}) => {
  const styles = useStyles();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      className={cx(styles.emptyItemsContent, className)}
    >
      <Typography variant="body2" className={styles.description}>
        {description}
      </Typography>
      {addButton}
      {errorText && (
        <Grid className={styles.errorContent}>
          <Warning color="error" className={styles.errorIcon} />
          <Typography variant="h4" className={styles.errorText}>
            {errorText}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
