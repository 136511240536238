import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import { resetStageApplicantCountAction } from 'containers/ApplicantsV2/actions';
import {
  clearApplicantCache,
  removeSelectedApplicantId,
  resetApplicantDataAction,
} from 'containers/ApplicantTableV2/actions';
import {
  addDefaultErrorMessageAction,
  addMessageAction,
} from 'containers/FlashMessage/actions';
import { closePopup } from 'containers/GlobalPopup/actions';
import { updateMovedApplicantStage } from 'containers/Messenger/actions';
import { Transition } from 'containers/MoveApplicantDialog/constants';
import { SelectedApplicant } from 'containers/SelectedApplicant/types';
import globalMessages from 'shared/global/messages';
import { apiPost } from 'utils/axios';
import { removeParams } from 'utils/urlUtils';

interface UseApplicantTransitionOptions {
  fromStageId?: string;
  toStageId: string;
  isMasterApplicantsView?: boolean;
  jobId?: string;
  toJobId?: string;
  transitionType: Transition;
  shouldRunWhenLand: boolean;
  archivedReasonId?: string | number;
  rejectionReasonId?: string | number;
  onSuccessMessage?: string;
}

export default function useApplicantTransition(applicantId: string) {
  const dispatch = useDispatch();

  if (!REACT_APP_GLOBAL_API_BASE_URL_V2) {
    throw new Error('REACT_APP_GLOBAL_API_BASE_URL_V2 is undefined');
  }

  const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/transitions`;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { pathname } = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const messengerPath = /\/messenger\//g.test(pathname);

  const handleMoveToStage = useCallback(
    (
      {
        fromStageId,
        toStageId,
        jobId,
        toJobId,
        transitionType,
        shouldRunWhenLand = false,
        archivedReasonId,
        rejectionReasonId,
        onSuccessMessage,
      }: UseApplicantTransitionOptions,
      onSuccess: () => void = () => {},
    ) => {
      const moveToStage = async () => {
        setIsLoading(true);
        try {
          const res: { data: { applicant: SelectedApplicant } } = await apiPost(
            url,
            {
              to_stage: toStageId,
              funnel: jobId ?? toJobId,
              should_run_when_land: shouldRunWhenLand,
              type: transitionType,
              archived_reason_id: archivedReasonId,
              rejection_reason_id: rejectionReasonId,
            },
          );
          if (res.data && res.data.applicant) {
            dispatch(
              addMessageAction(
                onSuccessMessage ??
                  intl.formatMessage(globalMessages.movedApplicantSuccessfully),
                'success',
              ),
            );

            // reset count for stage selectors
            dispatch(
              resetStageApplicantCountAction({
                fromStageId,
                toStageId: res.data.applicant.stage.id,
              }),
            );

            // update stage info saved in applicant
            dispatch(resetApplicantDataAction(res.data.applicant));

            // remove the applicant from the useApplicantDetail hook cache
            dispatch(clearApplicantCache(applicantId));
            dispatch(removeSelectedApplicantId(applicantId));
            setIsLoading(false);
            dispatch(closePopup());
            if (messengerPath) {
              dispatch(updateMovedApplicantStage(res.data.applicant.stage.id));
            }

            const params = removeParams({
              popupType: null,
              transitionType: null,
              toStageId: null,
              fromStageId: null,
              selectedJobId: null,
              isValidationSaved: null,
              selectedRunAutomatedActions: null,
            });
            history.push(`${pathname}?${params}`);
          }
          onSuccess();
        } catch (exception: unknown) {
          setIsLoading(false);
          setError(exception as string);
        }
      };

      void moveToStage();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, url],
  );

  if (error) {
    dispatch(addDefaultErrorMessageAction());
  }

  return { handleMoveToStage, isLoading };
}
