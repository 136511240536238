import { Box, Dialog, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactPlayer from 'react-player/youtube';

import FountainGradient from './images/FountainGradient.svg';
import { PlayIcon } from './images/PlayIcon';
import { WidgetIconScreenshot } from './images/WidgetIconScreenshot';
import { WidgetWindowScreenshot } from './images/WidgetWindowScreenshot';
import { messages } from './messages';

const useStyles = makeStyles(() => ({
  previewSection: {
    width: '40%',
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    backgroundImage: `url("${FountainGradient}")`,
    backgroundPosition: 'right',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  previewWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  window: {
    height: '652px',
    width: '380px',
  },
  icon: {
    height: '80px',
    width: '100px',
  },
  videoWrapper: {
    position: 'relative',
    paddingTop: '56.25%',
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  blurredBackdrop: {
    backdropFilter: 'blur(6px)',
    backgroundColor: 'rgb(19, 30, 64, 0.6)',
  },
  widgetWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },
  playIconWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    margin: 'auto',
    height: 100,
    width: 120,
    textAlign: 'center',
  },
  playIcon: {
    height: 100,
    width: 80,
  },
  iconWrapper: {
    height: '100%',
    position: 'relative',
  },
  pressPlay: {
    fontSize: 14,
    color: '#FFFFFF',
  },
}));

export const Preview = () => {
  const styles = useStyles();

  const [isShowVideo, setIsShowVideo] = useState(false);

  const handleShowVideoClick = () => {
    setIsShowVideo(true);
  };

  const handleVideoDialogClose = () => {
    setIsShowVideo(false);
  };

  return (
    <section className={styles.previewSection}>
      <Box className={styles.widgetWrapper}>
        <div className={styles.iconWrapper}>
          <div className={styles.playIconWrapper}>
            <IconButton
              className={styles.playIcon}
              onClick={handleShowVideoClick}
            >
              <PlayIcon className={styles.playIcon}></PlayIcon>
            </IconButton>
            <Typography className={styles.pressPlay}>
              <FormattedMessage {...messages.pressPlay} />
            </Typography>
          </div>
          <WidgetWindowScreenshot className={styles.window} />
        </div>

        <WidgetIconScreenshot className={styles.icon} />
      </Box>
      <Dialog
        onClose={handleVideoDialogClose}
        open={isShowVideo}
        fullWidth
        maxWidth="lg"
        {...{
          BackdropProps: { classes: { root: styles.blurredBackdrop } },
        }}
      >
        <div className={styles.videoWrapper}>
          <ReactPlayer
            className={styles.video}
            url="https://www.youtube.com/watch?v=iulT1gZ-sxw"
            config={{
              playerVars: {
                autoplay: 1,
                controls: 1,
                mute: 0,
                loop: 0,
                rel: 0,
                showinfo: 0,
              },
            }}
            height="100%"
            width="100%"
          />
        </div>
      </Dialog>
    </section>
  );
};
