import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  REACT_APP_GLOBAL_API_BASE_URL,
  REACT_APP_GLOBAL_API_BASE_URL_V2,
} from 'runtimeEnvVars';

import { sendWhatsAppConversationRequest } from 'containers/ApplicantTableV2/actions';
import { addMessageAction } from 'containers/FlashMessage/actions';
import { apiGet, apiPost } from 'utils/axios';

import {
  deliverSmsErrorAction,
  deliverSmsSuccessAction,
  fetchMembersErrorAction,
  fetchMembersSuccessAction,
} from './actions';
import { DELIVER_SMS_INIT, FETCH_MEMBERS_INIT } from './constants';

export function* deliverSms(action) {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/message_templates/deliver`;

  try {
    const response = yield call(apiPost, url, action.params);
    if (action.params.delivery_params.send_at) {
      yield put(addMessageAction(action.messages.success, 'success'));
    }
    if (action.params.delivery_params.is_whats_app_template) {
      yield put(sendWhatsAppConversationRequest());
    }
    yield put(deliverSmsSuccessAction(response.data));
  } catch (err) {
    yield put(addMessageAction(action.messages.error, 'error'));
    yield put(deliverSmsErrorAction(err));
  }
}

export function* fetchMembers(action) {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL}/brands/${action.brandSlug}/users`;

  try {
    const { data: response } = yield call(apiGet, url);
    yield put(fetchMembersSuccessAction(response.users));
  } catch (err) {
    yield put(fetchMembersErrorAction(err));
  }
}

export const deliverSmsSaga = takeLatest(DELIVER_SMS_INIT, deliverSms);
export const fetchMembersSaga = takeLatest(FETCH_MEMBERS_INIT, fetchMembers);

export default function* sendEmailPopupSaga() {
  yield all([deliverSmsSaga, fetchMembersSaga]);
}
