import { CustomTooltip } from '@fountain/fountain-ui-components';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import InfoGrey from 'images/info-grey.svg';

import { messages } from './messages';
import useStyles from './styles';

export interface InfoTooltipProps {
  title: string;
}

export const InfoTooltip: FC<InfoTooltipProps> = ({ title, ...otherProps }) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <CustomTooltip title={title} dense {...otherProps}>
      <img
        src={InfoGrey}
        alt={intl.formatMessage(messages.infoIcon)}
        className={classes.infoIcon}
      />
    </CustomTooltip>
  );
};
