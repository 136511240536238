import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    date: {
      minWidth: theme.spacing(5),
    },
    filters: {
      flex: 5,
    },
    button: {
      flex: 1,
    },
    container: {
      display: 'flex',
    },
    tabButton: {
      padding: theme.spacing(1.5, 1),
      border: '4px solid transparent',
      boxSizing: 'border-box',
      transition: 'none',
    },
    tabName: {
      fontFamily: 'CircularXXWeb-Book, sans-serif',

      '&.selected': {
        fontFamily: 'CircularXXWeb-Medium, sans-serif',
      },
    },
    selected: {
      borderRadius: 0,
      borderLeft: 0,
      borderRight: 0,
      borderBottom: `4px solid ${theme.palette.common.blue400}`,
      padding: theme.spacing(1.5, 1.5),
    },
    filterContainer: {
      width: '100%',
      dispay: 'flex',
      justifyContent: 'right',
      '& > div': {
        margin: theme.spacing(0, 1),
      },
      maxHeight: 360,
    },
    imageWrapper: {
      paddingTop: theme.spacing(1.5),
    },
    buttonGroup: {
      padding: theme.spacing(1),
      gap: theme.spacing(1),
    },
    drawer: {
      width: 'auto',
      height: '520px',
    },
    enabledFilter: {
      transform: `translate(${theme.spacing(1)}px, ${theme.spacing(-2)}px)`,
    },
  }),
);
