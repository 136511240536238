import { FunnelDetails } from 'api-clients/monolith';
import React, { FC } from 'react';

import { StageHeader } from '../../StageHeader';
import { StageContainerByType } from './StageContainerByType';

export interface StageDetailProps {
  opening: FunnelDetails;
}

export const StageDetail: FC<StageDetailProps> = ({ opening }) => {
  return (
    <>
      <StageHeader opening={opening} />
      <StageContainerByType opening={opening} />
    </>
  );
};
