import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NODE_ENV } from 'runtimeEnvVars';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error) {
    this.setState({ hasError: true });
    if (NODE_ENV !== 'production') {
      window.console.error(error);
    }
    this.triggerAlert();
  }

  triggerAlert() {
    this.props.dispatchErrorMessage();
    this.setState({ hasError: false });
  }

  render() {
    if (this.state.hasError) {
      return <>{this.props.children};</>;
    }
    return <>{this.props.children}</>;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
  dispatchErrorMessage: PropTypes.func.isRequired,
};

export default ErrorBoundary;
