/*
 *
 * ApplicantsImportModal actions
 *
 */

import {
  APPLICANTS_DELETE_UPLOAD,
  APPLICANTS_SUBMIT,
  APPLICANTS_SUBMIT_ERROR,
  APPLICANTS_SUBMIT_SUCCESS,
  APPLICANTS_UPLOAD,
  FETCH_APPLICANTS_AUTOMATED_ACTIONS,
  FETCH_APPLICANTS_AUTOMATED_ACTIONS_ERROR,
  FETCH_APPLICANTS_AUTOMATED_ACTIONS_SUCCESS,
} from './constants';

export function applicantsUpload(file) {
  return {
    type: APPLICANTS_UPLOAD,
    file,
  };
}

export function applicantsSubmitAction({
  file,
  jobId,
  stageId,
  notifyApplicant,
  notifyOwner,
  phonePlatform,
  autoGenerateEmail,
}) {
  return {
    type: APPLICANTS_SUBMIT,
    file,
    jobId,
    stageId,
    notifyApplicant,
    notifyOwner,
    phonePlatform,
    autoGenerateEmail,
  };
}

export function applicantsSubmitSuccessAction() {
  return {
    type: APPLICANTS_SUBMIT_SUCCESS,
  };
}

export function applicantsSubmitErrorAction(error) {
  return {
    type: APPLICANTS_SUBMIT_ERROR,
    error,
  };
}

export function deleteApplicantsUpload() {
  return {
    type: APPLICANTS_DELETE_UPLOAD,
  };
}

export function fetchApplicantsAutomatedActionsAction({
  stageId,
  funnelExternalId,
}) {
  return {
    type: FETCH_APPLICANTS_AUTOMATED_ACTIONS,
    stageId,
    funnelExternalId,
  };
}

export function fetchApplicantsAutomatedActionsSuccessAction(payload) {
  return {
    type: FETCH_APPLICANTS_AUTOMATED_ACTIONS_SUCCESS,
    payload,
  };
}

export function fetchApplicantsAutomatedActionsErrorAction() {
  return {
    type: FETCH_APPLICANTS_AUTOMATED_ACTIONS_ERROR,
  };
}
