import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  cancel: {
    id: `app.containers.WorkflowEditor.components.StageHeader.cancel`,
    defaultMessage: 'Cancel',
  },
  discard: {
    id: `app.containers.WorkflowEditor.components.StageHeader.discard`,
    defaultMessage: 'Discard',
  },
  discardChanges: {
    id: `app.containers.WorkflowEditor.components.StageHeader.discardChanges`,
    defaultMessage: 'Discard Changes',
  },
  discardChangesWarning: {
    id: `app.containers.WorkflowEditor.components.StageHeader.discardChangesWarning`,
    defaultMessage: 'Are you sure you want to discard the changes?',
  },
  dontShowAgain: {
    id: `app.containers.WorkflowEditor.components.StageHeader.dontShowAgain`,
    defaultMessage: 'Don’t show this again',
  },
  save: {
    id: 'app.containers.WorkflowEditor.components.StageHeader.save',
    defaultMessage: 'Save',
  },
  saveChanges: {
    id: 'app.containers.WorkflowEditor.components.StageHeader.saveChanges',
    defaultMessage: 'Save Changes',
  },
  saveConfirm: {
    id: 'app.containers.WorkflowEditor.components.StageHeader.saveConfirm',
    defaultMessage: 'Are you sure that you want to make this change?',
  },
  saveWarning: {
    id: 'app.containers.WorkflowEditor.components.StageHeader.saveWarning',
    defaultMessage:
      'You are making changes to the workflow that can’t be undone and will impact {count} openings.',
  },
  stageNameChangeWarning: {
    id: 'app.components.InputEditor.stageNameChangeWarning',
    defaultMessage:
      'Changing the stage name will remove the availabilities associated with this stage name.',
  },
  stageTitle: {
    id: 'app.containers.WorkflowEditor.components.StageHeader.stageTitle',
    defaultMessage: 'Stage Title',
  },
  stageTitleHeader: {
    id: 'app.containers.WorkflowEditor.components.StageHeader.stageTitleHeader',
    defaultMessage: 'Stage Title Header',
  },
});
