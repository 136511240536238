import { all, delay, put, takeEvery } from 'redux-saga/effects';

import { removeMessageAction } from './actions';
import { ADD_MESSAGE } from './constants';

/**
 * Github repos request/response handler
 */
export function* autoClose(action) {
  if (action.autoClose) {
    yield delay(5000);
    yield put(removeMessageAction());
  }
}

export const autoCloseSaga = takeEvery(ADD_MESSAGE, autoClose);

// Individual exports for testing
export default function* authRouteSaga() {
  yield all([autoCloseSaga]);
}
