import { MenuItem } from '@fountain/fountain-ui-components';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import useNavItemStyles from '../NavigationMenuItem/styles';
import NavLink from '../NavLink';
import messages from './messages';

export default function PosthireNavLink({ permission, menuItem }) {
  const navClasses = useNavItemStyles();
  const { accountSlug } = useParams();
  const postToHireUrl = `${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/posthire/workers`;

  const regEx = new RegExp(`/([^/]*)/posthire/workers$`);
  const active = regEx.test(window.location.pathname);

  const menuItemProps = {
    classes: { root: navClasses.root, selected: navClasses.selected },
    selected: active,
    disabled: active,
    href: postToHireUrl,
    isExternalLink: true,
    className: navClasses.menuListItem,
  };

  return (
    <>
      {menuItem ? (
        <MenuItem {...menuItemProps}>
          <FormattedMessage {...messages.posthire} />
        </MenuItem>
      ) : (
        <NavLink
          active={active}
          display={permission}
          message={messages.posthire}
          link={postToHireUrl}
          dataTestid="posthire"
          external
        />
      )}
    </>
  );
}

PosthireNavLink.propTypes = {
  permission: PropTypes.bool.isRequired,
  menuItem: PropTypes.bool,
};
