import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import { addDefaultErrorMessageAction } from 'containers/FlashMessage/actions';
import { apiGet } from 'utils/axios';

export default function useApplicantAssignableUsers(applicantId, shouldFetch) {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setIsFetching(true);

      try {
        const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/users`;
        const res = await apiGet(url);

        setData(res.data);
        setError('');
      } catch (e) {
        setError(e);
      } finally {
        setIsFetching(false);
      }
    };

    if (applicantId && shouldFetch && !data) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicantId, shouldFetch]);

  useEffect(() => {
    if (error) dispatch(addDefaultErrorMessageAction());
  }, [dispatch, error]);

  return { data, isFetching };
}
