import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

import useStyles from './styles';

function PartnerDetail({ details, requestedRevision }) {
  const classes = useStyles();

  if (!details.length) return null;

  const partnerOption = details[0].partner_option;

  return (
    <Grid className={classes.partnerDetailContainer}>
      <Typography
        variant="body1"
        className={classes.partnerOption}
        color="textPrimary"
      >
        {partnerOption} {requestedRevision && ' - Requested Revision'}
      </Typography>
      <Grid className={classes.partnerDetails}>
        {details.map(detail => (
          <Grid key={detail.key} className={classes.partnerDetail}>
            {detail.category === 'url' || detail.category === 'file' ? (
              <a href={detail.value} target="_blank" rel="noreferrer">
                {detail.label}
              </a>
            ) : (
              <Grid container>
                <Typography variant="body2" className={classes.detailLabel}>
                  {detail.label}:
                </Typography>
                <Typography variant="body2" className={classes.detailValue}>
                  &nbsp;{detail.value}
                </Typography>
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

PartnerDetail.propTypes = {
  details: PropTypes.array,
  requestedRevision: PropTypes.bool,
};

export default PartnerDetail;
