import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL } from 'runtimeEnvVars';

import {
  addDefaultErrorMessageAction,
  addMessageAction,
} from 'containers/FlashMessage/actions';
import messages from 'containers/SourcingPurchaseEditJob/messages';
import { apiPatch } from 'utils/axios';

export default function useJobUpdate(successAction) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleJobUpdate = async (jobId, data) => {
    const url = `${REACT_APP_GLOBAL_API_BASE_URL}/jobs/${jobId}`;
    setError('');
    setIsLoading(true);
    try {
      const res = await apiPatch(url, { ...data });
      if (res.data) {
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.jobSucessMessage),
            'success',
          ),
        );
        successAction(res.data);
        setError('');
      }
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error) {
      if (
        error.indexOf('Location must include a valid city, state and country') >
        -1
      ) {
        dispatch(addMessageAction(error, 'error'));
      } else {
        dispatch(addDefaultErrorMessageAction());
      }
    }
  }, [dispatch, error]);

  return { handleJobUpdate, isLoading };
}
