import { TextButton } from '@fountain/fountain-ui-components';
import { Typography } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import {
  CancelablePromise,
  WorkflowEditorService,
  WorkflowStageDetail,
  WorkflowStageUpdates,
} from 'api-clients/monolith';
import React, { FC } from 'react';
import { classNames } from 'react-extras';
import { useIntl } from 'react-intl';

import { useApiServiceMutation } from 'hooks/useApiServiceMutation';
import useCreateTestApplicant from 'hooks/useCreateTestApplicant';

import { messages } from './messages';
import useStyles from './styles';

export interface StagePreviewProps {
  openingId: string;
  stageId: string;
  isPreviewDisabled: boolean;
  partnerOptionId: boolean;
}

export const StagePreview: FC<StagePreviewProps> = ({
  openingId,
  stageId,
  isPreviewDisabled,
  partnerOptionId,
}) => {
  const intl = useIntl();
  const styles = useStyles();

  const stageDataToSave = {
    workflow_stage: {
      stage: {
        no_results_message: '',
        no_results_html_mode: false,
        portal_body: '',
        portal_body_html_mode: false,
        portal_title: '',
        portal_instructions: '',
        success_message: '',
        portal_javascript: '',
        success_message_javascript: '',
      },
    },
  };

  const { handleCreateTestApplicant } = useCreateTestApplicant();

  const { mutation: showPreview } = useApiServiceMutation<
    WorkflowStageDetail,
    (
      stageExternalId: string,
      funnelSlug: string,
      requestBody?: WorkflowStageUpdates,
    ) => CancelablePromise<WorkflowStageDetail>,
    { errors: Record<string, Array<string>> }
  >(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    WorkflowEditorService.patchInternalApiWorkflowEditorFunnelsStages,
    {
      onSuccess: () => {
        const windowReference = window.open('about:blank', '_blank');
        void handleCreateTestApplicant(openingId, stageId, windowReference);
      },
    },
  );

  const handlePreview = () => {
    showPreview(openingId, stageId, stageDataToSave);
  };

  const isButtonDisabled = isPreviewDisabled || partnerOptionId;

  return (
    <>
      <TextButton
        color="primary"
        onClick={handlePreview}
        disabled={isButtonDisabled}
        className={classNames(styles.button, {
          [styles.buttonDisabled]: isButtonDisabled,
        })}
        data-testid="stagePreview"
      >
        <Typography
          variant="h4"
          color="primary"
          className={classNames({
            [styles.buttonDisabled]: isButtonDisabled,
          })}
        >
          {intl.formatMessage(messages.stagePreview)}
        </Typography>
        <OpenInNew className={styles.openInNew} />
      </TextButton>
    </>
  );
};
