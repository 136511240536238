import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';

import { SharedConditionProps } from '../../types';
import { ACCURATE_STATUS, CHECKR_STATUS, STERLING_STATUS } from '../constants';
import { messages } from '../messages';
import { AccurateStatus } from './AccurateStatus';
import { CheckrStatus } from './CheckrStatus';
import { SterlingStatus } from './SterlingStatus';

export interface BackgroundCheckProps extends SharedConditionProps {
  onChangeBackgroundCheck: (backgroundCheck: {
    label: string;
    value: string;
  }) => void;
}

export const BackgroundCheck: FC<BackgroundCheckProps> = ({
  condition,
  errors,
  onChangeBackgroundCheck,
  ruleId,
  setDocumentSigningRules,
  setRules,
}) => {
  const intl = useIntl();

  const { integrations } = useContext(RulesEditDataContext);

  const backgroundCheckProviders = [
    ...(integrations?.checkr
      ? [
          {
            label: 'Checkr',
            value: CHECKR_STATUS,
          },
        ]
      : []),
    ...(integrations?.sterling
      ? [
          {
            label: 'Sterling',
            value: STERLING_STATUS,
          },
        ]
      : []),
    ...(integrations?.accurate
      ? [
          {
            label: 'Accurate',
            value: ACCURATE_STATUS,
          },
        ]
      : []),
  ];

  const selectedBgcOption = backgroundCheckProviders.find(
    bgc => bgc.value === condition.type,
  );

  const sharedProps = {
    condition,
    errors,
    ruleId,
    setDocumentSigningRules,
    setRules,
  };

  return (
    <>
      <Grid item xs={6}>
        <StyledReactSelect
          options={backgroundCheckProviders}
          value={selectedBgcOption ?? null}
          onChange={onChangeBackgroundCheck}
          label={intl.formatMessage(messages.backgroundCheck)}
          aria-label={intl.formatMessage(messages.backgroundCheck)}
          isSearchable
          placeholder=""
          required
        />
      </Grid>
      {condition.type &&
        {
          [CHECKR_STATUS]: <CheckrStatus {...sharedProps} />,
          [STERLING_STATUS]: <SterlingStatus {...sharedProps} />,
          [ACCURATE_STATUS]: <AccurateStatus {...sharedProps} />,
        }[condition.type]}
    </>
  );
};
