import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  positionIs: {
    defaultMessage: 'Position is...',
    id: `app.containers.WorkflowEditor.components.StageDetail.JobRouterStages.JobRouterConditions.positionIs`,
  },
  locationIs: {
    defaultMessage: 'Location is...',
    id: `app.containers.WorkflowEditor.components.StageDetail.JobRouterStages.JobRouterConditions.locationIs`,
  },
  locationGroupIs: {
    defaultMessage: 'Location Group is...',
    id: `app.containers.WorkflowEditor.components.StageDetail.JobRouterStages.JobRouterConditions.locationGroupIs`,
  },
  workflowIs: {
    defaultMessage: 'Workflow is...',
    id: `app.containers.WorkflowEditor.components.StageDetail.JobRouterStages.JobRouterConditions.workflowIs`,
  },
});
