import { SidebarStage, StageType } from 'api-clients/monolith/';
import { useIntl } from 'react-intl';

import { messages } from './messages';

export const useGetAdditionalInfoText = (
  additionalInfo: SidebarStage['additional_info'],
  StageType: StageType,
): string | null => {
  const intl = useIntl();

  const packageName =
    additionalInfo?.package_name ??
    additionalInfo?.package_title ??
    intl.formatMessage(messages.na);
  switch (StageType) {
    case 'AccurateStage':
      if (additionalInfo?.is_integration_configured) {
        return intl.formatMessage(messages.accurateStage, {
          packageName,
        });
      }
      return intl.formatMessage(messages.backgroundCheckStageUnconfigured);
    case 'CheckrCanada::Stage':
    case 'BackgroundCheckerStage':
      if (additionalInfo?.is_integration_configured) {
        return intl.formatMessage(messages.backgroundCheckStage, {
          packageName,
        });
      }
      return intl.formatMessage(messages.backgroundCheckStageUnconfigured);
    case 'LearningStage':
      if (additionalInfo?.lessonly_integration_enabled) {
        return intl.formatMessage(messages.learningProvider, {
          learningProvider: 'Lessonly',
        });
      }
      return intl.formatMessage(messages.setupIntegration, {
        integration: 'Lessonly',
      });
    case 'SchoolkeepStage':
      if (additionalInfo?.schoolkeep_integration_enabled) {
        return intl.formatMessage(messages.learningProvider, {
          learningProvider: 'Northpass',
        });
      }
      return intl.formatMessage(messages.setupIntegration, {
        integration: 'Northpass',
      });
    case 'DocumentSignatureStage':
      if (additionalInfo?.signature_provider) {
        return intl.formatMessage(messages.documentSignatureStage, {
          signatureProvider: additionalInfo.signature_provider,
        });
      }
      return intl.formatMessage(messages.documentSignatureStageUnconfigured);
    default:
      return null;
  }
};
