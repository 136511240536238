import { theme as muiTheme } from '@fountain/fountain-ui-components';
import PropTypes from 'prop-types';
import React from 'react';

const TYPES_COLOR = {
  active: muiTheme.palette.common.blue400,
  secondary: muiTheme.palette.common.gray800,
};

const CustomizeColumnsIcon = ({ type, className }) => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={className}
      d="M15 0H1C0.4 0 0 0.4 0 1V13C0 13.6 0.4 14 1 14H15C15.6 14 16 13.6 16 13V1C16 0.4 15.6 0 15 0ZM2 6H10V8H2V6ZM10 2V4H2V2H10ZM2 10H10V12H2V10ZM14 12H12V2H14V12Z"
      fill={TYPES_COLOR[type]}
    />
  </svg>
);

CustomizeColumnsIcon.propTypes = {
  type: PropTypes.oneOf(['active', 'secondary']),
  className: PropTypes.string,
};

CustomizeColumnsIcon.defaultProps = {
  type: 'secondary',
};

export default CustomizeColumnsIcon;
