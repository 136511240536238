import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    secondary: {
      width: 94,
    },
    primary: {
      width: 72,
    },
    iconButton: {
      marginLeft: 'auto',
    },
    day: {
      width: '56px',
      minWidth: '48px',
      display: 'flex',
      marginTop: theme.spacing(1),
    },
    trashcanIcon: {
      fontSize: theme.spacing(1.5),
    },
    buttonWrapper: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2),
      justifyContent: 'flex-end',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    periodWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      width: '100%',
    },
    subRow: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
      alignItems: 'center',
      height: 40,
    },
    errRow: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
      alignItems: 'center',
      maxHeight: 40,
    },
    row: {
      padding: theme.spacing(1.5, 0),
      '&:not(:last-child)': {
        borderBottom: theme.customBorders.gray300,
      },
      display: 'flex',
      flexDirection: 'row',
    },
    dropDown: {
      minWidth: 112,
    },
    timeInput: {
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#bbb',
      },
    },
    unavailable: {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      width: '100%',
      padding: theme.spacing(1),
    },
  }),
);
