import PDFObject from 'pdfobject';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const PdfViewer = ({ pdfBlob, containerId, isPreview, width, height }) => {
  useEffect(() => {
    PDFObject.embed(pdfBlob, `#${containerId}`, {
      forceIframe: true,
      pdfOpenParams: {
        view: 'FitV',
        pagemode: 'thumbs',
        toolbar: isPreview ? '0' : '1',
        statusbar: '0',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div style={{ width, height }} id={containerId} />;
};

PdfViewer.propTypes = {
  pdfBlob: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  containerId: PropTypes.string,
  isPreview: PropTypes.bool,
};

PdfViewer.defaultProps = {
  width: '100%',
  height: '100%',
  isPreview: false,
};

export default PdfViewer;
