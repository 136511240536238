import {
  differenceInCalendarDays,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInYears,
  distanceInWordsToNow,
  format,
  getMinutes,
  setMinutes,
  startOfMinute,
} from 'date-fns';
import { convertToLocalTime } from 'date-fns-timezone';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import truncate from 'lodash/truncate';

// For use in the most annoying of circumstances... (ie libraries)
// sometimes it is acutally easier to JS instead of CSS.
export const capitalize = s => s.charAt(0).toUpperCase() + s.slice(1);

export const getInitials = name => {
  let initialLetters = '';
  if (name && name.length) {
    if (name.match(/\b\w/g)) {
      initialLetters = name.match(/\b\w/g).join('');
      // Above regex does not catch non english name
      // so we take first chracters of last, and first name
    } else {
      const names = name.split(' ');
      const firstName = names[0];
      const lastName = names[1];
      if (firstName && lastName) {
        initialLetters = firstName[0] + lastName[0];
      } else if (firstName) {
        // eslint-disable-next-line prefer-destructuring
        initialLetters = firstName[0];
      }
    }
    return initialLetters.substring(0, 2).toUpperCase();
  }
  return '';
};

export const getUsPhoneNumber = phone =>
  `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(6)}`;

export const getDaysFromNow = utcTimeString => {
  const localTimeString = convertToLocalTime(utcTimeString, {
    timeZone: 'UTC',
  });
  const label = distanceInWordsToNow(localTimeString);
  const value = differenceInCalendarDays(localTimeString, new Date());
  return { label, value };
};

export const getLocalTimeFromUTC = utcTimeString => {
  const utcTime = new Date(utcTimeString).toString();
  return format(utcTime, 'MM/DD/YYYY, hh:mm a');
};

export const getTimeAgoText = timeString => {
  const localTimeString = convertToLocalTime(timeString, { timeZone: 'UTC' });
  const word = distanceInWordsToNow(localTimeString);

  if (word === 'less than a minute') return 'Just Now';
  return word
    .replace(/about |over |almost /gi, '')
    .replace(/ minutes?/, 'm')
    .replace(/ hours?/, 'h')
    .replace(/ days?/, 'd')
    .replace(/ months?/, 'mo')
    .replace(/ years?/, 'yr.')
    .concat(' ago');
};

export const getMonthAndDate = timeString => {
  const date = new Date(timeString);
  return format(date, 'MMM DD');
};

export const getDateText = timeString => {
  const date = new Date(timeString);
  return format(date, 'MMM D, YYYY');
};

export const getDashedDateUS = timeString => format(timeString, 'MM-DD-YYYY');

export const getReversedDashedDateUS = timeString =>
  format(timeString, 'YYYY-MM-DD');

export const getSlashedDateUs = timeString => {
  const date = new Date(timeString);
  return format(date, 'MM/DD/YYYY');
};

export const getFullDateText = timeString => {
  const date = new Date(timeString);
  return format(date, 'MMMM D, YYYY');
};

export const getTimeOfDay = timeString => {
  const date = new Date(timeString);
  return format(date, 'hh:mm a');
};

export const getMonthYearText = timeString => {
  const date = new Date(timeString);
  return format(date, 'MMM YYYY');
};

export const getDateDifference = (startDateString, endDateString) =>
  differenceInCalendarDays(new Date(endDateString), new Date(startDateString));

export const getLinearProgressValue = (duration, daysRemaining) => {
  const daysElapsed = duration - daysRemaining;
  return (daysElapsed / duration) * 100;
};

export const getNumWeeks = (createdAt, endAt) => {
  const dateDifference = getDateDifference(createdAt, endAt);
  if (dateDifference < 15) return 2;
  if (dateDifference < 22) return 3;
  return 4;
};

export const validatePhoneNumber = phone => {
  if (phone == null || phone === '') {
    return true;
  }
  const validDigits = phone.match(
    /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g,
  );

  return validDigits && validDigits[0].length;
};

export const validateEmail = email => {
  const re = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );
  return re.test(String(email).toLowerCase());
};

export const getDateString = numWeeksFromNow => {
  const dateFromNowObj = new Date(
    Date.now() + 1000 * 60 * 60 * 24 * 7 * numWeeksFromNow,
  );
  const year = dateFromNowObj.getUTCFullYear();
  const month = dateFromNowObj.getUTCMonth() + 1;
  const day = dateFromNowObj.getUTCDate();

  return `${year}-${month}-${day}`;
};

export const snakeCaseKeys = obj => {
  const snakedEntries = Object.entries(obj).map(entry => [
    snakeCase(entry[0]),
    entry[1],
  ]);
  return Object.fromEntries(snakedEntries);
};

export const camelCaseKeys = obj => {
  const cameledEntries = Object.entries(obj).map(entry => [
    camelCase(entry[0]),
    entry[1],
  ]);
  return Object.fromEntries(cameledEntries);
};

export const roundToNearestMinute = (date, interval) => {
  const roundedMinutes = Math.floor(getMinutes(date) / interval) * interval;
  return setMinutes(startOfMinute(date), roundedMinutes);
};
// converting weeks to millisec

export const getStageTypeClassName = stageShortType =>
  stageShortType && stageShortType.toLowerCase().replace(/ /g, '_');

export function truncateFileName(filename = '') {
  const extension = filename.split('.').pop().toLowerCase();
  const name = filename.split('.').shift();

  return `${truncate(name, { length: 30 })}.${extension}`;
}

export function isFilePreviewable(document) {
  return document.previewable || /\.pdf$/i.test(document.filename);
}

export function getTimeZone(date) {
  return date
    .toLocaleTimeString('en-us', { timeZoneName: 'short' })
    .split(' ')[2];
}

export const humanizeSnakeCaseString = string => string.split('_').join(' ');

export const getTimeLapse = timeString => {
  if (!timeString) return { value: 0, unit: '' };

  let dateDifference = differenceInYears(new Date(), new Date(timeString));
  if (dateDifference >= 1) return { value: dateDifference, unit: 'year' };

  dateDifference = differenceInMonths(new Date(), new Date(timeString));
  if (dateDifference >= 1) return { value: dateDifference, unit: 'month' };

  dateDifference = differenceInDays(new Date(), new Date(timeString));
  if (dateDifference >= 1) return { value: dateDifference, unit: 'day' };

  dateDifference = differenceInHours(new Date(), new Date(timeString));
  if (dateDifference >= 1) return { value: dateDifference, unit: 'hour' };

  dateDifference = differenceInMinutes(new Date(), new Date(timeString));
  if (dateDifference >= 1) return { value: dateDifference, unit: 'minute' };
  return { value: 1, unit: 'minute' };
};
