import { defineMessages } from 'react-intl';

export const timezoneMessages = defineMessages({
  'Australia/Adelaide': {
    id: 'app.Calendar.Timezone.Australia/Adelaide',
    defaultMessage: 'Australia/Adelaide',
  },
  'Australia/Broken_Hill': {
    id: 'app.Calendar.Timezone.Australia/Broken_Hill',
    defaultMessage: 'Australia/Broken_Hill',
  },
  'America/Anchorage': {
    id: 'app.Calendar.Timezone.America/Anchorage',
    defaultMessage: 'America/Anchorage',
  },
  'America/Juneau': {
    id: 'app.Calendar.Timezone.America/Juneau',
    defaultMessage: 'America/Juneau',
  },
  'America/Nome': {
    id: 'app.Calendar.Timezone.America/Nome',
    defaultMessage: 'America/Nome',
  },
  'America/Yakutat': {
    id: 'app.Calendar.Timezone.America/Yakutat',
    defaultMessage: 'America/Yakutat',
  },
  'Pacific/Gambier': {
    id: 'app.Calendar.Timezone.Pacific/Gambier',
    defaultMessage: 'Pacific/Gambier',
  },
  'Asia/Almaty': {
    id: 'app.Calendar.Timezone.Asia/Almaty',
    defaultMessage: 'Asia/Almaty',
  },
  'Europe/Amsterdam': {
    id: 'app.Calendar.Timezone.Europe/Amsterdam',
    defaultMessage: 'Europe/Amsterdam',
  },
  'Europe/Athens': {
    id: 'app.Calendar.Timezone.Europe/Athens',
    defaultMessage: 'Europe/Athens',
  },
  'America/Anguilla': {
    id: 'app.Calendar.Timezone.America/Anguilla',
    defaultMessage: 'America/Anguilla',
  },
  'America/Antigua': {
    id: 'app.Calendar.Timezone.America/Antigua',
    defaultMessage: 'America/Antigua',
  },
  'America/Argentina/San_Luis': {
    id: 'app.Calendar.Timezone.AmericaArgentina/San_Luis',
    defaultMessage: 'America/Argentina/San_Luis',
  },
  'America/Aruba': {
    id: 'app.Calendar.Timezone.America/Aruba',
    defaultMessage: 'America/Aruba',
  },
  'America/Asuncion': {
    id: 'app.Calendar.Timezone.America/Asuncion',
    defaultMessage: 'America/Asuncion',
  },
  'America/Barbados': {
    id: 'app.Calendar.Timezone.America/Barbados',
    defaultMessage: 'America/Barbados',
  },
  'America/Blanc-Sablon': {
    id: 'app.Calendar.Timezone.AmericaBlanc-Sablon',
    defaultMessage: 'America/Blanc-Sablon',
  },
  'America/Boa_Vista': {
    id: 'app.Calendar.Timezone.America/Boa_Vista',
    defaultMessage: 'America/Boa_Vista',
  },
  'America/Campo_Grande': {
    id: 'app.Calendar.Timezone.America/Campo_Grande',
    defaultMessage: 'America/Campo_Grande',
  },
  'America/Cuiaba': {
    id: 'app.Calendar.Timezone.America/Cuiaba',
    defaultMessage: 'America/Cuiaba',
  },
  'America/Curacao': {
    id: 'app.Calendar.Timezone.America/Curacao',
    defaultMessage: 'America/Curacao',
  },
  'America/Dominica': {
    id: 'app.Calendar.Timezone.America/Dominica',
    defaultMessage: 'America/Dominica',
  },
  'America/Eirunepe': {
    id: 'app.Calendar.Timezone.America/Eirunepe',
    defaultMessage: 'America/Eirunepe',
  },
  'America/Glace_Bay': {
    id: 'app.Calendar.Timezone.America/Glace_Bay',
    defaultMessage: 'America/Glace_Bay',
  },
  'America/Goose_Bay': {
    id: 'app.Calendar.Timezone.America/Goose_Bay',
    defaultMessage: 'America/Goose_Bay',
  },
  'America/Grenada': {
    id: 'app.Calendar.Timezone.America/Grenada',
    defaultMessage: 'America/Grenada',
  },
  'America/Guadeloupe': {
    id: 'app.Calendar.Timezone.America/Guadeloupe',
    defaultMessage: 'America/Guadeloupe',
  },
  'America/Guyana': {
    id: 'app.Calendar.Timezone.America/Guyana',
    defaultMessage: 'America/Guyana',
  },
  'America/Halifax': {
    id: 'app.Calendar.Timezone.America/Halifax',
    defaultMessage: 'America/Halifax',
  },
  'America/Manaus': {
    id: 'app.Calendar.Timezone.America/Manaus',
    defaultMessage: 'America/Manaus',
  },
  'America/Marigot': {
    id: 'app.Calendar.Timezone.America/Marigot',
    defaultMessage: 'America/Marigot',
  },
  'America/Martinique': {
    id: 'app.Calendar.Timezone.America/Martinique',
    defaultMessage: 'America/Martinique',
  },
  'America/Moncton': {
    id: 'app.Calendar.Timezone.America/Moncton',
    defaultMessage: 'America/Moncton',
  },
  'America/Montserrat': {
    id: 'app.Calendar.Timezone.America/Montserrat',
    defaultMessage: 'America/Montserrat',
  },
  'America/Porto_Velho': {
    id: 'app.Calendar.Timezone.America/Porto_Velho',
    defaultMessage: 'America/Porto_Velho',
  },
  'America/Port_of_Spain': {
    id: 'app.Calendar.Timezone.America/Port_of_Spain',
    defaultMessage: 'America/Port_of_Spain',
  },
  'America/Puerto_Rico': {
    id: 'app.Calendar.Timezone.America/Puerto_Rico',
    defaultMessage: 'America/Puerto_Rico',
  },
  'America/Rio_Branco': {
    id: 'app.Calendar.Timezone.America/Rio_Branco',
    defaultMessage: 'America/Rio_Branco',
  },
  'America/Santo_Domingo': {
    id: 'app.Calendar.Timezone.America/Santo_Domingo',
    defaultMessage: 'America/Santo_Domingo',
  },
  'America/St_Barthelemy': {
    id: 'app.Calendar.Timezone.America/St_Barthelemy',
    defaultMessage: 'America/St_Barthelemy',
  },
  'America/St_Kitts': {
    id: 'app.Calendar.Timezone.America/St_Kitts',
    defaultMessage: 'America/St_Kitts',
  },
  'America/St_Lucia': {
    id: 'app.Calendar.Timezone.America/St_Lucia',
    defaultMessage: 'America/St_Lucia',
  },
  'America/St_Thomas': {
    id: 'app.Calendar.Timezone.America/St_Thomas',
    defaultMessage: 'America/St_Thomas',
  },
  'America/St_Vincent': {
    id: 'app.Calendar.Timezone.America/St_Vincent',
    defaultMessage: 'America/St_Vincent',
  },
  'America/Thule': {
    id: 'app.Calendar.Timezone.America/Thule',
    defaultMessage: 'America/Thule',
  },
  'America/Tortola': {
    id: 'app.Calendar.Timezone.America/Tortola',
    defaultMessage: 'America/Tortola',
  },
  'Antarctica/Palmer': {
    id: 'app.Calendar.Timezone.Antarctica/Palmer',
    defaultMessage: 'Antarctica/Palmer',
  },
  'Atlantic/Bermuda': {
    id: 'app.Calendar.Timezone.Atlantic/Bermuda',
    defaultMessage: 'Atlantic/Bermuda',
  },
  'Atlantic/Stanley': {
    id: 'app.Calendar.Timezone.Atlantic/Stanley',
    defaultMessage: 'Atlantic/Stanley',
  },
  'Antarctica/McMurdo': {
    id: 'app.Calendar.Timezone.Antarctica/McMurdo',
    defaultMessage: 'Antarctica/McMurdo',
  },
  'Antarctica/South_Pole': {
    id: 'app.Calendar.Timezone.Antarctica/South_Pole',
    defaultMessage: 'Antarctica/South_Pole',
  },
  'Pacific/Auckland': {
    id: 'app.Calendar.Timezone.Pacific/Auckland',
    defaultMessage: 'Pacific/Auckland',
  },
  'Pacific/Funafuti': {
    id: 'app.Calendar.Timezone.Pacific/Funafuti',
    defaultMessage: 'Pacific/Funafuti',
  },
  'Pacific/Kwajalein': {
    id: 'app.Calendar.Timezone.Pacific/Kwajalein',
    defaultMessage: 'Pacific/Kwajalein',
  },
  'Pacific/Majuro': {
    id: 'app.Calendar.Timezone.Pacific/Majuro',
    defaultMessage: 'Pacific/Majuro',
  },
  'Pacific/Nauru': {
    id: 'app.Calendar.Timezone.Pacific/Nauru',
    defaultMessage: 'Pacific/Nauru',
  },
  'Pacific/Tarawa': {
    id: 'app.Calendar.Timezone.Pacific/Tarawa',
    defaultMessage: 'Pacific/Tarawa',
  },
  'Pacific/Wake': {
    id: 'app.Calendar.Timezone.Pacific/Wake',
    defaultMessage: 'Pacific/Wake',
  },
  'Pacific/Wallis': {
    id: 'app.Calendar.Timezone.Pacific/Wallis',
    defaultMessage: 'Pacific/Wallis',
  },
  'Atlantic/Azores': {
    id: 'app.Calendar.Timezone.Atlantic/Azores',
    defaultMessage: 'Atlantic/Azores',
  },
  'Asia/Baghdad': {
    id: 'app.Calendar.Timezone.Asia/Baghdad',
    defaultMessage: 'Asia/Baghdad',
  },
  'Asia/Baku': {
    id: 'app.Calendar.Timezone.Asia/Baku',
    defaultMessage: 'Asia/Baku',
  },
  'Asia/Dubai': {
    id: 'app.Calendar.Timezone.Asia/Dubai',
    defaultMessage: 'Asia/Dubai',
  },
  'Indian/Mahe': {
    id: 'app.Calendar.Timezone.Indian/Mahe',
    defaultMessage: 'Indian/Mahe',
  },
  'Indian/Mauritius': {
    id: 'app.Calendar.Timezone.Indian/Mauritius',
    defaultMessage: 'Indian/Mauritius',
  },
  'Indian/Reunion': {
    id: 'app.Calendar.Timezone.Indian/Reunion',
    defaultMessage: 'Indian/Reunion',
  },
  'Asia/Bangkok': {
    id: 'app.Calendar.Timezone.Asia/Bangkok',
    defaultMessage: 'Asia/Bangkok',
  },
  'Europe/Belgrade': {
    id: 'app.Calendar.Timezone.Europe/Belgrade',
    defaultMessage: 'Europe/Belgrade',
  },
  'Europe/Berlin': {
    id: 'app.Calendar.Timezone.Europe/Berlin',
    defaultMessage: 'Europe/Berlin',
  },
  'America/Bogota': {
    id: 'app.Calendar.Timezone.America/Bogota',
    defaultMessage: 'America/Bogota',
  },
  'Europe/Bratislava': {
    id: 'app.Calendar.Timezone.Europe/Bratislava',
    defaultMessage: 'Europe/Bratislava',
  },
  'Australia/Brisbane': {
    id: 'app.Calendar.Timezone.Australia/Brisbane',
    defaultMessage: 'Australia/Brisbane',
  },
  'Europe/Brussels': {
    id: 'app.Calendar.Timezone.Europe/Brussels',
    defaultMessage: 'Europe/Brussels',
  },
  'Europe/Bucharest': {
    id: 'app.Calendar.Timezone.Europe/Bucharest',
    defaultMessage: 'Europe/Bucharest',
  },
  'Europe/Budapest': {
    id: 'app.Calendar.Timezone.Europe/Budapest',
    defaultMessage: 'Europe/Budapest',
  },
  'America/Araguaina': {
    id: 'app.Calendar.Timezone.America/Araguaina',
    defaultMessage: 'America/Araguaina',
  },
  'America/Argentina/Buenos_Aires': {
    id: 'app.Calendar.Timezone.AmericaArgentina/Buenos_Aires',
    defaultMessage: 'America/Argentina/Buenos_Aires',
  },
  'America/Argentina/Catamarca': {
    id: 'app.Calendar.Timezone.AmericaArgentina/Catamarca',
    defaultMessage: 'America/Argentina/Catamarca',
  },
  'America/Argentina/Cordoba': {
    id: 'app.Calendar.Timezone.AmericaArgentina/Cordoba',
    defaultMessage: 'America/Argentina/Cordoba',
  },
  'America/Argentina/Jujuy': {
    id: 'app.Calendar.Timezone.AmericaArgentina/Jujuy',
    defaultMessage: 'America/Argentina/Jujuy',
  },
  'America/Argentina/La_Rioja': {
    id: 'app.Calendar.Timezone.AmericaArgentina/La_Rioja',
    defaultMessage: 'America/Argentina/La_Rioja',
  },
  'America/Argentina/Mendoza': {
    id: 'app.Calendar.Timezone.AmericaArgentina/Mendoza',
    defaultMessage: 'America/Argentina/Mendoza',
  },
  'America/Argentina/Rio_Gallegos': {
    id: 'app.Calendar.Timezone.AmericaArgentina/Rio_Gallegos',
    defaultMessage: 'America/Argentina/Rio_Gallegos',
  },
  'America/Argentina/Salta': {
    id: 'app.Calendar.Timezone.AmericaArgentina/Salta',
    defaultMessage: 'America/Argentina/Salta',
  },
  'America/Argentina/San_Juan': {
    id: 'app.Calendar.Timezone.AmericaArgentina/San_Juan',
    defaultMessage: 'America/Argentina/San_Juan',
  },
  'America/Argentina/Tucuman': {
    id: 'app.Calendar.Timezone.AmericaArgentina/Tucuman',
    defaultMessage: 'America/Argentina/Tucuman',
  },
  'America/Argentina/Ushuaia': {
    id: 'app.Calendar.Timezone.AmericaArgentina/Ushuaia',
    defaultMessage: 'America/Argentina/Ushuaia',
  },
  'America/Bahia': {
    id: 'app.Calendar.Timezone.America/Bahia',
    defaultMessage: 'America/Bahia',
  },
  'America/Belem': {
    id: 'app.Calendar.Timezone.America/Belem',
    defaultMessage: 'America/Belem',
  },
  'America/Cayenne': {
    id: 'app.Calendar.Timezone.America/Cayenne',
    defaultMessage: 'America/Cayenne',
  },
  'America/Fortaleza': {
    id: 'app.Calendar.Timezone.America/Fortaleza',
    defaultMessage: 'America/Fortaleza',
  },
  'America/Godthab': {
    id: 'app.Calendar.Timezone.America/Godthab',
    defaultMessage: 'America/Godthab',
  },
  'America/Maceio': {
    id: 'app.Calendar.Timezone.America/Maceio',
    defaultMessage: 'America/Maceio',
  },
  'America/Miquelon': {
    id: 'app.Calendar.Timezone.America/Miquelon',
    defaultMessage: 'America/Miquelon',
  },
  'America/Montevideo': {
    id: 'app.Calendar.Timezone.America/Montevideo',
    defaultMessage: 'America/Montevideo',
  },
  'America/Paramaribo': {
    id: 'app.Calendar.Timezone.America/Paramaribo',
    defaultMessage: 'America/Paramaribo',
  },
  'America/Recife': {
    id: 'app.Calendar.Timezone.America/Recife',
    defaultMessage: 'America/Recife',
  },
  'America/Santarem': {
    id: 'app.Calendar.Timezone.America/Santarem',
    defaultMessage: 'America/Santarem',
  },
  'America/Sao_Paulo': {
    id: 'app.Calendar.Timezone.America/Sao_Paulo',
    defaultMessage: 'America/Sao_Paulo',
  },
  'Antarctica/Rothera': {
    id: 'app.Calendar.Timezone.Antarctica/Rothera',
    defaultMessage: 'Antarctica/Rothera',
  },
  'Africa/Blantyre': {
    id: 'app.Calendar.Timezone.Africa/Blantyre',
    defaultMessage: 'Africa/Blantyre',
  },
  'Africa/Bujumbura': {
    id: 'app.Calendar.Timezone.Africa/Bujumbura',
    defaultMessage: 'Africa/Bujumbura',
  },
  'Africa/Cairo': {
    id: 'app.Calendar.Timezone.Africa/Cairo',
    defaultMessage: 'Africa/Cairo',
  },
  'Africa/Gaborone': {
    id: 'app.Calendar.Timezone.Africa/Gaborone',
    defaultMessage: 'Africa/Gaborone',
  },
  'Africa/Johannesburg': {
    id: 'app.Calendar.Timezone.Africa/Johannesburg',
    defaultMessage: 'Africa/Johannesburg',
  },
  'Africa/Kigali': {
    id: 'app.Calendar.Timezone.Africa/Kigali',
    defaultMessage: 'Africa/Kigali',
  },
  'Africa/Lubumbashi': {
    id: 'app.Calendar.Timezone.Africa/Lubumbashi',
    defaultMessage: 'Africa/Lubumbashi',
  },
  'Africa/Lusaka': {
    id: 'app.Calendar.Timezone.Africa/Lusaka',
    defaultMessage: 'Africa/Lusaka',
  },
  'Africa/Maputo': {
    id: 'app.Calendar.Timezone.Africa/Maputo',
    defaultMessage: 'Africa/Maputo',
  },
  'Africa/Maseru': {
    id: 'app.Calendar.Timezone.Africa/Maseru',
    defaultMessage: 'Africa/Maseru',
  },
  'Africa/Mbabane': {
    id: 'app.Calendar.Timezone.Africa/Mbabane',
    defaultMessage: 'Africa/Mbabane',
  },
  'Africa/Tripoli': {
    id: 'app.Calendar.Timezone.Africa/Tripoli',
    defaultMessage: 'Africa/Tripoli',
  },
  'Asia/Amman': {
    id: 'app.Calendar.Timezone.Asia/Amman',
    defaultMessage: 'Asia/Amman',
  },
  'Asia/Beirut': {
    id: 'app.Calendar.Timezone.Asia/Beirut',
    defaultMessage: 'Asia/Beirut',
  },
  'Asia/Damascus': {
    id: 'app.Calendar.Timezone.Asia/Damascus',
    defaultMessage: 'Asia/Damascus',
  },
  'Asia/Gaza': {
    id: 'app.Calendar.Timezone.Asia/Gaza',
    defaultMessage: 'Asia/Gaza',
  },
  'Asia/Nicosia': {
    id: 'app.Calendar.Timezone.Asia/Nicosia',
    defaultMessage: 'Asia/Nicosia',
  },
  'Europe/Chisinau': {
    id: 'app.Calendar.Timezone.Europe/Chisinau',
    defaultMessage: 'Europe/Chisinau',
  },
  'Europe/Kaliningrad': {
    id: 'app.Calendar.Timezone.Europe/Kaliningrad',
    defaultMessage: 'Europe/Kaliningrad',
  },
  'Europe/Kiev': {
    id: 'app.Calendar.Timezone.Europe/Kiev',
    defaultMessage: 'Europe/Kiev',
  },
  'Europe/Mariehamn': {
    id: 'app.Calendar.Timezone.Europe/Mariehamn',
    defaultMessage: 'Europe/Mariehamn',
  },
  'Europe/Simferopol': {
    id: 'app.Calendar.Timezone.Europe/Simferopol',
    defaultMessage: 'Europe/Simferopol',
  },
  'Europe/Uzhgorod': {
    id: 'app.Calendar.Timezone.Europe/Uzhgorod',
    defaultMessage: 'Europe/Uzhgorod',
  },
  'Europe/Zaporozhye': {
    id: 'app.Calendar.Timezone.Europe/Zaporozhye',
    defaultMessage: 'Europe/Zaporozhye',
  },
  'America/Scoresbysund': {
    id: 'app.Calendar.Timezone.America/Scoresbysund',
    defaultMessage: 'America/Scoresbysund',
  },
  'Atlantic/Cape_Verde': {
    id: 'app.Calendar.Timezone.Atlantic/Cape_Verde',
    defaultMessage: 'Atlantic/Cape_Verde',
  },
  'America/Caracas': {
    id: 'app.Calendar.Timezone.America/Caracas',
    defaultMessage: 'America/Caracas',
  },
  'Africa/Casablanca': {
    id: 'app.Calendar.Timezone.Africa/Casablanca',
    defaultMessage: 'Africa/Casablanca',
  },
  'America/Belize': {
    id: 'app.Calendar.Timezone.America/Belize',
    defaultMessage: 'America/Belize',
  },
  'America/Cancun': {
    id: 'app.Calendar.Timezone.America/Cancun',
    defaultMessage: 'America/Cancun',
  },
  'America/Chicago': {
    id: 'app.Calendar.Timezone.America/Chicago',
    defaultMessage: 'America/Chicago',
  },
  'America/Costa_Rica': {
    id: 'app.Calendar.Timezone.America/Costa_Rica',
    defaultMessage: 'America/Costa_Rica',
  },
  'America/El_Salvador': {
    id: 'app.Calendar.Timezone.America/El_Salvador',
    defaultMessage: 'America/El_Salvador',
  },
  'America/Guatemala': {
    id: 'app.Calendar.Timezone.America/Guatemala',
    defaultMessage: 'America/Guatemala',
  },
  'America/Indiana/Knox': {
    id: 'app.Calendar.Timezone.AmericaIndiana/Knox',
    defaultMessage: 'America/Indiana/Knox',
  },
  'America/Indiana/Tell_City': {
    id: 'app.Calendar.Timezone.AmericaIndiana/Tell_City',
    defaultMessage: 'America/Indiana/Tell_City',
  },
  'America/Managua': {
    id: 'app.Calendar.Timezone.America/Managua',
    defaultMessage: 'America/Managua',
  },
  'America/Matamoros': {
    id: 'app.Calendar.Timezone.America/Matamoros',
    defaultMessage: 'America/Matamoros',
  },
  'America/Menominee': {
    id: 'app.Calendar.Timezone.America/Menominee',
    defaultMessage: 'America/Menominee',
  },
  'America/Merida': {
    id: 'app.Calendar.Timezone.America/Merida',
    defaultMessage: 'America/Merida',
  },
  'America/North_Dakota/Center': {
    id: 'app.Calendar.Timezone.AmericaNorth_Dakota/Center',
    defaultMessage: 'America/North_Dakota/Center',
  },
  'America/North_Dakota/New_Salem': {
    id: 'app.Calendar.Timezone.AmericaNorth_Dakota/New_Salem',
    defaultMessage: 'America/North_Dakota/New_Salem',
  },
  'America/Rainy_River': {
    id: 'app.Calendar.Timezone.America/Rainy_River',
    defaultMessage: 'America/Rainy_River',
  },
  'America/Rankin_Inlet': {
    id: 'app.Calendar.Timezone.America/Rankin_Inlet',
    defaultMessage: 'America/Rankin_Inlet',
  },
  'America/Regina': {
    id: 'app.Calendar.Timezone.America/Regina',
    defaultMessage: 'America/Regina',
  },
  'America/Swift_Current': {
    id: 'app.Calendar.Timezone.America/Swift_Current',
    defaultMessage: 'America/Swift_Current',
  },
  'America/Tegucigalpa': {
    id: 'app.Calendar.Timezone.America/Tegucigalpa',
    defaultMessage: 'America/Tegucigalpa',
  },
  'America/Winnipeg': {
    id: 'app.Calendar.Timezone.America/Winnipeg',
    defaultMessage: 'America/Winnipeg',
  },
  'Pacific/Easter': {
    id: 'app.Calendar.Timezone.Pacific/Easter',
    defaultMessage: 'Pacific/Easter',
  },
  'Pacific/Galapagos': {
    id: 'app.Calendar.Timezone.Pacific/Galapagos',
    defaultMessage: 'Pacific/Galapagos',
  },
  'America/Chihuahua': {
    id: 'app.Calendar.Timezone.America/Chihuahua',
    defaultMessage: 'America/Chihuahua',
  },
  'Asia/Chongqing': {
    id: 'app.Calendar.Timezone.Asia/Chongqing',
    defaultMessage: 'Asia/Chongqing',
  },
  'Europe/Copenhagen': {
    id: 'app.Calendar.Timezone.Europe/Copenhagen',
    defaultMessage: 'Europe/Copenhagen',
  },
  'Australia/Darwin': {
    id: 'app.Calendar.Timezone.Australia/Darwin',
    defaultMessage: 'Australia/Darwin',
  },
  'Antarctica/Mawson': {
    id: 'app.Calendar.Timezone.Antarctica/Mawson',
    defaultMessage: 'Antarctica/Mawson',
  },
  'Asia/Bishkek': {
    id: 'app.Calendar.Timezone.Asia/Bishkek',
    defaultMessage: 'Asia/Bishkek',
  },
  'Asia/Dhaka': {
    id: 'app.Calendar.Timezone.Asia/Dhaka',
    defaultMessage: 'Asia/Dhaka',
  },
  'Asia/Karachi': {
    id: 'app.Calendar.Timezone.Asia/Karachi',
    defaultMessage: 'Asia/Karachi',
  },
  'Asia/Novokuznetsk': {
    id: 'app.Calendar.Timezone.Asia/Novokuznetsk',
    defaultMessage: 'Asia/Novokuznetsk',
  },
  'Asia/Omsk': {
    id: 'app.Calendar.Timezone.Asia/Omsk',
    defaultMessage: 'Asia/Omsk',
  },
  'Asia/Qyzylorda': {
    id: 'app.Calendar.Timezone.Asia/Qyzylorda',
    defaultMessage: 'Asia/Qyzylorda',
  },
  'Asia/Thimphu': {
    id: 'app.Calendar.Timezone.Asia/Thimphu',
    defaultMessage: 'Asia/Thimphu',
  },
  'Indian/Chagos': {
    id: 'app.Calendar.Timezone.Indian/Chagos',
    defaultMessage: 'Indian/Chagos',
  },
  'Europe/Dublin': {
    id: 'app.Calendar.Timezone.Europe/Dublin',
    defaultMessage: 'Europe/Dublin',
  },
  'America/Atikokan': {
    id: 'app.Calendar.Timezone.America/Atikokan',
    defaultMessage: 'America/Atikokan',
  },
  'America/Cayman': {
    id: 'app.Calendar.Timezone.America/Cayman',
    defaultMessage: 'America/Cayman',
  },
  'America/Detroit': {
    id: 'app.Calendar.Timezone.America/Detroit',
    defaultMessage: 'America/Detroit',
  },
  'America/Grand_Turk': {
    id: 'app.Calendar.Timezone.America/Grand_Turk',
    defaultMessage: 'America/Grand_Turk',
  },
  'America/Guayaquil': {
    id: 'app.Calendar.Timezone.America/Guayaquil',
    defaultMessage: 'America/Guayaquil',
  },
  'America/Havana': {
    id: 'app.Calendar.Timezone.America/Havana',
    defaultMessage: 'America/Havana',
  },
  'America/Indiana/Indianapolis': {
    id: 'app.Calendar.Timezone.AmericaIndiana/Indianapolis',
    defaultMessage: 'America/Indiana/Indianapolis',
  },
  'America/Indiana/Marengo': {
    id: 'app.Calendar.Timezone.AmericaIndiana/Marengo',
    defaultMessage: 'America/Indiana/Marengo',
  },
  'America/Indiana/Petersburg': {
    id: 'app.Calendar.Timezone.AmericaIndiana/Petersburg',
    defaultMessage: 'America/Indiana/Petersburg',
  },
  'America/Indiana/Vevay': {
    id: 'app.Calendar.Timezone.AmericaIndiana/Vevay',
    defaultMessage: 'America/Indiana/Vevay',
  },
  'America/Indiana/Vincennes': {
    id: 'app.Calendar.Timezone.AmericaIndiana/Vincennes',
    defaultMessage: 'America/Indiana/Vincennes',
  },
  'America/Indiana/Winamac': {
    id: 'app.Calendar.Timezone.AmericaIndiana/Winamac',
    defaultMessage: 'America/Indiana/Winamac',
  },
  'America/Iqaluit': {
    id: 'app.Calendar.Timezone.America/Iqaluit',
    defaultMessage: 'America/Iqaluit',
  },
  'America/Jamaica': {
    id: 'app.Calendar.Timezone.America/Jamaica',
    defaultMessage: 'America/Jamaica',
  },
  'America/Kentucky/Louisville': {
    id: 'app.Calendar.Timezone.AmericaKentucky/Louisville',
    defaultMessage: 'America/Kentucky/Louisville',
  },
  'America/Kentucky/Monticello': {
    id: 'app.Calendar.Timezone.AmericaKentucky/Monticello',
    defaultMessage: 'America/Kentucky/Monticello',
  },
  'America/Montreal': {
    id: 'app.Calendar.Timezone.America/Montreal',
    defaultMessage: 'America/Montreal',
  },
  'America/Nassau': {
    id: 'app.Calendar.Timezone.America/Nassau',
    defaultMessage: 'America/Nassau',
  },
  'America/New_York': {
    id: 'app.Calendar.Timezone.America/New_York',
    defaultMessage: 'America/New_York',
  },
  'America/Nipigon': {
    id: 'app.Calendar.Timezone.America/Nipigon',
    defaultMessage: 'America/Nipigon',
  },
  'America/Panama': {
    id: 'app.Calendar.Timezone.America/Panama',
    defaultMessage: 'America/Panama',
  },
  'America/Pangnirtung': {
    id: 'app.Calendar.Timezone.America/Pangnirtung',
    defaultMessage: 'America/Pangnirtung',
  },
  'America/Port-au-Prince': {
    id: 'app.Calendar.Timezone.America/Portau-Prince',
    defaultMessage: 'America/Port-au-Prince',
  },
  'America/Resolute': {
    id: 'app.Calendar.Timezone.America/Resolute',
    defaultMessage: 'America/Resolute',
  },
  'America/Thunder_Bay': {
    id: 'app.Calendar.Timezone.America/Thunder_Bay',
    defaultMessage: 'America/Thunder_Bay',
  },
  'America/Toronto': {
    id: 'app.Calendar.Timezone.America/Toronto',
    defaultMessage: 'America/Toronto',
  },
  'Pacific/Fiji': {
    id: 'app.Calendar.Timezone.Pacific/Fiji',
    defaultMessage: 'Pacific/Fiji',
  },
  'Pacific/Guam': {
    id: 'app.Calendar.Timezone.Pacific/Guam',
    defaultMessage: 'Pacific/Guam',
  },
  'Africa/Harare': {
    id: 'app.Calendar.Timezone.Africa/Harare',
    defaultMessage: 'Africa/Harare',
  },
  'America/Adak': {
    id: 'app.Calendar.Timezone.America/Adak',
    defaultMessage: 'America/Adak',
  },
  'Pacific/Fakaofo': {
    id: 'app.Calendar.Timezone.Pacific/Fakaofo',
    defaultMessage: 'Pacific/Fakaofo',
  },
  'Pacific/Honolulu': {
    id: 'app.Calendar.Timezone.Pacific/Honolulu',
    defaultMessage: 'Pacific/Honolulu',
  },
  'Pacific/Johnston': {
    id: 'app.Calendar.Timezone.Pacific/Johnston',
    defaultMessage: 'Pacific/Johnston',
  },
  'Pacific/Rarotonga': {
    id: 'app.Calendar.Timezone.Pacific/Rarotonga',
    defaultMessage: 'Pacific/Rarotonga',
  },
  'Pacific/Tahiti': {
    id: 'app.Calendar.Timezone.Pacific/Tahiti',
    defaultMessage: 'Pacific/Tahiti',
  },
  'Europe/Helsinki': {
    id: 'app.Calendar.Timezone.Europe/Helsinki',
    defaultMessage: 'Europe/Helsinki',
  },
  'Australia/Hobart': {
    id: 'app.Calendar.Timezone.Australia/Hobart',
    defaultMessage: 'Australia/Hobart',
  },
  'Antarctica/Casey': {
    id: 'app.Calendar.Timezone.Antarctica/Casey',
    defaultMessage: 'Antarctica/Casey',
  },
  'Asia/Brunei': {
    id: 'app.Calendar.Timezone.Asia/Brunei',
    defaultMessage: 'Asia/Brunei',
  },
  'Asia/Choibalsan': {
    id: 'app.Calendar.Timezone.Asia/Choibalsan',
    defaultMessage: 'Asia/Choibalsan',
  },
  'Asia/Harbin': {
    id: 'app.Calendar.Timezone.Asia/Harbin',
    defaultMessage: 'Asia/Harbin',
  },
  'Asia/Hong_Kong': {
    id: 'app.Calendar.Timezone.Asia/Hong_Kong',
    defaultMessage: 'Asia/Hong_Kong',
  },
  'Asia/Kashgar': {
    id: 'app.Calendar.Timezone.Asia/Kashgar',
    defaultMessage: 'Asia/Kashgar',
  },
  'Asia/Kuching': {
    id: 'app.Calendar.Timezone.Asia/Kuching',
    defaultMessage: 'Asia/Kuching',
  },
  'Asia/Macau': {
    id: 'app.Calendar.Timezone.Asia/Macau',
    defaultMessage: 'Asia/Macau',
  },
  'Asia/Makassar': {
    id: 'app.Calendar.Timezone.Asia/Makassar',
    defaultMessage: 'Asia/Makassar',
  },
  'Asia/Manila': {
    id: 'app.Calendar.Timezone.Asia/Manila',
    defaultMessage: 'Asia/Manila',
  },
  'Asia/Shanghai': {
    id: 'app.Calendar.Timezone.Asia/Shanghai',
    defaultMessage: 'Asia/Shanghai',
  },
  'Asia/Ulaanbaatar': {
    id: 'app.Calendar.Timezone.Asia/Ulaanbaatar',
    defaultMessage: 'Asia/Ulaanbaatar',
  },
  'Asia/Irkutsk': {
    id: 'app.Calendar.Timezone.Asia/Irkutsk',
    defaultMessage: 'Asia/Irkutsk',
  },
  'Europe/Istanbul': {
    id: 'app.Calendar.Timezone.Europe/Istanbul',
    defaultMessage: 'Europe/Istanbul',
  },
  'Antarctica/Davis': {
    id: 'app.Calendar.Timezone.Antarctica/Davis',
    defaultMessage: 'Antarctica/Davis',
  },
  'Asia/Hovd': {
    id: 'app.Calendar.Timezone.Asia/Hovd',
    defaultMessage: 'Asia/Hovd',
  },
  'Asia/Ho_Chi_Minh': {
    id: 'app.Calendar.Timezone.Asia/Ho_Chi_Minh',
    defaultMessage: 'Asia/Ho_Chi_Minh',
  },
  'Asia/Jakarta': {
    id: 'app.Calendar.Timezone.Asia/Jakarta',
    defaultMessage: 'Asia/Jakarta',
  },
  'Asia/Phnom_Penh': {
    id: 'app.Calendar.Timezone.Asia/Phnom_Penh',
    defaultMessage: 'Asia/Phnom_Penh',
  },
  'Asia/Pontianak': {
    id: 'app.Calendar.Timezone.Asia/Pontianak',
    defaultMessage: 'Asia/Pontianak',
  },
  'Asia/Vientiane': {
    id: 'app.Calendar.Timezone.Asia/Vientiane',
    defaultMessage: 'Asia/Vientiane',
  },
  'Indian/Christmas': {
    id: 'app.Calendar.Timezone.Indian/Christmas',
    defaultMessage: 'Indian/Christmas',
  },
  'Asia/Jerusalem': {
    id: 'app.Calendar.Timezone.Asia/Jerusalem',
    defaultMessage: 'Asia/Jerusalem',
  },
  'Asia/Kabul': {
    id: 'app.Calendar.Timezone.Asia/Kabul',
    defaultMessage: 'Asia/Kabul',
  },
  'Asia/Kamchatka': {
    id: 'app.Calendar.Timezone.Asia/Kamchatka',
    defaultMessage: 'Asia/Kamchatka',
  },
  'Asia/Aqtau': {
    id: 'app.Calendar.Timezone.Asia/Aqtau',
    defaultMessage: 'Asia/Aqtau',
  },
  'Asia/Aqtobe': {
    id: 'app.Calendar.Timezone.Asia/Aqtobe',
    defaultMessage: 'Asia/Aqtobe',
  },
  'Asia/Ashgabat': {
    id: 'app.Calendar.Timezone.Asia/Ashgabat',
    defaultMessage: 'Asia/Ashgabat',
  },
  'Asia/Dushanbe': {
    id: 'app.Calendar.Timezone.Asia/Dushanbe',
    defaultMessage: 'Asia/Dushanbe',
  },
  'Asia/Oral': {
    id: 'app.Calendar.Timezone.Asia/Oral',
    defaultMessage: 'Asia/Oral',
  },
  'Asia/Samarkand': {
    id: 'app.Calendar.Timezone.Asia/Samarkand',
    defaultMessage: 'Asia/Samarkand',
  },
  'Asia/Yekaterinburg': {
    id: 'app.Calendar.Timezone.Asia/Yekaterinburg',
    defaultMessage: 'Asia/Yekaterinburg',
  },
  'Indian/Kerguelen': {
    id: 'app.Calendar.Timezone.Indian/Kerguelen',
    defaultMessage: 'Indian/Kerguelen',
  },
  'Indian/Maldives': {
    id: 'app.Calendar.Timezone.Indian/Maldives',
    defaultMessage: 'Indian/Maldives',
  },
  'Asia/Kathmandu': {
    id: 'app.Calendar.Timezone.Asia/Kathmandu',
    defaultMessage: 'Asia/Kathmandu',
  },
  'Asia/Kolkata': {
    id: 'app.Calendar.Timezone.Asia/Kolkata',
    defaultMessage: 'Asia/Kolkata',
  },
  'Asia/Krasnoyarsk': {
    id: 'app.Calendar.Timezone.Asia/Krasnoyarsk',
    defaultMessage: 'Asia/Krasnoyarsk',
  },
  'Asia/Kuala_Lumpur': {
    id: 'app.Calendar.Timezone.Asia/Kuala_Lumpur',
    defaultMessage: 'Asia/Kuala_Lumpur',
  },
  'Asia/Kuwait': {
    id: 'app.Calendar.Timezone.Asia/Kuwait',
    defaultMessage: 'Asia/Kuwait',
  },
  'America/La_Paz': {
    id: 'app.Calendar.Timezone.America/La_Paz',
    defaultMessage: 'America/La_Paz',
  },
  'America/Lima': {
    id: 'app.Calendar.Timezone.America/Lima',
    defaultMessage: 'America/Lima',
  },
  'Europe/Lisbon': {
    id: 'app.Calendar.Timezone.Europe/Lisbon',
    defaultMessage: 'Europe/Lisbon',
  },
  'Europe/Ljubljana': {
    id: 'app.Calendar.Timezone.Europe/Ljubljana',
    defaultMessage: 'Europe/Ljubljana',
  },
  'Africa/Abidjan': {
    id: 'app.Calendar.Timezone.Africa/Abidjan',
    defaultMessage: 'Africa/Abidjan',
  },
  'Africa/Accra': {
    id: 'app.Calendar.Timezone.Africa/Accra',
    defaultMessage: 'Africa/Accra',
  },
  'Africa/Bamako': {
    id: 'app.Calendar.Timezone.Africa/Bamako',
    defaultMessage: 'Africa/Bamako',
  },
  'Africa/Banjul': {
    id: 'app.Calendar.Timezone.Africa/Banjul',
    defaultMessage: 'Africa/Banjul',
  },
  'Africa/Bissau': {
    id: 'app.Calendar.Timezone.Africa/Bissau',
    defaultMessage: 'Africa/Bissau',
  },
  'Africa/Conakry': {
    id: 'app.Calendar.Timezone.Africa/Conakry',
    defaultMessage: 'Africa/Conakry',
  },
  'Africa/Dakar': {
    id: 'app.Calendar.Timezone.Africa/Dakar',
    defaultMessage: 'Africa/Dakar',
  },
  'Africa/El_Aaiun': {
    id: 'app.Calendar.Timezone.Africa/El_Aaiun',
    defaultMessage: 'Africa/El_Aaiun',
  },
  'Africa/Freetown': {
    id: 'app.Calendar.Timezone.Africa/Freetown',
    defaultMessage: 'Africa/Freetown',
  },
  'Africa/Lome': {
    id: 'app.Calendar.Timezone.Africa/Lome',
    defaultMessage: 'Africa/Lome',
  },
  'Africa/Nouakchott': {
    id: 'app.Calendar.Timezone.Africa/Nouakchott',
    defaultMessage: 'Africa/Nouakchott',
  },
  'Africa/Ouagadougou': {
    id: 'app.Calendar.Timezone.Africa/Ouagadougou',
    defaultMessage: 'Africa/Ouagadougou',
  },
  'Africa/Sao_Tome': {
    id: 'app.Calendar.Timezone.Africa/Sao_Tome',
    defaultMessage: 'Africa/Sao_Tome',
  },
  'America/Danmarkshavn': {
    id: 'app.Calendar.Timezone.America/Danmarkshavn',
    defaultMessage: 'America/Danmarkshavn',
  },
  'Antarctica/Vostok': {
    id: 'app.Calendar.Timezone.Antarctica/Vostok',
    defaultMessage: 'Antarctica/Vostok',
  },
  'Atlantic/Canary': {
    id: 'app.Calendar.Timezone.Atlantic/Canary',
    defaultMessage: 'Atlantic/Canary',
  },
  'Atlantic/Faroe': {
    id: 'app.Calendar.Timezone.Atlantic/Faroe',
    defaultMessage: 'Atlantic/Faroe',
  },
  'Atlantic/Madeira': {
    id: 'app.Calendar.Timezone.Atlantic/Madeira',
    defaultMessage: 'Atlantic/Madeira',
  },
  'Atlantic/Reykjavik': {
    id: 'app.Calendar.Timezone.Atlantic/Reykjavik',
    defaultMessage: 'Atlantic/Reykjavik',
  },
  'Atlantic/St_Helena': {
    id: 'app.Calendar.Timezone.Atlantic/St_Helena',
    defaultMessage: 'Atlantic/St_Helena',
  },
  'Europe/Guernsey': {
    id: 'app.Calendar.Timezone.Europe/Guernsey',
    defaultMessage: 'Europe/Guernsey',
  },
  'Europe/Isle_of_Man': {
    id: 'app.Calendar.Timezone.Europe/Isle_of_Man',
    defaultMessage: 'Europe/Isle_of_Man',
  },
  'Europe/Jersey': {
    id: 'app.Calendar.Timezone.Europe/Jersey',
    defaultMessage: 'Europe/Jersey',
  },
  'Europe/London': {
    id: 'app.Calendar.Timezone.Europe/London',
    defaultMessage: 'Europe/London',
  },
  'Europe/Madrid': {
    id: 'app.Calendar.Timezone.Europe/Madrid',
    defaultMessage: 'Europe/Madrid',
  },
  'Asia/Magadan': {
    id: 'app.Calendar.Timezone.Asia/Magadan',
    defaultMessage: 'Asia/Magadan',
  },
  'America/Mazatlan': {
    id: 'app.Calendar.Timezone.America/Mazatlan',
    defaultMessage: 'America/Mazatlan',
  },
  'Australia/Melbourne': {
    id: 'app.Calendar.Timezone.Australia/Melbourne',
    defaultMessage: 'Australia/Melbourne',
  },
  'America/Mexico_City': {
    id: 'app.Calendar.Timezone.America/Mexico_City',
    defaultMessage: 'America/Mexico_City',
  },
  'America/Noronha': {
    id: 'app.Calendar.Timezone.America/Noronha',
    defaultMessage: 'America/Noronha',
  },
  'Atlantic/South_Georgia': {
    id: 'app.Calendar.Timezone.Atlantic/South_Georgia',
    defaultMessage: 'Atlantic/South_Georgia',
  },
  'Europe/Minsk': {
    id: 'app.Calendar.Timezone.Europe/Minsk',
    defaultMessage: 'Europe/Minsk',
  },
  'Africa/Monrovia': {
    id: 'app.Calendar.Timezone.Africa/Monrovia',
    defaultMessage: 'Africa/Monrovia',
  },
  'America/Monterrey': {
    id: 'app.Calendar.Timezone.America/Monterrey',
    defaultMessage: 'America/Monterrey',
  },
  'Africa/Addis_Ababa': {
    id: 'app.Calendar.Timezone.Africa/Addis_Ababa',
    defaultMessage: 'Africa/Addis_Ababa',
  },
  'Africa/Asmara': {
    id: 'app.Calendar.Timezone.Africa/Asmara',
    defaultMessage: 'Africa/Asmara',
  },
  'Africa/Dar_es_Salaam': {
    id: 'app.Calendar.Timezone.Africa/Dar_es_Salaam',
    defaultMessage: 'Africa/Dar_es_Salaam',
  },
  'Africa/Djibouti': {
    id: 'app.Calendar.Timezone.Africa/Djibouti',
    defaultMessage: 'Africa/Djibouti',
  },
  'Africa/Kampala': {
    id: 'app.Calendar.Timezone.Africa/Kampala',
    defaultMessage: 'Africa/Kampala',
  },
  'Africa/Khartoum': {
    id: 'app.Calendar.Timezone.Africa/Khartoum',
    defaultMessage: 'Africa/Khartoum',
  },
  'Africa/Mogadishu': {
    id: 'app.Calendar.Timezone.Africa/Mogadishu',
    defaultMessage: 'Africa/Mogadishu',
  },
  'Antarctica/Syowa': {
    id: 'app.Calendar.Timezone.Antarctica/Syowa',
    defaultMessage: 'Antarctica/Syowa',
  },
  'Asia/Aden': {
    id: 'app.Calendar.Timezone.Asia/Aden',
    defaultMessage: 'Asia/Aden',
  },
  'Asia/Bahrain': {
    id: 'app.Calendar.Timezone.Asia/Bahrain',
    defaultMessage: 'Asia/Bahrain',
  },
  'Asia/Qatar': {
    id: 'app.Calendar.Timezone.Asia/Qatar',
    defaultMessage: 'Asia/Qatar',
  },
  'Europe/Moscow': {
    id: 'app.Calendar.Timezone.Europe/Moscow',
    defaultMessage: 'Europe/Moscow',
  },
  'Europe/Samara': {
    id: 'app.Calendar.Timezone.Europe/Samara',
    defaultMessage: 'Europe/Samara',
  },
  'Indian/Antananarivo': {
    id: 'app.Calendar.Timezone.Indian/Antananarivo',
    defaultMessage: 'Indian/Antananarivo',
  },
  'Indian/Comoro': {
    id: 'app.Calendar.Timezone.Indian/Comoro',
    defaultMessage: 'Indian/Comoro',
  },
  'Indian/Mayotte': {
    id: 'app.Calendar.Timezone.Indian/Mayotte',
    defaultMessage: 'Indian/Mayotte',
  },
  'America/Boise': {
    id: 'app.Calendar.Timezone.America/Boise',
    defaultMessage: 'America/Boise',
  },
  'America/Cambridge_Bay': {
    id: 'app.Calendar.Timezone.America/Cambridge_Bay',
    defaultMessage: 'America/Cambridge_Bay',
  },
  'America/Dawson_Creek': {
    id: 'app.Calendar.Timezone.America/Dawson_Creek',
    defaultMessage: 'America/Dawson_Creek',
  },
  'America/Denver': {
    id: 'app.Calendar.Timezone.America/Denver',
    defaultMessage: 'America/Denver',
  },
  'America/Edmonton': {
    id: 'app.Calendar.Timezone.America/Edmonton',
    defaultMessage: 'America/Edmonton',
  },
  'America/Hermosillo': {
    id: 'app.Calendar.Timezone.America/Hermosillo',
    defaultMessage: 'America/Hermosillo',
  },
  'America/Inuvik': {
    id: 'app.Calendar.Timezone.America/Inuvik',
    defaultMessage: 'America/Inuvik',
  },
  'America/Ojinaga': {
    id: 'app.Calendar.Timezone.America/Ojinaga',
    defaultMessage: 'America/Ojinaga',
  },
  'America/Phoenix': {
    id: 'app.Calendar.Timezone.America/Phoenix',
    defaultMessage: 'America/Phoenix',
  },
  'America/Shiprock': {
    id: 'app.Calendar.Timezone.America/Shiprock',
    defaultMessage: 'America/Shiprock',
  },
  'America/Yellowknife': {
    id: 'app.Calendar.Timezone.America/Yellowknife',
    defaultMessage: 'America/Yellowknife',
  },
  'Asia/Colombo': {
    id: 'app.Calendar.Timezone.Asia/Colombo',
    defaultMessage: 'Asia/Colombo',
  },
  'Asia/Muscat': {
    id: 'app.Calendar.Timezone.Asia/Muscat',
    defaultMessage: 'Asia/Muscat',
  },
  'Africa/Nairobi': {
    id: 'app.Calendar.Timezone.Africa/Nairobi',
    defaultMessage: 'Africa/Nairobi',
  },
  'America/St_Johns': {
    id: 'app.Calendar.Timezone.America/St_Johns',
    defaultMessage: 'America/St_Johns',
  },
  'Asia/Novosibirsk': {
    id: 'app.Calendar.Timezone.Asia/Novosibirsk',
    defaultMessage: 'Asia/Novosibirsk',
  },
  'Pacific/Enderbury': {
    id: 'app.Calendar.Timezone.Pacific/Enderbury',
    defaultMessage: 'Pacific/Enderbury',
  },
  'Pacific/Tongatapu': {
    id: 'app.Calendar.Timezone.Pacific/Tongatapu',
    defaultMessage: 'Pacific/Tongatapu',
  },
  'America/Dawson': {
    id: 'app.Calendar.Timezone.America/Dawson',
    defaultMessage: 'America/Dawson',
  },
  'America/Los_Angeles': {
    id: 'app.Calendar.Timezone.America/Los_Angeles',
    defaultMessage: 'America/Los_Angeles',
  },
  'America/Santa_Isabel': {
    id: 'app.Calendar.Timezone.America/Santa_Isabel',
    defaultMessage: 'America/Santa_Isabel',
  },
  'America/Vancouver': {
    id: 'app.Calendar.Timezone.America/Vancouver',
    defaultMessage: 'America/Vancouver',
  },
  'America/Whitehorse': {
    id: 'app.Calendar.Timezone.America/Whitehorse',
    defaultMessage: 'America/Whitehorse',
  },
  'Pacific/Pitcairn': {
    id: 'app.Calendar.Timezone.Pacific/Pitcairn',
    defaultMessage: 'Pacific/Pitcairn',
  },
  'Africa/Algiers': {
    id: 'app.Calendar.Timezone.Africa/Algiers',
    defaultMessage: 'Africa/Algiers',
  },
  'Africa/Bangui': {
    id: 'app.Calendar.Timezone.Africa/Bangui',
    defaultMessage: 'Africa/Bangui',
  },
  'Africa/Brazzaville': {
    id: 'app.Calendar.Timezone.Africa/Brazzaville',
    defaultMessage: 'Africa/Brazzaville',
  },
  'Africa/Ceuta': {
    id: 'app.Calendar.Timezone.Africa/Ceuta',
    defaultMessage: 'Africa/Ceuta',
  },
  'Africa/Douala': {
    id: 'app.Calendar.Timezone.Africa/Douala',
    defaultMessage: 'Africa/Douala',
  },
  'Africa/Kinshasa': {
    id: 'app.Calendar.Timezone.Africa/Kinshasa',
    defaultMessage: 'Africa/Kinshasa',
  },
  'Africa/Lagos': {
    id: 'app.Calendar.Timezone.Africa/Lagos',
    defaultMessage: 'Africa/Lagos',
  },
  'Africa/Libreville': {
    id: 'app.Calendar.Timezone.Africa/Libreville',
    defaultMessage: 'Africa/Libreville',
  },
  'Africa/Luanda': {
    id: 'app.Calendar.Timezone.Africa/Luanda',
    defaultMessage: 'Africa/Luanda',
  },
  'Africa/Malabo': {
    id: 'app.Calendar.Timezone.Africa/Malabo',
    defaultMessage: 'Africa/Malabo',
  },
  'Africa/Ndjamena': {
    id: 'app.Calendar.Timezone.Africa/Ndjamena',
    defaultMessage: 'Africa/Ndjamena',
  },
  'Africa/Niamey': {
    id: 'app.Calendar.Timezone.Africa/Niamey',
    defaultMessage: 'Africa/Niamey',
  },
  'Africa/Porto-Novo': {
    id: 'app.Calendar.Timezone.AfricaPorto-Novo',
    defaultMessage: 'Africa/Porto-Novo',
  },
  'Africa/Tunis': {
    id: 'app.Calendar.Timezone.Africa/Tunis',
    defaultMessage: 'Africa/Tunis',
  },
  'Africa/Windhoek': {
    id: 'app.Calendar.Timezone.Africa/Windhoek',
    defaultMessage: 'Africa/Windhoek',
  },
  'Arctic/Longyearbyen': {
    id: 'app.Calendar.Timezone.Arctic/Longyearbyen',
    defaultMessage: 'Arctic/Longyearbyen',
  },
  'Europe/Andorra': {
    id: 'app.Calendar.Timezone.Europe/Andorra',
    defaultMessage: 'Europe/Andorra',
  },
  'Europe/Gibraltar': {
    id: 'app.Calendar.Timezone.Europe/Gibraltar',
    defaultMessage: 'Europe/Gibraltar',
  },
  'Europe/Luxembourg': {
    id: 'app.Calendar.Timezone.Europe/Luxembourg',
    defaultMessage: 'Europe/Luxembourg',
  },
  'Europe/Malta': {
    id: 'app.Calendar.Timezone.Europe/Malta',
    defaultMessage: 'Europe/Malta',
  },
  'Europe/Monaco': {
    id: 'app.Calendar.Timezone.Europe/Monaco',
    defaultMessage: 'Europe/Monaco',
  },
  'Europe/Oslo': {
    id: 'app.Calendar.Timezone.Europe/Oslo',
    defaultMessage: 'Europe/Oslo',
  },
  'Europe/Paris': {
    id: 'app.Calendar.Timezone.Europe/Paris',
    defaultMessage: 'Europe/Paris',
  },
  'Europe/Podgorica': {
    id: 'app.Calendar.Timezone.Europe/Podgorica',
    defaultMessage: 'Europe/Podgorica',
  },
  'Europe/San_Marino': {
    id: 'app.Calendar.Timezone.Europe/San_Marino',
    defaultMessage: 'Europe/San_Marino',
  },
  'Europe/Tirane': {
    id: 'app.Calendar.Timezone.Europe/Tirane',
    defaultMessage: 'Europe/Tirane',
  },
  'Europe/Vaduz': {
    id: 'app.Calendar.Timezone.Europe/Vaduz',
    defaultMessage: 'Europe/Vaduz',
  },
  'Europe/Vatican': {
    id: 'app.Calendar.Timezone.Europe/Vatican',
    defaultMessage: 'Europe/Vatican',
  },
  'Europe/Zurich': {
    id: 'app.Calendar.Timezone.Europe/Zurich',
    defaultMessage: 'Europe/Zurich',
  },
  'Australia/Perth': {
    id: 'app.Calendar.Timezone.Australia/Perth',
    defaultMessage: 'Australia/Perth',
  },
  'Pacific/Port_Moresby': {
    id: 'app.Calendar.Timezone.Pacific/Port_Moresby',
    defaultMessage: 'Pacific/Port_Moresby',
  },
  'Europe/Prague': {
    id: 'app.Calendar.Timezone.Europe/Prague',
    defaultMessage: 'Europe/Prague',
  },
  'Asia/Rangoon': {
    id: 'app.Calendar.Timezone.Asia/Rangoon',
    defaultMessage: 'Asia/Rangoon',
  },
  'Indian/Cocos': {
    id: 'app.Calendar.Timezone.Indian/Cocos',
    defaultMessage: 'Indian/Cocos',
  },
  'Europe/Riga': {
    id: 'app.Calendar.Timezone.Europe/Riga',
    defaultMessage: 'Europe/Riga',
  },
  'Asia/Riyadh': {
    id: 'app.Calendar.Timezone.Asia/Riyadh',
    defaultMessage: 'Asia/Riyadh',
  },
  'Europe/Rome': {
    id: 'app.Calendar.Timezone.Europe/Rome',
    defaultMessage: 'Europe/Rome',
  },
  'Pacific/Apia': {
    id: 'app.Calendar.Timezone.Pacific/Apia',
    defaultMessage: 'Pacific/Apia',
  },
  'Pacific/Midway': {
    id: 'app.Calendar.Timezone.Pacific/Midway',
    defaultMessage: 'Pacific/Midway',
  },
  'Pacific/Niue': {
    id: 'app.Calendar.Timezone.Pacific/Niue',
    defaultMessage: 'Pacific/Niue',
  },
  'Pacific/Pago_Pago': {
    id: 'app.Calendar.Timezone.Pacific/Pago_Pago',
    defaultMessage: 'Pacific/Pago_Pago',
  },
  'America/Santiago': {
    id: 'app.Calendar.Timezone.America/Santiago',
    defaultMessage: 'America/Santiago',
  },
  'Europe/Sarajevo': {
    id: 'app.Calendar.Timezone.Europe/Sarajevo',
    defaultMessage: 'Europe/Sarajevo',
  },
  'Asia/Seoul': {
    id: 'app.Calendar.Timezone.Asia/Seoul',
    defaultMessage: 'Asia/Seoul',
  },
  'Asia/Singapore': {
    id: 'app.Calendar.Timezone.Asia/Singapore',
    defaultMessage: 'Asia/Singapore',
  },
  'Europe/Skopje': {
    id: 'app.Calendar.Timezone.Europe/Skopje',
    defaultMessage: 'Europe/Skopje',
  },
  'Europe/Sofia': {
    id: 'app.Calendar.Timezone.Europe/Sofia',
    defaultMessage: 'Europe/Sofia',
  },
  'Asia/Anadyr': {
    id: 'app.Calendar.Timezone.Asia/Anadyr',
    defaultMessage: 'Asia/Anadyr',
  },
  'Pacific/Efate': {
    id: 'app.Calendar.Timezone.Pacific/Efate',
    defaultMessage: 'Pacific/Efate',
  },
  'Pacific/Guadalcanal': {
    id: 'app.Calendar.Timezone.Pacific/Guadalcanal',
    defaultMessage: 'Pacific/Guadalcanal',
  },
  'Pacific/Kosrae': {
    id: 'app.Calendar.Timezone.Pacific/Kosrae',
    defaultMessage: 'Pacific/Kosrae',
  },
  'Pacific/Noumea': {
    id: 'app.Calendar.Timezone.Pacific/Noumea',
    defaultMessage: 'Pacific/Noumea',
  },
  'Pacific/Ponape': {
    id: 'app.Calendar.Timezone.Pacific/Ponape',
    defaultMessage: 'Pacific/Ponape',
  },
  'Europe/Stockholm': {
    id: 'app.Calendar.Timezone.Europe/Stockholm',
    defaultMessage: 'Europe/Stockholm',
  },
  'Antarctica/DumontDUrville': {
    id: 'app.Calendar.Timezone.Antarctica/DumontDUrville',
    defaultMessage: 'Antarctica/DumontDUrville',
  },
  'Asia/Sakhalin': {
    id: 'app.Calendar.Timezone.Asia/Sakhalin',
    defaultMessage: 'Asia/Sakhalin',
  },
  'Australia/Currie': {
    id: 'app.Calendar.Timezone.Australia/Currie',
    defaultMessage: 'Australia/Currie',
  },
  'Australia/Lindeman': {
    id: 'app.Calendar.Timezone.Australia/Lindeman',
    defaultMessage: 'Australia/Lindeman',
  },
  'Australia/Sydney': {
    id: 'app.Calendar.Timezone.Australia/Sydney',
    defaultMessage: 'Australia/Sydney',
  },
  'Pacific/Saipan': {
    id: 'app.Calendar.Timezone.Pacific/Saipan',
    defaultMessage: 'Pacific/Saipan',
  },
  'Pacific/Truk': {
    id: 'app.Calendar.Timezone.Pacific/Truk',
    defaultMessage: 'Pacific/Truk',
  },
  'Asia/Taipei': {
    id: 'app.Calendar.Timezone.Asia/Taipei',
    defaultMessage: 'Asia/Taipei',
  },
  'Europe/Tallinn': {
    id: 'app.Calendar.Timezone.Europe/Tallinn',
    defaultMessage: 'Europe/Tallinn',
  },
  'Asia/Tashkent': {
    id: 'app.Calendar.Timezone.Asia/Tashkent',
    defaultMessage: 'Asia/Tashkent',
  },
  'Asia/Tbilisi': {
    id: 'app.Calendar.Timezone.Asia/Tbilisi',
    defaultMessage: 'Asia/Tbilisi',
  },
  'Asia/Tehran': {
    id: 'app.Calendar.Timezone.Asia/Tehran',
    defaultMessage: 'Asia/Tehran',
  },
  'America/Tijuana': {
    id: 'app.Calendar.Timezone.America/Tijuana',
    defaultMessage: 'America/Tijuana',
  },
  'Asia/Dili': {
    id: 'app.Calendar.Timezone.Asia/Dili',
    defaultMessage: 'Asia/Dili',
  },
  'Asia/Jayapura': {
    id: 'app.Calendar.Timezone.Asia/Jayapura',
    defaultMessage: 'Asia/Jayapura',
  },
  'Asia/Pyongyang': {
    id: 'app.Calendar.Timezone.Asia/Pyongyang',
    defaultMessage: 'Asia/Pyongyang',
  },
  'Asia/Tokyo': {
    id: 'app.Calendar.Timezone.Asia/Tokyo',
    defaultMessage: 'Asia/Tokyo',
  },
  'Pacific/Palau': {
    id: 'app.Calendar.Timezone.Pacific/Palau',
    defaultMessage: 'Pacific/Palau',
  },
  'Asia/Urumqi': {
    id: 'app.Calendar.Timezone.Asia/Urumqi',
    defaultMessage: 'Asia/Urumqi',
  },
  'Europe/Vienna': {
    id: 'app.Calendar.Timezone.Europe/Vienna',
    defaultMessage: 'Europe/Vienna',
  },
  'Europe/Vilnius': {
    id: 'app.Calendar.Timezone.Europe/Vilnius',
    defaultMessage: 'Europe/Vilnius',
  },
  'Asia/Vladivostok': {
    id: 'app.Calendar.Timezone.Asia/Vladivostok',
    defaultMessage: 'Asia/Vladivostok',
  },
  'Europe/Volgograd': {
    id: 'app.Calendar.Timezone.Europe/Volgograd',
    defaultMessage: 'Europe/Volgograd',
  },
  'Europe/Warsaw': {
    id: 'app.Calendar.Timezone.Europe/Warsaw',
    defaultMessage: 'Europe/Warsaw',
  },
  'Asia/Yakutsk': {
    id: 'app.Calendar.Timezone.Asia/Yakutsk',
    defaultMessage: 'Asia/Yakutsk',
  },
  'Asia/Yerevan': {
    id: 'app.Calendar.Timezone.Asia/Yerevan',
    defaultMessage: 'Asia/Yerevan',
  },
  'Europe/Zagreb': {
    id: 'app.Calendar.Timezone.Europe/Zagreb',
    defaultMessage: 'Europe/Zagreb',
  },
  unknown: {
    id: 'app.Calendar.Timezone.unknown',
    defaultMessage: 'unknown',
  },
});
