import { Loader } from '@fountain/fountain-ui-components';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import React, { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  previewContainer: {
    width: '100%',
    height: '100%',
    marginLeft: theme.spacing(5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  previewSection: {
    height: '700px',
    width: '500px',
  },
  hideWidget: {
    display: 'none',
  },
  showWidget: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  previewWrapper: {
    height: '700px',
    width: '500px',
  },
  frame: {
    width: '100%',
    height: '100%',
    border: 'none',
  },
}));

export interface PreviewWidgetProps {
  isWidgetLoading: boolean;
  setIsWidgetLoading: React.Dispatch<React.SetStateAction<boolean>>;
  widgetUrl: string;
}

export const PreviewWidget: React.VFC<PreviewWidgetProps> = ({
  isWidgetLoading,
  setIsWidgetLoading,
  widgetUrl,
}) => {
  const styles = useStyles();

  useEffect(() => {
    window.onmessage = (event: MessageEvent) => {
      if (event.data === 'widget-config-loaded') {
        setIsWidgetLoading(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className={styles.previewContainer}>
      <div className={styles.previewSection}>
        <div
          className={classnames({
            [styles.hideWidget]: isWidgetLoading,
            [styles.showWidget]: !isWidgetLoading,
          })}
        >
          <div className={styles.previewWrapper}>
            <iframe
              title="Chatbot Widget Icon"
              src={widgetUrl}
              className={styles.frame}
            />
          </div>
        </div>
        {isWidgetLoading && <Loader block size="2rem" />}
      </div>
    </section>
  );
};
