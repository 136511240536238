/*
 *
 * FlashMessage reducer
 *
 */
import produce from 'immer';

import { ADD_MESSAGE, REMOVE_MESSAGE } from './constants';

export const initialState = {
  messages: [],
};

const flashMessageReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ADD_MESSAGE:
        draft.messages = [
          ...draft.messages,
          {
            id: action.id,
            text: action.text,
            variant: action.variant,
          },
        ];
        break;
      case REMOVE_MESSAGE:
        if (!action.id) draft.messages = draft.messages.slice(1);
        draft.messages = draft.messages.filter(m => m.id !== action.id);
        break;
      // no default
    }
  });

export default flashMessageReducer;
