const styles = theme => ({
  listItem: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: 6,
  },
  nav: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  name: {
    fontSize: theme.typography.fontSize,
    color: theme.palette.common.black,
  },
  listItemText: {
    paddingLeft: theme.spacing(1),
  },
  icon: {
    marginRight: 0,
  },
});

export default styles;
