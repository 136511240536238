import { Checkbox } from '@fountain/fountain-ui-components';
import { FormControlLabel, Typography } from '@material-ui/core';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import useStyles from './styles';

const FormikCheckbox = ({ label, ...props }) => {
  const classes = useStyles(props);
  const [field, meta] = useField(props);

  return (
    <FormControlLabel
      className={classes.formControlLabel}
      control={
        <Checkbox
          className={classes.checkbox}
          checked={props.checked}
          onChange={field.onChange}
          error={meta.error}
          {...props}
        />
      }
      label={
        <Typography
          variant="body2"
          color="textPrimary"
          className={classes.label}
        >
          {label}
        </Typography>
      }
    />
  );
};

FormikCheckbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default FormikCheckbox;
