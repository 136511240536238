import { Grid } from '@material-ui/core';
import { LicenseDataField } from 'api-clients/monolith';
import React, { useEffect, useState, VFC } from 'react';

import { SetValue } from 'containers/DataValidationModal/components/helpers';

import LicenseData from './LicenseData';

export interface LicenseDataContainerProps {
  licenseData: LicenseDataField[];
  editDisabled: boolean;
  handleKeyPress: () => void;
  setValue: SetValue;
}

export const LicenseDataContainer: VFC<LicenseDataContainerProps> = ({
  licenseData,
  editDisabled,
  handleKeyPress,
  setValue,
}) => {
  const [data, setData] = useState<LicenseDataField[]>([]);

  useEffect(() => {
    if (licenseData) {
      setData(licenseData);
    }
  }, [licenseData]);

  const handleSetValue = (newValue: LicenseDataField, idx: number) => {
    const newData = data.map((entry, index) => {
      if (index === idx) {
        return newValue;
      }

      return entry;
    });

    setValue(newData);
  };

  return (
    <Grid>
      {licenseData.map((data, index) => {
        // eslint-disable-next-line react/jsx-key
        return (
          <LicenseData
            licenseData={data}
            editDisabled={editDisabled}
            handleKeyPress={handleKeyPress}
            setValue={handleSetValue}
            index={index}
            // eslint-disable-next-line react/no-array-index-key
            key={`license-data-${index}`}
          />
        );
      })}
    </Grid>
  );
};

export default LicenseDataContainer;
