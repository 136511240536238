import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  ignoringAnswer: {
    id: 'app.containers.Chatbot.ignoringAnswer',
    defaultMessage: `Ignore this answer`,
  },
  answerIgnored: {
    id: 'app.containers.Chatbot.answerIgnored',
    defaultMessage: `This answer is Ignored`,
  },
  selectAnswerForQuestion: {
    id: 'app.containers.Chatbot.selectAnswerForQuestion',
    defaultMessage: `Select a correct answer for this question`,
  },
});
