import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  tooLarge: {
    id: 'app.containers.AIWorkflowBuilder.FileUpload.tooLarge',
    defaultMessage: 'The file is too large. Please try again.',
  },
  required: {
    id: 'app.containers.AIWorkflowBuilder.FileUpload.required',
    defaultMessage: 'Please upload a file.',
  },
  failed: {
    id: 'app.containers.AIWorkflowBuilder.FileUpload.failed',
    defaultMessage: 'The upload failed. Please try again.',
  },
  validImage: {
    id: 'app.containers.AIWorkflowBuilder.validImage.',
    defaultMessage: 'Please upload a valid file of type .txt',
  },
  uploadIcon: {
    id: 'app.containers.AIWorkflowBuilder.FileUpload.uploadIcon',
    defaultMessage: 'Upload',
  },
  inputButton: {
    id: 'app.containers.AIWorkflowBuilder.FileUpload.inputButton',
    defaultMessage: 'Upload File',
  },
  maxSize: {
    id: 'app.containers.AIWorkflowBuilder.FileUpload.maxSize',
    defaultMessage: 'Max file size:',
  },
  indicator: {
    id: 'app.containers.AIWorkflowBuilder.FileUpload.indicator',
    defaultMessage: 'Uploading',
  },
  blurry: {
    id: 'app.containers.AIWorkflowBuilder.FileUpload.blurry',
    defaultMessage:
      'This image appears blurry. Retake the photo with a steady hand in a well lit space for best results.',
  },
  remove: {
    id: 'app.containers.AIWorkflowBuilder.FileUpload.remove',
    defaultMessage: 'Delete File',
  },
  submit: {
    id: 'app.containers.AIWorkflowBuilder.FileUpload.submit',
    defaultMessage: 'Send',
  },
  document: {
    id: 'app.containers.AIWorkflowBuilder.FileUpload.document',
    defaultMessage: 'Document',
  },
});
