import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  externalCalendarIntegration: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.ExternalCalendarCard.externalCalendarIntegration`,
    defaultMessage: 'External Calendar Integration',
  },
  externalCalendarDescription: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.ExternalCalendarCard.externalCalendarDescription`,
    defaultMessage:
      "Your Apple iCloud, Google, Office 365, Exchange, and Outlook calendars can be integrated into Fountain's scheduling and sessions system. Once the calendar is properly integrated and setup per your preferences, your sessions will be available on your work calendar.",
  },
  connectCalendar: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.ExternalCalendarCard.connectCalendar`,
    defaultMessage: 'Connect Calendar',
  },
});
