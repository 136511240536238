import {
  EmptySearchState,
  EmptyState,
  ErrorState,
  LoadingState,
} from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Choose } from 'react-extras';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import JobSearchResultsList from 'containers/JobSearch/JobSearchResults/JobSearchResultsList';
import { JobSearchResultsListControls } from 'containers/JobSearch/JobSearchResults/JobSearchResultsListControls';
import messages from 'containers/JobSearch/messages';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import { fetchSearchResultInitAction } from './actions';
import reducer from './reducer';
import saga from './saga';
import {
  makeSelectLocations,
  makeSelectLocationsError,
  makeSelectLocationsLoading,
} from './selectors';
import useStyles from './styles';

// todo: move this ordering logic to backend
const getOrderedLocations = (locations, jobTitle) => {
  const head = [];
  const tail = [];
  locations.forEach(location => {
    const matchingJob = location.funnels.find(
      funnel => funnel.title === jobTitle,
    );
    if (matchingJob) {
      head.push(location);
      return;
    }

    tail.push(location);
  });

  return [...head, ...tail];
};

const JobSearchResults = ({
  fetchLocations,
  locations,
  locationsError,
  locationsLoading,
  onSelected,
  orderResultsListBy,
  searchString,
  isUrl,
  locationId,
  isDirty,
  shouldShowActiveFilter,
}) => {
  useInjectReducer({ key: 'jobSearch', reducer });
  useInjectSaga({ key: 'jobSearch', saga });

  const intl = useIntl();
  const styles = useStyles();
  const [shouldFetchActiveOnly, setShouldFetchActiveOnly] = useState(false);

  const orderedLocations = useMemo(() => {
    if (orderResultsListBy) {
      return getOrderedLocations(locations, orderResultsListBy);
    }

    return locations;
  }, [locations, orderResultsListBy]);

  useEffect(() => {
    const string = isDirty ? searchString : '';
    fetchLocations(shouldFetchActiveOnly, string, locationId);
  }, [
    fetchLocations,
    searchString,
    shouldFetchActiveOnly,
    locationId,
    isDirty,
  ]);

  const handleChangeActiveOnly = isActiveOnly => {
    setShouldFetchActiveOnly(isActiveOnly);
  };

  const handleClickReplay = () =>
    fetchLocations(shouldFetchActiveOnly, searchString);

  return (
    <Grid className={styles.jobSearchResultsContainer}>
      {shouldShowActiveFilter && (
        <Grid className={styles.jobSearchResultsListControlsContainer}>
          <JobSearchResultsListControls
            onChangeActiveOnly={handleChangeActiveOnly}
          />
        </Grid>
      )}
      <Typography className={styles.jobSearchResultsLimit} variant="caption">
        {intl.formatMessage(messages.jobSearchLimitWarning)}
      </Typography>
      <Grid className={styles.jobSearchResultsListContainer}>
        <Choose>
          <Choose.When condition={locationsLoading}>
            <LoadingState message={intl.formatMessage(messages.loading)} />
          </Choose.When>
          <Choose.When condition={!!locationsError}>
            <ErrorState onClick={handleClickReplay} />
          </Choose.When>
          <Choose.When
            condition={orderedLocations.length === 0 && !searchString}
          >
            <EmptyState message={intl.formatMessage(messages.empty)} />
          </Choose.When>
          <Choose.When condition={orderedLocations.length === 0}>
            <EmptySearchState
              message={intl.formatMessage(messages.emptySearch, {
                searchString: `'${searchString}'`,
              })}
            />
          </Choose.When>
          <Choose.When condition={orderedLocations.length > 0}>
            <JobSearchResultsList
              onSelected={onSelected}
              options={orderedLocations}
              searchString={searchString}
              isUrl={isUrl}
            />
          </Choose.When>
        </Choose>
      </Grid>
    </Grid>
  );
};

JobSearchResults.propTypes = {
  isDirty: PropTypes.bool,
  locationId: PropTypes.string,
  fetchLocations: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      external_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      location_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      address: PropTypes.string,
      brand_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      funnels: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          title: PropTypes.string.isRequired,
          job_id: PropTypes.string.isRequired,
          active: PropTypes.bool.isRequired,
          applicants_count: PropTypes.number.isRequired,
        }),
      ),
    }),
  ).isRequired,
  locationsError: PropTypes.any,
  locationsLoading: PropTypes.bool.isRequired,
  onSelected: PropTypes.func.isRequired,
  orderResultsListBy: PropTypes.string,
  searchString: PropTypes.string.isRequired,
  isUrl: PropTypes.bool,
  shouldShowActiveFilter: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  locations: makeSelectLocations(),
  locationsError: makeSelectLocationsError(),
  locationsLoading: makeSelectLocationsLoading(),
});

const mapDispatchToProps = dispatch => ({
  fetchLocations: (active, query, locationId) =>
    dispatch(fetchSearchResultInitAction(active, query, locationId)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  JobSearchResults,
);
