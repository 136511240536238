import 'react-dropzone-uploader/dist/styles.css';

import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import Dropzone, { formatBytes } from 'react-dropzone-uploader';
import { classNames } from 'react-extras';

import CustomDropzoneUploadInput from 'components/CustomDropzone/components/CustomDropzoneUploadInput';
import MobileCustomDropzoneUploadInput from 'components/CustomDropzone/components/MobileCustomDropZoneUploadInput';
import { Error } from 'components/Error';

import useStyles from './styles';

const MobileCustomDropzone = ({
  fileType,
  maxSize,
  maxFiles,
  multiple,
  error,
  isMobile,
  handleOnChange,
  fileUploadError,
}) => {
  const classes = useStyles();

  return (
    <Grid className={classes.mobileUploadContainer}>
      <Grid
        className={classNames(classes.mobileUpload, { error })}
        data-testid="CustomDropzone-MobileCustomDropZone"
      >
        <Dropzone
          styles={{
            dropzone: {
              border: 'none',
              width: '100%',
              minHeight: 'unset',
              overflow: 'unset',
            },
          }}
          onChangeStatus={handleOnChange}
          maxSize={maxSize}
          maxFiles={maxFiles}
          multiple={multiple}
          inputContent={(inputProps, dropzoneProps) => (
            <CustomDropzoneUploadInput
              isDropzoneActive={dropzoneProps.active}
              fileType={fileType}
              hasError={!!fileUploadError}
              maxSize={maxSize}
              error={error}
              isMobile={isMobile}
            />
          )}
          PreviewComponent={() => <MobileCustomDropzoneUploadInput />}
          accept={fileType}
        />
      </Grid>
      <Grid className={classes.errorContainer}>
        <Typography className={classes.maxSize}>
          {fileType !== '*' && fileType} (max. {formatBytes(maxSize)})
        </Typography>
        <Error error={error} />
      </Grid>
    </Grid>
  );
};

MobileCustomDropzone.propTypes = {
  fileType: PropTypes.string,
  maxSize: PropTypes.number,
  maxFiles: PropTypes.number,
  multiple: PropTypes.bool,
  error: PropTypes.bool,
  isMobile: PropTypes.bool,
  handleOnChange: PropTypes.func,
  fileUploadError: PropTypes.any,
};

MobileCustomDropzone.defaultProps = {
  fileType: '*',
  maxSize: 15728640, // 15 MB (binary)
  maxFiles: 1,
  multiple: false,
};

export default MobileCustomDropzone;
