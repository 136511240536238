import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import { makeSelectWhoami } from 'containers/Auth_old/selectors';
import { addMessageAction } from 'containers/FlashMessage/actions';
import { apiPost } from 'utils/axios';

export default function useCreateTestApplicant(successAction) {
  const dispatch = useDispatch();
  const currentUser = useSelector(makeSelectWhoami());

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleCreateTestApplicant = async (
    jobId,
    stageExternalId,
    targetReference,
  ) => {
    const data = {
      funnel_id: jobId,
      stage_id: stageExternalId,
      applicant: {
        name: currentUser.name,
        email: currentUser.email,
        phone_number: '00 00 00',
      },
    };

    const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/test_applicants`;
    setError('');
    setIsLoading(true);
    try {
      const res = await apiPost(url, data);
      if (res.data) {
        const location = targetReference?.location ?? null;
        const src = targetReference?.src ?? null;

        if (location !== null) {
          location.assign(res.data.redirect_url);
          dispatch(addMessageAction('Created test applicant!', 'success'));
          successAction(res.data);
        } else if (src !== null) {
          // eslint-disable-next-line no-param-reassign
          targetReference.src = res.data.redirect_url;
        }
        setError('');
      }
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  return { handleCreateTestApplicant, isLoading, error };
}
