import { makeStyles, Theme } from '@material-ui/core/styles';
import { ChatbotFeedback } from 'api-clients/monolith/models/ChatbotFeedback';

import { CORRECT, INCORRECT, PENDING } from 'containers/Chatbot/constants';

interface FeedbackCardProps {
  feedback: ChatbotFeedback;
}

const iconColor = {
  [CORRECT]: '#009E52',
  [INCORRECT]: '#E51F58',
  [PENDING]: 'white',
};

export const useStyles = makeStyles<Theme, FeedbackCardProps>(theme => ({
  container: {
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.common.gray300}`,
    boxShadow: theme.customShadows.shadow3,
    borderRadius: '5px',
    padding: theme.spacing(3, 3, 1.5, 3),
    marginBottom: theme.spacing(3),
  },
  topContainer: {
    marginBottom: theme.spacing(1),
  },
  dateContainer: {
    alignSelf: 'baseline',
    justifySelf: 'flex-end',
  },
  dividerContainer: {
    margin: theme.spacing(2, 0),
  },
  divider: {
    width: '100%',
  },
  centerButton: {
    margin: theme.spacing(0, 2),
  },
  selectedButton: {
    width: 'fit-content',
    height: '40px',
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.common.gray300}`,
    borderRadius: '5px',
    padding: theme.spacing(1.5),
    marginRight: theme.spacing(1),
  },
  selectedButtonLabel: {
    color: theme.palette.common.gray800,
    marginLeft: theme.spacing(1),
  },
  feedbackIcon: {
    alignSelf: 'center',
    color: props => iconColor[props.feedback],
  },
  icon: {
    width: '12px',
    height: '12px',
  },
  closeButton: {
    marginLeft: theme.spacing(1),
  },
  closeIcon: {
    color: theme.palette.common.gray600,
  },
  incorrectIntentFeedback: {
    width: '100%',
    display: 'none',
  },
}));
