/*
 *
 * Auth constants
 *
 */

export const WHOAMI_INIT = 'app/Auth/WHOAMI_INIT';
export const WHOAMI_SUCCESS = 'app/Auth/WHOAMI_SUCCESS';
export const WHOAMI_ERROR = 'app/Auth/WHOAMI_ERROR';
export const SET_SELECTED_BRAND = 'app/Auth/SET_SELECTED_BRAND';
export const SET_SELECTED_LOCATION = 'app/Auth/SET_SELECTED_LOCATION';
export const FETCH_ALL_BRANDS_INIT = 'app/Auth/FETCH_ALL_BRANDS_INIT';
export const FETCH_ALL_BRANDS_SUCCESS = 'app/Auth/FETCH_ALL_BRANDS_SUCCESS';
export const FETCH_ALL_BRANDS_ERROR = 'app/Auth/FETCH_ALL_BRANDS_ERROR';

export const SIGN_OUT_INIT = 'app/Auth/SIGN_OUT_INIT';
export const SIGN_OUT_SUCCESS = 'app/Auth/SIGN_OUT_SUCCESS';
export const SIGN_OUT_ERROR = 'app/Auth/SIGN_OUT_ERROR';

export const ACCOUNTS_INIT = 'app/Auth/ACCOUNTS_INIT';
export const ACCOUNTS_SUCCESS = 'app/Auth/ACCOUNTS_SUCCESS';
export const ACCOUNTS_ERROR = 'app/Auth/ACCOUNTS_ERROR';

export const USERS_INIT = 'app/Auth/USERS_INIT';
export const USERS_SUCCESS = 'app/Auth/USERS_SUCCESS';
export const USERS_ERROR = 'app/Auth/USERS_ERROR';

export const SET_SELECTED_ACCOUNT = 'app/Auth/SET_SELECTED_ACCOUNT';
export const SET_SELECTED_USER = 'app/Auth/SET_SELECTED_USER';

export const IMPERSONATE_USER_INIT = 'app/Auth/IMPERSONATE_USER_INIT';
export const IMPERSONATE_USER_SUCCESS = 'app/Auth/IMPERSONATE_USER_SUCCESS';
export const IMPERSONATE_USER_ERROR = 'app/Auth/IMPERSONATE_USER_ERROR';

export const REGISTER_MOBILE_DEVICE_INIT =
  'app/Auth/REGISTER_MOBILE_DEVICE_INIT';
export const REGISTER_MOBILE_DEVICE_SUCCESS =
  'app/Auth/REGISTER_MOBILE_DEVICE_SUCCESS';
export const REGISTER_MOBILE_DEVICE_ERROR =
  'app/Auth/REGISTER_MOBILE_DEVICE_ERROR';

export const ADMIN_ROLE = 'Admin';
export const REGULAR_ROLE = 'Regular User';

export const UPDATE_CLIENT_SETTINGS = 'app/Auth/UPDATE_CLIENT_SETTINGS';
export const UPDATE_CLIENT_SETTINGS_SUCCESS =
  'app/Auth/UPDATE_CLIENT_SETTINGS_SUCCESS';
export const UPDATE_CLIENT_SETTINGS_ERROR =
  'app/Auth/UPDATE_CLIENT_SETTINGS_ERROR';
export const UPDATE_IS_FAQ_BOT_ENABLED = 'app/Auth/UPDATE_IS_FAQ_BOT_ENABLED';
