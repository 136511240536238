import { Alert } from '@fountain/fountain-ui-components';
import { Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import messages from './messages';
import { useStyles } from './styles';

export const ErrorMessage = ({ searchString }: { searchString: string }) => {
  const styles = useStyles();
  const intl = useIntl();

  if (searchString === '')
    return (
      <>
        <Typography className={styles.cellContent}>
          {intl.formatMessage(messages.emptyTableMessage)}
        </Typography>
        <Alert fullWidth type="danger" className={styles.alertContainer}>
          <FormattedMessage {...messages.warningMessage} />
        </Alert>
      </>
    );

  return (
    <Typography className={styles.cellContent}>
      {intl.formatMessage(messages.searchEmptyTableMessage)}
    </Typography>
  );
};
