import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectBulkActionQueue = state => state.bulkActionQueue || initialState;

export const makeSelectBulkActionQueue = () =>
  createSelector(selectBulkActionQueue, substate => substate);

export default makeSelectBulkActionQueue;
