import { Checkbox } from '@fountain/fountain-ui-components';
import {
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { WorkAvailabilityBlockEnum } from './constants';
import useStyles from './styles';

const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

const WEEKEND = ['Saturday', 'Sunday'];

const BLOCKS = ['Morning', 'Afternoon', 'Evening'];

export const WorkAvailabilityInput = ({
  selectedOptions,
  onChange,
  editDisabled,
  error,
}) => {
  const classes = useStyles();

  const getAvailabilityBlockKey = (day, block) =>
    WorkAvailabilityBlockEnum[`${day.toLowerCase()}${block}`];

  const renderRowsForDays = days => (
    <>
      {/*
      TODO: Add section title and make each section collapsable

      <TableRow>
        <TableCell>{title}</TableCell>
      </TableRow>
     */}
      {days.map(day => (
        <TableRow key={day} className={classes.row}>
          <TableCell className={classes.cell}>{day}</TableCell>
          {BLOCKS.map(block => (
            <TableCell key={block} className={classes.cell}>
              <FormControlLabel
                className={classes.label}
                checked={editDisabled}
                control={
                  <Checkbox
                    value={getAvailabilityBlockKey(day, block)}
                    className={classNames(classes.checkbox, {
                      [classes.checkboxEditDisabled]: editDisabled,
                    })}
                    onChange={onChange}
                    checked={
                      selectedOptions &&
                      selectedOptions.includes(
                        getAvailabilityBlockKey(day, block),
                      )
                    }
                    error={error}
                  />
                }
              />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );

  return (
    <Table className={classes.root}>
      <TableHead>
        <TableRow>
          <TableCell />
          {BLOCKS.map(block => (
            <TableCell key={block} className={classes.cell}>
              {block}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody />
      {renderRowsForDays(WEEKDAYS, 'Weekdays')}
      {renderRowsForDays(WEEKEND, 'Weekend')}
    </Table>
  );
};

WorkAvailabilityInput.propTypes = {
  selectedOptions: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  editDisabled: PropTypes.bool,
  error: PropTypes.bool,
};

WorkAvailabilityInput.defaultProps = {
  editDisabled: false,
};

export default WorkAvailabilityInput;
