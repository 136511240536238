import { defineMessages } from 'react-intl';

const scope = 'app.containers.EditPartnerOptionData';

export const messages = defineMessages({
  modalHeader: {
    id: `${scope}.modalHeader`,
    defaultMessage: 'Edit partner data fields',
  },
  clearDialogBody: {
    id: `${scope}.clearDialogBody`,
    defaultMessage:
      'Are you sure you want to clear the information from this field?',
  },
  clearDialogTitle: {
    id: `${scope}.clearDialogTitle`,
    defaultMessage: 'Clear Information',
  },
  clearFieldSuccessMessage: {
    id: `${scope}.clearFieldSuccessMessage`,
    defaultMessage: 'Data field cleared',
  },
  clearFieldErrorMessage: {
    id: `${scope}.clearFieldErrorMessage`,
    defaultMessage: 'Unable to clear data field',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save',
  },
  resubmitFieldErrorMessage: {
    id: `${scope}.resubmitFieldErrorMessage`,
    defaultMessage: 'Unable to resubmit partner data',
  },
  resubmitFieldSuccessMessage: {
    id: `${scope}.resubmitFieldSuccessMessage`,
    defaultMessage: 'Data successfully resubmitted to partner',
  },
  updateFieldErrorMessage: {
    id: `${scope}.updateFieldErrorMessage`,
    defaultMessage: 'Unable to update data field',
  },
  updateFieldSuccessMessage: {
    id: `${scope}.updateFieldSuccessMessage`,
    defaultMessage: 'Data field updated',
  },
});
