/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import {
  Radio,
  StyledReactSelect,
  styledSelectDefaultStyles,
} from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import {
  WorkflowAccurateStage,
  WorkflowBackgroundCheckerStage,
  WorkflowSterlingStage,
} from 'api-clients/monolith';
import produce from 'immer';
import React, { useContext, useEffect, useState, VFC } from 'react';
import { useIntl } from 'react-intl';

import { StageSettingCard } from 'containers/WorkflowEditor/components/StageSettingCard';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { SettingSwitch } from '../../../SettingSwitch';
import {
  ADVANCE,
  NOTHING,
  RUN,
  RUN_AND_ADVANCE,
  RUN_AND_ADVANCE_ON_COMPLETION,
  RUN_AND_ADVANCE_ON_FINAL_STATUS,
  RUN_AND_ADVANCE_WHEN_CLEAR,
  RUN_WHEN_STATUS_NO_LONGER_PENDING,
} from './constants';
import { messages } from './messages';
import { useStyles } from './styles';

const styledSelectStyles: unknown = {
  ...styledSelectDefaultStyles,
  menu: (provided: Record<string, unknown>) => ({
    ...provided,
    width: 'auto',
  }),
};

type OptionType = { id: number; title: string };

type DataKeyTypes =
  | typeof RUN
  | typeof ADVANCE
  | typeof RUN_AND_ADVANCE
  | typeof NOTHING
  | typeof RUN_AND_ADVANCE_ON_COMPLETION
  | typeof RUN_AND_ADVANCE_ON_FINAL_STATUS
  | typeof RUN_AND_ADVANCE_WHEN_CLEAR
  | typeof RUN_WHEN_STATUS_NO_LONGER_PENDING;

export interface ActionsCardProps {
  stage:
    | WorkflowAccurateStage
    | WorkflowBackgroundCheckerStage
    | WorkflowSterlingStage;
}

export const ActionsCard: VFC<ActionsCardProps> = ({ stage }) => {
  const styles = useStyles();
  const intl = useIntl();

  const { setStage } = useContext(StageContext);

  const nonReportRunningActionTypes: string[] =
    stage.additional_info.non_report_running_action_types || [];
  const reportRunningActionTypes: string[] =
    stage.additional_info.report_running_action_types || [];

  const [moveFromCustomStageEnabled, setMoveFromCustomStageEnabled] = useState(
    Boolean(stage.additional_info.auto_advance_from_stage_id),
  );

  useEffect(() => {
    setMoveFromCustomStageEnabled(
      Boolean(stage.additional_info.auto_advance_from_stage_id),
    );
  }, [stage.additional_info]);

  const nonReportRunningTranslations: Record<
    typeof NOTHING | typeof ADVANCE | string,
    string
  > = {
    [NOTHING]: intl.formatMessage(messages.keepApplicantInThisStage),
    [ADVANCE]: intl.formatMessage(
      messages.advanceWhenApplicantSubmittedConsent,
    ),
  };

  const reportRunningTranslations: Record<
    | typeof RUN
    | typeof RUN_AND_ADVANCE
    | typeof RUN_WHEN_STATUS_NO_LONGER_PENDING
    | typeof RUN_AND_ADVANCE_ON_COMPLETION
    | typeof RUN_AND_ADVANCE_WHEN_CLEAR
    | typeof RUN_AND_ADVANCE_ON_FINAL_STATUS
    | string,
    string
  > = {
    [RUN]: intl.formatMessage(messages.keepApplicantInThisStage),
    [RUN_AND_ADVANCE]: intl.formatMessage(messages.advanceWhenCheckRun),
    [RUN_WHEN_STATUS_NO_LONGER_PENDING]: intl.formatMessage(
      messages.advanceRegardlessOfStatus,
    ),
    [RUN_AND_ADVANCE_ON_COMPLETION]: intl.formatMessage(
      messages.advanceWhenStatusClearConsiderSuspended,
    ),
    [RUN_AND_ADVANCE_WHEN_CLEAR]: intl.formatMessage(
      messages.advanceWhenClearConsider,
    ),
    [RUN_AND_ADVANCE_ON_FINAL_STATUS]: intl.formatMessage(
      messages.advanceWhenClearConsiderPostAdverse,
    ),
  };

  const customStages = stage.additional_info.custom_stages ?? [];
  const selectedCustomStage = customStages.find(
    customStage =>
      customStage.id === stage.additional_info.auto_advance_from_stage_id,
  );

  const onToggleActionChange = (key: DataKeyTypes) => {
    setStage(
      produce(stage, draftStage => {
        if (key === RUN_AND_ADVANCE_WHEN_CLEAR) {
          draftStage.extra.after_consent_action = key;
          draftStage.additional_info.auto_advance_from_stage_id = null;
        } else {
          draftStage.extra.after_consent_action = key;
        }
      }),
    );
  };

  const onChangeAction = (option: { label: string; value: DataKeyTypes }) => {
    if (stage.extra.after_consent_action === option.value) {
      return;
    }

    onToggleActionChange(option.value);
  };

  const onToggleMoveChange = () => {
    if (stage.additional_info.auto_advance_from_stage_id) {
      setStage(
        produce(stage, draftStage => {
          if (draftStage.additional_info) {
            draftStage.additional_info.auto_advance_from_stage_id = null;
          }
        }),
      );
    }

    setMoveFromCustomStageEnabled(!moveFromCustomStageEnabled);
  };

  const onChangeCustomStage = (option: OptionType) => {
    setStage(
      produce(stage, draftStage => {
        if (draftStage.additional_info) {
          draftStage.additional_info.auto_advance_from_stage_id = option.id;
        }
      }),
    );
  };

  const nonReportRunningOptions = nonReportRunningActionTypes.map(
    actionType => ({
      value: actionType,
      label: nonReportRunningTranslations[actionType],
    }),
  );

  const reportRunningOptions = reportRunningActionTypes.map(actionType => ({
    value: actionType,
    label: reportRunningTranslations[actionType],
  }));

  const selectedDoNotRunReportOption =
    nonReportRunningOptions.find(
      option => option.value === stage.extra.after_consent_action,
    ) || null;

  const selectedRunReportOption =
    reportRunningOptions.find(
      option => option.value === stage.extra.after_consent_action,
    ) || null;

  return (
    <StageSettingCard
      variant="default"
      title={intl.formatMessage(messages.actions)}
    >
      <Grid>
        <Typography
          variant="body2"
          className={styles.actionsHeader}
          color="textPrimary"
        >
          {stage.type === 'BackgroundCheckerStage' &&
          stage.additional_info.checkr_invitation_enabled
            ? intl.formatMessage(
                messages.onceApplicantConsentsRunBackgroundCheck,
              )
            : intl.formatMessage(messages.selectActionOnceApplicantConsents)}
        </Typography>
        {(stage.type !== 'BackgroundCheckerStage' ||
          !stage.additional_info.checkr_invitation_enabled) && (
          <Grid className={styles.doNotRunRadioOption}>
            <Radio
              label={intl.formatMessage(
                messages.doNotRunCheckAfterConsentSubmitted,
              )}
              checked={Boolean(selectedDoNotRunReportOption)}
              onChange={() => onToggleActionChange(NOTHING)}
            />
            {Boolean(selectedDoNotRunReportOption) && (
              <Grid className={styles.selectDropdown} item xs={8}>
                <StyledReactSelect
                  aria-label={intl.formatMessage(
                    messages.selectWhatHappensAfterConsentSubmitted,
                  )}
                  label={intl.formatMessage(messages.applicantMoveRule)}
                  options={nonReportRunningOptions}
                  value={selectedDoNotRunReportOption}
                  onChange={onChangeAction}
                  styles={styledSelectStyles}
                />
              </Grid>
            )}
          </Grid>
        )}
        <Grid>
          {(stage.type !== 'BackgroundCheckerStage' ||
            !stage.additional_info.checkr_invitation_enabled) && (
            <Radio
              label={intl.formatMessage(messages.runCheckAfterSubmitted)}
              checked={Boolean(selectedRunReportOption)}
              onChange={() => onToggleActionChange(RUN)}
            />
          )}
          {Boolean(selectedRunReportOption) && (
            <Grid
              className={
                stage.type !== 'BackgroundCheckerStage' ||
                !stage.additional_info.checkr_invitation_enabled
                  ? styles.selectDropdown
                  : undefined
              }
              item
              xs={8}
            >
              <StyledReactSelect
                aria-label={intl.formatMessage(
                  messages.selectWhatHappensAfterConsentSubmitted,
                )}
                label={intl.formatMessage(messages.applicantMoveRule)}
                options={reportRunningOptions}
                value={selectedRunReportOption}
                onChange={onChangeAction}
                styles={styledSelectStyles}
              />
            </Grid>
          )}
        </Grid>
        {stage.extra.after_consent_action !== RUN_AND_ADVANCE_WHEN_CLEAR &&
          (stage.type !== 'BackgroundCheckerStage' ||
            !stage.additional_info.checkr_invitation_enabled) && (
            <Grid className={styles.customStagesDropdown}>
              <SettingSwitch
                key={`${stage.type}`}
                dataKey="auto_advance_from_stage_id"
                checked={moveFromCustomStageEnabled}
                onChange={onToggleMoveChange}
                label={intl.formatMessage(
                  messages.moveFromCustomStageIfClearOrConsider,
                )}
                fieldComponent={
                  moveFromCustomStageEnabled && (
                    <Grid className={styles.packageSelect} item xs={8}>
                      <StyledReactSelect
                        aria-label={intl.formatMessage(
                          messages.selectCustomStage,
                        )}
                        label={intl.formatMessage(messages.customStage)}
                        getOptionLabel={(option: OptionType) => option.title}
                        getOptionValue={(option: OptionType) => option.id}
                        options={stage.additional_info.custom_stages}
                        value={selectedCustomStage ?? null}
                        onChange={onChangeCustomStage}
                      />
                    </Grid>
                  )
                }
              />
            </Grid>
          )}
      </Grid>
    </StageSettingCard>
  );
};
