import { Dropdown } from '@fountain/fountain-ui-components';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

import FormikInput from 'components/FormikInput';
import { FIELD_TYPES } from 'utils/constants';

import { CONSUMER_FIELDS } from './constants';

function ConsumerFields({ values, setFieldValue, errors }) {
  return (
    <Box mt={3}>
      <Box mb={3}>
        <Typography variant="h2">Consumer Information</Typography>
      </Box>
      <Grid container spacing={3}>
        {CONSUMER_FIELDS.map(field => {
          switch (field.type) {
            case FIELD_TYPES.INPUT: {
              return (
                <Grid item xs={12} sm={6} key={field.name}>
                  <FormikInput
                    name={`consumer.${field.name}`}
                    label={field.label}
                    value={values.consumer[field.name]}
                    error={errors.consumer && errors.consumer[field.name]}
                  />
                </Grid>
              );
            }
            case FIELD_TYPES.DROPDOWN: {
              return (
                <Grid item xs={12} sm={6} key={field.name}>
                  <Dropdown
                    isSearchable
                    label={field.label}
                    options={field.options}
                    value={values.consumer[field.name]}
                    onChange={e => setFieldValue(`consumer.${field.name}`, e)}
                    error={errors.consumer && errors.consumer[field.name]}
                  />
                </Grid>
              );
            }
            default:
              return null;
          }
        })}
      </Grid>
    </Box>
  );
}

ConsumerFields.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
};

export default ConsumerFields;
