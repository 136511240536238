import { FountainLocation } from '@fountain/types/base';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Choose } from 'react-extras';
import { useIntl } from 'react-intl';

import JobSearchInput from 'containers/JobSearch/JobSearchInput';
import JobSearchSelect from 'containers/JobSearch/JobSearchSelect';
import { Funnel } from 'containers/Messenger/types';

import messages from './messages';

export interface JobSearchProps {
  label?: ReactNode;
  locationId?: string;
  placeholder?: string;
  clearInputOnClick?: boolean;
  error?: string;
  disabled?: boolean;
  focus?: boolean;
  onSelected?: (location: FountainLocation, funnel: Funnel) => void;
  paperProps?: {
    maxHeight: number;
    minHeight: number;
    zIndex: number;
  };
  value?: string;
  variant?: 'search' | 'select';
  isUrl?: boolean;
  shouldShowActiveFilter?: boolean;
  noLabel?: boolean;
}

// todo-accessibility: handle keyboard navigation of results list
export const JobSearch: FC<JobSearchProps> = ({
  onSelected = () => {},
  paperProps = {
    maxHeight: 360,
    minHeight: 254,
  },
  focus = false,
  value = '',
  variant = 'search',
  isUrl,
  label,
  disabled,
  locationId,
  noLabel,
  placeholder,
  clearInputOnClick,
  error,
  shouldShowActiveFilter = true,
}) => {
  const [searchString, setSearchString] = useState(value);
  const intl = useIntl();
  useEffect(() => {
    setSearchString(value);
  }, [value]);

  const handleClear = () => {
    setSearchString('');
  };

  useEffect(() => {
    if (locationId && !value) {
      handleClear();
    }
  }, [locationId, value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
  };

  const handleSelected = (location: FountainLocation, funnel: Funnel) => {
    setSearchString(funnel.title);
    onSelected(location, funnel);
  };

  const handleReset = () => {
    if (value !== searchString) {
      setSearchString(value);
    }
  };

  const sharedProps = {
    onChange: handleChange,
    onClear: handleClear,
    onSelected: handleSelected,
    focus,
    value: searchString,
    error,
    shouldShowActiveFilter,
  };

  const inputProps = {
    ...sharedProps,
    isDirty: value !== searchString,
    placeholder: placeholder ?? intl.formatMessage(messages.searchPlaceholder),
  };

  const selectProps = {
    ...sharedProps,
    disabled,
    locationId,
    label: noLabel ? null : label ?? intl.formatMessage(messages.selectLabel),
    paperProps,
    isUrl,
    isDirty: value !== searchString,
    handleReset,
    placeholder: placeholder ?? intl.formatMessage(messages.selectPlaceholder),
    ...(clearInputOnClick !== null ? { clearInputOnClick } : {}),
  };

  return (
    <Choose>
      <Choose.When condition={variant === 'search'}>
        <JobSearchInput {...inputProps} />
      </Choose.When>
      <Choose.When condition={variant === 'select'}>
        {/** @ts-expect-error this will resolve when typing `JobSearchSelect */}
        <JobSearchSelect {...selectProps} />
      </Choose.When>
    </Choose>
  );
};

export default JobSearch;
