import { theme as muiTheme } from '@fountain/fountain-ui-components';
import PropTypes from 'prop-types';
import React from 'react';

const SVG = ({ color, size }) => {
  const scale = n => Math.floor((size * n) / 10);
  const height = scale(8);
  return (
    <svg
      width={size}
      height={height}
      viewBox={`0 0 ${height} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={`M${scale(2)} ${scale(4)}L${scale(4)} ${scale(6)}L${scale(
          8,
        )} ${scale(2)}`}
        stroke={color}
        strokeWidth={scale(3) / 2}
        strokeLinecap="square"
      />
    </svg>
  );
};

SVG.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

SVG.defaultProps = {
  color: muiTheme.palette.common.gray500,
  size: 10,
};

export default SVG;
