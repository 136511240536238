import produce from 'immer';

import {
  FETCH_SEARCH_RESULT_ERROR,
  FETCH_SEARCH_RESULT_INIT,
  FETCH_SEARCH_RESULT_SUCCESS,
} from './constants';

export const initialState = {
  locations: {
    data: [],
    loading: false,
    error: null,
  },
};

const jobSearchReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case FETCH_SEARCH_RESULT_INIT:
        draft.locations.loading = true;
        break;
      case FETCH_SEARCH_RESULT_SUCCESS:
        draft.locations.data = action.locations;
        draft.locations.loading = false;
        draft.locations.error = null;
        break;
      case FETCH_SEARCH_RESULT_ERROR:
        draft.locations.data = [];
        draft.locations.loading = false;
        draft.locations.error = action.error;
        break;
      // no default
    }
  });

export default jobSearchReducer;
