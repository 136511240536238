import { VideoRecordingStageQuestion } from 'api-clients/monolith';
import { v4 as uuid } from 'uuid';

export const DEFAULT_DURATION = 10;

export const generateDefaultQuestion: () => Omit<
  VideoRecordingStageQuestion,
  'id'
> & {
  id: string;
} = () => ({
  id: uuid(),
  body: '',
  max_length: DEFAULT_DURATION,
});
