import {
  Button,
  CustomTooltip,
  IconButton,
} from '@fountain/fountain-ui-components';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { Alert } from '@material-ui/lab';
import {
  CareerSiteParsingAbility,
  ChatbotTrainingStatus,
  ChatbotWidgetSettings,
} from 'api-clients/monolith';
import React, { VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Description } from '../Components/Description';
import { InputSetting } from '../Components/InputSetting';
import { SectionTitle } from '../Components/SectionTitle';
import { SettingTitle } from '../Components/SettingTitle';
import { HandleSettingChange, SelectedBrand } from '../types';
import { CareerSiteTrainingStatus } from './CareerSiteTrainingStatus';
import { CheckAutoScrape } from './CheckAutoScrape';
import { messages } from './messages';

const HELPER_MESSAGE = 'Needs a custom parser - please reach out to Gig Team.';

const useStyles = makeStyles(theme => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(3),
  },
  box2: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    alignItems: 'right',
    marginTop: theme.spacing(3),
  },
  title: {
    marginTop: theme.spacing(0),
  },
  minorTitle: {
    marginTop: theme.spacing(2),
  },
  majorTitle: {
    marginTop: theme.spacing(4),
  },
  input: {
    marginTop: theme.spacing(2),
  },
  saveButton: {
    borderRadius: '5px',
    width: '182px',
    padding: theme.spacing(1, 2),
    '&:disabled': {
      opacity: 'unset',
    },
  },
  disabledCareerSiteSetting: {
    pointerEvents: 'none',
    opacity: '0.4',
    padding: '10px',
    paddingTop: 'unset',
  },
  editButton: {
    right: '12px',
  },
  'MuiButton-endIcon': {
    marginLeft: 'unset',
    marginRight: 'unset',
  },
  alertMessage: {
    marginTop: theme.spacing(3),
  },
}));

export interface FaqSettingsProps {
  settings: ChatbotWidgetSettings;
  handleSettingChange: HandleSettingChange;
  selectedBrand: SelectedBrand;
  careerSiteParsingAbility: CareerSiteParsingAbility;
  setCareerSiteParsingAbility: React.Dispatch<
    React.SetStateAction<CareerSiteParsingAbility>
  >;
  careerSiteTrainingStatus: ChatbotTrainingStatus;
  setCareerSiteTrainingStatus: React.Dispatch<
    React.SetStateAction<ChatbotTrainingStatus>
  >;
  disableCareerSiteSetting: boolean;
  setDisableCareerSiteSetting: (flag: boolean) => void;
}

export const CareerSiteSettings: VFC<FaqSettingsProps> = ({
  settings,
  handleSettingChange,
  selectedBrand,
  careerSiteParsingAbility,
  setCareerSiteParsingAbility,
  careerSiteTrainingStatus,
  setCareerSiteTrainingStatus,
  disableCareerSiteSetting,
  setDisableCareerSiteSetting,
}) => {
  const styles = useStyles();
  const intl = useIntl();

  return (
    <section className={styles.section}>
      <div className={styles.box}>
        <SectionTitle
          value={messages.careerSiteSettings}
          className={styles.title}
        />
        <CareerSiteTrainingStatus
          selectedBrand={selectedBrand}
          careerSiteTrainingStatus={careerSiteTrainingStatus}
          setCareerSiteTrainingStatus={setCareerSiteTrainingStatus}
        />
        {disableCareerSiteSetting && (
          <CustomTooltip
            title={intl.formatMessage(messages.editCareerSiteSettings)}
            dense
            placement="top"
          >
            <IconButton
              aria-label="edit"
              className={styles.editButton}
              onClick={() => {
                setDisableCareerSiteSetting(false);
              }}
            >
              <EditIcon />
            </IconButton>
          </CustomTooltip>
        )}
      </div>
      <div
        className={
          disableCareerSiteSetting ? styles.disabledCareerSiteSetting : ''
        }
      >
        <SettingTitle
          value={messages.careerSiteNumberOfJobs}
          className={styles.majorTitle}
        />
        <Description value={messages.careerSiteNumberOfJobsDesc} />
        <InputSetting
          settingKey="no_of_jobs_for_career_site"
          settingValue={settings.no_of_jobs_for_career_site}
          onChange={handleSettingChange}
          errorMessage={intl.formatMessage(messages.noOfJobsError)}
          pattern="[0-9]{1,10}"
        />

        <SettingTitle
          value={messages.jobsTrainingSourceTitle}
          className={styles.majorTitle}
        />
        <Description value={messages.chatbotPurposeDesc} />
        <InputSetting
          settingKey="career_site_url"
          settingValue={settings.career_site_url}
          onChange={handleSettingChange}
          errorMessage={intl.formatMessage(messages.trainingSourceError)}
          placeholder="https://www.jobs.company.com"
          pattern="https?://.+"
        />
        {careerSiteParsingAbility === 'NO' && (
          <Alert severity="info" className={styles.alertMessage}>
            {HELPER_MESSAGE}
          </Alert>
        )}
        <div className={styles.box}>
          <CheckAutoScrape
            selectedBrand={selectedBrand}
            careerSiteScrapStatus={careerSiteParsingAbility}
            setCareerSiteScrapStatus={setCareerSiteParsingAbility}
            careerSiteUrl={settings.career_site_url ?? ''}
          />
        </div>
        <div className={styles.box2}>
          <Button
            type="primary"
            submit
            className={styles.saveButton}
            disabled={careerSiteParsingAbility !== 'YES'}
          >
            <FormattedMessage {...messages.saveAndScrape} />
          </Button>
        </div>
      </div>
    </section>
  );
};
