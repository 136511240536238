import { Correct, Incorrect } from '@fountain/fountain-ui-components';
import { Divider, Grid, IconButton, Typography } from '@material-ui/core';
import {
  BotType,
  CancelablePromise,
  ChatbotFeedback,
  ChatbotFeedbackData,
  ChatbotService,
  ChatbotUpdateFeedbackParams,
  FaqbotUpdateFeedbackParams,
  IntentsWithBotReply,
  IntentsWithBotReplyResponse,
} from 'api-clients/monolith';
import { ChatbotModelMode } from 'api-clients/monolith/models/ChatbotModelMode';
import classNames from 'classnames';
import React, { useEffect, useState, VFC } from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import {
  CORRECT,
  FAQ_BOT,
  INCORRECT,
  PENDING,
} from 'containers/Chatbot/constants';
import { addMessageAction } from 'containers/FlashMessage/actions';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';

import { EditIcon } from './EditIcon';
import { FeedbackButton } from './FeedbackButton';
import { IncorrectIntentFeedback } from './IncorrectIntentFeedback';
import { BottomLabels, TopLabels } from './Labels';
import { MessageBubble } from './MessageBubble';
import { messages } from './messages';
import { useStyles } from './styles';

export interface FeedbackCardProps {
  applicantMessage: string;
  botMessage: string | null;
  botType: BotType;
  intent: string | null;
  interpretation: string | null;
  feedback: ChatbotFeedback;
  feedbackData?: ChatbotFeedbackData;
  logId: string;
  createdAt: Date;
  modelName?: string;
  modelMode?: ChatbotModelMode;
  score?: number;
  confidenceThreshold?: number;
  question?: string;
}

export const FeedbackCard: VFC<FeedbackCardProps> = ({
  applicantMessage,
  botMessage,
  botType,
  intent,
  interpretation,
  feedback,
  feedbackData,
  logId,
  createdAt,
  modelName,
  modelMode,
  score,
  confidenceThreshold,
  question,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const iconButtons = {
    [CORRECT]: { icon: Correct, type: CORRECT, label: 'Correct' },
    [INCORRECT]: { icon: Incorrect, type: INCORRECT, label: 'Incorrect' },
  };

  const [currentStatus, setCurrentStatus] = useState(feedback);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [alertMessage, showAlertMessage] = useState(true);
  const [selectedIntent, setSelectedIntent] = useState<IntentsWithBotReply>();
  const [newIntent, setNewIntent] = useState(feedbackData?.new_intent);
  const [ignoreIntent, setIgnoreIntent] = useState(feedbackData?.ignore_intent);
  const [modelIntentWithBotReply, setmodelIntentWithBotReply] =
    useState<Record<string, IntentsWithBotReply[]>>();

  const [intentWithBotReply, setIntentWithBotReply] =
    useState<IntentsWithBotReply[]>();

  const styles = useStyles({ feedback: currentStatus });

  const { mutation: updateFeedback } = useApiServiceMutation<
    void,
    (
      logId: string,
      requestBody: ChatbotUpdateFeedbackParams,
    ) => CancelablePromise<void>
    // eslint-disable-next-line @typescript-eslint/unbound-method
  >(ChatbotService.patchInternalApiChatbotChatbotLogs, {
    onSuccess: () => {
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.updateFeedbackSuccess),
          'success',
        ),
      );
    },
    onError: () => {
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.updateFeedbackError),
          'error',
          false,
        ),
      );
    },
  });

  const { mutation: updateIntent } = useApiServiceMutation<
    void,
    (
      logId: string,
      requestBody: FaqbotUpdateFeedbackParams,
    ) => CancelablePromise<void>
    // eslint-disable-next-line @typescript-eslint/unbound-method
  >(ChatbotService.patchInternalApiChatbotAutomatedResponsesUpdateIntent, {
    onSuccess: () => {
      if (alertMessage) {
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.updateFeedbackSuccess),
            'success',
          ),
        );
      }
    },
    onError: () => {
      if (alertMessage) {
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.updateFeedbackError),
            'error',
            false,
          ),
        );
      }
    },
  });

  const { mutation: getModelIntentWithBotReply } = useApiServiceMutation<
    IntentsWithBotReplyResponse,
    (modelName: string) => CancelablePromise<IntentsWithBotReplyResponse>
  >(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    ChatbotService.getInternalApiChatbotAutomatedResponsesGetIntentsWithBotReply,
    {
      onSuccess: result => {
        if (modelName) {
          setmodelIntentWithBotReply({
            ...modelIntentWithBotReply,
            [modelName]: result.intent_with_bot_reply,
          });
          setIntentWithBotReply(result.intent_with_bot_reply);
        }
      },
    },
  );

  const handleFeedbackButtonClick = (status: ChatbotFeedback) => {
    updateFeedback(logId, {
      chatbot_log: {
        feedback: status,
      },
    });
    setCurrentStatus(status);
    setIsEditEnabled(false);
    if (status === CORRECT) {
      showAlertMessage(false);
      updateIntent(logId, {
        faqbot_log: {
          new_intent: '',
          ignore_intent: false,
        },
      });
      setSelectedIntent(undefined);
      setNewIntent(undefined);
      setIgnoreIntent(false);
    } else if (status === INCORRECT && modelName) {
      if (modelIntentWithBotReply && modelName in modelIntentWithBotReply) {
        setIntentWithBotReply(modelIntentWithBotReply[modelName]);
      } else {
        getModelIntentWithBotReply(modelName);
      }
    }
  };

  useEffect(() => {
    if (
      currentStatus === INCORRECT &&
      !feedbackData?.ignore_intent &&
      modelName
    ) {
      getModelIntentWithBotReply(modelName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditFeedbackClick = () => {
    setIsEditEnabled(true);
  };

  const handleCloseEditClick = () => {
    setIsEditEnabled(false);
  };

  return (
    <Grid container className={styles.container}>
      <Grid
        item
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        wrap="nowrap"
        spacing={2}
        className={styles.topContainer}
      >
        <TopLabels
          botType={botType}
          modelName={modelName}
          modelMode={modelMode}
        />
        <Grid item className={styles.dateContainer}>
          <Typography variant="body2">
            <FormattedDate dateStyle="short" value={createdAt} />
          </Typography>
        </Grid>
      </Grid>
      <MessageBubble
        applicantMessage={applicantMessage}
        botMessage={botMessage ?? ''}
        botType={botType}
      />
      <Grid item container className={styles.dividerContainer}>
        <Divider className={styles.divider} />
      </Grid>
      <Grid
        item
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        wrap="nowrap"
      >
        <BottomLabels
          intent={intent}
          interpretation={interpretation}
          score={score}
          confidenceThreshold={confidenceThreshold}
          question={question}
        />
        <Grid item>
          {(currentStatus === PENDING || isEditEnabled) && (
            <>
              {Object.values(iconButtons).map(iconButton => (
                <FeedbackButton
                  key={iconButton.type}
                  type={iconButton.type as ChatbotFeedback}
                  Icon={iconButton.icon}
                  label={iconButton.label}
                  handleFeedbackButtonClick={handleFeedbackButtonClick}
                />
              ))}
              {isEditEnabled && (
                <IconButton
                  onClick={handleCloseEditClick}
                  className={styles.closeButton}
                >
                  <Incorrect
                    className={classNames(styles.icon, styles.closeIcon)}
                  />
                </IconButton>
              )}
            </>
          )}
          {currentStatus !== PENDING && !isEditEnabled && (
            <Grid container direction="row" alignItems="center">
              <Grid
                item
                container
                direction="row"
                className={styles.selectedButton}
              >
                {currentStatus === CORRECT && (
                  <Correct
                    className={classNames(styles.icon, styles.feedbackIcon)}
                  />
                )}
                {currentStatus === INCORRECT && (
                  <Incorrect
                    className={classNames(styles.icon, styles.feedbackIcon)}
                  />
                )}
                <Typography
                  variant="body2"
                  className={styles.selectedButtonLabel}
                >
                  {iconButtons[currentStatus].label}
                </Typography>
              </Grid>
              <IconButton onClick={handleEditFeedbackClick}>
                <EditIcon
                  className={classNames(styles.icon, styles.editIcon)}
                />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      {currentStatus === INCORRECT && botType === FAQ_BOT && (
        /* Setting this to display:none as we don't see a valid usecase for this now. But this may be required later */
        <div className={styles.incorrectIntentFeedback}>
          <Grid item container className={styles.dividerContainer}>
            <Divider className={styles.divider} />
          </Grid>
          <IncorrectIntentFeedback
            logId={logId}
            newIntent={newIntent}
            ignoreIntent={ignoreIntent}
            setIgnoreIntent={setIgnoreIntent}
            currentStatus={currentStatus}
            updateIntent={updateIntent}
            intentWithBotReply={intentWithBotReply}
            selectedIntent={selectedIntent}
            setSelectedIntent={setSelectedIntent}
          />
        </div>
      )}
    </Grid>
  );
};
