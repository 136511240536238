/* eslint-disable camelcase */
import {
  Alert,
  Button,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Radio,
  StyledReactSelect,
} from '@fountain/fountain-ui-components';
import { FountainLocation } from '@fountain/types/base';
import { Grid } from '@material-ui/core';
import {
  CancelablePromise,
  SidebarStage,
  StageCloneParams,
  StageType,
  WorkflowEditorService,
  WorkflowStageDetail,
  WorkflowStageUpdates,
} from 'api-clients/monolith';
import { useForm, useSimpleToggle } from 'hooks';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ConfirmationModal } from 'components/ConfirmationModal';
import { Error } from 'components/Error';
import { addMessageAction } from 'containers/FlashMessage/actions';
import { JobSearch } from 'containers/JobSearch';
import { Funnel } from 'containers/Messenger/types';
import { SelectOption } from 'containers/WorkflowEditor/components/StageColumnHeader/useGetStagePlacementOptions';
import { useGetStageTypeOptions } from 'containers/WorkflowEditor/components/StageColumnHeader/useGetStageTypeOptions';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';
import { useAvailableStages } from 'containers/WorkflowEditor/hooks/useAvailableStages';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';

import { messages } from './messages';
import { useStyles } from './styles';

import type { SidebarStageResponse } from 'api-clients/monolith/models/SidebarStageResponse';

export interface AddSubstageModalProps {
  onClose: () => void;
  stageId: WorkflowStageDetail['id'];
}

interface FormValues {
  title?: string;
  opening?: string;
  type?: StageType;
  opening_slug?: string;
  source_stage_external_id?: string;
  substageSubmitType: 'new' | 'clone';
}

export const AddSubstageModal: FC<AddSubstageModalProps> = ({
  onClose,
  stageId,
}) => {
  const intl = useIntl();
  const styles = useStyles();
  const dispatch = useDispatch();

  const {
    onSaveChanges,
    refetchStage,
    isDirty,
    updateStageResult,
    resetUpdateStageResult,
  } = useContext(StageContext);

  const { funnelSlug } = useParams<{ funnelSlug: string }>();

  const {
    showContent: isConfirmationModalOpen,
    on: openConfirm,
    off: closeConfirm,
  } = useSimpleToggle();
  const [isSavingStageChanges, setIsSavingStageChanges] = useState(false);

  const {
    result: createStageResult,
    reset: resetCreateStageResult,
    mutation: createStage,
  } = useApiServiceMutation<
    WorkflowStageDetail,
    (
      funnelSlug: string,
      requestBody?: WorkflowStageUpdates,
    ) => CancelablePromise<WorkflowStageDetail>,
    { errors: Record<string, Array<string>> }
  >(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    WorkflowEditorService.postInternalApiWorkflowEditorFunnelsStages,
    {
      onSuccess: () => {
        onClose();
        refetchStage();
        // reset updateStageResult because we are working with a clean slate
        resetUpdateStageResult();
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.substageSuccess),
            'success',
          ),
        );
      },
    },
  );

  const {
    result: cloneStageResult,
    reset: resetCloneStageResult,
    mutation: cloneStage,
  } = useApiServiceMutation<
    WorkflowStageDetail,
    (
      funnelSlug: string,
      requestBody?: StageCloneParams,
    ) => CancelablePromise<WorkflowStageDetail>,
    { errors: Record<string, Array<string>> }
  >(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    WorkflowEditorService.postInternalApiWorkflowEditorFunnelsCloneStages,
    {
      onSuccess: () => {
        onClose();
        refetchStage();
        // reset updateStageResult because we are working with a clean slate
        resetUpdateStageResult();
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.substageSuccess),
            'success',
          ),
        );
      },
    },
  );

  const { result: fetchFunnelStagesResult, mutation: fetchFunnelStages } =
    useApiServiceMutation<
      SidebarStageResponse,
      (funnelSlug: string) => CancelablePromise<SidebarStageResponse>
    >(
      // eslint-disable-next-line @typescript-eslint/unbound-method
      WorkflowEditorService.getInternalApiWorkflowEditorFunnelsStages,
    );

  const validate = (values: FormValues) => {
    const errors: Partial<Record<keyof FormValues, string>> = {};

    if (values.substageSubmitType === 'new') {
      if (!values.type) {
        errors.type = intl.formatMessage(messages.stageTypeError);
      }
    }

    if (values.substageSubmitType === 'clone') {
      if (!values.opening) {
        errors.opening = intl.formatMessage(messages.selectOpeningError);
      }

      if (!values.source_stage_external_id) {
        errors.source_stage_external_id = intl.formatMessage(
          messages.stageToDuplicateError,
        );
      }
    }

    if (!values.title) {
      errors.title = intl.formatMessage(messages.substageNameError);
    }

    return errors;
  };

  const onConfirmSubmit = useCallback(() => {
    onSaveChanges();
    setIsSavingStageChanges(true);
  }, [onSaveChanges, setIsSavingStageChanges]);

  const onFormSubit = useCallback(
    (formValues: FormValues) => {
      if (isDirty && !isConfirmationModalOpen) {
        openConfirm();
        return;
      }

      const { title, type, source_stage_external_id } = formValues;

      if (formValues.substageSubmitType === 'new') {
        createStage(funnelSlug, {
          workflow_stage: {
            stage: {
              type,
              title,
              parent_stage_id: stageId,
            },
          },
        });
      }

      if (
        formValues.substageSubmitType === 'clone' &&
        source_stage_external_id
      ) {
        cloneStage(funnelSlug, {
          workflow_stage: {
            source_stage_external_id,
            stage: {
              title,
              parent_stage_id: stageId,
            },
          },
        });
      }
    },
    [
      isDirty,
      isConfirmationModalOpen,
      funnelSlug,
      cloneStage,
      createStage,
      openConfirm,
      stageId,
    ],
  );

  const { values, handleChange, handleSubmit, errors, resetForm } =
    useForm<FormValues>(onFormSubit, validate, { substageSubmitType: 'new' });

  const { result: availableStagesResult } = useAvailableStages({
    funnelSlug,
    subStageTypes: true,
  });

  const stageTypeOptions = useGetStageTypeOptions({
    stageTypes:
      availableStagesResult.status === 'ready'
        ? availableStagesResult.data.stage_types
        : [],
  });

  useEffect(() => {
    if (values.opening_slug) {
      fetchFunnelStages(values.opening_slug);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.opening_slug]);

  useEffect(() => {
    if (!isSavingStageChanges || updateStageResult.isLoading) return;

    if (updateStageResult.isError) {
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.substageNotSubmitted),
          'warning',
        ),
      );
      setIsSavingStageChanges(false);
      closeConfirm();
      onClose();
      return;
    }

    setIsSavingStageChanges(false);
    closeConfirm();
    onFormSubit(values);
  }, [
    isSavingStageChanges,
    setIsSavingStageChanges,
    closeConfirm,
    onClose,
    updateStageResult,
    resetUpdateStageResult,
    onFormSubit,
    values,
    dispatch,
    intl,
  ]);

  const selectedFunnelStages =
    (fetchFunnelStagesResult.status === 'ready' &&
      fetchFunnelStagesResult.data.stages.filter(stage =>
        stageTypeOptions.map(type => type.value).includes(stage.type),
      )) ||
    [];

  const createStageResultErrors =
    createStageResult.status === 'error' && createStageResult?.error?.errors;
  const cloneStageResultErrors =
    cloneStageResult.status === 'error' && cloneStageResult?.error?.errors;

  return (
    <Modal
      data-testid="add-substage-modal"
      ariaLabelledBy="add-substage-modal-header"
      open
      onClose={onClose}
      fullScreenOnMobile
      maxWidth={480}
    >
      <form onSubmit={handleSubmit}>
        <ModalHeader
          onClose={onClose}
          ariaLabelledBy="add-substage-modal-header"
          showIcon={false}
        >
          <FormattedMessage {...messages.addSubstage} />
        </ModalHeader>
        <ModalContent>
          {createStageResultErrors && (
            <Alert fullWidth type="danger" className={styles.errorsAlert}>
              <FormattedMessage
                {...messages.substageError}
                values={{ error: createStageResultErrors[0] }}
              />
            </Alert>
          )}
          {cloneStageResultErrors && (
            <Alert fullWidth type="danger" className={styles.errorsAlert}>
              <FormattedMessage
                {...messages.substageError}
                values={{ error: cloneStageResultErrors[0] }}
              />
            </Alert>
          )}
          <div className={styles.toggleContainer}>
            <div className={styles.toggle}>
              <Radio
                label={intl.formatMessage(messages.createNewStage)}
                checked={values.substageSubmitType === 'new'}
                disabled={
                  cloneStageResult.isLoading || createStageResult.isLoading
                }
                onChange={() => {
                  resetCloneStageResult(); // reset cloneStageResult so errors don't persist for wrong form
                  resetForm({ substageSubmitType: 'new', title: '' });
                }}
              />
            </div>
            <div className={styles.toggle}>
              <Radio
                label={intl.formatMessage(messages.cloneExistingStage)}
                checked={values.substageSubmitType === 'clone'}
                disabled={
                  cloneStageResult.isLoading || createStageResult.isLoading
                }
                onChange={() => {
                  resetCreateStageResult(); // reset createStageResult so errors don't persist for wrong form
                  resetForm({ substageSubmitType: 'clone', title: '' });
                }}
              />
            </div>
          </div>
          <Grid container direction="column" spacing={3}>
            {values.substageSubmitType === 'new' && (
              // TODO: Associated cloneStageResultErrors to specific inputs (requires work on BE/monolith as well)
              <Grid item>
                <StyledReactSelect
                  aria-label={intl.formatMessage(messages.stageType)}
                  label={intl.formatMessage(messages.stageType)}
                  options={stageTypeOptions}
                  value={
                    stageTypeOptions.find(
                      option => option.value === values.type,
                    ) ?? null
                  }
                  onChange={(option: SelectOption) => {
                    handleChange({
                      type: option.value as StageType,
                    });
                  }}
                  error={!values.type && Boolean(errors.type)}
                  isDisabled={createStageResult.isLoading}
                  required
                />
                <Error align="right" error={!values.type && errors.type} />
              </Grid>
            )}
            {values.substageSubmitType === 'clone' && (
              // TODO: Associated cloneStageResultErrors to specific inputs (requires work on BE/monolith as well)
              <>
                <Grid item>
                  <JobSearch
                    variant="select"
                    aria-label={intl.formatMessage(messages.selectOpening)}
                    label={
                      <>
                        <FormattedMessage {...messages.selectOpening} />{' '}
                        <span className={styles.requiredAsterix}>*</span>
                      </>
                    }
                    value={values.opening}
                    onSelected={(
                      location: FountainLocation,
                      funnel: Funnel,
                    ) => {
                      handleChange({
                        opening: funnel.title,
                        opening_slug: funnel.slug,
                        source_stage_external_id: undefined,
                      });
                    }}
                    error={!values.opening ? errors.opening : undefined}
                    isUrl={false}
                    disabled={cloneStageResult.isLoading}
                  />
                </Grid>
                <Grid item>
                  <StyledReactSelect
                    aria-label={intl.formatMessage(messages.stageToDuplicate)}
                    label={intl.formatMessage(messages.stageToDuplicate)}
                    options={selectedFunnelStages}
                    getOptionLabel={(option: SidebarStage) => option.title}
                    getOptionValue={(option: SidebarStage) =>
                      option.external_id
                    }
                    value={
                      selectedFunnelStages.find(
                        option =>
                          option.external_id ===
                          values.source_stage_external_id,
                      ) ?? null
                    }
                    onChange={(option: SidebarStage) => {
                      handleChange({
                        source_stage_external_id: option.external_id,
                        title: option.title,
                      });
                    }}
                    error={
                      !values.source_stage_external_id &&
                      Boolean(errors.source_stage_external_id)
                    }
                    isDisabled={cloneStageResult.isLoading}
                    required
                  />
                  <Error
                    align="right"
                    error={
                      !values.source_stage_external_id &&
                      errors.source_stage_external_id
                    }
                  />
                </Grid>
              </>
            )}
            <Grid item>
              <Input
                aria-label={intl.formatMessage(messages.substageName)}
                label={intl.formatMessage(messages.substageName)}
                className={styles.input}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                  handleChange({ title: event.target.value })
                }
                value={values.title}
                error={!values.title && Boolean(errors.title)}
                disabled={
                  cloneStageResult.isLoading || createStageResult.isLoading
                }
                required
              />
              <Error align="right" error={!values.title && errors.title} />
            </Grid>
          </Grid>
        </ModalContent>
        <ModalFooter>
          <Button
            type="secondary"
            onClick={onClose}
            disabled={cloneStageResult.isLoading || createStageResult.isLoading}
          >
            <FormattedMessage {...messages.cancel} />
          </Button>
          <Button
            data-testid="add-substage-modal-submit-button"
            submit
            disabled={cloneStageResult.isLoading || createStageResult.isLoading}
            isLoading={
              cloneStageResult.isLoading || createStageResult.isLoading
            }
          >
            <FormattedMessage {...messages.addSubstage} />
          </Button>
        </ModalFooter>
      </form>
      {isConfirmationModalOpen && (
        <ConfirmationModal
          ariaLabelledBy={intl.formatMessage(messages.unsavedChanges)}
          title={intl.formatMessage(messages.unsavedChanges)}
          onCancel={closeConfirm}
          onClose={closeConfirm}
          onConfirm={onConfirmSubmit}
          bodyContent={
            <FormattedMessage {...messages.unsavedChangesDescription} />
          }
          cancelButtonContent={
            <FormattedMessage {...messages.continueEditing} />
          }
          confirmButtonContent={
            <FormattedMessage {...messages.saveAllChanges} />
          }
          disabled={isSavingStageChanges}
          isLoading={isSavingStageChanges}
        />
      )}
    </Modal>
  );
};
