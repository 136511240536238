import { Grid, makeStyles } from '@material-ui/core';
import {
  ApplicantDetailDataAddress,
  PartnerOptionDataField,
} from 'api-clients/monolith';
import { compact, isEmpty } from 'lodash';
import React, { VFC } from 'react';

import { DETAIL_TYPES } from 'components/ApplicantDrawerDetailsList/constants';

import { HistoryContent } from '../HistoryContent';

const useStyles = makeStyles(theme => ({
  editPartnerData: {
    color: theme.palette.common.black,
  },
  field: {
    borderRadius: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: theme.palette.common.gray100,
    },
    padding: theme.spacing(1, 2),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0),
    },
  },
}));

interface ReadonlyContentProps {
  details: PartnerOptionDataField;
  fieldType: string;
}

export const ReadonlyContent: VFC<ReadonlyContentProps> = ({
  details,
  fieldType,
}) => {
  const { key, value, applicant } = details;
  const classes = useStyles();
  /* eslint-disable camelcase */
  const renderAddress = (value: ApplicantDetailDataAddress) => {
    const { street_name, address_2, city, state, zipcode, country } = value;

    return (
      <li>
        {compact([street_name, address_2, city, state, zipcode, country]).join(
          ', ',
        )}
      </li>
    );
  };
  /* eslint-enable camelcase */
  const renderContent = () => {
    switch (fieldType) {
      case DETAIL_TYPES.file:
        return (
          typeof value === 'string' && (
            <a href={value || 'javscript:void()'} target="_blank">
              Download
            </a>
          )
        );
      case DETAIL_TYPES.history_employment:
        if (isEmpty(value)) {
          return <>-</>;
        }
        return (
          Array.isArray(value) && (
            <HistoryContent
              historyItems={value}
              historyType="employment"
              key={`${applicant ?? 0}-${details.key}`}
            />
          )
        );
      case DETAIL_TYPES.history_address: {
        if (isEmpty(value)) {
          return <>-</>;
        }
        return (
          Array.isArray(value) && (
            <HistoryContent
              historyItems={value}
              historyType="address"
              key={`${applicant ?? 0}-${details.key}`}
            />
          )
        );
      }
      case DETAIL_TYPES.address: {
        if (isEmpty(value)) {
          return <>-</>;
        }
        return renderAddress(value as ApplicantDetailDataAddress);
      }
      default:
        return <></>;
    }
  };

  return (
    <Grid className={classes.field} key={`readonly-${key}`}>
      <b>{details.title}:</b> <br />
      {renderContent()}
    </Grid>
  );
};
