import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    color: theme.palette.common.blue400,
  },
  description: {
    color: theme.palette.common.gray800,
    paddingBottom: theme.spacing(2),
  },
  draggable: {
    marginBottom: theme.spacing(1),

    '&:last-child': {
      marginBottom: 0,
    },
  },
  dragIcon: {
    fill: theme.palette.common.gray500,
    fontSize: theme.spacing(1.5),

    '&:hover': {
      cursor: 'grab',
    },
  },
}));
