import { ApplicantBlob, DataType } from 'containers/DataValidationModal/types';
import {
  Message,
  MessagingThread,
  SelectedApplicant,
} from 'containers/SelectedApplicant/types';
import { Status } from 'containers/StatusLabels/utils';
import { ApplicantDetailDataDetailsData } from 'hooks/useApplicantDetail.d';

import {
  ADD_NEW_MESSAGE,
  ADD_NOTE,
  BULK_ACTION_INIT,
  BULK_ACTION_SUBMITTED,
  CLEAR_APPLICANT_CACHE,
  CLEAR_APPLICANT_DATA_FIELD_COLUMN,
  DELETE_APPLICANT_DETAIL,
  DELETE_APPLICANT_ERROR,
  DELETE_APPLICANT_START,
  DELETE_APPLICANT_SUCCESS,
  DELETE_NOTE,
  FETCH_APPLICANT_DETAILS,
  FETCH_APPLICANT_DETAILS_DONE,
  REMOVE_APPLICANT_FROM_STAGE,
  REMOVE_APPLICANT_FROM_STAGE_ON_CLOSE,
  REMOVE_APPLICANT_MESSAGE,
  REMOVE_APPLICANT_TEMP_MESSAGE,
  REMOVE_SELECTED_APPLICANT_ID,
  RESET_APPLICANT_DATA,
  RESET_APPLICANT_UNREAD_MESSAGES,
  RESET_SELECTED_APPLICANT_IDS,
  RESIZE_TABLE_COLUMN,
  SEND_WHATSAPP_CONVERSATION_REQUEST,
  SET_APPLICANT_BLOB,
  SET_APPLICANT_BLOBS,
  SET_APPLICANT_DETAIL,
  SET_APPLICANT_EXTRA_DETAIL,
  SET_APPLICANT_MESSAGES,
  SET_APPLICANT_MESSAGING_THREAD,
  SET_APPLICANT_NOTES,
  SET_APPLICANTS,
  SET_APPLICANTS_ERROR,
  SET_APPLICANTS_SUCCESS,
  SET_BOT_SUGGESTION_SENT,
  SET_IS_OVERLAY_VISIBILE_FALSE,
  SET_IS_OVERLAY_VISIBILE_TRUE,
  SET_SELECT_BY_COUNT,
  SET_SELECTED_APPLICANT_DETAIL,
  SET_SELECTED_APPLICANT_IDS,
  SET_SELECTED_FILTER_STAGE_IDS_WITH_FUNNELS,
  UPDATE_APPLICANT_DATA_FIELD_COLUMN,
  UPDATE_APPLICANT_DETAIL,
  UPDATE_MESSAGE_DATA_KEY_BY_MESSAGE_ID,
  UPDATE_MESSAGE_MEDIA_DELETED_BY_MESSAGE_ID,
  UPDATE_NOTE,
  UPDATE_PROFILE_APPLICANT_STATUSES,
  UPDATE_TABLE_APPLICANT_STATUSES,
  UPDATE_WHATSAPP_MESSAGE,
} from './constants';
import { Applicant, Note, TableColumnWidthsKey } from './types';

export function addNewMessageAction(applicantId: string, message: Message) {
  return {
    type: ADD_NEW_MESSAGE,
    applicantId,
    message,
  };
}

export function sendWhatsAppConversationRequest() {
  return {
    type: SEND_WHATSAPP_CONVERSATION_REQUEST,
  };
}

export function updateWhatsAppMessage(message: unknown) {
  return {
    type: UPDATE_WHATSAPP_MESSAGE,
    message,
  };
}

export function addNoteAction(applicantId: string, newNote: Note) {
  return {
    type: ADD_NOTE,
    applicantId,
    newNote,
  };
}
interface DeleteApplicantActionProps {
  applicantId: string;
  fromStageId: string;
  message: string;
}

export function deleteApplicantAction({
  applicantId,
  fromStageId,
  message,
}: DeleteApplicantActionProps) {
  return {
    type: DELETE_APPLICANT_START,
    applicantId,
    fromStageId,
    message,
  };
}

export function deleteApplicantSuccessAction() {
  return {
    type: DELETE_APPLICANT_SUCCESS,
  };
}

export function deleteApplicantErrorAction() {
  return {
    type: DELETE_APPLICANT_ERROR,
  };
}

export function deleteDetail(
  applicantId: string,
  key: string,
  dataType: DataType,
) {
  return {
    type: DELETE_APPLICANT_DETAIL,
    applicantId,
    key,
    dataType,
  };
}

export function deleteNoteAction(applicantId: string, noteId: string) {
  return {
    type: DELETE_NOTE,
    applicantId,
    noteId,
  };
}

export function setApplicantBlobAction(
  applicantId: string,
  blob: ApplicantBlob,
) {
  return {
    type: SET_APPLICANT_BLOB,
    applicantId,
    blob,
  };
}

export function setApplicantBlobsAction(
  applicantId: string,
  blobs: ApplicantBlob[],
) {
  return {
    type: SET_APPLICANT_BLOBS,
    applicantId,
    blobs,
  };
}

export function setApplicantsAction() {
  return {
    type: SET_APPLICANTS,
  };
}

export function setApplicantsActionSuccess(
  applicants: Applicant[],
  count: number,
) {
  return {
    type: SET_APPLICANTS_SUCCESS,
    applicants,
    count,
  };
}

export function setApplicantsActionError() {
  return {
    type: SET_APPLICANTS_ERROR,
  };
}

export function updateApplicant(
  applicantId: string,
  dataType: DataType,
  data: ApplicantDetailDataDetailsData[],
) {
  return {
    type: UPDATE_APPLICANT_DETAIL,
    applicantId,
    dataType,
    data,
  };
}

export function updateApplicantDataFieldColumn(
  applicantId: string,
  dataField: { key: string; value: string },
) {
  return {
    type: UPDATE_APPLICANT_DATA_FIELD_COLUMN,
    applicantId,
    dataField,
  };
}

export function clearApplicantDataFieldColumn(
  applicantId: string,
  key: string,
) {
  return {
    type: CLEAR_APPLICANT_DATA_FIELD_COLUMN,
    applicantId,
    key,
  };
}

export function fetchApplicantDetailsInit({
  applicantId,
}: {
  applicantId: string;
}) {
  return {
    type: FETCH_APPLICANT_DETAILS,
    applicantId,
  };
}

export function fetchApplicantDetailsDone() {
  return {
    type: FETCH_APPLICANT_DETAILS_DONE,
  };
}

export function updateApplicantTableStatuses(
  applicantId: string,
  statuses: Status[],
) {
  return {
    type: UPDATE_TABLE_APPLICANT_STATUSES,
    applicantId,
    statuses,
  };
}

export function updateApplicantProfileStatuses(
  applicantId: string,
  statuses: Status[],
) {
  return {
    type: UPDATE_PROFILE_APPLICANT_STATUSES,
    applicantId,
    statuses,
  };
}

export function setApplicantExtraDetailAction(
  applicantId: string,
  applicantData: SelectedApplicant,
) {
  return {
    type: SET_APPLICANT_EXTRA_DETAIL,
    applicantId,
    applicantData,
  };
}

export function setSelectedApplicantDetailAction(
  applicantId: string,
  applicantData: SelectedApplicant,
) {
  return {
    type: SET_SELECTED_APPLICANT_DETAIL,
    applicantId,
    applicantData,
  };
}

export function setApplicantNotesAction(applicantId: string, notes: Note[]) {
  return {
    type: SET_APPLICANT_NOTES,
    applicantId,
    notes,
  };
}

export function setApplicantMessagesAction(
  applicantId: string,
  messages: Message[],
) {
  return {
    type: SET_APPLICANT_MESSAGES,
    applicantId,
    messages,
  };
}

export function setBotSuggestionSent(applicantMessageId: number) {
  return {
    type: SET_BOT_SUGGESTION_SENT,
    applicantMessageId,
  };
}

export function setApplicantMessagingThreadAction(
  applicantId: string,
  messagingThread: MessagingThread,
) {
  return {
    type: SET_APPLICANT_MESSAGING_THREAD,
    applicantId,
    messagingThread,
  };
}

export function resetApplicantUnreadMessages(applicantId: string) {
  return {
    type: RESET_APPLICANT_UNREAD_MESSAGES,
    applicantId,
  };
}

// accept 'all' | {applicant_id, id}[]
// we should eventually update this so its only an array, and create another field for when `all` is selected.
// having two different types here makes our life difficult
export function setSelectedApplicantIds(
  selectedApplicantIds: string | string[],
) {
  const uniqApplicantIds = Array.isArray(selectedApplicantIds)
    ? Array.from(new Set(selectedApplicantIds))
    : selectedApplicantIds;
  return {
    type: SET_SELECTED_APPLICANT_IDS,
    selectedApplicantIds: uniqApplicantIds,
  };
}

export function resetSelectedApplicantIds() {
  return {
    type: RESET_SELECTED_APPLICANT_IDS,
  };
}

export function removeSelectedApplicantId(selectedApplicantId: string) {
  return {
    type: REMOVE_SELECTED_APPLICANT_ID,
    selectedApplicantId,
  };
}

// Selecting a transition with an 'arbitrary' count to transition
// This means we will transition say 250 applicants, but not any _specific_ applicant
export function setSelectByCount(selectByCount: number) {
  return {
    type: SET_SELECT_BY_COUNT,
    selectByCount,
  };
}

export function resetSelectByCount() {
  return {
    type: SET_SELECT_BY_COUNT,
    selectByCount: undefined,
  };
}

export function updateNoteAction(applicantId: string, updatedNote: Note) {
  return {
    type: UPDATE_NOTE,
    applicantId,
    updatedNote,
  };
}

export function removeApplicantFromStageAction(
  fromStageId: string,
  applicantIds: string[],
) {
  return {
    type: REMOVE_APPLICANT_FROM_STAGE,
    fromStageId,
    applicantIds,
  };
}

export function removeApplicantFromStageOnCloseAction(applicantIds: string[]) {
  return {
    type: REMOVE_APPLICANT_FROM_STAGE_ON_CLOSE,
    applicantIds,
  };
}

export function removeApplicantTempMessageAction(applicantId: string) {
  return {
    type: REMOVE_APPLICANT_TEMP_MESSAGE,
    applicantId,
  };
}

export function removeApplicantMessageAction(
  applicantId: string,
  messageId: string,
) {
  return {
    type: REMOVE_APPLICANT_MESSAGE,
    applicantId,
    messageId,
  };
}

export function setSelectedFilterStageIdsWithFunnel(
  selectedFilterStageIdsWithFunnel: [
    {
      /* eslint-disable camelcase */
      funnel_id: string;
      stage_ids: number[];
      /* eslint-enable camelcase */
    },
  ],
) {
  return {
    type: SET_SELECTED_FILTER_STAGE_IDS_WITH_FUNNELS,
    selectedFilterStageIdsWithFunnel,
  };
}

export function resetApplicantDataAction(applicant: SelectedApplicant) {
  return {
    type: RESET_APPLICANT_DATA,
    applicant,
  };
}

export function resizeTableColumnAction(
  columnType: TableColumnWidthsKey,
  width: number,
) {
  return {
    type: RESIZE_TABLE_COLUMN,
    columnType,
    width,
  };
}

export function bulkActionInit() {
  return { type: BULK_ACTION_INIT };
}

export function bulkActionSubmitted() {
  return { type: BULK_ACTION_SUBMITTED };
}

export function setApplicantDetail(applicant: SelectedApplicant) {
  return {
    type: SET_APPLICANT_DETAIL,
    applicant,
  };
}

export function clearApplicantCache(applicantIds?: string | string[]) {
  return {
    type: CLEAR_APPLICANT_CACHE,
    applicantIds,
  };
}

export const setIsOverlayVisibleTrue = () => ({
  type: SET_IS_OVERLAY_VISIBILE_TRUE,
});
export const setIsOverlayVisibleFalse = () => ({
  type: SET_IS_OVERLAY_VISIBILE_FALSE,
});

export function updateMessageDataKeyByMessageId(
  messageId: number,
  dataKey: string,
) {
  return {
    type: UPDATE_MESSAGE_DATA_KEY_BY_MESSAGE_ID,
    messageId,
    dataKey,
  };
}

export function updateMessageMediaDeletedByMessageId(messageId: number) {
  return {
    type: UPDATE_MESSAGE_MEDIA_DELETED_BY_MESSAGE_ID,
    messageId,
  };
}
