import { Theme } from '@material-ui/core';
import { makeStyles, StyleRules } from '@material-ui/core/styles';

const generateStyles = (theme: Theme) => {
  const styles: StyleRules = {
    td: {
      padding: theme.spacing(0, 1),
      minWidth: 180,
      maxWidth: 180,
    },
    title: {
      color: theme.palette.common.gray800,
      width: '100%',
      maxWidth: 'inherit',
      minWidth: 0,
      display: 'flex',
    },
    link: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  };
  return styles;
};

const useStyles: () => Record<keyof ReturnType<typeof generateStyles>, string> =
  makeStyles(generateStyles);

export default useStyles;
