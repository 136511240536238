/* eslint-disable camelcase */
import { ErrorState, Loader } from '@fountain/fountain-ui-components';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  AutomatedResponsesDetail,
  AutomatedResponsesService,
  CancelablePromise,
  MessageTemplateDetail,
} from 'api-clients/monolith';
import { useApiService } from 'hooks';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { Pagination } from 'components/Pagination';
import { makeSelectFeatureChatbotAdminEnabled } from 'containers/Auth_old/selectors';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';

import { AutomatedResponseRow } from './AutomatedResponseRow';
import { messages } from './messages';
import { useStyles } from './styles';

export interface ListAutomatedResponsesProps {
  setSelectedAutomatedResponse: Dispatch<
    SetStateAction<AutomatedResponsesDetail | null>
  >;
  setMessageTemplates: Dispatch<SetStateAction<MessageTemplateDetail[]>>;
  page: number;
  setPage: (page: number) => void;
  isRefetch: boolean;
  setIsRefetch: Dispatch<SetStateAction<boolean>>;
  isActiveModelDetailLoading: boolean;
  isActiveModel: boolean;
}

export const ListAutomatedResponses: FC<ListAutomatedResponsesProps> = ({
  setSelectedAutomatedResponse,
  setMessageTemplates,
  page,
  setPage,
  isRefetch,
  setIsRefetch,
  isActiveModelDetailLoading,
  isActiveModel,
}) => {
  const styles = useStyles();

  const isFeatureChatbotAdminEnabled = useSelector(
    makeSelectFeatureChatbotAdminEnabled(),
  );

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [lastPage, setLastPage] = useState(1);

  useEffect(() => {
    setLastPage(Math.ceil(totalRows / perPage));
  }, [page, perPage, totalRows]);

  // Get Automated Response API call
  const { result: automatedResponses, refetch: reFetchAutomatedResponses } =
    useApiService(
      useCallback(
        () =>
          AutomatedResponsesService.getInternalApiChatbotAutomatedResponses(
            page,
          ),
        [page],
      ),
    );

  useEffect(() => {
    if (automatedResponses.status === 'ready') {
      setTotalRows(automatedResponses.data.pagination?.total_count ?? 0);
      setIsRefetch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [automatedResponses]);

  useEffect(() => {
    if (isRefetch) {
      reFetchAutomatedResponses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefetch]);

  // Get selected Automated resposne API call
  const {
    result: selectedAutomatedResponse,
    mutation: fetchAutomatedResponse,
  } = useApiServiceMutation<
    {
      automated_response: AutomatedResponsesDetail;
      message_templates: Array<MessageTemplateDetail>;
    },
    (id: number) => CancelablePromise<{
      automated_response: AutomatedResponsesDetail;
      message_templates: Array<MessageTemplateDetail>;
    }>
  >(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    AutomatedResponsesService.getInternalApiChatbotAutomatedResponses1,
  );

  const handleEditAutomatedResponse = (id: number) => {
    fetchAutomatedResponse(id);
  };

  if (selectedAutomatedResponse.status === 'ready') {
    setSelectedAutomatedResponse(
      selectedAutomatedResponse.data.automated_response,
    );
    setMessageTemplates(selectedAutomatedResponse.data.message_templates);
  }

  if (
    automatedResponses.isLoading ||
    selectedAutomatedResponse.isLoading ||
    isActiveModelDetailLoading
  ) {
    return <Loader block size="2rem" />;
  }

  if (!isActiveModel) {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={styles.messageContainer}
      >
        <Typography variant="h4">
          <FormattedMessage {...messages.chooseModel} />
        </Typography>
      </Grid>
    );
  }

  if (automatedResponses.isError) {
    return <ErrorState onClick={reFetchAutomatedResponses} />;
  }

  if (
    automatedResponses.status === 'ready' &&
    automatedResponses.data.pagination?.total_count === 0
  ) {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={styles.messageContainer}
      >
        <Typography variant="h4">
          <FormattedMessage {...messages.noAutomatedResponses} />
        </Typography>
      </Grid>
    );
  }

  return (
    <>
      <Table role="table" padding="none" className={styles.table}>
        <TableHead className={styles.tableHead}>
          <TableRow role="row" className={styles.tableRow}>
            <TableCell role="cell">Question</TableCell>
            {isFeatureChatbotAdminEnabled && (
              <TableCell role="cell">Intent</TableCell>
            )}
            <TableCell role="cell">Message</TableCell>
            {isFeatureChatbotAdminEnabled && (
              <>
                <TableCell role="cell">Label</TableCell>
                <TableCell role="cell"></TableCell>
              </>
            )}
            <TableCell role="cell"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {automatedResponses.data.automated_responses.map(item => (
            <AutomatedResponseRow
              key={item.id}
              {...item}
              handleEditAutomatedResponse={handleEditAutomatedResponse}
              refetchAutomatedResponses={reFetchAutomatedResponses}
            />
          ))}
        </TableBody>
      </Table>
      {totalRows > perPage && (
        <Pagination
          perPage={perPage}
          currentPage={page}
          lastPage={lastPage}
          setPage={setPage}
          setPerPage={setPerPage}
          hidePerPage
        />
      )}
    </>
  );
};
