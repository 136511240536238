/*
 * Bulk Locations Messages
 *
 * This contains all the text for the Bulk Locations component.
 */

import { defineMessages } from 'react-intl';
export const scope = 'app.components.BulkLocations';

export const messages = defineMessages({
  bulkLocationsTitle: {
    id: `${scope}.bulkLocations`,
    defaultMessage: 'Bulk Locations (Beta)',
  },
});
