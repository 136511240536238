/**
 *
 * Account
 *
 */

import { Loader } from '@fountain/fountain-ui-components';
import PropTypes from 'prop-types';
import React from 'react';
import { Choose } from 'react-extras';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { impersonateUserInitAction } from 'containers/Auth_old/actions';
import {
  makeSelectAccessibleAccountSlugs,
  makeSelectAccount,
  makeSelectAccountSlug,
  makeSelectCurrentBrand,
  makeSelectIsSuperAdmin,
  makeSelectSelectedAccountSlug,
} from 'containers/Auth_old/selectors';
import NotFoundPage from 'containers/NotFoundPage';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import { closeMobileDrawerAction, toggleMobileDrawerAction } from './actions';
import Component from './components';
import reducer from './reducer';
import saga from './saga';
import { makeSelectMobileDrawerOpen } from './selectors';

/* eslint-disable-next-line react/prefer-stateless-function */
export class Account extends React.PureComponent {
  componentDidMount() {
    const {
      match: {
        params: { accountSlug },
      },
      account,
      impersonateUser,
      isSuperAdmin,
    } = this.props;

    if (isSuperAdmin && account.slug !== accountSlug) {
      impersonateUser(null, accountSlug, true);
    }
  }

  shouldShowLocationChooser = () => {
    const currentUrl = this.props.location.pathname;
    if (
      currentUrl.indexOf('jobs') !== -1 &&
      currentUrl.indexOf('sourcing') === -1
    ) {
      return true;
    }

    return false;
  };

  render() {
    const {
      match: {
        params: { accountSlug },
      },
      accountSlug: accountSlugParam,
      account,
      currentBrand,
      isSuperAdmin,
      toggleMobileDrawer,
      mobileDrawerOpen,
      handleCloseMobileDrawer,
      selectedAccountSlug,
      accessibleAccountSlugs,
    } = this.props;
    const showLocationChooser = this.shouldShowLocationChooser();

    return (
      <Choose>
        <Choose.When
          condition={
            accountSlug === accountSlugParam ||
            accountSlug === selectedAccountSlug ||
            accessibleAccountSlugs.includes(accountSlug)
          }
        >
          <Component
            account={account}
            currentBrand={currentBrand}
            isSuperAdmin={isSuperAdmin}
            showLocationChooser={showLocationChooser}
            toggleMobileDrawer={toggleMobileDrawer}
            mobileDrawerOpen={mobileDrawerOpen}
            handleCloseMobileDrawer={handleCloseMobileDrawer}
          />
        </Choose.When>
        <Choose.When
          condition={isSuperAdmin && accountSlug !== accountSlugParam}
        >
          <div>
            <Loader fullScreen size="2rem" />
          </div>
        </Choose.When>
        <Choose.Otherwise>
          <NotFoundPage />
        </Choose.Otherwise>
      </Choose>
    );
  }
}

Account.propTypes = {
  match: PropTypes.object.isRequired,
  accountSlug: PropTypes.string.isRequired,
  selectedAccountSlug: PropTypes.string.isRequired,
  accessibleAccountSlugs: PropTypes.array.isRequired,
  currentBrand: PropTypes.object,
  account: PropTypes.object,
  isSuperAdmin: PropTypes.bool,
  toggleMobileDrawer: PropTypes.func,
  mobileDrawerOpen: PropTypes.bool,
  handleCloseMobileDrawer: PropTypes.func,
  impersonateUser: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  accountSlug: makeSelectAccountSlug(),
  selectedAccountSlug: makeSelectSelectedAccountSlug(),
  accessibleAccountSlugs: makeSelectAccessibleAccountSlugs(),
  currentBrand: makeSelectCurrentBrand(),
  account: makeSelectAccount(),
  isSuperAdmin: makeSelectIsSuperAdmin(),
  mobileDrawerOpen: makeSelectMobileDrawerOpen(),
});

const mapDispatchToProps = dispatch => ({
  toggleMobileDrawer: () => dispatch(toggleMobileDrawerAction()),
  handleCloseMobileDrawer: () => dispatch(closeMobileDrawerAction()),
  impersonateUser: (userId, accountSlug, reloadPage) =>
    dispatch(impersonateUserInitAction(userId, accountSlug, reloadPage)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'account', reducer });
const withSaga = injectSaga({ key: 'account', saga });

export default compose(withReducer, withSaga, withConnect, withRouter)(Account);
