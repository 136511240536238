import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  baseline: {
    defaultMessage: 'Baseline',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.TechCheckStatus.baseline`,
  },
  fail: {
    defaultMessage: 'Fail',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.TechCheckStatus.fail`,
  },
  passAll: {
    defaultMessage: 'Pass all',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.TechCheckStatus.passAll`,
  },
  passWithDataMissing: {
    defaultMessage: 'Pass with data missing',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.TechCheckStatus.passWithDataMissing`,
  },
  tier1: {
    defaultMessage: 'Tier 1',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.TechCheckStatus.tier1`,
  },
  tier2: {
    defaultMessage: 'Tier 2',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.TechCheckStatus.tier2`,
  },
  tierName: {
    defaultMessage: 'Tier name',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.TechCheckStatus.tierName`,
  },
});
