import { makeStyles } from '@material-ui/core/styles';
/**
 *
 * Material UI styles for NotFoundPage
 *
 *
 * @see https://material-ui.com/styles/basics
 *
 */

const useStyles = makeStyles(theme => ({
  notFoundSection: {
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
