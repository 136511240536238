/* eslint-disable camelcase */
import {
  Alert,
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  StyledReactSelect,
} from '@fountain/fountain-ui-components';
import { Typography } from '@material-ui/core';
import {
  DocumentSignatureStagesTemplateSetting,
  DocumentSigningStagesTemplateSetting,
  EmbeddedSignatureTemplate,
  User,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import produce from 'immer';
import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';

import { DocumentSigningRuleProps } from 'containers/WorkflowEditor/components/Rules/DocumentSigningRules/types';

import { messages } from './messages';
import { useStyles } from './styles';

export interface DocumentModalProps {
  onClose: () => void;
  availableTemplates: Array<EmbeddedSignatureTemplate>;
  existingTemplate?:
    | DocumentSignatureStagesTemplateSetting
    | DocumentSigningStagesTemplateSetting;
  embeddedSignatureTemplateStages?: Array<{
    id?: number;
    stage_id?: number;
    signers_mapping?: Record<string, string>;
    embedded_signature_template_id?: number;
  }>;
  accountUsers?: User[];
  setStage: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
  setDocumentSigningRules?: React.Dispatch<
    React.SetStateAction<DocumentSigningRuleProps>
  >;
  ruleId?: string | number;
}

export const DocumentModal: FC<DocumentModalProps> = ({
  onClose,
  availableTemplates,
  existingTemplate,
  embeddedSignatureTemplateStages,
  accountUsers,
  setStage,
  setDocumentSigningRules,
  ruleId,
}) => {
  const intl = useIntl();
  const styles = useStyles();
  const isEditing = typeof existingTemplate?.id !== 'string';

  const [selectedDocument, setSelectedDocument] = useState(
    availableTemplates.find(
      temp =>
        existingTemplate?.template_id ===
        (temp.document_signature_template_id ?? temp.id),
    ),
  );

  const defaultSigners = embeddedSignatureTemplateStages?.find(
    ests =>
      ests.embedded_signature_template_id === existingTemplate?.template_id,
  )?.signers_mapping;

  const [signers, setSigners] = useState(defaultSigners);

  const signerRoles = selectedDocument?.signer_roles?.filter(
    role => role.name !== 'Applicant',
  );

  const signerEmails = Object.values(signers ?? {});
  const nonUniqueSigners = signerEmails.filter(
    (email, idx) => signerEmails.indexOf(email) !== idx,
  );

  /* eslint-disable no-underscore-dangle */
  const handleSubmit = () => {
    const newTemplateId =
      selectedDocument?.document_signature_template_id ?? selectedDocument?.id;

    if (!setDocumentSigningRules) {
      setStage(
        produce((draftStage: WorkflowStageDetail) => {
          if (
            (draftStage.type !== 'DocumentSignatureStage' &&
              draftStage.type !== 'DocumentSigningStage') ||
            !selectedDocument
          ) {
            return;
          }

          const elseTemplateSet =
            draftStage.additional_info?.else_template_set_attributes;
          if (elseTemplateSet?.template_settings_attributes) {
            // TemplateSet exists
            if (isEditing) {
              // Editing existing template
              const draftTemplate =
                elseTemplateSet.template_settings_attributes.find(
                  temp =>
                    temp.template_id ===
                    (existingTemplate?.document_signature_template_id ??
                      existingTemplate?.template_id),
                );
              if (draftTemplate) {
                draftTemplate.template_id = newTemplateId;
                draftTemplate.embedded_signature_template_title =
                  selectedDocument.title;
              }
            } else {
              // Adding new template to list
              elseTemplateSet.template_settings_attributes.push({
                template_id: newTemplateId,
                embedded_signature_template_title: selectedDocument.title,
              });
              // Note: the corollary to this lives in <Documents> `handleDeleteDocument` where we
              // destroy the entire template set if the last template is deleted
              if (elseTemplateSet._destroy) {
                delete elseTemplateSet._destroy;
              }
            }
          } else {
            // Net New
            draftStage.additional_info = {
              ...draftStage.additional_info,
              else_template_set_attributes: {
                id: uuid(),
                template_settings_attributes: [
                  {
                    template_id: newTemplateId,
                    embedded_signature_template_title: selectedDocument.title,
                  },
                ],
              },
            };
          }
        }),
      );
    } else {
      setDocumentSigningRules(
        produce((draftRules: DocumentSigningRuleProps) => {
          if (!selectedDocument || !ruleId) return;

          const draftTemplateSettings =
            ((draftRules[ruleId] || {}).template_set_attributes ?? {})
              .template_settings_attributes ?? [];

          if (isEditing) {
            // Editing existing template
            const draftTemplate = draftTemplateSettings.find(
              temp =>
                temp.template_id ===
                (existingTemplate?.document_signature_template_id ??
                  existingTemplate?.template_id),
            );
            if (draftTemplate) {
              draftTemplate.template_id = newTemplateId;
              draftTemplate.embedded_signature_template_title =
                selectedDocument.title;
            }
          } else {
            draftTemplateSettings.push({
              template_id: newTemplateId,
              embedded_signature_template_title: selectedDocument.title,
            });
          }
        }),
      );
    }
    /* eslint-enable no-underscore-dangle */

    setStage(
      produce((draftStage: WorkflowStageDetail) => {
        if (
          draftStage.type !== 'DocumentSignatureStage' &&
          draftStage.type !== 'DocumentSigningStage'
        ) {
          return;
        }
        // Signers only set manually for DocumentSigningStages
        if (draftStage.type !== 'DocumentSignatureStage') {
          const embeddedSignatureTemplatesStages =
            draftStage.additional_info
              .embedded_signature_templates_stages_attributes;

          if (embeddedSignatureTemplatesStages?.length) {
            // Existing Signers
            const draftEmbeddedSignatureTemplatesStage =
              embeddedSignatureTemplatesStages.find(
                ests =>
                  ests.embedded_signature_template_id ===
                  (existingTemplate?.template_id ?? selectedDocument?.id),
              );

            if (draftEmbeddedSignatureTemplatesStage) {
              draftEmbeddedSignatureTemplatesStage.embedded_signature_template_id =
                selectedDocument?.id;
              draftEmbeddedSignatureTemplatesStage.signers_mapping =
                signers ?? {};
            } else {
              embeddedSignatureTemplatesStages.push({
                embedded_signature_template_id: selectedDocument?.id,
                stage_id: draftStage.id,
                signers_mapping: signers ?? {},
              });
            }
          } else {
            // Net New Signer
            draftStage.additional_info = {
              ...draftStage.additional_info,
              embedded_signature_templates_stages_attributes: [
                {
                  embedded_signature_template_id: selectedDocument?.id,
                  stage_id: draftStage.id,
                  signers_mapping: signers ?? {},
                },
              ],
            };
          }
        }
      }),
    );

    onClose();
  };

  const onChangeDocumentTemplate = (temp: EmbeddedSignatureTemplate) => {
    setSelectedDocument(temp);

    const newDefaultSigners = embeddedSignatureTemplateStages?.find(
      ests => ests.embedded_signature_template_id === temp?.id,
    )?.signers_mapping;

    if (newDefaultSigners) {
      setSigners(newDefaultSigners);
    } else if (
      temp.document_signature_template_id ||
      temp.id !== selectedDocument?.id
    ) {
      setSigners({});
    } else {
      setSigners(defaultSigners);
    }
  };

  const onChangeSignerRole =
    (signerRole: { name?: string }) => (option: User) => {
      setSigners({
        ...signers,
        [signerRole.name ?? '']: option.email,
      });
    };

  const isSelectedDocumentUsedInStage = Boolean(
    embeddedSignatureTemplateStages?.find(
      ests => ests.embedded_signature_template_id === selectedDocument?.id,
    ),
  );

  return (
    <Modal
      data-testid="document-modal"
      ariaLabelledBy="document-modal-header"
      open
      onClose={onClose}
      fullScreenOnMobile
      maxWidth={480}
    >
      <form onSubmit={handleSubmit} key={existingTemplate?.id}>
        <ModalHeader
          onClose={onClose}
          ariaLabelledBy="document-modal-header"
          showIcon={false}
        >
          {isEditing ? (
            <FormattedMessage {...messages.editDocument} />
          ) : (
            <FormattedMessage {...messages.addDocument} />
          )}
        </ModalHeader>
        <ModalContent>
          {nonUniqueSigners.length > 0 && (
            <Alert type="danger" className={styles.alertContainer} fullWidth>
              <FormattedMessage {...messages.uniqueSigners} />
            </Alert>
          )}
          <div>
            <StyledReactSelect
              aria-label={intl.formatMessage(messages.documentTemplate)}
              label={intl.formatMessage(messages.documentTemplate)}
              options={availableTemplates}
              getOptionLabel={(temp: EmbeddedSignatureTemplate) => temp.title}
              getOptionValue={(temp: EmbeddedSignatureTemplate) => temp.id}
              onChange={onChangeDocumentTemplate}
              value={selectedDocument}
            />
          </div>
          {selectedDocument &&
            accountUsers &&
            signerRoles?.map(signerRole => {
              return (
                <div
                  className={styles.signerField}
                  key={`${selectedDocument.template_id ?? ''}-${
                    signerRole.name ?? ''
                  }`}
                >
                  <StyledReactSelect
                    aria-label={intl.formatMessage(messages.roleSelectLabel, {
                      role: signerRole.name ?? '',
                    })}
                    label={intl.formatMessage(messages.roleSelectLabel, {
                      role: signerRole.name ?? '',
                    })}
                    options={accountUsers}
                    getOptionLabel={(user: User) => user.name}
                    getOptionValue={(user: User) => user.email}
                    onChange={onChangeSignerRole(signerRole)}
                    value={accountUsers.find(
                      option =>
                        option.email === signers?.[signerRole.name ?? ''],
                    )}
                    error={nonUniqueSigners.some(
                      email => email === signers?.[signerRole.name ?? ''],
                    )}
                  />
                </div>
              );
            })}

          {isSelectedDocumentUsedInStage && (
            <Typography
              variant="body2"
              color="textPrimary"
              className={styles.warning}
            >
              <FormattedMessage {...messages.warningChangesToRoles} />
            </Typography>
          )}
        </ModalContent>
        <ModalFooter>
          <Button
            className={styles.button}
            disableRipple
            onClick={onClose}
            type="secondary"
          >
            <FormattedMessage {...messages.cancel} />
          </Button>
          <Button
            className={styles.button}
            data-testid="document-modal-submit-button"
            disableRipple
            disabled={
              !selectedDocument ||
              nonUniqueSigners.length > 0 ||
              signerRoles?.some(role => !signers?.[role.name ?? ''])
            }
            submit
          >
            {isEditing ? (
              <FormattedMessage {...messages.updateDocument} />
            ) : (
              <FormattedMessage {...messages.addDocument} />
            )}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
