/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JobDetailedResponse } from '../models/JobDetailedResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiSelfServeV1Service {

    /**
     * show
     * Returns Job
     * @param id Job#external_id
     * @returns JobDetailedResponse success
     * @throws ApiError
     */
    public static getApiSelfServeV1Jobs(
        id: string,
    ): CancelablePromise<JobDetailedResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api_self_serve/v1/jobs/{id}',
            path: {
                'id': id,
            },
        });
    }

}