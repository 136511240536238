import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    contentWrapper: {
      width: '100%',
    },
    notificationContent: {
      maxWidth: '700px',
      flexDirection: 'column',
      gap: theme.spacing(1.25),
    },
    roomyTop: {
      marginTop: theme.spacing(1),
    },
    darkText: {
      color: theme.palette.common.gray800,
    },
    emailPill: {
      display: 'flex',
      marginRight: 'auto',
      marginLeft: theme.spacing(1),
      backgroundColor: theme.palette.common.blue50,
      color: theme.palette.common.blue500,
      borderRadius: 100,
      padding: theme.spacing(0.5, 0.75, 0.5, 1),
      height: '28px',
    },
    deleteIcon: {
      marginLeft: theme.spacing(1),
    },
    deleteNarrowIcon: {
      marginLeft: theme.spacing(0.25),
    },
    ellipseIcon: {
      marginLeft: theme.spacing(1),
      height: '4px',
    },
    ellipseNarrowIcon: {
      marginLeft: theme.spacing(0.25),
      height: '4px',
    },
    emailBox: {
      zIndex: 100,
      backgroundColor: theme.palette.common.white,
      '& > button': {
        margin: theme.spacing(1, 1, 1, 1),
      },
    },
    emailLine: {
      display: 'flex',
    },
    emailLabel: {
      width: '100%',
      marginLeft: '0px',
      '& > span': {
        marginRight: 'auto',
      },
    },
    standardLabel: {
      alignItems: 'start',
      margin: theme.spacing(0, 0, 0, 4.5),
      '& > span': {
        marginRight: 'auto',
      },
    },
    marginSwitch: {
      margin: theme.spacing(0, 1, 0, 1.5),
    },
    addedSpacing: {
      margin: theme.spacing(0, 0, 0, 0),
    },
  }),
);
