import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  noWorkflowHiringOpenings: {
    id: `app.containers.WorkflowEditor.components.StageDetail.JobRouterStages.JobSwitcherStage.noWorkflowHiringOpenings`,
    defaultMessage: 'Workflow does not have any associated hiring openings',
  },
  selectOpenings: {
    id: `app.containers.WorkflowEditor.components.StageDetail.JobRouterStages.JobSwitcherStage.selectOpenings`,
    defaultMessage: 'Select openings to show',
  },
  selectionConditions: {
    id: `app.containers.WorkflowEditor.components.StageDetail.JobRouterStages.JobSwitcherStage.selectionConditions`,
    defaultMessage: 'Selection Conditions',
  },
  selectConditionsDescription: {
    id: `app.containers.WorkflowEditor.components.StageDetail.JobRouterStages.JobSwitcherStage.selectConditionsDescription`,
    defaultMessage:
      'When your opening is marked as inactive, select alternate openings that match your workflow to show an applicant where they could potentially be redirected. Applicants will be moved to the following stage in the funnel upon reaching their new opening.',
  },
  workflowRequired: {
    id: `app.containers.WorkflowEditor.components.StageDetail.JobRouterStages.JobSwitcherStage.workflowRequired`,
    defaultMessage:
      'Error: missing workflow. This stage requires a workflow to be associated with the opening. Please add one and refresh this page.',
  },
  workflowTooltipDescription: {
    defaultMessage:
      'Redirect requires openings to be on the same workflow as the current opening',
    id: `app.containers.WorkflowEditor.components.StageDetail.JobRouterStages.JobSwitcherStage.workflowTooltipDescription`,
  },
});
