import { Grid, makeStyles, Theme } from '@material-ui/core';
import React, { FC } from 'react';

import { AddItemsCard } from '../AddItemsCard';

const INSIDE = 'inside';
const OUTSIDE = 'outside';

const useStyles = makeStyles((theme: Theme) => ({
  itemsContent: {
    backgroundColor: theme.palette.common.gray100,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(0.625),
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  insideButton: {
    marginRight: 'auto',
  },
}));

export interface AddItemRowsProps {
  addButton: React.ReactNode;
  className?: string;
  emptyContentDescription: string;
  errorText?: string;
  isEmpty?: boolean;
  buttonPlacement?: 'inside' | 'outside';
}

export const AddItemRows: FC<AddItemRowsProps> = ({
  addButton,
  children,
  className,
  emptyContentDescription,
  errorText,
  isEmpty,
  buttonPlacement = OUTSIDE,
}) => {
  const styles = useStyles();

  if (isEmpty) {
    return (
      <AddItemsCard
        addButton={addButton}
        className={className}
        description={emptyContentDescription}
        errorText={errorText}
      />
    );
  }

  return (
    <div className={className}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        className={styles.itemsContent}
      >
        {children}
        {buttonPlacement === INSIDE && (
          <Grid className={styles.insideButton}>{addButton}</Grid>
        )}
      </Grid>
      {buttonPlacement === OUTSIDE && addButton}
    </div>
  );
};
