import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { VFC } from 'react';

export const ApplicantAvatar: VFC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#8B4FFB" />
      <path
        d="M18.75 12.75C18.75 14.2688 17.5188 15.5 16 15.5C14.4812 15.5 13.25 14.2688 13.25 12.75C13.25 11.2312 14.4812 10 16 10C17.5188 10 18.75 11.2312 18.75 12.75Z"
        fill="white"
      />
      <path
        d="M20.7556 22C20.8938 22 21.0065 21.8879 20.9997 21.75C20.8693 19.1047 18.6807 17 16 17C13.3193 17 11.1307 19.1047 11.0003 21.75C10.9935 21.8879 11.1062 22 11.2444 22H20.7556Z"
        fill="white"
      />
    </svg>
  </SvgIcon>
);
