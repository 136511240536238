/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WebhookNotification } from '../models/WebhookNotification';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WebhooksService {

    /**
     * Requests Settings
     * Returns Webhooks for the account of logged in user
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiWebhooksNotifications(): CancelablePromise<{
        notifications: WebhookNotification;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/webhooks/notifications',
        });
    }

    /**
     * Updates Settings
     * Updates account wide notification settings
     * @param requestBody
     * @returns any successful
     * @throws ApiError
     */
    public static postInternalApiWebhooksNotifications(
        requestBody: WebhookNotification,
    ): CancelablePromise<{
        notifications: WebhookNotification;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/webhooks/notifications',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}