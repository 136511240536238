import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  inactiveModel: {
    id: 'app.containers.Chatbot.Automatedreponses.inactive',
    defaultMessage: 'inactive / Not configured',
  },
  accuracy: {
    id: 'app.containers.Chatbot.Automatedreponses.accuracy',
    defaultMessage: 'Accuracy',
  },
  reviews: {
    id: 'app.containers.Chatbot.Automatedreponses.reviews',
    defaultMessage: 'reviews',
  },
  unknown: {
    id: 'app.containers.Chatbot.Automatedreponses.unknown',
    defaultMessage: 'unknown',
  },
});
