import { AvailableStageType } from 'api-clients/monolith';
import { AvailableRuleStageType } from 'api-clients/monolith/models/AvailableRuleStageType';
import { useIntl } from 'react-intl';

import { messages } from './messages';
import { SelectOption } from './useGetStagePlacementOptions';

export const useGetStageTypeOptions = ({
  stageTypes,
}: {
  stageTypes: AvailableStageType[] | AvailableRuleStageType[];
}) => {
  const intl = useIntl();

  return stageTypes.map<SelectOption>(type => {
    let option: SelectOption = { label: type.name, value: type.type };
    const disabledMessage =
      type.type === 'DocumentSigningStage' ||
      type.type === 'DocumentSignatureStage'
        ? intl.formatMessage(messages.unavailable)
        : intl.formatMessage(messages.alreadyAdded);
    if ('stage_type_limit_reached' in type && type.stage_type_limit_reached)
      option = {
        isDisabled: true,
        disabledMessage,
        ...option,
      };
    return option;
  });
};
