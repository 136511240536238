import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  condition: {
    defaultMessage: 'CONDITION',
    id: `app.containers.WorkflowEditor.components.Rules.PartnerRules.PartnerCondition.condition`,
  },
  conditionType: {
    defaultMessage: 'Condition Type',
    id: `app.containers.WorkflowEditor.components.Rules.PartnerRules.PartnerCondition.conditionType`,
  },
  partnerDetailHasKey: {
    defaultMessage: 'Partner detail has key',
    id: `app.containers.WorkflowEditor.components.Rules.PartnerRules.PartnerCondition.partnerDetailHasKey`,
  },
  partnerDetailHasKeyWithValue: {
    defaultMessage: 'Partner detail has key with value',
    id: `app.containers.WorkflowEditor.components.Rules.PartnerRules.PartnerCondition.partnerDetailHasKeyWithValue`,
  },
  partnerLabelDoesNotHaveKey: {
    defaultMessage: 'Partner label does not have key',
    id: `app.containers.WorkflowEditor.components.Rules.PartnerRules.PartnerCondition.partnerLabelDoesNotHaveKey`,
  },
  partnerLabelHasKey: {
    defaultMessage: 'Partner label has key',
    id: `app.containers.WorkflowEditor.components.Rules.PartnerRules.PartnerCondition.partnerLabelHasKey`,
  },
  partnerLabelHasKeyWithValue: {
    defaultMessage: 'Partner label has key with value',
    id: `app.containers.WorkflowEditor.components.Rules.PartnerRules.PartnerCondition.partnerLabelHasKeyWithValue`,
  },
  partnerStatusHasTitle: {
    defaultMessage: 'Partner status has title',
    id: `app.containers.WorkflowEditor.components.Rules.PartnerRules.PartnerCondition.partnerStatusHasTitle`,
  },
  partnerStatusIs: {
    defaultMessage: 'Partner status is',
    id: `app.containers.WorkflowEditor.components.Rules.PartnerRules.PartnerCondition.partnerStatusIs`,
  },
  partnerStatusIsNot: {
    defaultMessage: 'Partner status is not',
    id: `app.containers.WorkflowEditor.components.Rules.PartnerRules.PartnerCondition.partnerStatusIsNot`,
  },
});
