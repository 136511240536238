import { defineMessages } from 'react-intl';

export const scope = 'app.components.Document.components';

export default defineMessages({
  fullScreen: {
    id: `${scope}.fullScreen`,
    defaultMessage: 'Fullscreen',
  },
  rotateRight: {
    id: `${scope}.rotateRight`,
    defaultMessage: 'Rotate Right',
  },
  rotateLeft: {
    id: `${scope}.rotateLeft`,
    defaultMessage: 'Rotate Left',
  },
  zoomIn: {
    id: `${scope}.zoomIn`,
    defaultMessage: 'Zoom In',
  },
  zoomOut: {
    id: `${scope}.zoomOut`,
    defaultMessage: 'Zoom Out',
  },
});
