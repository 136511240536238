import { Loader } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { SidebarStage } from 'api-clients/monolith';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { addMessageAction } from 'containers/FlashMessage/actions';
import { useFunnel } from 'containers/WorkflowEditor/hooks/useFunnel';
import { useStages } from 'containers/WorkflowEditor/hooks/useStages';
import { StageContextProvider } from 'containers/WorkflowEditor/StageContextProvider/StageContextProvider';

import { OpeningNavBar } from '../OpeningNavBar';
import { WorkflowNavBarContainer } from '../WorkflowNavBarContainer';
import messages from './messages';
import { StageDetail } from './StageDetail';
import { useStyles } from './styles';

interface ParamProps {
  accountSlug: string;
  funnelSlug: string;
  stageSlug: string;
}

export interface WorkflowEditorContentProps {
  setFunnelExternalId: (funnelExternalId: string) => void;
}

export const WorkflowEditorContent: React.FC<WorkflowEditorContentProps> = ({
  setFunnelExternalId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { accountSlug, funnelSlug, stageSlug } = useParams<ParamProps>();
  const history = useHistory();
  const [selectedStage, setSelectedStage] = useState<
    SidebarStage | undefined
  >();

  const { result: funnelResult } = useFunnel({ funnelSlug });
  const { result: stagesResult, refetch: refetchStages } = useStages({
    funnelSlug,
  });

  useEffect(() => {
    if (funnelResult.status === 'ready') {
      setFunnelExternalId(funnelResult.data.external_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funnelResult]);

  // When we don't have a selected stage, await the stages result and update the url
  // with the first one.
  useEffect(() => {
    if (stagesResult.status !== 'ready' || funnelResult.status !== 'ready') {
      return;
    }

    // If funnelResult.data.slug is not equal to the url funnelSlug, we do
    // not want to set a default stage slug yet
    if (funnelResult.data.slug !== funnelSlug) {
      return;
    }

    const firstStageSlug = stagesResult.data.stages[0].slug;
    const stageExistsWithinFunnel = stagesResult.data.stages.some(
      (stage: SidebarStage) => stage.slug === stageSlug,
    );
    if (stageSlug && stageExistsWithinFunnel) {
      return;
    }
    if (firstStageSlug) {
      history.replace(
        `/${accountSlug}/openings/${funnelSlug}/workflow/${firstStageSlug}`,
      );
    }
  }, [stagesResult, history, accountSlug, funnelResult, funnelSlug, stageSlug]);

  // Sync the selectedStage state with URL stage slug
  useEffect(() => {
    if (
      stagesResult.status === 'ready' ||
      stagesResult.status === 'reloading'
    ) {
      const currentStage = stagesResult.data.stages.find(
        ({ slug }) => slug === stageSlug,
      );
      const shouldUpdateStage =
        currentStage && currentStage.external_id !== selectedStage?.external_id;
      if (shouldUpdateStage) {
        setSelectedStage(currentStage);
      }
    }
  }, [stagesResult, stageSlug, selectedStage]);

  useEffect(() => {
    if (funnelResult.isError && funnelResult?.error === 'Forbidden') {
      dispatch(
        addMessageAction(intl.formatMessage(messages.forbiddenError), 'error'),
      );
      history.replace(`/${accountSlug}/openings`);
    } else if (funnelResult.isError || stagesResult.isError) {
      dispatch(
        addMessageAction(intl.formatMessage(messages.apiError), 'error', false),
      );
    }
  }, [
    dispatch,
    funnelResult,
    intl,
    stagesResult.isError,
    history,
    accountSlug,
  ]);

  const isPageLoading =
    funnelResult.isLoading || stagesResult.status === 'loading';

  const stagesDataAvailable =
    (stagesResult.status === 'ready' || stagesResult.status === 'reloading') &&
    selectedStage !== undefined;

  return (
    <>
      {isPageLoading && <Loader fullScreen size="2rem" />}

      {funnelResult.isError && null}

      {funnelResult.status === 'ready' && (
        <>
          {funnelResult.data.is_workflow_enabled_funnel &&
          funnelResult.data.workflow ? (
            <WorkflowNavBarContainer
              applicantCount={funnelResult.data.applicants_count}
              workflowFunnels={funnelResult.data.workflow_funnels}
              title={funnelResult.data.workflow.title}
              id={funnelResult.data.workflow.id}
              externalId={funnelResult.data.workflow.external_id}
            />
          ) : (
            <OpeningNavBar
              applicantCount={funnelResult.data.applicants_count}
              opening={funnelResult.data}
              selected="workflowEditor"
            />
          )}
          {stagesResult.isError && null}
          {stagesDataAvailable && (
            <Grid className={classes.workflowEditorContent}>
              <StageContextProvider
                externalId={selectedStage.external_id}
                funnelStages={stagesResult.data.stages}
                unsupportedAxStages={stagesResult.data.unsupported_stage_types}
                opening={funnelResult.data}
                refetchStages={refetchStages}
                selectedStage={selectedStage}
                withSidebar
              >
                <Grid item className={classes.stageDetailContainer}>
                  <StageDetail opening={funnelResult.data} />
                </Grid>
              </StageContextProvider>
            </Grid>
          )}
        </>
      )}
    </>
  );
};
