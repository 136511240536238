import { Grid } from '@material-ui/core';
import React, { FC, useContext } from 'react';

import { DataKeySelect } from 'containers/WorkflowEditor/components/Rules/Condition/components/DataKeySelect';
import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';

import { SharedConditionProps } from '../../types';
import { ComparatorSelect } from '../components/ComparatorSelect';
import { InputField } from '../InputField';

export const ApplicantData: FC<SharedConditionProps> = props => {
  const { data_keys: dataKeys } = useContext(RulesEditDataContext);

  const selectedDataKeyType = React.useMemo(() => {
    if (!dataKeys) {
      return undefined;
    }

    const matches = Object.values(dataKeys)
      .map(keys => keys.find(key => key.key === props.condition.extra.key))
      .filter(Boolean);

    return matches?.[0]?.type ?? undefined;
  }, [dataKeys, props.condition.extra.key]);

  const isDatePickerField = selectedDataKeyType === 'datepicker';

  return (
    <>
      <DataKeySelect {...props} />
      <ComparatorSelect {...props} />
      <Grid item xs={6}>
        <InputField
          {...props}
          placeholder={isDatePickerField ? 'YYYY-MM-DD' : ''}
        />
      </Grid>
    </>
  );
};
