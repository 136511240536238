import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  defaultDocuments: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Documents.DefaultDocuments.defaultDocuments`,
    defaultMessage: 'Default Documents',
  },
  manageDocuments: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Documents.DefaultDocuments.manageDocuments`,
    defaultMessage:
      'Manage documents to send to applicants upon landing in this stage if no alternate documents are sent.',
  },
});
