import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { VFC } from 'react';

export const PlayIcon: VFC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 102 102">
    <svg
      width="102"
      height="102"
      viewBox="0 0 102 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 50.7813C0 22.7344 22.7344 0 50.7813 0C78.8281 0 101.563 22.7344 101.563 50.7813C101.563 78.8281 78.8281 101.563 50.7813 101.563C22.7344 101.563 0 78.8281 0 50.7813ZM73.0417 45.6615C73.954 46.1693 74.7141 46.9116 75.2433 47.8118C75.7725 48.7119 76.0515 49.7371 76.0515 50.7813C76.0515 51.8254 75.7725 52.8506 75.2433 53.7507C74.7141 54.6509 73.954 55.3932 73.0417 55.901L43.8594 72.1146C42.9677 72.6096 41.9622 72.8632 40.9424 72.8505C39.9226 72.8377 38.9237 72.559 38.0447 72.0419C37.1656 71.5248 36.4367 70.7871 35.9302 69.9019C35.4236 69.0168 35.1568 68.0147 35.1563 66.9948V34.5677C35.1563 30.1042 39.9531 27.276 43.8594 29.4479L73.0417 45.6615Z"
        fill="white"
      />
    </svg>
  </SvgIcon>
);
