import { MenuItem } from '@fountain/fountain-ui-components';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { isCurrentPageMatch } from '../../helpers';
import makeStyles from './styles';

export default function NavigationMenuItem({ message, ...props }) {
  const classes = makeStyles();

  const { to, partialMatch } = props;
  const selected = to && isCurrentPageMatch(to, partialMatch);

  const menuItemProps = {
    classes: { root: classes.root, selected: classes.selected },
    ...props,
    selected,
    disabled: selected,
    className: classes.menuListItem,
  };

  return (
    <MenuItem {...menuItemProps}>
      <FormattedMessage {...message} />
    </MenuItem>
  );
}

NavigationMenuItem.propTypes = {
  to: PropTypes.string,
  partialMatch: PropTypes.bool,
  message: PropTypes.object.isRequired,
  'data-testid': PropTypes.string.isRequired,
};
