import { defineMessages } from 'react-intl';

export const scope = 'app.components.DelayMessageOptions';

export default defineMessages({
  messageTimeZoneInfo: {
    id: `${scope}.messageTimeZoneInfo`,
    defaultMessage:
      "Message is scheduled in the Opening's timezone: {timezone}",
  },
});
