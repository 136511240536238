import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import React, { VFC } from 'react';
import { useIntl } from 'react-intl';

import messages from './messages';

const useStyles = makeStyles(theme => ({
  selectText: {
    fontWeight: 500,
    margin: theme.spacing(0, 3),
  },
  bulkActionWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  button: {
    padding: theme.spacing(1.5, 2.5),
    borderRadius: '8px',
    border: `1px solid ${theme.palette.common.gray400}`,
    boxShadow: theme.customShadows.shadow1,
    fontSize: '14px',
    lineHeight: '16px',
    '&:hover': {
      backgroundColor: theme.palette.common.blue400,
      color: theme.palette.common.white,
    },
  },
  buttonIcon: {
    height: '16px',
    width: '16px',
    marginRight: theme.spacing(1),
  },
}));

export interface BulkActionProps {
  count: number;
  handleBulkAction: (action: string) => void;
}

export const BulkAction: VFC<BulkActionProps> = ({
  count,
  handleBulkAction,
}) => {
  const intl = useIntl();
  const styles = useStyles();

  return (
    <div className={styles.bulkActionWrapper}>
      <Typography variant="h4" className={styles.selectText}>
        {intl.formatMessage(messages.selected, {
          count,
        })}
      </Typography>
      <Button
        className={styles.button}
        onClick={() => handleBulkAction('approved')}
      >
        <CheckIcon className={styles.buttonIcon} />
        <span>{intl.formatMessage(messages.approveAll)}</span>
      </Button>
      <Button
        className={styles.button}
        onClick={() => handleBulkAction('rejected')}
      >
        <ClearIcon className={styles.buttonIcon} />
        <span>{intl.formatMessage(messages.rejectAll)}</span>
      </Button>
    </div>
  );
};
