import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Autolinker from 'autolinker';
import PropTypes from 'prop-types';
import React from 'react';
import { classNames } from 'react-extras';

import { MESSAGE_DIRECTION } from '../../constants';
import { messageBubbleStyles } from './styles';

const MessageBubble = ({
  classes,
  className,
  direction,
  hasLinks,
  message,
  isWhatsappPreview,
}) => {
  const getContent = () => {
    if (hasLinks) {
      const linkedBody = Autolinker.link(message);
      return <div dangerouslySetInnerHTML={{ __html: linkedBody }} />;
    }
    return message;
  };

  return (
    <Typography
      className={classNames(className, classes.messageBubble, {
        [classes.whatsappGreenBubble]:
          direction === MESSAGE_DIRECTION.sending && isWhatsappPreview,
        [classes.egressBubble]:
          direction === MESSAGE_DIRECTION.sending && !isWhatsappPreview,
        [classes.ingressBubble]: direction === MESSAGE_DIRECTION.receiving,
      })}
      component="div"
      variant="body2"
    >
      {getContent()}
    </Typography>
  );
};

MessageBubble.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  direction: PropTypes.string,
  hasLinks: PropTypes.bool,
  message: PropTypes.string.isRequired,
  isWhatsappPreview: PropTypes.bool,
};

MessageBubble.defaultProps = {
  direction: 'egress',
  hasLinks: false,
};

export default withStyles(messageBubbleStyles)(MessageBubble);
