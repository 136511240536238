import { Grid, makeStyles, Typography } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import {
  ApplicantDetailDataAddress,
  PartnerOptionDataField,
} from 'api-clients/monolith';
import React, { useEffect, useState, VFC } from 'react';
import { classNames } from 'react-extras';
import { useIntl } from 'react-intl';

import ActionButtonsContainer from 'components/ApplicantDrawerDetailsList/components/Field/ActionButtons/ActionButtonsContainer';
import ClearDetailFieldDialog from 'components/ApplicantDrawerDetailsList/components/Field/ClearDetailFieldDialog';
import EditingFieldContainer from 'components/ApplicantDrawerDetailsList/components/Field/EditingFieldContainer';
import ValuesContainer from 'components/ApplicantDrawerDetailsList/components/Field/Values/ValuesContainer';
import { DETAIL_TYPES } from 'components/ApplicantDrawerDetailsList/constants';
import useDeletePartnerOptionData from 'hooks/partners/useDeletePartnerOptionData';
import useUpdatePartnerOptionData from 'hooks/partners/useUpdatePartnerOptionData';
import useUserPermissions from 'hooks/useUserPermissions';

import { messages } from '../messages';

// Borrowed from app/components/ApplicantDrawerDetailsList/components/Field/index.js
const isFieldEmpty = (value: string | unknown[] | ApplicantDetailDataAddress) =>
  !value ||
  !!(Array.isArray(value) && value.length === 0) ||
  !!(
    typeof value === 'object' &&
    Object.values(value).filter(element => !!element).length === 0
  );

const useStyles = makeStyles(theme => ({
  field: {
    borderRadius: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: theme.palette.common.gray100,
    },
    padding: theme.spacing(1, 2),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0),
    },
  },
  fieldContainer: {
    width: '100%',
  },
  fieldContent: {
    width: '70%',
  },
  title: {
    paddingBottom: theme.spacing(0.5),
    color: theme.palette.common.gray600,
  },
}));

interface FieldProps {
  className: string;
  detail: PartnerOptionDataField;
  applicantId: string;
  partnerOptionDataId: string;
  refreshData: () => void;
}

export const Field: VFC<FieldProps> = ({
  detail,
  applicantId,
  className,
  partnerOptionDataId,
  refreshData,
}) => {
  const intl = useIntl();

  intl.formatMessage(messages.updateFieldErrorMessage);
  const classes = useStyles();
  const [showButtons, setShowButtons] = useState(false);
  const [editingField, setEditingField] = useState(false);
  const [value, setValue] = useState(detail.value);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { updatePartnerOptionData, isUpdating } = useUpdatePartnerOptionData({
    successMessage: intl.formatMessage(messages.updateFieldSuccessMessage),
    errorMessage: intl.formatMessage(messages.updateFieldErrorMessage),
  });
  const { deletePartnerOptionData, isDeleting } = useDeletePartnerOptionData({
    successMessage: intl.formatMessage(messages.clearFieldSuccessMessage),
    errorMessage: intl.formatMessage(messages.clearFieldErrorMessage),
  });
  const { manageApplicantsPermission } = useUserPermissions();

  useEffect(() => {
    setValue(detail.value);
  }, [detail.value]);

  useEffect(() => {
    if (detail.type === DETAIL_TYPES.availability && !detail.value) {
      setValue([]);
    }
  }, [detail.type, detail.value]);

  useEffect(() => {
    if ((editingField || isUpdating || isDeleting) && showButtons) {
      setShowButtons(false);
    }
  }, [editingField, isUpdating, isDeleting, showButtons]);

  const handleClickDialogClear = () => {
    setIsModalOpen(false);

    const fields = [{ key: detail.key, value, type: detail.type }];

    deletePartnerOptionData({
      applicantId,
      partnerOptionDataId,
      fields,
    })
      .then(() => {
        setValue('');
      })
      .finally(() => {
        refreshData();
      });
  };

  const handleClickClearActionButton = () => setIsModalOpen(true);
  const handleClickEditActionButton = () => setEditingField(!editingField);

  const handleClickEditCancel = () => {
    setValue(detail.value);
    setEditingField(false);
  };

  const handleClickEditConfirm = () => {
    const fields = [{ key: detail.key, value, type: detail.type }];

    updatePartnerOptionData({
      applicantId,
      partnerOptionDataId,
      fields,
    }).finally(() => {
      setEditingField(false);
      refreshData();
    });
  };

  const handleCloseDialog = () => setIsModalOpen(false);
  const handleMouseEnter = () => setShowButtons(true);
  const handleMouseLeave = () => setShowButtons(false);

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classNames(classes.field, className)}
        justify="space-between"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Grid
          item
          container
          className={classes.fieldContainer}
          justify="space-between"
        >
          <Grid className={classes.fieldContent}>
            <Typography variant="h4" className={classes.title}>
              {detail.title}
            </Typography>
            {!editingField && (
              <Fade key={detail.title} in>
                <div>
                  <ValuesContainer detail={detail} value={value} />
                </div>
              </Fade>
            )}
          </Grid>
          {manageApplicantsPermission && editingField && (
            <EditingFieldContainer
              applicantId={applicantId}
              detail={detail}
              onCancel={handleClickEditCancel}
              onConfirm={handleClickEditConfirm}
              onSetValue={setValue}
              value={value}
            />
          )}
          {showButtons && !editingField && (
            <Grid item>
              <ActionButtonsContainer
                detail={detail}
                isFieldEmpty={isFieldEmpty(value)}
                onClickClear={handleClickClearActionButton}
                onClickEdit={handleClickEditActionButton}
                value={value}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      {isModalOpen && (
        <ClearDetailFieldDialog
          onClickCancel={handleCloseDialog}
          onClickClear={handleClickDialogClear}
          onCloseDialog={handleCloseDialog}
        />
      )}
    </>
  );
};
