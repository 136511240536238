import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  applyToJobSettings: {
    id: 'app.containers.ChatbotWidget.applyToJobSettings',
    defaultMessage: 'Apply to Job Chatbot Settings',
  },
  applyToJobSubtitle: {
    id: 'app.containers.ChatbotWidget.applyToJobSubtitle',
    defaultMessage: 'Search customization',
  },
  applyToJobDesc: {
    id: 'app.containers.ChatbotWidget.applyToJobDesc',
    defaultMessage:
      'Show applicants the following details on their opening options',
  },
  searchRadius: {
    id: 'app.containers.ChatbotWidget.searchRadius',
    defaultMessage: 'Search radius',
  },
  includeRemoteJobs: {
    id: 'app.containers.ChatbotWidget.includeRemoteJobs',
    defaultMessage: 'Include remote jobs',
  },
  viewControl: {
    id: 'app.containers.ChatbotWidget.viewControl',
    defaultMessage: 'View control',
  },
  selectOpeningsSwitch: {
    id: 'app.containers.ChatbotWidget.selectOpeningsSwitch',
    defaultMessage: 'Only show chatbot widget to certain openings',
  },
  selectOpeningsLabel: {
    id: 'app.containers.ChatbotWidget.selectOpeningsLabel',
    defaultMessage: 'Openings',
  },
  selectOpeningsPlaceholder: {
    id: 'app.containers.ChatbotWidget.selectOpeningsPlaceholder',
    defaultMessage: 'Select opening(s)',
  },
  locationDropdownLimitLabel: {
    id: 'app.containers.ChatbotWidget.locationDropdownLimitLabel',
    defaultMessage: 'Location dropdown limit',
  },
  locationDropdownLimitError: {
    id: 'app.containers.ChatbotWidget.locationDropdownLimitError',
    defaultMessage: 'Value should be between 0 to 20',
  },
});
