import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { DataKey } from 'api-clients/monolith';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import { getActionErrorMessage } from 'containers/WorkflowEditor/components/Rules/Action/constants';
import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';
import { GetGroupedDataKeys } from 'containers/WorkflowEditor/RulesEditDataContextProvider/RulesEditDataContextProvider';

import { RulesProps, SharedActionProps } from '../../../types';
import messages from './messages';

export interface DataKeySelectProps extends SharedActionProps {
  isSwitchKey?: boolean;
  label?: string;
}

export const DataKeySelect: FC<DataKeySelectProps> = ({
  setRules,
  ruleId,
  action,
  isSwitchKey,
  errors,
  label,
}) => {
  const intl = useIntl();
  const { extra } = action;

  const { data_keys: dataKeys } = useContext(RulesEditDataContext);

  const keyField = isSwitchKey ? 'switch_key' : 'key';
  const predefinedField = isSwitchKey ? 'switch_key_standard' : 'standard';

  const onChangeDataKey = (dataKey: DataKey) => {
    setRules(
      produce((draftRules: RulesProps) => {
        const draftActions =
          draftRules[ruleId]?.action_set_attributes?.actions_attributes ?? [];

        const idx = draftActions.findIndex(act => act.id === action.id);

        const { predefined, key, identifier } = dataKey;

        const draftActionExtra = draftActions[idx].extra;

        if (isSwitchKey) {
          draftActionExtra.switch_key_standard = predefined;
          draftActionExtra.switch_key = key;
          draftActionExtra.switch_key_identifier = identifier;
        } else {
          draftActionExtra.standard = predefined;
          draftActionExtra.key = key;
          draftActionExtra.data_key_identifier = identifier;
        }

        delete draftActionExtra.value;
      }),
    );
  };

  const selectedKey = {
    key: extra[keyField],
    predefined: extra[predefinedField],
  };
  const selectDisplayValue = selectedKey.key
    ? {
        key: `${selectedKey.key} (${
          selectedKey.predefined ? 'standard' : 'custom'
        })`,
      }
    : null;

  const errorMessage =
    !selectedKey.key && getActionErrorMessage(keyField, errors);

  // TODO: create select with tabs for custom keys / standard & additional keys
  return (
    <Grid item xs={6}>
      <StyledReactSelect
        options={GetGroupedDataKeys(dataKeys)}
        value={selectDisplayValue}
        onChange={onChangeDataKey}
        label={label ?? intl.formatMessage(messages.dataKey)}
        aria-label={label ?? intl.formatMessage(messages.dataKey)}
        getOptionLabel={(option: DataKey) => option.key}
        isOptionSelected={(option: DataKey) =>
          option.key === selectedKey.key &&
          option.predefined === selectedKey.predefined
        }
        isSearchable
        required
        placeholder=""
        error={errorMessage}
      />
    </Grid>
  );
};
