import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type QueryValue = string | number;

function useQueryState(
  queryKey: string,
  orElse: QueryValue,
): [string, (value: QueryValue | null) => void];
function useQueryState(
  queryKey: string,
): [string | null, (value: QueryValue | null) => void];
function useQueryState(
  queryKey: string,
  orElse?: QueryValue,
): [unknown, (value: QueryValue | null) => void] {
  const location = useLocation();
  const history = useHistory();
  const search = new URLSearchParams(location.search);
  const currentValue = search.get(queryKey);

  const setQuery = useCallback(
    (value: QueryValue | null) => {
      const search = new URLSearchParams(window.location.search);
      if (value === null) {
        search.delete(queryKey);
      } else {
        search.set(queryKey, value.toString());
      }
      const queryString = search.toString();

      history.push(`${location.pathname}?${queryString}`);
    },
    [history, location, queryKey],
  );

  if (typeof orElse === 'undefined') {
    return [currentValue, setQuery];
  }
  return [currentValue ?? orElse.toString(), setQuery];
}

export { useQueryState };
