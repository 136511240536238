import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { SetStateAction } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { messages } from '../messages';
import { useStyles } from './styles';
import { AutocompleteOption } from './types';

interface LocationSelectProps {
  autoCompleteValue: AutocompleteOption;
  setAutocompleteValue: (value: SetStateAction<AutocompleteOption>) => void;
  inputAutocompleteValue: string;
  setInputAutocompleteValue: (value: string) => void;
  autocompleteOptions: AutocompleteOption[];
  // eslint-disable-next-line camelcase
  setSelectedLocation: (location: { onsite_address: string }) => void;
  setIsAddingNewLocation: (isAdding: boolean) => void;
  setIsAddingLocationFromInput: (isAddingLocationFromInput: boolean) => void;
  setInputLocation: (location: string) => void;
}

const LocationSelect: React.FC<LocationSelectProps> = ({
  autoCompleteValue,
  setAutocompleteValue,
  inputAutocompleteValue,
  setInputAutocompleteValue,
  autocompleteOptions,
  setSelectedLocation,
  setIsAddingNewLocation,
  setInputLocation,
  setIsAddingLocationFromInput,
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const noneOption = {
    key: '',
    value: '',
  };
  return (
    <div data-test className={styles.selectLocationContainer}>
      <Box display="flex" className={styles.gapBetween}>
        <Grid item xs={12} sm={12}>
          <Typography variant="body2" className={styles.label}>
            <FormattedMessage {...messages.location} />
          </Typography>
          <Autocomplete
            value={autoCompleteValue}
            onChange={(event, newValue) => {
              setAutocompleteValue(newValue ?? noneOption);
              setSelectedLocation({
                onsite_address: newValue?.value ?? '',
              });
            }}
            getOptionLabel={option => option.value}
            inputValue={inputAutocompleteValue}
            onInputChange={(event, newInputValue) => {
              setInputAutocompleteValue(newInputValue);
            }}
            id="controllable-states-demo"
            data-testid="calendar-create_event-location_details-autocomplete"
            options={autocompleteOptions}
            fullWidth
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={intl.formatMessage(messages.locationPlaceholder)}
                inputProps={{
                  ...params.inputProps,
                  'data-testid':
                    'calendar-create_event-location_details-location_select',
                }}
              />
            )}
            PaperComponent={({ children }) => (
              <Paper>
                <Button
                  color="primary"
                  fullWidth
                  className={styles.addNewOption}
                  data-testid="calendar-create_event-location_details-location_select-add_new_location"
                  onMouseDown={() => {
                    setIsAddingNewLocation(true);
                    setIsAddingLocationFromInput(true);
                    setInputLocation('');
                  }}
                >
                  <FormattedMessage {...messages.addNewLocation} />
                </Button>
                {children}
              </Paper>
            )}
          />
        </Grid>
      </Box>
    </div>
  );
};

export default LocationSelect;
