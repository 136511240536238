import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  bodyText: {
    id: 'app.containers.WorkflowEditor.components.UnsavedChangesPrompt.bodyText',
    defaultMessage:
      'You have unsaved changes on the stage, which will be lost if you do not save. Do you want to continue editing or discard the changes?',
  },
  continueEditing: {
    id: 'app.containers.WorkflowEditor.components.UnsavedChangesPrompt.continueEditing',
    defaultMessage: 'Continue Editing',
  },
  discard: {
    id: 'app.containers.WorkflowEditor.components.UnsavedChangesPrompt.discard',
    defaultMessage: 'Discard',
  },
  unsavedChanges: {
    id: 'app.containers.WorkflowEditor.components.UnsavedChangesPrompt.unsavedChanges',
    defaultMessage: 'Unsaved Changes',
  },
  unsavedChangesModal: {
    id: 'app.containers.WorkflowEditor.components.UnsavedChangesPrompt.unsavedChangesModal',
    defaultMessage: 'Unsaved Changes Modal',
  },
});
