import produce from 'immer';

import {
  CLEAR_FILTERS,
  DELETE_SAVED_FILTER_SUCCESS,
  FETCH_FILTER_SETS_ERROR,
  FETCH_FILTER_SETS_SUCCESS,
  FETCH_SAVED_FILTER_SUCCESS,
  FETCH_SAVED_FILTERS_SUCCESS,
  SAVE_FILTER_SUCCESS,
  SET_DATA_CONDITIONS,
  SET_FILTER_SETS,
  SET_FILTERS,
  SET_SELECTED_FILTER,
  SET_STAGE_CONDITIONS,
  SET_TEMP_FILTER,
  UPDATE_SAVED_FILTER_ERROR,
  UPDATE_SAVED_FILTER_SUCCESS,
} from './constants';

export const initialState = {
  filters: [],
  stageConditions: [],
  filterSets: [],
  savedFilters: [],
  selectedFilter: null,
  tempFilter: null,
  isUpdatingFilters: false,
  isUpdatedFilters: false,
};

const sortFilters = (a, b) => a.title.localeCompare(b.title);

const applicantsFilterReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case FETCH_FILTER_SETS_SUCCESS:
        draft.filterSets = action.filterSets;
        break;
      case FETCH_FILTER_SETS_ERROR:
        break;
      case SET_STAGE_CONDITIONS:
        draft.stageConditions = action.stageConditions;
        break;
      case SET_DATA_CONDITIONS:
        draft.dataConditions = action.dataConditions;
        break;
      case SET_FILTERS:
        draft.filters = action.filters;
        break;
      case SET_FILTER_SETS:
        draft.filterSets = action.filterSets;
        break;
      case FETCH_SAVED_FILTERS_SUCCESS:
        draft.savedFilters = action.savedFilters;
        draft.savedFilters.sort(sortFilters);
        break;
      case DELETE_SAVED_FILTER_SUCCESS:
        draft.selectedFilter = null;
        draft.tempFilter = null;
        draft.savedFilters = draft.savedFilters.filter(
          stageFilter => stageFilter.id !== action.filterId,
        );
        break;
      case UPDATE_SAVED_FILTER_SUCCESS:
        draft.savedFilters = draft.savedFilters.map(savedFilter => {
          if (savedFilter.id === action.updatedFilter.id) {
            return action.updatedFilter;
          }
          return savedFilter;
        });
        draft.savedFilters.sort(sortFilters);
        draft.isUpdatingFilters = false;
        draft.isUpdatedFilters = true;
        break;
      case UPDATE_SAVED_FILTER_ERROR:
        draft.isUpdatingFilters = false;
        draft.isUpdatedFilters = false;
        break;
      case SAVE_FILTER_SUCCESS:
        draft.selectedFilter = action.savedFilter;
        draft.tempFilter = null;
        break;
      case FETCH_SAVED_FILTER_SUCCESS:
        if (action.savedFilter.visible) {
          draft.selectedFilter = action.savedFilter;
          draft.tempFilter = null;
        } else {
          draft.selectedFilter = null;
          draft.tempFilter = action.savedFilter;
        }
        break;
      case SET_SELECTED_FILTER:
        draft.selectedFilter = action.savedFilter;
        break;
      case SET_TEMP_FILTER:
        draft.tempFilter = action.tempFilter;
        break;
      case CLEAR_FILTERS:
        draft.selectedFilter = null;
        draft.tempFilter = null;
      // no default
    }
  });

export default applicantsFilterReducer;
