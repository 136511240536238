import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  stageColumn: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 2, 8, 2),
    gap: theme.spacing(1),
    height: '100%',
    overflowY: 'scroll',
    width: 'auto',
    '@media(min-width: 1400px)': {
      width: '490px',
    },
  },
}));
