import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  emailPDF: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.I9FormStage.I9Form.emailPDF`,
    defaultMessage: 'Email completed I-9 PDF',
  },
  getSSN: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.I9FormStage.I9Form.getSSN`,
    defaultMessage: 'Get SSN Confirmation from applicant (recommended)',
  },
  I9Form: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.I9FormStage.I9Form.I9Form`,
    defaultMessage: 'I-9 Form',
  },
  moveApplicant: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.I9FormStage.I9Form.moveApplicant`,
    defaultMessage:
      'Automatically move applicant to next stage after the I-9 form is completed (recommended)',
  },
  recipient: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.I9FormStage.I9Form.recipient`,
    defaultMessage: 'Recipient',
  },
  shouldAutoAdvanceWhenLabelsComplete: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.I9FormStage.I9Form.shouldAutoAdvanceWhenLabelsComplete`,
    defaultMessage:
      'Automatically move applicant to next stage when all labels are completed',
  },
  showStageInPortal: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.I9FormStage.I9Form.showStageInPortal`,
    defaultMessage: 'Show stage in applicant portal progress tracker',
  },
  emailI9SectionOne: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.I9FormStage.I9Form.emailI9SectionOne`,
    defaultMessage: 'Email "Section 1 complete" notification',
  },
});
