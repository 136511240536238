import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  distributeApplicantsRuleStage: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1, 1, 1, 1),
    minWidth: '790px',
  },
  stageDescription: {
    marginBottom: theme.spacing(2),
  },
  addOpeningContent: {
    marginBottom: theme.spacing(3),
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',

    '&.disabled': {
      cursor: 'not-allowed',
    },
  },
  addOpeningText: {
    marginLeft: theme.spacing(1),
    userSelect: 'none',
  },
  plusIcon: {
    border: theme.customBorders.gray400,
    borderRadius: '100%',
    padding: theme.spacing(1),

    '&.disabled': {
      backgroundColor: theme.palette.common.gray100,
    },
  },
}));

export default useStyles;
