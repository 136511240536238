import { Button, CustomTooltip } from '@fountain/fountain-ui-components';
import { IconButton, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CareerSiteSettings } from 'api-clients/monolith';
import React, { useState, VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import TrashIcon from 'images/trashcan.svg';

import { Description } from '../ChatbotWidget/Components/Description';
import { SectionTitle } from '../ChatbotWidget/Components/SectionTitle';
import { SettingTitle } from '../ChatbotWidget/Components/SettingTitle';
import { SettingSwitch } from './Components/SettingSwitch';
import { messages } from './messages';
import { HandleSettingChange } from './types';

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing(3),
  },
  labels: {
    marginTop: theme.spacing(2),
  },
  inputContainer: {
    position: 'relative',
    paddingRight: theme.spacing(8),
  },
  deleteButton: {
    position: 'absolute',
    top: '30%',
  },
}));

export interface FaqSettingsProps {
  settings: CareerSiteSettings;
  handleSettingChange: HandleSettingChange;
}

export const FaqSettings: VFC<FaqSettingsProps> = ({
  settings,
  handleSettingChange,
}) => {
  const styles = useStyles();
  const initialQuestions = 3;
  const maxQuestionsAllowed = 5;

  const [questions, setQuestions] = useState(
    settings?.prefilled_faq_chatbot_questions,
  );

  const addQuestion = () => {
    if (questions.length < maxQuestionsAllowed) {
      setQuestions(prevQuestions => [...prevQuestions, '']);
    }
  };

  const deleteQuestion = (index: number) => {
    if (questions.length > initialQuestions) {
      setQuestions(prevQuestions => {
        const updatedQuestions = prevQuestions.filter((_, i) => i !== index);
        handleSettingChange(
          'prefilled_faq_chatbot_questions',
          updatedQuestions,
        );

        return updatedQuestions;
      });
    }
  };

  const handleQuestionChange = (index: number, value: string) => {
    setQuestions(prevQuestions => {
      const newQuestions = [...prevQuestions];
      newQuestions[index] = value;

      handleSettingChange('prefilled_faq_chatbot_questions', newQuestions);

      return newQuestions;
    });
  };

  return (
    <section>
      <SectionTitle value={messages.faqBotSettings} />
      <CustomTooltip
        placement="top"
        disableHoverListener={!!settings.is_faq_bot_trained}
        dense
        title={<FormattedMessage {...messages.trainFaqBot} />}
      >
        <SettingSwitch
          settingKey="show_faq_chatbot_by_default"
          label={messages.showFaqByDefault}
          checked={settings?.show_faq_chatbot_by_default}
          onChange={handleSettingChange}
          disabled={!settings.is_faq_bot_trained}
        />
      </CustomTooltip>
      <Description value={messages.showFaqBotDesc} />

      <SettingTitle
        value={messages.preFilledFaqQuestionLabel}
        className={styles.title}
      />
      <Description value={messages.preFilledFaqQuestionDesc} />

      <div className={styles.labels}>
        {questions.map((q, index) => (
          <div
            key={q[index]}
            className={index >= initialQuestions ? styles.inputContainer : ''}
          >
            <TextField
              id="prefilled_faq_chatbot_questions"
              label={`Question${index + 1}`}
              style={{ margin: '8 0' }}
              fullWidth
              value={q}
              onChange={e => handleQuestionChange(index, e.target.value)}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />

            {index >= initialQuestions && (
              <IconButton
                onClick={() => deleteQuestion(index)}
                className={styles.deleteButton}
              >
                <img src={TrashIcon} alt="trash" role="presentation" />
              </IconButton>
            )}
          </div>
        ))}
        <Button
          variant="outlined"
          type="secondary"
          disabled={questions.length === maxQuestionsAllowed}
          onClick={addQuestion}
        >
          Add Question
        </Button>
      </div>
    </section>
  );
};
