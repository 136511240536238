/*
 *
 * FileRecollectionDrawer actions
 *
 */

import { SET_FILE_RECOLLECTION_DATA } from './constants';

export function setFileRecollectDataAction(fileRecollectionData) {
  return {
    type: SET_FILE_RECOLLECTION_DATA,
    fileRecollectionData,
  };
}
