import { APPLICANT_DRAWER_TABS } from 'containers/ApplicantDrawerPopup/constants';

import { Bucket, BucketObject, SortDirection, SortLabel } from './types';

export const REDUCER_KEY = 'messenger';

export const FETCH_OPENINGS_INIT = 'app/Messenger/FETCH_OPENINGS_INIT';
export const FETCH_OPENINGS_MOBILE_INIT =
  'app/Messenger/FETCH_OPENINGS_MOBILE_INIT';
export const FETCH_OPENINGS_SUCCESS = 'app/Messenger/FETCH_OPENINGS_SUCCESS';
export const FETCH_OPENINGS_ERROR = 'app/Messenger/FETCH_OPENINGS_ERROR';

export const SET_LOCATION_THREAD_COUNT =
  'app/Messenger/SET_LOCATION_THREAD_COUNT';

export const FETCH_MESSAGE_THREADS_INIT =
  'app/Messenger/FETCH_MESSAGE_THREADS_INIT';
export const FETCH_MESSAGE_THREADS_SUCCESS =
  'app/Messenger/FETCH_MESSAGE_THREADS_SUCCESS';
export const FETCH_MESSAGE_THREADS_ERROR =
  'app/Messenger/FETCH_MESSAGE_THREADS_ERROR';
export const UPDATE_MESSAGE_THREADS_BUCKET_INIT =
  'app/Messenger/UPDATE_MESSAGE_THREADS_BUCKET_INIT';
export const UPDATE_MESSAGE_THREADS_BUCKET_SUCCESS =
  'app/Messenger/UPDATE_MESSAGE_THREADS_BUCKET_SUCCESS';
export const UPDATE_MESSAGE_THREADS_COUNT =
  'app/Messenger/UPDATE_MESSAGE_THREADS_COUNT';
export const UPDATE_MESSAGE_THREADS_BUCKET_ERROR =
  'app/Messenger/UPDATE_MESSAGE_THREADS_BUCKET_ERROR';
export const TOGGLE_IS_ALL_SELECTED = 'app/Messenger/TOGGLE_IS_ALL_SELECTED';
export const SET_IS_MOBILE_MESSAGES_PANEL_OPEN =
  'app/Messenger/SET_IS_MOBILE_MESSAGES_PANEL_OPEN';
export const SET_IS_MOBILE_MESSAGES_PANEL_CLOSE =
  'app/Messenger/SET_IS_MOBILE_MESSAGES_PANEL_CLOSE';
export const UPDATE_FUNNEL_THREAD_COUNT =
  'app/Messenger/UPDATE_FUNNEL_THREAD_COUNT';
export const UPDATE_MESSAGING_THREAD_ACTIONS =
  'app/Messenger/UPDATE_MESSAGING_THREAD_ACTIONS';

export const SET_FILE_RECOLLECTION_DATA =
  'app/FileRecollectionDrawer/SET_FILE_RECOLLECTION_DATA';

export const SELECT_ALL_MESSAGE_THREADS =
  'app/Messenger/SELECT_ALL_MESSAGE_THREADS';
export const SELECT_MESSAGE_THREAD = 'app/Messenger/SELECT_MESSAGE_THREAD';
export const RESET_SELECTED_MESSAGE_THREADS =
  'app/Messenger/RESET_SELECTED_MESSAGE_THREAD_IDS';
export const RESET_MESSAGE_THREAD_DATA =
  'app/Messenger/RESET_MESSAGE_THREAD_DATA';
export const UPDATE_MESSAGE_THREAD = 'app/Messenger/UPDATE_MESSAGE_THREAD';
export const SET_ACTIVE_STATES = 'app/Messenger/SET_ACTIVE_STATE';
export const UPDATE_MESSAGE_THREAD_UNREAD_COUNT =
  'app/Messenger/UPDATE_MESSAGE_THREAD_UNREAD_COUNT';

export const DELETE_APPLICANT_FROM_MESSENGER =
  'app/Messenger/DELETE_APPLICANT_FROM_MESSENGER';
export const DELETE_APPLICANT_FROM_MESSENGER_SUCCESS =
  'app/Messenger/DELETE_APPLICANT_FROM_MESSENGER_SUCCESS';
export const OPEN_DELETE_APPLICANT_MODAL =
  'app/Messenger/OPEN_DELETE_APPLICANT_MODAL';
export const CLOSE_DELETE_APPLICANT_MODAL =
  'app/Messenger/CLOSE_DELETE_APPLICANT_MODAL';
export const OPT_IN_FOR_MESSENGER = 'app/Messenger/OPT_IN_FOR_MESSENGER';
export const OPEN_VIDEO_RECORDING_POPUP =
  'app/Messenger/OPEN_VIDEO_RECORDING_POPUP';
export const THREAD_ASSIGNED_TO_USER = 'app/Messenger/THREAD_ASSIGNED_TO_USER';

export const UPDATE_MOVED_APPLICANT_STAGE =
  'app/Messenger/UPDATE_PROFILE_HEADER_STAGE';

export const APPLICANT_PROFILE_DEFAULT_TAB_INDEX =
  APPLICANT_DRAWER_TABS.MESSAGES;

export const MESSENGER_LOCAL_STORAGE_KEY = 'lastMessengerUrl';
export const DEFAULT_ROWS_PER_PAGE = 10;
export const SENDER_TYPE_APPLICANT = 'applicant';
export const MESSAGE_THREAD_DEFAULT_BUCKET = 'open';
export const DEFAULT_MESSAGE_TYPE = 'allMessages';
export const ASSIGNED_TO_ME_MESSAGE_TYPE = 'assignedToMe';
export const WHATS_APP_MESSAGE_TYPE = 'whatsApp';
export const EMAIL = 'email';

export const TABS = {
  OPEN: 0,
  CLOSE: 1,
};

export const MESSAGE_THREAD_BUCKET: Record<keyof typeof TABS, Bucket> = {
  OPEN: 'open',
  CLOSE: 'closed',
};

export const SORT_LABELS: SortLabel[] = ['Newest', 'Oldest'];
export const DEFAULT_SORT_LABEL: SortDirection = 'newest';

export const BUCKET_LABELS: BucketObject[] = [
  { id: 1, label: 'Open', value: 'open' },
  { id: 2, label: 'Closed', value: 'closed' },
];

export const MESSAGES_TYPES = [
  { id: 1, label: 'All Messages', value: DEFAULT_MESSAGE_TYPE },
  { id: 2, label: 'Assigned to me', value: ASSIGNED_TO_ME_MESSAGE_TYPE },
  { id: 3, label: 'WhatsApp', value: WHATS_APP_MESSAGE_TYPE },
];

export const EMAIL_MESSAGE_TYPE = { id: 4, label: 'Email', value: EMAIL };

export const BUCKETS: Bucket[] = ['open', 'closed'];

export const SORT_DIRECTION: Record<SortDirection, string> = {
  newest: 'desc',
  oldest: 'asc',
};
