import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  // DESKTOP
  container: {
    backgroundColor: theme.palette.common.white,
    borderBottom: theme.customBorders.gray300,
    padding: theme.spacing(0, 3),
    minHeight: 52,
  },
  leftSection: {
    marginLeft: theme.spacing(3),
    height: '100%',
    whiteSpace: 'nowrap',
    '& > :not(:last-child)': {
      marginRight: theme.spacing(3),
    },
    '& > :last-child': {
      marginRight: theme.spacing(4),
    },
    '&.invisible': {
      opacity: 0,
    },
  },
  rightSection: {
    width: 'auto',
    '&.invisible': {
      opacity: 0,
    },
  },
  navIcons: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  // MOBILE
  containerMobile: {
    backgroundColor: theme.palette.common.white,
    borderBottom: theme.customBorders.gray300,
    borderTop: theme.customBorders.gray300,
    padding: theme.spacing(5, 1.5, 1.5),
  },
  fountainLogoMobile: {
    borderRadius: 3,
    height: 32,
    width: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  leftSectionMobile: {
    height: '100%',
    '& > :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  rightSectionMobile: {
    width: 'unset',
  },
}));

export default useStyles;
