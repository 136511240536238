import { VonqProduct } from 'api-clients/monolith';
import produce from 'immer';

import {
  HAPI_LOADED,
  PRODUCT_UPDATE,
  VONQ_CAMPAIGN_FAIL,
  VONQ_CAMPAIGN_POSTING,
  VONQ_CAMPAIGN_PURCHASE,
  VONQ_CAMPAIGN_SUCCESS,
  VONQ_FUNNEL_CHANGE,
  VONQ_RESET,
} from './actions';

export const REDUCER_KEY = 'sourcing';

export interface SourcingState {
  hapiLoaded: boolean;
  products: Array<VonqProduct>;
  campaignId: string | undefined;
  funnelExternalId: string | undefined | null;
  campaignPosting: boolean;
}

export const initialState: SourcingState = {
  hapiLoaded: false,
  products: [],
  campaignId: undefined,
  funnelExternalId: undefined,
  campaignPosting: false,
};
type HapiLoaded = {
  type: typeof HAPI_LOADED;
};

type ProductAction = {
  type: typeof PRODUCT_UPDATE;
  products: Array<VonqProduct>;
};
type FunnelAction = {
  type: typeof VONQ_FUNNEL_CHANGE;
  funnelExternalId: string | null;
};
type PurchaseAction = {
  type: typeof VONQ_CAMPAIGN_PURCHASE;
  campaignId: string;
};
type PurchaseSuccessAction = {
  type: typeof VONQ_CAMPAIGN_SUCCESS;
};
type PurchaseFailAction = {
  type: typeof VONQ_CAMPAIGN_FAIL;
};
type PurchasePostingAction = {
  type: typeof VONQ_CAMPAIGN_POSTING;
};
type ResetAction = {
  type: typeof VONQ_RESET;
};

type Action =
  | HapiLoaded
  | ProductAction
  | PurchaseAction
  | PurchaseSuccessAction
  | PurchaseFailAction
  | PurchasePostingAction
  | ResetAction
  | FunnelAction;
const sourcingReducer = (
  state: SourcingState = initialState,
  action: Action,
): SourcingState =>
  produce(state, draft => {
    switch (action.type) {
      case HAPI_LOADED:
        draft.hapiLoaded = true;
        break;
      case PRODUCT_UPDATE:
        (draft as SourcingState).products = action.products || [];
        (draft as SourcingState).campaignId = undefined;
        break;
      case VONQ_CAMPAIGN_PURCHASE:
        (draft as SourcingState).campaignId = action.campaignId;
        break;
      case VONQ_CAMPAIGN_SUCCESS:
        (draft as SourcingState).campaignId = undefined;
        (draft as SourcingState).products = [];
        (draft as SourcingState).campaignPosting = false;
        break;
      case VONQ_CAMPAIGN_FAIL:
        (draft as SourcingState).campaignPosting = false;
        break;
      case VONQ_CAMPAIGN_POSTING:
        (draft as SourcingState).campaignPosting = true;
        break;
      case VONQ_FUNNEL_CHANGE:
        (draft as SourcingState).funnelExternalId = action.funnelExternalId;
        break;
      case VONQ_RESET:
        (draft as SourcingState).products = [];
        (draft as SourcingState).campaignId = undefined;
        (draft as SourcingState).funnelExternalId = null;
        break;
      // no default
    }
  });

export default sourcingReducer;
