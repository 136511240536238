import { getLocalStorageItem, setLocalStorageItem } from 'utils/storageUtils';

import { MessageTemplatesVariant } from './constants';

const EMAIL_TEMPLATE_KEY = 'recentlyUsedEmailTemplates';
const SMS_TEMPLATE_KEY = 'recentlyUsedSmsTemplates';
const WHATS_APP_TEMPLATE_KEY = 'recentlyUsedWhatsAppTemplates';

/**
 * Get local storage key that matches variant.
 *
 * @param {string} userId state.whoami.external_id
 * @param {string} variant MessageTemplatesVariant.EMAIL | MessageTemplatesVariant.SMS | MessageTemplatesVariant.WHATSAPP
 *
 * @returns {string} key to use for local storage
 */
const getTemplateKey = (userId, variant) => {
  switch (variant) {
    case MessageTemplatesVariant.SMS:
      return `${SMS_TEMPLATE_KEY}::${userId}`;
    case MessageTemplatesVariant.WHATSAPP:
      return `${WHATS_APP_TEMPLATE_KEY}::${userId}`;
    case MessageTemplatesVariant.EMAIL:
    default:
      return `${EMAIL_TEMPLATE_KEY}::${userId}`;
  }
};

/* eslint-disable no-plusplus */
/**
 * Get templates sorted in order of templateIds.
 *
 * @param {array} templateIds
 * @param {array} templates
 *
 * @returns {array} templates sorted in order of templateIds
 */
const getRecentlyUsedTemplatesSorted = (templateIds, templates) => {
  const sorted = [];
  for (let i = 0; i < templateIds.length; i++) {
    for (let j = 0; j < templates.length; j++) {
      if (templateIds[i] === templates[j].id) {
        sorted.push(templates[j]);
        break;
      }
    }
  }

  return sorted;
};
/* eslint-enable no-plusplus */

/**
 * Get templates that match recently used template ids on local storage.
 *
 * @param {array} templates
 * @param {string} userId state.whoami.external_id
 * @param {string} variant MessageTemplatesVariant.EMAIL | MessageTemplatesVariant.SMS | MessageTemplatesVariant.WHATSAPP
 *
 * @returns {array} recently used templates
 */
export const getRecentlyUsedTemplates = (templates, userId, variant) => {
  if (templates.length === 0) {
    return templates;
  }

  const templateIds = getRecentlyUsedTemplateIds(userId, variant);
  const filteredTemplates = templates.filter(template =>
    templateIds.includes(template.id),
  );

  return getRecentlyUsedTemplatesSorted(templateIds, filteredTemplates);
};

/**
 * Get recently used template ids from local storage.
 *
 * @param {string} userId state.whoami.external_id
 * @param {string} variant MessageTemplatesVariant.EMAIL | MessageTemplatesVariant.SMS | MessageTemplatesVariant.WHATSAPP
 *
 * @returns {array} templateIds
 */
export const getRecentlyUsedTemplateIds = (userId, variant) => {
  const templateKey = getTemplateKey(userId, variant);
  return JSON.parse(getLocalStorageItem(templateKey)) || [];
};

/**
 * Ensure stored ids have no deprecated keys
 *
 * @param {func} setLocalStorageCallback
 * @param {array} templateIds
 * @param {array} templateIds
 *
 * @returns {array} updated templateIds
 */
const handleDeprecatedKeys = (
  setLocalStorageCallback,
  templateIds,
  templates,
) => {
  const filteredTemplateIds = templates
    .filter(({ id }) => templateIds.includes(id))
    .map(({ id }) => id);

  if (filteredTemplateIds.length < templateIds.length) {
    const updatedTemplateIds = templateIds.filter(id =>
      filteredTemplateIds.includes(id),
    );
    return setLocalStorageCallback(updatedTemplateIds);
  }

  return templateIds;
};

/**
 * Ensure stored ids have no duplicate keys
 *
 * @param {func} setLocalStorageCallback
 * @param {string|number} templateId
 * @param {array} templateIds
 *
 * @returns {array} updated templateIds
 */
const handleDuplicateKeys = (
  setLocalStorageCallback,
  templateId,
  templateIds,
) => {
  let filteredTemplateIds = templateIds;

  const index = templateIds.findIndex(id => id === templateId);
  if (index > -1) {
    filteredTemplateIds = templateIds.filter(id => id !== templateId);
  }

  const updatedTemplateIds = [templateId, ...filteredTemplateIds.slice(0, 2)];
  return setLocalStorageCallback(updatedTemplateIds);
};

/**
 * Update recently used template ids on local storage. Uses simple LRU eviction strategy.
 *
 * @param {object} template
 * @param {string} userId state.whoami.external_id
 * @param {string} variant MessageTemplatesVariant.EMAIL | MessageTemplatesVariant.SMS | MessageTemplatesVariant.WHATSAPP
 *
 * @returns {void}
 */
export const updateRecentlyUsedTemplateIds = (
  template,
  templates,
  userId,
  variant,
) => {
  const callback = key => value => {
    setLocalStorageItem(key, JSON.stringify(value));
    return JSON.parse(getLocalStorageItem(key)) || [];
  };

  const templateKey = getTemplateKey(userId, variant);
  let templateIds = JSON.parse(getLocalStorageItem(templateKey)) || [];
  if (templateIds) {
    templateIds = handleDeprecatedKeys(
      callback(templateKey),
      templateIds,
      templates,
    );
    templateIds = handleDuplicateKeys(
      callback(templateKey),
      template.id,
      templateIds,
    );
  }

  if (!templateIds) {
    callback(templateKey)([template.id]);
  }
};

/**
 * Sort templates alphabetically by name
 *
 * @param {object} a template
 * @param {object} b template
 *
 * @returns {number}
 */
export const sortTemplatesAlphabetically = (a, b) => {
  if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) {
    return -1;
  }

  if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) {
    return 1;
  }

  return 0;
};
