import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  advancedOptions: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.JobMatcherStages.AdvancedOptions.advancedOptions',
    defaultMessage: 'Advanced Options',
  },
  allowApplicantsToAdvance: {
    id: `app.containers.WorkflowEditor.components.StageDetail.JobMatcherStages.AdvancedOptions.AdvancedSettings.allowApplicantsToAdvance`,
    defaultMessage:
      'Allow applicants to self advance by using the merge key [ADVANCE_URL]',
  },
  toggleDistanceLimit: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.JobMatcherStages.AdvancedOptions.toggleDistanceLimit',
    defaultMessage:
      "Only show active openings within a specified radius of the applicant's zip code",
  },
  distanceLimitSelect: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.JobMatcherStages.AdvancedOptions.distanceLimitSelect',
    defaultMessage:
      "Select maximum distance in miles from the applican's zip code",
  },
  numberOfMiles: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.JobMatcherStages.AdvancedOptions.numberOfMiles',
    defaultMessage: '{miles} miles',
  },
});
