import { ExpansionPanelSummary } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const AccordionSummary = withStyles(theme => ({
  root: {
    minHeight: 40,
    borderRadius: 5,
    padding: theme.spacing(1.25, 1.25, 1.25, 3.5),
    '&:hover': {
      background: '#131E401F',
    },
    '&:active': {
      background: '#131E4052',
    },

    '&::before': {
      content: "'>'",
      color: theme.palette.common.gray600,
      position: 'absolute',
      width: 4.75,
      height: 8,
      left: 12,
      top: '30%',
    },

    '&$expanded': {
      background: theme.palette.common.gray100,
      '&:hover': {
        background: '#131E401F',
      },
      minHeight: 40,
      '&::before': {
        position: 'absolute',
        width: 4.75,
        height: 8,
        left: 17,
        top: '40%',
        transform: 'rotate(90deg)',
      },
    },
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
      '& > *:first-child': {
        fontWeight: 400,
      },
    },
  },
  expanded: {},
}))(ExpansionPanelSummary);

export default AccordionSummary;
