import { makeStyles } from '@material-ui/core/styles';
import { ApprovalObj, OpeningApprovalsService } from 'api-clients/monolith';
import { useApiService, useSimpleToggle } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';

import { Pagination } from 'components/Pagination';
import SearchInput from 'components/SearchInput';
import { useQueryState } from 'hooks/useQueryState';

import { ApprovalsModal } from './ApprovalsModal';
import { ApprovalsTable } from './ApprovalsTable';
import { BulkAction } from './BulkAction';
import { Filters } from './Filters';

import type { ApprovalSort } from './ApprovalsTable';

export const filterOptions = ['Any Status', 'Approved', 'Pending', 'Rejected'];

const useStyles = makeStyles(theme => ({
  approvalContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '42px',
    marginBottom: theme.spacing(1),
  },
}));

export const Approvals = () => {
  const styles = useStyles();

  const [approvals, setApprovals] = useState<ApprovalObj[]>([]);
  const [selectedApprovals, setSelectedApprovals] = useState<string[]>([]);
  const [selectedApproval, setSelectedApproval] = useState('');
  const [action, setAction] = useState('');
  const [searchOpeningTitle, setSearchOpeningTitle] = useState('');
  const [sort, setSort] = useState<ApprovalSort>({
    key: 'opening',
    value: 'asc',
  });

  const [openingTitle, setOpeningTitle] = useQueryState('title', '');
  const [openingId, setOpeningId] = useQueryState('openingId', '');
  const [openingAction, setOpeningAction] = useQueryState('action');
  const [assignedToMe, setAssignedToMe] = useQueryState(
    'assignedToMe',
    openingId ? 'false' : 'true',
  );
  const [status, setStatus] = useQueryState('status', 'Pending');
  const [page, setPage] = useQueryState('page', 1);
  const [perPage, setPerPage] = useQueryState('perPage', 10);

  const [totalRows, setTotalRows] = useState(0);
  const lastPage = Math.ceil(totalRows / parseInt(perPage, 10));

  const {
    showContent: isCustomizeStageModalOpen,
    on: openCustomizeStageModal,
    off: closeCustomizeStageModal,
  } = useSimpleToggle();

  const { result, refetch: refreshApprovalsData } = useApiService(
    useCallback(
      () =>
        OpeningApprovalsService.getInternalApiOpeningApprovalApprovals(
          status,
          searchOpeningTitle,
          parseInt(page, 10),
          parseInt(perPage, 10),
          openingId,
          assignedToMe === 'true',
          sort.key,
          sort.value,
        ),
      [
        status,
        searchOpeningTitle,
        page,
        perPage,
        openingId,
        assignedToMe,
        sort,
      ],
    ),
  );

  const handleAction = useCallback(
    (approvalId: string, action: string) => {
      setSelectedApprovals([]);
      setSelectedApproval(approvalId);
      setAction(action);
      openCustomizeStageModal();
    },
    [openCustomizeStageModal],
  );

  const handleBulkAction = (action: string) => {
    setSelectedApproval('');
    setAction(action);
    openCustomizeStageModal();
  };

  const handleStatusChange = (status: string) => {
    setStatus(status.trim());
    if (openingId) {
      setOpeningId(null);
      setOpeningTitle(null);
    }
    refreshApprovalsData();
  };

  useEffect(() => {
    if (result.status === 'ready') {
      setTotalRows(result.data.pagination.total_count ?? 0);
      setApprovals(result.data.opening_approvals);
      if (openingId) {
        const approvalRecord: ApprovalObj = result.data.opening_approvals[0];
        setOpeningTitle(approvalRecord.title);
        if (
          openingAction &&
          approvalRecord.manage_access &&
          approvalRecord.status === 'pending'
        )
          handleAction(approvalRecord.id, openingAction);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const clearSearchText = () => {
    setOpeningId(null);
    setOpeningTitle(null);
    setSearchOpeningTitle('');
    setOpeningAction(null);
  };

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOpeningId(null);
    setOpeningTitle(e.target.value);
  };

  const filterApprovals = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || (e.target as HTMLInputElement).value === '')
      setSearchOpeningTitle(openingTitle);
  };

  const toggleAssignedToMe = () => {
    setAssignedToMe(assignedToMe === 'true' ? 'false' : 'true');
    if (openingId) {
      setOpeningId(null);
      setOpeningTitle(null);
    }
  };

  return (
    <>
      <div className={styles.approvalContainer}>
        <SearchInput
          /* @ts-expect-error this will go away when `SearchInput` is converted to typescript */
          value={openingTitle}
          onChange={onSearchInputChange}
          onClear={clearSearchText}
          placeholder="Search openings"
          onKeyUp={filterApprovals}
        />

        <div className={styles.actionsWrapper}>
          <Filters
            status={status}
            assignedToMe={assignedToMe === 'true'}
            toggleAssignedToMe={toggleAssignedToMe}
            handleStatusChange={handleStatusChange}
          />

          {selectedApprovals.length > 0 && (
            <BulkAction
              count={selectedApprovals.length}
              handleBulkAction={handleBulkAction}
            />
          )}
        </div>
      </div>

      <ApprovalsTable
        approvalsResult={result}
        approvals={approvals}
        selectedApprovals={selectedApprovals}
        setSelectedApprovals={setSelectedApprovals}
        sort={sort}
        setSort={setSort}
        handleAction={handleAction}
      />

      {result.status === 'ready' && totalRows > parseInt(perPage, 10) && (
        <Pagination
          perPage={parseInt(perPage, 10)}
          currentPage={parseInt(page, 10)}
          lastPage={lastPage}
          setPage={setPage}
          setPerPage={setPerPage}
        />
      )}

      {isCustomizeStageModalOpen && (
        <ApprovalsModal
          selectedApprovals={[...selectedApprovals, ...[selectedApproval]]}
          setSelectedApprovals={setSelectedApprovals}
          setSelectedApproval={setSelectedApproval}
          status={action}
          refreshApprovalsData={refreshApprovalsData}
          isOpen={isCustomizeStageModalOpen}
          onClose={closeCustomizeStageModal}
        />
      )}
    </>
  );
};
