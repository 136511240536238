const styles = theme => ({
  listItem: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: 6,
  },
  avatar: {
    width: '50px',
    height: '50px',
  },
  smallAvatar: {
    width: '35px',
    height: '35px',
    marginRight: theme.spacing(1.25),
  },
  menuRoot: {
    minWidth: 220,
  },
  menuItem: {
    height: 56,
    padding: `0 ${theme.spacing(2)}px`,
    borderRadius: 6,
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
    '& li': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  nav: {
    paddingTop: 0,
    zIndex: theme.zIndex.modal + 1,
    paddingBottom: 0,
  },
  navLink: {
    textDecoration: 'none',
  },
  name: {
    fontSize: theme.typography.fontSize,
    color: theme.palette.common.black,
  },
  listItemText: {
    paddingLeft: theme.spacing(1),
  },
  icon: {
    marginRight: 0,
  },
});

export default styles;
