import { Grid } from '@material-ui/core';
import {
  WorkflowAccurateStage,
  WorkflowBackgroundCheckerStage,
  WorkflowSterlingStage,
} from 'api-clients/monolith';
import produce from 'immer';
import React, { useContext, VFC } from 'react';
import { useIntl } from 'react-intl';

import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { StageSettingCard } from '../../../../StageSettingCard';
import { SettingSwitch } from '../../../SettingSwitch';
import {
  NOTIFY_APPLICANT_ON_REPORT_CREATED,
  NOTIFY_ON_REPORT_CLEAR,
  NOTIFY_ON_REPORT_CONSIDER,
  NOTIFY_ON_REPORT_CREATED,
  NOTIFY_ON_REPORT_SUSPENDED,
} from './constants';
import { messages } from './messages';
import { useStyles } from './styles';

type DataKeyTypes =
  | typeof NOTIFY_APPLICANT_ON_REPORT_CREATED
  | typeof NOTIFY_ON_REPORT_CLEAR
  | typeof NOTIFY_ON_REPORT_CONSIDER
  | typeof NOTIFY_ON_REPORT_CREATED
  | typeof NOTIFY_ON_REPORT_SUSPENDED;

export interface NotificationsCardProps {
  stage:
    | WorkflowAccurateStage
    | WorkflowBackgroundCheckerStage
    | WorkflowSterlingStage;
}

export const NotificationsCard: VFC<NotificationsCardProps> = ({ stage }) => {
  const styles = useStyles();
  const intl = useIntl();

  const { setStage } = useContext(StageContext);

  const onToggleChange = (key: DataKeyTypes) => () => {
    setStage(
      produce(stage, draftStage => {
        draftStage.extra[key] = !draftStage.extra[key];
      }),
    );
  };

  const labelsByDataKey: Record<DataKeyTypes, string> = {
    [NOTIFY_APPLICANT_ON_REPORT_CREATED]: intl.formatMessage(
      messages.notifyApplicantOnReportCreated,
    ),
    [NOTIFY_ON_REPORT_CREATED]: intl.formatMessage(
      messages.notifyOnReportCreated,
    ),
    [NOTIFY_ON_REPORT_CLEAR]: intl.formatMessage(messages.notifyOnReportClear),
    [NOTIFY_ON_REPORT_CONSIDER]: intl.formatMessage(
      messages.notifyOnReportConsider,
    ),
    [NOTIFY_ON_REPORT_SUSPENDED]: intl.formatMessage(
      messages.notifyOnReportSuspended,
    ),
  };

  return (
    <StageSettingCard
      variant="default"
      title={intl.formatMessage(messages.notifications)}
    >
      <Grid className={styles.cardBody}>
        {Object.keys(labelsByDataKey).map((dataKey: string) => {
          const key = dataKey as keyof typeof labelsByDataKey;

          return (
            <SettingSwitch
              key={key}
              dataKey={key}
              checked={!!stage.extra[key]}
              onChange={onToggleChange(key)}
              label={labelsByDataKey[key]}
            />
          );
        })}
      </Grid>
    </StageSettingCard>
  );
};
