import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { FC } from 'react';

export const CopyIcon: FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path d="M4 0H14C14.6 0 15 0.4 15 1V13H13V2H4V0Z" />
    <path d="M2 4H10C10.6 4 11 4.4 11 5V15C11 15.6 10.6 16 10 16H2C1.4 16 1 15.6 1 15V5C1 4.4 1.4 4 2 4Z" />
  </SvgIcon>
);
