import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme>(theme => ({
  confirmedInputValue: {
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  ctaIcon: {
    '&:last-child': {
      marginRight: 0,
    },
    display: 'flex',
    marginRight: theme.spacing(3.5),
  },
  ctaIconsContainer: {
    marginLeft: theme.spacing(2.25),
  },
  edit: {
    marginLeft: theme.spacing(1),
  },
  inputEditorContainer: {
    display: 'flex',
    width: '100%',
    minWidth: 0,
    marginRight: theme.spacing(3),
  },
  iconButton: {
    fontSize: theme.typography.button.fontSize,
  },
  savedInputValue: {
    color: theme.palette.common.gray800,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  inputText: {
    minWidth: 0,
  },
}));

export default useStyles;
