import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  actions: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BackgroundCheckStage.ActionsCard.actions`,
    defaultMessage: 'Actions',
  },
  applicantMoveRule: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BackgroundCheckStage.ActionsCard.applicantMoveRule`,
    defaultMessage: 'Applicant move rule',
  },
  customStage: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BackgroundCheckStage.ActionsCard.customStage`,
    defaultMessage: 'Custom stage',
  },
  keepApplicantInThisStage: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BackgroundCheckStage.ActionsCard.keepApplicantInThisStage`,
    defaultMessage: 'Keep the applicant in this stage (recommended)',
  },
  runCheckAfterSubmitted: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BackgroundCheckStage.ActionsCard.runCheckAfterSubmitted`,
    defaultMessage:
      'Run Background Check after consent has been submitted (recommended)',
  },
  selectWhatHappensAfterConsentSubmitted: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BackgroundCheckStage.ActionsCard.selectWhatHappensAfterConsentSubmitted`,
    defaultMessage:
      'Select what happens after applicant consent has been submitted',
  },
  moveFromCustomStageIfClearOrConsider: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BackgroundCheckStage.ActionsCard.moveFromCustomStageIfClearOrConsider`,
    defaultMessage:
      'Move applicants FROM a selected custom stage if their background check results are Clear or Consider (Engaged)',
  },
  advanceWhenCheckRun: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BackgroundCheckStage.ActionsCard.advanceWhenCheckRun`,
    defaultMessage: 'Advance when Background Check has run',
  },
  advanceRegardlessOfStatus: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BackgroundCheckStage.ActionsCard.advanceRegardlessOfStatus`,
    defaultMessage:
      'Advance when Background Check has run regardless of the final status',
  },
  advanceWhenStatusClearConsiderSuspended: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BackgroundCheckStage.ActionsCard.advanceWhenStatusClearConsiderSuspended`,
    defaultMessage:
      'Advance when Background Check has run and status is Clear, Consider, or Suspended',
  },
  advanceWhenClearConsider: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BackgroundCheckStage.ActionsCard.advanceWhenClearConsider`,
    defaultMessage:
      'Advance when Background Check has run and status is Clear or Consider (Engaged)',
  },
  advanceWhenClearConsiderPostAdverse: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BackgroundCheckStage.ActionsCard.advanceWhenClearConsiderPostAdverse`,
    defaultMessage:
      'Advance when Background Check has run and status is Clear, Consider (Engaged), or Consider (Post Adverse Action)',
  },
  selectCustomStage: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BackgroundCheckStage.ActionsCard.selectCustomStage`,
    defaultMessage: 'Select a custom stage',
  },
  onceApplicantConsentsRunBackgroundCheck: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BackgroundCheckStage.ActionsCard.onceApplicantConsentsRunBackgroundCheck`,
    defaultMessage:
      'Once applicant consents, Checkr will run Background Check and:',
  },
  selectActionOnceApplicantConsents: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BackgroundCheckStage.ActionsCard.selectActionOnceApplicantConsents`,
    defaultMessage: 'Select what action to take once the applicant consents.',
  },
  doNotRunCheckAfterConsentSubmitted: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BackgroundCheckStage.ActionsCard.doNotRunCheckAfterConsentSubmitted`,
    defaultMessage:
      'Do not run Background Check after consent has been submitted',
  },
  advanceWhenApplicantSubmittedConsent: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BackgroundCheckStage.ActionsCard.advanceWhenApplicantSubmittedConsent`,
    defaultMessage: 'Advance when applicant has submitted the consent form',
  },
});
