import { objectToFormData } from 'object-to-formdata';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import {
  addDefaultErrorMessageAction,
  addMessageAction,
} from 'containers/FlashMessage/actions';
import { apiPost } from 'utils/axios';

export default function useCreateCcpaRequest() {
  const dispatch = useDispatch();

  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleCreateCcpaRequest = async params => {
    const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/ccpa_webforms`;

    // Allows us to send a File in the nested data structure
    const formData = objectToFormData(params);

    setIsLoading(true);
    try {
      const res = await apiPost(url, formData);

      if (res.data) {
        dispatch(addMessageAction('Created CCPA Request!', 'success'));
        setSuccess(true);
        setError('');
      }
    } catch (e) {
      dispatch(addDefaultErrorMessageAction());
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  return { handleCreateCcpaRequest, success, isLoading, error };
}
