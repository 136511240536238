import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import { addDefaultErrorMessageAction } from 'containers/FlashMessage/actions';
import { apiGet } from 'utils/axios';

export default function useFetchOpenings() {
  const dispatch = useDispatch();
  const [openings, setOpenings] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);

  const fetchOpenings = useCallback(
    async params => {
      const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/openings`;

      setIsFetching(true);
      try {
        const { data } = await apiGet(url, params);
        const camelizedPagination = {
          currentPage: data.pagination.current_page,
          nextPage: data.pagination.next_page,
          totalCount: data.pagination.total_count,
          totalPages: data.pagination.total_pages,
        };
        setOpenings(data.openings);
        setPagination(camelizedPagination);
        setError(null);
      } catch (e) {
        setError(e);
        dispatch(addDefaultErrorMessageAction());
      } finally {
        setIsFetching(false);
      }
    },
    [dispatch],
  );

  return { fetchOpenings, isFetching, error, openings, pagination };
}
