import { Grid } from '@material-ui/core';
import { WorkflowMultiStage } from 'api-clients/monolith';
import produce from 'immer';
import React, { useContext, VFC } from 'react';
import { useIntl } from 'react-intl';

import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { StageSettingCard } from '../../../../StageSettingCard';
import { SettingSwitch } from '../../../SettingSwitch';
import { AutoAdvanceWhenLabelsComplete } from '../../SettingsSwitches';
import {
  IS_HIDDEN_FROM_PORTAL,
  SHOULD_AUTO_ADVANCE_WHEN_LABELS_COMPLETE,
} from './constants';
import { messages } from './messages';
import { useStyles } from './styles';

export interface AdvancedSettingsCardProps {
  stage: WorkflowMultiStage;
}

export const AdvancedSettingsCard: VFC<AdvancedSettingsCardProps> = ({
  stage,
}) => {
  const styles = useStyles();
  const intl = useIntl();

  const { setStage } = useContext(StageContext);

  const {
    advanced_settings: advancedSettings,
    extra: { auto_advance_on_completion: autoAdvanceOnCompletion },
  } = stage;
  const hasStageLabels = Boolean(stage.has_stage_labels);

  const onToggleIsHiddenFromPortal = () => {
    setStage(
      produce(stage, draftStage => {
        draftStage.advanced_settings.is_hidden_from_portal =
          !draftStage.advanced_settings.is_hidden_from_portal;
      }),
    );
  };

  const onToggleChangeAutoAdvance = () => {
    setStage(
      produce(stage, draftStage => {
        draftStage.extra.auto_advance_on_completion =
          !draftStage.extra.auto_advance_on_completion;
      }),
    );
  };

  return (
    <StageSettingCard
      variant="default"
      title={intl.formatMessage(messages.settings)}
    >
      <Grid className={styles.cardBody}>
        <Grid item xs={12} className={styles.fieldRow}>
          <SettingSwitch
            dataKey={IS_HIDDEN_FROM_PORTAL}
            checked={!advancedSettings.is_hidden_from_portal}
            onChange={onToggleIsHiddenFromPortal}
            label={intl.formatMessage(messages.showStageInApplicantPortal)}
          />
          <SettingSwitch
            dataKey={SHOULD_AUTO_ADVANCE_WHEN_LABELS_COMPLETE}
            checked={!!autoAdvanceOnCompletion}
            onChange={onToggleChangeAutoAdvance}
            label={intl.formatMessage(
              messages.automaticallyMoveApplicantToNextStage,
            )}
          />
          {hasStageLabels && (
            <AutoAdvanceWhenLabelsComplete stage={stage} setStage={setStage} />
          )}
        </Grid>
      </Grid>
    </StageSettingCard>
  );
};
