import React, { FC } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import messages from '../messages';

export interface SvgProps {
  description?: MessageDescriptor;
  title?: MessageDescriptor;
}

export const PenSvg: FC<SvgProps> = ({ description, title }) => {
  const iconTitle = title ?? messages.editIconTitle;
  const iconDescription = description ?? messages.editIconDescription;
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="pen-svg-title pen-svg-description"
      role="img"
    >
      {/* an empty title element should prevent the default tooltip from the other title element */}
      <title />
      <title id="pen-svg-title">
        <FormattedMessage {...iconTitle} />
      </title>
      <desc id="pen-svg-description">
        <FormattedMessage {...iconDescription} />
      </desc>
      <path
        d="M10.121 0.29297C9.93347 0.105499 9.67916 0.000183105 9.414 0.000183105C9.14884 0.000183105 8.89453 0.105499 8.707 0.29297L1 7.99997L0 12L4 11L11.707 3.29297C11.8945 3.10544 11.9998 2.85113 11.9998 2.58597C11.9998 2.32081 11.8945 2.0665 11.707 1.87897L10.121 0.29297Z"
        fill="#969EB3"
      />
    </svg>
  );
};

export default PenSvg;
