import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'app.containers.AIWorkflowBuilder.title',
    defaultMessage: 'AI WorkFlow Builder',
  },
  applicantPreviewWorkflow: {
    id: 'app.containers.AIWorkflowBuilder.applicantPreviewWorkflow',
    defaultMessage: 'Applicant Preview of Workflow',
  },
  editWorkflow: {
    id: 'app.containers.AIWorkflowBuilder.editWorkflow',
    defaultMessage: 'Edit Workflow',
  },
  testApplicant: {
    id: 'app.containers.AIWorkflowBuilder.testApplicant',
    defaultMessage: 'Test as Applicant',
  },
  cannotEditExistingWorkflows: {
    id: 'app.containers.AIWorkflowBuilder.existingWorkflows',
    defaultMessage:
      'We are sorry, but the AI Assistant cannot currently be used with existing workflows.',
  },
});
