import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  notConnectedYetTitle: {
    id: 'app.Calendar.NotConnectedYet.title',
    defaultMessage: 'Lets connect your work calendar',
  },
  notConnectedYetDescription: {
    id: 'app.Calendar.NotConnectedYet.description',
    defaultMessage:
      'We’ve made it easier to sync your availability with your work calendar. Unlock powerful scheduling features:',
  },
  startConnectingCalendar: {
    id: 'app.Calendar.startConnectingCalendar.title',
    defaultMessage: 'Start',
  },
  calendarIntegrationName: {
    id: 'app.Calendar.CalendarSync.name',
    defaultMessage: 'Calendar',
  },
  calendarIntegrationTitle: {
    id: 'app.Calendar.CalendarSync.title',
    defaultMessage: 'Calendar connected',
  },
  timezone: {
    id: 'app.Calendar.AvailabilityRules.timezone',
    defaultMessage: 'Timezone',
  },
  availabilityTitle: {
    id: 'app.Calendar.AvailabilityRules.title',
    defaultMessage: 'Weekly recurring availability',
  },
  availabilityDescription: {
    id: 'app.Calendar.AvailabilityRules.desciption',
    defaultMessage:
      'Applicants will only be able to book sessions within your available time. Your availability repeats every week.',
  },
  calendarSyncSideboxTitle: {
    id: 'app.Calendar.CalendarSyncSidebox.title',
    defaultMessage: 'Setup your calendar integration',
  },
  calendarSyncSideboxDescription: {
    id: 'app.Calendar.CalendarSyncSidebox.description',
    defaultMessage:
      'Connect your work calendar to access more powerful scheduling features that include:',
  },
  calendarIntegrationBullet1: {
    id: 'app.Calendar.CalendarSyncSidebox.bullet1',
    defaultMessage: 'View booked events from your work calendar',
  },
  calendarIntegrationButton: {
    id: 'app.Calendar.CalendarSyncSidebox.button',
    defaultMessage: 'Connect calendar',
  },
  calendarIntegrationBullet2: {
    id: 'app.Calendar.CalendarSyncSidebox.bullet2',
    defaultMessage:
      'Automatically schedule applicant events around your work events',
  },
  editButton: {
    id: `app.Calendar.CalendarSync.edit_button`,
    defaultMessage: 'Edit',
  },
  calendar: {
    id: 'app.Calendar.calendar',
    defaultMessage: 'Calendar',
  },
  schedule: {
    id: 'app.Calendar.schedule',
    defaultMessage: 'Schedule',
  },
  scheduleDescription: {
    id: 'app.Calendar.scheduleDescription',
    defaultMessage:
      'Set your availability for when you want your sessions to take place.',
  },
  unsavedAvailability: {
    id: 'app.Calendar.unsavedAvailability',
    defaultMessage:
      'You have unsaved availability, are you sure you want to leave?',
  },
  errorText: {
    id: 'app.Calendar.errorText',
    defaultMessage:
      'We detected an error. You can refresh this page or try linking your calendar. If the error persists, reach out to your admin.',
  },
  availabilityTab: {
    id: 'app.Calendar.ScheduleSideBar.availability',
    defaultMessage: 'Availability',
  },
  calendarIntegrationTab: {
    id: 'app.Calendar.ScheduleSideBar.calendarIntegration',
    defaultMessage: 'Calendar Integration',
  },
  calendarIntegrationDetail: {
    id: 'app.Calendar.ScheduleSideBar.calendarIntegrationDetail',
    defaultMessage:
      'Connecting your calendar will schedule your availability around your calendar events',
  },
  scheduledSessions: {
    id: 'app.Calendar.ScheduleSideBar.scheduledSessions',
    defaultMessage: 'Scheduled Sessions',
  },
  eventManagerTab: {
    id: 'app.Calendar.ScheduleSideBar.eventManagerTab',
    defaultMessage: 'Scheduled Sessions',
  },
  searchOpenings: {
    id: 'app.Calendar.OpeningSearchModal.searchOpenings',
    defaultMessage: 'Search Openings',
  },
});
