import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  errorsPresent: {
    id: `app.containers.WorkflowEditor.components.StageDetail.errorsPresent`,
    defaultMessage:
      'Save could not be performed due to unresolved issues. Please address the highlighted errors below.',
  },
  unsupportedStage: {
    id: `app.containers.WorkflowEditor.components.StageDetail.unsupportedStage`,
    defaultMessage:
      'This {stageType} is no longer supported in the Workflow Editor. Current applicants will continue to move through this stage undisrupted. To make configuration changes, please delete this stage and create a new one from the available options.',
  },
  stageComingSoon: {
    id: `app.containers.WorkflowEditor.components.StageDetail.stageComingSoon`,
    defaultMessage:
      'This {stageType} is not yet supported in the Workflow Editor. Current applicants will continue to move through this stage undisrupted.',
  },
});
