import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
export default function validate(values) {
  const { smsText, whatsappText, whatsappEnabled } = values;

  const errors = {};

  // After typing into the React-Quill editor, it will always have <p><br></p>
  // even after deleting all the text so that's why this check was added
  if (!whatsappEnabled && (!smsText || smsText === '<p><br></p>')) {
    errors.smsText = <FormattedMessage {...messages.smsTextRequired} />;
  }

  // After typing into the React-Quill editor, it will always have <p><br></p>
  // even after deleting all the text so that's why this check was added
  if (
    whatsappEnabled &&
    (!whatsappText || whatsappText === '<p><br></p>') &&
    (!smsText || smsText === '<p><br></p>')
  ) {
    errors.whatsappText = (
      <FormattedMessage {...messages.whatsappTemplateRequired} />
    );
    errors.smsText = <FormattedMessage {...messages.smsTextRequired} />;
  }

  return errors;
}
