import invariant from 'invariant';
import React, { VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { messages } from './messages';

export interface DeleteEventModalProps {
  deleteEvent: (eventExternalId: string) => void;
  onCancel: () => void;
  eventExternalId?: string;
  isLoading: boolean;
}

export const DeleteEventModal: VFC<DeleteEventModalProps> = ({
  deleteEvent,
  onCancel,
  eventExternalId,
  isLoading,
}) => {
  const intl = useIntl();

  invariant(eventExternalId, 'Event External Id must be present');
  return (
    <ConfirmationModal
      negative
      ariaLabelledBy={intl.formatMessage(messages.deleteEvent)}
      onCancel={onCancel}
      onConfirm={() => deleteEvent(eventExternalId)}
      title={intl.formatMessage(messages.deleteEvent)}
      bodyContent={<FormattedMessage {...messages.modalBody} />}
      cancelButtonContent={<FormattedMessage {...messages.cancel} />}
      confirmButtonContent={<FormattedMessage {...messages.deleteEvent} />}
      blurOverlay={false}
      isLoading={isLoading}
      disabled={isLoading}
    />
  );
};
