import { IconButton } from '@fountain/fountain-ui-components';
import { Button, Grid, Typography } from '@material-ui/core';
import { WorkflowTechCheckStage } from 'api-clients/monolith';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';
import { PlusIcon } from 'images/PlusIcon';

import {
  DEFAULT_BASELINE_REQUIREMENT,
  getDefaultRequirement,
} from './constants';
import { messages } from './messages';
import { RequirementCard } from './RequirementCard';
import { useStyles } from './styles';

export interface RequirementsProps {
  stage: WorkflowTechCheckStage;
}

export const Requirements: FC<RequirementsProps> = ({ stage }) => {
  const intl = useIntl();
  const styles = useStyles();

  const { setStage } = useContext(StageContext);
  const settings = stage.additional_info?.settings ?? [];
  // eslint-disable-next-line no-underscore-dangle
  const filteredSettings = settings.filter(setting => !setting._destroy);
  const baseLineSetting = filteredSettings.find(setting => setting.is_base_set);
  // Only 3 Tiers (including Baseline) may be defined
  const canAddTiers = filteredSettings.length < 3;

  const onAddRequirementTier = () => {
    setStage(
      produce(stage, draftStage => {
        const settings = draftStage.additional_info?.settings ?? [];
        if (canAddTiers) {
          if (settings.some(setting => setting.is_base_set)) {
            settings.push(getDefaultRequirement(filteredSettings.length));
          } else {
            // Should be removed when BE and DB require a baseline tier present
            settings.push(DEFAULT_BASELINE_REQUIREMENT);
          }
        }
      }),
    );
  };

  return (
    <Grid>
      {baseLineSetting && (
        <Grid
          key={baseLineSetting.title}
          className={styles.requirementContainer}
        >
          <RequirementCard
            key={baseLineSetting.title}
            setting={baseLineSetting}
            setStage={setStage}
          />
        </Grid>
      )}
      {filteredSettings
        .filter(setting => !setting.is_base_set)
        .map(setting => (
          <Grid key={setting.id} className={styles.requirementContainer}>
            <RequirementCard
              key={setting.id}
              setting={setting}
              setStage={setStage}
            />
          </Grid>
        ))}

      {canAddTiers && (
        <Grid container justify="center" className={styles.addTierContainer}>
          <Button
            aria-label={intl.formatMessage(messages.addTier)}
            onClick={onAddRequirementTier}
            disableRipple
          >
            <IconButton
              size="small"
              primary
              className={styles.plusButton}
              component={Grid}
            >
              <PlusIcon color="inherit" fontSize="inherit" />
            </IconButton>
            <Typography variant="h4" color="primary" className={styles.addTier}>
              {intl.formatMessage(messages.addTier)}
            </Typography>
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
