import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import { addNoteAction } from 'containers/ApplicantTableV2/actions';
import {
  addDefaultErrorMessageAction,
  addMessageAction,
} from 'containers/FlashMessage/actions';
import { apiPost } from 'utils/axios';

export default function useApplicantNewNote(
  applicantId,
  content,
  successCallback,
) {
  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const addNote = async successMessage => {
    setIsLoading(true);
    try {
      if (content) {
        const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/notes`;
        const res = await apiPost(url, { note: { content } });
        if (res.data) {
          dispatch(addNoteAction(applicantId, res.data.note));
          dispatch(addMessageAction(successMessage, 'success'));
          successCallback();
          setError(null);
        }
      }
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error) {
      dispatch(addDefaultErrorMessageAction());
    }
  }, [dispatch, error]);

  return [{ isLoading }, addNote];
}
