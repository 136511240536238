import React from 'react';

const CopySvg = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="copy"
    role="img"
  >
    <path
      d="M9 1C9 0.734784 8.89464 0.48043 8.70711 0.292893C8.51957 0.105357 8.26522 0 8 0L1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1L0 9C0 9.26522 0.105357 9.51957 0.292893 9.70711C0.48043 9.89464 0.734784 10 1 10H4.5V4.5C4.5 4.23478 4.60536 3.98043 4.79289 3.79289C4.98043 3.60536 5.23478 3.5 5.5 3.5H9V1Z"
      fill="#969eb3"
    />
    <path
      d="M11.5 5H6.5C6.22386 5 6 5.22386 6 5.5V11.5C6 11.7761 6.22386 12 6.5 12H11.5C11.7761 12 12 11.7761 12 11.5V5.5C12 5.22386 11.7761 5 11.5 5Z"
      fill="#969eb3"
    />
  </svg>
);

export default CopySvg;
