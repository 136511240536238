import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  address: {
    width: '400px',
  },
  addEmployerInfoButton: {
    width: 'inherit',
  },
  businessName: {
    width: '340px',
  },
  city: {
    width: '200px',
  },
  firstName: {
    width: '260px',
  },
  lastName: {
    width: '260px',
  },
  state: {
    minWidth: '200px',
  },
  title: {
    width: '140px',
  },
  trashcanIcon: {
    fontSize: theme.spacing(1.5),
  },
  zipcode: {
    width: '120px',
  },
  employerInfoHelperText: {
    color: theme.palette.common.gray800,
    margin: theme.spacing(1.5, 0, 1, 1.5),
  },
}));
