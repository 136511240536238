import { IntlShape } from 'react-intl';

import {
  ADD_MESSAGE,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_VARIANT,
  REMOVE_MESSAGE,
} from './constants';
import messages from './messages';

export type Variant = 'error' | 'warning' | 'info' | 'success';

let messageId = 0;

export function addMessageAction(
  text: string,
  variant: Variant = DEFAULT_VARIANT,
  autoClose = true,
) {
  messageId += 1;
  return {
    type: ADD_MESSAGE,
    text,
    variant,
    autoClose,
    id: messageId,
  };
}

export function removeMessageAction(id: number) {
  return {
    type: REMOVE_MESSAGE,
    id,
  };
}

/**
 * Prefer the i18n-ready `addDefaultErrorMessageActionI18n` instead
 * @deprecated
 */

export function addDefaultErrorMessageAction() {
  return addMessageAction(DEFAULT_ERROR_MESSAGE, 'error');
}

export function addDefaultErrorMessageActionI18n(intl: IntlShape) {
  return addMessageAction(intl.formatMessage(messages.defaultError), 'error');
}
