import {
  Button as FountainButton,
  Input,
} from '@fountain/fountain-ui-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import React, { useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { messages } from './messages';
import { useStyles } from './styles';

export interface RenameWorkflowModalProps {
  onWorkflowTitleUpdate: (workflowName: string) => void;
  renameModalVisible: boolean;
  setRenameModalVisible: (modalVisible: boolean) => void;
  workflowTitle: string;
}

export const RenameWorkflowModal: VFC<RenameWorkflowModalProps> = ({
  onWorkflowTitleUpdate,
  renameModalVisible,
  setRenameModalVisible,
  workflowTitle,
}) => {
  const intl = useIntl();
  const styles = useStyles();
  const [workflowName, setWorkflowName] = useState(workflowTitle);
  const toggleRenameModal = () => {
    setRenameModalVisible(!renameModalVisible);
  };
  const onSubmit = () => {
    onWorkflowTitleUpdate(workflowName);
    setRenameModalVisible(false);
  };
  return (
    <Dialog open={renameModalVisible} onClose={toggleRenameModal} fullWidth>
      <DialogTitle className={styles.dialogHeader} disableTypography>
        <Typography variant="h3" className={styles.dialogTitle}>
          <FormattedMessage {...messages.renameWorkflow} />
        </Typography>
        <IconButton aria-label="close" onClick={toggleRenameModal}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" className={styles.dialogBody}>
          <FormattedMessage {...messages.newWorkflowName} />
        </Typography>
        <br />
        <Input
          value={workflowName}
          onChange={event => setWorkflowName(event.target.value)}
          label={intl.formatMessage(messages.renameLabel)}
          variant="outlined"
        />
      </DialogContent>
      <DialogActions className={styles.footer}>
        <FountainButton
          onClick={toggleRenameModal}
          className={styles.cancelButton}
          size="small"
          type="secondary"
        >
          <FormattedMessage {...messages.cancel} />
        </FountainButton>
        <FountainButton
          onClick={onSubmit}
          color="primary"
          size="small"
          className={styles.renameButton}
        >
          <FormattedMessage {...messages.rename} />
        </FountainButton>
      </DialogActions>
    </Dialog>
  );
};
