export const RUN = 'run';
export const ADVANCE = 'advance';
export const RUN_AND_ADVANCE = 'run_and_advance';
export const NOTHING = 'nothing';
export const RUN_WHEN_STATUS_NO_LONGER_PENDING =
  'run_when_status_no_longer_pending';
export const RUN_AND_ADVANCE_ON_COMPLETION = 'run_and_advance_on_completion';
export const RUN_AND_ADVANCE_WHEN_CLEAR = 'run_and_advance_when_clear';
export const RUN_AND_ADVANCE_ON_FINAL_STATUS =
  'run_and_advance_on_final_status';
