import {
  FETCH_SEARCH_RESULT_ERROR,
  FETCH_SEARCH_RESULT_INIT,
  FETCH_SEARCH_RESULT_SUCCESS,
} from './constants';

export function fetchSearchResultInitAction(active, query, locationId) {
  return {
    type: FETCH_SEARCH_RESULT_INIT,
    active,
    query,
    locationId,
  };
}

export function fetchSearchResultErrorAction(error) {
  return {
    type: FETCH_SEARCH_RESULT_ERROR,
    error,
  };
}

export function fetchSearchResultSuccessAction(locations) {
  return {
    type: FETCH_SEARCH_RESULT_SUCCESS,
    locations,
  };
}
