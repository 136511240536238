import { makeStyles } from '@material-ui/core/styles';
import {
  ChatbotWidgetBrand,
  ChatbotWidgetSettings,
} from 'api-clients/monolith';
import React, { VFC } from 'react';

import { Description } from '../Components/Description';
import { SectionTitle } from '../Components/SectionTitle';
import { SettingSwitch } from '../Components/SettingSwitch';
import { SettingTitle } from '../Components/SettingTitle';
import { HandleSettingChange } from '../types';
import { BrandSelector } from './BrandSelector';
import { messages } from './messages';

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing(2),
  },
  switch: {
    marginTop: theme.spacing(4),
  },
}));

export interface GeneralSettingsProps {
  brands: ChatbotWidgetBrand[];
  selectedBrandOption: ChatbotWidgetBrand;
  handleBrandChange: (brands: ChatbotWidgetBrand) => void;
  settings: ChatbotWidgetSettings;
  handleSettingChange: HandleSettingChange;
}

export const GeneralSettings: VFC<GeneralSettingsProps> = ({
  brands,
  selectedBrandOption,
  handleBrandChange,
  settings,
  handleSettingChange,
}) => {
  const styles = useStyles();

  return (
    <section>
      <SectionTitle value={messages.generalSettings} />
      <SettingTitle value={messages.brandYourWidget} className={styles.title} />
      <Description value={messages.brandYourWidgetDesc} />
      <BrandSelector
        brands={brands}
        selectedBrand={selectedBrandOption}
        onChange={handleBrandChange}
      />
      <Description value={messages.brandSelectorDescription} />
      <SettingSwitch
        settingKey="open_widget_by_default"
        label={messages.openWindowSetting}
        checked={settings.open_widget_by_default}
        onChange={handleSettingChange}
        className={styles.switch}
      />
      <Description value={messages.openingWindowDesc} />
      <SettingSwitch
        settingKey="jobs_directory_widget_enabled"
        label={messages.enableJobsDirectorywidget}
        checked={settings.jobs_directory_widget_enabled}
        onChange={handleSettingChange}
        className={styles.switch}
      />
    </section>
  );
};
