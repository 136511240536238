import { produce } from 'immer';

type ReorderStagesArgs<T> = {
  endPosition: number;
  stages: T[];
  startPosition: number;
};

type WithPosition = Partial<{ position: number }>;
// stand-in value so minPosition will always find something smaller
const giantStagePosition = 50000;

// Find the lowest stage position to use as offset. This is usually 1, but
// acts_as_list doesn't guarantee that in all cases.
const minPosition = <T extends WithPosition>(stages: T[]): number =>
  stages.reduce((min, stage) => {
    const pos = stage.position ?? 0;
    return pos < min ? pos : min;
  }, giantStagePosition);

export const reorderStages = <T extends WithPosition>({
  endPosition,
  stages,
  startPosition,
}: ReorderStagesArgs<T>): T[] => {
  return produce(stages, draft => {
    const offset = minPosition(stages);
    const [removed] = draft.splice(startPosition - offset, 1);
    draft.splice(endPosition - offset, 0, removed);

    draft.forEach((stage, index) => {
      // eslint-disable-next-line no-param-reassign
      stage.position = index + 1;
    });
  });
};
