import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  sidebarContainer: {
    minWidth: '250px',
    borderRight: theme.customBorders.gray200,
    padding: theme.spacing(1.5, 2, 0),
    height: 'calc(100vh - 52px)',
    overflow: 'auto',
    backgroundColor: theme.palette.common.gray100,
    '& > *:nth-child(2)': {
      paddingTop: theme.spacing(1),
    },
  },
  subHeader: {
    cursor: 'pointer',
    paddingLeft: theme.spacing(3),
  },
  header: {
    '&::before': {
      content: "''",
    },
    paddingLeft: theme.spacing(2),
  },
  selectedMenu: {
    backgroundColor: '#131E401F !important',
    '& > *:first-child': {
      fontWeight: 700,
    },
  },
  subHeaderText: {
    color: theme.palette.common.gray700,
  },
}));
