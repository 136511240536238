/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AvailableRuleStageType } from '../models/AvailableRuleStageType';
import type { AvailableStageType } from '../models/AvailableStageType';
import type { CalendarUser } from '../models/CalendarUser';
import type { FunnelDetails } from '../models/FunnelDetails';
import type { JobMatcherConditionOptions } from '../models/JobMatcherConditionOptions';
import type { LessonlyContentData } from '../models/LessonlyContentData';
import type { NorthpassCourseData } from '../models/NorthpassCourseData';
import type { Reason } from '../models/Reason';
import type { RulesEditData } from '../models/RulesEditData';
import type { RuleStageDistributionBasis } from '../models/RuleStageDistributionBasis';
import type { SidebarStageResponse } from '../models/SidebarStageResponse';
import type { StageCloneParams } from '../models/StageCloneParams';
import type { WorkflowDetails } from '../models/WorkflowDetails';
import type { WorkflowStageDetail } from '../models/WorkflowStageDetail';
import type { WorkflowStageUpdates } from '../models/WorkflowStageUpdates';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WorkflowEditorService {

    /**
     * list archived reasons
     * Returns a serialized list of archived reasons associated to an account
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiWorkflowEditorArchivedReasons(): CancelablePromise<{
        archived_reasons: Array<Reason>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/workflow_editor/archived_reasons',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * request users and their calendars
     * Returns list of users along with their calendars if they have one
     * @returns CalendarUser successful
     * @throws ApiError
     */
    public static getInternalApiWorkflowEditorCalendarUsers(): CancelablePromise<Array<CalendarUser>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/workflow_editor/calendar_users',
        });
    }

    /**
     * provisions a new calendar for a user
     * Returns the newly provisioned calendar
     * @param requestBody
     * @returns CalendarUser successful
     * @throws ApiError
     */
    public static postInternalApiWorkflowEditorCalendarUsers(
        requestBody: {
            user_id?: number;
        },
    ): CancelablePromise<CalendarUser> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/workflow_editor/calendar_users',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * clone stage
     * Creates a funnel stage from params
     * @param funnelSlug funnel_slug
     * @param requestBody
     * @returns WorkflowStageDetail created
     * @throws ApiError
     */
    public static postInternalApiWorkflowEditorFunnelsCloneStages(
        funnelSlug: string,
        requestBody?: StageCloneParams,
    ): CancelablePromise<WorkflowStageDetail> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/workflow_editor/funnels/{funnel_slug}/clone_stages',
            path: {
                'funnel_slug': funnelSlug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
                422: `unprocessable entity`,
            },
        });
    }

    /**
     * list stage types
     * @param funnelSlug funnel_slug
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiWorkflowEditorFunnelsRuleStageTypes(
        funnelSlug: string,
    ): CancelablePromise<{
        rule_stage_types: Array<AvailableRuleStageType>;
        rule_stage_distribution_bases: Array<RuleStageDistributionBasis>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/workflow_editor/funnels/{funnel_slug}/rule_stage_types',
            path: {
                'funnel_slug': funnelSlug,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * list stage types
     * @param funnelSlug funnel_slug
     * @param subStageTypes flag to return only Substage types
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiWorkflowEditorFunnelsStageTypes(
        funnelSlug: string,
        subStageTypes?: boolean,
    ): CancelablePromise<{
        stage_types: Array<AvailableStageType>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/workflow_editor/funnels/{funnel_slug}/stage_types',
            path: {
                'funnel_slug': funnelSlug,
            },
            query: {
                'sub_stage_types': subStageTypes,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * update partner integrations
     * @param stageExternalId stage_external_id
     * @returns WorkflowStageDetail successful
     * @throws ApiError
     */
    public static putInternalApiWorkflowEditorStagesPartnerIntegrations(
        stageExternalId: string,
    ): CancelablePromise<WorkflowStageDetail> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/internal_api/workflow_editor/stages/{stage_external_id}/partner_integrations',
            path: {
                'stage_external_id': stageExternalId,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * list stages
     * @param funnelSlug funnel_slug
     * @returns SidebarStageResponse successful
     * @throws ApiError
     */
    public static getInternalApiWorkflowEditorFunnelsStages(
        funnelSlug: string,
    ): CancelablePromise<SidebarStageResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/workflow_editor/funnels/{funnel_slug}/stages',
            path: {
                'funnel_slug': funnelSlug,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * create stage
     * Creates a funnel stage from params
     * @param funnelSlug funnel_slug
     * @param requestBody
     * @returns WorkflowStageDetail created
     * @throws ApiError
     */
    public static postInternalApiWorkflowEditorFunnelsStages(
        funnelSlug: string,
        requestBody?: WorkflowStageUpdates,
    ): CancelablePromise<WorkflowStageDetail> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/workflow_editor/funnels/{funnel_slug}/stages',
            path: {
                'funnel_slug': funnelSlug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
                422: `unprocessable entity`,
            },
        });
    }

    /**
     * show funnel
     * Returns a serialized representation of a funnel
     * @param funnelSlug funnel_slug
     * @returns FunnelDetails successful
     * @throws ApiError
     */
    public static getInternalApiWorkflowEditorFunnels(
        funnelSlug: string,
    ): CancelablePromise<FunnelDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/workflow_editor/funnels/{funnel_slug}',
            path: {
                'funnel_slug': funnelSlug,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * retrieve job_matcher_condition_options
     * @returns JobMatcherConditionOptions successful
     * @throws ApiError
     */
    public static getInternalApiWorkflowEditorJobMatcherConditionOptions(): CancelablePromise<JobMatcherConditionOptions> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/workflow_editor/job_matcher_condition_options',
        });
    }

    /**
     * retrieve lessonly lessons and paths data for the current account
     * @returns LessonlyContentData successful
     * @throws ApiError
     */
    public static getInternalApiWorkflowEditorStagesLearningStagesLessonlyContent(): CancelablePromise<LessonlyContentData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/workflow_editor/stages/learning_stages/lessonly_content',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                422: `unproccessable`,
            },
        });
    }

    /**
     * retrieve northpass course data for the current account
     * @returns NorthpassCourseData successful
     * @throws ApiError
     */
    public static getInternalApiWorkflowEditorStagesLearningStagesNorthpassCourses(): CancelablePromise<NorthpassCourseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/workflow_editor/stages/learning_stages/northpass_courses',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                422: `unproccessable`,
            },
        });
    }

    /**
     * list rejection reasons
     * Returns a serialized list of rejection reasons associated to an account
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiWorkflowEditorRejectionReasons(): CancelablePromise<{
        rejection_reasons: Array<Reason>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/workflow_editor/rejection_reasons',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * retrieve rules_edit_data
     * @param stageExternalId stage#external_id
     * @param filterToSelectedOnly when enabled, only return openings data values of currently selected funnels
     * @param openingsOnly when enabled, only return the openings key/value as the json response
     * @returns RulesEditData successful
     * @throws ApiError
     */
    public static getInternalApiWorkflowEditorRulesEditData(
        stageExternalId: string,
        filterToSelectedOnly?: boolean,
        openingsOnly?: boolean,
    ): CancelablePromise<RulesEditData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/workflow_editor/rules_edit_data/{stage_external_id}',
            path: {
                'stage_external_id': stageExternalId,
            },
            query: {
                'filter_to_selected_only': filterToSelectedOnly,
                'openings_only': openingsOnly,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * show stage
     * Returns a serialized representation of a funnel stage
     * @param funnelSlug funnel#slug
     * @param stageExternalId stage#external_id
     * @returns WorkflowStageDetail successful
     * @throws ApiError
     */
    public static getInternalApiWorkflowEditorFunnelsStages1(
        funnelSlug: string,
        stageExternalId: string,
    ): CancelablePromise<WorkflowStageDetail> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/workflow_editor/funnels/{funnel_slug}/stages/{stage_external_id}',
            path: {
                'funnel_slug': funnelSlug,
                'stage_external_id': stageExternalId,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * update stage
     * Updates a stage from params
     * @param funnelSlug funnel#slug
     * @param stageExternalId stage#external_id
     * @param requestBody
     * @returns WorkflowStageDetail success
     * @throws ApiError
     */
    public static patchInternalApiWorkflowEditorFunnelsStages(
        funnelSlug: string,
        stageExternalId: string,
        requestBody?: WorkflowStageUpdates,
    ): CancelablePromise<WorkflowStageDetail> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/internal_api/workflow_editor/funnels/{funnel_slug}/stages/{stage_external_id}',
            path: {
                'funnel_slug': funnelSlug,
                'stage_external_id': stageExternalId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
                422: `unprocessable entity`,
            },
        });
    }

    /**
     * delete stage
     * Delete a stage with provided external id
     * @param stageExternalId stage#external_id
     * @returns void
     * @throws ApiError
     */
    public static deleteInternalApiWorkflowEditorStages(
        stageExternalId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/internal_api/workflow_editor/stages/{stage_external_id}',
            path: {
                'stage_external_id': stageExternalId,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * workflow editor url
     * Redirects to Workflow Editor with an opening belonging to the workflow
     * @param workflowId workflow_id
     * @returns void
     * @throws ApiError
     */
    public static getInternalApiWorkflowEditorWorkflowsWorkflowEditorUrl(
        workflowId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/workflow_editor/workflows/{workflow_id}/workflow_editor_url',
            path: {
                'workflow_id': workflowId,
            },
            errors: {
                302: `redirect`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * show workflow
     * Returns a serialized representation of a workflow
     * @param workflowId workflow_id
     * @returns WorkflowDetails successful
     * @throws ApiError
     */
    public static getInternalApiWorkflowEditorWorkflows(
        workflowId: number,
    ): CancelablePromise<WorkflowDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/workflow_editor/workflows/{workflow_id}',
            path: {
                'workflow_id': workflowId,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * update workflow
     * Updates a workflow from params
     * @param workflowId workflow_id
     * @param requestBody
     * @returns WorkflowDetails success
     * @throws ApiError
     */
    public static patchInternalApiWorkflowEditorWorkflows(
        workflowId: number,
        requestBody?: WorkflowDetails,
    ): CancelablePromise<WorkflowDetails> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/internal_api/workflow_editor/workflows/{workflow_id}',
            path: {
                'workflow_id': workflowId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
                422: `unprocessable entity`,
            },
        });
    }

    /**
     * delete workflow
     * Soft deletes the workflow
     * @param workflowExternalId workflow_external_id
     * @returns any successful
     * @throws ApiError
     */
    public static deleteInternalApiWorkflowEditorWorkflows(
        workflowExternalId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/internal_api/workflow_editor/workflows/{workflow_external_id}',
            path: {
                'workflow_external_id': workflowExternalId,
            },
            errors: {
                403: `forbidden`,
                404: `not found`,
            },
        });
    }

}