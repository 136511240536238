/* eslint-disable camelcase */
import {
  Button,
  CustomTooltip,
  EditPencil,
  InnerContent,
  ModalFooter,
  SimpleModal,
  SimpleModalHeader,
} from '@fountain/fountain-ui-components';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import ExternalLink from '@material-ui/core/Link';
import { AssessmentDataFieldSet } from 'api-clients/monolith';
import cx from 'classnames';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import globalMessages from 'shared/global/messages';

import { messages } from './messages';
import useStyles from './styles';

export interface AssessmentModalProps {
  currentAssessmentId?: number;
  assessments: Array<AssessmentDataFieldSet>;
  assessmentHref: string;
  stageId: string;
  closeModal: () => void;
  onAssessmentChange: (newAssessmentDataFieldSetId: number) => void;
}

export const AssessmentModal: FC<AssessmentModalProps> = ({
  currentAssessmentId,
  assessments,
  assessmentHref,
  stageId,
  closeModal,
  onAssessmentChange,
}) => {
  const classes = useStyles();

  return (
    <SimpleModal onClose={closeModal} maxWidth={640} blurOverlay={false}>
      <SimpleModalHeader onClose={closeModal}>
        <Typography variant="h3">
          <FormattedMessage {...messages.modalHeader} />
        </Typography>
      </SimpleModalHeader>
      <InnerContent>
        <Typography variant="body2" className={classes.modalInstructions}>
          <FormattedMessage {...messages.modalInstructions} />
        </Typography>
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHead className={classes.tableHead}>
              <TableRow>
                {assessments.length > 0 ? (
                  <>
                    <TableCell className={classes.tableHeaderCell}>
                      <Typography variant="h4">
                        <FormattedMessage {...messages.title} />
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableHeaderEmptyCell}>
                      <Typography variant="h4">
                        <FormattedMessage {...messages.manage} />
                      </Typography>
                    </TableCell>
                  </>
                ) : (
                  <TableCell className={classes.tableBodyCell}>
                    <Typography variant="h5">
                      <FormattedMessage {...messages.instructions} />
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {assessments.map((assessment, idx) => (
                <TableRow key={assessment.external_id}>
                  <TableCell
                    className={cx(classes.tableBodyCell, {
                      [classes.tableCellLast]: idx === assessments.length - 1,
                    })}
                    onClick={() => {
                      onAssessmentChange(assessment.id);
                      closeModal();
                    }}
                  >
                    <Typography variant="h5" className={classes.title}>
                      {assessment.title}
                    </Typography>
                    {assessment.id === currentAssessmentId && (
                      <Typography variant="h5" className={classes.activeLabel}>
                        <FormattedMessage {...messages.activeAssessment} />
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell
                    className={cx(classes.tableBodyEditCell, {
                      [classes.tableCellLast]: idx === assessments.length - 1,
                    })}
                  >
                    <CustomTooltip
                      dense
                      title={<FormattedMessage {...messages.editAssessment} />}
                    >
                      <ExternalLink
                        aria-label="edit"
                        className={classes.editMessageButton}
                        href={`${assessmentHref}/${assessment.external_id}/edit?stage_id=${stageId}`}
                        target="_blank"
                      >
                        <EditPencil
                          viewBox="0 0 16 16"
                          className={classes.editMessageIcon}
                        />
                      </ExternalLink>
                    </CustomTooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </InnerContent>
      <ModalFooter className={classes.footer}>
        <Button
          autoWidth
          className={classes.footerButton}
          onClick={() => closeModal()}
          size="small"
          type="secondary"
        >
          <FormattedMessage {...globalMessages.close} />
        </Button>
        <Button
          autoWidth
          className={classes.footerButton}
          disabled={false}
          href={`${assessmentHref}/new?stage_id=${stageId}`}
          size="small"
          submit
          type="primary"
        >
          <FormattedMessage {...messages.createNewAssessment} />
        </Button>
      </ModalFooter>
    </SimpleModal>
  );
};

export default AssessmentModal;
