import { Button } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import { StageSettingCard } from '../../../../StageSettingCard';
import { CRONOFY } from './constants';
import { messages } from './messages';
import { useStyles } from './styles';

export const ExternalCalendarCard: FC = () => {
  const styles = useStyles();
  const intl = useIntl();

  const { accountSlug } = useParams<{
    accountSlug: string;
  }>();

  return (
    <StageSettingCard
      variant="default"
      title={intl.formatMessage(messages.externalCalendarIntegration)}
    >
      <Grid>
        <Typography variant="body2" className={styles.description}>
          {intl.formatMessage(messages.externalCalendarDescription)}
        </Typography>
        <Button
          aria-label={intl.formatMessage(messages.connectCalendar)}
          type="secondary"
          size="small"
          autoWidth
          className={styles.connectCalendarButton}
          href={`${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/users/${CRONOFY}/new`}
          target="_blank"
        >
          <Typography variant="h4">
            {intl.formatMessage(messages.connectCalendar)}
          </Typography>
        </Button>
      </Grid>
    </StageSettingCard>
  );
};
