import { defineMessages } from 'react-intl';

export const scope = 'app.components.globalNav.calendarNavLink';

export default defineMessages({
  calendar: {
    id: 'app.components.globalNav.calendarNavLink.calendar',
    defaultMessage: 'Calendar',
  },
  schedule: {
    id: 'app.components.globalNav.calendarNavLink.schedule',
    defaultMessage: 'Schedule',
  },
});
