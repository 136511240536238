/* eslint-disable camelcase */
import {
  Button,
  InnerContent,
  ModalFooter,
} from '@fountain/fountain-ui-components';
import { Typography } from '@material-ui/core';
import React, { useEffect, useState, VFC } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { usePDF } from 'react-to-pdf';

import { AssessmentFormData } from 'containers/StatusLabels/utils';
import globalMessages from 'shared/global/messages';

import { messages } from '../messages';
import AssessmentDataFieldQuestions from './AssessmentDataFieldQuestions';

export interface AssessmentFormModalContentProps {
  assessmentData: AssessmentFormData;
  handleClose: () => void;
}

export const AssessmentFormModalContent: VFC<
  AssessmentFormModalContentProps
> = ({ assessmentData, handleClose }) => {
  const completionDate = (
    <FormattedDate value={assessmentData.completed_at} dateStyle="short" />
  );
  const score = `${assessmentData.score}/${
    Object.keys(assessmentData.response_data).length
  }`;
  const { toPDF, targetRef } = usePDF({
    filename: `${assessmentData.applicant_name} - ${assessmentData.title}.pdf`,
  });
  const defaultContentStyle = React.useMemo(() => ({ padding: '24px' }), []);
  const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);
  const [contentStyle, setContentStyle] =
    useState<React.CSSProperties>(defaultContentStyle);

  const handlePDFDownload = () => {
    setContentStyle({
      padding: '24px',
      height: 'auto',
      maxHeight: 'none',
      overflow: 'visible',
    });
    setIsDownloadingPDF(true);
  };

  useEffect(() => {
    if (isDownloadingPDF) {
      toPDF();
      setContentStyle(defaultContentStyle);
      setIsDownloadingPDF(false);
    }
  }, [defaultContentStyle, isDownloadingPDF, toPDF]);

  return (
    <>
      <InnerContent style={contentStyle} ref={targetRef}>
        {isDownloadingPDF && (
          <div>
            <Typography variant="h3" color="textPrimary">
              {assessmentData.applicant_name}
            </Typography>
          </div>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h4" color="textPrimary">
            <FormattedMessage
              {...messages.answeredCorrect}
              values={{ score }}
            />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage
              {...messages.completionDate}
              values={{ completionDate }}
            />
          </Typography>
        </div>
        <AssessmentDataFieldQuestions
          questions={assessmentData.response_data}
        />
      </InnerContent>
      <ModalFooter>
        <Button
          onClick={() => handlePDFDownload()}
          size="small"
          type="secondary"
        >
          <FormattedMessage {...messages.download} />
        </Button>
        <Button autoWidth onClick={handleClose} size="small" type="primary">
          <FormattedMessage {...globalMessages.close} />
        </Button>
      </ModalFooter>
    </>
  );
};

export default AssessmentFormModalContent;
