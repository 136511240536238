import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  editAvailability: {
    id: 'app.Calendar.EventCard.editAvailability',
    defaultMessage: 'Edit Availability',
  },
  manageEvent: {
    id: 'app.Calendar.EventCard.manageEvent',
    defaultMessage: 'Manage event',
  },
  duplicateEvent: {
    id: 'app.Calendar.EventCard.duplicateEvent',
    defaultMessage: 'Duplicate event',
  },
  deleteEvent: {
    id: 'app.Calendar.EventCard.deleteEvent',
    defaultMessage: 'Delete event',
  },
  hostMessage: {
    id: 'app.Calendar.EventCard.hostMessage',
    defaultMessage: 'Owner: {hostName}',
  },
  moreActionsMenu: {
    id: 'app.Calendar.EventCard.moreActionsMenu',
    defaultMessage: 'more-actions-menu',
  },
  manageRoster: {
    id: 'app.Calendar.EventCard.manageRoster',
    defaultMessage: 'Manage Roster',
  },
  bookingCounts: {
    id: 'app.Calendar.EventCard.bookingCounts',
    defaultMessage: 'Attendees: {bookedCount} of {maxCount}',
  },
  videoLink: {
    id: 'app.Calendar.EventCard.videoLink',
    defaultMessage: 'Join Video Chat as Host',
  },
});
