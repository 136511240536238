import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      '&:last-child': {
        marginRight: 0,
      },

      marginRight: theme.spacing(2),
      transition: 'none',
    },
    stageHeaderContent: {
      boxShadow: `0px 1px 2px ${theme.palette.common.gray300}`,
      zIndex: 1,
    },
    stageTitleHeader: {
      padding: theme.spacing(2.5, 3),
      borderBottom: theme.customBorders.gray300,
      zIndex: 1,
    },
    input: {
      width: theme.spacing(39),
    },
    buttonsContainer: {
      display: 'flex',
    },
    modalContents: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2.5),
      marginBottom: theme.spacing(2),
    },
    errorContent: {
      backgroundColor: theme.palette.common.orange100,
      borderRadius: theme.spacing(0.625),
      padding: theme.spacing(1),
      marginTop: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
    },
    errorIcon: {
      height: theme.spacing(2),
      marginRight: theme.spacing(0.25),
      color: theme.palette.common.orange400,
    },
    errorText: {
      color: theme.palette.common.red800,
    },
  }),
);

export default styles;
