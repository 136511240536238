import { theme as muiTheme } from '@fountain/fountain-ui-components';
import { makeStyles, StyleRules } from '@material-ui/core/styles';

export const highlightStyle = {
  color: 'inherit',
  backgroundColor: muiTheme.palette.common.orange200,
};

const generateStyles = () => {
  const styles: StyleRules = {
    loadingOverlay: {
      display: 'flex',
      flex: '1',
      flexDirection: 'column',
    },
  };
  return styles;
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const useStyles: () => Record<
  keyof ReturnType<typeof generateStyles>,
  string
> = makeStyles(generateStyles);
