import { WorkflowStageDetail } from 'api-clients/monolith';
import produce from 'immer';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { SettingSwitch } from '../../../SettingSwitch';
import { messages } from '../messages';

type IsHiddenFromPortal = {
  stage: WorkflowStageDetail;
  setStage: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
};

export const IsHiddenFromPortal: FC<IsHiddenFromPortal> = ({
  stage,
  setStage,
}) => {
  const onToggleIsHiddenFromPortal = () => {
    setStage(
      produce(stage, draftStage => {
        draftStage.advanced_settings.is_hidden_from_portal =
          !draftStage.advanced_settings.is_hidden_from_portal;
      }),
    );
  };

  return (
    <SettingSwitch
      dataKey="is_hidden_from_portal"
      checked={!stage.advanced_settings.is_hidden_from_portal}
      onChange={onToggleIsHiddenFromPortal}
      label={<FormattedMessage {...messages.showStageInApplicantPortal} />}
    />
  );
};
