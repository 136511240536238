import { LOCATION_CHANGE } from 'connected-react-router';
import Route from 'route-parser';

import { setSelectedLocationAction } from 'containers/Auth_old/actions';
import { initialState } from 'containers/Auth_old/reducer';

import { WHOAMI_SUCCESS } from '../containers/Auth_old/constants';
/**
 * This middleware captures WHOAMI_SUCCESS action to update the
 * selected brand based on the url. This will ensure that the url and
 * the store stays in sync
 */

// eslint-disable-next-line consistent-return
const routeAuthMiddleware = store => next => action => {
  if (action.type === LOCATION_CHANGE || action.type === WHOAMI_SUCCESS) {
    // Let the original reducer do its thing
    next(action);

    // Now check if there is a mismatch between the brand in the url
    // and the selected brand in the store
    const { auth } = store.getState();
    if (!auth) {
      return next(action);
    }
    const brands = store.getState().auth.whoami.brands || initialState;
    if (!brands.length) {
      return next(action);
    }
    const path = store.getState().router.location.pathname;

    const brandRoute = Route(
      `/:accountSlug(/brands/:brandSlug(/locations(/:locationId))(/*rest1))(/*rest2)`,
    );
    const parsed = brandRoute.match(path);
    if (!parsed) {
      return new Error('Unable to parse route. Check routeAuthMiddleware.');
    }
    if (parsed.brandSlug) {
      const parsedBrand = brands.find(
        brand => brand.slug === parsed.brandSlug && !brand.isAccount,
      );

      if (parsedBrand) {
        if (parsed.locationId) {
          const location = parsedBrand.locations.find(
            l => l.id === parsed.locationId,
          );
          if (location) {
            return store.dispatch(setSelectedLocationAction(location));
          }
        } else {
          const newLocation = parsedBrand.locations.find(l => !l.locked);
          if (newLocation) {
            return store.dispatch(setSelectedLocationAction(newLocation));
          }
        }
      } else {
        return new Error('Could not find valid brand in URL');
      }
    }
  } else {
    return next(action);
  }
};

export default routeAuthMiddleware;
