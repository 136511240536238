/**
 *
 * MobileMessagePreview
 *
 */

import { Grid, withStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Autolinker from 'autolinker';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { classNames } from 'react-extras';

import MobileEmailPreview from './components/MobileEmailPreview';
import MobileSmsPreview from './components/MobileSmsPreview';
import { MESSAGE_DIRECTION, MESSAGE_TYPE } from './constants';
import styles from './styles';

function MobileMessagePreview({
  classes,
  fromEmail,
  toEmail,
  hasLinks,
  isLoading,
  messageSubject,
  messageText,
  smsConversation,
  type,
  phoneNumber,
  isWhatsappPreview,
}) {
  const messageTextModified =
    messageText && messageText.replace(/<a/g, '<a target=_blank');
  const linkedMessageTextModified = Autolinker.link(messageTextModified);

  return (
    <Grid
      container
      className={classes.mobilePhoneContainer}
      alignItems="center"
      justify="center"
    >
      {isLoading ? (
        <Skeleton variant="rect" />
      ) : (
        <Grid className={classes.mobilePhoneScreen}>
          <div className={classes.screenTopCover} />
          <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.topGreyBlock}
          >
            {phoneNumber}
          </Grid>
          <Grid className={classes.messageContent}>
            <div
              className={classNames(classes.messageTextPreview, {
                sms: type === 'sms',
              })}
            >
              {type === MESSAGE_TYPE.email && (
                <MobileEmailPreview
                  fromEmail={fromEmail}
                  messageBody={linkedMessageTextModified}
                  messageSubject={messageSubject}
                  toEmail={toEmail}
                />
              )}
              {type === MESSAGE_TYPE.sms && (
                <MobileSmsPreview
                  hasLinks={hasLinks}
                  messageText={messageText}
                  smsConversation={smsConversation}
                  isWhatsappPreview={isWhatsappPreview}
                />
              )}
            </div>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

MobileMessagePreview.defaultProps = {
  type: 'email',
};

MobileMessagePreview.propTypes = {
  classes: PropTypes.object.isRequired,
  fromEmail: PropTypes.string,
  toEmail: PropTypes.string,
  hasLinks: PropTypes.bool,
  isLoading: PropTypes.bool,
  messageSubject: PropTypes.string,
  messageText: PropTypes.string,
  type: PropTypes.string,
  phoneNumber: PropTypes.string,
  smsConversation: PropTypes.arrayOf(
    PropTypes.exact({
      direction: PropTypes.oneOf([
        MESSAGE_DIRECTION.receiving,
        MESSAGE_DIRECTION.sending,
      ]).isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  isWhatsappPreview: PropTypes.bool,
};

export default withStyles(styles)(memo(MobileMessagePreview));
