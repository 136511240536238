import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addStageTitle: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizeStageModal.addStageTitle`,
    defaultMessage: 'Add stage title',
  },
  applicantFacingContent: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizeStageModal.applicantFacingContent`,
    defaultMessage: 'Applicant-facing content',
  },
  cancel: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizeStageModal.cancel`,
    defaultMessage: 'Cancel',
  },
  changeEditor: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizeStageModal.changeEditor`,
    defaultMessage: 'Change Editor',
  },
  changeEditorInfo: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizeStageModal.changeEditorInfo`,
    defaultMessage:
      'Changing the editing mode will delete any existing content. Do you want to continue?',
  },
  changesSavedSuccessfully: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizeStageModal.changesSavedSuccessfully`,
    defaultMessage: 'Changes saved successfully',
  },
  characterCount: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizeStageModal.characterCount`,
    defaultMessage: 'Character count: {count}/100',
  },
  continue: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizeStageModal.continue`,
    defaultMessage: 'Continue',
  },
  customizeStage: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizeStageModal.customizeStage`,
    defaultMessage: 'Customize Stage',
  },
  customizeStageModal: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizeStageModal.customizeStageModal`,
    defaultMessage: 'Customize Stage Modal',
  },
  customScripts: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizeStageModal.customScripts`,
    defaultMessage: 'Custom Scripts',
  },
  modalDescription: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizeStageModal.modalDescription`,
    defaultMessage:
      'These settings allow you to change the default text for the stage, as well as insert custom JavaScript code. All fields are optional.',
  },
  noResults: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizeStageModal.noResults`,
    defaultMessage: 'No Results Message',
  },
  noResultsInfo: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizeStageModal.noResultsInfo`,
    defaultMessage:
      'This message will only be shown to applicants if there are no Openings matching the condition on this stage. Include [ADVANCE_URL] to allow applicants to move to next stage',
  },
  noResultsPlaceholder: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizeStageModal.noResultsPlaceholder`,
    defaultMessage:
      'Insert [ADVANCE_LINK] to allow applicants to self-advance.',
  },
  postCompletionScript: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizeStageModal.postCompletionScript`,
    defaultMessage: 'Post-Completion Script',
  },
  postCompletionScriptInfo: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizeStageModal.postCompletionScriptInfo`,
    defaultMessage:
      'Add JavaScript code to be executed after an applicant completes the stage.',
  },
  saveAndPreview: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizeStageModal.saveAndPreview`,
    defaultMessage: 'Save & Preview',
  },
  saveEdits: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizeStageModal.saveEdits`,
    defaultMessage: 'Save Edits',
  },
  stageBody: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizeStageModal.stageBody`,
    defaultMessage: 'Stage Body',
  },
  stageInstructions: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizeStageModal.stageInstructions`,
    defaultMessage: 'Stage Instructions (we recommend no more than 50 words)',
  },
  stageInstructionsPlaceholder: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizeStageModal.stageInstructionsPlaceholder`,
    defaultMessage:
      'Ex. Please complete the following questions with your application.',
  },
  stageName: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizeStageModal.stageName`,
    defaultMessage: 'Stage Name',
  },
  stageScript: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizeStageModal.stageScript`,
    defaultMessage: 'Stage Script',
  },
  stageScriptInfo: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizeStageModal.stageScriptInfo`,
    defaultMessage:
      'Add JavaScript to be executed when an applicant lands on the stage.',
  },
  successMessage: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizeStageModal.successMessage`,
    defaultMessage: 'Success Message',
  },
  useCodeEditor: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizeStageModal.useCodeEditor`,
    defaultMessage: 'Use code editor',
  },
  useVisualEditor: {
    id: `app.containers.WorkflowEditor.components.StageHeader.CustomizeStageModal.useVisualEditor`,
    defaultMessage: 'Use visual editor',
  },
});
