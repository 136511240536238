import { defineMessages } from 'react-intl';

export const scope = 'app.containers.StatusLabels';

export default defineMessages({
  noLink: {
    id: `${scope}.noLink`,
    defaultMessage: '{label} does not have a link',
  },
  noVideos: {
    id: `${scope}.noVideos`,
    defaultMessage: 'No videos for {label}',
  },
  defaultError: {
    id: `${scope}.defaultError`,
    defaultMessage: 'There was an error with {label}',
  },
  cancelAndReschedule: {
    id: `${scope}.cancelAndReschedule`,
    defaultMessage:
      'Applicant has been notified of session cancellation and has been requested to reschedule',
  },
  requestReschedule: {
    id: `${scope}.requestReschedule`,
    defaultMessage: 'Applicant has been requested to reschedule',
  },
  rescheduledTooltip: {
    id: `${scope}.rescheduledTooltip`,
    defaultMessage: 'Rescheduled {count} times',
  },
});
