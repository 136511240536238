import { Grid, Typography } from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import Skeleton from '@material-ui/lab/Skeleton';
import classNames from 'classnames';
import React, { VFC } from 'react';
import { useIntl } from 'react-intl';

import { ACTIVE, STEALTH, SUGGESTION } from '../constants';
import { messages } from './messages';
import { useStyles } from './styles';

import type { AutomatedResponseModel } from 'api-clients/monolith/models/AutomatedResponseModel';

export interface ModelStatusProps {
  activeModel: AutomatedResponseModel | null;
  isActiveModelDetailLoading: boolean;
}

export const ModelStatus: VFC<ModelStatusProps> = ({
  activeModel,
  isActiveModelDetailLoading,
}) => {
  const styles = useStyles();
  const intl = useIntl();

  return (
    <Grid
      item
      container
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <Grid item>
        {isActiveModelDetailLoading && (
          <Skeleton
            variant="rect"
            height={24}
            width={380}
            data-testid="skeleton-loader"
          />
        )}
        {!isActiveModelDetailLoading && (
          <Typography
            variant="body2"
            className={classNames(styles.label, {
              [styles.active]: activeModel?.mode === ACTIVE,
              [styles.stealth]: activeModel?.mode === STEALTH,
              [styles.suggestion]: activeModel?.mode === SUGGESTION,
            })}
          >
            {!activeModel
              ? intl.formatMessage(messages.inactiveModel)
              : `${activeModel.name} is ${activeModel.mode}`}
            {activeModel && (
              <CheckCircleRoundedIcon
                className={classNames(styles.checkIcon, {
                  [styles.activeIcon]: activeModel?.mode === ACTIVE,
                  [styles.stealthIcon]: activeModel?.mode === STEALTH,
                  [styles.suggestion]: activeModel?.mode === SUGGESTION,
                })}
              />
            )}
          </Typography>
        )}
      </Grid>
      <Grid item>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            {isActiveModelDetailLoading && (
              <Skeleton
                variant="rect"
                height={24}
                width={175}
                data-testid="skeleton-loader"
              />
            )}
            {!isActiveModelDetailLoading && activeModel && (
              <Typography
                variant="body2"
                className={classNames(styles.label, {
                  [styles.active]: activeModel?.mode === ACTIVE,
                  [styles.stealth]: activeModel?.mode === STEALTH,
                  [styles.suggestion]: activeModel?.mode === SUGGESTION,
                })}
              >
                {intl.formatMessage(messages.accuracy)}
                {': '}
                {activeModel?.accuracy === 0
                  ? intl.formatMessage(messages.unknown)
                  : `${activeModel?.accuracy}%`}
                {` (${activeModel?.feedback_count} ${intl.formatMessage(
                  messages.reviews,
                )})`}
              </Typography>
            )}
          </Grid>
          <Grid item>
            {isActiveModelDetailLoading && (
              <Skeleton
                variant="rect"
                height={24}
                width={175}
                data-testid="skeleton-loader"
              />
            )}
            {!isActiveModelDetailLoading && activeModel && (
              <Typography
                variant="body2"
                className={classNames(styles.label, {
                  [styles.active]: activeModel?.mode === ACTIVE,
                  [styles.stealth]: activeModel?.mode === STEALTH,
                  [styles.suggestion]: activeModel?.mode === SUGGESTION,
                })}
              >
                Confidence Threshold: {activeModel?.confidence_threshold}%
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
