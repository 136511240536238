import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { SharedConditionProps } from '../../types';
import {
  LESSONLY_SCORE,
  LESSONLY_STATUS,
  NORTHPASS_COURSE_PROGRESS,
  NORTHPASS_QUIZ_SCORE,
} from '../constants';
import { InputField } from '../InputField/InputField';
import { messages as conditionMessages } from '../messages';
import { LessonlySelect } from './LessonlySelect';
import { LessonlyStatus } from './LessonlyStatus';
import { NorthpassSelect } from './NorthpassSelect';

export const LearningCondition: FC<SharedConditionProps> = props => {
  return (
    <>
      <Grid item xs={6}>
        {props.condition.type &&
          {
            [LESSONLY_SCORE]: <LessonlySelect {...props} />,
            [LESSONLY_STATUS]: <LessonlySelect {...props} />,
            [NORTHPASS_COURSE_PROGRESS]: <NorthpassSelect {...props} />,
            [NORTHPASS_QUIZ_SCORE]: <NorthpassSelect {...props} />,
          }[props.condition.type]}
      </Grid>
      <Grid item xs={6}>
        <FormattedMessage {...conditionMessages.isGreaterThan} />
      </Grid>
      <Grid item xs={6}>
        {props.condition.type &&
          {
            [LESSONLY_SCORE]: (
              <InputField {...props} valueKey="score_percent" />
            ),
            [LESSONLY_STATUS]: <LessonlyStatus {...props} />,
            [NORTHPASS_COURSE_PROGRESS]: (
              <InputField {...props} valueKey="progress" />
            ),
            [NORTHPASS_QUIZ_SCORE]: <InputField {...props} valueKey="score" />,
          }[props.condition.type]}
      </Grid>
    </>
  );
};
