const styles = theme => ({
  root: {
    background: theme.palette.common.white,
    border: theme.customBorders.gray200,
    color: theme.palette.common.gray800,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1, 2),
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  success: {
    boxShadow: '0px 8px 16px rgba(19, 30, 64, 0.16), inset 2px 0px 0px #36B386',
  },
  error: {
    boxShadow: '0px 8px 16px rgba(19, 30, 64, 0.16), inset 2px 0px 0px #DE0E4B',
  },
  info: {
    boxShadow: `0px 8px 16px rgba(19, 30, 64, 0.16), inset 2px 0px 0px ${theme.palette.common.blue400}`,
  },
  warning: {
    boxShadow: '0px 8px 16px rgba(19, 30, 64, 0.16), inset 2px 0px 0px #EF9800',
  },
  action: {
    margin: 0,
    padding: 0,
  },
  message: {
    padding: theme.spacing(0, 1),
  },
  button: {
    padding: 0,
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  close: {
    color: theme.palette.common.gray500,
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(),
  },
});

export default styles;
