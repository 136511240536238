/**
 *
 * Material UI styles for ApplicantTableV2NameCell
 *
 *
 * @see https://material-ui.com/styles/basics
 *
 */

const styles = theme => ({
  td: {
    padding: theme.spacing(0, 1),
  },
  content: {
    maxWidth: 'inherit',
  },
  nameText: {
    marginRight: theme.spacing(0.5),
    width: '100%',
    color: theme.palette.common.blue400,
    cursor: 'pointer',
    display: 'flex',
    maxWidth: 'inherit',
  },
  nameHighlighter: {
    maxWidth: 'inherit',
    width: '100%',
    display: 'flex',
    minWidth: 0,

    '& > mark': {
      whiteSpace: 'pre',
    },

    '& > span': {
      maxWidth: 'inherit',
      whiteSpace: 'pre',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'table-cell',
    },
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
  applicantNameLink: {
    textDecoration: 'none',
    width: '100%',
    display: 'flex',
  },
});

export default styles;
