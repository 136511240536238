import './styles.css';

import { SchedulerService } from 'api-clients/monolith';
import * as CronofyElements from 'cronofy-elements';
import React, { useContext, useEffect, useState, VFC } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router';

import {
  makeSelectExternalId,
  makeSelectWhoami,
} from 'containers/Auth_old/selectors';

import { CalendarContext } from './context';
import { messages } from './messages';

type AvailabilityCallback = {
  notification: {
    type:
      | 'availability_rule_not_found'
      | 'availability_rule_saved'
      | 'availability_rule_edited';
  };
};

export const CronofyAvailabilityRules: VFC<{ elementToken: string | null }> = ({
  elementToken,
}) => {
  const intl = useIntl();
  const [element, setElement] = useState<HTMLElement | null>(null);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const calendarContext = useContext(CalendarContext);
  const { time_zone: timeZone } = useSelector(makeSelectWhoami());
  const userExternalId = useSelector(makeSelectExternalId());

  useEffect(() => {
    if (!element && calendarContext.cronofy_response) {
      setElement(
        CronofyElements.AvailabilityRules({
          element_token: elementToken,
          target_id: 'cronofy-element-availability-rules',
          availability_rule_id: `work_hours_${userExternalId}`,
          config: {
            start_time: '08:00',
            end_time: '18:00',
            duration: 15,
          },
          styles: {
            prefix: 'CustomCronofyAR',
          },
          tzid: timeZone,
          callback: (event: AvailabilityCallback) => {
            setShouldBlockNavigation(
              event.notification.type === 'availability_rule_not_found',
            );
          },
          translations: {
            en: {
              availability_rules: {
                save_new_rules: 'Save', // this element is about to get replaced in CR-549
              },
            },
          },
        }),
      );
    }
  }, [element, calendarContext, userExternalId, timeZone, elementToken]);

  return (
    <>
      <div
        id="cronofy-element-availability-rules"
        data-testid="cronofy-element-availability-rules"
      />
      <Prompt
        when={shouldBlockNavigation}
        message={intl.formatMessage(messages.unsavedAvailability)}
      />
    </>
  );
};

export type CronofyNotification = {
  notification: {
    type: 'profile_revoked';
  };
};

export const CronofyCalendarSync: VFC<{ elementToken?: string | null }> = ({
  elementToken,
}) => {
  const intl = useIntl();
  const [element, setElement] = useState<HTMLElement | null>(null);
  const calendarContext = useContext(CalendarContext);
  const userExternalId = useSelector(makeSelectExternalId());

  useEffect(() => {
    if (!element && calendarContext.client_id) {
      setElement(
        CronofyElements.CalendarSync({
          target_id: 'cronofy-element-calendar-sync',
          authorization: {
            redirect_uri: calendarContext.callback_url,
            client_id: calendarContext.client_id,
            scope: 'read_write',
          },
          callback: async (event: CronofyNotification) => {
            if (event.notification.type === 'profile_revoked') {
              await SchedulerService.deleteInternalApiSchedulerCalendars(
                userExternalId,
              );
              window.location.reload();
            }
          },
          element_token: elementToken,
          single_profile: true,
          styles: {
            prefix: 'CustomCronofyCS',
          },
          translations: {
            en: {
              calendar_sync: {
                calendar_accounts: intl.formatMessage(
                  messages.calendarIntegrationName,
                ),
                edit_accounts: intl.formatMessage(messages.editButton),
              },
            },
          },
        }),
      );
    }
  }, [element, calendarContext, intl, userExternalId, elementToken]);

  return (
    <div
      id="cronofy-element-calendar-sync"
      data-testid="cronofy-element-calendar-sync"
      className={elementToken ? '' : 'CenterMe'}
    />
  );
};
