import { theme } from '@fountain/fountain-ui-components';
import PropTypes from 'prop-types';
import React from 'react';

const SortingArrows = ({ sortDirection }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 10L8 14L12 10H4Z"
      fill={
        sortDirection === 'asc'
          ? theme.palette.common.gray800
          : theme.palette.common.gray500
      }
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6L8 2L4 6L12 6Z"
      fill={
        sortDirection === 'desc'
          ? theme.palette.common.gray800
          : theme.palette.common.gray500
      }
    />
  </svg>
);

SortingArrows.propTypes = {
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
};

export default SortingArrows;
