import { Alert } from '@fountain/fountain-ui-components';
import { Typography } from '@material-ui/core';
import React, { VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from './messages';
import { useStyles } from './styles';

export interface UnsupportedStageProps {
  bodyText: React.ReactNode;
}

export const UnsupportedStage: VFC<UnsupportedStageProps> = ({ bodyText }) => {
  const styles = useStyles();

  return (
    <div>
      <Alert className={styles.alert} type="warning" fullWidth={false}>
        <FormattedMessage {...messages.unsupportedStage} />
      </Alert>
      <Typography
        className={styles.bodyText}
        variant="body2"
        color="textPrimary"
      >
        {bodyText}
      </Typography>
      <Typography
        className={styles.contactSupport}
        variant="body2"
        color="textPrimary"
      >
        <FormattedMessage
          {...messages.contactSupport}
          values={{
            link: (chunks: string[]) => (
              <a rel="noopener noreferrer" href="mailto:support@fountain.com">
                {chunks}
              </a>
            ),
          }}
        />
      </Typography>
    </div>
  );
};
