import { defineMessages } from 'react-intl';

export const scope =
  'app.components.ApplicantTableV2.components.ApplicantTableV2UserCell';

export default defineMessages({
  assignUser: {
    id: 'app.components.ApplicantTableV2.components.ApplicantTableV2UserCell.assignUser',
    defaultMessage: 'assign user',
  },
  noOptionsAvailable: {
    id: 'app.components.ApplicantTableV2.components.ApplicantTableV2UserCell.noOptionsAvailable',
    defaultMessage: 'No options available',
  },
});
