import React, { FC } from 'react';

import { SharedConditionProps } from '../../types';
import { ComparatorSelect } from '../components/ComparatorSelect';
import { DataKeySelect } from '../components/DataKeySelect';

export const ApplicantDataComparison: FC<SharedConditionProps> = props => {
  return (
    <>
      <DataKeySelect {...props} />
      <ComparatorSelect {...props} />
      <DataKeySelect {...props} isStandard2 />
    </>
  );
};
