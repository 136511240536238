import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  advancedBookingOptions: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.AdvancedBookingOptions.advancedBookingOptions`,
    defaultMessage: 'Advanced Booking Options',
  },
  limitFutureSessions: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.AdvancedBookingOptions.limitFutureSessions`,
    defaultMessage: 'Limit future sessions',
  },
  preventBookingAfterDays: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.AdvancedBookingOptions.preventBookingAfterDays`,
    defaultMessage:
      'Prevent applicants from booking sessions beyond this many days from today',
  },
  showOneSessionPerTimeframe: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.AdvancedBookingOptions.showOneSessionPerTimeframe`,
    defaultMessage: 'Last minute bookings',
  },
  preventBookingForHours: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.AdvancedBookingOptions.preventBookingForHours`,
    defaultMessage:
      'Prevent applicants from booking a session within this many hours',
  },
  instantInterviewWarning: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.AdvancedBookingOptions.instantInterviewWarning`,
    defaultMessage:
      'This setting will be ignored by instant interview enabled slots',
  },
  maxReschedules: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.AdvancedBookingOptions.maxReschedules`,
    defaultMessage: 'Max reschedules',
  },
  limitRescheduleCount: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.AdvancedBookingOptions.limitRescheduleCount`,
    defaultMessage: 'Prevent applicants from rescheduling this many times',
  },
  lastMinuteRescheduling: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.AdvancedBookingOptions.lastMinuteRescheduling`,
    defaultMessage: 'Last minute rescheduling',
  },
  limitCancelTimeHours: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.AdvancedBookingOptions.limitCancelTimeHours`,
    defaultMessage:
      'Prevent applicants from rescheduling within this many hours',
  },
  placeholderDays: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.AdvancedBookingOptions.placeholderDays`,
    defaultMessage: 'days',
  },
  placeholderHours: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.AdvancedBookingOptions.placeholderHours`,
    defaultMessage: 'hours',
  },
  placeholderTimes: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.AdvancedBookingOptions.placeholderTimes`,
    defaultMessage: 'times',
  },
});
