import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import {
  makeSelectAccountSlug,
  makeSelectCountryCode,
} from 'containers/Auth_old/selectors';
import { addMessageAction } from 'containers/FlashMessage/actions';
import { USD_INR_RATE } from 'containers/SourcingPurchaseNew/constants';
import { apiPost } from 'utils/axios';
import { isAmazonIndia } from 'utils/constants';

export default function useSourcingPurchase(successAction, successMsg) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const accountSlug = useSelector(makeSelectAccountSlug());
  const countryCode = useSelector(makeSelectCountryCode());

  const handleCreateSourcingPurchase = async (funnelSlug, data) => {
    const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/${accountSlug}/jobs/${funnelSlug}/sourcing`;
    setError('');
    setIsLoading(true);

    try {
      const { budget } = data;
      const res = await apiPost(url, {
        ...data,
        budget: isAmazonIndia(accountSlug, countryCode)
          ? Math.round(budget / USD_INR_RATE)
          : budget,
      });

      if (res.data) {
        dispatch(addMessageAction(successMsg, 'success'));
        successAction(res.data);
        setError('');
      }
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error) {
      dispatch(addMessageAction(error, 'error'));
    }
  }, [dispatch, error]);

  return { handleCreateSourcingPurchase, isLoading };
}
