import {
  CustomTooltip,
  EditPencil,
  IconButton,
  Trashcan,
} from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import {
  RestrictedLocation,
  WorkflowSchedulerStage,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import { useSimpleToggle } from 'hooks';
import produce from 'immer';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ConfirmationModal } from '../../../../../../../../components/ConfirmationModal';
import { AddressFormModal } from '../AddressFormModal';
import messages from './messages';
import { useStyles } from './styles';

export interface AddressRowProps {
  restrictedLocation: RestrictedLocation;
  setStage: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
  stage: WorkflowSchedulerStage;
}

export const AddressRow: FC<AddressRowProps> = ({
  restrictedLocation,
  setStage,
  stage,
}) => {
  const styles = useStyles();
  const intl = useIntl();

  const {
    showContent: isDeleteMessageModalOpen,
    toggle: toggleIsDeleteMessageModalOpen,
  } = useSimpleToggle(false);

  const {
    showContent: isAddressFormModalOpen,
    off: closeAddressFormModal,
    on: openAddressFormModal,
  } = useSimpleToggle(false);

  const onConfirmDelete = () => {
    setStage(
      produce(stage, draftStage => {
        const draftRestrictedLocations =
          (draftStage.additional_info &&
            draftStage.additional_info.restricted_locations_attributes) ||
          [];

        if (draftRestrictedLocations.length > 0) {
          const idx = draftRestrictedLocations.findIndex(
            location => location.location === restrictedLocation?.location,
          );

          draftRestrictedLocations[idx] = {
            ...restrictedLocation,
            _destroy: true,
          };
        }
      }),
    );
  };

  return (
    <>
      <Grid
        container
        className={styles.row}
        alignItems="center"
        justify="space-between"
      >
        <Typography variant="body2" className={styles.locationText}>
          {restrictedLocation.location}
        </Typography>
        <Grid>
          <CustomTooltip dense title={intl.formatMessage(messages.edit)}>
            <IconButton
              aria-label={intl.formatMessage(messages.edit)}
              className={styles.editMessageButton}
              onClick={openAddressFormModal}
            >
              <EditPencil
                viewBox="0 0 16 16"
                className={styles.editMessageIcon}
              />
            </IconButton>
          </CustomTooltip>
          <CustomTooltip dense title={intl.formatMessage(messages.delete)}>
            <IconButton
              aria-label={intl.formatMessage(messages.delete)}
              className={styles.deleteMessageButton}
              onClick={toggleIsDeleteMessageModalOpen}
            >
              <Trashcan
                viewBox="0 0 16 16"
                className={styles.deleteMessageIcon}
              />
            </IconButton>
          </CustomTooltip>
        </Grid>
      </Grid>
      {isAddressFormModalOpen && (
        <AddressFormModal
          isOpen={isAddressFormModalOpen}
          onClose={closeAddressFormModal}
          restrictedLocation={restrictedLocation}
          setStage={setStage}
          stage={stage}
        />
      )}
      {isDeleteMessageModalOpen && (
        <ConfirmationModal
          ariaLabelledBy={intl.formatMessage(messages.deleteOnsiteAddress)}
          bodyContent={<FormattedMessage {...messages.askDelete} />}
          cancelButtonContent={<FormattedMessage {...messages.cancel} />}
          confirmButtonContent={<FormattedMessage {...messages.delete} />}
          negative
          onCancel={toggleIsDeleteMessageModalOpen}
          onConfirm={onConfirmDelete}
          title={intl.formatMessage(messages.deleteOnsiteAddress)}
        />
      )}
    </>
  );
};
