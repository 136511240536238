import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  pagination: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  paginationContent: {
    position: 'relative',
  },
  paginationLeftContent: {
    position: 'absolute',
    left: 0,
    marginLeft: theme.spacing(2),
  },
  pageSelectContainer: {
    maxWidth: 400,
  },
  pageSelect: {
    alignItems: 'center',
  },
  pageSelectInputRoot: {
    width: 53,
    height: 32,
    border: `1px solid ${theme.palette.common.gray300}`,
    borderRadius: 3,
    margin: theme.spacing(0, 2),
  },
  pageSelectInput: {
    textAlign: 'center',
  },
  rowsPerPage: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowsPerPageSelect: {
    marginLeft: theme.spacing(1),
  },
  verticalCenter: {
    alignItems: 'center',
  },
  lastPage: {
    marginLeft: theme.spacing(2),
  },
}));
