import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  customizeColumns: {
    id: 'app.Calendar.EventManager.customizeColumns',
    defaultMessage: 'Customize Columns',
  },
  eventManagerHeading: {
    id: 'app.Calendar.EventManager.eventManagerHeading',
    defaultMessage: 'Event manager',
  },
  scheduledSessions: {
    id: 'app.Calendar.EventManager.scheduledSessions',
    defaultMessage: 'Scheduled Sessions',
  },
  eventMangerDescription: {
    id: 'app.Calendar.EventManager.eventMangerDescription',
    defaultMessage:
      'For openings containing an event stage you can create and manage the event you create here.',
  },
  createEvent: {
    id: 'app.Calendar.EventManager.createEvent',
    defaultMessage: 'Create Event',
  },
  timeErrorMessage: {
    id: 'app.Calendar.EventManager.timeErrorMessage',
    defaultMessage: 'Must be in the future',
  },
  deleteSuccess: {
    id: 'app.Calendar.EventManager.deleteSuccess',
    defaultMessage: 'Success',
  },
  error: {
    id: 'app.Calendar.EventManager.error',
    defaultMessage: 'Something went wrong',
  },
  requiredField: {
    id: 'app.Calendar.EventManager.requiredField',
    defaultMessage: 'Required Field',
  },
  noHostAssigned: {
    id: 'app.Calendar.EventManager.noHostAssigned',
    defaultMessage: 'No host assigned',
  },
  addEvent: {
    id: 'app.Calendar.EventManager.addEvent',
    defaultMessage: 'Add event',
  },
  duplicateEvent: {
    id: 'app.Calendar.EventManager.duplicateEvent',
    defaultMessage: 'Duplicate event',
  },
  editEvent: {
    id: 'app.Calendar.EventManager.editEvent',
    defaultMessage: 'Edit event',
  },
  openingCount: {
    id: 'app.Calendar.EventManager.openingCount',
    defaultMessage:
      '{openingCount, plural,=0 {All Openings} one {# Opening} other {# Openings}}',
  },
  openingAdd: {
    id: 'app.Calendar.EventManager.openingAdd',
    defaultMessage: 'Add Opening to Filter',
  },
  openingClear: {
    id: 'app.Calendar.EventManager.openingClear',
    defaultMessage: 'Clear Opening Filter',
  },
  openingRemove: {
    id: 'app.Calendar.EventManager.openingRemove',
    defaultMessage: 'Remove {opening}',
  },
  searchOpenings: {
    id: 'app.Calendar.EventManager.searchOpenings',
    defaultMessage: 'Search Openings',
  },
  hasOpenings: {
    id: 'app.Calendar.EventManager.hasOpenings',
    defaultMessage: 'Openings included in filter:',
  },
  manageRoster: {
    id: 'app.Calendar.ManageRoster.manageRoster',
    defaultMessage: 'Manage Roster',
  },
  submit: {
    id: 'app.Calendar.ManageRoster.submit',
    defaultMessage: 'Submit',
  },
  cancel: {
    id: 'app.Calendar.ManageRoster.cancel',
    defaultMessage: 'Cancel',
  },
  action: {
    id: 'app.Calendar.ManageRoster.action',
    defaultMessage: 'Action to perform',
  },
  updated: {
    id: 'app.Calendar.ManageRoster.updated',
    defaultMessage: 'Attendance successfully updated',
  },
  advanced: {
    id: 'app.Calendar.ManageRoster.advanced',
    defaultMessage: 'Advanced to next stage succesfully',
  },
  selectedCount: {
    id: 'app.Calendar.ManageRoster.selectedCount',
    defaultMessage: '{count} selected',
  },
  selectAll: {
    id: 'app.Calendar.ManageRoster.selectAll',
    defaultMessage: 'select all',
  },
  selectNone: {
    id: 'app.Calendar.ManageRoster.selectNone',
    defaultMessage: 'select none',
  },
  attended: {
    id: 'app.Calendar.ManagerRoster.attended',
    defaultMessage: 'Mark attended',
  },
  attendedDrawer: {
    id: 'app.Calendar.ManagerRoster.attendedDrawer',
    defaultMessage: 'Attended',
  },
  absent: {
    id: 'app.Calendar.ManagerRoster.absent',
    defaultMessage: 'Mark not attended',
  },
  absentDrawer: {
    id: 'app.Calendar.ManagerRoster.absentDrawer',
    defaultMessage: 'Not attended',
  },
  advance: {
    id: 'app.Calendar.ManagerRoster.advance',
    defaultMessage: 'Move to next stage',
  },
  name: {
    id: 'app.Calendar.ManageRoster.name',
    defaultMessage: 'Applicant',
  },
  phone: {
    id: 'app.Calendar.ManageRoster.phone',
    defaultMessage: 'Phone',
  },
  opening: {
    id: 'app.Calendar.ManageRoster.opening',
    defaultMessage: 'Opening',
  },
  stage: {
    id: 'app.Calendar.ManageRoster.stage',
    defaultMessage: 'Stage',
  },
  QuickActions: {
    id: 'app.Calendar.ManageRoster.QuickActions',
    defaultMessage: 'Quick Actions',
  },
  Yes: {
    id: 'app.Calendar.ManageRoster.Yes',
    defaultMessage: 'Yes',
  },
  No: {
    id: 'app.Calendar.ManageRoster.No',
    defaultMessage: 'No',
  },
  sendEmail: {
    id: 'app.Calendar.ManageRoster.sendEmail',
    defaultMessage: 'Send email',
  },
  sendSMS: {
    id: 'app.Calendar.ManageRoster.sendSMS',
    defaultMessage: 'Send text/SMS',
  },
  moveTo: {
    id: 'app.Calendar.ManageRoster.moveTo',
    defaultMessage: 'Move to...',
  },
  noEvent: {
    id: 'app.Calendar.Event.NoEvent',
    defaultMessage: 'No events found',
  },
  noEventDetail: {
    id: 'app.Calendar.Event.NoEventDetail',
    defaultMessage: 'There are no events for {title}.',
  },
});
