import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addAddress: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.OnsiteAddressCard.addAddress`,
    defaultMessage: 'Add Address',
  },
  onsiteAddress: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.OnsiteAddressCard.onsiteAddress`,
    defaultMessage: 'Onsite Address',
  },
  manageOnsiteAddressDescription: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.OnsiteAddressCard.manageOnsiteAddressDescription`,
    defaultMessage:
      'Manage onsite addresses where your sessions will take place.',
  },
});
