import { useTheme } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const ExportIcon = props => {
  const theme = useTheme();
  const fill = theme.palette.common.blue400;
  return (
    <SvgIcon {...props}>
      <path
        d="M16.5861 4.586L9.17212 12L12.0001 14.828L19.4141 7.414L24.0001 12V0H12.0001L16.5861 4.586Z"
        fill={fill}
      />
      <path
        d="M20 20H4V4H8V0H4C2.93913 0 1.92172 0.421427 1.17157 1.17157C0.421427 1.92172 0 2.93913 0 4L0 20C0 21.0609 0.421427 22.0783 1.17157 22.8284C1.92172 23.5786 2.93913 24 4 24H20C21.0609 24 22.0783 23.5786 22.8284 22.8284C23.5786 22.0783 24 21.0609 24 20V16H20V20Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

export default ExportIcon;
