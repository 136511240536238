import { defineMessages } from 'react-intl';

export default defineMessages({
  apiError: {
    id: 'app.components.WorkflowEditor.StageContextProvider.apiError',
    defaultMessage:
      'An error occurred. Please check your network or try again later.',
  },
  changesSavedSuccessfully: {
    id: 'app.components.WorkflowEditor.StageContextProvider.changesSavedSuccessfully',
    defaultMessage: 'Changes saved successfully',
  },
  forbiddenError: {
    id: 'app.components.WorkflowEditor.StageContextProvider.forbiddenError',
    defaultMessage:
      'You do not have access to this workflow. Contact your account admin for permission',
  },
});
