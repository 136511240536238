import { ExpansionPanel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const Accordion = withStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: theme.palette.common.gray100,
    paddingBottom: theme.spacing(1),

    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
}))(ExpansionPanel);

export default Accordion;
