import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import React, { VFC } from 'react';
import { useIntl } from 'react-intl';

import { messages } from './messages';
import { useStyles } from './styles';

export const sortOptions = [
  'Newest',
  'Oldest',
  'Confidence - Highest',
  'Confidence - Lowest',
  'Status - Ascending',
  'Status - Descending',
] as const;
export type SelectedSortBy = (typeof sortOptions)[number];
export interface SortOption {
  key: SelectedSortBy;
}

export interface SortProps {
  selectedSortBy: SelectedSortBy;
  handleSortChange: (sortBy: SelectedSortBy) => void;
}

export const Sort: VFC<SortProps> = ({ selectedSortBy, handleSortChange }) => {
  const styles = useStyles();
  const intl = useIntl();

  return (
    <Grid item>
      <StyledReactSelect
        options={sortOptions.map(item => ({
          key: item,
        }))}
        value={{ key: selectedSortBy }}
        onChange={(option: SortOption) => handleSortChange(option.key)}
        label={intl.formatMessage(messages.sortLabel)}
        aria-label={intl.formatMessage(messages.sortLabel)}
        getOptionLabel={(option: SortOption) => option.key}
        getOptionValue={(option: SortOption) => option.key}
        className={styles.selectSortInput}
      />
    </Grid>
  );
};
