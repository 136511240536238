import validator from 'validator';

export const isValidURL = (urlString: string): boolean => {
  return validator.isURL(urlString);
};

function urlWithProtocol(inputUrl: string): string {
  let url = inputUrl;
  if (!/^https?:\/\//i.test(url)) {
    url = `https://${url}`;
  }
  return url;
}

export const extractDomain = (urlString: string): string => {
  try {
    const domain = new URL(urlWithProtocol(urlString));
    return domain.hostname;
  } catch (error) {
    return '';
  }
};
