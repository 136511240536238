import { produce } from 'immer';

import {
  CREATE_STAGE_FILTER_SUCCESS,
  DELETE_STAGE_FILTER_SUCCESS,
  FETCH_STAGE_FILTERS_SUCCESS,
  FUNNELS_SEARCH_COMPLETE,
  FUNNELS_SEARCH_INIT,
  SET_SELECTED_FILTER,
  UPDATE_FUNNELS_SEARCH,
  UPDATE_STAGE_FILTER_SUCCESS,
} from './constants';

export const initialState = {
  stageFilters: [],
  selectedFilter: null,
  search: '',
  isFetchingFunnels: false,
  jobsData: [],
};

const sortFilters = (a, b) => a.title.localeCompare(b.title);

const jobsStagesFilterPopupReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case FETCH_STAGE_FILTERS_SUCCESS:
        draft.stageFilters = action.stageFilters;
        draft.stageFilters.sort(sortFilters);
        break;
      case UPDATE_STAGE_FILTER_SUCCESS: {
        draft.selectedFilter = action.stageFilter;
        draft.stageFilters = draft.stageFilters.map(stageFilter => {
          if (stageFilter.id === action.stageFilter.id)
            return action.stageFilter;
          return stageFilter;
        });
        draft.stageFilters.sort(sortFilters);
        break;
      }
      case CREATE_STAGE_FILTER_SUCCESS:
        draft.selectedFilter = action.stageFilter;
        draft.stageFilters.push(action.stageFilter);
        draft.stageFilters.sort(sortFilters);
        break;
      case DELETE_STAGE_FILTER_SUCCESS: {
        draft.selectedFilter = null;
        draft.stageFilters = draft.stageFilters.filter(
          stageFilter => stageFilter.id !== action.filterId,
        );
        break;
      }
      case SET_SELECTED_FILTER:
        draft.selectedFilter = action.selectedFilter;
        break;
      case UPDATE_FUNNELS_SEARCH:
        draft.search = action.search;
        break;
      case FUNNELS_SEARCH_INIT:
        draft.isFetchingFunnels = true;
        break;
      case FUNNELS_SEARCH_COMPLETE:
        draft.jobsData = action.jobsData;
        draft.isFetchingFunnels = false;
        break;
      // no default
    }
  });

export default jobsStagesFilterPopupReducer;
