/**
 * Get the date format pattern for the given locale.
 * @example
 *     getDateFormatPattern('en-US');   // mm/dd/yyyy
 *     getDateFormatPattern('en-GB');   // dd/mm/yyyy
 *     getDateFormatPattern('de');      // dd.mm.yyyy
 */
export const getDateFormatPattern = (locale: string) => {
  const getPatternForPart = (part: Intl.DateTimeFormatPart) => {
    switch (part.type) {
      case 'day':
        return 'dd';
      case 'month':
        return 'mm';
      case 'year':
        return 'yyyy';
      case 'literal':
        return part.value;
      default:
        return '';
    }
  };

  return new Intl.DateTimeFormat(locale)
    .formatToParts(new Date('2021-01-01'))
    .map(getPatternForPart)
    .join('');
};
