import { TableCell } from '@material-ui/core';
import React, { FC, memo } from 'react';

import useStyles from './styles';

export interface ApplicantTableV2ProgressCellProps {
  completedStages?: number;
  totalStages?: number;
}

export const ApplicantTableV2ProgressCell: FC<
  ApplicantTableV2ProgressCellProps
> = ({ completedStages, totalStages }) => {
  const styles = useStyles();

  return (
    <TableCell role="cell" className={styles.td}>
      {completedStages && totalStages && (
        <div className={styles.progressBarContainer}>
          <div
            className={styles.progressBar}
            style={{
              width: `${Math.round((completedStages / totalStages) * 100)}%`,
            }}
          />
        </div>
      )}
    </TableCell>
  );
};

export default memo(ApplicantTableV2ProgressCell);
