import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Typography } from '@material-ui/core';
import {
  WorkflowDocumentSignatureStage,
  WorkflowDocumentSigningStage,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import { produce } from 'immer';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import {
  makeSelectIsEmbeddedDocusignEnabled,
  makeSelectWhoami,
} from 'containers/Auth_old/selectors';
import { StageSettingCard } from 'containers/WorkflowEditor/components/StageSettingCard';

import { SettingSwitch } from '../../../SettingSwitch';
import { AutoAdvanceWhenLabelsComplete } from '../../SettingsSwitches';
import { messages } from './messages';
import { useStyles } from './styles';

export interface SettingsProps {
  stage: WorkflowDocumentSigningStage | WorkflowDocumentSignatureStage;
  setStage: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
}

type AutoAdvanceRule =
  | 'applicant_all_templates'
  | 'applicant_any_template'
  | 'all_roles_all_templates'
  | 'all_roles_any_template';

type AutoAdvanceOption = {
  label: React.ReactNode;
  value: AutoAdvanceRule;
};

export const Settings: FC<SettingsProps> = ({ stage, setStage }) => {
  const styles = useStyles();
  const intl = useIntl();
  const { accountSlug } = useParams<{
    accountSlug: string;
  }>();

  const isDocusign = stage.type === 'DocumentSignatureStage';
  const isSubstage = Boolean(stage.parent_stage_id);
  const hasStageLabels = Boolean(stage.has_stage_labels);
  const isEmbeddedDocusign = useSelector(makeSelectIsEmbeddedDocusignEnabled());

  const allRolesAllTemplatesOption: AutoAdvanceOption = {
    label: intl.formatMessage(messages.allRolesAllTemplates, {
      b: chunks => <b>{chunks}</b>,
      u: chunks => <u>{chunks}</u>,
    }),
    value: 'all_roles_all_templates',
  };
  const embeddedDocusignOptions: AutoAdvanceOption[] = [
    {
      label: intl.formatMessage(messages.applicantAllTemplates, {
        b: chunks => <b>{chunks}</b>,
        u: chunks => <u>{chunks}</u>,
      }),
      value: 'applicant_all_templates',
    },
    {
      label: intl.formatMessage(messages.allRolesAllTemplates, {
        b: chunks => <b>{chunks}</b>,
        u: chunks => <u>{chunks}</u>,
      }),
      value: 'all_roles_all_templates',
    },
  ];

  const allOptions: AutoAdvanceOption[] = [
    {
      label: intl.formatMessage(messages.applicantAllTemplates, {
        b: chunks => <b>{chunks}</b>,
        u: chunks => <u>{chunks}</u>,
      }),
      value: 'applicant_all_templates',
    },
    {
      label: intl.formatMessage(messages.applicantAnyTemplate, {
        b: chunks => <b>{chunks}</b>,
        u: chunks => <u>{chunks}</u>,
      }),
      value: 'applicant_any_template',
    },
    allRolesAllTemplatesOption,
    {
      label: intl.formatMessage(messages.allRolesAnyTemplate, {
        b: chunks => <b>{chunks}</b>,
        u: chunks => <u>{chunks}</u>,
      }),
      value: 'all_roles_any_template',
    },
  ];

  const docusignOptions = isEmbeddedDocusign
    ? embeddedDocusignOptions
    : [allRolesAllTemplatesOption];

  const autoAdvanceOptions: AutoAdvanceOption[] = isDocusign
    ? docusignOptions
    : allOptions;

  const { docusign_qes_feature_enabled: docusignQesFeatureEnabled } =
    useSelector(makeSelectWhoami());

  const showInPortal = !stage.advanced_settings.is_hidden_from_portal;
  const isEmailOwnerToggleEnabled = Boolean(stage.extra.send_copy_to_owner);
  const isAutoAdvanceRuleEnabled =
    Boolean(stage.additional_info.auto_advance_rule) &&
    stage.additional_info.auto_advance_rule !== 'disabled';
  const isQesEnabled =
    stage.type === 'DocumentSignatureStage' &&
    Boolean(stage.additional_info.qes_enabled);

  return (
    <StageSettingCard
      buttonText={intl.formatMessage(messages.manageIntegration)}
      buttonTextColor="primary"
      href={`${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/integrations/screening`}
      target="_blank"
      title={<FormattedMessage {...messages.settings} />}
      variant="button"
    >
      {isDocusign && (
        <Typography className={styles.bodyText} variant="body2">
          <FormattedMessage
            {...messages.isDocusignIntegration}
            values={{ signingProvider: 'DocuSign' }}
          />
        </Typography>
      )}

      <div className={styles.toggleGroupContainer}>
        <SettingSwitch
          dataKey="auto-advance-rule-toggle"
          checked={isAutoAdvanceRuleEnabled}
          disabled={isSubstage}
          onChange={() => {
            setStage(
              produce(stage, draftStage => {
                if (isDocusign) {
                  draftStage.additional_info.auto_advance_rule =
                    isAutoAdvanceRuleEnabled
                      ? 'disabled'
                      : 'all_roles_all_templates';
                } else {
                  draftStage.additional_info.auto_advance_rule =
                    isAutoAdvanceRuleEnabled
                      ? 'disabled'
                      : 'applicant_all_templates';
                }
              }),
            );
          }}
          label={<FormattedMessage {...messages.moveApplicantToNextStage} />}
          fieldComponent={
            isAutoAdvanceRuleEnabled ? (
              <StyledReactSelect
                aria-label={intl.formatMessage(messages.autoAdvanceSelectLabel)}
                isDisabled={isSubstage}
                onChange={({ value }: AutoAdvanceOption) => {
                  setStage(
                    produce(stage, draftStage => {
                      draftStage.additional_info.auto_advance_rule = value;
                    }),
                  );
                }}
                options={autoAdvanceOptions}
                value={autoAdvanceOptions.find(
                  ({ value }) =>
                    value === stage.additional_info.auto_advance_rule,
                )}
              />
            ) : null
          }
        />
        {!isSubstage && (
          <SettingSwitch
            dataKey="show-in-portal-toggle"
            checked={showInPortal}
            onChange={() => {
              setStage(
                produce(stage, draftStage => {
                  draftStage.advanced_settings.is_hidden_from_portal =
                    !draftStage.advanced_settings.is_hidden_from_portal;
                }),
              );
            }}
            label={<FormattedMessage {...messages.showStageInPortal} />}
          />
        )}
        {!isDocusign && (
          <SettingSwitch
            dataKey="email-owner-toggle"
            checked={isEmailOwnerToggleEnabled}
            onChange={() => {
              setStage(
                produce(stage, draftStage => {
                  draftStage.extra.send_copy_to_owner =
                    !isEmailOwnerToggleEnabled;
                }),
              );
            }}
            label={<FormattedMessage {...messages.emailDocumentsToOwner} />}
          />
        )}
        {stage.type === 'DocumentSignatureStage' &&
          docusignQesFeatureEnabled && (
            <SettingSwitch
              dataKey="is-qes-enabled"
              checked={isQesEnabled}
              onChange={() => {
                setStage(
                  produce(stage, draftStage => {
                    draftStage.additional_info.qes_enabled = !isQesEnabled;
                  }),
                );
              }}
              tooltipDescription={intl.formatMessage(messages.qesDescription)}
              label={
                <FormattedMessage {...messages.qualifiedElectronicSignature} />
              }
            />
          )}
        {hasStageLabels && (
          <AutoAdvanceWhenLabelsComplete stage={stage} setStage={setStage} />
        )}
      </div>
    </StageSettingCard>
  );
};
