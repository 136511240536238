import { defineMessages } from 'react-intl';

export const scope = 'app.images';

export default defineMessages({
  editIconTitle: {
    id: `${scope}.editIcon`,
    defaultMessage: 'Edit Icon',
  },
  editIconDescription: {
    id: `${scope}.editIconDescription`,
    defaultMessage: 'This icon is used to indicate a user may edit data',
  },
  trashcanIconTitle: {
    id: `${scope}.trashcanIcon`,
    defaultMessage: 'Trashcan Icon',
  },
  trashcanIconDescription: {
    id: `${scope}.trashcanIconDescription`,
    defaultMessage: 'This icon is used to indicate a user may clear data',
  },
});
