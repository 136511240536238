import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { FormControlLabel, Grid, Switch, Typography } from '@material-ui/core';
import { User, WorkflowI9FormStage } from 'api-clients/monolith';
import produce from 'immer';
import React, { useContext, VFC } from 'react';
import { useIntl } from 'react-intl';

import { StageSettingCard } from 'containers/WorkflowEditor/components/StageSettingCard';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import {
  AUTO_ADVANCE_ON_COMPLETION,
  EMAIL_I9_FORM,
  EMAIL_I9_SECTION_ONE,
  GET_SSN_CONFIRMATION,
  IS_HIDDEN_FROM_PORTAL,
  SHOULD_AUTO_ADVANCE_WHEN_LABELS_COMPLETE,
} from './constants';
import { messages } from './messages';
import { useStyles } from './styles';

export interface I9FormStageProps {
  stage: WorkflowI9FormStage;
}

export const I9Form: VFC<I9FormStageProps> = ({ stage }) => {
  const styles = useStyles();
  const intl = useIntl();

  const { setStage } = useContext(StageContext);
  const {
    additional_info: additionalInfo,
    advanced_settings: {
      is_hidden_from_portal: isHiddenFromPortal,
      should_auto_advance_when_labels_complete:
        shouldAutoAdvanceWhenLabelsComplete,
    },
    extra: {
      auto_advance_on_completion: autoAdvanceOnCompletion,
      get_ssn_confirmation: getSSNConfirmation,
      email_i9_form: emailI9Form,
      email_i9_section_one: emailI9SectionOne,
      email_i9_form_recipient: emailI9FormRecipient,
    },
  } = stage;

  const userOptions: User[] = additionalInfo.user_options;
  const hasStageLabels = stage.has_stage_labels;
  const onToggleChange =
    (
      key:
        | typeof AUTO_ADVANCE_ON_COMPLETION
        | typeof IS_HIDDEN_FROM_PORTAL
        | typeof GET_SSN_CONFIRMATION
        | typeof EMAIL_I9_FORM
        | typeof EMAIL_I9_SECTION_ONE
        | typeof SHOULD_AUTO_ADVANCE_WHEN_LABELS_COMPLETE,
    ) =>
    () => {
      setStage(
        produce(stage, draftStage => {
          if (
            key === IS_HIDDEN_FROM_PORTAL ||
            key === SHOULD_AUTO_ADVANCE_WHEN_LABELS_COMPLETE
          ) {
            draftStage.advanced_settings[key] =
              !draftStage.advanced_settings[key];
          } else {
            draftStage.extra[key] = !draftStage.extra[key];
          }
        }),
      );
    };

  const onChangeEmailRecipient = (recipient: User) => {
    setStage(currentStage => ({
      ...currentStage,
      extra: {
        ...currentStage.extra,
        email_i9_form_recipient: recipient.email,
      },
    }));
  };

  return (
    <StageSettingCard
      title={intl.formatMessage(messages.I9Form)}
      isLoading={false}
      variant="default"
    >
      <Grid container className={styles.container}>
        <Grid item xs={12} className={styles.paddingBottom}>
          <FormControlLabel
            key={intl.formatMessage(messages.moveApplicant)}
            label={
              <Typography variant="body2" className={styles.switchLabel}>
                {intl.formatMessage(messages.moveApplicant)}
              </Typography>
            }
            control={
              <Switch
                checked={Boolean(autoAdvanceOnCompletion)}
                onChange={onToggleChange(AUTO_ADVANCE_ON_COMPLETION)}
              />
            }
          />
        </Grid>
        <Grid item xs={12} className={styles.paddingBottom}>
          <FormControlLabel
            key={intl.formatMessage(messages.showStageInPortal)}
            label={
              <Typography variant="body2" className={styles.switchLabel}>
                {intl.formatMessage(messages.showStageInPortal)}
              </Typography>
            }
            control={
              <Switch
                checked={!isHiddenFromPortal}
                onChange={onToggleChange(IS_HIDDEN_FROM_PORTAL)}
              />
            }
          />
        </Grid>
        {!stage.ax_enabled && (
          <Grid item xs={12} className={styles.paddingBottom}>
            <FormControlLabel
              key={intl.formatMessage(messages.getSSN)}
              label={
                <Typography variant="body2" className={styles.switchLabel}>
                  {intl.formatMessage(messages.getSSN)}
                </Typography>
              }
              control={
                <Switch
                  checked={getSSNConfirmation}
                  onChange={onToggleChange(GET_SSN_CONFIRMATION)}
                />
              }
            />
          </Grid>
        )}
        {hasStageLabels && (
          <Grid item xs={12} className={styles.paddingBottom}>
            <FormControlLabel
              key={intl.formatMessage(
                messages.shouldAutoAdvanceWhenLabelsComplete,
              )}
              label={
                <Typography variant="body2" className={styles.switchLabel}>
                  {intl.formatMessage(
                    messages.shouldAutoAdvanceWhenLabelsComplete,
                  )}
                </Typography>
              }
              control={
                <Switch
                  checked={shouldAutoAdvanceWhenLabelsComplete}
                  onChange={onToggleChange(
                    SHOULD_AUTO_ADVANCE_WHEN_LABELS_COMPLETE,
                  )}
                />
              }
            />
          </Grid>
        )}
        <Grid item xs={12} className={styles.i9PaddingBottom}>
          <FormControlLabel
            key={intl.formatMessage(messages.emailPDF)}
            className={styles.emailI9Switch}
            label={
              <Typography variant="body2" className={styles.switchLabel}>
                {intl.formatMessage(messages.emailPDF)}
              </Typography>
            }
            control={
              <Switch
                checked={emailI9Form}
                onChange={onToggleChange(EMAIL_I9_FORM)}
              />
            }
          />

          {emailI9Form && (
            <StyledReactSelect
              className={styles.select}
              aria-label={intl.formatMessage(messages.recipient)}
              label={intl.formatMessage(messages.recipient)}
              options={userOptions}
              value={userOptions.find(user => {
                return user.email === emailI9FormRecipient;
              })}
              getOptionLabel={(user: User) => user.email}
              getOptionValue={(user: User) => user.email}
              onChange={onChangeEmailRecipient}
            />
          )}
        </Grid>

        <Grid item xs={12} className={styles.i9PaddingBottom}>
          <FormControlLabel
            key={intl.formatMessage(messages.emailI9SectionOne)}
            className={styles.emailI9Switch}
            label={
              <Typography variant="body2" className={styles.switchLabel}>
                {intl.formatMessage(messages.emailI9SectionOne)}
              </Typography>
            }
            control={
              <Switch
                checked={emailI9SectionOne}
                onChange={onToggleChange(EMAIL_I9_SECTION_ONE)}
              />
            }
          />

          {emailI9SectionOne && (
            <StyledReactSelect
              className={styles.select}
              aria-label={intl.formatMessage(messages.recipient)}
              label={intl.formatMessage(messages.recipient)}
              options={userOptions}
              value={userOptions.find(user => {
                return user.email === emailI9FormRecipient;
              })}
              getOptionLabel={(user: User) => user.email}
              getOptionValue={(user: User) => user.email}
              onChange={onChangeEmailRecipient}
            />
          )}
        </Grid>
      </Grid>
    </StageSettingCard>
  );
};
