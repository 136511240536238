import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  success: {
    id: 'app.containers.AIWorkflowBuilder.MediaMessage.header',
    defaultMessage: 'File uploaded successfully!',
  },
  error: {
    id: 'app.containers.AIWorkflowBuilder.MediaMessage.error',
    defaultMessage: 'Error uploading file!',
  },
});
