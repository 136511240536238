import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'app.containers.Chatbot.AIJobsDirectoryCareerSite.title',
    defaultMessage: 'AI Jobs Directory Career Site',
  },
  header: {
    id: 'app.containers.Chatbot.AIJobsDirectoryCareerSite.header',
    defaultMessage: 'AI Jobs Directory Settings',
  },
  generalSettings: {
    id: 'app.containers.AIJobsDirectoryCareerSite.generalSettings',
    defaultMessage: 'General Settings',
  },
  brandYourWidget: {
    id: 'app.containers.AIJobsDirectoryCareerSite.brandYourWidget',
    defaultMessage: 'Brand your widget',
  },
  brandYourWidgetDesc: {
    id: 'app.containers.AIJobsDirectoryCareerSite.brandYourWidgetDesc',
    defaultMessage:
      'Select a brand to apply its logo and color, as well as all the chatbot widget settings configured on this page.',
  },
  additionaBrandInfo: {
    id: 'app.containers.AIJobsDirectoryCareerSite.additionaBrandInfo',
    defaultMessage:
      'To add additional brands or configure the existing ones, go to Company Settings.',
  },
  aiJobCategorization: {
    id: 'app.containers.AIJobsDirectoryCareerSite.aiJobCategorization',
    defaultMessage: 'AI Job Categorization',
  },
  categorizationSwitchLabel: {
    id: 'app.containers.AIJobsDirectoryCareerSite.categorizationSwitchLabel',
    defaultMessage: 'Categorize jobs by Job Type',
  },
  categorizationSwitchDesc: {
    id: 'app.containers.AIJobsDirectoryCareerSite.categorizationSwitchDesc',
    defaultMessage:
      'Allow AI to organize Fountain positions into eight different categories by Job Department and Type (ex. Sales, Marketing, etc)',
  },
  experienceLevelSwitchLabel: {
    id: 'app.containers.AIJobsDirectoryCareerSite.experienceLevelSwitchLabel',
    defaultMessage: 'Categorize jobs by Experience Level',
  },
  experienceLevelSwitchDesc: {
    id: 'app.containers.AIJobsDirectoryCareerSite.experienceLevelSwitchDesc',
    defaultMessage:
      'Allow AI to organize positions into three different work experience categories (ex.Entry-level, Mid-level and Senior-level)',
  },
  faqBotSettings: {
    id: 'app.containers.Chatbot.AIJobsDirectoryCareerSite.faqBotSettings',
    defaultMessage: 'FAQ Chatbot Settings',
  },
  showFaqByDefault: {
    id: 'app.containers.Chatbot.AIJobsDirectoryCareerSite.showFaqByDefault',
    defaultMessage: 'Show FAQ chatbot by default',
  },
  showFaqBotDesc: {
    id: 'app.containers.AIJobsDirectoryCareerSite.showFaqBotDesc',
    defaultMessage:
      'This will automatically show the FAQ chatbot when an applicant lands on the AI jobs directory site. Ensure the chatbot has been trained in Web Widget FAQ settings.',
  },
  preFilledFaqQuestionLabel: {
    id: 'app.containers.AIJobsDirectoryCareerSite.preFilledFaqQuestionLabel',
    defaultMessage: 'Pre-filled FAQ chatbot questions',
  },
  preFilledFaqQuestionDesc: {
    id: 'app.containers.AIJobsDirectoryCareerSite.preFilledFaqQuestionDesc',
    defaultMessage:
      'Below are three pre-filled commonly asked questions from applicants. Edit the default questions or add up to five questions.',
  },
  question: {
    id: 'app.containers.AIJobsDirectoryCareerSite.question',
    defaultMessage: 'Question',
  },
  settingUpdateSuccess: {
    id: 'app.containers.AIJobsDirectoryCareerSite.settingUpdateSuccess',
    defaultMessage: 'Setting updated successfully',
  },
  settingUpdateFailed: {
    id: 'app.containers.AIJobsDirectoryCareerSite.settingUpdateFailed',
    defaultMessage: 'Setting update failed',
  },
  saveSetting: {
    id: 'app.containers.AIJobsDirectoryCareerSite.saveSetting',
    defaultMessage: 'Save',
  },
  previewCareerSite: {
    id: 'app.containers.AIJobsDirectoryCareerSite.previewCareerSite',
    defaultMessage: 'Preview Career Site',
  },
  trainFaqBot: {
    id: 'app.containers.AIJobsDirectoryCareerSite.trainFaqBot',
    defaultMessage:
      'Chatbot must be trained in the Web Widget settings in order to show the FAQ chatbot.',
  },
});
