import { Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import useLocation from 'hooks/useLocation';
import ExportIcon from 'images/ExportIcon';

import messages from './messages';
import useStyles from './styles';

const TemplateMenuFooter = () => {
  const styles = useStyles();
  const { account } = useLocation();
  const url = `${REACT_APP_MONOLITH_BASE_URL}/${account}/message_templates`;

  return (
    <a
      className={styles.link}
      href={url}
      rel="noopener noreferrer"
      target="_blank"
    >
      <Typography variant="h4">
        <FormattedMessage {...messages.dialogAction} />
      </Typography>
      <ExportIcon className={styles.exportIcon} />
    </a>
  );
};

export default TemplateMenuFooter;
