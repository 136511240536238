import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import {
  generateHashStatusMessages,
  getConditionErrorMessage,
} from 'containers/WorkflowEditor/components/Rules/Condition/constants';
import { messages as conditionMessages } from 'containers/WorkflowEditor/components/Rules/Condition/messages';
import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';

import { DocumentSigningRuleProps } from '../../../DocumentSigningRules/types';
import { RulesProps, SharedConditionProps } from '../../../types';

export const AccurateStatus: FC<SharedConditionProps> = ({
  condition,
  ruleId,
  setRules,
  errors,
  setDocumentSigningRules,
}) => {
  const intl = useIntl();

  const { condition_statuses: conditionStatuses } =
    useContext(RulesEditDataContext);
  const { extra } = condition;

  const onChangeStatus = (newStatus: { label: string; value: string }) => {
    const rulesSetter = setRules ?? setDocumentSigningRules;

    if (rulesSetter) {
      rulesSetter(
        produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];

          const idx = draftConditions.findIndex(
            cond => cond.id === condition.id,
          );

          draftConditions[idx].extra.status = newStatus.value;
        }),
      );
    }
  };

  const messageByStatus = generateHashStatusMessages(intl);

  const statusOptions = conditionStatuses?.accurate?.map(status => ({
    label: messageByStatus[status],
    value: status,
  }));

  const selectedStatusOption =
    statusOptions?.find(option => option.value === extra.status) ?? null;

  const statusErrorMessage =
    !extra?.status && getConditionErrorMessage('status', errors);

  return (
    <Grid item xs={6}>
      <StyledReactSelect
        options={statusOptions}
        value={selectedStatusOption}
        onChange={onChangeStatus}
        label={intl.formatMessage(conditionMessages.status)}
        aria-label={intl.formatMessage(conditionMessages.status)}
        isSearchable
        error={statusErrorMessage}
        placeholder=""
        required
      />
    </Grid>
  );
};
