import { defineMessages } from 'react-intl';

export default defineMessages({
  clear: {
    id: 'app.containers.BulkActionQueue.components.BulkActionQueuedItem.clear',
    defaultMessage: 'Clear',
  },
  errorCount: {
    id: 'app.containers.BulkActionQueue.components.BulkActionQueuedItem.errorCount',
    defaultMessage: `{count} {count, plural, one {error} other {errors}} while performing action`,
  },
  errorLoadingApplicants: {
    id: 'app.containers.BulkActionQueue.components.BulkActionQueuedItem.errorLoadingApplicants',
    defaultMessage: 'error loading applicants',
  },
  left: {
    id: 'app.containers.BulkActionQueue.components.BulkActionQueuedItem.left',
    defaultMessage: 'left',
  },
  loadingApplicants: {
    id: 'app.containers.BulkActionQueue.components.BulkActionQueuedItem.loadingApplicants',
    defaultMessage: 'loading applicants',
  },
  pause: {
    id: 'app.containers.BulkActionQueue.components.BulkActionQueuedItem.pause',
    defaultMessage: 'Pause',
  },
  resume: {
    id: 'app.containers.BulkActionQueue.components.BulkActionQueuedItem.resume',
    defaultMessage: 'Resume',
  },
  deletingApplicants: {
    id: 'app.containers.BulkActionQueue.components.BulkActionQueuedItem.deletingApplicants',
    defaultMessage: 'Deleting Applicants',
  },
  rejectingApplicants: {
    id: 'app.containers.BulkActionQueue.components.BulkActionQueuedItem.rejectingApplicants',
    defaultMessage: 'Rejecting Applicants',
  },
  sendingEmail: {
    id: 'app.containers.BulkActionQueue.components.BulkActionQueuedItem.sendingEmail',
    defaultMessage: 'Sending Email',
  },
  sendingSms: {
    id: 'app.containers.BulkActionQueue.components.BulkActionQueuedItem.sendingSms',
    defaultMessage: 'Sending SMS',
  },
  assigningTo: {
    id: 'app.containers.BulkActionQueue.components.BulkActionQueuedItem.assigningTo',
    defaultMessage: 'Assigning to ',
  },
  movingTo: {
    id: 'app.containers.BulkActionQueue.components.BulkActionQueuedItem.movingTo',
    defaultMessage: 'Moving to ',
  },
});
