import { makeStyles } from '@material-ui/core/styles';
/**
 *
 * Material UI styles for Document
 *
 *
 * @see https://material-ui.com/styles/basics
 *
 */

const filePreviewTranslate = rotate => {
  if (rotate === 90) {
    return 'translate(0, -100%)';
  }

  if (rotate === 180) {
    return 'translate(-100%, -100%)';
  }

  if (rotate === 270) {
    return 'translate(-100%, 0)';
  }

  return '';
};

const filePreviewTransformOrigin = rotate => {
  if (rotate === 90 || rotate === 270) {
    return '0 0';
  }

  if (rotate === 180) {
    return 'left top';
  }

  return 'left top';
};

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    display: 'flex',
    flexdDirection: 'column',
  },
  previewHoverActions: {
    position: 'absolute',
    padding: theme.spacing(1),
    bottom: theme.spacing(1),
  },
  previewContainer: {
    backgroundColor: theme.palette.common.gray200,
    border: `${theme.spacing(1)}px solid ${theme.palette.common.white}`,
    borderRadius: 8,
    height: 336,
    marginTop: theme.spacing(2),
    position: 'relative', // for previewHoverActions

    '&.isFullscreen': {
      position: 'fixed',
      left: 0,
      right: 0,
      top: 0,
      zIndex: theme.zIndex.previewFullscreen,
      marginTop: 0,
      height: '100%',
      borderRadius: 0,
    },

    '& $previewHoverActions': {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    '&:hover': {
      cursor: 'pointer',
      '& $previewHoverActions': {
        [theme.breakpoints.up('md')]: {
          display: 'flex',
        },
      },
    },
  },
  previewContainerContent: {
    height: '100%',
    overflow: 'auto',
  },
  previewNotImgContainerContent: {
    height: '100%',
    overflow: 'auto',
    marginLeft: theme.spacing(3),
  },
  previewContainerNotImgFile: {
    backgroundColor: theme.palette.common.white,
    height: 48,
    marginTop: theme.spacing(2),
  },
  previewHoverAction: {
    backgroundColor: theme.palette.common.white,
    width: theme.spacing(4),
    height: theme.spacing(4),

    '&.download': {
      borderRadius: 3,
    },
    '&.fullScreen': {
      borderTopLeftRadius: 3,
      borderBottomLeftRadius: 3,
      borderRight: theme.customBorders.gray200,
    },
    '&.rotateCounterClockwise': {
      borderRight: theme.customBorders.gray200,
    },
    '&.zoomOut': {
      borderTopRightRadius: 3,
      borderBottomRightRadius: 3,
    },
    '&:hover': {
      backgroundColor: theme.palette.common.gray100,
    },
  },
  previewHoverActionDisabled: {
    background: theme.palette.common.gray100,
  },
  filePreview: {
    maxWidth: '100%',
    maxHeight: '100%',

    '&.inactive': {
      background: theme.palette.common.black,
      opacity: 0.3,
    },
    '&.adjusted': {
      transform: props =>
        `rotate(${props.rotate}deg) scale(${props.scale}) ${
          props.scale <= 1 ? '' : filePreviewTranslate(props.rotate)
        }`,
      transformOrigin: props =>
        props.scale <= 1 ? '' : filePreviewTransformOrigin(props.rotate),
    },
  },
  iconButton: {
    padding: theme.spacing(1),
  },
  checkmarkIcon: {
    marginRight: theme.spacing(1),
    height: theme.spacing(1.5),
  },
  recollectIcon: {
    marginRight: theme.spacing(1),
    height: theme.spacing(2),
  },
  noRipple: {
    '&:hover': {
      backgroundColor: 'inherit',
    },
    padding: 0,
  },
  approveButton: {
    width: 110,
  },
  closeFullscreen: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    cursor: 'pointer',
  },
  cancelRecollectionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    alignItems: 'center',
  },
  cancelRecollectionText: {
    color: theme.palette.common.red400,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  recollectionText: {
    display: 'flex',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  noPreview: {
    marginTop: theme.spacing(2),
  },
  approveAndRecollectContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  footer: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(1),
  },
  recollectButton: {
    marginRight: theme.spacing(1),
  },
  approvedAndUnapprovedContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    alignItems: 'center',
    width: '100%',
  },
}));

export default useStyles;
