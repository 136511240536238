import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  opening: {
    id: 'app.components.FilterDropdown.opening',
    defaultMessage:
      '{count, plural,=0 {All Openings} one {# Opening} other {# Openings}}',
  },
  stage: {
    id: 'app.components.FilterDropdown.stage',
    defaultMessage:
      '{count, plural,=0 {All Stages} one {# Stage} other {# Stages}}',
  },
  locationGroup: {
    id: 'app.components.FilterDropdown.locationGroup',
    defaultMessage:
      '{count, plural,=0 {All Location Groups} one {# Location Group} other {# Location Groups}}',
  },
  searchPlaceholder: {
    id: 'app.components.FilterDropdown.searchOpenings',
    defaultMessage: 'Search {name}s',
  },
  clearAll: {
    id: 'app.components.FilterDropdown.clearAll',
    defaultMessage: 'Clear all',
  },
});
