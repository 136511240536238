import { ErrorState, Loader } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import { BotType, ChatbotLogSort, ChatbotService } from 'api-clients/monolith';
import React, { useCallback, useEffect, useState, VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Pagination } from 'components/Pagination';
import { FeedbackCard } from 'containers/Chatbot/ChatbotFeedback/FeedbackCard';
import { useApiService } from 'hooks/useApiService';

import { SelectedStatus } from '../Filters';
import { messages } from './messages';
import { useStyles } from './styles';

export interface ChatbotLogProps {
  botType?: BotType;
  selectedStatus?: SelectedStatus;
  isRefetch: boolean;
  setIsRefetch: (flag: boolean) => void;
  setShowLoadMore: (flag: boolean) => void;
  selectedModels?: Array<string>;
  selectedIntents?: Array<string>;
  page: number;
  setPage: (page: number) => void;
  sortBy?: ChatbotLogSort;
}

export const ChatbotLog: VFC<ChatbotLogProps> = ({
  botType,
  selectedStatus,
  isRefetch,
  setIsRefetch,
  setShowLoadMore,
  selectedModels,
  selectedIntents,
  page,
  setPage,
  sortBy,
}) => {
  const styles = useStyles();

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [lastPage, setLastPage] = useState(1);

  useEffect(() => {
    setLastPage(Math.ceil(totalRows / perPage));
  }, [page, perPage, totalRows]);

  useEffect(() => {
    setPage(1);
    setShowLoadMore(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botType]);

  const { result: chatbotLog, refetch: fetchChatbotLogs } = useApiService(
    useCallback(
      () =>
        ChatbotService.postInternalApiChatbotChatbotLogsChatbotLogs({
          bot_type: botType,
          status: selectedStatus === 'All' ? undefined : selectedStatus,
          models: selectedModels,
          intents: selectedIntents,
          page,
          per_page: perPage,
          sort_by: sortBy,
        }),
      [
        page,
        perPage,
        botType,
        selectedStatus,
        selectedModels,
        selectedIntents,
        sortBy,
      ],
    ),
  );

  useEffect(() => {
    if (chatbotLog.status === 'ready') setTotalRows(chatbotLog.data.count);
  }, [chatbotLog]);

  useEffect(() => {
    if (isRefetch && chatbotLog.status === 'ready') {
      setPage(1);
      fetchChatbotLogs();
      setIsRefetch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatbotLog, isRefetch]);

  if (chatbotLog.isLoading) {
    return <Loader block size="2rem" />;
  }
  if (chatbotLog.isError) {
    return <ErrorState onClick={fetchChatbotLogs} />;
  }
  if (chatbotLog.status === 'ready' && chatbotLog.data.count === 0) {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={styles.noLogs}
      >
        <Typography variant="h4">
          <FormattedMessage {...messages.noChatbotLogs} />
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid item className={styles.logContainer}>
      {chatbotLog.status === 'ready' && (
        <>
          {chatbotLog.data.chatbot_logs.map(log => (
            <FeedbackCard
              key={log.id}
              applicantMessage={log.applicant_message}
              botMessage={log.bot_message}
              botType={log.bot_type}
              intent={log.intent}
              interpretation={log.interpretation}
              feedback={log.feedback}
              feedbackData={log.feedback_data}
              logId={log.id}
              createdAt={new Date(log.created_at)}
              modelName={log.model_name}
              modelMode={log.model_mode}
              confidenceThreshold={log.confidence_threshold}
              score={log.score}
              question={log.question}
            />
          ))}
          {totalRows > perPage && (
            <Pagination
              perPage={perPage}
              currentPage={page}
              lastPage={lastPage}
              setPage={setPage}
              setPerPage={setPerPage}
            />
          )}
        </>
      )}
    </Grid>
  );
};
