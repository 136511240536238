import { MenuItem } from '@fountain/fountain-ui-components';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { makeSelectCountryCode } from 'containers/Auth_old/selectors';
import useUserPermissions from 'hooks/useUserPermissions';
import { isAmazonIndia } from 'utils/constants';

import useNavItemStyles from '../NavigationMenuItem/styles';
import NavLink from '../NavLink';
import messages from './messages';

export default function SourcingNavLink({ menuItem }) {
  const navClasses = useNavItemStyles();
  const { accountSlug } = useParams();
  const { postToJobBoardsPermission, textToApplyPermission } =
    useUserPermissions();

  const countryCode = useSelector(makeSelectCountryCode());

  const amazonIndia = isAmazonIndia(accountSlug, countryCode);

  const permission =
    !amazonIndia && (postToJobBoardsPermission || textToApplyPermission);

  const sourcingNavLink = postToJobBoardsPermission
    ? `/${accountSlug}/sourcing`
    : `/${accountSlug}/sourcing/text_to_apply`;

  const regExes = [
    new RegExp(`/([^/]*)/sourcing/?`),
    new RegExp(`/([^/]*)/payments$`),
    new RegExp(`/([^/]*)/post_to_indeed/?`),
  ];
  const active = regExes.some(re => re.test(window.location.pathname));

  const menuItemProps = {
    classes: { root: navClasses.root, selected: navClasses.selected },
    selected: active,
    disabled: active,
    href: sourcingNavLink,
    isExternalLink: true,
    className: navClasses.menuListItem,
  };

  return (
    <>
      {menuItem ? (
        <MenuItem {...menuItemProps}>
          <FormattedMessage {...messages.sourcing} />
        </MenuItem>
      ) : (
        <NavLink
          active={active}
          display={permission}
          link={sourcingNavLink}
          message={messages.sourcing}
          dataTestid="sourcing"
        />
      )}
    </>
  );
}

SourcingNavLink.propTypes = {
  menuItem: PropTypes.bool,
};
