import { EventAvailableSlot } from 'api-clients/monolith';
import { useEffect } from 'react';

export const useSelectedLocationEffect = (
  handleChange: (params: Partial<EventAvailableSlot>) => void,
  locations: Array<{
    // eslint-disable-next-line camelcase
    onsite_address: string;
  }>,
  // eslint-disable-next-line camelcase
  selectedLocation: { onsite_address: string },
) => {
  useEffect(() => {
    if (locations.length > 1 && selectedLocation) {
      handleChange({ location: selectedLocation?.onsite_address });
    }
  }, [handleChange, locations, selectedLocation]);
};
