import { Button } from '@fountain/fountain-ui-components';
import {
  FormControlLabel,
  Grid,
  InputAdornment,
  OutlinedInput,
  Switch,
  Typography,
} from '@material-ui/core';
import { CancelablePromise, WebhookNotification } from 'api-clients/monolith';
import React, { useEffect, useState, VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { useStyles } from 'containers/Webhooks/styles';

import { EmailAddress } from './EmailAddress';
import { messages } from './messages';

export interface NotificationUpdate {
  notifications: WebhookNotification;
}

export interface ControlledWebhookNotification extends WebhookNotification {
  controlRate?: string;
}

export interface NotificationsProps extends NotificationUpdate {
  setNotification: (
    ...args: Parameters<
      (
        notifications: WebhookNotification,
      ) => CancelablePromise<NotificationUpdate>
    >
  ) => void;
}

export const Notifications: VFC<NotificationsProps> = ({
  notifications,
  setNotification,
}) => {
  const styles = useStyles();
  const [data, setData] =
    useState<ControlledWebhookNotification>(notifications);

  useEffect(() => {
    if (notifications) {
      setData({
        ...notifications,
        controlRate: (notifications.delivery_hourly_rate / 100.0).toFixed(2),
      });
    }
  }, [notifications, setData]);

  const setEmails = (emails: string[]) => {
    setData({ ...data, emails });
  };
  const handleToggleDeliveryFailed = () => {
    setData({ ...data, delivery_failed: !data?.delivery_failed });
  };

  const handleHourlyRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount = e.target.value;
    if (!amount || amount.match(/^\d{1,2}(\.\d{0,2})?$/)) {
      setData({
        ...data,
        controlRate: amount,
        delivery_hourly_rate: Math.floor(parseFloat(amount) * 100),
      });
    }
  };

  const handleToggleIsDisabled = () => {
    setData({ ...data, is_disabled: !data?.is_disabled });
  };

  const handleSubmit = () => {
    if (data) {
      setNotification(data);
    }
  };

  return (
    <>
      <Grid item>
        <Typography variant="h2" className={styles.addedSpacing}>
          <FormattedMessage {...messages.notificationSettingsTitle} />
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" className={styles.addedSpacing}>
          <FormattedMessage {...messages.notificationSubtitle} />
        </Typography>
      </Grid>

      {data && (
        <>
          <Grid item className={styles.roomyTop}>
            <EmailAddress emails={data.emails} handleUpdate={setEmails} />
          </Grid>
          <Grid item className={styles.roomyTop}>
            <FormControlLabel
              control={
                <Switch
                  className={styles.marginSwitch}
                  checked={data.delivery_failed}
                  onChange={handleToggleDeliveryFailed}
                />
              }
              label={
                <Typography display="inline" className={styles.darkText}>
                  <FormattedMessage {...messages.deliveryFails} />
                </Typography>
              }
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              className={styles.standardLabel}
              control={
                <OutlinedInput
                  value={data.controlRate}
                  onChange={handleHourlyRateChange}
                  margin="dense"
                  endAdornment={
                    <InputAdornment position="end">%</InputAdornment>
                  }
                  inputProps={{
                    size: 10,
                  }}
                  disabled={!data.delivery_failed}
                />
              }
              label={
                <Typography display="inline" variant="body2">
                  <FormattedMessage {...messages.deliveryRate} />
                </Typography>
              }
              labelPlacement="top"
            />
          </Grid>
          <Grid item className={styles.roomyTop}>
            <FormControlLabel
              control={
                <Switch
                  className={styles.marginSwitch}
                  checked={data.is_disabled}
                  onChange={handleToggleIsDisabled}
                />
              }
              label={
                <Typography display="inline" className={styles.darkText}>
                  <FormattedMessage {...messages.isDisabled} />
                </Typography>
              }
            />
          </Grid>
          <Grid item className={styles.roomyTop}>
            <Button onClick={handleSubmit} className={styles.addedSpacing}>
              <FormattedMessage {...messages.save} />
            </Button>
          </Grid>
        </>
      )}
    </>
  );
};
