import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import {
  CancelablePromise,
  WorkflowEditorService,
  WorkflowSchoolkeepStage,
} from 'api-clients/monolith';
import { NorthpassCourse } from 'api-clients/monolith/models/NorthpassCourse';
import { NorthpassCourseData } from 'api-clients/monolith/models/NorthpassCourseData';
import produce from 'immer';
import React, { useContext, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import { Error } from 'components/Error';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';

import { StageSettingCard } from '../../../../StageSettingCard';
import { SettingSwitch } from '../../../SettingSwitch';
import {
  AutoAdvanceOnCompletion,
  AutoAdvanceWhenLabelsComplete,
  IsHiddenFromPortal,
} from '../../SettingsSwitches';
import { messages } from './messages';
import { useStyles } from './styles';

export interface IntegrationCardProps {
  stage: WorkflowSchoolkeepStage;
}

export const IntegrationCard: VFC<IntegrationCardProps> = ({ stage }) => {
  const styles = useStyles();
  const intl = useIntl();
  const { accountSlug } = useParams<{
    accountSlug: string;
  }>();
  const hasStageLabels = Boolean(stage.has_stage_labels);

  const { setStage, updateStageResult } = useContext(StageContext);
  const errors =
    (updateStageResult?.isError && updateStageResult.error?.errors) ||
    undefined;

  const { mutation: fetchCourses, result } = useApiServiceMutation<
    NorthpassCourseData,
    () => CancelablePromise<NorthpassCourseData>,
    { errors: string[] }
  >(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    WorkflowEditorService.getInternalApiWorkflowEditorStagesLearningStagesNorthpassCourses,
  );

  const onFocusCoursesDropdown = () => {
    if (result.status !== 'ready') {
      fetchCourses();
    }
  };

  const onCourseChange = (course: NorthpassCourse) => {
    setStage(
      produce(stage, draftStage => {
        draftStage.extra.schoolkeep_course_id = course.id;
        draftStage.extra.schoolkeep_course_title = course.title;
        draftStage.extra.schoolkeep_course_enrollment_link =
          course.enrollment_link;
      }),
    );
  };

  const onToggleAutoAdvanceOnQuizCompletion = () => {
    setStage(
      produce(stage, draftStage => {
        draftStage.extra.auto_advance_on_quiz_completion =
          !draftStage.extra.auto_advance_on_quiz_completion;
      }),
    );
  };

  const courses = result.status === 'ready' ? result.data.courses : [];

  const selectedCourse = {
    id: stage.extra.schoolkeep_course_id,
    title: stage.extra.schoolkeep_course_title,
    enrollment_link: stage.extra.schoolkeep_course_enrollment_link,
  };

  const manageIntegrationHref = `${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/integrations/screening?activeProvider=schoolkeep`;

  return (
    <StageSettingCard
      title={intl.formatMessage(messages.northpassIntegration)}
      variant="button"
      buttonText={intl.formatMessage(messages.manageIntegration)}
      buttonTextColor="primary"
      href={manageIntegrationHref}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Grid container className={styles.cardBody} direction="column">
        <Grid className={styles.courseSelect} item xs={5}>
          <StyledReactSelect
            aria-label={intl.formatMessage(messages.course)}
            options={courses}
            value={selectedCourse}
            getOptionLabel={(option: NorthpassCourse) => option.title}
            getOptionValue={(option: NorthpassCourse) => option.id}
            onFocus={onFocusCoursesDropdown}
            isLoading={result.isLoading}
            onChange={onCourseChange}
            label={intl.formatMessage(messages.course)}
            error={Boolean(errors?.base)}
          />
          <Error
            error={
              !selectedCourse.id &&
              errors?.base &&
              intl.formatMessage(messages.courseRequired)
            }
            align="right"
          />
        </Grid>
        <AutoAdvanceOnCompletion
          stage={stage}
          setStage={setStage}
          label={
            <FormattedMessage
              {...messages.automaticallyMoveAfterLearningCompleted}
            />
          }
        />
        <SettingSwitch
          dataKey="auto_advance_on_quiz_completion"
          checked={Boolean(stage.extra.auto_advance_on_quiz_completion)}
          onChange={onToggleAutoAdvanceOnQuizCompletion}
          label={intl.formatMessage(
            messages.automaticallyMoveAfterAQuizCompleted,
          )}
        />
        <IsHiddenFromPortal stage={stage} setStage={setStage} />
        {hasStageLabels && (
          <AutoAdvanceWhenLabelsComplete stage={stage} setStage={setStage} />
        )}
      </Grid>
    </StageSettingCard>
  );
};
