import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { DATE_COLUMN_WIDTH } from '../../constants';

const generateStyles = (theme: Theme) => ({
  td: {
    padding: theme.spacing(0, 1),
    minWidth: DATE_COLUMN_WIDTH,
  },
  dateText: {
    color: theme.palette.common.gray800,
    width: '100%',
  },
});

const useStyles: () => Record<keyof ReturnType<typeof generateStyles>, string> =
  makeStyles(generateStyles);

export default useStyles;
