import { Theme } from '@material-ui/core';
import { makeStyles, StyleRules } from '@material-ui/core/styles';

import { APPLICATION_LOCALE_WIDTH } from 'containers/ApplicantTableV2/constants';

const generateStyles = (theme: Theme) => {
  const styles: StyleRules = {
    td: {
      padding: theme.spacing(0, 1),
    },
    applicationLocaleCell: {
      width: APPLICATION_LOCALE_WIDTH,
      margin: 'auto',
    },
  };
  return styles;
};

const useStyles: () => Record<keyof ReturnType<typeof generateStyles>, string> =
  makeStyles(generateStyles);

export default useStyles;
