import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import globalMessages from 'shared/global/messages';

import { MESSAGE_DIRECTION } from '../../constants';
import MessageBubble from './MessageBubble';
import styles from './styles';

const MobileSmsPreview = ({
  classes,
  hasLinks,
  messageText,
  smsConversation,
  isWhatsappPreview,
}) => {
  const { formatMessage } = useIntl();

  if (smsConversation)
    return smsConversation.map((sms, index) => (
      <MessageBubble
        /* eslint-disable-next-line react/no-array-index-key */
        key={index}
        className={classes.message}
        direction={sms.direction}
        hasLinks={hasLinks}
        message={sms.value}
        isWhatsappPreview={isWhatsappPreview}
      />
    ));

  const defaultMessageText = `[${formatMessage(globalMessages.message)}]`;

  return (
    <MessageBubble
      hasLinks={hasLinks}
      message={messageText || defaultMessageText}
      isWhatsappPreview={isWhatsappPreview}
    />
  );
};

MobileSmsPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  hasLinks: PropTypes.bool,
  messageText: PropTypes.string,
  smsConversation: PropTypes.arrayOf(
    PropTypes.exact({
      direction: PropTypes.oneOf([
        MESSAGE_DIRECTION.receiving,
        MESSAGE_DIRECTION.sending,
      ]).isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  isWhatsappPreview: PropTypes.bool,
};

export default withStyles(styles)(MobileSmsPreview);
