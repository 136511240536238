import { CustomTooltip, IconButton } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import useStyles from 'containers/DataValidationModal/components/FileField/styles';
import { EditButtonBaseProps } from 'containers/DataValidationModal/components/FileField/types';
import { TestId } from 'containers/DataValidationModal/components/testData';
import DownloadSvg from 'images/_shared/download.svg';
import PenIcon from 'images/applicant_detail/pen';
import messages from 'shared/global/messages';

export type EditButtonProps = EditButtonBaseProps;

export const EditButton: FC<EditButtonProps> = ({
  applicantBlob,
  isMobile,
  setIsEditable,
  showButtons,
  url,
}) => {
  const styles = useStyles();
  const intl = useIntl();
  return (
    <Grid data-testid={TestId.EDIT_BUTTON}>
      <a
        href={url ?? applicantBlob[0].download_url}
        rel="noopener noreferrer"
        target="_blank"
      >
        <CustomTooltip
          dense
          title={<FormattedMessage {...messages.download} />}
        >
          <IconButton
            className={showButtons ? styles.hoverButton : styles.noHoverButton}
          >
            <img
              src={DownloadSvg}
              alt={intl.formatMessage(messages.download)}
            />
          </IconButton>
        </CustomTooltip>
      </a>
      <CustomTooltip title={<FormattedMessage {...messages.edit} />} dense>
        <IconButton
          onClick={() => setIsEditable(true)}
          className={
            showButtons && !isMobile ? styles.hoverButton : styles.noHoverButton
          }
        >
          <PenIcon />
        </IconButton>
      </CustomTooltip>
    </Grid>
  );
};
