import { Link, TableCell, Typography } from '@material-ui/core';
import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { makeSelectTableColumnWidths } from 'containers/ApplicantTableV2/selectors';
import { TableColumnWidthsKey } from 'containers/ApplicantTableV2/types';
import { makeSelectAccountSlug } from 'containers/Auth_old/selectors';

import useStyles from './styles';

export interface ApplicantTableV2LinkCellProps {
  title: string;
  columnType: TableColumnWidthsKey;
  host: string;
  path: string;
}

export const ApplicantTableV2LinkCell: FC<ApplicantTableV2LinkCellProps> = ({
  title,
  columnType,
  host = '/',
  path,
}) => {
  const styles = useStyles();
  const accountSlug = useSelector(makeSelectAccountSlug());
  const tableColumnWidths = useSelector(makeSelectTableColumnWidths());
  const url = `${host}${accountSlug ?? ''}/${path}`;

  const stopPropagation = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  return (
    <TableCell
      role="cell"
      style={{
        minWidth: tableColumnWidths[columnType],
        maxWidth: tableColumnWidths[columnType],
      }}
      className={styles.td}
    >
      <Typography
        variant="body2"
        className={styles.title}
        onClick={stopPropagation}
        title={title}
      >
        <Link
          className={styles.link}
          href={url}
          target="_blank"
          color="inherit"
        >
          {title}
        </Link>
      </Typography>
    </TableCell>
  );
};

export default memo(ApplicantTableV2LinkCell);
