import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import { getConditionErrorMessage } from 'containers/WorkflowEditor/components/Rules/Condition/constants';
import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';

import { DocumentSigningRuleProps } from '../../../DocumentSigningRules/types';
import { RulesProps, SharedConditionProps } from '../../../types';
import { generateLessonlyResourceOptions, ResourceOption } from '../constants';
import { messages } from '../messages';

export const LessonlySelect: FC<SharedConditionProps> = ({
  condition,
  ruleId,
  setRules,
  setDocumentSigningRules,
  errors,
}) => {
  const intl = useIntl();

  const { extra } = condition;

  const { lessonly_options: lessonlyOptions } =
    useContext(RulesEditDataContext);

  const options = generateLessonlyResourceOptions(
    lessonlyOptions?.lessons,
    lessonlyOptions?.paths,
  );
  const selectValue = options.reduce<ResourceOption | undefined>(
    (prev, curr) => {
      return (
        prev ||
        (curr.value === extra.assignable_id && curr) ||
        curr.options?.find(option => option.value === extra.assignable_id)
      );
    },
    undefined,
  );

  const onChangeSelect = (newValue: ResourceOption) => {
    const rulesSetter = setRules ?? setDocumentSigningRules;

    if (rulesSetter) {
      rulesSetter(
        produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];
          const idx = draftConditions.findIndex(
            cond => cond.id === condition.id,
          );

          draftConditions[idx].extra.assignable_id = Number(newValue.value);
          draftConditions[idx].extra.assignable_type = newValue.type;
        }),
      );
    }
  };

  const assignableIdErrorMessage =
    !extra?.assignable_id && getConditionErrorMessage('assignable_id', errors);

  const assignableTypeErrorMessage =
    !extra?.assignable_type &&
    getConditionErrorMessage('assignable_type', errors);

  return (
    <StyledReactSelect
      options={options}
      label={intl.formatMessage(messages.lessonlyResource)}
      aria-label={intl.formatMessage(messages.lessonlyResource)}
      onChange={onChangeSelect}
      value={selectValue}
      isSearchable
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      error={assignableIdErrorMessage || assignableTypeErrorMessage}
      placeholder=""
      required
    />
  );
};
