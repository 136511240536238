import { Grid, IconButton, Input } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { WorkflowBuilderChatbotMessage } from 'api-clients/monolith';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState, VFC } from 'react';

import PlusIcon from 'images/add';

import { HandlesubmitApplicantResponse } from '../types';
import { FileUploadModal } from './FileUploadModal';
import { SendIcon } from './icons/SendIcon';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      '& > div[role="presentation"].MuiAutocomplete-popper': {
        top: '-10px !important',
        left: '40px !important',
        width: '460px !important',
      },
    },
  },
  wrapper: {
    padding: theme.spacing(0, 2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  messageInputContainer: {
    padding: theme.spacing(2, 0),
    background: theme.palette.common.gray50,
    flex: 1,
  },
  messageInputWrapper: {
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.common.gray300}`,
    boxShadow: `0px 5px 12px rgba(0, 0, 0, 0.1)`,
    borderRadius: 16,
    padding: theme.spacing(1.25, 1, 1.25, 3),
    background: theme.palette.common.white,
  },
  inputContainer: {
    flex: 1,
    marginRight: theme.spacing(1.5),
    '& fieldset': {
      border: 'none',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      padding: 0,
    },
  },
  inputText: {
    '& > textarea': {
      lineHeight: '24px',
    },
  },
  inputButtonWrapper: {
    alignSelf: 'flex-end',
  },
  inputButton: {
    marginRight: theme.spacing(0.5),
    width: 40,
    height: 40,
  },
  inputIcon: {
    width: 24,
    height: 24,
  },
}));

export interface MessageInputProps {
  submitApplicantResponse: HandlesubmitApplicantResponse;
  updateMessages: (messages: WorkflowBuilderChatbotMessage) => void;
  setShowTypingIndicator: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MessageInput: VFC<MessageInputProps> = ({
  submitApplicantResponse,
  updateMessages,
  setShowTypingIndicator,
}) => {
  const styles = useStyles();
  const theme = useTheme();

  const [showFileUploadModal, setShowFileUploadModal] = useState(false);

  const inputRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, [inputRef]);

  const [inputMessage, setInputMessage] = useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputMessage(e.target.value);
  };

  const handleSubmit = () => {
    if (!inputMessage) return;

    void submitApplicantResponse({
      applicantResponse: inputMessage,
    });

    setInputMessage('');
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (!isEmpty(inputMessage)) handleSubmit();
    }
  };

  const handleFileUploadClick = () => {
    setShowFileUploadModal(true);
  };

  const handleCloseFileUploadModalClick = () => {
    setShowFileUploadModal(false);
  };

  return (
    <div className={styles.wrapper}>
      <IconButton onClick={handleFileUploadClick}>
        <PlusIcon color="#006eda" />
      </IconButton>
      <Grid item className={styles.messageInputContainer}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={styles.messageInputWrapper}
        >
          <Grid item className={styles.inputContainer}>
            <Input
              data-testid="message-input"
              fullWidth
              inputRef={inputRef}
              placeholder="Type your message.."
              disableUnderline
              value={inputMessage}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              multiline
              rowsMax={5}
              className={styles.inputText}
            />
          </Grid>

          <Grid item className={styles.inputButtonWrapper}>
            <IconButton
              disabled={isEmpty(inputMessage)}
              className={styles.inputButton}
              onClick={handleSubmit}
            >
              <SendIcon
                className={styles.inputIcon}
                fill={
                  inputMessage === ''
                    ? theme.palette.common.gray400
                    : theme.palette.primary.main
                }
                data-testid="send-message-icon"
              />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      {showFileUploadModal && (
        <FileUploadModal
          handleCloseFileUploadModal={handleCloseFileUploadModalClick}
          updateMessages={updateMessages}
          setShowTypingIndicator={setShowTypingIndicator}
        />
      )}
    </div>
  );
};
