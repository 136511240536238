import { Input } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { produce } from 'immer';
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';

import { Error } from 'components/Error';
import { getConditionErrorMessage } from 'containers/WorkflowEditor/components/Rules/Condition/constants';

import { DocumentSigningRuleProps } from '../../../DocumentSigningRules/types';
import {
  PartnerRuleProps,
  SharedPartnerConditionProps,
} from '../../../PartnerRules/types';
import { RulesProps, SharedConditionProps } from '../../../types';
import { messages } from './messages';

export const PartnerTitleInput: FC<
  SharedConditionProps | SharedPartnerConditionProps
> = ({
  condition,
  ruleId,
  setRules,
  setPartnerRules,
  setDocumentSigningRules,
  errors,
}) => {
  const intl = useIntl();
  const { extra } = condition;

  const [inputValue, setInputValue] = useState(extra?.title ?? '');

  const onChangeInput = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setInputValue(e.target.value);
  };

  const onBlurInput = () => {
    const rulesSetter = setRules ?? setDocumentSigningRules;

    if (rulesSetter) {
      rulesSetter(
        produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];

          const idx = draftConditions.findIndex(
            cond => cond.id === condition.id,
          );

          draftConditions[idx].extra.title = inputValue;
        }),
      );
    }

    if (setPartnerRules) {
      setPartnerRules(
        produce((draftRules: PartnerRuleProps) => {
          const draftCondition = draftRules[ruleId].condition_attributes;

          draftCondition.extra.title = inputValue;
        }),
      );
    }
  };

  const titleErrorMessage =
    !extra?.title && getConditionErrorMessage('title', errors);

  return (
    <Grid item xs={6}>
      <Input
        label={intl.formatMessage(messages.title)}
        aria-label={intl.formatMessage(messages.title)}
        fullWidth
        value={inputValue}
        onChange={onChangeInput}
        onBlur={onBlurInput}
        error={Boolean(titleErrorMessage)}
        placeholder=""
        required
      />
      <Error error={titleErrorMessage} align="right" />
    </Grid>
  );
};
