import { ReviewStageDataFieldTypeEnum } from 'api-clients/monolith/models/WorkflowReviewStage';
import uniqueId from 'lodash/uniqueId';

import { DataField, RadioDataField } from 'components/FormBuilder/schemas';

import { createDefaultCommonField } from '../CommonQuestions/helpers';

export const createNewRadioOption = (): RadioDataField['options'][number] => ({
  _id: uniqueId(),
  label: '',
  value: '',
  visible: true,
});

export const createDefaultRadioField = (position: number): RadioDataField => ({
  type: ReviewStageDataFieldTypeEnum.radioField,
  ...createDefaultCommonField(position),
  options: [createNewRadioOption()],
});

export function isRadioDataField(
  dataField: DataField,
): dataField is RadioDataField {
  return dataField.type === ReviewStageDataFieldTypeEnum.radioField;
}
