import { MenuItem } from '@fountain/fountain-ui-components';
import { Menu, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { messages } from './messages';
import { useStyles } from './styles';
export interface StageColumnHeaderMenuProps {
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  isMenuOpen: boolean;
  openCloneStageModal: () => void;
  openNewStageModal: () => void;
}

export const StageColumnHeaderMenu: FC<StageColumnHeaderMenuProps> = ({
  anchorEl,
  handleClose,
  isMenuOpen,
  openCloneStageModal,
  openNewStageModal,
}) => {
  const styles = useStyles();
  const intl = useIntl();

  const handleNewStageClick = () => {
    handleClose();
    openNewStageModal();
  };

  const handleCloneStageClick = () => {
    handleClose();
    openCloneStageModal();
  };

  return (
    <Menu
      role="menu"
      aria-label={intl.formatMessage(messages.addStageMenu)}
      classes={{ paper: styles.menuPaper, list: styles.list }}
      anchorEl={anchorEl}
      open={Boolean(anchorEl && isMenuOpen)}
      onClose={() => handleClose()}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      <MenuItem onClick={handleNewStageClick}>
        <Typography className={styles.text} variant="body2">
          <FormattedMessage {...messages.createNewStage} />
        </Typography>
      </MenuItem>
      <MenuItem onClick={handleCloneStageClick}>
        <Typography className={styles.text} variant="body2">
          <FormattedMessage {...messages.cloneExistingStage} />
        </Typography>
      </MenuItem>
    </Menu>
  );
};
