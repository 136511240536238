import { Grid } from '@material-ui/core';
import { useApplicantBlobs } from 'hooks';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { makeSelectSingleApplicant } from 'containers/ApplicantTableV2/selectors';
import { MobilePreview } from 'containers/DataValidationModal/components/FileField/MobilePreview';
import { Preview } from 'containers/DataValidationModal/components/FileField/Preview';
import useStyles from 'containers/DataValidationModal/components/FileField/styles';
import { Upload } from 'containers/DataValidationModal/components/FileField/Upload';
import { TestId } from 'containers/DataValidationModal/components/testData';
import { SharedFieldProps } from 'containers/DataValidationModal/components/types';
import { ApplicantBlob } from 'containers/DataValidationModal/types';

export type FileFieldProps = SharedFieldProps;

export const FileField: FC<FileFieldProps> = ({
  applicantId,
  detail,
  error,
  isMobile,
  isRequired,
  savePayload,
  type,
}) => {
  const styles = useStyles();

  const { blobs }: { blobs?: ApplicantBlob[] } = useSelector(
    makeSelectSingleApplicant(),
  );

  const [isEditable, setIsEditable] = useState(false);
  const [showButtons, setShowButtons] = useState<boolean | undefined>();
  const [url, setUrl] = useState<undefined | string>();
  const [selectedFile, setSelectedFile] = useState<File | undefined>();

  const { fetchApplicantBlobs } = useApplicantBlobs(applicantId);
  useEffect(() => {
    void fetchApplicantBlobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const applicantBlob =
    (blobs && blobs.filter(blob => blob.fieldname === detail.key)) || [];

  const shouldShowPreview =
    (applicantBlob.length !== 0 || selectedFile !== undefined) && !isEditable;

  const handleUpload = (file: File) => {
    const objectURL = URL.createObjectURL(file);
    setUrl(objectURL);
    setSelectedFile(file);

    const payload = {
      [detail.key]: file,
      dataType: type,
    };
    savePayload(payload);
    setIsEditable(false);
  };

  const sharedProps = {
    detail,
    error,
    handleUpload,
    isMobile,
    isRequired,
    setIsEditable,
  };

  const previewProps = {
    ...sharedProps,
    applicantId,
    file: selectedFile,
    isRequired,
    applicantBlob,
    showButtons,
    url,
  };

  const uploadProps = { ...sharedProps, isEditable };

  return (
    <Grid
      container
      data-testid={TestId.FILE_FIELD}
      direction="row"
      alignItems="center"
      className={styles.field}
      justify="space-between"
      onMouseEnter={() => setShowButtons(true)}
      onMouseLeave={() => setShowButtons(false)}
    >
      {isMobile && shouldShowPreview && <MobilePreview {...previewProps} />}
      {!isMobile && shouldShowPreview && <Preview {...previewProps} />}
      {!shouldShowPreview && <Upload {...uploadProps} />}
    </Grid>
  );
};
