import {
  PartnerOptionDataUpdateRequest,
  PartnerOptionDatumService,
} from 'api-clients/monolith';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { addMessageAction } from 'containers/FlashMessage/actions';

export default function useUpdatePartnerOptionData({
  successMessage,
  errorMessage,
}: {
  successMessage: string;
  errorMessage: string;
}) {
  const dispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useState(false);

  const updatePartnerOptionData = async ({
    applicantId,
    partnerOptionDataId,
    fields,
  }: {
    applicantId: string;
    partnerOptionDataId: string;
    fields: PartnerOptionDataUpdateRequest;
  }) => {
    setIsUpdating(true);
    try {
      await PartnerOptionDatumService.putInternalApiApplicantsPartnerOptionData(
        applicantId,
        partnerOptionDataId,
        { fields },
      );

      dispatch(addMessageAction(successMessage, 'success'));
    } catch (e) {
      dispatch(addMessageAction(errorMessage, 'error'));
    } finally {
      setIsUpdating(false);
    }
  };

  return { updatePartnerOptionData, isUpdating };
}
