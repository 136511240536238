import { Loader } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { retrieveKnowledgeBase } from 'containers/ChatAgent/api';
import {
  AxAgentValues,
  KnowledgeBaseResponse,
} from 'containers/ChatAgent/types';
import { addMessageAction } from 'containers/FlashMessage/actions';

import { messages } from '../../messages';
import { List } from './list';
import { useKnowledgeBaseStyles } from './styles';
import { Upload } from './Upload';

export interface KnowledgeBaseProps {
  setAxAgentValues: React.Dispatch<React.SetStateAction<AxAgentValues>>;
  accessToken: string;
  axAgentValues: AxAgentValues;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
}

export const KnowledgeBase: VFC<KnowledgeBaseProps> = ({
  setAxAgentValues,
  accessToken,
  axAgentValues,
  setIsLoading,
  isLoading,
}) => {
  const classes = useKnowledgeBaseStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const [isKnowledgeBaseUploaded, setIsKnowledgeBaseUploaded] =
    useState<boolean>(false);
  const [knowledgeBase, setKnowledgeBase] = useState<KnowledgeBaseResponse>();

  useEffect(() => {
    const fetchKnowledgeBase = async () => {
      setIsLoading(true);
      try {
        const response = await retrieveKnowledgeBase(
          accessToken,
          axAgentValues.knowledgeBaseUuid,
        );
        setKnowledgeBase(response);
      } catch (err) {
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.fetchKnowledgeBaseFailed),
            'error',
          ),
        );
      } finally {
        setIsLoading(false);
      }
    };

    if (axAgentValues.knowledgeBaseUuid) void fetchKnowledgeBase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, isKnowledgeBaseUploaded]);

  if (isLoading) return <Loader fullScreen size="2rem" />;

  return (
    <Grid className={classes.container}>
      <Grid className={classes.header}>
        <Typography variant="h2">
          <FormattedMessage {...messages.uploadKnowledgeBase} />
        </Typography>
        <Typography variant="body2">
          <FormattedMessage {...messages.uploadKnowledgeBaseInstruction} />
        </Typography>
        <Grid className={classes.content}>
          <Typography variant="body2">
            <FormattedMessage
              {...messages.sampleKnowledgeBaseInstruction}
              values={{
                templateFileLink: (chunks: React.ReactNode) => (
                  <a href={axAgentValues.sampleKnowledgeBaseFileUrl ?? '#'}>
                    {chunks}
                  </a>
                ),
                helpCenterLink: (chunks: React.ReactNode) => (
                  <a
                    href={
                      axAgentValues.knowledgeBaseHelpCenterArticleUrl ?? '#'
                    }
                  >
                    {chunks}
                  </a>
                ),
              }}
            />
          </Typography>
        </Grid>
      </Grid>

      {isKnowledgeBaseUploaded || knowledgeBase ? (
        <List
          knowledgeBase={knowledgeBase}
          accessToken={accessToken}
          setIsKnowledgeBaseUploaded={setIsKnowledgeBaseUploaded}
        />
      ) : (
        <Upload
          setAxAgentValues={setAxAgentValues}
          accessToken={accessToken}
          setIsKnowledgeBaseUploaded={setIsKnowledgeBaseUploaded}
        />
      )}
    </Grid>
  );
};
