import Typography from '@material-ui/core/Typography';
import React, { FC } from 'react';

export interface TestableTypographyProps {
  className: string;
}

export const TestableTypography: FC<TestableTypographyProps> = ({
  className,
  children,
  ...props
}) => (
  <Typography data-testid={className} {...{ className, ...props }}>
    {children}
  </Typography>
);
