import { LoadingStatus } from 'api/loadingStatus';
import {
  ApiError,
  AvailabilityRuleError,
  Period,
  SchedulerService,
  WeeklyPeriods,
} from 'api-clients/monolith';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addMessageAction } from 'containers/FlashMessage/actions';

import { messages } from './messages';
export interface UseCreateAvailabilityRulesOptions {
  weeklyPeriods: Period[];
}

type AvailabilityRulesState =
  | LoadingStatus<WeeklyPeriods, string>
  | { status: 'idle' };

export const useCreateAvailabilityRules = () => {
  const [data, setData] = useState<AvailabilityRulesState>({ status: 'idle' });
  const [errors, setErrors] = useState<AvailabilityRuleError[]>([]);
  const dispatch = useDispatch();
  const intl = useIntl();
  const createAvailabilityRules = useCallback(
    async ({ weeklyPeriods }: UseCreateAvailabilityRulesOptions) => {
      setData({ status: 'loading' });
      try {
        const result =
          await SchedulerService.postInternalApiSchedulerAvailabilityRules({
            weekly_periods: weeklyPeriods,
          });
        setData({ status: 'ready', data: result });
        setErrors([]);
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.createAvailabilityRulesSuccess),
            'success',
          ),
        );
      } catch (error: unknown) {
        setData({ status: 'error' });
        if (error instanceof ApiError) {
          if (error.status === 422) {
            setErrors(error.body as AvailabilityRuleError[]);
            dispatch(
              addMessageAction(
                intl.formatMessage(messages.createAvailabilityRulesError),
                'error',
              ),
            );
          } else {
            dispatch(
              addMessageAction(
                intl.formatMessage(messages.networkError),
                'error',
              ),
            );
          }
        } else {
          throw new Error('error creating availability');
        }
      }
    },
    [dispatch, intl],
  );

  const reset = () => {
    setData({ status: 'idle' });
    setErrors([]);
  };

  return {
    availabilityRules: data,
    createAvailabilityRules,
    errors,
    reset,
  };
};
