import { Grid, Hidden, Typography } from '@material-ui/core';
import classnames from 'classnames';
import React, { VFC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Accordion from 'components/Accordion/Accordion';
import AccordionDetails from 'components/Accordion/AccordionDetails';
import AccordionSummary from 'components/Accordion/AccordionSummary';
import {
  makeSelectAccountSlug,
  makeSelectFeatureChatbotAdminEnabled,
  makeSelectFeatureChatbotAutomatedResponseEnabled,
  makeSelectFeatureChatbotReviewEnabled,
  makeSelectFountainAiCareerSiteEnabled,
  makeSelectFountainAiFaqEnabled,
  makeSelectIsAccountFountainAiEnabled,
} from 'containers/Auth_old/selectors';
import {
  AI_JOBS_DIRECTORY_CAREER_SITE,
  ALL,
  ANALYTICS,
  AUTOMATED_RESPONSES,
  CHATBOT_REVIEW,
  CHATBOT_WIDGET,
  DATA_COLLECTION_BOT,
  FAQ_BOT,
  SCHEDULER_BOT,
} from 'containers/Chatbot/constants';

import { messages as automatedResponsesMessages } from '../AutomatedResponses/messages';
import { messages as chatbotFeedbackMessages } from '../ChatbotFeedback/messages';
import { messages as chatbotWidgetMessages } from '../ChatbotWidget/messages';
import { SubMenu, WidgetSubMenu } from '../types';
import { useStyles } from './styles';

export const chatbotFeedbackSubMenus: { label: string; value: SubMenu }[] = [
  { label: 'All', value: ALL },
  { label: 'Data Collection bot', value: DATA_COLLECTION_BOT },
  { label: 'Scheduler bot', value: SCHEDULER_BOT },
  { label: 'FAQ bot', value: FAQ_BOT },
];

export interface SidebarProps {
  botType: SubMenu;
  handleSidebarMenuSelection: (
    botType: SubMenu,
    module: string,
    widgetSubMenu?: WidgetSubMenu,
  ) => void;
}

export const Sidebar: VFC<SidebarProps> = ({
  botType,
  handleSidebarMenuSelection,
}) => {
  const styles = useStyles();
  const { pathname } = useLocation();

  const accountSlug = useSelector(makeSelectAccountSlug()) ?? '';
  const isAutomatedResponsesPath =
    pathname === `/${accountSlug}/chatbot/${AUTOMATED_RESPONSES}`;
  const isChatbotWidgetPath =
    pathname === `/${accountSlug}/chatbot/${CHATBOT_WIDGET}`;
  const isWidgetAnalyticsPatch =
    pathname === `/${accountSlug}/chatbot/${CHATBOT_WIDGET}/${ANALYTICS}`;
  const isChatbotReviewPath =
    pathname === `/${accountSlug}/chatbot/${CHATBOT_REVIEW}`;
  const isAIJobsDirectoryCareerSitePath =
    pathname === `/${accountSlug}/chatbot/${AI_JOBS_DIRECTORY_CAREER_SITE}`;

  const isFeatureAutomatedResponseEnabled = useSelector(
    makeSelectFeatureChatbotAutomatedResponseEnabled(),
  );
  const isFeatureChatbotAdminEnabled = useSelector(
    makeSelectFeatureChatbotAdminEnabled(),
  );
  const isFeatureChatbotReviewEnabled = useSelector(
    makeSelectFeatureChatbotReviewEnabled(),
  );
  const isFeatureFountainAiEnabled = useSelector(
    makeSelectIsAccountFountainAiEnabled(),
  );
  const isFeatureFountainAiFaqEnabled = useSelector(
    makeSelectFountainAiFaqEnabled(),
  );
  const isFountainAiCareerSiteEnabled = useSelector(
    makeSelectFountainAiCareerSiteEnabled(),
  );

  return (
    <>
      <Hidden smDown>
        <Grid item className={styles.sidebarContainer}>
          {/* Automated Responses sidebar menu */}
          {(isFeatureAutomatedResponseEnabled ||
            isFeatureChatbotAdminEnabled) && (
            <Accordion>
              <AccordionSummary
                id="automatedResponses"
                onClick={() =>
                  handleSidebarMenuSelection(null, AUTOMATED_RESPONSES)
                }
                className={classnames(styles.header, {
                  [styles.selectedMenu]: isAutomatedResponsesPath,
                })}
              >
                <Typography variant="h4">
                  <FormattedMessage
                    {...automatedResponsesMessages.automatedResponses}
                  />
                </Typography>
              </AccordionSummary>
            </Accordion>
          )}

          {/* Chatbot Review sidebar menu */}
          {(isFeatureChatbotReviewEnabled || isFeatureChatbotAdminEnabled) && (
            <Accordion expanded>
              <AccordionSummary
                onClick={() => handleSidebarMenuSelection(ALL, CHATBOT_REVIEW)}
                className={styles.header}
              >
                <Typography variant="h4">
                  <FormattedMessage
                    {...chatbotFeedbackMessages.chatbotFeedback}
                  />
                </Typography>
              </AccordionSummary>
              {chatbotFeedbackSubMenus.map(bot => (
                <AccordionDetails
                  key={bot.label}
                  className={classnames(styles.subHeader, {
                    [styles.selectedMenu]:
                      botType === bot.value && isChatbotReviewPath,
                  })}
                  onClick={() =>
                    handleSidebarMenuSelection(bot.value, CHATBOT_REVIEW)
                  }
                >
                  <Typography variant="body2" className={styles.subHeaderText}>
                    {bot.label}
                  </Typography>
                </AccordionDetails>
              ))}
            </Accordion>
          )}

          {(isFeatureFountainAiEnabled || isFeatureFountainAiFaqEnabled) && (
            <Accordion expanded>
              <AccordionSummary
                id="automatedResponses"
                onClick={() => handleSidebarMenuSelection(null, CHATBOT_WIDGET)}
                className={classnames(styles.header, {
                  [styles.selectedMenu]: isChatbotWidgetPath,
                })}
              >
                <Typography variant="h4">
                  <FormattedMessage {...chatbotWidgetMessages.header} />
                </Typography>
              </AccordionSummary>
              {isFeatureFountainAiFaqEnabled && (
                <AccordionDetails
                  className={classnames(styles.subHeader, {
                    [styles.selectedMenu]: isWidgetAnalyticsPatch,
                  })}
                  onClick={() =>
                    handleSidebarMenuSelection(null, CHATBOT_WIDGET, ANALYTICS)
                  }
                >
                  <Typography variant="body2" className={styles.subHeaderText}>
                    <FormattedMessage {...chatbotWidgetMessages.subHeader} />
                  </Typography>
                </AccordionDetails>
              )}
            </Accordion>
          )}

          {isFountainAiCareerSiteEnabled && (
            <Accordion expanded>
              <AccordionSummary
                id="jdCareerSite"
                onClick={() =>
                  handleSidebarMenuSelection(
                    null,
                    AI_JOBS_DIRECTORY_CAREER_SITE,
                  )
                }
                className={classnames(styles.header, {
                  [styles.selectedMenu]: isAIJobsDirectoryCareerSitePath,
                })}
              >
                <Typography variant="h4">AI Jobs Directory Site</Typography>
              </AccordionSummary>
            </Accordion>
          )}
        </Grid>
      </Hidden>
    </>
  );
};
