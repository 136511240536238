import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const TemplateIcon = props => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8176 0C14.0246 0 14.2227 0.0841441 14.3664 0.233112L21.2862 7.405C21.4233 7.54716 21.5 7.737 21.5 7.93455V23.2373C21.5 23.6585 21.1585 24 20.7373 24H2.76266C2.34146 24 2 23.6585 2 23.2373V0.762661C2 0.341455 2.34146 0 2.76266 0H13.8176ZM6.5 12C5.67157 12 5 12.6716 5 13.5C5 14.3284 5.67157 15 6.5 15H17C17.8284 15 18.5 14.3284 18.5 13.5C18.5 12.6716 17.8284 12 17 12H6.5ZM5 19.5C5 18.6716 5.67157 18 6.5 18H17C17.8284 18 18.5 18.6716 18.5 19.5C18.5 20.3284 17.8284 21 17 21H6.5C5.67157 21 5 20.3284 5 19.5ZM19.1583 9.07892L12.8813 9.07892C12.6707 9.07892 12.5 8.90819 12.5 8.69759L12.5 2.42059C12.5 2.08086 12.9107 1.91072 13.151 2.15094L19.428 8.42795C19.6682 8.66817 19.4981 9.07892 19.1583 9.07892Z"
    />
  </SvgIcon>
);

export default TemplateIcon;
