import { FountainLocation } from '@fountain/types/base';
import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import SearchInput from 'components/SearchInput';
import JobSearchResults from 'containers/JobSearch/JobSearchResults';
import { Funnel } from 'containers/Messenger/types';

import { messages } from './messages';

interface JobSearchInputProps {
  focus?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
  onSelected: (location: FountainLocation, funnel: Funnel) => void;
  placeholder: string;
  value: string;
  isDirty?: boolean;
  shouldShowActiveFilter?: boolean;
}

const JobSearchInput: FC<JobSearchInputProps> = ({
  focus,
  onSelected,
  isDirty,
  shouldShowActiveFilter,
  ...props
}) => {
  const intl = useIntl();

  const handleSelected = (location: FountainLocation, funnel: Funnel) => () => {
    onSelected(location, funnel);
  };

  return (
    <>
      <Box
        px={1}
        pt={1}
        aria-label={intl.formatMessage(messages.jobSearchInputVariant)}
      >
        <SearchInput
          // @ts-expect-error will go away when converting `SearchInput` to typescript
          fullWidth
          autoFocus={focus}
          {...props}
        />
      </Box>
      <JobSearchResults
        onSelected={handleSelected}
        searchString={props.value}
        shouldShowActiveFilter={shouldShowActiveFilter}
        isDirty={isDirty}
      />
    </>
  );
};

export default JobSearchInput;
