import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  description: {
    color: theme.palette.common.gray800,
    paddingBottom: theme.spacing(3),
  },
  connectCalendarButton: {
    color: theme.palette.common.blue400,
  },
}));
