/* eslint-disable camelcase */
import {
  Button,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  StyledReactSelect,
} from '@fountain/fountain-ui-components';
import { FountainLocation } from '@fountain/types/base';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import {
  CancelablePromise,
  SidebarStage,
  StageCloneParams,
  WorkflowEditorService,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { WorkflowSelect } from 'components/ChangeWorkflowModal';
import { Error } from 'components/Error';
import {
  makeSelectAccountHasLegacyWorkflows,
  makeSelectAccountHasWorkflows,
} from 'containers/Auth_old/selectors';
import { addMessageAction } from 'containers/FlashMessage/actions';
import { JobSearch } from 'containers/JobSearch';
import { Funnel } from 'containers/Messenger/types';
import { terminalStagesHash } from 'containers/WorkflowEditor/constants';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';
import { useForm } from 'hooks/useForm';

import {
  SelectOption,
  useGetStagePlacementOptions,
} from '../useGetStagePlacementOptions';
import { messages } from './messages';
import { useStyles } from './styles';
import { WorkflowStageSelect } from './WorkflowStageSelect';

import type { SidebarStageResponse } from 'api-clients/monolith/models/SidebarStageResponse';

export interface CloneStageModalProps {
  onClose: () => void;
  open: boolean;
  refetchStages: () => void;
  stages: SidebarStage[];
}

interface FormValues {
  title?: string;
  position: number;
  opening?: string;
  type?: string;
  opening_slug?: string;
  source_stage_external_id: string;
  workflow_external_id: string | null;
}

type StageSource = 'opening' | 'workflow';

// we need to default to showing the opening selection because there seems to
// be a visual bug with the job search menu where it won't render correctly if initially
// hidden
const defaultSource = (
  hasWorkflows: boolean,
  hasLegacyWorkflows: boolean,
): StageSource => {
  if (hasWorkflows && hasLegacyWorkflows) {
    return 'opening';
  }
  if (hasWorkflows) {
    return 'workflow';
  }
  return 'opening';
};

export const CloneStageModal: FC<CloneStageModalProps> = ({
  onClose,
  open,
  refetchStages,
  stages,
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const accountHasWorkflows = useSelector(makeSelectAccountHasWorkflows());
  const accountHasLegacyWorkflows = useSelector(
    makeSelectAccountHasLegacyWorkflows(),
  );
  const [stageSource, setStageSource] = useState<StageSource>(
    defaultSource(accountHasWorkflows, accountHasLegacyWorkflows),
  );

  const { accountSlug, funnelSlug } = useParams<{
    accountSlug: string;
    funnelSlug: string;
  }>();

  const { result: cloneStageResult, mutation: cloneStage } =
    useApiServiceMutation<
      WorkflowStageDetail,
      (
        funnelSlug: string,
        requestBody?: StageCloneParams,
      ) => CancelablePromise<WorkflowStageDetail>,
      { errors: Record<string, Array<string>> }
    >(
      // eslint-disable-next-line @typescript-eslint/unbound-method
      WorkflowEditorService.postInternalApiWorkflowEditorFunnelsCloneStages,
      {
        onSuccess: (data: WorkflowStageDetail) => {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          onClose();
          refetchStages();
          history.push(
            `/${accountSlug}/openings/${funnelSlug}/workflow/${
              data.slug ?? ''
            }`,
          );
          dispatch(
            addMessageAction(
              intl.formatMessage(messages.stageSuccessfullyCloned),
              'success',
            ),
          );
        },
      },
    );

  const { result: fetchFunnelStagesResult, mutation: fetchFunnelStages } =
    useApiServiceMutation<
      SidebarStageResponse,
      (funnelSlug: string) => CancelablePromise<SidebarStageResponse>
    >(
      // eslint-disable-next-line @typescript-eslint/unbound-method
      WorkflowEditorService.getInternalApiWorkflowEditorFunnelsStages,
    );

  const validate = (values: FormValues) => {
    const errors: Partial<Record<keyof FormValues, string>> = {};

    if (!values.opening && !values.workflow_external_id) {
      errors.workflow_external_id = intl.formatMessage(messages.mustChoose);
    }

    if (!values.title) {
      errors.title = intl.formatMessage(messages.addAStageTitle);
    }

    if (!values.source_stage_external_id) {
      errors.source_stage_external_id = intl.formatMessage(
        messages.selectAStage,
      );
    }

    return errors;
  };

  const onCloneStage = (formValues: FormValues) => {
    const { title, position, source_stage_external_id } = formValues;

    let newPosition;

    if (position === -1) {
      newPosition = undefined;
    } else {
      newPosition = position && position > 0 ? position + 1 : position;
    }

    cloneStage(funnelSlug, {
      workflow_stage: {
        source_stage_external_id,
        stage: {
          title,
          position: newPosition,
        },
      },
    });
  };

  const { values, handleChange, handleSubmit, errors } = useForm<FormValues>(
    onCloneStage,
    validate,
    {
      source_stage_external_id: '',
      position: -1,
      workflow_external_id: null,
    },
  );

  React.useEffect(() => {
    if (values.opening_slug) {
      fetchFunnelStages(values.opening_slug);
    }
  }, [fetchFunnelStages, values.opening_slug]);

  const selectedFunnelStages =
    (fetchFunnelStagesResult.status === 'ready' &&
      fetchFunnelStagesResult.data.stages.filter(
        stage => !terminalStagesHash[stage.type] && stage.type !== 'RuleStage',
      )) ||
    [];

  const selectedStage =
    selectedFunnelStages.find(
      option => option.external_id === values.source_stage_external_id,
    ) ?? null;

  const stagePlacementOptions = useGetStagePlacementOptions({ stages });

  const cloneStageResultErrors =
    cloneStageResult.status === 'error'
      ? cloneStageResult?.error?.errors
      : undefined;

  return (
    <Modal
      ariaLabelledBy={intl.formatMessage(messages.cloneStageModal)}
      disableBackdropClick
      fullScreenOnMobile
      onClose={onClose}
      open={open}
      maxWidth={496}
    >
      <form onSubmit={handleSubmit}>
        <ModalHeader
          ariaLabelledBy={intl.formatMessage(messages.cloneStageModalHeader)}
          onClose={onClose}
          showIcon={false}
        >
          <FormattedMessage {...messages.cloneExistingStage} />
        </ModalHeader>
        <ModalContent dividers>
          <Typography className={styles.modalText} variant="body2">
            <FormattedMessage {...messages.cloneStageModalContent} />
          </Typography>
          <Grid container direction="column" spacing={3}>
            {accountHasWorkflows && accountHasLegacyWorkflows && (
              <Grid item>
                <FormControl component="fieldset" size="small">
                  <FormLabel component="legend">
                    <FormattedMessage {...messages.stageSource} />
                  </FormLabel>
                  <RadioGroup
                    aria-label={intl.formatMessage(messages.stageSource)}
                    name="stageSource"
                    value={stageSource}
                    row
                    onChange={event => {
                      setStageSource(event.target.value as StageSource);
                      handleChange({
                        title: undefined,
                        source_stage_external_id: undefined,
                        opening_slug: undefined,
                        workflow_external_id: null,
                        position: -1,
                      });
                    }}
                  >
                    <FormControlLabel
                      value="opening"
                      control={<Radio />}
                      label={intl.formatMessage(messages.opening)}
                    />
                    <FormControlLabel
                      value="workflow"
                      control={<Radio />}
                      label={intl.formatMessage(messages.workflow)}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            )}
            <Grid item hidden={stageSource === 'workflow'}>
              <JobSearch
                variant="select"
                focus={false}
                label={intl.formatMessage(messages.opening)}
                value={values.opening}
                placeholder={intl.formatMessage(messages.selectAnOpening)}
                onSelected={(_location: FountainLocation, funnel: Funnel) => {
                  handleChange({
                    opening: funnel.title,
                    opening_slug: funnel.slug,
                    source_stage_external_id: undefined,
                  });
                }}
                isUrl={false}
              />
              <Error error={errors.workflow_external_id} align="right" />
            </Grid>
            <Grid item hidden={stageSource === 'opening'}>
              <WorkflowSelect
                fromWorkflowId=""
                onChange={workflow =>
                  handleChange({ workflow_external_id: workflow.id })
                }
                onClear={() => handleChange({ workflow_external_id: null })}
                workflowId={values.workflow_external_id}
              />
              <Error error={errors.workflow_external_id} align="right" />
            </Grid>
            {Boolean(values.opening) && (
              <Grid item>
                <StyledReactSelect
                  aria-label={intl.formatMessage(messages.stage)}
                  options={selectedFunnelStages}
                  label={intl.formatMessage(messages.stage)}
                  placeholder={intl.formatMessage(messages.selectAStage)}
                  onChange={(option: SidebarStage) => {
                    handleChange({
                      source_stage_external_id: option.external_id,
                      title: option.title,
                    });
                  }}
                  getOptionLabel={(option: SidebarStage) => option.title}
                  getOptionValue={(option: SidebarStage) => option.external_id}
                  value={selectedStage}
                  error={
                    !values.source_stage_external_id &&
                    Boolean(errors.source_stage_external_id)
                  }
                  required
                />
                <Error
                  align="right"
                  error={
                    !values.source_stage_external_id &&
                    errors.source_stage_external_id
                  }
                />
              </Grid>
            )}
            {!!values.workflow_external_id && (
              <Grid item>
                <WorkflowStageSelect
                  workflowId={values.workflow_external_id}
                  value={values.source_stage_external_id}
                  error={!!errors.source_stage_external_id}
                  onChange={stage =>
                    handleChange({
                      source_stage_external_id: stage.id,
                      title: stage.title,
                    })
                  }
                />
              </Grid>
            )}
            {(values.opening || values.workflow_external_id) && (
              <>
                <Grid item>
                  <Input
                    aria-label={intl.formatMessage(messages.stageTitleInput)}
                    className={styles.input}
                    label={intl.formatMessage(messages.title)}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                      handleChange({ title: event.target.value })
                    }
                    value={values.title ?? ''}
                    error={
                      (!values.title && Boolean(errors.title)) ||
                      Boolean(cloneStageResultErrors)
                    }
                    required
                  />
                  <Error align="right" error={!values.title && errors.title} />
                  <Error
                    error={
                      cloneStageResultErrors ? cloneStageResultErrors[0] : ''
                    }
                    align="right"
                  />
                </Grid>
                <Grid item>
                  <StyledReactSelect
                    aria-label={intl.formatMessage(
                      messages.stagePlacementDropdown,
                    )}
                    options={stagePlacementOptions}
                    label={intl.formatMessage(messages.stagePlacement)}
                    onChange={(option: SelectOption) => {
                      handleChange({ position: option.value as number });
                    }}
                    value={stagePlacementOptions.find(
                      option => option.value === values.position,
                    )}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </ModalContent>
        <ModalFooter>
          <Button disableRipple onClick={onClose} size="small" type="secondary">
            <FormattedMessage {...messages.cancel} />
          </Button>
          <Button
            disableRipple
            submit
            size="small"
            disabled={cloneStageResult.isLoading}
            isLoading={cloneStageResult.isLoading}
          >
            <FormattedMessage {...messages.cloneStage} />
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
