import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import messages from 'containers/ApplicantsFilter/messages';

import { fetchCategories } from './categories';
import {
  callDeleteApplicantsFilter,
  callSaveFilter,
  callUpdateApplicantsFilter,
  fetchFilterData,
  fetchFilters,
} from './filter';

export function useApplicantsFilter(filterIdParam, fetchCategoriesOnChange) {
  const [filterId, setFilterId] = useState(filterIdParam);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const errorMessage = formatMessage(messages.fetchFilterFail);

  useEffect(() => {
    fetchFilterData(dispatch, errorMessage, filterId);
    if (fetchCategoriesOnChange) {
      fetchFilters(dispatch, formatMessage(messages.fetchSavedFilterFail));
      fetchCategories(dispatch, errorMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCategoriesOnChange, filterId]);

  async function fetchFilter(filter) {
    setFilterId(filter);
  }

  const deleteApplicantsFilter = async id => {
    await callDeleteApplicantsFilter(
      dispatch,
      id,
      formatMessage(messages.deleteFilter),
      formatMessage(messages.deleteFilterFail),
    );
    setFilterId('');
  };

  const updateApplicantsFilter = async (
    id,
    title,
    visible,
    criteria,
    isCurrentFilter,
  ) => {
    const successMessage = [
      formatMessage(messages.updateFilter),
      isCurrentFilter ? formatMessage(messages.applying) : null,
    ].join(' ');
    await callUpdateApplicantsFilter(
      dispatch,
      id,
      title,
      visible,
      criteria,
      successMessage,
      formatMessage(messages.updateFilterFail),
    );
  };

  const saveFilter = async (title, visible, criteria) => {
    await callSaveFilter(
      dispatch,
      title,
      visible,
      criteria,
      formatMessage(messages.saveFilter),
      formatMessage(messages.saveFilterFail),
      setFilterId,
    );
  };

  return {
    updateApplicantsFilter,
    deleteApplicantsFilter,
    saveFilter,
    fetchFilters,
    fetchFilter,
  };
}
