import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: '5px',
    width: 'fit-content',
    padding: theme.spacing(1.5, 3),
  },
  deleteButton: {
    width: 'auto',
    border: 'none',
    boxShadow: 'none',
    color: theme.palette.common.red400,
    marginRight: 'auto',

    // Style when it's loading (spinner)
    '&:has(img)': {
      backgroundColor: theme.palette.common.gray200,
    },
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 0),
  },
  saveButton: {
    borderRadius: '5px',
    width: '80px',
    padding: theme.spacing(1.5, 3),
  },
}));
