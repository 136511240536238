import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  trashIconContainer: {
    minWidth: theme.spacing(4.5),
  },
  trashcanIcon: {
    fontSize: theme.spacing(1.5),
    color: theme.palette.common.gray500,
  },
}));
