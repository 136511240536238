import { Dropdown } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { produce } from 'immer';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import {
  comparators,
  getConditionErrorMessage,
} from 'containers/WorkflowEditor/components/Rules/Condition/constants';

import { DocumentSigningRuleProps } from '../../../DocumentSigningRules/types';
import {
  PartnerRuleProps,
  SharedPartnerConditionProps,
} from '../../../PartnerRules/types';
import { RulesProps, SharedConditionProps } from '../../../types';
import messages from './messages';

export type ComparatorSelectProps = (
  | SharedConditionProps
  | SharedPartnerConditionProps
) & {
  onlyReturnBasicComparators?: boolean;
};
export const ComparatorSelect: FC<ComparatorSelectProps> = React.memo(
  ({
    condition,
    ruleId,
    setRules,
    setPartnerRules,
    setDocumentSigningRules,
    errors,
    onlyReturnBasicComparators,
  }) => {
    const intl = useIntl();

    const { extra } = condition;

    const onChangeComparator = (newComparator: string) => {
      const rulesSetter = setRules ?? setDocumentSigningRules;

      if (rulesSetter) {
        rulesSetter(
          produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
            const draftConditions =
              draftRules[ruleId]?.condition_set_attributes
                ?.conditions_attributes ?? [];

            const idx = draftConditions.findIndex(
              cond => cond.id === condition.id,
            );

            draftConditions[idx].extra.comparator = newComparator;
          }),
        );
      }

      if (setPartnerRules) {
        setPartnerRules(
          produce((draftRules: PartnerRuleProps) => {
            const draftCondition = draftRules[ruleId].condition_attributes;

            draftCondition.extra.comparator = newComparator;
          }),
        );
      }
    };

    const errorMessage =
      !extra.comparator && getConditionErrorMessage('comparator', errors);

    return (
      <Grid item xs={6}>
        <Dropdown
          options={comparators(intl, { onlyReturnBasicComparators })}
          value={extra?.comparator}
          onChange={onChangeComparator}
          label={intl.formatMessage(messages.operator)}
          aria-label={intl.formatMessage(messages.operator)}
          isSearchable
          error={errorMessage}
          placeholder=""
          required
        />
      </Grid>
    );
  },
);
