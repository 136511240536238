import { Typography } from '@material-ui/core';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import React from 'react';
import { classNames } from 'react-extras';
import { FormattedMessage } from 'react-intl';

import SearchableText from 'containers/JobSearch/JobSearchResults/SearchableText';

import messages from '../messages';
import useStyles from './styles';

/* eslint-disable camelcase */
interface JobOption {
  id: string;
  title: string;
  job_id: string;
  active: boolean;
  applicants_count: number;
}
/* eslint-enable camelcase */

interface JobSearchResultsListItemProps {
  searchString: string;
  option: JobOption;
}

const JobSearchResultsListItem: React.VFC<JobSearchResultsListItemProps> = ({
  option,
  searchString,
}) => {
  const styles = useStyles();

  return (
    <>
      <FiberManualRecord
        className={classNames(
          styles.activeIndicator,
          styles.activeIndicatorSmall,
          { active: option.active },
        )}
      />
      <Typography className={styles.listItemTitle} noWrap>
        <SearchableText searchString={searchString} text={option.title} />
      </Typography>
      <Typography className={styles.listItemSubtitle} noWrap>
        <FormattedMessage
          {...messages.applicantsFound}
          values={{ number: `${option.applicants_count}` }}
        />
      </Typography>
    </>
  );
};

export default JobSearchResultsListItem;
