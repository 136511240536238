import { EventsService } from 'api-clients/monolith';
import { useLocalStorageItem } from 'hooks';
import { useCallback, useEffect } from 'react';

import { Filterable } from 'components/FilterDropdown/util';
import { useInfiniteApiService } from 'hooks/useInfiniteApiService';

export type EventQueryFunnel = {
  name: string;
  id: string;
};

export type EventQuery = {
  date: 'upcoming' | 'today' | 'past';
  stages: Filterable[];
  page: number;
  funnels: Filterable[];
  selectedColumns?: string[];
};

export const useEvents = () => {
  const [filters, setFilters] = useLocalStorageItem<EventQuery>(
    {
      date: 'today',
      stages: [],
      page: 0,
      funnels: [],
    },
    'eventFilter',
  );
  const fetch = useCallback(
    () =>
      EventsService.getInternalApiEvents(
        filters.date,
        filters.page,
        filters.stages.map(f => f.id),
        filters.funnels.map(f => f.id),
      ),
    [filters],
  );
  const { queryKey, ...service } = useInfiniteApiService(fetch);

  useEffect(() => {
    if (queryKey !== filters.page) {
      setFilters(filters => ({ ...filters, page: queryKey }));
    }
  }, [filters, queryKey, setFilters]);

  return { filters, setFilters, ...service };
};
