import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  addCustomerSupportButton: {
    width: 'inherit',
  },
  description: {
    color: theme.palette.common.gray800,
    paddingBottom: theme.spacing(3),
  },
  icon: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    '& path': {
      fill: theme.palette.common.gray600,
    },
  },
  input: {
    width: theme.spacing(25.5),
  },
  inputContainer: {
    gap: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  inputItem: {
    minHeight: theme.spacing(10.125),
  },
  trashcanIcon: {
    fontSize: theme.spacing(1.5),
  },
}));
