import { CustomTooltip, IconButton } from '@fountain/fountain-ui-components';
import { FormControlLabel, Grid, Switch, Typography } from '@material-ui/core';
import cx from 'classnames';
import React, { FC } from 'react';

import InformationIcon from 'images/info_v2';

import { useStyles } from './styles';

export interface SettingSwitchProps {
  checked: boolean;
  dataKey: string;
  disabled?: boolean;
  fieldComponent?: React.ReactNode;
  label: React.ReactNode;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  tooltipDescription?: string;
}

export const SettingSwitch: FC<SettingSwitchProps> = ({
  checked,
  dataKey,
  disabled = false,
  fieldComponent,
  label,
  onChange,
  tooltipDescription,
}) => {
  const styles = useStyles();

  return (
    <Grid item xs={12} className={styles.fieldRow}>
      <FormControlLabel
        control={
          <Switch
            checked={checked}
            disabled={disabled}
            data-testid={dataKey}
            onChange={onChange}
          />
        }
        label={
          <Grid container alignItems="center">
            <Typography
              variant="body2"
              className={cx(styles.switchLabel, {
                [styles.disabled]: disabled,
                [styles.enabled]: !disabled,
              })}
            >
              {label}
            </Typography>
            {tooltipDescription && (
              <CustomTooltip
                dense
                disableFocusListener
                enterTouchDelay={100}
                title={tooltipDescription}
                className={styles.tooltip}
              >
                <IconButton size="small" className={styles.infoButton}>
                  <InformationIcon />
                </IconButton>
              </CustomTooltip>
            )}
          </Grid>
        }
      />
      {fieldComponent && (
        <Grid className={styles.fieldComponent}>{fieldComponent}</Grid>
      )}
    </Grid>
  );
};
