import { Input, StyledReactSelect } from '@fountain/fountain-ui-components';
import {
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  Typography,
} from '@material-ui/core';
import {
  User,
  WorkflowSchedulingStage,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import produce from 'immer';
import React, { FC, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Error } from 'components/Error';
import {
  makeSelectIsAccountFountainAiEnabled,
  makeSelectIsPostInterviewRecruiterNotificationsEnabled,
  makeSelectSlotAvailabilityBasedOpeningsEnabled,
} from 'containers/Auth_old/selectors';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import {
  BotPlatformToggleGroup,
  BotPlatformTypes,
} from '../../../BotPlatformToggleGroup';
import { useStyles } from '../styles';
import {
  ALLOW_ADVANCE_ON_SELECT_TIME_SLOT,
  FUNNEL_STATUS_DAYS,
  FUNNEL_STATUS_RECIPIENT,
  FUNNEL_STATUS_RESPONDS_TO_BOOKED_SLOTS,
  FUNNEL_STATUS_SLOTS,
  IS_HIDDEN_FROM_PORTAL,
  POST_INTERVIEW_MOVE_APPLICANT_TO_NEXT_STAGE,
  RECEIVE_REMINDER_AWAY_FROM_STAGE,
  SHOW_APPLICANT_ONE_SESSION_PER_TIME_FRAME,
} from './constants';
import { messages } from './messages';

export interface BaseSettingsProps {
  stage: WorkflowSchedulingStage;
  setStage: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
}

export const BaseSettings: FC<BaseSettingsProps> = ({ stage, setStage }) => {
  const styles = useStyles();
  const intl = useIntl();
  const {
    additional_info: additionalInfo,
    advanced_settings: advancedSettings,
    parent_stage_id: parentStageId,
  } = stage;

  const [slotValue, setSlotValue] = React.useState(
    additionalInfo?.funnel_status_slots,
  );
  const [dayValue, setDayValue] = React.useState(
    additionalInfo?.funnel_status_days,
  );
  const { updateStageResult } = useContext(StageContext);

  const hasSlotsAvailableError =
    updateStageResult.isError &&
    updateStageResult.error?.errors?.funnel_status_slots !== undefined;
  const hasDaysError =
    updateStageResult.isError &&
    updateStageResult.error?.errors?.funnel_status_days !== undefined;
  const hasRecipientError =
    updateStageResult.isError &&
    updateStageResult.error?.errors?.funnel_status_recipient !== undefined;

  const isSubstage = Boolean(parentStageId);
  const hasStageLabels = Boolean(stage.has_stage_labels);
  const userOptions: User[] = additionalInfo.user_options;

  const isFountainAiEnabled = useSelector(
    makeSelectIsAccountFountainAiEnabled(),
  );

  const isPostInterviewRecruiterNotificationsEnabled = useSelector(
    makeSelectIsPostInterviewRecruiterNotificationsEnabled(),
  );

  const isSlotAvailabilityBasedOpeningsEnabled = useSelector(
    makeSelectSlotAvailabilityBasedOpeningsEnabled(),
  );

  const onToggleChange =
    (
      key:
        | typeof ALLOW_ADVANCE_ON_SELECT_TIME_SLOT
        | typeof FUNNEL_STATUS_RESPONDS_TO_BOOKED_SLOTS
        | typeof IS_HIDDEN_FROM_PORTAL
        | typeof RECEIVE_REMINDER_AWAY_FROM_STAGE
        | typeof SHOW_APPLICANT_ONE_SESSION_PER_TIME_FRAME
        | BotPlatformTypes
        | typeof POST_INTERVIEW_MOVE_APPLICANT_TO_NEXT_STAGE
        | 'should_auto_advance_when_labels_complete',
    ) =>
    () => {
      setStage(
        produce(stage, draftStage => {
          if (
            key === IS_HIDDEN_FROM_PORTAL ||
            key === 'should_auto_advance_when_labels_complete'
          ) {
            draftStage.advanced_settings[key] =
              !draftStage.advanced_settings[key];
          } else if (draftStage.additional_info) {
            draftStage.additional_info[key] = !draftStage.additional_info[key];
          }
        }),
      );
    };

  const onChangeInput = (
    key: typeof FUNNEL_STATUS_SLOTS | typeof FUNNEL_STATUS_DAYS,
    value: string,
  ) => {
    setStage(
      produce(stage, draftStage => {
        if (
          (key === FUNNEL_STATUS_SLOTS || key === FUNNEL_STATUS_DAYS) &&
          typeof value === 'string'
        ) {
          const parsedValue = parseInt(value, 10);
          if (Number.isNaN(parsedValue)) {
            if (key === FUNNEL_STATUS_DAYS) {
              setDayValue(0);
            } else if (key === FUNNEL_STATUS_SLOTS) {
              setSlotValue(0);
            }
          } else {
            if (key === FUNNEL_STATUS_DAYS) {
              setDayValue(parsedValue);
            } else if (key === FUNNEL_STATUS_SLOTS) {
              setSlotValue(parsedValue);
            }
            draftStage.additional_info[key] = parsedValue;
          }
        }
      }),
    );
  };

  const onChangeUser = (key: typeof FUNNEL_STATUS_RECIPIENT, value: User) => {
    setStage(
      produce(stage, draftStage => {
        if (key === FUNNEL_STATUS_RECIPIENT) {
          draftStage.additional_info[key] = value.email;
        }
      }),
    );
  };

  const CustomInputLabel: React.FC = ({ children }) => {
    const styles = useStyles();
    return (
      <Typography
        variant="body2"
        color="textSecondary"
        className={styles.nestedInputLabel}
      >
        {children}
      </Typography>
    );
  };

  return (
    <Grid className={styles.cardContainer}>
      {isFountainAiEnabled && (
        <BotPlatformToggleGroup
          title={intl.formatMessage(messages.enableSchedulerBot)}
          onToggle={onToggleChange}
          platforms={{
            widget_bot_enabled: !!stage.additional_info.widget_bot_enabled,
            sms_bot_enabled: !!stage.additional_info.sms_bot_enabled,
            t2a_bot_enabled: !!stage.additional_info.t2a_bot_enabled,
          }}
        />
      )}
      <Grid item xs={12} className={styles.fieldRow}>
        <FormControlLabel
          control={
            <Switch
              data-testid={ALLOW_ADVANCE_ON_SELECT_TIME_SLOT}
              checked={additionalInfo?.allow_advance_on_select_time_slot}
              onChange={onToggleChange(ALLOW_ADVANCE_ON_SELECT_TIME_SLOT)}
            />
          }
          label={
            <Typography variant="body2" className={styles.switchLabel}>
              {isSubstage
                ? intl.formatMessage(
                    messages.moveApplicantAfterBookingSessionSubStage,
                  )
                : intl.formatMessage(messages.moveApplicantAfterBookingSession)}
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={12} className={styles.fieldRow}>
        <FormControlLabel
          control={
            <Switch
              data-testid={RECEIVE_REMINDER_AWAY_FROM_STAGE}
              checked={additionalInfo?.receive_reminder_away_from_stage}
              onChange={onToggleChange(RECEIVE_REMINDER_AWAY_FROM_STAGE)}
            />
          }
          label={
            <Typography variant="body2" className={styles.switchLabel}>
              {intl.formatMessage(messages.sendSessionReminders)}
            </Typography>
          }
        />
      </Grid>
      {!isSubstage && (
        <Grid item xs={12} className={styles.fieldRow}>
          <FormControlLabel
            control={
              <Switch
                data-testid={IS_HIDDEN_FROM_PORTAL}
                checked={!advancedSettings?.is_hidden_from_portal}
                onChange={onToggleChange(IS_HIDDEN_FROM_PORTAL)}
              />
            }
            label={
              <Typography variant="body2" className={styles.switchLabel}>
                {intl.formatMessage(messages.showStageInApplicantPortal)}
              </Typography>
            }
          />
        </Grid>
      )}
      {!isSubstage && hasStageLabels && (
        <Grid item xs={12} className={styles.fieldRow}>
          <FormControlLabel
            control={
              <Switch
                data-testid="should_auto_advance_when_labels_complete"
                checked={
                  stage.advanced_settings
                    .should_auto_advance_when_labels_complete ?? false
                }
                onChange={onToggleChange(
                  'should_auto_advance_when_labels_complete',
                )}
              />
            }
            label={
              <Typography variant="body2" className={styles.switchLabel}>
                <FormattedMessage
                  {...messages.shouldAutoAdvanceWhenLabelsComplete}
                />
              </Typography>
            }
          />
        </Grid>
      )}
      {stage.type !== 'SchedulerV2Stage' && (
        <Grid item xs={12} className={styles.fieldRow}>
          <FormControlLabel
            control={
              <Switch
                data-testid={SHOW_APPLICANT_ONE_SESSION_PER_TIME_FRAME}
                checked={
                  additionalInfo?.show_applicant_one_session_per_time_frame
                }
                onChange={onToggleChange(
                  SHOW_APPLICANT_ONE_SESSION_PER_TIME_FRAME,
                )}
              />
            }
            label={
              <Typography variant="body2" className={styles.switchLabel}>
                {intl.formatMessage(messages.showOneSessionPerTimeframe)}
              </Typography>
            }
          />
        </Grid>
      )}
      {isPostInterviewRecruiterNotificationsEnabled && (
        <Grid item xs={12} className={styles.fieldRow}>
          <FormControlLabel
            control={
              <Switch
                data-testid={POST_INTERVIEW_MOVE_APPLICANT_TO_NEXT_STAGE}
                checked={
                  additionalInfo?.post_interview_move_applicant_to_next_stage
                }
                onChange={onToggleChange(
                  POST_INTERVIEW_MOVE_APPLICANT_TO_NEXT_STAGE,
                )}
              />
            }
            label={
              <Typography variant="body2" className={styles.switchLabel}>
                {intl.formatMessage(
                  messages.postInterviewMoveApplicantToNextStage,
                )}
              </Typography>
            }
          />
        </Grid>
      )}
      {isSlotAvailabilityBasedOpeningsEnabled && (
        <Grid item xs={12} className={styles.fieldRow}>
          <FormControlLabel
            control={
              <Switch
                data-testid={FUNNEL_STATUS_RESPONDS_TO_BOOKED_SLOTS}
                checked={additionalInfo?.funnel_status_responds_to_booked_slots}
                onChange={onToggleChange(
                  FUNNEL_STATUS_RESPONDS_TO_BOOKED_SLOTS,
                )}
              />
            }
            label={
              <Typography variant="body2" className={styles.switchLabel}>
                {intl.formatMessage(messages.openingActiveByBookedSlots)}
              </Typography>
            }
          />
          {additionalInfo?.funnel_status_responds_to_booked_slots && (
            <Grid
              className={styles.switchWithInputField}
              data-testid="slots-available-container"
            >
              <CustomInputLabel>
                <FormattedMessage
                  {...messages.openingActiveByBookedSlotsAdditionalInfo}
                />
              </CustomInputLabel>
              <StyledReactSelect
                className={styles.select}
                aria-label={intl.formatMessage(messages.recipient)}
                label={intl.formatMessage(messages.recipient)}
                options={userOptions}
                error={hasRecipientError}
                value={userOptions.find(user => {
                  return user.email === additionalInfo?.funnel_status_recipient;
                })}
                getOptionLabel={(user: User) => user.email}
                getOptionValue={(user: User) => user.email}
                onChange={(selectedValue: User) =>
                  onChangeUser(FUNNEL_STATUS_RECIPIENT, selectedValue)
                }
              />
              <Error
                error={
                  hasRecipientError &&
                  updateStageResult.error?.errors?.funnel_status_recipient
                }
              />
              <Grid className={styles.nestedInputRowContainer}>
                <Grid>
                  <Input
                    data-testid={FUNNEL_STATUS_SLOTS}
                    value={slotValue}
                    className={styles.nestedInput}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeInput(
                        FUNNEL_STATUS_SLOTS,
                        String(event.target.value),
                      )
                    }
                    error={hasSlotsAvailableError}
                    endAdornment={
                      <InputAdornment position="end">
                        <Typography variant="body2">
                          <FormattedMessage {...messages.sessions} />
                        </Typography>
                      </InputAdornment>
                    }
                  />
                  <Error
                    error={
                      hasSlotsAvailableError &&
                      updateStageResult.error?.errors?.funnel_status_slots
                    }
                  />
                </Grid>
                <Grid>
                  <Input
                    data-testid={FUNNEL_STATUS_DAYS}
                    value={dayValue}
                    className={styles.nestedInput}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeInput(
                        FUNNEL_STATUS_DAYS,
                        String(event.target.value),
                      )
                    }
                    error={hasDaysError}
                    endAdornment={
                      <InputAdornment position="end">
                        <Typography variant="body2">
                          <FormattedMessage {...messages.days} />
                        </Typography>
                      </InputAdornment>
                    }
                  />
                  <Error
                    error={
                      hasDaysError &&
                      updateStageResult.error?.errors?.funnel_status_days
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};
