import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  errorsAlert: {
    marginBottom: theme.spacing(3),
    '& > div': {
      margin: 'initial', // Have icon & text left aligned as per design
    },
  },
  requiredAsterix: {
    color: theme.palette.common.red400,
  },
  toggle: {
    paddingBottom: theme.spacing(3),

    '&:last-child': {
      paddingBottom: 0,
    },
  },
  toggleContainer: {
    paddingBottom: theme.spacing(4),
  },
  input: {
    borderColor: theme.palette.common.gray300,
  },
}));
