import { TextField, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { messages } from '../messages';
import { useStyles } from './styles';

interface LocationTextFieldProps {
  inputLocation: string;
  setInputLocation: (location: string) => void;
  handleAddNewLocation: () => void;
  setIsAddingLocationFromInput: (addingNewLocation: boolean) => void;
}

const LocationTextField: React.FC<LocationTextFieldProps> = ({
  inputLocation,
  setInputLocation,
  handleAddNewLocation,
  setIsAddingLocationFromInput,
}) => {
  const styles = useStyles();
  const intl = useIntl();

  return (
    <div className={styles.selectLocationContainer}>
      <Typography variant="body2" className={styles.label}>
        <FormattedMessage {...messages.location} />
      </Typography>
      <TextField
        placeholder={intl.formatMessage(messages.locationPlaceholder)}
        inputProps={{
          'data-testid':
            'calendar-create_event-location_details-location_text_field',
        }}
        fullWidth
        variant="outlined"
        size="small"
        value={inputLocation}
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
          setInputLocation(event.target.value);
          setIsAddingLocationFromInput(true);
        }}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            event.preventDefault();
            handleAddNewLocation();
          }
        }}
        onBlur={handleAddNewLocation}
      />
    </div>
  );
};

export default LocationTextField;
