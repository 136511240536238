/* eslint-disable no-underscore-dangle */
import { Button } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import {
  WorkflowSchedulerStage,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import { useSimpleToggle } from 'hooks';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { StageSettingCard } from 'containers/WorkflowEditor/components/StageSettingCard';

import { AddressFormModal } from './AddressFormModal';
import { AddressRow } from './AddressRow';
import { messages } from './messages';
import { useStyles } from './styles';

export interface OnsiteAddressCardProps {
  stage: WorkflowSchedulerStage;
  setStage: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
}

export const OnsiteAddressCard: FC<OnsiteAddressCardProps> = ({
  stage,
  setStage,
}) => {
  const styles = useStyles();
  const intl = useIntl();

  const restrictedLocations =
    stage.additional_info.restricted_locations_attributes;

  const {
    showContent: isAddressFormModalOpen,
    off: closeAddressFormModal,
    on: openAddressFormModal,
  } = useSimpleToggle(false);

  const availableRestrictedLocations = restrictedLocations.filter(
    restrictedLocation => !restrictedLocation._destroy,
  );

  return (
    <>
      <StageSettingCard
        variant="default"
        title={intl.formatMessage(messages.onsiteAddress)}
      >
        <Grid>
          <Typography variant="body2" className={styles.description}>
            {intl.formatMessage(messages.manageOnsiteAddressDescription)}
          </Typography>
          <Grid>
            <Grid container>
              {availableRestrictedLocations?.map(restrictedLocation => (
                <AddressRow
                  key={restrictedLocation.id}
                  restrictedLocation={restrictedLocation}
                  setStage={setStage}
                  stage={stage}
                />
              ))}
            </Grid>
          </Grid>
          <Button
            type="secondary"
            size="small"
            autoWidth
            className={styles.addAddressButton}
            onClick={openAddressFormModal}
          >
            <Typography variant="h4">
              {intl.formatMessage(messages.addAddress)}
            </Typography>
          </Button>
        </Grid>
      </StageSettingCard>
      {isAddressFormModalOpen && (
        <AddressFormModal
          isOpen={isAddressFormModalOpen}
          onClose={closeAddressFormModal}
          setStage={setStage}
          stage={stage}
        />
      )}
    </>
  );
};
