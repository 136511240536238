import { EventData } from 'api-clients/monolith';
import { useEffect, useState } from 'react';

import { isSameDay } from 'containers/ScheduleApplicantModal/components/AddAvailabilityModal/utils';

import { TimeOption } from './AddEventModal';

function addMinutes(date: Date, minutes: number) {
  const time = date.getTime();
  return new Date(time + minutes * 60 * 1000);
}

function timeConvert(rawMinutes: number) {
  const hours = Math.floor(rawMinutes / 60);
  const minutes = rawMinutes % 60;
  return hours >= 1 ? `${hours}h ${minutes}mins` : `${minutes}mins`;
}

const areBothDaysSame = (date: Date, today: Date) =>
  date.getFullYear() === today.getFullYear() &&
  date.getMonth() === today.getMonth() &&
  date.getDate() === today.getDate();

export function useGenerateAvailability({
  minDate,
  handleChange,
  defaultStart,
  defaultEnd,
}: {
  minDate: Date;
  handleChange: (params: Partial<EventData>) => void;
  defaultStart?: string;
  defaultEnd?: string;
}) {
  const [selectedDate, setSelectedDate] = useState(
    defaultStart && defaultEnd ? new Date(defaultStart) : minDate,
  );
  const [availableStartTimes, setAvailableStartTimes] = useState<TimeOption[]>(
    [],
  );
  const [availableEndTimes, setAvailableEndTimes] = useState<TimeOption[]>([]);

  const generateAvailabilityTimes = (
    date: Date,
    minutes: number,
  ): TimeOption[] => {
    const localEndDay = new Date(date);
    localEndDay.setHours(23);
    localEndDay.setMinutes(59);

    const times: TimeOption[] = [];
    let totalMinutes = 0;
    let selectedDate = date;

    while (selectedDate < localEndDay) {
      totalMinutes += minutes;

      times.push({
        label: selectedDate.toLocaleTimeString(),
        value: selectedDate.toISOString(),
        hours: timeConvert(totalMinutes),
      });

      selectedDate = addMinutes(selectedDate, minutes);
    }

    return times;
  };

  useEffect(() => {
    let currentAvailabilityTimes: TimeOption[];
    let endDateTime: Date;

    if (isSameDay(selectedDate)) {
      const currentMinute = new Date().getMinutes();
      // rounding down seconds and adding minutes to reach the next quarter hour
      const startDate = addMinutes(
        new Date(Math.floor(new Date().getTime() / 60000) * 60000),
        15 + Math.ceil(currentMinute / 15) * 15 - currentMinute,
      );

      currentAvailabilityTimes = generateAvailabilityTimes(startDate, 15);
      endDateTime = addMinutes(startDate, 15);
    } else {
      const selectedDateLocal = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate(),
        0,
        0,
      );

      currentAvailabilityTimes = generateAvailabilityTimes(
        selectedDateLocal,
        15,
      );
      endDateTime = addMinutes(selectedDateLocal, 15);
    }

    const currentAvailabilityEndTimes = generateAvailabilityTimes(
      endDateTime,
      15,
    );
    if (
      defaultStart &&
      defaultEnd &&
      areBothDaysSame(new Date(defaultStart), selectedDate)
    ) {
      handleChange({
        start_time: currentAvailabilityTimes.find(
          time => time.value === defaultStart,
        )?.value,
      });
      handleChange({
        end_time: currentAvailabilityEndTimes.find(
          time => time.value === defaultEnd,
        )?.value,
      });
    } else {
      handleChange({ start_time: currentAvailabilityTimes[0].value });
      handleChange({
        end_time:
          currentAvailabilityEndTimes[
            currentAvailabilityEndTimes.length > 1 ? 1 : 0
          ].value,
      });
    }
    setAvailableStartTimes(currentAvailabilityTimes.slice(0, -1)); // 11:45 - 12:00 is unbookable?
    setAvailableEndTimes(currentAvailabilityEndTimes);
  }, [handleChange, minDate, selectedDate, defaultStart, defaultEnd]);

  const onStartDateChange = (timeOption: TimeOption) => {
    const currentAvailabilityEndTimes = generateAvailabilityTimes(
      addMinutes(new Date(timeOption.value), 15),
      15,
    );
    setAvailableEndTimes(currentAvailabilityEndTimes);
    handleChange({ start_time: timeOption.value });
    handleChange({
      end_time:
        currentAvailabilityEndTimes[
          currentAvailabilityEndTimes.length > 1 ? 1 : 0
        ].value,
    });
  };

  return {
    selectedDate,
    availableStartTimes,
    availableEndTimes,
    onStartDateChange,
    setSelectedDate,
  };
}
