import {
  WorkflowSchedulerStage,
  WorkflowSchedulerV2Stage,
} from 'api-clients/monolith';
import React, { useContext, VFC } from 'react';
import { useSelector } from 'react-redux';

import { makeAvailableSlotsNewAvailabilityMessage } from 'containers/Auth_old/selectors';
import { StageSection } from 'containers/WorkflowEditor/components/StageSection';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { IdleMoveRule } from '../../../IdleMoveRule';
import { StageSettingMessageCard } from '../../../StageSettingMessageCard';
import { ExternalCalendarCard } from './ExternalCalendarCard';
import { LiveVideoCard } from './LiveVideoCard';
import { OnsiteAddressCard } from './OnsiteAddressCard';
import { SchedulerCard } from './SchedulerCard';
import { SessionHostsCardContainer } from './SchedulerUsersCard';
import { SessionDetailsCardContainer } from './SessionDetailsCard/SessionDetailsCard';

export interface SchedulerStageZeroProps {
  stage: WorkflowSchedulerV2Stage;
  address: string;
  openingExternalId: string;
}

export const SchedulerStageZero: VFC<SchedulerStageZeroProps> = ({
  stage,
  address,
  openingExternalId,
}) => {
  const { setStage } = useContext(StageContext);

  const isSubstage = Boolean(stage.parent_stage_id);

  return (
    <div>
      <StageSection>
        <SessionDetailsCardContainer
          stage={stage}
          setStage={setStage}
          address={address}
        />
      </StageSection>
      <StageSection>
        <SessionHostsCardContainer setStage={setStage} stage={stage} />
      </StageSection>
      <StageSection>
        <SchedulerCard
          stage={stage}
          setStage={setStage}
          openingExternalId={openingExternalId}
        />
      </StageSection>
      <StageSection>
        <LiveVideoCard stage={stage} setStage={setStage} />
      </StageSection>
      {!isSubstage && (
        <>
          <StageSection>
            <IdleMoveRule stage={stage} />
          </StageSection>
          <StageSection>
            <StageSettingMessageCard
              messageType="scheduler_v2"
              stageExternalId={stage.external_id}
            />
          </StageSection>
        </>
      )}
      <StageSection>
        <StageSettingMessageCard
          messageType="confirmation"
          stageExternalId={stage.external_id}
        />
      </StageSection>
      <StageSection>
        <StageSettingMessageCard
          messageType="cancellation_v2"
          stageExternalId={stage.external_id}
        />
      </StageSection>
      {!isSubstage && (
        <>
          <StageSection>
            <StageSettingMessageCard
              messageType="reminder"
              stageExternalId={stage.external_id}
            />
          </StageSection>
          <StageSection>
            <StageSettingMessageCard
              messageType="trigger"
              stageExternalId={stage.external_id}
            />
          </StageSection>
        </>
      )}
    </div>
  );
};

export interface SchedulerStageProps {
  stage: WorkflowSchedulerStage;
  openingExternalId: string;
}

export const SchedulerStage: VFC<SchedulerStageProps> = ({
  stage,
  openingExternalId,
}) => {
  const { setStage } = useContext(StageContext);
  const availableSlotsNewAvailabilityMessage = useSelector(
    makeAvailableSlotsNewAvailabilityMessage(),
  );

  const isSubstage = Boolean(stage.parent_stage_id);

  return (
    <div data-testid="schedulerStage">
      {!stage?.additional_info?.connected_to_external_calendar && (
        <StageSection>
          <ExternalCalendarCard />
        </StageSection>
      )}
      <StageSection>
        <SchedulerCard
          stage={stage}
          setStage={setStage}
          openingExternalId={openingExternalId}
        />
      </StageSection>
      <StageSection>
        <LiveVideoCard stage={stage} setStage={setStage} />
      </StageSection>
      <StageSection>
        <OnsiteAddressCard stage={stage} setStage={setStage} />
      </StageSection>
      {!isSubstage && (
        <>
          <StageSection>
            <IdleMoveRule stage={stage} />
          </StageSection>
          <StageSection>
            <StageSettingMessageCard
              messageType="scheduler"
              stageExternalId={stage.external_id}
            />
          </StageSection>
        </>
      )}
      <StageSection>
        <StageSettingMessageCard
          messageType="confirmation"
          stageExternalId={stage.external_id}
        />
      </StageSection>
      <StageSection>
        <StageSettingMessageCard
          messageType="cancellation"
          stageExternalId={stage.external_id}
        />
      </StageSection>
      {!isSubstage && (
        <>
          <StageSection>
            <StageSettingMessageCard
              messageType="reminder"
              stageExternalId={stage.external_id}
            />
          </StageSection>
          <StageSection>
            <StageSettingMessageCard
              messageType="trigger"
              stageExternalId={stage.external_id}
            />
          </StageSection>
        </>
      )}
      {availableSlotsNewAvailabilityMessage && (
        <StageSection>
          <StageSettingMessageCard
            messageType="availability"
            stageExternalId={stage.external_id}
          />
        </StageSection>
      )}
    </div>
  );
};
