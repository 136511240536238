import { useEffect, useRef, useState } from 'react';

const initialState = {
  clientHeight: 0,
  clientLeft: 0,
  clientTop: 0,
  clientWidth: 0,
  offsetHeight: 0,
  offsetLeft: 0,
  offsetTop: 0,
  offsetWidth: 0,
  scrollHeight: 0,
  scrollLeft: 0,
  scrollTop: 0,
  scrollWidth: 0,
};

const keys = Object.keys(initialState);

/**
 * Return ref and its client, offset, scroll values.
 *
 * @param {object|null} currentRef React ref object
 *
 * @returns {object}
 */
const useRefMeasurements = (currentRef = null) => {
  const newRef = useRef(null);
  const ref = currentRef || newRef;
  const [measurements, setMeasurements] = useState(initialState);

  useEffect(() => {
    if (ref && ref.current) {
      const updatedState = {};
      keys.forEach(key => {
        updatedState[key] = ref.current[key];
      });
      setMeasurements(updatedState);
    }
  }, [ref, setMeasurements]);

  return { ...measurements, ref };
};

export default useRefMeasurements;
