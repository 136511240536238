import { Input } from '@fountain/fountain-ui-components';
import { Action } from 'api-clients/monolith';
import { produce } from 'immer';
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';

import { Error } from 'components/Error';
import { getActionErrorMessage } from 'containers/WorkflowEditor/components/Rules/Action/constants';
import { messages as ruleMessages } from 'containers/WorkflowEditor/components/Rules/messages';
import {
  RulesProps,
  SharedActionProps,
} from 'containers/WorkflowEditor/components/Rules/types';

type FilteredKeys<T, U> = NonNullable<
  { [K in keyof T]: T[K] extends U | undefined ? K : never }[keyof T]
>;

export type ActionInputKeys = FilteredKeys<
  Action['extra'],
  string | number | boolean | Array<string>
>;

export interface InputFieldProps extends SharedActionProps {
  valueKey?: ActionInputKeys;
}

export const InputField: FC<InputFieldProps> = ({
  action,
  ruleId,
  setRules,
  valueKey = 'value',
  errors,
}) => {
  const intl = useIntl();
  const { extra } = action;

  const [inputValue, setInputValue] = useState(
    (extra[valueKey] as string) ?? '',
  );

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const onBlurInput = () => {
    setRules(
      produce((draftRules: RulesProps) => {
        const draftActions =
          draftRules[ruleId]?.action_set_attributes?.actions_attributes ?? [];

        const draftAction = draftActions.find(act => act.id === action.id);
        if (draftAction) {
          draftAction.extra = {
            ...draftAction.extra,
            [valueKey]: inputValue,
          };
        }
      }),
    );
  };

  const errorMessage = getActionErrorMessage(valueKey, errors);

  return (
    <>
      <Input
        label={intl.formatMessage(ruleMessages.value)}
        aria-label={intl.formatMessage(ruleMessages.value)}
        fullWidth
        value={inputValue}
        onChange={onChangeInput}
        onBlur={onBlurInput}
        error={Boolean(!extra[valueKey] && errorMessage)}
        required
      />
      <Error error={!extra[valueKey] && errorMessage} align="right" />
    </>
  );
};
