import { Button, Grid, Link as MuiLink, Typography } from '@material-ui/core';
import { CalendarContext } from 'api-clients/monolith';
import { messages } from 'Calendar/messages';
import { useCalendarStyles as useStyles } from 'Calendar/styles';
import cx from 'classnames';
import React, { VFC } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, Link, useParams } from 'react-router-dom';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

export enum CalendarTabs {
  Availability,
  Session,
  Integration,
}

export const SideBar: VFC<{
  router: CalendarContext;
  tabValue: CalendarTabs;
  onChange: (tab: CalendarTabs) => void;
}> = ({ router, tabValue, onChange }) => {
  type UrlParams = {
    accountSlug: string;
    funnelSlug: string;
  };
  const { accountSlug } = useParams<UrlParams>();
  const styles = useStyles();

  return (
    <Grid item xs={12} sm={12} md={3} lg={2} className={styles.tabs}>
      <Typography variant="h3" className={styles.tabTitle}>
        <FormattedMessage {...messages.schedule} />
      </Typography>
      {router.has_calendar_zero && (
        <Grid
          component={Button}
          onClick={() => onChange(CalendarTabs.Availability)}
          className={cx(styles.tab, {
            [styles.selectedTab]: tabValue === CalendarTabs.Availability,
          })}
        >
          <Grid
            component={Link}
            to={generatePath('/:accountSlug/schedule/availability', {
              accountSlug,
            })}
          >
            <FormattedMessage {...messages.availabilityTab} />
          </Grid>
        </Grid>
      )}

      {router.is_event_manager && (
        <>
          <Grid
            component={Button}
            onClick={() => onChange(CalendarTabs.Session)}
            className={cx(styles.tab, {
              [styles.selectedTab]: tabValue === CalendarTabs.Session,
            })}
          >
            <Grid
              component={Link}
              to={generatePath('/:accountSlug/schedule/session', {
                accountSlug,
              })}
            >
              <FormattedMessage {...messages.scheduledSessions} />
            </Grid>
          </Grid>
          {!router.has_calendar_zero && (
            <Grid className={styles.tab} component={Button}>
              <Grid
                component={MuiLink}
                href={`${REACT_APP_MONOLITH_BASE_URL}${router.calendar_v1_path}`}
              >
                <FormattedMessage {...messages.calendar} />
              </Grid>
            </Grid>
          )}
        </>
      )}
      {router.has_calendar_zero && (
        <>
          <Grid
            component={Button}
            onClick={() => onChange(CalendarTabs.Integration)}
            className={cx(styles.tab, {
              [styles.selectedTab]: tabValue === CalendarTabs.Integration,
            })}
          >
            <Grid
              component={Link}
              to={generatePath('/:accountSlug/schedule/calendar-integration', {
                accountSlug,
              })}
            >
              <FormattedMessage {...messages.calendarIntegrationTab} />
            </Grid>
          </Grid>
          {!router.is_event_manager && (
            <Grid className={styles.tab} component={Button}>
              <Grid
                component={MuiLink}
                href={`${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/sessions`}
              >
                <FormattedMessage {...messages.scheduledSessions} />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
