import { makeStyles } from '@material-ui/core/styles';

import { USER_COLUMN_WIDTH } from '../../constants';

const useStyles = makeStyles(theme => ({
  td: {
    justifyContent: 'center',
    minWidth: USER_COLUMN_WIDTH,
    maxWidth: USER_COLUMN_WIDTH,
  },
  usersDropdown: {
    minWidth: 240,
  },
  unreadMessagesCount: {
    height: 16,
    textAlign: 'center',
    backgroundColor: theme.palette.common.red600,
    color: theme.palette.common.white,
    borderRadius: 5,
  },
  badgePlus: {
    height: 32,
    width: 32,
    minWidth: 32,
    lineHeight: '1.75rem',
    textAlign: 'center',
    border: `1px solid ${theme.palette.common.gray200}`,
    color: theme.palette.primary.main,
    borderRadius: 20,
    margin: 'auto',
  },
  userSelectCircle: {
    '& > div': {
      margin: 'auto',
    },
  },
}));

export default useStyles;
