import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { classNames } from 'react-extras';
import { useDispatch } from 'react-redux';

import RecipientsModal from 'components/RecipientsModal';
import { setSelectedApplicantIds } from 'containers/ApplicantTableV2/actions';
import { addMessageAction } from 'containers/FlashMessage/actions';
import useApplicantsRequestingRevision from 'hooks/useApplicantsRequestingRevision';
import ApplicantIcon from 'images/applicants';
import WarningIcon from 'images/WarningSvg';

import useStyles from './styles';

function RedoRecipientsCard({
  applicantIds,
  partnerStageId,
  maxRecipients,
  title,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { fetchApplicantsRequestingRevision, applicants, isFetching } =
    useApplicantsRequestingRevision();

  const [isRecipientsModalOpen, setIsRecipientsModalOpen] = useState(false);

  const applicantsWithoutRedo = applicants.filter(
    applicant => !applicant.allow_redo,
  );

  useEffect(() => {
    fetchApplicantsRequestingRevision({ applicantIds, partnerStageId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (applicants.length) {
      const copyApplicantIds = [...applicantIds];

      applicantsWithoutRedo.forEach(applicant => {
        const index = copyApplicantIds.indexOf(applicant.external_id);
        copyApplicantIds.splice(index, 1);
      });

      if (!copyApplicantIds.length) {
        dispatch(
          addMessageAction(
            'The selected applicants can not be revised for the selected Partner Stage.',
            'error',
          ),
        );
      }

      dispatch(setSelectedApplicantIds(copyApplicantIds));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicants]);

  const handleOpenRecipientsModal = () => {
    setIsRecipientsModalOpen(true);
  };

  const handleCloseRecipientsModal = () => {
    setIsRecipientsModalOpen(false);
  };

  const selectedApplicantsCount = applicantIds.length;

  const recipientsLimitExceeded =
    !isFetching && selectedApplicantsCount > maxRecipients;

  const WarningsCount = !!applicantsWithoutRedo.length && (
    <Grid className={classes.warningsCount}>
      <WarningIcon className={classes.warningIcon} />
      <Typography variant="overline" className={classes.warningText}>
        {applicantsWithoutRedo.length} Warning
        {applicantsWithoutRedo.length > 1 && 's'}
      </Typography>
    </Grid>
  );

  return (
    <>
      <Grid className={classes.recipientsCard}>
        <Typography variant="h4">{title}</Typography>
        <Grid
          container
          alignItems="center"
          className={classes.selectedApplicantsCountContainer}
        >
          <ApplicantIcon
            className={classNames(classes.applicantIcon, {
              error: recipientsLimitExceeded,
            })}
          />
          <Typography
            className={recipientsLimitExceeded && classes.errorText}
            variant="overline"
          >
            {selectedApplicantsCount} Recipient
            {(selectedApplicantsCount > 1 || selectedApplicantsCount <= 0) &&
              's'}
          </Typography>
          {recipientsLimitExceeded && (
            <Typography className={classes.errorText} variant="overline">
              &nbsp;(max. {maxRecipients})
            </Typography>
          )}
          {WarningsCount}
        </Grid>
        {!isFetching && applicantsWithoutRedo.length > 0 && (
          <Typography
            className={classes.review}
            onClick={handleOpenRecipientsModal}
            variant="body2"
            color="primary"
          >
            Review Warnings
          </Typography>
        )}
      </Grid>
      {isRecipientsModalOpen && (
        <RecipientsModal
          handleClose={handleCloseRecipientsModal}
          applicantsWithoutRedo={applicantsWithoutRedo}
          WarningsCount={WarningsCount}
          maxRecipients={maxRecipients}
          selectedApplicantsCount={selectedApplicantsCount}
        />
      )}
    </>
  );
}

RedoRecipientsCard.propTypes = {
  applicantIds: PropTypes.array,
  partnerStageId: PropTypes.string,
  maxRecipients: PropTypes.number,
  title: PropTypes.string.isRequired,
};

export default RedoRecipientsCard;
