import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import makeSelectApplicantTableV2 from 'containers/ApplicantTableV2/selectors';
import { BulkSelect } from 'containers/ApplicantTableV2/types';
import { POPUP_TYPES } from 'containers/GlobalPopup/constants';
import { ESTIMATED_NUMBER_OF_APPLICANTS_PROCESSED_PER_MINUTE_BY_TYPE } from 'utils/constants';

import BulkActionConfirmationModal from './BulkActionConfirmationModal';

function withBulkActionConfirmation(WrappedComponent) {
  const Component = props => {
    const { bulkSelect, selectedApplicantIds, totalRows, selectByCount } =
      useSelector(makeSelectApplicantTableV2());

    const location = useLocation();
    const { popupType } = queryString.parse(location.search);

    const [actionType, setActionType] = useState(undefined);
    const [showBulkActionModal, setShowBulkActionModal] = useState(false);

    useEffect(() => {
      if (
        popupType === POPUP_TYPES.SEND_BULK_SMS_POPUP ||
        popupType === POPUP_TYPES.SEND_BULK_EMAIL_POPUP
      ) {
        setActionType('message');
      } else {
        setActionType('other');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (!selectedApplicantIds.length) {
        // todo: TSQ-217 - remove in favor of onClose after removal of old move applicant dialogs
        if (props.handleClose) {
          props.handleClose();
        }
        if (props.onClose) {
          props.onClose();
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedApplicantIds]);

    let numberOfSelectedApplicants;
    switch (bulkSelect) {
      case BulkSelect.ALL:
        numberOfSelectedApplicants = totalRows;
        break;
      case BulkSelect.SOME:
        numberOfSelectedApplicants = selectedApplicantIds.length;
        break;
      case BulkSelect.COUNT:
        numberOfSelectedApplicants = selectByCount;
        break;
      default:
        numberOfSelectedApplicants = 0;
        break;
    }

    const handleContinue = () => setShowBulkActionModal(true);

    if (
      numberOfSelectedApplicants >
        ESTIMATED_NUMBER_OF_APPLICANTS_PROCESSED_PER_MINUTE_BY_TYPE[
          actionType
        ] &&
      !showBulkActionModal
    ) {
      return (
        <BulkActionConfirmationModal
          actionType={actionType}
          handleClose={props.handleClose}
          handleContinue={handleContinue}
          numberOfSelectedApplicants={numberOfSelectedApplicants}
        />
      );
    }

    return <WrappedComponent {...props} />;
  };

  Component.propTypes = {
    // todo: TSQ-217 - remove in favor of onClose after removal of old move applicant dialogs
    handleClose: PropTypes.func,
    // todo: TSQ-217 - make required
    onClose: PropTypes.func,
  };

  return Component;
}

export default withBulkActionConfirmation;
