/*
 *
 * SendSmsPopup actions
 *
 */

import {
  DELIVER_SMS_ERROR,
  DELIVER_SMS_INIT,
  DELIVER_SMS_SUCCESS,
  FETCH_MEMBERS_ERROR,
  FETCH_MEMBERS_INIT,
  FETCH_MEMBERS_SUCCESS,
} from './constants';

export function deliverMessageInitAction(params, messages) {
  return {
    type: DELIVER_SMS_INIT,
    params,
    messages,
  };
}

export function deliverSmsSuccessAction(payload) {
  return {
    type: DELIVER_SMS_SUCCESS,
    payload,
  };
}

export function deliverSmsErrorAction() {
  return {
    type: DELIVER_SMS_ERROR,
  };
}

export function fetchMembersInitAction(brandSlug) {
  return {
    type: FETCH_MEMBERS_INIT,
    brandSlug,
  };
}

export function fetchMembersSuccessAction(users) {
  return {
    type: FETCH_MEMBERS_SUCCESS,
    users,
  };
}

export function fetchMembersErrorAction(error) {
  return {
    type: FETCH_MEMBERS_ERROR,
    error,
  };
}
