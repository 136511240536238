import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  and: {
    defaultMessage: 'AND',
    id: `app.containers.WorkflowEditor.components.StageDetail.JobRouterStages.JobRouterConditions.JobRouterConditionSet.JobRouterCondition.and`,
  },
  condition: {
    defaultMessage: 'CONDITION',
    id: `app.containers.WorkflowEditor.components.StageDetail.JobRouterStages.JobRouterConditions.JobRouterConditionSet.JobRouterCondition.condition`,
  },
  deleteConditionNumber: {
    defaultMessage: 'Delete condition {number}',
    id: `app.containers.WorkflowEditor.components.StageDetail.JobRouterStages.JobRouterConditions.JobRouterConditionSet.JobRouterCondition.deleteConditionNumber`,
  },
  conditionType: {
    defaultMessage: 'Condition Type',
    id: `app.containers.WorkflowEditor.components.StageDetail.JobRouterStages.JobRouterConditions.JobRouterConditionSet.JobRouterCondition.conditionType`,
  },
  selectCondition: {
    defaultMessage: 'Select condition',
    id: `app.containers.WorkflowEditor.components.StageDetail.JobRouterStages.JobRouterConditions.JobRouterConditionSet.JobRouterCondition.selectCondition`,
  },
  selectValue: {
    defaultMessage: 'Select value',
    id: `app.containers.WorkflowEditor.components.StageDetail.JobRouterStages.JobRouterConditions.JobRouterConditionSet.JobRouterCondition.selectValue`,
  },
  infoIcon: {
    defaultMessage: 'Info icon',
    id: `app.containers.WorkflowEditor.components.StageDetail.JobRouterStages.JobRouterConditions.JobRouterConditionSet.JobRouterCondition.infoIcon`,
  },
  conditionTypeNumber: {
    defaultMessage: 'Condition type {number}',
    id: `app.containers.WorkflowEditor.components.StageDetail.JobRouterStages.JobRouterConditions.JobRouterConditionSet.JobRouterCondition.conditionTypeNumber`,
  },
  conditionValueNumber: {
    defaultMessage: 'Condition value {number}',
    id: `app.containers.WorkflowEditor.components.StageDetail.JobRouterStages.JobRouterConditions.JobRouterConditionSet.JobRouterCondition.conditionValueNumber`,
  },
});
