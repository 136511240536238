import { CardProps } from './Card';
import { ConversationIcon } from './images/ConversationIcon';
import { CustomizeIcon } from './images/CustomizeIcon';
import { HumanHandoffIcon } from './images/HumanHandoffIcon';
import { QuestionMarkIcon } from './images/QuestionMarkIcon';
import { messages } from './messages';

export const cardContents: CardProps[] = [
  {
    Icon: ConversationIcon,
    header: messages.conversationHeader,
    descriptions: [
      messages.conversationDescription1,
      messages.conversationDescription2,
      messages.conversationDescription3,
    ],
  },
  {
    Icon: CustomizeIcon,
    header: messages.customizeHeader,
    descriptions: [
      messages.customizeDescription1,
      messages.customizeDescription2,
    ],
  },
  {
    Icon: HumanHandoffIcon,
    header: messages.humanHandoffHeader,
    descriptions: [
      messages.humanHandoffDescription1,
      messages.humanHandoffDescription2,
      messages.humanHandoffDescription3,
    ],
  },
  {
    Icon: QuestionMarkIcon,
    header: messages.collectDataHeader,
    descriptions: [
      messages.collectDataDescription1,
      messages.collectDataDescription2,
      messages.collectDataDescription3,
    ],
  },
];
