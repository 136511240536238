import { useCallback, useState } from 'react';

export const useSimpleToggle = (initialValue = false) => {
  const [showContent, setShowContent] = useState(initialValue);
  return {
    showContent,
    on: useCallback(() => setShowContent(true), []),
    off: useCallback(() => setShowContent(false), []),
    toggle: useCallback(() => setShowContent(current => !current), []),
  };
};

export default useSimpleToggle;
