import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addAStageTitle: {
    id: `app.containers.WorkflowEditor.components.StageColumnHeader.CloneStageModal.addAStageTitle`,
    defaultMessage: 'Add a stage title',
  },
  cancel: {
    id: `app.containers.WorkflowEditor.components.StageColumnHeader.CloneStageModal.cancel`,
    defaultMessage: 'Cancel',
  },
  cloneStage: {
    id: `app.containers.WorkflowEditor.components.StageColumnHeader.CloneStageModal.cloneStage`,
    defaultMessage: 'Clone stage',
  },
  cloneStageModal: {
    id: `app.containers.WorkflowEditor.components.StageColumnHeader.CloneStageModal.cloneStageModal`,
    defaultMessage: 'Clone Stage Modal',
  },
  cloneStageModalContent: {
    id: `app.containers.WorkflowEditor.components.StageColumnHeader.CloneStageModal.cloneStageModalContent`,
    defaultMessage:
      'Cloning a stage will copy all messages and settings that aren’t associated with a specific stage. This includes workflow specific items like move rules that will not be copied since they are dependent on the entire workflow.',
  },
  cloneStageModalHeader: {
    id: `app.containers.WorkflowEditor.components.StageColumnHeader.CloneStageModal.cloneStageModalHeader`,
    defaultMessage: 'Clone Stage Modal Header',
  },
  cloneExistingStage: {
    id: `app.containers.WorkflowEditor.components.StageColumnHeader.CloneStageModal.cloneExistingStage`,
    defaultMessage: 'Clone existing stage',
  },
  opening: {
    id: `app.containers.WorkflowEditor.components.StageColumnHeader.CloneStageModal.opening`,
    defaultMessage: 'Opening',
  },
  selectAnOpening: {
    id: `app.containers.WorkflowEditor.components.StageColumnHeader.CloneStageModal.selectAnOpening`,
    defaultMessage: 'Select an opening',
  },
  selectAStage: {
    id: `app.containers.WorkflowEditor.components.StageColumnHeader.CloneStageModal.selectAStage`,
    defaultMessage: 'Select a stage',
  },
  stage: {
    id: `app.containers.WorkflowEditor.components.StageColumnHeader.CloneStageModal.stage`,
    defaultMessage: 'Stage',
  },
  stagePlacement: {
    id: `app.containers.WorkflowEditor.components.StageColumnHeader.CloneStageModal.stagePlacement`,
    defaultMessage: 'Stage placement',
  },
  stagePlacementDropdown: {
    id: `app.containers.WorkflowEditor.components.StageColumnHeader.CloneStageModal.stagePlacementDropdown`,
    defaultMessage: 'Stage Placement Dropdown',
  },
  stageSuccessfullyCloned: {
    id: `app.containers.WorkflowEditor.components.StageColumnHeader.CloneStageModal.stageSuccessfullyCloned`,
    defaultMessage: 'Stage successfully cloned',
  },
  stageTitleInput: {
    id: `app.containers.WorkflowEditor.components.StageColumnHeader.CloneStageModal.stageTitleInput`,
    defaultMessage: 'Stage Title Input',
  },
  stageTypeSeletor: {
    id: `app.containers.WorkflowEditor.components.StageColumnHeader.CloneStageModal.stageTypeSeletor`,
    defaultMessage: 'Stage Type Selector',
  },
  title: {
    id: `app.containers.WorkflowEditor.components.StageColumnHeader.CloneStageModal.title`,
    defaultMessage: 'Title',
  },
  stageSource: {
    id: `app.containers.WorkflowEditor.components.StageColumnHeader.CloneStageModal.stageSource`,
    defaultMessage: 'Stage Source',
  },
  workflow: {
    id: `app.containers.WorkflowEditor.components.StageColumnHeader.CloneStageModal.workflow`,
    defaultMessage: 'Workflow',
  },
  mustChoose: {
    id: `app.containers.WorkflowEditor.components.StageColumnHeader.CloneStageModal.mustChoose`,
    defaultMessage:
      'Must choose an opening or a workflow to clone a stage from',
  },
});
