import { Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from './messages';

const useStyles = makeStyles<Theme, { metric: string }>(theme => ({
  box: {
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.common.gray300}`,
    borderRadius: '12px',
    padding: theme.spacing(4),
    width: '308px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    gridArea: props => props.metric,
  },
  count: {
    fontSize: '80px',
    fontWeight: 700,
    lineHeight: '80px',
    color: theme.palette.common.gray800,
  },
  title: {
    lineHeight: '20px',
    height: '40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
  },
}));

export interface CardProps {
  metric: keyof typeof messages;
  value: number;
}

export const Card: VFC<CardProps> = ({ metric, value }) => {
  const classes = useStyles({ metric });

  return (
    <div className={classes.box}>
      <Typography display="inline" variant="h1" className={classes.count}>
        {value}
      </Typography>
      <Typography display="inline" variant="body2" className={classes.title}>
        <FormattedMessage {...messages[metric]} />
      </Typography>
    </div>
  );
};
