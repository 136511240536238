import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React, { FC } from 'react';

export const AssessmentStageIcon: FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.725 10.775L5.225 9.275C4.925 8.975 4.925 8.525 5.225 8.225C5.525 7.925 5.975 7.925 6.275 8.225L7.25 9.2L9.725 6.725C10.025 6.425 10.475 6.425 10.775 6.725C11.075 7.025 11.075 7.475 10.775 7.775L7.775 10.775C7.475 11.075 7.1 11.15 6.725 10.775Z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M13.25 2.75H11V4.25H12.5V12.5H3.5V4.25H5V2.75H2.75C2.3 2.75 2 3.05 2 3.5V13.25C2 13.7 2.3 14 2.75 14H13.25C13.7 14 14 13.7 14 13.25V3.5C14 3.05 13.7 2.75 13.25 2.75Z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path d="M10.25 2H5.75V5H10.25V2Z" fillRule="evenodd" clipRule="evenodd" />
  </SvgIcon>
);
