import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from './messages';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(6),
    gap: theme.spacing(2),
  },
  header: {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: 500,
  },
}));

export const AiRecommendations = () => {
  const classes = useStyles();
  return (
    <Grid className={classes.container}>
      <Grid className={classes.header}>
        <Typography variant="h2">
          <FormattedMessage {...messages.aiRecommendations} />
        </Typography>
      </Grid>
    </Grid>
  );
};
