import { Grid } from '@material-ui/core';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

import FountainLogo from 'images/fountain-logo.svg';

const GlobalNavLogo = () => {
  const { accountSlug } = useParams();
  const openingsPath = `/${accountSlug}/openings`;

  return (
    <Grid item>
      <Link to={openingsPath} data-testid="navbar-logo">
        <img src={FountainLogo} alt="fountain logo" />
      </Link>
    </Grid>
  );
};

export default GlobalNavLogo;
