import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addOnsiteAddress: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.OnsiteAddressCard.AddressFormModal.addOnsiteAddress`,
    defaultMessage: 'Add Onsite Address',
  },
  address: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.OnsiteAddressCard.AddressFormModal.address`,
    defaultMessage: 'Address',
  },
  cancel: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.OnsiteAddressCard.AddressFormModal.cancel`,
    defaultMessage: 'Cancel',
  },
  saveAddress: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.OnsiteAddressCard.AddressFormModal.saveAddress`,
    defaultMessage: 'Save Address',
  },
});
