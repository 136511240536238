import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  requirementContainer: {
    marginBottom: theme.spacing(5),

    '&:last-child': {
      marginBottom: 0,
    },
  },
  requiredAsterix: {
    color: theme.palette.common.red400,
  },
  browserRow: {
    marginBottom: theme.spacing(2),
    width: theme.spacing(35),

    '&:last-child': {
      marginBottom: 0,
    },
  },
  description: {
    color: theme.palette.common.gray800,
    paddingBottom: theme.spacing(3),
  },
  hardwareContainer: {
    gap: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
  },
  hardwareSpecInput: {
    width: theme.spacing(12.5),
  },
  hardwareSpecSelect: {
    minWidth: theme.spacing(12.5),
  },
  heading: {
    color: theme.palette.common.gray800,
    paddingBottom: theme.spacing(3),
    fontWeight: 'bold',
  },
  inputErrorPresent: {
    minHeight: theme.spacing(10.125),
  },
  internetContainer: {
    gap: theme.spacing(3),
    display: 'flex',
    width: 'fit-content',
  },
  internetInput: {
    width: theme.spacing(20),
  },
  screenResolutionContainer: {
    gap: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
  },
  screenResolutionLabel: {
    display: 'flex',
    gap: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    textTransform: 'capitalize',
  },
  screenResolutionInput: {
    width: theme.spacing(10),
  },
  screenResolutionX: {
    minHeight: theme.spacing(7.25),
  },
  trashcanIcon: {
    fontSize: theme.spacing(1.5),
    marginRight: theme.spacing(1),
  },
}));
