import { EventAvailableSlot } from 'api-clients/monolith';

export const defaultEventData = {
  title: '',
  instructions: '',
  start_time: '',
  end_time: '',
  location: '',
  max_attendees: '1',
  opening_stage_ids: [],
  booked_slots_count: undefined,
  period: '' as EventAvailableSlot['period'],
  user: '',
};

export const conditionTypes = [
  { value: 'stage', label: 'Stage is...' },
  { value: 'opening', label: 'Opening is...' },
  { value: 'location_group', label: 'Location Group is...' },
];

export const repeatEvent: { value: string; label: string }[] = [
  { value: '', label: 'Does not repeat' },
  { value: 'days', label: 'Daily' },
  { value: 'weeks', label: 'Weekly' },
  { value: 'weekdays', label: 'Mon - Fri' },
];

export const frequencyOptions = Array.from({ length: 30 }, (_, index) => ({
  value: index + 1,
  label: `${index + 1}`,
}));

export type EventKey = keyof EventAvailableSlot;
export type EventValue = EventAvailableSlot[EventKey];

export type HandleSettingChange = (key: EventKey, value: EventValue) => void;

export const NO_OPTIONS = 15;
