import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';

import EditingField from 'components/ApplicantDrawerDetailsList/components/EditingField';
import useStyles from 'containers/DataValidationModal/components/Field/styles';
import {
  formatValue,
  onAddressChange,
  onAvailabilityChange,
  onCheckboxChange,
  onDateChange,
  onDropdownChange,
  onValueChange,
} from 'containers/DataValidationModal/components/helpers';
import { TestId } from 'containers/DataValidationModal/components/testData';
import { Title } from 'containers/DataValidationModal/components/Title';
import { SharedFieldProps } from 'containers/DataValidationModal/components/types';
import useApplicantUpdateDetail from 'hooks/useApplicantUpdateDetail';

export type FieldProps = SharedFieldProps;

export const Field: FC<FieldProps> = ({
  applicantId,
  className,
  detail,
  error,
  isRequired,
  preventStoreUpdates,
  savePayload,
  setIsDirty,
  type,
  updateApplicantDisabled,
}) => {
  const styles = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [value, setValue] = useState(detail.value);
  const { updateApplicantDetail } = useApplicantUpdateDetail(
    applicantId,
    type,
    preventStoreUpdates,
  );

  useEffect(() => {
    if (detail.type === 'availability' && !detail.value) {
      setValue([]);
    }
    if (detail.option_bank_id && detail.selected_option) {
      if (detail.type === 'dropdown') {
        // OptionBankDropdown expects the full selected option object
        setValue(detail.selected_option);
      } else {
        setValue(detail.selected_option.value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatePayload = () => {
    const payload = {
      ...(type && {
        [type]: {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          [detail.key]: formatValue(detail, value),
        },
      }),
    };

    if (updateApplicantDisabled) {
      savePayload(payload);
      return;
    }

    void updateApplicantDetail(payload);
  };

  useEffect(() => {
    updatePayload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Grid
      container
      data-testid={TestId.FIELD}
      direction="row"
      alignItems="center"
      className={classNames(styles.field, className)}
      justify="space-between"
    >
      <Grid
        item
        container
        className={styles.fieldContainer}
        justify="space-between"
      >
        <Title
          isRequired={isRequired}
          subtitle={detail.subtitle}
          title={detail.title}
        />
        <Grid container justify="space-between" wrap="nowrap">
          <Grid item className={styles.input}>
            <EditingField
              applicantId={applicantId}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              value={value}
              onDropdownChange={onDropdownChange}
              onValueChange={onValueChange}
              onCheckboxChange={onCheckboxChange}
              onAvailabilityChange={onAvailabilityChange}
              onDateChange={onDateChange}
              onAddressChange={onAddressChange}
              detail={detail}
              error={error}
              setIsDirty={setIsDirty}
              setValue={setValue}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
