import { useCallback } from 'react';

import { debounce } from 'utils/debounce';

type Callback = Parameters<typeof useCallback>[0];

/**
 * Hook for use in React components.
 *
 * @param {function} fn function to execute after debounce period
 * @param {number} delay how long to wait before executing `fn`
 *
 * @returns {function} Returns function to be called with arguments to pass to `fn`
 *
 * @example
 *
 * // debounce function passed as prop
 * const Component = ({ onChange, ...props }) => {
 *    const [value, setValue] = React.useState('');
 *
 *    const debounce = useDebounce(onChange, 500);
 *
 *    const handleChange = (event) => {
 *      setValue(event.target.value);
 *      debounce(event.target.value, Date.now());
 *    }
 *
 *    return (<input onChange={handleChange} value={value} />)
 * }
 *
 * @example
 *
 * // debounce inline function (must wrap in `React.useCallback`)
 * const Component = () => {
 *    const [value, setValue] = React.useState('');
 *
 *    const debounce = useDebounce(
 *      React.useCallback(
 *        (str, timestamp) => {
 *          window.console.log({ str, timestamp });
 *        }
 *      ), 500
 *    );
 *
 *    const handleChange = (event) => {
 *      setValue(event.target.value);
 *      debounce(event.target.value, Date.now());
 *    }
 *
 *    return (<input onChange={handleChange} value={value} />)
 * }
 */
export const useDebounce = (fn: Callback, delay: number): Callback =>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useCallback(debounce(fn, delay), [fn, delay]);
