import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  active: {
    borderBottom: `${theme.spacing(0.5)}px solid ${
      theme.palette.common.blue400
    }`,
    borderTop: `${theme.spacing(0.5)}px solid transparent`,
    '& p': {
      color: theme.palette.common.gray800,
      fontFamily: theme.typography.h4.fontFamily,
      fontWeight: 500,
    },
    '& span': {
      color: theme.palette.common.gray800,
      fontFamily: theme.typography.h4.fontFamily,
      fontWeight: 500,
    },
  },
  container: {
    height: '100%',
    width: 'fit-content',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  leftSectionTrigger: {
    color: theme.palette.common.gray800,
    '&:hover': {
      color: theme.palette.common.blue400,
    },
    '&$menuItem': {
      marginRight: theme.spacing(0.5),
    },
  },
  routerLink: {
    textDecoration: 'none',
  },
}));

export default useStyles;
