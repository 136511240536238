import { Checkbox, FormControlLabel, Grid, Paper } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useStyles } from 'components/FilterDropdown/styles';
import { Filterable } from 'components/FilterDropdown/util';
import SearchInput from 'components/SearchInput';

import { messages } from '../messages';

export interface FilterOpenProps<T> {
  name: 'opening' | 'stage' | 'locationGroup';
  setQuery: (query: string) => void;
  selected: T[];
  setSelected: (selection: T[]) => void;
  options: T[];
}

export const FilterOpen: FC<FilterOpenProps<Filterable>> = ({
  name,
  selected,
  setSelected,
  setQuery,
  options,
}) => {
  const [search, setSearch] = useState('');
  const classes = useStyles();
  const intl = useIntl();

  const remaining = options.filter(
    opening => !selected.some(option => option.id === opening.id),
  );

  useEffect(() => {
    setQuery(search);
  }, [search, setQuery]);

  return (
    <Paper
      variant="outlined"
      className={
        classes[name === 'stage' ? 'rightOpenContainer' : 'openContainer']
      }
    >
      <SearchInput
        // @ts-expect-error this will go away when `SearchInput` is converted to typescript
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearch(e.target.value)
        }
        value={search}
        fullWidth
        className={classes.searchInput}
        onClear={() => setSearch('')}
        placeholder={intl.formatMessage(messages.searchPlaceholder, { name })}
      />
      {selected.map(option => (
        <Grid key={option.id}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked
                onChange={() =>
                  setSelected(
                    selected.filter(opening => opening.id !== option.id),
                  )
                }
              />
            }
            label={option.title}
            className={classes.checks}
          />
        </Grid>
      ))}
      {selected.length > 0 && remaining.length > 0 && (
        <Grid className={classes.separator} />
      )}
      {remaining.map(option => (
        <Grid key={option.id}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={false}
                onChange={() => setSelected([...selected, option])}
              />
            }
            label={option.title}
            title={option.title}
            className={classes.checks}
          />
        </Grid>
      ))}
    </Paper>
  );
};
