import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import { addMessageAction } from 'containers/FlashMessage/actions';
import { apiPost } from 'utils/axios';

// display of rotation is local until blobs are refetched
export default function useBlobRotate(applicantId) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleRotateSave = async (blobId, degrees) => {
    const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/blobs/${blobId}/rotate`;
    setIsLoading(true);
    try {
      await apiPost(url, { degrees });
      dispatch(
        addMessageAction(
          'Successfully saved! Your changes will be applied soon.',
        ),
      );
      setError('');
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error) {
      dispatch(
        addMessageAction('Whoops, something went wrong. Please try again.'),
      );
    }
  }, [dispatch, error]);

  return { handleRotateSave, isLoading };
}
