import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  stageSettingCardContainer: {
    width: '100%',
    borderRadius: theme.misc.borderRadius,
    border: theme.customBorders.gray300,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.common.gray100,
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    height: theme.spacing(7.125),
    borderTopLeftRadius: theme.misc.borderRadius,
    borderTopRightRadius: theme.misc.borderRadius,
  },
  icon: {
    fill: theme.palette.common.red400,
    marginTop: theme.spacing(0.5),
  },
  button: {
    height: theme.spacing(4),
    '&:hover': {
      backgroundColor: theme.palette.common.gray200,
    },
  },
  text: {
    color: theme.palette.common.gray800,
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '66%',
  },
  childrenContainer: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
    alignItems: 'center',
    borderRadius: theme.misc.borderRadius,
  },
  leftButton: {
    marginRight: 'auto',
    marginLeft: theme.spacing(2),
  },
}));
