import { defineMessages } from 'react-intl';

export const scope = 'app.containers.MessageTemplateTextEditor';

export default defineMessages({
  confirmExitHtml: {
    id: `${scope}.confirmExitHtml`,
    defaultMessage:
      'Are you sure you want to exit HTML mode? Your HTML may be modified',
  },
  deleteModalConfirmTrailingText: {
    id: `${scope}.deleteModalConfirmTrailingText`,
    defaultMessage:
      'Are you sure you want to delete the {templateName} template?',
  },
  htmlEditor: {
    id: `${scope}.htmlEditor`,
    defaultMessage: 'HTML Editor',
  },
  mergeKeys: {
    id: `${scope}.mergeKeys`,
    defaultMessage: 'Merge Keys',
  },
  template: {
    id: `${scope}.template`,
    defaultMessage: 'template',
  },
  templates: {
    id: `${scope}.templates`,
    defaultMessage: 'Templates',
  },
});
