import { Button, CustomTooltip } from '@fountain/fountain-ui-components';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChatbotService, ChatbotTrainingStatus } from 'api-clients/monolith';
import classnames from 'classnames';
import React, { useCallback, useMemo, useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addMessageAction } from 'containers/FlashMessage/actions';

import { SelectedBrand } from '../types';
import { ErrorIcon } from './icons/ErrorIcon';
import { LoadingIcon } from './icons/LoadingIcon';
import { PendingIcon } from './icons/PendingIcon';
import { SuccessIcon } from './icons/SuccessIcon';
import { messages } from './messages';

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: '5px',
    width: '188px',
    padding: theme.spacing(1, 2),
    '&:disabled': {
      opacity: 'unset',
      backgroundColor: 'unset',
    },
  },
  icon: {
    width: '16px',
    height: '16px',
    marginLeft: theme.spacing(1),
  },
  buttonText: {
    lineHeight: '20px',
    fontWeight: 450,
  },
  '@keyframes rotation': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(359deg)',
    },
  },
  loader: {
    animation: '$rotation 0.7s infinite linear',
  },
}));

export interface TrainingStatusProps {
  selectedBrand: SelectedBrand;
  careerSiteTrainingStatus: ChatbotTrainingStatus;
  setCareerSiteTrainingStatus: React.Dispatch<
    React.SetStateAction<ChatbotTrainingStatus>
  >;
}

export const CareerSiteTrainingStatus: VFC<TrainingStatusProps> = ({
  selectedBrand,
  careerSiteTrainingStatus,
  setCareerSiteTrainingStatus,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const statusDetails = useMemo(() => {
    switch (careerSiteTrainingStatus) {
      case 'SUCCESS':
        return {
          message: messages.scrapingSuccessStatus,
          icon: <SuccessIcon className={styles.icon} />,
        };
      case 'PENDING':
      case 'RUNNING':
        return {
          message: messages.scrapingPendingStatus,
          icon: <PendingIcon className={styles.icon} />,
        };
      case 'FAILURE':
        return {
          message: messages.scrapingErrorStatus,
          icon: <ErrorIcon className={styles.icon} />,
        };
      default:
        return null;
    }
  }, [careerSiteTrainingStatus, styles.icon]);

  const [isFetchingStatus, setIsFetchingStatus] = useState(false);

  const fetchTrainingStatus = useCallback(() => {
    return ChatbotService.postInternalApiChatbotSettingsRefreshCareerSiteScrapingStatus(
      selectedBrand.brandId,
      selectedBrand.isAllBrands,
    );
  }, [selectedBrand]);

  const handleFetchStatusClick = () => {
    if (
      careerSiteTrainingStatus === 'PENDING' ||
      careerSiteTrainingStatus === 'RUNNING'
    ) {
      setIsFetchingStatus(true);
      fetchTrainingStatus()
        .then(result => {
          setCareerSiteTrainingStatus(result.career_site_scraping_status);
          if (
            result.career_site_scraping_status === 'PENDING' ||
            result.career_site_scraping_status === 'RUNNING'
          ) {
            dispatch(
              addMessageAction(
                intl.formatMessage(messages.fetchPendingStatusForCareerSite),
                'success',
              ),
            );
          }
        })
        .catch(() => {
          dispatch(
            addMessageAction(
              intl.formatMessage(messages.fetchErrorStatus),
              'error',
            ),
          );
        })
        .finally(() => {
          setIsFetchingStatus(false);
        });
    }
  };

  if (!careerSiteTrainingStatus) return null;
  if (!statusDetails) return null;

  return (
    <CustomTooltip
      title={
        (careerSiteTrainingStatus === 'PENDING' ||
          careerSiteTrainingStatus === 'RUNNING') &&
        intl.formatMessage(messages.tooltip)
      }
      dense
      placement="top"
    >
      <Button
        type="secondary"
        className={styles.button}
        onClick={handleFetchStatusClick}
        disabled={
          careerSiteTrainingStatus === 'SUCCESS' ||
          careerSiteTrainingStatus === 'FAILURE'
        }
      >
        <Typography variant="body2" className={styles.buttonText}>
          <FormattedMessage {...statusDetails.message} />
        </Typography>
        {isFetchingStatus ? (
          <LoadingIcon className={classnames(styles.icon, styles.loader)} />
        ) : (
          statusDetails.icon
        )}
      </Button>
    </CustomTooltip>
  );
};
