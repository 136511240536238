import { Button } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import React, { SetStateAction, VFC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { BackToCompanySettings } from 'components/BackToCompanySettings';
import { makeSelectFeatureChatbotAdminEnabled } from 'containers/Auth_old/selectors';

import { messages } from './messages';
import { useStyles } from './styles';

export interface HeaderProps {
  setIsBulkImportPopupOpen: React.Dispatch<SetStateAction<boolean>>;
  setIsToggleModePopupOpen: React.Dispatch<SetStateAction<boolean>>;
}

export const Header: VFC<HeaderProps> = ({
  setIsBulkImportPopupOpen,
  setIsToggleModePopupOpen,
}) => {
  const styles = useStyles();

  const isFeatureChatbotAdminEnabled = useSelector(
    makeSelectFeatureChatbotAdminEnabled(),
  );

  return (
    <Grid
      item
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={styles.title}
    >
      <Grid item>
        <BackToCompanySettings />
        <Typography display="inline" variant="h2">
          <FormattedMessage {...messages.automatedResponses} />
        </Typography>
      </Grid>
      {isFeatureChatbotAdminEnabled && (
        <Grid item>
          <Button
            size="small"
            type="secondary"
            onClick={() => {
              setIsBulkImportPopupOpen(true);
            }}
            className={styles.bulkUploadButton}
          >
            <FormattedMessage {...messages.bulkUploadCsvFile} />
          </Button>
          <Button
            size="small"
            type="primary"
            onClick={() => {
              setIsToggleModePopupOpen(true);
            }}
            className={styles.bulkUploadButton}
          >
            <FormattedMessage {...messages.changeMode} />
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
