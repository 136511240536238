import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  modalText: {
    marginBottom: theme.spacing(2),
  },
  input: {
    borderColor: theme.palette.common.gray300,
  },
}));
