import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  moveApplicant: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.W4Stage.W4StateForm.moveApplicant`,
    defaultMessage:
      'Automatically move applicant to next stage after the W4 State form is completed (recommended)',
  },
  selectState: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.W4Stage.W4StateForm.selectState`,
    defaultMessage: 'Select State',
  },
  settings: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.W4Stage.W4StateForm.settings`,
    defaultMessage: 'Settings',
  },
  w4StateForm: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.W4Stage.W4Form.w4StateForm`,
    defaultMessage: 'W4 State Form',
  },
});
