/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OpeningStatusV2 } from '../models/OpeningStatusV2';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OpeningStatusV2Service {

    /**
     * get status
     * Returns the status for a specified funnel
     * @param externalId Opening#external_id
     * @returns any success
     * @throws ApiError
     */
    public static getApiSelfServeV2OpeningsStatusV2(
        externalId: string,
    ): CancelablePromise<{
        status: OpeningStatusV2;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api_self_serve/v2/openings/{external_id}/status_v2',
            path: {
                'external_id': externalId,
            },
        });
    }

    /**
     * update status
     * Updates the status for a specified funnel
     * @param externalId Opening#external_id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static postApiSelfServeV2OpeningsStatusV2(
        externalId: string,
        requestBody: {
            status: OpeningStatusV2;
        },
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api_self_serve/v2/openings/{external_id}/status_v2',
            path: {
                'external_id': externalId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `unauthorized`,
            },
        });
    }

    /**
     * get status options
     * Returns the possible statuses for a specified funnel
     * @param externalId Opening#external_id
     * @returns any success
     * @throws ApiError
     */
    public static getApiSelfServeV2OpeningsStatusOptions(
        externalId: string,
    ): CancelablePromise<Array<{
        status: OpeningStatusV2;
        /**
         * Whether or not the specified funnel has that status
         */
        selected: boolean;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api_self_serve/v2/openings/{external_id}/status_options',
            path: {
                'external_id': externalId,
            },
        });
    }

}