/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AvailableTimes } from '../models/AvailableTimes';
import type { BookingResult } from '../models/BookingResult';
import type { CalendarContext } from '../models/CalendarContext';
import type { Period } from '../models/Period';
import type { WeeklyPeriods } from '../models/WeeklyPeriods';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SchedulerService {

    /**
     * fetch availability rules
     * @returns WeeklyPeriods successful
     * @throws ApiError
     */
    public static getInternalApiSchedulerAvailabilityRules(): CancelablePromise<WeeklyPeriods> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/scheduler/availability_rules',
        });
    }

    /**
     * create availability rules
     * @param requestBody
     * @returns WeeklyPeriods successful
     * @throws ApiError
     */
    public static postInternalApiSchedulerAvailabilityRules(
        requestBody: {
            weekly_periods: Array<Period>;
        },
    ): CancelablePromise<WeeklyPeriods> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/scheduler/availability_rules',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `unprocessable entity`,
            },
        });
    }

    /**
     * remove calendar
     * @param id Calendar ID
     * @returns void
     * @throws ApiError
     */
    public static deleteInternalApiSchedulerCalendars(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/internal_api/scheduler/calendars/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }

    /**
     * fetch availability for stage
     * @param stageId
     * @returns AvailableTimes successful
     * @throws ApiError
     */
    public static getInternalApiSchedulerCalendarsAvailability(
        stageId: string,
    ): CancelablePromise<AvailableTimes> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/scheduler/calendars/availability',
            query: {
                'stage_id': stageId,
            },
        });
    }

    /**
     * directly book
     * @param requestBody
     * @returns BookingResult successful
     * @throws ApiError
     */
    public static postInternalApiSchedulerCalendarsBook(
        requestBody?: {
            applicant_id: string;
            user_id: string;
            stage_id: string;
            start_time: string;
            end_time: string;
        },
    ): CancelablePromise<BookingResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/scheduler/calendars/book',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `records not found`,
                422: `unbookable`,
            },
        });
    }

    /**
     * Requests Cronofy Calendar Context
     * Returns initial context for calendar
     * @returns CalendarContext successful
     * @throws ApiError
     */
    public static getInternalApiSchedulerCronofyInformation(): CancelablePromise<CalendarContext> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/scheduler/cronofy_information',
        });
    }

}