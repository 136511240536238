import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  labelsWrapper: {
    width: '75%',
  },
  label: {
    width: 'fit-content',
    backgroundColor: theme.palette.common.gray200,
    color: theme.palette.common.gray800,
    borderRadius: '5px',
    padding: theme.spacing(0.5, 1.25),
    fontSize: '12px',
  },
  classifiedLabel: {
    backgroundColor: '#E5E7FF',
  },
  lowConfidenceLabel: {
    backgroundColor: theme.palette.common.orange100,
  },
  highConfidenceLabel: {
    backgroundColor: theme.palette.common.green100,
  },
}));
