/*
 *
 * Account constants
 *
 */

export const UPDATE_ACCOUNT_INIT = 'app/Account/UPDATE_ACCOUNT_INIT';
export const UPDATE_ACCOUNT_SUCCESS = 'app/Account/UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_FAILED = 'app/Account/UPDATE_ACCOUNT_FAILED';
export const RESET_ACCOUNT_STATUS = 'app/Account/RESET_ACCOUNT_STATUS';
export const TOGGLE_MOBILE_DRAWER = 'app/Account/TOGGLE_MOBILE_DRAWER';
export const CLOSE_MOBILE_DRAWER = 'app/Account/CLOSE_MOBILE_DRAWER';
