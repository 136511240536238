import { Dispatch } from 'redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import {
  deleteSavedFilterErrorAction,
  deleteSavedFilterSuccessAction,
  fetchSavedFiltersErrorAction,
  fetchSavedFiltersSuccessAction,
  saveFilterSuccessAction,
  setSelectedFilterAction,
  updateSavedFilterErrorAction,
  updateSavedFilterSuccessAction,
} from 'containers/ApplicantsFilter/actions';
import { addMessageAction } from 'containers/FlashMessage/actions';
import { apiDelete, apiGet, apiPatch, apiPost } from 'utils/axios';

interface LabelNameValue {
  label: string;
  name: string;
  value: string;
}
interface Id {
  id: string;
}
interface Criteria {
  combinator: LabelNameValue;
  rules: Id[];
}
interface FilterSet {
  visible: boolean;
  title: string;
  id: string;
  criteria: Criteria;
}
interface FilterContainer {
  // eslint-disable-next-line camelcase
  applicant_filter_set: FilterSet;
}
interface FiltersContainer {
  // eslint-disable-next-line camelcase
  applicant_filter_sets: FilterSet[];
}
interface FilterApi {
  data: FilterContainer;
}
interface FiltersApi {
  data: FiltersContainer;
}
interface FilterPostApi {
  data: Id;
}

const filterUrl = (filterIdP: string) =>
  `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicant_filter_sets/${filterIdP}`;

const applicantFilterSetsUrl = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicant_filter_sets`;

async function fetchFilters(dispatch: Dispatch, errorMessage: string) {
  try {
    const response: FiltersApi = await apiGet(applicantFilterSetsUrl);
    dispatch(
      fetchSavedFiltersSuccessAction(response.data.applicant_filter_sets),
    );
  } catch (e) {
    dispatch(fetchSavedFiltersErrorAction());
    dispatch(addMessageAction(errorMessage));
  }
}

async function fetchFilterData(
  dispatch: Dispatch,
  errorMessage: string,
  filterId?: string,
) {
  if (filterId) {
    try {
      const url = filterUrl(filterId);
      const response: FilterApi = await apiGet(url);
      dispatch(setSelectedFilterAction(response.data.applicant_filter_set));
    } catch {
      dispatch(addMessageAction(errorMessage, 'error'));
    }
  }
}

async function callSaveFilter(
  dispatch: Dispatch,
  title: string,
  visible: boolean,
  criteria: Criteria,
  messageSuccess: string,
  messageError: string,
  setFilterId: (arg0: string) => void,
) {
  try {
    const response: FilterPostApi = await apiPost(applicantFilterSetsUrl, {
      applicant_filter_set: {
        title,
        visible,
        criteria,
      },
    });
    const savedFilter = {
      id: response.data.id,
      title,
      visible,
      criteria,
    };
    dispatch(saveFilterSuccessAction(savedFilter));
    dispatch(addMessageAction(messageSuccess, 'success'));
    setFilterId(response.data.id);
  } catch (e) {
    dispatch(addMessageAction(messageError, 'error'));
  }
}

async function callDeleteApplicantsFilter(
  dispatch: Dispatch,
  deleteFilterId: string,
  successMessage: string,
  errorMessage: string,
) {
  try {
    await apiDelete(filterUrl(deleteFilterId));
    dispatch(deleteSavedFilterSuccessAction(deleteFilterId));
    dispatch(addMessageAction(successMessage));
  } catch (e) {
    dispatch(deleteSavedFilterErrorAction());
    dispatch(addMessageAction(errorMessage));
  }
}

async function callUpdateApplicantsFilter(
  dispatch: Dispatch,
  id: string,
  title: string,
  visible: boolean,
  criteria: Criteria,
  successMessage: string,
  errorMessage: string,
) {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicant_filter_sets/${id}`;
  const filterParams = {
    applicant_filter_set: {
      title,
      visible,
      criteria,
    },
  };
  const updatedFilter = {
    id,
    title,
    visible,
    criteria,
  };

  try {
    const response: FilterPostApi = await apiPatch(url, filterParams);
    dispatch(updateSavedFilterSuccessAction(updatedFilter, response.data));

    dispatch(addMessageAction(successMessage, 'success'));
  } catch (e) {
    dispatch(updateSavedFilterErrorAction());
    dispatch(addMessageAction(errorMessage, 'error'));
  }
}

export {
  callDeleteApplicantsFilter,
  callSaveFilter,
  callUpdateApplicantsFilter,
  fetchFilterData,
  fetchFilters,
};
