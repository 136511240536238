/* eslint-disable camelcase */
import {
  CustomTooltip,
  Loader,
  SimpleModal,
  SimpleModalHeader,
  SimpleModalTitle,
} from '@fountain/fountain-ui-components';
import { Box, makeStyles } from '@material-ui/core';
import invariant from 'invariant';
import React, { FC, memo, VFC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addMessageAction } from 'containers/FlashMessage/actions';
import { AssessmentFormData, Status } from 'containers/StatusLabels/utils';
import { StageIconByType } from 'containers/WorkflowEditor/components/StageColumn/StageCard/StageIconByType';
import useApplicantDetail, {
  ApplicantDetailData,
} from 'hooks/useApplicantDetail';
import InfoIcon from 'images/InfoIcon';

import AssessmentFormModalContent from './AssessmentFormModalContent';
import { messages } from './messages';

interface ScheduleApplicantModalShellProps {
  headerTitle: string;
  handleClose: () => void;
}

const AssessmentModalShell: FC<ScheduleApplicantModalShellProps> = ({
  headerTitle,
  handleClose,
  children,
}) => {
  const intl = useIntl();

  return (
    <SimpleModal onClose={handleClose} maxWidth={600} blurOverlay={false}>
      <SimpleModalHeader onClose={handleClose}>
        <Box mr={1}>
          <StageIconByType type="AssessmentStage" />
        </Box>
        <SimpleModalTitle>{headerTitle}</SimpleModalTitle>
        <CustomTooltip
          disableFocusListener
          enterTouchDelay={100}
          title={intl.formatMessage(messages.tooltip)}
          dense
        >
          <InfoIcon />
        </CustomTooltip>
      </SimpleModalHeader>
      {children}
    </SimpleModal>
  );
};

const useStyles = makeStyles(theme => ({
  loader: {
    margin: theme.spacing(8, 0),
  },
}));

const getFormData = (
  applicant: ApplicantDetailData,
  assessmentFormId: string,
): AssessmentFormData => {
  let assessmentData: AssessmentFormData | undefined;

  applicant.status.find((status: Status) => {
    assessmentData = status.actions.find(action => {
      return action.assessment_data?.id === assessmentFormId;
    })?.assessment_data;

    return assessmentData !== undefined;
  });

  invariant(assessmentData, 'assessmentData can not be undefined');

  return assessmentData;
};

export interface AssessmentFormModalProps {
  applicantId: string;
  assessmentFormId: string;
  handleClose: () => void;
}

export const AssessmentFormModal: VFC<AssessmentFormModalProps> = ({
  applicantId,
  assessmentFormId,
  handleClose,
}) => {
  const styles = useStyles();
  const { data: applicant, isFetching: isApplicantDetailLoading } =
    useApplicantDetail(applicantId);
  const dispatch = useDispatch();
  const intl = useIntl();

  if (isApplicantDetailLoading) {
    return (
      <AssessmentModalShell headerTitle="" handleClose={handleClose}>
        <div className={styles.loader}>
          <Loader block size="2rem" />
        </div>
      </AssessmentModalShell>
    );
  }
  if (!applicant) {
    dispatch(
      addMessageAction(
        intl.formatMessage(messages.applicantErrorToast),
        'error',
      ),
    );
    return null;
  }

  const assessmentData = getFormData(applicant, assessmentFormId);
  const headerTitle = `${assessmentData.title}: ${assessmentData.applicant_name}`;

  return (
    <AssessmentModalShell headerTitle={headerTitle} handleClose={handleClose}>
      <AssessmentFormModalContent
        assessmentData={assessmentData}
        handleClose={handleClose}
      />
    </AssessmentModalShell>
  );
};

export default memo(AssessmentFormModal);
