import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  browseAFile: {
    marginLeft: theme.spacing(1),
    color: theme.palette.common.gray800,
    fontFamily: theme.typography.body2.fontFamily,
    lineHeight: theme.typography.body2.lineHeight,
    fontSize: theme.typography.body2.fontSize,
  },
  uploadIcon: {
    width: '12.8px',
    height: theme.spacing(1.5),
    color: theme.palette.common.gray600,
  },
}));

export default styles;
