import axios from 'axios';
import { REACT_APP_SIGN_OUT_REDIRECT_URL } from 'runtimeEnvVars';

import { TRACKING_VISITOR_KEY } from 'utils/constants';
import { getSessionItem } from 'utils/storageUtils';

import { DEFAULT_ERROR_MESSAGE } from '../containers/FlashMessage/constants';

const SIGN_OUT_URL = REACT_APP_SIGN_OUT_REDIRECT_URL;
let cancelToken;

axios.interceptors.response.use(null, error => {
  if (error && error.response) {
    if (error.response.status === 401) {
      // initial call to whoami will get intercepted here and redirect, need to throw log event
      const visitorTrack = getSessionItem(TRACKING_VISITOR_KEY);
      window.location.replace(`${SIGN_OUT_URL}?app=${visitorTrack}`);
    }
    const { data } = error.response;
    if (typeof data === 'object') {
      const errorPayload =
        data.error ||
        data.errors ||
        data.error_message ||
        data.error_type ||
        data.message ||
        DEFAULT_ERROR_MESSAGE;
      return Promise.reject(errorPayload);
    }
    return Promise.reject(DEFAULT_ERROR_MESSAGE);
  }
  return Promise.reject(error);
});

const config = {
  withCredentials: true,
};

export function apiGet(path, params) {
  return axios.get(path, { ...config, params });
}
export function apiPost(path, data) {
  return axios.post(path, data, config);
}

export function apiPatch(path, data) {
  return axios.patch(path, data, config);
}

export function apiPut(path, data) {
  return axios.put(path, data, config);
}

export function apiDelete(path, data) {
  return axios.delete(path, { ...config, ...data });
}

export function apiGetWithCancelRequest(path, params) {
  if (cancelToken) {
    cancelToken.cancel('request canceled');
  }
  cancelToken = axios.CancelToken.source();
  return axios.get(path, {
    ...config,
    params,
    ...{ cancelToken: cancelToken.token },
  });
}
