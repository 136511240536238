import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  ariaLoadingSpinner: {
    id: `app.components.WorkflowEditorContent.StageDetail.PartnerStage.PartnerIntegrationConnectedCard.ariaLoadingSpinner`,
    defaultMessage: 'Loading spinner',
  },
  ariaRefreshPartnerOptions: {
    id: `app.components.WorkflowEditorContent.StageDetail.PartnerStage.PartnerIntegrationConnectedCard.ariaRefreshPartnerOptions`,
    defaultMessage: 'Refresh partner options',
  },
  partnerDataCollectionPackageWarning: {
    id: `app.components.WorkflowEditorContent.StageDetail.PartnerStage.PartnerIntegrationConnectedCard.partnerDataCollectionPackageWarning`,
    defaultMessage:
      "Stage option type is required, please select one.  Make sure 'settings_webhook' is set up correctly if no options are available.",
  },
  refreshing: {
    id: `app.components.WorkflowEditorContent.StageDetail.PartnerStage.PartnerIntegrationConnectedCard.refreshing`,
    defaultMessage: 'Refreshing...',
  },
  refreshOptions: {
    id: `app.components.WorkflowEditorContent.StageDetail.PartnerStage.PartnerIntegrationConnectedCard.refreshOptions`,
    defaultMessage: 'Refresh options from {partnerName}',
  },
  refreshFailed: {
    id: `app.components.WorkflowEditorContent.StageDetail.PartnerStage.PartnerIntegrationConnectedCard.refreshFailed`,
    defaultMessage:
      'Refresh failed, if the issue persists check the integration settings',
  },
  selectStageOptionTypeError: {
    id: `app.components.WorkflowEditorContent.StageDetail.PartnerStage.PartnerIntegrationConnectedCard.selectStageOptionTypeError`,
    defaultMessage: 'Select stage option type',
  },
  stageOptionType: {
    id: `app.components.WorkflowEditorContent.StageDetail.PartnerStage.PartnerIntegrationConnectedCard.stageOptionType`,
    defaultMessage: 'Stage Option Type',
  },
  success: {
    id: `app.components.WorkflowEditorContent.StageDetail.PartnerStage.PartnerIntegrationConnectedCard.success`,
    defaultMessage: '{partnerName} options reloaded',
  },
});
