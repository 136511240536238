export const DELIVERY_STATUS = {
  DELIVERED: 'delivered',
  FAILED: 'failed',
  READ: 'read',
  SENDING: 'sending',
  SENT: 'sent',
  UNDELIVERED: 'undelivered',
  UNKNOWN: 'unknown',
};

export const PHONE_PLATFORM_TYPES = {
  whats_app: 'whats_app',
  sms: 'sms',
};

export const PHONE_PLATFORM_TITLES = {
  whats_app: 'WhatsApp',
  sms: 'SMS',
  chatbot_widget: 'Chat Widget',
};
