import { Input, StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import {
  SessionDetail,
  SessionDetailAttributes,
  WorkflowSchedulerV2Stage,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import produce from 'immer';
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  VFC,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Error } from 'components/Error';
import { StageSettingCard } from 'containers/WorkflowEditor/components/StageSettingCard';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';
import { useDebounce } from 'hooks/useDebounce';

import { LocationInput } from './LocationInput';
import { messages } from './messages';
import { useStyles } from './styles';

type DropdownValue = number | string | undefined;

export interface SessionDetailsCardProps {
  onInputChange: (
    key: keyof Omit<
      SessionDetailAttributes,
      'calendar_scheduler_stages_session_details_attributes'
    >,
    value?: string | number | null,
  ) => void;
  address: string;
  sessionDetail?: SessionDetail;
}

export const SessionDetailsCard: VFC<SessionDetailsCardProps> = ({
  onInputChange,
  sessionDetail,
  address,
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const [title, setTitle] = useState(sessionDetail?.title);
  const [description, setDescription] = useState(sessionDetail?.description);
  const [locationAddress, setLocationAddress] = useState(
    sessionDetail?.location ?? address ?? '',
  );
  const [maxAttendees, setMaxAttendees] = useState<number | null | undefined>(
    sessionDetail?.max_attendees,
  );
  const dropdownValues: Array<DropdownValue> = [15, 30, 45, 60, 90];
  const isCustom = (val: DropdownValue) => {
    if (val === undefined) {
      return true;
    }
    if (dropdownValues.includes(val)) {
      return false;
    }
    return true;
  };
  const [custom, setCustom] = useState(
    sessionDetail?.duration_minutes
      ? isCustom(sessionDetail?.duration_minutes)
      : false,
  );
  const [customValue, setCustomValue] = useState(
    custom ? sessionDetail?.duration_minutes : null,
  );

  const options = dropdownValues
    .concat('Custom')
    .map((value: DropdownValue, i) => {
      const label =
        value === 'Custom'
          ? 'Custom'
          : `${value as number | string} ${intl.formatMessage(
              messages.minutes,
            )}`;
      return { id: i, value, label };
    });

  const { updateStageResult } = useContext(StageContext);

  const dropdownValue = (val: DropdownValue) => {
    if (isCustom(val) || custom) {
      return options.find(option => option.value === 'Custom');
    }

    return options.find(option => option.value === val);
  };
  useEffect(() => {
    setTitle(sessionDetail?.title ?? '');
  }, [sessionDetail?.title]);

  useEffect(() => {
    setDescription(sessionDetail?.description ?? '');
  }, [sessionDetail?.description]);

  const isSessionDetailsCustom = useCallback(() => {
    const dropdownValues = [15, 30, 45, 60, 90];
    const isCustomMinutes = (val?: number) => {
      if (val === undefined) {
        return true;
      }
      if (dropdownValues.includes(val)) {
        return false;
      }
      return true;
    };
    return isCustomMinutes(sessionDetail?.duration_minutes);
  }, [sessionDetail?.duration_minutes]);

  useEffect(() => {
    setCustom(
      sessionDetail?.duration_minutes !== undefined
        ? isSessionDetailsCustom()
        : false,
    );
  }, [
    sessionDetail?.duration_minutes,
    isSessionDetailsCustom,
    sessionDetail?.id,
  ]);

  useEffect(() => {
    setCustomValue(
      isSessionDetailsCustom() ? sessionDetail?.duration_minutes : null,
    );
  }, [
    sessionDetail?.duration_minutes,
    isSessionDetailsCustom,
    sessionDetail?.id,
  ]);

  useEffect(() => {
    setMaxAttendees(sessionDetail?.max_attendees);
  }, [sessionDetail?.max_attendees]);

  useEffect(() => {
    setLocationAddress(sessionDetail?.location ?? address ?? '');
  }, [address, sessionDetail?.location]);

  const errorMessage = (section: string) => {
    if (!updateStageResult.isError) {
      return undefined;
    }

    const errors = updateStageResult.error?.errors;
    if (errors === undefined) {
      return errors;
    }

    return errors[section]
      ?.map(error => {
        return error.charAt(0).toUpperCase() + error.slice(1);
      })
      ?.join(', ');
  };

  return (
    <StageSettingCard
      variant="default"
      title={intl.formatMessage(messages.eventDetails)}
    >
      <Grid container>
        <Grid item lg={6} md={12}>
          <div className={styles.wrapper}>
            <Typography variant="body2">
              <FormattedMessage {...messages.details} />
            </Typography>
            <div>
              <Input
                value={title}
                onChange={(e: { target: { value: string } }) => {
                  setTitle(e.target.value);
                  onInputChange('title', e.target.value);
                }}
                label={intl.formatMessage(messages.sessionName)}
                error={Boolean(errorMessage('session_detail.title'))}
              />
              <Error error={errorMessage('session_detail.title')} />
            </div>
            <StyledReactSelect
              options={options}
              value={dropdownValue(sessionDetail?.duration_minutes)}
              onChange={(e: { value: string }) => {
                const numberValue = Number(e.value);
                setCustom(isCustom(numberValue));
                const duration = isCustom(numberValue)
                  ? customValue
                  : numberValue;
                if (typeof duration !== 'number') return;
                onInputChange('duration_minutes', duration);
              }}
              label={intl.formatMessage(messages.duration)}
              isSearchable={false}
            />
            {custom && (
              <Grid container spacing={2}>
                <Grid item lg={4} md={6}>
                  <Input
                    value={customValue}
                    required
                    onChange={(e: { target: { value: string } }) => {
                      const parsedValue = parseInt(e.target.value, 10);
                      if (Number.isNaN(parsedValue)) {
                        onInputChange('duration_minutes', null);
                        return setCustomValue(null);
                      }
                      setCustomValue(parsedValue);
                      return onInputChange('duration_minutes', parsedValue);
                    }}
                    className={styles.lengthInput}
                    error={Boolean(
                      errorMessage('session_detail.duration_minutes'),
                    )}
                  />
                  <Error
                    error={errorMessage('session_detail.duration_minutes')}
                  />
                </Grid>
                <Grid item lg={4} md={6}>
                  <div className={styles.minuteLabel}>
                    {intl.formatMessage(messages.minutes)}{' '}
                    <span className={styles.required}>&nbsp;*</span>
                  </div>
                </Grid>
              </Grid>
            )}
            <div>
              <Input
                value={maxAttendees}
                onChange={(e: { target: { value: string } }) => {
                  const parsedValue = parseInt(e.target.value, 10);
                  if (Number.isNaN(parsedValue)) {
                    onInputChange('max_attendees', null);
                    return setMaxAttendees(null);
                  }
                  setMaxAttendees(parsedValue);
                  return onInputChange('max_attendees', parsedValue);
                }}
                required
                label={intl.formatMessage(messages.numberOfApplicants)}
                error={Boolean(errorMessage('session_detail.max_attendees'))}
              />
              {errorMessage('session_detail.max_attendees') ? (
                <Error error={errorMessage('session_detail.max_attendees')} />
              ) : (
                <Typography variant="body2">
                  <FormattedMessage {...messages.attendeeDetails} />
                </Typography>
              )}
            </div>
            <div>
              <Input
                value={description}
                onChange={(e: { target: { value: string } }) => {
                  setDescription(e.target.value);
                  onInputChange('description', e.target.value);
                }}
                label={intl.formatMessage(messages.eventInstructions)}
                error={Boolean(errorMessage('session_detail.description'))}
              />
              <Error error={errorMessage('session_detail.description')} />
            </div>
          </div>
          <div className={styles.wrapper}>
            <Typography className={styles.subheading} variant="h3">
              <FormattedMessage {...messages.location} />
            </Typography>
            <Typography className={styles.labelCompressed} variant="body2">
              <FormattedMessage {...messages.locationSubtitle} />
            </Typography>
            <LocationInput
              value={locationAddress}
              onChange={(e: { target: { value: string } }) => {
                setLocationAddress(e.target.value);
                onInputChange('location', e.target.value);
              }}
              label={intl.formatMessage(messages.location)}
            />
            <Error error={errorMessage('session_detail.location')} />
          </div>
        </Grid>
      </Grid>
    </StageSettingCard>
  );
};

interface SessionDetailsCardContainerProps {
  stage: WorkflowSchedulerV2Stage;
  setStage: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
  address: string;
}

export const SessionDetailsCardContainer: VFC<
  SessionDetailsCardContainerProps
> = ({ stage, setStage, address }) => {
  const onInputChange = (
    key: keyof Omit<
      SessionDetailAttributes,
      'calendar_scheduler_stages_session_details_attributes'
    >,
    value?: string | number,
  ) => {
    setStage(
      produce(stage, draftStage => {
        if (!draftStage?.additional_info?.session_detail) {
          return;
        }

        if (key === 'max_attendees' || key === 'duration_minutes') {
          draftStage.additional_info.session_detail[key] = value as
            | number
            | undefined;
        } else if (['description', 'location', 'title'].includes(key)) {
          draftStage.additional_info.session_detail[key] = value as string;
        }
      }),
    );
  };

  const debouncedOnInputChange = useDebounce(onInputChange, 48);

  return (
    <SessionDetailsCard
      onInputChange={debouncedOnInputChange}
      sessionDetail={stage.additional_info?.session_detail}
      address={address}
    />
  );
};
