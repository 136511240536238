import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  askDelete: {
    id: 'app.components.WorkflowEditor.components.WorkflowEditorContent.StageDetail.SchedulerStage.OnsiteAddressCard.AddressRow.askDelete',
    defaultMessage:
      'Please save any changes to this stage prior to deleting an onsite address. Do you want to continue deleting the onsite address?',
  },
  delete: {
    id: 'app.components.WorkflowEditor.components.WorkflowEditorContent.StageDetail.SchedulerStage.OnsiteAddressCard.AddressRow.delete',
    defaultMessage: 'Delete',
  },
  deleteOnsiteAddress: {
    id: 'app.components.WorkflowEditor.components.WorkflowEditorContent.StageDetail.SchedulerStage.OnsiteAddressCard.AddressRow.deleteOnsiteAddress',
    defaultMessage: 'Delete Onsite Address',
  },
  edit: {
    id: 'app.components.WorkflowEditor.components.WorkflowEditorContent.StageDetail.SchedulerStage.OnsiteAddressCard.AddressRow.edit',
    defaultMessage: 'Edit',
  },
  cancel: {
    id: 'app.components.WorkflowEditor.components.WorkflowEditorContent.StageDetail.SchedulerStage.OnsiteAddressCard.AddressRow.cancel',
    defaultMessage: 'Cancel',
  },
});
export default messages;
