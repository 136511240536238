/* eslint-disable no-underscore-dangle */
import { Button, IconButton } from '@fountain/fountain-ui-components';
import { Button as MuiButton, Grid, Typography } from '@material-ui/core';
import {
  WorkflowCustomStage,
  WorkflowRuleStage,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import { produce } from 'immer';
import React, { useContext, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';

import { StageSettingCardEmptyState } from 'containers/WorkflowEditor/components/StageSettingCardEmptyState';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';
import { RulesEditDataContextProvider } from 'containers/WorkflowEditor/RulesEditDataContextProvider/RulesEditDataContextProvider';
import { PlusIcon } from 'images/PlusIcon';

import { ElseAction } from './Action/ElseAction';
import { DEFAULT_ELSE_ACTION, DEFAULT_RULE } from './constants';
import { messages } from './messages';
import { RuleCard } from './RuleCard';
import { useStyles } from './styles';
import { RulesProps } from './types';

export interface RulesComponentProps {
  stage: WorkflowCustomStage | WorkflowRuleStage;
}

export const Rules: VFC<RulesComponentProps> = ({ stage }) => {
  const styles = useStyles();
  const intl = useIntl();

  const { rules, setRules, setStage, updateStageResult } =
    useContext(StageContext);

  const availableRules = { ...rules };

  // Filter out any rules that have _destroy set to true
  Object.keys(availableRules).forEach(ruleId => {
    if (availableRules[ruleId]._destroy) {
      delete availableRules[ruleId];
    }
  });

  const availableElseActionSet =
    stage.additional_info.else_action_set_attributes;

  const errors =
    (updateStageResult.isError && updateStageResult?.error?.errors) ||
    undefined;

  const currentElseAction =
    !availableElseActionSet?._destroy &&
    availableElseActionSet?.actions_attributes?.[0];

  const onAddRule = () => {
    setRules(
      produce((draftRules: RulesProps) => {
        draftRules[uuid()] = DEFAULT_RULE;
      }),
    );

    if (availableElseActionSet?._destroy || !availableElseActionSet) {
      setStage(
        produce(stage, draftStage => {
          const elseActionSet =
            draftStage.additional_info.else_action_set_attributes;

          const elseActions = elseActionSet?.actions_attributes;

          if (!elseActionSet) {
            // Sets else_action_set_attributes if it doesn't exist yet so that the else action shows up for rules in the UI
            draftStage.additional_info.else_action_set_attributes = {
              actions_attributes: [{ ...DEFAULT_ELSE_ACTION }],
            };
          } else if (elseActionSet && '_destroy' in elseActionSet) {
            delete elseActionSet._destroy;
          } else if (elseActions?.[0]) {
            elseActions[0] = DEFAULT_ELSE_ACTION;
          }
        }),
      );
    }
  };

  const onDeleteRule = (ruleId: string | number) => {
    setRules(
      produce((draftRules: RulesProps) => {
        draftRules[ruleId]._destroy = true;
      }),
    );

    // If availableRules.length is equal to 1, that means it's the last rule before being deleted.
    // We can assume it's deleted after the setRules above has been run so now we want to set
    // _destroy to true on else_action_set_attributes so it will be destroy upon update + not show up in the ui
    if (Object.keys(availableRules).length === 1) {
      setStage(
        produce((draftStage: WorkflowStageDetail) => {
          if (
            draftStage.type !== 'RuleStage' &&
            draftStage.type !== 'CustomStage'
          ) {
            return;
          }
          const elseActionSet =
            draftStage.additional_info.else_action_set_attributes;

          if (elseActionSet) {
            elseActionSet._destroy = true;
          }
        }),
      );
    }
  };

  if (Object.keys(availableRules).length === 0 && stage.type !== 'RuleStage') {
    return (
      <StageSettingCardEmptyState
        stageSettingCardProps={{
          title: intl.formatMessage(messages.rule),
          variant: 'default',
        }}
        buttonComponent={
          <Button
            type="secondary"
            size="small"
            autoWidth
            onClick={onAddRule}
            disableRipple
            aria-label={intl.formatMessage(messages.addCustomRule)}
          >
            <Typography variant="h4" color="primary">
              <FormattedMessage {...messages.addCustomRule} />
            </Typography>
          </Button>
        }
      />
    );
  }

  return (
    <RulesEditDataContextProvider externalId={stage.external_id}>
      <Grid>
        {Object.keys(availableRules as Record<string, unknown>).map(
          (ruleId, index) => {
            /* eslint-disable @typescript-eslint/no-unsafe-assignment */
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const rule = availableRules[ruleId];

            return (
              <RuleCard
                key={ruleId}
                ruleId={ruleId}
                rule={rule}
                index={index}
                setRules={setRules}
                onDeleteRule={onDeleteRule}
                stageType={stage.type}
                errors={errors}
              />
            );
          },
        )}
        <Grid container justify="center">
          <MuiButton
            onClick={onAddRule}
            aria-label={intl.formatMessage(messages.addRule)}
          >
            <IconButton
              size="small"
              primary
              className={styles.plusButton}
              component={Grid}
            >
              <PlusIcon color="inherit" fontSize="inherit" />
            </IconButton>
            <Typography variant="h4" color="primary" className={styles.addRule}>
              <FormattedMessage {...messages.addRule} />
            </Typography>
          </MuiButton>
        </Grid>
      </Grid>
      {currentElseAction && (
        <ElseAction
          setStage={setStage}
          action={currentElseAction}
          stageType={stage.type}
          errors={errors}
        />
      )}
    </RulesEditDataContextProvider>
  );
};
/* eslint-enable @typescript-eslint/no-unsafe-assignment */
