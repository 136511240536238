import { Button } from '@fountain/fountain-ui-components';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChatbotService } from 'api-clients/monolith';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addMessageAction } from 'containers/FlashMessage/actions';

import { UpgradeIcon } from './images/UpgradeIcon';
import { messages } from './messages';

const useStyles = makeStyles(theme => ({
  contactSalesHeaderContainer: {
    padding: theme.spacing(1.5),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.common.blue400,
  },
  headerText: {
    lineHeight: '24px',
    fontWeight: 500,
    color: theme.palette.common.white,
  },
  icon: {
    height: '24px',
    width: '24px',
    margin: theme.spacing(0, 1),
  },
  button: {
    border: '1px solid #FFFFFF',
    filter: 'drop-shadow(0px 1px 2px rgba(19, 30, 64, 0.08))',
    borderRadius: '4px',
    margin: theme.spacing(0, 2),
    width: 'fit-content',
  },
}));

export const ContactSalesHeader = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleContactSalesClick = async () => {
    try {
      await ChatbotService.postInternalApiChatbotContactSales();
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.contactSalesSuccess),
          'success',
        ),
      );
    } catch (error) {
      // TODO; Log error in Datadog
    }
  };

  return (
    <div className={styles.contactSalesHeaderContainer}>
      <UpgradeIcon className={styles.icon} />
      <Typography variant="body2" className={styles.headerText}>
        <FormattedMessage {...messages.contactSalesHeader} />
      </Typography>
      <Button
        type="primary"
        size="small"
        className={styles.button}
        onClick={handleContactSalesClick}
      >
        <FormattedMessage {...messages.getAccessNow} />
      </Button>
    </div>
  );
};
