import { createBrowserHistory } from 'history';

export const getBasename = (pathname: string): string | undefined => {
  const [, maybeShortSha] = pathname.split('/');
  return maybeShortSha.match(/^[0-9a-f]{7}$/) &&
    Number.isSafeInteger(parseInt(maybeShortSha, 16))
    ? `/${maybeShortSha}`
    : undefined;
};

export const history = createBrowserHistory({
  basename: getBasename(window.location.pathname),
});

export default history;
