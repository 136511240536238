import { WorkflowEditorService } from 'api-clients/monolith';
import { useApiService } from 'hooks';
import React from 'react';

export function useStage({
  funnelSlug,
  externalId,
}: {
  funnelSlug: string;
  externalId: string;
}) {
  const fetchStage = React.useCallback(
    () =>
      WorkflowEditorService.getInternalApiWorkflowEditorFunnelsStages1(
        funnelSlug,
        externalId,
      ),
    [funnelSlug, externalId],
  );

  return useApiService(fetchStage);
}
