import { Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC } from 'react';

import {
  Title,
  TitleProps,
} from 'containers/DataValidationModal/components/Title';

const useStyles = makeStyles<Theme>(theme => ({
  gridContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(0, 2),
  },
}));

export type FileFieldTitleProps = TitleProps;

export const FileFieldTitle: FC<FileFieldTitleProps> = ({
  children,
  ...props
}) => {
  const styles = useStyles();
  return (
    <Grid item className={styles.gridContent}>
      <Grid container direction="row" justify="space-between">
        <Title {...props} />
        {children}
      </Grid>
    </Grid>
  );
};
