/* eslint-disable camelcase */
import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import { getConditionErrorMessage } from 'containers/WorkflowEditor/components/Rules/Condition/constants';
import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';

import { DocumentSigningRuleProps } from '../../../DocumentSigningRules/types';
import {
  PartnerRuleProps,
  SharedPartnerConditionProps,
} from '../../../PartnerRules/types';
import { RulesProps, SharedConditionProps } from '../../../types';
import { messages } from './messages';

export const PartnerLabelSelect: FC<
  SharedConditionProps | SharedPartnerConditionProps
> = ({
  condition,
  ruleId,
  setRules,
  setPartnerRules,
  setDocumentSigningRules,
  errors,
}) => {
  const intl = useIntl();
  const { partner_label_keys: partnerLabelKeys } =
    useContext(RulesEditDataContext);
  const { extra } = condition;

  const onChangePartnerDetail = (partnerLabel: {
    label: string;
    value: string;
    partner_label_key?: string;
  }) => {
    // Because partnerLabel.partner_id is actually the external_id, we need a way to
    // grab the actual id which has been set at the start of partnerLabel.partner_label_key ¯\_(ツ)_/¯
    const labelKeySplit = partnerLabel.partner_label_key?.split(':');
    const partnerId = labelKeySplit?.[0];
    const partnerLabelKey = labelKeySplit?.[1] ?? partnerLabel.value;

    const rulesSetter = setRules ?? setDocumentSigningRules;

    if (rulesSetter) {
      rulesSetter(
        produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];

          const idx = draftConditions.findIndex(
            cond => cond.id === condition.id,
          );

          draftConditions[idx].extra.key = partnerLabelKey;
          draftConditions[idx].extra.partner_id = partnerId;
        }),
      );
    }

    if (setPartnerRules) {
      setPartnerRules(
        produce((draftRules: PartnerRuleProps) => {
          const draftCondition = draftRules[ruleId].condition_attributes;

          draftCondition.extra.key = partnerLabelKey;
          draftCondition.extra.partner_id = partnerId;
        }),
      );
    }
  };

  const selectedPartnerLabel =
    partnerLabelKeys?.find(option =>
      setPartnerRules
        ? option.value === extra.key
        : option.partner_label_key?.split(':')[1] === extra.key,
    ) ?? null;

  const keyErrorMessage =
    !extra?.key && getConditionErrorMessage('key', errors);

  const partnerIdErrorMessage =
    !extra?.partner_id && getConditionErrorMessage('partner_id', errors);

  return (
    <Grid item xs={6}>
      <StyledReactSelect
        options={partnerLabelKeys}
        value={selectedPartnerLabel}
        onChange={onChangePartnerDetail}
        label={intl.formatMessage(messages.partnerLabel)}
        aria-label={intl.formatMessage(messages.partnerLabel)}
        isSearchable
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        error={keyErrorMessage || partnerIdErrorMessage}
        placeholder=""
        required
      />
    </Grid>
  );
};
