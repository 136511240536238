/* eslint-disable camelcase */
import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import {
  generateHashStatusMessages,
  getConditionErrorMessage,
} from 'containers/WorkflowEditor/components/Rules/Condition/constants';
import { messages as conditionMessages } from 'containers/WorkflowEditor/components/Rules/Condition/messages';
import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';

import { DocumentSigningRuleProps } from '../../../DocumentSigningRules/types';
import { RulesProps, SharedConditionProps } from '../../../types';

type SterlingStageType = {
  id: string;
  title: string;
  stage_id: number;
};

export const SterlingStatus: FC<SharedConditionProps> = ({
  condition,
  ruleId,
  setRules,
  errors,
  setDocumentSigningRules,
}) => {
  const intl = useIntl();

  const {
    condition_statuses: conditionStatuses,
    sterling_stages: sterlingStages,
  } = useContext(RulesEditDataContext);
  const { extra } = condition;

  const onChangeSterlingStage = (sterlingStage: {
    id: string;
    stage_id: number;
    title: string;
  }) => {
    const rulesSetter = setRules ?? setDocumentSigningRules;

    if (rulesSetter) {
      rulesSetter(
        produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];

          const idx = draftConditions.findIndex(
            cond => cond.id === condition.id,
          );

          draftConditions[idx].ref_stage_1_id = sterlingStage.stage_id;
        }),
      );
    }
  };

  const onChangeStatus = (newStatus: { label: string; value: string }) => {
    const rulesSetter = setRules ?? setDocumentSigningRules;

    if (rulesSetter) {
      rulesSetter(
        produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];

          const idx = draftConditions.findIndex(
            cond => cond.id === condition.id,
          );

          draftConditions[idx].extra.status = newStatus.value;
        }),
      );
    }
  };

  const selectedSterlingStage =
    sterlingStages?.find(
      stage => stage.stage_id === condition.ref_stage_1_id,
    ) ?? null;

  const messageByStatus = generateHashStatusMessages(intl);

  const statusOptions = conditionStatuses?.sterling?.map(status => ({
    label: messageByStatus[status],
    value: status,
  }));

  const selectedStatusOption =
    statusOptions?.find(option => option.value === extra.status) ?? null;

  const sterlingStageIdErrorMessage =
    !condition.ref_stage_1_id &&
    getConditionErrorMessage('sterling_stage_id', errors);

  const statusErrorMessage =
    !extra.status && getConditionErrorMessage('status', errors);

  return (
    <>
      <Grid item xs={6}>
        <StyledReactSelect
          options={sterlingStages}
          value={selectedSterlingStage}
          onChange={onChangeSterlingStage}
          label={intl.formatMessage(conditionMessages.stage)}
          aria-label={intl.formatMessage(conditionMessages.stage)}
          getOptionLabel={(option: SterlingStageType) => option.title}
          getOptionValue={(option: SterlingStageType) => option.stage_id}
          isSearchable
          error={sterlingStageIdErrorMessage}
          placeholder=""
          required
        />
      </Grid>
      <Grid item xs={6}>
        <StyledReactSelect
          options={statusOptions}
          value={selectedStatusOption ?? null}
          onChange={onChangeStatus}
          label={intl.formatMessage(conditionMessages.status)}
          aria-label={intl.formatMessage(conditionMessages.status)}
          isSearchable
          error={statusErrorMessage}
          placeholder=""
          required
        />
      </Grid>
    </>
  );
};
