import { Grid } from '@material-ui/core';
import { BotType } from 'api-clients/monolith/models/BotType';
import React, { VFC } from 'react';

import { FAQ_BOT } from 'containers/Chatbot/constants';

import { ApplicantMessage } from './ApplicantMessage';
import { BotMessage } from './BotMessage';
import { useStyles } from './styles';

export interface MessageBubbleProps {
  applicantMessage: string;
  botMessage: string;
  botType: BotType;
}

export const MessageBubble: VFC<MessageBubbleProps> = ({
  applicantMessage,
  botMessage,
  botType,
}) => {
  const styles = useStyles();

  return (
    <Grid
      item
      container
      direction="column"
      className={styles.messageContainer}
      spacing={2}
    >
      {botType === FAQ_BOT ? (
        <ApplicantMessage message={applicantMessage} />
      ) : (
        <BotMessage message={botMessage} />
      )}
      {botType === FAQ_BOT ? (
        <BotMessage message={botMessage} />
      ) : (
        <ApplicantMessage message={applicantMessage} />
      )}
    </Grid>
  );
};
