import 'react-quill/dist/quill.snow.css';
import 'codemirror/lib/codemirror.css';

import {
  CustomTooltip,
  IconButton as StyledIconButton,
} from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core/';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useRef, useState } from 'react';
import { classNames } from 'react-extras';
import { injectIntl } from 'react-intl';

import MergeKeysSelect from 'components/MergeKeysSelect';
import TemplateMenu, { TemplateMenuVariant } from 'containers/TemplateMenu';
import useOnClickOutside from 'hooks/useOnClickOutside';
import HtmlSvg from 'images/html';
import MergeKeysSvg from 'images/merge-keys';

import messages from '../messages';
import useStyles from '../styles';

const EditorActionsToolbarWidget = ({
  children,
  icon,
  isSelected,
  onClick,
  shouldShowOptions,
  tooltipMessage,
  buttonDisabled,
  buttonTestId,
}) => (
  <>
    <CustomTooltip title={tooltipMessage} dense>
      <div>
        <StyledIconButton
          isSelected={isSelected}
          onClick={onClick}
          defaultIconColor
          data-testid={`${buttonTestId}-button`}
          disabled={buttonDisabled}
        >
          {icon}
        </StyledIconButton>
      </div>
    </CustomTooltip>
    {shouldShowOptions && <>{children}</>}
  </>
);

EditorActionsToolbarWidget.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  shouldShowOptions: PropTypes.bool,
  tooltipMessage: PropTypes.string.isRequired,
  buttonDisabled: PropTypes.bool,
  buttonTestId: PropTypes.string.isRequired,
};

EditorActionsToolbarWidget.defaultProps = {
  shouldShowOptions: false,
  buttonDisabled: false,
};

function EditorActionsToolbar({
  intl,
  enableMessageTemplates,
  enableMergeKeys,
  handleSelectTemplate,
  handleMergeKeySelect,
  htmlModeEnabled,
  mergeKeys,
  setHtmlModeEnabled,
  value,
  isSms,
  isWhatsapp,
  templateType,
  hasRequiredTab,
  enableHtmlModeSwitch,
}) {
  const classes = useStyles();
  const messageTemplateVariant = isWhatsapp
    ? TemplateMenuVariant.WHATSAPP
    : isSms
    ? TemplateMenuVariant.SMS
    : TemplateMenuVariant.EMAIL;

  const [isMergeKeysModalOpen, setIsMergeKeysModalOpen] = useState(false);

  const toggleMergeKeysDropdown = () => {
    setIsMergeKeysModalOpen(!isMergeKeysModalOpen);
  };

  const mergeKeysRef = useRef();
  const closeMergeKeysModal = useCallback(
    () => setIsMergeKeysModalOpen(false),
    [],
  );

  useOnClickOutside(mergeKeysRef, closeMergeKeysModal);

  const handleClickHtmlIcon = () => {
    if (htmlModeEnabled && value) {
      // eslint-disable-next-line no-alert
      if (window.confirm(intl.formatMessage(messages.confirmExitHtml))) {
        setHtmlModeEnabled(false);
      }
    } else {
      setHtmlModeEnabled(!htmlModeEnabled);
    }
  };

  return (
    <Grid
      container
      justify="flex-end"
      className={classNames(classes.bottomButtons)}
    >
      <Grid className={classes.messageTemplates} container justify="flex-end">
        {enableMessageTemplates && (
          <TemplateMenu
            onSelected={handleSelectTemplate}
            templateType={templateType}
            variant={messageTemplateVariant}
            forceHtmlModeEnabled={setHtmlModeEnabled}
          />
        )}
        {enableMergeKeys && (
          <Grid ref={mergeKeysRef}>
            <EditorActionsToolbarWidget
              icon={<MergeKeysSvg />}
              isSelected={isMergeKeysModalOpen}
              onClick={toggleMergeKeysDropdown}
              shouldShowOptions={isMergeKeysModalOpen}
              buttonDisabled={!Object.keys(mergeKeys).length}
              buttonTestId="merge-keys"
              tooltipMessage={intl.formatMessage(messages.mergeKeys)}
            >
              <MergeKeysSelect
                handleMergeKeySelect={handleMergeKeySelect}
                mergeKeys={mergeKeys}
                setIsMergeKeysModalOpen={setIsMergeKeysModalOpen}
                hasRequiredTab={hasRequiredTab}
              />
            </EditorActionsToolbarWidget>
          </Grid>
        )}
        {enableHtmlModeSwitch && (
          <EditorActionsToolbarWidget
            icon={<HtmlSvg />}
            isSelected={htmlModeEnabled}
            onClick={handleClickHtmlIcon}
            tooltipMessage={intl.formatMessage(messages.htmlEditor)}
            buttonTestId="html-editor"
          />
        )}
      </Grid>
    </Grid>
  );
}

EditorActionsToolbar.defaultProps = {
  enableMessageTemplates: false,
  enableMergeKeys: false,
  handleMergeKeySelect: () => {},
  htmlModeEnabled: false,
  mergeKeys: [],
  isSms: false,
  isWhatsapp: false,
};

EditorActionsToolbar.propTypes = {
  enableMessageTemplates: PropTypes.bool,
  enableMergeKeys: PropTypes.bool,
  handleSelectTemplate: PropTypes.func.isRequired,
  handleMergeKeySelect: PropTypes.func,
  htmlModeEnabled: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  mergeKeys: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  value: PropTypes.string,
  setHtmlModeEnabled: PropTypes.func.isRequired,
  isSms: PropTypes.bool,
  isWhatsapp: PropTypes.bool,
  templateType: PropTypes.string,
  hasRequiredTab: PropTypes.bool,
  enableHtmlModeSwitch: PropTypes.bool,
};

export default memo(injectIntl(EditorActionsToolbar));
