import {
  Button,
  Input,
  Loader,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  StyledReactSelect,
} from '@fountain/fountain-ui-components';
import { Typography } from '@material-ui/core';
import {
  ApproversObj,
  CancelablePromise,
  OpeningApprovalsService,
  OpeningApproverGroupObj,
  OpeningApproverGroupParams,
} from 'api-clients/monolith';
import { useApiService } from 'hooks';
import React, { useCallback, useEffect, useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addMessageAction } from 'containers/FlashMessage/actions';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';
import globalMessages from 'shared/global/messages';

import messages from './messages';
import { useStyles } from './styles';

export interface ApproversModalProps {
  refreshApproversData: () => void;
  isOpen: boolean;
  onClose: () => void;
  selectedApproverGroupId?: string;
}

export const ApproverGroupsModal: VFC<ApproversModalProps> = ({
  refreshApproversData,
  isOpen,
  onClose,
  selectedApproverGroupId,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const [approverGroupName, setApproverGroupName] = useState<string | null>(
    null,
  );
  const [selectedApprovers, setSelectedApprovers] = useState<ApproversObj[]>(
    [],
  );

  const { mutation: fetchApproverGroup, result: selectedApproverGroupResult } =
    useApiServiceMutation<
      OpeningApproverGroupObj,
      (approverGroupId: string) => CancelablePromise<OpeningApproverGroupObj>
    >(
      // eslint-disable-next-line @typescript-eslint/unbound-method
      OpeningApprovalsService.getInternalApiOpeningApprovalApproverGroups1,
      {
        onSuccess: data => {
          setApproverGroupName(data.approver_group.name);
          setSelectedApprovers(data.approver_group.approvers ?? []);
        },
      },
    );

  useEffect(() => {
    if (selectedApproverGroupId) {
      fetchApproverGroup(selectedApproverGroupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { result: approversResult } = useApiService(
    useCallback(
      () =>
        OpeningApprovalsService.getInternalApiOpeningApprovalApproverGroupsGetApprovers(),
      [],
    ),
  );

  const handleSuccess = (message: string) => {
    onClose();
    refreshApproversData();
    dispatch(addMessageAction(message, 'success'));
  };

  const { mutation: createApproverGroup } = useApiServiceMutation<
    OpeningApproverGroupParams,
    (
      requestBody: OpeningApproverGroupParams,
    ) => CancelablePromise<OpeningApproverGroupParams>,
    { message: string }
  >(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    OpeningApprovalsService.postInternalApiOpeningApprovalApproverGroups,
    {
      onSuccess: () => {
        handleSuccess(intl.formatMessage(messages.createSuccessMessage));
      },
      onError: errorBody => {
        const errorMessages =
          errorBody?.message ?? 'Unable to process the request.';
        dispatch(addMessageAction(errorMessages, 'error'));
      },
    },
  );

  const { mutation: updateApproverGroup } = useApiServiceMutation<
    OpeningApproverGroupParams,
    (
      id: string,
      requestBody: OpeningApproverGroupParams,
    ) => CancelablePromise<OpeningApproverGroupParams>,
    { message: string }
  >(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    OpeningApprovalsService.patchInternalApiOpeningApprovalApproverGroups,
    {
      onSuccess: () => {
        handleSuccess(intl.formatMessage(messages.updateSuccessMessage));
      },
      onError: errorBody => {
        const errorMessages =
          errorBody?.message ?? 'Unable to process the request.';
        dispatch(addMessageAction(errorMessages, 'error'));
      },
    },
  );

  const onSave = () => {
    if (selectedApprovers.length > 4) {
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.maxApproversErrorMessage),
          'error',
          false,
        ),
      );
      return;
    }

    if (!approverGroupName) {
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.groupNameErrorMessage),
          'error',
          false,
        ),
      );
      return;
    }
    const selectedApproverIds = selectedApprovers.map(item => item.id);

    const removedApprovers =
      selectedApproverGroupResult.status !== 'ready'
        ? []
        : selectedApproverGroupResult.data.approver_group.approvers.reduce(
            (acc: string[], item) => {
              if (!selectedApproverIds.includes(item.id)) acc.push(item.id);
              return acc;
            },
            [],
          );

    if (selectedApproverGroupId) {
      updateApproverGroup(selectedApproverGroupId, {
        opening_approver_group: {
          name: approverGroupName,
          add_users: selectedApproverIds,
          remove_users: removedApprovers,
        },
      });
    } else {
      createApproverGroup({
        opening_approver_group: {
          name: approverGroupName,
          add_users: selectedApproverIds,
          remove_users: removedApprovers,
        },
      });
    }
  };
  const handleSelectUser = (approvers: ApproversObj[] | null) => {
    setSelectedApprovers(approvers ?? []);
  };

  const handleGroupNameChange = (e: { target: { value: string } }) => {
    setApproverGroupName(e.target.value);
  };

  if (approversResult.isLoading || selectedApproverGroupResult.isLoading) {
    // TODO: Implement error state
  }

  if (approversResult.isLoading || selectedApproverGroupResult.isLoading)
    return <Loader fullScreen size="1.5rem" />;

  if (approversResult.status === 'ready')
    return (
      <Modal
        maxWidth="480px"
        ariaLabelledBy={<FormattedMessage {...messages.createApproverGroup} />}
        disableBackdropClick
        fullScreenOnMobile
        onClose={onClose}
        open={isOpen}
      >
        <ModalHeader
          ariaLabelledBy="<FormattedMessage {...messages.createApproverGroup} />"
          onClose={onClose}
          showIcon={false}
        >
          {intl.formatMessage(messages.createApproverGroup)}
        </ModalHeader>
        <ModalContent dividers>
          <div className={styles.modalContent}>
            <Typography variant="body2" className={styles.info}>
              <FormattedMessage {...messages.approversModalSubtitle} />
            </Typography>

            <div className={styles.inputContent}>
              <Typography variant="body2">
                <FormattedMessage {...messages.approverGroupNameLabel} />
              </Typography>

              <Input
                fullWidth
                onChange={handleGroupNameChange}
                value={approverGroupName}
                placeholder={intl.formatMessage(messages.inputPlaceholderText)}
                data-testid="template-name"
              />
            </div>

            <div className={styles.inputContent}>
              <Typography variant="body2">
                <FormattedMessage {...messages.approverGroupUsersLabel} />
              </Typography>

              <StyledReactSelect
                isMulti
                canSelectAll={false}
                options={approversResult.data.approvers}
                onChange={handleSelectUser}
                placeholder={intl.formatMessage(messages.selectPlaceolderText)}
                getOptionLabel={(option: ApproversObj) => option.name}
                getOptionValue={(option: ApproversObj) => option.id}
                value={selectedApprovers}
              />

              <Typography variant="body2">
                <FormattedMessage {...messages.approversModalSelectInfo} />
              </Typography>
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <Button
            aria-label={intl.formatMessage(globalMessages.cancel)}
            onClick={onClose}
            type="secondary"
            autoWidth
            className={styles.modalButton}
          >
            {intl.formatMessage(globalMessages.cancel)}
          </Button>
          <Button
            aria-label={intl.formatMessage(globalMessages.save)}
            onClick={onSave}
            disabled={
              !(
                (selectedApprovers.length > 0 && approverGroupName) ||
                (selectedApproverGroupResult.status === 'ready' &&
                  approverGroupName !==
                    selectedApproverGroupResult.data.approver_group.name)
              )
            }
            autoWidth
            className={styles.modalButton}
          >
            {intl.formatMessage(globalMessages.save)}
          </Button>
        </ModalFooter>
      </Modal>
    );

  return null;
};
