import { Box, SvgIconProps, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  container: {
    width: '321px',
    height: '250px',
    padding: theme.spacing(2, 3),
    background:
      'linear-gradient(0deg, rgba(94, 68, 255, 0.06), rgba(94, 68, 255, 0.06)), #FFFFFF',
    borderRadius: '16px',
  },
  icon: {
    width: '46px',
    height: '46px',
  },
  header: {
    fontWeight: 500,
    lineHeight: '24px',
    margin: theme.spacing(1, 0),
  },
  list: {
    listStyle: 'disc outside',
    paddingLeft: theme.spacing(3),
    margin: theme.spacing(1, 0),
  },
  description: {
    color: theme.palette.common.gray700,
    lineHeight: '26px',
  },
}));

export interface IntlMessage {
  id: string;
  defaultMessage: string;
}

export interface CardProps {
  Icon: React.VFC<SvgIconProps>;
  header: IntlMessage;
  descriptions: IntlMessage[];
}

export const Card: React.VFC<CardProps> = ({ Icon, header, descriptions }) => {
  const styles = useStyles();

  return (
    <Box className={styles.container}>
      <Icon className={styles.icon} />
      <Typography variant="h3" className={styles.header}>
        <FormattedMessage {...header} />
      </Typography>
      <ul className={styles.list}>
        {descriptions.map(description => (
          <li key={description.id}>
            <Typography variant="body1" className={styles.description}>
              <FormattedMessage {...description} />
            </Typography>
          </li>
        ))}
      </ul>
    </Box>
  );
};
