import { Box, createStyles, makeStyles } from '@material-ui/core';
import { EventAvailableSlot } from 'api-clients/monolith';
import React, { VFC } from 'react';

import { EventCapacity } from './EventCapacity';
import { EventDateTime } from './EventDateTime';

const useStyles = makeStyles(theme =>
  createStyles({
    rowContainer: {
      marginTop: theme.spacing(3),
    },
  }),
);

export interface EventSettingsProps {
  handleChange: (params: Partial<EventAvailableSlot>) => void;
  availableSlot: EventAvailableSlot;
  errors: Partial<Record<keyof EventAvailableSlot, string>>;
  series: boolean;
}

export const EventSettings: VFC<EventSettingsProps> = ({
  handleChange,
  availableSlot,
  errors,
  series,
}) => {
  const styles = useStyles();

  return (
    <Box className={styles.rowContainer}>
      <EventDateTime
        handleChange={handleChange}
        availableSlot={availableSlot}
        errors={errors}
        series={series}
      />
      <EventCapacity
        handleChange={handleChange}
        availableSlot={availableSlot}
        errors={errors}
      />
    </Box>
  );
};
