/* eslint-disable no-underscore-dangle */
import { Button } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import {
  WorkflowDocumentSignatureStage,
  WorkflowDocumentSigningStage,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import { produce } from 'immer';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';

import { DEFAULT_DOCUMENT_SIGNING_RULE } from 'containers/WorkflowEditor/components/Rules/constants';

import { DocumentSigningRuleCard } from './DocumentSigningRuleCard';
import { messages } from './messages';
import { useStyles } from './styles';
import { DocumentSigningRuleProps } from './types';

export interface DocumentSigningRulesProps {
  stage: WorkflowDocumentSigningStage | WorkflowDocumentSignatureStage;
  setStage: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
  setDocumentSigningRules: React.Dispatch<
    React.SetStateAction<DocumentSigningRuleProps>
  >;
  documentSigningRules: DocumentSigningRuleProps;
  errors?: Record<string, Array<string>>;
}

export const DocumentSigningRules: FC<DocumentSigningRulesProps> = ({
  stage,
  documentSigningRules,
  setDocumentSigningRules,
  errors,
  setStage,
}) => {
  const styles = useStyles();
  const intl = useIntl();

  const availableRules = { ...documentSigningRules };

  // Filter out any documentSigningRules that have _destroy set to true
  Object.keys(availableRules).forEach(ruleId => {
    if (availableRules[ruleId]._destroy) {
      delete availableRules[ruleId];
    }
  });

  const onAddRule = () => {
    setDocumentSigningRules(
      produce((draftRules: DocumentSigningRuleProps) => {
        draftRules[uuid()] = DEFAULT_DOCUMENT_SIGNING_RULE;
      }),
    );
  };

  const onDeleteRule = (ruleId: string | number) => {
    setDocumentSigningRules(
      produce((draftRules: DocumentSigningRuleProps) => {
        draftRules[ruleId]._destroy = true;
      }),
    );

    // This is used to set _destroy on any embedded_signature_template_stage (signers) that are no longer being used in the stage
    setStage(
      produce(stage, draftStage => {
        const allRulesDeleted = Object.keys(availableRules).length === 1;

        // destroy the template_rule_set if the last document is destroyed
        if (
          allRulesDeleted &&
          draftStage.additional_info.template_rule_set_attributes
        ) {
          draftStage.additional_info.template_rule_set_attributes._destroy =
            true;
        }
        // Looping through a given rule.template_settings_attributes and checking that the same template / document doesn't
        // exist in "default documents" and "alternate documents"
        availableRules?.[
          ruleId
        ]?.template_set_attributes?.template_settings_attributes?.forEach(
          templateSetting => {
            const id = templateSetting.template_id;

            const isDefaultTemplateAlreadyUsed =
              stage.additional_info.else_template_set_attributes?.template_settings_attributes?.some(
                setting => !setting._destroy && setting.template_id === id,
              );

            const copyDocumentSigningRules = { ...documentSigningRules };

            // Filtering out the rule itself from consideration
            delete copyDocumentSigningRules[ruleId];

            const isAlternateTemplateAlreadyUsed = Object.values(
              copyDocumentSigningRules,
            ).some(rule => {
              const templateSettings =
                rule.template_set_attributes.template_settings_attributes;

              return templateSettings?.some(
                setting => !setting._destroy && setting.template_id === id,
              );
            });

            // Removing embedded_signature_template_stage (signers) if this is the only instance of the template / document
            if (
              !isDefaultTemplateAlreadyUsed &&
              !isAlternateTemplateAlreadyUsed &&
              draftStage.type === 'DocumentSigningStage' &&
              draftStage.additional_info
                .embedded_signature_templates_stages_attributes
            ) {
              const draftEmbeddedSignatureTemplatesStages =
                draftStage.additional_info
                  .embedded_signature_templates_stages_attributes;

              const idx = draftEmbeddedSignatureTemplatesStages.findIndex(
                ests => ests.embedded_signature_template_id === id,
              );

              if (draftEmbeddedSignatureTemplatesStages[idx]) {
                draftEmbeddedSignatureTemplatesStages[idx]._destroy = true;
              }
            }
          },
        );
      }),
    );
  };

  if (Object.keys(availableRules).length === 0) {
    return (
      <Button
        type="secondary"
        size="small"
        autoWidth
        onClick={onAddRule}
        disableRipple
        aria-label={intl.formatMessage(messages.addDocumentSet)}
        className={styles.noDocumentSetsAddButton}
      >
        <Typography variant="h4" color="primary">
          <FormattedMessage {...messages.addDocumentSet} />
        </Typography>
      </Button>
    );
  }

  return (
    <Grid className={styles.rulesContainer}>
      {Object.keys(availableRules as Record<string, unknown>).map(
        (ruleId, index) => {
          const rule = availableRules[ruleId];

          return (
            <DocumentSigningRuleCard
              key={ruleId}
              stage={stage}
              ruleId={ruleId}
              rule={rule}
              index={index}
              setDocumentSigningRules={setDocumentSigningRules}
              setStage={setStage}
              onDeleteRule={onDeleteRule}
              errors={errors}
              availableEmbeddedSignatureTemplates={
                // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                stage.additional_info.available_embedded_signature_templates ||
                []
              }
            />
          );
        },
      )}
      <Button
        type="secondary"
        size="small"
        autoWidth
        onClick={onAddRule}
        disableRipple
        aria-label={intl.formatMessage(messages.addDocumentSet)}
        className={styles.addDocumentSetButton}
      >
        <Typography variant="h4" color="primary">
          <FormattedMessage {...messages.addDocumentSet} />
        </Typography>
      </Button>
    </Grid>
  );
};
