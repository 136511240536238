import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  advancedSettings: {
    id: 'app.components.WorkflowEditor.components.WorkflowEditorContent.StageDetail.HiredStage.AdvancedSettingsCard.advancedSettings',
    defaultMessage: 'Advanced Settings',
  },
  shouldAutoAdvanceWhenLabelsComplete: {
    id: 'app.components.WorkflowEditor.components.WorkflowEditorContent.StageDetail.HiredStage.AdvancedSettingsCard.shouldAutoAdvanceWhenLabelsComplete',
    defaultMessage:
      'Automatically move applicant to next stage when all labels are completed',
  },
  showStageInApplicantPortal: {
    id: 'app.components.WorkflowEditor.components.WorkflowEditorContent.StageDetail.HiredStage.AdvancedSettingsCard.showStageInApplicantPortal',
    defaultMessage: 'Show stage in applicant portal progress tracker',
  },
});
export default messages;
