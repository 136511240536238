import { Button, MoveToNextStage } from '@fountain/fountain-ui-components';
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  MenuItem,
  Popover,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Drawer from '@material-ui/core/Drawer';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useStyles } from 'Calendar/EventManager/ManageRoster/styles';
import { messages } from 'Calendar/EventManager/messages';
import cx from 'classnames';
import { useSimpleToggle } from 'hooks';
import React, { FC, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import useUserPermissions from 'hooks/useUserPermissions';
import Check from 'images/checkmarkV2';
import Absent from 'images/close.svg';
import Email from 'images/email-bulk.svg';
import ExpandIcon from 'images/icon-caret-right.svg';
import SMS from 'images/send-sms.svg';

export interface QuickActionsProps {
  count: number;
  perform: (action: Actions) => void;
}

const baseActions = ['email', 'sms', 'attended', 'absent'] as const;
const withManageApplicantsPermsActions = [
  ...baseActions,
  'advanced',
  'move',
] as const;

export type Actions = (typeof withManageApplicantsPermsActions)[number];

export const QuickActions: FC<QuickActionsProps> = ({ count, perform }) => {
  const intl = useIntl();
  const isMicro = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
  const styles = useStyles();
  const buttonRef = useRef<null | HTMLElement>(null);
  const {
    showContent: isDrawerOpen,
    toggle: toggleDrawer,
    off: closeDrawer,
  } = useSimpleToggle();
  const { manageApplicantsPermission: canManageApplicants } =
    useUserPermissions();
  const availableActions = canManageApplicants
    ? withManageApplicantsPermsActions
    : baseActions;

  const performClosing = (action: Actions) => {
    perform(action);
    closeDrawer();
  };

  const message = (action: Actions): string => {
    switch (action) {
      case 'attended':
        return intl.formatMessage(messages.attendedDrawer);
      case 'absent':
        return intl.formatMessage(messages.absentDrawer);
      case 'advanced':
        return intl.formatMessage(messages.advance);
      case 'sms':
        return intl.formatMessage(messages.sendSMS);
      case 'email':
        return intl.formatMessage(messages.sendEmail);
      case 'move':
        return intl.formatMessage(messages.moveTo);
      default:
    }
    return '';
  };

  const icon = (action: Actions): JSX.Element => {
    switch (action) {
      case 'attended':
        return <Check size={24} />;
      case 'absent':
        return <img src={Absent} alt={message(action)} />;
      case 'advanced':
      case 'move':
        return <MoveToNextStage />;
      case 'email':
        return <img src={Email} alt={message(action)} />;
      case 'sms':
        return <img src={SMS} alt={message(action)} />;
      default:
    }
    return <img alt="coming soon" />;
  };

  const openActions = (event: React.MouseEvent<HTMLButtonElement>) => {
    buttonRef.current = event.currentTarget;
    toggleDrawer();
  };

  return (
    <ClickAwayListener onClickAway={closeDrawer}>
      <>
        <Box className={styles.bulkActions}>
          <Grid container>
            <Grid item xs={6} className={styles.selectedCount}>
              <FormattedMessage
                {...messages.selectedCount}
                values={{ count }}
              />
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end">
                <Button onClick={openActions} type="secondary">
                  <FormattedMessage {...messages.QuickActions} />
                  <img
                    alt={intl.formatMessage(messages.QuickActions)}
                    className={cx(styles.caretIcon, {
                      [styles.caretIconCollapsed]: isDrawerOpen,
                    })}
                    src={ExpandIcon}
                  />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {isMicro ? (
          <Drawer anchor="bottom" open={isDrawerOpen} onClose={closeDrawer}>
            <div className={styles.fullWidth}>
              <List>
                <ListItem button key="heading" onClick={closeDrawer}>
                  <ListItemText
                    primary={intl.formatMessage(messages.QuickActions)}
                  />
                </ListItem>
              </List>
              <Divider />
              <List>
                {availableActions.map(action => {
                  return (
                    <ListItem
                      button
                      key={action}
                      onClick={() => {
                        performClosing(action);
                      }}
                    >
                      <ListItemIcon>{icon(action)}</ListItemIcon>
                      <ListItemText primary={message(action)} />
                    </ListItem>
                  );
                })}
              </List>
            </div>
          </Drawer>
        ) : (
          isDrawerOpen && (
            <Popover
              anchorEl={buttonRef.current}
              keepMounted
              open={isDrawerOpen}
              onClose={closeDrawer}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: -8,
                horizontal: 'right',
              }}
            >
              {availableActions.map(action => {
                return (
                  <MenuItem
                    button
                    key={action}
                    data-testid={`quick-action-${action}`}
                    onClick={() => {
                      performClosing(action);
                    }}
                  >
                    <ListItemIcon>{icon(action)}</ListItemIcon>
                    <ListItemText primary={message(action)} />
                  </MenuItem>
                );
              })}
            </Popover>
          )
        )}
      </>
    </ClickAwayListener>
  );
};
