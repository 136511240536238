import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  accurateIntegration: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BackgroundCheckStage.IntegrationCard.accurateIntegration`,
    defaultMessage: 'Accurate Integration',
  },
  checkrGeo: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BackgroundCheckStage.IntegrationCard.checkrGeo`,
    defaultMessage: 'Checkr Geo',
  },
  checkrIntegration: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BackgroundCheckStage.IntegrationCard.checkrIntegration`,
    defaultMessage: 'Checkr Integration',
  },
  manageIntegration: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BackgroundCheckStage.IntegrationCard.manageIntegration`,
    defaultMessage: 'Manage Integration',
  },
  none: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BackgroundCheckStage.IntegrationCard.none`,
    defaultMessage: 'None',
  },
  progressiveScreening: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BackgroundCheckStage.IntegrationCard.progressiveScreening`,
    defaultMessage:
      "Use Checkr's Progressive Screening to run the rest of the reports only if MVR passes",
  },
  reportPackage: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BackgroundCheckStage.IntegrationCard.reportPackage`,
    defaultMessage: 'Report Package',
  },
  sterlingIntegration: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BackgroundCheckStage.IntegrationCard.sterlingIntegration`,
    defaultMessage: 'Sterling Integration',
  },
});
