import { Grid } from '@material-ui/core';
import React, { FC, useContext } from 'react';

import { SharedActionProps } from 'containers/WorkflowEditor/components/Rules/types';
import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';

import { DataKeySelect } from '../components/DataKeySelect';
import { FieldByType } from './FieldByType';

export const UpdateApplicantData: FC<SharedActionProps> = React.memo(props => {
  const { action } = props;

  const { data_keys: dataKeys } = useContext(RulesEditDataContext);

  const dataKeysToSearch = [
    ...(dataKeys?.standard_keys ?? []),
    ...(dataKeys?.custom_keys ?? []),
  ];
  const selectedStandardDataKey = dataKeysToSearch.find(
    dataKey => dataKey.key === action.extra.key,
  );

  return (
    <>
      <DataKeySelect {...props} />
      <Grid item xs={6}>
        <FieldByType
          {...props}
          selectedStandardDataKey={selectedStandardDataKey}
        />
      </Grid>
    </>
  );
});
