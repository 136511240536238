import { Typography } from '@material-ui/core';
import { lighten, makeStyles, Theme } from '@material-ui/core/styles';
import classnames from 'classnames';
import React, { VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from './messages';

const useStyles = makeStyles<Theme, { metric: keyof typeof messages }>(
  theme => ({
    box: {
      width: '200px',
      padding: theme.spacing(1.25, 2),
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '12px',
      backgroundColor: props =>
        lighten(
          props.metric === 'markedHelpful'
            ? theme.palette.common.green100
            : theme.palette.common.red100,
          0.7,
        ),
    },
    count: {
      fontSize: '42px',
      fontWeight: 700,
      lineHeight: '42px',
      color: props =>
        props.metric === 'markedHelpful'
          ? theme.palette.common.green400
          : theme.palette.common.red400,
    },
    percent: {
      fontSize: '32px',
    },
    title: {
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '14px',
      marginBottom: theme.spacing(1),
      textTransform: 'uppercase',
      color: props =>
        props.metric === 'markedHelpful'
          ? theme.palette.common.green800
          : theme.palette.common.red800,
    },
  }),
);

export interface FeedbackPercentageBoxProps {
  metric: keyof typeof messages;
  value: number;
}

export const FeedbackPercentageBox: VFC<FeedbackPercentageBoxProps> = ({
  metric,
  value,
}) => {
  const classes = useStyles({ metric });

  return (
    <div className={classes.box}>
      <Typography display="inline" variant="body1" className={classes.title}>
        <FormattedMessage {...messages[metric]} />
      </Typography>
      <span>
        <Typography display="inline" variant="h1" className={classes.count}>
          {value}
        </Typography>
        <Typography
          display="inline"
          variant="body2"
          className={classnames(classes.count, classes.percent)}
        >
          %
        </Typography>
      </span>
    </div>
  );
};
