import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  preWrapText: {
    whiteSpace: 'pre-wrap',
  },
  radioGroup: {
    gap: theme.spacing(2),
    '& label': {
      '& span:first-child': {
        paddingTop: 0,
        alignSelf: 'flex-start',
      },
      marginRight: 0,
    },
  },
}));
