import { all, call, put, takeLatest } from 'redux-saga/effects';
import { REACT_APP_GLOBAL_API_BASE_URL } from 'runtimeEnvVars';

import { apiPatch } from 'utils/axios';

import {
  updateAccountFailedAction,
  updateAccountSuccessAction,
} from './actions';
import { UPDATE_ACCOUNT_INIT } from './constants';

function* updateAccount(data) {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL}/users/self`;

  const params = {};
  if (data.name.length > 0) {
    params.name = data.name;
  }

  if (data.oldPassword.length > 0 && data.newPassword.length > 0) {
    params.old_password = data.oldPassword;
    params.new_password = data.newPassword;
  }

  if (data.phoneNumber.length > 0) {
    params.phone_number = data.phoneNumber;
  }

  try {
    const response = yield call(apiPatch, url, params);
    yield put(updateAccountSuccessAction(response.data));
  } catch (error) {
    yield put(updateAccountFailedAction(error));
  }
}

export const updateAccountSaga = takeLatest(UPDATE_ACCOUNT_INIT, updateAccount);

// Individual exports for testing
export default function* accountSaga() {
  yield all([updateAccountSaga]);
}
