import React from 'react';
import Highlighter from 'react-highlight-words';

import useStyles from './styles';

interface SearchableTextProps {
  searchString: string;
  text?: string;
}
const SearchableText: React.VFC<SearchableTextProps> = ({
  searchString,
  text,
}) => {
  const styles = useStyles();

  return (
    <Highlighter
      autoEscape
      highlightClassName={styles.highlight}
      searchWords={[searchString]}
      textToHighlight={text ?? ''}
    />
  );
};

export default SearchableText;
