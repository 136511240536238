import { WorkflowEditorService } from 'api-clients/monolith';
import { useApiService } from 'hooks';
import React from 'react';

export function useRulesTargetOpenings({
  externalId,
  filterToSelectedOnly,
}: {
  externalId: string;
  filterToSelectedOnly?: boolean;
}) {
  const fetchRulesTargetOpenings = React.useCallback(
    () =>
      WorkflowEditorService.getInternalApiWorkflowEditorRulesEditData(
        externalId,
        filterToSelectedOnly,
        true,
      ),
    [externalId, filterToSelectedOnly],
  );

  return useApiService(fetchRulesTargetOpenings);
}
