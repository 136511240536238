import {
  Button,
  Input,
  Loader,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Radio,
  StyledReactSelect,
} from '@fountain/fountain-ui-components';
import { Grid, RadioGroup, Typography } from '@material-ui/core';
import {
  CancelablePromise,
  RuleStageDistributionBasis,
  SidebarStage,
  WorkflowEditorService,
  WorkflowStageDetail,
  WorkflowStageUpdates,
} from 'api-clients/monolith';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Error } from 'components/Error';
import { addMessageAction } from 'containers/FlashMessage/actions';
import { useAvailableRuleStages } from 'containers/WorkflowEditor/hooks/useAvailableRuleStages';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';
import { useForm } from 'hooks/useForm';

import { messages as createStageMessages } from '../NewStageModal/messages';
import { useGetDistributionBasisOptions } from '../useGetDistributionBasisOptions';
import {
  SelectOption,
  useGetStagePlacementOptions,
} from '../useGetStagePlacementOptions';
import { useGetStageTypeOptions } from '../useGetStageTypeOptions';
import { messages } from './messages';
import { useStyles } from './styles';
import { RuleStageType, useGetDescriptionText } from './useGetDescriptionText';

interface RuleTypeOption {
  label: string;
  value: RuleStageType;
}

export interface AddRuleModalProps {
  onClose: () => void;
  refetchStages: () => void;
  stages: SidebarStage[];
}

interface FormValues {
  title?: string;
  type: RuleStageType;
  // eslint-disable-next-line camelcase
  distribution_basis?: RuleStageDistributionBasis;
  position: number;
}

const isRuleStageDistributionBasis = (
  value: string,
): value is RuleStageDistributionBasis => {
  const ruleStageDistributionBases = new Set(['hiring_need', 'percentage']);

  return ruleStageDistributionBases.has(value);
};

const RadioDescriptionText: FC<{ type: RuleStageDistributionBasis }> = ({
  type,
}) => {
  const radioDescriptionText = useGetDescriptionText(type);
  return <FormattedMessage {...radioDescriptionText} />;
};

export const AddRuleModal: FC<AddRuleModalProps> = ({
  onClose,
  refetchStages,
  stages,
}) => {
  const intl = useIntl();
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { accountSlug, funnelSlug } = useParams<{
    accountSlug: string;
    funnelSlug: string;
  }>();

  const validate = (values: FormValues) => {
    const errors: Partial<Record<keyof FormValues, string>> = {};

    if (!values.title) {
      errors.title = intl.formatMessage(messages.addRuleTitle);
    }

    return errors;
  };

  const stagePlacementOptions = useGetStagePlacementOptions({ stages });
  const { result: availableRuleStagesResult } = useAvailableRuleStages({
    funnelSlug,
  });

  const ruleTypeOptions = useGetStageTypeOptions({
    stageTypes:
      availableRuleStagesResult.status === 'ready'
        ? availableRuleStagesResult.data.rule_stage_types
        : [],
  });

  const distributionBasisOptions = useGetDistributionBasisOptions({
    distributionBases:
      availableRuleStagesResult.status === 'ready'
        ? availableRuleStagesResult.data.rule_stage_distribution_bases
        : [],
  });

  const { result: addRuleStageResult, mutation: addRuleStage } =
    useApiServiceMutation<
      WorkflowStageDetail,
      (
        funnelSlug: string,
        requestBody?: WorkflowStageUpdates,
      ) => CancelablePromise<WorkflowStageDetail>,
      { errors: Record<string, Array<string>> }
    >(
      // eslint-disable-next-line @typescript-eslint/unbound-method
      WorkflowEditorService.postInternalApiWorkflowEditorFunnelsStages,
      {
        onSuccess: data => {
          onClose();
          refetchStages();
          history.push(
            `/${accountSlug}/openings/${funnelSlug}/workflow/${
              data.slug ?? ''
            }`,
          );
          dispatch(
            addMessageAction(
              intl.formatMessage(messages.ruleCreateSuccess),
              'success',
            ),
          );
        },
      },
    );

  const onSubmit = (stage: FormValues) => {
    const { position } = stage;

    addRuleStage(funnelSlug, {
      workflow_stage: {
        stage: {
          ...stage,
          position: position > 0 ? position + 1 : position,
        },
      },
    });
  };

  const { values, handleChange, handleSubmit, errors } = useForm<FormValues>(
    onSubmit,
    validate,
    {
      position: -1,
      type: 'RuleStage',
      title: '',
      /* eslint-disable-next-line camelcase */
      distribution_basis: 'percentage',
    },
  );

  const displayDistributionBases: boolean =
    values.type === 'DistributeApplicantsRuleStage' &&
    !!distributionBasisOptions.length;

  const addRuleStageResultErrors =
    addRuleStageResult.status === 'error' && addRuleStageResult?.error?.errors;

  const isLoading =
    addRuleStageResult.isLoading || availableRuleStagesResult.isLoading;

  const descriptionText = useGetDescriptionText(values.type);

  return (
    <Modal
      ariaLabelledBy={intl.formatMessage(messages.addRule)}
      disableBackdropClick
      fullScreenOnMobile
      onClose={onClose}
      open
      maxWidth={496}
    >
      <form onSubmit={handleSubmit}>
        <ModalHeader
          ariaLabelledBy={intl.formatMessage(messages.addRule)}
          onClose={onClose}
          showIcon={false}
        >
          <FormattedMessage {...messages.addNewRule} />
        </ModalHeader>
        <ModalContent dividers>
          {availableRuleStagesResult.isLoading && (
            <Loader fullSection size="2rem" />
          )}
          {availableRuleStagesResult.isError && (
            <Typography variant="body2" className={styles.descriptionText}>
              <FormattedMessage {...messages.ruleStageLoadError} />
            </Typography>
          )}
          {availableRuleStagesResult.status === 'ready' && (
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Input
                  aria-label={intl.formatMessage(createStageMessages.title)}
                  className={styles.input}
                  hideErrorMessage
                  label={intl.formatMessage(createStageMessages.title)}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange({ title: event.target.value })
                  }
                  value={values.title}
                  error={Boolean(errors.title ?? addRuleStageResultErrors)}
                  required
                />
                <Error
                  error={
                    errors.title ||
                    (addRuleStageResultErrors && addRuleStageResultErrors[0])
                  }
                  align="right"
                />
              </Grid>
              <Grid item>
                <StyledReactSelect
                  aria-label={intl.formatMessage(messages.ruleType)}
                  options={ruleTypeOptions}
                  label={intl.formatMessage(messages.ruleType)}
                  value={ruleTypeOptions.find(
                    option => option.value === values.type,
                  )}
                  onChange={(option: RuleTypeOption) => {
                    handleChange({ type: option.value });
                  }}
                />
                <Typography className={styles.descriptionText} variant="body2">
                  <FormattedMessage {...descriptionText} />
                </Typography>
              </Grid>
              {displayDistributionBases && (
                <Grid item>
                  {/* eslint-disable camelcase */}
                  <RadioGroup
                    aria-label={intl.formatMessage(messages.distributionBasis)}
                    name={intl.formatMessage(messages.distributionBasis)}
                    value={values.distribution_basis}
                    onChange={e => {
                      if (isRuleStageDistributionBasis(e.target.value)) {
                        handleChange({
                          distribution_basis: e.target.value,
                        });
                      }
                    }}
                  >
                    {distributionBasisOptions.map(option => (
                      <React.Fragment key={option.label}>
                        <Radio
                          value={option.value.toString()}
                          label={option.label}
                          checked={values.distribution_basis === option.value}
                        />
                        <Typography
                          className={styles.radioDescriptionText}
                          variant="body2"
                        >
                          <RadioDescriptionText type={option.value} />
                        </Typography>
                      </React.Fragment>
                    ))}
                  </RadioGroup>
                  {/* eslint-enable camelcase */}
                </Grid>
              )}
              <Grid item>
                <StyledReactSelect
                  aria-label={intl.formatMessage(
                    createStageMessages.stagePlacement,
                  )}
                  options={stagePlacementOptions}
                  label={intl.formatMessage(createStageMessages.stagePlacement)}
                  onChange={(option: SelectOption) => {
                    handleChange({ position: option.value as number });
                  }}
                  value={stagePlacementOptions.find(
                    option => option.value === values.position,
                  )}
                />
              </Grid>
            </Grid>
          )}
        </ModalContent>
        <ModalFooter>
          <Button disableRipple onClick={onClose} size="small" type="secondary">
            <FormattedMessage {...messages.cancel} />
          </Button>
          <Button
            data-testid="add-rule-save-button"
            disableRipple
            submit
            size="small"
            disabled={isLoading || availableRuleStagesResult.isError}
            isLoading={isLoading}
          >
            <FormattedMessage {...messages.addRule} />
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
