import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  distributeRule: {
    marginBottom: theme.spacing(2),
    position: 'relative',

    '&.hasErrors': {
      marginBottom: theme.spacing(3),
    },
  },
  dropdown: {
    '&.disabled': {
      '& > div > div': {
        background: theme.palette.common.gray100,
      },
    },
  },
  deleteRule: {
    position: 'absolute',
    right: theme.spacing(-3),
    top: theme.spacing(2.25),
    cursor: 'pointer',

    '&.isFirstRule': {
      top: theme.spacing(4.75),
    },
  },
  locationsDropdown: {
    marginRight: theme.spacing(1),
  },
  openingsDropdown: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  stagesDropdown: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  percentageFieldContainer: {
    marginLeft: theme.spacing(1),
  },
  percentageField: {
    border: theme.customBorders.gray300,
    boxShadow: theme.customShadows.selectDropdown,

    '&.disabled': {
      background: theme.palette.common.gray100,
    },
  },
  percentageErrorMsg: {
    display: 'flex',
    marginTop: theme.spacing(0.5),
    lineHeight: `${theme.spacing(2)}px`,
  },
}));

export default useStyles;
