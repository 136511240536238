import { defineMessages } from 'react-intl';

export default defineMessages({
  settingsTitle: {
    id: 'app.containers.OpeningApprovals.autoApprove.settingsTitle',
    defaultMessage: 'Auto approval Settings',
  },
  autoApproveMessage: {
    id: 'app.containers.OpeningApprovals.autoApprove.autoApproveMessage',
    defaultMessage: 'Auto-Approve',
  },
  autoApproveIdleDays: {
    id: 'app.containers.OpeningApprovals.autoApprove.autoApproveIdleDays',
    defaultMessage:
      'An opening can be auto-approved and become Active, if the approver(s) has not responded within specified days',
  },
});
