import { all, call, put, takeLatest } from 'redux-saga/effects';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import { apiGet } from 'utils/axios';

import {
  fetchMessageTemplatesErrorAction,
  fetchMessageTemplatesSuccessAction,
} from './actions';
import {
  FETCH_MESSAGE_TEMPLATES_INIT,
  MessageTemplatesVariant,
} from './constants';

export function* fetchMessageTemplates({
  templateType,
  templateVariant,
  applicantId,
}) {
  const queryParams = new URLSearchParams();

  switch (templateVariant) {
    case MessageTemplatesVariant.EMAIL:
      queryParams.set('email_enabled', 'true');
      break;
    case MessageTemplatesVariant.SMS:
      queryParams.set('sms_enabled', 'true');
      break;
    case MessageTemplatesVariant.WHATSAPP:
      queryParams.set('whats_app_enabled', 'true');
      break;
    default:
      break;
  }

  if (templateType) {
    queryParams.set('type', templateType);
  }

  if (applicantId) {
    queryParams.set('external_id', applicantId);
  }

  try {
    const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/message_templates?${queryParams}`;
    const response = yield call(apiGet, url);
    yield put(
      fetchMessageTemplatesSuccessAction(
        response.data.message_templates,
        response.data.whats_app_templates,
      ),
    );
  } catch (err) {
    yield put(fetchMessageTemplatesErrorAction(err));
  }
}

export const fetchMessageTemplatesSaga = takeLatest(
  FETCH_MESSAGE_TEMPLATES_INIT,
  fetchMessageTemplates,
);

export default function* locationsSaga() {
  yield all([fetchMessageTemplatesSaga]);
}
