import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  switchLabel: {
    paddingLeft: theme.spacing(2),
  },
  enabled: {
    color: theme.palette.common.gray800,
  },
  disabled: {
    color: theme.palette.common.gray500,
  },
  fieldRow: {
    paddingBottom: theme.spacing(3),

    '&:last-child': {
      paddingBottom: 0,
    },
  },
  fieldComponent: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(4.25),
  },
  tooltip: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    height: 16,
    width: 16,
  },
  infoButton: {
    height: 16,
    width: 16,
  },
}));
