import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  faqCount: {
    id: 'app.containers.Chatbot.Analytics.faqCount',
    defaultMessage: 'Questions answered',
  },
  responseStats: {
    id: 'app.containers.Chatbot.Analytics.responseStats',
    defaultMessage: 'Response stats',
  },
  markedHelpful: {
    id: 'app.containers.Chatbot.Analytics.markedHelpful',
    defaultMessage: 'Marked helpful',
  },
  markedUnhelpful: {
    id: 'app.containers.Chatbot.Analytics.markedUnhelpful',
    defaultMessage: 'Marked unhelpful',
  },
});
