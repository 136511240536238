import {
  CustomTooltip,
  StyledReactSelect,
} from '@fountain/fountain-ui-components';
import { Grid, makeStyles } from '@material-ui/core';
import { JobMatcherCondition as JobMatcherConditionType } from 'api-clients/monolith';
import cx from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { RowDeleteButton } from 'containers/WorkflowEditor/components/Rules/RowDeleteButton';
import { RowTag } from 'containers/WorkflowEditor/components/Rules/RowTag';
import InfoGrey from 'images/info-grey.svg';
import { messages as globalMessages } from 'shared/global/messages';

import { messages } from './messages';

const useStyles = makeStyles(theme => ({
  conditionRow: {
    marginBottom: theme.spacing(3),

    '&:last-child': {
      marginBottom: 0,
    },
  },
  tagContainer: {
    marginBottom: theme.spacing(1.75),
    height: theme.spacing(4.5),
  },
  deleteButton: {
    paddingTop: theme.spacing(3),
  },
  tooltipContainer: {
    marginTop: theme.spacing(2.5),
    alignSelf: 'center',
  },
  conditionSelect: {
    '& .MuiGrid-container': {
      minHeight: '46px',
    },
  },
  disabledSelect: {
    '& .MuiGrid-container': {
      backgroundColor: theme.palette.common.gray100,
    },
    '& img': {
      color: theme.palette.common.gray300,
    },
  },
  infoTooltip: {
    paddingLeft: theme.spacing(1),
  },
}));

export type JobRouterConditionRow = JobMatcherConditionType & {
  fixed?: boolean;
  tooltipText?: string;
};

export type ConditionType = {
  label: string;
  value: JobMatcherConditionType['resource'];
  isDisabled?: boolean;
};

export interface JobRouterConditionProps {
  condition: JobRouterConditionRow;
  valueOptions: Array<{ label?: string; value?: number }>;
  typeOptions: ConditionType[];
  index: number;
  isError: boolean;
  onChangeConditionType: (type: ConditionType['value']) => void;
  onChangeConditionValue: (
    values: Array<{ label: string; value: number }>,
  ) => void;
  onDelete: () => void;
}

export const JobRouterCondition: React.VFC<JobRouterConditionProps> = ({
  condition,
  valueOptions,
  typeOptions,
  index,
  isError,
  onChangeConditionType,
  onChangeConditionValue,
  onDelete,
}) => {
  const intl = useIntl();
  const styles = useStyles();

  const selectedType = typeOptions.find(
    conditionType => conditionType.value === condition.resource,
  );

  const selectedValues =
    valueOptions.filter(option => {
      if (option.value === undefined) {
        return false;
      }
      return (condition.resource_ids ?? []).includes(option.value);
    }) ?? [];

  return (
    <Grid container className={styles.conditionRow}>
      <Grid
        container
        justify="space-between"
        wrap="nowrap"
        alignItems="center"
        className={styles.tagContainer}
      >
        <RowTag
          label={
            index === 0
              ? intl.formatMessage(messages.condition)
              : intl.formatMessage(messages.and)
          }
        />
      </Grid>
      <Grid item container spacing={2} justify="center">
        <Grid item xs>
          <StyledReactSelect
            aria-label={intl.formatMessage(messages.conditionTypeNumber, {
              number: index + 1,
            })}
            className={cx(styles.conditionSelect, {
              [styles.disabledSelect]: condition.fixed,
            })}
            isDisabled={condition.fixed}
            error={
              isError &&
              !selectedType &&
              intl.formatMessage(globalMessages.required)
            }
            isSearchable
            label={intl.formatMessage(messages.conditionType)}
            noOptionsMessage={() => (
              <FormattedMessage {...globalMessages.noOptionsAvailable} />
            )}
            onChange={(type: ConditionType) => {
              onChangeConditionType(type.value);
            }}
            options={typeOptions}
            placeholder={intl.formatMessage(messages.selectCondition)}
            value={selectedType}
          />
        </Grid>
        <Grid item xs>
          <StyledReactSelect
            aria-label={intl.formatMessage(messages.conditionValueNumber, {
              number: index + 1,
            })}
            className={cx(styles.conditionSelect, {
              [styles.disabledSelect]: condition.fixed,
            })}
            isDisabled={condition.fixed}
            error={
              isError &&
              selectedValues.length === 0 &&
              intl.formatMessage(globalMessages.required)
            }
            isMulti
            isSearchable
            label={intl.formatMessage(globalMessages.value)}
            noOptionsMessage={() => (
              <FormattedMessage {...globalMessages.noOptionsAvailable} />
            )}
            onChange={onChangeConditionValue}
            options={valueOptions}
            placeholder={intl.formatMessage(messages.selectValue)}
            value={selectedValues}
          />
        </Grid>
        <Grid
          item
          xs={1}
          className={cx({ [styles.tooltipContainer]: condition.fixed })}
        >
          {condition.fixed ? (
            <CustomTooltip
              dense
              placement="top"
              title={condition.tooltipText ?? ''}
              className={styles.infoTooltip}
            >
              <img src={InfoGrey} alt={intl.formatMessage(messages.infoIcon)} />
            </CustomTooltip>
          ) : (
            <RowDeleteButton
              className={styles.deleteButton}
              allowDelete
              onDelete={onDelete}
              ariaLabel={intl.formatMessage(messages.deleteConditionNumber, {
                number: index + 1,
              })}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
