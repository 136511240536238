import { RuleStageDistributionBasis } from 'api-clients/monolith';
import { camelCase } from 'lodash';
import { useIntl } from 'react-intl';

import { messages } from './messages';

export const useGetDistributionBasisOptions = ({
  distributionBases,
}: {
  distributionBases: RuleStageDistributionBasis[];
}) => {
  const intl = useIntl();

  return distributionBases.map(basis => {
    const label = camelCase(basis);
    switch (label) {
      case 'hiringNeed':
      case 'percentage':
        return { label: intl.formatMessage(messages[label]), value: basis };
      default:
        throw new Error(
          `Received invalid RuleStageDistributionBasis: ${label}`,
        );
    }
  });
};
