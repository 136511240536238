export type Environment = 'prod' | 'uat' | 'stage' | 'dev';

const getSubdomain = (hostname: string): string => {
  if (
    hostname.startsWith('app.') &&
    (hostname.endsWith('.fountain.com') ||
      hostname.endsWith('.tryfountain.com'))
  ) {
    return hostname.slice(4).split('.')[0];
  }

  throw new Error(`Received unexpected hostname: ${hostname}`);
};

export const NONPROD_SUBDOMAIN_MAP = new Map<string, Environment>([
  ['faut-01', 'uat'],
  ['uat-01', 'uat'],

  ['dev-01', 'dev'],
  ['dev-02', 'dev'],
  ['faut-02', 'dev'],

  // Spread in our standard Dropship domains (e.g. `['staging-use-01', 'stage']`)
  ...Array.from(Array(20).keys()).map<[string, Environment]>(i => [
    `staging-use-${String(i + 1).padStart(2, '0')}`,
    'stage',
  ]),
]);

export function subdomainEnvironment(hostname: string): Environment {
  return NONPROD_SUBDOMAIN_MAP.get(getSubdomain(hostname)) ?? 'prod';
}
