import { Menu } from '@fountain/fountain-ui-components';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { classNames } from 'react-extras';

import { isMenuActive } from '../../helpers';
import NavigationMenuItem from '../NavigationMenuItem/index';
import makeStyles from './styles';

export default function NavigationMenu({
  id,
  triggerElContents,
  dataTestIds,
  menuItems,
}) {
  const classes = makeStyles();
  return (
    <Menu
      className={classNames(
        classes.topMenu,
        isMenuActive(menuItems) && classes.active,
      )}
      menuWidth={244}
      id={id}
      dataTestIds={dataTestIds}
      MenuListProps={{ className: classes.menuList }}
      triggerEl={
        <>
          <Typography
            variant="body2"
            className={classNames(classes.leftSectionTrigger, classes.menuItem)}
          >
            {triggerElContents}
          </Typography>
        </>
      }
      transformOrigin={{ vertical: 0, horizontal: 'left' }}
    >
      {menuItems.children
        .filter(mi => mi.viewable)
        .map(mi => (
          <NavigationMenuItem key={mi.to || mi.href} {...mi} />
        ))}
    </Menu>
  );
}

NavigationMenu.propTypes = {
  id: PropTypes.string,
  triggerElContents: PropTypes.node.isRequired,
  menuItems: PropTypes.object.isRequired,
  dataTestIds: PropTypes.object.isRequired,
};
