import {
  DocumentSignatureStagesTemplateSetting,
  DocumentSigningStagesTemplateSetting,
} from 'api-clients/monolith';
import { v4 as uuid } from 'uuid';

export const DEFAULT_DOCUMENT:
  | DocumentSignatureStagesTemplateSetting
  | DocumentSigningStagesTemplateSetting = {
  id: uuid(),
};
