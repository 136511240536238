/**
 *
 * BulkSendSmsPopup
 *
 */

import 'react-datepicker/dist/react-datepicker.css';

import {
  Button,
  Checkbox,
  Drawer as DrawerPopup,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerHeaderIcon,
  IconButton as StyledIconButton,
  Loader,
  StyledReactSelect,
} from '@fountain/fountain-ui-components';
import { Divider, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { classNames } from 'react-extras';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { PHONE_PLATFORM_TYPES } from 'components/SmsMessage/constants';
import { makeSelectJob } from 'containers/ApplicantsV2/selectors';
import { SELECT_ALL } from 'containers/ApplicantTableV2/constants';
import {
  makeSelectCurrentLocation,
  makeSelectWhoami,
} from 'containers/Auth_old/selectors';
import withBulkActionConfirmation from 'containers/BulkActionConfirmationModal';
import { addDefaultErrorMessageAction } from 'containers/FlashMessage/actions';
import { POPUP_TYPES } from 'containers/GlobalPopup/constants';
import MessageTemplateTextEditor from 'containers/MessageTemplateTextEditor';
import { DelayMessageOptions } from 'containers/SendEmailPopup/components/DelayMessageOptions/DelayMessageOptions';
import { TemplateMenuVariant } from 'containers/TemplateMenu';
import { MessageTemplatesType } from 'containers/TemplateMenu/constants';
import useApplicantFileRecollection from 'hooks/useApplicantFileRecollection';
import useBulkMessageApplicants from 'hooks/useBulkMessageApplicants';
import useFetchMessageTemplates from 'hooks/useFetchMessageTemplates';
import useForm from 'hooks/useForm';
import useKeyPress from 'hooks/useKeyPress';
import useTargetStageId from 'hooks/useTargetStageId';
import ChatIcon from 'images/chat-icon.svg';
import PreviewIcon from 'images/preview-icon.svg';
import globalMessages from 'shared/global/messages';
import { minDate } from 'utils/date';
import { formatPhoneNumber } from 'utils/formatPhoneNumber';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import BulkChannelSwitch from './BulkChannelSwitch';
import { SEND_TYPE } from './constants';
import MessagePreviewDeviceView from './MessagePreviewDeviceView';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';
import useStyles from './styles';
import {
  generateDefaultTemplate,
  generateSendTypeOptions,
  phonePlatformTabs,
} from './utils';
import validate from './validate';

export function BulkSendSmsPopup({
  accountSlug,
  applicantIds,
  fromMav,
  open,
  handleClose,
  intl,
  searchQuery,
  selectedApplicant,
  applicantsCount,
}) {
  const cmd = useKeyPress('Meta');
  const enter = useKeyPress('Enter');
  const control = useKeyPress('Control');
  const classes = useStyles();
  const selectAll = applicantIds === SELECT_ALL || undefined;

  useApplicantFileRecollection(selectedApplicant.info.id, accountSlug);

  useInjectReducer({ key: 'sendSmsPopup', reducer });
  useInjectSaga({ key: 'sendSmsPopup', saga });

  const [mobilePreviewIndex, setMobilePreviewIndex] = useState(0);
  const [phonePlatform, setPhonePlatform] = useState(PHONE_PLATFORM_TYPES.sms);

  const currentLocation = useSelector(makeSelectCurrentLocation());
  const currentJob = useSelector(makeSelectJob());

  const timeZone = { timeZone: currentJob && currentJob.time_zone };

  const [previewMessageOpenForMobile, setPreviewMessageOpenForMobile] =
    useState(false);

  const sendTypeOptions = generateSendTypeOptions(intl.formatMessage);
  const defaultTemplate = generateDefaultTemplate(intl.formatMessage);

  const { pathname } = useLocation();
  const isMasterApplicantsView = pathname === `/${accountSlug}/applicants`;
  const whoami = useSelector(makeSelectWhoami());
  const canSendOnlyTemplatesInSms = whoami.can_send_only_templates_in_sms;

  // This LD flag is to diregard applicant phone platform preference.
  // Recruiters can select the messaging channel(SMS/WhatsApp) and send message.
  // This is specifically built for APAC customers
  const messageApplicantFromAnyChannel =
    whoami.feature_flags['message-applicant-from-any-channel'];

  const isWhatsappEnabled =
    (isMasterApplicantsView && whoami.whats_app_enabled) ||
    (currentJob && currentJob.whatsapp_enabled);

  const defaultValues = {
    smsSelectedTemplate: defaultTemplate,
    whatsappSelectedTemplate: {},
    smsText: '',
    whatsappText: '',
    whatsappEnabled: isWhatsappEnabled,
    sendType: sendTypeOptions[0],
    startDate: new Date(),
    sendAt: null,
    delaySendingMessage: false,
  };

  const {
    fetchMessageTemplates,
    isFetching: isLoadingTemplates,
    error: fetchTemplateError,
    templates,
  } = useFetchMessageTemplates();

  useEffect(() => {
    if (isWhatsappEnabled)
      fetchMessageTemplates(
        MessageTemplatesType.GENERAL,
        TemplateMenuVariant.WHATSAPP,
        isMasterApplicantsView ? '' : selectedApplicant.info.id,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWhatsappEnabled]);

  const { createTemporaryMessageTemplate, handleBulkMessage, isSubmitting } =
    useBulkMessageApplicants({ messageType: 'sms' });

  const handleDeliverSms = async () => {
    let smsTemplateId =
      smsSelectedTemplate.id !== defaultTemplate.id && // confirms template is selected
      smsSelectedTemplate.sms_text === smsText && // confirms template is unmodified
      smsSelectedTemplate.id;

    // no free text is allowed for whatsapp so template should be present
    const whatsappTemplateId = whatsappSelectedTemplate?.id;

    if (!smsTemplateId && Boolean(smsText)) {
      smsTemplateId = await createTemporaryMessageTemplate({
        sms_enabled: Boolean(smsText),
        sms_text: smsText,
      });
    }

    await handleBulkMessage({
      applicantIds: (!selectAll && applicantIds) || undefined,
      smsTemplateId: smsTemplateId || undefined,
      whatsappTemplateId,
      search: selectAll && searchQuery,
      sendStart: delaySendingMessage ? sendAt.value : undefined,
    });

    handleClose();
  };

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    isSubmitting: isSubmittingForm,
  } = useForm(handleDeliverSms, validate, defaultValues);

  const debouncedHandleSubmit = debounce(handleSubmit, 1000);

  const {
    smsSelectedTemplate,
    whatsappSelectedTemplate,
    smsText,
    whatsappText,
    sendType,
    startDate,
    sendAt,
    delaySendingMessage,
  } = values;

  // Submit form if cmd + enter keyed
  useEffect(() => {
    if ((cmd || control) && enter) {
      debouncedHandleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmd, control, enter, handleSubmit]);

  useEffect(() => {
    if (currentJob) {
      handleChange({
        startDate: minDate(timeZone),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentJob]);

  const handleSelectTemplate = template => {
    handleChange({
      smsSelectedTemplate: template,
      smsText: template && template.sms_text,
    });
    setMobilePreviewIndex(phonePlatformTabs.SMS);
  };

  const handleSelectWhatsappTemplate = template => {
    handleChange({
      whatsappSelectedTemplate: template,
      whatsappText: template ? template.whats_app_text : '',
    });
    setMobilePreviewIndex(phonePlatformTabs.WHATSAPP);
  };

  const insertSmsMergeKey = key => {
    const wrappedKey = `[${key}]`;
    const newSmsText = smsText ? smsText.concat(wrappedKey) : wrappedKey;
    handleChange({
      smsText: newSmsText,
    });
  };

  const handleEscCloseDrawer = () => {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        intl.formatMessage(globalMessages.closeConfirmationMessage),
      )
    ) {
      return handleClose();
    }

    return null;
  };

  const handleSelectDelaySendingMessage = () => {
    const sendNowOrLater =
      sendType.value === SEND_TYPE.NOW
        ? sendTypeOptions[1]
        : sendTypeOptions[0];
    handleChange({
      sendType: sendNowOrLater,
      sendAt: null,
      delaySendingMessage: !delaySendingMessage,
    });
  };

  const twilioNumber = currentLocation?.twilio_number?.number;

  // A bit of a bandaid to cover the most-used scenarios
  const headerMessageCount = () => {
    if (selectAll && !applicantsCount) {
      return isWhatsappEnabled ? (
        <FormattedMessage {...messages.sendBulkMessageslSelectAllDefault} />
      ) : (
        <FormattedMessage {...messages.sendBulkSmslSelectAllDefault} />
      );
    }

    const message = isWhatsappEnabled
      ? messages.sendBulkMessages
      : messages.sendBulkSms;

    const count =
      selectAll && applicantsCount ? applicantsCount : applicantIds.length;

    return <FormattedMessage {...message} values={{ applicantCount: count }} />;
  };

  const handleChannelChange = e => {
    const { value } = e.target;
    setPhonePlatform(value);
    if (value === PHONE_PLATFORM_TYPES.sms) {
      handleSelectWhatsappTemplate({});
      setMobilePreviewIndex(phonePlatformTabs.SMS);
    } else {
      handleSelectTemplate({});
      setMobilePreviewIndex(phonePlatformTabs.WHATSAPP);
    }
  };

  const isSmsChannelSelected =
    !messageApplicantFromAnyChannel ||
    (messageApplicantFromAnyChannel &&
      phonePlatform === PHONE_PLATFORM_TYPES.sms);
  const isWhatsAppChannnelSelected =
    !messageApplicantFromAnyChannel ||
    (messageApplicantFromAnyChannel &&
      phonePlatform === PHONE_PLATFORM_TYPES.whats_app);

  return (
    <DrawerPopup
      paperClassName={classes.smsDrawerContainer}
      onEscapeKeyDown={handleEscCloseDrawer}
      disableEscapeKeyDown
      open={open}
      handleClose={handleClose}
      anchor="right"
    >
      <Grid container direction="row" className={classes.sendSmsDrawerContent}>
        <Grid
          item
          container
          direction="column"
          wrap="nowrap"
          className={classNames(classes.sendSmsForm, {
            messagePreviewOpenForMobileView: previewMessageOpenForMobile,
          })}
        >
          <DrawerHeader className={classes.header}>
            <Grid
              container
              alignItems="center"
              direction="row"
              wrap="nowrap"
              className={classes.headerContent}
            >
              <DrawerHeaderIcon>
                <img src={ChatIcon} alt="Preview Message" />
              </DrawerHeaderIcon>
              <Typography variant="h3">
                {delaySendingMessage ? (
                  isWhatsappEnabled ? (
                    <FormattedMessage {...messages.scheduleMessages} />
                  ) : (
                    <FormattedMessage {...globalMessages.scheduleSms} />
                  )
                ) : (
                  headerMessageCount()
                )}
              </Typography>
              <StyledIconButton
                className={classes.messagePreviewIcon}
                onClick={() =>
                  setPreviewMessageOpenForMobile(!previewMessageOpenForMobile)
                }
                role="presentation"
              >
                <img src={PreviewIcon} alt="preview-icon" />
              </StyledIconButton>
            </Grid>
          </DrawerHeader>
          <DrawerContent>
            {messageApplicantFromAnyChannel && isWhatsappEnabled && (
              <BulkChannelSwitch
                phonePlatform={phonePlatform}
                handleChannelChange={handleChannelChange}
              />
            )}

            {!isWhatsappEnabled && (
              <div className={classes.section}>
                <Typography variant="body2" className={classes.label}>
                  <FormattedMessage {...globalMessages.sendFrom} />{' '}
                  <span className={classes.phoneNumber}>
                    {twilioNumber && formatPhoneNumber(twilioNumber)}
                  </span>
                </Typography>
              </div>
            )}
            <div className={!isWhatsappEnabled && classes.section}>
              {isWhatsappEnabled && isSmsChannelSelected && (
                <Typography variant="h3" className={classes.label}>
                  <span>
                    <FormattedMessage {...messages.smsMessage} />
                  </span>
                </Typography>
              )}
              {isSmsChannelSelected && (
                <MessageTemplateTextEditor
                  enableMessageTemplates
                  handleMergeKeySelect={insertSmsMergeKey}
                  errorText={errors.smsText}
                  hasError={!!errors.smsText}
                  enableMergeKeys={!canSendOnlyTemplatesInSms}
                  onChange={event => handleChange({ smsText: event })}
                  value={smsText}
                  required
                  handleSelectTemplate={handleSelectTemplate}
                  placeholder={intl.formatMessage(messages.enterYourMessage)}
                  isSms
                  disabled={canSendOnlyTemplatesInSms}
                />
              )}
              {isWhatsappEnabled && isWhatsAppChannnelSelected && (
                <>
                  {!messageApplicantFromAnyChannel && (
                    <Divider className={classes.divideMargin} />
                  )}
                  <Typography variant="h3" className={classes.label}>
                    <span>
                      <FormattedMessage {...messages.whatsappMessage} />
                    </span>
                  </Typography>

                  <StyledReactSelect
                    placeholder={intl.formatMessage(
                      messages.selectWhatsappTemplate,
                    )}
                    noOptionsMessage={() => (
                      <FormattedMessage {...messages.noTemplateAvailable} />
                    )}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    options={templates}
                    value={
                      Object.keys(whatsappSelectedTemplate).length > 0
                        ? whatsappSelectedTemplate
                        : ''
                    }
                    onChange={handleSelectWhatsappTemplate}
                    error={
                      (!!errors.whatsappText && errors.whatsappText) ||
                      !!fetchTemplateError
                    }
                    isLoading={isLoadingTemplates}
                    className={classes.templateDropdown}
                  />
                </>
              )}
            </div>
            {!fromMav && (
              <Grid container alignItems="center">
                <div className={classes.checkboxContainer}>
                  <Checkbox
                    color="primary"
                    checked={delaySendingMessage}
                    onChange={handleSelectDelaySendingMessage}
                    className={classes.noPadding}
                  />
                </div>
                <div>
                  <FormattedMessage {...messages.delaySendingMessage} />
                </div>
              </Grid>
            )}
            {delaySendingMessage && !fromMav && (
              <div className={classes.section}>
                <DelayMessageOptions
                  handleChange={handleChange}
                  sendType={sendType}
                  startDate={startDate}
                  timeZone={timeZone}
                  sendAt={sendAt}
                />
              </div>
            )}
            {isSmsChannelSelected && (
              <div className={classes.smsInfo}>
                <FormattedMessage {...messages.smsInfo} />
              </div>
            )}
          </DrawerContent>
          <DrawerFooter>
            <Button
              className={classes.cancelButton}
              type="secondary"
              onClick={handleClose}
            >
              <FormattedMessage {...globalMessages.cancel} />
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={isSubmitting || isSubmittingForm}
              isLoading={isSubmitting}
              data-component="req-bulktxtmodal-send"
              data-testid="send-sms-popup"
            >
              {delaySendingMessage ? (
                <FormattedMessage {...globalMessages.schedule} />
              ) : (
                <FormattedMessage {...globalMessages.send} />
              )}
            </Button>
          </DrawerFooter>
        </Grid>

        <Grid
          item
          container
          direction="column"
          wrap="nowrap"
          className={classNames(classes.messagePreviewContainer, {
            messagePreviewOpenForMobileView: previewMessageOpenForMobile,
          })}
        >
          <MessagePreviewDeviceView
            isWhatsappEnabled={isWhatsappEnabled}
            setPreviewMessageOpenForMobile={setPreviewMessageOpenForMobile}
            previewMessageOpenForMobile={previewMessageOpenForMobile}
            handleClose={handleClose}
            setMobilePreviewIndex={setMobilePreviewIndex}
            mobilePreviewIndex={mobilePreviewIndex}
            smsText={smsText}
            whatsappText={whatsappText}
            twilioNumber={twilioNumber}
            messageApplicantFromAnyChannel={messageApplicantFromAnyChannel}
          />
        </Grid>
      </Grid>
    </DrawerPopup>
  );
}

BulkSendSmsPopup.propTypes = {
  accountSlug: PropTypes.string.isRequired,
  applicantIds: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired, // HI-862 BulkSelect Support Needed
  fromMav: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  searchQuery: PropTypes.string,
  selectedApplicant: PropTypes.object.isRequired,
  applicantsCount: PropTypes.number,
};

const BulkSendSmsPopupWrapper = ({ handleClose, intl, location, open }) => {
  const { accountSlug } = useParams();
  const { popupType, query } = queryString.parse(location.search);
  const dispatch = useDispatch();

  const {
    error,
    isFetching,
    selectedApplicant,
    selectedApplicantIds,
    isMasterApplicantsView,
    totalSelectedApplicantCount,
  } = useTargetStageId({});

  useEffect(() => {
    if (error) {
      dispatch(addDefaultErrorMessageAction());
      handleClose();
    }
  }, [dispatch, error, handleClose]);

  if (isFetching && !error && !selectedApplicant) return <Loader fullScreen />;

  if (popupType !== POPUP_TYPES.SEND_BULK_SMS_POPUP || !selectedApplicant)
    return null;

  return (
    <BulkSendSmsPopup
      accountSlug={accountSlug}
      applicantIds={selectedApplicantIds}
      fromMav={isMasterApplicantsView}
      handleClose={handleClose}
      intl={intl}
      open={open}
      searchQuery={query}
      selectedApplicant={selectedApplicant}
      applicantsCount={totalSelectedApplicantCount}
    />
  );
};

BulkSendSmsPopupWrapper.propTypes = {
  handleClose: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
};

export default compose(
  withRouter,
  injectIntl,
  withBulkActionConfirmation,
)(BulkSendSmsPopupWrapper);
