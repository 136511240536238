import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  noSlotsAvailable: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.noSlotsAvailable`,
    defaultMessage:
      'No slots remaining. Set more slots for applicants to book.',
  },
  slotsAvailable: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.slotsAvailable`,
    defaultMessage: 'SLOTS AVAILABLE',
  },
  setTimeSlotDescription: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.setTimeSlotDescription`,
    defaultMessage: 'Set at least 20 time slots for applicants to select.',
  },
  setAvailability: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.setAvailability`,
    defaultMessage: 'Set Availability',
  },
  scheduler: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.scheduler`,
    defaultMessage: 'Scheduler',
  },
  advancedSettings: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.advancedSettings`,
    defaultMessage: 'Advanced Settings',
  },
});
