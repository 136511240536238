import { createSelector } from 'reselect';

import { initialState } from './reducer';
import { State } from './types';

/**
 * Direct selector to the validation group state
 */

const selectDataValidationDomain = (state: State) =>
  // @ts-expect-error Unclear why we're accessing this bit of state
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  state.dataValidation || initialState;

/**
 * Default selector used by DataValidationModal
 */

const makeSelectValidationGroup = () =>
  createSelector(
    selectDataValidationDomain,
    (state: State) => state.validationGroup,
  );

export { makeSelectValidationGroup };
