import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectJobSearchDomain = state => state.jobSearch || initialState;

const makeSelectLocations = () =>
  createSelector(selectJobSearchDomain, substate => substate.locations.data);

const makeSelectLocationsError = () =>
  createSelector(selectJobSearchDomain, substate => substate.locations.error);

const makeSelectLocationsLoading = () =>
  createSelector(selectJobSearchDomain, substate => substate.locations.loading);

const makeSelectJobSearch = () =>
  createSelector(selectJobSearchDomain, substate => substate);

export default makeSelectJobSearch;
export {
  makeSelectLocations,
  makeSelectLocationsError,
  makeSelectLocationsLoading,
};
