import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  ignoreIntentBtn: {
    flex: 1,
    marginRight: theme.spacing(0.5),
  },
  intentAnswer: {
    flex: 1,
    marginLeft: theme.spacing(0.5),
  },
}));
