import { Button } from '@fountain/fountain-ui-components';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChatbotService } from 'api-clients/monolith';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { BackToCompanySettings } from 'components/BackToCompanySettings';
import { addMessageAction } from 'containers/FlashMessage/actions';

import { FountainAIIcon } from './images/FountainAIIcon';
import { messages } from './messages';

const useStyles = makeStyles(theme => ({
  headerContainer: {
    padding: theme.spacing(2, 0),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 0),
  },
  headerText: {
    lineHeight: '40px',
    fontWeight: 500,
  },
  icon: {
    height: '52px',
    width: '52px',
    marginRight: theme.spacing(2.5),
  },
  description: {
    color: theme.palette.common.gray800,
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 450,
    width: '75%',
  },
  button: {
    border: '1px solid #FFFFFF',
    filter: 'drop-shadow(0px 1px 2px rgba(19, 30, 64, 0.08))',
    width: 'fit-content',
    height: 40,
    borderRadius: 8,
    marginTop: theme.spacing(1.5),
  },
}));

export const Header = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleContactSalesClick = async () => {
    try {
      await ChatbotService.postInternalApiChatbotContactSales();
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.contactSalesSuccess),
          'success',
        ),
      );
    } catch (error) {
      // TODO; Log error in Datadog
    }
  };

  return (
    <section className={styles.headerContainer}>
      <BackToCompanySettings />
      <Box className={styles.header}>
        <FountainAIIcon className={styles.icon} />
        <Typography display="inline" variant="h1" className={styles.headerText}>
          Fountain AI
        </Typography>
      </Box>
      <Typography variant="body1" className={styles.description}>
        <FormattedMessage {...messages.headerDescription} />
        <Button
          type="primary"
          size="small"
          className={styles.button}
          onClick={handleContactSalesClick}
        >
          <FormattedMessage {...messages.getAccessNow} />
        </Button>
      </Typography>
    </section>
  );
};
