import { CustomTooltip } from '@fountain/fountain-ui-components';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import { ApprovalObj } from 'api-clients/monolith';
import React, { VFC } from 'react';
import { classNames } from 'react-extras';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'table-cell',
  },
  icon: {
    height: '14px',
    width: '14px',
    color: theme.palette.common.gray500,
    verticalAlign: 'middle',
  },
  toolTip: {
    marginBottom: theme.spacing(2),
  },
  approvedIcon: {
    color: theme.palette.common.green400,
  },
  rejectedIcon: {
    color: theme.palette.common.red400,
  },
  statusText: {
    marginLeft: theme.spacing(1.25),
    verticalAlign: 'middle',
  },
}));

export interface StatusProps {
  approval: ApprovalObj;
}

export const Status: VFC<StatusProps> = ({ approval }) => {
  const styles = useStyles();

  return (
    <>
      <div>
        <CustomTooltip
          disableFocusListener
          enterTouchDelay={100}
          title={approval.approved_by.join('\n')}
          className={styles.toolTip}
          dense
          placement="top"
        >
          <div className={styles.wrapper}>
            <CheckCircleRoundedIcon
              className={classNames(styles.approvedIcon, styles.icon)}
            />
            <Typography variant="caption" className={styles.statusText}>
              {approval.approved_count}
            </Typography>
          </div>
        </CustomTooltip>
      </div>

      <div>
        <CustomTooltip
          disableFocusListener
          enterTouchDelay={100}
          title={approval.pending_by.join('\n')}
          dense
        >
          <div className={styles.wrapper}>
            <RemoveCircleRoundedIcon className={styles.icon} />
            <Typography variant="caption" className={styles.statusText}>
              {approval.pending_count}
            </Typography>
          </div>
        </CustomTooltip>
      </div>

      <div>
        <CustomTooltip
          disableFocusListener
          enterTouchDelay={100}
          title={approval.rejected_by.join('\n')}
          dense
        >
          <div className={styles.wrapper}>
            <CancelRoundedIcon
              className={classNames(styles.rejectedIcon, styles.icon)}
            />
            <Typography variant="caption" className={styles.statusText}>
              {approval.rejected_count}
            </Typography>
          </div>
        </CustomTooltip>
      </div>
    </>
  );
};
