import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import { setFileRecollectDataAction } from 'containers/FileRecollectionDrawer/actions';
import { addDefaultErrorMessageAction } from 'containers/FlashMessage/actions';
import useUserPermissions from 'hooks/useUserPermissions';
import { apiGet } from 'utils/axios';

export default function useApplicantFileRecollection(applicantId, accountSlug) {
  const { manageApplicantsPermission } = useUserPermissions();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchData = useCallback(async () => {
    const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/file_upload_requests/new?account_slug=${accountSlug}&external_id=${applicantId}`;
    setIsLoading(true);
    try {
      const res = await apiGet(url);
      dispatch(setFileRecollectDataAction(res.data));
      setError('');
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicantId]);

  useEffect(() => {
    if (applicantId && manageApplicantsPermission) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  useEffect(() => {
    if (error) {
      dispatch(addDefaultErrorMessageAction());
    }
  }, [dispatch, error]);

  return { isLoading };
}
