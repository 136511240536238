import './styles.css';

import { Button, CalendarSync } from '@fountain/fountain-ui-components';
import { List, ListItem, Typography } from '@material-ui/core';
import React, { VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from './messages';
import { useStyles } from './styles';

interface SyncCalendarSideboxProps {
  clickHandler?: () => void;
}

export const SyncCalendarSidebox: VFC<SyncCalendarSideboxProps> = ({
  clickHandler,
}) => {
  const styles = useStyles();

  return (
    <div className={styles.sideboxWrapper}>
      <CalendarSync className={styles.leftCalendarImage} />
      <div>
        <Typography className={styles.bottomMargin} variant="h4">
          <FormattedMessage {...messages.calendarSyncSideboxTitle} />
        </Typography>
        <Typography variant="body2">
          <FormattedMessage {...messages.calendarSyncSideboxDescription} />
        </Typography>
        <List>
          <ListItem className={styles.calendarSyncBullets}>
            <Typography variant="body2">
              <FormattedMessage {...messages.calendarIntegrationBullet1} />
            </Typography>
          </ListItem>
          <ListItem className={styles.calendarSyncBullets}>
            <Typography variant="body2">
              <FormattedMessage {...messages.calendarIntegrationBullet2} />
            </Typography>
          </ListItem>
        </List>
        <Button
          type="primary"
          className={styles.startButton}
          onClick={clickHandler}
        >
          <Typography variant="h4">
            <FormattedMessage {...messages.calendarIntegrationButton} />
          </Typography>
        </Button>
      </div>
    </div>
  );
};
