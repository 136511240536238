import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import {
  resetApplicantUnreadMessages,
  setApplicantMessagesAction,
  setApplicantMessagingThreadAction,
} from 'containers/ApplicantTableV2/actions';
import { makeSelectApplicantMessages } from 'containers/ApplicantTableV2/selectors';
import { addDefaultErrorMessageAction } from 'containers/FlashMessage/actions';
import { apiGet, apiPatch } from 'utils/axios';

export default function useApplicantMessages(applicantId) {
  const dispatch = useDispatch();
  const inStoreMessages = useSelector(makeSelectApplicantMessages(applicantId));

  const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/messages`;
  const markAsReadUrl = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/messages/mark_as_read`;

  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState('');

  const fetchData = async () => {
    setIsFetching(true);
    try {
      const res = await apiGet(url);
      dispatch(setApplicantMessagesAction(applicantId, res.data.messages));
      dispatch(
        setApplicantMessagingThreadAction(
          applicantId,
          res.data.messaging_thread,
        ),
      );
      if (
        res.data.messaging_thread &&
        res.data.messaging_thread.unread_count > 0
      ) {
        await apiPatch(markAsReadUrl);
        dispatch(resetApplicantUnreadMessages(applicantId));
      }
      setError('');
    } catch (e) {
      setError(e);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    // Applicant Messages part has pusher setup.
    // no need to refetch unless messages store is empty
    if (
      (applicantId && inStoreMessages === undefined) ||
      inStoreMessages.length === 0
    )
      fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  useEffect(() => {
    if (error) {
      dispatch(addDefaultErrorMessageAction());
    }
  }, [dispatch, error]);

  return { isFetching };
}
