import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  topMenu: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '& p': {
      color: theme.palette.common.gray800,
    },
    '& span': {
      color: theme.palette.common.gray800,
    },
    height: '100%',
    '&:hover': {
      background: 'none',
      '& img': {
        filter: `invert(49%) sepia(79%) saturate(5627%) hue-rotate(196deg) brightness(100%) contrast(109%)`,
      },
      '& span': {
        color: theme.palette.common.blue400,
      },
      '& p': {
        color: theme.palette.common.blue400,
      },
    },
  },
  active: {
    borderBottom: `${theme.spacing(0.5)}px solid ${
      theme.palette.common.blue400
    }`,
    borderTop: `${theme.spacing(0.5)}px solid transparent`,
    '& p': {
      color: theme.palette.common.gray800,
      fontFamily: theme.typography.h4.fontFamily,
      fontWeight: 500,
    },
    '& span': {
      color: theme.palette.common.gray800,
      fontFamily: theme.typography.h4.fontFamily,
      fontWeight: 500,
    },
  },
  menuItem: null,
  arrowIcon: {
    marginLeft: theme.spacing(1),
    transform: props => props.isOpen && 'rotate(180deg)',
    transition: 'transform 250ms linear',
  },
  leftSectionTrigger: {
    color: theme.palette.common.gray800,
    '&:hover': {
      color: theme.palette.common.blue400,
    },
    '&$menuItem': {
      marginRight: theme.spacing(0.5),
    },
  },
  menuList: {
    '&.MuiMenu-list': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  root: {
    '&$selected': {
      '& span': {
        color: theme.palette.common.blue400,
      },
      '&:hover': {
        backgroundColor: theme.palette.common.blue100,
      },
      '& p': {
        color: theme.palette.common.blue400,
      },
      backgroundColor: theme.palette.common.blue100,
      borderRadius: '5px',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.gray100,
    },
  },
}));

export default useStyles;
