/**
 *
 * Material UI styles for MobileEmailPreview
 *
 *
 * @see https://material-ui.com/styles/basics
 *
 */

const styles = theme => ({
  messageHeader: {
    borderBottom: theme.customBorders.gray200,
    padding: theme.spacing(2),
  },
  fromEmail: {
    marginBottom: theme.spacing(1),
  },
  emailAddressHeader: {
    fontSize: 10,
    textTransform: 'capitalize',
  },
  emailAddress: {
    fontSize: 10,
  },
  messageContent: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2, 2, 0, 2),
  },
  messageSubject: {
    fontSize: 12,
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  messageBody: {
    fontSize: 10,
    color: theme.palette.common.gray600,
    '& > iframe': {
      width: '100%',
      minHeight: 270,
      border: 'none',
    },
  },
});

export default styles;
