import React, { VFC } from 'react';

import { HistoryAddressItem, HistoryItem } from './HistoryAddressItem';
import { HistoryEmploymentItem } from './HistoryEmploymentItem';

interface HistoryContentProps {
  historyItems: HistoryItem[];
  historyType: string;
}

export const HistoryContent: VFC<HistoryContentProps> = ({
  historyItems,
  historyType,
}) => {
  const monthList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const formattedMonth = (value: number) => {
    if (value === 0) return 'Current';
    return monthList[value];
  };

  const formattedYear = (year: number) => {
    if (year === 0) return '';
    return year;
  };

  const formattedTimeRange = (details: HistoryItem) => {
    const startMonthNumber = parseInt(details.startMonth as string, 10);
    const endMonthNumber = parseInt(details.endMonth as string, 10);
    const startYear = parseInt(details.startYear as string, 10);
    const endYearNumber = parseInt(details.endYear as string, 10);

    return (
      <>
        {formattedMonth(startMonthNumber)} {startYear} -{' '}
        {formattedMonth(endMonthNumber)} {formattedYear(endYearNumber)}
      </>
    );
  };

  /* eslint-disable react/no-array-index-key */
  const renderHistoryItem = (item: HistoryItem, index: number) => {
    switch (historyType) {
      case 'employment':
        return (
          <HistoryEmploymentItem
            details={item}
            key={`history_employemnt_item_${index}`}
          />
        );
      case 'address':
        return (
          <HistoryAddressItem
            details={item}
            key={`history_address_item_${index}`}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <ul>
      {historyItems.map((item: HistoryItem, index: number) => (
        <li key={`history_item_${index}`}>
          <b>{formattedTimeRange(item)}</b> <br />
          {item.noHistory ? (
            <span>No History</span>
          ) : (
            renderHistoryItem(item, index)
          )}
        </li>
      ))}
    </ul>
  );
  /* eslint-enable react/no-array-index-key */
};
