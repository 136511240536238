import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  careerSiteSettings: {
    id: 'app.containers.ChatbotWidget.careerSiteSettings',
    defaultMessage: 'Career Site Settings',
  },
  chatbotPurpose: {
    id: 'app.containers.ChatbotWidget.chatbotPurpose',
    defaultMessage:
      'Select a purpose for chatbot: Demo or Customer Implementaion',
  },
  chatbotPurposeDesc: {
    id: 'app.containers.ChatbotWidget.chatbotPurposeDesc',
    defaultMessage: 'Please type in a jobs page career site URL.',
  },
  noOfJobsError: {
    id: 'app.containers.ChatbotWidget.noOfJobsError',
    defaultMessage: 'Should be a valid number',
  },
  jobsTrainingSourceTitle: {
    id: 'app.containers.ChatbotWidget.jobsTrainingSourceTitle',
    defaultMessage: 'Chatbot Jobs Training Source',
  },
  checkForAutoScrape: {
    id: 'app.containers.ChatbotWidget.checkForAutoScrape',
    defaultMessage: 'Check for Auto-Scrape',
  },
  jobsTrainingSourceDesc: {
    id: 'app.containers.ChatbotWidget.jobsTrainingSourceDesc',
    defaultMessage: 'please type in jobs page career site URL.',
  },
  saveAndScrape: {
    id: 'app.containers.ChatbotWidget.saveAndScrape',
    defaultMessage: 'Save and scrape',
  },
  helperText: {
    id: 'app.containers.ChatbotWidget.helperText',
    defaultMessage: 'Needs a custom parser - please reach out to Gig Team.',
  },
  editCareerSiteSettings: {
    id: 'app.containers.ChatbotWidget.editCareerSiteSettings',
    defaultMessage: 'Edit',
  },
  trainingSourceError: {
    id: 'app.containers.ChatbotWidget.trainingSourceError',
    defaultMessage: 'Please provide a valid url',
  },
  careerSiteName: {
    id: 'app.containers.ChatbotWidget.careerSiteNameDesc',
    defaultMessage: 'Name',
  },
  careerSiteNumberOfJobs: {
    id: 'app.containers.ChatbotWidget.careerSiteNumberOfJobs',
    defaultMessage: 'Number of Jobs',
  },
  careerSiteNumberOfJobsDesc: {
    id: 'app.containers.ChatbotWidget.careerSiteNumberOfJobsDesc',
    defaultMessage:
      'For demo purposes, please limit the number of jobs to a minimal amount for fast scraping (less than 200). For customer implementation, please type the total amount of jobs.',
  },
  fallbackTitle: {
    id: 'app.containers.ChatbotWidget.fallbackTitle',
    defaultMessage: 'Fallback response',
  },
  fallbackDesc: {
    id: 'app.containers.ChatbotWidget.fallbackDesc',
    defaultMessage:
      "Provide contact details for an applicant to reach out to for further assistance if the chatbot can't answer the applicant's question.",
  },
  email: {
    id: 'app.containers.ChatbotWidget.email',
    defaultMessage: 'Email',
  },
  emailError: {
    id: 'app.containers.ChatbotWidget.emailError',
    defaultMessage: 'Please provide a valid email',
  },
  phone: {
    id: 'app.containers.ChatbotWidget.phone',
    defaultMessage: 'Phone',
  },
  phoneError: {
    id: 'app.containers.ChatbotWidget.phoneError',
    defaultMessage: 'Please provide a valid phone number',
  },
  enableAxWidgetTitle: {
    id: 'app.containers.ChatbotWidget.enableAxWidgetTitle',
    defaultMessage: 'Enable FAQ chatbot in Applicant Portal',
  },
  enableAxWidgetDesc: {
    id: 'app.containers.ChatbotWidget.enableAxWidgetDesc',
    defaultMessage:
      'FAQ bot will be automatically embedded in the widget experience during the application process.',
  },
  enableFaqTitle: {
    id: 'app.containers.ChatbotWidget.enableFaqTitle',
    defaultMessage: 'Launch FAQ chatbot ',
  },
  enableFaqDesc: {
    id: 'app.containers.ChatbotWidget.enableFaqDesc',
    defaultMessage:
      'Ensure the FAQ chatbot settings including name, training source and fallback response have been correctly set up before launching FAQ chatbot.',
  },
  scrapingPendingStatus: {
    id: 'app.containers.ChatbotWidget.scrapingPendingStatus',
    defaultMessage: 'Scraping in Progress',
  },
  scrapingSuccessStatus: {
    id: 'app.containers.ChatbotWidget.scrapingSuccessStatus',
    defaultMessage: 'Ready to Launch',
  },
  scrapingErrorStatus: {
    id: 'app.containers.ChatbotWidget.scrapingErrorStatus',
    defaultMessage: 'Error',
  },
  fetchPendingStatusForCareerSite: {
    id: 'app.containers.ChatbotWidget.fetchPendingStatusForCareerSite',
    defaultMessage:
      'Scraping is still in progress. Please retry after sometime.',
  },
  fetchErrorStatus: {
    id: 'app.containers.ChatbotWidget.fetchErrorStatus',
    defaultMessage: 'Unable to retrieve status. Please try after sometime.',
  },
  tooltip: {
    id: 'app.containers.ChatbotWidget.tooltip',
    defaultMessage: 'Click to fetch status',
  },
});
