import { createSelector } from 'reselect';

import { InitialState, initialState } from './reducer';

/**
 * Direct selector to the language domain
 */

const selectLanguage = (state: { language?: InitialState }) =>
  state.language ?? initialState;

/**
 * Select the language locale
 */

const makeSelectLocale = () =>
  createSelector(selectLanguage, languageState => languageState.locale);

export { makeSelectLocale, selectLanguage };
