import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import {
  makeSelectEnforceBrandBasedPermissions,
  makeSelectPolicies,
  makeSelectWhoami,
} from 'containers/Auth_old/selectors';
import globalMessages from 'shared/global/messages';

import NavigationMenu from '../NavigationMenu';
import messages from './messages';

function JobsMenu({ intl }) {
  const { accountSlug } = useParams();
  const openingsPath = `/${accountSlug}/openings`;
  const bulkLocationsPath = `/${accountSlug}/bulk_locations`;
  const workflowsPath = `/${accountSlug}/workflows`;

  const policies = useSelector(makeSelectPolicies());
  const {
    view_jobs_directory: viewJobsDirectory,
    manage_workflows: manageWorkflows,
    view_workflows: viewWorkflows,
    manage_funnels: manageFunnels,
    view_funnels: viewFunnels,
  } = policies;
  const enforceBrandBasedPermissions = useSelector(
    makeSelectEnforceBrandBasedPermissions(),
  );

  const {
    account_has_workflows: accountHasWorkflows,
    bulk_locations_beta: userAndAccountHasBulkLocationsBeta,
    fountain_ai_career_site_enabled: careerSiteEnabled,
  } = useSelector(makeSelectWhoami());

  const getJobsDirectoryUrl = () => {
    if (!careerSiteEnabled)
      return `${REACT_APP_MONOLITH_BASE_URL}/c/${accountSlug}/picker`;

    const { hostname } = window.location;

    if (hostname === 'localhost') {
      return `http://localhost:5666/${accountSlug}`;
    }

    return `https://careers.${hostname.slice(4)}/${accountSlug}`;
  };

  const menuItems = {
    label: intl.formatMessage(globalMessages.jobs),
    children: [
      {
        viewable: manageFunnels || viewFunnels,
        to: openingsPath,
        isLink: true,
        label: intl.formatMessage(messages.openings),
        message: messages.openings,
        'data-testid': 'navbar-openings',
      },
      {
        viewable: manageFunnels || viewFunnels,
        href: `${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/locations#`,
        isExternalLink: true,
        label: intl.formatMessage(globalMessages.locations),
        message: globalMessages.locations,
        'data-testid': 'navbar-locations',
      },
      {
        viewable: userAndAccountHasBulkLocationsBeta,
        to: bulkLocationsPath,
        isLink: true,
        label: intl.formatMessage(messages.bulkLocations),
        message: messages.bulkLocations,
        'data-testid': 'navbar-bulk_locations',
      },
      {
        viewable: manageFunnels || viewFunnels,
        href: `${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/positions`,
        isExternalLink: true,
        label: intl.formatMessage(messages.positions),
        message: messages.positions,
        'data-testid': 'navbar-positions',
      },
      {
        viewable: (viewWorkflows || manageWorkflows) && accountHasWorkflows,
        to: workflowsPath,
        isLink: true,
        label: intl.formatMessage(messages.workflows),
        message: messages.workflows,
        'data-testid': 'navbar-workflows',
      },
      {
        viewable: viewJobsDirectory && !enforceBrandBasedPermissions,
        href: getJobsDirectoryUrl(),
        target: '_blank',
        isExternalLink: true,
        label: intl.formatMessage(messages.jobsDirectory),
        message: messages.jobsDirectory,
        'data-testid': 'navbar-jobs_directory',
      },
    ],
  };

  const triggerElContents = <FormattedMessage {...globalMessages.jobs} />;

  return (
    <NavigationMenu
      menuItems={menuItems}
      id="jobs-menu-trigger"
      triggerElContents={triggerElContents}
      dataTestIds={{
        trigger: 'navbar-jobs',
        popover: 'navbar-jobs-link-container',
      }}
    />
  );
}

JobsMenu.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(JobsMenu);
