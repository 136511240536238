import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  rulesContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
    borderTop: theme.customBorders.gray300,
  },
  addDocumentSetButton: {
    marginTop: theme.spacing(4),
  },
  noDocumentSetsAddButton: {
    marginTop: theme.spacing(2),
  },
}));
