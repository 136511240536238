import { Caret, Input, TextButton } from '@fountain/fountain-ui-components';
import {
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  Typography,
} from '@material-ui/core';
import {
  WorkflowSchedulingStage,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import produce from 'immer';
import React, { FC, useContext } from 'react';
import { classNames } from 'react-extras';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { Error } from 'components/Error';
import { makeSelectWhoami } from 'containers/Auth_old/selectors';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';
import useSimpleToggle from 'hooks/useSimpleToggle';

import { useStyles } from '../styles';
import {
  LIMIT_CANCEL_TIME,
  LIMIT_CANCEL_TIME_HOURS,
  LIMIT_RESCHEDULE,
  LIMIT_RESCHEDULE_COUNT,
  PREVENT_BOOKING_AFTER,
  PREVENT_BOOKING_AFTER_DAYS,
  PREVENT_BOOKING_FOR,
  PREVENT_BOOKING_FOR_HOURS,
} from './constants';
import { messages } from './messages';

const CustomInputLabel: React.FC = ({ children }) => {
  const styles = useStyles();
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      className={styles.nestedInputLabel}
    >
      {children}
    </Typography>
  );
};

export interface AdvancedBookingOptionsProps {
  stage: WorkflowSchedulingStage;
  setStage: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
}

export const AdvancedBookingOptions: FC<AdvancedBookingOptionsProps> = ({
  stage,
  setStage,
}) => {
  const styles = useStyles();
  const { instant_interview_enabled: isInstantInterviewEnabled } = useSelector(
    makeSelectWhoami(),
  );

  const { updateStageResult } = useContext(StageContext);
  const { additional_info: additionalInfo } = stage;

  const hasPreventBookingAfterDaysError =
    updateStageResult.isError &&
    updateStageResult.error?.errors?.prevent_booking_after_days !== undefined;
  const hasPreventBookingForHoursError =
    updateStageResult.isError &&
    updateStageResult.error?.errors?.prevent_booking_for_hours !== undefined;
  const hasLimitRescheduleCountError =
    updateStageResult.isError &&
    updateStageResult.error?.errors?.limit_reschedule_count !== undefined;
  const hasLimitCancelTimeHoursError =
    updateStageResult.isError &&
    updateStageResult.error?.errors?.limit_cancel_time_hours !== undefined;

  const shouldStartExpanded =
    Boolean(additionalInfo.prevent_booking_after) ||
    Boolean(additionalInfo.prevent_booking_for) ||
    Boolean(additionalInfo.limit_reschedule) ||
    Boolean(additionalInfo.limit_cancel_time);

  const {
    showContent: isAdvancedBookingOptionsOpen,
    toggle: toggleAdvancedBookingOptions,
  } = useSimpleToggle(shouldStartExpanded);

  const onToggleChange =
    (
      key:
        | typeof PREVENT_BOOKING_AFTER
        | typeof PREVENT_BOOKING_FOR
        | typeof LIMIT_RESCHEDULE
        | typeof LIMIT_CANCEL_TIME,
    ) =>
    () => {
      setStage(
        produce(stage, draftStage => {
          if (draftStage.additional_info) {
            draftStage.additional_info[key] = !draftStage.additional_info[key];
          }
        }),
      );
    };

  const onInputChange = (
    key:
      | typeof PREVENT_BOOKING_AFTER_DAYS
      | typeof PREVENT_BOOKING_FOR_HOURS
      | typeof LIMIT_RESCHEDULE_COUNT
      | typeof LIMIT_CANCEL_TIME_HOURS,
    e: { target: { value: string } },
  ) => {
    const { value } = e.target;

    setStage(
      produce(stage, draftStage => {
        if (draftStage.additional_info) {
          draftStage.additional_info[key] = parseInt(value, 10) || 0;
        }
      }),
    );
  };

  return (
    <Grid className={styles.cardContainer}>
      {isAdvancedBookingOptionsOpen ? (
        <TextButton
          color="inherit"
          className={classNames(
            styles.advancedBookingOptionsToggle,
            styles.advancedBookingOptionsSpacing,
          )}
          onClick={toggleAdvancedBookingOptions}
        >
          <Caret className={styles.caretIconOpened} />
          <Typography variant="h4">
            <FormattedMessage {...messages.advancedBookingOptions} />
          </Typography>
        </TextButton>
      ) : (
        <TextButton
          color="inherit"
          className={classNames(styles.advancedBookingOptionsToggle)}
          onClick={toggleAdvancedBookingOptions}
        >
          <Caret className={styles.caretIconClosed} />
          <Typography variant="h4">
            <FormattedMessage {...messages.advancedBookingOptions} />
          </Typography>
        </TextButton>
      )}
      {isAdvancedBookingOptionsOpen && (
        <Grid container className={styles.advancedBookingOptionsContent}>
          <Grid item xs={12} className={styles.fieldRow}>
            <FormControlLabel
              control={
                <Switch
                  data-testid={LIMIT_CANCEL_TIME}
                  checked={additionalInfo.prevent_booking_after}
                  onChange={onToggleChange(PREVENT_BOOKING_AFTER)}
                />
              }
              label={
                <Typography variant="body2" className={styles.switchLabel}>
                  <FormattedMessage {...messages.limitFutureSessions} />
                </Typography>
              }
            />
            <Grid
              className={styles.switchWithInputField}
              data-testid="prevent-booking-after-days-container"
            >
              <CustomInputLabel>
                <FormattedMessage {...messages.preventBookingAfterDays} />
              </CustomInputLabel>
              <Input
                data-testid={PREVENT_BOOKING_AFTER_DAYS}
                value={additionalInfo.prevent_booking_after_days ?? ''}
                className={styles.nestedInput}
                onChange={(e: { target: { value: string } }) =>
                  onInputChange(PREVENT_BOOKING_AFTER_DAYS, e)
                }
                error={hasPreventBookingAfterDaysError}
                endAdornment={
                  <InputAdornment position="end">
                    <Typography variant="body2">
                      <FormattedMessage {...messages.placeholderDays} />
                    </Typography>
                  </InputAdornment>
                }
              />
              <Error
                error={
                  hasPreventBookingAfterDaysError &&
                  updateStageResult.error?.errors?.prevent_booking_after_days[0]
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className={styles.fieldRow}>
            <FormControlLabel
              control={
                <Switch
                  data-testid={LIMIT_RESCHEDULE}
                  checked={additionalInfo.prevent_booking_for}
                  onChange={onToggleChange(PREVENT_BOOKING_FOR)}
                />
              }
              label={
                <Typography variant="body2" className={styles.switchLabel}>
                  <FormattedMessage {...messages.showOneSessionPerTimeframe} />
                </Typography>
              }
            />
            <Grid
              className={styles.switchWithInputField}
              data-testid="prevent-booking-for-hours-container"
            >
              <CustomInputLabel>
                <FormattedMessage {...messages.preventBookingForHours} />
              </CustomInputLabel>
              {isInstantInterviewEnabled && (
                <CustomInputLabel>
                  <FormattedMessage {...messages.instantInterviewWarning} />
                </CustomInputLabel>
              )}
              <Input
                data-testid={PREVENT_BOOKING_FOR_HOURS}
                value={additionalInfo.prevent_booking_for_hours ?? ''}
                className={styles.nestedInput}
                onChange={(e: { target: { value: string } }) =>
                  onInputChange(PREVENT_BOOKING_FOR_HOURS, e)
                }
                error={hasPreventBookingForHoursError}
                endAdornment={
                  <InputAdornment position="end">
                    <Typography variant="body2">
                      <FormattedMessage {...messages.placeholderHours} />
                    </Typography>
                  </InputAdornment>
                }
              />
              <Error
                error={
                  hasPreventBookingForHoursError &&
                  updateStageResult.error?.errors?.prevent_booking_for_hours[0]
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className={styles.fieldRow}>
            <FormControlLabel
              control={
                <Switch
                  data-testid={PREVENT_BOOKING_AFTER}
                  checked={additionalInfo.limit_reschedule}
                  onChange={onToggleChange(LIMIT_RESCHEDULE)}
                />
              }
              label={
                <Typography variant="body2" className={styles.switchLabel}>
                  <FormattedMessage {...messages.maxReschedules} />
                </Typography>
              }
            />
            <Grid
              className={styles.switchWithInputField}
              data-testid="limit-reschedule-count-container"
            >
              <CustomInputLabel>
                <FormattedMessage {...messages.limitRescheduleCount} />
              </CustomInputLabel>
              <Input
                data-testid={LIMIT_RESCHEDULE_COUNT}
                value={additionalInfo.limit_reschedule_count ?? ''}
                className={styles.nestedInput}
                onChange={(e: { target: { value: string } }) =>
                  onInputChange(LIMIT_RESCHEDULE_COUNT, e)
                }
                error={hasLimitRescheduleCountError}
                endAdornment={
                  <InputAdornment position="end">
                    <Typography variant="body2">
                      <FormattedMessage {...messages.placeholderTimes} />
                    </Typography>
                  </InputAdornment>
                }
              />
              <Error
                error={
                  hasLimitRescheduleCountError &&
                  updateStageResult.error?.errors?.limit_reschedule_count[0]
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className={styles.fieldRow}>
            <FormControlLabel
              control={
                <Switch
                  data-testid={PREVENT_BOOKING_FOR}
                  checked={additionalInfo.limit_cancel_time}
                  onChange={onToggleChange(LIMIT_CANCEL_TIME)}
                />
              }
              label={
                <Typography variant="body2" className={styles.switchLabel}>
                  <FormattedMessage {...messages.lastMinuteRescheduling} />
                </Typography>
              }
            />
            <Grid
              className={styles.switchWithInputField}
              data-testid="limit-cancel-time-hours-container"
            >
              <CustomInputLabel>
                <FormattedMessage {...messages.limitCancelTimeHours} />
              </CustomInputLabel>
              <Input
                data-testid={LIMIT_CANCEL_TIME_HOURS}
                value={additionalInfo.limit_cancel_time_hours ?? ''}
                className={styles.nestedInput}
                onChange={(e: { target: { value: string } }) =>
                  onInputChange(LIMIT_CANCEL_TIME_HOURS, e)
                }
                error={hasLimitCancelTimeHoursError}
                endAdornment={
                  <InputAdornment position="end">
                    <Typography variant="body2">
                      <FormattedMessage {...messages.placeholderHours} />
                    </Typography>
                  </InputAdornment>
                }
              />
              <Error
                error={
                  hasLimitCancelTimeHoursError &&
                  updateStageResult.error?.errors?.limit_cancel_time_hours[0]
                }
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
