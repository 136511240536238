import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { VFC } from 'react';

export const UpgradeIcon: VFC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.25C6.615 2.25 2.25 6.615 2.25 12C2.25 17.385 6.615 21.75 12 21.75C17.385 21.75 21.75 17.385 21.75 12C21.75 6.615 17.385 2.25 12 2.25ZM12.53 7.72C12.3894 7.57955 12.1988 7.50066 12 7.50066C11.8012 7.50066 11.6106 7.57955 11.47 7.72L8.47 10.72C8.39631 10.7887 8.33721 10.8715 8.29622 10.9635C8.25523 11.0555 8.23318 11.1548 8.23141 11.2555C8.22963 11.3562 8.24816 11.4562 8.28588 11.5496C8.3236 11.643 8.37974 11.7278 8.45096 11.799C8.52218 11.8703 8.60701 11.9264 8.7004 11.9641C8.79379 12.0018 8.89382 12.0204 8.99452 12.0186C9.09522 12.0168 9.19454 11.9948 9.28654 11.9538C9.37854 11.9128 9.46134 11.8537 9.53 11.78L11.25 10.06V15.75C11.25 15.9489 11.329 16.1397 11.4697 16.2803C11.6103 16.421 11.8011 16.5 12 16.5C12.1989 16.5 12.3897 16.421 12.5303 16.2803C12.671 16.1397 12.75 15.9489 12.75 15.75V10.06L14.47 11.78C14.5387 11.8537 14.6215 11.9128 14.7135 11.9538C14.8055 11.9948 14.9048 12.0168 15.0055 12.0186C15.1062 12.0204 15.2062 12.0018 15.2996 11.9641C15.393 11.9264 15.4778 11.8703 15.549 11.799C15.6203 11.7278 15.6764 11.643 15.7141 11.5496C15.7518 11.4562 15.7704 11.3562 15.7686 11.2555C15.7668 11.1548 15.7448 11.0555 15.7038 10.9635C15.6628 10.8715 15.6037 10.7887 15.53 10.72L12.53 7.72Z"
        fill="#CCF6FF"
      />
    </svg>
  </SvgIcon>
);
