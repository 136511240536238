import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TypographyProps } from '@material-ui/core/Typography';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { messages } from 'shared/global/messages';

const useStyles = makeStyles(theme => ({
  error: {
    marginTop: theme.spacing(0.75),
    marginBottom: theme.spacing(-1.5),
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
}));

export interface ErrorProps {
  align?: TypographyProps['align'];
  className?: string;
  error?: boolean | object | string;
}

export const Error: FC<ErrorProps> = ({ align, className, error }) => {
  const styles = useStyles();
  const intl = useIntl();

  if (!error) {
    return null;
  }

  const errorMessage =
    typeof error === 'string' ? (
      error
    ) : (
      <FormattedMessage {...messages.requiredFieldMessage} />
    );

  return (
    <Grid
      aria-label={intl.formatMessage(messages.error)}
      className={className ?? styles.error}
    >
      <Typography align={align} color="error" variant="body2">
        {errorMessage}
      </Typography>
    </Grid>
  );
};
