import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  selectDropdown: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(3.5),
  },
  actionsHeader: {
    marginBottom: theme.spacing(3),
  },
  customStagesDropdown: {
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(1.375),
    borderTop: theme.customBorders.gray300,
  },
  doNotRunRadioOption: {
    marginBottom: theme.spacing(3),
  },
}));
