import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  addAutomatedResponseBtn: {
    width: 'fit-content',
  },
  actionPanel: {
    marginBottom: theme.spacing(2),
  },
  enableFaqBotLabel: {
    marginLeft: theme.spacing(1),
  },
}));
