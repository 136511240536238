import { WorkflowBuilderChatbotMessage } from 'api-clients/monolith';

export const USER = 'user' as const;

export const createWorkflowMessage = (
  positionName?: string,
  location?: string,
): WorkflowBuilderChatbotMessage => {
  const funnelLocation = location ? `in ${location}` : '';
  const defaultBotMessage: WorkflowBuilderChatbotMessage = {
    key: null,
    content:
      'Welcome to Fountain AI! Let’s get started on your workflow. Tell me what you’d like to see in this workflow:',
    sender: 'assistant',
    element_disable: false,
    element_data: {
      key: 'button',
      buttons: [
        {
          label: 'Personal Information Data Collection',
          value: 'data_collection',
        },
        {
          label: 'Schedule an Interview',
          value: 'schedule',
        },
        {
          label: 'Background Check',
          value: 'background_check',
        },
        {
          label: `Create a workflow for ${
            positionName as string
          } ${funnelLocation}`,
          value: 'suggest_workflow',
        },
      ],
    },
    created_at: new Date().toISOString(),
  };

  return defaultBotMessage;
};
