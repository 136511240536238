import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  tag: {
    borderRadius: theme.spacing(0.625),
    display: 'inline-block',
    padding: theme.spacing(0.5, 1),
    backgroundColor: theme.palette.common.gray100,
    color: theme.palette.common.gray800,
  },
}));
