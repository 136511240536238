import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  loadingIcon: {
    marginTop: theme.spacing(2.5),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  partnerDataCollectionPackageWarning: {
    marginBottom: theme.spacing(2.5),
  },
  refreshFailed: {
    marginTop: theme.spacing(2.5),
    color: theme.palette.error.main,
  },
  refreshIcon: {
    marginTop: theme.spacing(2.5),
  },
  refreshText: {
    marginTop: theme.spacing(2.5),
  },
  selectOptionDropdown: {
    width: 362,
    marginRight: theme.spacing(2),
  },
}));
