import { WorkflowBuilderChatbotMessage } from 'api-clients/monolith';
import React, { useState, VFC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import { addMessageAction } from 'containers/FlashMessage/actions';
import { apiPut } from 'utils/axios';

import { FileUpload } from './FileUpload';
import { messages } from './messages';

type UrlParams = {
  funnelSlug: string;
};

export interface MediaMessageProps {
  handleCloseFileUploadModal: () => void;
  updateMessages: (message: WorkflowBuilderChatbotMessage) => void;
  setShowTypingIndicator: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MediaMessage: VFC<MediaMessageProps> = ({
  handleCloseFileUploadModal,
  updateMessages,
  setShowTypingIndicator,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [file, setFile] = useState<File>();
  const [isUploading, setIsUploading] = useState(false);
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);
  const [isUploadFailed, setIsUploadFailed] = useState(false);

  const { funnelSlug } = useParams<UrlParams>();

  const handleSubmit = async () => {
    if (!file) return;
    try {
      setIsUploading(true);

      const url = `${REACT_APP_MONOLITH_BASE_URL}/internal_api/ai_builder/workflow/chat`;

      const fileUploadBody = new FormData();
      fileUploadBody.append('Content-Type', file.type);
      fileUploadBody.append('funnel_slug', funnelSlug);
      fileUploadBody.append('file', file);

      await apiPut(url, fileUploadBody);

      setIsUploading(false);
      setIsUploadSuccess(true);
      setShowTypingIndicator(true);
      updateMessages({
        content: file.name,
        created_at: new Date().toISOString(),
        sender: 'user',
        element_data: { key: 'file' },
      });

      dispatch(
        addMessageAction(intl.formatMessage(messages.success), 'success'),
      );
    } catch (error) {
      setIsUploadFailed(true);
      dispatch(addMessageAction(intl.formatMessage(messages.error), 'error'));
    } finally {
      setIsUploading(false);
      handleCloseFileUploadModal();
    }
  };

  const handleUpload = (file: File) => {
    setFile(file);
  };

  return (
    <FileUpload
      handleUpload={handleUpload}
      handleSubmit={handleSubmit}
      isUploading={isUploading}
      isUploadSuccess={isUploadSuccess}
      isUploadFailed={isUploadFailed}
      uploadedFileData={undefined}
    />
  );
};
