import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addStage: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.addStage',
    defaultMessage: 'Add Stage',
  },
  addRule: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.addRule',
    defaultMessage: 'Add Rule',
  },
  addStageMenu: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.addStageMenu',
    defaultMessage: 'Add Stage Dropdown Menu',
  },
  afterStage: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.stageColumnHeader.afterStage',
    defaultMessage: 'after stage',
  },
  alreadyAdded: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.stageColumnHeader.alreadyAdded',
    defaultMessage: 'Already Added',
  },
  createNewStage: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.createNewStage',
    defaultMessage: 'Create New Stage',
  },
  cloneExistingStage: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.cloneExistingStage',
    defaultMessage: 'Clone Existing Stage',
  },
  continueEditing: {
    id: 'app.containers.WorkflowEditor.components.UnsavedChangesPrompt.continueEditing',
    defaultMessage: 'Continue Editing',
  },
  discard: {
    id: 'app.containers.WorkflowEditor.components.UnsavedChangesPrompt.discard',
    defaultMessage: 'Discard',
  },
  hiringNeed: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.AddRuleModal.hiringNeed',
    defaultMessage: 'Hiring goal basis',
  },
  percentage: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.AddRuleModal.percentage',
    defaultMessage: 'Percentage basis',
  },
  stageColumnHeader: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.stageColumnHeader',
    defaultMessage: 'Stage Column Header',
  },
  stageCreatedSuccessfully: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.stageColumnHeader.stageCreatedSuccessfully',
    defaultMessage: 'Stage created successfully',
  },
  topOfWorkflow: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.stageColumnHeader.topOfWorkflow',
    defaultMessage: 'At the top of the workflow',
  },
  unavailable: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.stageColumnHeader.unavailable',
    defaultMessage: 'Unavailable',
  },
  unsavedChangesBodyText: {
    id: 'app.containers.WorkflowEditor.components.UnsavedChangesPrompt.unsavedChangesBodyText',
    defaultMessage:
      'You have unsaved changes on the stage, which will be lost if you do not save. Do you want to continue editing or discard the changes?',
  },
  unsavedChanges: {
    id: 'app.containers.WorkflowEditor.components.UnsavedChangesPrompt.unsavedChanges',
    defaultMessage: 'Unsaved Changes',
  },
  unsavedChangesModal: {
    id: 'app.containers.WorkflowEditor.components.UnsavedChangesPrompt.unsavedChangesModal',
    defaultMessage: 'Unsaved Changes Modal',
  },
  unsupportedAxStageTypes: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.unsupportedAxStageTypes',
    defaultMessage:
      'This workflow includes unsupported stages in Applicant Experience 2.0 and will be automatically reverted to 1.0. Delete these stages to retain <link>Applicant Experience 2.0 benefits</link>:',
  },
});
