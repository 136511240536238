import { Button, Grid, Typography } from '@material-ui/core';
import React, { VFC } from 'react';
import { formatBytes } from 'react-dropzone-uploader';
import { useIntl } from 'react-intl';

import Document from './icons/document.svg';
import { messages } from './messages';
import { useStyles } from './styles';

export interface FilePreviewProps {
  type: string;
  url?: string;
  name: string;
  size?: number;
}

export const FilePreview: VFC<FilePreviewProps> = ({
  type,
  url,
  name,
  size,
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const imageUpload = type.includes('image');

  const Details = () => {
    return (
      <>
        <Typography variant="body1" className={styles.previewName}>
          {name}
        </Typography>
        {size && (
          <Typography variant="body2" className={styles.previewSize}>
            {formatBytes(size)}
          </Typography>
        )}
      </>
    );
  };

  return (
    <Grid container direction="column" className={styles.previewWrapper}>
      {imageUpload ? (
        <>
          <div className={styles.imagePreview}>
            <img alt="Preview" src={url} />
          </div>
          <Details />
        </>
      ) : (
        <Button
          fullWidth
          className={styles.previewButton}
          variant="outlined"
          startIcon={
            <img
              alt={intl.formatMessage(messages.document)}
              className={styles.iconDark}
              src={Document}
            />
          }
        >
          <Grid container direction="column">
            <Details />
          </Grid>
        </Button>
      )}
    </Grid>
  );
};
