import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const FountainLogo = ({ color, className, height, width }) => {
  const theme = useTheme();
  // N.B.: Swapping the implementation to use `fill: currentColor` + `color`
  // would avoid the need for targeting `.fountainLogo path` in
  // `app/components/PublicNavbar/styles.js`
  const fill = theme.palette.common[color] || theme.palette.common.blue400;

  return (
    <svg
      className={className}
      width={width || '97'}
      height={height || '16'}
      viewBox="0 0 97 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M25.5222 0.279441C24.26 0.279441 23.2994 0.632165 22.6408 1.33729C21.9818 2.04274 21.6526 3.03095 21.6526 4.30161V5.03485H19.822V7.40225H21.6526V15.5934H24.5029V7.40225H27.5612V5.03485H24.5029V4.21793C24.5029 3.68737 24.6347 3.28561 24.8983 3.0133C25.1615 2.74099 25.5709 2.60468 26.1257 2.60468C26.4445 2.60468 26.7396 2.63966 27.01 2.70929C27.2804 2.77924 27.5197 2.87698 27.7278 3.00251V0.803134C27.4917 0.649491 27.1833 0.523962 26.8019 0.425892C26.4201 0.328476 25.9939 0.279114 25.5222 0.279114V0.279441Z"
          fill={fill}
        />
        <path
          d="M36.3195 5.43299C35.4875 4.95833 34.5369 4.72067 33.4692 4.72067C32.4015 4.72067 31.4753 4.95833 30.6504 5.43299C29.8252 5.90797 29.1838 6.57125 28.726 7.42315C28.2683 8.27538 28.0394 9.24562 28.0394 10.3352C28.0394 11.4247 28.2715 12.3917 28.7364 13.2367C29.201 14.0818 29.846 14.7382 30.6712 15.206C31.4964 15.6738 32.4294 15.9078 33.4695 15.9078C34.5097 15.9078 35.4878 15.6738 36.3198 15.206C37.1518 14.7382 37.8001 14.0821 38.265 13.2367C38.7296 12.3917 38.962 11.4244 38.962 10.3352C38.962 9.24594 38.7296 8.27538 38.265 7.42315C37.8001 6.57125 37.1522 5.90797 36.3198 5.43299H36.3195ZM35.7475 11.9586C35.5462 12.4267 35.2515 12.7895 34.8632 13.0481C34.4746 13.3067 34.0101 13.4355 33.4692 13.4355C32.9284 13.4355 32.4914 13.3067 32.1168 13.0481C31.7425 12.7899 31.451 12.4267 31.2429 11.9586C31.0348 11.4908 30.9309 10.9497 30.9309 10.3348C30.9309 9.71995 31.0312 9.15833 31.2325 8.69021C31.4335 8.22242 31.7247 7.85597 32.1064 7.59052C32.4875 7.32541 32.9417 7.19236 33.4689 7.19236C33.9961 7.19236 34.4743 7.32541 34.8629 7.59052C35.2508 7.85597 35.5459 8.22602 35.7472 8.70067C35.9481 9.17566 36.0487 9.72027 36.0487 10.3348C36.0487 10.9494 35.9481 11.4904 35.7472 11.9586H35.7475Z"
          fill={fill}
        />
        <path
          d="M46.9298 11.2779C46.9298 11.9484 46.7532 12.4721 46.3994 12.849C46.0455 13.2259 45.5638 13.4149 44.9535 13.4149C44.3432 13.4149 43.8851 13.223 43.5387 12.8389C43.192 12.4551 43.0187 11.9347 43.0187 11.2779V5.03513H40.1892V11.4665C40.1892 12.3466 40.3797 13.1288 40.7612 13.813C41.1423 14.4976 41.6903 15.0281 42.4048 15.4054C43.119 15.7823 43.9685 15.9709 44.9532 15.9709C45.9378 15.9709 46.8152 15.7823 47.5434 15.4054C48.2715 15.0284 48.826 14.4976 49.2078 13.813C49.5889 13.1288 49.7798 12.3466 49.7798 11.4665V5.03513H46.9295V11.2779H46.9298Z"
          fill={fill}
        />
        <path
          d="M59.5995 5.2339C59.0587 4.89164 58.4065 4.72067 57.6439 4.72067C56.7006 4.72067 55.9205 4.95833 55.3033 5.43299C54.8618 5.77264 54.5197 6.19957 54.2733 6.71084V5.03515H51.4646V15.5933H54.2941V9.91609C54.2941 9.06419 54.4918 8.39045 54.8868 7.89454C55.2822 7.39896 55.8335 7.15084 56.5408 7.15084C57.2482 7.15084 57.7128 7.35352 58.0179 7.75822C58.3227 8.16358 58.4756 8.78469 58.4756 9.62253V15.593H61.3051V8.97298C61.3051 8.09329 61.1558 7.33587 60.8578 6.70005C60.5595 6.06456 60.14 5.57617 59.5992 5.23358L59.5995 5.2339Z"
          fill={fill}
        />
        <path
          d="M78.5099 5.29701C77.7746 4.91323 76.9007 4.72101 75.8885 4.72101C75.1256 4.72101 74.4251 4.82921 73.7872 5.04562C73.149 5.26235 72.5734 5.54512 72.0605 5.89425V8.40811C72.4767 8.00308 72.9617 7.68893 73.5168 7.46533C74.0712 7.24206 74.6611 7.13026 75.285 7.13026C76.131 7.13026 76.7897 7.35745 77.2614 7.81119C77.7328 8.26493 77.9688 8.91088 77.9688 9.74905V10.296C77.7509 10.0086 77.4747 9.76311 77.1367 9.56043C76.6235 9.25347 75.9924 9.0995 75.2435 9.0995C74.4946 9.0995 73.8599 9.23941 73.2983 9.51859C72.7364 9.79808 72.2962 10.1927 71.977 10.7023C71.6579 11.2123 71.4985 11.816 71.4985 12.5143C71.4985 13.157 71.6475 13.7366 71.9459 14.2531C72.2439 14.7699 72.6601 15.1749 73.1941 15.4682C73.7278 15.7614 74.3348 15.9082 75.0143 15.9082C75.8184 15.9082 76.5086 15.7019 77.0845 15.29C77.4617 15.02 77.7552 14.6843 77.9688 14.2851V15.5937H80.7567V9.45549C80.7567 8.47806 80.5658 7.63663 80.1847 6.93118C79.8029 6.22605 79.2449 5.68144 78.5099 5.29701V5.29701ZM77.0952 13.7395C76.804 13.8863 76.4638 13.9595 76.0758 13.9595C75.4792 13.9595 75.0149 13.8127 74.6819 13.5195C74.3491 13.2263 74.1826 12.8353 74.1826 12.3463C74.1826 11.8572 74.3524 11.4738 74.6923 11.1939C75.0318 10.9148 75.5 10.7749 76.0966 10.7749C76.6933 10.7749 77.1228 10.918 77.4695 11.2041C77.8159 11.4904 77.9895 11.8641 77.9895 12.325C77.9895 12.6323 77.9129 12.9082 77.7607 13.1524C77.6081 13.3969 77.3864 13.5924 77.0949 13.7389L77.0952 13.7395Z"
          fill={fill}
        />
        <path
          d="M96.5365 6.70038C96.2382 6.06488 95.8188 5.5765 95.278 5.2339C94.7371 4.89164 94.0849 4.72067 93.3224 4.72067C92.379 4.72067 91.5989 4.95833 90.9818 5.43299C90.5403 5.77264 90.1981 6.19957 89.9517 6.71084V5.03515H87.143V15.5933H89.9725V9.91609C89.9725 9.06419 90.1702 8.39045 90.5653 7.89454C90.9607 7.39896 91.5119 7.15084 92.2193 7.15084C92.9266 7.15084 93.3912 7.35352 93.6963 7.75822C94.0012 8.16358 94.1541 8.78469 94.1541 9.62253V15.593H96.9836V8.97298C96.9836 8.09329 96.8342 7.33587 96.5362 6.70005L96.5365 6.70038Z"
          fill={fill}
        />
        <path
          d="M69.627 13.4463C69.391 13.5372 69.093 13.5826 68.7323 13.5826C68.233 13.5826 67.8512 13.4463 67.588 13.174C67.3244 12.9017 67.1926 12.4931 67.1926 11.9484V7.40225H70.209V5.03484H67.1926V2.1019H64.3423V5.03484H62.2202V7.40225H64.3423V12.3044C64.3423 13.0449 64.4773 13.6869 64.7478 14.2318C65.0182 14.7764 65.4204 15.2027 65.9544 15.5097C66.4881 15.8166 67.1367 15.9706 67.8996 15.9706C68.4541 15.9706 68.9118 15.9147 69.2728 15.8029C69.6335 15.6911 69.987 15.5237 70.334 15.3001V13.0795L70.3133 13.1004C70.0912 13.2403 69.8623 13.3554 69.6267 13.446L69.627 13.4463Z"
          fill={fill}
        />
        <path
          d="M85.3756 5.03485H82.5253V15.5934H85.3756V5.03485Z"
          fill={fill}
        />
        <path
          d="M83.9293 0.384033C82.9872 0.384033 82.2234 1.15323 82.2234 2.10189C82.2234 3.05055 82.9872 3.81975 83.9293 3.81975C84.8714 3.81975 85.6353 3.05055 85.6353 2.10189C85.6353 1.15323 84.8714 0.384033 83.9293 0.384033V0.384033Z"
          fill={fill}
        />
        <path
          d="M12.2489 15.5937C13.8575 15.5937 15.1615 14.2805 15.1615 12.6607C15.1615 11.0409 13.8575 9.72778 12.2489 9.72778C10.2021 9.72778 8.69128 11.2426 8.00274 13.834H7.17493C6.48606 11.2426 4.97555 9.72778 2.92874 9.72778C1.32019 9.72778 0.0161438 11.0409 0.0161438 12.6607C0.0161438 14.2805 1.32019 15.5937 2.92874 15.5937H12.2489Z"
          fill={fill}
        />
        <path
          d="M12.249 2.1022C10.2022 2.1022 8.69137 3.61706 8.00283 6.20839H7.17502C6.48615 3.61706 4.97564 2.1022 2.92883 2.1022C1.32028 2.1022 0.0162354 3.41536 0.0162354 5.03515C0.0162354 6.65493 1.32028 7.96809 2.92883 7.96809C4.87305 7.96809 6.47381 8.88733 7.58892 10.5179C8.70403 8.88733 10.3048 7.96809 12.249 7.96809C13.8576 7.96809 15.1616 6.65493 15.1616 5.03515C15.1616 3.41536 13.8576 2.1022 12.249 2.1022V2.1022Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

FountainLogo.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default FountainLogo;
