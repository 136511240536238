import { FC } from 'react';

import loadable from 'utils/loadable';

import { DataValidationModalProps } from './DataValidationModal';

export const DataValidationModalAsync: FC<DataValidationModalProps> = loadable(
  () =>
    import('./DataValidationModal').then(module => ({
      default: module.DataValidationModal,
    })),
);

export type { DataValidationModalProps } from './DataValidationModal';
export { DataValidationModal } from './DataValidationModal';
