import { Grid } from '@material-ui/core';
import {
  CancelablePromise,
  FunnelDetails,
  SidebarStage,
  WorkflowEditorService,
  WorkflowStageDetail,
  WorkflowStageUpdates,
} from 'api-clients/monolith';
import React, { FC, useState } from 'react';
import {
  DragDropContext,
  Droppable,
  DropResult,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addMessageAction } from 'containers/FlashMessage/actions';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';

import { messages } from './messages';
import { DraggableStageCard } from './StageCard';
import { useStyles } from './styles';
import { reorderStages } from './utils';

export interface StageColumnViewProps {
  onDiscardChanges: () => void;
  onDragEnd: OnDragEndResponder;
  refetchStages: () => void;
  scheduledApplicants?: string;
  selectedStage?: SidebarStage;
  stages: SidebarStage[];
}

export const StageColumnView: FC<StageColumnViewProps> = ({
  onDiscardChanges,
  onDragEnd,
  refetchStages,
  scheduledApplicants,
  selectedStage,
  stages,
}) => {
  const styles = useStyles();
  const intl = useIntl();

  if (!stages.length) {
    return null;
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="stage-column">
        {provided => (
          <Grid
            aria-label={intl.formatMessage(messages.stageColumn)}
            className={styles.stageColumn}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {stages.map(stage => {
              return (
                <DraggableStageCard
                  key={stage.external_id}
                  onDiscardChanges={onDiscardChanges}
                  refetchStages={refetchStages}
                  scheduledApplicants={scheduledApplicants}
                  selected={selectedStage?.external_id === stage.external_id}
                  stage={stage}
                />
              );
            })}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export interface StageColumnProps {
  onDiscardChanges: () => void;
  opening: FunnelDetails;
  refetchStages: () => void;
  selectedStage?: SidebarStage;
  stages: SidebarStage[];
}

export const StageColumn: FC<StageColumnProps> = ({
  onDiscardChanges,
  opening,
  refetchStages,
  selectedStage,
  stages: stagesData,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [stages, setStages] = useState<SidebarStage[]>(stagesData);

  const { result, mutation: updateStageMutation } = useApiServiceMutation<
    WorkflowStageDetail,
    (
      stageExternalId: string,
      funnelSlug: string,
      requestBody: WorkflowStageUpdates,
    ) => CancelablePromise<WorkflowStageDetail>
    // eslint-disable-next-line @typescript-eslint/unbound-method
  >(WorkflowEditorService.patchInternalApiWorkflowEditorFunnelsStages, {
    onSuccess: () => {
      refetchStages();
    },
  });

  React.useEffect(() => {
    if (result.isError) {
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.reorderStagesError),
          'error',
          false,
        ),
      );
    }
  }, [dispatch, intl, result]);

  React.useEffect(() => {
    if (stagesData) {
      setStages(stagesData);
    }
  }, [stagesData]);

  const onDragEnd = ({ destination, source, draggableId }: DropResult) => {
    if (!destination) {
      return;
    }
    // No change required
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newStages = reorderStages({
      stages,
      startPosition: source.index,
      endPosition: destination.index,
    });
    setStages(newStages);

    updateStageMutation(opening.slug, draggableId, {
      workflow_stage: {
        stage: {
          position: destination.index,
        },
      },
    });
  };

  const stageColumnViewProps: StageColumnViewProps = {
    onDiscardChanges,
    onDragEnd,
    refetchStages,
    selectedStage,
    stages,
  };

  return <StageColumnView {...stageColumnViewProps} />;
};
