import {
  Close,
  IconButton,
  Input,
  InputProps,
  Success,
  TextButton,
} from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import globalMessages from 'shared/global/messages';

import { messages } from './messages';
import useStyles from './styles';

export interface InputEditorBaseProps {
  onConfirm: (value: string) => void;
  onCancel: () => void;
  isEditing?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  className?: string;
  inputClassName?: string;
  setIsEditingStageTitle?: (value: boolean) => void;
  setShowWarning?: (value: boolean) => void;
  typographyVariant?: Variant;
}

export type InputEditorProps = InputEditorBaseProps & InputProps;

export const InputEditor: FC<InputEditorProps> = ({
  value = '',
  onConfirm,
  onChange,
  onCancel,
  className,
  isEditing = false,
  inputClassName,
  setIsEditingStageTitle,
  setShowWarning,
  typographyVariant = 'h4',
  ...otherProps
}) => {
  const [draftInputValue, setDraftInputValue] = useState(value);
  const [isEditingValue, setIsEditingValue] = useState(isEditing);

  useEffect(() => {
    setDraftInputValue(value);
  }, [value]);

  const classes = useStyles();
  const intl = useIntl();

  const onClickEdit = () => {
    setIsEditingValue(true);
    if (setIsEditingStageTitle) setIsEditingStageTitle(true);
  };

  const onCancelEdit = () => {
    setIsEditingValue(false);
    setDraftInputValue(value);

    if (setIsEditingStageTitle) setIsEditingStageTitle(false);
    if (onCancel) onCancel();
  };

  const onConfirmEdit = () => {
    setIsEditingValue(false);

    if (setIsEditingStageTitle) setIsEditingStageTitle(false);
    if (onConfirm) onConfirm(draftInputValue);
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDraftInputValue(e.target.value);

    if (setShowWarning) setShowWarning(true);
    if (onChange) onChange(e.target.value);
  };

  if (isEditingValue) {
    return (
      <Grid className={classNames(className, classes.inputEditorContainer)}>
        <Grid alignItems="center" container wrap="nowrap">
          <Input
            {...otherProps}
            className={inputClassName}
            onChange={onChangeInput}
            value={draftInputValue}
          />
          <Grid className={classes.ctaIconsContainer} item>
            <Grid alignItems="center" container wrap="nowrap">
              <IconButton
                className={classes.iconButton}
                onClick={onConfirmEdit}
                aria-label={intl.formatMessage(globalMessages.confirm)}
              >
                <Success
                  className={classes.ctaIcon}
                  color="secondary"
                  fontSize="inherit"
                />
              </IconButton>
              <IconButton className={classes.iconButton} onClick={onCancelEdit}>
                <Close
                  aria-label={intl.formatMessage(globalMessages.close)}
                  className={classes.ctaIcon}
                  color="secondary"
                  fontSize="inherit"
                />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid className={classNames(className, classes.inputEditorContainer)}>
      <Grid alignItems="center" container wrap="nowrap">
        <Grid item className={classes.inputText}>
          <Typography
            variant={typographyVariant}
            className={classes.savedInputValue}
          >
            {value}
          </Typography>
        </Grid>
        <Grid>
          <TextButton
            className={classes.edit}
            color="primary"
            onClick={onClickEdit}
          >
            <Typography color="primary" variant="h4">
              {intl.formatMessage(messages.edit)}
            </Typography>
          </TextButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
