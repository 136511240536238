/*
 *
 * Account actions
 *
 */

import {
  CLOSE_MOBILE_DRAWER,
  RESET_ACCOUNT_STATUS,
  TOGGLE_MOBILE_DRAWER,
  UPDATE_ACCOUNT_FAILED,
  UPDATE_ACCOUNT_INIT,
  UPDATE_ACCOUNT_SUCCESS,
} from './constants';

export function updateAccountInitAction(
  name,
  phoneNumber,
  oldPassword,
  newPassword,
  validationSuccessCallback,
  validationErrorCallback,
) {
  return {
    type: UPDATE_ACCOUNT_INIT,
    name,
    phoneNumber,
    oldPassword,
    newPassword,
    validationSuccessCallback,
    validationErrorCallback,
  };
}

export function updateAccountSuccessAction(applicant) {
  return {
    type: UPDATE_ACCOUNT_SUCCESS,
    applicant,
  };
}

export function updateAccountFailedAction(error) {
  return {
    type: UPDATE_ACCOUNT_FAILED,
    error,
  };
}

export function resetAccountStatus() {
  return {
    type: RESET_ACCOUNT_STATUS,
  };
}

export function toggleMobileDrawerAction() {
  return {
    type: TOGGLE_MOBILE_DRAWER,
  };
}

export function closeMobileDrawerAction() {
  return {
    type: CLOSE_MOBILE_DRAWER,
  };
}
