import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
  },
  icon: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    '& path': {
      fill: theme.palette.common.gray600,
    },
  },
  iconWithErrorPresent: {
    paddingBottom: theme.spacing(2.625),
  },
  text: {
    color: theme.palette.common.gray800,
  },
  input: {
    width: theme.spacing(22.5),
  },
  inputErrorPresent: {
    minHeight: theme.spacing(10.125),
  },
  select: {
    width: theme.spacing(37.5),
  },
  inputContainer: {
    margin: `${theme.spacing(2)}px 0px`,
    gap: theme.spacing(2),
    display: 'flex',
    alignItems: 'end',
  },
  checkboxWrapper: {
    padding: theme.spacing(0, 1.5),
  },
}));
