import { Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { FC } from 'react';

import { TestId } from 'containers/DataValidationModal/components/testData';

const useStyles = makeStyles<Theme>(theme => ({
  fieldContent: {
    marginBottom: theme.spacing(1),
  },
  isRequired: {
    '&::after': {
      content: "'*'",
      color: theme.palette.common.red400,
      marginLeft: theme.spacing(0.5),
    },
  },
  subtitle: {
    fontSize: '0.75rem',
    fontStyle: 'italics',
  },
  title: {
    color: theme.palette.common.gray800,
    fontFamily: theme.typography.h6.fontFamily,
  },
}));

export interface TitleProps {
  isRequired?: boolean;
  subtitle?: string | null;
  title: string;
}

export const Title: FC<TitleProps> = ({ isRequired, subtitle, title }) => {
  const styles = useStyles();
  return (
    <Grid className={styles.fieldContent} data-testid={TestId.TITLE}>
      <Typography
        className={classNames(styles.title, {
          [styles.isRequired]: isRequired,
        })}
        variant="body2"
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography className={styles.subtitle} variant="body2">
          {subtitle}
        </Typography>
      )}
    </Grid>
  );
};
