/* eslint-disable camelcase */
import { Box, FormControlLabel, Radio, Typography } from '@material-ui/core';
import React, { VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { AssessmentFormDataEntry } from 'containers/StatusLabels/utils';

import { messages } from '../../messages';
import { useStyles } from './styles';

export interface AssessmentDataFieldQuestionsProps {
  questions: AssessmentFormDataEntry;
}

export const AssessmentDataFieldQuestions: VFC<
  AssessmentDataFieldQuestionsProps
> = ({ questions }) => {
  const styles = useStyles();
  const sortedQuestions = Object.entries(questions).sort(
    (a, b) => a[1].position - b[1].position,
  );

  return (
    <Box className={styles.container}>
      {sortedQuestions.map((dataField, index) => {
        const questionKey = dataField[0];
        const dataFieldDetails = dataField[1];

        return (
          <Box className={styles.questionContainer} key={questionKey}>
            <Typography
              variant="body2"
              className={styles.position}
              color="textPrimary"
            >
              {index + 1}.
            </Typography>
            <Box>
              <Typography
                variant="body2"
                className={styles.questionText}
                color="textPrimary"
              >
                {dataFieldDetails.question}
              </Typography>
              {dataFieldDetails.options.map(option => {
                const isApplicantAnswer =
                  dataFieldDetails.applicant_answer === option.value;
                const isCorrectAnswer = false || option?.correct_answer;
                const radioClass = isApplicantAnswer
                  ? styles.applicantAnswer
                  : styles.otherAnswer;
                const checked = isApplicantAnswer || isCorrectAnswer;

                return (
                  <Box key={option.value}>
                    <FormControlLabel
                      className={styles.radioOption}
                      control={
                        <Radio
                          disabled
                          checked={checked}
                          className={radioClass}
                          size="small"
                        />
                      }
                      label={
                        <React.Fragment>
                          <Box display="flex" gridGap="4px">
                            <Typography variant="body2" color="textPrimary">
                              {option.value}{' '}
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                className={styles.applicantAnswerValueHint}
                              >
                                {isCorrectAnswer &&
                                  isApplicantAnswer === false && (
                                    <FormattedMessage
                                      {...messages.correctAnswer}
                                    />
                                  )}
                                {option.weight && (
                                  <FormattedMessage
                                    {...messages.weightedScore}
                                    values={{ points: option.weight }}
                                  />
                                )}
                              </Typography>
                            </Typography>
                          </Box>
                        </React.Fragment>
                      }
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default AssessmentDataFieldQuestions;
