import { datadogRum } from '@datadog/browser-rum';
import { Loader } from '@fountain/fountain-ui-components';
import { Grid, makeStyles } from '@material-ui/core';
import {
  WorkflowBuilderChatbotInitResponse,
  WorkflowBuilderFunnelStage,
  WorkflowBuilderService,
} from 'api-clients/monolith';
import { isEmpty } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { ChatBot } from './ChatBot';
import { messages } from './messages';
import { PreviewWorkflow } from './PreviewWorkflow';
import { StageSelector } from './StageSelector';

const useStyles = makeStyles({
  workFlowBuilderContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 'calc(100vh - 52px)', // substracting the navbar height
    width: '100%',
  },
  loader: {
    width: '100%',
  },
});

type UrlParams = {
  accountSlug: string;
  funnelSlug: string;
};

export const WorkFlowBuilder: FC = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { funnelSlug } = useParams<UrlParams>();

  const [workflowData, setWorkflowData] =
    useState<WorkflowBuilderChatbotInitResponse>();

  const [stages, setStages] = useState<Array<WorkflowBuilderFunnelStage>>();

  const [selectedStage, setSelectedStage] =
    useState<WorkflowBuilderFunnelStage>();

  const [isFetching, setIsFetching] = useState(false);

  const initializeChat = useCallback(() => {
    return WorkflowBuilderService.postInternalApiAiBuilderWorkflowChat({
      funnel_slug: funnelSlug,
    });
  }, [funnelSlug]);

  useEffect(() => {
    if (isEmpty(workflowData?.messages)) {
      setIsFetching(true);
      initializeChat()
        .then(result => {
          setWorkflowData(result);
          setStages(result.stages);
          setSelectedStage(result.stages[0]);
        })
        .catch(error => {
          datadogRum.addError(error);
        })
        .finally(() => {
          setIsFetching(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetching) {
    return (
      <Grid item className={classes.loader}>
        <Loader block size="2rem" />
      </Grid>
    );
  }

  if (workflowData) {
    return (
      <>
        <Grid className={classes.workFlowBuilderContainer}>
          <ChatBot
            initialMessages={workflowData.messages}
            setStages={setStages}
            hasIndicator={workflowData.show_typing_indicator as boolean}
            funnel={workflowData.funnel}
          />

          <StageSelector stages={stages} setSelectedStage={setSelectedStage} />

          <PreviewWorkflow
            selectedStage={selectedStage}
            funnelExternalId={workflowData.funnel.external_id}
          />
        </Grid>
      </>
    );
  }

  return (
    <Grid item className={classes.workFlowBuilderContainer}>
      <div style={{ margin: 'auto', textAlign: 'center' }}>
        <h1>{intl.formatMessage(messages.cannotEditExistingWorkflows)}</h1>
      </div>
    </Grid>
  );
};
