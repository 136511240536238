/*
 *
 * GlobalPopup reducer
 *
 */
import produce from 'immer';

import {
  CLOSE_PERSISTED_POPUP,
  CLOSE_POPUP,
  SET_PERSISTED_POPUP,
  SET_SELECTED_POPUP,
} from './constants';

export const initialState = {
  selectedPopup: '',
  // query params before opening popup
  prevQueryParams: '',
};

const globalPopupReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_PERSISTED_POPUP:
        draft.persistedPopup = action.persistedPopupType;
        draft.prevQueryParams = action.prevQueryParams;
        break;
      case SET_SELECTED_POPUP:
        draft.selectedPopup = action.popupType;
        draft.prevQueryParams = action.prevQueryParams;
        break;
      case CLOSE_POPUP:
        draft.selectedPopup = '';
        draft.prevQueryParams = '';
        break;
      case CLOSE_PERSISTED_POPUP:
        draft.persistedPopup = '';
        draft.prevQueryParams = '';
        break;
      default:
        break;
    }
  });

export default globalPopupReducer;
