import { FormControlLabel, Switch, Typography } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import messages from '../messages';
import useStyles from './styles';

interface JobSearchResultsListControlsProps {
  onChangeActiveOnly: (checked: boolean) => void;
}

export const JobSearchResultsListControls: FC<
  JobSearchResultsListControlsProps
> = ({ onChangeActiveOnly }) => {
  const styles = useStyles();
  const [isActiveOnly, setIsActiveOnly] = useState(false);

  const handleChangeActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsActiveOnly(event.target.checked);
    onChangeActiveOnly(event.target.checked);
  };

  return (
    <FormControlLabel
      className={styles.switch}
      control={<Switch checked={isActiveOnly} onChange={handleChangeActive} />}
      label={
        <Typography className={styles.switchLabel} variant="caption">
          <FormattedMessage {...messages.switch} />
        </Typography>
      }
    />
  );
};
