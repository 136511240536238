import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addSubstage: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.SubstagesCard.addSubstage`,
    defaultMessage: 'Add Substage',
  },
  cancel: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.SubstagesCard.cancel`,
    defaultMessage: 'Cancel',
  },
  continueEditing: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.SubstagesCard.continueEditing`,
    defaultMessage: 'Continue Editing',
  },
  cloneExistingStage: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.SubstagesCard.cloneExistingStage`,
    defaultMessage: 'Clone an existing stage',
  },
  createNewStage: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.SubstagesCard.createNewStage`,
    defaultMessage: 'Create new from scratch',
  },
  saveAllChanges: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.SubstagesCard.saveAllChanges`,
    defaultMessage: 'Save All Changes',
  },
  selectOpening: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.SubstagesCard.selectOpening`,
    defaultMessage: 'Select Opening',
  },
  selectOpeningError: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.SubstagesCard.selectOpeningError`,
    defaultMessage: 'Select opening to duplicate from',
  },
  stageToDuplicate: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.SubstagesCard.stageToDuplicate`,
    defaultMessage: 'Stage to duplicate',
  },
  stageToDuplicateError: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.SubstagesCard.stageToDuplicateError`,
    defaultMessage: 'Select stage to duplicate',
  },
  stageType: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.SubstagesCard.stageType`,
    defaultMessage: 'Stage Type',
  },
  stageTypeError: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.SubstagesCard.stageTypeError`,
    defaultMessage: 'Select stage type',
  },
  substageError: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.SubstagesCard.substageError`,
    defaultMessage:
      'Save could not be performed due to unresolved issues: {error}',
  },
  substageName: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.SubstagesCard.substageName`,
    defaultMessage: 'Substage Name',
  },
  substageNameError: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.SubstagesCard.substageNameError`,
    defaultMessage: 'Add substage name',
  },
  substageNotSubmitted: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.SubstagesCard.substageNotSubmitted`,
    defaultMessage:
      'Substage creation form not submitted due to errors in the Multi stage',
  },
  substageSuccess: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.SubstagesCard.substageSuccess`,
    defaultMessage: 'Substage created successfully',
  },
  unsavedChanges: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.SubstagesCard.unsavedChanges`,
    defaultMessage: 'Unsaved Changes',
  },
  unsavedChangesDescription: {
    id: `app.containers.WorkflowEditor.components.StageDetail.MultiStage.SubstagesCard.unsavedChangesDescription`,
    defaultMessage:
      'You have unsaved changes on the stage, which will be automatically saved if you continue. Do you want to save all changes (including the new Substage) or continue editing?',
  },
});
