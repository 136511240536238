import { StageType } from 'api-clients/monolith/models/StageType';

export const terminalStagesHash: Record<
  'HiredStage' | 'ArchivedStage' | 'RejectedStage' | string,
  boolean
> = {
  HiredStage: true,
  ArchivedStage: true,
  RejectedStage: true,
};

export const stageTypes: StageType[] = [
  'AccurateStage',
  'ArchivedStage',
  'BackgroundCheckerStage',
  'CheckrCanada::Stage',
  'CustomStage',
  'DataCollectionStage',
  'DistributeApplicantsRuleStage',
  'DocumentSignatureStage',
  'DocumentSigningStage',
  'FountainDocumentSigningStage',
  'HiredStage',
  'I9FormStage',
  'LearningStage',
  'MultiStage',
  'NewCheckrCanada::Stage',
  'OnfidoStage',
  'PartnerStage',
  'RejectedStage',
  'RuleStage',
  'SchedulerStage',
  'SchoolkeepStage',
  'SterlingStage',
  'TechCheckStage',
  'VideoRecordingStage',
  'W4FederalFormStage',
  'W4StateFormStage',
];
