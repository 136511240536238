export const WorkAvailabilityBlockEnum = {
  sundayMorning: 'sunday_morning',
  sundayAfternoon: 'sunday_afternoon',
  sundayEvening: 'sunday_evening',
  mondayMorning: 'monday_morning',
  mondayAfternoon: 'monday_afternoon',
  mondayEvening: 'monday_evening',
  tuesdayMorning: 'tuesday_morning',
  tuesdayAfternoon: 'tuesday_afternoon',
  tuesdayEvening: 'tuesday_evening',
  wednesdayMorning: 'wednesday_morning',
  wednesdayAfternoon: 'wednesday_afternoon',
  wednesdayEvening: 'wednesday_evening',
  thursdayMorning: 'thursday_morning',
  thursdayAfternoon: 'thursday_afternoon',
  thursdayEvening: 'thursday_evening',
  fridayMorning: 'friday_morning',
  fridayAfternoon: 'friday_afternoon',
  fridayEvening: 'friday_evening',
  saturdayMorning: 'saturday_morning',
  saturdayAfternoon: 'saturday_afternoon',
  saturdayEvening: 'saturday_evening',
};
