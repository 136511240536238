import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  fromDataKey: {
    defaultMessage: 'From data key',
    id: `app.containers.WorkflowEditor.components.Rules.Action.SwitchApplicantData.fromDataKey`,
  },
  toDataKey: {
    defaultMessage: 'To data key',
    id: `app.containers.WorkflowEditor.components.Rules.Action.SwitchApplicantData.toDataKey`,
  },
});
