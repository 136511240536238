import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  moveApplicant: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.W4Stage.W4FederalForm.moveApplicant`,
    defaultMessage:
      'Automatically move applicant to next stage after the W4 Federal form is completed (recommended)',
  },
  settings: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.W4Stage.W4FederalForm.settings`,
    defaultMessage: 'Settings',
  },
});
