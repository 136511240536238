/**
 *
 * Initials Component
 *
 */

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { classNames } from 'react-extras';

import { getInitials } from 'utils/stringutils';

import { INITIALS_CONTAINER_WIDTH } from './constants';
import useStyles from './styles';

const INITIALS_GROUPS = [
  'abc',
  'def',
  'ghi',
  'jkl',
  'mno',
  'pqr',
  'stu',
  'vwx',
  'yz',
];

// eslint-disable-next-line consistent-return
export const getAvatarGroup = ({ initials }) => {
  const firstLetter = initials[0];
  if (firstLetter) {
    // eslint-disable-next-line prefer-destructuring
    return INITIALS_GROUPS.filter(
      group => group.indexOf(firstLetter.toLowerCase()) !== -1,
    )[0];
  }
};

const Initials = ({ className, color, customName, name, size, variant }) => {
  const initials = customName || getInitials(name);

  const classes = useStyles({
    color,
    size,
    variant,
    ...(color === 'avatar' && { avatarGroup: getAvatarGroup({ initials }) }),
  });

  return (
    <Grid item className={classNames(classes.initialsContainer, className)}>
      <Typography
        variant={size === 'small' ? 'h3' : 'body2'}
        className={classes.initialText}
      >
        {initials}
      </Typography>
    </Grid>
  );
};

Initials.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['avatar', 'primary', 'secondary', 'mobile']),
  customName: PropTypes.string,
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.keys(INITIALS_CONTAINER_WIDTH)),
  variant: PropTypes.oneOf(['contained', 'outlined']),
};

Initials.defaultProps = {
  size: 'medium',
  variant: 'contained',
};

export default memo(Initials);
