export const EMPTY_VALUE = '-';

export const ValueTypeEnum = {
  ARRAY: 'array',
  BOOLEAN: 'boolean',
  NULL: 'null',
  OBJECT: 'object',
  STRING: 'string',
  NUMBER: 'number',
};
