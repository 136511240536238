import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import { addDefaultErrorMessageAction } from 'containers/FlashMessage/actions';
import { openMoveApplicantPopup } from 'containers/GlobalPopup/actions';
import { apiPatch } from 'utils/axios';

export default function useApplicantToggleLabelCompletion(
  applicantId,
  completed,
) {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [labelCompleted, setLabelCompleted] = useState(completed);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const patchData = async customLabelId => {
    setIsLoading(true);
    try {
      const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/custom_labels/${customLabelId}/toggle_label_completion`;

      const res = await apiPatch(url);

      if (res.data.can_be_advanced) {
        dispatch(
          openMoveApplicantPopup({
            applicantId,
            transitionType: 'advance',
          }),
        );
      }

      setData(res.data.applicant);
      setLabelCompleted(!labelCompleted);
      setError('');
    } catch (e) {
      dispatch(addDefaultErrorMessageAction());
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  return { data, error, isLoading, patchData, labelCompleted };
}
