import { InlineMessage } from '@fountain/fountain-ui-components';
import React, { VFC } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import { AlternateDocuments } from '../AlternateDocuments';
import { DefaultDocuments } from '../DefaultDocuments';
import { messages } from '../messages';
import { useStyles } from './styles';

export const NoTemplates: VFC = () => {
  const styles = useStyles();
  const intl = useIntl();
  const { accountSlug } = useParams<{
    accountSlug: string;
  }>();

  const companySettingsHref = `${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/document_signing`;

  return (
    <div>
      <InlineMessage
        className={styles.noTemplatesSection}
        type="orange"
        message={intl.formatMessage(messages.uploadDocumentTemplates, {
          link: chunks => (
            <a
              className={styles.bodyTextColor}
              href={companySettingsHref}
              target="_blank"
              rel="noopener noreferrer"
            >
              {chunks}
            </a>
          ),
        })}
      />
      <DefaultDocuments className={styles.noTemplatesSection} />
      <AlternateDocuments />
    </div>
  );
};
