import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  input: {
    borderColor: theme.palette.common.gray300,
  },
  descriptionText: {
    marginTop: theme.spacing(1),
  },
  radioDescriptionText: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1.5),
    marginLeft: theme.spacing(3.5),
  },
}));
