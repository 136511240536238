import { Input } from '@fountain/fountain-ui-components';
import { Box, Popper } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState, VFC } from 'react';

import { SuggestionMenu } from 'containers/WorkflowEditor/components/WorkflowEditorContent/StageDetail/SchedulerStage/SessionDetailsCard/SuggestionMenu';
import { useSimpleToggle } from 'hooks/useSimpleToggle';

import { useStyles } from './styles';
import { useSuggestedAddress } from './useSuggestedAddress';
const MIN_PHRASE = 3;

export interface LocationInputProps {
  value: string;
  label: string;
  onChange: (e: { target: { value: string } }) => void;
}

export const LocationInput: VFC<LocationInputProps> = ({
  value,
  label,
  onChange,
}) => {
  const { showContent, on, off } = useSimpleToggle();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [popperWidth, setPopperWidth] = useState<number>(0);
  const valueRef = useRef<string>(value);
  const locationRef = useRef<HTMLDivElement>(null);
  const styles = useStyles();

  const { suggestions, getPlace, setEnabled } = useSuggestedAddress(
    value,
    MIN_PHRASE,
  );

  useEffect(() => {
    if (valueRef.current !== value && value.length > MIN_PHRASE) {
      on();
      setEnabled(true);
      setAnchorEl(locationRef.current);
      setPopperWidth(locationRef.current?.offsetWidth ?? 0);
    } else {
      off();
      setEnabled(false);
    }
  }, [setEnabled, value, on, off]);

  const handleSelectPlace = useCallback(
    async (placeId: string) => {
      const details = await getPlace(placeId);
      const address2 = details.address_2 ? ` ${details.address_2}` : '';
      const newValue = `${details.street_address}${address2}, ${details.city}, ${details.state_code} ${details.postal_code}, ${details.country_code}`;
      onChange({ target: { value: newValue } });
      valueRef.current = newValue;
      off();
    },
    [getPlace, onChange, off],
  );

  return (
    <>
      <div ref={locationRef}>
        <Input value={value} onChange={onChange} label={label} />
      </div>
      <Popper
        placement="bottom-start"
        anchorEl={anchorEl}
        open={showContent}
        modifiers={{
          preventOverflow: {
            enabled: true,
            escapeWithReference: true,
            boundariesElement: 'viewport',
          },
          flip: { enabled: false },
        }}
        className={styles.suggestionPopper}
        style={{ width: `${popperWidth}px` }}
      >
        <Box bgcolor="background.paper">
          <SuggestionMenu
            suggestions={suggestions}
            onSelect={handleSelectPlace}
            onClickAway={off}
            open={showContent}
          />
        </Box>
      </Popper>
    </>
  );
};
