import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { VFC } from 'react';

export const ConversationIcon: VFC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 46 46">
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="46" height="46" rx="23" fill="#5E44FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3575 30.9039C16.7219 30.9683 17.0914 31.0004 17.4615 31C18.6315 31.0015 19.7781 30.6781 20.7689 30.067C21.4834 30.232 22.2311 30.32 23 30.32C27.9126 30.32 32 26.7205 32 22.16C32 17.5995 27.9126 14 23 14C18.0874 14 14 17.5995 14 22.16C14 24.3442 14.9462 26.3189 16.4683 27.7741C16.6825 27.979 16.724 28.1621 16.7028 28.2664C16.5887 28.8304 16.3301 29.3565 15.9514 29.795C15.8728 29.8862 15.82 29.9961 15.7984 30.1137C15.7767 30.2312 15.787 30.3523 15.8282 30.4647C15.8694 30.5772 15.94 30.6769 16.0329 30.754C16.1258 30.8311 16.2378 30.8828 16.3575 30.9039ZM19.5385 21.14C19.263 21.14 18.9989 21.2475 18.8042 21.4387C18.6094 21.63 18.5 21.8895 18.5 22.16C18.5 22.4305 18.6094 22.69 18.8042 22.8812C18.9989 23.0725 19.263 23.18 19.5385 23.18C19.8139 23.18 20.078 23.0725 20.2728 22.8812C20.4675 22.69 20.5769 22.4305 20.5769 22.16C20.5769 21.8895 20.4675 21.63 20.2728 21.4387C20.078 21.2475 19.8139 21.14 19.5385 21.14ZM21.9615 22.16C21.9615 21.8895 22.0709 21.63 22.2657 21.4387C22.4604 21.2475 22.7246 21.14 23 21.14C23.2754 21.14 23.5396 21.2475 23.7343 21.4387C23.9291 21.63 24.0385 21.8895 24.0385 22.16C24.0385 22.4305 23.9291 22.69 23.7343 22.8812C23.5396 23.0725 23.2754 23.18 23 23.18C22.7246 23.18 22.4604 23.0725 22.2657 22.8812C22.0709 22.69 21.9615 22.4305 21.9615 22.16ZM26.4615 21.14C26.1861 21.14 25.922 21.2475 25.7272 21.4387C25.5325 21.63 25.4231 21.8895 25.4231 22.16C25.4231 22.4305 25.5325 22.69 25.7272 22.8812C25.922 23.0725 26.1861 23.18 26.4615 23.18C26.737 23.18 27.0011 23.0725 27.1958 22.8812C27.3906 22.69 27.5 22.4305 27.5 22.16C27.5 21.8895 27.3906 21.63 27.1958 21.4387C27.0011 21.2475 26.737 21.14 26.4615 21.14Z"
        fill="white"
      />
    </svg>
  </SvgIcon>
);
