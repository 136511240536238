import { createContext } from '@fountain/fountain-ui-components';
import { ReviewStageDataField } from 'api-clients/monolith/models/WorkflowReviewStage';
import { useForm, useLocalStorageItem } from 'hooks';
import produce from 'immer';
import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ValidationResult } from 'hooks/useForm';
import { removeLocalStorageItem } from 'utils/storageUtils';

import { arrayHelpers, createFormBuilderInitialValue } from './helpers';
import { useWorkflowReviewStage } from './hooks';
import { formBuilderMessages } from './messages';
import { DataField, dataFieldSchema } from './schemas';
import { FormBuilderApi } from './types';

export type FormBuilderContext = FormBuilderApi;

export const [FormBuilderContextProvider, useFormBuilderContextState] =
  createContext<FormBuilderContext>({});

type QuestionDrawerApi = {
  setCreateQuestion: () => void;
  setEditQuestion: (dataFieldId: string) => void;
  closeDrawer: () => void;
};

type QuestionDrawerContextState = QuestionDrawerApi & {
  isDrawerOpen: boolean;
  isEdit: boolean;
  value: string | null;
};

const failedToImplement =
  (fnName: string) =>
  (...args: Array<unknown>) => {
    throw new Error(
      `You failed to implement function: ${fnName} called with: ${JSON.stringify(
        args,
      )}`,
    );
  };

export const [QuestionDrawerContextProvider, useQuestionDrawerContextState] =
  createContext<QuestionDrawerContextState>({
    defaultValue: {
      isDrawerOpen: false,
      isEdit: false,
      value: null,
      setCreateQuestion: failedToImplement('setCreateQuestion'),
      setEditQuestion: failedToImplement('setEditQuestion'),
      closeDrawer: failedToImplement('closeDrawer'),
    },
  });

const FORM_BUILDER_LS_KEY = 'formBuilder';

export const QuestionDrawerProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { stage } = useWorkflowReviewStage();
  const [value, setValue] = useLocalStorageItem<
    QuestionDrawerContextState['value']
  >(null, `${FORM_BUILDER_LS_KEY}-${stage.id}`);

  useEffect(() => {
    return () => {
      // we presist the drawer being open in LS for browser reloads
      // but since we scope the LS key to the stage id we want to
      // kill the key when the user leaves this stage so we dont
      // accumulate lots of LS keys over time in the users browser
      removeLocalStorageItem(`${FORM_BUILDER_LS_KEY}-${stage.id}`);
    };
  }, [stage]);

  const api = {
    setCreateQuestion: () => setValue('create'),
    setEditQuestion: (dataFieldId: string) => setValue(dataFieldId),
    closeDrawer: () => setValue(null),
  };

  const isDrawerOpen = value !== null;
  const isEdit = isDrawerOpen && value !== 'create';

  return (
    <QuestionDrawerContextProvider
      value={{
        ...api,
        value,
        isDrawerOpen,
        isEdit,
      }}
    >
      {children}
    </QuestionDrawerContextProvider>
  );
};

type ErrorsValue = string | Record<number, string>;
type ErrorsObj = Partial<Record<keyof DataField, ErrorsValue>>;

export const FormBuilderProvider = ({ children }: { children: ReactNode }) => {
  const { setStage, stage, activeDataEntries } = useWorkflowReviewStage();
  const { isEdit, value, closeDrawer } = useQuestionDrawerContextState();
  const intl = useIntl();
  // we assert the return value of validator to match what the hook expects
  // bc the current type does not account for arrays with errors so through
  // assertions in the fn below we handle arrays to change the hooks type
  // would require a larger refactor we want to avoid right now as its used
  // in many places
  const validator = useCallback(
    (values: DataField) => {
      const errors: ErrorsObj = {};

      const result = dataFieldSchema.safeParse(values);

      if (!result.success) {
        result.error.errors.forEach(({ path, message }) => {
          if (path.includes('options')) {
            errors[path[0] as keyof DataField] = {
              ...(errors[path[0] as keyof DataField] as ErrorsObj),
              [path[1]]: message,
            } as ErrorsValue;
          } else {
            errors[path[0] as keyof DataField] = message;
          }
        });
      }

      const isDupeKey = activeDataEntries.some(({ key, id }) =>
        isEdit ? key === values.key && id !== values.id : key === values.key,
      );

      if (isDupeKey) {
        errors.key = intl.formatMessage(
          formBuilderMessages.dupeKeyValidationError,
        );
        return errors as ValidationResult<DataField>;
      }
      return errors as ValidationResult<DataField>;
    },
    [activeDataEntries, intl, isEdit],
  );

  const handleSubmit = useCallback(
    (values: DataField) => {
      // we run through zod again here bc the transforms that happen with zod
      // in the validations call are not persisted to the submit call (different)
      // so we know its valid at this point but we want to take advantage of the
      // zod transforms
      const result = dataFieldSchema.safeParse(values);

      if (!result.success) {
        throw new Error(
          `on submit error, this is unlikely see comment above: ${JSON.stringify(
            result.error.errors,
          )}`,
        );
      }

      setStage(
        produce(stage, draftStage => {
          if (isEdit) {
            const idxToUpdate =
              stage.additional_info.review_questionnaire.findIndex(
                ({ id }) => id === values.id,
              );

            if (idxToUpdate < 0) return;

            // unfortunately the types at the monolith level arent discriminated unions
            // so we must alias here to ReviewStageDataField to account for the difference
            draftStage.additional_info.review_questionnaire[idxToUpdate] =
              result.data as ReviewStageDataField;
          } else {
            draftStage.additional_info.review_questionnaire.push(
              result.data as ReviewStageDataField,
            );
          }
        }),
      );

      closeDrawer();
    },
    [setStage, stage, closeDrawer, isEdit],
  );

  /*
   * we assert initialValue bc the init value for create isnt exact to the
   * dataField as it will have null values until user fills out the form
   */
  const initialValue = createFormBuilderInitialValue({
    isEdit,
    dataFields: activeDataEntries,
    dataFieldId: value,
  }) as DataField;

  const formApi = useForm<DataField>(handleSubmit, validator, initialValue);

  const api: FormBuilderApi = useMemo(
    () => ({
      ...formApi,
      arrayHelpers,
    }),
    [formApi],
  );

  return (
    <FormBuilderContextProvider value={api}>
      {children}
    </FormBuilderContextProvider>
  );
};
