/*
 *
 * Auth reducer
 *
 */
// TODO: Break up Auth
import produce from 'immer';

import {
  ACCOUNTS_SUCCESS,
  FETCH_ALL_BRANDS_ERROR,
  FETCH_ALL_BRANDS_INIT,
  FETCH_ALL_BRANDS_SUCCESS,
  SET_SELECTED_ACCOUNT,
  SET_SELECTED_BRAND,
  SET_SELECTED_LOCATION,
  SET_SELECTED_USER,
  SIGN_OUT_SUCCESS,
  UPDATE_CLIENT_SETTINGS,
  UPDATE_CLIENT_SETTINGS_ERROR,
  UPDATE_CLIENT_SETTINGS_SUCCESS,
  UPDATE_IS_FAQ_BOT_ENABLED,
  USERS_SUCCESS,
  WHOAMI_ERROR,
  WHOAMI_INIT,
  WHOAMI_SUCCESS,
} from './constants';

export const initialState = {
  error: null,
  whoami: {
    brands: [{ locations: [] }],
  },
  signedOut: true,
  isLoading: true,
  superAdmin: {
    accounts: [],
    users: [],
  },
  isUpdatingClientSettings: false,
  clientSettingsUpdateSuccess: false,
  isFetchingAllBrands: false,
  fetchingAllBrandsError: null,
  isAllBrandsFetched: false,
};
const authReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case WHOAMI_INIT:
        draft.isLoading = true;
        break;
      case WHOAMI_SUCCESS: {
        const { whoami } = action;
        let updatedBrands;
        if (whoami.brands.length > 1) {
          updatedBrands = whoami.brands.map((brand, index) => {
            if (!brand.isAccount && index === 1) {
              return { ...brand, isSelected: true };
            }
            return brand;
          });
        } else if (whoami.brands.length === 1) {
          const account = whoami.brands[0];
          updatedBrands = [{ ...account, isSelected: true }];
        }
        const updatedWhoami = {
          ...whoami,
          brands: updatedBrands,
        };

        draft.error = null;
        draft.whoami = updatedWhoami;
        draft.signedOut = false;
        draft.isLoading = false;
        break;
      }
      case WHOAMI_ERROR:
        draft.error = action.error;
        draft.isLoading = false;
        break;
      case FETCH_ALL_BRANDS_INIT:
        draft.isFetchingAllBrands = true;
        break;
      case FETCH_ALL_BRANDS_SUCCESS: {
        const { brands } = action;
        const { brands: oldBrands } = draft.whoami;
        const selectedBrand = oldBrands.find(brand => brand.isSelected);
        const updatedBrands = brands.map(brand => {
          if (brand.slug === selectedBrand.slug) {
            return { ...brand, isSelected: true };
          }
          return brand;
        });
        const updatedWhoami = {
          ...draft.whoami,
          brands: updatedBrands,
        };
        draft.whoami = updatedWhoami;
        draft.isFetchingAllBrands = false;
        draft.isAllBrandsFetched = true;
        break;
      }
      case FETCH_ALL_BRANDS_ERROR:
        draft.fetchingAllBrandsError = action.error;
        draft.isFetchingAllBrands = false;
        break;
      case SET_SELECTED_BRAND: {
        const { brands } = draft.whoami;
        const updatedBrands = brands.map(brand => {
          if (brand.id === action.brand.id) {
            const updatedBrand = { ...brand, isSelected: true };
            if (updatedBrand.locations.length) {
              updatedBrand.locations[0] = {
                ...updatedBrand.locations[0],
                isSelected: true,
              };
            }
            return updatedBrand;
          }

          return { ...brand, isSelected: false };
        });
        draft.whoami.brands = updatedBrands;
        break;
      }
      case UPDATE_CLIENT_SETTINGS: {
        draft.isUpdatingClientSettings = true;
        draft.clientSettingsUpdateSuccess = null;
        break;
      }
      case UPDATE_CLIENT_SETTINGS_SUCCESS: {
        draft.whoami.client_settings = {
          ...draft.whoami.client_settings,
          ...action.data,
        };
        draft.isUpdatingClientSettings = false;
        draft.clientSettingsUpdateSuccess = true;
        break;
      }
      case UPDATE_CLIENT_SETTINGS_ERROR: {
        draft.isUpdatingClientSettings = false;
        draft.clientSettingsUpdateSuccess = false;
        break;
      }
      case SET_SELECTED_LOCATION: {
        const { brands } = draft.whoami;
        const selectedBrand = brands.filter(brand => brand.isSelected)[0];
        const updatedBrands = brands.map(brand => {
          if (brand.name === selectedBrand.name) {
            const updatedLocations = brand.locations.map(location => {
              if (location.id === action.location.id) {
                return { ...location, isSelected: true };
              }
              return { ...location, isSelected: false };
            });
            return { ...brand, locations: updatedLocations };
          }

          const updatedLocations = brand.locations.map(location => ({
            ...location,
            isSelected: false,
          }));
          return { ...brand, locations: updatedLocations };
        });
        draft.whoami.brands = updatedBrands;
        break;
      }
      case ACCOUNTS_SUCCESS: {
        const { accounts } = action;
        const w = draft.whoami;
        const { is_impersonated: isImpersonating, brands } = w;
        let updatedAccounts;
        if (isImpersonating) {
          const impersonatedAccountId = brands.find(
            brand => brand.isAccount,
          ).id;
          updatedAccounts = accounts.map(account => {
            if (account.external_id === impersonatedAccountId) {
              return { ...account, isSelected: true };
            }
            return { ...account, isSelected: false };
          });
        } else {
          updatedAccounts = accounts.map((account, index) => {
            if (index === 0) {
              return { ...account, isSelected: true };
            }
            return { ...account, isSelected: false };
          });
        }

        draft.superAdmin.accounts = updatedAccounts;
        break;
      }
      case SET_SELECTED_ACCOUNT: {
        const { accounts } = draft.superAdmin;
        const updatedAccounts = accounts.map(account => {
          if (account.external_id === action.account.external_id) {
            return { ...account, isSelected: true };
          }
          return { ...account, isSelected: false };
        });

        draft.superAdmin.accounts = updatedAccounts;
        break;
      }
      case USERS_SUCCESS: {
        const { users } = action;
        const updatedUsers = users.sort(user => (user.owner ? -1 : 1));
        draft.superAdmin.users = updatedUsers;
        break;
      }
      case SET_SELECTED_USER: {
        const { users } = draft.superAdmin;
        const updatedUsers = users.map(user => {
          if (user.id === action.user.id) {
            return { ...user, isSelected: true };
          }
          return { ...user, isSelected: false };
        });
        draft.superAdmin.users = updatedUsers;
        break;
      }
      case SIGN_OUT_SUCCESS: {
        draft.signedOut = true;
        break;
      }
      case UPDATE_IS_FAQ_BOT_ENABLED: {
        draft.whoami.is_faq_bot_enabled = !draft.whoami.is_faq_bot_enabled;
        break;
      }
      // no default
    }
  });
export default authReducer;
