import { Box, Typography } from '@material-ui/core';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { WorkflowBuilderChatbotMessage } from 'api-clients/monolith';
import classnames from 'classnames';
import React, { VFC } from 'react';

import { FountainLogo } from '../icons/FountainLogo';

const useStyles = (fromUser: boolean, customWidth: number | null) =>
  makeStyles(theme => ({
    messageBubbleContainer: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: fromUser ? 'flex-end' : 'flex-start',
      gap: theme.spacing(1),
    },
    message: {
      backgroundColor: lighten(theme.palette.primary.main, 0.9),
      border: '1px solid rgba(3, 7, 37, 0.12)',
      padding: theme.spacing(2),
      borderRadius: 6,
      wordBreak: 'break-word',
      maxWidth: `${customWidth ? customWidth / 2 : 400}px`,
    },
    messageContent: {
      fontSize: '16px',
      color: theme.palette.common.gray900,
      whiteSpace: 'pre-wrap',
    },
    fromUser: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    brandLogoWrapper: {
      borderRadius: '25px',
      border: '1px solid rgba(3, 7, 37, 0.12)',
      backgroundColor: theme.palette.common.white,
      width: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(1.75),
    },
    brandLogo: {
      maxWidth: 16,
      maxheight: 16,
    },
  }));

export interface MessageBubbleProps {
  message: WorkflowBuilderChatbotMessage;
  children?: React.ReactNode;
}

export const MessageBubble: VFC<MessageBubbleProps> = ({
  message,
  children,
}) => {
  const { content: messageBody, sender: senderType } = message ?? {};
  const fromUser = senderType === 'user';

  const urlParams = new URLSearchParams(window.location.search);
  const customWidth = urlParams.get('customWidth');

  const styles = useStyles(
    fromUser,
    customWidth ? Number.parseFloat(customWidth) : null,
  )();

  if (!children && !messageBody) {
    return null;
  }

  return (
    <div className={styles.messageBubbleContainer}>
      {!fromUser && (
        <Box className={styles.brandLogoWrapper}>
          <FountainLogo
            data-testid="fountain-logo"
            className={styles.brandLogo}
          />
        </Box>
      )}
      <div
        className={classnames(styles.message, {
          [styles.fromUser]: fromUser,
        })}
      >
        {children ?? (
          <Typography className={styles.messageContent}>
            {messageBody}
          </Typography>
        )}
      </div>
    </div>
  );
};
