import { ApiError, CancelablePromise, CancelError } from 'api-clients/monolith';
import { useCallback, useState } from 'react';

import {
  buildErrorState,
  buildIdleState,
  buildLoadingState,
  buildReadyState,
  ResultStatus,
} from '../api/resultStatus';

export interface MutationOptions<T, E> {
  onSuccess?: (data: T) => void;
  onError?: (e: E | undefined) => void;
}

export const useApiServiceMutation = <
  T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  F extends (...a: any[]) => CancelablePromise<T>,
  E = unknown,
>(
  service: F,
  options?: MutationOptions<T, E>,
): {
  result: ResultStatus<T, E>;
  reset: () => void;
  mutation: (...args: Parameters<F>) => void;
} => {
  const [result, setResult] = useState<ResultStatus<T, E>>(buildIdleState());
  const reset = useCallback(() => setResult(buildIdleState()), []);
  const mutation = useCallback(
    (...args: Parameters<F>) => {
      setResult(buildLoadingState());

      const promise = service(...args);
      promise
        .then(data => {
          setResult(buildReadyState(data));

          if (options?.onSuccess) {
            options.onSuccess(data);
          }

          return data;
        })
        .catch((e: Error) => {
          let errorBody: E | undefined;

          if (e instanceof CancelError) {
            return;
          }

          if (e instanceof ApiError) {
            errorBody = e.body as E;

            setResult(buildErrorState(errorBody));
          } else {
            setResult(buildErrorState());
          }

          if (options?.onError) {
            options.onError(errorBody);
          }
        });

      return () => promise.cancel();
    },
    [options, service],
  );

  return { result, reset, mutation };
};
