import { Typography } from '@material-ui/core';
import React, { VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from './messages';
import { useStyles } from './styles';

export interface AlternateDocumentsProps {
  className?: string;
}

export const AlternateDocuments: VFC<AlternateDocumentsProps> = ({
  className,
}) => {
  const styles = useStyles();

  return (
    <div className={className}>
      <Typography
        className={styles.marginBottom2}
        color="textPrimary"
        variant="h4"
      >
        <FormattedMessage {...messages.alternateDocuments} />
      </Typography>
      <Typography
        className={styles.marginBottom}
        color="textPrimary"
        variant="body2"
      >
        <FormattedMessage {...messages.sendAlternateDocuments} />
      </Typography>
      <Typography color="textSecondary" variant="body2">
        <FormattedMessage {...messages.alternateDocumentSupportText} />
      </Typography>
    </div>
  );
};
