import queryString from 'query-string';
import { all, call, debounce, put } from 'redux-saga/effects';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import { apiGet } from 'utils/axios';

import {
  fetchSearchResultErrorAction,
  fetchSearchResultSuccessAction,
} from './actions';
import { FETCH_SEARCH_RESULT_INIT } from './constants';

export const fetchSearchResultSaga = debounce(
  500,
  FETCH_SEARCH_RESULT_INIT,
  fetchSearchResult,
);

export function* fetchSearchResult({ active, query, locationId }) {
  const queryParams = {};
  if (query) queryParams.query = query;
  if (active) queryParams.active = active;
  if (locationId) queryParams.location_id = locationId;

  const params = queryString.stringify(queryParams);
  const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/search/funnels_by_location?${params}`;

  try {
    const response = yield call(apiGet, url);
    // todo: remove
    // ! this is a temporary hack to fix https://fountain.atlassian.net/browse/PLAT-880?atlOrigin=eyJpIjoiYTEwYzZiZGZlNDc1NDU1YWIzNjFjMGIwOWFmMGJhZGQiLCJwIjoiamlyYS1zbGFjay1pbnQifQ
    const res = Array.isArray(response.data) ? response.data : [];
    yield put(fetchSearchResultSuccessAction(res));
  } catch (error) {
    // TODO: REPLACE WITH GLOBAL NOTIFICATION
    yield put(fetchSearchResultErrorAction(error));
  }
}

export default function* jobSearchSaga() {
  yield all([fetchSearchResultSaga]);
}
