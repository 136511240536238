/**
 *
 * BulkActionQueue
 *
 */

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import PropTypes from 'prop-types';
import Pusher from 'pusher-js/with-encryption';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { classNames } from 'react-extras';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';

import { fetchJobDetailInitAction } from 'containers/ApplicantsV2/actions';
import { makeSelectBulkActionSubmitting } from 'containers/ApplicantTableV2/selectors';
import {
  makeSelectEnvVars,
  makeSelectExternalId,
} from 'containers/Auth_old/selectors';
import { addMessageAction } from 'containers/FlashMessage/actions';
import useApplicants, { getQueryParams } from 'hooks/useApplicants';
import useFetchBulkActionsQueue from 'hooks/useFetchBulkActionsQueue';
import CloseIcon from 'images/close-white.svg';
import LayersIcon from 'images/layers.svg';
import { useInjectReducer } from 'utils/injectReducer';

import { closeBulkActionQueueMenu, openBulkActionQueueMenu } from './actions';
import BulkActionQueuedItem from './components/BulkActionQueuedItem';
import { BULK_ACTION_STATUS } from './constants';
import messages from './messages';
import reducer from './reducer';
import { makeSelectBulkActionQueue } from './selectors';
import useStyles from './styles';

let channel = null;
let pusher = null;

/**
 * @return {null}
 */
export function BulkActionQueue({ intl }) {
  useInjectReducer({ key: 'bulkActionQueue', reducer });

  const location = useLocation();
  const bulkActionQueue = useSelector(makeSelectBulkActionQueue());
  const userExternalId = useSelector(makeSelectExternalId());
  const bulkActionSubmitting = useSelector(makeSelectBulkActionSubmitting());
  const { isBulkActionQueueMenuOpen } = bulkActionQueue;

  const dispatch = useDispatch();
  const { query, filter_id: filterId } = queryString.parse(location.search);

  const { fetchApplicants } = useApplicants();

  const envVars = useSelector(makeSelectEnvVars());

  if (!pusher) {
    pusher = new Pusher(envVars.REACT_APP_PUSHER_APP_KEY, {
      cluster: envVars.REACT_APP_PUSHER_CLUSTER,
    });
  }

  const { initialBulkActions, isFetching } = useFetchBulkActionsQueue();

  const anchorElRef = useRef();

  const [bulkActions, setBulkActions] = useState({});
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const classes = useStyles();

  const channelName = `bulk_action;user;${userExternalId}`;

  const callback = data => {
    switch (data.status) {
      case BULK_ACTION_STATUS.created:
        return handleOpenBulkActionQueueMenu();
      case BULK_ACTION_STATUS.pending:
      case BULK_ACTION_STATUS.running:
      case BULK_ACTION_STATUS.errored:
      case BULK_ACTION_STATUS.paused: {
        const prevData = bulkActions[data.id];
        const userName = data.user_name || (prevData && prevData.user_name);
        const startTime = data.start_time || (prevData && prevData.start_time);

        if (data.status === BULK_ACTION_STATUS.errored) {
          dispatch(
            addMessageAction(
              `Bulk Action Error - ${data.error_message}`,
              'error',
            ),
          );
        }

        return setBulkActions({
          ...bulkActions,
          [data.id]: {
            ...data,
            user_name: userName,
            start_time: startTime,
          },
        });
      }
      case BULK_ACTION_STATUS.complete: {
        setBulkActions({ ...bulkActions, [data.id]: data });
        if (data.type !== 'BulkMessage') {
          const queryParams = getQueryParams({
            location: window.location,
          });
          // MAV
          const mavMatch = matchPath(window.location.pathname, {
            path: '/:accountSlug/applicants',
            exact: true,
            strict: false,
          });
          if (mavMatch) {
            fetchApplicants(queryParams);
          } else {
            // applicant table
            const noBrandApplicantTableMatch = matchPath(
              window.location.pathname,
              {
                path: '/:accountSlug/jobs/:jobId/v2/stages/:stageExternalId',
                exact: true,
                strict: false,
              },
            );
            const brandedApplicantTableMatch = matchPath(
              window.location.pathname,
              {
                path: '/:accountSlug/brands/:brandSlug/locations/:locationId/jobs/:jobId/v2/stages/:stageExternalId',
                exact: true,
                strict: false,
              },
            );
            const applicantTableMatch =
              noBrandApplicantTableMatch || brandedApplicantTableMatch;
            if (applicantTableMatch) {
              dispatch(
                fetchJobDetailInitAction(
                  applicantTableMatch.params.jobId,
                  query,
                  filterId,
                ),
              );
              fetchApplicants(queryParams);
            }
          }
        }
        return handleOpenBulkActionQueueMenu();
      }
      case BULK_ACTION_STATUS.deleted: {
        const newState = { ...bulkActions };
        delete newState[data.id];
        return setBulkActions(newState);
      }
      default:
        return null;
    }
  };

  const bulkActionsListEndRef = useRef(null);

  const subscribeToPusher = () => {
    channel = pusher.subscribe(channelName);

    channel.bind('status_update', callback);

    scrollToBottom();
  };

  useEffect(() => {
    // We only want to connect a user to pusher if they are performing a bulk action.
    let hasBulkActionInProgress = false;

    Object.keys(initialBulkActions).forEach(key => {
      if (initialBulkActions[key].status !== 'complete') {
        hasBulkActionInProgress = true;
      }
    });

    if (channel || hasBulkActionInProgress || bulkActionSubmitting) {
      subscribeToPusher();
    }

    return () => {
      if (channel) {
        channel.unbind();
        pusher.unsubscribe(channelName);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialBulkActions, bulkActions, bulkActionSubmitting]);

  useEffect(() => {
    if (initialBulkActions) setBulkActions(initialBulkActions);
  }, [initialBulkActions]);

  const handleOpenBulkActionQueueMenu = () =>
    dispatch(openBulkActionQueueMenu());
  const handleCloseBulkActionQueueMenu = () =>
    dispatch(closeBulkActionQueueMenu());

  const toggleBulkActionsWidget = () => {
    if (isBulkActionQueueMenuOpen) {
      return handleCloseBulkActionQueueMenu();
    }

    return handleOpenBulkActionQueueMenu();
  };

  const scrollToBottom = () => {
    if (bulkActionsListEndRef && bulkActionsListEndRef.current) {
      bulkActionsListEndRef.current.scrollIntoView();
    }
  };

  const handleDragMove = e => {
    setIsDragging(true);
    setTranslate({
      x: translate.x + e.movementX,
      y: translate.y + e.movementY,
    });
  };

  const handleDragStop = () => {
    setIsDragging(false);
  };

  if (Object.keys(bulkActions).length < 1 || isFetching) return null;

  return (
    <Draggable
      bounds="body"
      handle=".draggableBulkActionQueue"
      onStop={handleDragStop}
      onDrag={handleDragMove}
    >
      <div className={`${classes.bulkActionQueue} draggableBulkActionQueue`}>
        <IconButton
          title={intl.formatMessage(messages.bulkJobs)}
          classes={{ root: classes.trigger }}
          className={classNames({
            [classes.closeWidget]: isBulkActionQueueMenuOpen,
          })}
          onClick={toggleBulkActionsWidget}
        >
          {!isBulkActionQueueMenuOpen ? (
            <img src={LayersIcon} className={classes.icon} alt="queue-icon" />
          ) : (
            <img src={CloseIcon} className={classes.icon} alt="close-icon" />
          )}
        </IconButton>
        <Menu
          style={{
            transform: `translateX(${translate.x}px) translateY(${translate.y}px)`,
          }}
          classes={{ paper: classes.bulkActionsList }}
          MenuListProps={{
            disablePadding: true,
          }}
          onEntered={scrollToBottom}
          anchorEl={anchorElRef}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{ vertical: -8, horizontal: 'right' }}
          getContentAnchorEl={null}
          open={!isDragging && isBulkActionQueueMenuOpen}
          onClose={toggleBulkActionsWidget}
        >
          {Object.keys(bulkActions).map(bulkAction => (
            <BulkActionQueuedItem
              key={bulkActions[bulkAction].id}
              data={bulkActions[bulkAction]}
            />
          ))}
          <div ref={bulkActionsListEndRef} />
        </Menu>
      </div>
    </Draggable>
  );
}

export default injectIntl(BulkActionQueue);

BulkActionQueue.propTypes = {
  intl: PropTypes.object.isRequired,
};
