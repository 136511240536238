import {
  CustomTooltip,
  EditPencil,
  IconButton,
  Trashcan,
} from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { useSimpleToggle } from 'hooks';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { ConfirmationModal } from 'components/ConfirmationModal';

import { messages } from './messages';
import { useStyles } from './styles';

export interface ItemRowProps {
  title: string;
  type?: string;
  id: string | number;
  confirmDeleteModalTitle?: string;
  confirmDeleteModalDescription?: string;
  confirmDeleteModalButton?: string;
  icon?: React.ReactNode;
  onDelete: (id: string | number) => void;
  onEdit: (id: string | number) => void;
  // Props for applying styles based on user input
  hoverable?: boolean;
  dragging?: boolean;
}

export const ItemRow: FC<ItemRowProps> = ({
  title,
  type,
  id,
  confirmDeleteModalTitle,
  confirmDeleteModalDescription,
  confirmDeleteModalButton,
  onEdit,
  onDelete,
  icon,
  hoverable = false,
  dragging = false,
}) => {
  const styles = useStyles();
  const intl = useIntl();

  const {
    showContent: isDeleteMessageModalOpen,
    toggle: toggleIsDeleteMessageModalOpen,
  } = useSimpleToggle(false);

  const onConfirmDelete = () => {
    onDelete(id);
    toggleIsDeleteMessageModalOpen();
  };

  const onClickEdit = () => {
    onEdit(id);
  };

  return (
    <>
      <Grid
        container
        className={classNames(styles.row, {
          hoverable,
          dragging,
        })}
        alignItems="center"
      >
        {icon}
        {type && (
          <Typography variant="body2" className={styles.type}>
            {type}
          </Typography>
        )}
        <Typography variant="h4" className={styles.title}>
          {title}
        </Typography>
        <Grid className={styles.actionIcons}>
          <CustomTooltip dense title={intl.formatMessage(messages.edit)}>
            <IconButton
              aria-label={intl.formatMessage(messages.edit)}
              className={styles.editMessageButton}
              onClick={onClickEdit}
            >
              <EditPencil className={styles.editMessageIcon} />
            </IconButton>
          </CustomTooltip>
          <CustomTooltip dense title={intl.formatMessage(messages.delete)}>
            <IconButton
              aria-label={intl.formatMessage(messages.delete)}
              className={styles.deleteMessageButton}
              onClick={toggleIsDeleteMessageModalOpen}
            >
              <Trashcan
                viewBox="0 0 16 16"
                className={styles.deleteMessageIcon}
              />
            </IconButton>
          </CustomTooltip>
        </Grid>
      </Grid>
      {isDeleteMessageModalOpen && (
        <ConfirmationModal
          ariaLabelledBy={
            confirmDeleteModalTitle ?? intl.formatMessage(messages.delete)
          }
          bodyContent={
            confirmDeleteModalDescription ??
            intl.formatMessage(messages.askDelete)
          }
          cancelButtonContent={intl.formatMessage(messages.cancel)}
          confirmButtonContent={
            confirmDeleteModalButton ?? intl.formatMessage(messages.delete)
          }
          negative
          onCancel={toggleIsDeleteMessageModalOpen}
          onConfirm={onConfirmDelete}
          title={confirmDeleteModalTitle ?? intl.formatMessage(messages.delete)}
        />
      )}
    </>
  );
};
