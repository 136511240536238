import { Typography } from '@material-ui/core';
import Autolinker from 'autolinker';
import React from 'react';

import Values from 'components/ApplicantDrawerDetailsList/components/Field/Values/Values';

import { DETAIL_TYPES } from '../../../constants';
import { DetailType, DetailValueType } from '../../../props';
import useStyles from '../styles';
import { EMPTY_VALUE, ValueTypeEnum } from './constants';
import { getType } from './utils';

const ValuesContainer = ({ ...props }) => {
  const styles = useStyles();

  const DerivedValues = Values(props);

  if (!DerivedValues && DerivedValues !== 0) {
    return <>{EMPTY_VALUE}</>;
  }

  const isStringValue = getType(props.value) === ValueTypeEnum.STRING;
  const isDateValue = props.detail.type === DETAIL_TYPES.date_picker;

  if (isStringValue && !isDateValue) {
    return (
      <Typography
        variant="h4"
        className={styles.textContent}
        dangerouslySetInnerHTML={{
          __html:
            Autolinker.link(DerivedValues, { sanitizeHtml: true }) ||
            EMPTY_VALUE,
        }}
      />
    );
  }

  return (
    <Typography variant="h4" className={styles.textContent}>
      {DerivedValues}
    </Typography>
  );
};

ValuesContainer.propTypes = {
  detail: DetailType.isRequired,
  value: DetailValueType,
};

export default ValuesContainer;
