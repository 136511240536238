import { Grid } from '@material-ui/core';
import { FunnelDetails, SidebarStage } from 'api-clients/monolith';
import React, { FC } from 'react';

import { StageColumn } from '../../StageColumn';
import { StageColumnHeader } from '../../StageColumnHeader';
import { useStyles } from './styles';

export interface StagesSidebarProps {
  isStageAdditionBlocked: boolean;
  onDiscardChanges: () => void;
  opening: FunnelDetails;
  refetchStages: () => void;
  selectedStage?: SidebarStage;
  stages: SidebarStage[];
  unsupportedAxStages: string[];
}

export const StagesSidebar: FC<StagesSidebarProps> = React.memo(
  ({
    isStageAdditionBlocked,
    onDiscardChanges,
    opening,
    refetchStages,
    selectedStage,
    stages,
    unsupportedAxStages,
  }) => {
    const classes = useStyles();
    return (
      <Grid item className={classes.sidebar}>
        <StageColumnHeader
          refetchStages={refetchStages}
          stages={stages}
          unsupportedAxStages={unsupportedAxStages}
          isStageAdditionBlocked={isStageAdditionBlocked}
          onDiscardChanges={onDiscardChanges}
        />
        <StageColumn
          opening={opening}
          onDiscardChanges={onDiscardChanges}
          refetchStages={refetchStages}
          stages={stages}
          selectedStage={selectedStage}
        />
      </Grid>
    );
  },
);

StagesSidebar.displayName = 'StagesSidebar';
