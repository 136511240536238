import { Grid, TableCell, Typography } from '@material-ui/core';
import React, { FC, memo } from 'react';

import useStyles from './styles';

export interface ApplicantTableV2UnreadMessagesCellProps {
  unreadMessagesCount: number;
}

export const ApplicantTableV2UnreadMessagesCell: FC<
  ApplicantTableV2UnreadMessagesCellProps
> = ({ unreadMessagesCount }) => {
  const styles = useStyles();

  return (
    <TableCell role="cell" className={styles.td}>
      {Boolean(unreadMessagesCount) && (
        <Grid container justify="center" className={styles.unreadMessagesCell}>
          <Grid className={styles.unreadMessagesCount}>
            <Typography className={styles.numMessages}>
              {unreadMessagesCount}
            </Typography>
          </Grid>
        </Grid>
      )}
    </TableCell>
  );
};

export default memo(ApplicantTableV2UnreadMessagesCell);
