import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import { addMessageAction } from 'containers/FlashMessage/actions';
import { apiPost } from 'utils/axios';

export default function useApplicantAssignUser(applicantId, defaultUser) {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(defaultUser);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const postData = async user => {
    setIsLoading(true);

    try {
      const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/users/${user.id}`;
      const res = await apiPost(url);
      if (res.data.is_unassigned) {
        setUserData(null);
      } else {
        setUserData(user);
      }
      dispatch(addMessageAction(res.data.message, 'success'));
      setError('');
    } catch (e) {
      setError(e);
      setUserData(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setUserData(defaultUser);
  }, [defaultUser]);

  useEffect(() => {
    if (error) {
      dispatch(addMessageAction(error, 'error'));
      setError('');
    }
  }, [dispatch, error]);

  return { isLoading, userData, postData };
}
