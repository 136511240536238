import React from 'react';

const DesktopPreviewSvg = () => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 9V2C15 0.864 14.136 0 13 0H3C1.864 0 1 0.864 1 2V9H0V10C0 11.105 0.895 12 2 12H14C15.105 12 16 11.105 16 10V9H15ZM3 2H13V9H3V2Z"
      fill="#969eb3"
    />
  </svg>
);

export default DesktopPreviewSvg;
