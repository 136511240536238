import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FaqAnalyticsData } from 'api-clients/monolith';
import React, { VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { FeedbackPercentageBox } from './FeedbackPercentageBox';
import { messages } from './messages';

const useStyles = makeStyles(theme => ({
  card: {
    border: `1px solid ${theme.palette.common.gray300}`,
    borderRadius: '12px',
    padding: theme.spacing(2, 3),
    width: '648px',
    gridArea: 'responseStats',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: theme.spacing(1, 0),
  },
  title: {
    fontSize: '12px',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  countWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  count: {
    fontSize: '80px',
    fontWeight: 700,
    lineHeight: '80px',
    color: theme.palette.common.gray800,
  },
  subText: {
    lineHeight: '20px',
    margin: theme.spacing(1),
  },
  percentBoxWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));

export interface CardProps {
  faqAnalyticsData: FaqAnalyticsData;
}

export const ResponseStatsCard: VFC<CardProps> = ({ faqAnalyticsData }) => {
  const classes = useStyles();

  const {
    faq_count: faqCount,
    approved_faq_count: approvedCount,
    rejected_faq_count: rejectedCount,
  } = faqAnalyticsData;

  const approvedPercent =
    approvedCount > 0
      ? Math.floor((approvedCount * 100) / faqCount)
      : approvedCount;

  const rejectedPercent =
    rejectedCount > 0
      ? Math.floor((rejectedCount * 100) / faqCount)
      : rejectedCount;

  return (
    <div className={classes.card}>
      <div className={classes.titleWrapper}>
        <Typography display="inline" variant="body2" className={classes.title}>
          <FormattedMessage {...messages.responseStats} />
        </Typography>
        <div className={classes.countWrapper}>
          <Typography display="inline" variant="h1" className={classes.count}>
            {faqCount}
          </Typography>
          <Typography
            display="inline"
            variant="body2"
            className={classes.subText}
          >
            <FormattedMessage {...messages.faqCount} />
          </Typography>
        </div>
      </div>
      <div className={classes.percentBoxWrapper}>
        <FeedbackPercentageBox metric="markedHelpful" value={approvedPercent} />
        <FeedbackPercentageBox
          metric="markedUnhelpful"
          value={rejectedPercent}
        />
      </div>
    </div>
  );
};
