export const INITIALS_CONTAINER_WIDTH = {
  small: 24,
  medium: 32,
  large: 50,
  extraLarge: 72,
};

// as percentage of font size of selected typography
export const INITIALS_FONT_SIZE = {
  small: 1,
  medium: 0.785,
  large: 1,
  extraLarge: 1.5,
};
