import { Button } from '@fountain/fountain-ui-components';
import { Button as MuiButton, makeStyles, Typography } from '@material-ui/core';
import { JobMatcherConditionOptions } from 'api-clients/monolith';
import React, { FC, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { AddItemsCard } from 'containers/WorkflowEditor/components/AddItemsCard';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import {
  ConditionType,
  JobRouterCondition,
  JobRouterConditionRow,
} from './JobRouterCondition';
import { messages } from './messages';

const useStyles = makeStyles(theme => ({
  addButton: {
    color: theme.palette.common.blue400,
  },
}));

export interface JobRouterConditionSetProps {
  conditions: JobRouterConditionRow[];
  conditionTypeOptions: ConditionType[];
  conditionTypes: JobMatcherConditionOptions;
  onAddCondition: () => void;
  onDeleteCondition: (id: string) => () => void;
  onChangeConditionType: (id: string) => (type: ConditionType['value']) => void;
  onChangeConditionValue: (id: string) => (
    values: Array<{
      label: string;
      value: number;
    }> | null,
  ) => void;
}

export const JobRouterConditionSet: FC<JobRouterConditionSetProps> = ({
  conditions,
  conditionTypeOptions,
  conditionTypes,
  onAddCondition,
  onDeleteCondition,
  onChangeConditionType,
  onChangeConditionValue,
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const { updateStageResult } = useContext(StageContext);
  const {
    positions,
    location_groups: locationGroups,
    locations,
    workflows,
  } = conditionTypes;

  const isEmpty = !conditions.length;

  const conditionOptionsByType = {
    Position: positions,
    Location: locations,
    LocationGroup: locationGroups,
    Workflow: workflows,
  };

  const hasAddedMaximumConditionRows =
    conditions.length >= conditionTypeOptions.length;

  if (isEmpty) {
    return (
      <AddItemsCard
        addButton={
          <Button
            type="secondary"
            size="small"
            autoWidth
            className={styles.addButton}
            onClick={onAddCondition}
          >
            <Typography variant="h4">
              <FormattedMessage {...messages.addCondition} />
            </Typography>
          </Button>
        }
        description={intl.formatMessage(messages.noConditionsDescription)}
        errorText={intl.formatMessage(messages.conditionsRequired)}
      />
    );
  }

  return (
    <>
      {conditions.map((condition, index) => {
        if (!condition.id) {
          return null;
        }
        const conditionOptions = condition.resource
          ? conditionOptionsByType[condition.resource]
          : [];

        const valueOptions = (conditionOptions ?? []).map(({ name, id }) => ({
          label: name,
          value: id,
        }));

        return (
          <JobRouterCondition
            key={condition.id}
            condition={condition}
            index={index}
            isError={updateStageResult.status === 'error'}
            onChangeConditionType={onChangeConditionType(condition.id)}
            onChangeConditionValue={onChangeConditionValue(condition.id)}
            onDelete={onDeleteCondition(condition.id)}
            typeOptions={conditionTypeOptions}
            valueOptions={valueOptions}
          />
        );
      })}
      <MuiButton
        aria-label="Add Conditions"
        color="primary"
        disabled={hasAddedMaximumConditionRows}
        onClick={onAddCondition}
      >
        <Typography variant="body2" color="primary">
          <FormattedMessage {...messages.addConditions} />
        </Typography>
      </MuiButton>
    </>
  );
};
