import { WorkflowHiredStage } from 'api-clients/monolith';
import React, { VFC } from 'react';

import { StageSection } from 'containers/WorkflowEditor/components/StageSection';

import { IdleMoveRule } from '../../../IdleMoveRule';
import { StageSettingMessageCard } from '../../../StageSettingMessageCard';
import { AdvancedSettingsCard } from './AdvancedSettingsCard';

export interface HiredStageProps {
  stage: WorkflowHiredStage;
}

export const HiredStage: VFC<HiredStageProps> = ({ stage }) => {
  return (
    <div data-testid="hiredStage">
      <div>
        <StageSection>
          <IdleMoveRule stage={stage} />
        </StageSection>
        <StageSection>
          <StageSettingMessageCard
            messageType="hired"
            stageExternalId={stage.external_id}
          />
        </StageSection>
        <StageSection>
          <StageSettingMessageCard
            messageType="trigger"
            stageExternalId={stage.external_id}
          />
        </StageSection>
        <StageSection>
          <AdvancedSettingsCard stage={stage} />
        </StageSection>
      </div>
    </div>
  );
};
