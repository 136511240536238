import { Alert, Button } from '@fountain/fountain-ui-components';
import { Grid, Link } from '@material-ui/core';
import { SidebarStage } from 'api-clients/monolith';
import { useSimpleToggle } from 'hooks';
import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ConfirmationModal } from '../../../../components/ConfirmationModal';
import { AddRuleModal } from './AddRuleModal';
import { CloneStageModal } from './CloneStageModal';
import { messages } from './messages';
import { NewStageModal } from './NewStageModal';
import { StageColumnHeaderMenu } from './StageColumnHeaderMenu';
import { useStyles } from './styles';

export interface StageColumnHeaderProps {
  stages: SidebarStage[];
  unsupportedAxStages: string[];
  isStageAdditionBlocked: boolean;
  refetchStages: () => void;
  onDiscardChanges: () => void;
}

export const StageColumnHeader: FC<StageColumnHeaderProps> = ({
  refetchStages,
  onDiscardChanges,
  isStageAdditionBlocked,
  stages,
  unsupportedAxStages,
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const {
    showContent: isUnsavedChangesPromptOpen,
    on: openUnsavedChangesPrompt,
    off: closeUnsavedChangesPrompt,
  } = useSimpleToggle();

  const {
    showContent: isMenuOpen,
    on: openMenu,
    off: closeMenu,
  } = useSimpleToggle();

  const {
    showContent: isNewStageModalOpen,
    on: openNewStageModal,
    off: closeNewStageModal,
  } = useSimpleToggle();

  const {
    showContent: isCloneStageModaOpen,
    on: openCloneStageModal,
    off: closeCloneStageModal,
  } = useSimpleToggle();

  const {
    showContent: isAddRuleModalOpen,
    on: openAddRuleModal,
    off: closeAddRuleModal,
  } = useSimpleToggle();

  const handleAddStage = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    if (isStageAdditionBlocked) {
      openUnsavedChangesPrompt();
      return;
    }

    openMenu();
  };

  const handleAddRule = () => {
    setAnchorEl(null); // clear anchorEl in case it has been set and not cleared
    if (isStageAdditionBlocked) {
      openUnsavedChangesPrompt();
      return;
    }

    openAddRuleModal();
  };

  const handleClose = () => {
    setAnchorEl(null);
    closeMenu();
  };

  const handleUnsavedChangesConfirm = () => {
    onDiscardChanges();
    closeUnsavedChangesPrompt();
    if (anchorEl) {
      openMenu();
    } else {
      openAddRuleModal();
    }
  };

  return (
    <>
      <Grid
        aria-label={intl.formatMessage(messages.stageColumnHeader)}
        className={styles.buttonWrapper}
        container
      >
        <Button
          onClick={handleAddStage}
          disableRipple
          className={styles.button}
          type="secondary"
        >
          <FormattedMessage {...messages.addStage} />
        </Button>
        <Button
          onClick={handleAddRule}
          disableRipple
          className={styles.button}
          type="secondary"
        >
          <FormattedMessage {...messages.addRule} />
        </Button>
      </Grid>
      {unsupportedAxStages.length > 0 && (
        <Alert fullWidth type="warning">
          <>
            <FormattedMessage
              {...messages.unsupportedAxStageTypes}
              values={{
                link: (text: React.ReactFragment) => (
                  <Link
                    href="https://new.fountain.com/announcements/applicant-experience-2-0-changelog"
                    target="_blank"
                  >
                    {text}
                  </Link>
                ),
              }}
            />

            <div className={styles.unsupportedAxStages}>
              {unsupportedAxStages.join(', ')}
            </div>
          </>
        </Alert>
      )}
      {Boolean(anchorEl) && (
        <StageColumnHeaderMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          isMenuOpen={isMenuOpen}
          openCloneStageModal={openCloneStageModal}
          openNewStageModal={openNewStageModal}
        />
      )}
      {isNewStageModalOpen && (
        <NewStageModal
          onClose={closeNewStageModal}
          open={isNewStageModalOpen}
          stages={stages}
          refetchStages={refetchStages}
        />
      )}
      {isCloneStageModaOpen && (
        <CloneStageModal
          onClose={closeCloneStageModal}
          open={isCloneStageModaOpen}
          refetchStages={refetchStages}
          stages={stages}
        />
      )}
      {isAddRuleModalOpen && (
        <AddRuleModal
          onClose={closeAddRuleModal}
          refetchStages={refetchStages}
          stages={stages}
        />
      )}
      {isUnsavedChangesPromptOpen && (
        <ConfirmationModal
          ariaLabelledBy={intl.formatMessage(messages.unsavedChangesModal)}
          onCancel={handleUnsavedChangesConfirm}
          onClose={closeUnsavedChangesPrompt}
          onConfirm={closeUnsavedChangesPrompt}
          title={intl.formatMessage(messages.unsavedChanges)}
          bodyContent={
            <FormattedMessage {...messages.unsavedChangesBodyText} />
          }
          cancelButtonContent={<FormattedMessage {...messages.discard} />}
          confirmButtonContent={
            <FormattedMessage {...messages.continueEditing} />
          }
        />
      )}
    </>
  );
};
