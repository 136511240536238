import { WorkflowCustomStage } from 'api-clients/monolith';
import React, { VFC } from 'react';

import { IdleMoveRule } from 'containers/WorkflowEditor/components/IdleMoveRule';
import { Rules } from 'containers/WorkflowEditor/components/Rules';
import { StageSection } from 'containers/WorkflowEditor/components/StageSection';
import { StageSettingMessageCard } from 'containers/WorkflowEditor/components/StageSettingMessageCard';

import { AdvancedSettings } from './AdvancedSettings';

export const CustomStage: VFC<{ stage: WorkflowCustomStage }> = ({ stage }) => {
  const isSubstage = stage.parent_stage_id;

  return (
    <div data-testid="customStage">
      {!isSubstage && (
        <>
          <StageSection>
            <Rules stage={stage} />
          </StageSection>
          <StageSection>
            <IdleMoveRule stage={stage} />
          </StageSection>
          <StageSection>
            <StageSettingMessageCard
              messageType="automated"
              stageExternalId={stage.external_id}
            />
          </StageSection>
          <StageSection>
            <StageSettingMessageCard
              messageType="trigger"
              stageExternalId={stage.external_id}
            />
          </StageSection>
        </>
      )}
      <StageSection>
        <AdvancedSettings stage={stage} />
      </StageSection>
    </div>
  );
};
