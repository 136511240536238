/**
 *
 * RecipientsModal Component
 *
 */

import {
  Button,
  SimpleModal,
  SimpleModalFooter,
  SimpleModalHeader,
  SimpleModalTitle,
} from '@fountain/fountain-ui-components';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { classNames } from 'react-extras';
import { compose } from 'redux';

import ApplicantIcon from 'images/applicants';
import WarningIcon from 'images/WarningSvg';

import useStyles from './styles';

function RecipientsModal({
  handleClose,
  applicantsWithoutRedo,
  WarningsCount,
  maxRecipients,
  selectedApplicantsCount,
}) {
  const classes = useStyles();

  const onClickDone = () => {
    handleClose();
  };

  return (
    <SimpleModal maxWidth={512} handleClose={handleClose}>
      <SimpleModalHeader onClose={handleClose}>
        <Grid>
          <SimpleModalTitle>Edit Recipients</SimpleModalTitle>
          <Grid
            container
            alignItems="center"
            className={classes.applicantsCountContainer}
          >
            <ApplicantIcon className={classes.applicantIcon} />
            <Typography variant="overline">
              {selectedApplicantsCount} Recipient
              {selectedApplicantsCount > 1 && 's'} (max. {maxRecipients})
            </Typography>
            {WarningsCount}
          </Grid>
        </Grid>
      </SimpleModalHeader>
      <Grid className={classes.modalBody}>
        <Grid container className={classes.warningSection} wrap="nowrap">
          <WarningIcon className={classes.warningIcon} />
          <Grid>
            <Grid>
              <Typography className={classes.warningText} variant="overline">
                No problems were found with these selected applications.
              </Typography>
            </Grid>
            <Grid>
              <Typography className={classes.warningText} variant="overline">
                Applicants will not be contacted.
              </Typography>
            </Grid>
            <Grid className={classes.applicantsWithoutRedoContainer}>
              {applicantsWithoutRedo.length &&
                applicantsWithoutRedo.map(applicantWithoutRedo => (
                  <Grid
                    key={applicantWithoutRedo.external_id}
                    className={classes.applicantWithoutRedo}
                  >
                    <Typography variant="overline" color="textPrimary">
                      {applicantWithoutRedo.name}{' '}
                      {`<${applicantWithoutRedo.email}>`}
                    </Typography>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SimpleModalFooter>
        {selectedApplicantsCount > maxRecipients && (
          <Grid container alignItems="center">
            <WarningIcon className={classNames(classes.warningIconRed)} />
            <Typography className={classes.errorText} variant="overline">
              Maximum {maxRecipients} Recipients
            </Typography>
          </Grid>
        )}
        <Button
          className={classes.cancelButton}
          onClick={onClickDone}
          size="small"
          color="primary"
        >
          Okay
        </Button>
      </SimpleModalFooter>
    </SimpleModal>
  );
}

RecipientsModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  WarningsCount: PropTypes.node,
  maxRecipients: PropTypes.number,
  applicantsWithoutRedo: PropTypes.array,
  selectedApplicantsCount: PropTypes.number,
};

export default compose(memo)(RecipientsModal);
