import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addNewRule: {
    id: `app.containers.WorkflowEditor.components.StageColumnHeader.AddRuleModal.addNewRule`,
    defaultMessage: 'Add a new rule',
  },
  addRule: {
    id: `app.containers.WorkflowEditor.components.StageColumnHeader.AddRuleModal.addRule`,
    defaultMessage: 'Add Rule',
  },
  addRuleTitle: {
    id: `app.containers.WorkflowEditor.components.StageColumnHeader.AddRuleModal.addRuleTitle`,
    defaultMessage: 'Add a rule title',
  },
  cancel: {
    id: `app.containers.WorkflowEditor.components.StageColumnHeader.AddRuleModal.cancel`,
    defaultMessage: 'Cancel',
  },
  distributionBasis: {
    id: `app.containers.WorkflowEditor.components.StageColumnHeader.AddRuleModal.distributionBasis`,
    defaultMessage: 'Distribution Basis',
  },
  distributeRuleText: {
    id: `app.containers.WorkflowEditor.components.StageColumnHeader.AddRuleModal.distributeRuleText`,
    defaultMessage:
      'This rule type allows incoming applicants to be distributed across selected openings based on a pre-set percentage or hiring needs.',
  },
  hiringNeedBasedText: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.AddRuleModal.hiringNeedBasedText',
    defaultMessage:
      'Automatically distribute applicants to up to 20 openings based on a combination of hiring goals, hires to date and opening conversion rate.',
  },
  moveRuleText: {
    id: `app.containers.WorkflowEditor.components.StageColumnHeader.AddRuleModal.moveRuleText`,
    defaultMessage:
      "This rule type can be used to fast-track, auto-reject, or update an applicant's data when matching a certain criteria.",
  },
  percentageBasedText: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.AddRuleModal.percentageBasedText',
    defaultMessage:
      'Automatically distribute applicants to up to 20 openings based on a target percent allocation. It can manually be adjusted as your hiring needs change.',
  },
  ruleCreateSuccess: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.stageColumnHeader.ruleCreateSuccess',
    defaultMessage: 'Rule created successfully',
  },
  ruleType: {
    id: `app.containers.WorkflowEditor.components.StageColumnHeader.AddRuleModal.ruleType`,
    defaultMessage: 'Rule Type',
  },
  ruleStageLoadError: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.NewStageModal.ruleStageLoadError',
    defaultMessage: 'Failed to load available rule stages, please try again.',
  },
});
