/*
 *
 * JobsStagesFilterPopup constants
 *
 */

export const FETCH_STAGE_FILTERS_INIT =
  'app/JobsStagesFilterPopup/FETCH_STAGE_FILTERS_INIT';
export const FETCH_STAGE_FILTERS_SUCCESS =
  'app/JobsStagesFilterPopup/FETCH_STAGE_FILTERS_SUCCESS';
export const FETCH_STAGE_FILTERS_ERROR =
  'app/JobsStagesFilterPopup/FETCH_STAGE_FILTERS_ERROR';
export const CREATE_STAGE_FILTER_INIT =
  'app/JobsStagesFilterPopup/CREATE_STAGE_FILTER_INIT';
export const CREATE_STAGE_FILTER_SUCCESS =
  'app/JobsStagesFilterPopup/CREATE_STAGE_FILTER_SUCCESS';
export const CREATE_STAGE_FILTER_ERROR =
  'app/JobsStagesFilterPopup/CREATE_STAGE_FILTER_ERROR';
export const UPDATE_STAGE_FILTER_INIT =
  'app/JobsStagesFilterPopup/UPDATE_STAGE_FILTER_INIT';
export const UPDATE_STAGE_FILTER_SUCCESS =
  'app/JobsStagesFilterPopup/UPDATE_STAGE_FILTER_SUCCESS';
export const UPDATE_STAGE_FILTER_ERROR =
  'app/JobsStagesFilterPopup/UPDATE_STAGE_FILTER_ERROR';
export const DELETE_STAGE_FILTER_INIT =
  'app/JobsStagesFilterPopup/DELETE_STAGE_FILTER_INIT';
export const DELETE_STAGE_FILTER_SUCCESS =
  'app/JobsStagesFilterPopup/DELETE_STAGE_FILTER_SUCCESS';
export const DELETE_STAGE_FILTER_ERROR =
  'app/JobsStagesFilterPopup/DELETE_STAGE_FILTER_ERROR';
export const SET_SELECTED_FILTER =
  'app/JobsStagesFilterPopup/SET_SELECTED_FILTER';
export const APPLY_NON_SAVED_FILTER_INIT =
  'app/JobsStagesFilterPopup/APPLY_NON_SAVED_FILTER_INIT';
export const FETCH_FILTER_SET_SUCCESS =
  'app/JobsStagesFilterPopup/FETCH_FILTER_SET_SUCCESS';
export const FUNNELS_SEARCH_COMPLETE =
  'app/JobsStagesFilterPopup/FUNNELS_SEARCH_COMPLETE';
export const UPDATE_FUNNELS_SEARCH =
  'app/JobsStagesFilterPopup/UPDATE_FUNNELS_SEARCH';
export const FUNNELS_SEARCH_INIT =
  'app/JobsStagesFilterPopup/FUNNELS_SEARCH_INIT';

export const DEFAULT_HEIGHT = 60;

export const DEFAULT_STAGE_FILTER_OPTION = {
  id: 'default',
  title: 'Default',
  stages: [],
};
