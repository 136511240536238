import { Grid, makeStyles } from '@material-ui/core';
import { PartnerOptionDataField } from 'api-clients/monolith';
import React, { useEffect, useState, VFC } from 'react';
import { useSelector } from 'react-redux';

import { DETAIL_TYPES } from 'components/ApplicantDrawerDetailsList/constants';
import { makeSelectWhoami } from 'containers/Auth_old/selectors';
import useGetPartnerOptionData from 'hooks/partners/useGetPartnerOptionData';

import { Field } from './field';
import { ReadonlyContent } from './ReadOnlyContent';

const useStyles = makeStyles(theme => ({
  editPartnerData: {
    color: theme.palette.common.black,
  },
}));

interface EditPartnerOptionDataFormProps {
  applicantId: string;
  stageId: string;
  partnerOptionId: string;
  partnerStatusId?: string;
}

export const EditPartnerOptionDataForm: VFC<EditPartnerOptionDataFormProps> = ({
  applicantId,
  stageId,
  partnerOptionId,
  partnerStatusId,
}) => {
  const whoami = useSelector(makeSelectWhoami());
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [partnerOptionDataId, setPartnerOptionDataId] = useState('');
  const [partnerOptionData, setPartnerOptionData] = useState<
    PartnerOptionDataField[]
  >([]);

  const { result, refetch } = useGetPartnerOptionData({
    applicantId,
    stageId,
    partnerOptionId,
    partnerStatusId,
  });

  useEffect(() => {
    if (result.status === 'ready' && result.data) {
      setPartnerOptionData(result.data.data_fields);
      setPartnerOptionDataId(result.data.partner_option_data_id);
      setIsLoading(false);
    }
  }, [result]);

  return isLoading ? (
    <></>
  ) : (
    <Grid>
      {partnerOptionData.map(data => {
        switch (data.type) {
          case DETAIL_TYPES.availability:
          case DETAIL_TYPES.checkboxes:
          case DETAIL_TYPES.date_picker:
          case DETAIL_TYPES.dropdown:
          case DETAIL_TYPES.license_data:
          case DETAIL_TYPES.radio:
          case DETAIL_TYPES.text_area:
          case DETAIL_TYPES.text_field:
            return (
              <Field
                className={classes.editPartnerData}
                applicantId={applicantId}
                detail={data}
                key={`${applicantId}-${data.key}`}
                partnerOptionDataId={partnerOptionDataId}
                refreshData={refetch}
              />
            );
          case DETAIL_TYPES.file:
          case DETAIL_TYPES.history_address:
          case DETAIL_TYPES.history_employment:
          case DETAIL_TYPES.address:
            // Since Address is *not* supported yet because of this bug:
            // https://fountain.atlassian.net/browse/HUS-1767
            // making this readonly until the issue is fixed.
            return (
              whoami.feature_flags[
                'show-read-only-partner-data-in-edit-pop-up'
              ] && (
                <ReadonlyContent
                  fieldType={data.type}
                  details={data}
                  key={`${applicantId}-${data.key}`}
                />
              )
            );
          default:
            return <></>;
        }
      })}
    </Grid>
  );
};

export default EditPartnerOptionDataForm;
