import styled from 'styled-components';

const Wrapper = styled.div`
  height: 50vh;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  h1 {
    text-align: center;
  }
`;

export default Wrapper;
