import { Alert } from '@fountain/fountain-ui-components';
import { FunnelDetails, WorkflowStageDetail } from 'api-clients/monolith';
import React, { FC, useContext, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { StageDetailFooter } from '../../../../StageDetailFooter';
import { CustomStage } from '../../CustomStage';
import { DataCollectionStage } from '../../DataCollectionStage';
import { DocumentSigningStage } from '../../DocumentSigningStage';
import { I9FormStage } from '../../I9FormStage';
import { SchedulerStage, SchedulerStageZero } from '../../SchedulerStage';
import { UnsupportedStage } from '../../UnsupportedStage';
import { W4Stage } from '../../W4Stage';
import { messages } from './messages';
import { useStyles } from './styles';

interface StageContainerProps {
  opening: FunnelDetails;
  isHelloSignProvider?: boolean;
  subStage: WorkflowStageDetail;
}

const SubstageContainer: FC<StageContainerProps> = ({
  isHelloSignProvider,
  opening,
  subStage,
}) => {
  switch (subStage.type) {
    case 'CustomStage':
      return <CustomStage stage={subStage} />;
    case 'DataCollectionStage':
      return <DataCollectionStage stage={subStage} />;
    // DocumentSigningStage handles multiple stage types.
    // `DocumentSigningStage` === fountain whitelabeled hellosign
    // `DocumentSignatureStage` can be docusign or the deprecated standalone HelloSign
    case 'DocumentSigningStage':
    case 'DocumentSignatureStage':
      if (subStage.type === 'DocumentSignatureStage' && isHelloSignProvider) {
        return (
          <UnsupportedStage
            bodyText={
              <FormattedMessage
                {...messages.unsupportedStage}
                values={{ stageType: 'HelloSign Document Signing Stage' }}
              />
            }
          />
        );
      }
      return <DocumentSigningStage stage={subStage} />;
    case 'I9FormStage':
      return <I9FormStage stage={subStage} />;
    case 'SchedulerStage':
      return (
        <SchedulerStage
          stage={subStage}
          openingExternalId={opening.external_id}
        />
      );
    case 'SchedulerV2Stage':
      return (
        <SchedulerStageZero
          stage={subStage}
          address={opening.location?.address}
          openingExternalId={opening.external_id}
        />
      );
    case 'W4FederalFormStage':
    case 'W4StateFormStage':
      return <W4Stage stage={subStage} />;
    default:
      return (
        <UnsupportedStage
          bodyText={
            <FormattedMessage
              {...messages.stageComingSoon}
              values={{ stageType: subStage.type }}
            />
          }
        />
      );
  }
};

export type SubstageContainerByTypeProps = {
  opening: FunnelDetails;
};

export const SubstageContainerByType: FC<SubstageContainerByTypeProps> = ({
  opening,
}) => {
  const classes = useStyles();
  const contentRef = useRef<HTMLDivElement>(null);

  const { stage, updateStageResult } = useContext(StageContext);

  return (
    <div className={classes.stageContainer} ref={contentRef}>
      {updateStageResult.isError && updateStageResult.error && (
        <Alert fullWidth type="danger" className={classes.errorsAlert}>
          <FormattedMessage {...messages.errorsPresent} />
        </Alert>
      )}
      <SubstageContainer
        isHelloSignProvider={
          (stage.type === 'DocumentSignatureStage' &&
            stage.additional_info
              .is_document_signature_stage_hellosign_provider) ??
          false
        }
        opening={opening}
        subStage={stage}
      />
      <StageDetailFooter externalId={stage.external_id} />
    </div>
  );
};
