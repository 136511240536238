import { defineMessages } from 'react-intl';

export const scope =
  'app.containers.AutomatedResponses.AutomatedResponseToggleModel';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Change Automated Responses Model Mode',
  },
  modelName: {
    id: `${scope}.modelName`,
    defaultMessage: 'Model Name',
  },
  mode: {
    id: `${scope}.mode`,
    defaultMessage: 'Mode',
  },
  updateModelSuccess: {
    id: `${scope}.updateModelSuccess`,
    defaultMessage: `Updated successfully.`,
  },
  updateModelError: {
    id: `${scope}.updateModelError`,
    defaultMessage: `Update failed. Please try again.`,
  },
  threshold: {
    id: `${scope}.threshold`,
    defaultMessage: 'Thresold',
  },
  confidenceThresholdErrorMessage: {
    id: `${scope}.confidenceThresholdErrorMessage`,
    defaultMessage: 'must be in the range {min} - {max}',
  },
});
