import { SchedulerService } from 'api-clients/monolith';
import { useApiService } from 'hooks';
import { useCallback } from 'react';

export const useGetAvailabilityRules = () => {
  const fetchAvailabillityRules = useCallback(
    () => SchedulerService.getInternalApiSchedulerAvailabilityRules(),
    [],
  );

  return useApiService(fetchAvailabillityRules);
};
