import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  employerAddress: {
    width: 400,
  },
  employerAddress2: {
    width: 230,
  },
  addEmployerInfoButton: {
    width: 'inherit',
  },
  employerName: {
    width: 340,
  },
  city: {
    width: 200,
  },
  state: {
    minWidth: 200,
  },
  employerIdentificationNumber: {
    width: 320,
  },
  trashcanIcon: {
    fontSize: theme.spacing(1.5),
  },
  zipcode: {
    width: 120,
  },
}));
