import { Theme } from '@material-ui/core';
import { makeStyles, StyleRules } from '@material-ui/core/styles';

const generateStyles = (theme: Theme) => {
  const styles: StyleRules = {
    td: {
      minWidth: theme.customWidths.unreadMessagesCountColumnWidth,
      maxWidth: theme.customWidths.unreadMessagesCountColumnWidth,
    },
    unreadMessagesCell: {
      width: 40,
      margin: 'auto',
    },
    numMessages: {
      fontSize: '0.6875rem',
    },
    unreadMessagesCount: {
      height: 16,
      minWidth: 24,
      textAlign: 'center',
      display: 'inline-block',
      backgroundColor: theme.palette.common.red400,
      color: theme.palette.common.white,
      borderRadius: 3,
      position: 'relative',
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),

      '&::after': {
        content: "''",
        display: 'inline-block',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        width: 0,
        height: 0,
        border: `${theme.spacing(0.5)}px solid transparent`,
        borderTopColor: theme.palette.common.red400,
        borderBottom: 0,
        borderLeft: 0,
        marginLeft: theme.spacing(-0.25),
        marginBottom: theme.spacing(-0.5),
      },
    },
  };
  return styles;
};

const useStyles: () => Record<keyof ReturnType<typeof generateStyles>, string> =
  makeStyles(generateStyles);

export default useStyles;
