import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const generateStyles = (theme: Theme) => ({
  td: {
    padding: theme.spacing(0, 1),
    minWidth: theme.customWidths.progressColumnWidth,
    maxWidth: theme.customWidths.progressColumnWidth,
  },
  progressBarContainer: {
    backgroundColor: theme.palette.common.gray200,
    borderRadius: 5,
    height: 6,
    width: '100%',
    display: 'inline-block',
  },
  progressBar: {
    backgroundColor: theme.palette.common.gray400,
    borderRadius: 5,
    height: 6,
    maxWidth: '100%',
  },
});

const useStyles: () => Record<keyof ReturnType<typeof generateStyles>, string> =
  makeStyles(generateStyles);

export default useStyles;
