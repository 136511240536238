import { ApiError, CancelablePromise, CancelError } from 'api-clients/monolith';
import { useCallback, useEffect, useState } from 'react';

import {
  buildErrorState,
  buildLoadingState,
  buildReadyState,
  buildreloadState,
  ReloadableResultStatus,
} from '../api/resultStatus';

export interface UseApiService<T, E> {
  result: ReloadableResultStatus<T, E>;
  refetch: () => void;
}

/**
 * Should be kept in sync with the copy of `applicant-ui`'s `useApiService`
 * hook to allow both to reference a single `:packages/react-hooks` or similar
 *
 * @see {@link :packages/applicant-ui/app/hooks/useApiService.ts}
 */
export const useApiService = <T, E = unknown>(
  // This service should be memoized before passed in, for example by using `useCallback`.
  service: () => CancelablePromise<T>,
): UseApiService<T, E> => {
  const [result, setResult] = useState<ReloadableResultStatus<T, E>>(
    buildLoadingState(),
  );
  const [queryKey, setQueryKey] = useState(false);

  const setLoadingOrReloading = useCallback(() => {
    setResult(currentResult => {
      // Has loaded once succesfully and has valid cached data
      if (currentResult.status === 'ready') {
        return buildreloadState<T>(currentResult.data);
      }
      if (currentResult.status !== 'loading') {
        return buildLoadingState();
      }
      return currentResult;
    });
  }, []);

  const refetch = useCallback(() => {
    setQueryKey(currentKey => !currentKey);
  }, []);

  useEffect(() => {
    setLoadingOrReloading();
    const promise = service();
    promise
      .then(data => {
        setResult(buildReadyState(data));
      })
      .catch((e: Error) => {
        if (e instanceof CancelError) {
          return;
        }
        if (e instanceof ApiError) {
          setResult(
            buildErrorState((e.body as E) || (e.statusText as unknown as E)),
          );
          return;
        }
        setResult(buildErrorState());
      });
    return () => promise.cancel();
  }, [service, queryKey, setLoadingOrReloading]);

  return { result, refetch };
};
