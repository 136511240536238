import { WorkflowW4FormStage } from 'api-clients/monolith';
import React, { FC } from 'react';

import { AdvancedSettings } from 'containers/WorkflowEditor/components/AdvancedSettings';
import { IdleMoveRule } from 'containers/WorkflowEditor/components/IdleMoveRule';
import { StageSection } from 'containers/WorkflowEditor/components/StageSection';
import { StageSettingMessageCard } from 'containers/WorkflowEditor/components/StageSettingMessageCard';
import { EmployerInformation } from 'containers/WorkflowEditor/components/WorkflowEditorContent/StageDetail/W4Stage/EmployerInformation';
import { W4FederalForm } from 'containers/WorkflowEditor/components/WorkflowEditorContent/StageDetail/W4Stage/W4FederalForm';
import { W4StateForm } from 'containers/WorkflowEditor/components/WorkflowEditorContent/StageDetail/W4Stage/W4StateForm';

export interface W4StageProps {
  stage: WorkflowW4FormStage;
}

export const W4Stage: FC<W4StageProps> = ({ stage }) => {
  const messageType =
    stage.type === 'W4StateFormStage' ? 'w4_state_form' : 'w4_federal_form';

  const isSubstage = Boolean(stage.parent_stage_id);

  return (
    <div data-testid={stage.type}>
      {!isSubstage && (
        <StageSection>
          {stage.type === 'W4StateFormStage' && <W4StateForm stage={stage} />}
          {stage.type === 'W4FederalFormStage' && (
            <W4FederalForm stage={stage} />
          )}
        </StageSection>
      )}
      <StageSection>
        <EmployerInformation stage={stage} />
      </StageSection>
      {!isSubstage && (
        <>
          <StageSection>
            <IdleMoveRule stage={stage} />
          </StageSection>
          <StageSection>
            <StageSettingMessageCard
              messageType={messageType}
              stageExternalId={stage.external_id}
            />
          </StageSection>
          <StageSection>
            <StageSettingMessageCard
              messageType="trigger"
              stageExternalId={stage.external_id}
            />
          </StageSection>
        </>
      )}
      {stage.account_go_enabled && (
        <StageSection>
          <AdvancedSettings stage={stage} />
        </StageSection>
      )}
    </div>
  );
};
