export const DEFAULT_AGENT_VALUES = {
  first_name: '',
  last_name: '',
  email: '',
  relationship_with_consumer: '',
  proof_of_authorization_file: '',
};

export const DEFAULT_CONSUMER_VALUES = {
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  address_1: '',
  address_2: '',
  city: '',
  state: '',
  zipcode: '',
};

export const DEFAULT_CCPA_REQUEST_VALUES = {
  consumer_relationship_with_fountain: '',
  information_request: false,
  access_request: false,
  deletion_request: false,
};

export const SUPPORTED_FORMATS = [
  'application/pdf',
  'image/png',
  'image/apng',
  'image/bmp',
  'image/jpeg',
];

export const REQUEST_TYPES = {
  INFORMATION_REQUEST: 'information_request',
  ACCESS_REQUEST: 'access_request',
  DELETION_REQUEST: 'deletion_request',
};

export const REQUESTOR_TYPES = {
  consumer: "I'm a consumer",
  authorized_agent: "I'm an Authorized Agent (Verification Required)",
};

// size in bytes (~10MB)
export const MAX_FILE_SIZE = 10000000;
