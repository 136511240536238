import { IconButton } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import React, { FC } from 'react';

import CustomDropzone from 'components/CustomDropzone';
import { EditButton } from 'containers/DataValidationModal/components/FileField/EditButton';
import { FileFieldTitle } from 'containers/DataValidationModal/components/FileField/FileFieldTitle';
import useStyles from 'containers/DataValidationModal/components/FileField/styles';
import { SharedPreviewProps } from 'containers/DataValidationModal/components/FileField/types';
import LinkIconBlack from 'images/link-icon-black.svg';
import TrashCan from 'images/trashcan.svg';

export type MobilePreviewProps = SharedPreviewProps;

export const MobilePreview: FC<MobilePreviewProps> = ({
  detail,
  error,
  file,
  handleUpload,
  isRequired,
  applicantBlob,
  isMobile,
  setIsEditable,
  showButtons,
  url,
}) => {
  const styles = useStyles();
  const editButtonProps = {
    applicantBlob,
    isMobile,
    setIsEditable,
    showButtons,
    url,
  };
  return (
    <>
      <FileFieldTitle
        isRequired={isRequired}
        subtitle={detail.subtitle}
        title={detail.title}
      >
        <EditButton {...editButtonProps} />
      </FileFieldTitle>
      <CustomDropzone
        isMobile={isMobile}
        error={error}
        onChangeStatus={handleUpload}
      />
      <Grid
        className={styles.mobileUploadedFileContainer}
        container
        direction="row"
      >
        <IconButton className={styles.linkIconBlack}>
          <img
            className={styles.linkIconBlack}
            src={LinkIconBlack}
            alt="attached-file"
          />
        </IconButton>
        <Typography className={styles.mobileUploadedFile}>
          {file ? file.name : applicantBlob[0].filename}
        </Typography>
        <IconButton type="button" onClick={() => setIsEditable(true)}>
          <img className={styles.trashIcon} alt="remove-file" src={TrashCan} />
        </IconButton>
      </Grid>
    </>
  );
};
