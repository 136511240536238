/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateWhatsAppTemplateParams } from '../models/CreateWhatsAppTemplateParams';
import type { WhatsAppTemplateDetail } from '../models/WhatsAppTemplateDetail';
import type { WhatsAppTemplateList } from '../models/WhatsAppTemplateList';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultWhatsAppMessageTemplateService {

    /**
     * Retrieves Available Default WhatsApp templates
     * Returns a list of default whatsapp templates to an account
     * @param page Pagination defaults to 0
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiMessageTemplateDefaultWhatsAppMessageTemplates(
        page?: number,
    ): CancelablePromise<{
        message_templates: Array<WhatsAppTemplateList>;
        pagination?: {
            current_page: number;
            next_page: number | null;
            per_page: number;
            total_count: number;
            total_pages: number;
        };
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/message_template/default_whats_app_message_templates',
            query: {
                'page': page,
            },
        });
    }

    /**
     * Create Default WhatsApp Message Template
     * @param requestBody Default WhatsApp Message Template
     * @returns any created
     * @throws ApiError
     */
    public static postInternalApiMessageTemplateDefaultWhatsAppMessageTemplates(
        requestBody: CreateWhatsAppTemplateParams,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/message_template/default_whats_app_message_templates',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Retrieves template Details
     * @param id whats template ID
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiMessageTemplateDefaultWhatsAppMessageTemplates1(
        id: number,
    ): CancelablePromise<{
        message_template: WhatsAppTemplateDetail;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/message_template/default_whats_app_message_templates/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Delete WhatsApp Template
     * @param id whats template ID
     * @returns any successful
     * @throws ApiError
     */
    public static deleteInternalApiMessageTemplateDefaultWhatsAppMessageTemplates(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/internal_api/message_template/default_whats_app_message_templates/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update whats app template with latest status
     * @param id whats template ID
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiMessageTemplateDefaultWhatsAppMessageTemplatesFetchLatestStatus(
        id: number,
    ): CancelablePromise<{
        message_template: WhatsAppTemplateDetail;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/message_template/default_whats_app_message_templates/{id}/fetch_latest_status',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get tempaltes types
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiMessageTemplateDefaultWhatsAppMessageTemplatesGetTemplateTypes(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/message_template/default_whats_app_message_templates/get_template_types',
        });
    }

    /**
     * Get languages
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiMessageTemplateDefaultWhatsAppMessageTemplatesGetLanguages(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/message_template/default_whats_app_message_templates/get_languages',
        });
    }

}