import { Typography } from '@material-ui/core';
import React, { VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from '../messages';
import { useStyles } from '../styles';
import { Option, SessionHostsDropdown } from './SessionHostsDropdown';

interface HeaderProps {
  options: Option[];
  addUser: (option: Option | null) => void;
  disallowaddUser: boolean;
}

export const Header: VFC<HeaderProps> = ({
  options,
  addUser,
  disallowaddUser,
}) => {
  const styles = useStyles();

  return (
    <>
      <div className={styles.flex}>
        <Typography className={styles.subtext} variant="caption">
          <FormattedMessage {...messages.ifThereAreMultipleInterviewers} />
        </Typography>
        <SessionHostsDropdown
          options={options}
          onChange={addUser}
          disabled={disallowaddUser}
        />
      </div>
    </>
  );
};
