import { Grid } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';
import { WorkflowW4FormStage } from 'api-clients/monolith';
import React, { FC, useContext } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { StageSettingCard } from 'containers/WorkflowEditor/components/StageSettingCard';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import {
  AutoAdvanceOnCompletion,
  AutoAdvanceWhenLabelsComplete,
  IsHiddenFromPortal,
} from '../SettingsSwitches';

export type W4FormMessages = {
  settings: MessageDescriptor;
  moveApplicant: MessageDescriptor;
};

export interface W4FormProps {
  stage: WorkflowW4FormStage;
  styles: ClassNameMap<'container'>;
  messages: W4FormMessages;
  children?: React.ReactNode;
}

export const W4Form: FC<W4FormProps> = ({
  stage,
  styles,
  messages,
  children,
}) => {
  const { setStage } = useContext(StageContext);

  const isSubstage = Boolean(stage.parent_stage_id);
  const hasStageLabels = Boolean(stage.has_stage_labels);

  return (
    <StageSettingCard
      title={<FormattedMessage {...messages.settings} />}
      isLoading={false}
      variant="default"
    >
      <Grid container className={styles.container}>
        {children}
        <AutoAdvanceOnCompletion
          stage={stage}
          setStage={setStage}
          label={<FormattedMessage {...messages.moveApplicant} />}
        />
        <IsHiddenFromPortal stage={stage} setStage={setStage} />
        {!isSubstage && hasStageLabels && (
          <AutoAdvanceWhenLabelsComplete stage={stage} setStage={setStage} />
        )}
      </Grid>
    </StageSettingCard>
  );
};
