import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { VFC } from 'react';

export const BotAvatar: VFC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#1260FD" />
      <g clipPath="url(#clip0_542_13625)">
        <path
          d="M24 10V8H18V10H20V12H12V10H14V8H8V10H10V12H8V24H24V12H22V10H24ZM18 15C18.6 15 19 15.4 19 16C19 16.6 18.6 17 18 17C17.4 17 17 16.6 17 16C17 15.4 17.4 15 18 15ZM14 15C14.6 15 15 15.4 15 16C15 16.6 14.6 17 14 17C13.4 17 13 16.6 13 16C13 15.4 13.4 15 14 15ZM20 21H12V19H20V21Z"
          fill="white"
        />
      </g>
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#D6D9E4" />
      <defs>
        <clipPath id="clip0_542_13625">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
