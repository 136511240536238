import {
  Button,
  CustomTooltip as Tooltip,
  MoveToNextStage as MoveToNextStageIcon,
  Reject as RejectIcon,
} from '@fountain/fountain-ui-components';
import { IconButton } from '@material-ui/core';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import messages from 'shared/global/messages';

import { useStyles } from './styles';

const APPLICANT_TABLE_QUICK_ACTIONS_ICON_CLASSNAME =
  'applicant-table-quick-actions-icon';

interface ActionsProps {
  handleClickNextStage: () => void;
  handleClickReject: () => void;
}

const QuickActionsIconButton: FC<ActionsProps> = ({
  handleClickNextStage,
  handleClickReject,
}) => {
  return (
    <>
      <Tooltip dense title={<FormattedMessage {...messages.moveToNextStage} />}>
        <IconButton
          className={APPLICANT_TABLE_QUICK_ACTIONS_ICON_CLASSNAME}
          onClick={handleClickNextStage}
        >
          <MoveToNextStageIcon
            className={APPLICANT_TABLE_QUICK_ACTIONS_ICON_CLASSNAME}
          />
        </IconButton>
      </Tooltip>
      <Tooltip dense title={<FormattedMessage {...messages.reject} />}>
        <IconButton
          className={APPLICANT_TABLE_QUICK_ACTIONS_ICON_CLASSNAME}
          onClick={handleClickReject}
        >
          <RejectIcon
            className={APPLICANT_TABLE_QUICK_ACTIONS_ICON_CLASSNAME}
          />
        </IconButton>
      </Tooltip>
    </>
  );
};

const QuickActionsTextButton: FC<ActionsProps> = ({
  handleClickNextStage,
  handleClickReject,
}) => {
  const styles = useStyles();
  return (
    <>
      <Tooltip dense title={<FormattedMessage {...messages.moveToNextStage} />}>
        <Button
          className={styles.moveToButton}
          onClick={handleClickNextStage}
          autoWidth
        >
          <FormattedMessage {...messages.moveToNextStage} />
        </Button>
      </Tooltip>
      <Button
        className={styles.rejectButton}
        type="secondary"
        onClick={handleClickReject}
      >
        <FormattedMessage {...messages.reject} />
      </Button>
    </>
  );
};

export interface QuickActionsProps extends ActionsProps {
  variant?: 'icon' | 'text';
}

export const QuickActions: FC<QuickActionsProps> = ({ variant, ...props }) => {
  switch (variant) {
    case 'text':
      return <QuickActionsTextButton {...props} />;
    case 'icon':
    default:
      return <QuickActionsIconButton {...props} />;
  }
};
