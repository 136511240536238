import { Button, Input, Loader } from '@fountain/fountain-ui-components';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  CancelablePromise,
  OpeningApprovalSettingsParams,
  OpeningApprovalsService,
} from 'api-clients/monolith';
import { useApiService } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { makeSelectPolicies } from 'containers/Auth_old/selectors';
import { addMessageAction } from 'containers/FlashMessage/actions';
import { StageSettingCard } from 'containers/WorkflowEditor/components';
import { SettingSwitch } from 'containers/WorkflowEditor/components/WorkflowEditorContent/SettingSwitch';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';
import globalMessages from 'shared/global/messages';

import messages from './messages';

const useStyles = makeStyles(theme => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  switchWrapper: {
    padding: theme.spacing(0, 1.5),
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  inputRoot: {
    width: 'unset',
  },
  input: {
    width: '88px',
  },
  button: {
    height: '32px',
    padding: theme.spacing(1, 3),
    marginTop: theme.spacing(2),
  },
  loadingContainer: {
    height: '70%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const Settings = () => {
  const styles = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const policies = useSelector(makeSelectPolicies());
  const { manage_opening_approvers: manageOpeningApprovers } = policies;

  const [autoApprove, setAutoApprove] = useState(false);
  const [autoApproveIdleDays, setAutoApproveIdleDays] = useState(3);

  const { result } = useApiService(
    useCallback(
      () => OpeningApprovalsService.getInternalApiOpeningApprovalSettings(),
      [],
    ),
  );

  const { mutation: updateApprovalSettings } = useApiServiceMutation<
    OpeningApprovalSettingsParams,
    (
      requestBody: OpeningApprovalSettingsParams,
    ) => CancelablePromise<OpeningApprovalSettingsParams>
  >(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    OpeningApprovalsService.postInternalApiOpeningApprovalSettingsUpdateSettings,
    {
      onSuccess: () => {
        dispatch(addMessageAction('Changes saved successfully', 'success'));
      },
      onError: () => {
        dispatch(addMessageAction('Error', 'error', false));
      },
    },
  );

  useEffect(() => {
    if (result.status === 'ready') {
      setAutoApprove(result.data.auto_approve_enabled);
      setAutoApproveIdleDays(result.data.auto_approve_idle_days);
    }
  }, [result]);

  const onClickSendAutomatedMessage = (e: { target: { checked: boolean } }) => {
    const checked = !!e.target.checked;
    setAutoApprove(checked);
  };
  const onChangeNumberOfDays = (e: { target: { value: string } }) => {
    const count = parseInt(e.target.value, 10) || 0;
    setAutoApproveIdleDays(count);
  };
  const handleSave = () => {
    updateApprovalSettings({
      opening_auto_approval_enabled: autoApprove,
      opening_auto_approval_idle_days: autoApproveIdleDays,
    });
  };

  if (result.isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <Loader block size="1.5rem" />
      </div>
    );
  }

  if (result.isError) {
    // TODO: implement exception
  }

  if (result.status === 'ready') {
    return (
      <StageSettingCard
        variant="default"
        title={intl.formatMessage(messages.settingsTitle)}
      >
        <div className={styles.box}>
          <Box className={styles.switchWrapper}>
            <SettingSwitch
              dataKey="enable_move_to_notifications"
              checked={Boolean(autoApprove)}
              onChange={onClickSendAutomatedMessage}
              label={intl.formatMessage(messages.autoApproveMessage)}
            />
          </Box>

          {autoApprove && (
            <>
              <Typography variant="body2">
                {intl.formatMessage(messages.autoApproveIdleDays)}
              </Typography>
              <div className={styles.inputWrapper}>
                <Input
                  value={autoApproveIdleDays}
                  onChange={onChangeNumberOfDays}
                  className={styles.input}
                  autoFocus
                  rootClass={styles.inputRoot}
                />
                <Typography variant="body2">
                  {intl.formatMessage(globalMessages.days)}
                </Typography>
              </div>
            </>
          )}
        </div>

        {manageOpeningApprovers && (
          <Button
            type="secondary"
            size="small"
            className={styles.button}
            onClick={handleSave}
            disabled={autoApproveIdleDays === 0}
          >
            <Typography variant="h4" color="primary">
              {intl.formatMessage(globalMessages.save)}
            </Typography>
          </Button>
        )}
      </StageSettingCard>
    );
  }

  return null;
};
