import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import produce from 'immer';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import { PartnerLabelSelect } from 'containers/WorkflowEditor/components/Rules/Condition/components/PartnerLabelSelect';
import { PartnerDetailHasKey } from 'containers/WorkflowEditor/components/Rules/Condition/PartnerDetailHasKey';
import { PartnerDetailHasKeyWithValue } from 'containers/WorkflowEditor/components/Rules/Condition/PartnerDetailHasKeyWithValue';
import { PartnerLabelHasKeyWithValue } from 'containers/WorkflowEditor/components/Rules/Condition/PartnerLabelHasKeyWithValue';
import { PartnerStatusHasTitle } from 'containers/WorkflowEditor/components/Rules/Condition/PartnerStatusHasTitle';
import { PartnerStatusIs } from 'containers/WorkflowEditor/components/Rules/Condition/PartnerStatusIs/PartnerStatusIs';
import { PartnerStatusIsNot } from 'containers/WorkflowEditor/components/Rules/Condition/PartnerStatusIsNot';
import { RowTag } from 'containers/WorkflowEditor/components/Rules/RowTag';
import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';

import { PartnerRuleProps, SharedPartnerConditionProps } from '../types';
import { messages } from './messages';
import { useStyles } from './styles';

export const PartnerCondition: FC<SharedPartnerConditionProps> = React.memo(
  ({ condition, ruleId, errors, setPartnerRules }) => {
    const intl = useIntl();
    const styles = useStyles();

    const { partner_options: partnerOptions } =
      useContext(RulesEditDataContext);

    const onSelectConditionType = (newCondition: {
      label: string;
      value: string;
    }) => {
      setPartnerRules(
        produce((draftRules: PartnerRuleProps) => {
          const draftCondition = draftRules[ruleId].condition_attributes;

          draftCondition.type = newCondition.value;
          draftCondition.extra = {};
        }),
      );
    };

    const generatePartnerConditionTypeOptions = () => {
      if (partnerOptions && partnerOptions.length > 0) {
        return [
          {
            label: intl.formatMessage(messages.partnerDetailHasKey),
            value: 'PartnerStage::Conditions::PartnerDetailHasKey',
          },
          {
            label: intl.formatMessage(messages.partnerDetailHasKeyWithValue),
            value: 'PartnerStage::Conditions::PartnerDetailHasKeyWithValue',
          },
          {
            label: intl.formatMessage(messages.partnerLabelHasKey),
            value: 'PartnerStage::Conditions::PartnerLabelHasKey',
          },
          {
            label: intl.formatMessage(messages.partnerLabelDoesNotHaveKey),
            value: 'PartnerStage::Conditions::PartnerLabelDoesNotHaveKey',
          },
          {
            label: intl.formatMessage(messages.partnerLabelHasKeyWithValue),
            value: 'PartnerStage::Conditions::PartnerLabelHasKeyWithValue',
          },
          {
            label: intl.formatMessage(messages.partnerStatusIs),
            value: 'PartnerStage::Conditions::PartnerStatusIs',
          },
          {
            label: intl.formatMessage(messages.partnerStatusIsNot),
            value: 'PartnerStage::Conditions::PartnerStatusIsNot',
          },
          {
            label: intl.formatMessage(messages.partnerStatusHasTitle),
            value: 'PartnerStage::Conditions::PartnerStatusHasTitle',
          },
        ];
      }
      return [];
    };

    const conditionTypeOptions = generatePartnerConditionTypeOptions();
    const selectedCondition = () =>
      conditionTypeOptions.find(cond => cond.value === condition.type);

    const currentSelectedCondition = selectedCondition();

    const sharedPartnerConditionProps = {
      setPartnerRules,
      condition,
      ruleId,
      errors,
    };

    return (
      <Grid container className={styles.conditionRow}>
        <Grid
          container
          justify="space-between"
          wrap="nowrap"
          alignItems="center"
          className={styles.tagContainer}
        >
          <RowTag label={intl.formatMessage(messages.condition)} />
        </Grid>
        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <StyledReactSelect
              data-testid="select"
              options={conditionTypeOptions}
              value={currentSelectedCondition}
              onChange={onSelectConditionType}
              label={intl.formatMessage(messages.conditionType)}
              isSearchable
              aria-label={intl.formatMessage(messages.conditionType)}
              placeholder=""
              required
            />
          </Grid>
          {currentSelectedCondition?.value &&
            {
              'PartnerStage::Conditions::PartnerDetailHasKey': (
                <PartnerDetailHasKey {...sharedPartnerConditionProps} />
              ),

              'PartnerStage::Conditions::PartnerDetailHasKeyWithValue': (
                <PartnerDetailHasKeyWithValue
                  {...sharedPartnerConditionProps}
                />
              ),

              'PartnerStage::Conditions::PartnerLabelHasKey': (
                <PartnerLabelSelect {...sharedPartnerConditionProps} />
              ),

              'PartnerStage::Conditions::PartnerLabelDoesNotHaveKey': (
                <PartnerLabelSelect {...sharedPartnerConditionProps} />
              ),

              'PartnerStage::Conditions::PartnerLabelHasKeyWithValue': (
                <PartnerLabelHasKeyWithValue {...sharedPartnerConditionProps} />
              ),

              'PartnerStage::Conditions::PartnerStatusIs': (
                <PartnerStatusIs {...sharedPartnerConditionProps} />
              ),

              'PartnerStage::Conditions::PartnerStatusIsNot': (
                <PartnerStatusIsNot {...sharedPartnerConditionProps} />
              ),
              'PartnerStage::Conditions::PartnerStatusHasTitle': (
                <PartnerStatusHasTitle {...sharedPartnerConditionProps} />
              ),
            }[currentSelectedCondition.value]}
        </Grid>
      </Grid>
    );
  },
);
