/* eslint-disable camelcase */
import { FountainLocation } from '@fountain/types/base';
import { StageType } from 'api-clients/monolith';

import { Message, SelectedApplicant } from 'containers/SelectedApplicant/types';
import { Status } from 'containers/StatusLabels/utils';

export interface CustomLabel {
  id: string;
  title: string;
  completed: boolean;
}
export interface Note {
  content: string;
  created_at: string;
  id: string;
  last_updated_by?: string;
  user: {
    email: string;
    id: string;
    name: string;
  };
}
export interface Stage {
  id: string;
  name: string;
  slug: string;
  title: string;
  type: StageType;
  short_type: string;
  next_stage_id: string;
  is_next_stage_rejected: boolean;
  validation_group_id: number;
  is_bot_enabled: boolean;
}
export interface TableColumnWidths {
  name: number;
  assigned_user: number;
  stage: number;
  account: number;
  email: number;
  phone: number;
  opening: number;
  statuses: number;
  custom_label: number;
  applied_at: number;
  landed_at: number;
  idle_since: number;
  utm_source: number;
  note: number;
  message_count: number;
  _location: number;
  _type: number;
  progress: number;
  brand: number;
  date_of_birth: number;
  phone_platform: number;
  application_locale: number;
  idle_move_out_in: number;
  archived_reason: number;
}

export type TableColumnWidthsKey = keyof TableColumnWidths;
export type SourceType = 'vonq' | 'campaign' | 'simple' | 'advanced' | null;
export interface Applicant {
  id: string;
  account_slug: string;
  account_name: string;
  is_duplicate: boolean;
  pool_applicant_slug?: string;
  sms_unsubscribed: boolean;
  can_receive_sms: boolean;
  email_unsubscribed: boolean;
  has_suspicious_location: boolean;
  applied_through_fountain_pool: boolean;
  is_boosted: boolean;
  managable: boolean;
  unread_messages: number;
  name: string;
  applicant_id: string;
  email: string;
  phone: string;
  status: Status[];
  custom_labels: CustomLabel[];
  applied_at: string;
  landed_at: string;
  idle_since: number;
  utm_source?: string;
  note?: Note;
  rejection_reason?: string;
  _location: FountainLocation;
  _type: SourceType;
  stage: Stage;
  opening: { id: string; name: string; slug: string; title: string };
  messages: Message[];
  notes: Note[];
  progress?: { completed_stages: number; total_stages: number };
  assigned_user?: { id: string; name: string };
  date_of_birth?: string | null;
  phone_platform: string | null;
  application_locale?: string | null;
  idle_move_out_in?: number;
  archived_reason?: string;
}

export interface StageIdsWithFunnel {
  funnel_id: string;
  stage_ids: string[];
}

export enum BulkSelect {
  NONE = 'NONE',
  COUNT = 'COUNT',
  ALL = 'ALL',
  SOME = 'SOME',
}

export interface ApplicantTableV2 {
  applicants: {
    [key: string]: Applicant | undefined;
  };
  isFetchingApplicants: boolean;
  isDeletingApplicants: boolean;
  isLoading: boolean;
  bulkSelect: BulkSelect;
  selectedApplicantIds: string | string[];
  selectByCount?: number;
  totalRows: number;
  selectedFilterStageIdsWithFunnel: StageIdsWithFunnel[];
  selectedFilterJobIdsCount: number;
  shouldShowOverlay: boolean;
  tableColumnWidths: TableColumnWidths;
  selectedApplicant: SelectedApplicant;
  bulkActionSubmitting: boolean;
  details?: {
    [key: string]: SelectedApplicant;
  };
}

interface BoostDetail {
  external_id: string;
}

interface IndeedCampaign {
  external_id: string;
}

export interface Job {
  id: string;
  slug: string;
  title: string;
  time_zone: string;
  stages: Stage[];
  can_be_boosted: boolean;
  has_active_boost: boolean;
  indeed_campaign_eligible: boolean;
  active_indeed_campaign: boolean;
  indeed_billing_failed: boolean;
  is_card_deactivated: boolean;
  boost?: BoostDetail;
  indeed_campaign?: IndeedCampaign;
  account_slug: string;
  brand_slug: string;
}

export interface QueryParams {
  stage_id?: string;
  query?: string;
  filter_set_id?: string;
  stage_conditions_filter_set_id?: string;
  per_page: string;
  page: string;
  sort_direction?: string;
  sort_column?: string;
  duplicate_applicant_id?: string;
}

export type ExpandedColumns = Record<string, boolean>;
