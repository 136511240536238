import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  editMessageTemplate: {
    id: 'app.containers.Chatbot.ChatbotFeedback.editMessageTemplate',
    defaultMessage: `Edit message template ➚`,
  },
  botAvatarTooltip: {
    id: 'app.containers.Chatbot.ChatbotFeedback.botAvatarTooltip',
    defaultMessage: `Fountain Bot`,
  },
  applicantAvatarTooltip: {
    id: 'app.containers.Chatbot.ChatbotFeedback.applicantAvatarTooltip',
    defaultMessage: `Applicant`,
  },
});
