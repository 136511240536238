import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Message } from '../types';

const useStyles = makeStyles(theme => ({
  description: {
    marginTop: theme.spacing(1),
    lineHeight: '20px',
    fontWeight: 450,
  },
}));

interface DescriptionProps {
  value: Message;
}

export const Description: VFC<DescriptionProps> = ({ value }) => {
  const styles = useStyles();

  return (
    <Typography variant="body2" className={styles.description}>
      <FormattedMessage {...value} />
    </Typography>
  );
};
