import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  applicantCount: {
    id: `app.containers.WorkflowEditor.components.OpeningNavBar.applicantCount`,
    defaultMessage: '{applicantCount} applicants',
  },
  openingDetails: {
    id: `app.containers.WorkflowEditor.components.OpeningNavBar.openingDetails`,
    defaultMessage: 'Opening details',
  },
  workflowEditor: {
    id: `app.containers.WorkflowEditor.components.OpeningNavBar.workFlowEditor`,
    defaultMessage: 'Workflow editor',
  },
  workflowEditorNavigation: {
    id: `app.containers.WorkflowEditor.components.OpeningNavBar.workflowEditorNavigation`,
    defaultMessage: 'Workflow Editor Navigation',
  },
  manageLabels: {
    id: `app.containers.WorkflowEditor.components.OpeningNavBar.manageLabels`,
    defaultMessage: 'Manage labels',
  },
});
