import { Dropdown } from '@fountain/fountain-ui-components';
import PropTypes from 'prop-types';
import React from 'react';

import OptionBankDropdown from 'components/OptionBankDropdown';

const ValueEditorDropdown = ({
  className,
  error,
  onChange,
  optionBankId,
  options,
  partialUrl,
  value,
  ...props
}) => {
  if (optionBankId) {
    return (
      <OptionBankDropdown
        className={className}
        error={error}
        onChange={onChange}
        optionBankId={optionBankId}
        partialUrl={partialUrl}
        value={value}
        {...props}
      />
    );
  }
  return (
    <Dropdown
      className={className}
      error={error}
      onChange={onChange}
      options={options}
      value={value}
      isSearchable
      {...props}
    />
  );
};

ValueEditorDropdown.propTypes = {
  className: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  optionBankId: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]),
  options: PropTypes.array,
  partialUrl: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
  ]),
};

export default ValueEditorDropdown;
