import { CustomTooltip, IconButton } from '@fountain/fountain-ui-components';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import {
  ChatbotTrainingStatus,
  ChatbotWidgetSettings,
} from 'api-clients/monolith';
import React, { VFC } from 'react';
import { useIntl } from 'react-intl';

import { Description } from '../Components/Description';
import { InputSetting } from '../Components/InputSetting';
import { SectionTitle } from '../Components/SectionTitle';
import { SettingSwitch } from '../Components/SettingSwitch';
import { SettingTitle } from '../Components/SettingTitle';
import { HandleSettingChange, SelectedBrand } from '../types';
import { messages } from './messages';
import { TrainingStatus } from './TrainingStatus';

const FAQ_WEBSITE_URL_REGX = 'https://.*';
const FAQ_FALLBACK_EMAIL_REGX = '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
const FAQ_BOT_NAME_REGX = '[a-zA-Z0-9 ]+';

const useStyles = makeStyles(theme => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(3),
  },
  title: {
    marginTop: theme.spacing(0),
  },
  minorTitle: {
    marginTop: theme.spacing(2),
  },
  majorTitle: {
    marginTop: theme.spacing(4),
  },
  input: {
    marginTop: theme.spacing(2),
  },
  disabledFaqSection: {
    pointerEvents: 'none',
    opacity: '0.4',
    padding: '10px',
    paddingTop: 'unset',
  },
  editButton: {
    right: '12px',
  },
  'MuiButton-endIcon': {
    marginLeft: 'unset',
    marginRight: 'unset',
  },
}));

export interface FaqSettingsProps {
  settings: ChatbotWidgetSettings;
  handleSettingChange: HandleSettingChange;
  selectedBrand: SelectedBrand;
  trainingStatus: ChatbotTrainingStatus;
  setTrainingStatus: React.Dispatch<
    React.SetStateAction<ChatbotTrainingStatus>
  >;
  faqSectionDisabled: boolean;
  setDisableFaqSection: (flag: boolean) => void;
}

export const FaqSettings: VFC<FaqSettingsProps> = ({
  settings,
  handleSettingChange,
  selectedBrand,
  trainingStatus,
  setTrainingStatus,
  faqSectionDisabled,
  setDisableFaqSection,
}) => {
  const styles = useStyles();
  const intl = useIntl();

  return (
    <section className={styles.section}>
      <div className={styles.box}>
        <SectionTitle value={messages.faqSettings} className={styles.title} />
        <TrainingStatus
          selectedBrand={selectedBrand}
          trainingStatus={trainingStatus}
          setTrainingStatus={setTrainingStatus}
        />
        {faqSectionDisabled && (
          <CustomTooltip
            title={intl.formatMessage(messages.editFaqChatbotSettings)}
            dense
            placement="top"
          >
            <IconButton
              aria-label="edit"
              className={styles.editButton}
              onClick={() => {
                setDisableFaqSection(false);
              }}
            >
              <EditIcon />
            </IconButton>
          </CustomTooltip>
        )}
      </div>
      <div className={faqSectionDisabled ? styles.disabledFaqSection : ''}>
        <SettingSwitch
          settingKey="faq_enabled"
          label={messages.enableFaqTitle}
          checked={settings.faq_enabled}
          onChange={handleSettingChange}
          className={styles.majorTitle}
        />
        <Description value={messages.enableFaqDesc} />
        {settings.faq_enabled && (
          <>
            <SettingTitle
              value={messages.botNameTitle}
              className={styles.minorTitle}
            />
            <Description value={messages.botNameDesc} />
            <InputSetting
              settingKey="faq_bot_name"
              settingValue={settings.faq_bot_name}
              onChange={handleSettingChange}
              errorMessage={intl.formatMessage(messages.botNameError)}
              placeholder="Fountain AI"
              faqSectionDisabled={faqSectionDisabled}
              pattern={FAQ_BOT_NAME_REGX}
              isrequired
            />
            <SettingTitle
              value={messages.trainingSourceTitle}
              className={styles.majorTitle}
            />
            <Description value={messages.trainingSourceDesc} />
            <InputSetting
              settingKey="faq_website_url"
              settingValue={settings.faq_website_url}
              onChange={handleSettingChange}
              errorMessage={intl.formatMessage(messages.trainingSourceError)}
              placeholder="https://www.company.com/faq/"
              faqSectionDisabled={faqSectionDisabled}
              pattern={FAQ_WEBSITE_URL_REGX}
              isrequired
            />
            <SettingTitle
              value={messages.fallbackTitle}
              className={styles.majorTitle}
            />
            <Description value={messages.fallbackDesc} />
            <InputSetting
              settingKey="faq_fallback_email"
              settingValue={settings.faq_fallback_email}
              onChange={handleSettingChange}
              errorMessage={intl.formatMessage(messages.emailError)}
              placeholder="name@email.com"
              faqSectionDisabled={faqSectionDisabled}
              pattern={FAQ_FALLBACK_EMAIL_REGX}
            />
            <SettingTitle
              value={messages.fallbackPhoneNumber}
              className={styles.majorTitle}
            />
            <InputSetting
              settingKey="faq_fallback_phone_number"
              settingValue={settings.faq_fallback_phone_number}
              onChange={handleSettingChange}
              errorMessage={intl.formatMessage(messages.phoneError)}
              placeholder="1234567890"
              faqSectionDisabled={faqSectionDisabled}
            />
            <SettingSwitch
              settingKey="ax_faq_widget_enabled"
              label={messages.enableAxWidgetTitle}
              checked={settings.ax_faq_widget_enabled}
              onChange={handleSettingChange}
              className={styles.majorTitle}
            />
            <Description value={messages.enableAxWidgetDesc} />
          </>
        )}
      </div>
    </section>
  );
};
