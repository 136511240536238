import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  askDelete: {
    id: `app.containers.WorkflowEditor.components.AddItemRows.ItemRow.askDelete`,
    defaultMessage: 'Are you sure you want to delete?',
  },
  delete: {
    id: `app.containers.WorkflowEditor.components.AddItemRows.ItemRow.delete`,
    defaultMessage: 'Delete',
  },
  edit: {
    id: `app.containers.WorkflowEditor.components.AddItemRows.ItemRow.edit`,
    defaultMessage: 'Edit',
  },
  cancel: {
    id: `app.containers.WorkflowEditor.components.AddItemRows.ItemRow.cancel`,
    defaultMessage: 'Cancel',
  },
});
