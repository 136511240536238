import { CustomTooltip, IconButton } from '@fountain/fountain-ui-components';
import { Theme, useMediaQuery } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  CancelablePromise,
  ManageRosterService,
  Roster,
} from 'api-clients/monolith';
import { eventManagerContext } from 'Calendar/EventManager/context';
import { BulkActionTable } from 'Calendar/EventManager/ManageRoster/BulkActionTable';
import {
  attended,
  mapSlots,
  RosterApplicant,
} from 'Calendar/EventManager/ManageRoster/util';
import { messages } from 'Calendar/EventManager/messages';
import { useBookedSlots } from 'Calendar/hooks/useBookedSlots';
import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  VFC,
} from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { makeSelectSelectedApplicantIds } from 'containers/ApplicantTableV2/selectors';
import {
  makeSelectAccountSlug,
  makeSelectClientSettings,
  makeShowColumnsDrawerInSessions,
} from 'containers/Auth_old/selectors';
import {
  DEFAULT_COLUMNS,
  DEFAULT_UPS_COLUMNS,
} from 'containers/CustomizableColumnsDrawer/constants';
import {
  addDefaultErrorMessageActionI18n,
  addMessageAction,
} from 'containers/FlashMessage/actions';
import { openCustomizableColumnsDrawer } from 'containers/GlobalPopup/actions';
import { makeSelectSubmitStatus } from 'containers/MoveApplicantDialog/selectors';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';
import CustomizeColumnsIcon from 'images/customize-columns-icon';
import { isUPS } from 'utils/constants';

import { useStyles } from './styles';

export interface ManageRosterProps {
  eventExternalId: string;
}

/* eslint-disable camelcase */
export const ManageRoster: VFC<ManageRosterProps> = ({ eventExternalId }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const styles = useStyles();
  const { filters } = useContext(eventManagerContext);
  const {
    clientSettings: { scheduleSessionColumnSettings },
  } = useSelector(makeSelectClientSettings());
  const accountSlug = useSelector(makeSelectAccountSlug()) as string;
  const showColumnsDrawer = useSelector(makeShowColumnsDrawerInSessions());

  const columns =
    scheduleSessionColumnSettings && scheduleSessionColumnSettings.length
      ? scheduleSessionColumnSettings
      : (isUPS(accountSlug) && showColumnsDrawer
          ? DEFAULT_UPS_COLUMNS
          : DEFAULT_COLUMNS
        ).scheduleSessionColumnSettings;

  const currentFilters = useMemo(() => {
    return {
      ...filters,
      selectedColumns: columns.map(column => column.key),
    };
  }, [filters, columns]);

  const { result, refetch } = useBookedSlots({
    externalId: eventExternalId,
    queryFilter: currentFilters,
  });
  const [applicants, setApplicants] = useState<RosterApplicant[]>([]);
  const { isLoading: isSubmitting } = useSelector(makeSelectSubmitStatus());
  const selectedApplicantIds = useSelector(makeSelectSelectedApplicantIds());
  const anyApplicantSelected = applicants.some(applicant =>
    typeof selectedApplicantIds === 'string'
      ? selectedApplicantIds === applicant.id
      : selectedApplicantIds.some(id => id === applicant.id),
  );
  const shouldRefresh = useRef(false);
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (result.status === 'ready') {
      setApplicants(
        mapSlots(result.data).map(roster => ({
          ...roster,
          checked: applicants.find(a => a.id === roster.id)?.checked ?? false,
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const { mutation: manageRoster } = useApiServiceMutation<
    {
      available_slot_id: string;
      action: 'absent' | 'attended' | 'advanced';
      roster: Array<Roster>;
    },
    ({
      available_slot_id,
      applicant_ids,
      action,
    }: {
      available_slot_id: string;
      applicant_ids: string[];
      action: 'absent' | 'attended' | 'advanced';
    }) => CancelablePromise<{
      available_slot_id: string;
      action: 'absent' | 'attended' | 'advanced';
      roster: Array<Roster>;
    }>,
    {
      message: string;
    }
    // eslint-disable-next-line @typescript-eslint/unbound-method
  >(ManageRosterService.postInternalApiEventsRosters, {
    onSuccess: response => {
      if (response.action === 'advanced') {
        refetch();
      } else {
        // For attended and absent we can update on the client side.
        response.roster.forEach(roster => {
          const appIndex = applicants.findIndex(
            applicant => applicant.id === roster.applicant.id,
          );
          if (appIndex !== -1) {
            applicants[appIndex] = {
              ...applicants[appIndex],
              attended: attended(roster.applicant_showed),
            };
          }
        });
        setApplicants([...applicants]);
      }
      const message =
        response.action === 'advanced' ? messages.advanced : messages.updated;
      dispatch(addMessageAction(intl.formatMessage(message), 'success'));
    },
    onError: response => {
      dispatch(
        response?.message
          ? addMessageAction(response.message, 'error')
          : addDefaultErrorMessageActionI18n(intl),
      );
    },
  });

  useEffect(() => {
    if (isSubmitting && anyApplicantSelected) {
      shouldRefresh.current = true;
    }
  }, [isSubmitting, anyApplicantSelected, shouldRefresh]);

  useEffect(() => {
    if (shouldRefresh.current && !isSubmitting) {
      shouldRefresh.current = false;
      refetch();
    }
  }, [isSubmitting, shouldRefresh, refetch]);

  const applicantsToggle = (checked: boolean) => {
    setApplicants(
      applicants.map(applicant => ({ ...applicant, checked: !checked })),
    );
  };

  const checkApplicant = (checkApplicant: RosterApplicant) => {
    setApplicants(
      applicants.map(applicant => {
        if (applicant.id === checkApplicant.id) {
          return {
            ...applicant,
            checked: !applicant.checked,
          };
        }
        return applicant;
      }),
    );
  };
  /* eslint-enable camelcase */

  const openCustomizableColumns = () => {
    dispatch(openCustomizableColumnsDrawer());
  };

  return (
    <>
      {!isMobile && showColumnsDrawer && (
        <CustomTooltip
          title={intl.formatMessage(messages.customizeColumns)}
          className={styles.customizeColumnsButton}
          dense
        >
          <IconButton
            onClick={openCustomizableColumns}
            aria-controls="customizable-columns-drawer"
            aria-haspopup="true"
            aria-label="Customize Columns"
          >
            <CustomizeColumnsIcon className={styles.customizeColumnsIcon} />
          </IconButton>
        </CustomTooltip>
      )}
      {result.status === 'ready' ? (
        <BulkActionTable
          applicants={applicants}
          eventExternalId={eventExternalId}
          manageRoster={manageRoster}
          applicantsToggle={applicantsToggle}
          checkApplicant={checkApplicant}
          columns={columns.map(column => ({
            key: column.key as keyof RosterApplicant,
            title: column.title,
            keyType: column.key_type,
          }))}
        />
      ) : (
        <Skeleton variant="rect" className={styles.skeletonCard} />
      )}
    </>
  );
};
