import { defineMessages } from 'react-intl';

export const scope = 'app.components.ApplicantDrawerDetailsList';

export default defineMessages({
  clearDialogBody: {
    id: `${scope}.clearDialogBody`,
    defaultMessage:
      'Are you sure you want to clear the information from this field?',
  },
  clearDialogTitle: {
    id: `${scope}.clearDialogTitle`,
    defaultMessage: 'Clear Information',
  },
  clearFieldSuccessMessage: {
    id: `${scope}.clearFieldSuccessMessage`,
    defaultMessage: 'Data field cleared',
  },
  hideEmptyFields: {
    id: `${scope}.hideEmptyFields`,
    defaultMessage: 'Hide Empty Fields',
  },
  showEmptyFields: {
    id: `${scope}.showEmptyFields`,
    defaultMessage: 'Show Empty Fields',
  },
});
