/* eslint-disable camelcase */
import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import {
  documentSigningStatusesHash,
  getConditionErrorMessage,
} from 'containers/WorkflowEditor/components/Rules/Condition/constants';
import { messages as conditionMessages } from 'containers/WorkflowEditor/components/Rules/Condition/messages';
import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';

import { DocumentSigningRuleProps } from '../../DocumentSigningRules/types';
import {
  RulesProps,
  SharedConditionProps,
  SignatureTemplateType,
} from '../../types';
import messages from './messages';

export interface CommonSignStatusProps extends SharedConditionProps {
  statusField: 'white_labeled_hellosign' | 'docusign' | 'hellosign';
  documentSelectLabel: string;
}

export const CommonSignStatus: FC<CommonSignStatusProps> = ({
  condition,
  ruleId,
  setRules,
  statusField,
  documentSelectLabel,
  errors,
  setDocumentSigningRules,
}) => {
  const intl = useIntl();

  const {
    condition_statuses: conditionStatuses,
    signature_templates: signatureTemplates,
  } = useContext(RulesEditDataContext);
  const { extra } = condition;

  const onChangeTemplate = (newTemplate: {
    title: string;
    template_id: string;
  }) => {
    const rulesSetter = setRules ?? setDocumentSigningRules;

    if (rulesSetter) {
      rulesSetter(
        produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];

          const idx = draftConditions.findIndex(
            cond => cond.id === condition.id,
          );

          draftConditions[idx].extra.template_id = newTemplate.template_id;
        }),
      );
    }
  };

  const onChangeStatus = (newStatus: { label: string; value: string }) => {
    const rulesSetter = setRules ?? setDocumentSigningRules;

    if (rulesSetter) {
      rulesSetter(
        produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];

          const idx = draftConditions.findIndex(
            cond => cond.id === condition.id,
          );

          draftConditions[idx].extra.status = newStatus.value;
        }),
      );
    }
  };

  const selectedSignatureTemplate =
    signatureTemplates?.find(
      template => template.template_id === extra.template_id,
    ) ?? null;

  const statusOptions = (conditionStatuses ?? {})[statusField]?.map(status => ({
    label: documentSigningStatusesHash[status],
    value: status,
  }));

  const selectedStatusOption =
    statusOptions?.find(option => option.value === extra.status) ?? null;

  const templateIdErrorMessage =
    !extra?.template_id && getConditionErrorMessage('template_id', errors);

  const statusErrorMessage =
    !extra?.status && getConditionErrorMessage('status', errors);

  return (
    <>
      <Grid item xs={6}>
        <StyledReactSelect
          options={signatureTemplates}
          value={selectedSignatureTemplate}
          onChange={onChangeTemplate}
          label={documentSelectLabel || intl.formatMessage(messages.document)}
          aria-label={
            documentSelectLabel || intl.formatMessage(messages.document)
          }
          getOptionLabel={(option: SignatureTemplateType) => option.title}
          getOptionValue={(option: SignatureTemplateType) => option.template_id}
          isSearchable
          error={templateIdErrorMessage}
          placeholder=""
          required
        />
      </Grid>
      <Grid item xs={6}>
        <StyledReactSelect
          options={statusOptions}
          value={selectedStatusOption}
          onChange={onChangeStatus}
          label={intl.formatMessage(conditionMessages.status)}
          aria-label={intl.formatMessage(conditionMessages.status)}
          isSearchable
          error={statusErrorMessage}
          placeholder=""
          required
        />
      </Grid>
    </>
  );
};
