import { WorkflowEditorService } from 'api-clients/monolith';
import { useApiService } from 'hooks';
import React from 'react';

export function useJobRouterConditionOptions() {
  const fetchJobRouterConditionOptions = React.useCallback(
    () =>
      WorkflowEditorService.getInternalApiWorkflowEditorJobMatcherConditionOptions(),
    [],
  );

  return useApiService(fetchJobRouterConditionOptions);
}
