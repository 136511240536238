import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  learnMore: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.LiveVideoCard.learnMore`,
    defaultMessage: 'Learn More',
  },
  liveVideo: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.LiveVideoCard.liveVideo`,
    defaultMessage: 'Live Video',
  },
  liveVideoDescription: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.LiveVideoCard.liveVideoDescription`,
    defaultMessage: 'Meet and speak to applicants through a remote video call.',
  },
  enableLiveVideoCall: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.LiveVideoCard.enableLiveVideoCall`,
    defaultMessage: 'Enable live video call',
  },
});
