import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import { setApplicantBlobsAction } from 'containers/ApplicantTableV2/actions';
import { addDefaultErrorMessageAction } from 'containers/FlashMessage/actions';
import { apiGet } from 'utils/axios';

export default function useApplicantBlobs(applicantId) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchApplicantBlobs = useCallback(async () => {
    const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/blobs`;
    setIsLoading(true);
    try {
      const res = await apiGet(url);
      dispatch(setApplicantBlobsAction(applicantId, res.data.blobs));
      setError('');
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }, [applicantId, dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(addDefaultErrorMessageAction());
    }
  }, [dispatch, error]);

  return { fetchApplicantBlobs, isLoading };
}
