import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import { setValidationGroupAction } from 'containers/DataValidationModal/actions';
import { addDefaultErrorMessageAction } from 'containers/FlashMessage/actions';
import { apiGet } from 'utils/axios';

export default function useValidationGroup(validationGroupId) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchValidationGroup = async () => {
    const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/validation_groups/${validationGroupId}`;

    setIsLoading(true);
    try {
      const res = await apiGet(url);
      dispatch(setValidationGroupAction(res.data.validation_group));
      setIsLoading(false);
    } catch (e) {
      dispatch(addDefaultErrorMessageAction());
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!validationGroupId) {
      return;
    }
    fetchValidationGroup(validationGroupId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { fetchValidationGroup, isLoading, error };
}
