import {
  VideoRecordingStageQuestion,
  WorkflowVideoRecordingStage,
} from 'api-clients/monolith';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';

import { IdleMoveRule } from 'containers/WorkflowEditor/components/IdleMoveRule';
import { StageSection } from 'containers/WorkflowEditor/components/StageSection';
import { StageSettingMessageCard } from 'containers/WorkflowEditor/components/StageSettingMessageCard';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { AdvancedSettings } from './AdvancedSettings';
import { generateDefaultQuestion } from './constants';
import { Questions } from './Questions';

export interface VideoRecordingStageProps {
  stage: WorkflowVideoRecordingStage;
}

export const VideoRecordingStage: FC<VideoRecordingStageProps> = ({
  stage,
}) => {
  const { setStage } = useContext(StageContext);

  const onAddQuestion = () => {
    setStage(
      produce(stage, draftStage => {
        draftStage.additional_info.questions.push(
          generateDefaultQuestion() as unknown as VideoRecordingStageQuestion,
        );
      }),
    );
  };

  return (
    <div data-testid="videoRecordingStage">
      <StageSection>
        <Questions
          onAddQuestion={onAddQuestion}
          questions={stage.additional_info.questions ?? []}
        />
      </StageSection>
      <StageSection>
        <IdleMoveRule stage={stage} />
      </StageSection>
      <StageSection>
        <StageSettingMessageCard
          messageType="video_recording"
          stageExternalId={stage.external_id}
        />
      </StageSection>
      <StageSection>
        <StageSettingMessageCard
          messageType="trigger"
          stageExternalId={stage.external_id}
        />
      </StageSection>
      <StageSection>
        <AdvancedSettings stage={stage} />
      </StageSection>
    </div>
  );
};
