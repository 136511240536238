import { defineMessages } from 'react-intl';

export default defineMessages({
  createApproverGroup: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.createApproverGroup',
    defaultMessage: 'Create Approver Group',
  },
  approversModalSubtitle: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.approversModalSubtitle',
    defaultMessage:
      'Select approvers to create an approver group. Approver group can be assigned to an opening to approve or reject an opening before it becomes Active.',
  },
  approversModalSelectInfo: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.approversModalSelectInfo',
    defaultMessage:
      'A minimum of one and up to four approvers can be added in a group.',
  },
  approverGroupNameLabel: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.approverGroupNameLabel',
    defaultMessage: 'Approver Group',
  },
  approverGroupUsersLabel: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.approverGroupUsersLabel',
    defaultMessage: 'Approvers',
  },
  deleteConfirmationModalContent: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.deleteConfirmationModalContent',
    defaultMessage: `Are you sure you want to delete the selected approver group?`,
  },
  deleteConfirmationModalTitle: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.deleteConfirmationModalTitle',
    defaultMessage: `Delete Approver group`,
  },
  emptyTableMessage: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.emptyTableMessage',
    defaultMessage: `No approver group created yet.`,
  },
  warningMessage: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.warningMessage',
    defaultMessage: `At least one approver group needs to be set up in order to use Opening Approvals.`,
  },
  searchEmptyTableMessage: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.searchEmptyTableMessage',
    defaultMessage: `No Approver Groups found.`,
  },
  selectPlaceolderText: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.selectPlaceolderText',
    defaultMessage: `Select or search users`,
  },
  inputPlaceholderText: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.inputPlaceholderText',
    defaultMessage: `e.g. Northeast Opening Approvers`,
  },
  createSuccessMessage: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.createSuccessMessage',
    defaultMessage: `Approver Group created Successfully`,
  },
  updateSuccessMessage: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.updateSuccessMessage',
    defaultMessage: `Approver Group updated Successfully`,
  },
  deleteSuccessMessage: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.deleteSuccessMessage',
    defaultMessage: `Approver Group deleted Successfully`,
  },
  maxApproversErrorMessage: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.maxApproversErrorMessage',
    defaultMessage: `Maximum of only 4 approvers can be added to a group.`,
  },
  groupNameErrorMessage: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.groupNameErrorMessage',
    defaultMessage: `Please enter the approver group name.`,
  },
});
