import {
  Button,
  InnerContent,
  SimpleModalFooter,
} from '@fountain/fountain-ui-components';
import {
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React, { ChangeEventHandler, useState, VFC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import {
  openHireApplicantModal,
  openRejectApplicantModal,
  openScheduleApplicantModal,
} from 'containers/GlobalPopup/actions';
import useJob from 'hooks/useJob';
import globalMessages from 'shared/global/messages';

import { messages } from '../../messages';

const useStyles = makeStyles(theme => ({
  cancelButton: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  submitButton: {
    [theme.breakpoints.down('xs')]: {
      flex: 1,
    },
  },
  formControlLabel: {
    color: theme.palette.common.black,
    padding: theme.spacing(0.25),
  },
  radioGroup: {
    marginTop: theme.spacing(2),
  },
  radioText: {
    fontSize: theme.typography.fontSize,
  },
}));

interface getJobData {
  data: {
    stages: [
      {
        // eslint-disable-next-line camelcase
        is_hiring_stage: boolean;
        type: string;
        name: string;
        // eslint-disable-next-line camelcase
        partner_id: number | null;
      },
    ];
  };
  isLoading: boolean;
}

const getJobIdFromUrl = (url: string): string | null => {
  // Regular expression to capture the UUID after '/jobs/'
  // eslint-disable-next-line no-useless-escape
  const regex = /\/jobs\/([a-f0-9\-]{36})/i;
  const match = url.match(regex);

  // Return the captured Job ID if found, otherwise null
  return match ? match[1] : null;
};

const getHiredStage = (
  dataObject: getJobData,
): {
  // eslint-disable-next-line camelcase
  is_hiring_stage: boolean;
  type: string;
  name: string;
  // eslint-disable-next-line camelcase
  partner_id: number | null;
} => {
  const hiredStages = dataObject.data.stages.filter(
    stage => stage.is_hiring_stage,
  );
  if (hiredStages.length > 1) {
    const partnerHiredStages = hiredStages.filter(
      stage => stage.partner_id !== null,
    );
    if (partnerHiredStages.length > 0) {
      return partnerHiredStages[0];
    }
  }
  return hiredStages[0];
};

const getRejectedStage = (
  dataObject: getJobData,
): { type: string; name: string } => {
  return dataObject.data.stages.filter(
    stage => stage.type === 'RejectedStage',
  )[0];
};

interface ModalContentProps {
  handleClose: () => void;
  applicantId: string;
  attended: boolean;
  schedulerStageId: string;
  schedulerStageType: string;
}

const ACTION_TYPES = {
  hire: 'hire',
  reject: 'reject',
  reschedule: 'reschedule',
};

export const ImmediateHiringDecisionModalContent: VFC<ModalContentProps> = ({
  handleClose,
  applicantId,
  attended,
  schedulerStageId,
  schedulerStageType,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const jobUuid = getJobIdFromUrl(window.location.href);
  const data = useJob(jobUuid);
  let hiredStageName = 'Hired';
  let rejectedStageName = 'Rejected';
  if (data.isLoading === false && data.data) {
    const hiredStage = getHiredStage(data as unknown as getJobData);
    if (hiredStage) {
      hiredStageName = hiredStage.name;
    }
    const rejectedStage = getRejectedStage(data as unknown as getJobData);
    if (rejectedStage) {
      rejectedStageName = rejectedStage.name;
    }
  }

  const [selectedAction, setSelectedAction] = useState('');

  const onSubmit = () => {
    switch (selectedAction) {
      case ACTION_TYPES.hire:
        dispatch(
          openHireApplicantModal({
            applicantId,
            fromStageId: schedulerStageId,
          }),
        );
        break;
      case ACTION_TYPES.reject:
        dispatch(
          openRejectApplicantModal({
            applicantId,
            fromStageId: schedulerStageId,
          }),
        );
        break;
      case ACTION_TYPES.reschedule:
        dispatch(
          openScheduleApplicantModal({
            applicantId,
            schedulerStageId,
            schedulerStageType,
          }),
        );
        break;
      default:
        break;
    }
  };

  const handleSelectAction: ChangeEventHandler<HTMLInputElement> = e => {
    setSelectedAction(e.target.value);
  };

  const nonRejectAction = attended
    ? ACTION_TYPES.hire
    : ACTION_TYPES.reschedule;
  const nonRejectActionLabel =
    nonRejectAction === ACTION_TYPES.hire ? (
      <FormattedMessage
        {...messages.candidateMoveToHiredOption}
        values={{
          b: (chunks: string) => <b>{chunks}</b>,
          stageName: hiredStageName,
        }}
      />
    ) : (
      <FormattedMessage
        {...messages.candidateRescheduleOption}
        values={{
          b: (chunks: string) => <b>{chunks}</b>,
        }}
      />
    );

  return (
    <>
      <InnerContent>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage {...messages.moveForward} />
        </Typography>
        <RadioGroup
          value={selectedAction}
          onChange={handleSelectAction}
          className={classes.radioGroup}
        >
          <FormControlLabel
            value={nonRejectAction}
            control={
              <Radio
                key={nonRejectAction}
                style={{ paddingLeft: 5, paddingTop: 0, paddingBottom: 0 }}
              />
            }
            label={
              <div className={classes.radioText}>{nonRejectActionLabel}</div>
            }
            className={classes.formControlLabel}
          />
          <FormControlLabel
            value={ACTION_TYPES.reject}
            control={
              <Radio
                key={ACTION_TYPES.reject}
                style={{ paddingLeft: 5, paddingTop: 0, paddingBottom: 0 }}
              />
            }
            label={
              <div className={classes.radioText}>
                <FormattedMessage
                  {...messages.candidateRejectOption}
                  values={{
                    b: (chunks: string) => <b>{chunks}</b>,
                    stageName: rejectedStageName,
                  }}
                />
              </div>
            }
            className={classes.formControlLabel}
          />
        </RadioGroup>
      </InnerContent>
      <SimpleModalFooter>
        <Button
          className={classes.cancelButton}
          type="secondary"
          onClick={handleClose}
          size="small"
        >
          <FormattedMessage {...globalMessages.cancel} />
        </Button>
        <Button
          className={classes.submitButton}
          onClick={onSubmit}
          size="small"
        >
          <FormattedMessage {...globalMessages.submit} />
        </Button>
      </SimpleModalFooter>
    </>
  );
};

export default ImmediateHiringDecisionModalContent;
