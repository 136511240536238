import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  modal: {
    height: '584px',
  },
  switchLabel: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.common.gray800,
  },
  availableHost: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  addUserButton: {
    marginTop: theme.spacing(1),
  },
  button: {
    alignSelf: 'center',
    color: theme.palette.common.blue400,
  },
  searchInput: {
    width: '100%',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: theme.spacing(3),
  },
  text: {
    color: theme.palette.common.gray800,
  },
  columns: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  icon: {
    fontSize: '12px',
    fill: theme.palette.common.gray600,
  },
  tableHeader: {
    color: theme.palette.common.gray600,
  },
  tableHeaderRoot: {
    '&.MuiTableCell-root': {
      padding: theme.spacing(0, 0, 1, 0),
    },
  },
  tableCellRoot: {
    '&.MuiTableCell-root': {
      padding: theme.spacing(0, 0, 0, 0),
    },
  },
  tableRow: {
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
    '&:not(:last-child)': {
      borderBottom: theme.customBorders.gray300,
    },
  },
  availableColumn: { width: '96px' },
  interviewerColumn: {
    paddingRight: theme.spacing(4),
    width: '200px',
    maxWidth: '100%',
  },
  divider: {
    backgroundColor: theme.palette.common.gray300,
    marginBottom: theme.spacing(1),
  },
  modalContent: {
    display: 'flex',
    gap: theme.spacing(3),
    flexDirection: 'column',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0),
    listStyleType: 'none',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px, 16px, 12px, 16px',
  },
  availableRow: {
    marginBottom: theme.spacing(10),
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  subtext: {
    alignSelf: 'center',
  },
}));
