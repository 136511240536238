import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    card: {
      border: theme.customBorders.gray300,
      padding: theme.spacing(1.5, 2),
      borderRadius: theme.misc.borderRadius,
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    textRightBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: theme.spacing(2),
    },
    textWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0.75),
      padding: '5px 0px',
      width: '100%',
    },
    menuPaper: {
      minWidth: '260px',
      borderRadius: theme.misc.borderRadius,
      margin: theme.spacing(0.25, 0),
      maxHeight: '272px',
      overflowY: 'auto',
      padding: theme.spacing(1, 0),
      [theme.breakpoints.down('sm')]: {
        maxWidth: theme.spacing(50),
      },
    },
    cardHeader: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    listItem: {
      minHeight: '40px',
      display: 'flex',
      gap: theme.spacing(1),
    },
    list: {
      padding: 0,
    },
    divider: {
      backgroundColor: theme.palette.common.gray300,
      margin: theme.spacing(1, 0),
    },
    trashcan: {
      fill: theme.palette.common.red400,
    },
    copy: {
      fill: theme.palette.common.gray600,
    },
    icon: {
      fontSize: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    deleteEvent: {
      color: theme.palette.common.red400,
    },
    caretButton: {
      transform: 'rotate(90deg)',
    },
    caretIconCollapsed: {
      transform: 'rotate(180deg)',
    },
    noUnderline: {
      color: theme.palette.common.gray800,
      textDecoration: 'none',
    },
    centerMessage: {
      backgroundColor: theme.palette.common.gray100,
      height: theme.spacing(16),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    videoLinkIcon: {
      padding: theme.spacing(0, 0.5, 0, 1),
    },
    videoLink: {
      '&:hover': {
        textDecoration: 'none',
      },
    },
  }),
);
