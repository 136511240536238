import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  settings: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Settings.settings`,
    defaultMessage: 'Settings',
  },
  integration: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Settings.integration`,
    defaultMessage: 'Integration',
  },
  manageIntegration: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Settings.manageIntegration`,
    defaultMessage: 'Manage Integration',
  },
  isDocusignIntegration: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Settings.isDocusignIntegration`,
    defaultMessage:
      'This document signing stage uses the {signingProvider} integration.',
  },
  moveApplicantToNextStage: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Settings.moveApplicantToNextStage`,
    defaultMessage: 'Move applicant to next stage after signing (recommended)',
  },
  showStageInPortal: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Settings.showStageInPortal`,
    defaultMessage: 'Show stage in applicant portal progress tracker',
  },
  emailDocumentsToOwner: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Settings.emailDocumentsToOwner`,
    defaultMessage: 'Email completed documents to opening owner',
  },
  autoAdvanceSelectLabel: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Settings.autoAdvanceSelectLabel`,
    defaultMessage: 'Select an option for moving applicants to the next stage',
  },
  fountainDocumentSigning: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Settings.fountainDocumentSigning`,
    defaultMessage: 'Fountain Document Signing',
  },
  applicantAllTemplates: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Settings.applicantAllTemplates`,
    defaultMessage:
      'when the <b><u>applicant completes all</u></b> of the selected documents',
  },
  applicantAnyTemplate: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Settings.applicantAnyTemplate`,
    defaultMessage:
      'when the <b><u>applicant completes any</u></b> of the selected documents',
  },
  allRolesAllTemplates: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Settings.allRolesAllTemplates`,
    defaultMessage:
      'when all <b><u>signers complete all</u></b> of the selected documents',
  },
  allRolesAnyTemplate: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Settings.allRolesAnyTemplate`,
    defaultMessage:
      'when all <b><u>signers complete any</u></b> of the the selected documents',
  },
  qualifiedElectronicSignature: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Settings.qualifiedElectronicSignature`,
    defaultMessage: 'Qualified Electronic Signature (QES)',
  },
  qesDescription: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Settings.qesDescription`,
    defaultMessage:
      'QES includes a qualified digital certificate that indicates the identity verification has been completed face-to-face. The qualified certificates can only be issued by a Qualified Trust Service Provider (QTSP) authorized by regulatory bodies who assess the QTSP standards.',
  },
});
