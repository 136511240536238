import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { VFC } from 'react';

export const CustomizeIcon: VFC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 46 46">
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="46" height="46" rx="23" fill="#5E44FF" />
      <g clipPath="url(#clip0_802_2035)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.0948 13.5C30.7546 13.5 30.4226 13.6004 30.1403 13.7895L25.5443 16.852C24.391 17.6211 23.3364 18.5287 22.404 19.5544C24.3192 20.4547 25.86 21.9955 26.7602 23.9107C27.786 22.978 28.6936 21.923 29.4627 20.7695L32.5261 16.1744C32.6985 15.9153 32.7975 15.6142 32.8125 15.3033C32.8274 14.9924 32.7577 14.6833 32.6108 14.4088C32.464 14.1344 32.2454 13.9049 31.9784 13.7449C31.7114 13.5848 31.4061 13.5002 31.0948 13.5ZM23.5856 26.1888C24.1787 25.8594 24.7515 25.4947 25.3009 25.0968C24.9358 24.1731 24.385 23.3342 23.6827 22.632C22.9804 21.9297 22.1415 21.3789 21.2179 21.0137C20.82 21.5629 20.4553 22.1354 20.1259 22.7282L19.8744 23.1806C20.649 23.4034 21.3542 23.8193 21.9241 24.3893C22.4939 24.9594 22.9097 25.6648 23.1323 26.4394L23.5865 26.1879L23.5856 26.1888ZM18.5652 24.3567C17.6654 24.3567 16.8025 24.7142 16.1662 25.3504C15.53 25.9867 15.1725 26.8496 15.1725 27.7494C15.1726 27.9354 15.1344 28.1193 15.0604 28.2899C14.9864 28.4605 14.8781 28.6141 14.7423 28.7411C14.6065 28.8681 14.446 28.9658 14.2709 29.0282C14.0957 29.0906 13.9096 29.1163 13.7241 29.1038C13.6014 29.0956 13.4788 29.1208 13.3693 29.1768C13.2599 29.2328 13.1677 29.3175 13.1027 29.4219C13.0377 29.5262 13.0022 29.6463 13.0001 29.7692C12.998 29.8921 13.0293 30.0133 13.0907 30.1198C13.6127 31.0265 14.4193 31.7355 15.3855 32.1368C16.3517 32.538 17.4233 32.609 18.434 32.3388C19.4447 32.0686 20.3379 31.4723 20.9749 30.6424C21.612 29.8126 21.9572 28.7956 21.9571 27.7494C21.9571 27.3039 21.8693 26.8627 21.6988 26.4511C21.5283 26.0395 21.2784 25.6655 20.9634 25.3504C20.6483 25.0354 20.2743 24.7855 19.8627 24.615C19.451 24.4445 19.0108 24.3567 18.5652 24.3567Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_802_2035">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(13 13)"
          />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
