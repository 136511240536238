import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  field: {
    borderRadius: theme.spacing(0.5),
    width: '100%',
  },
  hoverButton: {
    height: 40,
    width: 40,
    padding: 'unset',
  },
  noHoverButton: {
    opacity: 0,
  },
  editIcon: {
    verticalAlign: 'middle',
  },
  editCancel: {
    padding: 0,
    cursor: 'pointer',
    border: theme.customBorders.gray300,
    borderRadius: theme.misc.borderRadius,
    width: theme.spacing(5),
    height: theme.spacing(5),
    background: 'none',
    [theme.breakpoints.down('sm')]: {
      border: 'none',
      '.&hover': {
        backgroundColor: 'none',
      },
    },
  },
  noEditCancel: {
    opacity: 0,
  },
  mobileUploadedFile: {
    color: theme.palette.common.gray800,
    fontFamily: theme.typography.body2.fontFamily,
    lineHeight: theme.typography.body2.lineHeight,
    fontSize: theme.typography.body2.fontSize,
    height: theme.spacing(1.5),
    whiteSpace: 'nowrap',
    marginLeft: theme.spacing(0.4),
  },
  mobileUploadedFileContainer: {
    marginTop: theme.spacing(1),
    alignItems: 'center',
  },
  trashIcon: {
    marginLeft: theme.spacing(0.75),
    width: theme.spacing(2),
    cursor: 'pointer',
    color: theme.palette.common.red400,
  },
  linkIconBlack: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    color: theme.palette.common.gray800,
    verticalAlign: 'middle',
    marginTop: theme.spacing(0.25),
  },
}));

export default useStyles;
