import { ApiSelfServeV2Service } from 'api-clients/monolith';
import { useApiService } from 'hooks';
import React from 'react';

export function useWorkflowOpenings({
  workflowId,
  title,
  page,
}: {
  workflowId: string;
  title: string;
  page: number;
}) {
  const fetchOpeningsForWorkflow = React.useCallback(
    () =>
      ApiSelfServeV2Service.getApiSelfServeV2Openings(workflowId, title, page),
    [workflowId, title, page],
  );

  return useApiService(fetchOpeningsForWorkflow);
}
