import { defineMessages } from 'react-intl';

export default defineMessages({
  apiError: {
    id: 'app.components.WorkflowEditor.RulesEditDataContextProvider.apiError',
    defaultMessage:
      'An error occurred. Please check your network or try again later.',
  },
  customKeys: {
    id: 'app.components.WorkflowEditor.RulesEditDataContextProvider.customKeys',
    defaultMessage: 'Custom Keys',
  },
  standardKeys: {
    id: 'app.components.WorkflowEditor.RulesEditDataContextProvider.standardKeys',
    defaultMessage: 'Standard Keys',
  },
});
