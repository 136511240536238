import PropTypes from 'prop-types';
import React from 'react';

const RequestRevisionSvg = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6667 12.8667C12.6667 13.4 12.2 13.8667 11.6667 13.8667H9.33334V15.8667H11.6667C13.3333 15.8667 14.6667 14.5333 14.6667 12.8667V10.8667H12.6667V12.8667Z"
      fill="#969EB3"
    />
    <path
      d="M1.33334 12.8667C1.33334 14.5333 2.66667 15.8667 4.33334 15.8667H6V13.8667H4.33334C3.8 13.8667 3.33334 13.4 3.33334 12.8667V10.8667H1.33334V12.8667Z"
      fill="#969EB3"
    />
    <path
      d="M11.6667 2.53333H7.13334V0.999997C7.13334 0.33333 6.4 -0.06667 5.86667 0.266663L1.73334 2.86666C1.2 3.2 1.2 3.93333 1.73334 4.26666L5.8 6.8C6.33334 7.13333 7.06667 6.73333 7.06667 6.06666V4.53333H11.6C12.1333 4.53333 12.6 5 12.6 5.53333V7.53333H14.6V5.53333C14.6667 3.86666 13.3333 2.53333 11.6667 2.53333Z"
      fill="#969EB3"
    />
  </svg>
);

RequestRevisionSvg.propTypes = {
  className: PropTypes.string,
};

export default RequestRevisionSvg;
