import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  stageDetailContainer: {
    width: '100%',
    minWidth: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  workflowEditorContent: {
    height: '100%',
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
}));
