/**
 *
 * RequestRevisionSuccessPopup Component
 *
 */

import {
  Button,
  InnerContent,
  SimpleModal,
  SimpleModalFooter,
  SimpleModalHeader,
  SimpleModalHeaderIcon,
  SimpleModalTitle,
} from '@fountain/fountain-ui-components';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { compose } from 'redux';

import CheckMarkIcon from 'images/checkmark.svg';

function RequestRevisionSuccessPopup({ handleSubmit, setIsSuccessModalOpen }) {
  const submit = () => {
    setIsSuccessModalOpen(false);
    handleSubmit();
  };

  return (
    <SimpleModal maxWidth={512}>
      <SimpleModalHeader onClose={submit}>
        <SimpleModalHeaderIcon Icon={CheckMarkIcon} color="blue" />
        <SimpleModalTitle>Request Processing</SimpleModalTitle>
      </SimpleModalHeader>
      <InnerContent>
        <Typography>
          Your revision requests are in-progress. It can take the system several
          minutes to update applicants&apos; status and stage.
        </Typography>
      </InnerContent>
      <SimpleModalFooter>
        <Button onClick={submit} color="primary">
          Got it
        </Button>
      </SimpleModalFooter>
    </SimpleModal>
  );
}

RequestRevisionSuccessPopup.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  setIsSuccessModalOpen: PropTypes.func.isRequired,
};

export default compose(memo)(RequestRevisionSuccessPopup);
