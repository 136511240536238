import { IntlShape } from 'react-intl';

import {
  CLOSE_DELETE_APPLICANT_MODAL,
  DELETE_APPLICANT_FROM_MESSENGER,
  DELETE_APPLICANT_FROM_MESSENGER_SUCCESS,
  FETCH_MESSAGE_THREADS_ERROR,
  FETCH_MESSAGE_THREADS_INIT,
  FETCH_MESSAGE_THREADS_SUCCESS,
  FETCH_OPENINGS_ERROR,
  FETCH_OPENINGS_INIT,
  FETCH_OPENINGS_MOBILE_INIT,
  FETCH_OPENINGS_SUCCESS,
  OPEN_DELETE_APPLICANT_MODAL,
  OPEN_VIDEO_RECORDING_POPUP,
  RESET_MESSAGE_THREAD_DATA,
  RESET_SELECTED_MESSAGE_THREADS,
  SELECT_ALL_MESSAGE_THREADS,
  SELECT_MESSAGE_THREAD,
  SET_ACTIVE_STATES,
  SET_FILE_RECOLLECTION_DATA,
  SET_IS_MOBILE_MESSAGES_PANEL_CLOSE,
  SET_IS_MOBILE_MESSAGES_PANEL_OPEN,
  SET_LOCATION_THREAD_COUNT,
  TOGGLE_IS_ALL_SELECTED,
  UPDATE_FUNNEL_THREAD_COUNT,
  UPDATE_MESSAGE_THREAD,
  UPDATE_MESSAGE_THREAD_UNREAD_COUNT,
  UPDATE_MESSAGE_THREADS_BUCKET_ERROR,
  UPDATE_MESSAGE_THREADS_BUCKET_INIT,
  UPDATE_MESSAGE_THREADS_COUNT,
  UPDATE_MESSAGING_THREAD_ACTIONS,
  UPDATE_MOVED_APPLICANT_STAGE,
} from './constants';
import {
  ActiveStates,
  FileRecollectionData,
  MessageType,
  MessagingThreads,
  Openings,
  Params,
  SortDirection,
} from './types';

export function fetchOpeningsAction(messageType?: MessageType) {
  return {
    type: FETCH_OPENINGS_INIT,
    messageType,
  };
}

export function fetchOpeningsMobileInit() {
  return {
    type: FETCH_OPENINGS_MOBILE_INIT,
  };
}

export function fetchOpeningsSuccessAction(openings: Openings[]) {
  return {
    type: FETCH_OPENINGS_SUCCESS,
    openings,
  };
}

export function fetchOpeningsErrorAction() {
  return {
    type: FETCH_OPENINGS_ERROR,
  };
}

export function loadMessagingThreads(
  params: Params,
  isMobile: boolean,
  sortDirection: SortDirection,
  messageType?: MessageType,
) {
  return {
    type: FETCH_MESSAGE_THREADS_INIT,
    params,
    isMobile,
    sortDirection,
    messageType,
  };
}

export function fetchMessageThreadsSuccessAction(
  messageThreads: MessagingThreads[],
  count: number,
  isMobile: boolean,
  sortDirection: SortDirection,
) {
  return {
    type: FETCH_MESSAGE_THREADS_SUCCESS,
    messageThreads,
    count,
    isMobile,
    sortDirection,
  };
}

export function updateMessageThreadsCountAction(params: Params) {
  return {
    type: UPDATE_MESSAGE_THREADS_COUNT,
    params,
  };
}

export function fetchMessageThreadsErrorAction() {
  return {
    type: FETCH_MESSAGE_THREADS_ERROR,
  };
}

export function updateMessageThreadsBucketAction(
  params: Params,
  intl: IntlShape,
  threadMessage: string,
  sortDirection: SortDirection,
  messageType?: MessageType,
) {
  return {
    type: UPDATE_MESSAGE_THREADS_BUCKET_INIT,
    params,
    intl,
    threadMessage,
    sortDirection,
    messageType,
  };
}

export function updateMessageThreadsBucketError() {
  return {
    type: UPDATE_MESSAGE_THREADS_BUCKET_ERROR,
  };
}

export function setLocationThreadCountAction(
  locationId: string,
  threadCount: number,
) {
  return {
    type: SET_LOCATION_THREAD_COUNT,
    locationId,
    threadCount,
  };
}

export function toggleIsAllSelected() {
  return {
    type: TOGGLE_IS_ALL_SELECTED,
  };
}

export function setIsMobileMessagesPanelOpenAction() {
  return {
    type: SET_IS_MOBILE_MESSAGES_PANEL_OPEN,
  };
}

export function setIsMobileMessagesPanelCloseAction() {
  return {
    type: SET_IS_MOBILE_MESSAGES_PANEL_CLOSE,
  };
}

export const updateFunnelThreadCount = (
  funnelId: string,
  openMessagingThreadsCount: number,
) => ({
  type: UPDATE_FUNNEL_THREAD_COUNT,
  payload: {
    funnelId,
    openMessagingThreadsCount,
  },
});

export function updateMessagingThreadActions(
  // eslint-disable-next-line camelcase
  openMessagingThreadsCountByFunnel: Record<string, number>,
  selectedMessagingThreads: MessagingThreads[],
) {
  return {
    type: UPDATE_MESSAGING_THREAD_ACTIONS,
    openMessagingThreadsCountByFunnel,
    selectedMessagingThreads,
  };
}

export function setFileRecollectDataAction(
  fileRecollectionData: FileRecollectionData,
) {
  return {
    type: SET_FILE_RECOLLECTION_DATA,
    fileRecollectionData,
  };
}

export function selectAllMessageThreads(
  messageThreads: MessagingThreads[],
  isAllSelected: boolean,
) {
  return {
    type: SELECT_ALL_MESSAGE_THREADS,
    messageThreads,
    isAllSelected,
  };
}

export function selectMessageThread(
  externalId: string,
  messageId: number,
  funnelExternalId: string,
) {
  return {
    type: SELECT_MESSAGE_THREAD,
    externalId,
    messageId,
    funnelExternalId,
  };
}

export function resetSelectedMessageThreads() {
  return {
    type: RESET_SELECTED_MESSAGE_THREADS,
  };
}

export function resetMessageThreadData() {
  return {
    type: RESET_MESSAGE_THREAD_DATA,
  };
}

export function updateMessageThread(messageThread: MessagingThreads) {
  return {
    type: UPDATE_MESSAGE_THREAD,
    messageThread,
  };
}

export function setActiveStates(activeState: ActiveStates) {
  return {
    type: SET_ACTIVE_STATES,
    activeState,
  };
}

export function setMessageThreadUnreadCount(
  externalId: string,
  unreadCount: number,
  unreadEmailCount: number,
) {
  return {
    type: UPDATE_MESSAGE_THREAD_UNREAD_COUNT,
    externalId,
    unreadCount,
    unreadEmailCount,
  };
}

export function openDeleteApplicantModal() {
  return {
    type: OPEN_DELETE_APPLICANT_MODAL,
  };
}

export function closeDeleteApplicantModal() {
  return {
    type: CLOSE_DELETE_APPLICANT_MODAL,
  };
}

export function deleteApplicantFromMessenger(
  applicantId: string,
  intl: IntlShape,
  updateThreadCountParams: Params,
) {
  return {
    type: DELETE_APPLICANT_FROM_MESSENGER,
    applicantId,
    intl,
    updateThreadCountParams,
  };
}

export function deleteApplicantFromMessengerSuccess(applicantId: string) {
  return {
    type: DELETE_APPLICANT_FROM_MESSENGER_SUCCESS,
    applicantId,
  };
}

export function openVideoRecordingPopup(isVideoPopupOpen: boolean) {
  return {
    type: OPEN_VIDEO_RECORDING_POPUP,
    isVideoPopupOpen,
  };
}

export function updateMovedApplicantStage(movedStageId: string) {
  return {
    type: UPDATE_MOVED_APPLICANT_STAGE,
    movedStageId,
  };
}
