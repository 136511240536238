import { CircularXXWebBook, theme } from '@fountain/fountain-ui-components';
import React from 'react';

export default function FrameHead() {
  return (
    <style>
      {`@font-face {
          font-family: "CircularXXWeb-Book";
          src: url(${CircularXXWebBook}) format("woff");
        }`}
      {`body {
          color: ${theme.palette.common.gray600};
          font-family: 'CircularXXWeb-Book', sans-serif;
          font-size: 11px;
          line-height: 15px;
          overflow-y: scroll;
          word-break: break-word;
          overflow-wrap: break-word;
          padding-left: 5px;
        }`}
      {`p { margin: 0; }`}
    </style>
  );
}
