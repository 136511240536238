import { defineMessages } from 'react-intl';

// TODO - update the scope
export const scope = 'app.components.ApplicantTableV2Pagination';

export default defineMessages({
  perPageCaption: {
    id: `${scope}.perPageCaption`,
    defaultMessage: 'Show per page',
  },
  page: {
    id: `${scope}.page`,
    defaultMessage: 'Page',
  },
  prev: {
    id: `${scope}.prev`,
    defaultMessage: 'Prev',
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: 'Next',
  },
});
