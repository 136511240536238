import { Button } from '@fountain/fountain-ui-components';
import { Popper, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import SearchInput from 'components/SearchInput';

import { messages } from '../../messages';
import { useStyles } from './styles';

export interface Option {
  title: string;
  value?: string | number;
}

const StyledOption: VFC<{ option: Option }> = ({ option }) => {
  const styles = useStyles();

  return (
    <div>
      <Typography
        variant="body2"
        color="textPrimary"
        className={classNames({
          [styles.mouseOverAdd]: !option.value,
        })}
      >
        {option.title}
      </Typography>
    </div>
  );
};

export interface SessionHostsDropdownProps {
  options: Option[];
  onChange: (option: Option | null) => void;
  disabled: boolean;
}
export const SessionHostsDropdown: VFC<SessionHostsDropdownProps> = ({
  options,
  onChange,
  disabled,
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  }, [anchorEl, setAnchorEl]);

  useEffect(() => {
    if (disabled) handleClose();
  }, [disabled, handleClose]);

  return (
    <>
      <Button
        disableRipple
        className={styles.button}
        type="secondary"
        onClick={handleClick}
        disabled={disabled}
      >
        <FormattedMessage {...messages.addUserButton} />
      </Button>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-end"
        className={styles.popper}
      >
        <Autocomplete
          open
          onClose={handleClose}
          classes={{
            paper: styles.paper,
            option: styles.option,
            popperDisablePortal: styles.popperDisablePortal,
          }}
          onChange={(_, option) => {
            onChange(option);
          }}
          disableCloseOnSelect
          disablePortal
          renderTags={() => null}
          noOptionsText={
            <Typography color="textPrimary" variant="body2">
              <FormattedMessage {...messages.noUsers} />
            </Typography>
          }
          renderOption={option => <StyledOption option={option} />}
          options={options}
          getOptionLabel={option => option.title}
          renderInput={params => (
            <SearchInput
              ref={params.InputProps.ref}
              // @ts-expect-error this will go away when converting this component to typescript
              inputProps={params.inputProps}
              autoFocus
              placeholder={intl.formatMessage(messages.search)}
              className={styles.inputBase}
            />
          )}
        />
      </Popper>
    </>
  );
};
