import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  plusButton: {
    fontSize: theme.typography.overline.fontSize,
  },
  addRule: {
    marginLeft: theme.spacing(1),
  },
}));
