import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import {
  CancelablePromise,
  WorkflowEditorService,
  WorkflowLearningStage,
} from 'api-clients/monolith';
import { LessonlyContentData } from 'api-clients/monolith/models/LessonlyContentData';
import { LessonlyLesson } from 'api-clients/monolith/models/LessonlyLesson';
import { LessonlyPath } from 'api-clients/monolith/models/LessonlyPath';
import produce from 'immer';
import React, { useContext, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import { Error } from 'components/Error';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';

import { StageSettingCard } from '../../../../StageSettingCard';
import { SettingSwitch } from '../../../SettingSwitch';
import {
  AutoAdvanceOnCompletion,
  IsHiddenFromPortal,
} from '../../SettingsSwitches';
import { messages } from './messages';
import { useStyles } from './styles';

export const IntegrationCard: VFC<{ stage: WorkflowLearningStage }> = ({
  stage,
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const { accountSlug } = useParams<{
    accountSlug: string;
  }>();

  const { setStage, updateStageResult } = useContext(StageContext);
  const errors =
    (updateStageResult?.isError && updateStageResult.error?.errors) ||
    undefined;
  const hasStageLabels = stage.has_stage_labels;
  const { mutation: fetchCourses, result } = useApiServiceMutation<
    LessonlyContentData,
    () => CancelablePromise<LessonlyContentData>,
    { errors: string[] }
  >(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    WorkflowEditorService.getInternalApiWorkflowEditorStagesLearningStagesLessonlyContent,
  );

  const onFocusContentDropdown = () => {
    if (result.status !== 'ready') {
      fetchCourses();
    }
  };

  const onContentChange = (content: LessonlyLesson | LessonlyPath) => {
    setStage(
      produce(stage, draftStage => {
        draftStage.extra.learning_resource = {
          title: content.title,
          id: content?.id?.toString(),
          type: content.type,
        };
      }),
    );
  };

  const onToggleShouldAdvanceWhenLabelsComplete = () => {
    setStage(
      produce(stage, draftStage => {
        draftStage.advanced_settings.should_auto_advance_when_labels_complete =
          !draftStage.advanced_settings
            .should_auto_advance_when_labels_complete;
      }),
    );
  };

  const onToggleNotifyWithLessonly = () => {
    setStage(
      produce(stage, draftStage => {
        draftStage.extra.notify_with_lessonly =
          !draftStage.extra.notify_with_lessonly;
      }),
    );
  };

  const manageIntegrationHref = `${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/integrations/screening?activeProvider=lessonly`;

  const content = result.status === 'ready' ? result.data.content : {};
  const lessons = content?.lessons;
  const paths = content?.paths;

  const groupedOptions = [
    ...(lessons
      ? [{ label: intl.formatMessage(messages.lessons), options: lessons }]
      : []),
    ...(paths
      ? [{ label: intl.formatMessage(messages.paths), options: paths }]
      : []),
  ];

  return (
    <StageSettingCard
      title={intl.formatMessage(messages.lessonlyIntegration)}
      variant="button"
      buttonText={intl.formatMessage(messages.manageIntegration)}
      buttonTextColor="primary"
      href={manageIntegrationHref}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Grid container className={styles.cardBody} direction="column">
        <Grid className={styles.courseSelect} item xs={5}>
          <StyledReactSelect
            aria-label={intl.formatMessage(messages.content)}
            options={result.isLoading ? undefined : groupedOptions}
            value={stage.extra?.learning_resource}
            getOptionLabel={(option: LessonlyLesson | LessonlyPath) =>
              option.title
            }
            getOptionValue={(option: LessonlyLesson | LessonlyPath) =>
              option.id.toString()
            }
            onFocus={onFocusContentDropdown}
            isLoading={result.isLoading}
            onChange={onContentChange}
            label={intl.formatMessage(messages.content)}
            error={Boolean(errors?.base)}
          />
          <Error
            error={
              !stage.extra?.learning_resource?.id &&
              errors?.base &&
              intl.formatMessage(messages.contentRequired)
            }
            align="right"
          />
        </Grid>
        <AutoAdvanceOnCompletion
          stage={stage}
          setStage={setStage}
          label={
            <FormattedMessage
              {...messages.automaticallyMoveAfterLearningCompleted}
            />
          }
        />
        <SettingSwitch
          dataKey="notify_with_lessonly"
          checked={Boolean(stage.extra?.notify_with_lessonly)}
          onChange={onToggleNotifyWithLessonly}
          label={intl.formatMessage(messages.enableLessonlyNotifications)}
          tooltipDescription={intl.formatMessage(messages.byEnablingThisToggle)}
        />
        <IsHiddenFromPortal stage={stage} setStage={setStage} />
        {hasStageLabels && (
          <SettingSwitch
            dataKey="should_auto_advance_when_labels_complete"
            checked={Boolean(
              stage.advanced_settings?.should_auto_advance_when_labels_complete,
            )}
            onChange={onToggleShouldAdvanceWhenLabelsComplete}
            label={intl.formatMessage(
              messages.shouldAutoAdvanceWhenLabelsComplete,
            )}
          />
        )}
      </Grid>
    </StageSettingCard>
  );
};
