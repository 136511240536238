import { Button } from '@fountain/fountain-ui-components';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CareerSiteParsingAbility, ChatbotService } from 'api-clients/monolith';
import React, { useCallback, useMemo, useState, VFC } from 'react';
import { classNames } from 'react-extras';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addMessageAction } from 'containers/FlashMessage/actions';

import { SelectedBrand } from '../types';
import { ErrorIcon } from './icons/ErrorIcon';
import { LoadingIcon } from './icons/LoadingIcon';
import { SuccessIcon } from './icons/SuccessIcon';
import { messages } from './messages';

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: '5px',
    width: '204px',
    padding: theme.spacing(1, 2),
    '&:disabled': {
      opacity: 'unset',
      backgroundColor: 'unset',
    },
  },
  icon: {
    width: '16px',
    height: '16px',
    marginLeft: theme.spacing(1),
  },
  buttonText: {
    lineHeight: '20px',
    fontWeight: 450,
  },
  '@keyframes rotation': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(359deg)',
    },
  },
  loader: {
    animation: '$rotation 0.7s infinite linear',
  },
}));

export interface CheckAutoScrapeProps {
  selectedBrand: SelectedBrand;
  careerSiteScrapStatus: CareerSiteParsingAbility;
  setCareerSiteScrapStatus: React.Dispatch<
    React.SetStateAction<CareerSiteParsingAbility>
  >;
  careerSiteUrl: string | undefined;
}

export const CheckAutoScrape: VFC<CheckAutoScrapeProps> = ({
  selectedBrand,
  careerSiteScrapStatus,
  setCareerSiteScrapStatus,
  careerSiteUrl,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const statusDetails = useMemo(() => {
    switch (careerSiteScrapStatus) {
      case 'YES':
        return {
          message: messages.scrapingSuccessStatus,
          icon: <SuccessIcon className={styles.icon} />,
        };
      case 'NO':
        return {
          message: messages.scrapingErrorStatus,
          icon: <ErrorIcon className={styles.icon} />,
        };
      default:
        return null;
    }
  }, [careerSiteScrapStatus, styles.icon]);

  const [isFetchingStatus, setIsFetchingStatus] = useState(false);

  const fetchTrainingStatus = useCallback(() => {
    return ChatbotService.postInternalApiChatbotSettingsCheckCareerSiteParsingAbility(
      selectedBrand.brandId,
      selectedBrand.isAllBrands,
      careerSiteUrl ?? '',
    );
  }, [selectedBrand, careerSiteUrl]);

  const handleFetchStatusClick = () => {
    setIsFetchingStatus(true);
    fetchTrainingStatus()
      .then(result => {
        setCareerSiteScrapStatus(result.career_site_parsing_ability);
      })
      .catch(() => {
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.fetchErrorStatus),
            'error',
          ),
        );
      })
      .finally(() => {
        setIsFetchingStatus(false);
      });
  };

  return (
    <Button
      type="secondary"
      className={styles.button}
      onClick={handleFetchStatusClick}
    >
      <Typography variant="body2" className={styles.buttonText}>
        <FormattedMessage {...messages.checkForAutoScrape} />
      </Typography>
      {isFetchingStatus ? (
        <LoadingIcon className={classNames(styles.icon, styles.loader)} />
      ) : (
        statusDetails?.icon
      )}
    </Button>
  );
};
