import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from '../messages';
import { AxAgentValues } from '../types';
import { useConfirmationModalStyles } from './styles';

interface DeleteWebsiteConfirmationModalProps {
  handleClose: () => void;
  openConfirmDialog: boolean;
  deleteIndex: number | null;
  setOpenConfirmDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setAxAgentValues: React.Dispatch<React.SetStateAction<AxAgentValues>>;
}

export const DeleteWebsiteConfirmationModal: React.FC<
  DeleteWebsiteConfirmationModalProps
> = ({
  handleClose,
  openConfirmDialog,
  deleteIndex,
  setOpenConfirmDialog,
  setAxAgentValues,
}) => {
  const classes = useConfirmationModalStyles();

  const confirmDelete = () => {
    if (deleteIndex !== null) {
      setAxAgentValues(prev => ({
        ...prev,
        customWebsites:
          prev.customWebsites &&
          prev.customWebsites.filter(
            (_: string, index: number) => index !== deleteIndex,
          ),
      }));

      setOpenConfirmDialog(false);
      handleClose();
    }
  };

  return (
    <Dialog
      open={openConfirmDialog}
      onClose={() => setOpenConfirmDialog(false)}
      classes={{ paperScrollPaper: classes.modalContainer }}
    >
      <DialogTitle classes={{ root: classes.modalTitle }}>
        <FormattedMessage {...messages.deleteWebsiteDomain} />
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <DialogContentText classes={{ root: classes.modalContentText }}>
          <FormattedMessage {...messages.deleteWebsiteDomainConfirmation} />
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <Button
          onClick={() => {
            setOpenConfirmDialog(false);
            handleClose();
          }}
          color="default"
          variant="outlined"
          className={classes.btnCancel}
        >
          <FormattedMessage {...messages.cancel} />
        </Button>
        <Button
          onClick={confirmDelete}
          color="secondary"
          variant="outlined"
          className={classes.btnDelete}
        >
          <FormattedMessage {...messages.delete} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
