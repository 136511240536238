import { Divider, Grid } from '@material-ui/core';
import {
  AutomatedResponsesDetail,
  ChatbotService,
  MessageTemplateDetail,
} from 'api-clients/monolith';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { makeSelectFeatureChatbotAdminEnabled } from 'containers/Auth_old/selectors';
import { useApiService } from 'hooks/useApiService';

import { Actions } from './Actions';
import { AddViewAutomatedResponse } from './AddViewAutomatedResponse';
import { AutomatedResponseBulkUpload } from './AutomatedResponseBulkUpload';
import { AutomatedResponseToggleModel } from './AutomatedResponseToggleModel';
import { Header } from './Header';
import { ListAutomatedResponses } from './ListAutomatedResponses';
import { messages } from './messages';
import { ModelStatus } from './ModelStatus';
import { useStyles } from './styles';

export const AutomatedResponses = () => {
  const styles = useStyles();
  const intl = useIntl();

  const isFeatureChatbotAdminEnabled = useSelector(
    makeSelectFeatureChatbotAdminEnabled(),
  );

  const [page, setPage] = useState(1);

  const [isBulkImportPopupOpen, setIsBulkImportPopupOpen] = useState(false);
  const [isToggleModePopupOpen, setIsToggleModePopupOpen] = useState(false);

  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isRefetch, setIsRefetch] = useState(false);

  const [selectedAutomatedResponse, setSelectedAutomatedResponse] =
    useState<AutomatedResponsesDetail | null>(null);
  const [messageTemplates, setMessageTemplates] = useState<
    MessageTemplateDetail[]
  >([]);

  const { result: activeModelDetail, refetch: refetchActiveModelDetails } =
    useApiService(
      useCallback(
        () => ChatbotService.getInternalApiChatbotAutomatedResponseModels(true),
        [],
      ),
    );

  const activeModel = useMemo(() => {
    if (activeModelDetail.status === 'ready') {
      setIsRefetch(false);
      if (activeModelDetail.data.automated_response_models.length > 0)
        return activeModelDetail.data.automated_response_models[0];
    }
    return null;
  }, [activeModelDetail]);

  useEffect(() => {
    if (isRefetch) {
      refetchActiveModelDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefetch]);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage(messages.automatedResponses)}</title>
        <meta name="description" content="Automated Responses" />
      </Helmet>

      <Grid
        item
        container
        direction="column"
        wrap="nowrap"
        className={styles.container}
      >
        <Grid
          item
          container
          direction="column"
          wrap="nowrap"
          className={styles.header}
        >
          <Header
            setIsBulkImportPopupOpen={setIsBulkImportPopupOpen}
            setIsToggleModePopupOpen={setIsToggleModePopupOpen}
          />
          {isFeatureChatbotAdminEnabled && (
            <ModelStatus
              activeModel={activeModel}
              isActiveModelDetailLoading={activeModelDetail.isLoading}
            />
          )}
          <Grid item>
            <Divider className={styles.divider} />
          </Grid>
        </Grid>
        {isBulkImportPopupOpen && (
          <AutomatedResponseBulkUpload
            isBulkImportPopupOpen={isBulkImportPopupOpen}
            setIsBulkImportPopupOpen={setIsBulkImportPopupOpen}
          />
        )}
        {isToggleModePopupOpen && (
          <AutomatedResponseToggleModel
            setIsToggleModePopupOpen={setIsToggleModePopupOpen}
            activeModel={activeModel}
            setIsRefetch={setIsRefetch}
          />
        )}
        {isOpenForm || selectedAutomatedResponse ? (
          <AddViewAutomatedResponse
            setIsOpenForm={setIsOpenForm}
            isViewMode={Boolean(selectedAutomatedResponse)}
            selectedAutomatedResponse={selectedAutomatedResponse}
            setSelectedAutomatedResponse={setSelectedAutomatedResponse}
            messageTemplates={messageTemplates}
          />
        ) : (
          <>
            <Actions
              setIsOpenForm={setIsOpenForm}
              isActiveModel={Boolean(activeModel)}
            />
            <ListAutomatedResponses
              setSelectedAutomatedResponse={setSelectedAutomatedResponse}
              setMessageTemplates={setMessageTemplates}
              page={page}
              setPage={setPage}
              isRefetch={isRefetch}
              setIsRefetch={setIsRefetch}
              isActiveModelDetailLoading={activeModelDetail.isLoading}
              isActiveModel={Boolean(activeModel)}
            />
          </>
        )}
      </Grid>
    </>
  );
};
