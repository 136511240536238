import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { produce } from 'immer';
import React, { FC, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
  everifyCaseEligibilityStatementsHash,
  getConditionErrorMessage,
} from 'containers/WorkflowEditor/components/Rules/Condition/constants';
import { messages as conditionMessages } from 'containers/WorkflowEditor/components/Rules/Condition/messages';
import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';

import { RulesProps, SharedConditionProps } from '../../types';

export const EverifyCaseEligibilityStatement: FC<SharedConditionProps> = ({
  condition,
  ruleId,
  setRules,
  setDocumentSigningRules,
  errors,
}) => {
  const intl = useIntl();

  const { extra } = condition;

  const { condition_statuses: conditionStatuses } =
    useContext(RulesEditDataContext);

  const caseEligibilityLabels = everifyCaseEligibilityStatementsHash(intl);

  const eligibilityStatementOptions =
    conditionStatuses?.everify?.case_eligibility_statement?.map(
      caseEligibilityStatement => ({
        label: caseEligibilityLabels[caseEligibilityStatement],
        value: caseEligibilityStatement,
      }),
    );

  const sortedCaseEligibilityStatementOptions = useMemo(
    () =>
      eligibilityStatementOptions?.sort((a, b) =>
        a.label.localeCompare(b.label),
      ),
    [eligibilityStatementOptions],
  );

  const selectedCaseEligibilityStatement =
    eligibilityStatementOptions?.find(
      option => option.value === extra.everify?.case_eligibility_statement,
    ) ?? null;

  const onChangeSelection = (newSelection: {
    label: string;
    value: string;
  }) => {
    const rulesSetter = setRules ?? setDocumentSigningRules;

    if (rulesSetter) {
      rulesSetter(
        produce((draftRules: RulesProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];
          const idx = draftConditions.findIndex(
            cond => cond.id === condition.id,
          );
          draftConditions[idx].extra = {
            everify: { case_eligibility_statement: newSelection.value },
          };
        }),
      );
    }
  };

  const caseEligibilityStatementErrorMessage =
    !extra?.everify?.case_eligibility_statement &&
    getConditionErrorMessage('caseEligibilityStatement', errors);

  return (
    <Grid item xs={6}>
      <StyledReactSelect
        options={sortedCaseEligibilityStatementOptions}
        label={intl.formatMessage(
          conditionMessages.everifyCaseEligibilityStatement,
        )}
        aria-label={intl.formatMessage(
          conditionMessages.everifyCaseEligibilityStatement,
        )}
        onChange={onChangeSelection}
        value={selectedCaseEligibilityStatement}
        error={caseEligibilityStatementErrorMessage}
        placeholder=""
        required
      />
    </Grid>
  );
};
