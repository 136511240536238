import { Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';
import React, { useEffect, useState, VFC } from 'react';

import { useStyles } from './styles';

export interface InterpretationLabelProps {
  interpretation: string;
}

export const InterpretationLabel: VFC<InterpretationLabelProps> = ({
  interpretation,
}) => {
  const styles = useStyles();

  const [parsedInterpretation, setParsedInterpretation] =
    useState(interpretation);

  useEffect(() => {
    if (interpretation) {
      if (Array.isArray(interpretation))
        setParsedInterpretation(interpretation.join(', '));
    }
  }, [interpretation]);

  return (
    <Grid item>
      <Typography
        variant="body2"
        className={classNames(styles.label, styles.classifiedLabel)}
      >
        {parsedInterpretation}
      </Typography>
    </Grid>
  );
};
