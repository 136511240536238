import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { AutomatedResponseModel } from 'api-clients/monolith';
import React, { VFC } from 'react';
import { useIntl } from 'react-intl';

import { messages } from './messages';
import { useStyles } from './styles';

export interface ModelFilterProps {
  models: AutomatedResponseModel[];
  handleModelChange: (values: AutomatedResponseModel[]) => void;
}

export const ModelFilter: VFC<ModelFilterProps> = ({
  models,
  handleModelChange,
}) => {
  const styles = useStyles();
  const intl = useIntl();

  return (
    <Grid item>
      <StyledReactSelect
        isMulti
        canSelectAll
        options={models}
        onChange={handleModelChange}
        label={intl.formatMessage(messages.modelLabel)}
        aria-label={intl.formatMessage(messages.modelLabel)}
        getOptionLabel={(option: AutomatedResponseModel) => option.name}
        getOptionValue={(option: AutomatedResponseModel) => option.external_id}
        className={styles.selectModelInput}
      />
    </Grid>
  );
};
