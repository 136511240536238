import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React, { FC } from 'react';

export const ArchivedStageIcon: FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM10.25 8.75C10.6642 8.75 11 8.41421 11 8C11 7.58579 10.6642 7.25 10.25 7.25H9C8.86193 7.25 8.75 7.13807 8.75 7V4.75C8.75 4.33579 8.41421 4 8 4C7.58579 4 7.25 4.33579 7.25 4.75V8C7.25 8.41421 7.58579 8.75 8 8.75H10.25Z"
    />
  </SvgIcon>
);
