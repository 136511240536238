import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  alertContainer: {
    marginBottom: theme.spacing(1.5),
    '& > div': {
      margin: 'initial', // Have icon & text left aligned as per design
    },
  },
  signerField: {
    paddingTop: theme.spacing(3),
  },
  warning: {
    marginTop: theme.spacing(3),
  },
  button: {
    width: 'auto',
  },
}));
