/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IndeedCampaignPredictionResponse } from '../models/IndeedCampaignPredictionResponse';
import type { IndeedIntegrationSettings } from '../models/IndeedIntegrationSettings';
import type { UpdateIndeedFeedSettings } from '../models/UpdateIndeedFeedSettings';
import type { VonqCampaignCreate } from '../models/VonqCampaignCreate';
import type { VonqFunnel } from '../models/VonqFunnel';
import type { VonqToken } from '../models/VonqToken';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class JobBoardsService {

    /**
     * Campaign Predictions
     * Fetches Indeed Campaign Prediction data for a given funnel and budget/date params
     * @param funnelExternalId
     * @param budget
     * @param startDate
     * @param endDate
     * @returns IndeedCampaignPredictionResponse Success
     * @throws ApiError
     */
    public static getInternalApiJobBoardsIndeedCampaignPredictions(
        funnelExternalId: string,
        budget: number,
        startDate: string,
        endDate: string,
    ): CancelablePromise<IndeedCampaignPredictionResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/job_boards/indeed_campaign_predictions/{funnel_external_id}',
            path: {
                'funnel_external_id': funnelExternalId,
            },
            query: {
                'budget': budget,
                'start_date': startDate,
                'end_date': endDate,
            },
            errors: {
                422: `Error`,
            },
        });
    }

    /**
     * Indeed Integration
     * @returns IndeedIntegrationSettings successful
     * @throws ApiError
     */
    public static getInternalApiJobBoardsIndeedIntegration(): CancelablePromise<IndeedIntegrationSettings> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/job_boards/indeed_integration',
        });
    }

    /**
     * Update Indeed Settings
     * @param requestBody Update Indeed Feed Settings
     * @returns IndeedIntegrationSettings successful
     * @throws ApiError
     */
    public static patchInternalApiJobBoardsIndeedIntegration(
        requestBody: UpdateIndeedFeedSettings,
    ): CancelablePromise<IndeedIntegrationSettings> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/internal_api/job_boards/indeed_integration',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Unlink indeed
     * Unlinks the current indeed integration from the associated user (or account/brand)
     * @returns void
     * @throws ApiError
     */
    public static deleteInternalApiJobBoardsIndeedIntegrationUnlink(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/internal_api/job_boards/indeed_integration/unlink',
        });
    }

    /**
     * Returns decorated funnel for injecting into VONQ HAPI Elements
     * @param funnelId The Opening ID
     * @returns VonqFunnel successful
     * @throws ApiError
     */
    public static getInternalApiJobBoardsVonqFunnels(
        funnelId: string,
    ): CancelablePromise<VonqFunnel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/job_boards/vonq_funnels/{funnel_id}',
            path: {
                'funnel_id': funnelId,
            },
            errors: {
                401: `not signed in`,
                404: `not found`,
            },
        });
    }

    /**
     * Purchases made within VONQ HAPI Elements
     * @param funnelId The Opening ID
     * @param requestBody Payload from Vonq
     * @returns any successful
     * @throws ApiError
     */
    public static postInternalApiJobBoardsVonqFunnels(
        funnelId: string,
        requestBody: VonqCampaignCreate,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/job_boards/vonq_funnels/{funnel_id}',
            path: {
                'funnel_id': funnelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `not signed in`,
                404: `no access`,
            },
        });
    }

    /**
     * Takes Campaign Offline
     * @param sourcingPurchaseId The Sourcing Purchase
     * @returns any successful
     * @throws ApiError
     */
    public static deleteInternalApiJobBoardsVonqProducts(
        sourcingPurchaseId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/internal_api/job_boards/vonq_products/{sourcing_purchase_id}',
            path: {
                'sourcing_purchase_id': sourcingPurchaseId,
            },
            errors: {
                400: `does not succeed`,
                401: `unauthorized`,
            },
        });
    }

    /**
     * retrieve JWT token
     * @returns VonqToken successful
     * @throws ApiError
     */
    public static postInternalApiJobBoardsVonq(): CancelablePromise<VonqToken> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/job_boards/vonq',
            errors: {
                401: `unauthorized`,
            },
        });
    }

}