import { defineMessages } from 'react-intl';

export const multipleChoiceQuestionFormMessages = defineMessages({
  addOptionButtonText: {
    id: `app.components.FormBuilder.QuestionTypeForms.MultipleChoice.addOptionButtonText`,
    defaultMessage: 'Add option',
  },
  questionFormOptionChoiceLabel: {
    id: `app.components.FormBuilder.QuestionTypeForms.MultipleChoice.questionFormOptionChoiceLabel`,
    defaultMessage: 'choice text',
  },
  questionFormOptionChoicePlaceholder: {
    id: `app.components.FormBuilder.QuestionTypeForms.MultipleChoice.questionFormOptionChoicePlaceholder`,
    defaultMessage: 'Choice',
  },
  questionFormOptionDeleteText: {
    id: `app.components.FormBuilder.QuestionTypeForms.MultipleChoice.questionFormOptionDeleteText`,
    defaultMessage: 'delete option',
  },
  header: {
    id: `app.components.FormBuilder.QuestionTypeForms.MultipleChoice.header`,
    defaultMessage: 'Options',
  },
});
