import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { VFC } from 'react';

export const SendIcon: VFC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 18 20">
    <svg>
      <path
        d="M16.0312 0.126509C16.4991 -0.207908 17.1431 0.163919 17.0874 0.736312L15.2725 19.3941C15.2186 19.9479 14.5522 20.1984 14.1469 19.8172L9.8221 15.7502C9.59644 15.538 9.49685 15.224 9.55896 14.9206L10.1741 11.9146C10.2171 11.7044 9.96774 11.5604 9.80719 11.7028L7.51152 13.7385C7.27975 13.944 6.95803 14.0147 6.66142 13.9254L0.976924 12.2135C0.444178 12.0531 0.327922 11.3507 0.780562 11.0272L16.0312 0.126509Z"
        fill={props.fill}
      />
    </svg>
  </SvgIcon>
);
