const resources = ['brands', 'jobs', 'locations', 'stages'];

const isGuid = (str: string) =>
  str.match(
    /\b[\da-f]{8}-[\da-f]{4}-[1-5][\da-f]{3}-[89ab][\da-f]{3}-[\da-f]{12}\b/i,
  );

interface PathData {
  [index: string]: string;
}

/**
 * Get available information from the url.
 *
 * @returns {object} k: slug, v: value
 */
const useLocation = () => {
  const {
    location: { pathname, search: queryString },
  } = window;

  /**
   * @typedef {object} PathData
   *
   * @property {string} [brand] - brandId
   * @property {string} [job] - jobId
   * @property {string} [location] - locationId
   * @property {string} [stage] - stageId
   */
  const pathData: PathData = {};
  pathname
    .split('/')
    .filter(segment => !!segment)
    .forEach((segment, index, arr) => {
      if (index === 0) {
        pathData.account = segment;
      }

      const nextSegment = arr[index + 1];
      if (isGuid(segment) || !nextSegment) {
        return;
      }

      if (!resources.includes(nextSegment)) {
        const key =
          segment.charAt(segment.length - 1) === 's'
            ? segment.slice(0, segment.length - 1)
            : segment;
        pathData[key] = nextSegment;
      }
    });

  const search = new URLSearchParams(queryString);
  const searchData = Object.fromEntries(search);

  return { ...pathData, ...searchData };
};

export default useLocation;
