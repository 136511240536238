import { Grid, Typography } from '@material-ui/core';
import cx from 'classnames';
import React, { FC } from 'react';

import { useStyles } from './styles';

export interface TagProps {
  className?: string;
  label: string;
}

export const Tag: FC<TagProps> = ({ className, label }) => {
  const styles = useStyles();

  return (
    <Grid className={cx(styles.tag, className)}>
      <Typography variant="h5">{label}</Typography>
    </Grid>
  );
};
