import {
  FETCH_MESSAGE_TEMPLATES_ERROR,
  FETCH_MESSAGE_TEMPLATES_INIT,
  FETCH_MESSAGE_TEMPLATES_SUCCESS,
} from './constants';

export function fetchMessageTemplatesAction(
  templateType,
  templateVariant,
  applicantId,
) {
  return {
    type: FETCH_MESSAGE_TEMPLATES_INIT,
    templateType,
    templateVariant,
    applicantId,
  };
}

export function fetchMessageTemplatesSuccessAction(
  messageTemplates,
  whatsAppTemplates,
) {
  return {
    type: FETCH_MESSAGE_TEMPLATES_SUCCESS,
    messageTemplates,
    whatsAppTemplates,
  };
}

export function fetchMessageTemplatesErrorAction() {
  return {
    type: FETCH_MESSAGE_TEMPLATES_ERROR,
  };
}
