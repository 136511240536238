import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import { getConditionErrorMessage } from 'containers/WorkflowEditor/components/Rules/Condition/constants';
import { messages as conditionMessages } from 'containers/WorkflowEditor/components/Rules/Condition/messages';
import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';

import { DocumentSigningRuleProps } from '../../../DocumentSigningRules/types';
import {
  PartnerRuleProps,
  SharedPartnerConditionProps,
} from '../../../PartnerRules/types';
import { RulesProps, SharedConditionProps } from '../../../types';

export const PartnerStatusSelect: FC<
  SharedConditionProps | SharedPartnerConditionProps
> = ({
  condition,
  ruleId,
  setRules,
  setDocumentSigningRules,
  setPartnerRules,
  errors,
}) => {
  const intl = useIntl();
  const { partner_statuses: partnerStatuses } =
    useContext(RulesEditDataContext);
  const { extra } = condition;

  const onChangeStatus = (newStatus: { label: string; value: string }) => {
    const rulesSetter = setRules ?? setDocumentSigningRules;

    if (rulesSetter) {
      rulesSetter(
        produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];

          const idx = draftConditions.findIndex(
            cond => cond.id === condition.id,
          );

          draftConditions[idx].extra.status = newStatus.value;
        }),
      );
    }

    if (setPartnerRules) {
      setPartnerRules(
        produce((draftRules: PartnerRuleProps) => {
          const draftCondition = draftRules[ruleId].condition_attributes;

          draftCondition.extra.status = newStatus.value;
        }),
      );
    }
  };

  const selectedStatus =
    partnerStatuses?.find(option => option.value === extra.status) ?? null;

  const statusErrorMessage =
    !extra?.status && getConditionErrorMessage('status', errors);

  return (
    <Grid item xs={6}>
      <StyledReactSelect
        options={partnerStatuses}
        value={selectedStatus}
        onChange={onChangeStatus}
        label={intl.formatMessage(conditionMessages.status)}
        aria-label={intl.formatMessage(conditionMessages.status)}
        isSearchable
        error={statusErrorMessage}
        placeholder=""
        required
      />
    </Grid>
  );
};
