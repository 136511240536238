import { CustomLabel } from 'containers/ApplicantTableV2/types';

const TRANSLATION: { [key: string]: string } = {
  it: 'Contattato',
  es: 'Contactado',
  de: 'Kontaktiert',
  fr: 'Contacté',
  en: 'Contacted',
};

export const isContactedLabel = (customLabel: CustomLabel): boolean => {
  return customLabel.title.trim().toLowerCase() === 'contacted';
};

export const replaceCustomLabelTitle = (
  customLabel: CustomLabel,
  recruiterLanguage: string,
): CustomLabel => {
  return {
    ...customLabel,
    title: TRANSLATION[recruiterLanguage] || customLabel.title,
  };
};

export const translateCustomLabels = (
  customLabels: CustomLabel[] | undefined,
  recruiterLanguage: string,
): CustomLabel[] | undefined => {
  return customLabels?.map(customLabel => {
    if (isContactedLabel(customLabel)) {
      return replaceCustomLabelTitle(customLabel, recruiterLanguage);
    }
    return customLabel;
  });
};
