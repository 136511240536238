import { DateTime } from 'luxon';

const TIME_FORMAT = 'h:mm a ZZZZ';

export const roundMinutes = (minutes: number) =>
  ((Math.round(minutes / 15) * 15) % 60) + 15;

export type TimeZoneParam = { timeZone: string };

export const getDayDifference = (startDateString?: string | null) => {
  if (!startDateString) return false;
  const startDate = new Date(startDateString);
  const currentDate = new Date();
  const diff = Math.abs(currentDate.getTime() - startDate.getTime()); // in milliseconds
  const dayInMilliseconds = 1000 * 60 * 60 * 24;
  return diff / dayInMilliseconds;
};

export const sameDay = (startDateString: string) => {
  if (!startDateString) return false;
  const startDate = new Date(startDateString);
  const currentDate = new Date();
  return (
    startDate.getDate() === currentDate.getDate() &&
    startDate.getMonth() === currentDate.getMonth() &&
    startDate.getFullYear() === currentDate.getFullYear()
  );
};

export function generateAvailabilityTimes(
  date: Date,
  minutes: number,
  timeZone: TimeZoneParam,
) {
  const todayZone = DateTime.local({ zone: timeZone.timeZone });
  const today = todayZone.toJSDate();

  let selectedDate =
    today < date
      ? DateTime.fromJSDate(date, { zone: timeZone.timeZone }).set({
          hour: 0,
          minute: 0,
        })
      : todayZone.set({
          minute: ((Math.round(DateTime.local().minute / 15) * 15) % 60) + 15,
        });

  const endDay = selectedDate.set({ hour: 23, minute: 59 });
  const times = [];

  while (selectedDate < endDay) {
    times.push({
      label: selectedDate.toFormat(TIME_FORMAT),
      value: selectedDate.toISO(),
    });
    selectedDate = selectedDate.plus({ minutes });
  }

  return times;
}

export const minDate = (timeZone: TimeZoneParam) => {
  const today = DateTime.now().setZone(timeZone.timeZone);
  return new Date(today.year, today.month - 1, today.day);
};

export const isDateYearSameAsCurrent = (date: Date | string) => {
  const dateYear = new Date(date).getFullYear();
  const currentYear = new Date().getFullYear();

  return dateYear === currentYear;
};

export const dateStringToDateObject = (dateString: string) => {
  let date = dateString
    ? new Date(dateString.replace(/-/g, '/').replace(/T.+/, ''))
    : undefined;
  date = Number.isNaN(Number(date)) ? undefined : date;

  return date;
};
