// import {
//   DUMMY_LESSONLY_LESSONS,
//   DUMMY_LESSONLY_PATHS,
// } from './LearningCondition.fixtures';

export interface ResourceOption {
  label: string;
  value: string | number;
  type?: string;
  options?: Array<{
    label: string;
    value: string | number;
    type?: string;
  }>;
}

interface LessonlyResource {
  id: number;
  title: string;
  type: string;
}

export const generateLessonlyResourceOptions = (
  lessons?: Array<LessonlyResource> /* = DUMMY_LESSONLY_LESSONS */,
  paths?: Array<LessonlyResource> /* = DUMMY_LESSONLY_PATHS */,
): ResourceOption[] => {
  const options = [];
  if (lessons) {
    options.push({
      label: 'Lessons',
      value: 'lessons-group',
      options: lessons.map(lesson => ({
        label: lesson.title,
        value: lesson.id,
        type: lesson.type,
      })),
    });
  }
  if (paths) {
    options.push({
      label: 'Paths',
      value: 'paths-group',
      options: paths.map(path => ({
        label: path.title,
        value: path.id,
        type: path.type,
      })),
    });
  }
  return options;
};
