import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';

import { Field } from 'containers/DataValidationModal/components/Field';
import { FileField } from 'containers/DataValidationModal/components/FileField';
import { SharedFieldProps } from 'containers/DataValidationModal/components/types';

const useStyles = makeStyles(theme => ({
  field: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1.5),
    },
  },
}));

export type DataValidationFieldProps = SharedFieldProps;

export const DataValidationField: FC<DataValidationFieldProps> = ({
  ...props
}) => {
  const styles = useStyles();
  return (
    <Grid className={styles.field}>
      {props.detail.type !== 'file' && <Field {...props} />}
      {props.detail.type === 'file' && <FileField {...props} />}
    </Grid>
  );
};
