import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme>(theme => ({
  infoIcon: {
    height: theme.spacing(1.5),
    width: theme.spacing(1.5),
  },
}));

export default useStyles;
