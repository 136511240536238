import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  bulkActionQueue: {
    position: 'fixed',
    zIndex: theme.zIndex.previewFullscreen,
    bottom: theme.spacing(4),
    right: theme.spacing(6),
  },
  trigger: {
    border: theme.customBorders.gray300,
    boxShadow: `0px 4px 8px rgba(19, 30, 64, 0.16)`,
    background: theme.palette.common.white,
    height: 42,
    width: 42,

    '&:hover': {
      backgroundColor: theme.palette.common.gray200,
    },
  },
  closeWidget: {
    backgroundColor: theme.palette.common.blue400,
    border: theme.customBorders.primary,
  },
  bulkActionsList: {
    background: 'transparent',
    border: 'none',
    borderRadius: theme.spacing(0.5),
    boxShadow: 'none',
    marginTop: theme.spacing(-8.25),
    marginLeft: theme.spacing(-4.25),
    width: 360,
    maxWidth: '100%',
    maxHeight: 247,
    minHeight: 247,
    display: 'flex',
    flexDirection: 'column',
    bottom: 82,
    right: 47,

    // These need !important to override the Menu styling because scrolling
    // a Page will result in the Menu being mispositioned
    top: 'initial !important',
    left: 'initial !important',

    '& > ul': {
      backgroundColor: theme.palette.common.white,
      display: 'flex',
      flexDirection: 'column',
      border: theme.customBorders.gray300,
      borderBottom: 'none',
      borderRadius: theme.spacing(0.5),
      boxShadow: theme.customShadows.dropdown,
      marginTop: 'auto',
    },

    '& li': {
      borderBottom: theme.customBorders.gray300,
    },
  },
  tooltip: {
    paddingTop: theme.spacing(1),
  },
  icon: {
    WebkitUserDrag: 'none',
  },
}));

export default useStyles;
