import {
  Datepicker,
  StyledReactSelect,
} from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import { DataKey } from 'api-clients/monolith';
import { produce } from 'immer';
import React, { FC, useState } from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { InputField } from 'containers/WorkflowEditor/components/Rules/Action/components/InputField';
import { getActionErrorMessage } from 'containers/WorkflowEditor/components/Rules/Action/constants';
import {
  CHECKBOXES,
  DATEPICKER,
  DROPDOWN,
  RADIO,
} from 'containers/WorkflowEditor/components/Rules/constants';
import { dateStringToDateObject } from 'utils/date';
import { getDateFormatPattern } from 'utils/datePlaceholder';
import { getReversedDashedDateUS } from 'utils/stringutils';

import { RulesProps, SharedActionProps } from '../../../types';
import { messages } from './messages';
import { useStyles } from './styles';

export interface FieldByTypeProps extends SharedActionProps {
  label?: string;
  selectedStandardDataKey?: DataKey;
}

export const FieldByType: FC<FieldByTypeProps> = React.memo(
  ({ setRules, ruleId, action, errors, selectedStandardDataKey }) => {
    const intl = useIntl();
    const locale = useSelector(makeSelectLocale());
    const styles = useStyles();

    const { extra } = action;

    const isCheckboxes = selectedStandardDataKey?.type === CHECKBOXES;

    let defaultSelectValue;

    if (isCheckboxes && Array.isArray(extra?.value)) {
      defaultSelectValue = extra?.value?.map(str => ({
        label: str,
        value: str,
      }));
    } else {
      defaultSelectValue = selectedStandardDataKey?.options?.find(
        option => option.value === extra.value,
      );
    }

    const [dropdownValue, setDropdownValue] = useState(defaultSelectValue);

    const onChangeDropdownOption = (option: {
      label: string;
      value: string;
    }) => {
      setDropdownValue(option);
    };

    const onBlurSelect = () => {
      setRules(
        produce((draftRules: RulesProps) => {
          const draftActions =
            draftRules[ruleId]?.action_set_attributes?.actions_attributes ?? [];

          const idx = draftActions.findIndex(act => act.id === action.id);

          if (Array.isArray(dropdownValue)) {
            draftActions[idx].extra.value = dropdownValue.map(obj => obj.value);
          } else {
            draftActions[idx].extra.value = dropdownValue?.value;
          }
        }),
      );
    };

    const onChangeDate = (date: Date) => {
      setRules(
        produce((draftRules: RulesProps) => {
          const draftActions =
            draftRules[ruleId]?.action_set_attributes?.actions_attributes ?? [];

          const idx = draftActions.findIndex(act => act.id === action.id);

          draftActions[idx].extra.value =
            getReversedDashedDateUS(date) || undefined;
        }),
      );
    };

    const errorMessage = getActionErrorMessage('value', errors);

    switch (selectedStandardDataKey?.type) {
      case CHECKBOXES:
      case DROPDOWN:
      case RADIO: {
        return (
          <StyledReactSelect
            options={selectedStandardDataKey.options ?? undefined}
            value={dropdownValue}
            onChange={onChangeDropdownOption}
            label={intl.formatMessage(messages.dropdownValue)}
            isSearchable
            aria-label={intl.formatMessage(messages.dropdownValue)}
            placeholder=""
            required
            error={!extra?.value && errorMessage}
            isMulti={isCheckboxes}
            onBlur={onBlurSelect}
          />
        );
      }
      case DATEPICKER: {
        const dateObj =
          typeof extra?.value === 'string'
            ? dateStringToDateObject(extra.value)
            : undefined;

        return (
          <Grid>
            <Typography variant="body2" className={styles.label}>
              {intl.formatMessage(messages.dateValue)}
            </Typography>
            <Datepicker
              locale={locale}
              onDateChange={date => {
                onChangeDate(date as Date);
              }}
              renderDate={date => (
                <FormattedDate dateStyle="short" value={date} />
              )}
              renderPlaceholder={() => getDateFormatPattern(locale)}
              value={dateObj}
              alignError="right"
              error={errorMessage}
            />
          </Grid>
        );
      }
      default: {
        return (
          <InputField
            action={action}
            ruleId={ruleId}
            setRules={setRules}
            errors={errors}
          />
        );
      }
    }
  },
);
