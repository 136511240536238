import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  form: {
    minHeight: theme.spacing(16),
    marginBottom: '50px',
    overflow: 'auto',
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  stageMappingContainer: {
    marginTop: theme.spacing(2),
  },
  workflowLabel: {
    marginTop: theme.spacing(1),
    lineHeight: '0.8rem',
    fontSize: '0.8rem',
    fontFamily: theme.typography.body2.fontFamily,
  },
  body: {
    color: theme.palette.common.gray800,
    marginBottom: theme.spacing(1),
  },
  workflowHeader: {
    color: theme.palette.common.gray600,
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  sourceType: {
    fontSize: '0.75rem',
  },
  checkboxText: {
    color: theme.palette.common.gray800,
  },
  headerContainer: {
    gap: '0.75rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    padding: '0.75rem',
    background: theme.palette.common.blue100,
    borderRadius: '6.25rem',
  },
  workflowSelect: {
    marginBottom: theme.spacing(0.5),
  },
  updateDescripiton: {
    lineHeight: '20px',
    margin: theme.spacing(1, 0),
  },
  subStage: {
    paddingLeft: theme.spacing(1),
  },
  columnSpacing: {
    paddingRight: theme.spacing(3),
  },
  schedulerWarning: {
    backgroundColor: theme.palette.common.blue100,
    borderRadius: '0.625rem',
    display: 'flex',
    gap: '0.5rem',
    marginBottom: theme.spacing(1),
  },
  infoIcon: {
    color: theme.palette.common.blue400,
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
}));
