import { IconButton, Typography } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

const TemplateMenuHeader = ({ onClose }) => (
  <>
    <Typography variant="h4">
      <FormattedMessage {...messages.dialogTitle} />
    </Typography>
    <IconButton aria-label="close" onClick={onClose} size="small">
      <Close fontSize="small" />
    </IconButton>
  </>
);

TemplateMenuHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default TemplateMenuHeader;
