import { defineMessages } from 'react-intl';

const scope = 'app.containers.ImmediateHiringDecisionModal';

export const messages = defineMessages({
  modalHeader: {
    id: `${scope}.modalHeader`,
    defaultMessage: 'Hiring Decision',
  },
  moveForward: {
    id: `${scope}.moveForward`,
    defaultMessage: 'How would you like to move forward with this applicant?',
  },
  candidateRescheduleOption: {
    id: `${scope}.candidateRescheduleOption`,
    defaultMessage: '<b>Reschedule</b> this interview',
  },
  candidateMoveToHiredOption: {
    id: `${scope}.candidateMoveToStageOption`,
    defaultMessage: `Move to <b>{stageName}</b>`,
  },
  candidateRejectOption: {
    id: `${scope}.candidateRejectOption`,
    defaultMessage: 'Move to <b>{stageName}</b>',
  },
});
