/* eslint-disable no-underscore-dangle */
import { TextButton, Trashcan } from '@fountain/fountain-ui-components';
import { Grid, IconButton, Typography } from '@material-ui/core';
import {
  DocumentSigningStagesRuleAttributes,
  EmbeddedSignatureTemplate,
  WorkflowDocumentSignatureStage,
  WorkflowDocumentSigningStage,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import { produce } from 'immer';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';

import { DEFAULT_CONDITION } from 'containers/WorkflowEditor/components/Rules/RuleCard/constants';

import { Condition } from '../../Condition';
import { messages as ruleMessages } from '../../RuleCard/messages';
import { TemplateSettings } from '../TemplateSettings';
import { DocumentSigningRuleProps } from '../types';
import { messages } from './messages';
import { useStyles } from './styles';

export interface DocumentSigningRuleCardProps {
  stage: WorkflowDocumentSigningStage | WorkflowDocumentSignatureStage;
  ruleId: string;
  index: number;
  rule: DocumentSigningStagesRuleAttributes;
  setDocumentSigningRules: React.Dispatch<
    React.SetStateAction<DocumentSigningRuleProps>
  >;
  availableEmbeddedSignatureTemplates: EmbeddedSignatureTemplate[];
  errors?: Record<string, Array<string>>;
  onDeleteRule: (ruleId: string | number) => void;
  setStage: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
}

export const DocumentSigningRuleCard: FC<DocumentSigningRuleCardProps> =
  React.memo(
    ({
      stage,
      rule,
      index,
      ruleId,
      setDocumentSigningRules,
      onDeleteRule,
      errors,
      availableEmbeddedSignatureTemplates,
      setStage,
    }) => {
      const styles = useStyles();
      const intl = useIntl();

      const {
        condition_set_attributes: conditionSetAttributes,
        template_set_attributes: templateSetAttributes,
      } = rule;

      const onAddCondition = () => {
        setDocumentSigningRules(
          produce((draftRules: DocumentSigningRuleProps) => {
            const draftConditions =
              ((draftRules[ruleId] || {}).condition_set_attributes ?? {})
                .conditions_attributes ?? [];

            draftConditions.push({
              ...DEFAULT_CONDITION,
              id: uuid(),
            });
          }),
        );
      };

      const onToggleLogic = () => {
        setDocumentSigningRules(
          produce((draftRules: DocumentSigningRuleProps) => {
            if (
              !draftRules[ruleId]?.condition_set_attributes ||
              !draftRules[ruleId].condition_set_attributes.logic
            ) {
              return;
            }
            const currentLogic =
              draftRules[ruleId].condition_set_attributes.logic;
            draftRules[ruleId].condition_set_attributes.logic =
              currentLogic === 'AND' ? 'OR' : 'AND';
          }),
        );
      };

      const conditions = conditionSetAttributes?.conditions_attributes;
      const availableConditions = conditions?.filter(cond => !cond._destroy);
      const availableTemplateSettings =
        templateSetAttributes.template_settings_attributes?.filter(
          setting => !setting._destroy,
        ) ?? [];
      const { logic } = rule.condition_set_attributes;
      const matchConditionText =
        logic === 'AND'
          ? intl.formatMessage(ruleMessages.matchAllConditions)
          : intl.formatMessage(ruleMessages.matchAnyCondition);
      const showLogicToggleButton =
        availableConditions && availableConditions.length > 1;

      return (
        <Grid className={styles.ruleCardContainer}>
          <Grid
            container
            alignItems="center"
            justify="space-between"
            className={styles.documentSetHeader}
          >
            <div className={styles.documentSetTitleContainer}>
              <Typography variant="h3" color="textPrimary">
                <FormattedMessage
                  {...messages.documentSet}
                  values={{ number: index + 1 }}
                />
              </Typography>
              {showLogicToggleButton && (
                <TextButton
                  className={styles.logicButton}
                  color="primary"
                  onClick={onToggleLogic}
                >
                  <Typography color="primary" variant="h4">
                    {matchConditionText}
                  </Typography>
                </TextButton>
              )}
            </div>
            <IconButton
              aria-label={intl.formatMessage(messages.documentSet, {
                number: index + 1,
              })}
              onClick={() => onDeleteRule(ruleId)}
            >
              <Trashcan viewBox="0 0 16 16" className={styles.trashcanIcon} />
            </IconButton>
          </Grid>

          <Grid>
            <div className={styles.paddingBottom}>
              <Grid>
                {availableConditions?.map((condition, idx) => {
                  return (
                    <Condition
                      key={uuid()}
                      ruleId={ruleId}
                      index={idx}
                      condition={condition}
                      setDocumentSigningRules={setDocumentSigningRules}
                      allowDelete={availableConditions.length > 1}
                      errors={errors}
                      logic={logic}
                    />
                  );
                })}
              </Grid>

              <TextButton
                color="primary"
                className={styles.paddingTop}
                onClick={onAddCondition}
                aria-label={`${intl.formatMessage(messages.documentSet, {
                  number: index + 1,
                })} ${intl.formatMessage(messages.addConditions)}`}
              >
                <Typography variant="body2" color="primary">
                  + <FormattedMessage {...messages.addConditions} />
                </Typography>
              </TextButton>
            </div>

            <Grid>
              <TemplateSettings
                key={uuid()}
                stage={stage}
                ruleId={ruleId}
                setDocumentSigningRules={setDocumentSigningRules}
                errors={errors}
                templateSettings={availableTemplateSettings}
                setStage={setStage}
                availableEmbeddedSignatureTemplates={
                  availableEmbeddedSignatureTemplates
                }
              />
            </Grid>
          </Grid>
        </Grid>
      );
    },
  );
