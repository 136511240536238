import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addCondition: {
    defaultMessage: 'Add Condition',
    id: `app.containers.WorkflowEditor.components.StageDetail.JobRouterStages.JobRouterConditions.JobRouterConditionSet.addCondition`,
  },
  noConditionsDescription: {
    defaultMessage:
      'No conditions have been added yet. To generate a list of openings to route to, please add conditions.',
    id: `app.containers.WorkflowEditor.components.StageDetail.JobRouterStages.JobRouterConditions.JobRouterConditionSet.noConditionsDescription`,
  },
  conditionsRequired: {
    defaultMessage: 'Conditions are required, please add at least one.',
    id: `app.containers.WorkflowEditor.components.StageDetail.JobRouterStages.JobRouterConditions.JobRouterConditionSet.conditionsRequired`,
  },
  addConditions: {
    defaultMessage: '+ Add Conditions',
    id: `app.containers.WorkflowEditor.components.StageDetail.JobRouterStages.JobRouterConditions.JobRouterConditionSet.addConditions`,
  },
});
