import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import produce from 'immer';
import React, { FC, useContext, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addMessageAction } from 'containers/FlashMessage/actions';
import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';

import { AND, OR } from '../constants';
import { DocumentSigningRuleProps } from '../DocumentSigningRules/types';
import { RowDeleteButton } from '../RowDeleteButton';
import { RowTag } from '../RowTag';
import { RulesProps, SharedConditionProps } from '../types';
import { ApplicantAge } from './ApplicantAge/ApplicantAge';
import { ApplicantData } from './ApplicantData';
import { ApplicantDataComparison } from './ApplicantDataComparison';
import { AssessmentFormScoreResult } from './AssessmentFormScoreResult';
import { BackgroundCheck } from './BackgroundCheck';
import { CommonSignStatus } from './CommonSignStatus';
import { DataKeySelect } from './components/DataKeySelect';
import { LabelSelect } from './components/LabelSelect/LabelSelect';
import { LocationSelect } from './components/LocationSelect';
import { PartnerLabelSelect } from './components/PartnerLabelSelect';
import {
  ACCURATE_STATUS,
  APPLICANT_AGE,
  APPLICANT_DATA,
  APPLICANT_DATA_COMPARISON,
  APPLICANT_DATA_EXISTS,
  APPLICANT_DATA_NOT_EXISTS,
  APPLICANT_LABEL_IS,
  APPLICANT_LABEL_IS_NOT,
  APPLICANT_LOCATION,
  ASSESSMENT_FORM_SCORE,
  CHECKR_STATUS,
  DOCU_SIGN_STATUS,
  DOCUMENT_UPLOADED,
  documentSigningStatusByProvider,
  DUPLICATE_APPLICANT,
  EMBEDDED_SIGN_STATUS,
  EVERIFY_CASE_ELIGIBILITY_STATEMENT,
  EVERIFY_CASE_STATUS,
  HELLO_SIGN_STATUS,
  I9_FORM_STATUS,
  LESSONLY_SCORE,
  LESSONLY_STATUS,
  NORTHPASS_COURSE_PROGRESS,
  NORTHPASS_QUIZ_SCORE,
  PARTNER_DETAIL_HAS_KEY,
  PARTNER_DETAIL_HAS_KEY_WITH_VALUE,
  PARTNER_LABEL_DOES_NOT_HAVE_KEY,
  PARTNER_LABEL_HAS_KEY,
  PARTNER_LABEL_HAS_KEY_WITH_VALUE,
  PARTNER_STATUS_HAS_TITLE,
  PARTNER_STATUS_IS,
  PARTNER_STATUS_IS_NOT,
  SCORE_CARD_RESULT,
  STERLING_SERVICE_LEVEL_RESULTS,
  STERLING_STATUS,
  TECH_CHECK_STATUS,
  W4_FEDERAL_FORM_STATUS,
} from './constants';
import { DuplicateApplicant } from './DuplicateApplicant';
import { EverifyCaseEligibilityStatement } from './EverifyCaseEligibilityStatement';
import { EverifyCaseStatus } from './EverifyCaseStatus';
import { I9FormStatus } from './I9FormStatus';
import { LearningCondition } from './LearningCondition';
import { messages as learningConditionMessages } from './LearningCondition/messages';
import { messages } from './messages';
import { PartnerDetailHasKey } from './PartnerDetailHasKey';
import { PartnerDetailHasKeyWithValue } from './PartnerDetailHasKeyWithValue';
import { PartnerLabelHasKeyWithValue } from './PartnerLabelHasKeyWithValue';
import { PartnerStatusHasTitle } from './PartnerStatusHasTitle';
import { PartnerStatusIs } from './PartnerStatusIs/PartnerStatusIs';
import { PartnerStatusIsNot } from './PartnerStatusIsNot';
import { ScoreCardResult } from './ScoreCardResult';
import { SterlingServiceLevelResults } from './SterlingServiceLevelResults';
import { useStyles } from './styles';
import { TechCheckStatus } from './TechCheckStatus';
import { W4FederalFormStatus } from './W4FederalFormStatus';

export interface ConditionComponentProps extends SharedConditionProps {
  allowDelete: boolean;
  index: number;
  isPresetCondition?: boolean;
  errors?: {
    [key: string]: string[];
  };
  logic?: 'AND' | 'OR';
}

export const Condition: FC<ConditionComponentProps> = React.memo(
  ({
    condition,
    ruleId,
    index,
    allowDelete,
    setRules,
    setDocumentSigningRules,
    errors,
    logic,
    isPresetCondition = false,
  }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const styles = useStyles();

    const {
      integrations,
      schoolkeep_options: schoolkeepOptions,
      lessonly_options: lessonlyOptions,
      show_tech_check_rules: showTechCheckRules,
      show_i9_form_rules: showI9FormRules,
      show_everify_rules: showEverifyRules,
      w4_federal_form_stages: w4FederalFormStages,
      partner_integrations_exist: partnerIntegrationsExist,
    } = useContext(RulesEditDataContext);

    const onSelectConditionType = (newCondition: {
      label: string;
      value: string;
    }) => {
      const rulesSetter = setRules ?? setDocumentSigningRules;

      if (!rulesSetter) {
        return;
      }
      rulesSetter(
        produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];

          const idx = draftConditions.findIndex(
            cond => cond.id === condition.id,
          );

          draftConditions[idx] = {
            id: condition?.id,
            type: newCondition.value,
            extra: {},
          };
        }),
      );
    };

    const onDeleteCondition = () => {
      const rulesSetter = setRules ?? setDocumentSigningRules;

      if (!rulesSetter) {
        return;
      }
      rulesSetter(
        produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];

          const idx = draftConditions.findIndex(
            cond => cond.id === condition.id,
          );

          // eslint-disable-next-line no-underscore-dangle
          draftConditions[idx]._destroy = true;
        }),
      );
    };

    const generateConditionTypeOptions = () => {
      let bgcConditionType;

      if (integrations?.checkr) {
        bgcConditionType = CHECKR_STATUS;
      } else if (integrations?.sterling) {
        bgcConditionType = STERLING_STATUS;
      } else if (integrations?.accurate) {
        bgcConditionType = ACCURATE_STATUS;
      }

      let ruleConditionTypes = [
        {
          label: intl.formatMessage(messages.applicantFieldIs),
          value: APPLICANT_DATA,
        },
        {
          label: intl.formatMessage(messages.applicantFieldExists),
          value: APPLICANT_DATA_EXISTS,
        },
        {
          label: intl.formatMessage(messages.applicantFieldDoesntExist),
          value: APPLICANT_DATA_NOT_EXISTS,
        },
        {
          label: intl.formatMessage(messages.applicantLabelIs),
          value: APPLICANT_LABEL_IS,
        },
        {
          label: intl.formatMessage(messages.applicantLabelIsNot),
          value: APPLICANT_LABEL_IS_NOT,
        },
        {
          label: intl.formatMessage(messages.applicantAgeIs),
          value: APPLICANT_AGE,
        },
        {
          label: intl.formatMessage(messages.applicantLocation),
          value: APPLICANT_LOCATION,
        },
        {
          label: intl.formatMessage(messages.applicantFieldComparison),
          value: APPLICANT_DATA_COMPARISON,
        },
        {
          label: intl.formatMessage(messages.scoreCardIs),
          value: SCORE_CARD_RESULT,
        },
        {
          label: intl.formatMessage(messages.documentIsUploaded),
          value: DOCUMENT_UPLOADED,
        },
        {
          label: intl.formatMessage(messages.applicantDuplicateStatus),
          value: DUPLICATE_APPLICANT,
        },
      ];

      const partnerConditionTypes = [
        {
          label: intl.formatMessage(messages.partnerDetailHasKey),
          value: PARTNER_DETAIL_HAS_KEY,
        },
        {
          label: intl.formatMessage(messages.partnerDetailHasKeyWithValue),
          value: PARTNER_DETAIL_HAS_KEY_WITH_VALUE,
        },
        {
          label: intl.formatMessage(messages.partnerLabelHasKey),
          value: PARTNER_LABEL_HAS_KEY,
        },
        {
          label: intl.formatMessage(messages.partnerLabelDoesNotHaveKey),
          value: PARTNER_LABEL_DOES_NOT_HAVE_KEY,
        },
        {
          label: intl.formatMessage(messages.partnerLabelHasKeyWithValue),
          value: PARTNER_LABEL_HAS_KEY_WITH_VALUE,
        },
        {
          label: intl.formatMessage(messages.partnerStatusIs),
          value: PARTNER_STATUS_IS,
        },
        {
          label: intl.formatMessage(messages.partnerStatusIsNot),
          value: PARTNER_STATUS_IS_NOT,
        },
        {
          label: intl.formatMessage(messages.partnerStatusHasTitle),
          value: PARTNER_STATUS_HAS_TITLE,
        },
      ];

      if (partnerIntegrationsExist) {
        ruleConditionTypes = ruleConditionTypes.concat(partnerConditionTypes);
      }

      if (w4FederalFormStages && w4FederalFormStages?.length > 0) {
        ruleConditionTypes.push({
          label: intl.formatMessage(messages.w4FederalFormStatus),
          value: W4_FEDERAL_FORM_STATUS,
        });
      }

      if (integrations?.signature_provider) {
        const documentSignatureType = integrations.signature_provider;

        ruleConditionTypes.push({
          label: intl.formatMessage(messages.documentSigningStatus),
          value: documentSigningStatusByProvider[documentSignatureType],
        });
      }

      if (bgcConditionType) {
        ruleConditionTypes.push({
          label: intl.formatMessage(messages.backgroundCheckStatus),
          value: bgcConditionType,
        });
      }

      if (integrations?.lessonly) {
        ruleConditionTypes.push(
          {
            label: intl.formatMessage(messages.lessonlyScore),
            value: LESSONLY_SCORE,
          },
          {
            label: intl.formatMessage(messages.lessonlyStatus),
            value: LESSONLY_STATUS,
          },
        );
      }

      if (integrations?.schoolkeep) {
        ruleConditionTypes.push(
          {
            label: intl.formatMessage(messages.northpassCourseProgress),
            value: NORTHPASS_COURSE_PROGRESS,
          },
          {
            label: intl.formatMessage(messages.northpassQuizScore),
            value: NORTHPASS_QUIZ_SCORE,
          },
        );
      }

      if (integrations?.sterling) {
        ruleConditionTypes.push({
          label: intl.formatMessage(
            messages.sterlingServiceLevelResultsInclude,
          ),
          value: STERLING_SERVICE_LEVEL_RESULTS,
        });
      }

      if (showTechCheckRules) {
        ruleConditionTypes.push({
          label: intl.formatMessage(messages.techCheckStatus),
          value: TECH_CHECK_STATUS,
        });
      }

      if (showI9FormRules) {
        ruleConditionTypes.push({
          label: intl.formatMessage(messages.i9FormStatus),
          value: I9_FORM_STATUS,
        });
      }

      if (showEverifyRules) {
        ruleConditionTypes.push({
          label: intl.formatMessage(messages.everifyCaseStatus),
          value: EVERIFY_CASE_STATUS,
        });
        ruleConditionTypes.push({
          label: intl.formatMessage(messages.everifyCaseEligibilityStatement),
          value: EVERIFY_CASE_ELIGIBILITY_STATEMENT,
        });
      }

      return ruleConditionTypes;
    };

    const sharedConditionProps = useMemo(
      () => ({
        setRules,
        setDocumentSigningRules,
        condition,
        ruleId,
        errors,
      }),
      [condition, errors, ruleId, setDocumentSigningRules, setRules],
    );

    const conditionTypeOptions = generateConditionTypeOptions();

    const selectedCondition = () => {
      switch (condition.type) {
        case CHECKR_STATUS:
        case STERLING_STATUS:
        case ACCURATE_STATUS:
          return {
            label: intl.formatMessage(messages.backgroundCheckStatus),
            value: condition.type,
          };
        case ASSESSMENT_FORM_SCORE:
          return {
            label: intl.formatMessage(messages.assessmentFormScore),
            value: condition.type,
          };
        default:
          return conditionTypeOptions.find(
            cond => cond.value === condition.type,
          );
      }
    };

    const currentSelectedCondition = selectedCondition();

    /*
      Catching integration errors from the BE, because integration routes have not been converted to 2.0 APIs.
      This should eventually be removed in favor of individual requests where the monolith response has errors included
      when integration resource fetching routes have been moved to `/internal_api/...` and swaggerized.
    */
    useEffect(() => {
      if (
        [LESSONLY_SCORE, LESSONLY_STATUS].includes(
          currentSelectedCondition?.value ?? '',
        ) &&
        integrations?.lessonly &&
        lessonlyOptions === null
      ) {
        dispatch(
          addMessageAction(
            intl.formatMessage(
              learningConditionMessages.lessonlyIntegrationError,
            ),
            'error',
            true,
          ),
        );
      }
      if (
        [NORTHPASS_COURSE_PROGRESS, NORTHPASS_QUIZ_SCORE].includes(
          currentSelectedCondition?.value ?? '',
        ) &&
        integrations?.schoolkeep &&
        schoolkeepOptions === null
      ) {
        dispatch(
          addMessageAction(
            intl.formatMessage(
              learningConditionMessages.northpassIntegrationError,
            ),
            'error',
            true,
          ),
        );
      }
    }, [
      dispatch,
      currentSelectedCondition,
      integrations,
      lessonlyOptions,
      schoolkeepOptions,
      intl,
      condition,
    ]);

    let rowTagLabel = intl.formatMessage(messages.or);

    if (index === 0) {
      rowTagLabel = intl.formatMessage(messages.condition);
    } else if (logic === AND) {
      rowTagLabel = intl.formatMessage(messages.and);
    } else if (logic === OR) {
      rowTagLabel = intl.formatMessage(messages.or);
    }

    const fullWidthConditionSelect = [ASSESSMENT_FORM_SCORE].includes(
      currentSelectedCondition?.value ?? '',
    );

    return (
      <Grid container className={styles.conditionRow}>
        <Grid
          container
          justify="space-between"
          wrap="nowrap"
          alignItems="center"
          className={styles.tagContainer}
        >
          <RowTag label={rowTagLabel} />
          <RowDeleteButton
            allowDelete={allowDelete}
            onDelete={onDeleteCondition}
            ariaLabel={intl.formatMessage(messages.deleteConditionNumber, {
              number: index + 1,
            })}
          />
        </Grid>
        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={fullWidthConditionSelect ? 12 : 6}>
            {isPresetCondition && currentSelectedCondition ? (
              <>
                <Typography color="textPrimary">
                  {currentSelectedCondition.label}
                </Typography>
                <input type="hidden" value={currentSelectedCondition.label} />
              </>
            ) : (
              <StyledReactSelect
                data-testid="select"
                options={conditionTypeOptions}
                value={currentSelectedCondition}
                onChange={onSelectConditionType}
                label={intl.formatMessage(messages.conditionType)}
                isSearchable
                aria-label={intl.formatMessage(messages.conditionType)}
                placeholder=""
                required
              />
            )}
          </Grid>
          {currentSelectedCondition?.value &&
            {
              [APPLICANT_DATA]: <ApplicantData {...sharedConditionProps} />,
              [APPLICANT_AGE]: <ApplicantAge {...sharedConditionProps} />,
              [APPLICANT_DATA_EXISTS]: (
                <DataKeySelect {...sharedConditionProps} />
              ),
              [APPLICANT_DATA_NOT_EXISTS]: (
                <DataKeySelect {...sharedConditionProps} />
              ),
              [APPLICANT_LABEL_IS]: <LabelSelect {...sharedConditionProps} />,
              [APPLICANT_LABEL_IS_NOT]: (
                <LabelSelect {...sharedConditionProps} />
              ),
              [APPLICANT_LOCATION]: (
                <LocationSelect {...sharedConditionProps} />
              ),
              [APPLICANT_DATA_COMPARISON]: (
                <ApplicantDataComparison {...sharedConditionProps} />
              ),
              [ASSESSMENT_FORM_SCORE]: (
                <AssessmentFormScoreResult {...sharedConditionProps} />
              ),
              [DOCUMENT_UPLOADED]: (
                <DataKeySelect {...sharedConditionProps} onlyReturnFileKeys />
              ),
              [CHECKR_STATUS]: (
                <BackgroundCheck
                  {...sharedConditionProps}
                  onChangeBackgroundCheck={onSelectConditionType}
                />
              ),
              [STERLING_STATUS]: (
                <BackgroundCheck
                  {...sharedConditionProps}
                  onChangeBackgroundCheck={onSelectConditionType}
                />
              ),
              [ACCURATE_STATUS]: (
                <BackgroundCheck
                  {...sharedConditionProps}
                  onChangeBackgroundCheck={onSelectConditionType}
                />
              ),
              [DOCU_SIGN_STATUS]: (
                <CommonSignStatus
                  {...sharedConditionProps}
                  documentSelectLabel={intl.formatMessage(
                    messages.docuSignDocument,
                  )}
                  statusField="docusign"
                />
              ),
              [DUPLICATE_APPLICANT]: (
                <DuplicateApplicant {...sharedConditionProps} />
              ),
              [EMBEDDED_SIGN_STATUS]: (
                <CommonSignStatus
                  {...sharedConditionProps}
                  documentSelectLabel={intl.formatMessage(
                    messages.helloSignDocument,
                  )}
                  statusField="white_labeled_hellosign"
                />
              ),
              [EVERIFY_CASE_STATUS]: (
                <EverifyCaseStatus {...sharedConditionProps} />
              ),
              [EVERIFY_CASE_ELIGIBILITY_STATEMENT]: (
                <EverifyCaseEligibilityStatement {...sharedConditionProps} />
              ),
              [HELLO_SIGN_STATUS]: (
                <CommonSignStatus
                  {...sharedConditionProps}
                  documentSelectLabel={intl.formatMessage(
                    messages.helloSignDocument,
                  )}
                  statusField="hellosign"
                />
              ),
              [I9_FORM_STATUS]: <I9FormStatus {...sharedConditionProps} />,
              [LESSONLY_SCORE]: <LearningCondition {...sharedConditionProps} />,
              [LESSONLY_STATUS]: (
                <LearningCondition {...sharedConditionProps} />
              ),
              [NORTHPASS_COURSE_PROGRESS]: (
                <LearningCondition {...sharedConditionProps} />
              ),
              [NORTHPASS_QUIZ_SCORE]: (
                <LearningCondition {...sharedConditionProps} />
              ),
              [PARTNER_DETAIL_HAS_KEY]: (
                <PartnerDetailHasKey {...sharedConditionProps} />
              ),
              [PARTNER_DETAIL_HAS_KEY_WITH_VALUE]: (
                <PartnerDetailHasKeyWithValue {...sharedConditionProps} />
              ),
              [PARTNER_LABEL_HAS_KEY]: (
                <PartnerLabelSelect {...sharedConditionProps} />
              ),
              [PARTNER_LABEL_DOES_NOT_HAVE_KEY]: (
                <PartnerLabelSelect {...sharedConditionProps} />
              ),
              [PARTNER_LABEL_HAS_KEY_WITH_VALUE]: (
                <PartnerLabelHasKeyWithValue {...sharedConditionProps} />
              ),
              [PARTNER_STATUS_IS]: (
                <PartnerStatusIs {...sharedConditionProps} />
              ),
              [PARTNER_STATUS_IS_NOT]: (
                <PartnerStatusIsNot {...sharedConditionProps} />
              ),
              [PARTNER_STATUS_HAS_TITLE]: (
                <PartnerStatusHasTitle {...sharedConditionProps} />
              ),
              [W4_FEDERAL_FORM_STATUS]: (
                <W4FederalFormStatus {...sharedConditionProps} />
              ),
              [SCORE_CARD_RESULT]: (
                <ScoreCardResult {...sharedConditionProps} />
              ),
              [STERLING_SERVICE_LEVEL_RESULTS]: (
                <SterlingServiceLevelResults {...sharedConditionProps} />
              ),
              [TECH_CHECK_STATUS]: (
                <TechCheckStatus {...sharedConditionProps} />
              ),
            }[currentSelectedCondition.value]}
        </Grid>
      </Grid>
    );
  },
);

Condition.displayName = 'Condition';
