/**
 *
 * ProgressIcon for BulkActionQueuedItem
 *
 */

import { Loader } from '@fountain/fountain-ui-components';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';

import { BULK_ACTION_STATUS } from 'containers/BulkActionQueue/constants';
import ErrorIcon from 'images/error-filled.svg';
import NoProgressIcon from 'images/no-progress.svg';
import CheckmarkIcon from 'images/tick-circle.svg';

import useStyles from './styles';

const ProgressIcon = ({ progress, status, isLoading }) => {
  const classes = useStyles();
  const theme = useTheme();

  if (status === BULK_ACTION_STATUS.complete)
    return <img src={CheckmarkIcon} alt="complete-icon" />;
  if (
    status === BULK_ACTION_STATUS.running ||
    status === BULK_ACTION_STATUS.pending
  )
    return (
      <CircularProgressbar
        className={classes.circularProgressbar}
        value={progress}
        strokeWidth={10}
        styles={buildStyles({
          pathColor: theme.palette.common.blue400,
          trailColor: theme.palette.common.gray200,
        })}
      />
    );
  if (status === BULK_ACTION_STATUS.errored) {
    return <img src={ErrorIcon} alt="error-icon" />;
  }
  if (isLoading) {
    return <Loader size="1rem" />;
  }
  return <img src={NoProgressIcon} alt="no-progress-icon" />;
};

ProgressIcon.propTypes = {
  progress: PropTypes.number,
  status: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

export default ProgressIcon;
