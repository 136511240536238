import { TechCheckStageSetting } from 'api-clients/monolith';
import { v4 as uuid } from 'uuid';

export const getDefaultRequirement = (idx: number): TechCheckStageSetting => ({
  id: uuid(),
  title: `Tier ${idx}`,
  is_base_set: false,
});

// This should be created by DEFAULT when TechCheckStage is created but there could be stages without a `baseline` in 1.0
export const DEFAULT_BASELINE_REQUIREMENT: TechCheckStageSetting = {
  id: uuid(),
  title: 'Baseline',
  is_base_set: true,
};
