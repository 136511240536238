import {
  Button,
  Datepicker,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  StyledReactSelect,
} from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { EventData } from 'api-clients/monolith';
import React, { VFC } from 'react';
import { classNames } from 'react-extras';
import {
  FormatDateOptions,
  FormattedDate,
  FormattedMessage,
  useIntl,
} from 'react-intl';

import { Error } from 'components/Error';
import Calendar from 'images/schedule-calendar.svg';

import { EventStageOption, EventUserOption } from '../../hooks';
import { messages } from './messages';

export interface TimeOption {
  label: string;
  value: string;
  hours: string;
}
export interface Option {
  label: string;
  value: string;
}

const useStyles = makeStyles(theme => ({
  rowContainer: {
    marginTop: theme.spacing(2),
  },
  columnContainer: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(2),
    },
  },
  label: {
    marginBottom: theme.spacing(0.5),
  },
  nestedRequired: {
    '& p': {
      '&::after': {
        content: "'*'",
        color: theme.palette.common.red400,
        margin: theme.spacing(0.5),
      },
    },
  },
  required: {
    '&::after': {
      content: "'*'",
      color: theme.palette.common.red400,
      margin: theme.spacing(0.5),
    },
  },
}));
export interface AddEventModalProps {
  handleClose: () => void;
  onDateChange: (selectedDate: Date) => void;
  minDate: Date;
  selectedDate: Date;
  onChange: (params: Partial<EventData>) => void;
  payload: EventData;
  locale: string;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  openingStageOptions: EventStageOption[];
  startTimeOptions: Option[];
  endTimeOptions: Option[];
  // eslint-disable-next-line camelcase
  userOptions: { name: string; external_id: string }[];
  timeZone: string;
  onStartDateChange: (time: TimeOption) => void;
  errors: Partial<Record<keyof EventData, string>>;
  isLoading: boolean;
  isDirty: boolean;
  headerText: string;
}

export const AddEventModal: VFC<AddEventModalProps> = ({
  handleClose,
  minDate,
  onChange,
  payload,
  locale,
  handleSubmit,
  openingStageOptions,
  startTimeOptions,
  endTimeOptions,
  userOptions,
  onDateChange,
  selectedDate,
  timeZone,
  onStartDateChange,
  errors,
  isLoading,
  isDirty,
  headerText,
}) => {
  const styles = useStyles();
  const intl = useIntl();

  const isDisabled = !isDirty || isLoading;

  const localizationOptions: FormatDateOptions = {
    hour: 'numeric',
    minute: 'numeric',
    timeZone,
    timeZoneName: 'short',
  };

  const selectedStage = openingStageOptions.find(
    stage =>
      `${stage.funnel_external_id}:${stage.external_id}` ===
      payload.opening_stage_ids.find(
        id => id === `${stage.funnel_external_id}:${stage.external_id}`,
      ),
  );
  const selectedUser = userOptions.find(
    user => user.external_id === payload.user,
  );
  const selectedStartTime = startTimeOptions.find(
    time => time.value === payload.start_time,
  );
  const selectedEndTime = endTimeOptions.find(
    time => time.value === payload.end_time,
  );

  return (
    <Modal ariaLabelledBy={headerText} open onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalHeader
          ariaLabelledBy={headerText}
          onClose={handleClose}
          Icon={Calendar}
        >
          <Typography>{headerText}</Typography>
        </ModalHeader>
        <ModalContent>
          <div className={styles.rowContainer}>
            <StyledReactSelect
              label={intl.formatMessage(messages.openingStage)}
              aria-label={intl.formatMessage(messages.openingStage)}
              options={openingStageOptions}
              value={selectedStage}
              getOptionLabel={(option: EventStageOption) => option.title}
              getOptionValue={(option: EventStageOption) => option.external_id}
              className={styles.nestedRequired}
              onChange={(option: EventStageOption) =>
                onChange({
                  opening_stage_ids: [
                    `${option.funnel_external_id}:${option.external_id}`,
                  ],
                })
              }
              error={Boolean(errors.opening_stage_ids)}
            />
            {errors.opening_stage_ids && (
              <Error error={errors.opening_stage_ids} />
            )}
          </div>
          <Grid container className={styles.rowContainer} direction="row">
            <Grid item xs={12} sm={6} className={styles.columnContainer}>
              <StyledReactSelect
                label={intl.formatMessage(messages.assignTo)}
                aria-label={intl.formatMessage(messages.assignTo)}
                options={userOptions}
                value={selectedUser}
                getOptionLabel={(option: EventUserOption) => option.name}
                getOptionValue={(option: EventUserOption) => option.external_id}
                className={styles.nestedRequired}
                // eslint-disable-next-line camelcase
                onChange={(option: { name: string; external_id: string }) =>
                  onChange({ user: option.external_id })
                }
                error={Boolean(errors.user)}
              />
              {errors.user && <Error error={errors.user} />}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body2"
                className={classNames(styles.label, styles.required)}
              >
                <FormattedMessage {...messages.date} />
              </Typography>
              <Datepicker
                locale={locale}
                minDate={minDate}
                value={selectedDate}
                renderDate={(selectedDate: Date) => (
                  <FormattedDate dateStyle="short" value={selectedDate} />
                )}
                onDateChange={date => {
                  if (date instanceof Date) {
                    onDateChange(date);
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.rowContainer} direction="row">
            <Grid item xs={12} sm={6} className={styles.columnContainer}>
              <StyledReactSelect
                label={intl.formatMessage(messages.startTime)}
                aria-label={intl.formatMessage(messages.startTime)}
                options={startTimeOptions}
                value={selectedStartTime}
                className={styles.nestedRequired}
                getOptionLabel={(option: TimeOption) =>
                  intl.formatTime(option.value, localizationOptions)
                }
                onChange={(option: TimeOption) => onStartDateChange(option)}
                error={Boolean(errors.start_time)}
              />
              {errors.start_time && <Error error={errors.start_time} />}
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledReactSelect
                label={intl.formatMessage(messages.endTime)}
                aria-label={intl.formatMessage(messages.endTime)}
                options={endTimeOptions}
                value={selectedEndTime}
                className={styles.nestedRequired}
                getOptionLabel={(option: Option) =>
                  intl.formatTime(option.value, localizationOptions)
                }
                onChange={(option: Option) =>
                  onChange({ end_time: option.value })
                }
                error={Boolean(errors.end_time)}
              />
              {errors.end_time && <Error error={errors.end_time} />}
            </Grid>
          </Grid>
          <Grid container className={styles.rowContainer} direction="row">
            <Grid item xs={12} sm={12}>
              <Input
                label={intl.formatMessage(messages.location)}
                value={payload.location}
                autoFocus
                placeholder={intl.formatMessage(messages.addressPlaceholder)}
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                  onChange({ location: event.target.value })
                }
              />
            </Grid>
          </Grid>
          <Grid container className={styles.rowContainer} direction="row">
            <Grid item xs={12} sm={6} className={styles.columnContainer}>
              <Input
                label={intl.formatMessage(messages.eventName)}
                variant="outlined"
                value={payload.title}
                autoFocus
                placeholder={intl.formatMessage(messages.eventPlaceholder)}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                  onChange({ title: event.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                label={intl.formatMessage(messages.maxAttendees)}
                variant="outlined"
                type="number"
                value={payload.max_attendees}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                  onChange({ max_attendees: event.target.value })
                }
              />
            </Grid>
          </Grid>
          <div className={styles.rowContainer}>
            <Typography variant="body2" className={styles.label}>
              <FormattedMessage {...messages.instructions} />
            </Typography>
            <Input
              variant="outlined"
              value={payload.instructions}
              placeholder={intl.formatMessage(messages.instructionsPlaceholder)}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                onChange({ instructions: event.target.value })
              }
            />
          </div>
        </ModalContent>
        <ModalFooter>
          <Button type="secondary" onClick={handleClose} size="small">
            <FormattedMessage {...messages.cancel} />
          </Button>
          <Button
            size="small"
            submit
            isLoading={isLoading}
            disabled={isDisabled}
          >
            <FormattedMessage {...messages.schedule} />
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
