import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setIsOverlayVisibleFalse,
  setIsOverlayVisibleTrue,
} from 'containers/ApplicantTableV2/actions';
import { makeSelectShouldShowOverlay } from 'containers/ApplicantTableV2/selectors';
import {
  TransitionType,
  useGlobalPopupTransitionFunctions,
} from 'hooks/useGlobalPopupTransitionFunctions';

import { QuickActions } from './QuickActions';

export interface QuickActionsContainer {
  applicantId: string;
  variant?: 'icon' | 'text';
}

export const QuickActionsContainer: FC<QuickActionsContainer> = ({
  applicantId,
  variant,
}) => {
  const dispatch = useDispatch();
  const shouldShowOverlay = useSelector(makeSelectShouldShowOverlay());

  const { getTransitionFunction, isLoading } =
    useGlobalPopupTransitionFunctions({
      applicantId,
    });

  useEffect(() => {
    if (isLoading && !shouldShowOverlay) {
      dispatch(setIsOverlayVisibleTrue());
    }

    if (!isLoading && shouldShowOverlay) {
      dispatch(setIsOverlayVisibleFalse());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isLoading]);

  const handleClick = (actionClicked: TransitionType) => async () => {
    const handleTransition = await getTransitionFunction(actionClicked);
    handleTransition();
  };

  return (
    <QuickActions
      handleClickNextStage={handleClick('moveToNextStage')}
      handleClickReject={handleClick('moveToReject')}
      variant={variant}
    />
  );
};
