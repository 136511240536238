import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  selectStage: {
    defaultMessage: 'Select Stage',
    id: `app.containers.WorkflowEditor.components.Rules.Action.BackgroundConsentData.selectStage`,
  },
  package: {
    defaultMessage: 'Package',
    id: `app.containers.WorkflowEditor.components.Rules.Action.BackgroundConsentData.package`,
  },
});
