import { Typography } from '@material-ui/core';
import React, { VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from './messages';
import { useStyles } from './styles';

export interface DefaultDocumentsProps {
  className?: string;
}

export const DefaultDocuments: VFC<DefaultDocumentsProps> = ({ className }) => {
  const styles = useStyles();

  return (
    <div className={className}>
      <Typography
        className={styles.marginBottom2}
        color="textPrimary"
        variant="h4"
      >
        <FormattedMessage {...messages.defaultDocuments} />
      </Typography>
      <Typography color="textPrimary" variant="body2">
        <FormattedMessage {...messages.manageDocuments} />
      </Typography>
    </div>
  );
};
