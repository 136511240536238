import { createSelector } from 'reselect';

import { initialState } from './reducer';

/**
 * Direct selector to the fileRecollectionDrawer state domain
 */

const selectFileRecollectionDrawerDomain = state =>
  state.fileRecollectionDrawer || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by FileRecollectionDrawer
 */

const makeSelectFileRecollectionDrawer = () =>
  createSelector(selectFileRecollectionDrawerDomain, substate => substate);

export default makeSelectFileRecollectionDrawer;
export { selectFileRecollectionDrawerDomain };
