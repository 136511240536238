import produce from 'immer';

import {
  CLOSE_BULK_ACTION_QUEUE_MENU,
  OPEN_BULK_ACTION_QUEUE_MENU,
} from './constants';

export const initialState = {
  isBulkActionQueueMenuOpen: false,
};

const bulkActionQueueReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case OPEN_BULK_ACTION_QUEUE_MENU: {
        draft.isBulkActionQueueMenuOpen = true;
        break;
      }
      case CLOSE_BULK_ACTION_QUEUE_MENU: {
        draft.isBulkActionQueueMenuOpen = false;
        break;
      }
      // no default
    }
  });

export default bulkActionQueueReducer;
