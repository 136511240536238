/**
 *
 * Asynchronously loads the TextToApply component
 *
 */

import { Loader } from '@fountain/fountain-ui-components';

import loadable from 'utils/loadable';

export default loadable(() => import('./index'), {
  LoadingComponent: Loader,
});
