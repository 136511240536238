import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { WorkflowJobMatcherStage } from 'api-clients/monolith';
import React, { VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { AdvancedSettings } from 'containers/WorkflowEditor/components/AdvancedSettings';
import { IdleMoveRule } from 'containers/WorkflowEditor/components/IdleMoveRule';
import { StageSection } from 'containers/WorkflowEditor/components/StageSection';
import { StageSettingCard } from 'containers/WorkflowEditor/components/StageSettingCard';
import { StageSettingMessageCard } from 'containers/WorkflowEditor/components/StageSettingMessageCard';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { AdvancedOptions } from '../AdvancedOptions';
import { JobRouterConditions } from '../JobRouterConditions';
import { messages } from './messages';

const useStyles = makeStyles(theme => ({
  conditionSubHeader: {
    marginBottom: theme.spacing(2),
  },
  description: {
    marginBottom: theme.spacing(3),
    lineHeight: '20px',
  },
}));

export interface JobSelectorStageProps {
  stage: WorkflowJobMatcherStage;
}

export const JobSelectorStage: VFC<JobSelectorStageProps> = ({ stage }) => {
  const styles = useStyles();
  const { setStage } = React.useContext(StageContext);

  return (
    <div data-testid="jobSelectorStage">
      <StageSection>
        <StageSettingCard
          title={<FormattedMessage {...messages.selectOpenings} />}
          variant="default"
        >
          <Typography
            className={styles.description}
            color="textPrimary"
            variant="body2"
          >
            <FormattedMessage {...messages.selectConditionsDescription} />
          </Typography>
          <Typography
            className={styles.conditionSubHeader}
            color="textPrimary"
            variant="h4"
          >
            <FormattedMessage {...messages.selectionConditions} />
          </Typography>
          <JobRouterConditions
            stage={stage}
            conditions={stage.additional_info.conditions.operands ?? []}
          />
          <AdvancedOptions stage={stage} setStage={setStage} />
        </StageSettingCard>
      </StageSection>

      <StageSection>
        <IdleMoveRule stage={stage} />
      </StageSection>

      <StageSection>
        <StageSettingMessageCard
          messageType="job_selector"
          stageExternalId={stage.external_id}
        />
      </StageSection>

      <StageSection>
        <StageSettingMessageCard
          messageType="trigger"
          stageExternalId={stage.external_id}
        />
      </StageSection>
      {stage.account_go_enabled && (
        <StageSection>
          <AdvancedSettings stage={stage} />
        </StageSection>
      )}
    </div>
  );
};
