import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const generateStyles = (theme: Theme) => ({
  td: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
});

const useStyles: () => Record<keyof ReturnType<typeof generateStyles>, string> =
  makeStyles(generateStyles);

export default useStyles;
