import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  applicantCount: {
    id: `app.containers.WorkflowEditor.components.WorkflowNavBar.applicantCount`,
    defaultMessage: '{applicantCount} applicants',
  },
  workflowEditorNavigation: {
    id: `app.containers.WorkflowEditor.components.WorkflowNavBar.workflowEditorNavigation`,
    defaultMessage: 'Workflow Funnel Editor Navigation',
  },
  workflowEditorOpeningSelector: {
    id: `app.containers.WorkflowEditor.components.WorkflowNavBar.workflowEditorOpeningSelector`,
    defaultMessage: 'Opening Selector',
  },
  workflowTitle: {
    id: `app.containers.WorkflowEditor.components.WorkflowNavBar.workflowTitle`,
    defaultMessage: 'Workflow Title',
  },
  newWorkflowName: {
    id: 'app.containers.WorkfloWEditor.components.WorkflowNavBar.newWorkflowName',
    defaultMessage: 'Please enter a new name for this workflow',
  },
  renameWorkflowMenu: {
    id: 'app.containers.WorkfloWEditor.components.WorkflowNavBar.renameWorkflowMenu',
    defaultMessage: 'Rename this workflow',
  },
  renameWorkflow: {
    id: 'app.containers.WorkfloWEditor.components.WorkflowNavBar.renameWorkflow',
    defaultMessage: 'Rename Workflow',
  },
  cancel: {
    id: 'app.containers.WorkfloWEditor.components.WorkflowNavBar.cancel',
    defaultMessage: 'Cancel',
  },
  rename: {
    id: 'app.containers.WorkfloWEditor.components.WorkflowNavBar.rename',
    defaultMessage: 'Rename',
  },
  renameLabel: {
    id: 'app.containers.WorkfloWEditor.components.WorkflowNavBar.renameLabel',
    defaultMessage: 'Name',
  },
  renameSuccess: {
    id: 'app.containers.WorkfloWEditor.components.WorkflowNavBar.renameSuccess',
    defaultMessage: 'Workflow renamed successfully',
  },
  associatedOpenings: {
    id: 'app.containers.WorkfloWEditor.components.WorkflowNavBar.associatedOpenings',
    defaultMessage: '{count} associated openings',
  },
  associatedOpening: {
    id: 'app.containers.WorkfloWEditor.components.WorkflowNavBar.associatedOpening',
    defaultMessage: '{count} associated opening',
  },
  applicants: {
    id: 'app.containers.WorkfloWEditor.components.WorkflowNavBar.applicants',
    defaultMessage: '{count} Applicants',
  },
  applicant: {
    id: 'app.containers.WorkfloWEditor.components.WorkflowNavBar.applicant',
    defaultMessage: '{count} Applicant',
  },
  search: {
    id: 'app.containers.WorkfloWEditor.components.WorkflowNavBar.search',
    defaultMessage: 'Search',
  },
});
