import { FountainLocation } from '@fountain/types/base';
import { Typography } from '@material-ui/core';
import React from 'react';

import SearchableText from 'containers/JobSearch/JobSearchResults/SearchableText';

import useStyles from './styles';

interface JobSearchResultsListSubheaderProps {
  option: FountainLocation;
  searchString: string;
}
const JobSearchResultsListSubheader: React.VFC<
  JobSearchResultsListSubheaderProps
> = ({ option, searchString }) => {
  const styles = useStyles();

  return (
    <>
      <Typography className={styles.listSubheaderTitle} noWrap>
        <SearchableText searchString={searchString} text={option.name} />
      </Typography>
      <Typography className={styles.listSubheaderSubtitle} noWrap>
        <SearchableText searchString={searchString} text={option.address} />
      </Typography>
    </>
  );
};

export default JobSearchResultsListSubheader;
