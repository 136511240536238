import { VonqProduct } from 'api-clients/monolith';

export const HAPI_LOADED = 'app/Sourcing/HAPI_LOADED';
export const PRODUCT_UPDATE = 'app/Sourcing/PRODUCT_UPDATE';
export const VONQ_CAMPAIGN_PURCHASE = 'app/Sourcing/VONQ_CAMPAIGN_PURCHASE';
export const VONQ_CAMPAIGN_SUCCESS = 'app/Sourcing/VONQ_CAMPAIGN_SUCCESS';
export const VONQ_CAMPAIGN_FAIL = 'app/Sourcing/VONQ_CAMPAIGN_FAIL';
export const VONQ_CAMPAIGN_POSTING = 'app/Sourcing/VONQ_CAMPAIGN_POSTING';
export const VONQ_RESET = 'app/Sourcing/VONQ_RESET';
export const VONQ_FUNNEL_CHANGE = 'app/Sourcing/VONQ_FUNNEL_CHANGE';

export const hapiLoaded = () => {
  return {
    type: HAPI_LOADED,
  };
};

export function setVonqFunnel(funnelExternalId: string | null) {
  return {
    type: VONQ_FUNNEL_CHANGE,
    funnelExternalId,
  };
}

export function setVonqProducts(products: Array<VonqProduct>) {
  return {
    type: PRODUCT_UPDATE,
    products,
  };
}

export function setVonqCampaign(campaignId: string | undefined) {
  return {
    type: VONQ_CAMPAIGN_PURCHASE,
    campaignId,
  };
}

export function vonqCampaignSuccess() {
  return {
    type: VONQ_CAMPAIGN_SUCCESS,
  };
}

export function vonqCampaignFail() {
  return {
    type: VONQ_CAMPAIGN_FAIL,
  };
}

export function vonqCampaignPosting() {
  return {
    type: VONQ_CAMPAIGN_POSTING,
  };
}

export function resetVonq() {
  return {
    type: VONQ_RESET,
  };
}
