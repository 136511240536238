import { Input } from '@fountain/fountain-ui-components';
import { Grid, Paper, Popper } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import JobSearchResults from 'containers/JobSearch/JobSearchResults';
import useRefFocus from 'hooks/useRefFocus';
import useRefMeasurements from 'hooks/useRefMeasurements';

import { messages } from './messages';
import useStyles from './styles';

const JobSearchSelectInputArrow = ({ isOpen }) => {
  const styles = useStyles();

  const arrowProps = {
    className: styles.arrow,
    fontSize: 'small',
  };

  return isOpen ? (
    <ArrowDropUp {...arrowProps} />
  ) : (
    <ArrowDropDown {...arrowProps} />
  );
};

JobSearchSelectInputArrow.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

const JobSearchSelect = ({
  focus,
  label,
  onChange,
  onClear,
  onSelected,
  paperProps,
  placeholder,
  value,
  isUrl,
  disabled,
  locationId,
  clearInputOnClick,
  error,
  handleReset,
  isDirty,
  shouldShowActiveFilter,
}) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [orderResultsListBy, setOrderResultsListBy] = useState('');
  const inputRef = useRefFocus(focus || isMenuVisible);
  const intl = useIntl();
  const {
    offsetHeight: inputContainerHeight,
    offsetWidth: inputContainerWidth,
    ref: inputContainerRef,
  } = useRefMeasurements();

  const styles = useStyles({
    ...paperProps,
    inputContainerHeight,
    inputContainerWidth,
  });

  const handleClickAway = () => {
    if (isMenuVisible) {
      handleReset();
      setIsMenuVisible(false);
    }
  };

  const handleChangeInput = event => {
    if (!isMenuVisible) {
      setIsMenuVisible(true);
    }
    onChange(event);
  };

  const handleClickInput = () => {
    if (value && !disabled) {
      setOrderResultsListBy(value);
    }

    if (clearInputOnClick) {
      onClear();
    }

    handleReset();
    setIsMenuVisible(!isMenuVisible);
  };

  const handleClickPaper = () => {
    if (inputRef && inputRef.current && isMenuVisible) {
      inputRef.current.focus();
    }
  };

  const handleSelected = (location, funnel) => () => {
    setIsMenuVisible(false);
    onSelected(location, funnel);
  };

  return (
    <>
      <Grid
        aria-label={intl.formatMessage(messages.jobSearchSelectVariant)}
        className={styles.jobSearchSelectContainer}
        ref={inputContainerRef}
      >
        <Input
          disabled={disabled}
          className={!error ? styles.jobSearchSelectInput : ''}
          endAdornment={
            <JobSearchSelectInputArrow
              isOpen={isMenuVisible}
              onClick={handleClickInput}
            />
          }
          fullWidth
          inputProps={{
            className: styles.input,
          }}
          inputRef={inputRef}
          label={label}
          onChange={handleChangeInput}
          onClick={handleClickInput}
          placeholder={placeholder}
          value={value}
          variant="outlined"
          error={error}
        />
      </Grid>
      <Popper
        anchorEl={inputContainerRef.current}
        className={styles.popper}
        open={isMenuVisible}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper
            className={styles.paper}
            elevation={12}
            onClick={handleClickPaper}
          >
            <JobSearchResults
              onSelected={handleSelected}
              orderResultsListBy={orderResultsListBy}
              searchString={value}
              isUrl={isUrl}
              isDirty={isDirty}
              locationId={locationId}
              shouldShowActiveFilter={shouldShowActiveFilter}
            />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

JobSearchSelect.propTypes = {
  isDirty: PropTypes.bool,
  handleReset: PropTypes.func,
  clearInputOnClick: PropTypes.bool,
  error: PropTypes.string,
  locationId: PropTypes.string,
  disabled: PropTypes.bool,
  focus: PropTypes.bool.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onSelected: PropTypes.func.isRequired,
  paperProps: PropTypes.shape({
    maxHeight: PropTypes.number.isRequired,
    minHeight: PropTypes.number.isRequired,
  }).isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isUrl: PropTypes.bool,
  shouldShowActiveFilter: PropTypes.bool,
};

export default JobSearchSelect;
