import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  alternateDocuments: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Documents.AlternateDocuments.alternateDocuments`,
    defaultMessage: 'Alternate Documents',
  },
  sendAlternateDocuments: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Documents.AlternateDocuments.sendAlternateDocuments`,
    defaultMessage:
      'Send alternate documents to applicants based on conditions',
  },
  alternateDocumentSupportText: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Documents.AlternateDocuments.alternateDocumentSupportText`,
    defaultMessage:
      'Documents here will send in place of the other documents selected above.',
  },
});
