import { Loader } from '@fountain/fountain-ui-components';
import { makeStyles } from '@material-ui/core/styles';
import {
  CancelablePromise,
  ChatbotService,
  ChatbotWidgetSettingResponse,
} from 'api-clients/monolith';
import { ChatbotWidgetBrand } from 'api-clients/monolith/models/ChatbotWidgetBrand';
import React, { useEffect, useState, VFC } from 'react';

import { useApiServiceMutation } from 'hooks/useApiServiceMutation';

import { Settings } from './Settings';
import { SelectedBrand, WidgetUrl } from './types';

const useStyles = makeStyles(theme => ({
  section: {
    position: 'relative',
    overflow: 'hidden',
  },
  content: {
    height: 'calc(100% - 72px)',
    overflowY: 'scroll',
  },
  divider: {
    margin: theme.spacing(4, 0, 1),
  },
}));

interface PublishChatbotWidgetProps {
  brands: ChatbotWidgetBrand[];
  defaultBrandId: string;
  setIsWidgetLoading: React.Dispatch<React.SetStateAction<boolean>>;
  widgetUrl: string;
  setWidgetUrl: React.Dispatch<React.SetStateAction<WidgetUrl>>;
}

export const PublishChatbotWidget: VFC<PublishChatbotWidgetProps> = ({
  brands,
  defaultBrandId,
  setIsWidgetLoading,
  widgetUrl,
  setWidgetUrl,
}) => {
  const classes = useStyles();

  const [selectedBrand, setSelectedBrand] = useState<SelectedBrand>({
    option: brands[0],
    brandId: defaultBrandId,
    isAllBrands: brands[0].key === '*',
  });

  const { mutation: fetchSetting, result: settingsResponse } =
    useApiServiceMutation<
      ChatbotWidgetSettingResponse,
      (
        brandId: string,
        isAllBrands: boolean,
      ) => CancelablePromise<ChatbotWidgetSettingResponse>
      // eslint-disable-next-line @typescript-eslint/unbound-method
    >(ChatbotService.getInternalApiChatbotSettings);

  useEffect(() => {
    fetchSetting(selectedBrand.brandId, selectedBrand.option.key === '*');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBrandChange = (option: ChatbotWidgetBrand) => {
    const isAllBrands = option.key === '*';
    const brandId = isAllBrands ? defaultBrandId : option.key;

    setSelectedBrand({ option, brandId, isAllBrands });
    setIsWidgetLoading(true);
    fetchSetting(brandId, isAllBrands);
  };

  if (settingsResponse.isLoading) return <Loader fullScreen size="2rem" />;

  if (settingsResponse.status !== 'ready') return <></>;

  return (
    <section className={classes.section}>
      <div className={classes.content}>
        <Settings
          brands={brands}
          selectedBrand={selectedBrand}
          settingsResponse={settingsResponse.data}
          handleBrandChange={handleBrandChange}
          widgetUrl={widgetUrl}
          setWidgetUrl={setWidgetUrl}
        />
      </div>
    </section>
  );
};
