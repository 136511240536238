import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { produce } from 'immer';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { getConditionErrorMessage } from 'containers/WorkflowEditor/components/Rules/Condition/constants';

import { DocumentSigningRuleProps } from '../../DocumentSigningRules/types';
import { RulesProps, SharedConditionProps } from '../../types';
import { messages } from './messages';

export const SterlingServiceLevelResults: FC<SharedConditionProps> = ({
  condition,
  ruleId,
  setRules,
  setDocumentSigningRules,
  errors,
}) => {
  const intl = useIntl();

  const { extra } = condition;

  const selectOptions = [
    {
      value: 'unperformable',
      label: intl.formatMessage(messages.unperformable),
    },
    { value: 'consider', label: intl.formatMessage(messages.consider) },
  ];

  const selectedOption =
    selectOptions?.find(option => option.value === extra.sterling_result) ??
    null;

  const onChangeSterlingResult = (newSterlingResult: {
    label: string;
    value: string;
  }) => {
    const rulesSetter = setRules ?? setDocumentSigningRules;

    if (rulesSetter) {
      rulesSetter(
        produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];
          const idx = draftConditions.findIndex(
            cond => cond.id === condition.id,
          );

          draftConditions[idx].extra.sterling_result = newSterlingResult.value;
        }),
      );
    }
  };

  const sterlingResultErrorMessage =
    !extra?.sterling_result &&
    getConditionErrorMessage('sterling_result', errors);

  return (
    <Grid item xs={6}>
      <StyledReactSelect
        options={selectOptions}
        label={intl.formatMessage(messages.sterlingResult)}
        aria-label={intl.formatMessage(messages.sterlingResult)}
        onChange={onChangeSterlingResult}
        value={selectedOption}
        error={sterlingResultErrorMessage}
        placeholder=""
        required
      />
    </Grid>
  );
};
