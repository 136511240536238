import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addEvent: {
    id: 'app.Calendar.AddEventModal.addEvent',
    defaultMessage: 'Add event',
  },
  assignTo: {
    id: 'app.Calendar.AddEventModal.assignTo',
    defaultMessage: 'Assign to',
  },
  date: {
    id: 'app.Calendar.AddEventModal.date',
    defaultMessage: 'Date',
  },
  cancel: {
    id: 'app.Calendar.AddEventModal.cancel',
    defaultMessage: 'Cancel',
  },
  schedule: {
    id: 'app.Calendar.AddEventModal.schedule',
    defaultMessage: 'Schedule',
  },
  instructionsPlaceholder: {
    id: 'app.Calendar.AddEventModal.instructionsPlaceholder',
    defaultMessage: 'e.g. Please bring your ID',
  },
  instructions: {
    id: 'app.Calendar.AddEventModal.instructions',
    defaultMessage: 'Instructions',
  },
  maxAttendees: {
    id: 'app.Calendar.AddEventModal.maxAttendees',
    defaultMessage: 'Max Attendees',
  },
  eventPlaceholder: {
    id: 'app.Calendar.AddEventModal.eventPlaceholder',
    defaultMessage: 'Name applicants see',
  },
  eventName: {
    id: 'app.Calendar.AddEventModal.eventName',
    defaultMessage: 'Event name',
  },
  addressPlaceholder: {
    id: 'app.Calendar.AddEventModal.addressPlaceholder',
    defaultMessage: 'e.g. 777 Apple Street, SF',
  },
  location: {
    id: 'app.Calendar.AddEventModal.location',
    defaultMessage: 'Location',
  },
  endTime: {
    id: 'app.Calendar.AddEventModal.endTime',
    defaultMessage: 'End time',
  },
  startTime: {
    id: 'app.Calendar.AddEventModal.startTime',
    defaultMessage: 'Start time',
  },
  openingStage: {
    id: 'app.Calendar.AddEventModal.openingStage',
    defaultMessage: 'Opening stage',
  },
});
