import {
  SimpleModal,
  SimpleModalHeader,
  SimpleModalTitle,
} from '@fountain/fountain-ui-components';
import React, { memo, VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { EditPartnerOptionDataModalContent } from './components/EditPartnerOptionDataModalContent';
import { messages } from './messages';

export interface EditPartnerOptionDataModalProps {
  applicantId: string;
  handleClose: () => void;
  partnerStageId: string;
  partnerId: string;
  partnerOptionId: string;
  partnerStatusId: string;
}

export const EditPartnerOptionDataModal: VFC<
  EditPartnerOptionDataModalProps
> = ({
  applicantId,
  handleClose,
  partnerId,
  partnerStageId,
  partnerOptionId,
  partnerStatusId,
}) => {
  return (
    <SimpleModal onClose={handleClose} maxWidth={672} blurOverlay={false}>
      <SimpleModalHeader onClose={handleClose}>
        <SimpleModalTitle>
          <FormattedMessage {...messages.modalHeader} />
        </SimpleModalTitle>
      </SimpleModalHeader>
      <EditPartnerOptionDataModalContent
        applicantId={applicantId}
        handleClose={handleClose}
        stageId={partnerStageId}
        partnerId={partnerId}
        partnerOptionId={partnerOptionId}
        partnerStatusId={partnerStatusId}
      />
    </SimpleModal>
  );
};

export default memo(EditPartnerOptionDataModal);
