import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import {
  generateFormStatusMessages,
  getConditionErrorMessage,
  W4_FEDERAL_FORM_STATUS,
} from 'containers/WorkflowEditor/components/Rules/Condition/constants';
import { messages as conditionMessages } from 'containers/WorkflowEditor/components/Rules/Condition/messages';
import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';

import { DocumentSigningRuleProps } from '../../DocumentSigningRules/types';
import { RulesProps, SharedConditionProps } from '../../types';

export const W4FederalFormStatus: FC<SharedConditionProps> = ({
  condition,
  ruleId,
  setRules,
  setDocumentSigningRules,
  errors,
}) => {
  const intl = useIntl();

  const { extra } = condition;

  const { condition_statuses: conditionStatuses } =
    useContext(RulesEditDataContext);

  const messageByStatus = generateFormStatusMessages(
    intl,
    W4_FEDERAL_FORM_STATUS,
  );

  const statusOptions = conditionStatuses?.w4_federal_form?.map(status => ({
    label: messageByStatus[status],
    value: status,
  }));
  const selectedStatus =
    statusOptions?.find(option => option.value === extra.status) ?? null;

  const onChangeStatus = (newStatus: { label: string; value: string }) => {
    const rulesSetter = setRules ?? setDocumentSigningRules;

    if (rulesSetter) {
      rulesSetter(
        produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];
          const idx = draftConditions.findIndex(
            cond => cond.id === condition.id,
          );

          draftConditions[idx].extra.status = newStatus.value;
        }),
      );
    }
  };

  const statusErrorMessage =
    !extra?.status && getConditionErrorMessage('status', errors);

  return (
    <Grid item xs={6}>
      <StyledReactSelect
        options={statusOptions}
        label={intl.formatMessage(conditionMessages.status)}
        aria-label={intl.formatMessage(conditionMessages.status)}
        onChange={onChangeStatus}
        value={selectedStatus}
        error={statusErrorMessage}
        placeholder=""
        required
      />
    </Grid>
  );
};
