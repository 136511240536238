import { Input } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import { WorkflowStageDetail } from 'api-clients/monolith';
import cx from 'classnames';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Error } from 'components/Error';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import {
  DOWNLOAD_SPEED,
  RequirementBasicInputField,
  UPLOAD_SPEED,
} from './constants';
import { messages } from './messages';
import { useStyles } from './styles';

export interface InternetSpeedInputsProps {
  uploadSpeed?: string;
  downloadSpeed?: string;
  settingId: number | string;
}

export const InternetSpeedInputs: FC<InternetSpeedInputsProps> = React.memo(
  ({ uploadSpeed, downloadSpeed, settingId }) => {
    const intl = useIntl();
    const styles = useStyles();

    const { updateStageResult, setStage } = useContext(StageContext);
    const errors =
      (updateStageResult?.isError && updateStageResult.error?.errors) ||
      undefined;

    const onChangeInput =
      (key: RequirementBasicInputField) =>
      (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = event.target;
        setStage(
          produce((draftStage: WorkflowStageDetail) => {
            if (
              draftStage.type === 'TechCheckStage' &&
              draftStage.additional_info?.settings
            ) {
              const draftSetting = draftStage.additional_info.settings.find(
                setting => setting.id === settingId,
              );
              if (draftSetting) {
                draftSetting[key] = value;
              }
            }
          }),
        );
      };

    return (
      <>
        <Grid>
          <Typography variant="body2" className={styles.heading}>
            <FormattedMessage {...messages.internetHeading} />
          </Typography>
        </Grid>
        <Grid className={styles.internetContainer}>
          <Grid
            className={cx(styles.internetInput, {
              [styles.inputErrorPresent]:
                (!uploadSpeed && errors?.['settings.upload_speed']) ||
                (!downloadSpeed && errors?.['settings.download_speed']),
            })}
          >
            <Input
              aria-label={intl.formatMessage(messages.uploadSpeed)}
              label={intl.formatMessage(messages.uploadSpeed)}
              value={uploadSpeed}
              onChange={onChangeInput(UPLOAD_SPEED)}
              error={Boolean(!uploadSpeed && errors?.['settings.upload_speed'])}
              required
            />
            <Error
              error={
                !uploadSpeed &&
                errors?.['settings.upload_speed'] &&
                intl.formatMessage(messages.addUploadSpeed)
              }
              align="right"
            />
          </Grid>
          <Grid
            className={cx(styles.internetInput, {
              [styles.inputErrorPresent]:
                (!uploadSpeed && errors?.['settings.upload_speed']) ||
                (!downloadSpeed && errors?.['settings.download_speed']),
            })}
          >
            <Input
              aria-label={intl.formatMessage(messages.downloadSpeed)}
              label={intl.formatMessage(messages.downloadSpeed)}
              value={downloadSpeed}
              onChange={onChangeInput(DOWNLOAD_SPEED)}
              error={Boolean(
                !downloadSpeed && errors?.['settings.download_speed'],
              )}
              required
            />
            <Error
              error={
                !downloadSpeed &&
                errors?.['settings.download_speed'] &&
                intl.formatMessage(messages.addDownloadSpeed)
              }
              align="right"
            />
          </Grid>
        </Grid>
      </>
    );
  },
);
