import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  partnerDetailContainer: {
    marginBottom: theme.spacing(4),
  },
  partnerOption: {
    marginBottom: theme.spacing(0.75),
    fontFamily: theme.typography.h5.fontFamily,
  },
  partnerDetails: {
    marginLeft: theme.spacing(0.5),
  },
  partnerDetail: {
    marginBottom: theme.spacing(0.5),
    whiteSpace: 'pre-line',
  },
  detailLabel: {
    color: theme.palette.common.gray800,
    fontFamily: theme.typography.h4.fontFamily,
  },
  detailValue: {
    color: theme.palette.common.gray800,
  },
}));

export default useStyles;
