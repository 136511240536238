import { Loader, StyledReactSelect } from '@fountain/fountain-ui-components';
import { Reason, WorkflowStageDetail } from 'api-clients/monolith';
import { produce } from 'immer';
import React, { useContext, VFC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addMessageAction } from 'containers/FlashMessage/actions';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { useArchivedReasons } from '../../../hooks/useArchivedReasons';
import { useRejectionReasons } from '../../../hooks/useRejectionReasons';
import { messages } from '../messages';
import { useStyles } from '../styles';

// Assumes REASON_STAGES.includes(targetStage.type)
export const ReasonSelect: VFC<{ stage: WorkflowStageDetail }> = ({
  stage,
}) => {
  const intl = useIntl();
  const styles = useStyles();
  const dispatch = useDispatch();
  const { move_rule: moveRule } = stage;
  const { setStage, funnelStages } = useContext(StageContext);
  const { result: archivedReasonsResult } = useArchivedReasons();
  const { result: rejectedReasonResult } = useRejectionReasons();

  const targetStage = funnelStages.find(
    stage => stage.id === moveRule?.target_stage_id,
  );

  const isLoading =
    archivedReasonsResult.status === 'loading' ||
    rejectedReasonResult.status === 'loading';

  const isError =
    archivedReasonsResult.status === 'error' ||
    rejectedReasonResult.status === 'error';

  React.useEffect(() => {
    if (isError) {
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.stageReasonLoadingError),
          'success',
          true,
        ),
      );
    }
  }, [dispatch, intl, isError]);

  if (isLoading) {
    return <Loader size="2rem" />;
  }

  if (isError) {
    return null;
  }

  // Generating options based on type of stage and request response
  const reasonSelectOptions =
    targetStage?.type === 'ArchivedStage'
      ? archivedReasonsResult.data.archived_reasons
      : rejectedReasonResult.data.rejection_reasons;

  const onChangeReason = (reason: Reason) => {
    setStage(
      produce((draftStage: WorkflowStageDetail) => {
        if (targetStage && draftStage.move_rule) {
          if (targetStage.type === 'ArchivedStage') {
            draftStage.move_rule.archived_reason_id = reason.id;
            draftStage.move_rule.rejection_reason_id = null;
          }
          if (targetStage.type === 'RejectedStage') {
            draftStage.move_rule.rejection_reason_id = reason.id;
            draftStage.move_rule.archived_reason_id = null;
          }
        }
      }),
    );
  };

  return (
    <StyledReactSelect
      className={styles.select}
      aria-label={intl.formatMessage(messages.reason)}
      label={intl.formatMessage(messages.reason)}
      options={reasonSelectOptions}
      value={
        reasonSelectOptions?.find(option => {
          return targetStage?.type === 'ArchivedStage'
            ? option.id === moveRule?.archived_reason_id
            : option.id === moveRule?.rejection_reason_id;
        }) ?? null
      }
      getOptionLabel={(option: Reason) => {
        return option.content;
      }}
      getOptionValue={(option: Reason) => {
        return option.id;
      }}
      onChange={onChangeReason}
    />
  );
};
