import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React, { FC } from 'react';

export const MultiStageIcon: FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 4C2 4.55228 2.44772 5 3 5L13 5C13.5523 5 14 4.55228 14 4V2C14 1.44772 13.5523 1 13 1H3C2.44772 1 2 1.44772 2 2V4ZM4 4C4.55229 4 5 3.55228 5 3C5 2.44772 4.55229 2 4 2C3.44772 2 3 2.44772 3 3C3 3.55228 3.44772 4 4 4Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 9C2 9.55228 2.44772 10 3 10H13C13.5523 10 14 9.55228 14 9V7C14 6.44772 13.5523 6 13 6H3C2.44772 6 2 6.44772 2 7V9ZM4 9C4.55229 9 5 8.55228 5 8C5 7.44772 4.55229 7 4 7C3.44772 7 3 7.44772 3 8C3 8.55228 3.44772 9 4 9Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 14C2 14.5523 2.44772 15 3 15H13C13.5523 15 14 14.5523 14 14V12C14 11.4477 13.5523 11 13 11H3C2.44772 11 2 11.4477 2 12V14ZM4 14C4.55229 14 5 13.5523 5 13C5 12.4477 4.55229 12 4 12C3.44772 12 3 12.4477 3 13C3 13.5523 3.44772 14 4 14Z"
    />
  </SvgIcon>
);
