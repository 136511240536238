import { IconButton as StyledIconButton } from '@fountain/fountain-ui-components';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';

import DesktopPreviewSvg from 'images/desktop-preview';
import MobilePreviewSvg from 'images/mobile-preview';

import useStyles from './styles';

const FormFactorChooser = ({ typeSelected, onClientSelected, className }) => {
  const classes = useStyles();
  return (
    <Grid className={className} container justify="center">
      <StyledIconButton
        className={classes.mobilePreviewButton}
        isSelected={typeSelected === 'mobile'}
        onClick={() => onClientSelected('mobile')}
      >
        <MobilePreviewSvg />
      </StyledIconButton>
      <StyledIconButton
        isSelected={typeSelected === 'desktop'}
        onClick={() => onClientSelected('desktop')}
      >
        <DesktopPreviewSvg />
      </StyledIconButton>
    </Grid>
  );
};

FormFactorChooser.propTypes = {
  className: PropTypes.string,
  onClientSelected: PropTypes.func.isRequired,
  typeSelected: PropTypes.string.isRequired,
};

export default FormFactorChooser;
