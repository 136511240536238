/**
 *
 * GlobalPopup
 *
 */

import queryString from 'query-string';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import ApplicantDrawerPopup from 'containers/ApplicantDrawerPopup/Loadable';
import ApplicantsActionsImportModal from 'containers/ApplicantsActionsImportModal/Loadable';
import ApplicantsFilter from 'containers/ApplicantsFilter/Loadable';
import ApplicantsImportModal from 'containers/ApplicantsImportModal/Loadable';
import { removeApplicantFromStageOnCloseAction } from 'containers/ApplicantTableV2/actions';
import ApplicantUploadFileModal from 'containers/ApplicantUploadFileModal/Loadable';
import { AssessmentFormModal } from 'containers/AssessmentFormModal';
import { makeSelectWhoami } from 'containers/Auth_old/selectors';
import CustomizableColumnsDrawer from 'containers/CustomizableColumnsDrawer/Loadable';
import { DataValidationModalAsync } from 'containers/DataValidationModal';
import DeleteApplicantModalWrapper from 'containers/DeleteApplicantModal/Loadable';
import EditPartnerOptionDataModal from 'containers/EditPartnerOptionData';
import FileRecollectionDrawer from 'containers/FileRecollectionDrawer/Loadable';
import ImmediateHiringDecisionModal from 'containers/ImmediateHiringDecisionModal';
import JobsStagesFilterPopup from 'containers/JobsStagesFilterPopup/Loadable';
import { MoveApplicantDialogContainer } from 'containers/MoveApplicantDialog';
import MoveApplicantModalWrapper from 'containers/MoveApplicantPopup/Loadable';
import ScheduleApplicantCancelModal from 'containers/ScheduleApplicantCancelModal/Loadable';
import ScheduleApplicantModal from 'containers/ScheduleApplicantModal/Loadable';
import BulkSendEmailPopup from 'containers/SendEmailPopup/components/BulkSendEmail/Loadable';
import SendEmailPopup from 'containers/SendEmailPopup/Loadable';
import BulkSendSmsPopup from 'containers/SendSmsPopup/BulkSendSmsPopup';
import SendSmsPopup from 'containers/SendSmsPopup/Loadable';
import UnsubscribeModal from 'containers/UnsubscribeModal/Loadable';
import VideoRecordingPopover from 'containers/VideoRecordingPopover/Loadable';
import WxExportApplicantModal from 'containers/WxExportApplicantModal';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import { closePersistedPopup, closePopup } from './actions';
import { POPUP_TYPES } from './constants';
import reducer from './reducer';
import saga from './saga';
import { makeSelectPrevQueryParams } from './selectors';

export function GlobalPopup() {
  const accountHasWorkflows = useSelector(
    makeSelectWhoami(),
  ).account_has_workflows;
  useInjectReducer({ key: 'globalPopup', reducer });
  useInjectSaga({ key: 'globalPopup', saga });

  const dispatch = useDispatch();
  const location = useLocation();
  const prevQueryParams = useSelector(makeSelectPrevQueryParams());

  const [isPopupOpen, setIsPopupOpen] = useState(true);
  const [isPersistedPopupOpen, setIsPersistedPopupOpen] = useState(true);

  const [selectedPopup, setSelectedPopup] = useState('');
  const [selectedPersistedPopup, setSelectedPersistedPopup] = useState('');

  const [isFromStageId, setIsFromStageId] = useState(false);

  const {
    applicantId,
    assessmentFormId,
    statusLabelTitle,
    fromStageId,
    toStageId,
    selectedStageId,
    schedulerStageId,
    schedulerStageType,
    attended,
    partnerId,
    partnerOptionId,
    partnerStageId,
    partnerStatusId,
  } = queryString.parse(location.search);

  useEffect(() => {
    const query = queryString.parse(location.search);

    if (query.persistedPopupType) {
      setSelectedPersistedPopup(query.persistedPopupType);
      setIsPersistedPopupOpen(true);
    } else {
      setSelectedPersistedPopup('');
    }

    if (query.popupType) {
      setSelectedPopup(query.popupType);
      setIsPopupOpen(true);
    } else {
      setSelectedPopup('');
    }
  }, [location]);

  const handleClose = () => {
    setIsPopupOpen(false);

    if (isPersistedPopupOpen && (fromStageId || selectedStageId))
      setIsFromStageId(true);
    dispatch(closePopup(prevQueryParams));
  };

  const handleClosePersistedPopup = () => {
    setIsPersistedPopupOpen(false);
    dispatch(closePersistedPopup(prevQueryParams));
    if (isFromStageId && fromStageId === toStageId)
      dispatch(removeApplicantFromStageOnCloseAction([applicantId]));
    setIsFromStageId(false);
  };

  const triggerAppcuesPageDetection = () => {
    if (window.Appcues) {
      window.Appcues.page();
    }
  };

  const renderPersistedPopup = () => {
    triggerAppcuesPageDetection();
    switch (selectedPersistedPopup) {
      case POPUP_TYPES.APPLICANT_DRAWER_POPUP:
        return (
          <ApplicantDrawerPopup
            open={isPersistedPopupOpen}
            onClose={handleClosePersistedPopup}
          />
        );
      default:
        return null;
    }
  };

  const renderPopup = () => {
    triggerAppcuesPageDetection();

    switch (selectedPopup) {
      case POPUP_TYPES.SCHEDULE_APPLICANT:
        return (
          <ScheduleApplicantModal
            applicantId={applicantId}
            schedulerStageId={schedulerStageId}
            schedulerStageType={schedulerStageType}
            handleClose={handleClose}
          />
        );
      case POPUP_TYPES.CANCEL_SCHEDULE_APPLICANT:
        return <ScheduleApplicantCancelModal handleClose={handleClose} />;
      case POPUP_TYPES.APPLICANT_BULK_UPLOAD_POPUP:
        return <ApplicantsImportModal handleClose={handleClose} />;
      case POPUP_TYPES.BULK_ACTIONS_UPLOAD:
        return (
          <ApplicantsActionsImportModal
            accountHasWorkflows={accountHasWorkflows}
            handleClose={handleClose}
          />
        );
      case POPUP_TYPES.DELETE_APPLICANT_MODAL:
        return (
          <DeleteApplicantModalWrapper
            handleClose={handleClose}
            location={location}
          />
        );
      case POPUP_TYPES.SEND_EMAIL_POPUP:
        return (
          <SendEmailPopup
            open={isPopupOpen}
            handleClose={handleClose}
            location={location}
          />
        );
      case POPUP_TYPES.SEND_BULK_EMAIL_POPUP:
        return (
          <BulkSendEmailPopup
            open={isPopupOpen}
            handleClose={handleClose}
            location={location}
          />
        );
      case POPUP_TYPES.SEND_SMS_POPUP:
        return <SendSmsPopup open={isPopupOpen} handleClose={handleClose} />;
      case POPUP_TYPES.SEND_BULK_SMS_POPUP:
        return (
          <BulkSendSmsPopup
            open={isPopupOpen}
            handleClose={handleClose}
            location={location}
          />
        );
      case POPUP_TYPES.UNSUBSCRIBE_MODAL:
        return <UnsubscribeModal handleClose={handleClose} />;
      case POPUP_TYPES.UPLOAD_FILE_MODAL:
        return (
          <ApplicantUploadFileModal
            handleClose={handleClose}
            location={location}
          />
        );
      case POPUP_TYPES.MOVE_APPLICANT_POPUP:
        return (
          <MoveApplicantModalWrapper
            open={isPopupOpen}
            handleClose={handleClose}
          />
        );
      case POPUP_TYPES.APPLICANTS_FILTER:
        return (
          <ApplicantsFilter open={isPopupOpen} handleClose={handleClose} />
        );
      case POPUP_TYPES.JOBS_STAGES_FILTER_POPUP:
        return (
          <JobsStagesFilterPopup open={isPopupOpen} handleClose={handleClose} />
        );
      case POPUP_TYPES.MOVE_APPLICANT_TO_JOB_POPUP:
        return (
          <MoveApplicantDialogContainer
            dialogType="moveApplicantToJob"
            onClose={handleClose}
          />
        );
      case POPUP_TYPES.REJECT_APPLICANT_MODAL:
        return (
          <MoveApplicantDialogContainer
            dialogType="moveApplicantToReject"
            onClose={handleClose}
          />
        );
      case POPUP_TYPES.HIRE_APPLICANT_MODAL:
        return (
          <MoveApplicantDialogContainer
            dialogType="moveApplicantToHired"
            onClose={handleClose}
          />
        );
      case POPUP_TYPES.ON_HOLD_APPLICANT_MODAL:
        return (
          <MoveApplicantDialogContainer
            dialogType="moveApplicantToOnHold"
            onClose={handleClose}
          />
        );
      case POPUP_TYPES.DATA_VALIDATION_MODAL:
        return <DataValidationModalAsync handleClose={handleClose} />;
      case POPUP_TYPES.FILE_RECOLLECTION:
        return (
          <FileRecollectionDrawer
            open={isPopupOpen}
            handleClose={handleClose}
          />
        );
      case POPUP_TYPES.CUSTOMIZABLE_COLUMNS_DRAWER:
        return (
          <CustomizableColumnsDrawer
            open={isPopupOpen}
            handleClose={handleClose}
          />
        );
      case POPUP_TYPES.VIDEO_RECORDING_POPOVER:
        return (
          <VideoRecordingPopover
            open={isPopupOpen}
            handleClose={handleClose}
            applicantId={applicantId}
            statusLabelTitle={statusLabelTitle}
            candidateNavigationEnabled
          />
        );
      case POPUP_TYPES.REVIEW_ASSESSMENT_MODAL:
        return (
          <AssessmentFormModal
            handleClose={handleClose}
            applicantId={applicantId}
            assessmentFormId={assessmentFormId}
          />
        );
      case POPUP_TYPES.WX_EXPORT_APPLICANT_MODAL:
        return <WxExportApplicantModal handleClose={handleClose} />;
      case POPUP_TYPES.IMMEDIATE_HIRING_DECISION:
        return (
          <ImmediateHiringDecisionModal
            applicantId={applicantId}
            schedulerStageId={schedulerStageId}
            schedulerStageType={schedulerStageType}
            attended={attended}
            handleClose={handleClose}
          />
        );
      case POPUP_TYPES.EDIT_PARTNER_OPTION_DATA_MODAL:
        return (
          <EditPartnerOptionDataModal
            applicantId={applicantId}
            handleClose={handleClose}
            partnerStageId={partnerStageId}
            partnerId={partnerId}
            partnerOptionId={partnerOptionId}
            partnerStatusId={partnerStatusId}
          />
        );
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      {renderPersistedPopup()}
      {renderPopup()}
    </React.Fragment>
  );
}

export default memo(GlobalPopup);
