import { Button } from '@fountain/fountain-ui-components';
import {
  Checkbox,
  TableCell,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import {
  attendButtons,
  RosterApplicant,
  rowActions,
} from 'Calendar/EventManager/ManageRoster/util';
import { messages } from 'Calendar/EventManager/messages';
import cx from 'classnames';
import React, { VFC } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { applicantValueRenderer } from 'containers/ApplicantsV2/constants';
import { QuickActions } from 'containers/QuickActions';
import useUserPermissions from 'hooks/useUserPermissions';

import { useStyles } from './styles';

export type CustomRenderFn = ({
  applicant,
  rowIdx,
  perform,
  styles,
}: {
  applicant: RosterApplicant;
  rowIdx: number;
  perform: (action: rowActions, applicant: RosterApplicant) => void;
  styles: ClassNameMap;
}) => JSX.Element;

interface CustomRenders {
  [key: string]: CustomRenderFn;
}

const CUSTOM_RENDERS: CustomRenders = {
  name: ({ applicant, perform, styles }) => (
    <Button
      type="text"
      disableUnderline
      autoWidth
      onClick={() => perform('open', applicant)}
      className={styles.disableButtonUnderline}
    >
      <Typography>{applicant.name}</Typography>
    </Button>
  ),
  attended: ({ applicant, perform, styles }) => (
    <ButtonGroup>
      {attendButtons.map(value => (
        <Button
          variant="outlined"
          size="small"
          key={value}
          type={applicant.attended === value ? 'primary' : 'secondary'}
          onClick={() =>
            perform(value === 'Yes' ? 'attended' : 'absent', applicant)
          }
          className={styles.attendButton}
        >
          <FormattedMessage {...messages[value]} />
        </Button>
      ))}
    </ButtonGroup>
  ),
  opening: ({ applicant }) => (
    <Typography>{applicant.funnel?.title}</Typography>
  ),
  stage: ({ applicant }) => (
    <Typography>{applicant.stage ? applicant.stage.name : ''}</Typography>
  ),
};

const renderFnForKey = (key: string, keyType?: string) => {
  if (CUSTOM_RENDERS[key]) {
    return CUSTOM_RENDERS[key];
  }

  if (keyType === 'date') {
    return ({ applicant }: { applicant: RosterApplicant }) => (
      <Typography>
        {applicant[key as keyof RosterApplicant] ? (
          <FormattedDate
            dateStyle="short"
            value={new Date(applicant[key as keyof RosterApplicant] as string)}
          />
        ) : null}
      </Typography>
    );
  }

  return ({ applicant }: { applicant: RosterApplicant }) => (
    <Typography>
      {
        // @ts-expect-error todo
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        applicantValueRenderer(keyType, applicant[key])
      }
    </Typography>
  );
};

export interface RowProps {
  applicant: RosterApplicant;
  check: (applicant: RosterApplicant) => void;
  perform: (action: rowActions, applicant: RosterApplicant) => void;
  columns: { key: string; title: string; keyType: string }[];
}

export const Row: VFC<RowProps> = ({ applicant, check, perform, columns }) => {
  const styles = useStyles();
  const isMega = useMediaQuery<Theme>(theme => theme.breakpoints.up('xl'));
  const { manageApplicantsPermission: canManageApplicants } =
    useUserPermissions();

  return (
    <TableRow className={styles.rosterRow}>
      {/* First cell is always the checkbox and actions cell */}
      <TableCell className={styles.actionsColumn}>
        <Checkbox
          checked={applicant.checked}
          onClick={() => check(applicant)}
        />
        {canManageApplicants && (
          <QuickActions
            handleClickNextStage={() => perform('advance', applicant)}
            handleClickReject={() => perform('reject', applicant)}
            variant="icon"
          />
        )}
      </TableCell>
      {/* Other cells */}
      {columns.map((column, idx) => (
        <TableCell
          key={column.key}
          className={cx({
            [styles.largeColumnCutoff]: isMega,
            [styles.mediumColumnCutoff]: !isMega,
          })}
        >
          {renderFnForKey(
            column.key,
            column.keyType,
          )({ applicant, rowIdx: idx, perform, styles })}
        </TableCell>
      ))}
    </TableRow>
  );
};
