import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  automatedResponses: {
    id: 'app.containers.Chatbot.Automatedreponses.automatedResponses',
    defaultMessage: `Automated Responses`,
  },
  bulkUploadCsvFile: {
    id: 'app.containers.Chatbot.Automatedreponses.bulkUploadCsvFile',
    defaultMessage: 'Bulk import via CSV',
  },
  changeMode: {
    id: 'app.containers.Chatbot.Automatedreponses.changeMode',
    defaultMessage: 'Change mode',
  },
});
