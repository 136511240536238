/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PartnerOptionDataField } from '../models/PartnerOptionDataField';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PartnersService {

    /**
     * Show Partner Option Data
     * Returns a single PartnerOptionData for an applicant and stage
     * @param applicantId Applicant external ID
     * @param stageId Stage external ID
     * @param partnerOptionId Partner option ID
     * @param partnerStatusId PartnerStatus external ID
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiApplicantsPartnerOptionData(
        applicantId: string,
        stageId: string,
        partnerOptionId: string,
        partnerStatusId?: string,
    ): CancelablePromise<{
        stage_name?: string;
        stage_id: string;
        partner_option_data_id: string;
        data_fields: Array<PartnerOptionDataField>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/applicants/{applicant_id}/partner_option_data/{stage_id}',
            path: {
                'applicant_id': applicantId,
                'stage_id': stageId,
            },
            query: {
                'partner_option_id': partnerOptionId,
                'partner_status_id': partnerStatusId,
            },
            errors: {
                403: `Forbidden`,
            },
        });
    }

}