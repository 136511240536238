import { Tooltip as ConditionalTooltip } from '@fountain/fountain-ui-components';
import { List, ListItem, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { useSelector } from 'react-redux';

import { PHONE_PLATFORM_TYPES } from 'components/SmsMessage/constants';
import { makeSelectExternalId } from 'containers/Auth_old/selectors';
import SmsIcon from 'images/sms-blue-icon.svg';
import WhatsappIcon from 'images/whatsapp-logo.svg';

import { MessageTemplatesVariant } from './constants';
import makeSelectTemplateMenuState from './selectors';
import useStyles from './styles';
import {
  sortTemplatesAlphabetically,
  updateRecentlyUsedTemplateIds,
} from './utils';

const TemplatesList = ({ onSelected, searchString, templates, variant }) => {
  const styles = useStyles();
  const { messageTemplates: templatesRaw = [], whatsAppTemplates = [] } =
    useSelector(makeSelectTemplateMenuState());
  const userId = useSelector(makeSelectExternalId());

  // The instance of `templates` coming from  `TemplateMenuContent` is out of
  // Redux and frozen by `immer`, a copy is required to avoid mutation
  const sortedTemplates = [...templates].sort(sortTemplatesAlphabetically);

  const handleClickListItem = template => () => {
    updateRecentlyUsedTemplateIds(
      template,
      variant === MessageTemplatesVariant.WHATSAPP
        ? whatsAppTemplates
        : templatesRaw,
      userId,
      variant,
    );
    onSelected(template);
  };

  return (
    <List disablePadding>
      {sortedTemplates.map(template => (
        <ListItem
          button
          className={styles.listItem}
          key={`${
            template.sms_enabled
              ? MessageTemplatesVariant.SMS
              : MessageTemplatesVariant.WHATSAPP
          }-${template.id}`}
          onClick={handleClickListItem(template)}
        >
          <ConditionalTooltip
            dense
            enterDelay={500}
            text={template.name}
            title={template.name}
          >
            <Typography noWrap variant="caption">
              {[
                MessageTemplatesVariant.SMS,
                MessageTemplatesVariant.WHATSAPP,
              ].includes(variant) &&
                (template.sms_enabled ? (
                  <img
                    src={SmsIcon}
                    alt={PHONE_PLATFORM_TYPES.sms}
                    className={styles.templateIcon}
                  />
                ) : (
                  <img
                    src={WhatsappIcon}
                    alt={PHONE_PLATFORM_TYPES.whats_app}
                    className={styles.templateIcon}
                  />
                ))}
              <Highlighter
                autoEscape
                highlightClassName={styles.highlight}
                searchWords={[searchString]}
                textToHighlight={template.name}
              />
            </Typography>
          </ConditionalTooltip>
        </ListItem>
      ))}
    </List>
  );
};

TemplatesList.propTypes = {
  onSelected: PropTypes.func.isRequired,
  searchString: PropTypes.string.isRequired,
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  variant: PropTypes.oneOf([
    MessageTemplatesVariant.EMAIL,
    MessageTemplatesVariant.SMS,
    MessageTemplatesVariant.WHATSAPP,
  ]).isRequired,
};

export default TemplatesList;
