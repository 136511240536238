import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  action: {
    defaultMessage: 'ACTION',
    id: `app.containers.WorkflowEditor.components.Rules.PartnerRules.PartnerAction.action`,
  },
  actionType: {
    defaultMessage: 'Action Type',
    id: `app.containers.WorkflowEditor.components.Rules.PartnerRules.PartnerAction.actionType`,
  },
  moveApplicantToNextStage: {
    defaultMessage: 'Move applicant to next stage',
    id: `app.containers.WorkflowEditor.components.Rules.PartnerRules.PartnerAction.moveApplicantToNextStage`,
  },
  moveApplicantToStage: {
    defaultMessage: 'Move applicant to stage',
    id: `app.containers.WorkflowEditor.components.Rules.PartnerRules.PartnerAction.moveApplicantToStage`,
  },
});
