import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addActions: {
    id: `app.containers.WorkflowEditor.components.Rules.RuleCard.addActions`,
    defaultMessage: 'Add actions',
  },
  addConditions: {
    id: `app.containers.WorkflowEditor.components.Rules.RuleCard.addConditions`,
    defaultMessage: 'Add conditions',
  },
  delete: {
    id: `app.containers.WorkflowEditor.components.Rules.RuleCard.delete`,
    defaultMessage: 'Delete',
  },
  deleteRule: {
    id: `app.containers.WorkflowEditor.components.Rules.RuleCard.deleteRule`,
    defaultMessage: 'Delete Rule',
  },
  matchAllConditions: {
    id: `app.containers.WorkflowEditor.components.Rules.RuleCard.matchAllConditions`,
    defaultMessage: 'Match all conditions',
  },
  matchAnyCondition: {
    id: `app.containers.WorkflowEditor.components.Rules.RuleCard.matchAnyCondition`,
    defaultMessage: 'Match any condition',
  },
  rule: {
    id: `app.containers.WorkflowEditor.components.Rules.RuleCard.rule`,
    defaultMessage: 'Rule',
  },
  ruleNumber: {
    id: `app.containers.WorkflowEditor.components.Rules.RuleCard.ruleNumber`,
    defaultMessage: 'Rule {number}',
  },
});
