import { ApiError, CancelablePromise, CancelError } from 'api-clients/monolith';
import { useCallback, useEffect, useRef, useState } from 'react';

import { ReloadableResultStatus } from '../api/resultStatus';

export interface UseInfiniteApiService<T, E> {
  data: T[];
  lastResult: ReloadableResultStatus<T, E>;
  queryKey: number;
  next: () => void;
  refetch: (all?: boolean) => void;
}

interface LengthWise {
  length: number;
}

const decrementQueryKeyToZero = (oldQueryKey: number) =>
  oldQueryKey > 1 ? oldQueryKey - 1 : 0;

export const useInfiniteApiService = <T extends LengthWise, E = unknown>(
  // This service should be memoized before passed in, for example by using `useCallback`.
  service: () => CancelablePromise<T>,
): UseInfiniteApiService<T, E> => {
  const [data, setData] = useState<T[]>([]);
  const [lastResult, setLastResult] = useState<ReloadableResultStatus<T, E>>({
    status: 'loading',
    isLoading: true,
    isError: false,
  });
  const [queryKey, setQueryKey] = useState(0);
  const ready = useRef(true);
  const [refetchable, setRefetchable] = useState(false);

  const setLoadingOrReloading = useCallback(() => {
    setLastResult(currentResult => {
      // Last page loaded
      if (currentResult.status === 'ready') {
        return {
          status: 'reloading',
          data: currentResult.data,
          isLoading: true,
          isError: false,
        };
      }
      if (currentResult.status !== 'loading') {
        return {
          status: 'loading',
          isLoading: true,
          isError: false,
        };
      }
      return currentResult;
    });
  }, []);

  const next = () => {
    if (ready.current) {
      setQueryKey(currentKey => currentKey + 1);
      ready.current = false;
    }
  };

  const refetch = (all = false) => {
    if (all || queryKey < 2) {
      setData([]);
      setRefetchable(fetchable => !fetchable);
    } else {
      setData(data => data.slice(0, data.length - 2));
    }
    setQueryKey(all ? 0 : decrementQueryKeyToZero);
    setLastResult({ status: 'loading', isLoading: true, isError: false });
    ready.current = false;
  };

  useEffect(() => {
    ready.current = true;
  }, [data]);

  useEffect(() => {
    setLoadingOrReloading();
    const promise = service();
    promise
      .then(lastData => {
        setLastResult({
          status: 'ready',
          data: lastData,
          isLoading: false,
          isError: false,
        });
        if (lastData.length !== 0) {
          setData(data => [...data, lastData]);
        }
      })
      .catch((e: Error) => {
        if (e instanceof CancelError) {
          return;
        }
        if (e instanceof ApiError) {
          setLastResult({
            status: 'error',
            isLoading: false,
            isError: true,
            error: e.body as E,
          });
          return;
        }
        setLastResult({ status: 'error', isLoading: false, isError: true });
      });
    return () => promise.cancel();
  }, [service, refetchable, setLoadingOrReloading]);

  return { data, lastResult, queryKey, next, refetch };
};
