import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  hoverButton: {
    height: 32,
    width: 32,
    padding: 'unset',
  },
}));

export default useStyles;
