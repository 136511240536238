import { DateTime, Settings } from 'luxon';

/**
 * Get timezone. We try the current user's timezone first and then default to the browser timezone if data is not available.
 *
 * @param {string} timezone IANA formatted string - `America/Los_Angeles`
 * @returns {string} IANA formatted string - `America/Los_Angeles`
 */
export const getTimezone = timezone =>
  timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * Get the min date for the calendar.
 * Return today's date plus one day if the browser date is a day before the user's date.
 * Return today's date minus one day if the browser date is a day after the user's date.
 *
 * @param {string} timezone IANA formatted string - `America/Los_Angeles`
 * @returns {Date}
 */
export const getMinDate = timezone => {
  Settings.defaultZone = getTimezone(timezone);
  const currentMinute = DateTime.local().minute;
  // Min Date should be fast-forward as much as 44 minutes so that it matches our earliest availability time
  const today = DateTime.local().plus({
    minutes: 30 + Math.ceil(currentMinute / 15) * 15 - currentMinute,
  });
  return new Date(today.year, today.month - 1, today.day);
};

/**
 * Determine whether or not two Date objects are on the same day
 *
 * @param {Date} refDate the date we're checking
 * @returns {Boolean}
 */
export const isSameDay = refDate => {
  const today = new Date();
  return (
    today.getFullYear() === refDate.getFullYear() &&
    today.getMonth() === refDate.getMonth() &&
    today.getDate() === refDate.getDate()
  );
};
