import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  details: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SessionDetailsCard.header`,
    defaultMessage: 'Set the basic details of your events',
  },
  sessionName: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SessionDetailsCard.eventName`,
    defaultMessage: 'Event name',
  },
  eventDetails: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SessionDetailsCard.eventDetails',
    defaultMessage: 'Availability details',
  },
  numberOfApplicants: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SessionDetailsCard.numberOfApplicants',
    defaultMessage: 'Number of applicants per event',
  },
  eventInstructions: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SessionDetailsCard.eventInstructions',
    defaultMessage: 'Event instructions',
  },
  duration: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SessionDetailsCard.duration',
    defaultMessage: 'Duration',
  },
  minutes: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SessionDetailsCard.minutes',
    defaultMessage: 'Minutes',
  },
  hours: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SessionDetailsCard.hours',
    defaultMessage: 'Hours',
  },
  location: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SessionDetailsCard.location',
    defaultMessage: 'Location',
  },
  locationSubtitle: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SessionDetailsCard.locationSubtitle',
    defaultMessage: 'Let applicants know where your events will take place.',
  },
  loading: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SessionDetailsCard.loading',
    defaultMessage: 'Loading...',
  },
  failed: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SessionDetailsCard.failed',
    defaultMessage: 'Failed to load suggestions...',
  },
  attendeeDetails: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SessionDetailsCard.attendeeDetails',
    defaultMessage: 'The amount of applicants that can attend each event',
  },
});
