import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  header: {
    padding: theme.spacing(3),
  },
  body: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  bodyText: {
    color: theme.palette.common.gray800,
    lineHeight: '20px',
  },
  footer: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3),

    '& button': {
      width: 'unset',

      '&:last-of-type': {
        marginLeft: theme.spacing(2),
      },
    },
  },
}));
