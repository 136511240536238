import { Button as MuiButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import LoadingIcon from './loading-icon.svg';

const useStyles = makeStyles(() => ({
  '@keyframes rotation': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(359deg)',
    },
  },
  root: {
    animation: '$rotation 0.7s infinite linear',
  },
}));

const useOverrideStyles = makeStyles(theme => ({
  contained: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export type LoadingButtonProps = React.ComponentProps<typeof MuiButton>;

export const LoadingButton: React.VFC<LoadingButtonProps> = ({
  classes,
  ...rest
}) => {
  const styles = useStyles();
  const overrideClasses = useOverrideStyles();

  return (
    <MuiButton {...rest} classes={{ ...(classes ?? null), ...overrideClasses }}>
      <img alt="loading icon" className={styles.root} src={LoadingIcon} />
    </MuiButton>
  );
};
