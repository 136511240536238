/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChatbotTryFountainAi } from '../models/ChatbotTryFountainAi';
import type { ChatbotWidgetAliveParams } from '../models/ChatbotWidgetAliveParams';
import type { ChatbotWidgetBrandConfig } from '../models/ChatbotWidgetBrandConfig';
import type { ChatbotWidgetBrands } from '../models/ChatbotWidgetBrands';
import type { ChatbotWidgetInitResponse } from '../models/ChatbotWidgetInitResponse';
import type { ChatbotWidgetParams } from '../models/ChatbotWidgetParams';
import type { ChatbotWidgetPresignedBlob } from '../models/ChatbotWidgetPresignedBlob';
import type { LogFaqLinkClickEventParams } from '../models/LogFaqLinkClickEventParams';
import type { UpdateFaqFeedbackParams } from '../models/UpdateFaqFeedbackParams';
import type { WidgetSessionResetParams } from '../models/WidgetSessionResetParams';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ChatbotWidgetService {

    /**
     * list brands
     * Returns a list of brands associated to an account
     * @returns ChatbotWidgetBrands successful
     * @throws ApiError
     */
    public static getInternalApiChatbotChatbotWidgetBrands(): CancelablePromise<ChatbotWidgetBrands> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/chatbot/chatbot_widget/brands',
        });
    }

    /**
     * Initializes chat configuration
     * @param chatbotWidgetId
     * @param brandColor Brand Color
     * @returns ChatbotWidgetBrandConfig successful
     * @throws ApiError
     */
    public static getInternalApiChatbotWidgetConfiguration(
        chatbotWidgetId: string,
        brandColor?: string,
    ): CancelablePromise<ChatbotWidgetBrandConfig> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/chatbot/widget/configuration',
            headers: {
                'CHATBOT-WIDGET-ID': chatbotWidgetId,
            },
            query: {
                'brand_color': brandColor,
            },
        });
    }

    /**
     * Updates applicant
     * @param chatbotWidgetId
     * @param chatbotWidgetApplicantId
     * @param requestBody Chatbot Widget Applicant update params
     * @returns ChatbotWidgetInitResponse successful
     * @throws ApiError
     */
    public static putInternalApiChatbotWidgetChat(
        chatbotWidgetId: string,
        chatbotWidgetApplicantId: string,
        requestBody: ChatbotWidgetParams,
    ): CancelablePromise<ChatbotWidgetInitResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/internal_api/chatbot/widget/chat',
            headers: {
                'CHATBOT-WIDGET-ID': chatbotWidgetId,
                'CHATBOT-WIDGET-APPLICANT-ID': chatbotWidgetApplicantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `invalid request`,
            },
        });
    }

    /**
     * Initialize chat
     * @param chatbotWidgetId
     * @param requestBody Try Fountain AI params
     * @param chatbotWidgetApplicantId
     * @returns ChatbotWidgetInitResponse successful
     * @throws ApiError
     */
    public static postInternalApiChatbotWidgetChat(
        chatbotWidgetId: string,
        requestBody: ChatbotTryFountainAi,
        chatbotWidgetApplicantId?: string,
    ): CancelablePromise<ChatbotWidgetInitResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/chatbot/widget/chat',
            headers: {
                'CHATBOT-WIDGET-ID': chatbotWidgetId,
                'CHATBOT-WIDGET-APPLICANT-ID': chatbotWidgetApplicantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Initialize Faq chat
     * @param chatbotWidgetId
     * @param requestBody Faq Params
     * @param chatbotWidgetApplicantId
     * @returns ChatbotWidgetInitResponse successful
     * @throws ApiError
     */
    public static postInternalApiChatbotWidgetFaqChat(
        chatbotWidgetId: string,
        requestBody: {
            is_applicant_onboarded: boolean;
            is_applicant_portal?: boolean;
        },
        chatbotWidgetApplicantId?: string,
    ): CancelablePromise<ChatbotWidgetInitResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/chatbot/widget/faq_chat',
            headers: {
                'CHATBOT-WIDGET-ID': chatbotWidgetId,
                'CHATBOT-WIDGET-APPLICANT-ID': chatbotWidgetApplicantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Returns presigned blob meta data
     * Returns presigned blob meta data
     * @param chatbotWidgetId
     * @param chatbotWidgetApplicantId
     * @param fileName File Name
     * @param isApplicantOnboarded
     * @param isApplicantPortal
     * @returns ChatbotWidgetPresignedBlob successful
     * @throws ApiError
     */
    public static getInternalApiChatbotWidgetPresignedBlob(
        chatbotWidgetId: string,
        chatbotWidgetApplicantId: string,
        fileName?: string,
        isApplicantOnboarded?: boolean,
        isApplicantPortal?: boolean,
    ): CancelablePromise<ChatbotWidgetPresignedBlob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/chatbot/widget/presigned_blob',
            headers: {
                'CHATBOT-WIDGET-ID': chatbotWidgetId,
                'CHATBOT-WIDGET-APPLICANT-ID': chatbotWidgetApplicantId,
            },
            query: {
                'file_name': fileName,
                'is_applicant_onboarded': isApplicantOnboarded,
                'is_applicant_portal': isApplicantPortal,
            },
        });
    }

    /**
     * Handoff to applicant’s preferred platform
     * @param chatbotWidgetId
     * @param requestBody Chatbot Widget Session update params
     * @returns any successful
     * @throws ApiError
     */
    public static putInternalApiChatbotWidgetCloseHandoffSession(
        chatbotWidgetId: string,
        requestBody: WidgetSessionResetParams,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/internal_api/chatbot/widget/close_handoff_session',
            headers: {
                'CHATBOT-WIDGET-ID': chatbotWidgetId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update FAQ feedback
     * @param chatbotWidgetId
     * @param chatbotWidgetApplicantId
     * @param requestBody FAQ link click event params
     * @returns any successful
     * @throws ApiError
     */
    public static putInternalApiChatbotWidgetLogFaqFeedback(
        chatbotWidgetId: string,
        chatbotWidgetApplicantId: string,
        requestBody: UpdateFaqFeedbackParams,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/internal_api/chatbot/widget/log_faq_feedback',
            headers: {
                'CHATBOT-WIDGET-ID': chatbotWidgetId,
                'CHATBOT-WIDGET-APPLICANT-ID': chatbotWidgetApplicantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Log FAQ Response link click
     * @param chatbotWidgetId
     * @param chatbotWidgetApplicantId
     * @param requestBody FAQ link click event params
     * @returns any successful
     * @throws ApiError
     */
    public static putInternalApiChatbotWidgetLogFaqLinkClick(
        chatbotWidgetId: string,
        chatbotWidgetApplicantId: string,
        requestBody: LogFaqLinkClickEventParams,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/internal_api/chatbot/widget/log_faq_link_click',
            headers: {
                'CHATBOT-WIDGET-ID': chatbotWidgetId,
                'CHATBOT-WIDGET-APPLICANT-ID': chatbotWidgetApplicantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * alive
     * @param chatbotWidgetId
     * @param chatbotWidgetApplicantId
     * @param requestBody Chatbot Widget Alive params
     * @returns any successful
     * @throws ApiError
     */
    public static putInternalApiChatbotWidgetAlive(
        chatbotWidgetId: string,
        chatbotWidgetApplicantId: string,
        requestBody: ChatbotWidgetAliveParams,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/internal_api/chatbot/widget/alive',
            headers: {
                'CHATBOT-WIDGET-ID': chatbotWidgetId,
                'CHATBOT-WIDGET-APPLICANT-ID': chatbotWidgetApplicantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}