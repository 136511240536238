import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addCustomerSupport: {
    id: `app.containers.WorkflowEditor.components.StageDetail.TechCheckStage.CustomerSupport.addCustomerSupport`,
    defaultMessage: 'Add customer support',
  },
  addLink: {
    id: `app.containers.WorkflowEditor.components.StageDetail.TechCheckStage.CustomerSupport.addLink`,
    defaultMessage: 'Add website URL',
  },
  addPhoneNumber: {
    id: `app.containers.WorkflowEditor.components.StageDetail.TechCheckStage.CustomerSupport.addPhoneNumber`,
    defaultMessage: 'Add phone number',
  },
  customerSupport: {
    id: `app.containers.WorkflowEditor.components.StageDetail.TechCheckStage.CustomerSupport.customerSupport`,
    defaultMessage: 'Customer Support',
  },
  customerSupportBodyText: {
    id: `app.containers.WorkflowEditor.components.StageDetail.TechCheckStage.CustomerSupport.customerSupportBodyText`,
    defaultMessage:
      'Provide the applicant with support options by adding phone number and website link.',
  },
  delete: {
    id: `app.containers.WorkflowEditor.components.StageDetail.TechCheckStage.CustomerSupport.delete`,
    defaultMessage: 'Delete',
  },
  link: {
    id: `app.containers.WorkflowEditor.components.StageDetail.TechCheckStage.CustomerSupport.link`,
    defaultMessage: 'Link',
  },
  phoneNumber: {
    id: `app.containers.WorkflowEditor.components.StageDetail.TechCheckStage.CustomerSupport.phoneNumber`,
    defaultMessage: 'Phone Number',
  },
});
