import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  countryCode: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.BackgroundCheckStage.JobLocation.countryCode`,
    defaultMessage: 'Country Code',
  },
  description: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.BackgroundCheckStage.JobLocation.description`,
    defaultMessage:
      'Select the job’s country location. For positions without a single location, use your company’s address.',
  },
  jobLocation: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.BackgroundCheckStage.JobLocation.jobLocation`,
    defaultMessage: 'Job Location',
  },
});
