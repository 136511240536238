import { Button, Radio } from '@fountain/fountain-ui-components';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import FormikCheckbox from 'components/FormikCheckbox';
import PublicNavbar from 'components/PublicNavbar';
import useCreateCcpaRequest from 'hooks/useCreateCcpaRequest';

import AuthorizedAgentFields from './components/AuthorizedAgentFields';
import CcpaRequestFields from './components/CcpaRequestFields';
import ConsumerFields from './components/ConsumerFields';
import SuccessContent from './components/SuccessContent';
import {
  DEFAULT_AGENT_VALUES,
  DEFAULT_CCPA_REQUEST_VALUES,
  DEFAULT_CONSUMER_VALUES,
  MAX_FILE_SIZE,
  REQUEST_TYPES,
  REQUESTOR_TYPES,
  SUPPORTED_FORMATS,
} from './constants';
import useStyles from './styles';

const INITIAL_VALUES = {
  authorized_agent: {
    authorized_agent: DEFAULT_AGENT_VALUES,
    consumer: DEFAULT_CONSUMER_VALUES,
    ccpa_request: DEFAULT_CCPA_REQUEST_VALUES,
    agreement: false,
  },
  consumer: {
    consumer: DEFAULT_CONSUMER_VALUES,
    ccpa_request: DEFAULT_CCPA_REQUEST_VALUES,
    agreement: false,
  },
};

function CCPA() {
  const classes = useStyles();

  const [requester, setRequester] = useState('');
  const [validateOnChange, setValidateOnChange] = useState(false);

  const { handleCreateCcpaRequest, success, isLoading } =
    useCreateCcpaRequest();

  useEffect(() => {
    setValidateOnChange(false);
  }, [requester]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [success, requester]);

  const CCPA_REQUEST_SCHEMA = Yup.object()
    .shape({
      consumer_relationship_with_fountain: Yup.string('').required('Required'),
      [REQUEST_TYPES.INFORMATION_REQUEST]: Yup.bool(),
      [REQUEST_TYPES.ACCESS_REQUEST]: Yup.bool(),
      [REQUEST_TYPES.DELETION_REQUEST]: Yup.bool(),
    })
    .test(
      'hasOneRequestTypeSelected', // custom request type validation
      null,
      obj => {
        if (
          obj.information_request ||
          obj.access_request ||
          obj.deletion_request
        ) {
          return true;
        }

        return new Yup.ValidationError('Required', null, 'request_type');
      },
    );

  const CONSUMER_SCHEMA = Yup.object().shape({
    consumer: Yup.object().shape({
      first_name: Yup.string('').min(2, 'Too short').required('Required'),
      last_name: Yup.string('').min(2, 'Too short').required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
      phone_number: Yup.string()
        .matches(
          /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
          'Phone number is not valid',
        )
        .required('Required'),
      address_1: Yup.string('').min(2, 'Too short').required('Required'),
      city: Yup.string('').min(2, 'Too short').required('Required'),
      state: Yup.string('').required('Required'),
      zipcode: Yup.string('').min(3, 'Too short').required('Required'),
    }),
    ccpa_request: CCPA_REQUEST_SCHEMA,
    agreement: Yup.bool().oneOf([true], 'Field must be checked'),
  });

  const AUTHORIZED_AGENT_SCHEMA = Yup.object().shape({
    authorized_agent: Yup.object().shape({
      first_name: Yup.string('').required('Required'),
      last_name: Yup.string('').required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
      relationship_with_consumer: Yup.string('').required('Required'),
      proof_of_authorization_file: Yup.mixed()
        .required('A file is required')
        .test(
          'fileFormat',
          'Unsupported Format',
          value => value && SUPPORTED_FORMATS.includes(value.type),
        )
        .test(
          'fileSize',
          `File is too large. Maximum size: ${MAX_FILE_SIZE / 1000}kb`,
          value => value && value.size <= MAX_FILE_SIZE,
        ),
    }),
    // Authorized agent form also has Consumer form.
    consumer: CONSUMER_SCHEMA.fields.consumer,
    ccpa_request: CCPA_REQUEST_SCHEMA,
    agreement: Yup.bool().oneOf([true], 'Field must be checked'),
  });

  const REQUESTOR_SCHEMA = {
    consumer: CONSUMER_SCHEMA,
    authorized_agent: AUTHORIZED_AGENT_SCHEMA,
  };

  const handleSubmitForm = values => {
    handleCreateCcpaRequest(values);
  };

  return (
    <>
      <PublicNavbar />
      <Box py={6.25}>
        <Grid className={classes.ccpaContainer}>
          {success ? (
            <SuccessContent />
          ) : (
            <>
              <Typography
                variant="h2"
                align="center"
                className={classes.ccpaHeading}
              >
                Submit a CCPA data request
              </Typography>
              <Box mb={3}>
                <Typography variant="body1" color="textPrimary">
                  If you are a California resident, the California Consumer
                  Privacy Act (“CCPA”) provides you with certain rights with
                  respect to your data. Fountain has elected to extend these
                  rights to all residents of the United States. We have created
                  the form below to assist applicable residents in submitting
                  these requests. We may ask for additional information to
                  verify your identity or a requester’s authority to act on your
                  behalf. The information provided through this form will be
                  used to respond to and process your request and to keep
                  compliance records of your request. Please see the{' '}
                  <a
                    href="https://www.fountain.com/privacy.html"
                    target="_blank"
                    className={classes.privacyLink}
                  >
                    &quot;Additional Information for California Residents&quot;
                  </a>{' '}
                  section of our Privacy Policy for more information about these
                  rights.
                </Typography>
              </Box>
              <FormControl component="fieldset">
                <Box mb={2}>
                  <FormLabel>Requester</FormLabel>
                </Box>
                <RadioGroup
                  name="requester"
                  value={requester}
                  onChange={e => setRequester(e.target.value)}
                >
                  {Object.keys(REQUESTOR_TYPES).map(key => (
                    <Radio
                      key={key}
                      value={key}
                      label={REQUESTOR_TYPES[key]}
                      className={classes.radio}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              {requester && (
                <>
                  <Box my={4}>
                    <Divider />
                  </Box>
                  <Grid>
                    <Formik
                      initialValues={INITIAL_VALUES[requester]}
                      validationSchema={REQUESTOR_SCHEMA[requester]}
                      onSubmit={handleSubmitForm}
                      enableReinitialize
                      validateOnChange={validateOnChange}
                      validateOnBlur={false}
                    >
                      {formikProps => {
                        const { values, errors, setFieldValue, setFieldError } =
                          formikProps;
                        return (
                          <Form>
                            {requester === 'consumer' && (
                              <>
                                <ConsumerFields
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  errors={errors}
                                />
                                <CcpaRequestFields
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  errors={errors}
                                />
                              </>
                            )}
                            {requester === 'authorized_agent' && (
                              <>
                                <AuthorizedAgentFields
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  errors={errors}
                                  schema={AUTHORIZED_AGENT_SCHEMA}
                                  setFieldError={setFieldError}
                                />
                                <ConsumerFields
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  errors={errors}
                                />
                                <CcpaRequestFields
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  errors={errors}
                                />
                              </>
                            )}
                            <Box my={4}>
                              <Divider />
                            </Box>
                            <Box mt={3}>
                              <FormControl error={errors.agreement}>
                                <FormikCheckbox
                                  alignItems="flex-start"
                                  name="agreement"
                                  checked={values.agreement}
                                  label="I certify under penalty of perjury that the consumer is a United States resident, that all information I have submitted is true and accurate, and that I am the individual, or authorized agent of the individual, whose information is the subject of this request."
                                />
                                {errors.agreement && (
                                  <Box mt={1}>
                                    <Typography variant="body2" color="error">
                                      {errors.agreement}
                                    </Typography>
                                  </Box>
                                )}
                              </FormControl>
                            </Box>
                            <Box mt={3}>
                              <Grid container justify="flex-end">
                                <Button
                                  onClick={() => setValidateOnChange(true)}
                                  submit
                                  isLoading={isLoading}
                                  disabled={isLoading}
                                >
                                  SUBMIT
                                </Button>
                              </Grid>
                            </Box>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      </Box>
    </>
  );
}

export default CCPA;
