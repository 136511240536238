import { Button } from '@fountain/fountain-ui-components';
import { Typography } from '@material-ui/core';
import { VideoRecordingStageQuestion } from 'api-clients/monolith';
import cx from 'classnames';
import React, { VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { StageSettingCard } from 'containers/WorkflowEditor/components/StageSettingCard';

import { messages } from './messages';
import { Question } from './Question';
import { useStyles } from './styles';

export interface QuestionsProps {
  questions: VideoRecordingStageQuestion[];
  onAddQuestion: () => void;
}

export const Questions: VFC<QuestionsProps> = ({
  questions,
  onAddQuestion,
}) => {
  const styles = useStyles();

  // eslint-disable-next-line no-underscore-dangle
  const availableQuestions = questions.filter(question => !question._destroy);

  return (
    <StageSettingCard
      title={<FormattedMessage {...messages.videoRecordingQuestions} />}
      variant="default"
    >
      <Typography color="textPrimary" variant="body2">
        <FormattedMessage {...messages.questionsExplanationText} />
      </Typography>
      <div
        className={cx(styles.questionsContainer, {
          [styles.questionsContainerWithDeleteButton]: questions.length > 1,
        })}
      >
        {availableQuestions.map((question, index) => (
          <Question
            key={question.id}
            allowDelete={availableQuestions.length > 1}
            index={index}
            question={question}
          />
        ))}
        <Button
          className={styles.addButton}
          onClick={onAddQuestion}
          size="small"
          type="secondary"
        >
          <FormattedMessage {...messages.addQuestion} />
        </Button>
      </div>
    </StageSettingCard>
  );
};
