import { createSelector } from 'reselect';

import { Applicant, Job, Stage } from 'containers/ApplicantTableV2/types';
import { Reason } from 'hooks/useAutomatedActions';
import { createDeepEqualSelector } from 'utils/selectors';

import { DialogType, Reason as ReasonType, Transition } from './constants';
export const REDUCER_KEY = 'moveApplicantDialog';

interface Data {
  applicantCount: number;
  applicantId: string;
  applicantName: string;
  applicants: Record<string, Applicant>;
  selectedApplicantIds: string | string[];
  fromJobId: string;
  fromStageId: string;
  fromStageType: string;
  toStageId: string;
  toDestinationStageId?: string;
  isBulk: boolean;
  isMasterApplicantsView: boolean;
  isValidationSaved: boolean;
  rawQueryString: string;
  warnW4Federal: boolean;
  warnWotc: boolean;
  toJobId: string;
  transitionType: Transition;
  reasonId: string;
}

interface AutomatedActions {
  isVisible: boolean;
  shouldRun: boolean;
  toStage: Partial<Stage>;
  destinationStage?: Stage;
}

interface JobStagesSelectors {
  isVisible: boolean;
  job: Partial<Job>;
  stage: Partial<Stage>;
}

interface ReasonSelector {
  defaultReason: Partial<Reason>;
  isVisible: boolean;
  reason: Partial<Reason>;
  type: ReasonType;
}

interface DialogContent {
  automatedActions: AutomatedActions;
  jobStageSelectors: JobStagesSelectors;
  reasonSelector: ReasonSelector;
}

interface SubmitStatus {
  isEnabled: boolean;
  isLoading: boolean;
}

export interface MoveApplicantDialogState {
  data: Data;
  dialogContent: DialogContent;
  dialogType: DialogType;
  isDialogOpen: boolean;
  isLoading: boolean;
  submitStatus: SubmitStatus;
}
export const initialState: MoveApplicantDialogState = {
  data: {
    applicantCount: 0,
    applicantId: '',
    applicantName: '',
    applicants: {},
    fromJobId: '',
    fromStageType: '',
    fromStageId: '',
    isBulk: false,
    isMasterApplicantsView: false,
    isValidationSaved: false,
    rawQueryString: '',
    selectedApplicantIds: [],
    toStageId: '',
    warnW4Federal: false,
    warnWotc: false,
    toJobId: '',
    transitionType: '' as Transition,
    reasonId: '',
  },
  dialogContent: {
    automatedActions: {
      isVisible: false,
      shouldRun: true,
      toStage: {},
    },
    jobStageSelectors: {
      isVisible: false,
      job: {},
      stage: {},
    },
    reasonSelector: {
      defaultReason: {},
      isVisible: false,
      reason: {},
      type: '' as ReasonType,
    },
  },
  dialogType: '' as DialogType,
  isDialogOpen: false,
  isLoading: false,
  submitStatus: {
    isEnabled: false,
    isLoading: false,
  },
};

const selectState = (state: { [REDUCER_KEY]: MoveApplicantDialogState }) =>
  state[REDUCER_KEY] || initialState;

export const makeSelectData = () =>
  createDeepEqualSelector(selectState, substate => substate.data);

export const makeSelectDialogContent = () =>
  createSelector(selectState, substate => substate.dialogContent);

export const makeSelectDialogType = () =>
  createSelector(selectState, substate => substate.dialogType);

export const makeSelectIsDialogOpen = () =>
  createSelector(selectState, substate => substate.isDialogOpen);

export const makeSelectIsLoading = () =>
  createSelector(selectState, substate => substate.isLoading);

export const makeSelectAutomatedActions = () =>
  createSelector(
    makeSelectDialogContent(),
    substate => substate.automatedActions,
  );

export const makeSelectJobStageSelectors = () =>
  createSelector(
    makeSelectDialogContent(),
    substate => substate.jobStageSelectors,
  );

export const makeSelectReasonSelector = () =>
  createSelector(
    makeSelectDialogContent(),
    substate => substate.reasonSelector,
  );

export const makeSelectSubmitStatus = () =>
  createSelector(selectState, substate => substate.submitStatus);
