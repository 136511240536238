import { Button } from '@material-ui/core';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Pusher from 'pusher-js/with-encryption';
import React, { SetStateAction, useEffect, VFC } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  makeSelectAccountId,
  makeSelectEnvVars,
} from 'containers/Auth_old/selectors';

import { SelectedFilters } from '../Filters';
import { messages } from './messages';
import { useStyles } from './styles';

interface LoadMoreProps {
  showLoadMore: boolean;
  setShowLoadMore: (flag: boolean) => void;
  setIsRefetch: (flag: boolean) => void;
  setSelectedFilters: React.Dispatch<SetStateAction<SelectedFilters>>;
}

interface PusherChatBotData {
  /* eslint-disable camelcase */
  bot_type: string;
  /* eslint-enable */
}

export const LoadMore: VFC<LoadMoreProps> = ({
  showLoadMore,
  setShowLoadMore,
  setSelectedFilters,
  setIsRefetch,
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const { REACT_APP_PUSHER_APP_KEY, REACT_APP_PUSHER_CLUSTER } = useSelector(
    makeSelectEnvVars(),
  );
  const accountExternalId = useSelector(makeSelectAccountId());

  const accountInboundChatBotLog = (data: PusherChatBotData) => {
    const params = new URLSearchParams(window.location.search);
    const botType = params.get('botType');
    if (data.bot_type === 'All' && ['All', null].includes(botType))
      setShowLoadMore(true);
    if (data.bot_type === botType) setShowLoadMore(true);
  };

  useEffect(() => {
    const pusher = new Pusher(REACT_APP_PUSHER_APP_KEY, {
      cluster: REACT_APP_PUSHER_CLUSTER,
    });
    const accountChatBotChannelName = `${accountExternalId}-chatbot-review`;
    const accountChatBotChannel = pusher.subscribe(accountChatBotChannelName);
    accountChatBotChannel.bind('inbound:chatBotLog', accountInboundChatBotLog);

    return () => {
      accountChatBotChannel.unbind();
      pusher.unsubscribe(accountChatBotChannelName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoadMore = () => {
    setIsRefetch(true);
    setShowLoadMore(false);
    setSelectedFilters({
      status: 'All',
      models: [],
      intents: [],
      sortBy: 'Newest',
    });
  };

  if (!showLoadMore) {
    return null;
  }

  return (
    <Button
      variant="contained"
      color="primary"
      className={styles.loadMore}
      onClick={handleLoadMore}
    >
      <AutorenewIcon className={styles.loadMoreIcon} />
      {intl.formatMessage(messages.loadMore)}
    </Button>
  );
};
