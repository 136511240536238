import { SidebarStage } from 'api-clients/monolith';

import { faker } from 'testUtils';

import { StageCardProps } from './StageCard';

export const fakeSidebarStage = ({
  /* eslint-disable camelcase */
  applicants_count = faker.datatype.number(),
  move_rule = {
    idle_days_count: 10,
    target_stage_title: faker.hacker.phrase(),
  },
  short_type = 'Approved',
  external_id = faker.datatype.uuid(),
  additional_info = {
    is_integration_configured: true,
    package_name: faker.hacker.noun(),
  },
  /* eslint-enable camelcase */
  title = faker.name.jobType(),
  type = 'CheckrCanada::Stage',
  position = faker.datatype.number(),
  slug = faker.name.jobType(),
  extra = {},
}: Partial<SidebarStage> = {}): SidebarStage => ({
  applicants_count,
  short_type,
  additional_info,
  title,
  type,
  move_rule,
  position,
  external_id,
  slug,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  extra,
});

export const defaultProps: StageCardProps = {
  stage: fakeSidebarStage(),
  refetchStages: () => {},
  onDiscardChanges: () => {},
};

export const fakeRuleStage = ({
  position = faker.datatype.number(),
}: Partial<SidebarStage> = {}): SidebarStage =>
  fakeSidebarStage({
    type: 'RuleStage',
    position,
  });
