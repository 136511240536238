import { defineMessages } from 'react-intl';

export default defineMessages({
  descriptionHelperText: {
    id: 'app.components.SourcingPurchaseEditJob.descriptionHelperText',
    defaultMessage: '{characterCount} characters (minimum 250 characters)',
  },
  editLocation: {
    id: 'app.components.SourcingPurchaseEditJob.editLocation',
    defaultMessage: 'Edit Location',
  },
  editBrand: {
    id: 'app.components.SourcingPurchaseEditJob.editBrand',
    defaultMessage: 'Edit Brand',
  },
  postingTitlePlaceholder: {
    id: 'app.components.SourcingPurchaseEditJob.postingTitlePlaceholder',
    defaultMessage: 'e.g. Cashier',
  },
  postingTitleLabel: {
    id: 'app.components.SourcingPurchaseEditJob.postingTitleLabel',
    defaultMessage: 'Posting Title',
  },
  brandLabel: {
    id: 'app.components.SourcingPurchaseEditJob.brandLabel',
    defaultMessage: 'Brand',
  },
  locationLabel: {
    id: 'app.components.SourcingPurchaseEditJob.locationLabel',
    defaultMessage: 'Location',
  },
  jobHoursLabel: {
    id: 'app.components.SourcingPurchaseEditJob.jobHoursLabel',
    defaultMessage: 'Job Hours',
  },
  jobSucessMessage: {
    id: 'app.components.SourcingPurchaseEditJob.jobSucessMessage',
    defaultMessage: 'Job details successfully updated.',
  },
  jobTypeLabel: {
    id: 'app.components.SourcingPurchaseEditJob.jobTypeLabel',
    defaultMessage: 'Job Type',
  },
  industryLabel: {
    id: 'app.components.SourcingPurchaseEditJob.industryLabel',
    defaultMessage: 'Industry',
  },
  finishEditButton: {
    id: 'app.components.SourcingPurchaseEditJob.finishEditButton',
    defaultMessage: 'Finish Edit',
  },
  postingTitleMaxChar: {
    id: 'app.components.SourcingPurchaseEditJob.postingTitleMaxChar',
    defaultMessage: 'Posting Title must not exceed 70 characters',
  },
  descriptionMaxChar: {
    id: 'app.components.SourcingPurchaseEditJob.descriptionMaxChar',
    defaultMessage: 'Description must be over 250 characters',
  },
  leastPayAmount: {
    id: 'app.components.SourcingPurchaseEditJob.leastPayAmount',
    defaultMessage: 'Pay must be at least $1',
  },
  locationMissingCityStateCountry: {
    id: 'app.components.SourcingPurchaseEditJob.locationMissingCityStateCountry',
    defaultMessage: 'Location must include a valid city, state and country',
  },
  minimumPayRequired: {
    id: 'app.components.SourcingPurchaseEditJob.minimumPayRequired',
    defaultMessage: 'Min. pay required',
  },
  minimumPayAmount: {
    id: 'app.components.SourcingPurchaseEditJob.minimumPayAmount',
    defaultMessage: 'Min. pay must be at least $1',
  },
  maxPayRequired: {
    id: 'app.components.SourcingPurchaseEditJob.maxPayRequired',
    defaultMessage: 'Max. pay required',
  },
  minimumMaxPayAmount: {
    id: 'app.components.SourcingPurchaseEditJob.minimumMaxPayAmount',
    defaultMessage: 'Max. pay must be at least $1',
  },
});
