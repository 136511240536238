import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  sortLabel: {
    id: 'app.containers.Chatbot.ChatbotFeedback.sortLabel',
    defaultMessage: `Sort By`,
  },
  statusLabel: {
    id: 'app.containers.Chatbot.ChatbotFeedback.statusLabel',
    defaultMessage: `Status`,
  },
  modelLabel: {
    id: 'app.containers.Chatbot.ChatbotFeedback.modelLabel',
    defaultMessage: `Models`,
  },
  intentLabel: {
    id: 'app.containers.Chatbot.ChatbotFeedback.intentLabel',
    defaultMessage: `Intents`,
  },
  intentFetchError: {
    id: 'app.containers.Chatbot.intentFetchError',
    defaultMessage: `Error fetching intents.`,
  },
});
