import { matchPath, useLocation } from 'react-router-dom';

const useIsMasterApplicantsView = () => {
  const { pathname } = useLocation();
  return !!matchPath(pathname, {
    path: '/:accountSlug/applicants',
    exact: true,
    strict: false,
  });
};

export default useIsMasterApplicantsView;
