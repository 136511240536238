import { WorkflowW4FormStage } from 'api-clients/monolith';
import React, { FC } from 'react';

import { W4Form } from '../W4Form';
import { messages } from './messages';
import { useStyles } from './styles';

export interface W4FederalFormProps {
  stage: WorkflowW4FormStage;
}

export const W4FederalForm: FC<W4FederalFormProps> = ({ stage }) => {
  const styles = useStyles();

  return <W4Form stage={stage} styles={styles} messages={messages}></W4Form>;
};
