import queryString from 'query-string';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  addDefaultErrorMessageAction,
  addMessageAction,
} from 'containers/FlashMessage/actions';
import { apiPatch } from 'utils/axios';
const usePatch = ({ uri }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  let isCancelled = false;

  const cancelPatch = () => {
    isCancelled = true;
  };

  const patchData = async (
    options,
    requestData,
    callback,
    successMessage = {},
  ) => {
    setIsLoading(true);

    try {
      let url = uri;
      const params = queryString.stringify(options);
      if (params) {
        url += `?${params}`;
      }

      const res = await apiPatch(url, requestData);

      if (!isCancelled && res.data) {
        setError(null);
        setData(res.data);
        setIsLoading(false);

        if (callback) {
          callback(res.data);
        }

        if (!successMessage.disable) {
          dispatch(
            addMessageAction(
              successMessage.text || 'Success!',
              successMessage.variant || 'success',
            ),
          );
        }
      }
    } catch (e) {
      if (!isCancelled) {
        setData(null);
        setError(e);
        setIsLoading(false);
        dispatch(addDefaultErrorMessageAction());
      }
    }
  };

  const resetState = () => {
    setData(null);
    setError(null);
  };

  return { data, error, patchData, cancelPatch, isLoading, resetState };
};

export default usePatch;
