/**
 *
 * AuthRoute
 *
 */

import { Loader } from '@fountain/fountain-ui-components';
import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Route from 'route-parser';
import { REACT_APP_SIGN_OUT_REDIRECT_URL } from 'runtimeEnvVars';

import PrivateRouteComponent from 'components/PrivateRoute/Loadable';
import {
  registerMobileDeviceInitAction,
  whoamiInitAction,
} from 'containers/Auth_old/actions';
import reducer from 'containers/Auth_old/reducer';
import saga from 'containers/Auth_old/saga';
import {
  makeSelectIsFetchingWhoami,
  makeSelectIsLoggedIn,
  makeSelectWhoami,
  makeSelectWhoamiError,
} from 'containers/Auth_old/selectors';
import { changeLocaleAction } from 'containers/LanguageProvider/actions';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

// Move this to a CONST/ENV_VAR
const authUrl = REACT_APP_SIGN_OUT_REDIRECT_URL;
export function AuthRoute({
  checkLoggedInStatus,
  component,
  isLoggedIn,
  registerMobileDevice,
  location,
  whoamiError,
  isLoading,
  whoami,
  changeLocale,
  ...rest
}) {
  const { pathname } = location;
  const route = Route(
    `/:accountSlug(/brands/:brandSlug(/locations(/:locationId))(/*rest1))(/*rest2)`,
  );
  const parsed = route.match(pathname);

  useInjectSaga({ key: 'auth', saga });
  useInjectReducer({ key: 'auth', reducer });
  useEffect(() => {
    if (!isLoggedIn) checkLoggedInStatus(parsed.brandSlug);
    // This is for the usecase where we embed this web app inside a mobile app
    document.addEventListener('message', handleMessageFromContainerApp);
    return () =>
      document.removeEventListener('message', handleMessageFromContainerApp);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (whoami) changeLocale(whoami.locale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whoami]);

  const handleMessageFromContainerApp = event => {
    registerMobileDevice(JSON.parse(event.data).deviceId);
  };

  if (isLoading) {
    return <Loader fullScreen size="2rem" />;
  }
  return (
    <PrivateRouteComponent
      component={component}
      authUrl={authUrl}
      isLoggedIn={isLoggedIn}
      location={location}
      {...rest}
    />
  );
}

AuthRoute.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  component: PropTypes.oneOfType([
    PropTypes.instanceOf(React.Component),
    PropTypes.func,
  ]),
  location: PropTypes.object.isRequired,
  whoami: PropTypes.shape({
    locale: PropTypes.string,
  }),
  whoamiError: PropTypes.bool,
  checkLoggedInStatus: PropTypes.func.isRequired,
  registerMobileDevice: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  changeLocale: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  isLoggedIn: makeSelectIsLoggedIn(),
  whoami: makeSelectWhoami(),
  whoamiError: makeSelectWhoamiError(),
  isLoading: makeSelectIsFetchingWhoami(),
});

function mapDispatchToProps(dispatch) {
  return {
    checkLoggedInStatus: brandSlug => dispatch(whoamiInitAction(brandSlug)),
    changeLocale: locale => dispatch(changeLocaleAction(locale)),
    registerMobileDevice: deviceId =>
      dispatch(registerMobileDeviceInitAction(deviceId)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo, withRouter)(AuthRoute);
