import { WorkflowStageDetail } from 'api-clients/monolith';
import produce from 'immer';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { SettingSwitch } from '../../../SettingSwitch';
import { messages } from '../messages';

type GoEnabled = {
  stage: WorkflowStageDetail;
  setStage: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
};

export const GoEnabled: FC<GoEnabled> = ({ stage, setStage }) => {
  const onToggleGoEnabled = () => {
    setStage(
      produce(stage, draftStage => {
        draftStage.go_enabled = !draftStage.go_enabled;
      }),
    );
  };

  return (
    <SettingSwitch
      dataKey="go_enabled"
      checked={stage.go_enabled}
      onChange={onToggleGoEnabled}
      label={<FormattedMessage {...messages.goEnabled} />}
    />
  );
};
