import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
} from '@fountain/fountain-ui-components';
import { Typography } from '@material-ui/core';
import React, { FC } from 'react';

import { useStyles } from './styles';

export interface ConfirmationModalProps {
  ariaLabelledBy: string;
  bodyContent: React.ReactNode;
  cancelButtonContent: React.ReactNode;
  confirmButtonContent: React.ReactNode;
  isOpen?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  maxWidth?: number;
  onCancel: (event: React.MouseEvent<HTMLButtonElement>) => void;
  // For consumers that need to differentiate between clicking the secondary "cancel" button and
  // a "close modal" action (i.e. clicking the header 'X', or clicking outside the modal).
  // If `onClose` is provided, it will override the the "close modal" behavior.
  onClose?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onConfirm: (event: React.MouseEvent<HTMLButtonElement>) => void;
  title: string;
  negative?: boolean;
  blurOverlay?: boolean;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  ariaLabelledBy,
  bodyContent,
  cancelButtonContent,
  confirmButtonContent,
  isOpen = true,
  isLoading = false,
  disabled = false,
  maxWidth = 400,
  onCancel,
  onClose,
  onConfirm,
  title,
  negative = false,
  blurOverlay = true,
}) => {
  const styles = useStyles();

  return (
    <Modal
      ariaLabelledBy={ariaLabelledBy}
      fullScreenOnMobile
      onClose={onClose ?? onCancel}
      open={isOpen}
      blurOverlay={blurOverlay}
      maxWidth={maxWidth}
    >
      <ModalHeader
        ariaLabelledBy={title}
        className={styles.header}
        onClose={onClose ?? onCancel}
        showIcon={false}
      >
        {title}
      </ModalHeader>
      <div className={styles.body}>
        <Typography className={styles.bodyText} variant="body2">
          {bodyContent}
        </Typography>
      </div>
      <ModalFooter className={styles.footer}>
        <Button
          onClick={onCancel}
          size="small"
          type="secondary"
          disabled={disabled}
        >
          {cancelButtonContent}
        </Button>
        <Button
          onClick={onConfirm}
          size="small"
          negative={negative}
          disabled={disabled}
          isLoading={isLoading}
        >
          {confirmButtonContent}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
