import { Grid, Hidden, makeStyles } from '@material-ui/core';
import React, { VFC } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';

import GlobalNav from 'components/GlobalNav';
import { makeSelectAccountSlug } from 'containers/Auth_old/selectors';

import { AiRecommendations } from './AiRecommendations';
import { ChatbotSetup } from './ChatbotSetup';
import { AI_AGENT_SETUP, AI_RECOMMENDATIONS } from './constants';
import { messages } from './messages';
import { Sidebar } from './Sidebar';

const useStyles = makeStyles({
  container: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    height: '100%',
    overflow: 'auto',
  },
});

export interface ChatAgentProps {
  match: {
    path: string;
  };
}

export const ChatAgent: VFC<ChatAgentProps> = ({ match: { path } }) => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();

  const { url } = useRouteMatch();
  const accountSlug = useSelector(makeSelectAccountSlug()) ?? '';

  const handleSidebarMenuSelection = (module: string) => {
    const path = `/${accountSlug}/fountain_ai/${module}`;

    history.replace(path);
  };

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage(messages.title)}</title>
        <meta name="description" content="Fountain AI" />
      </Helmet>
      <div className={classes.container}>
        <Hidden smDown>
          <GlobalNav />
        </Hidden>

        <Grid
          container
          direction="row"
          wrap="nowrap"
          className={classes.content}
        >
          <Sidebar handleSidebarMenuSelection={handleSidebarMenuSelection} />

          <Switch>
            <Route path={[`${path}/${AI_AGENT_SETUP}`]}>
              <>
                <ChatbotSetup />
              </>
            </Route>

            <Route path={[`${path}/${AI_RECOMMENDATIONS}`]}>
              <>
                <AiRecommendations />
              </>
            </Route>

            <Redirect to={`${url}/${AI_AGENT_SETUP}`} />
          </Switch>
        </Grid>
      </div>
    </>
  );
};
