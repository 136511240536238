import { TableCell } from '@material-ui/core';
import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import CustomLabels from 'components/CustomLabels';
import { makeSelectTableColumnWidths } from 'containers/ApplicantTableV2/selectors';
import {
  Applicant,
  ExpandedColumns,
  TableColumnWidthsKey,
} from 'containers/ApplicantTableV2/types';
import {
  makeSelectAccountSlug,
  makeSelectCountryCode,
} from 'containers/Auth_old/selectors';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { isAmazon } from 'utils/constants';

import { translateCustomLabels } from './helpers';
import useStyles from './styles';

export interface ApplicantTableV2CustomLabelsCellProps {
  applicant: Applicant;
  expandedColumns: ExpandedColumns;
  columnType: TableColumnWidthsKey;
}
export const ApplicantTableV2CustomLabelsCell: FC<
  ApplicantTableV2CustomLabelsCellProps
> = ({ applicant, expandedColumns, columnType }) => {
  const styles = useStyles();
  const tableColumnWidths = useSelector(makeSelectTableColumnWidths());
  const accountSlug = useSelector(makeSelectAccountSlug());
  const countryCode = useSelector(makeSelectCountryCode());
  const recruiterLanguage = useSelector(makeSelectLocale()).slice(0, 2);

  const { custom_labels: customLabels } = applicant;
  // TODO: Currently we are hardcoding the customLabel 'Contacted' but we should do it through the API in the future
  // to more context: in monolith we have this service: spec/services/translation_service_spec.rb
  // we could contact this service creating a translation controller with the action translate
  // in the api_self_serve/v2 API with the params: text, from, to

  const translatedCustomLabels = translateCustomLabels(
    customLabels,
    recruiterLanguage,
  );

  return (
    <TableCell
      role="cell"
      style={{
        minWidth: tableColumnWidths[columnType],
        maxWidth: tableColumnWidths[columnType],
      }}
      className={styles.td}
    >
      {Boolean(customLabels?.length) && (
        <CustomLabels
          applicant={applicant}
          customLabels={translatedCustomLabels}
          isExpanded={
            isAmazon(accountSlug, countryCode)
              ? true
              : expandedColumns.expand_custom_label_cell_content
          }
          labelsContainerWidth={tableColumnWidths[columnType]}
        />
      )}
    </TableCell>
  );
};

export default memo(ApplicantTableV2CustomLabelsCell);
