import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { REACT_APP_GLOBAL_API_BASE_URL } from 'runtimeEnvVars';

import { addDefaultErrorMessageAction } from 'containers/FlashMessage/actions';
import { apiDelete, apiGet, apiPatch, apiPost } from 'utils/axios';

import { closePopup } from '../GlobalPopup/actions';
import {
  accountsErrorAction,
  accountsSuccessAction,
  allBrandsErrorAction,
  allBrandsSuccessAction,
  impersonateUserErrorAction,
  impersonateUserSuccessAction,
  registerMobileDeviceErrorAction,
  registerMobileDeviceSuccessAction,
  signoutErrorAction,
  signoutSuccessAction,
  updateClientSettingsSuccess,
  usersErrorAction,
  usersSuccessAction,
  whoamiErrorAction,
  whoamiSuccessAction,
} from './actions';
import {
  ACCOUNTS_INIT,
  FETCH_ALL_BRANDS_INIT,
  IMPERSONATE_USER_INIT,
  REGISTER_MOBILE_DEVICE_INIT,
  SIGN_OUT_INIT,
  UPDATE_CLIENT_SETTINGS,
  USERS_INIT,
  WHOAMI_INIT,
} from './constants';

function appcuesIdentify(whoAmI) {
  window.Appcues.identify(whoAmI.external_id, {
    firstName: whoAmI.name,
    companyName: whoAmI.account_name,
    role: whoAmI.role,
    email: whoAmI.email,
    lastLogin: whoAmI.last_sign_in_at,
    createdAt: whoAmI.created_at,
    accountSlug: whoAmI.account_name,
  });
}

export function* authenticationSaga(action) {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL}/whoami?${
    action.brandSlug ? `brand_slug=${action.brandSlug}` : ''
  }`;

  try {
    const { data: whoAmI } = yield call(apiGet, url);
    yield put(whoamiSuccessAction(whoAmI));

    yield delay(5000);

    appcuesIdentify(whoAmI);

    yield window.postMessage('loginSuccess');
  } catch (err) {
    yield put(whoamiErrorAction(true));
  }
}
export function* authentication() {
  yield takeLatest(WHOAMI_INIT, authenticationSaga);
}
export function* fetchAllBrandsSaga() {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL}/users/brands`;

  try {
    const { data: brands } = yield call(apiGet, url);
    yield put(allBrandsSuccessAction(brands));
  } catch (err) {
    yield put(allBrandsErrorAction(true));
  }
}
export function* fetchAllBrands() {
  yield takeLatest(FETCH_ALL_BRANDS_INIT, fetchAllBrandsSaga);
}
export function* signOutSaga() {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL}/users/session`;

  try {
    const response = yield call(apiDelete, url);
    yield put(signoutSuccessAction(response));
  } catch (err) {
    yield put(signoutErrorAction(err));
  }
}
export function* signOut() {
  yield takeLatest(SIGN_OUT_INIT, signOutSaga);
}

export function* accountsSaga() {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL}/accounts`;

  try {
    const response = yield call(apiGet, url);
    yield put(accountsSuccessAction(response.data.accounts));
  } catch (err) {
    yield put(accountsErrorAction(err));
  }
}
export function* getAccounts() {
  yield takeLatest(ACCOUNTS_INIT, accountsSaga);
}

export function* usersSaga(action) {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL}/accounts/${action.account.external_id}/users`;

  try {
    const response = yield call(apiGet, url);
    yield put(usersSuccessAction(response.data.users));
  } catch (err) {
    yield put(usersErrorAction(err));
  }
}
export function* getUsers() {
  yield takeLatest(USERS_INIT, usersSaga);
}

export function* impersonateUserSaga(action) {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL}/users/${action.userId}/impersonate`;

  const params = {};

  if (action.accountSlug) {
    params.account_slug = action.accountSlug;
  }

  try {
    yield call(apiPost, url, params);
    yield put(impersonateUserSuccessAction());
    if (action.reloadPage) {
      window.location.reload();
    } else {
      window.location.replace('/');
    }
  } catch (err) {
    yield put(impersonateUserErrorAction(err));
  }
}
export function* impersonateUser() {
  yield takeLatest(IMPERSONATE_USER_INIT, impersonateUserSaga);
}

export function* registerMobileDeviceSaga(action) {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL}/mobile_devices`;

  const data = { mobile_device: { device_id: action.deviceId } };

  try {
    yield call(apiPost, url, data);
    yield put(registerMobileDeviceSuccessAction());
  } catch (err) {
    yield put(registerMobileDeviceErrorAction(err));
  }
}
export function* registerMobileDevice() {
  yield takeLatest(REGISTER_MOBILE_DEVICE_INIT, registerMobileDeviceSaga);
}

export function* updateClientSettings(action) {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL}/users/update_client_settings`;

  try {
    const response = yield call(apiPatch, url, {
      [action.view]: action.columns,
      quickActions: action.quickActions,
    });
    yield put(updateClientSettingsSuccess(response.data));
    yield put(closePopup());
  } catch (error) {
    yield put(addDefaultErrorMessageAction());
    yield put(closePopup());
  }
}

export function* updateClientSettingsSaga() {
  yield takeLatest(UPDATE_CLIENT_SETTINGS, updateClientSettings);
}

export default function* rootSaga() {
  yield all([
    authentication(),
    fetchAllBrands(),
    signOut(),
    getAccounts(),
    getUsers(),
    impersonateUser(),
    registerMobileDevice(),
    updateClientSettingsSaga(),
  ]);
}
