/* eslint-disable no-underscore-dangle */
import { Alert, Button } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import {
  DocumentSignatureStagesTemplateSetting,
  DocumentSigningStagesTemplateSetting,
  WorkflowDocumentSignatureStage,
  WorkflowDocumentSigningStage,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import { useSimpleToggle } from 'hooks';
import produce from 'immer';
import React, { FC, useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';

import { AddItemRows } from 'containers/WorkflowEditor/components/AddItemRows';
import { ItemRow } from 'containers/WorkflowEditor/components/AddItemRows/ItemRow';
import { DocumentSigningRules } from 'containers/WorkflowEditor/components/Rules/DocumentSigningRules';
import { DocumentSigningRuleProps } from 'containers/WorkflowEditor/components/Rules/DocumentSigningRules/types';
import { StageSettingCard } from 'containers/WorkflowEditor/components/StageSettingCard';
import { StageDetailPatchResponse } from 'containers/WorkflowEditor/contexts/stageContext';

import { AlternateDocuments } from './AlternateDocuments';
import { DEFAULT_DOCUMENT } from './constants';
import { DefaultDocuments } from './DefaultDocuments';
import { DocumentModal } from './DocumentModal';
import { messages } from './messages';
import { NoTemplates } from './NoTemplates';
import { useStyles } from './styles';

const DocumentsStageSettingCard: FC = ({ children }) => {
  return (
    <StageSettingCard
      title={<FormattedMessage {...messages.documents} />}
      variant="default"
    >
      {children}
    </StageSettingCard>
  );
};

export interface DocumentsProps {
  stage: WorkflowDocumentSigningStage | WorkflowDocumentSignatureStage;
  setStage: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
  setDocumentSigningRules: React.Dispatch<
    React.SetStateAction<DocumentSigningRuleProps>
  >;
  documentSigningRules: DocumentSigningRuleProps;
  updateStageResult: StageDetailPatchResponse;
}

export const Documents: VFC<DocumentsProps> = ({
  stage,
  setStage,
  setDocumentSigningRules,
  documentSigningRules,
  updateStageResult,
}) => {
  const intl = useIntl();
  const styles = useStyles();

  const [selectedTemplateForModal, setSelectedTemplateForModal] = useState<
    | DocumentSigningStagesTemplateSetting
    | DocumentSignatureStagesTemplateSetting
  >();
  const {
    showContent: isModalOpen,
    on: openModal,
    off: closeModal,
  } = useSimpleToggle();

  const selectedTemplates =
    stage.additional_info.else_template_set_attributes?.template_settings_attributes?.filter(
      temp => !temp._destroy,
    ) ?? [];

  const availableTemplates =
    stage.additional_info.available_embedded_signature_templates?.filter(
      temp =>
        // Filter current selectedTemplates
        !selectedTemplates.some(
          selected =>
            selected.template_id ===
            (temp.document_signature_template_id ?? temp.id),
        ) ||
        // Add back selectedTemplate if editing
        selectedTemplateForModal?.template_id ===
          (temp.document_signature_template_id ?? temp.id),
    ) ?? [];

  const allTemplatesDeleted = Object.keys(selectedTemplates).length === 1;

  const maybeEmbeddedSignatureTemplateStages =
    stage.type === 'DocumentSigningStage'
      ? stage.additional_info.embedded_signature_templates_stages_attributes
      : undefined;
  const maybeAccountUsers =
    stage.type === 'DocumentSigningStage'
      ? stage.additional_info.account_users
      : undefined;

  const handleAddDocument = () => {
    setSelectedTemplateForModal(DEFAULT_DOCUMENT);
    openModal();
  };

  const handleDeleteDocument = (id: string | number) => {
    setStage(
      produce(stage, draftStage => {
        // destroy the else_template_set if the last document is destroyed
        // NOTE: the corollary to this lives in <DocumentModal> `handleSubmit` where we
        // remove `_destroy` if the user repopulates the set
        if (
          allTemplatesDeleted &&
          draftStage.additional_info.else_template_set_attributes
        ) {
          draftStage.additional_info.else_template_set_attributes._destroy =
            true;
        }

        if (
          draftStage.additional_info.else_template_set_attributes
            ?.template_settings_attributes
        ) {
          const draftTemplateSettings =
            draftStage.additional_info.else_template_set_attributes
              .template_settings_attributes;

          const idx = draftTemplateSettings.findIndex(
            ts => ts.template_id === id,
          );

          draftTemplateSettings[idx]._destroy = true;
        }

        // Checking that it doesn't exist in alternate documents
        const isAlternateTemplateAlreadyUsed = Object.values(
          documentSigningRules,
        ).some(rule => {
          const templateSettings =
            rule.template_set_attributes.template_settings_attributes;

          return templateSettings?.some(
            setting => !setting._destroy && setting.template_id === id,
          );
        });

        if (
          draftStage.type === 'DocumentSigningStage' &&
          draftStage.additional_info
            .embedded_signature_templates_stages_attributes &&
          !isAlternateTemplateAlreadyUsed
        ) {
          const draftEmbeddedSignatureTemplatesStages =
            draftStage.additional_info
              .embedded_signature_templates_stages_attributes;

          const idx = draftEmbeddedSignatureTemplatesStages.findIndex(
            ests => ests.embedded_signature_template_id === id,
          );

          if (draftEmbeddedSignatureTemplatesStages[idx]) {
            draftEmbeddedSignatureTemplatesStages[idx]._destroy = true;
          }
        }
      }),
    );
  };

  const handleEditDocument =
    (
      template:
        | DocumentSignatureStagesTemplateSetting
        | DocumentSigningStagesTemplateSetting,
    ) =>
    () => {
      setSelectedTemplateForModal(template);
      openModal();
    };

  const handleCloseDocumentModal = () => {
    setSelectedTemplateForModal(undefined);
    closeModal();
  };

  if (availableTemplates.length === 0 && selectedTemplates.length === 0) {
    return (
      <DocumentsStageSettingCard>
        <NoTemplates />
      </DocumentsStageSettingCard>
    );
  }

  const errors =
    (updateStageResult.isError && updateStageResult.error?.errors) || undefined;

  return (
    <DocumentsStageSettingCard>
      {errors &&
        errors['else_template_set.template_settings.template']?.length > 0 && (
          <Alert fullWidth type="danger" className={styles.errorsAlert}>
            {/* TODO: this needs to be translated! */}
            <span>
              {errors['else_template_set.template_settings.template'].join(
                ', ',
              )}
            </span>
          </Alert>
        )}
      <DefaultDocuments className={styles.marginBottom2} />
      <AddItemRows
        className={styles.addDefaultDocumentsSection}
        emptyContentDescription={intl.formatMessage(messages.noDocuments)}
        isEmpty={selectedTemplates.length === 0}
        addButton={
          <Button
            type="secondary"
            size="small"
            autoWidth
            className={styles.addButton}
            onClick={handleAddDocument}
            disabled={
              availableTemplates.length === 0 && selectedTemplates.length > 0
            }
          >
            <FormattedMessage {...messages.addDocument} />
          </Button>
        }
        errorText={intl.formatMessage(messages.noDocumentsError)}
      >
        <Grid container direction="column">
          {selectedTemplates.map(template => {
            const {
              embedded_signature_template_title:
                templateTitle = intl.formatMessage(
                  messages.templateTitleMissing,
                ),
              template_id: templateId = uuid(),
              document_signature_template_id: documentSignatureTemplateId,
            } = template;
            return (
              <Grid className={styles.row} key={templateId}>
                <ItemRow
                  title={templateTitle}
                  id={documentSignatureTemplateId ?? templateId ?? 0}
                  key={templateId}
                  onDelete={handleDeleteDocument}
                  onEdit={handleEditDocument(template)}
                  confirmDeleteModalTitle={intl.formatMessage(
                    messages.removeDocument,
                  )}
                  confirmDeleteModalButton={intl.formatMessage(messages.remove)}
                  confirmDeleteModalDescription={intl.formatMessage(
                    messages.removeDocumentConfirmationText,
                    {
                      title: templateTitle,
                    },
                  )}
                />
              </Grid>
            );
          })}
        </Grid>
      </AddItemRows>
      <div>
        <AlternateDocuments className={styles.alernateDocuments} />
        <DocumentSigningRules
          stage={stage}
          setStage={setStage}
          setDocumentSigningRules={setDocumentSigningRules}
          documentSigningRules={documentSigningRules}
          errors={errors}
        />
      </div>
      {isModalOpen && (
        <DocumentModal
          onClose={handleCloseDocumentModal}
          availableTemplates={availableTemplates}
          existingTemplate={selectedTemplateForModal}
          embeddedSignatureTemplateStages={maybeEmbeddedSignatureTemplateStages}
          accountUsers={maybeAccountUsers}
          setStage={setStage}
          key={
            selectedTemplateForModal?.embedded_signature_template_title ??
            uuid()
          }
        />
      )}
    </DocumentsStageSettingCard>
  );
};
