import { defineMessages } from 'react-intl';

export const scope = 'app.components.globalNav.helpMenu';

export default defineMessages({
  helpCenter: {
    id: `${scope}.helpCenter`,
    defaultMessage: 'Help Center',
  },
  contactSupport: {
    id: `${scope}.contactSupport`,
    defaultMessage: 'Contact Support',
  },
});
