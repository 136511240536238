import { Grid, Hidden, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import GlobalNav from 'components/GlobalNav';

import { messages } from './messages';
import { WorkFlowBuilder } from './WorkFlowBuilder';

const useStyles = makeStyles({
  nav: {
    width: '100%',
  },
  container: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
});

export const AIWorkflowBuilder: FC = () => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage(messages.title)}</title>
        <meta name="description" content="Messenger" />
      </Helmet>

      <Hidden smDown>
        <Grid className={classes.nav}>
          <GlobalNav />
        </Grid>
      </Hidden>
      <Grid className={classes.container}>
        <WorkFlowBuilder />
      </Grid>
    </>
  );
};
