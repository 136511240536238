/**
 *
 * DesktopMessagePreview
 *
 */

import { Grid, withStyles } from '@material-ui/core';
import Autolinker from 'autolinker';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { classNames } from 'react-extras';
import Frame from 'react-frame-component';
import { FormattedMessage, useIntl } from 'react-intl';

import globalMessages from 'shared/global/messages';

import FrameHead from './FrameHead';
import styles from './styles';

function DesktopMessagePreview({
  classes,
  fromEmail,
  toEmail,
  messageSubject,
  messageText,
}) {
  const intl = useIntl();
  const messageTextModified = messageText.replace(/<a/g, '<a target=_blank');
  const linkedMessageTextModified = Autolinker.link(messageTextModified);

  return (
    <Grid
      container
      className={classes.desktopBrowserContainer}
      alignItems="center"
      justify="center"
    >
      <Grid className={classes.desktopBrowserScreen}>
        <Grid container className={classes.browserTabsSection}>
          <div className={classes.browserTabsButtonsContainer}>
            <div className={classes.browserTabsButton} />
            <div className={classes.browserTabsButton} />
            <div className={classes.browserTabsButton} />
          </div>
          <div className={classes.browserTab} />
        </Grid>
        <Grid
          // container
          className={classes.messageContent}
        >
          <Grid className={classes.messageHeader}>
            <Grid className={classes.messageSubject} title={messageSubject}>
              {messageSubject || (
                <span className={classes.placeholder}>
                  [{intl.formatMessage(globalMessages.emailSubject)}]
                </span>
              )}
            </Grid>
            {fromEmail && (
              <div className={classes.fromEmail}>
                <FormattedMessage {...globalMessages.from} />
                :&nbsp;
                <span title={fromEmail} className={classes.emailAddress}>
                  {fromEmail}
                </span>
              </div>
            )}
            <div className={classes.toEmail}>
              <FormattedMessage {...globalMessages.to} />
              :&nbsp;
              {toEmail && (
                <span title={toEmail} className={classes.emailAddress}>
                  {toEmail}
                </span>
              )}
            </div>
          </Grid>
          <div className={classes.messageBody}>
            <div className={classes.messageTextPreview}>
              {messageText ? (
                <Frame head={<FrameHead />}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: linkedMessageTextModified,
                    }}
                  />
                </Frame>
              ) : (
                <div
                  className={classNames(
                    classes.placeholder,
                    classes.textPlaceholder,
                  )}
                >
                  [{intl.formatMessage(globalMessages.message)}]
                </div>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

DesktopMessagePreview.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object,
  fromEmail: PropTypes.string,
  toEmail: PropTypes.string,
  messageSubject: PropTypes.string,
  messageText: PropTypes.string,
};

export default withStyles(styles)(memo(DesktopMessagePreview));
