import { CustomTooltip } from '@fountain/fountain-ui-components';
import { Grid, IconButton } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React, { FC, useContext } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addMessageAction } from 'containers/FlashMessage/actions';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';
import CopyIcon from 'images/applicant_detail/copy';

import messages from './messages';
import { useStyles } from './styles';

export type StageDetailFooterProps = {
  externalId: string;
};

export const StageDetailFooter: FC<StageDetailFooterProps> = ({
  externalId,
}) => {
  const intl = useIntl();
  const styles = useStyles();
  const dispatch = useDispatch();

  const { stage } = useContext(StageContext);
  const isRule = stage.type === 'RuleStage';
  const modifiedBy =
    (stage.type === 'RuleStage' && stage.additional_info.modified_by) ?? '';
  const modifiedAt =
    (stage.type === 'RuleStage' && stage.additional_info.modified_at) ?? '';

  const onClickCopy = () => {
    void window.navigator.clipboard.writeText(externalId);
    dispatch(
      addMessageAction(intl.formatMessage(messages.copySuccess), 'success'),
    );
  };

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={styles.rootContainer}
    >
      <Typography variant="overline">
        {isRule ? (
          <FormattedMessage
            {...messages.ruleIdDisplay}
            values={{ externalId }}
          />
        ) : (
          <FormattedMessage
            {...messages.stageIdDisplay}
            values={{ externalId }}
          />
        )}
      </Typography>
      <CustomTooltip dense title={intl.formatMessage(messages.copy)}>
        <IconButton
          aria-label={intl.formatMessage(messages.copyIdToClipboardAriaLabel)}
          onClick={onClickCopy}
        >
          <CopyIcon />
        </IconButton>
      </CustomTooltip>

      {isRule && !!modifiedBy && !!modifiedAt && (
        <Grid>
          <FormattedMessage
            {...messages.lastEditedInfo}
            values={{ modifiedBy }}
          />
          {', '}
          <FormattedDate value={new Date(modifiedAt)} dateStyle="short" />
        </Grid>
      )}
    </Grid>
  );
};
