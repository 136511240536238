import { ReviewStageDataFieldTypeEnum } from 'api-clients/monolith/models/WorkflowReviewStage';

import { DataField, TextDataField } from 'components/FormBuilder/schemas';

import { GROUPED_QUESTION_OPTIONS, PAY_RATE_KEY } from './constants';

export const createDefaultCommonField = (
  position: number,
): Omit<DataField, 'type'> => ({
  question: '',
  hint: '',
  key: '',
  position,
  required: true,
});

export const createDefaultTextField = (position: number): TextDataField => ({
  type: ReviewStageDataFieldTypeEnum.textField,
  ...createDefaultCommonField(position),
});

export const createDefaultPayRateField = (position: number): TextDataField => ({
  type: ReviewStageDataFieldTypeEnum.textField,
  ...createDefaultCommonField(position),
  key: PAY_RATE_KEY,
  hiring_manager_standard_question: true,
});

export const getGroupedQuestionOptions = ({
  questionnaireHasHiringManagerStandardQuestion,
}: {
  questionnaireHasHiringManagerStandardQuestion: boolean;
}) =>
  // we can filter here bc there is only 1 custom question .. when we have more we'll
  // need to map then filter the options under tha custom label
  questionnaireHasHiringManagerStandardQuestion
    ? GROUPED_QUESTION_OPTIONS.filter(({ label }) => label !== 'custom')
    : GROUPED_QUESTION_OPTIONS;
