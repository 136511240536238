import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  accurateStage: {
    id: 'app.containers.WorkflowEditor.components.StageColumn.StageCard.accurateStage',
    defaultMessage: 'Accurate package: {packageName}',
  },
  applicants: {
    id: 'app.containers.WorkflowEditor.components.StageColumn.StageCard.applicants',
    defaultMessage: 'Applicants',
  },
  backgroundCheckStage: {
    id: 'app.containers.WorkflowEditor.components.StageColumn.StageCard.backgroundCheckrStage',
    defaultMessage: `Checkr package: {packageName}`,
  },
  backgroundCheckStageUnconfigured: {
    id: 'app.containers.WorkflowEditor.components.StageColumn.StageCard.backgroundCheckStageUnconfigured',
    defaultMessage: `Set background check provider!`,
  },
  calendar: {
    id: 'app.containers.WorkflowEditor.components.StageColumn.StageCard.calendar',
    defaultMessage: 'Calendar',
  },
  cancel: {
    id: 'app.containers.WorkflowEditor.components.StageColumn.StageCard.cancel',
    defaultMessage: 'Cancel',
  },
  delete: {
    id: 'app.containers.WorkflowEditor.components.StageColumn.StageCard.delete',
    defaultMessage: 'Delete',
  },
  deleteStage: {
    id: 'app.containers.WorkflowEditor.components.StageColumn.StageCard.deleteStage',
    defaultMessage: 'Delete Stage',
  },
  deleteStageConfirmationText: {
    id: 'app.containers.WorkflowEditor.components.StageColumn.StageCard.deleteStageConfirmationText',
    defaultMessage: `Stage will be permanently deleted and can't be recovered. Are you sure you want to delete stage "{title}"?`,
  },
  deleteSuccess: {
    id: 'app.containers.WorkflowEditor.components.StageColumn.StageCard.deleteSuccess',
    defaultMessage: 'Stage deleted',
  },
  drag: {
    id: 'app.containers.WorkflowEditor.components.StageColumn.StageCard.drag',
    defaultMessage: 'Drag',
  },
  dragToChange: {
    id: 'app.containers.WorkflowEditor.components.StageColumn.StageCard.dragToChange',
    defaultMessage: 'Drag to change the order of stages',
  },
  documentSignatureStage: {
    id: 'app.containers.WorkflowEditor.components.StageColumn.StageCard.documentSignatureStage',
    defaultMessage: `Signature provider: {signatureProvider}`,
  },
  documentSignatureStageUnconfigured: {
    id: 'app.containers.WorkflowEditor.components.StageColumn.StageCard.documentSignatureStageUnconfigured',
    defaultMessage: `Set signature provider!`,
  },
  learningProvider: {
    id: 'app.containers.WorkflowEditor.components.StageColumn.StageCard.learningProvider',
    defaultMessage: `Learning provider: {learningProvider}`,
  },
  movingToAfterDays: {
    id: 'app.containers.WorkflowEditor.components.StageColumn.StageCard.movingToAfterDays',
    defaultMessage: 'Moving to {stageTitle} after {numberOfDays} days idle',
  },
  na: {
    id: 'app.containers.WorkflowEditor.components.StageColumn.StageCard.na',
    defaultMessage: '(N/A)',
  },
  rule: {
    id: 'app.containers.WorkflowEditor.components.StageColumn.StageCard.rule',
    defaultMessage: 'Rule',
  },
  setupIntegration: {
    id: 'app.containers.WorkflowEditor.components.StageColumn.StageCard.setupIntegration',
    defaultMessage: `Setup {integration} integration!`,
  },
  stageCard: {
    id: 'app.containers.WorkflowEditor.components.StageColumn.StageCard.stageCard',
    defaultMessage: 'Stage Card',
  },
});
