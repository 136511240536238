import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  recipientsCard: {
    border: theme.customBorders.gray300,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1.5, 2),
    display: 'inline-block',
    minWidth: 250,
  },
  applicantIcon: {
    marginRight: theme.spacing(0.75),
    '& > path': {
      fill: theme.palette.common.gray600,
    },
  },
  applicantsCountContainer: {
    color: theme.palette.common.gray600,
    marginTop: theme.spacing(0.75),
  },
}));

export default useStyles;
