import invariant from 'invariant';
import React, { useContext, VFC } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { makeSelectWhoami } from 'containers/Auth_old/selectors';

import { eventManagerContext } from './context';
import { Events } from './Events';
import { NoEvents } from './Events/NoEvents';
import { mapEvent } from './mapEvent';
import { messages } from './messages';

function findObject<T>(
  arr: (T | T[])[],
  predicate: (value: T) => boolean,
): T | undefined {
  return arr.reduce<T | undefined>((foundObject, element) => {
    if (!foundObject) {
      if (Array.isArray(element)) {
        return findObject(element, predicate);
      }
      if (predicate(element)) {
        return element;
      }
    }
    return foundObject;
  }, undefined);
}

export interface EventsContainerProps {
  setDeleteEventModalOpen: () => void;
  setAddEventModalOpen: () => void;
  initialLoad: boolean;
}

export const EventsContainer: VFC<EventsContainerProps> = ({
  setDeleteEventModalOpen,
  setAddEventModalOpen,
  initialLoad,
}) => {
  const intl = useIntl();
  const { setEventModalVariant, pages, setSelectedEvent, canManage } =
    useContext(eventManagerContext);

  const duplicateEvent = (externalId: string) => {
    const event = findObject(pages, event => event.external_id === externalId);
    invariant(event, 'event must exist');
    setSelectedEvent(event);
    setEventModalVariant('duplicate');
    setAddEventModalOpen();
  };
  const deleteEvent = (externalId: string) => {
    const event = findObject(pages, event => event.external_id === externalId);
    invariant(event, 'event must exist');
    setSelectedEvent(event);
    setDeleteEventModalOpen();
  };

  const { locale } = useSelector(makeSelectWhoami());
  const noEvents = initialLoad && (pages.length === 0 || pages[0].length === 0);

  invariant(locale, 'locale is not defined');

  return (
    <>
      {noEvents ? (
        <NoEvents />
      ) : (
        pages.map(page => (
          <Events
            duplicateEvent={duplicateEvent}
            deleteEvent={deleteEvent}
            canManage={canManage}
            events={page.map(event =>
              mapEvent({
                event,
                locale,
                defaultHost: intl.formatMessage(messages.noHostAssigned),
              }),
            )}
            key={`${page[0].external_id}_page`}
          />
        ))
      )}
    </>
  );
};
