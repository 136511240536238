import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { addMessageAction } from 'containers/FlashMessage/actions';

import useUserPermissions from './useUserPermissions';

export default function usePostToJobBoardsPermissionRedirect(
  job,
  accountSlug,
  sourcingPurchaseType = 'standard',
) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { postToJobBoardsPermission } = useUserPermissions();

  useEffect(() => {
    if (!job) return;

    const {
      brand_slug: brandSlug,
      location_external_id: locationExternalId,
      id: externalId,
    } = job;

    const eligible = getEligibility(job, sourcingPurchaseType);

    if (!postToJobBoardsPermission || !eligible) {
      const redirectToUrl = `/${accountSlug}/brands/${brandSlug}/locations/${locationExternalId}/jobs/${externalId}/v2/stages`;
      history.push(redirectToUrl);
    }

    if (!postToJobBoardsPermission) {
      dispatch(
        addMessageAction('You are not authorized to access this page', 'error'),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job]);
}

function getEligibility(job, sourcingPurchaseType) {
  if (sourcingPurchaseType === 'campaign') {
    return job.indeed_campaign_eligible;
  }
  return job.can_be_boosted;
}
