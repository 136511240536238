import { Button } from '@fountain/fountain-ui-components';
import { FormControlLabel, Grid, Switch, Typography } from '@material-ui/core';
import {
  AutomatedResponsesService,
  CancelablePromise,
  UpdateFaqBotFlagParams,
} from 'api-clients/monolith';
import React, { Dispatch, SetStateAction, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { updateIsFaqBotEnabled } from 'containers/Auth_old/actions';
import {
  makeSelectAccountId,
  makeSelectFeatureChatbotAdminEnabled,
  makeSelectIsAccountFaqBotEnabled,
} from 'containers/Auth_old/selectors';
import { addMessageAction } from 'containers/FlashMessage/actions';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';

import { messages } from './messages';
import { useStyles } from './styles';

export interface ActionsProps {
  setIsOpenForm: Dispatch<SetStateAction<boolean>>;
  isActiveModel: boolean;
}

export const Actions: VFC<ActionsProps> = ({
  setIsOpenForm,
  isActiveModel,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const accountId = useSelector(makeSelectAccountId());

  const isFeatureChatbotAdminEnabled = useSelector(
    makeSelectFeatureChatbotAdminEnabled(),
  );
  const isAccountFaqBotEnabled = useSelector(
    makeSelectIsAccountFaqBotEnabled(),
  );

  // Post enable FAQ bot
  const { mutation: toggleFaqBot } = useApiServiceMutation<
    void,
    (id: string, requestBody: UpdateFaqBotFlagParams) => CancelablePromise<void>
  >(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    AutomatedResponsesService.patchInternalApiChatbotAutomatedResponsesUpdateFaqBot,
    {
      onSuccess: () => {
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.enableFaqBotSuccess),
            'success',
          ),
        );
        setIsOpenForm(false);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        dispatch(updateIsFaqBotEnabled());
      },
      onError: () => {
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.enableFaqBotError),
            'error',
            false,
          ),
        );
      },
    },
  );

  const handleFaqBotToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    toggleFaqBot(accountId, {
      account: {
        faq_bot_enabled_for_sms: event.target.checked,
      },
    });
  };

  const handleAddAutomatedResponse = () => {
    setIsOpenForm(true);
  };

  return (
    <Grid
      item
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={styles.actionPanel}
    >
      <Grid item>
        {isFeatureChatbotAdminEnabled && isActiveModel && (
          <Button
            type="primary"
            size="small"
            className={styles.addAutomatedResponseBtn}
            onClick={handleAddAutomatedResponse}
          >
            <FormattedMessage {...messages.addAutomatedResponse} />
          </Button>
        )}
      </Grid>

      <FormControlLabel
        control={
          <Switch
            defaultChecked={isAccountFaqBotEnabled}
            onChange={handleFaqBotToggle}
          />
        }
        label={
          <Typography variant="caption" className={styles.enableFaqBotLabel}>
            <FormattedMessage {...messages.enableFaqBot} />
          </Typography>
        }
      />
    </Grid>
  );
};
