import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import {
  addDefaultErrorMessageActionI18n,
  addMessageAction,
} from 'containers/FlashMessage/actions';
import globalMessages from 'shared/global/messages';
import { apiPost } from 'utils/axios';

export default function useBulkUploadCsv() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const bulkUploadCsv = async (
    file: Blob,
    modelName: string,
    modelType: string,
    mode: string,
    confidenceThreshold: number,
    // eslint-disable-next-line consistent-return
  ) => {
    setIsUploading(true);
    const url = `${REACT_APP_MONOLITH_BASE_URL}/internal_api/chatbot/automated_responses/bulk_upload_csv`;
    try {
      const finalBody = new FormData();
      finalBody.append('automated_response[file]', file);
      finalBody.append('automated_response[name]', modelName);
      finalBody.append('automated_response[type]', modelType);
      finalBody.append('automated_response[mode]', mode);
      finalBody.append(
        'automated_response[confidence_threshold]',
        String(confidenceThreshold), // typecasting is required here for appending the value to form data.
      );
      await apiPost(url, finalBody);
      dispatch(
        addMessageAction(
          intl.formatMessage(globalMessages.yourImportHasStarted),
          'success',
        ),
      );
      setError(null);
    } catch (e) {
      setError(e as Error);
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    if (error) {
      dispatch(addDefaultErrorMessageActionI18n(intl));
    }
  }, [dispatch, error, intl]);

  return { error, bulkUploadCsv, isUploading };
}
