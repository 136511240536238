import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  addTier: {
    marginLeft: theme.spacing(1),
  },
  addTierContainer: {
    marginBottom: theme.spacing(1),
  },
  plusButton: {
    fontSize: theme.typography.overline.fontSize,
  },
  requirementContainer: {
    marginBottom: theme.spacing(3),

    '&:last-child': {
      marginBottom: theme.spacing(1),
    },
  },
}));
