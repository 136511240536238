import { defineMessages } from 'react-intl';

export default defineMessages({
  addAnotherOpening: {
    id: `app.containers.WorkflowEditor.components.DistributeApplicantsRuleStage.addAnotherOpening`,
    defaultMessage: 'Add another opening',
  },
  distributeApplicantsRuleStageDescription: {
    id: `app.containers.WorkflowEditor.components.DistributeApplicantsRuleStage.distributeApplicantsRuleStageDescription`,
    defaultMessage:
      'With Distribute Applicants rules, a pre-set percentage of incoming applicants can be moved across selected openings. E.g. if 8 applicants land on this rule over the course of a day, and 4 openings are pre-set to receive 25% each, 2 applicants will have been moved to each of 4 openings.',
  },
  emptyFieldsValidation: {
    id: `app.containers.WorkflowEditor.components.DistributeApplicantsRuleStage.emptyFieldsValidation`,
    defaultMessage: 'Please populate or remove empty fields before saving.',
  },
  maxRowsReached: {
    id: `app.containers.WorkflowEditor.components.DistributeApplicantsRuleStage.maxRowsReached`,
    defaultMessage: 'Maximum number of 20 rows reached',
  },
  percentageDeductMessage: {
    id: `app.containers.WorkflowEditor.components.DistributeApplicantsRuleStage.percentageDeductMessage`,
    defaultMessage:
      'Please deduct {percentageDifference}% to ensure total sums to 100%',
  },
  percentageSumMessage: {
    id: `app.containers.WorkflowEditor.components.DistributeApplicantsRuleStage.percentageSumMessage`,
    defaultMessage:
      'Please add {percentageDifference}% to ensure total sums to 100%',
  },
  pleaseProvideValidSelection: {
    id: `app.containers.WorkflowEditor.components.DistributeApplicantsRuleStage.pleaseProvideValidSelection`,
    defaultMessage: 'Please provide a valid selection for all required fields',
  },
  save: {
    id: `app.containers.WorkflowEditor.components.DistributeApplicantsRuleStage.save`,
    defaultMessage: 'Save',
  },
});
