import { FormControlLabel, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import React, { VFC } from 'react';

import { SettingTitle } from 'containers/Chatbot/ChatbotWidget/Components/SettingTitle';

import { HandleSettingChange, Message, SettingKey } from '../types';

const useStyles = makeStyles(theme => ({
  setting: {
    margin: theme.spacing(2, 0, 0, 0),
  },
  switch: {
    marginRight: theme.spacing(1),
  },
}));

export interface SettingSwitchProps {
  settingKey: SettingKey;
  label: Message;
  checked: boolean;
  onChange: HandleSettingChange;
  className?: string;
  disabled?: boolean;
}

export const SettingSwitch: VFC<SettingSwitchProps> = ({
  settingKey,
  label,
  checked,
  onChange,
  className,
  disabled,
}) => {
  const styles = useStyles();
  return (
    <FormControlLabel
      className={classnames(styles.setting, className)}
      control={
        <Switch
          checked={checked}
          onChange={event => onChange(settingKey, event.target.checked)}
          className={styles.switch}
          disabled={disabled}
        />
      }
      label={<SettingTitle value={label} />}
    />
  );
};
