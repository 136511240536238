import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useCalendarStyles = makeStyles(theme =>
  createStyles({
    alert: {
      backgroundColor: theme.palette.common.red100,
      width: '100%',
      height: 32,
      padding: theme.spacing(1),
      '& .MuiAlert-message': {
        marginRight: '10px',
        padding: '0px',
      },
    },
    gridContent: {
      display: 'flex',
      flex: 1,
    },
    tabs: {
      padding: theme.spacing(2),
      paddingRight: theme.spacing(4),
      borderRight: 'solid 1px #d6d9e4',
    },
    tabTitle: {
      marginBottom: '15px',
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(1),
      },
    },
    tab: {
      width: '100%',
      marginBottom: '10px',
      color: theme.palette.common.gray600,
      opacity: 0.7,
      '& .MuiButton-label > a': {
        minHeight: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textDecoration: 'none',
        color: 'inherit',
      },
    },
    selectedTab: {
      backgroundColor: theme.palette.common.blue50,
      borderRadius: '5px',
      color: theme.palette.common.blue400,
      opacity: 1,
    },
    contentContainer: {
      overflowY: 'scroll',
      overflowX: 'hidden',
      padding: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
    scrollContent: {
      height: '80vh',
    },
    syncCalendar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);
export const useStyles = makeStyles(theme =>
  createStyles({
    bottomMargin: {
      marginBottom: theme.spacing(1),
    },
    centeredCalendarImage: {
      display: 'block',
      width: '48px',
      height: '48px',
    },
    leftCalendarImage: {
      display: 'block',
      width: '32px',
      height: '32px',
      marginBottom: theme.spacing(3),
    },
    startButton: {
      width: '100%',
      display: 'block',
      marginTop: theme.spacing(1),
    },
    connectButton: {
      minWidth: '115px',
      display: 'block',
      marginTop: theme.spacing(1),
    },
    unconnectedWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      width: '40vw',
      maxWidth: '440px',
    },
    sideboxWrapper: {
      border: 'solid 1px #d6d9e4',
      borderRadius: '5px',
      marginTop: '30px',
      padding: '20px',
      '& .MuiList-root > li': {
        marginLeft: '16px',
        paddingLeft: '5px',
      },
    },
    tabPanel: {
      width: '100%',
      '& iframe': {
        position: 'relative',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: 'calc(100vh - 162px)',
        width: '100%',
      },
    },
    syncWrapper: {
      height: '70vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    connectedWrapper: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
    },
    connectedElements: {
      display: 'flex',
      marginBottom: theme.spacing(4),
    },
    calendarSyncBullets: {
      display: 'list-item',
      listStyleType: 'disc',
      listStylePosition: 'outside',
    },
    calendarSyncBulletContainer: {
      '& > .MuiListItem-gutters': {
        marginLeft: '16px',
        paddingLeft: '5px',
        paddingTop: '0px',
      },
    },
    availabilityTitle: {
      marginBottom: theme.spacing(1),
    },
    availabilityContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: theme.spacing(2),
      gap: theme.spacing(2),
      height: '100%',
    },
    timezoneLabel: {
      color: theme.palette.common.gray600,
      marginTop: '24px',
      marginBottom: theme.spacing(1),
    },
    timezoneDisplay: {
      color: theme.palette.common.gray800,
      marginBottom: theme.spacing(2),
    },
  }),
);
