import { StageType } from 'api-clients/monolith';
import { v4 as uuid } from 'uuid';

import { DUMMY_RULES } from '../Rules.fixtures';

export const SingleRuleStageTypes: StageType[] = [
  'CustomStage',
  'DocumentSignatureStage',
  'DocumentSigningStage',
];

export const SingleConditionStageTypes: StageType[] = ['AssessmentStage'];

export const defaultProps = {
  rule: DUMMY_RULES[0],
  ruleId: uuid(),
  index: 0,
  setRules: () => {},
  onDeleteRule: () => {},
};
