import { ApplicantActionsData } from '@fountain/types/actionMenu';
import { useState } from 'react';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import { apiGet } from 'utils/axios';

export interface UseApplicantActionsOptions {
  applicantId: string;
}

export const useApplicantActions = ({
  applicantId,
}: UseApplicantActionsOptions) => {
  const [data, setData] = useState<ApplicantActionsData | null>(null);
  const [error, setError] = useState<unknown>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      if (!REACT_APP_GLOBAL_API_BASE_URL_V2) {
        throw new Error(
          'Missing "REACT_APP_GLOBAL_API_BASE_URL_V2" env variable',
        );
      }
      const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/action_menu`;
      const res = await apiGet(url);
      setData(res.data as ApplicantActionsData);
    } catch (exception: unknown) {
      setError(exception);
    } finally {
      setIsLoading(false);
    }
  };

  return { data, error, isLoading, fetchData };
};
