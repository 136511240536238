/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AutomatedResponseBulkUploadPayload } from '../models/AutomatedResponseBulkUploadPayload';
import type { AutomatedResponseBulkUploadResponse } from '../models/AutomatedResponseBulkUploadResponse';
import type { AutomatedResponseModel } from '../models/AutomatedResponseModel';
import type { AutomatedResponseModelResponse } from '../models/AutomatedResponseModelResponse';
import type { AutomatedResponseModelUpdatePayload } from '../models/AutomatedResponseModelUpdatePayload';
import type { ChatbotIntentParams } from '../models/ChatbotIntentParams';
import type { ChatbotLogs } from '../models/ChatbotLogs';
import type { ChatbotLogsFilter } from '../models/ChatbotLogsFilter';
import type { ChatbotUpdateFeedbackParams } from '../models/ChatbotUpdateFeedbackParams';
import type { ChatbotWidgetSettingCareerSiteParsingAbility } from '../models/ChatbotWidgetSettingCareerSiteParsingAbility';
import type { ChatbotWidgetSettingCareerSiteTrainingStatus } from '../models/ChatbotWidgetSettingCareerSiteTrainingStatus';
import type { ChatbotWidgetSettingFunnel } from '../models/ChatbotWidgetSettingFunnel';
import type { ChatbotWidgetSettingRefreshKnowledgeBaseResponse } from '../models/ChatbotWidgetSettingRefreshKnowledgeBaseResponse';
import type { ChatbotWidgetSettingResponse } from '../models/ChatbotWidgetSettingResponse';
import type { FaqAnalyticsData } from '../models/FaqAnalyticsData';
import type { FaqbotUpdateFeedbackParams } from '../models/FaqbotUpdateFeedbackParams';
import type { IntentsFilterList } from '../models/IntentsFilterList';
import type { IntentsWithBotReplyResponse } from '../models/IntentsWithBotReplyResponse';
import type { UpdateWidgetSettingParams } from '../models/UpdateWidgetSettingParams';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ChatbotService {

    /**
     * All automated response model
     * @param active filter with active model
     * @returns AutomatedResponseModelResponse Success
     * @throws ApiError
     */
    public static getInternalApiChatbotAutomatedResponseModels(
        active?: boolean,
    ): CancelablePromise<AutomatedResponseModelResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/chatbot/automated_response_models',
            query: {
                'active': active,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }

    /**
     * Updates automated_response_model
     * @param externalId automated response model external id
     * @param requestBody automated response model update params
     * @returns AutomatedResponseModel successful
     * @throws ApiError
     */
    public static patchInternalApiChatbotAutomatedResponseModels(
        externalId: string,
        requestBody?: AutomatedResponseModelUpdatePayload,
    ): CancelablePromise<AutomatedResponseModel> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/internal_api/chatbot/automated_response_models/{external_id}',
            path: {
                'external_id': externalId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `invalid values`,
            },
        });
    }

    /**
     * Automated responses bulk upload csv
     * @param requestBody Automated responses bulk upload
     * @returns AutomatedResponseBulkUploadResponse Success
     * @throws ApiError
     */
    public static postInternalApiChatbotAutomatedResponsesBulkUploadCsv(
        requestBody: AutomatedResponseBulkUploadPayload,
    ): CancelablePromise<AutomatedResponseBulkUploadResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/chatbot/automated_responses/bulk_upload_csv',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
            },
        });
    }

    /**
     * sample csv
     * @returns any Success
     * @throws ApiError
     */
    public static getInternalApiChatbotAutomatedResponsesSample(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/chatbot/automated_responses/sample',
            errors: {
                401: `unauthorized`,
            },
        });
    }

    /**
     * intent with bot reply
     * @param modelName Model Name
     * @returns IntentsWithBotReplyResponse Success
     * @throws ApiError
     */
    public static getInternalApiChatbotAutomatedResponsesGetIntentsWithBotReply(
        modelName: string,
    ): CancelablePromise<IntentsWithBotReplyResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/chatbot/automated_responses/get_intents_with_bot_reply/{model_name}',
            path: {
                'model_name': modelName,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }

    /**
     * Update Chatlog feedback
     * @param faqbotLogId Faqbot Log ID
     * @param requestBody Chatbot logs
     * @returns any successful
     * @throws ApiError
     */
    public static patchInternalApiChatbotAutomatedResponsesUpdateIntent(
        faqbotLogId: string,
        requestBody: FaqbotUpdateFeedbackParams,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/internal_api/chatbot/automated_responses/update_intent/{faqbot_log_id}',
            path: {
                'faqbot_log_id': faqbotLogId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `unprocessable`,
            },
        });
    }

    /**
     * list chatbot logs
     * Returns a list of chatbot logs associated to an account
     * @param requestBody Filter params
     * @returns any successful
     * @throws ApiError
     */
    public static postInternalApiChatbotChatbotLogsChatbotLogs(
        requestBody: ChatbotLogsFilter,
    ): CancelablePromise<{
        chatbot_logs: Array<ChatbotLogs>;
        funnel_titles_by_id: Record<string, string>;
        user_titles_by_id: Record<string, string>;
        messages_by_id?: Record<string, string>;
        count: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/chatbot/chatbot_logs/chatbot_logs',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
            },
        });
    }

    /**
     * Update Chatlog feedback
     * @param chatbotLogId Chatbot Log ID
     * @param requestBody Chatbot logs
     * @returns any successful
     * @throws ApiError
     */
    public static patchInternalApiChatbotChatbotLogs(
        chatbotLogId: string,
        requestBody: ChatbotUpdateFeedbackParams,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/internal_api/chatbot/chatbot_logs/{chatbot_log_id}',
            path: {
                'chatbot_log_id': chatbotLogId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `unprocessable`,
            },
        });
    }

    /**
     * List Intents
     * Returns a list of intents associated to the models
     * @param requestBody Automated Response Models
     * @returns IntentsFilterList successful
     * @throws ApiError
     */
    public static postInternalApiChatbotChatbotLogsIntents(
        requestBody: ChatbotIntentParams,
    ): CancelablePromise<IntentsFilterList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/chatbot/chatbot_logs/intents',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
            },
        });
    }

    /**
     * dashboard data
     * Returns FAQ Analytics
     * @returns FaqAnalyticsData successful
     * @throws ApiError
     */
    public static getInternalApiChatbotChatbotWidgetDashboard(): CancelablePromise<FaqAnalyticsData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/chatbot/chatbot_widget/dashboard',
            errors: {
                401: `unauthorized`,
            },
        });
    }

    /**
     * send_message
     * Sends a slack message that Fountain AI is requested.
     * @returns any successful
     * @throws ApiError
     */
    public static postInternalApiChatbotContactSales(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/chatbot/contact_sales',
        });
    }

    /**
     * Returns chatbot widget setting details
     * @param brandId Brand Id
     * @param isAllBrands Is All Brand Selected
     * @returns ChatbotWidgetSettingResponse successful
     * @throws ApiError
     */
    public static getInternalApiChatbotSettings(
        brandId: string,
        isAllBrands: boolean,
    ): CancelablePromise<ChatbotWidgetSettingResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/chatbot/settings',
            query: {
                'brand_id': brandId,
                'is_all_brands': isAllBrands,
            },
        });
    }

    /**
     * Updates chatbot widget settings
     * @param brandId Brand external_id
     * @param isAllBrands Is All Brand Selected
     * @param requestBody Updates widget settings
     * @returns ChatbotWidgetSettingResponse successful
     * @throws ApiError
     */
    public static putInternalApiChatbotSettings(
        brandId: string,
        isAllBrands: boolean,
        requestBody: UpdateWidgetSettingParams,
    ): CancelablePromise<ChatbotWidgetSettingResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/internal_api/chatbot/settings/{brand_id}',
            path: {
                'brand_id': brandId,
            },
            query: {
                'is_all_brands': isAllBrands,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Returns chatbot widget funnels matching the query
     * @param brandId Brand external_id
     * @param isAllBrands Is All Brand Selected
     * @param query Sample brand
     * @returns ChatbotWidgetSettingFunnel successful
     * @throws ApiError
     */
    public static getInternalApiChatbotSettingsSearchFunnels(
        brandId: string,
        isAllBrands: boolean,
        query: string,
    ): CancelablePromise<Array<ChatbotWidgetSettingFunnel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/chatbot/settings/{brand_id}/search_funnels',
            path: {
                'brand_id': brandId,
            },
            query: {
                'is_all_brands': isAllBrands,
                'query': query,
            },
        });
    }

    /**
     * Returns knowledge_base status and widget_setting_id
     * Returns latest faq_knowledge_base_status after refresh
     * @param brandId Brand external_id
     * @param isAllBrands Is All Brand Selected
     * @returns ChatbotWidgetSettingRefreshKnowledgeBaseResponse successful
     * @throws ApiError
     */
    public static postInternalApiChatbotSettingsRefreshKnowledgeBaseStatus(
        brandId: string,
        isAllBrands: boolean,
    ): CancelablePromise<ChatbotWidgetSettingRefreshKnowledgeBaseResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/chatbot/settings/{brand_id}/refresh_knowledge_base_status',
            path: {
                'brand_id': brandId,
            },
            query: {
                'is_all_brands': isAllBrands,
            },
        });
    }

    /**
     * Returns check_career_site_parsing_ability status and widget_setting_id
     * Returns latest career_site_parsing_ability
     * @param brandId Brand external_id
     * @param isAllBrands Is All Brand Selected
     * @param careerSiteUrl Career site url
     * @returns ChatbotWidgetSettingCareerSiteParsingAbility successful
     * @throws ApiError
     */
    public static postInternalApiChatbotSettingsCheckCareerSiteParsingAbility(
        brandId: string,
        isAllBrands: boolean,
        careerSiteUrl: string,
    ): CancelablePromise<ChatbotWidgetSettingCareerSiteParsingAbility> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/chatbot/settings/{brand_id}/check_career_site_parsing_ability',
            path: {
                'brand_id': brandId,
            },
            query: {
                'is_all_brands': isAllBrands,
                'career_site_url': careerSiteUrl,
            },
        });
    }

    /**
     * Returns refresh_career_site_scraping_status status and widget_setting_id
     * Returns latest refresh_career_site_scraping_status on refresh
     * @param brandId Brand external_id
     * @param isAllBrands Is All Brand Selected
     * @returns ChatbotWidgetSettingCareerSiteTrainingStatus successful
     * @throws ApiError
     */
    public static postInternalApiChatbotSettingsRefreshCareerSiteScrapingStatus(
        brandId: string,
        isAllBrands: boolean,
    ): CancelablePromise<ChatbotWidgetSettingCareerSiteTrainingStatus> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/chatbot/settings/{brand_id}/refresh_career_site_scraping_status',
            path: {
                'brand_id': brandId,
            },
            query: {
                'is_all_brands': isAllBrands,
            },
        });
    }

}