import { makeStyles } from '@material-ui/core/styles';
/**
 *
 * Material UI styles for ApplicantAddress
 *
 *
 * @see https://material-ui.com/styles/basics
 *
 */

const useStyles = makeStyles(theme => ({
  p: {
    marginTop: 0,
    marginBottom: 0,
  },
  input: {
    marginTop: theme.spacing(2),
  },
  label: {
    lineHeight: theme.typography.body2.lineHeight,
    fontSize: theme.typography.body2.fontSize,
    fontFamily: theme.typography.body2.fontFamily,
    marginBottom: theme.spacing(0.5),
  },
}));

export default useStyles;
