export const OPEN_BULK_ACTION_QUEUE_MENU =
  'app/BulkActionQueue/OPEN_BULK_ACTION_QUEUE_MENU';
export const CLOSE_BULK_ACTION_QUEUE_MENU =
  'app/BulkActionQueue/CLOSE_BULK_ACTION_QUEUE_MENU';

export const BULK_ACTION_STATUS = {
  created: 'created',
  pending: 'pending',
  running: 'running',
  paused: 'paused',
  complete: 'complete',
  deleted: 'deleted',
  errored: 'errored',
};

export const BULK_MESSAGE_TYPE = {
  email: 'emails',
  sms: 'SMS',
};

export const BULK_TYPE = {
  BulkMove: 'BulkMove',
  BulkReject: 'BulkReject',
  BulkDestroy: 'BulkDestroy',
  BulkAssign: 'BulkAssign',
  BulkMessage: 'BulkMessage',
};

export const BULK_ACTION_TEXT = {
  [BULK_TYPE.BulkMove]: 'applicants moved to',
  [BULK_TYPE.BulkReject]: 'applicants rejected',
  [BULK_TYPE.BulkDestroy]: 'applicants deleted',
  [BULK_TYPE.BulkAssign]: 'applicants assigned to',
};
