import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  cardBody: {
    padding: theme.spacing(0, 2),
  },
  packageSelect: {
    marginLeft: theme.spacing(-1.5),
    marginBottom: theme.spacing(3),
  },
}));
