import { Reason } from 'api-clients/monolith';

import { mockReasonSetting } from 'containers/WorkflowEditor/components/WorkflowEditorContent/WorkflowEditorContent.fixtures';

import { SCHEDULER } from '../../constants';
import { MessageRowProps } from './MessageRow';

export const defaultProps: MessageRowProps = {
  friendlyMessageType: 'Example message',
  messageType: SCHEDULER,
  messageRow: mockReasonSetting,
  setStage: () => {},
  reasons: [],
};

export const defaultReason: Reason = {
  content: 'Other Reason', // might need to be translated in future
  id: null,
};
