import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  errorsAlert: {
    marginBottom: theme.spacing(3),
    '& > div': {
      margin: 'initial', // Have icon & text left aligned as per design
    },
  },
  stageContainer: {
    padding: theme.spacing(3),
    flex: 1,
    overflowY: 'scroll',
  },
}));
