import { defineMessages } from 'react-intl';

export const scope = 'app.components.DataValidationModal';

export default defineMessages({
  modalTitle: {
    id: `${scope}.modalTitle`,
    defaultMessage: 'Validate Data',
  },
  confirm: {
    id: `${scope}.confirm`,
    defaultMessage: 'Confirm & Move',
  },
  dataValidationInstructions: {
    id: `${scope}.dataValidationInstructions`,
    defaultMessage:
      'Validate the following fields or add additional information:',
  },
  requiredField: {
    id: `${scope}.requiredField`,
    defaultMessage: 'Required Field',
  },
  manageDataValidations: {
    id: `${scope}.manageDataValidations`,
    defaultMessage: 'Manage Data Validations',
  },
  discardChanges: {
    id: `${scope}.discardChanges`,
    defaultMessage: 'Discard Changes',
  },
  keepEditing: {
    id: `${scope}.keepEditing`,
    defaultMessage: 'Keep Editing',
  },
  unsavedChangesMessage: {
    id: `${scope}.unsavedChangesMessage`,
    defaultMessage: 'You have unsaved changes. Are you sure you want to exit?',
  },
  unsavedChangesTitle: {
    id: `${scope}.unsavedChanges`,
    defaultMessage: 'Unsaved Changes',
  },
  updateApplicantDataSuccess: {
    id: `${scope}.updateApplicantDataSuccess`,
    defaultMessage: 'Updated applicant data successfully',
  },
});
