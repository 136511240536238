import {
  FETCH_SETUP_INTENT_ERROR,
  FETCH_SETUP_INTENT_INIT,
  FETCH_SETUP_INTENT_SUCCESS,
  RESET_SOURCING_PURCHASE_NEW,
  SET_ADVERTISER_BUDGET,
  SET_BUDGET,
  SET_COUPON,
  SET_DATE,
  SET_DURATION,
  SET_IS_INVOICED,
  SET_JOB,
  SET_PAYMENT_SOURCE,
  SET_POST_TO_FREE_BOARDS,
  SET_POSTING_TITLE,
  SET_PURCHASED,
  SET_SCHEDULED,
  SET_STARTS_AT,
  SET_SUBSCRIBE,
  SET_SUCCESS,
  SET_TIME,
  SET_TIME_ZONE,
  UNSCHEDULE,
} from './constants';

export function resetSourcingPurchaseAction() {
  return {
    type: RESET_SOURCING_PURCHASE_NEW,
  };
}

export function setBudgetAction(budget, accountSlug, countryCode) {
  return {
    type: SET_BUDGET,
    budget,
    accountSlug,
    countryCode,
  };
}

export function setSuccessAction() {
  return {
    type: SET_SUCCESS,
    success: true,
  };
}

export function setSubscribeAction(subscribe) {
  return {
    type: SET_SUBSCRIBE,
    subscribe,
  };
}

export function setCouponAction(coupon, accountSlug, countryCode) {
  return {
    type: SET_COUPON,
    coupon,
    accountSlug,
    countryCode,
  };
}

export function setPurchasedAction(
  advertiser,
  budget,
  purchased,
  accountSlug,
  countryCode,
) {
  return {
    type: SET_PURCHASED,
    budget,
    advertiser,
    purchased,
    accountSlug,
    countryCode,
  };
}

export function setAdvertiserBudgetAction(
  advertiser,
  budget,
  accountSlug,
  countryCode,
) {
  return {
    type: SET_ADVERTISER_BUDGET,
    advertiser,
    budget,
    accountSlug,
    countryCode,
  };
}

export function setJobAction(job) {
  return {
    type: SET_JOB,
    job,
  };
}

export function setDurationAction(duration) {
  return {
    type: SET_DURATION,
    duration,
  };
}

export function setPaymentSourceAction(paymentSource) {
  return {
    type: SET_PAYMENT_SOURCE,
    paymentSource,
  };
}

export function setPostingTitleAction(postingTitle) {
  return {
    type: SET_POSTING_TITLE,
    postingTitle,
  };
}

export function setPostToFreeBoardsAction(postToFreeBoards) {
  return {
    type: SET_POST_TO_FREE_BOARDS,
    postToFreeBoards,
  };
}

export function setStartsAtAction(startsAt) {
  return {
    type: SET_STARTS_AT,
    startsAt,
  };
}

export function setScheduledAction(scheduled) {
  return {
    type: SET_SCHEDULED,
    scheduled,
  };
}

export function setDateAction(date) {
  return {
    type: SET_DATE,
    date,
  };
}

export function setTimeAction(time) {
  return {
    type: SET_TIME,
    time,
  };
}

export function setTimeZoneAction(timeZone) {
  return {
    type: SET_TIME_ZONE,
    timeZone,
  };
}

export function setIsInvoicedAction(isInvoiced) {
  return {
    type: SET_IS_INVOICED,
    isInvoiced,
  };
}

export function unscheduleAction() {
  return {
    type: UNSCHEDULE,
  };
}

export function fetchSetupIntentInitAction(accountSlug, brandSlug) {
  return {
    type: FETCH_SETUP_INTENT_INIT,
    accountSlug,
    brandSlug,
  };
}

export function fetchSetupIntentSuccessAction(setupIntent) {
  return {
    type: FETCH_SETUP_INTENT_SUCCESS,
    setupIntent,
  };
}

export function fetchSetupIntentErrorAction(error) {
  return {
    type: FETCH_SETUP_INTENT_ERROR,
    error,
  };
}
