import { CustomTooltip } from '@fountain/fountain-ui-components';
import { Grid, TableCell, Typography } from '@material-ui/core';
import React, { FC, memo } from 'react';

import {
  PHONE_PLATFORM_TITLES,
  PHONE_PLATFORM_TYPES,
} from 'components/SmsMessage/constants';
import { PHONE_PLATFORM_COLUMN_WIDTH } from 'containers/ApplicantTableV2/constants';
import SmsIcon from 'images/sms-blue-icon.svg';
import WhatsappIcon from 'images/whatsapp-logo.svg';

import useStyles from './styles';

export interface ApplicantTableV2PhonePlatformCellProps {
  phonePlatform: string | null;
}

export const ApplicantTableV2PhonePlatformCell: FC<
  ApplicantTableV2PhonePlatformCellProps
> = ({ phonePlatform }) => {
  const classes = useStyles();

  return (
    <TableCell
      role="cell"
      style={{
        minWidth: PHONE_PLATFORM_COLUMN_WIDTH,
        maxWidth: PHONE_PLATFORM_COLUMN_WIDTH,
      }}
      className={classes.td}
    >
      <Grid container justify="center" className={classes.phonePlatformCell}>
        <Grid>
          <Typography>
            {phonePlatform === PHONE_PLATFORM_TYPES.sms && (
              <CustomTooltip title={PHONE_PLATFORM_TITLES.sms} dense>
                <img src={SmsIcon} alt={PHONE_PLATFORM_TYPES.sms} />
              </CustomTooltip>
            )}
            {phonePlatform === PHONE_PLATFORM_TYPES.whats_app && (
              <CustomTooltip title={PHONE_PLATFORM_TITLES.whats_app} dense>
                <img src={WhatsappIcon} alt={PHONE_PLATFORM_TYPES.whats_app} />
              </CustomTooltip>
            )}
          </Typography>
        </Grid>
      </Grid>
    </TableCell>
  );
};

export default memo(ApplicantTableV2PhonePlatformCell);
