import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import { getConditionErrorMessage } from 'containers/WorkflowEditor/components/Rules/Condition/constants';
import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';

import { DocumentSigningRuleProps } from '../../../DocumentSigningRules/types';
import { RulesProps, SharedConditionProps } from '../../../types';
import { messages } from './messages';

export type NewPartner = {
  label: string;
  value: string;
};

type UpdateConditionPartnerArgs = {
  rules: RulesProps;
  ruleId: SharedConditionProps['ruleId'];
  newPartner: NewPartner;
  condition: SharedConditionProps['condition'];
};

export function updateConditionPartner({
  rules,
  ruleId,
  newPartner,
  condition,
}: UpdateConditionPartnerArgs) {
  return produce(rules, (draftRules: RulesProps) => {
    const draftConditions =
      draftRules[ruleId]?.condition_set_attributes?.conditions_attributes ?? [];

    const idx = draftConditions.findIndex(cond => cond.id === condition.id);

    draftConditions[idx].extra.partner_id = newPartner.value;
  });
}

export const PartnerSelect: FC<SharedConditionProps> = ({
  condition,
  ruleId,
  setRules,
  setDocumentSigningRules,
  errors,
}) => {
  const intl = useIntl();
  const { partner_ids: partners } = useContext(RulesEditDataContext);

  const { extra } = condition;

  const onChangePartner = (newPartner: NewPartner) => {
    const rulesSetter = setRules ?? setDocumentSigningRules;

    if (rulesSetter) {
      rulesSetter(
        produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];

          const idx = draftConditions.findIndex(
            cond => cond.id === condition.id,
          );

          draftConditions[idx].extra.partner_id = newPartner.value;
        }),
      );
    }
  };

  const selectedPartner =
    partners?.find(option => option.value === extra.partner_id) ?? null;

  const partnerIdErrorMessage =
    !extra?.partner_id && getConditionErrorMessage('partner_id', errors);

  return (
    <Grid item xs={6}>
      <StyledReactSelect
        options={partners}
        value={selectedPartner}
        onChange={onChangePartner}
        label={intl.formatMessage(messages.partner)}
        aria-label={intl.formatMessage(messages.partner)}
        isSearchable
        error={partnerIdErrorMessage}
        placeholder=""
        required
      />
    </Grid>
  );
};
