import { defineMessages } from 'react-intl';

export default defineMessages({
  stageIdDisplay: {
    id: 'app.containers.WorkflowEditor.components.StageDetailFooter.stageIdDisplay',
    defaultMessage: 'Stage ID: {externalId}',
  },
  ruleIdDisplay: {
    id: 'app.containers.WorkflowEditor.components.StageDetailFooter.ruleIdDisplay',
    defaultMessage: 'Rule ID: {externalId}',
  },
  copyIdToClipboardAriaLabel: {
    id: 'app.containers.WorkflowEditor.components.StageDetailFooter.copyIdToClipboardAriaLabel',
    defaultMessage: 'Copy ID',
  },
  lastEditedInfo: {
    id: 'app.containers.WorkflowEditor.components.StageDetailFooter.lastEditedInfo',
    defaultMessage: 'Last edited by: {modifiedBy}',
  },
  copy: {
    id: 'app.containers.WorkflowEditor.components.StageDetailFooter.copy',
    defaultMessage: 'Copy',
  },
  copySuccess: {
    id: 'app.containers.WorkflowEditor.components.StageDetailFooter.copySuccess',
    defaultMessage: 'Successfully copied to clipboard',
  },
});
