import {
  Button,
  CustomTooltip,
  IconButton,
  Input,
  StyledReactSelect,
  Trashcan,
} from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import { SidebarStage, WorkflowStageDetail } from 'api-clients/monolith';
import cx from 'classnames';
import { produce } from 'immer';
import React, { useContext, VFC } from 'react';
import { useIntl } from 'react-intl';

import { Error } from 'components/Error';
import {
  StageSettingCard,
  StageSettingCardProps,
} from 'containers/WorkflowEditor/components/StageSettingCard';
import { SettingSwitch } from 'containers/WorkflowEditor/components/WorkflowEditorContent/SettingSwitch';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { StageSettingCardEmptyState } from '../StageSettingCardEmptyState';
import { DEFAULT_MOVE_RULE, REASON_STAGES } from './constants';
import { messages } from './messages';
import { ReasonSelect } from './ReasonSelect';
import { useStyles } from './styles';

export const IdleMoveRule: VFC<{ stage: WorkflowStageDetail }> = ({
  stage,
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const { move_rule: moveRule, external_id: externalId } = stage;

  const { setStage, funnelStages, updateStageResult } =
    useContext(StageContext);

  const stageSettingCardProps: StageSettingCardProps = {
    title: intl.formatMessage(messages.idleMoveRule),
    variant: 'default',
  };

  const handleDeleteIdleMoveRule = () => {
    setStage((draftStage: WorkflowStageDetail) => ({
      ...draftStage,
      move_rule: {
        ...draftStage.move_rule,
        _destroy: true,
      },
    }));
  };

  const onClickSendAutomatedMessage = (e: { target: { checked: boolean } }) => {
    const checked = !!e.target.checked;

    setStage(
      produce((draftStage: WorkflowStageDetail) => {
        if (draftStage.move_rule) {
          draftStage.move_rule.enable_move_to_notifications = checked;
        }
      }),
    );
  };

  const onClickIgnoreApplicantAction = (e: {
    target: { checked: boolean };
  }) => {
    const checked = !!e.target.checked;

    setStage(
      produce((draftStage: WorkflowStageDetail) => {
        if (draftStage.move_rule) {
          draftStage.move_rule.ignore_applicant_action = checked;
        }
      }),
    );
  };

  const onChangeNumberOfDays = (e: { target: { value: string } }) => {
    const count = parseInt(e.target.value, 10) || 0;

    setStage(
      produce((draftStage: WorkflowStageDetail) => {
        if (draftStage.move_rule) {
          draftStage.move_rule.idle_days_count = count;
        }
      }),
    );
  };

  const onAddMoveRule = () => {
    setStage(
      produce((draftStage: WorkflowStageDetail) => {
        draftStage.move_rule = DEFAULT_MOVE_RULE;
      }),
    );
  };

  const onChangeSelectedStage = (stage: SidebarStage) => {
    setStage(
      produce((draftStage: WorkflowStageDetail) => {
        if (draftStage.move_rule) {
          draftStage.move_rule.target_stage_id = stage.id;
          // reset ReasonSelect selection
          draftStage.move_rule.archived_reason_id = null;
          draftStage.move_rule.rejection_reason_id = null;
        }
      }),
    );
  };

  // eslint-disable-next-line no-underscore-dangle
  if (!moveRule || moveRule._destroy) {
    return (
      <StageSettingCardEmptyState
        bodyText={intl.formatMessage(
          messages.automaticallyTransferIdleApplicants,
        )}
        buttonComponent={
          <Button
            type="secondary"
            size="small"
            onClick={onAddMoveRule}
            autoWidth
          >
            <Typography variant="h4" color="primary">
              {intl.formatMessage(messages.addIdleMoveRule)}
            </Typography>
          </Button>
        }
        stageSettingCardProps={stageSettingCardProps}
      />
    );
  }

  const filteredFunnelStages = funnelStages.filter(
    stage => stage.external_id !== externalId,
  );

  const targetStage = filteredFunnelStages.find(
    stage => stage.id === moveRule.target_stage_id,
  );
  const hasIdleDaysError =
    updateStageResult.isError &&
    updateStageResult.error?.errors?.['move_rule.idle_days_count'] !==
      undefined;
  const hasTargetStageError =
    updateStageResult.isError &&
    updateStageResult.error?.errors?.['move_rule.target_stage'] !== undefined;
  const errorMessagePresent = hasIdleDaysError || hasTargetStageError;

  return (
    <StageSettingCard {...stageSettingCardProps}>
      <Typography variant="body2" className={styles.text}>
        {intl.formatMessage(messages.automaticallyTransferIdleApplicants)}
      </Typography>
      <Grid className={styles.container}>
        <Grid className={styles.inputContainer}>
          <Grid
            className={cx({ [styles.inputErrorPresent]: errorMessagePresent })}
          >
            <Input
              value={moveRule?.idle_days_count}
              onChange={onChangeNumberOfDays}
              className={styles.input}
              label={intl.formatMessage(messages.numberOfIdleDays)}
              data-testid="number-of-days-input"
              error={hasIdleDaysError}
            />
            <Error
              error={
                hasIdleDaysError && intl.formatMessage(messages.idleDaysError)
              }
              align="right"
            />
          </Grid>
          <Grid
            className={cx({ [styles.inputErrorPresent]: errorMessagePresent })}
          >
            <StyledReactSelect
              className={styles.select}
              aria-label={intl.formatMessage(messages.targetStageSelector)}
              label={intl.formatMessage(messages.targetStage)}
              options={filteredFunnelStages}
              value={targetStage}
              getOptionLabel={(stage: SidebarStage) => stage.title}
              getOptionValue={(stage: SidebarStage) => stage.id}
              onChange={onChangeSelectedStage}
              error={hasTargetStageError}
              required
            />
            <Error
              error={
                hasTargetStageError &&
                intl.formatMessage(messages.targetStageRequired)
              }
              align="right"
            />
          </Grid>
          {targetStage && REASON_STAGES.includes(targetStage.type) && (
            <Grid
              className={cx({
                [styles.inputErrorPresent]: errorMessagePresent,
              })}
            >
              <ReasonSelect stage={stage} />
            </Grid>
          )}
          <CustomTooltip
            dense
            title={intl.formatMessage(messages.delete)}
            className={cx({
              [styles.iconWithErrorPresent]: errorMessagePresent,
            })}
          >
            <IconButton onClick={handleDeleteIdleMoveRule}>
              <Trashcan
                aria-label={intl.formatMessage(messages.trashcan)}
                className={styles.icon}
                viewBox="0 0 16 16"
              />
            </IconButton>
          </CustomTooltip>
        </Grid>
        <Grid className={styles.checkboxWrapper}>
          <SettingSwitch
            dataKey="enable_move_to_notifications"
            checked={Boolean(moveRule?.enable_move_to_notifications)}
            onChange={onClickSendAutomatedMessage}
            label={intl.formatMessage(messages.sendAutomatedMessages)}
          />
          <SettingSwitch
            dataKey="ignore_applicant_action"
            checked={Boolean(moveRule?.ignore_applicant_action)}
            onChange={onClickIgnoreApplicantAction}
            label={intl.formatMessage(messages.ignoreApplicantAction)}
          />
        </Grid>
      </Grid>
    </StageSettingCard>
  );
};
