import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  statusLabels: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    padding: theme.spacing(1.5, 1),
  },
}));

export default useStyles;
