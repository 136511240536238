import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  searchInput: {
    width: props => (props.fullWidth ? '100%' : 232),
    maxWidth: '100%',
  },
  searchIcon: {
    marginRight: theme.spacing(1.5),
  },
  clearButton: {
    marginRight: theme.spacing(-2),
    backgroundColor: 'transparent',
  },
  inputStyle: {
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(5),
    },
  },
}));

export default useStyles;
