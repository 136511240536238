import { defineMessages } from 'react-intl';

export const scope = 'app.components.globalNav.jobsNavMenu';

export default defineMessages({
  openings: {
    id: `${scope}.openings`,
    defaultMessage: 'Openings',
  },
  bulkLocations: {
    id: `${scope}.bulkLocations`,
    defaultMessage: 'Bulk Locations (Beta)',
  },
  positions: {
    id: `${scope}.positions`,
    defaultMessage: 'Positions',
  },
  jobsDirectory: {
    id: `${scope}.jobsDirectory`,
    defaultMessage: 'Jobs Directory',
  },
  workflows: {
    id: `${scope}.workflows`,
    defaultMessage: 'Workflows',
  },
});
