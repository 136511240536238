import { defineMessages } from 'react-intl';

export default defineMessages({
  approvalModalTitle: {
    id: 'app.containers.OpeningApprovals.Approvals.approvalModalTitle',
    defaultMessage: 'Approve Opening',
  },
  rejectionModalTitle: {
    id: 'app.containers.OpeningApprovals.Approvals.rejectionModalTitle',
    defaultMessage: 'Add Rejection Reason',
  },
  approvalModalSubtitle: {
    id: 'app.containers.OpeningApprovals.Approvals.approveSubtitle',
    defaultMessage: 'Are you sure you want to approve this opening?',
  },
  rejectionModalSubtitle: {
    id: 'app.containers.OpeningApprovals.Approvals.rejectionSubtitle',
    defaultMessage: 'Leave a note for why you are rejecting this opening.',
  },
  approve: {
    id: 'app.containers.OpeningApprovals.Approvals.approve',
    defaultMessage: 'Approve',
  },
  reject: {
    id: 'app.containers.OpeningApprovals.Approvals.reject',
    defaultMessage: 'Reject',
  },
  approveAll: {
    id: 'app.containers.OpeningApprovals.Approvals.approveAll',
    defaultMessage: 'Approve all',
  },
  rejectAll: {
    id: 'app.containers.OpeningApprovals.Approvals.rejectAll',
    defaultMessage: 'Reject all',
  },
  rejectionReason: {
    id: 'app.containers.OpeningApprovals.Approvals.rejectionReason',
    defaultMessage: 'Rejection Reason',
  },
  approverGroup: {
    id: 'app.containers.OpeningApprovals.Approvals.approverGroup',
    defaultMessage: 'Approver Group',
  },
  approvalStatus: {
    id: 'app.containers.OpeningApprovals.Approvals.approvalStatus',
    defaultMessage: 'Approval Status',
  },
  approvalAction: {
    id: 'app.containers.OpeningApprovals.Approvals.approvalAction',
    defaultMessage: 'Approval Action',
  },
  noApprovalsFound: {
    id: 'app.containers.OpeningApprovals.Approvals.noApprovalsFound',
    defaultMessage: 'No Approvals found.',
  },
  selected: {
    id: 'app.containers.OpeningApprovals.Approvals.selected',
    defaultMessage: '{count} selected',
  },
  rejectionPlaceholder: {
    id: 'app.containers.OpeningApprovals.Approvals.rejectionPlaceholder',
    defaultMessage:
      'ex. The pay rate is incorrect. This position’s pay rate is $17.80/hr',
  },
});
