/*
 *
 * LanguageProvider reducer
 *
 */

import produce from 'immer';

import { DEFAULT_LOCALE } from '../../i18n';
import { CHANGE_LOCALE } from './constants';

export interface InitialState {
  locale: string;
}

export const initialState: InitialState = {
  locale: DEFAULT_LOCALE,
};

const languageProviderReducer = (
  state = initialState,
  action: { type: string; locale?: string },
) =>
  produce(state, draft => {
    switch (action.type) {
      case CHANGE_LOCALE:
        draft.locale = action.locale ?? DEFAULT_LOCALE;
        break;
      // no default
    }
  });

export default languageProviderReducer;
