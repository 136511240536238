import React, { FC } from 'react';

import DocumentFilePreview from 'components/Document/components/DocumentFilePreview';
import { EditButton } from 'containers/DataValidationModal/components/FileField/EditButton';
import { FileFieldTitle } from 'containers/DataValidationModal/components/FileField/FileFieldTitle';
import { SharedPreviewProps } from 'containers/DataValidationModal/components/FileField/types';

export type PreviewProps = SharedPreviewProps;

export const Preview: FC<PreviewProps> = ({
  applicantId,
  detail,
  file,
  isRequired,
  applicantBlob,
  isMobile,
  setIsEditable,
  showButtons,
  url,
}) => {
  const editButtonProps = {
    applicantBlob,
    isMobile,
    setIsEditable,
    showButtons,
    url,
  };
  return (
    <>
      <FileFieldTitle
        isRequired={isRequired}
        subtitle={detail.subtitle}
        title={detail.title}
      >
        <EditButton {...editButtonProps} />
      </FileFieldTitle>
      <DocumentFilePreview
        applicantId={applicantId}
        initialImageSrc={url ?? applicantBlob[0].download_url}
        filename={file ? file.name : applicantBlob[0].filename}
        id={file ? file.name : applicantBlob[0].id}
        previewUrl={url ?? applicantBlob[0].url}
      />
    </>
  );
};
