import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { VFC } from 'react';

export const FountainAIIcon: VFC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 64 64">
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" rx="32" fill="#8391F0" />
      <rect
        width="64"
        height="64"
        rx="32"
        fill="url(#paint0_linear_1159_7726)"
        fillOpacity="0.8"
      />
      <rect
        width="64"
        height="64"
        rx="32"
        fill="url(#paint1_radial_1159_7726)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.375 20.75C26.6195 20.7501 26.8573 20.8297 27.0524 20.977C27.2476 21.1242 27.3894 21.331 27.4566 21.566L28.6762 25.8352C28.9388 26.7546 29.4314 27.5919 30.1075 28.268C30.7837 28.9441 31.621 29.4367 32.5404 29.6993L36.8098 30.9189C37.0447 30.9862 37.2513 31.1281 37.3984 31.3232C37.5454 31.5183 37.625 31.7561 37.625 32.0004C37.625 32.2448 37.5454 32.4825 37.3984 32.6776C37.2513 32.8727 37.0447 33.0147 36.8098 33.0819L32.5404 34.3015C31.621 34.5641 30.7837 35.0567 30.1075 35.7328C29.4314 36.4089 28.9388 37.2462 28.6762 38.1656L27.4566 42.4348C27.3893 42.6697 27.2473 42.8763 27.0522 43.0234C26.8571 43.1704 26.6194 43.25 26.375 43.25C26.1306 43.25 25.8929 43.1704 25.6978 43.0234C25.5027 42.8763 25.3607 42.6697 25.2934 42.4348L24.0738 38.1656C23.8112 37.2462 23.3186 36.4089 22.6425 35.7328C21.9663 35.0567 21.129 34.5641 20.2096 34.3015L15.9402 33.0819C15.7053 33.0147 15.4987 32.8727 15.3516 32.6776C15.2046 32.4825 15.125 32.2448 15.125 32.0004C15.125 31.7561 15.2046 31.5183 15.3516 31.3232C15.4987 31.1281 15.7053 30.9862 15.9402 30.9189L20.2096 29.6993C21.129 29.4367 21.9663 28.9441 22.6425 28.268C23.3186 27.5919 23.8112 26.7546 24.0738 25.8352L25.2934 21.566C25.3606 21.331 25.5024 21.1242 25.6976 20.977C25.8927 20.8297 26.1305 20.7501 26.375 20.75Z"
        fill="white"
      />
      <path
        d="M40.1586 37.8373C39.9668 37.6991 39.7364 37.6249 39.5 37.625C39.2639 37.6252 39.0338 37.6996 38.8423 37.8377C38.6508 37.9759 38.5076 38.1707 38.433 38.3947L37.8402 40.1696C37.6166 40.8417 37.0884 41.3668 36.4176 41.5919L34.6423 42.1845C34.4187 42.2596 34.2244 42.4029 34.0867 42.5943C33.9491 42.7857 33.875 43.0155 33.875 43.2513C33.875 43.487 33.9491 43.7168 34.0867 43.9082C34.2244 44.0996 34.4187 44.243 34.6423 44.318L36.4176 44.9107C36.7488 45.0212 37.0498 45.2072 37.2967 45.4541C37.5436 45.7009 37.7297 46.0018 37.8402 46.333L38.433 48.1079C38.5081 48.3314 38.6514 48.5257 38.8429 48.6633C39.0343 48.801 39.2642 48.875 39.5 48.875C39.7358 48.875 39.9657 48.801 40.1571 48.6633C40.3486 48.5257 40.4919 48.3314 40.567 48.1079L41.1598 46.333C41.3834 45.6608 41.9116 45.1357 42.5824 44.9107L44.3577 44.318C44.5813 44.243 44.7756 44.0996 44.9133 43.9082C45.0509 43.7168 45.125 43.487 45.125 43.2513C45.125 43.0155 45.0509 42.7857 44.9133 42.5943C44.7756 42.4029 44.5813 42.2596 44.3577 42.1845L42.5824 41.5919C41.9101 41.3683 41.3849 40.8402 41.1598 40.1696L40.5685 38.3947C40.4938 38.1705 40.3504 37.9754 40.1586 37.8373Z"
        fill="white"
      />
      <path
        d="M42.0482 15.3564C41.8559 15.2063 41.6189 15.1249 41.375 15.125C41.1311 15.1249 40.8941 15.2063 40.7018 15.3564C40.5095 15.5064 40.3729 15.7165 40.3137 15.9532L39.9376 17.4637C39.7694 18.1367 39.4215 18.7513 38.931 19.2419C38.4406 19.7324 37.8261 20.0804 37.1532 20.2486L35.643 20.6248C35.4059 20.6835 35.1953 20.82 35.0448 21.0124C34.8943 21.2047 34.8125 21.442 34.8125 21.6863C34.8125 21.9306 34.8943 22.1678 35.0448 22.3602C35.1953 22.5526 35.4059 22.689 35.643 22.7477L37.1532 23.1239C37.8261 23.2922 38.4406 23.6402 38.931 24.1307C39.4215 24.6212 39.7694 25.2358 39.9376 25.9088L40.3137 27.4194C40.3724 27.6565 40.5089 27.8671 40.7012 28.0177C40.8936 28.1682 41.1308 28.25 41.375 28.25C41.6192 28.25 41.8564 28.1682 42.0488 28.0177C42.2411 27.8671 42.3776 27.6565 42.4363 27.4194L42.8124 25.9088C43.1564 24.5382 44.2264 23.468 45.5968 23.1239L47.107 22.7477C47.3441 22.689 47.5547 22.5526 47.7052 22.3602C47.8557 22.1678 47.9375 21.9306 47.9375 21.6863C47.9375 21.442 47.8557 21.2047 47.7052 21.0124C47.5547 20.82 47.3441 20.6835 47.107 20.6248L45.5968 20.2486C44.2264 19.9045 43.1564 18.8343 42.8124 17.4637L42.4363 15.9532C42.3771 15.7165 42.2405 15.5064 42.0482 15.3564Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1159_7726"
          x1="64"
          y1="54"
          x2="2.58745e-06"
          y2="6.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.110914" stopColor="#7D6AE2" />
          <stop offset="0.848457" stopColor="#F0EEFF" stopOpacity="0.24" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_1159_7726"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(48.5 4) rotate(120.881) scale(59.4243)"
        >
          <stop offset="0.213723" stopColor="#B382F9" />
          <stop offset="0.906276" stopColor="#B382F9" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  </SvgIcon>
);
