import { WorkflowEditorService } from 'api-clients/monolith';
import { useApiService } from 'hooks';
import React from 'react';

export function useRulesTargetStage({ externalId }: { externalId: string }) {
  const fetchRulesTargetStage = React.useCallback(
    () =>
      WorkflowEditorService.getInternalApiWorkflowEditorRulesEditData(
        externalId,
        true,
        false,
      ),
    [externalId],
  );

  return useApiService(fetchRulesTargetStage);
}
