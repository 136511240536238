import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import Frame from 'react-frame-component';
import { FormattedMessage } from 'react-intl';

import globalMessages from 'shared/global/messages';

import FrameHead from './FrameHead';
import styles from './styles';

const MobileEmailPreview = ({
  classes,
  fromEmail,
  messageBody,
  messageSubject,
  toEmail,
}) => (
  <>
    <Grid container direction="column" className={classes.messageHeader}>
      <Grid item container className={classes.fromEmail}>
        <Typography display="inline" className={classes.emailAddressHeader}>
          <FormattedMessage {...globalMessages.from} />
          :&nbsp;
        </Typography>
        <Typography
          display="inline"
          noWrap
          color="textSecondary"
          title={fromEmail}
          className={classes.emailAddress}
        >
          {fromEmail}
        </Typography>
      </Grid>
      <Grid item container>
        <Typography display="inline" className={classes.emailAddressHeader}>
          <FormattedMessage {...globalMessages.to} />
          :&nbsp;
        </Typography>
        <Typography
          display="inline"
          noWrap
          color="textSecondary"
          title={toEmail}
          className={classes.emailAddress}
        >
          {toEmail}
        </Typography>
      </Grid>
    </Grid>
    <Grid container direction="column" className={classes.messageContent}>
      <Typography
        variant="overline"
        color="textSecondary"
        display="block"
        noWrap
        className={classes.messageSubject}
        title={messageSubject}
      >
        {messageSubject || (
          <>
            [<FormattedMessage {...globalMessages.subject} />]
          </>
        )}
      </Typography>
      <Grid item className={classes.messageBody}>
        {messageBody ? (
          <Frame head={<FrameHead />}>
            <div
              dangerouslySetInnerHTML={{
                __html: messageBody,
              }}
            />
          </Frame>
        ) : (
          <>
            [<FormattedMessage {...globalMessages.message} />]
          </>
        )}
      </Grid>
    </Grid>
  </>
);

MobileEmailPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  fromEmail: PropTypes.string,
  messageBody: PropTypes.string,
  messageSubject: PropTypes.string,
  toEmail: PropTypes.string,
};

MobileEmailPreview.defaultProps = {
  fromEmail: '',
  toEmail: '',
};

export default withStyles(styles)(MobileEmailPreview);
