import { defineMessages } from 'react-intl';

export const scope = 'app.components.ApplicantsFilter';

export default defineMessages({
  applyFilters: {
    id: 'app.components.ApplicantsFilter.applyFilters',
    defaultMessage: 'Apply Filters',
  },
  newFilter: {
    id: 'app.components.ApplicantsFilter.newFilter',
    defaultMessage: 'Save As',
  },
  applyConfirmationTitle: {
    id: 'app.components.ApplicantsFilter.applyConfirmationTitle',
    defaultMessage: 'Unsaved Changes',
  },
  applyConfirmationBody: {
    id: 'app.components.ApplicantsFilter.applyConfirmationBody',
    defaultMessage: 'Are you sure you want to continue without saving?',
  },
  deleteConfirmationTitle: {
    id: 'app.components.ApplicantsFilter.deleteConfirmationTitle',
    defaultMessage: 'Delete Saved Filter',
  },
  deleteConfirmationBody: {
    id: 'app.components.ApplicantsFilter.deleteConfirmationBody',
    defaultMessage:
      'Saved filters are shared across all team members. Are you sure you want to delete this saved filter?',
  },
  unsavedChanges: {
    id: 'app.components.ApplicantsFilter.unsavedChanges',
    defaultMessage: 'Unsaved Changes',
  },
  filterTitlePlaceholder: {
    id: 'app.components.ApplicantsFilter.filterTitlePlaceholder',
    defaultMessage: 'Filter Name',
  },
  addDataCondition: {
    id: 'app.components.ApplicantsFilter.addDataCondition',
    defaultMessage: 'Add Data Filter',
  },
  comparatorLabel: {
    id: 'app.components.ApplicantsFilter.comparatorLabel',
    defaultMessage: 'type',
  },
  filters: {
    id: 'app.components.ApplicantsFilter.filters',
    defaultMessage: 'Filters',
  },
  noSavedFilters: {
    id: 'app.components.ApplicantsFilter.noSavedFilters',
    defaultMessage: 'No Saved Filters',
  },
  selectOperator: {
    id: 'app.components.ApplicantsFilter.selectOperator',
    defaultMessage: 'Select an operator',
  },
  selectValue: {
    id: 'app.components.ApplicantsFilter.selectValue',
    defaultMessage: 'Please Select',
  },
  newCategory: {
    id: 'app.components.ApplicantsFilter.newCategory',
    defaultMessage: '+ New Category',
  },
  newGroup: {
    id: 'app.components.ApplicantsFilter.newGroup',
    defaultMessage: '+Group',
  },
  newRule: {
    id: 'app.components.ApplicantsFilter.newRule',
    defaultMessage: '+Rule',
  },
  mustAddRule: {
    id: 'app.components.ApplicantsFilter.mustAddRule',
    defaultMessage: 'Must add a Rule.',
  },
  mustAddCategory: {
    id: 'app.components.ApplicantsFilter.mustAddCategory',
    defaultMessage: 'Must add a Category.',
  },
  datePickerErrorMessage: {
    id: 'app.components.ApplicantsFilter.datePickerErrorMessage',
    defaultMessage: 'Date cannot be empty.',
  },
  selectErrorMessage: {
    id: 'app.components.ApplicantsFilter.selectErrorMessage',
    defaultMessage: 'Dropdown can not be empty.',
  },
  inputErrorMessage: {
    id: 'app.components.ApplicantsFilter.inputErrorMessage',
    defaultMessage: 'Input field can not be empty.',
  },
  addressTooltip: {
    id: 'app.components.ApplicantsFilter.addressTooltip',
    defaultMessage: 'Address is case sensitive.',
  },
  address2Tooltip: {
    id: 'app.components.ApplicantsFilter.address2Tooltip',
    defaultMessage:
      'Enter a complete address e.g. APT 123. Address is case sensitive.',
  },
  fetchFilterFail: {
    id: 'app.components.ApplicantsFilter.fetchFilterFail',
    defaultMessage: 'An error occurred while fetching filter sets',
  },
  fetchSavedFilterFail: {
    id: 'app.components.ApplicantsFilter.fetchSavedFilterFail',
    defaultMessage: 'An error occurred while fetching saved filters',
  },
  deleteFilter: {
    id: 'app.components.ApplicantsFilter.deleteFilter',
    defaultMessage: 'Successfully deleted filter!',
  },
  deleteFilterFail: {
    id: 'app.components.ApplicantsFilter.deleteFilterFail',
    defaultMessage: 'An error occurred while deleting saved filter',
  },
  updateFilter: {
    id: 'app.components.ApplicantsFilter.updateFilter',
    defaultMessage: 'Successfully updated filter!',
  },
  applying: {
    id: 'app.components.ApplicantsFilter.applying',
    defaultMessage: 'Applying your changes.',
  },
  updateFilterFail: {
    id: 'app.components.ApplicantsFilter.updateFilterFail',
    defaultMessage: "'An error occurred while updating saved filter'",
  },
  saveFilter: {
    id: 'app.components.ApplicantsFilter.saveFilter',
    defaultMessage: 'Successfully saved filter',
  },
  saveFilterFail: {
    id: 'app.components.ApplicantsFilter.saveFilterFail',
    defaultMessage: 'An error occurred while saving the filter',
  },
  eq: {
    id: 'app.components.ApplicantsFilter.operators.eq',
    defaultMessage: 'is equal to',
  },
  not_contain: {
    id: 'app.components.ApplicantsFilter.operators.not_contain',
    defaultMessage: 'does not contain',
  },
  contain: {
    id: 'app.components.ApplicantsFilter.operators.contain',
    defaultMessage: 'contains',
  },
  include_any: {
    id: 'app.components.ApplicantsFilter.operators.include_any',
    defaultMessage: 'includes any',
  },
  include: {
    id: 'app.components.ApplicantsFilter.operators.include',
    defaultMessage: 'includes',
  },
  not_include: {
    id: 'app.components.ApplicantsFilter.operators.not_include',
    defaultMessage: 'does not include',
  },
  ends_with: {
    id: 'app.components.ApplicantsFilter.operators.ends_with',
    defaultMessage: 'ends with',
  },
  greater_eq: {
    id: 'app.components.ApplicantsFilter.operators.greater_eq',
    defaultMessage: 'Greater or equals',
  },
  greater: {
    id: 'app.components.ApplicantsFilter.operators.greater',
    defaultMessage: 'Greater than',
  },
  less_eq: {
    id: 'app.components.ApplicantsFilter.operators.less_eq',
    defaultMessage: 'Less or equals',
  },
  less: {
    id: 'app.components.ApplicantsFilter.operators.less',
    defaultMessage: 'Less than',
  },
  not_eq: {
    id: 'app.components.ApplicantsFilter.operators.not_eq',
    defaultMessage: 'is not equal to',
  },
  starts_with: {
    id: 'app.components.ApplicantsFilter.operators.starts_with',
    defaultMessage: 'Starts with',
  },
  is_null: {
    id: 'app.components.ApplicantsFilter.operators.is_null',
    defaultMessage: 'Is null',
  },
  not_null: {
    id: 'app.components.ApplicantsFilter.operators.not_null',
    defaultMessage: 'Is not null',
  },
  exist: {
    id: 'app.components.ApplicantsFilter.operators.exists',
    defaultMessage: 'exists',
  },
  not_exist: {
    id: 'app.components.ApplicantsFilter.operators.not_exist',
    defaultMessage: 'does not exist',
  },
  is_empty: {
    id: 'app.components.ApplicantsFilter.operators.is_empty',
    defaultMessage: 'is empty',
  },
  equal_any: {
    id: 'app.components.ApplicantsFilter.operators.equal_any',
    defaultMessage: 'is equal to any',
  },
  date_eq: {
    id: 'app.components.ApplicantsFilter.operators.date.eq',
    defaultMessage: 'Is',
  },
  date_greater: {
    id: 'app.components.ApplicantsFilter.operators.date.greater',
    defaultMessage: 'Is after',
  },
  date_less: {
    id: 'app.components.ApplicantsFilter.operators.date.less',
    defaultMessage: 'Is before',
  },
  date_today: {
    id: 'app.components.ApplicantsFilter.operators.date.today',
    defaultMessage: 'Today',
  },
  and: {
    id: 'app.components.ApplicantsFilter.combinators.and',
    defaultMessage: 'AND',
  },
  or: {
    id: 'app.components.ApplicantsFilter.combinators.or',
    defaultMessage: 'OR',
  },
});
