import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  title: {
    color: theme.palette.common.gray800,
  },
  itemContainer: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5), // MUI FormControlLabels have negative margins by default
  },
  item: {
    paddingRight: theme.spacing(2),
  },
}));
