import {
  Button,
  CustomTooltip,
  IconButton,
  Input,
  Trashcan,
} from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import { WorkflowTechCheckStage } from 'api-clients/monolith';
import produce from 'immer';
import React, { useContext, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Error } from 'components/Error';
import { StageSettingCard } from 'containers/WorkflowEditor/components/StageSettingCard';
import { StageSettingCardEmptyState } from 'containers/WorkflowEditor/components/StageSettingCardEmptyState';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { PHONE_NUMBER, URL } from './constants';
import { messages } from './messages';
import { useStyles } from './styles';

export const CustomerSupport: VFC<{ stage: WorkflowTechCheckStage }> = ({
  stage,
}) => {
  const intl = useIntl();
  const styles = useStyles();

  const { setStage, updateStageResult } = useContext(StageContext);

  const errors =
    (updateStageResult.isError && updateStageResult.error?.errors) || undefined;
  const phoneNumberError =
    errors && errors['customer_support.phone_number']?.[0];
  const urlError = errors && errors['customer_support.url']?.[0];

  const onAddCustomerSupport = () => {
    setStage({
      ...stage,
      additional_info: {
        ...stage.additional_info,
        customer_support: {
          phone_number: '',
          url: '',
        },
      },
    });
  };

  const onRemoveCustomerSupport = () => {
    setStage(
      produce(stage, draftStage => {
        const customerSupport = draftStage.additional_info?.customer_support;

        if (!customerSupport) return;

        // eslint-disable-next-line no-underscore-dangle
        customerSupport._destroy = true;
      }),
    );
  };

  const onChangeInput =
    (key: typeof PHONE_NUMBER | typeof URL) =>
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = event.target;
      setStage(
        produce(stage, draftStage => {
          if (draftStage.additional_info?.customer_support) {
            draftStage.additional_info.customer_support[key] = value;
          }
        }),
      );
    };

  if (
    !stage.additional_info?.customer_support ||
    // eslint-disable-next-line no-underscore-dangle
    stage.additional_info?.customer_support._destroy
  ) {
    return (
      <StageSettingCardEmptyState
        stageSettingCardProps={{
          title: intl.formatMessage(messages.customerSupport),
          variant: 'default',
        }}
        bodyText={intl.formatMessage(messages.customerSupportBodyText)}
        buttonComponent={
          <Button
            type="secondary"
            onClick={onAddCustomerSupport}
            disableRipple
            aria-label={intl.formatMessage(messages.addCustomerSupport)}
            className={styles.addCustomerSupportButton}
          >
            <Typography variant="h4" color="primary">
              <FormattedMessage {...messages.addCustomerSupport} />
            </Typography>
          </Button>
        }
      />
    );
  }

  return (
    <StageSettingCard
      title={intl.formatMessage(messages.customerSupport)}
      isLoading={false}
      variant="default"
    >
      <Grid>
        <Typography variant="body2" className={styles.description}>
          {intl.formatMessage(messages.customerSupportBodyText)}
        </Typography>
      </Grid>
      <Grid container className={styles.inputContainer}>
        <Grid item className={styles.inputItem}>
          <Input
            aria-label={intl.formatMessage(messages.phoneNumber)}
            label={intl.formatMessage(messages.phoneNumber)}
            className={styles.input}
            value={stage.additional_info.customer_support.phone_number}
            onChange={onChangeInput(PHONE_NUMBER)}
            error={Boolean(
              !stage.additional_info.customer_support.phone_number &&
                phoneNumberError,
            )}
          />
          <Error
            error={
              Boolean(
                !stage.additional_info.customer_support.phone_number &&
                  phoneNumberError,
              ) && intl.formatMessage(messages.addPhoneNumber)
            }
            align="right"
          />
        </Grid>
        <Grid item className={styles.inputItem}>
          <Input
            aria-label={intl.formatMessage(messages.link)}
            label={intl.formatMessage(messages.link)}
            className={styles.input}
            value={stage.additional_info.customer_support.url}
            onChange={onChangeInput(URL)}
            error={Boolean(
              !stage.additional_info.customer_support.url && urlError,
            )}
          />
          <Error
            error={
              Boolean(
                !stage.additional_info.customer_support.url && urlError,
              ) && intl.formatMessage(messages.addLink)
            }
            align="right"
          />
        </Grid>
        <CustomTooltip dense title={intl.formatMessage(messages.delete)}>
          <IconButton onClick={onRemoveCustomerSupport}>
            <Trashcan
              aria-label={intl.formatMessage(messages.delete)}
              className={styles.icon}
              viewBox="0 0 16 16"
            />
          </IconButton>
        </CustomTooltip>
      </Grid>
    </StageSettingCard>
  );
};
