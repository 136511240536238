import { Loader } from '@fountain/fountain-ui-components';
import { makeStyles } from '@material-ui/core/styles';
import { ChatbotService } from 'api-clients/monolith/services/ChatbotService';
import React, { useCallback } from 'react';

import { useApiService } from 'hooks/useApiService';

import { Card } from './Card';
import { ResponseStatsCard } from './ResponseStatsCard';

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(4, 0),
    display: 'grid',
    gap: theme.spacing(4),
    gridTemplateAreas: `
      "responseStats responseStats"
      "faqApplicantsCount linksClickedCount"
    `,
  },
}));

export const Dashboard = () => {
  const classes = useStyles();

  const { result } = useApiService(
    useCallback(
      () => ChatbotService.getInternalApiChatbotChatbotWidgetDashboard(),
      [],
    ),
  );

  if (result.status === 'loading') {
    return <Loader block size="2rem" />;
  }

  if (result.status !== 'ready') return <></>;

  const { data: faqAnalyticsData } = result;

  return (
    <div className={classes.content}>
      <ResponseStatsCard faqAnalyticsData={faqAnalyticsData} />
      <Card
        metric="faqApplicantsCount"
        value={faqAnalyticsData.faq_applicants_count}
      />
      <Card
        metric="linksClickedCount"
        value={faqAnalyticsData.links_clicked_count}
      />
    </div>
  );
};
