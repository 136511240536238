/* eslint-disable no-underscore-dangle */
import { TextButton, Trashcan } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import { RuleAttributes, StageType } from 'api-clients/monolith';
import { produce } from 'immer';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';

import { StageSettingCard } from '../../StageSettingCard';
import { Action } from '../Action';
import { Condition } from '../Condition';
import { AND, OR } from '../constants';
import { RulesProps } from '../types';
import { DEFAULT_ACTION, DEFAULT_CONDITION } from './constants';
import { messages } from './messages';
import {
  SingleConditionStageTypes,
  SingleRuleStageTypes,
} from './RuleCard.fixtures';
import { useStyles } from './styles';

export interface RuleCardProps {
  ruleId: string;
  index: number;
  rule: RuleAttributes;
  setRules: React.Dispatch<React.SetStateAction<RulesProps>>;
  deleteEnabled?: boolean;
  stageType?: StageType;
  errors?: {
    [key: string]: string[];
  };
  onDeleteRule: (ruleId: string | number) => void;
}

export const RuleCard: FC<RuleCardProps> = React.memo(
  ({
    rule,
    index,
    ruleId,
    setRules,
    onDeleteRule,
    deleteEnabled = true,
    stageType = 'RuleStage',
    errors,
  }) => {
    const styles = useStyles();

    const intl = useIntl();

    const {
      condition_set_attributes: conditionSetAttributes,
      action_set_attributes: actionSetAttributes,
    } = rule;

    const actions = actionSetAttributes?.actions_attributes;
    const conditions = conditionSetAttributes?.conditions_attributes;

    const onChangeConditionLogic = () => {
      setRules(
        produce((draftRules: RulesProps) => {
          const draftConditionSet =
            (draftRules[ruleId] || {}).condition_set_attributes ?? {};

          if (draftConditionSet.logic === AND) {
            draftConditionSet.logic = OR;
          } else {
            draftConditionSet.logic = AND;
          }
        }),
      );
    };

    const onAddCondition = () => {
      setRules(
        produce((draftRules: RulesProps) => {
          const draftConditions =
            ((draftRules[ruleId] || {}).condition_set_attributes ?? {})
              .conditions_attributes ?? [];

          draftConditions.push({
            ...DEFAULT_CONDITION,
            id: uuid(),
          });
        }),
      );
    };

    const onAddAction = () => {
      setRules(
        produce((draftRules: RulesProps) => {
          const draftActions =
            ((draftRules[ruleId] || {}).action_set_attributes ?? {})
              .actions_attributes ?? [];

          draftActions.push({
            ...DEFAULT_ACTION,
            id: uuid(),
          });
        }),
      );
    };

    const availableConditions = conditions?.filter(cond => !cond._destroy);
    const availableActions = actions?.filter(act => !act._destroy);

    const { logic } = rule.condition_set_attributes;

    const matchConditionText =
      logic === AND
        ? intl.formatMessage(messages.matchAllConditions)
        : intl.formatMessage(messages.matchAnyCondition);

    return (
      <Grid className={styles.ruleCardContainer}>
        <StageSettingCard
          title={
            SingleRuleStageTypes.includes(stageType)
              ? intl.formatMessage(messages.rule)
              : intl.formatMessage(messages.ruleNumber, { number: index + 1 })
          }
          leftButtonText={
            availableConditions && availableConditions.length > 1
              ? matchConditionText
              : undefined
          }
          leftButtonOnClick={onChangeConditionLogic}
          icon={
            <Trashcan
              color="secondary"
              viewBox="0 0 16 16"
              className={styles.trashcanIcon}
              aria-label={intl.formatMessage(messages.delete)}
            />
          }
          buttonText={intl.formatMessage(messages.deleteRule)}
          variant="button"
          onClick={() => onDeleteRule(ruleId)}
          buttonEnabled={deleteEnabled}
        >
          <Grid>
            <div className={styles.paddingBottom}>
              <Grid>
                {availableConditions?.map((condition, idx) => {
                  return (
                    <Condition
                      key={ruleId}
                      ruleId={ruleId}
                      index={idx}
                      condition={condition}
                      isPresetCondition={stageType === 'AssessmentStage'}
                      setRules={setRules}
                      allowDelete={availableConditions.length > 1}
                      errors={errors}
                      logic={logic}
                    />
                  );
                })}
              </Grid>

              {!SingleConditionStageTypes.includes(stageType) && (
                <TextButton
                  color="primary"
                  className={styles.paddingTop}
                  onClick={onAddCondition}
                  aria-label={`${intl.formatMessage(messages.ruleNumber, {
                    number: index + 1,
                  })} ${intl.formatMessage(messages.addConditions)}`}
                >
                  <Typography variant="body2" color="primary">
                    + <FormattedMessage {...messages.addConditions} />
                  </Typography>
                </TextButton>
              )}
            </div>

            <Grid>
              {availableActions?.map((action, idx) => {
                return (
                  <Action
                    key={uuid()}
                    action={action}
                    ruleId={ruleId}
                    index={idx}
                    allowDelete={availableActions.length > 1}
                    setRules={setRules}
                    conditions={availableConditions}
                    actions={availableActions}
                    errors={errors}
                  />
                );
              })}
            </Grid>

            <TextButton
              color="primary"
              className={styles.paddingTop}
              onClick={onAddAction}
              aria-label={`${intl.formatMessage(messages.ruleNumber, {
                number: index + 1,
              })} ${intl.formatMessage(messages.addActions)}`}
            >
              <Typography variant="body2" color="primary">
                + <FormattedMessage {...messages.addActions} />
              </Typography>
            </TextButton>
          </Grid>
        </StageSettingCard>
      </Grid>
    );
  },
);

RuleCard.displayName = 'RuleCard';
