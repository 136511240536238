/* eslint-disable camelcase */
import { Loader } from '@fountain/fountain-ui-components';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CopyIcon from '@material-ui/icons/FileCopy';
import React, { useEffect, useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  makeSelectAccountSlug,
  makeSelectWhoami,
} from 'containers/Auth_old/selectors';
import { addMessageAction } from 'containers/FlashMessage/actions';

import { retrieveAgentConnection, retrieveAxAgent } from '../api';
import { messages } from '../messages';
import { AxAgentValues } from '../types';
import { extractDomain, isValidURL } from '../utils';
import { DeleteWebsiteConfirmationModal } from './DeleteWebsiteConfirmationModal';
import { EmbedCodeModal } from './EmbedCodeModal';
import { useCustomizeChatbotStyles } from './styles';

export interface CustomizeChatbotProps {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  accessToken: string;
  setAxAgentValues: React.Dispatch<React.SetStateAction<AxAgentValues>>;
  axAgentValues: AxAgentValues;
  errors: {
    [key: string]: string;
  };
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
}

export const CustomizeChatbot: VFC<CustomizeChatbotProps> = ({
  handleChange,
  accessToken,
  setAxAgentValues,
  axAgentValues,
  errors,
  setIsLoading,
  isLoading,
}) => {
  const classes = useCustomizeChatbotStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [fallbackMethod, setFallbackMethod] = useState<string>('Email');
  const [newWebsite, setNewWebsite] = useState('');
  const [showAddWebsite, setShowAddWebsite] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [isCustomWebsiteEnable, setIsCustomWebsiteEnable] = useState(
    (axAgentValues.customWebsites?.length as number) > 0,
  );
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const [openEmbedCodeModal, setOpenEmbedCodeModal] = useState(false);

  const accountSlug = useSelector(makeSelectAccountSlug()) ?? '';
  const { whats_app_enabled: whatsappEnabled } = useSelector(
    makeSelectWhoami(),
  );

  const handleAddWebsite = () => {
    if (newWebsite && isValidURL(newWebsite)) {
      const domain = extractDomain(newWebsite);
      setAxAgentValues(prev => {
        const updatedWebsites = [...(prev.customWebsites ?? [])];
        if (editingIndex !== null) {
          updatedWebsites[editingIndex] = domain;
        } else {
          updatedWebsites.push(domain);
        }
        return {
          ...prev,
          customWebsites: updatedWebsites,
        };
      });
      setNewWebsite('');
      setShowAddWebsite(false);
      setEditingIndex(null);
    } else {
      dispatch(
        addMessageAction(intl.formatMessage(messages.enterValidUrl), 'error'),
      );
    }
  };

  const handleDeleteWebsite = (index: number) => {
    setDeleteIndex(index);
    setOpenConfirmDialog(true);
  };

  const toggleAddWebsite = () => {
    setShowAddWebsite(!showAddWebsite);
    setNewWebsite('');
    setEditingIndex(null);
  };

  const handleCustomWebsiteToggle = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const isChecked = event.target.checked;
    setIsCustomWebsiteEnable(isChecked);
    setAxAgentValues(prev => ({
      ...prev,
      customWebsites: isChecked ? prev.customWebsites : [],
    }));
  };

  useEffect(() => {
    const fetchAgents = async () => {
      setIsLoading(true);
      try {
        const agentData = await retrieveAxAgent(
          accessToken,
          axAgentValues.axAgentId,
        );
        const agentConnectionResponse = await retrieveAgentConnection(
          accountSlug,
          axAgentValues.axAgentId,
        );

        setAxAgentValues(prev => ({
          ...prev,
          chatbotName: agentData.name,
          emailFallback: agentData.emailFallback,
          phoneNumberFallback: agentData.phoneNumberFallback,
          websiteFallback: agentData.websiteFallback,
          enableInFountainPlatforms:
            agentConnectionResponse.agent_connection
              .enable_in_fountain_platforms,
          smsEnabled:
            agentConnectionResponse.agent_connection.sms_to_chat_agent_enabled,
          brandId: agentConnectionResponse.agent_connection.brand_external_id,
          brands: agentConnectionResponse.agent_connection.available_brands,
          atsConnectionId:
            agentConnectionResponse.agent_connection.ats_connection_id,
          status: agentData.status,
          customWebsites: agentData.allowedOrigins,
          noOfActiveFunnelsVisibleToApplicant:
            agentConnectionResponse.agent_connection
              .no_of_active_funnels_visible_to_applicant,
          applyBotType: agentData.applyBotType,
        }));
        setIsCustomWebsiteEnable(
          (agentData.allowedOrigins?.length as number) > 0,
        );
      } catch (err) {
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.fetchAgentsFailed),
            'error',
          ),
        );
      } finally {
        setIsLoading(false);
      }
    };

    if (axAgentValues.axAgentId) void fetchAgents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, axAgentValues.axAgentId]);

  const handleFallbackMethodChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setFallbackMethod(event.target.value as string);
  };

  const handleCloseEmbedModal = () => {
    setOpenEmbedCodeModal(false);
  };

  if (isLoading) return <Loader fullScreen size="2rem" />;

  return (
    <Grid className={classes.container}>
      <Grid className={classes.header}>
        <Typography variant="h2">
          <FormattedMessage {...messages.customizeAiAgent} />
        </Typography>
      </Grid>
      <Grid className={classes.sectionHeader}>
        <Grid className={classes.sectionHeader}>
          <Typography variant="h3">
            <FormattedMessage {...messages.aiAgentName} />
            <Typography component="span" color="error">
              *
            </Typography>
          </Typography>
        </Grid>
        <Grid className={classes.contactInfo}>
          <Grid className={classes.formInput}>
            <Typography variant="body2">
              <FormattedMessage {...messages.aiAgentName} />
            </Typography>
            <TextField
              placeholder={intl.formatMessage(messages.chatbotNamePlaceholder)}
              value={axAgentValues.chatbotName}
              fullWidth
              variant="outlined"
              size="small"
              name="chatbotName"
              error={!!errors.chatbotName}
              onChange={handleChange}
            />
            {errors.chatbotName && (
              <FormHelperText error>{errors.chatbotName}</FormHelperText>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.content}>
        <Grid className={classes.sectionHeader}>
          <Typography variant="h3">
            <FormattedMessage {...messages.aiAgentVisibility} />
            <Typography component="span" color="error">
              *
            </Typography>
          </Typography>
          <Typography variant="body2">
            <FormattedMessage {...messages.aiAgentVisibilityInstruction} />
          </Typography>
        </Grid>
        <Grid>
          <Grid className={classes.contactInfo}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={axAgentValues.enableInFountainPlatforms}
                  onChange={handleChange}
                  name="enableInFountainPlatforms"
                />
              }
              label={
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Typography variant="body1" className={classes.label}>
                    Fountain Website
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    <FormattedMessage
                      {...messages.fountainWebsiteDescription}
                    />
                  </Typography>
                </Box>
              }
              className={classes.visibilityLabel}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={axAgentValues.smsEnabled}
                  onChange={handleChange}
                  name="smsEnabled"
                />
              }
              label={
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  {whatsappEnabled ? (
                    <>
                      <Typography variant="body1" className={classes.label}>
                        SMS / WhatsApp
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        <FormattedMessage
                          {...messages.smsWhatsappEnabledDescription}
                        />
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography variant="body1" className={classes.label}>
                        SMS
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        <FormattedMessage {...messages.smsEnabledDescription} />
                      </Typography>
                    </>
                  )}
                </Box>
              }
              className={classes.visibilityLabel}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isCustomWebsiteEnable}
                  onChange={handleCustomWebsiteToggle}
                  name="enableInFountainPlatforms"
                />
              }
              label={
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Typography variant="body1" className={classes.label}>
                    Custom Website
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    <FormattedMessage {...messages.customWebsiteDescription} />
                  </Typography>
                </Box>
              }
              className={classes.visibilityLabel}
            />
          </Grid>

          {isCustomWebsiteEnable && (
            <Grid item xs={12} className={classes.customWebsiteSection}>
              <Button
                onClick={() => {
                  setOpenEmbedCodeModal(true);
                }}
                startIcon={<CopyIcon />}
                variant="outlined"
                color="secondary"
              >
                Get embed code
              </Button>
              {axAgentValues.customWebsites &&
                axAgentValues.customWebsites.map((website, index) =>
                  editingIndex === index ? (
                    // eslint-disable-next-line react/no-array-index-key
                    <Grid key={index} container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <TextField
                          variant="outlined"
                          fullWidth
                          value={newWebsite}
                          onChange={e => setNewWebsite(e.target.value)}
                          autoFocus
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            handleAddWebsite();
                            setEditingIndex(null);
                          }}
                        >
                          Save
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setEditingIndex(null);
                            setNewWebsite('');
                          }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      alignItems="center"
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      className={
                        index % 2 === 0
                          ? classes.customWebsiteEvenRow
                          : classes.customWebsiteOddRow
                      }
                    >
                      <Typography variant="caption" color="textSecondary">
                        <FormattedMessage {...messages.websiteDomain} />
                      </Typography>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="space-between"
                        className={classes.customWebsiteLabel}
                      >
                        <Typography variant="body2">{website}</Typography>
                        <Grid className={classes.actionButtons}>
                          <IconButton
                            onClick={() => {
                              setEditingIndex(index);
                              setNewWebsite(website);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDeleteWebsite(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  ),
                )}
              {showAddWebsite && (
                <Grid
                  item
                  container
                  direction="column"
                  className={classes.addWebsite}
                >
                  <Typography variant="caption" color="textSecondary">
                    <FormattedMessage {...messages.websiteDomain} />
                  </Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={newWebsite}
                    onChange={e => setNewWebsite(e.target.value)}
                    placeholder="www.example.com"
                    className={classes.addWebsiteInput}
                  />
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    spacing={1}
                  >
                    <Grid item>
                      <Button
                        onClick={toggleAddWebsite}
                        variant="outlined"
                        color="secondary"
                        size="medium"
                        className={classes.cancelBtn}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={handleAddWebsite}
                        variant="contained"
                        color="primary"
                        size="medium"
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {!showAddWebsite && (
                <Button
                  onClick={toggleAddWebsite}
                  startIcon={<AddIcon />}
                  variant="text"
                  color="primary"
                >
                  Add Website
                </Button>
              )}
            </Grid>
          )}

          {errors.visibility && (
            <FormHelperText error>{errors.visibility}</FormHelperText>
          )}
        </Grid>
      </Grid>
      <Grid className={classes.content}>
        <Grid className={classes.sectionHeader}>
          <Typography variant="h3">
            <FormattedMessage {...messages.fallbackInteractions} />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage {...messages.fallbackInteractionsInstruction} />
          </Typography>
        </Grid>
        <Grid className={classes.contactInfo}>
          <Grid className={classes.formInput}>
            <Typography variant="body2">
              <FormattedMessage {...messages.fallbackMethod} />
            </Typography>
            <Select
              value={fallbackMethod}
              onChange={handleFallbackMethodChange}
              variant="outlined"
              fullWidth
              classes={{
                root: classes.root,
              }}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
            >
              <MenuItem value="Email" className={classes.menuItem}>
                Email
              </MenuItem>
              <MenuItem value="PhoneNumber" className={classes.menuItem}>
                PhoneNumber
              </MenuItem>
              <MenuItem value="Website" className={classes.menuItem}>
                Website
              </MenuItem>
            </Select>
          </Grid>
          {fallbackMethod === 'Email' && (
            <Grid className={classes.formInput}>
              <Typography variant="body2">
                <FormattedMessage {...messages.email} />
              </Typography>
              <TextField
                placeholder="email@domain.com"
                fullWidth
                value={axAgentValues.emailFallback}
                variant="outlined"
                size="small"
                name="emailFallback"
                onChange={handleChange}
              />
            </Grid>
          )}
          {fallbackMethod === 'PhoneNumber' && (
            <Grid className={classes.formInput}>
              <Typography variant="body2">
                <FormattedMessage {...messages.phoneNumber} />
              </Typography>
              <TextField
                placeholder="(123) 456-7890"
                fullWidth
                value={axAgentValues.phoneNumberFallback}
                variant="outlined"
                size="small"
                name="phoneNumberFallback"
                onChange={handleChange}
              />
            </Grid>
          )}
          {fallbackMethod === 'Website' && (
            <Grid className={classes.formInput}>
              <Typography variant="body2">
                <FormattedMessage {...messages.website} />
              </Typography>
              <TextField
                placeholder="www.website.com/faq"
                fullWidth
                value={axAgentValues.websiteFallback}
                variant="outlined"
                size="small"
                name="websiteFallback"
                onChange={handleChange}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <DeleteWebsiteConfirmationModal
        handleClose={() => setOpenConfirmDialog(false)}
        openConfirmDialog={openConfirmDialog}
        deleteIndex={deleteIndex}
        setOpenConfirmDialog={setOpenConfirmDialog}
        setAxAgentValues={setAxAgentValues}
      />
      <EmbedCodeModal
        open={openEmbedCodeModal}
        handleClose={handleCloseEmbedModal}
        agentId={axAgentValues.axAgentId}
      />
    </Grid>
  );
};
