import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import produce from 'immer';
import React from 'react';
import { useIntl } from 'react-intl';

import useLocations from 'hooks/useLocations';

import { DocumentSigningRuleProps } from '../../../DocumentSigningRules/types';
import { RulesProps, SharedConditionProps } from '../../../types';
import messages from './messages';

export const LocationSelect: React.VFC<SharedConditionProps> = ({
  condition,
  setRules,
  setDocumentSigningRules,
  ruleId,
}) => {
  const { data, isFetching } = useLocations();
  const intl = useIntl();

  const onChangeLocation = (location: { label: string; value: string }) => {
    const rulesSetter = setRules ?? setDocumentSigningRules;

    if (rulesSetter) {
      rulesSetter(
        produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];

          const idx = draftConditions.findIndex(
            cond => cond.id === condition.id,
          );

          draftConditions[idx].location_id = location.value;
        }),
      );
    }
  };

  const options = data.map(loc => ({ label: loc.name, value: loc.id }));
  const selectedLocation = options.find(
    opt => opt.value === condition.location_id,
  );

  return (
    <Grid item xs={6}>
      <StyledReactSelect
        isLoading={isFetching}
        isDisabled={isFetching}
        options={options}
        value={selectedLocation}
        label={intl.formatMessage(messages.location)}
        onChange={onChangeLocation}
        placeholder={intl.formatMessage(messages.chooseLocation)}
      />
    </Grid>
  );
};
