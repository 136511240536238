import {
  Button,
  InnerContent,
  ModalFooter,
  SimpleModal,
  SimpleModalHeader,
} from '@fountain/fountain-ui-components';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { FunnelDetails } from 'api-clients/monolith';
import React, { useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { messages } from './messages';
import useStyles from './styles';

export interface SaveWorkflowConfirmationModalProps {
  opening: FunnelDetails;
  onCancel: () => void;
  onConfirm: (showAgain: boolean) => void;
}

export const SaveWorkflowConfirmationModal: VFC<
  SaveWorkflowConfirmationModalProps
> = ({ opening, onCancel, onConfirm }) => {
  const intl = useIntl();
  const styles = useStyles();
  const [checked, setChecked] = useState(false);

  return (
    <SimpleModal onClose={onCancel} maxWidth={560} blurOverlay={false}>
      <SimpleModalHeader onClose={onCancel}>
        <Typography variant="h3">
          <FormattedMessage {...messages.saveChanges} />
        </Typography>
      </SimpleModalHeader>
      <InnerContent>
        <div className={styles.modalContents}>
          <Typography variant="body2">
            <FormattedMessage
              {...messages.saveWarning}
              values={{ count: opening.workflow_funnels.length }}
            />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage {...messages.saveConfirm} />
          </Typography>
        </div>
        <FormControlLabel
          control={
            <Checkbox checked={checked} onChange={() => setChecked(!checked)} />
          }
          label={
            <Typography variant="body2">
              <FormattedMessage {...messages.dontShowAgain} />
            </Typography>
          }
        />
      </InnerContent>
      <ModalFooter>
        <Button
          autoWidth
          onClick={onCancel}
          size="small"
          type="secondary"
          aria-label={intl.formatMessage(messages.cancel)}
        >
          <FormattedMessage {...messages.cancel} />
        </Button>
        <Button
          autoWidth
          onClick={() => onConfirm(checked)}
          aria-label={intl.formatMessage(messages.save)}
          size="small"
          submit
          type="primary"
        >
          <FormattedMessage {...messages.save} />
        </Button>
      </ModalFooter>
    </SimpleModal>
  );
};
