import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  StageSettingMessageCardContainer: {
    width: '100%',
    borderRadius: theme.misc.borderRadius,
    border: theme.customBorders.gray200,
  },
  bodyText: {
    color: theme.palette.common.gray800,
    marginBottom: theme.spacing(2),
    display: 'flex',
  },
  messageCardContent: {
    backgroundColor: theme.palette.common.white,
    alignItems: 'baseline',
    width: '100%',
  },
  bottomBorder: {
    marginBottom: theme.spacing(2),
    borderBottom: theme.customBorders.gray300,
  },
  infoTooltip: {
    marginLeft: theme.spacing(0.5),
    maxHeight: theme.spacing(2),
    display: 'inline-block',
    verticalAlign: 'bottom',
  },
  addMessageLinkButton: {
    padding: 0,
  },
  addMessageLinkButtonText: {
    color: theme.palette.common.blue400,
  },
}));
