/**
 *
 * CustomDropzone Component
 *
 */

import 'react-dropzone-uploader/dist/styles.css';

import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone-uploader';
import { classNames } from 'react-extras';

import CustomDropzoneUploadInput from 'components/CustomDropzone/components/CustomDropzoneUploadInput';
import MobileCustomDropzone from 'components/CustomDropzone/components/MobileCustomDropZone';

import useStyles from './styles';

function CustomDropzone(props) {
  const classes = useStyles();

  const {
    className,
    onChangeStatus,
    fileType,
    maxSize,
    maxFiles,
    multiple,
    PreviewComponent,
    error,
    isMobile,
  } = props;
  const [fileUploadError, setFileUploadError] = useState(undefined);

  const handleOnChange = ({ file, remove }, status) => {
    switch (status) {
      case 'done':
        setFileUploadError(undefined);
        onChangeStatus(file, remove);
        break;
      case 'rejected_file_type':
        setFileUploadError('This file type is not supported. Try again!');
        break;
      case 'error_file_size':
        setFileUploadError('The file is too big. Try again!');
        break;
      default:
        break;
    }
  };

  return isMobile ? (
    <MobileCustomDropzone
      fileType={fileType}
      maxSize={maxSize}
      maxFiles={maxFiles}
      multiple={multiple}
      error={error}
      isMobile={isMobile}
      handleOnChange={handleOnChange}
      fileUploadError={fileUploadError}
    />
  ) : (
    <Grid
      container
      className={classNames(classes.container, className)}
      data-testid="CustomDropzone"
    >
      <Dropzone
        styles={{
          dropzone: {
            border: 'none',
            width: '100%',
            minHeight: 'unset',
            overflow: 'unset',
          },
          dropzoneActive: {
            background: 'none',
          },
          inputLabel: {
            position: 'unset',
            width: '100%',
          },
        }}
        onChangeStatus={handleOnChange}
        maxSize={maxSize}
        maxFiles={maxFiles}
        multiple={multiple}
        inputContent={(inputProps, dropzoneProps) => (
          <CustomDropzoneUploadInput
            isDropzoneActive={dropzoneProps.active}
            fileType={fileType}
            hasError={!!fileUploadError}
            maxSize={maxSize}
            error={error}
            isMobile={isMobile}
          />
        )}
        PreviewComponent={
          PreviewComponent
            ? previewProps => <PreviewComponent {...previewProps} />
            : null
        }
        accept={fileType}
      />
      <Typography
        display="block"
        variant="body2"
        align="center"
        className={classes.errorField}
      >
        {fileUploadError}
      </Typography>
    </Grid>
  );
}

CustomDropzone.propTypes = {
  className: PropTypes.string,
  onChangeStatus: PropTypes.func,
  fileType: PropTypes.string,
  maxSize: PropTypes.number,
  maxFiles: PropTypes.number,
  multiple: PropTypes.bool,
  PreviewComponent: PropTypes.func,
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
    PropTypes.string,
  ]),
  isMobile: PropTypes.bool,
};

CustomDropzone.defaultProps = {
  fileType: '*',
  maxSize: 15728640, // 15 MB (binary)
  maxFiles: 1,
  multiple: false,
  PreviewComponent: null,
};

export default CustomDropzone;
