import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addIdleMoveRule: {
    id: `app.containers.WorkflowEditor.components.IdleMoveRule.addIdleMoveRule`,
    defaultMessage: 'Add Idle Move Rule',
  },
  automaticallyTransferIdleApplicants: {
    id: `app.containers.WorkflowEditor.components.IdleMoveRule.automaticallyTransferIdleApplicants`,
    defaultMessage: 'Automatically transfer idle applicants to a target stage.',
  },
  delete: {
    id: `app.containers.WorkflowEditor.components.IdleMoveRule.delete`,
    defaultMessage: 'Delete',
  },
  idleDaysError: {
    id: `app.containers.WorkflowEditor.components.IdleMoveRule.idleDaysError`,
    defaultMessage: 'Must be greater than 0',
  },
  idleMoveRule: {
    id: `app.containers.WorkflowEditor.components.IdleMoveRule.idleMoveRule`,
    defaultMessage: 'Idle Move Rule',
  },
  ignoreApplicantAction: {
    id: `app.containers.WorkflowEditor.components.IdleMoveRule.ignoreApplicantAction`,
    defaultMessage: 'Ignore applicant action and consider idle period only',
  },
  numberOfIdleDays: {
    id: `app.containers.WorkflowEditor.components.IdleMoveRule.numberOfIdleDays`,
    defaultMessage: 'Number of idle days',
  },
  reason: {
    id: `app.containers.WorkflowEditor.components.IdleMoveRule.reason`,
    defaultMessage: 'Reason',
  },
  sendAutomatedMessages: {
    id: `app.containers.WorkflowEditor.components.IdleMoveRule.sendAutomatedMessages`,
    defaultMessage: 'Send automated messages associated with the target stage',
  },
  stageReasonLoadingError: {
    id: `app.containers.WorkflowEditor.components.IdleMoveRule.stageReasonLoadingError`,
    defaultMessage: 'Failed to load Idle Move Rule stage reasons.',
  },
  targetStage: {
    id: `app.containers.WorkflowEditor.components.IdleMoveRule.targetStage`,
    defaultMessage: 'Target stage',
  },
  targetStageRequired: {
    id: `app.containers.WorkflowEditor.components.IdleMoveRule.targetStageRequired`,
    defaultMessage: 'Select target stage',
  },
  targetStageSelector: {
    id: `app.containers.WorkflowEditor.components.IdleMoveRule.targetStageSelector`,
    defaultMessage: 'Target stage selector',
  },
  trashcan: {
    id: `app.containers.WorkflowEditor.components.IdleMoveRule.trashcan`,
    defaultMessage: 'Trashcan',
  },
});
