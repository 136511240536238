import {
  CancelablePromise,
  FunnelDetails,
  WorkflowDetails,
  WorkflowEditorService,
} from 'api-clients/monolith';
import React, { VFC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addMessageAction } from 'containers/FlashMessage/actions';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';

import { messages } from './WorkflowNavBar/messages';
import { WorkflowNavBar } from './WorkflowNavBar/WorkflowNavBar';

export interface WorkflowNavBarContainerProps {
  applicantCount: number;
  id: number;
  title: string | null | undefined;
  workflowFunnels: FunnelDetails['workflow_funnels'];
  externalId: string;
}

export const WorkflowNavBarContainer: VFC<WorkflowNavBarContainerProps> = ({
  applicantCount,
  id,
  title,
  workflowFunnels,
  externalId,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { mutation, result } = useApiServiceMutation<
    WorkflowDetails,
    (
      workflowId: number,
      requestBody?: WorkflowDetails,
    ) => CancelablePromise<WorkflowDetails>,
    { errors: Record<string, Array<string>> }
  >(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    WorkflowEditorService.patchInternalApiWorkflowEditorWorkflows,
    {
      onSuccess: () => {
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.renameSuccess),
            'success',
          ),
        );
      },
    },
  );

  const onWorkflowTitleUpdate = (title: string) => {
    mutation(id, { title });
  };

  const currentTitle =
    result.status === 'ready' ? result.data.title : title ?? '';

  return (
    <WorkflowNavBar
      applicantCount={applicantCount}
      workflowFunnels={workflowFunnels}
      workflowId={id}
      workflowTitle={currentTitle}
      workflowExternalId={externalId}
      onWorkflowTitleUpdate={onWorkflowTitleUpdate}
    />
  );
};
