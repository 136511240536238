import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>(theme => ({
  jobSearchMenuPaper: {
    boxShadow: theme.customShadows.dropdown,
    width: '35%',
    maxWidth: 480,
    overflowY: 'auto',
    marginTop: theme.spacing(6),
    maxHeight: 480,
    minHeight: 300,
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '70%',
      left: '16% !important',
      marginTop: theme.spacing(8),
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      // override default mui style for fullscreen
      maxWidth: '100%',
      maxHeight: '100vh',
      position: 'unset', // resets position abosolute since menu is fullScreen
    },
  },
}));
