import { defineMessages } from 'react-intl';

export default defineMessages({
  apiError: {
    id: 'app.components.WorkflowEditor.components.WorkflowEditorContent.apiError',
    defaultMessage:
      'An error occurred. Please check your network or try again later.',
  },
  forbiddenError: {
    id: 'app.components.WorkflowEditor.components.WorkflowEditorContent.forbiddenError',
    defaultMessage:
      'You do not have access to this workflow. Contact your account admin for permission',
  },
});
