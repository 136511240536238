import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  noLogs: {
    width: '100%',
    height: 'calc(100vh - (52px))',
  },
  logContainer: {
    width: '100%',
  },
}));
