import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addDocument: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Documents.DocumentModal.addDocument`,
    defaultMessage: 'Add Document',
  },
  cancel: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Documents.DocumentModal.cancel`,
    defaultMessage: 'Cancel',
  },
  documentTemplate: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Documents.DocumentModal.documentTemplate`,
    defaultMessage: 'Document Template',
  },
  editDocument: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Documents.DocumentModal.editDocument`,
    defaultMessage: 'Edit Document',
  },
  roleSelectLabel: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Documents.DocumentModal.roleSelectLabel`,
    defaultMessage: '{role} Role',
  },
  updateDocument: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Documents.DocumentModal.updateDocument`,
    defaultMessage: 'Update Document',
  },
  uniqueSigners: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Documents.DocumentModal.uniqueSigners`,
    defaultMessage: 'A user can only be assigned to one role.',
  },
  warningChangesToRoles: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Documents.DocumentModal.warningChangesToRoles`,
    defaultMessage:
      'Warning: Any changes to the roles will reflect to all instances of this document template within this document signing stage.',
  },
});
