export const FILE_RECOLLECTION_REDUCER_KEY = 'fileRecollectionDrawer';
export const REDUCER_KEY = 'templateMenu';

export const MessageTemplatesType = {
  GENERAL: 'general',
  I9_REDO_FORM: 'i9_redo_form',
  I9_SECTION_THREE_REMOTE_FORM: 'i9_section_three_remote_form',
  W4_FEDERAL_REDO_FORM: 'w4_federal_redo_form',
  W4_STATE_REDO_FORM: 'w4_state_redo_form',
  RECOLLECTION: 'recollection',
  REQUEST_REVISION: 'request_revision',
  CONVERSATION_REQUEST: 'conversation_request',
};

export const MessageTemplatesVariant = {
  EMAIL: 'email',
  SMS: 'sms',
  WHATSAPP: 'whatsapp',
};

export const FETCH_MESSAGE_TEMPLATES_INIT =
  'app/TemplateMenu/FETCH_MESSAGE_TEMPLATES_INIT';
export const FETCH_MESSAGE_TEMPLATES_SUCCESS =
  'app/TemplateMenu/FETCH_MESSAGE_TEMPLATES_SUCCESS';
export const FETCH_MESSAGE_TEMPLATES_ERROR =
  'app/TemplateMenu/FETCH_MESSAGE_TEMPLATES_ERROR';
