import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  buttonWrapper: {
    padding: theme.spacing(2.5, 2),
    gap: theme.spacing(2),
    borderBottom: theme.customBorders.gray200,
  },
  button: {
    flexGrow: 1,
  },
  menuPaper: {
    borderRadius: theme.misc.borderRadius,
    margin: theme.spacing(1, 0),
    overflowY: 'auto',
    padding: theme.spacing(1, 0),
  },
  list: {
    padding: 0,
  },
  text: {
    color: theme.palette.common.gray800,
  },
  unsupportedAxStages: {
    marginTop: theme.spacing(1),
  },
}));
