import { defineMessages } from 'react-intl';

export const scope =
  'app.containers.WorkflowEditor.components.StageSettingMessageCard';

export const messages = defineMessages({
  cardTitleInitial: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.cardTitleInitial`,
    defaultMessage: 'Initial Message',
  },
  cardTitleTrigger: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.cardTitleTrigger`,
    defaultMessage: 'Follow-Up Message',
  },
  cardTitleRejection: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.cardTitleRejection`,
    defaultMessage: 'Rejection Message',
  },
  cardTitleOnHold: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.cardTitleOnHold`,
    defaultMessage: 'On-Hold Message',
  },
  cardTitleConfirmation: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.cardTitleConfirmation`,
    defaultMessage: 'Confirmation Message',
  },
  cardTitleAvailability: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.cardTitleAvailability`,
    defaultMessage: 'New Availability Message',
  },
  cardTitleCancellation: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.cardTitleCancellation`,
    defaultMessage: 'Cancellation Message',
  },
  cardTitleConsent: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.cardTitleConsent`,
    defaultMessage: 'Consent Message',
  },
  cardTitleReminder: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.cardTitleReminder`,
    defaultMessage: 'Reminder Message',
  },
  cardTitleHired: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.cardTitleHired`,
    defaultMessage: 'Approved Message',
  },
  availabilityTooltip: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.availabilityTooltip`,
    defaultMessage:
      "Only sent to applicants in the stage. If applicant received a message about new availability for this event, they won't receive another one until 24 hours have passed.",
  },
  consentTooltip: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.consentTooltip`,
    defaultMessage:
      'If consent has already been collected, we will only send consent message if information is missing.',
  },
  followUpTooltip: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.followUpTooltip`,
    defaultMessage:
      "Follow-ups are sent once per day between 7 AM and 9 AM in the opening's time zone.",
  },
  bodyTextInitial: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.bodyTextInitial`,
    defaultMessage: 'Send a message when the applicant lands on this stage.',
  },
  bodyTextConsent: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.bodyTextConsent`,
    defaultMessage: 'Send consent message when applicant lands on stage',
  },
  bodyTextTriggerOld: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.bodyTextTriggerOld`,
    defaultMessage:
      'Send a follow-up to remind idle applicants to take action.',
  },
  bodyTextTrigger: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.bodyTextTrigger`,
    defaultMessage:
      'Send a follow-up to remind idle applicants to take action. Follow up message will be skipped if there is no slot availability',
  },
  bodyTextRejection: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.bodyTextRejection`,
    defaultMessage:
      'Send a rejection message when the applicant lands on this stage.',
  },
  bodyTextOnHold: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.bodyTextOnHold`,
    defaultMessage:
      'Send a on-hold message when the applicant lands on this stage.',
  },
  bodyTextConfirmation: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.bodyTextConfirmation`,
    defaultMessage:
      'Sent to applicants after booking a session on the calendar. It contains all details for the scheduled session.',
  },
  bodyTextAvailability: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.bodyTextAvailability`,
    defaultMessage:
      "Sent to applicants when new availability is added if they haven't already booked a session. Max one message per day.",
  },
  bodyTextCancellation: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.bodyTextCancellation`,
    defaultMessage: 'Sent to applicants upon cancelling a session.',
  },
  bodyTextReminder: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.bodyTextReminder`,
    defaultMessage: 'Sent to applicants before their scheduled session.',
  },
  addMessage: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.addMessage`,
    defaultMessage: 'Add Message',
  },
  addMessageTextButton: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.addMessageTextButton`,
    defaultMessage: '+ Add Message',
  },
  infoIcon: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.infoIcon`,
    defaultMessage: 'info icon',
  },
});
