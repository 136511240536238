import { WorkflowEventStage } from 'api-clients/monolith';
import React, { useContext, VFC } from 'react';

import { StageSection } from 'containers/WorkflowEditor/components/StageSection';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { IdleMoveRule } from '../../../IdleMoveRule';
import { StageSettingMessageCard } from '../../../StageSettingMessageCard';
import { SchedulerCard } from './SchedulerCard';

export interface EventStageProps {
  stage: WorkflowEventStage;
  openingExternalId: string;
}

export const EventStage: VFC<EventStageProps> = ({
  stage,
  openingExternalId,
}) => {
  const { setStage } = useContext(StageContext);

  const isSubstage = Boolean(stage.parent_stage_id);

  return (
    <div>
      <StageSection>
        <SchedulerCard
          openingExternalId={openingExternalId}
          stage={stage}
          setStage={setStage}
        />
      </StageSection>
      {!isSubstage && (
        <>
          <StageSection>
            <IdleMoveRule stage={stage} />
          </StageSection>
          <StageSection>
            <StageSettingMessageCard
              messageType="scheduler_v2"
              stageExternalId={stage.external_id}
            />
          </StageSection>
        </>
      )}
      <StageSection>
        <StageSettingMessageCard
          messageType="confirmation"
          stageExternalId={stage.external_id}
        />
      </StageSection>
      <StageSection>
        <StageSettingMessageCard
          messageType="cancellation_v2"
          stageExternalId={stage.external_id}
        />
      </StageSection>
      {!isSubstage && (
        <>
          <StageSection>
            <StageSettingMessageCard
              messageType="reminder"
              stageExternalId={stage.external_id}
            />
          </StageSection>
          <StageSection>
            <StageSettingMessageCard
              messageType="trigger"
              stageExternalId={stage.external_id}
            />
          </StageSection>
        </>
      )}
    </div>
  );
};
