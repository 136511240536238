import { DateTime, Settings } from 'luxon';

import { DateSelect } from './EventSettings/types';

const TIME_FORMAT = 'h:mm a ZZZZ';

const timeConvert = (num: number) => {
  const hours = Math.floor(num / 60);
  const minutes = num % 60;
  return hours >= 1 ? `${hours}h ${minutes}mins` : `${minutes}mins`;
};

export const generateAvailabilityTimes = (date: DateTime, minutes: number) => {
  const localEndDay = date.set({
    hour: 23,
    minute: 59,
  });
  const times = [];
  let hours = 0;
  let selectedDate = date;

  while (selectedDate < localEndDay) {
    hours += minutes;
    times.push({
      label: selectedDate.toFormat(TIME_FORMAT),
      value: selectedDate.toISO(),
      hours: timeConvert(hours),
    });
    selectedDate = selectedDate.plus({ minutes });
  }
  return times;
};

export const generateSplitOptions = (
  startTime: string,
  endTime: string,
  noSplitLabel: string,
) => {
  const start = new Date(startTime);
  const end = new Date(endTime);
  const durationInMs = end.getTime() - start.getTime();
  const durationInMinutes = durationInMs / (1000 * 60); // Convert milliseconds to minutes

  if (durationInMinutes <= 0) {
    return [];
  }

  const splitOptions: { label: string; value: number }[] = [];

  [10, 15, 20, 30, 40, 45, 60, 90, 120, 180, 240].forEach(time => {
    if (durationInMinutes % time === 0 && durationInMinutes > time) {
      splitOptions.push({ label: `${time} minutes`, value: time });
    }
  });

  const noSplitOption = { label: noSplitLabel, value: durationInMinutes };
  splitOptions.sort((a, b) => a.value - b.value).unshift(noSplitOption);
  return splitOptions;
};

const isSameDay = (refDate: Date) => {
  const today = new Date();
  return (
    today.getFullYear() === refDate.getFullYear() &&
    today.getMonth() === refDate.getMonth() &&
    today.getDate() === refDate.getDate()
  );
};

const calculateEndTimeFirstOption = (
  currentTime: DateTime,
  selectedStartTime: DateTime,
) => {
  const currentTimeDayMinutes = currentTime.hour * 60 + currentTime.minute;
  const selectedStartTimeMinutes =
    selectedStartTime.hour * 60 + selectedStartTime.minute;
  const latestEndTime =
    selectedStartTimeMinutes > currentTimeDayMinutes
      ? selectedStartTime
      : currentTime;
  return latestEndTime.plus({ minutes: 15 });
};

// Note: this code also lives in packages/recruiter-ui/app/containers/ScheduleApplicantModal/components/AddAvailabilityModal/index.tsx
// If you make modifications here, you may need to update that file as well
export const availability = (availDate: Date, selectedStartTime: string) => {
  let currentAvailabilityTimes;
  let endDateTime;
  if (isSameDay(availDate)) {
    // start at 15 minutes past the next quarter hour
    const currentTime = DateTime.local();
    const startDate = DateTime.local().plus({
      minutes:
        15 + Math.ceil(currentTime.minute / 15) * 15 - currentTime.minute,
      seconds: -currentTime.second,
      milliseconds: -currentTime.millisecond,
    });
    currentAvailabilityTimes = generateAvailabilityTimes(startDate, 15);

    const endTimeStartFrom = DateTime.fromISO(selectedStartTime);
    endDateTime = calculateEndTimeFirstOption(startDate, endTimeStartFrom);
  } else {
    // represents the selected day for the timezone of the current user
    const availDateLocal = DateTime.fromObject({
      year: availDate.getFullYear(),
      month: availDate.getMonth() + 1,
      day: availDate.getDate(),
      hour: 0,
      minute: 0,
    });
    currentAvailabilityTimes = generateAvailabilityTimes(availDateLocal, 15);

    const endTimeStartFrom = DateTime.fromISO(selectedStartTime);
    endDateTime = calculateEndTimeFirstOption(availDateLocal, endTimeStartFrom);
  }

  return { currentAvailabilityTimes, endDateTime };
};

export const convertDateStringToDateSelect = (
  dateString: string,
): DateSelect => {
  const selectedDate = DateTime.fromISO(dateString);
  const minutes = selectedDate.hour * 60 + selectedDate.minute;

  return {
    label: selectedDate.toFormat(TIME_FORMAT),
    value: selectedDate.toISO(),
    hours: timeConvert(minutes),
  };
};

export const initZone = (zone: string | undefined) => {
  if (zone && DateTime.local().zoneName !== zone) {
    Settings.defaultZone = zone;
  }
};
