import isEqual from 'lodash/isEqual';
import { createSelectorCreator, defaultMemoize } from 'reselect';

/**
 * `createSelector` method that uses deep equal to determine if an object has changed.
 *
 * https://github.com/reduxjs/reselect#q-why-is-my-selector-recomputing-when-the-input-state-stays-the-same
 *
 * [...A] selector created with createSelector that recomputes unexpectedly may be receiving a new object
 * on each update whether the values it contains have changed or not. `createSelector` uses an identity check (===)
 * to detect that an input has changed, so returning a new object on each update means that the selector
 * will recompute on each update.
 *
 * @returns {func}
 */
export const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqual,
);
