/**
 *
 * CustomLabels
 *
 */

import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useRef, useState } from 'react';
import { classNames } from 'react-extras';

import Label from './components/Label';
import useStyles from './styles';

export function CustomLabels({
  customLabels,
  isExpanded,
  showCategories,
  applicant,
  labelsContainerWidth,
}) {
  const [collapsedLabels, setCollapsedLabels] = useState({});

  const classes = useStyles();
  const node = useRef();
  const customLabelsNode = useRef();

  const [isOpen, setIsOpen] = useState(showCategories);

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleOutsideClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOutsideClick = e => {
    if (node.current && !node.current.contains(e.target) && !showCategories) {
      setIsOpen(false);
    }
  };

  const toggleOpen = e => {
    e.stopPropagation();

    setIsOpen(!isOpen);
  };

  const renderCustomLabels = () =>
    customLabels.map((label, index) => (
      <Label
        /* eslint-disable-next-line react/no-array-index-key */
        key={`${label.id}:${label.title}:${index}`}
        uniqueKey={`${label.id}:${label.title}:${index}`}
        applicant={applicant}
        setCollapsedLabels={setCollapsedLabels}
        labelsContainerWidth={labelsContainerWidth}
        customLabel={label}
        isOpen={isOpen}
        isExpanded={isExpanded}
      />
    ));

  /* eslint-disable-next-line testing-library/render-result-naming-convention -- This is not a test! Though `renderCustomLabels` should get extracted as a functional component */
  const renderedCustomLabels = renderCustomLabels(customLabels);

  return (
    <React.Fragment>
      <Grid
        data-testid="Custom-Labels-Container"
        container
        alignItems="center"
        className={classNames(classes.customLabelsContainer, {
          isExpanded,
          isOpen,
        })}
        ref={customLabelsNode}
      >
        <Grid
          ref={node}
          className={classNames(classes.container, {
            [classes.openedCustomLabels]: !showCategories && isOpen,
          })}
        >
          {customLabels && renderedCustomLabels}
        </Grid>
        {!isExpanded && Object.keys(collapsedLabels).length > 0 && (
          <Grid
            className={classes.collapsedCountCircle}
            onClick={toggleOpen}
            role="presentation"
          >
            +{Object.keys(collapsedLabels).length}
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}

CustomLabels.propTypes = {
  isExpanded: PropTypes.bool,
  showCategories: PropTypes.bool,
  applicant: PropTypes.object,
  customLabels: PropTypes.array,
  labelsContainerWidth: PropTypes.number,
};

CustomLabels.defaultProps = {
  customLabels: [],
};

export default memo(CustomLabels);
