import { WorkflowTechCheckStage } from 'api-clients/monolith';
import React, { FC } from 'react';

import { IdleMoveRule } from 'containers/WorkflowEditor/components/IdleMoveRule';
import { StageSection } from 'containers/WorkflowEditor/components/StageSection';
import { StageSettingMessageCard } from 'containers/WorkflowEditor/components/StageSettingMessageCard';

import { AdvancedSettings } from './AdvancedSettings';
import { CustomerSupport } from './CustomerSupport';
import { Requirements } from './Requirements';

export interface TechCheckStageProps {
  stage: WorkflowTechCheckStage;
}

export const TechCheckStage: FC<TechCheckStageProps> = ({ stage }) => {
  return (
    <div data-testid="techCheckStage">
      <StageSection>
        <Requirements stage={stage} />
      </StageSection>
      <StageSection>
        <CustomerSupport stage={stage} />
      </StageSection>
      <StageSection>
        <IdleMoveRule stage={stage} />
      </StageSection>
      <StageSection>
        <StageSettingMessageCard
          messageType="tech_check"
          stageExternalId={stage.external_id}
        />
      </StageSection>
      <StageSection>
        <StageSettingMessageCard
          messageType="trigger"
          stageExternalId={stage.external_id}
        />
      </StageSection>
      <StageSection>
        <AdvancedSettings stage={stage} />
      </StageSection>
    </div>
  );
};
