import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  '@keyframes ripple': {
    '0%': {
      boxShadow: `0 0 0 0 ${theme.palette.common.blue100}`,
    },
    '100%': {
      boxShadow: `0 0 0 24px ${theme.palette.common.blue100}`,
    },
  },
  content: {
    padding: theme.spacing(4),
    border: props =>
      props.hasError || props.error
        ? `1px dashed ${theme.palette.common.error}`
        : `1px dashed ${theme.palette.common.gray300}`,

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  uploadContainer: {
    width: 104,
    height: 104,
    background: theme.palette.background.default,
    borderRadius: 100,
    marginBottom: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadInnerContainer: {
    width: 56,
    height: 56,
    boxShadow: '0px 8px 32px 0px rgba(0, 160, 255, 0.24)',
    borderRadius: 100,
    background: props =>
      props.isActive || props.isHovered
        ? theme.palette.common.blue400
        : 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ripple: {
    animation: '$ripple 0.7s linear',
  },
  blackText: {
    color: theme.palette.common.gray800,
  },
  underlinedText: {
    textDecoration: 'underline',
  },
  subtext: {
    marginTop: theme.spacing(0.5),
  },
  errorMessage: {
    marginTop: theme.spacing(1.25),
    marginBottom: theme.spacing(-2.5),
  },
}));

export default styles;
