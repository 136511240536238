import { defineMessages } from 'react-intl';

export default defineMessages({
  chooseFile: {
    id: 'app.components.CustomDropzone.CustomDropzoneUploadInput.chooseFile',
    defaultMessage: 'choose file',
  },
  dragDrop: {
    id: 'app.components.CustomDropzone.CustomDropzoneUploadInput.dragDrop',
    defaultMessage: 'Drag and drop or',
  },
});
