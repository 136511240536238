import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  analytics: {
    id: 'app.containers.Chatbot.Analytics.analytics',
    defaultMessage: 'Insights',
  },
  faqApplicantsCount: {
    id: 'app.containers.Chatbot.Analytics.faqApplicantsCount',
    defaultMessage: 'Applicants asked questions',
  },
  approvedFaqCount: {
    id: 'app.containers.Chatbot.Analytics.approvedFaqCount',
    defaultMessage: 'Helpful answers',
  },
  rejectedFaqCount: {
    id: 'app.containers.Chatbot.Analytics.rejectedFaqCount',
    defaultMessage: 'Unhelpful answers',
  },
  rejectedFaqPercent: {
    id: 'app.containers.Chatbot.Analytics.rejectedFaqPercent',
    defaultMessage: 'Unhelpful answers',
  },
  linksClickedCount: {
    id: 'app.containers.Chatbot.Analytics.linksClickedCount',
    defaultMessage: 'Source link clicked',
  },
});
