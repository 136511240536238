/**
 *
 * BulkActionQueuedItem
 *
 */

import { CustomTooltip } from '@fountain/fountain-ui-components';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { classNames } from 'react-extras';
import { injectIntl } from 'react-intl';

import ProgressIcon from 'containers/BulkActionQueue/components/ProgressIcon';
import { BULK_ACTION_STATUS } from 'containers/BulkActionQueue/constants';
import ErrorIcon from 'images/error-filled.svg';
import globalMessages from 'shared/global/messages';

import messages from './messages';
import useStyles from './styles';

export function BulkActionQueuedItem({ data, intl }) {
  const [progress, setProgress] = useState(
    data.completed_applicants_count / data.applicants_count,
  );
  const classes = useStyles();

  useEffect(() => {
    const calculateProgress =
      (data.completed_applicants_count / data.applicants_count) * 100;
    const currentProgress = Number.isNaN(calculateProgress)
      ? 0
      : calculateProgress;

    setProgress(currentProgress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.completed_applicants_count]);

  const getTitle = () => {
    switch (data.type) {
      case 'BulkDestroy':
        return intl.formatMessage(messages.deletingApplicants);
      case 'BulkMessage':
        return data.arguments.message_type === 'email'
          ? intl.formatMessage(messages.sendingEmail)
          : intl.formatMessage(messages.sendingSms);
      case 'BulkAssign':
        return `${intl.formatMessage(messages.assigningTo)} ${
          data.arguments.user_name
        }`;
      case 'BulkReject':
        return intl.formatMessage(messages.rejectingApplicants);
      case 'BulkMove':
        if (data.arguments.stage_name) {
          return `${intl.formatMessage(messages.movingTo)} ${
            data.arguments.stage_name
          }`;
        }
        return `${intl.formatMessage(messages.movingTo)} ${intl.formatMessage(
          globalMessages.stage,
        )}`;
      default:
        return `${data.type}`;
    }
  };

  const [isHoveringActionItem, setIsHoveringActionItem] = useState(false);

  const onMouseEnterActionItem = () => {
    setIsHoveringActionItem(true);
  };

  const onMouseLeaveActionItem = () => {
    setIsHoveringActionItem(false);
  };

  const userInfo = `${data.user_name} added ${data.start_time}`;

  const isRunning = data.status === BULK_ACTION_STATUS.running;

  const title = getTitle();

  return (
    <MenuItem
      className={classNames(classes.bulkActionQueueMenuItem, { isRunning })}
      onMouseLeave={onMouseLeaveActionItem}
      disableRipple
    >
      <Grid container justify="space-between" alignItems="center" wrap="nowrap">
        <Grid
          item
          container
          alignItems="center"
          classes={{ root: classes.actionDetails }}
          wrap="nowrap"
        >
          <ProgressIcon
            progress={progress}
            status={data.status}
            isLoading={!data.completed_applicants_count}
          />

          {isHoveringActionItem ? (
            <Typography
              title={`${userInfo} - ${data.status}`}
              className={classes.userInfo}
              variant="body2"
              noWrap
            >
              {userInfo}
            </Typography>
          ) : (
            <>
              <Grid
                className={classes.bulkActionTextContainer}
                onMouseEnter={onMouseEnterActionItem}
              >
                <Typography
                  className={classes.bulkActionText}
                  variant="body2"
                  color="textPrimary"
                  title={title}
                >
                  {title}
                </Typography>
                <Typography variant="body2">
                  {(data.status === 'errored' &&
                    intl.formatMessage(messages.errorLoadingApplicants)) ||
                    (!data.applicants_count &&
                      intl.formatMessage(messages.loadingApplicants)) || (
                      <span>
                        {data.completed_applicants_count}/
                        {data.applicants_count || '--'}
                      </span>
                    )}
                </Typography>
              </Grid>
              {data.errored_applicants_count > 0 && (
                <Grid className={classes.error}>
                  <CustomTooltip
                    dense
                    title={intl.formatMessage(messages.errorCount, {
                      count: data.errored_applicants_count,
                    })}
                  >
                    <Grid container wrap="nowrap">
                      <img src={ErrorIcon} alt="error-icon" />
                      <Typography variant="body2" className={classes.errorText}>
                        {data.errored_applicants_count}
                      </Typography>
                    </Grid>
                  </CustomTooltip>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
      {isRunning && (
        <LinearProgress
          className={classes.progressBar}
          variant="determinate"
          value={progress}
        />
      )}
    </MenuItem>
  );
}

BulkActionQueuedItem.propTypes = {
  data: PropTypes.shape({
    applicants_count: PropTypes.number,
    arguments: PropTypes.object,
    completed_applicants_count: PropTypes.number,
    errored_applicants_count: PropTypes.number,
    id: PropTypes.number,
    status: PropTypes.oneOf([
      'created',
      'pending',
      'running',
      'paused',
      'errored',
      'complete',
      'deleted',
    ]).isRequired,
    type: PropTypes.string,
  }).isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(BulkActionQueuedItem);
