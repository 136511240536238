export function getSessionItem(key, defaultVal = '') {
  return window.sessionStorage
    ? window.sessionStorage.getItem(key)
    : defaultVal;
}

export function setSessionItem(key, val) {
  if (window.sessionStorage) {
    try {
      window.sessionStorage.setItem(key, val);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Session storage not supported');
    }
  }
}

export function getLocalStorageItem(key, defaultVal = '') {
  return window.localStorage ? window.localStorage.getItem(key) : defaultVal;
}

export function setLocalStorageItem(key, val) {
  if (window.localStorage) {
    try {
      window.localStorage.setItem(key, val);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Local storage not supported');
    }
  }
}

export function removeLocalStorageItem(key) {
  if (window.localStorage) {
    window.localStorage.removeItem(key);
  }
}
