/* eslint-disable camelcase */
import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import {
  Action,
  CheckrPackage,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import {
  getActionErrorMessage,
  RUN_ACCURATE_REPORTS,
  RUN_CHECKR_REPORTS,
} from 'containers/WorkflowEditor/components/Rules/Action/constants';
import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';

import { RulesProps } from '../../types';
import { messages } from './messages';

type TargetStageType = {
  id: string;
  title: string;
  type: string;
  stage_id: number;
};

export interface BackgroundConsentDataProps {
  setRules?: React.Dispatch<React.SetStateAction<RulesProps>>;
  setStage?: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
  action: Action;
  ruleId?: string | number;
  errors?: {
    [key: string]: string[];
  };
}

export const BackgroundConsentData: FC<BackgroundConsentDataProps> = React.memo(
  props => {
    const intl = useIntl();

    const { action, ruleId, setRules, setStage, errors } = props;

    const {
      checkr_stages: checkrStages,
      accurate_stages: accurateStages,
      checkr_packages: checkrPackages,
    } = useContext(RulesEditDataContext);

    let stages:
      | {
          id: string;
          stage_id?: number;
          title: string;
        }[]
      | undefined = [];

    if (action.type === RUN_CHECKR_REPORTS) {
      stages = checkrStages;
    } else if (action.type === RUN_ACCURATE_REPORTS) {
      stages = accurateStages;
    }

    const selectedStage =
      stages?.find(stage => stage.stage_id === action.ref_stage_1_id) ?? null;

    const onChangeStage = (stage: TargetStageType) => {
      if (setRules && ruleId) {
        setRules(
          produce((draftRules: RulesProps) => {
            const draftActions =
              draftRules[ruleId]?.action_set_attributes?.actions_attributes ??
              [];

            const idx = draftActions.findIndex(act => act.id === action.id);

            draftActions[idx].ref_stage_1_id = stage.stage_id;
          }),
        );
      } else if (setStage) {
        setStage(
          produce((draftStage: WorkflowStageDetail) => {
            if (
              draftStage.type !== 'RuleStage' &&
              draftStage.type !== 'CustomStage'
            ) {
              return;
            }
            const elseAction =
              draftStage?.additional_info?.else_action_set_attributes
                ?.actions_attributes?.[0];

            if (elseAction) {
              elseAction.ref_stage_1_id = stage.stage_id;
            }
          }),
        );
      }
    };

    const onChangePackage = (newPackage: CheckrPackage) => {
      if (setRules && ruleId) {
        setRules(
          produce((draftRules: RulesProps) => {
            const draftActions =
              draftRules[ruleId]?.action_set_attributes?.actions_attributes ??
              [];

            const idx = draftActions.findIndex(act => act.id === action.id);

            draftActions[idx].extra.package = newPackage.slug;
          }),
        );
      } else if (setStage) {
        setStage(
          produce((draftStage: WorkflowStageDetail) => {
            if (
              draftStage.type !== 'RuleStage' &&
              draftStage.type !== 'CustomStage'
            ) {
              return;
            }
            const elseAction =
              draftStage?.additional_info?.else_action_set_attributes
                ?.actions_attributes?.[0];

            if (elseAction) {
              elseAction.extra.package = newPackage.slug;
            }
          }),
        );
      }
    };

    const errorMessage =
      !action.ref_stage_1_id && getActionErrorMessage('ref_stage_1', errors);

    const selectedCheckrPackage =
      checkrPackages?.find(
        checkrPackage => checkrPackage.slug === action.extra?.package,
      ) ?? null;

    const packageNameErrorMessage =
      !action.extra.package && getActionErrorMessage('package', errors);

    return (
      <>
        <Grid item xs={6}>
          <StyledReactSelect
            value={selectedStage}
            options={stages}
            getOptionLabel={(option: TargetStageType) => option.title}
            getOptionValue={(option: TargetStageType) => option.stage_id}
            onChange={onChangeStage}
            label={intl.formatMessage(messages.selectStage)}
            aria-label={intl.formatMessage(messages.selectStage)}
            error={errorMessage}
            placeholder=""
            required
          />
        </Grid>
        <Grid item xs={6}>
          <StyledReactSelect
            options={checkrPackages}
            value={selectedCheckrPackage}
            onChange={onChangePackage}
            label={intl.formatMessage(messages.package)}
            aria-label={intl.formatMessage(messages.package)}
            getOptionLabel={(option: CheckrPackage) => option.name}
            getOptionValue={(option: CheckrPackage) => option.slug}
            isSearchable
            error={packageNameErrorMessage}
            placeholder=""
            required
          />
        </Grid>
      </>
    );
  },
);
