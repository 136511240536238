/*
 *
 * ApplicantActions reducer
 *
 */
import produce from 'immer';

import {
  CANCEL_AND_RESEND_HELLOSIGN_REQUEST_ERROR,
  CANCEL_AND_RESEND_HELLOSIGN_REQUEST_INIT,
  CANCEL_AND_RESEND_HELLOSIGN_REQUEST_SUCCESS,
  CHECKR_CANADA_RUN_REPORT_ERROR,
  CHECKR_CANADA_RUN_REPORT_INIT,
  CHECKR_CANADA_RUN_REPORT_SUCCESS,
  CHECKR_RUN_REMAINING_REPORT_ERROR,
  CHECKR_RUN_REMAINING_REPORT_INIT,
  CHECKR_RUN_REMAINING_REPORT_SUCCESS,
  CHECKR_RUN_REPORT_ERROR,
  CHECKR_RUN_REPORT_INIT,
  CHECKR_RUN_REPORT_SUCCESS,
  CHECKR_SEND_CONSENT_MESSAGE_ERROR,
  CHECKR_SEND_CONSENT_MESSAGE_INIT,
  CHECKR_SEND_CONSENT_MESSAGE_SUCCESS,
  ONFIDO_RESUME_ERROR,
  ONFIDO_RESUME_INIT,
  ONFIDO_RESUME_SUCCESS,
  ONFIDO_SEND_MESSAGE_ERROR,
  ONFIDO_SEND_MESSAGE_INIT,
  ONFIDO_SEND_MESSAGE_SUCCESS,
  ONFIDO_VOID_ERROR,
  ONFIDO_VOID_INIT,
  ONFIDO_VOID_SUCCESS,
  RESEND_HELLOSIGN_EMAIL_ERROR,
  RESEND_HELLOSIGN_EMAIL_INIT,
  RESEND_HELLOSIGN_EMAIL_SUCCESS,
  RESUBSCRIBE_EMAIL_ERROR,
  RESUBSCRIBE_EMAIL_START,
  RESUBSCRIBE_EMAIL_SUCCESS,
  RESUBSCRIBE_SMS_ERROR,
  RESUBSCRIBE_SMS_START,
  RESUBSCRIBE_SMS_SUCCESS,
  SCHEDULING_CANCEL_ERROR,
  SCHEDULING_CANCEL_INIT,
  SCHEDULING_CANCEL_SUCCESS,
  SCHEDULING_RESEND_SCHEDULER_EMAIL_ERROR,
  SCHEDULING_RESEND_SCHEDULER_EMAIL_INIT,
  SCHEDULING_RESEND_SCHEDULER_EMAIL_SUCCESS,
  SCHOOLKEEP_ENROLL_ERROR,
  SCHOOLKEEP_ENROLL_INIT,
  SCHOOLKEEP_ENROLL_SUCCESS,
  SEND_AUTOMATED_MESSAGE,
  SEND_AUTOMATED_MESSAGE_ERROR,
  SEND_AUTOMATED_MESSAGE_SUCCESS,
  WX_EXPORT_APPLICANT_ERROR,
  WX_EXPORT_APPLICANT_INIT,
  WX_EXPORT_APPLICANT_SUCCESS,
} from './constants';

export const initialState = {
  isSubmitting: false,
};

const applicantActionsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case RESUBSCRIBE_EMAIL_START:
        draft.isSubmitting = true;
        break;
      case RESUBSCRIBE_EMAIL_SUCCESS:
        draft.isSubmitting = false;
        break;
      case RESUBSCRIBE_EMAIL_ERROR:
        draft.isSubmittingEmailReactivation = false;
        break;
      case RESUBSCRIBE_SMS_START:
        draft.isSubmitting = true;
        break;
      case RESUBSCRIBE_SMS_SUCCESS:
        draft.isSubmitting = false;
        break;
      case RESUBSCRIBE_SMS_ERROR:
        draft.isSubmitting = false;
        break;
      case CHECKR_SEND_CONSENT_MESSAGE_INIT:
        draft.isSubmitting = true;
        break;
      case CHECKR_SEND_CONSENT_MESSAGE_SUCCESS:
        draft.isSubmitting = false;
        break;
      case CHECKR_SEND_CONSENT_MESSAGE_ERROR:
        draft.isSubmitting = false;
        break;
      case CHECKR_RUN_REPORT_INIT:
        draft.isSubmitting = true;
        break;
      case CHECKR_RUN_REPORT_SUCCESS:
        draft.isSubmitting = false;
        break;
      case CHECKR_RUN_REPORT_ERROR:
        draft.isSubmitting = false;
        break;
      case CHECKR_RUN_REMAINING_REPORT_INIT:
        draft.isSubmitting = true;
        break;
      case CHECKR_RUN_REMAINING_REPORT_SUCCESS:
        draft.isSubmitting = false;
        break;
      case CHECKR_RUN_REMAINING_REPORT_ERROR:
        draft.isSubmitting = false;
        break;
      case CANCEL_AND_RESEND_HELLOSIGN_REQUEST_INIT:
        draft.isSubmitting = true;
        break;
      case CANCEL_AND_RESEND_HELLOSIGN_REQUEST_SUCCESS:
        draft.isSubmitting = false;
        break;
      case CANCEL_AND_RESEND_HELLOSIGN_REQUEST_ERROR:
        draft.isSubmitting = false;
        break;
      case RESEND_HELLOSIGN_EMAIL_INIT:
        draft.isSubmitting = true;
        break;
      case RESEND_HELLOSIGN_EMAIL_SUCCESS:
        draft.isSubmitting = false;
        break;
      case RESEND_HELLOSIGN_EMAIL_ERROR:
        draft.isSubmitting = false;
        break;
      case SCHOOLKEEP_ENROLL_INIT:
        draft.isSubmitting = true;
        break;
      case SCHOOLKEEP_ENROLL_SUCCESS:
        draft.isSubmitting = false;
        break;
      case SCHOOLKEEP_ENROLL_ERROR:
        draft.isSubmitting = false;
        break;
      case SCHEDULING_CANCEL_INIT:
        draft.isSubmitting = true;
        break;
      case SCHEDULING_CANCEL_SUCCESS:
        draft.isSubmitting = false;
        break;
      case SCHEDULING_CANCEL_ERROR:
        draft.isSubmitting = false;
        break;
      case SCHEDULING_RESEND_SCHEDULER_EMAIL_INIT:
        draft.isSubmitting = true;
        break;
      case SCHEDULING_RESEND_SCHEDULER_EMAIL_SUCCESS:
        draft.isSubmitting = false;
        break;
      case SCHEDULING_RESEND_SCHEDULER_EMAIL_ERROR:
        draft.isSubmitting = false;
        break;
      case CHECKR_CANADA_RUN_REPORT_INIT:
        draft.isSubmitting = true;
        break;
      case CHECKR_CANADA_RUN_REPORT_SUCCESS:
        draft.isSubmitting = false;
        break;
      case CHECKR_CANADA_RUN_REPORT_ERROR:
        draft.isSubmitting = false;
        break;
      case ONFIDO_RESUME_INIT:
        draft.isSubmitting = true;
        break;
      case ONFIDO_RESUME_SUCCESS:
        draft.isSubmitting = false;
        break;
      case ONFIDO_RESUME_ERROR:
        draft.isSubmitting = false;
        break;
      case ONFIDO_SEND_MESSAGE_INIT:
        draft.isSubmitting = true;
        break;
      case ONFIDO_SEND_MESSAGE_SUCCESS:
        draft.isSubmitting = false;
        break;
      case ONFIDO_SEND_MESSAGE_ERROR:
        draft.isSubmitting = false;
        break;
      case ONFIDO_VOID_INIT:
        draft.isSubmitting = true;
        break;
      case ONFIDO_VOID_SUCCESS:
        draft.isSubmitting = false;
        break;
      case ONFIDO_VOID_ERROR:
        draft.isSubmitting = false;
        break;
      case SEND_AUTOMATED_MESSAGE:
        draft.isSubmitting = true;
        break;
      case SEND_AUTOMATED_MESSAGE_SUCCESS:
        draft.isSubmitting = false;
        break;
      case SEND_AUTOMATED_MESSAGE_ERROR:
        draft.isSubmitting = false;
        break;
      case WX_EXPORT_APPLICANT_INIT:
        draft.isSubmitting = true;
        break;
      case WX_EXPORT_APPLICANT_SUCCESS:
        draft.isSubmitting = false;
        break;
      case WX_EXPORT_APPLICANT_ERROR:
        draft.isSubmitting = false;
        break;
      default:
        break;
    }
  });

export default applicantActionsReducer;
