export const PREVENT_BOOKING_AFTER = 'prevent_booking_after';
export const PREVENT_BOOKING_AFTER_DAYS = 'prevent_booking_after_days';

export const PREVENT_BOOKING_FOR = 'prevent_booking_for';
export const PREVENT_BOOKING_FOR_HOURS = 'prevent_booking_for_hours';

export const LIMIT_RESCHEDULE = 'limit_reschedule';
export const LIMIT_RESCHEDULE_COUNT = 'limit_reschedule_count';

export const LIMIT_CANCEL_TIME = 'limit_cancel_time';
export const LIMIT_CANCEL_TIME_HOURS = 'limit_cancel_time_hours';
