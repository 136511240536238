/* eslint-disable camelcase */
import {
  SimpleModal,
  SimpleModalHeader,
  SimpleModalTitle,
} from '@fountain/fountain-ui-components';
import React, { memo, VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import ImmediateHiringDecisionModalContent from './components/ImmediateHiringDecisionModalContent';
import { messages } from './messages';

export interface ImmediateHiringDecisionModalProps {
  applicantId: string;
  handleClose: () => void;
  schedulerStageId: string;
  schedulerStageType: string;
  attended: 'true' | 'false' | null;
}

export const ImmediateHiringDecisionModal: VFC<
  ImmediateHiringDecisionModalProps
> = ({
  applicantId,
  attended,
  handleClose,
  schedulerStageId,
  schedulerStageType,
}) => {
  return (
    <SimpleModal onClose={handleClose} maxWidth={448} blurOverlay={false}>
      <SimpleModalHeader onClose={handleClose}>
        <SimpleModalTitle>
          <FormattedMessage {...messages.modalHeader} />
        </SimpleModalTitle>
      </SimpleModalHeader>
      <ImmediateHiringDecisionModalContent
        applicantId={applicantId}
        handleClose={handleClose}
        schedulerStageId={schedulerStageId}
        schedulerStageType={schedulerStageType}
        attended={attended === 'true'}
      />
    </SimpleModal>
  );
};

export default memo(ImmediateHiringDecisionModal);
