import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  notificationSettingsTitle: {
    id: 'app.Webhooks.Notifications.notificationSettingsTitle',
    defaultMessage: 'Webhook Notifications Settings',
  },
  notificationSubtitle: {
    id: 'app.Webhooks.Notifications.notificationSubtitle',
    defaultMessage:
      'Select who and when Fountain should notify about failed or disabled Webhooks.',
  },
  emailAddress: {
    id: 'app.Webhooks.Notifications.emailAddress',
    defaultMessage: 'Email address',
  },
  deliveryFails: {
    id: 'app.Webhooks.Notifications.deliveryFails',
    defaultMessage: 'Send email when Webhook delivery fails',
  },
  deliveryRate: {
    id: 'app.Webhooks.Notifications.deliveryRate',
    defaultMessage: 'Hourly threshold for failure',
  },
  isDisabled: {
    id: 'app.Webhooks.Notifications.isDisabled',
    defaultMessage: 'Send email when Webhook is disabled',
  },
  save: {
    id: 'app.Webhooks.Notifications.save',
    defaultMessage: 'Save',
  },
  updateSuccess: {
    id: 'app.Webhooks.Flash.updateSuccess',
    defaultMessage: 'Updated Webhooks Settings',
  },
  deleteEmail: {
    id: 'app.Webhooks.EmailAddress.deleteEmail',
    defaultMessage: 'Delete Email',
  },
  emailCount: {
    id: 'app.Webhooks.EmailAddress.emailsCount',
    defaultMessage: '{count} email addresses',
  },
  showAllEmails: {
    id: 'app.Webhooks.EmailAddress.showAllEmails',
    defaultMessage: 'Show all emails',
  },
});
