import { Loader } from '@fountain/fountain-ui-components';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import LinkIcon from '@material-ui/icons/Link';
import { WorkflowBuilderFunnelStage } from 'api-clients/monolith';
import React, { useEffect, useRef, VFC } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import {
  makeSelectPolicies,
  makeSelectWhoami,
} from 'containers/Auth_old/selectors';
import useCreateTestApplicant from 'hooks/useCreateTestApplicant';
import useUserPermissions from 'hooks/useUserPermissions';

import { messages } from '../messages';

const useStyles = makeStyles(theme => ({
  previewWorkflowContainer: {
    width: '632px',
    borderRight: theme.customBorders.gray200,
    position: 'relative',
  },
  header: {
    padding: theme.spacing(2.5, 2),
    background: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '64px',
    borderBottom: `1px solid ${theme.palette.common.gray200}`,
  },
  links: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  rotateIcon: {
    transform: 'rotate(145deg)',
  },
  button: {
    border: 0,
    padding: 0,
    color: theme.palette.primary.main,
    '&:hover': {
      border: 0,
      background: 'inherit',
    },
    '& svg, & img': {
      width: '16px',
      height: '16px',
      marginRight: '-4px',
    },
  },
  loader: {
    width: '100%',
    position: 'absolute',
    height: 'calc(100% - 64px)',
    top: '64px',
    background: 'rgb(0 0 0 / 50%)',
    padding: theme.spacing(3),
  },
}));

type UrlParams = {
  accountSlug: string;
  funnelSlug: string;
};

export interface PreviewWorkflowProps {
  selectedStage: WorkflowBuilderFunnelStage | undefined;
  funnelExternalId: string;
}

export const PreviewWorkflow: VFC<PreviewWorkflowProps> = ({
  selectedStage,
  funnelExternalId,
}) => {
  const classes = useStyles();
  const iframeReference = useRef(null);
  const intl = useIntl();
  const { accountSlug, funnelSlug } = useParams<UrlParams>();

  const { workflow_editor_v2_enabled: isWorkflowEditorV2Enabled } = useSelector(
    makeSelectWhoami(),
  );
  const { manageFunnelsPermission } = useUserPermissions();
  const { manage_workflows: manageWorkflows } = useSelector(
    makeSelectPolicies(),
  );
  const showWorkflowV2 =
    (!!manageFunnelsPermission || manageWorkflows) && isWorkflowEditorV2Enabled;

  const { handleCreateTestApplicant, isLoading: previewLoading } =
    useCreateTestApplicant();

  useEffect(() => {
    if (iframeReference.current && selectedStage) {
      void handleCreateTestApplicant(
        funnelExternalId,
        selectedStage.external_id,
        iframeReference.current,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStage]);

  const workflowEditorUrl = () => {
    if (showWorkflowV2) {
      return `/${accountSlug}/openings/${funnelSlug}/workflow`;
    }
    return `${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/openings/${funnelSlug}/workflow`;
  };

  const redirectWorkflowEditor = () => {
    window.open(workflowEditorUrl());
  };

  const redirectApplyUrl = () => {
    window.open(
      `${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/apply/${funnelSlug}?preview=true`,
    );
  };

  return (
    <>
      <Grid className={classes.previewWorkflowContainer}>
        <div className={classes.header}>
          <Typography variant="h3">
            {intl.formatMessage(messages.applicantPreviewWorkflow)}
          </Typography>
          <div className={classes.links}>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.button}
              startIcon={<EditIcon />}
              onClick={redirectWorkflowEditor}
            >
              <Typography variant="h4" color="primary">
                {intl.formatMessage(messages.editWorkflow)}
              </Typography>
            </Button>

            <Button
              variant="outlined"
              color="secondary"
              className={classes.button}
              startIcon={<LinkIcon className={classes.rotateIcon} />}
              onClick={redirectApplyUrl}
            >
              <Typography variant="h4" color="primary">
                {intl.formatMessage(messages.testApplicant)}
              </Typography>
            </Button>
          </div>
        </div>
        {previewLoading && (
          <div className={classes.loader}>
            <Loader block size="2rem" />
          </div>
        )}

        <iframe
          title="Preview"
          ref={iframeReference}
          width="100%"
          style={{
            height: 'calc(100% - 64px)',
            padding: '16px',
            border: 0,
          }}
        />
      </Grid>
    </>
  );
};
