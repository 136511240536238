import { ReloadableResultStatus } from 'api/resultStatus';
import { Reason, RulesEditData } from 'api-clients/monolith';
import React, { Dispatch, SetStateAction } from 'react';

export type ArchivedReasonsResponse = ReloadableResultStatus<
  {
    // eslint-disable-next-line camelcase
    archived_reasons: Reason[];
  },
  unknown
>;
export type RejectedReasonsResponse = ReloadableResultStatus<
  {
    // eslint-disable-next-line camelcase
    rejection_reasons: Reason[];
  },
  unknown
>;

type RulesEditDataContextExtras = {
  archivedReasonsResult: ArchivedReasonsResponse;
  rejectedReasonsResult: RejectedReasonsResponse;
  setFilterToSelectedOnly: Dispatch<SetStateAction<boolean>>;
  openingsResultIsLoading: boolean;
};

export const RulesEditDataContext = React.createContext<
  RulesEditData & RulesEditDataContextExtras
>({} as RulesEditData & RulesEditDataContextExtras);
