import { useEffect, useState } from 'react';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import { apiGet } from 'utils/axios';

export default function useFetchBulkActionsQueue() {
  const [initialBulkActions, setInitialBulkActions] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    let didCancel = false;

    const fetchInitialBulkActions = async () => {
      const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/bulk_actions`;

      setIsFetching(true);
      try {
        const response = await apiGet(url);
        if (!didCancel) {
          const transformedResponse = response.data.bulk_actions.reduce(
            (bulkActions, action) => ({ ...bulkActions, [action.id]: action }),
            {},
          );
          setInitialBulkActions(transformedResponse);
          setError(null);
          setIsFetching(false);
        }
      } catch (e) {
        if (!didCancel) {
          setError(e);
          setIsFetching(false);
        }
      }
    };
    fetchInitialBulkActions();

    return () => {
      didCancel = true;
    };
  }, []);

  return { isFetching, error, initialBulkActions };
}
