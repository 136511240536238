import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  reason: {
    defaultMessage: 'Reason',
    id: `app.containers.WorkflowEditor.components.Rules.Action.MoveToStage.ReasonSelect.reason`,
  },
  stageReasonLoadingError: {
    defaultMessage: 'Failed to load `Move applicant to stage` reasons.',
    id: `app.containers.WorkflowEditor.components.Rules.Action.MoveToStage.ReasonSelect.stageReasonLoadingError`,
  },
});
