import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addAutomatedResponse: {
    id: 'app.containers.Chatbot.AutomatedResponse.addAutomatedResponse',
    defaultMessage: `Add Automated Response`,
  },
  enableFaqBot: {
    id: 'app.containers.Chatbot.AutomatedResponse.enableFaqBot',
    defaultMessage: `Enable FAQ Bot`,
  },
  enableFaqBotSuccess: {
    id: 'app.containers.Chatbot.AutomatedResponse.enableFaqBotSuccess',
    defaultMessage: `Enable FAQ Bot updated successfully`,
  },
  enableFaqBotError: {
    id: 'app.containers.Chatbot.AutomatedResponse.enableFaqBotError',
    defaultMessage: `Error updaing Enable FAQ Bot`,
  },
});
