import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  ccpaContainer: {
    backgroundColor: theme.palette.common.white,
    maxWidth: 744,
    borderRadius: theme.spacing(0.5),
    border: theme.customBorders.gray300,
    boxShadow: theme.customShadows.shadow1,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(4, 3),
  },
  ccpaHeading: {
    marginBottom: theme.spacing(3),
  },
  radio: {
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  privacyLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
}));

export default useStyles;
