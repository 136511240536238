import {
  Button,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import {
  RestrictedLocation,
  WorkflowSchedulerStage,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import produce from 'immer';
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';

import { messages } from './messages';

export interface AddressFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  restrictedLocation?: RestrictedLocation;
  setStage: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
  stage: WorkflowSchedulerStage;
}

export const AddressFormModal: FC<AddressFormModalProps> = ({
  isOpen,
  onClose,
  restrictedLocation,
  setStage,
  stage,
}) => {
  const intl = useIntl();

  const [restrictedLocationName, setRestrictedLocationName] = useState(
    restrictedLocation?.location ?? '',
  );

  const onSubmit = () => {
    setStage(
      produce(stage, draftStage => {
        const draftRestrictedLocations =
          (draftStage.additional_info &&
            draftStage.additional_info.restricted_locations_attributes) ||
          [];

        if (draftStage.additional_info) {
          const idx = draftRestrictedLocations.findIndex(
            location => location.location === restrictedLocation?.location,
          );

          if (draftRestrictedLocations[idx]) {
            draftRestrictedLocations[idx] = {
              ...restrictedLocation,
              location: restrictedLocationName,
            };
          } else {
            draftStage.additional_info.restricted_locations_attributes = [
              ...draftRestrictedLocations,
              {
                id: restrictedLocation?.id ?? uuid(),
                location: restrictedLocationName,
              },
            ];
          }
        }
      }),
    );

    onClose();
  };

  return (
    <Modal
      ariaLabelledBy={intl.formatMessage(messages.addOnsiteAddress)}
      disableBackdropClick
      fullScreenOnMobile
      onClose={onClose}
      open={isOpen}
    >
      <ModalHeader
        ariaLabelledBy={intl.formatMessage(messages.addOnsiteAddress)}
        onClose={onClose}
        showIcon={false}
      >
        {intl.formatMessage(messages.addOnsiteAddress)}
      </ModalHeader>
      <ModalContent dividers>
        <Grid>
          <Input
            autoFocus
            value={restrictedLocationName}
            onChange={(e: { target: { value: string } }) =>
              setRestrictedLocationName(e.target.value)
            }
            label={intl.formatMessage(messages.address)}
          />
        </Grid>
      </ModalContent>
      <ModalFooter>
        <Button
          aria-label={intl.formatMessage(messages.cancel)}
          onClick={onClose}
          size="small"
          type="secondary"
          autoWidth
        >
          {intl.formatMessage(messages.cancel)}
        </Button>
        <Button
          aria-label={intl.formatMessage(messages.saveAddress)}
          onClick={onSubmit}
          size="small"
          autoWidth
          disabled={!restrictedLocationName}
        >
          {intl.formatMessage(messages.saveAddress)}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
