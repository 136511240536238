import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import {
  addNewMessageAction,
  removeApplicantMessageAction,
} from 'containers/ApplicantTableV2/actions';
import { addDefaultErrorMessageAction } from 'containers/FlashMessage/actions';
import { apiPost } from 'utils/axios';

export default function useResendMessage(applicantId) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleResend = async messageId => {
    setIsLoading(true);
    const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/messages/${messageId}/resend`;

    try {
      const res = await apiPost(url);
      if (res.data) {
        // POST - resend creates new Message object in db
        // swap old message with failed status with newly created message
        dispatch(removeApplicantMessageAction(applicantId, messageId));
        dispatch(addNewMessageAction(applicantId, res.data.message));
        setError('');
      }
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error) {
      dispatch(addDefaultErrorMessageAction());
    }
  }, [dispatch, error]);

  return { handleResend, isLoading };
}
