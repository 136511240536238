import { makeStyles } from '@material-ui/core/styles';

const HIRING_GOAL_FIELD_WIDTH = '132px';

const useStyles = makeStyles(theme => ({
  distributeRule: {
    marginBottom: theme.spacing(2),
    position: 'relative',

    '&.hasErrors': {
      marginBottom: theme.spacing(3),
    },
  },
  dropdown: {
    '&.disabled': {
      '& > div > div': {
        background: theme.palette.common.gray100,
      },
    },
  },
  deleteRuleContainer: {
    position: 'absolute',
    left: HIRING_GOAL_FIELD_WIDTH,
    top: theme.spacing(1.25),

    '&.isFirstRule': {
      top: theme.spacing(3.5),
    },
  },
  deleteRule: {
    border: 'none',
    background: 'none',
  },
  hiringGoalsContainer: {
    position: 'relative',
    width: HIRING_GOAL_FIELD_WIDTH,
  },
  locationsDropdown: {
    marginRight: theme.spacing(1),
  },
  openingsDropdown: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  smallTitle: {
    fontSize: '12px',
    color: theme.palette.common.gray200,
  },
  stagesDropdown: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  percentageField: {
    border: theme.customBorders.gray300,
    boxShadow: theme.customShadows.selectDropdown,

    '&.disabled': {
      background: theme.palette.common.gray100,
    },
  },
  percentageErrorMsg: {
    display: 'flex',
    marginTop: theme.spacing(0.5),
    lineHeight: `${theme.spacing(2)}px`,
  },
}));

export default useStyles;
