import { Input } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import {
  TechCheckStageBrowser,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import { validate } from 'compare-versions';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Error } from 'components/Error';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import {
  FIREFOX,
  SUPPORTED_BROWSER,
  SUPPORTED_BROWSERS_LIST,
} from './constants';
import { messages } from './messages';
import { useStyles } from './styles';

export interface BrowsersInputsProps {
  browsers?: Array<TechCheckStageBrowser>;
  settingId: number | string;
}

export const BrowsersInputs: FC<BrowsersInputsProps> = React.memo(
  ({ browsers, settingId }) => {
    const intl = useIntl();
    const styles = useStyles();

    const { updateStageResult, setStage } = useContext(StageContext);
    const errors =
      (updateStageResult?.isError && updateStageResult.error?.errors) ||
      undefined;

    const browserVersionsExist = browsers?.some(browser => browser.version);

    const onChangeBrowserVersion =
      (type: SUPPORTED_BROWSER) =>
      (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = event.target;
        setStage(
          produce((draftStage: WorkflowStageDetail) => {
            if (
              draftStage.type === 'TechCheckStage' &&
              draftStage.additional_info.settings
            ) {
              const draftSetting = draftStage.additional_info.settings.find(
                s => s.id === settingId,
              );
              if (draftSetting) {
                if (draftSetting.browsers_info) {
                  const { browsers = [] } = draftSetting.browsers_info;
                  const browser = browsers.find(b => b.name === type);
                  if (browser) {
                    browser.version = value;
                  } else {
                    browsers.push({ name: type, version: value });
                  }
                } else {
                  draftSetting.browsers_info = {
                    browsers: [{ name: type, version: value }],
                  };
                }
              }
            }
          }),
        );
      };

    return (
      <>
        <Grid>
          <Typography variant="body2" className={styles.heading}>
            <FormattedMessage {...messages.browserHeading} />{' '}
            <span className={styles.requiredAsterix}>*</span>
            <Error
              error={
                !browserVersionsExist &&
                Boolean(errors?.['settings.browsers_info']) &&
                intl.formatMessage(messages.browserVersionMustExist)
              }
            />
          </Typography>
        </Grid>
        {SUPPORTED_BROWSERS_LIST.map(browserType => {
          const browser = browsers?.find(
            browser => browser.name === browserType,
          );
          const validBrowserVersion = Boolean(
            browser?.version && validate(browser.version),
          );
          /*
              DB values for browsers_info are stored in a JSON format an require a different
              error structure to associate errors to the correct input
            */
          const errorMessageForType = (
            errors?.['settings.browsers_info']?.find(
              error =>
                !validBrowserVersion &&
                Boolean(
                  (error as unknown as Record<string, string>)?.[browserType],
                ),
            ) as unknown as Record<string, string>
          )?.[browserType];

          return (
            <Grid
              key={`${settingId}-${browserType}`}
              className={styles.browserRow}
            >
              <Input
                aria-label={browserType === FIREFOX ? 'Firefox' : browserType}
                label={browserType === FIREFOX ? 'Firefox' : browserType}
                value={browser?.version}
                onChange={onChangeBrowserVersion(browserType)}
                error={
                  (!browserVersionsExist &&
                    Boolean(errors?.['settings.browsers_info'])) ||
                  Boolean(browser?.version && errorMessageForType)
                }
              />
              <Error
                error={
                  (!browserVersionsExist &&
                    Boolean(errors?.['settings.browsers_info']) &&
                    intl.formatMessage(messages.addVersion)) ||
                  (browser?.version && errorMessageForType)
                }
                align="right"
              />
            </Grid>
          );
        })}
      </>
    );
  },
);
