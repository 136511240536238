/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WorkflowBuilderChatbotInitResponse } from '../models/WorkflowBuilderChatbotInitResponse';
import type { WorkflowBuilderChatbotRequest } from '../models/WorkflowBuilderChatbotRequest';
import type { WorkflowBuilderChatbotUpdateResponse } from '../models/WorkflowBuilderChatbotUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WorkflowBuilderService {

    /**
     * Collect and update response
     * @param requestBody Workflow Builder chatbot request
     * @returns WorkflowBuilderChatbotUpdateResponse successful
     * @throws ApiError
     */
    public static putInternalApiAiBuilderWorkflowChat(
        requestBody: WorkflowBuilderChatbotRequest,
    ): CancelablePromise<WorkflowBuilderChatbotUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/internal_api/ai_builder/workflow/chat',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Initialize chat
     * @param requestBody Workflow Builder chatbot request
     * @returns WorkflowBuilderChatbotInitResponse successful
     * @throws ApiError
     */
    public static postInternalApiAiBuilderWorkflowChat(
        requestBody: WorkflowBuilderChatbotRequest,
    ): CancelablePromise<WorkflowBuilderChatbotInitResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/ai_builder/workflow/chat',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get latest messages with stages
     * @param funnelSlug funnel_slug
     * @param threadId
     * @returns WorkflowBuilderChatbotUpdateResponse successful
     * @throws ApiError
     */
    public static getInternalApiAiBuilderWorkflowGetLatestMessage(
        funnelSlug: string,
        threadId: string,
    ): CancelablePromise<WorkflowBuilderChatbotUpdateResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/ai_builder/workflow/get_latest_message',
            query: {
                'funnel_slug': funnelSlug,
                'thread_id': threadId,
            },
        });
    }

}