import { FormControlLabel, Grid, Switch, Typography } from '@material-ui/core';
import { WorkflowTechCheckStage } from 'api-clients/monolith';
import produce from 'immer';
import React, { useContext, VFC } from 'react';
import { useIntl } from 'react-intl';

import { StageSettingCard } from 'containers/WorkflowEditor/components/StageSettingCard';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import {
  IS_HIDDEN_FROM_PORTAL,
  SHOULD_APPLICANT_SELF_ADVANCE,
} from './constants';
import { messages } from './messages';
import { useStyles } from './styles';

export const AdvancedSettings: VFC<{ stage: WorkflowTechCheckStage }> = ({
  stage,
}) => {
  const intl = useIntl();
  const styles = useStyles();

  const { setStage } = useContext(StageContext);
  const {
    advanced_settings: {
      is_hidden_from_portal: isHiddenFromPortal,
      should_applicant_self_advance: shouldApplicantSelfAdvance,
    },
  } = stage;
  const showInPortal = !isHiddenFromPortal;

  const onToggleChange =
    (
      key: typeof IS_HIDDEN_FROM_PORTAL | typeof SHOULD_APPLICANT_SELF_ADVANCE,
    ) =>
    () => {
      setStage(
        produce(stage, draftStage => {
          draftStage.advanced_settings[key] =
            !draftStage.advanced_settings[key];
        }),
      );
    };

  return (
    <StageSettingCard
      title={intl.formatMessage(messages.advancedSettings)}
      isLoading={false}
      variant="default"
    >
      <Grid container className={styles.container}>
        <Grid item xs={12} className={styles.fieldRow}>
          <FormControlLabel
            key={intl.formatMessage(messages.showStageInPortal)}
            label={
              <Typography variant="body2" className={styles.switchLabel}>
                {intl.formatMessage(messages.showStageInPortal)}
              </Typography>
            }
            control={
              <Switch
                checked={showInPortal}
                onChange={onToggleChange(IS_HIDDEN_FROM_PORTAL)}
              />
            }
          />
        </Grid>
        <Grid item xs={12} className={styles.fieldRow}>
          <FormControlLabel
            key={intl.formatMessage(messages.allowApplicantsToAdvance)}
            label={
              <Typography variant="body2" className={styles.switchLabel}>
                {intl.formatMessage(messages.allowApplicantsToAdvance)}
              </Typography>
            }
            control={
              <Switch
                checked={shouldApplicantSelfAdvance ?? false}
                onChange={onToggleChange(SHOULD_APPLICANT_SELF_ADVANCE)}
              />
            }
          />
        </Grid>
      </Grid>
    </StageSettingCard>
  );
};
