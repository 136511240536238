import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  addAutomatedResponse: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    boxSizing: 'border-box',
    padding: theme.spacing(2, 7),
    maxWidth: 856,
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  backButton: {
    marginRight: 'auto',
    marginBottom: theme.spacing(1),
  },
  inputContent: {
    marginBottom: theme.spacing(2),
  },
  label: {
    textTransform: 'capitalize',
    color: theme.palette.common.gray800,
    fontSize: 14,
    letterSpacing: '0.4px',
    marginBottom: theme.spacing(0.5),
  },
  addAutomatedResponseForm: {
    maxWidth: '100%',
    width: 515,
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  submitButton: {
    marginRight: theme.spacing(2),
  },
  editButton: {
    display: 'flex',
    justifyContent: 'end',
  },
  inputText: {
    '& *': {
      color: `${theme.palette.common.gray700} !important`,
    },
  },
}));
