import { Box, Typography } from '@material-ui/core';
import { eventManagerContext } from 'Calendar/EventManager/context';
import { useStyles } from 'Calendar/EventManager/Events/EventCard/styles';
import { messages } from 'Calendar/EventManager/messages';
import React, { useContext, VFC } from 'react';
import { FormattedMessage } from 'react-intl';

export const NoEvents: VFC = () => {
  const styles = useStyles();
  const { filters } = useContext(eventManagerContext);

  return (
    <Box className={styles.centerMessage}>
      <Typography variant="h3">
        <FormattedMessage {...messages.noEvent} />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage
          {...messages.noEventDetail}
          values={{ title: filters.date }}
        />
      </Typography>
    </Box>
  );
};
