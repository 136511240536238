import './styles.css';

import { Loader } from '@fountain/fountain-ui-components';
import { Grid, Hidden, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CalendarTabs, SideBar } from 'Calendar/Sidebar';
import React, { useEffect, useRef, useState, VFC } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import GlobalNav from 'components/GlobalNav';
import { makeSelectWhoami } from 'containers/Auth_old/selectors';
import { addDefaultErrorMessageActionI18n } from 'containers/FlashMessage/actions';

import { AvailabilityRulesContainer } from './AvailabilityRules';
import { UnsavedChangesPrompt } from './AvailabilityRules/UnsavedChangesPrompt';
import { CalendarContext } from './context';
import { EventManager } from './EventManager';
import { useGetContext } from './hooks';
import { LetsSyncYourCalendar } from './LetsSyncYourCalendar';
import { messages } from './messages';
import { useCalendarStyles, useStyles } from './styles';
import { SyncCalendarSidebox } from './SyncCalendarSidebox';
import { timezoneMessages } from './timezoneMessages';

interface AvailabilityRulesFormProps {
  handleSetDirty: () => void;
  handleUnsetDirty: () => void;
  isDirty: boolean;
}

const AvailabilityRulesForm: VFC<AvailabilityRulesFormProps> = props => {
  const styles = useStyles();
  const timeZone = useSelector(makeSelectWhoami()).time_zone;
  const message =
    timezoneMessages[timeZone as keyof typeof timezoneMessages] ??
    timezoneMessages.unknown;

  return (
    <div className={styles.availabilityContainer}>
      <Typography variant="h2" className={styles.availabilityTitle}>
        <FormattedMessage {...messages.availabilityTitle} />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage {...messages.availabilityDescription} />
      </Typography>
      <Typography variant="h4" className={styles.timezoneLabel}>
        <FormattedMessage {...messages.timezone} />
      </Typography>
      <Typography variant="body2" className={styles.timezoneDisplay}>
        <FormattedMessage {...message} />
      </Typography>
      <AvailabilityRulesContainer {...props} />
    </div>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: CalendarTabs;
  value: CalendarTabs;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;
  const styles = useStyles();

  return (
    <div hidden={value !== index} className={styles.tabPanel}>
      {value === index && children}
    </div>
  );
}

export const Calendar: VFC = () => {
  const styles = useCalendarStyles();
  const { result } = useGetContext();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [renderErrorState, setRenderErrorState] = useState(false);
  const elementToken =
    result.status === 'ready' &&
    result.data.cronofy_response.status === 'success'
      ? result.data.cronofy_response.element_token
      : null;
  const [syncStage, setSyncStage] = useState(0);
  const [isDirty, setIsDirty] = useState(false);
  const [hasConfirmedNavigation, setHasConfirmedNavigation] = useState(false);

  const { pathname } = useLocation();
  const [tabValue, setTabValue] = useState<CalendarTabs>(
    CalendarTabs.Availability,
  );
  const [nextTab, setNextTab] = useState<CalendarTabs>(
    CalendarTabs.Availability,
  );
  const scrollParent = useRef<null | HTMLDivElement>(null);

  const shouldBlockTabNavigation = () => {
    return tabValue === CalendarTabs.Availability && isDirty;
  };

  const handleTabChange = (newValue: CalendarTabs) => {
    if (shouldBlockTabNavigation() && !hasConfirmedNavigation) {
      return setNextTab(newValue);
    }

    return setTabValue(newValue);
  };

  const handleSetDirty = () => setIsDirty(true);
  const handleUnsetDirty = () => setIsDirty(false);
  const handleHasConfirmedNavigation = () => setHasConfirmedNavigation(true);
  const handleNavigation = (tab: CalendarTabs) => {
    setTabValue(tab);
    setNextTab(CalendarTabs.Availability);
    setHasConfirmedNavigation(false);
    setIsDirty(false);
  };

  useEffect(() => {
    if (result.status === 'error') {
      dispatch(addDefaultErrorMessageActionI18n(intl));
      setRenderErrorState(true);
    }
  }, [result, tabValue, dispatch, intl]);

  useEffect(() => {
    if (
      result.status === 'ready' &&
      !result.data.has_calendar_zero &&
      tabValue === CalendarTabs.Availability
    ) {
      setTabValue(CalendarTabs.Session);
    }
  }, [result, tabValue]);

  useEffect(() => {
    if (pathname.indexOf('session') > -1) {
      setTabValue(CalendarTabs.Session);
      return;
    }

    if (pathname.indexOf('calendar-integration') > -1) {
      setTabValue(CalendarTabs.Integration);
      return;
    }

    if (pathname.indexOf('availability') > -1) {
      setTabValue(CalendarTabs.Availability);
    }
  }, [pathname]);

  return (
    <>
      <div>
        <Helmet>
          <title>{intl.formatMessage(messages.schedule)}</title>
          <meta name="description" content="Calendar" />
        </Helmet>
      </div>
      <Grid container direction="column">
        <GlobalNav />
        {renderErrorState && (
          <div>
            <Alert severity="error" icon={false} className={styles.alert}>
              <FormattedMessage {...messages.errorText} />
            </Alert>
            <Typography variant="h2">
              <FormattedMessage {...messages.schedule} />
            </Typography>
            <Typography variant="body2">
              <FormattedMessage {...messages.scheduleDescription} />
            </Typography>
          </div>
        )}
        {result.isLoading && <Loader fullSection fullScreen />}
        {result.status === 'ready' && (
          <CalendarContext.Provider value={result.data}>
            <>
              <Grid container className={styles.gridContent}>
                <SideBar
                  tabValue={tabValue}
                  router={result.data}
                  onChange={handleTabChange}
                />
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={9}
                  lg={10}
                  className={styles.contentContainer}
                  ref={scrollParent}
                >
                  {result.data.has_calendar_zero && (
                    <TabPanel
                      value={tabValue}
                      index={CalendarTabs.Availability}
                    >
                      <Grid container spacing={6}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={8}
                          lg={8}
                          className={styles.scrollContent}
                        >
                          <AvailabilityRulesForm
                            handleSetDirty={handleSetDirty}
                            handleUnsetDirty={handleUnsetDirty}
                            isDirty={isDirty}
                          />
                        </Grid>
                        <Hidden smDown>
                          <Grid item md={4} lg={4}>
                            {(result.data.cronofy_response.status === 'error' ||
                              !result.data.calendar_connected) && (
                              <SyncCalendarSidebox
                                clickHandler={() => {
                                  setSyncStage(1);
                                  setTabValue(CalendarTabs.Integration);
                                }}
                              />
                            )}
                          </Grid>
                        </Hidden>
                      </Grid>
                    </TabPanel>
                  )}

                  <UnsavedChangesPrompt
                    isNavigationBlocked={shouldBlockTabNavigation()}
                    hasConfirmedNavigation={hasConfirmedNavigation}
                    handleHasConfirmedNavigation={handleHasConfirmedNavigation}
                    handleNavigation={handleNavigation}
                    nextTab={nextTab}
                  />
                  <TabPanel value={tabValue} index={CalendarTabs.Session}>
                    <div className={styles.scrollContent}>
                      <EventManager scrollParent={scrollParent.current} />
                    </div>
                  </TabPanel>
                  {result.data.has_calendar_zero && (
                    <TabPanel value={tabValue} index={CalendarTabs.Integration}>
                      <LetsSyncYourCalendar
                        connected={result.data.calendar_connected}
                        elementToken={elementToken}
                        initialState={syncStage}
                      />
                    </TabPanel>
                  )}
                </Grid>
              </Grid>
            </>
          </CalendarContext.Provider>
        )}
      </Grid>
    </>
  );
};
