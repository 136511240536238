import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    width: '85%',
    maxWidth: '1100px',
    margin: 'auto',
    display: 'flex',
    flexGrow: 1,
    overflowY: 'auto',
    padding: theme.spacing(0, 10, 0, 10),
    height: 'calc(100vh - (52px))',
    flexFlow: 'wrap',
    alignContent: 'flex-start',
  },
  header: {
    padding: theme.spacing(2, 0, 2),
    width: '100%',
  },
  loadMore: {
    position: 'absolute',
    borderRadius: 20,
    boxShadow: `1px 1px 0px 0px ${theme.palette.common.blurOverlay}`,
    fontSize: 14,
    left: '54%',
    top: 60,
    height: 30,
  },
  loadMoreIcon: {
    fontSize: 'medium',
  },
}));
