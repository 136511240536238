import { useEffect, useState } from 'react';

const pendingScripts = {};

const getScriptTag = src => {
  if (typeof document === 'undefined') {
    return null;
  }

  return document.querySelector(`script[src='${src}']`);
};

const loadScript = ({ src, id }) =>
  new Promise(resolve => {
    const script = document.createElement('script');
    script.src = src;
    script.id = id;
    script.addEventListener('load', () =>
      resolve({
        element: script,
        script: src,
        success: true,
        error: null,
      }),
    );
    script.addEventListener('error', error => {
      resolve({
        element: script,
        script: src,
        success: false,
        error,
      });
    });
    document.body.appendChild(script);
  });

const loadNewScript = ({ src, id }) => {
  const runningPromise = pendingScripts[src];

  if (runningPromise) {
    return runningPromise;
  }

  const script = getScriptTag(src);

  if (script) {
    const result = {
      element: script,
      script: script.src,
      success: true,
      error: null,
    };

    return Promise.resolve(result);
  }

  pendingScripts[src] = loadScript({ src, id }).then(result => {
    delete pendingScripts[src];

    return result;
  });

  return pendingScripts[src];
};

const removeFailedScript = src => {
  const node = document.querySelector(`script[src='${src}']`);

  if (node && node.parentNode) {
    node.parentNode.removeChild(node);
  }
};

export default function useScript(src, id) {
  // initial state set to true if:
  // 1. script is found in pending, or
  // 2. script doesn't exist in the dom
  const [loading, setLoading] = useState(
    () => pendingScripts[src] || !getScriptTag(src),
  );
  const [error, setError] = useState(null);

  useEffect(() => {
    let didCancel = false;

    const loadScripts = async () => {
      const result = await loadNewScript({ src, id });
      if (didCancel) return;
      if (result) {
        setError(result.error);
        if (!result.success) {
          removeFailedScript(src);
        }
      }
      setLoading(false);
    };

    loadScripts();

    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  return { loading, error, removeFailedScript };
}
