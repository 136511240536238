import { Grid, Typography } from '@material-ui/core';
import React from 'react';

import UploadFile from 'images/upload-file.svg';

import useStyles from './styles';

const MobileCustomDropzoneUploadInput = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.container}>
      <img
        className={classes.uploadIcon}
        src={UploadFile}
        alt="upload a file"
      />
      <Typography className={classes.browseAFile}>Browse a File</Typography>
    </Grid>
  );
};
export default MobileCustomDropzoneUploadInput;
