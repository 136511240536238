import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles';

import { MAX_WIDTH } from './constants';

const generateStyles = (theme: Theme) => {
  const styles: StyleRules<string> = {
    smsDrawerContainer: {
      [theme.breakpoints.down('md')]: {
        maxWidth: MAX_WIDTH,
      },
    },
    sendSmsDrawerContent: {
      height: '100%',
    },
    sendSmsForm: {
      height: '100%',
      width: MAX_WIDTH,

      [theme.breakpoints.down('md')]: {
        '&.messagePreviewOpenForMobileView': {
          display: 'none',
        },
      },
    },
    header: {
      padding: theme.spacing(4, 5),
    },
    messagePreviewIcon: {
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    editorTextArea: {
      backgroundColor: 'white',
      minHeight: 100,
    },
    section: {
      marginBottom: theme.spacing(2.5),
    },
    label: {
      marginBottom: theme.spacing(0.5),
    },
    phoneNumber: {
      color: theme.palette.common.blue400,
    },
    checkboxContainer: {
      padding: theme.spacing(1),
      marginLeft: `-${theme.spacing(1)}px`,
      height: 40,
      display: 'flex',
      alignItems: 'center',
    },
    cancelButton: {
      marginRight: theme.spacing(1),
    },
    messagePreviewContainer: {
      width: 420,
      maxWidth: '100%',
      height: '100%',

      [theme.breakpoints.down('md')]: {
        display: 'none',

        '&.messagePreviewOpenForMobileView': {
          width: MAX_WIDTH,
          display: 'flex',
        },
      },
    },
    messagePreviewDeviceView: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(9),

      [theme.breakpoints.down('md')]: {
        marginTop: 0,
      },
    },
    messagePreviewHeader: {
      padding: theme.spacing(2.5, 3.75),
    },
    backButton: {
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    closeButton: {
      position: 'absolute',
      right: 30,
      top: 20,
    },
    smsInfo: {
      fontSize: 14,
      lineHeight: '21px',
      color: '#969Eb3',
    },
    divideMargin: {
      margin: theme.spacing(3, 0),
    },
    tabs: {
      '& > div': {
        height: '40px',
        '& > span': {
          backgroundColor: theme.palette.common.blue400,
        },
      },
      '& div[role="tablist"]': {
        justifyContent: 'center',
        '& button': {
          minWidth: '120px',
          minHeight: '40px',
          '&:hover': {
            backgroundColor: '#F2F3F7',
          },
          '&.Mui-selected': {
            fontWeight: 500,
            color: theme.palette.common.blue400,
            backgroundColor: theme.palette.common.blue50,
            '&:hover': {
              color: theme.palette.common.blue400,
              backgroundColor: theme.palette.common.blue50,
            },
          },
        },
      },
    },
    noPadding: {
      padding: 0,
    },
    templateDropdown: {
      marginBottom: theme.spacing(5),
    },
    mobilePreviewIcon: {
      display: 'flex',
      alignItems: 'center',
      '& img': {
        marginRight: theme.spacing(0.5),
        height: '14px',
      },
      '& span': {
        marginLeft: theme.spacing(0.5),
        fontSize: '14px',
      },
    },
    radioGroup: { marginLeft: '12px' },
    radioForm: {
      '& .MuiFormControlLabel-label': {
        marginLeft: '3px',
      },
    },
  };
  return styles;
};

const useStyles: () => Record<keyof ReturnType<typeof generateStyles>, string> =
  makeStyles(generateStyles);

export default useStyles;
