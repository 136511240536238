import { defineMessages } from 'react-intl';

export const scope = 'app.components.globalNav.posthireNavLink';

export default defineMessages({
  posthire: {
    id: `${scope}.posthire`,
    defaultMessage: 'Posthire',
  },
});
