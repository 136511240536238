import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  htmlEditor: {
    width: '100%',
    border: theme.customBorders.gray400,
    borderRadius: theme.spacing(1),
    height: theme.spacing(25.5),
    fontSize: theme.typography.fontSize,
    marginBottom: theme.spacing(-0.625),

    '& > .CodeMirror': {
      height: theme.spacing(25.25),
      zIndex: 0,
      borderRadius: theme.spacing(1),
    },
  },
}));

export default useStyles;
