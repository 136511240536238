import { Applicant, Roster } from 'api-clients/monolith';

export interface RosterApplicant extends Applicant {
  attended: string | null;
  checked: boolean;
}

export const attended = (attend: boolean | null): string | null => {
  switch (attend) {
    case true:
      return 'Yes';
    case false:
      return 'No';
    default:
      return null;
  }
};

export const mapSlots = (slots?: Roster[]): RosterApplicant[] => {
  return (
    slots?.map(slot => {
      return {
        ...slot.applicant,
        attended: attended(slot.applicant_showed),
        checked: false,
      };
    }) ?? []
  );
};

export const attendButtons = ['Yes', 'No'] as const;

export type rowActions = 'attended' | 'absent' | 'advance' | 'reject' | 'open';
