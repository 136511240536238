import {
  CustomTooltip,
  IconButton as StyledIconButton,
} from '@fountain/fountain-ui-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { classNames } from 'react-extras';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { makeSelectWhoami } from 'containers/Auth_old/selectors';
import TemplateMenuContent from 'containers/TemplateMenu/TemplateMenuContent';
import TemplateMenuFooter from 'containers/TemplateMenu/TemplateMenuFooter';
import TemplateMenuHeader from 'containers/TemplateMenu/TemplateMenuHeader';
import useLocation from 'hooks/useLocation';
import useTargetStageId from 'hooks/useTargetStageId';
import TemplateIcon from 'images/TemplateIcon';

import { MessageTemplatesVariant } from './constants';
import messages from './messages';
import useStyles from './styles';

export const TemplateMenuVariant = MessageTemplatesVariant;

const TemplateMenu = ({
  buttonProps,
  icon,
  intl,
  onSelected,
  templateType,
  variant,
  isTemplateDialogOpen,
  setIsTemplateDialogOpen,
  isCustomButton,
  listWhatsAppTemplatesOnly,
  forceHtmlModeEnabled,
}) => {
  const currentUser = useSelector(makeSelectWhoami());

  const { applicantId } = useLocation();
  const { selectedApplicantIds, isMasterApplicantsView } = useTargetStageId({});
  const { applicantId: messengerApplicantId } = useParams();

  const sourceAplicantId = isMasterApplicantsView
    ? ''
    : messengerApplicantId || applicantId || selectedApplicantIds[0];

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isTemplateSelected, setIsTemplateSelected] = useState(false);

  useEffect(() => {
    if (isTemplateDialogOpen) {
      setIsDialogOpen(true);
    }
  }, [isTemplateDialogOpen]);

  const styles = useStyles({ isTemplateSelected });
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const handleClickButton = () => setIsDialogOpen(true);
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setIsTemplateDialogOpen(false);
  };

  const handleSelected = template => {
    onSelected(template);
    setIsTemplateSelected(true);
    setIsDialogOpen(false);
    forceHtmlModeEnabled(!!template.html_mode);
    setIsTemplateDialogOpen(false);
  };

  const mergedButtonProps = {
    ...buttonProps,
    className: classNames(styles.button, buttonProps.className),
    onClick: buttonProps.onClick || handleClickButton,
  };

  const templateMenuContentProps = {
    onSelected: handleSelected,
    templateType,
    variant,
    listWhatsAppTemplatesOnly,
    sourceAplicantId,
  };

  return (
    <>
      {!isCustomButton && (
        <StyledIconButton
          data-testid="Template-Dialog"
          aria-controls="template-dialog"
          aria-haspopup="true"
          {...mergedButtonProps}
        >
          {icon || (
            <CustomTooltip
              title={intl.formatMessage(messages.iconTooltip)}
              dense
            >
              <TemplateIcon className={styles.templateIcon} />
            </CustomTooltip>
          )}
        </StyledIconButton>
      )}
      <Dialog
        fullScreen={isMobile}
        id="template-dialog"
        onClose={handleCloseDialog}
        open={isDialogOpen}
        PaperProps={{
          className: classNames({ [styles.dialog]: !isMobile }),
        }}
      >
        <DialogTitle className={styles.dialogTitle} disableTypography>
          <TemplateMenuHeader onClose={handleCloseDialog} />
        </DialogTitle>
        <DialogContent className={styles.dialogContent} dividers>
          <TemplateMenuContent {...templateMenuContentProps} />
        </DialogContent>
        {!isMobile && currentUser.can_manage_message_templates && (
          <DialogActions className={styles.dialogActions} disableSpacing>
            <TemplateMenuFooter />
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

TemplateMenu.propTypes = {
  buttonProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  onSelected: PropTypes.func.isRequired,
  templateType: PropTypes.string,
  variant: PropTypes.string.isRequired,
  isTemplateDialogOpen: PropTypes.bool,
  setIsTemplateDialogOpen: PropTypes.func,
  isCustomButton: PropTypes.bool,
  listWhatsAppTemplatesOnly: PropTypes.bool,
};

TemplateMenu.defaultProps = {
  buttonProps: {
    className: '',
  },
  icon: null,
  isTemplateDialogOpen: false,
  isCustomButton: false,
};

export default injectIntl(TemplateMenu);
