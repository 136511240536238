import { ChatbotService } from 'api-clients/monolith';
import { useApiService } from 'hooks';
import React from 'react';

export function useGetSampleContent() {
  const fetchSampleContent = React.useCallback(
    () => ChatbotService.getInternalApiChatbotAutomatedResponsesSample(),
    [],
  );

  return useApiService(fetchSampleContent);
}
