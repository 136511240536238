import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React, { FC } from 'react';

export const HiredStageIcon: FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM11.5303 6.28033C11.8232 5.98744 11.8232 5.51256 11.5303 5.21967C11.2374 4.92678 10.7626 4.92678 10.4697 5.21967L7.46077 8.22857L6.23809 7.18056C5.9236 6.91099 5.45012 6.94741 5.18056 7.26191C4.91099 7.5764 4.94741 8.04988 5.26191 8.31944L7.01191 9.81944C7.30946 10.0745 7.75321 10.0574 8.03033 9.78033L11.5303 6.28033Z"
    />
  </SvgIcon>
);
