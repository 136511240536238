export const ALLOW_ADVANCE_ON_SELECT_TIME_SLOT =
  'allow_advance_on_select_time_slot';
export const RECEIVE_REMINDER_AWAY_FROM_STAGE =
  'receive_reminder_away_from_stage';
export const IS_HIDDEN_FROM_PORTAL = 'is_hidden_from_portal';
export const SHOW_APPLICANT_ONE_SESSION_PER_TIME_FRAME =
  'show_applicant_one_session_per_time_frame';
export const POST_INTERVIEW_MOVE_APPLICANT_TO_NEXT_STAGE =
  'post_interview_move_applicant_to_next_stage';
export const FUNNEL_STATUS_RESPONDS_TO_BOOKED_SLOTS =
  'funnel_status_responds_to_booked_slots';
export const FUNNEL_STATUS_SLOTS = 'funnel_status_slots';
export const FUNNEL_STATUS_DAYS = 'funnel_status_days';
export const FUNNEL_STATUS_RECIPIENT = 'funnel_status_recipient';
