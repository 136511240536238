import { Opening } from 'api-clients/monolith';

export interface Filterable {
  title: string;
  id: string;
  name: 'stage' | 'opening' | 'locationGroup';
}

export const mapFilterable = (
  filterable: (Opening | string)[] | undefined,
): Filterable[] => {
  if (!filterable) return [];
  return filterable.map(option => {
    switch (typeof option) {
      case 'string':
        return { title: option, id: option, name: 'stage' };
      default:
        return {
          title: option.funnel_name,
          id: option.funnel_external_id,
          name: 'opening',
        };
    }
  });
};
