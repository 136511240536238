/**
 *
 * SearchInput
 *
 */

import { IconButton, Input } from '@fountain/fountain-ui-components';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import React, { forwardRef, memo } from 'react';
import { classNames } from 'react-extras';

import CloseIconSmall from 'images/close-icon-sm.svg';
import SearchIcon from 'images/icon-search.svg';

import useStyles from './styles';

// Refer to below for allowed props
// https://material-ui.com/api/input/
const SearchInput = forwardRef((props, ref) => {
  const { className, fullWidth, onClear, size, value, ...rest } = props;

  const classes = useStyles({ fullWidth });

  return (
    <Input
      className={classes.inputStyle}
      endAdornment={
        value &&
        value.length > 0 && (
          <IconButton
            className={classes.clearButton}
            onClick={onClear}
            size="small"
          >
            <img src={CloseIconSmall} alt="close-icon" />
          </IconButton>
        )
      }
      inputRef={ref}
      rootClass={classNames(classes.searchInput, className)}
      size={size}
      startAdornment={
        <InputAdornment disablePointerEvents className={classes.searchIcon}>
          <img src={SearchIcon} alt="search" />
        </InputAdornment>
      }
      value={value}
      variant="filled"
      {...rest}
    />
  );
});

SearchInput.propTypes = {
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  onClear: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium']),
  value: PropTypes.string,
};

SearchInput.defaultProps = {
  size: 'small',
};

export default memo(SearchInput);
