import queryString from 'query-string';
import { matchPath } from 'react-router-dom';

import { Brand } from 'containers/Auth_old/selectors';
import { Funnel } from 'containers/Messenger/types';
import { history } from 'utils/history';

export interface FountainLocation {
  address: string;
  /* eslint-disable camelcase */
  brand_id: number | null;
  external_id: string;
  funnels: Funnel[];
  location_id: number;
  /* eslint-enable camelcase */
  id: string | number | null;
  name: string;
}

type QueryParams = Record<
  string,
  string | string[] | null | number | undefined | boolean
>;

/**
 *
 * @description updates query params and turns them into a string
 * @param newParams
 * @returns {string} updated query params
 *
 */
export const updateParams = (newParams: QueryParams) => {
  const { search } = history.location;
  const existingParams = queryString.parse(search) as QueryParams;

  Object.keys(newParams).forEach(newKey => {
    existingParams[newKey] = newParams[newKey];
  });

  return queryString.stringify(existingParams);
};

/**
 *
 * @description updates the url with `newParams`
 * @param newParams
 *
 */
export const setParamsToUrl = (newParams: QueryParams) => {
  const { pathname } = history.location;

  const params = updateParams(newParams);

  history.push({
    pathname,
    search: params,
  });
};

/**
 *
 * @description replaces the url with `newParams`
 * @param newParams
 *
 */
export const setParamsToUrlWithReplace = (newParams: QueryParams) => {
  const { pathname } = history.location;

  const params = updateParams(newParams);

  history.replace({
    pathname,
    search: params,
  });
};

/**
 *
 * @description updates the current params with `paramsToRemove`
 * @param paramsToRemove
 * @returns current url params after removing params from `paramsToRemove`
 *
 */
export const removeParams = (paramsToRemove: QueryParams) => {
  const { search } = history.location;
  const existingParams = queryString.parse(search);

  Object.keys(paramsToRemove).forEach(newKey => {
    delete existingParams[newKey];
  });

  return queryString.stringify(existingParams);
};

/**
 *
 * @description updates the url with `paramsToRemove`
 * @param paramsToRemove
 *
 */
export const removeParamsFromUrl = (paramsToRemove: QueryParams) => {
  const { pathname } = history.location;

  const params = removeParams(paramsToRemove);

  history.push({
    pathname,
    search: params,
  });
};

/**
 *
 * @description updates and replaces the url with `paramsToRemove`
 * @param paramsToRemove
 *
 */
export const removeParamsFromUrlWithReplace = (paramsToRemove: QueryParams) => {
  const { pathname } = history.location;

  const params = removeParams(paramsToRemove);

  history.replace({
    pathname,
    search: params,
  });
};

interface ApplicantTablePath {
  jobId: string;
  stageExternalId: string;
  accountSlug: string;
  brandSlug?: string;
  locationId?: string;
}

/**
 * @description used where stage id is inaccessible (i.e. global modals)
 * @param param location
 * @returns {string} StageId
 */
export const getStageIdFromUrl = ({ location }: { location: Location }) => {
  const deprecatingMatch = matchPath<ApplicantTablePath>(location.pathname, {
    path: '/:accountSlug/brands/:brandSlug/locations/:locationId/jobs/:jobId/v2/stages/:stageExternalId',
    exact: true,
    strict: false,
  });

  const match = matchPath<ApplicantTablePath>(location.pathname, {
    path: '/:accountSlug/jobs/:jobId/v2/stages/:stageExternalId',
    exact: true,
    strict: false,
  });

  const currentMatch = deprecatingMatch ?? match;

  return currentMatch ? currentMatch?.params?.stageExternalId : undefined;
};

/**
 * @description parses JobId from url params
 * @param param location
 * @returns {string} JobId
 */
export const getJobIdFromUrl = ({ location }: { location: Location }) => {
  const deprecatingMatch = matchPath<ApplicantTablePath>(location.pathname, {
    path: '/:accountSlug/brands/:brandSlug/locations/:locationId/jobs/:jobId/v2/stages/:stageExternalId',
    exact: true,
    strict: false,
  });

  const match = matchPath<ApplicantTablePath>(location.pathname, {
    path: '/:accountSlug/jobs/:jobId/v2/stages/:stageExternalId',
    exact: true,
    strict: false,
  });

  const currentMatch = deprecatingMatch ?? match;

  return currentMatch ? currentMatch.params.jobId : undefined;
};

/**
 *
 * @param location
 * @param funnel
 * @param brands
 * @param accountSlug
 * @returns {string} returns the entry point url for a given funnel's ``Applicant Table` or `Workflow Editor``
 */
export const generateLink = (
  location: FountainLocation,
  funnel: Funnel,
  brands: Brand[],
  accountSlug: string,
) => {
  const brand = brands.find(({ brand_id: brandId, default: brandDefaultId }) =>
    location.brand_id ? brandId === location.brand_id : brandDefaultId,
  );

  const matchWorkflowEditorPath = matchPath(window.location.pathname, {
    path: '/:accountSlug/openings/:funnelSlug/workflow/:stageSlug',
    exact: true,
    strict: false,
  });

  if (matchWorkflowEditorPath) {
    return `/${accountSlug}/openings/${funnel.slug ?? 'unknown'}/workflow`;
  }

  // This link is used for Applicant Table
  return `/${accountSlug}/brands/${
    (brand && brand.slug) || 'no_brand'
  }/locations/${location.external_id}/jobs/${funnel.job_id}/v2/stages`;
};
