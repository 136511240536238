import { EventsService } from 'api-clients/monolith';
import { useCallback } from 'react';

import { useApiService } from 'hooks/useApiService';

export const useStageTitles = ({
  query,
  enterprise,
}: {
  query: string;
  enterprise?: boolean;
}) => {
  const fetch = useCallback(
    () => EventsService.getInternalApiEventsStageTitles(query, enterprise),
    [enterprise, query],
  );

  return useApiService(fetch);
};
