import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    closedButton: {
      width: theme.spacing(23),
      border: `1px ${theme.palette.common.gray300} solid`,
      borderRadius: theme.spacing(0.75),
    },
    openButton: {
      width: theme.spacing(23),
      border: `2px ${theme.palette.common.blue400} solid`,
      borderRadius: theme.spacing(0.75),
      float: 'right',
      position: 'relative',
    },
    buttonInnerContainer: {
      display: 'flex',
      width: '100%',
      padding: theme.spacing(0.5, 1),
      color: theme.palette.common.gray600,
    },
    dismissableInnerContainer: {
      display: 'inline-flex',
      padding: theme.spacing(0.75, 1, 0.75, 1.5),
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: theme.spacing(1.25),
    },
    openCaret: {
      transform: 'rotate(180deg) translate(0px, -2px)',
      cursor: 'pointer',
    },
    closedCaret: {
      transform: 'translate(0px, 4px)',
      cursor: 'pointer',
    },
    plainIcon: {
      margin: '0px !important',
      padding: '0px !important',
    },
    cleanAllButton: {
      cursor: 'pointer',
      top: '49%',
      right: '30px',
      position: 'absolute',
    },
    spacing: {
      padding: theme.spacing(0.75, 1),
      flex: 1,
      cursor: 'pointer',
    },
    rounded: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      borderRadius: theme.spacing(2.5),
      background: 'var(--blue-50, #EDF6FF)',
      color: theme.palette.common.blue500,
      padding: theme.spacing(0.75, 1, 0.75, 1.5),
      '& > img': {
        cursor: 'pointer',
      },
    },
    centered: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    clearFilter: {
      textDecoration: 'none',
      '& > img': {
        height: theme.spacing(2),
        width: theme.spacing(2),
      },
    },
    openContainer: {
      width: theme.spacing(50),
      marginTop: theme.spacing(5.5),
      position: 'absolute',
      zIndex: 100,
      overflow: 'scroll',
      maxHeight: theme.spacing(50),
    },

    rightOpenContainer: {
      width: theme.spacing(50),
      marginTop: theme.spacing(5.5),
      position: 'absolute',
      right: '0px',
      zIndex: 100,
      overflow: 'scroll',
      maxHeight: theme.spacing(50),
    },
    searchInput: {
      width: '100%',
      padding: theme.spacing(1),
    },
    checks: {
      marginLeft: theme.spacing(1),
      width: theme.spacing(48),
      '& > .MuiTypography-body1': {
        fontSize: '14px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    separator: {
      width: '100%',
      height: '1px',
      backgroundColor: theme.palette.common.gray300,
      margin: theme.spacing(1, 0),
    },
    smallTitle: {
      fontSize: '12px',
      color: theme.palette.common.gray200,
    },
    invisibleButton: {
      background: 'transparent',
      border: 'none',
      fontSize: 0,
      cursor: 'pointer',
    },
    errorButton: {
      width: theme.spacing(23),
      border: `1px ${theme.palette.common.error} solid`,
      borderRadius: theme.spacing(0.5),
      float: 'right',
      position: 'relative',
    },
  }),
);
