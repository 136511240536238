import { LoadingStatus } from 'api/loadingStatus';
import { ApplicantPortalService, CancelError } from 'api-clients/monolith';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

export type AutocompleteResponse = Awaited<
  ReturnType<
    typeof ApplicantPortalService.getInternalApiPortalGoogleMapsAutocomplete
  >
>;

export interface AddressValues {
  // ignoring camelcase since these correspond to the name the back end is expecting
  // eslint-disable-next-line camelcase
  street_address: string;
  // eslint-disable-next-line camelcase
  address_2: string;
  city: string;
  // eslint-disable-next-line camelcase
  state_code: string;
  // eslint-disable-next-line camelcase
  postal_code: string;
  // eslint-disable-next-line camelcase
  country_code: string;
  latitude?: number;
  longitude?: number;
}

export type DetailsResponse = Awaited<
  ReturnType<
    typeof ApplicantPortalService.getInternalApiPortalGoogleMapsDetails
  >
>;

export type LoadedSuggestions =
  | LoadingStatus<AutocompleteResponse>
  | { status: 'idle' };

export type LoadedAddress = LoadingStatus<AddressValues>;

type AddressComponent = DetailsResponse['result']['address_components'][number];

const getComponent = (
  details: AddressComponent[],
  type: string,
): string | undefined => {
  const component = details.find(detail =>
    detail.types.some(label => label === type),
  );
  return component?.long_name;
};

// loads place details from the API service and extracts the components into
// the format our Address inputs expect
const getPlaceDetails = async (
  placeId: string,
  sessionId: string,
): Promise<AddressValues> => {
  const details =
    await ApplicantPortalService.getInternalApiPortalGoogleMapsDetails(
      placeId,
      sessionId,
    );
  const components = details.result.address_components;
  return {
    street_address: `${getComponent(components, 'street_number') ?? ''} ${
      getComponent(components, 'route') ?? ''
    }`,
    address_2: getComponent(components, 'subpremise') ?? '',
    city:
      getComponent(components, 'locality') ??
      getComponent(components, 'administrative_area_level_2') ??
      '',
    state_code: getComponent(components, 'administrative_area_level_1') ?? '',
    country_code: getComponent(components, 'country') ?? '',
    postal_code: getComponent(components, 'postal_code') ?? '',
    latitude: details.result.geometry.location.lat,
    longitude: details.result.geometry.location.lng,
  };
};

type CancelToken = () => void;

const autoComplete = debounce(
  (
    setSuggestions: (newSuggestions: LoadedSuggestions) => void,
    setCancelToken: (token: CancelToken) => void,
    query: string,
    location?: string,
    sessionId?: string,
  ) => {
    const promise =
      ApplicantPortalService.getInternalApiPortalGoogleMapsAutocomplete(
        query,
        location,
        sessionId,
      );
    // only set a loading indicator if the maps api is slow to respond
    const setLoadingTimeout = setTimeout(() => {
      setSuggestions({ status: 'loading' });
    }, 100);
    promise
      .then(data => {
        clearTimeout(setLoadingTimeout);
        setSuggestions({ status: 'ready', data });
      })
      .catch(error => {
        if (!(error instanceof CancelError)) {
          setSuggestions({ status: 'error' });
        }
      });
    setCancelToken(() => {
      clearTimeout(setLoadingTimeout);
      promise.cancel();
    });
  },
  500,
);

/**
 * Uses search phrase and potentially user geolocation to get Google Maps suggestions
 * Provides a function to get the details of the suggestions. The autocomplete portion is debounced to
 * prevent excessive requests. It will also avoid making searches until a given minimum number of characters
 * are provided.
 *
 * A Maps session begins with the first autocompletion and ends with the details request.
 */
export const useSuggestedAddress = (
  phrase: string,
  minPhraseLength: number,
) => {
  const [suggestions, setSuggestions] = useState<LoadedSuggestions>({
    status: 'idle',
  });
  const cancelToken = useRef(() => {});
  const location = useRef<string>();
  const sessionId = useRef<string>();
  const isEnabled = useRef(true);

  function setCancelToken(token: CancelToken) {
    cancelToken.current = token;
  }

  // callback to store suggestions found by the Maps autocomplete API and store the session ID
  const handleSuggestions = useCallback((newSuggestions: LoadedSuggestions) => {
    setSuggestions(newSuggestions);
    if (newSuggestions.status === 'ready') {
      sessionId.current = newSuggestions.data.maps_session;
    }
  }, []);

  // hook to run auto suggestions for the given phrase as long as it meets the minimum length
  // will abort any pending http requests between changes in the phrase with the cancelToken
  useEffect(() => {
    if (phrase.length >= minPhraseLength && isEnabled.current) {
      cancelToken.current();

      autoComplete(
        handleSuggestions,
        setCancelToken,
        phrase,
        location.current,
        sessionId.current,
      );
    }
  }, [phrase, minPhraseLength, handleSuggestions]);

  // callback to toggle the execution of auto suggestions. Disabling will
  // also cancel any pending lookups
  const setEnabled = useCallback((enabled: boolean) => {
    if (!enabled) {
      autoComplete.cancel();
    }
    isEnabled.current = enabled;
  }, []);

  // wrapper for the place details API to use the session ID we stored and automatically disable suggestions
  // until the phrase changes again. Clears the current session ID.
  const getPlace = useCallback(
    (placeId: string) => {
      setEnabled(false);
      if (!sessionId.current) {
        throw new Error('must call getPlace with session from Autocomplete');
      }
      const currentSession = sessionId.current;
      sessionId.current = undefined;
      return getPlaceDetails(placeId, currentSession);
    },
    [setEnabled],
  );

  return { suggestions, getPlace, setEnabled };
};
