import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  desktopSelectedTableName: props =>
    props.isMasterApplicantsView && {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
        visibility: 'hidden',
      },
    },
  mobileSelectedTableName: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
      visibility: 'hidden',
    },
  },
}));

export default useStyles;
