import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  StageSettingCardEmptyStateContainer: {
    width: '100%',
    borderRadius: theme.misc.borderRadius,
    border: theme.customBorders.gray200,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.common.gray100,
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    height: theme.spacing(7.125),
  },
  icon: {
    fill: theme.palette.common.red400,
    marginTop: theme.spacing(0.5),
  },
  text: {
    color: theme.palette.common.gray800,
    marginBottom: theme.spacing(2),
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '66%',
  },
  childrenContainer: {
    padding: theme.spacing(3),
    height: theme.spacing(15.25),
    backgroundColor: theme.palette.common.white,
  },
  infoTooltip: {
    marginLeft: theme.spacing(0.5),
    maxHeight: theme.spacing(2),
    display: 'inline-block',
    verticalAlign: 'bottom',
  },
  bodyButton: {
    height: theme.spacing(4),
    '&:hover': {
      backgroundColor: theme.palette.common.gray200,
    },
  },
}));
