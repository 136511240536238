import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  delaySendingWebook: {
    id: `app.components.WorkflowEditorContent.StageDetail.PartnerStage.PartnerIntegrationCard.DelaySendingWebhookSettings.delaySendingWebook`,
    defaultMessage: 'Run {partnerName} when applicant lands in',
  },
  delaySendingWebookError: {
    id: `app.components.WorkflowEditorContent.StageDetail.PartnerStage.PartnerIntegrationCard.DelaySendingWebhookSettings.delaySendingWebookError`,
    defaultMessage: 'Select stage',
  },
  targetStage: {
    id: `app.components.WorkflowEditorContent.StageDetail.PartnerStage.PartnerIntegrationCard.DelaySendingWebhookSettings.targetStage`,
    defaultMessage: 'Target Stage',
  },
});
