import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  row: {
    padding: theme.spacing(1, 3),
    border: theme.customBorders.gray300,
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(0.625),
    marginBottom: theme.spacing(1),

    '&:last-child': {
      borderBottom: theme.customBorders.gray300,
      marginBottom: 0,
    },

    '&.hoverable&:hover, &.dragging': {
      cursor: 'pointer',
      backgroundColor: theme.palette.common.gray100,
      border: theme.customBorders.gray400,
      boxShadow: `0 0 2px 2px ${theme.palette.common.gray100}`,
    },
  },
  editMessageButton: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    marginRight: theme.spacing(1),
  },
  deleteMessageButton: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  editMessageIcon: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    '& path': {
      fill: theme.palette.common.gray600,
    },
  },
  deleteMessageIcon: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    '& path': {
      fill: theme.palette.common.gray600,
    },
  },
  type: {
    marginLeft: theme.spacing(2.25),
    marginRight: theme.spacing(3),
  },
  title: {
    color: theme.palette.common.gray800,
  },
  actionIcons: {
    marginLeft: 'auto',
  },
}));
