import { createGlobalStyle } from 'styled-components';

const ApplicantTableStyle = createGlobalStyle`
  .applicant-table-cell-td {
    color: ${({ theme }) => theme.palette.common.gray800};
    padding: ${({ theme }) => theme.spacing(0, 1)};
    min-height: 48px;
  }

  .applicant-table-cell-td:last-child {
    padding: ${({ theme }) => theme.spacing(0, 1)};
  }

  .applicant-table-cell-td > span {
    width: 100%;
    max-width: inherit;
  }

  .applicant-table-cell-td.cell-without-wrap > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: table-cell;
  }

  .applicant-table-cell-td.cell-with-line-clamp > span {
    display: -webkit-box
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .applicant-table-cell-td.break-word > span {
    word-break: break-word;
  }

  .MuiIconButton-root.applicant-table-actions-icon,
  .MuiIconButton-root.applicant-table-quick-actions-icon {
    padding: ${({ theme }) => `${theme.spacing(1)}px`};
  }

  .MuiSvgIcon-root.applicant-table-actions-icon,
  .MuiSvgIcon-root.applicant-table-quick-actions-icon {
    color: ${({ theme }) => theme.palette.common.gray800};
  }

  .MuiSvgIcon-root.applicant-table-actions-icon {
    margin: ${({ theme }) => `${theme.spacing(-0.5)}px`};
  }

  .MuiSvgIcon-root.applicant-table-quick-actions-icon {
    font-size: ${({ theme }) => `${theme.spacing(2)}px`};
  }
`;

export default ApplicantTableStyle;
