import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  field: {
    borderRadius: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: theme.palette.common.gray100,
    },
    padding: theme.spacing(1, 2),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0),
    },
  },
  hoverButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  fieldContainer: {
    width: '100%',
  },
  input: {
    flex: 1,
    minWidth: 0,
  },
  iconRoot: {
    cursor: 'pointer',
    width: theme.spacing(4.5),
    padding: theme.spacing(1.25),
  },
  fieldContent: {
    width: '70%',
  },
  title: {
    paddingBottom: theme.spacing(0.5),
    color: theme.palette.common.gray600,
  },
  textContent: {
    wordBreak: 'normal',
    overflowWrap: 'anywhere',
  },
  editingFieldButton: {
    maxWidth: theme.spacing(12),
  },
  clearDialogButton: {
    marginLeft: theme.spacing(1),
  },
}));

export default useStyles;
