/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HiringGoal } from '../models/HiringGoal';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HiringGoalsService {

    /**
     * Requests Hiring Goals
     * Returns hiring goals for the logged in user
     * @param openingId filter query for opening_id
     * @param status filter query for one or many statuses
     * @param current filter query where hire_by is in the future (for opening's timezone)
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiHiringGoals(
        openingId?: string,
        status?: ('unfilled' | 'inactive' | 'filled' | Array<'unfilled' | 'inactive' | 'filled'>),
        current?: boolean,
    ): CancelablePromise<{
        hiring_goals: Array<HiringGoal>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/hiring_goals',
            query: {
                'opening_id': openingId,
                'status[]': status,
                'current': current,
            },
        });
    }

}