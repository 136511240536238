import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  alertBodyContainer: {
    gap: theme.spacing(0.5),
    display: 'flex',
  },
  cardBody: {
    marginLeft: theme.spacing(1.5), // MUI FormControlLabels have negative margins by default
    paddingBottom: theme.spacing(3),
  },
  editQuestionsButton: {
    height: theme.spacing(4),
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
  questionAlert: {
    height: theme.spacing(4),
    width: 'fit-content',
    marginBottom: theme.spacing(3),
  },
  dcBotHeader: {
    color: theme.palette.common.gray800,
  },
  dcBotItemContainer: {
    padding: theme.spacing(3),
    marginLeft: theme.spacing(4),
  },
  dcBotItem: {
    paddingRight: theme.spacing(2),
  },
}));
