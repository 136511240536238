/**
 *
 * Fullstory
 *
 */
// eslint-disable-next-line no-unused-vars
import PropTypes from 'prop-types';
import { useEffect } from 'react';

const Fullstory = ({ whoami }) => {
  useEffect(() => {
    identifyUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    whoami.external_id,
    whoami.email,
    whoami.name,
    whoami.role,
    whoami.phone,
    whoami.timeZone,
    whoami.accountSlug,
    whoami.accountLevel,
    whoami.userType,
    whoami.countryCode,
    whoami.locale,
    window.FS,
  ]);

  const identifyUser = () => {
    const {
      email,
      external_id: externalId,
      name: displayName,
      role,
      phone_number: phone,
      time_zone: timeZone,
      account_name: accountSlug,
      user_type: userType,
      country_code: countryCode,
      locale,
    } = whoami;

    if (externalId.length > 0 && window.FS) {
      // eslint-disable-next-line no-unused-expressions

      window.FS.identify(externalId, {
        displayName,
        externalId,
        email,
        role,
        phone,
        timeZone,
        accountSlug,
        userType,
        countryCode,
        locale,
      });
    }
  };

  return null;
};

Fullstory.propTypes = {
  whoami: PropTypes.object,
};

export default Fullstory;
