/*
 *
 * ApplicantsFilter constants
 *
 */
import * as constants from './constants';

export function fetchFilterSetsInitAction() {
  return {
    type: constants.FETCH_FILTER_SETS_INIT,
  };
}

export function fetchFilterSetsSuccessAction(filterSets) {
  return {
    type: constants.FETCH_FILTER_SETS_SUCCESS,
    filterSets,
  };
}

export function fetchFilterSetsErrorAction() {
  return {
    type: constants.FETCH_FILTER_SETS_ERROR,
  };
}

export function setFiltersAction(filters) {
  return {
    type: constants.SET_FILTERS,
    filters,
  };
}

export function setFilterSetsAction(filterSets) {
  return {
    type: constants.SET_FILTER_SETS,
    filterSets,
  };
}

export function setStageConditionsAction(stageConditions) {
  return {
    type: constants.SET_STAGE_CONDITIONS,
    stageConditions,
  };
}

export function setDataConditionsAction(dataConditions) {
  return {
    type: constants.SET_DATA_CONDITIONS,
    dataConditions,
  };
}

export function setSelectedFilterAction(savedFilter) {
  return {
    type: constants.SET_SELECTED_FILTER,
    savedFilter,
  };
}

// CREATE
export function saveFilterInitAction(title, visible, criteria) {
  return {
    type: constants.SAVE_FILTER_INIT,
    title,
    visible,
    criteria,
  };
}

export function saveFilterSuccessAction(savedFilter) {
  return {
    type: constants.SAVE_FILTER_SUCCESS,
    savedFilter,
  };
}

export function saveFilterErrorAction() {
  return {
    type: constants.SAVE_FILTER_ERROR,
  };
}

// INDEX
export function fetchSavedFiltersInitAction(jobId) {
  return {
    type: constants.FETCH_SAVED_FILTERS_INIT,
    jobId,
  };
}

export function fetchSavedFiltersSuccessAction(savedFilters) {
  return {
    type: constants.FETCH_SAVED_FILTERS_SUCCESS,
    savedFilters,
  };
}

export function fetchSavedFiltersErrorAction() {
  return {
    type: constants.FETCH_SAVED_FILTERS_ERROR,
  };
}

// SHOW
export function fetchSavedFilterInitAction(filterId) {
  return {
    type: constants.FETCH_SAVED_FILTER_INIT,
    filterId,
  };
}

export function fetchSavedFilterSuccessAction(savedFilter) {
  return {
    type: constants.FETCH_SAVED_FILTER_SUCCESS,
    savedFilter,
  };
}

export function fetchSavedFilterErrorAction() {
  return {
    type: constants.FETCH_SAVED_FILTER_ERROR,
  };
}

// DESTROY
export function deleteSavedFilterInitAction(filterId, response) {
  return {
    type: constants.DELETE_SAVED_FILTER_INIT,
    filterId,
    response,
  };
}

export function deleteSavedFilterSuccessAction(filterId) {
  return {
    type: constants.DELETE_SAVED_FILTER_SUCCESS,
    filterId,
  };
}

export function deleteSavedFilterErrorAction() {
  return {
    type: constants.DELETE_SAVED_FILTER_ERROR,
  };
}

// UPDATE
export function updateSavedFilterSuccessAction(updatedFilter, response) {
  return {
    type: constants.UPDATE_SAVED_FILTER_SUCCESS,
    updatedFilter,
    response,
  };
}

export function updateSavedFilterErrorAction() {
  return {
    type: constants.UPDATE_SAVED_FILTER_ERROR,
  };
}

export function setTempFilterAction(tempFilter) {
  return {
    type: constants.SET_TEMP_FILTER,
    tempFilter,
  };
}

export function clearFiltersAction() {
  return {
    type: constants.CLEAR_FILTERS,
  };
}
