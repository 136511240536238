import { PartnerRuleAttributes } from 'api-clients/monolith';
import { v4 as uuid } from 'uuid';

import { MOVE_TO_STAGE } from 'containers/WorkflowEditor/components/Rules/Action/constants';

export const DEFAULT_PARTNER_RULE: PartnerRuleAttributes = {
  action_attributes: {
    id: uuid(),
    type: MOVE_TO_STAGE,
    extra: {},
  },
  condition_attributes: {
    id: uuid(),
    extra: {},
    type: 'PartnerStage::Conditions::PartnerLabelHasKey',
  },
};
