import {
  InnerContent,
  SimpleModal,
  SimpleModalHeader,
} from '@fountain/fountain-ui-components';
import { Typography } from '@material-ui/core';
import { ChatbotService } from 'api-clients/monolith';
import { ChatbotLogs } from 'api-clients/monolith/models/ChatbotLogs';
import React, { useCallback, useEffect, useState, VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { useApiService } from 'hooks/useApiService';

import { FeedbackCard } from '../FeedbackCard';
import { messages } from '../messages';

export interface ChatbotFeedbackModalProps {
  handleClose: () => void;
  messageId: number;
}

export const ChatbotFeedbackModal: VFC<ChatbotFeedbackModalProps> = ({
  handleClose,
  messageId,
}) => {
  const { result: chatbotLog } = useApiService(
    useCallback(
      () =>
        ChatbotService.postInternalApiChatbotChatbotLogsChatbotLogs({
          message_id: messageId,
        }),
      [messageId],
    ),
  );

  const [log, setLog] = useState<ChatbotLogs | null>(null);

  useEffect(() => {
    if (chatbotLog.status === 'ready' && chatbotLog.data.count > 0)
      setLog(chatbotLog.data.chatbot_logs[0]);
  }, [chatbotLog]);

  return (
    <>
      {log && (
        <SimpleModal blurOverlay={false} onClose={handleClose} maxWidth={744}>
          <SimpleModalHeader onClose={handleClose}>
            <Typography variant="h3" color="textPrimary">
              <FormattedMessage {...messages.chatbotFeedback} />
            </Typography>
          </SimpleModalHeader>
          <InnerContent>
            <FeedbackCard
              key={log.id}
              applicantMessage={log.applicant_message}
              botMessage={log.bot_message}
              botType={log.bot_type}
              intent={log.intent}
              interpretation={log.interpretation}
              feedback={log.feedback}
              feedbackData={log.feedback_data}
              logId={log.id}
              createdAt={new Date(log.created_at)}
              modelName={log.model_name}
              modelMode={log.model_mode}
              confidenceThreshold={log.confidence_threshold}
              score={log.score}
              question={log.question}
            />
          </InnerContent>
        </SimpleModal>
      )}
    </>
  );
};
