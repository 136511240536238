import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Stage } from 'containers/ApplicantTableV2/types';
import { addDefaultErrorMessageAction } from 'containers/FlashMessage/actions';
import { fetchStages } from 'requests/requests';

type Data = Partial<Record<string, Stage[]>>;

export default function useStages(
  initialJobId?: string,
): [
  { data: Data; isFetching: boolean; error: string },
  React.Dispatch<React.SetStateAction<string | null>>,
] {
  const dispatch = useDispatch();

  const [jobId, setJobId] = useState<string | null>(initialJobId ?? null);
  const [data, setData] = useState<Data>({});
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!jobId || !!data[jobId]) return;

    setIsFetching(true);
    fetchStages(jobId)
      .then((res: { data: { stages: Stage[] } }) => {
        setData({ ...data, [jobId]: res.data.stages });
        setError('');
      })
      .catch((exception: unknown) => {
        setError(exception as string);
      })
      .finally(() => {
        setIsFetching(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId]);

  if (error) dispatch(addDefaultErrorMessageAction());

  return [{ data, isFetching, error }, setJobId];
}
