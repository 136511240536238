/* eslint-disable no-underscore-dangle */
import { CustomTooltip, Loader } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import { WorkflowDistributeApplicantsRuleStage } from 'api-clients/monolith';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { classNames } from 'react-extras';
import { useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';

import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';
import useLocations from 'hooks/useLocations';
import PlusIcon from 'images/plus-icon.svg';
import PlusIconGray from 'images/plus-icon-gray.svg';

import { DistributeRuleRow } from './DistributeRuleRow';
import messages from './messages';
import useStyles from './styles';

export const DEFAULT_DISTRIBUTE_RULE = {
  to_location_id: null,
  to_funnel_id: null,
  to_stage_id: null,
  percentage: 0,
  goal: null,
};

const ROW_LIMIT = 20;

export interface DistributeApplicantsRuleStageProps {
  stage: WorkflowDistributeApplicantsRuleStage;
}

export const DistributeApplicantsRuleStage: FC<
  DistributeApplicantsRuleStageProps
> = ({ stage }) => {
  const classes = useStyles();
  const intl = useIntl();

  const { data: locations, isFetching: isFetchingLocations } = useLocations();

  const { setStage } = useContext(StageContext);

  const { additional_info: additionalInfo } = stage;
  const distributeRules = additionalInfo.distribute_rules ?? [];
  const filterdDistributeRules = distributeRules.filter(
    distRule => !distRule._destroy,
  );
  const defaultLocation = additionalInfo.default_location ?? locations[0];

  const distributeRuleLimitReached =
    filterdDistributeRules?.length >= ROW_LIMIT;
  const addOpeningDisabled = isFetchingLocations || distributeRuleLimitReached;

  const handleAddRule = () => {
    if (distributeRuleLimitReached || !defaultLocation) {
      return;
    }

    setStage(
      produce(stage, draftStage => {
        if (draftStage.type !== 'DistributeApplicantsRuleStage') {
          return;
        }

        const draftDistributeRules =
          draftStage.additional_info?.distribute_rules ?? [];

        draftDistributeRules.push({
          ...DEFAULT_DISTRIBUTE_RULE,
          id: uuid(),
          to_location_id: defaultLocation.id,
          goal: null,
        });
      }),
    );
  };

  return (
    <Grid className={classes.distributeApplicantsRuleStage}>
      <Typography className={classes.stageDescription}>
        {stage.additional_info.distribution_basis === 'percentage'
          ? intl.formatMessage(
              messages.distributeApplicantsRuleStageDescription,
            )
          : intl.formatMessage(
              messages.distributeApplicantsRuleStageHiringNeedDescription,
            )}
      </Typography>

      <CustomTooltip
        title={
          distributeRuleLimitReached
            ? intl.formatMessage(messages.maxRowsReached)
            : ''
        }
        dense
      >
        <Grid
          onClick={handleAddRule}
          className={classNames(classes.addOpeningContent, {
            disabled: addOpeningDisabled,
          })}
        >
          {addOpeningDisabled ? (
            <img
              src={PlusIconGray}
              alt="plus"
              className={classNames(classes.plusIcon, { disabled: true })}
            />
          ) : (
            <img src={PlusIcon} alt="plus" className={classes.plusIcon} />
          )}
          <Typography
            color={addOpeningDisabled ? 'textSecondary' : 'primary'}
            className={classNames(classes.addOpeningText, {
              disabled: addOpeningDisabled,
            })}
          >
            {intl.formatMessage(messages.addAnotherOpening)}
          </Typography>
        </Grid>
      </CustomTooltip>
      {isFetchingLocations && <Loader fullSection size="2rem" />}
      {!isFetchingLocations &&
        filterdDistributeRules?.map((distributeRule, index) => (
          <DistributeRuleRow
            key={distributeRule.id}
            locations={locations}
            stageExternalId={stage.external_id}
            defaultLocation={
              (locations as { id: string; name: string }[]).find(
                loc => loc.id === distributeRule.to_location_id,
              ) ?? defaultLocation
            }
            index={index}
            distributionBasis={stage.additional_info.distribution_basis}
            distributeRule={distributeRule}
            allowDeletingRule={filterdDistributeRules?.length > 1}
            isLastRule={index === filterdDistributeRules?.length - 1}
            jobExternalId={additionalInfo.job_external_id}
          />
        ))}
    </Grid>
  );
};
