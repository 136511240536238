import { Button } from '@fountain/fountain-ui-components';
import { useDeleteEvent } from 'Calendar/hooks';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { addMessageAction } from 'containers/FlashMessage/actions';

import { messages } from '../messages';
import { FooterProps, UpdateModeType, UpdateModeTypes } from '../types';
import { UpdateModeConfirmationModal } from '../UpdateModeConfirmationModal';
import { useStyles } from './styles';

export const Footer: React.VFC<FooterProps> = ({
  isUpdatingEvent,
  isSaveButtonEnabled,
  resetForm,
  onSubmit,
  redirectToCalendar,
  availableSlot,
  runValidations,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const editSeries = !!urlSearchParams.get('editSeries');

  const isUpdate: boolean = availableSlot?.external_id !== undefined;

  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [isDeletingEvent, setIsDeletingEvent] = useState<boolean>(false);

  const { mutation: deleteEvent, result: deleteResult } = useDeleteEvent({
    onSuccess: () => {
      const successMessage = editSeries
        ? messages.seriesDeleteSuccess
        : messages.sessionDeleteSuccess;
      dispatch(addMessageAction(intl.formatMessage(successMessage), 'success'));
      redirectToCalendar();
    },
    onError: () => {
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.eventDeleteFailed),
          'error',
        ),
      );
    },
  });

  const deleteTriggered =
    deleteResult.isLoading ||
    deleteResult.status === 'ready' ||
    deleteResult.status === 'error';

  const handleDelete = () => {
    if (!availableSlot?.external_id) return;

    if (editSeries) {
      setIsDeletingEvent(true);
      setShowConfirmationModal(true);
    } else {
      deleteEvent(availableSlot.external_id, {
        delete_mode: UpdateModeTypes.SINGLE,
      });
    }
  };

  const handleSave = () => {
    if (!runValidations()) return;

    if (isUpdate && editSeries) {
      setIsDeletingEvent(false);
      setShowConfirmationModal(true);
    } else {
      onSubmit(UpdateModeTypes.SINGLE);
    }
  };

  const handleModalConfirmation = (selectedOption: UpdateModeType) => {
    if (!isDeletingEvent) {
      onSubmit(selectedOption);
    } else {
      if (!availableSlot?.external_id) return;

      deleteEvent(availableSlot.external_id, {
        delete_mode: selectedOption,
      });
    }

    setShowConfirmationModal(false);
  };

  const deleteMessage = editSeries
    ? messages.deleteSeries
    : messages.deleteSession;

  return (
    <div className={styles.footer}>
      {isUpdate && (
        <Button
          type="secondary"
          className={styles.deleteButton}
          onClick={handleDelete}
          isLoading={deleteResult.isLoading}
          disabled={isUpdatingEvent || deleteTriggered}
        >
          <FormattedMessage {...deleteMessage} />
        </Button>
      )}
      <Button
        type="secondary"
        className={styles.button}
        onClick={resetForm}
        disabled={isUpdatingEvent || deleteTriggered}
      >
        <FormattedMessage {...messages.cancel} />
      </Button>
      <Button
        type="primary"
        onClick={handleSave}
        className={styles.saveButton}
        isLoading={isUpdatingEvent}
        disabled={!isSaveButtonEnabled || isUpdatingEvent || deleteTriggered}
      >
        <FormattedMessage {...messages.save} />
      </Button>
      {isUpdate && editSeries && (
        <UpdateModeConfirmationModal
          event={availableSlot}
          open={showConfirmationModal}
          isLoading={isUpdatingEvent || deleteResult.isLoading}
          isDeleting={isDeletingEvent}
          onClose={() => {
            if (!isUpdatingEvent) setShowConfirmationModal(false);
          }}
          onConfirm={selectedOption => {
            handleModalConfirmation(selectedOption);
          }}
        />
      )}
    </div>
  );
};
