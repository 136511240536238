import { theme } from '@fountain/fountain-ui-components';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme>(() => ({
  mouseOverAdd: {
    cursor: 'copy',
  },
  button: {
    alignSelf: 'center',
    color: theme.palette.common.blue400,
  },
  popper: {
    margin: theme.spacing(1, 0, 0, 0),
    border: theme.customBorders.gray300,
    boxShadow: theme.customShadows.selectDropdown,
    borderRadius: theme.misc.borderRadius,
    backgroundColor: theme.palette.common.white,
    width: 300,
    zIndex: 1000,
  },
  inputBase: {
    padding: theme.spacing(1),
    width: '100%',
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
    color: theme.palette.common.white,
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    '&:first-child': {
      marginTop: theme.spacing(-1),
      '&[aria-disabled="true"]': {
        marginTop: theme.spacing(-2),
      },
    },
    padding: theme.spacing(1.5, 2),
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
    '&[aria-disabled="true"]': {
      opacity: 1,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: 'relative',
    width: '100% !important',
  },
}));
