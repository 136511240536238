import { TableCell } from '@material-ui/core';
import React, { FC, memo } from 'react';
import { classNames } from 'react-extras';
import { useSelector } from 'react-redux';

import { makeSelectTableColumnWidths } from 'containers/ApplicantTableV2/selectors';
import { TableColumnWidthsKey } from 'containers/ApplicantTableV2/types';

interface ApplicantTableV2CellProps {
  children: React.ReactChildren | React.ReactElement;
  columnType: TableColumnWidthsKey;
  truncateText?: boolean;
  useLineClamp?: boolean;
}
const ApplicantTableV2Cell: FC<ApplicantTableV2CellProps> = ({
  children,
  columnType,
  truncateText = true,
  useLineClamp = false,
}) => {
  const classes = classNames('applicant-table-cell-td', {
    'cell-without-wrap': truncateText && !useLineClamp,
    'cell-with-line-clamp': truncateText && useLineClamp,
    'break-word': useLineClamp,
  });
  const tableColumnWidths = useSelector(makeSelectTableColumnWidths());

  return (
    <TableCell
      role="cell"
      style={{
        minWidth: tableColumnWidths[columnType],
        maxWidth: tableColumnWidths[columnType],
      }}
      className={classes}
    >
      {children}
    </TableCell>
  );
};

export default memo(ApplicantTableV2Cell);
