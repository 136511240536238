import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  activeLabel: {
    display: 'inline-block',
    marginLeft: theme.spacing(1),
  },
  deleteIconButton: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    paddingBottom: theme.spacing(1),
  },
  deleteIcon: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
  },
  editMessageButton: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  editMessageIcon: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    '& path': {
      fill: theme.palette.common.gray600,
    },
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  footerButton: {
    '&:first-of-type': {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      '&:first-of-type': {
        display: 'none',
      },
      '&:last-of-type': {
        flex: 1,
      },
    },
  },
  modalInstructions: {
    marginBottom: theme.spacing(3),
  },
  tableBodyCell: {
    padding: '10px 20px',
    borderBottom: theme.customBorders.gray300,
    cursor: 'pointer',
    '& h5:nth-child(2)': {
      color: theme.palette.common.gray600,
    },
  },
  tableBodyEditCell: {
    padding: '0px 0px 0px 16px',
    borderBottom: theme.customBorders.gray300,
  },
  tableCellLast: {
    borderBottom: 0,
  },
  tableContainer: {
    maxHeight: '432px',
    marginBottom: '50px',
    border: theme.customBorders.gray300,
    borderRadius: '6px',
    overflow: 'auto',
  },
  tableHeaderEmptyCell: {
    width: theme.spacing(10),
    padding: 0,
  },
  tableHead: {
    backgroundColor: theme.palette.common.gray100,
    borderBottom: theme.customBorders.gray300,
    height: '45px',
  },
  tableHeaderCell: {
    borderBottom: theme.customBorders.gray300,
    padding: '12px 20px',
  },
  title: {
    display: 'inline-block',
  },
}));

export default useStyles;
