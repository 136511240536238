import { Loader } from '@fountain/fountain-ui-components';
import {
  CalendarSchedulerStagesSessionDetail,
  CalendarUser,
  WorkflowEditorService,
  WorkflowSchedulerV2Stage,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import produce from 'immer';
import invariant from 'invariant';
import React, { useEffect, useState, VFC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addDefaultErrorMessageActionI18n } from 'containers/FlashMessage/actions';
import { useCalendarUsers } from 'containers/WorkflowEditor/hooks/useCalendarUsers';

import { Option } from './components';
import { SchedulerUsersCard } from './SchedulerUsersCard';

export interface SessionHostsCardContainerProps {
  stage: WorkflowSchedulerV2Stage;
  setStage: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
}
export const SessionHostsCardContainer: VFC<SessionHostsCardContainerProps> = ({
  stage,
  setStage,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { result } = useCalendarUsers();

  const [users, setUsers] = useState<CalendarUser[]>([]);

  useEffect(() => {
    if (result.status === 'ready') {
      setUsers(
        result.data.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
        ),
      );
    }
    if (result.status === 'error') {
      dispatch(addDefaultErrorMessageActionI18n(intl));
    }
  }, [dispatch, intl, result, users]);

  const sessionDetail = stage.additional_info.session_detail;

  const hasNoUsers =
    !sessionDetail?.calendar_scheduler_stages_session_details?.length ||
    sessionDetail?.calendar_scheduler_stages_session_details?.every(
      // eslint-disable-next-line no-underscore-dangle
      calendarSessionDetail => calendarSessionDetail._destroy === true,
    );

  const hostCount =
    sessionDetail?.calendar_scheduler_stages_session_details?.filter(
      // eslint-disable-next-line no-underscore-dangle
      deet => !deet._destroy,
    )?.length ?? 0;

  const schedulerUsers =
    sessionDetail?.calendar_scheduler_stages_session_details?.map(detail => ({
      name: detail.calendar?.user.name,
      // eslint-disable-next-line no-underscore-dangle
      _destroy: detail._destroy,
      available: detail.available,
      id: detail.calendar?.user.id,
    })) ?? [];

  const options: Option[] = users
    .filter(
      user =>
        !sessionDetail?.calendar_scheduler_stages_session_details?.some(
          calendarSchedulerStagesSessionDetail =>
            calendarSchedulerStagesSessionDetail?.calendar?.id ===
              user.calendar?.id &&
            // eslint-disable-next-line no-underscore-dangle
            !calendarSchedulerStagesSessionDetail._destroy,
        ),
    )
    .map(user => ({
      title: user.name,
      value: user.calendar?.id,
    }));

  const disallowaddUser = hostCount >= 10;

  const removeUser = (userId?: number) => {
    setStage(
      produce(stage, draftStage => {
        if (
          !draftStage.additional_info.session_detail
            ?.calendar_scheduler_stages_session_details
        ) {
          return;
        }

        const draftCalendarSchedulerStagesSessionDetails =
          draftStage.additional_info.session_detail
            ?.calendar_scheduler_stages_session_details ?? [];

        const index = draftCalendarSchedulerStagesSessionDetails.findIndex(
          calendarSchedulerStagesSessionDetail =>
            calendarSchedulerStagesSessionDetail?.calendar?.user.id === userId,
        );

        if (index < 0) {
          return;
        }

        // eslint-disable-next-line no-underscore-dangle
        draftCalendarSchedulerStagesSessionDetails[index]._destroy = true;

        draftStage.additional_info.session_detail.calendar_scheduler_stages_session_details =
          [
            ...draftStage.additional_info.session_detail
              .calendar_scheduler_stages_session_details,
          ];
      }),
    );
  };

  const toggleUser = (userId?: number) => {
    setStage(
      produce(stage, draftStage => {
        if (
          !draftStage.additional_info.session_detail
            ?.calendar_scheduler_stages_session_details
        ) {
          return;
        }

        const draftCalendarSchedulerStagesSessionDetails =
          draftStage.additional_info.session_detail
            ?.calendar_scheduler_stages_session_details;

        const index = draftCalendarSchedulerStagesSessionDetails.findIndex(
          calendarSchedulerStagesSessionDetail =>
            calendarSchedulerStagesSessionDetail?.calendar?.user.id === userId,
        );

        if (index < 0) {
          return;
        }

        draftCalendarSchedulerStagesSessionDetails[index].available =
          !draftCalendarSchedulerStagesSessionDetails[index].available;

        draftStage.additional_info.session_detail.calendar_scheduler_stages_session_details =
          [
            ...draftStage.additional_info.session_detail
              .calendar_scheduler_stages_session_details,
          ];
      }),
    );
  };

  const addUser = async (option: Option | null) => {
    let user = users.find(user => user.calendar?.id === option?.value);
    if (!user?.calendar && option !== null) {
      user = users.find(user => user.name === option.title);
      if (user === undefined || user.calendar) {
        return;
      }
      const result =
        await WorkflowEditorService.postInternalApiWorkflowEditorCalendarUsers({
          user_id: user.id,
        });
      user.calendar = result.calendar;
      setUsers([...users.filter(they => they !== user), user]);
    }

    invariant(user, 'must exist');
    const { calendar } = user;
    invariant(calendar, 'must have calendar');

    const newCalendarSchedulerStagesSessionDetail: CalendarSchedulerStagesSessionDetail =
      {
        available: true,
        calendar_id: calendar.id,
        calendar: {
          ...calendar,
          user,
        },
      };

    setStage(
      produce(stage, draftStage => {
        if (
          !draftStage.additional_info.session_detail
            ?.calendar_scheduler_stages_session_details
        ) {
          return;
        }

        const index =
          draftStage.additional_info.session_detail.calendar_scheduler_stages_session_details.findIndex(
            calendarSchedulerStagesSessionDetail =>
              calendarSchedulerStagesSessionDetail.calendar?.user.id ===
                user?.id &&
              // eslint-disable-next-line no-underscore-dangle
              calendarSchedulerStagesSessionDetail._destroy,
          );

        if (index >= 0) {
          // eslint-disable-next-line no-underscore-dangle
          draftStage.additional_info.session_detail.calendar_scheduler_stages_session_details[
            index
          ]._destroy = false;
          draftStage.additional_info.session_detail.calendar_scheduler_stages_session_details[
            index
          ].available = true;
          return;
        }

        draftStage.additional_info.session_detail.calendar_scheduler_stages_session_details =
          [
            ...draftStage.additional_info.session_detail
              .calendar_scheduler_stages_session_details,
            newCalendarSchedulerStagesSessionDetail,
          ];
      }),
    );
  };

  return (
    <>
      {result.status === 'loading' && <Loader />}

      {result.status === 'ready' && (
        <SchedulerUsersCard
          toggleUser={toggleUser}
          removeUser={removeUser}
          setStage={setStage}
          addUser={addUser}
          hasNoUsers={hasNoUsers}
          disallowaddUser={disallowaddUser}
          schedulerUsers={schedulerUsers}
          options={options}
        />
      )}
    </>
  );
};
