import { CalendarContext as CalendarContextData } from 'api-clients/monolith';
import { createContext } from 'react';

/* eslint-disable camelcase */
type CalendarContext = Omit<CalendarContextData, 'cronofy_response'> & {
  cronofy_response:
    | CalendarContextData['cronofy_response']
    | { status: 'loading' };
};

export const defaultContext: CalendarContext = {
  calendar_v1_path: '',
  callback_url: '',
  client_id: '',
  has_calendar_zero: false,
  has_cronofy_slots: false,
  has_events: false,
  calendar_connected: false,
  cronofy_response: { status: 'loading' },
  is_event_manager: false,
};
/* eslint-enable camelcase */

export const CalendarContext = createContext<CalendarContext>(defaultContext);
