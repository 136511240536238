import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  cancel: {
    id: 'app.Calendar.Availability.cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'app.Calendar.Availability.save',
    defaultMessage: 'Save',
  },
  sun: {
    id: 'app.Calendar.Availability.sun',
    defaultMessage: 'Sun',
  },
  mon: {
    id: 'app.Calendar.Availability.mon',
    defaultMessage: 'Mon',
  },
  tues: {
    id: 'app.Calendar.Availability.tues',
    defaultMessage: 'Tues',
  },
  wed: {
    id: 'app.Calendar.Availability.wed',
    defaultMessage: 'Wed',
  },
  thurs: {
    id: 'app.Calendar.Availability.thurs',
    defaultMessage: 'Thurs',
  },
  fri: {
    id: 'app.Calendar.Availability.fri',
    defaultMessage: 'Fri',
  },
  sat: {
    id: 'app.Calendar.Availability.sat',
    defaultMessage: 'Sat',
  },
  unavailable: {
    id: 'app.Calendar.Availability.unavailable',
    defaultMessage: 'Unavailable',
  },
  createAvailabilityRulesSuccess: {
    id: 'app.Calendar.Availability.createAvailabilityRulesSuccess',
    defaultMessage: 'Successfully updated availability rules',
  },
  createAvailabilityRulesError: {
    id: 'app.Calendar.Availability.createAvailabilityRulesError',
    defaultMessage: 'Oops, something went wrong',
  },
  networkError: {
    id: 'app.Calendar.Availability.networkError',
    defaultMessage: 'Network error, please try again later',
  },
  unrecognizedDay: {
    id: 'app.Calendar.Availability.unrecognizedDay',
    defaultMessage: 'Day not recognized',
  },
  overlaps: {
    id: 'app.Calendar.Availability.overlaps',
    defaultMessage: "Time periods can't overlap",
  },
  orderMatters: {
    id: 'app.Calendar.Availability.orderMatters',
    defaultMessage: 'Start time must be before end time',
  },
  timeValue: {
    id: 'app.Calendar.Availability.timeValue',
    defaultMessage: 'Time must be 00:00 to 24:00',
  },
  bodyText: {
    id: 'app.Calendar.Availability.UnsavedChangesPrompt.bodyText',
    defaultMessage:
      'You have unsaved changes in your Recurring Availability, which will be lost if you do not save. Do you want to continue editing or discard the changes?',
  },
  continueEditing: {
    id: 'app.Calendar.Availability.UnsavedChangesPrompt.continueEditing',
    defaultMessage: 'Continue Editing',
  },
  discard: {
    id: 'app.Calendar.Availability.UnsavedChangesPrompt.discard',
    defaultMessage: 'Discard',
  },
  unsavedChanges: {
    id: 'app.Calendar.Availability.UnsavedChangesPrompt.unsavedChanges',
    defaultMessage: 'Unsaved Changes',
  },
  unsavedChangesModal: {
    id: 'app.Calendar.Availability.UnsavedChangesPrompt.unsavedChangesModal',
    defaultMessage: 'Unsaved Changes Modal',
  },
});
