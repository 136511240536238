import React from 'react';

const ChatSvg = ({ color }: { color: string }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="16" fill="none" />
    <path
      d="M9 8H23C23.552 8 24 8.448 24 9V19C24 19.552 23.552 20 23 20H18L12 24V20H9C8.448 20 8 19.552 8 19V9C8 8.448 8.448 8 9 8Z"
      fill="#131E40"
    />
    <circle cx="23" cy="9" r="4" fill={color} />
  </svg>
);

export default ChatSvg;
