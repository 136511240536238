import { Grid } from '@material-ui/core';
import {
  AutomatedResponse,
  AutomatedResponseModel,
  BotType,
  CancelablePromise,
  ChatbotIntentParams,
  ChatbotService,
  IntentsFilterList,
} from 'api-clients/monolith';
import { useApiService } from 'hooks';
import React, { useCallback, useEffect, useState, VFC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { makeSelectFeatureChatbotAdminEnabled } from 'containers/Auth_old/selectors';
import { addMessageAction } from 'containers/FlashMessage/actions';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';
import { setParamsToUrlWithReplace } from 'utils/urlUtils';

import { IntentFilter } from './IntentFilter';
import { messages } from './messages';
import { ModelFilter } from './ModelFilter';
import { SelectedSortBy, Sort } from './Sort';
import { SelectedStatus, StatusFilter } from './StatusFilter';
import { useStyles } from './styles';

export interface SelectedFilters {
  status: SelectedStatus;
  models: Array<string>;
  intents: Array<string>;
  sortBy: SelectedSortBy;
}

export interface FiltersProps {
  selectedFilters: SelectedFilters;
  setSelectedFilters: React.Dispatch<React.SetStateAction<SelectedFilters>>;
  setShowLoadMore: (flag: boolean) => void;
  botType?: BotType;
  setPage: (page: number) => void;
}

export const Filters: VFC<FiltersProps> = ({
  selectedFilters,
  setSelectedFilters,
  setShowLoadMore,
  botType,
  setPage,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const isFeatureChatbotAdminEnabled = useSelector(
    makeSelectFeatureChatbotAdminEnabled(),
  );
  const [models, setModels] = useState<AutomatedResponseModel[]>([]);
  const [intents, setIntents] = useState<AutomatedResponse[]>([]);

  const { mutation: getModelIntent } = useApiServiceMutation<
    IntentsFilterList,
    (requestBody: ChatbotIntentParams) => CancelablePromise<IntentsFilterList>
    // eslint-disable-next-line @typescript-eslint/unbound-method
  >(ChatbotService.postInternalApiChatbotChatbotLogsIntents, {
    onSuccess: result => {
      setIntents(result.intents);
    },
    onError: () => {
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.intentFetchError),
          'error',
          false,
        ),
      );
    },
  });

  const handleStatusChange = (status: SelectedStatus) => {
    setSelectedFilters({
      ...selectedFilters,
      status,
    });
    setParamsToUrlWithReplace({ status });
    setShowLoadMore(false);
  };

  const handleSortChange = (sortBy: SelectedSortBy) => {
    setSelectedFilters({
      ...selectedFilters,
      sortBy,
    });
    setParamsToUrlWithReplace({ sortBy });
    setShowLoadMore(false);
  };

  const handleModelChange = (modelsObj: AutomatedResponseModel[]) => {
    setPage(1);
    const modelIds = modelsObj?.map(model => model?.external_id);
    const models: string[] = modelsObj?.map(model => model?.name);
    setIntents([]);
    getModelIntent({
      automated_response_model: {
        external_ids: modelIds || [],
      },
    });
    setSelectedFilters({
      ...selectedFilters,
      models,
      intents: [],
    });
  };

  const handleIntentChange = (intentObj: AutomatedResponse[]) => {
    setPage(1);
    const intents = intentObj?.map(intent => intent?.intent);
    setSelectedFilters({
      ...selectedFilters,
      intents,
    });
  };

  const { result } = useApiService(
    useCallback(
      () => ChatbotService.getInternalApiChatbotAutomatedResponseModels(),
      [],
    ),
  );

  useEffect(() => {
    if (botType === 'FAQ Bot' && result.status === 'ready') {
      setModels(result.data.automated_response_models);
      getModelIntent({
        automated_response_model: {
          external_ids: [],
        },
      });
    } else {
      setSelectedFilters({
        ...selectedFilters,
        models: [],
        intents: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botType, result]);

  return (
    <Grid direction="row" className={styles.filterContainer}>
      <Sort
        selectedSortBy={selectedFilters.sortBy}
        handleSortChange={handleSortChange}
      />
      <StatusFilter
        selectedStatus={selectedFilters.status}
        handleStatusChange={handleStatusChange}
      />

      {isFeatureChatbotAdminEnabled && botType === 'FAQ Bot' && (
        <>
          <ModelFilter models={models} handleModelChange={handleModelChange} />
          <IntentFilter
            intents={intents}
            handleIntentChange={handleIntentChange}
          />
        </>
      )}
    </Grid>
  );
};
