/* eslint-disable no-underscore-dangle */
import { Trashcan } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import {
  TechCheckStageSetting,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import { produce } from 'immer';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { StageSettingCard } from 'containers/WorkflowEditor/components/StageSettingCard';
import globalMessages from 'shared/global/messages';

import { BrowsersInputs } from './BrowsersInputs';
import { HardwareInputs } from './HardwareInputs';
import { InternetSpeedInputs } from './InternetSpeedInputs';
import { messages } from './messages';
import { OperatingSystemsInputs } from './OperatingSystemsInputs';
import { useStyles } from './styles';

export interface RequirementCardProps {
  setting: TechCheckStageSetting;
  setStage: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
}

export const RequirementCard: FC<RequirementCardProps> = React.memo(
  ({ setting, setStage }) => {
    const styles = useStyles();
    const intl = useIntl();

    const onDeleteRequirement = () => {
      setStage(
        produce((draftStage: WorkflowStageDetail) => {
          if (
            draftStage.type === 'TechCheckStage' &&
            draftStage.additional_info?.settings
          ) {
            const requirementToDelete =
              draftStage.additional_info.settings.find(
                ({ id }) => id === setting.id,
              );
            if (!requirementToDelete) {
              return;
            }
            // Client-only construct: can delete the object from the array
            if (typeof requirementToDelete.id === 'string') {
              draftStage.additional_info.settings =
                draftStage.additional_info.settings.filter(
                  draftSetting => draftSetting.id !== requirementToDelete.id,
                );
            } else {
              // DB-persisted; add _destroy to delete via the API
              requirementToDelete._destroy = true;
            }

            // Update other setting title
            if (requirementToDelete.title === 'Tier 1') {
              const requirementToUpdate = draftStage.additional_info.settings
                .filter(setting => !setting._destroy)
                .find(({ title }) => title === 'Tier 2');
              if (!requirementToUpdate) {
                return;
              }
              requirementToUpdate.title = 'Tier 1';
            }
          }
        }),
      );
    };

    return (
      <StageSettingCard
        title={
          setting.is_base_set
            ? intl.formatMessage(messages.baselineTitle)
            : setting.title
        }
        icon={
          <Trashcan
            color="secondary"
            viewBox="0 0 16 16"
            className={styles.trashcanIcon}
            aria-label={intl.formatMessage(globalMessages.delete)}
          />
        }
        variant={setting.is_base_set ? 'default' : 'button'}
        buttonText={intl.formatMessage(messages.deleteTier)}
        onClick={onDeleteRequirement}
      >
        <Grid>
          <Typography variant="body2" className={styles.description}>
            <FormattedMessage {...messages.description} />
          </Typography>
        </Grid>
        <Grid className={styles.requirementContainer}>
          <BrowsersInputs
            browsers={setting.browsers_info?.browsers}
            settingId={setting.id}
          />
        </Grid>
        <Grid className={styles.requirementContainer}>
          <InternetSpeedInputs
            uploadSpeed={setting.upload_speed}
            downloadSpeed={setting.download_speed}
            settingId={setting.id}
          />
        </Grid>
        <Grid className={styles.requirementContainer}>
          <OperatingSystemsInputs
            operatingSystems={setting.operating_systems_info?.operating_systems}
            settingId={setting.id}
          />
        </Grid>
        <Grid className={styles.requirementContainer}>
          <HardwareInputs
            ram={setting.ram}
            noOfCores={setting.no_of_cores}
            processorSpeed={setting.processor_speed}
            screenWidth={setting.screen_width}
            screenHeight={setting.screen_height}
            settingId={setting.id}
          />
        </Grid>
      </StageSettingCard>
    );
  },
);
