import { PartnersService } from 'api-clients/monolith';
import { useApiService } from 'hooks';
import { useCallback } from 'react';

export default function useGetPartnerOptionData({
  applicantId,
  stageId,
  partnerOptionId,
  partnerStatusId,
}: {
  applicantId: string;
  stageId: string;
  partnerOptionId: string;
  partnerStatusId?: string;
}) {
  const fetch = useCallback(
    () =>
      PartnersService.getInternalApiApplicantsPartnerOptionData(
        applicantId,
        stageId,
        partnerOptionId,
        partnerStatusId,
      ),
    [applicantId, stageId, partnerOptionId, partnerStatusId],
  );

  return useApiService(fetch);
}
