import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  recipientsCard: {
    border: theme.customBorders.gray300,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1.5, 2),
    display: 'inline-block',
    minWidth: 250,
  },
  applicantIcon: {
    marginRight: theme.spacing(0.75),
    '& > path': {
      fill: theme.palette.common.gray600,
    },

    '&.error': {
      '& > path': {
        fill: theme.palette.common.red400,
      },
    },
  },
  selectedApplicantsCountContainer: {
    color: theme.palette.common.gray600,
    marginTop: theme.spacing(0.75),
  },
  review: {
    marginTop: theme.spacing(1.25),
    cursor: 'pointer',
  },
  warningsCount: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
  },
  warningIcon: {
    marginRight: theme.spacing(0.75),
    height: 12,
    width: 12,
  },
  warningText: {
    color: theme.palette.common.orange400,
  },
  errorText: {
    color: theme.palette.common.red400,
  },
}));

export default useStyles;
