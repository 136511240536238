import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import { setApplicantBlobAction } from 'containers/ApplicantTableV2/actions';
import { addDefaultErrorMessageAction } from 'containers/FlashMessage/actions';
import { apiPatch } from 'utils/axios';

export default function useApplicantApproveBlob(applicantId) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const approveBlob = async id => {
    const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/blobs/${id}/approve`;

    setIsLoading(true);
    try {
      const res = await apiPatch(url);
      dispatch(setApplicantBlobAction(applicantId, res.data.blob));
      setError('');
      setIsLoading(false);
    } catch (e) {
      setError(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error) {
      dispatch(addDefaultErrorMessageAction());
    }
  }, [dispatch, error]);

  return { approveBlob, isLoading };
}
