import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { VFC } from 'react';

import { EventCard } from './EventCard';

export interface FormattedEvent {
  title: string;
  time: string;
  hostName: string;
  bookedCount: string;
  maxCount: string;
  externalId: string;
  videoUrl?: string;
}

const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      display: 'flex',
      gap: theme.spacing(1.5),
      flexDirection: 'column',
      listStyle: 'none',
      padding: 0,
      margin: 0,
    },
  }),
);

export interface EventsProps {
  events: FormattedEvent[];
  duplicateEvent: (externalId: string) => void;
  deleteEvent: (externalId: string) => void;
  canManage: boolean;
}

export const Events: VFC<EventsProps> = ({
  events,
  duplicateEvent,
  deleteEvent,
  canManage,
}) => {
  const styles = useStyles();

  if (!events.length) {
    return <>no events</>;
  }

  return (
    <ul className={styles.wrapper}>
      {events.map(event => (
        <li key={event.externalId}>
          <EventCard
            duplicateEvent={duplicateEvent}
            deleteEvent={deleteEvent}
            hostName={event.hostName}
            time={event.time}
            title={event.title}
            bookedCount={event.bookedCount}
            maxCount={event.maxCount}
            externalId={event.externalId}
            videoUrl={event.videoUrl}
            canManage={canManage}
          />
        </li>
      ))}
    </ul>
  );
};
