import { RuleStageDistributionBasis, StageType } from 'api-clients/monolith';

import { messages } from './messages';

export type RuleStageType = Extract<
  StageType,
  'RuleStage' | 'DistributeApplicantsRuleStage'
>;

export const useGetDescriptionText = (
  type: RuleStageType | RuleStageDistributionBasis,
) => {
  switch (type) {
    case 'hiring_need':
      return messages.hiringNeedBasedText;
    case 'percentage':
      return messages.percentageBasedText;
    case 'DistributeApplicantsRuleStage':
      return messages.distributeRuleText;
    default:
      return messages.moveRuleText;
  }
};
