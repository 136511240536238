import { makeStyles } from '@material-ui/core/styles';

import { MAX_WIDTH } from './constants';

const useStyles = makeStyles(theme => ({
  emailSubject: {
    '& > p': {
      textTransform: 'none',
    },
  },
  emailDrawerContainer: {
    [theme.breakpoints.down('md')]: {
      maxWidth: MAX_WIDTH,
    },
  },
  sendEmailDrawer: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  sendEmailDrawerContent: {
    height: '100%',
  },
  sendEmailForm: {
    height: '100%',
    width: MAX_WIDTH,

    [theme.breakpoints.down('md')]: {
      '&.messagePreviewOpenForMobileView': {
        display: 'none',
      },
    },
  },
  header: {
    padding: theme.spacing(4, 5),
  },
  messagePreviewIcon: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  formFactorChooser: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
  },
  label: {
    marginBottom: theme.spacing(0.5),
  },
  section: {
    marginBottom: theme.spacing(3),
  },
  attachments: {
    marginTop: -theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  checkboxContainer: {
    marginBottom: theme.spacing(1),
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: 30,
    top: 20,
  },
  messagePreviewContainer: {
    maxWidth: '100%',
    width: 460,
    height: '100%',

    [theme.breakpoints.down('md')]: {
      display: 'none',

      '&.messagePreviewOpenForMobileView': {
        width: MAX_WIDTH,
        display: 'flex',
      },
    },
  },
  messagePreviewDeviceView: {
    display: 'flex',
    justifyContent: 'center',
  },
  backButton: {
    position: 'absolute',
    left: 30,
    top: 20,

    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  requestRevisionSvg: {
    '& > path': {
      fill: theme.palette.common.blue400,
    },
  },
}));

export default useStyles;
