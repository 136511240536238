import 'react-quill/dist/quill.snow.css';

import { Button, Warning } from '@fountain/fountain-ui-components';
import { Box, Grid, Typography } from '@material-ui/core';
import { FunnelDetails } from 'api-clients/monolith';
import React, { FC, useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { ConfirmationModal } from 'components/ConfirmationModal';
import { InputEditor } from 'components/InputEditor';
import {
  makeSelectWhoami,
  makeSelectWorkflowEditorPartnerStagePreview,
} from 'containers/Auth_old/selectors';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';
import useSimpleToggle from 'hooks/useSimpleToggle';

import { StagePreview } from '../StagePreview/StagePreview';
import { CustomizationBar } from './CustomizationBar';
import { messages } from './messages';
import { SaveWorkflowConfirmationModal } from './SaveWorkflowConfirmationModal';
import useStyles from './styles';

export interface StageHeaderProps {
  opening: FunnelDetails;
}

export const StageHeader: FC<StageHeaderProps> = React.memo(({ opening }) => {
  const intl = useIntl();
  const classes = useStyles();
  const whoami = useSelector(makeSelectWhoami());
  const [skipConfirmSaveModal, setSkipConfirmSaveModal] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isEditingStageTitle, setIsEditingStageTitle] = useState(false);
  const isWorkflowEditorPartnerStagePreviewEnabled = useSelector(
    makeSelectWorkflowEditorPartnerStagePreview(),
  );

  const {
    stage,
    setStage,
    isDirty,
    isStageTitleDirty,
    onSaveChanges,
    onDiscardChanges,
    updateStageResult,
  } = useContext(StageContext);
  const { title, type } = stage;

  useEffect(() => {
    setShowWarning(isStageTitleDirty);
  }, [isStageTitleDirty, isEditingStageTitle]);

  const {
    showContent: isDiscardChangesModalOpen,
    on: openDiscardChangesModal,
    off: closeDiscardChangesModal,
  } = useSimpleToggle();

  const onConfirmDiscardChanges = () => {
    onDiscardChanges();
    closeDiscardChangesModal();
  };

  const {
    showContent: isConfirmSaveModalOpen,
    on: openConfirmSaveModal,
    off: closeConfirmSaveModal,
  } = useSimpleToggle();

  const onConfirmSaveChanges = (showAgain: boolean) => {
    onSaveChanges();
    setSkipConfirmSaveModal(showAgain);
    closeConfirmSaveModal();
  };

  const onClickSaveChanges = () => {
    if (!opening.workflow || skipConfirmSaveModal) {
      onSaveChanges();
      return;
    }
    openConfirmSaveModal();
  };

  const onCancelStageTitleEdit = () => {};

  const onConfirmStageTitleEdit = (value: string) => {
    if (value !== title) {
      setStage(currentStage => ({
        ...currentStage,
        title: value,
      }));
    }
  };

  const isButtonDisabled = !isDirty || updateStageResult.isLoading;

  return (
    <>
      <Grid className={classes.stageHeaderContent}>
        <Grid
          container
          wrap="nowrap"
          className={classes.stageTitleHeader}
          data-testid="stageTitleHeader"
          aria-label={intl.formatMessage(messages.stageTitleHeader)}
        >
          <Box display="flex" flexGrow={1}>
            <InputEditor
              value={title}
              onConfirm={onConfirmStageTitleEdit}
              onCancel={onCancelStageTitleEdit}
              setIsEditingStageTitle={setIsEditingStageTitle}
              setShowWarning={setShowWarning}
              typographyVariant="h3"
              aria-label={intl.formatMessage(messages.stageTitle)}
            />
            {showWarning &&
              whoami.calendar_event_creation_enabled &&
              type === 'SchedulerStage' && (
                <Grid className={classes.errorContent}>
                  <Warning color="secondary" className={classes.errorIcon} />
                  <Typography variant="h5" className={classes.errorText}>
                    <FormattedMessage {...messages.stageNameChangeWarning} />
                  </Typography>
                </Grid>
              )}
          </Box>
          {isWorkflowEditorPartnerStagePreviewEnabled &&
            type === 'PartnerStage' && (
              <StagePreview
                openingId={opening.external_id}
                stageId={stage.external_id}
                isPreviewDisabled={!isButtonDisabled}
                partnerOptionId={!stage.partner_option_id}
              />
            )}
          <Grid className={classes.buttonsContainer}>
            <Button
              className={classes.button}
              aria-label={intl.formatMessage(messages.discardChanges)}
              disabled={isButtonDisabled}
              disableRipple
              type="secondary"
              onClick={openDiscardChangesModal}
            >
              {intl.formatMessage(messages.discardChanges)}
            </Button>
            <Button
              className={classes.button}
              aria-label={intl.formatMessage(messages.saveChanges)}
              disabled={isButtonDisabled}
              disableRipple
              onClick={onClickSaveChanges}
            >
              {intl.formatMessage(messages.saveChanges)}
            </Button>
          </Grid>
        </Grid>

        {type !== 'RuleStage' && (
          <CustomizationBar
            funnelExternalId={opening.external_id}
            funnelSlug={opening.slug}
          />
        )}
      </Grid>

      {isDiscardChangesModalOpen && (
        <ConfirmationModal
          ariaLabelledBy={intl.formatMessage(messages.discardChanges)}
          bodyContent={<FormattedMessage {...messages.discardChangesWarning} />}
          cancelButtonContent={intl.formatMessage(messages.cancel)}
          confirmButtonContent={intl.formatMessage(messages.discard)}
          negative
          onCancel={closeDiscardChangesModal}
          onConfirm={onConfirmDiscardChanges}
          title={intl.formatMessage(messages.discardChanges)}
        />
      )}

      {isConfirmSaveModalOpen && (
        <SaveWorkflowConfirmationModal
          onCancel={closeConfirmSaveModal}
          onConfirm={onConfirmSaveChanges}
          opening={opening}
        />
      )}
    </>
  );
});
