import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  navBar: {
    width: '100%',
    display: 'flex',
    borderBottom: theme.customBorders.gray200,
    height: 50,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    backgroundColor: theme.palette.common.blue400,
    color: theme.palette.common.white,
    '& .MuiButton-root:hover': {
      backgroundColor: '#5990ff',
    },
  },
  text: {
    fontSize: theme.typography.h4.fontSize,
    lineHeight: theme.typography.h4.lineHeight,
    color: theme.palette.common.white,
  },
  menu: {
    zIndex: 10,
  },
  dialogHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: 'theme.customBorders.gray300',
  },
  dialogTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  dialogBody: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    fontSize: theme.typography.fontSize,
    color: theme.palette.common.black,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cancelButton: {
    padding: theme.spacing(1.5, 3),
    width: 94,
    borderRadius: theme.misc.borderRadius,
  },
  renameButton: {
    padding: theme.spacing(1.5, 3),
    width: 100,
    borderRadius: theme.misc.borderRadius,
  },
  searchBar: {
    margin: theme.spacing(0, 1),
    height: 40,
    borderRadius: theme.misc.borderRadius,
    display: 'flex',
    justifyContent: 'center',
  },
  menuPaper: {
    boxShadow: theme.customShadows.dropdown,
    width: '35%',
    maxWidth: 480,
    overflowY: 'auto',
    maxHeight: 480,
    minHeight: 300,
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '70%',
      left: '16%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      // override default mui style for fullscreen
      maxWidth: '100%',
      maxHeight: '100vh',
      position: 'unset', // resets position abosolute since menu is fullScreen
    },
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  menuChild: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1),
  },
  ownerApplicants: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  openingTitle: {
    color: theme.palette.common.blue400,
    width: '100%',
    textWrap: 'wrap',
  },
  separator: {
    margin: theme.spacing(0, 1),
    fontSize: '1.2rem',
  },
  newTabIcon: {
    color: theme.palette.common.gray400,
  },
  activeIndicator: {
    height: theme.typography.fontSize * 0.8,
    width: theme.typography.fontSize * 0.8,
    color: theme.palette.common.gray400,
    gridArea: 'active-indicator',
    marginRight: theme.spacing(0.75),
    '&.active': {
      color: theme.palette.common.green400,
    },
  },
}));
