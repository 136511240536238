import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  messageRowContainer: {
    display: 'flex',
    flexFlow: 'row',
    paddingTop: theme.spacing(2),
    borderTop: theme.customBorders.gray300,
    paddingBottom: theme.spacing(2),
  },
  rowBottomBuffer: {
    paddingBottom: theme.spacing(2),
  },
  rejectionReasonCell: {
    marginRight: theme.spacing(4),
    minWidth: theme.spacing(20), // 160px
  },
  messageTitleCell: {
    width: theme.spacing(28), // 224px
    marginRight: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      width: theme.spacing(40), // 320px
    },
  },
  messageTitleCellWithRejection: {
    width: theme.spacing(20), // 160px
    marginRight: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      width: theme.spacing(36), // 288px
    },
  },
  deliveryMethodCell: {
    maxWidth: theme.spacing(13.5), // 108px
    marginRight: theme.spacing(4),
    color: theme.palette.common.black,
  },
  delayCell: {
    maxWidth: theme.spacing(11.25), // 90px
    marginRight: theme.spacing(4),
  },
  disableCell: {
    marginRight: theme.spacing(4),
  },
  actionsCell: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  actionsCellContent: {
    display: 'flex',
  },
  cellHeader: {
    marginBottom: theme.spacing(0.5),
    position: 'relative',

    '&.required': {
      '&:after': {
        content: "' *'",
        color: 'red',
        display: 'inline',
        position: 'absolute',
        marginLeft: theme.spacing(0.5),
      },
    },
  },
  cellContent: {
    color: theme.palette.common.black,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    display: 'flex',
    alignItems: 'center',
  },
  messageTitleContent: {
    color: theme.palette.common.black,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  editMessageButton: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    marginRight: theme.spacing(1),
  },
  deleteMessageButton: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  editMessageIcon: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    '& path': {
      fill: theme.palette.common.gray600,
    },
  },
  deleteMessageIcon: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    '& path': {
      fill: theme.palette.common.gray600,
    },
  },
  disableSwitchContainer: {
    flexDirection: 'column-reverse',
    alignItems: 'initial',
  },
  disableSwitchLabel: {
    marginBottom: theme.spacing(2),
  },
  linkedTemplate: {
    borderRadius: theme.spacing(0.75),
    marginRight: theme.spacing(1),
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
}));
