import { EventAvailableSlot } from 'api-clients/monolith';

import { Filterable } from 'components/FilterDropdown/util';

export interface StageSelectorProps {
  handleChange: (params: Partial<EventAvailableSlot>) => void;
  errors: Partial<Record<keyof EventAvailableSlot, string>>;
  availableSlot: EventAvailableSlot;
}

export interface ConditionOption {
  type: string;
  values: Filterable[];
}

export const ParamType = {
  LOCATION_GROUP: 'location_group',
  STAGE: 'stage',
  OPENING: 'opening',
} as const;

export type ParamType = (typeof ParamType)[keyof typeof ParamType];
