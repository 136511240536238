export const ACTIVE = 'active';
export const STEALTH = 'stealth';
export const SUGGESTION = 'suggestion';

export const DEFAULT_MODEL_TYPE = 'gpt3';
export const DEFAULT_CONFIDENCE_THRESHOLD = 90;
export const DEFAULT_MODE = STEALTH;

export const MODEL_TYPES = [
  {
    value: 'gpt3',
    label: 'GPT-3',
  },
];

export const MODES = [
  {
    value: STEALTH,
    label: 'Stealth',
  },
  {
    value: ACTIVE,
    label: 'Active',
  },
  {
    value: 'inactive',
    label: 'Inactive',
  },
  {
    value: SUGGESTION,
    label: 'Suggestion',
  },
];
