/**
 *
 * MergeKeysSelect
 *
 */

import { Grid, Tab, Tabs, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  makeSelectAccountSlug,
  makeSelectCountryCode,
  makeSelectIsAdmin,
} from 'containers/Auth_old/selectors';
import globalMessages from 'shared/global/messages';
import { isAmazon } from 'utils/constants';

import useStyles from './styles';

// TODO: Make separate endpoint for merge keys
// and change this component into container.
// Right now, mergeKeys payload is available only for FileRecollectionDrawer
// ^ fix needed to support for Send Email, Send SMS modal
export function MergeKeysSelect({
  intl,
  mergeKeys,
  handleMergeKeySelect,
  hasRequiredTab = true,
}) {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);

  const accountSlug = useSelector(makeSelectAccountSlug());
  const isAdmin = useSelector(makeSelectIsAdmin());
  const countryCode = useSelector(makeSelectCountryCode());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleTabChange = useCallback((event, newValue) => {
    setTabIndex(newValue);
  });

  const {
    recollection: { keys: recollectionKeys },
    position_keys: { keys: openingKeys },
    applicant_keys: { keys: applicantKeys },
    custom_keys: { keys: customKeys },
    customer_attribute_keys: { keys: customerAttributeKeys } = { keys: [] },
  } = mergeKeys;

  const requiredKeys = recollectionKeys.filter(key => key.required);

  const handleChange = keyObj => {
    const { key } = keyObj;
    handleMergeKeySelect(key); // inserts key into email, or SMS
  };

  // const allKeys = [
  //   ...requiredKeys,
  //   ...openingKeys,
  //   ...applicantKeys,
  //   ...customKeys,
  // ];

  const tabItems = [
    {
      messageId: 'applicant',
      keys: applicantKeys,
    },
    {
      messageId: 'opening',
      keys: openingKeys,
    },
    {
      messageId: 'custom',
      keys: customKeys,
    },
    ...(customerAttributeKeys.length > 0
      ? [
          {
            messageId: 'customerAttribute',
            keys: customerAttributeKeys,
          },
        ]
      : []),
  ];

  // TODO: [AI-36] Until we scope custom keys to brands, we want to hide them from
  // DSPs so that DSPs cannot see each other's custom keys or use them by accident
  // unless they are an admin.
  if (isAmazon(accountSlug, countryCode) && !isAdmin) {
    tabItems.pop();
  }

  if (hasRequiredTab) {
    tabItems.unshift({
      messageId: 'required',
      keys: requiredKeys,
    });
  }

  return (
    <Grid
      container
      className={classes.mergeKeysSelect}
      direction="column"
      data-testid="merge-keys-select"
    >
      <Tabs
        classes={{
          root: classes.tabsRoot,
          flexContainer: classes.tabsFlexContainer,
        }}
        indicatorColor="primary"
        value={tabIndex}
        onChange={handleTabChange}
      >
        {tabItems.map(item => (
          <Tab
            key={item.messageId}
            classes={{ root: classes.tabRoot }}
            label={intl.formatMessage(globalMessages[item.messageId])}
          />
        ))}
      </Tabs>
      <Grid container className={classes.keysContainer}>
        {tabItems[tabIndex].keys.map(keyObj => (
          <Grid
            onClick={() => handleChange(keyObj)}
            container
            className={classes.key}
            key={keyObj.key}
          >
            <Typography
              variant="body2"
              color="textPrimary"
              title={keyObj.title}
              noWrap
            >
              {keyObj.title}
            </Typography>
            <Typography variant="body2" noWrap title={keyObj.key}>
              [{keyObj.key}]
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

MergeKeysSelect.propTypes = {
  intl: PropTypes.object.isRequired,
  mergeKeys: PropTypes.object,
  handleMergeKeySelect: PropTypes.func,
  hasRequiredTab: PropTypes.bool,
};

MergeKeysSelect.defaultProps = {
  mergeKeys: {},
};

export default memo(injectIntl(MergeKeysSelect));
