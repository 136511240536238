import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  address: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.I9FormStage.EmployerInformation.address`,
    defaultMessage: 'Address',
  },
  addressAdditional: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.I9FormStage.EmployerInformation.addressAdditional`,
    defaultMessage: 'Floor, Suite, PO Box, etc. (optional)',
  },
  businessName: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.I9FormStage.EmployerInformation.businessName`,
    defaultMessage: 'Business Name',
  },
  city: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.I9FormStage.EmployerInformation.city`,
    defaultMessage: 'City',
  },
  delete: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.I9FormStage.EmployerInformation.delete`,
    defaultMessage: 'Delete',
  },
  employerInformation: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.I9FormStage.EmployerInformation.employerInformation`,
    defaultMessage: 'Default Employer Information for I-9',
  },
  firstName: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.I9FormStage.EmployerInformation.firstName`,
    defaultMessage: 'First Name',
  },
  lastName: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.I9FormStage.EmployerInformation.lastName`,
    defaultMessage: 'Last Name',
  },
  state: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.I9FormStage.EmployerInformation.state`,
    defaultMessage: 'State',
  },
  title: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.I9FormStage.EmployerInformation.title`,
    defaultMessage: 'Title',
  },
  zipcode: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.I9FormStage.EmployerInformation.zipcode`,
    defaultMessage: 'Zip',
  },
  i9BusinessInfoHelperText: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.I9FormStage.EmployerInformation.i9BusinessInfoHelperText`,
    defaultMessage:
      'Complete the business information template below to populate I-9 forms',
  },
});
