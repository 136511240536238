import {
  CustomTooltip,
  Loader,
  Modal,
  ModalContent,
  ModalHeader,
} from '@fountain/fountain-ui-components';
import { Grid, Typography, useTheme } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { classNames } from 'react-extras';
import { useIntl } from 'react-intl';
import useScrollOnDrag from 'react-scroll-ondrag';
import { v4 as uuid } from 'uuid';

import useBlobRotate from 'hooks/useBlobRotate';
import AddSvg from 'images/_shared/add.svg';
import FullscreenSvg from 'images/_shared/fullscreen.svg';
import RotateLeftSvg from 'images/_shared/rotate-left.svg';
import RotateRightSvg from 'images/_shared/rotate-right.svg';
import SubtractSvg from 'images/_shared/subtract.svg';
import CloseIcon from 'images/close.svg';
import DocumentIcon from 'images/document.svg';
import { extensionFinder } from 'utils/extensionFinder';
import { numberFormatting } from 'utils/formatNumber';
import { truncateFileName } from 'utils/stringutils';

import useStyles from '../styles';
import messages from './messages';
import PdfViewer from './PDFViewer';

const DEFAULT_SCALE = 1;
const MAX_SCALE = 5;
const MIN_SCALE = 0.2;
const ROTATION_ANGLE = 90;
const MAX_ROTATION = 360;

export const DocumentFilePreview = ({
  applicantId,
  initialImageSrc,
  filename,
  id,
  requestedReupload,
  previewUrl,
}) => {
  const theme = useTheme();
  const { handleRotateSave, isLoading: isLoadingBlob } =
    useBlobRotate(applicantId);
  const filePreviewRef = useRef();
  const { events } = useScrollOnDrag(filePreviewRef);
  const { formatMessage } = useIntl();

  const [rotate, setRotate] = useState(0);
  const [scale, setScale] = useState(DEFAULT_SCALE);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);

  const classes = useStyles({ rotate, scale });

  const handleDocumentModalClose = () => setIsDocumentModalOpen(false);

  const onRotate = targetDegree => {
    handleRotateSave(id, targetDegree);
    if (Math.abs(rotate + targetDegree) === MAX_ROTATION) return setRotate(0);
    return setRotate(rotate + targetDegree);
  };

  const onZoomInClick = () => {
    const incrementValue = scale < DEFAULT_SCALE ? 0.2 : DEFAULT_SCALE;
    const newScale = numberFormatting(scale + incrementValue, 1);
    if (newScale > MAX_SCALE) return setScale(MAX_SCALE);
    return setScale(newScale);
  };

  const onZoomOutClick = () => {
    const decrementValue = scale <= DEFAULT_SCALE ? 0.2 : DEFAULT_SCALE;
    const newScale = numberFormatting(scale - decrementValue, 1);
    if (newScale < MIN_SCALE) return setScale(MIN_SCALE);
    return setScale(newScale);
  };

  const onFullscreenClick = () => {
    setIsFullscreen(!isFullscreen);
  };

  const onDocumentPreview = () => {
    setIsDocumentModalOpen(!isDocumentModalOpen);
  };

  const handleCloseFullscreen = () => {
    setIsFullscreen(false);
  };

  const renderHoverActions = () => {
    if (requestedReupload) return null;
    return (
      <Grid
        container
        className={classes.previewHoverActions}
        alignItems="center"
        justify="flex-end"
      >
        <Grid item container direction="row" justify="flex-end">
          <CustomTooltip title={formatMessage(messages.fullScreen)} dense>
            <ButtonBase
              onClick={onFullscreenClick}
              className={classNames(classes.previewHoverAction, {
                fullScreen: isFullscreen,
              })}
            >
              <img src={FullscreenSvg} alt="fullscreen" />
            </ButtonBase>
          </CustomTooltip>
          <CustomTooltip title={formatMessage(messages.rotateRight)} dense>
            <ButtonBase
              onClick={() => onRotate(ROTATION_ANGLE)}
              className={classes.previewHoverAction}
            >
              <img src={RotateRightSvg} alt="rotate-right" />
            </ButtonBase>
          </CustomTooltip>
          <CustomTooltip title={formatMessage(messages.rotateLeft)} dense>
            <ButtonBase
              onClick={() => onRotate(-ROTATION_ANGLE)}
              className={classNames(classes.previewHoverAction, {
                rotateCounterClockwise: true,
              })}
            >
              <img src={RotateLeftSvg} alt="rotate-left" />
            </ButtonBase>
          </CustomTooltip>
          <CustomTooltip title={formatMessage(messages.zoomIn)} dense>
            {/* cannot supply disabled element to tooltip, hence <span> */}
            <span>
              <ButtonBase
                onClick={onZoomInClick}
                classes={{ disabled: classes.previewHoverActionDisabled }}
                className={classes.previewHoverAction}
                disabled={scale === MAX_SCALE}
              >
                <img src={AddSvg} alt="zoom-in" />
              </ButtonBase>
            </span>
          </CustomTooltip>
          <CustomTooltip title={formatMessage(messages.zoomOut)} dense>
            {/* cannot supply disabled element to tooltip, hence <span> */}
            <span>
              <ButtonBase
                onClick={onZoomOutClick}
                classes={{ disabled: classes.previewHoverActionDisabled }}
                className={classNames(classes.previewHoverAction, {
                  zoomOut: true,
                })}
                disabled={scale === MIN_SCALE}
              >
                <img src={SubtractSvg} alt="zoom-out" />
              </ButtonBase>
            </span>
          </CustomTooltip>
        </Grid>
      </Grid>
    );
  };

  const renderDocumentHoverActions = () => {
    if (requestedReupload) return null;
    return (
      <Grid
        container
        className={classes.previewHoverActions}
        alignItems="center"
        justify="space-between"
        onClick={onDocumentPreview}
      >
        <Grid item container direction="row" justify="flex-end">
          <CustomTooltip title={formatMessage(messages.fullScreen)} dense>
            <ButtonBase
              onClick={onDocumentPreview}
              className={classNames(classes.previewHoverAction, {
                fullScreen: isFullscreen,
              })}
            >
              <img src={FullscreenSvg} alt="fullscreen" />
            </ButtonBase>
          </CustomTooltip>
        </Grid>
      </Grid>
    );
  };
  const fileExtension = extensionFinder(filename);
  if (
    initialImageSrc &&
    ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)
  ) {
    return (
      <Grid
        container
        className={classNames(
          classes.previewContainer,
          requestedReupload && classes.opaque,
          {
            isFullscreen,
          },
        )}
        data-testid="Document-DocumentFilePreview-ImagePreview"
        ref={filePreviewRef}
        {...events}
      >
        {isFullscreen && (
          <img
            src={CloseIcon}
            alt="close-icon"
            className={classes.closeFullscreen}
            onClick={handleCloseFullscreen}
            role="presentation"
          />
        )}
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="center"
          className={classes.previewContainerContent}
          onClick={onFullscreenClick}
        >
          {isLoadingBlob ? (
            <Loader fullSection size="1.5rem" />
          ) : (
            <img
              alt="file preview"
              className={classNames(classes.filePreview, {
                inactive: requestedReupload,
                adjusted: rotate !== 0 || scale !== 0,
              })}
              src={initialImageSrc}
            />
          )}
        </Grid>
        {renderHoverActions()}
      </Grid>
    );
  }

  if (previewUrl && ['pdf'].includes(fileExtension)) {
    return (
      <Grid
        container
        className={classNames(
          classes.previewContainer,
          requestedReupload && classes.opaque,
          {
            isFullscreen,
          },
        )}
        data-testid="Document-DocumentFilePreview-PdfPreview"
        ref={filePreviewRef}
        {...events}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="center"
          className={classes.previewContainerContent}
          onClick={onDocumentPreview}
        >
          {isLoadingBlob ? (
            <Loader fullSection size="1.5rem" />
          ) : (
            <PdfViewer
              pdfBlob={previewUrl}
              isPreview
              containerId={`pdf-viewer-${uuid()}`}
            />
          )}
          {isDocumentModalOpen && (
            <Modal
              ariaLabelledBy="move-applicant-modal"
              open={isDocumentModalOpen}
              onClose={handleDocumentModalClose}
              maxWidth={theme.customHeights.documentPreviewModal}
              maxHeight={theme.customHeights.documentPreviewModal}
              /* fullScreen */
            >
              <ModalHeader
                Icon={DocumentIcon}
                onClose={handleDocumentModalClose}
              />
              <ModalContent>
                <PdfViewer
                  pdfBlob={previewUrl}
                  height={theme.customHeights.documentPreviewModal}
                  containerId={`pdf-viewer-${uuid()}`}
                />
              </ModalContent>
            </Modal>
          )}
        </Grid>
        {renderDocumentHoverActions()}
      </Grid>
    );
  }

  return (
    <Typography
      data-testid="Document-DocumentFilePreview-FileName"
      className={classes.noPreview}
      variant="body2"
    >
      {/* todo: handle case where filename does not have extension; currently returns `filename.filename` */}
      {truncateFileName(filename)}
    </Typography>
  );
};

DocumentFilePreview.propTypes = {
  applicantId: PropTypes.string.isRequired,
  initialImageSrc: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  requestedReupload: PropTypes.bool,
  previewUrl: PropTypes.string,
};

export default DocumentFilePreview;
