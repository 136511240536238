/*
 *
 * FileRecollectionDrawer reducer
 *
 */
import produce from 'immer';

import { SET_FILE_RECOLLECTION_DATA } from './constants';

export const initialState = {
  fileRecollectionData: {
    selected_user: {
      value: '',
      label: '',
    },
    available_senders: [],
    email_subject: '',
    merge_keys: [],
    message_templates: [],
    notify_users_on_submit: false,
    recollection_reason: null,
    recollection_reasons: [],
  },
};

const fileRecollectionDrawerReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_FILE_RECOLLECTION_DATA:
        draft.fileRecollectionData = action.fileRecollectionData;
        break;
      default:
        break;
    }
  });

export default fileRecollectionDrawerReducer;
