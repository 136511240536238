import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  lessonlyIntegration: {
    id: `app.containers.WorkflowEditor.components.StageDetail.LearningStage.IntegrationCard.lessonlyIntegration`,
    defaultMessage: 'Lessonly Integration',
  },
  manageIntegration: {
    id: `app.containers.WorkflowEditor.components.StageDetail.LearningStage.IntegrationCard.manageIntegration`,
    defaultMessage: 'Manage Integration',
  },
  content: {
    id: `app.containers.WorkflowEditor.components.StageDetail.LearningStage.IntegrationCard.content`,
    defaultMessage: 'Content',
  },
  contentRequired: {
    id: `app.containers.WorkflowEditor.components.StageDetail.LearningStage.IntegrationCard.contentRequired`,
    defaultMessage: 'Select content',
  },
  shouldAutoAdvanceWhenLabelsComplete: {
    id: `app.containers.WorkflowEditor.components.StageDetail.LearningStage.IntegrationCard.shouldAutoAdvanceWhenLabelsComplete`,
    defaultMessage:
      'Automatically move applicant to next stage when all labels are completed',
  },
  automaticallyMoveAfterLearningCompleted: {
    id: `app.containers.WorkflowEditor.components.StageDetail.LearningStage.IntegrationCard.automaticallyMoveAfterLearningCompleted`,
    defaultMessage:
      'Automatically move applicant to next stage after learning is completed (recommended)',
  },
  enableLessonlyNotifications: {
    id: `app.containers.WorkflowEditor.components.StageDetail.LearningStage.IntegrationCard.enableLessonlyNotifications`,
    defaultMessage:
      'Enable lessonly.com assignment notifications (not recommended)',
  },
  byEnablingThisToggle: {
    id: `app.containers.WorkflowEditor.components.StageDetail.LearningStage.IntegrationCard.byEnablingThisToggle`,
    defaultMessage:
      'When enabling, Lessonly will send the applicant an invitation to the learning material, which will require the applicant to create a Lessonly account and thereby add more friction to the process.\n\nWhen disabled, use [LESSONLY_LINK] in the automated message to send the applicant directly to the learning material.',
  },
  lessons: {
    id: `app.containers.WorkflowEditor.components.StageDetail.LearningStage.IntegrationCard.lessons`,
    defaultMessage: 'Lessons',
  },
  paths: {
    id: `app.containers.WorkflowEditor.components.StageDetail.LearningStage.IntegrationCard.paths`,
    defaultMessage: 'Paths',
  },
});
