import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: '1100px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowY: 'auto',
    padding: theme.spacing(0, 10, 0, 10),
    height: 'calc(100vh - (52px))',
  },
  header: {
    paddingTop: theme.spacing(2),
  },
  icon: {
    width: '24px',
    height: '18px',
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  suggestion: {
    backgroundColor: theme.palette.common.blue100,
  },
}));
