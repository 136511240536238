import { useState } from 'react';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import { apiGet } from 'utils/axios';

/**
 * @typedef {object} Stage
 * @property {boolean} is_current_stage
 * @property {boolean} is_next_stage
 * @property {boolean} is_on_hold_stage
 * @property {boolean} is_rejected_stage
 * @property {string} stage_id
 * @property {boolean} warn_w4_federal
 * @property {boolean} warn_wotc
 * @property {number|null} validation_group_id
 */

/**
 * @typedef {object} FetchDataReturn
 * @property {Stage[]} stages
 */

/**
 * @typedef {object} UseStageStatusReturn
 * @property {FetchDataReturn} data
 * @property {object|null} error
 * @property {boolean} isLoading
 * @property {@function} fetchData
 * /

/**
 * @param {string} applicantId
 * @returns {UseStageStatusReturn}
 */
export const useStageStatus = applicantId => {
  const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/stage_status`;

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await apiGet(url);
      setData(res.data);
      return res.data;
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }

    return { stages: [] };
  };

  return { data, error, isLoading, fetchData };
};
