/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable camelcase */
import {
  Button,
  Input,
  StyledReactSelect,
} from '@fountain/fountain-ui-components';
import { Grid, TextField, Typography } from '@material-ui/core';
import {
  AutomatedResponsesDetail,
  AutomatedResponsesService,
  CancelablePromise,
  MessageTemplateDetail,
  UpdateAutomatedResponseParams,
} from 'api-clients/monolith';
import { CreateAutomatedResponseParams } from 'api-clients/monolith/models/CreateAutomatedResponseParams';
import classnames from 'classnames';
import { useForm } from 'hooks';
import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Error } from 'components/Error';
import { makeSelectFeatureChatbotAdminEnabled } from 'containers/Auth_old/selectors';
import { addMessageAction } from 'containers/FlashMessage/actions';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';
import globalMessages from 'shared/global/messages';

import messages from './messages';
import { useStyles } from './styles';
import { FormValues } from './types';

export interface AddViewAutomatedResponseProps {
  setIsOpenForm: React.Dispatch<React.SetStateAction<boolean>>;
  isViewMode: boolean;
  selectedAutomatedResponse: AutomatedResponsesDetail | null;
  setSelectedAutomatedResponse: React.Dispatch<
    React.SetStateAction<AutomatedResponsesDetail | null>
  >;
  messageTemplates: MessageTemplateDetail[];
}

export const AddViewAutomatedResponse: FC<AddViewAutomatedResponseProps> = ({
  setIsOpenForm,
  isViewMode,
  selectedAutomatedResponse,
  setSelectedAutomatedResponse,
  messageTemplates,
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const isFeatureChatbotAdminEnabled = useSelector(
    makeSelectFeatureChatbotAdminEnabled(),
  );

  const [isEditMode, setIsEditMode] = useState(false);

  const cancelForm = () => {
    setIsOpenForm(false);
    setSelectedAutomatedResponse(null);
  };

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const { mutation: createAutomatedResponse } = useApiServiceMutation<
    void,
    (requestBody: CreateAutomatedResponseParams) => CancelablePromise<void>,
    { errors: string[] }
  >(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    AutomatedResponsesService.postInternalApiChatbotAutomatedResponses,
    {
      onSuccess: () => {
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.createAutomatedResponseSuccess),
            'success',
          ),
        );
        setIsOpenForm(false);
      },
      onError: errorBody => {
        const errorMessages = errorBody?.errors?.join('. ') ?? '';
        dispatch(addMessageAction(errorMessages, 'error'));
      },
    },
  );

  const { mutation: updateAutomatedResponse } = useApiServiceMutation<
    void,
    (
      id: number,
      requestBody: UpdateAutomatedResponseParams,
    ) => CancelablePromise<void>,
    { errors: string[] }
  >(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    AutomatedResponsesService.patchInternalApiChatbotAutomatedResponses,
    {
      onSuccess: () => {
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.updateAutomatedResponseSuccess),
            'success',
          ),
        );
        setSelectedAutomatedResponse(null);
      },
      onError: errorBody => {
        const errorMessages = errorBody?.errors?.join('. ') ?? '';
        dispatch(addMessageAction(errorMessages, 'error'));
      },
    },
  );

  const validate = () => {
    const errors: Partial<Record<keyof FormValues, string>> = {};

    if (!values.question) {
      errors.question = intl.formatMessage(messages.requiredField);
    }
    if (!values.intent) {
      errors.intent = intl.formatMessage(messages.requiredField);
    }
    if (!values.message) {
      errors.message = intl.formatMessage(messages.requiredField);
    }
    if (!values.label && values.label !== 0) {
      errors.label = intl.formatMessage(messages.requiredField);
    }
    if (!values.selectedTemplate) {
      errors.selectedTemplate = intl.formatMessage(messages.requiredField);
    }

    return errors;
  };

  const onSubmit = () => {
    let payload = {};

    if (isEditMode) {
      payload = {
        automated_response: {
          question: values.question,
          intent: values.intent,
          message: values.message,
          label: values.label,
          message_template_id: values.selectedTemplate?.id,
        },
      };
      if (selectedAutomatedResponse && selectedAutomatedResponse.id) {
        updateAutomatedResponse(selectedAutomatedResponse.id, payload);
      }
    } else {
      payload = {
        automated_response: {
          question: values.question,
          intent: values.intent,
          message: values.message,
          label: values.label,
        },
      };
      createAutomatedResponse(payload);
    }
  };

  const selectedOption =
    messageTemplates &&
    messageTemplates.find(
      option => option.id === selectedAutomatedResponse?.message_template_id,
    );

  const defaultFormValue: FormValues = {
    question: selectedAutomatedResponse?.question ?? '',
    intent: selectedAutomatedResponse?.intent ?? '',
    message: selectedAutomatedResponse?.message ?? '',
    label: selectedAutomatedResponse?.label ?? '',
    selectedTemplate: selectedOption ?? { id: 0, name: '' },
  };

  const { values, handleChange, handleSubmit, errors } = useForm<FormValues>(
    onSubmit,
    validate,
    defaultFormValue,
  );

  const handleGetTemplateOptionLabel = (option: { name: string }) =>
    option.name;
  const handleGetTemplateOptionValue = (option: { id: string }) => option.id;

  const handleChangeSelectedTemplate = (id: number) => {
    const messageTemplate = messageTemplates.find(option => option.id === id);

    handleChange({
      message: messageTemplate?.sms_text,
      selectedTemplate: messageTemplate,
    });
  };

  return (
    <Grid className={styles.addAutomatedResponse}>
      <form className={styles.addAutomatedResponseForm} onSubmit={handleSubmit}>
        {isViewMode && isFeatureChatbotAdminEnabled && (
          <Grid item xs={12} md={12} className={styles.editButton}>
            <Button
              type="secondary"
              size="small"
              onClick={handleEdit}
              style={{ width: 'auto' }}
            >
              <FormattedMessage {...globalMessages.edit} />
            </Button>
          </Grid>
        )}
        <Grid item xs={12} md={12} className={styles.inputContent}>
          <Typography variant="subtitle1" className={styles.label}>
            <FormattedMessage {...messages.question} />
          </Typography>

          <Input
            fullWidth
            value={values?.question}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChange({ question: event.target.value })
            }
            data-testid="automated-response-question"
            error={Boolean(errors.question)}
            disabled={!isEditMode && isViewMode}
            className={classnames({
              [styles.inputText]: isViewMode,
            })}
          />
          <Error error={errors.question} align="right" />
        </Grid>

        <Grid item xs={12} md={12} className={styles.inputContent}>
          <Typography variant="subtitle1" className={styles.label}>
            <FormattedMessage {...messages.intent} />
          </Typography>

          <Input
            fullWidth
            value={values?.intent}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChange({ intent: event.target.value })
            }
            data-testid="automated-response-intent"
            error={Boolean(errors.intent)}
            disabled={!isEditMode && isViewMode}
            className={classnames({
              [styles.inputText]: isViewMode,
            })}
          />
          <Error error={errors.intent} align="right" />
        </Grid>

        {isViewMode && (
          <Grid item xs={12} md={12} className={styles.inputContent}>
            <Typography variant="subtitle1" className={styles.label}>
              <FormattedMessage {...messages.messageTemplates} />
            </Typography>
            <Grid data-testid="automated-response-messageTemplate">
              <StyledReactSelect
                options={messageTemplates}
                getOptionLabel={handleGetTemplateOptionLabel}
                getOptionValue={handleGetTemplateOptionValue}
                value={values.selectedTemplate}
                canSelectAll={false}
                isTargetDisabled={false}
                showArrow={false}
                target={false}
                inlineSearch={false}
                isDisabled={!isEditMode && isViewMode}
                onChange={(option: { id: number }) => {
                  handleChangeSelectedTemplate(option.id);
                }}
                error={Boolean(errors.selectedTemplate)}
                className={classnames({
                  [styles.inputText]: isViewMode,
                })}
              />
            </Grid>
            <Error error={errors.selectedTemplate} align="right" />
          </Grid>
        )}

        <Grid item xs={12} md={12} className={styles.inputContent}>
          <Typography variant="subtitle1" className={styles.label}>
            <FormattedMessage {...messages.message} />
          </Typography>

          <TextField
            variant="outlined"
            value={values?.message}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChange({ message: event.target.value })
            }
            data-testid="automated-response-message"
            fullWidth
            multiline
            rows={3}
            rowsMax={10}
            disabled={isViewMode}
            error={isViewMode ? false : Boolean(errors.message)}
            className={classnames({
              [styles.inputText]: isViewMode,
            })}
          />
          <Error error={errors.message} align="right" />
        </Grid>

        <Grid item xs={12} md={12} className={styles.inputContent}>
          <Typography variant="subtitle1" className={styles.label}>
            <FormattedMessage {...messages.label} />
          </Typography>

          <Input
            fullWidth
            type="number"
            value={values?.label}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChange({ label: event.target.valueAsNumber })
            }
            data-testid="automated-response-label"
            disabled={!isEditMode && isViewMode}
            error={Boolean(errors.label)}
            className={classnames({
              [styles.inputText]: isViewMode,
            })}
          />
          <Error error={errors.label} align="right" />
        </Grid>

        <Grid>
          {isViewMode ? (
            <>
              {isFeatureChatbotAdminEnabled && (
                <Button
                  type="primary"
                  className={styles.submitButton}
                  disabled={!isEditMode}
                  submit
                >
                  <FormattedMessage {...messages.update} />
                </Button>
              )}
            </>
          ) : (
            <Button type="primary" className={styles.submitButton} submit>
              <FormattedMessage {...messages.create} />
            </Button>
          )}
          <Button type="secondary" onClick={cancelForm}>
            <FormattedMessage {...globalMessages.cancel} />
          </Button>
        </Grid>
      </form>
    </Grid>
  );
};
