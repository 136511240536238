import { EventsService } from 'api-clients/monolith';
import { useCallback } from 'react';

import { useApiService } from 'hooks/useApiService';

export const useStageOnSiteAddresses = ({
  stageTitles,
  openingTitles,
  locationGroupTitles,
}: {
  stageTitles: Array<string>;
  openingTitles?: Array<string>;
  locationGroupTitles?: Array<string>;
}) => {
  const locationService = useCallback(
    () =>
      EventsService.getInternalApiEventsConditions(
        stageTitles,
        openingTitles,
        locationGroupTitles,
      ),
    [locationGroupTitles, openingTitles, stageTitles],
  );
  const { result } = useApiService(locationService);
  return { result };
};
