import { TextButton } from '@fountain/fountain-ui-components';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import LeftArrow from 'images/arrow-left.svg';
import globalMessages from 'shared/global/messages';

import useStyles from './styles';

const BackButton = props => {
  const classes = useStyles();
  return (
    <TextButton color="secondary" component="a" {...props}>
      <img src={LeftArrow} alt="back arrow" className={classes.leftArrow} />
      <FormattedMessage {...globalMessages.back} />
    </TextButton>
  );
};

BackButton.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
};

export default BackButton;
