import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  CareerSiteParsingAbility,
  ChatbotService,
  ChatbotTrainingStatus,
  ChatbotWidgetBrand,
  ChatbotWidgetSettingFunnel,
  ChatbotWidgetSettingResponse,
  ChatbotWidgetSettings,
} from 'api-clients/monolith';
import React, { useEffect, useMemo, useState, VFC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  makeSelectFountainAiCarreerSiteSettingEnabled,
  makeSelectFountainAiFaqEnabled,
  makeSelectIsAccountFountainAiEnabled,
} from 'containers/Auth_old/selectors';
import { addMessageAction } from 'containers/FlashMessage/actions';

import { ApplyToJobSettings } from './ApplyToJobSettings';
import { CareerSiteSettings } from './CareerSiteSettings';
import { EmbedCode } from './EmbedCode';
import { FaqSettings } from './FaqSettings';
import { Footer } from './Footer';
import { GeneralSettings } from './GeneralSettings';
import { messages } from './messages';
import { HandleSettingChange, SelectedBrand, WidgetUrl } from './types';
import {
  getChatWidgetBaseUrl,
  getEmbedCodeSnippet,
  getWidgetUrl,
} from './utils';

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(4, 0, 1),
  },
  saveButtonWrapper: {
    left: 0,
    width: '100%',
    textAlign: 'end',
    paddingTop: theme.spacing(2),
  },
  button: {
    borderRadius: '5px',
    width: 'fit-content',
  },
}));

export interface SettingsProps {
  brands: ChatbotWidgetBrand[];
  selectedBrand: SelectedBrand;
  handleBrandChange: (brands: ChatbotWidgetBrand) => void;
  settingsResponse: ChatbotWidgetSettingResponse;
  widgetUrl: string;
  setWidgetUrl: React.Dispatch<React.SetStateAction<WidgetUrl>>;
}

export const Settings: VFC<SettingsProps> = ({
  brands,
  selectedBrand,
  settingsResponse,
  handleBrandChange,
  widgetUrl,
  setWidgetUrl,
}) => {
  const chatWidgetBaseUrl = getChatWidgetBaseUrl(window.location.hostname);
  const dispatch = useDispatch();
  const intl = useIntl();
  const styles = useStyles();

  const isFeatureFountainAiEnabled = useSelector(
    makeSelectIsAccountFountainAiEnabled(),
  );
  const isFeatureFountainAiFaqEnabled = useSelector(
    makeSelectFountainAiFaqEnabled(),
  );

  const isFeatureFountainAiCarreerSiteSettingEnabled = useSelector(
    makeSelectFountainAiCarreerSiteSettingEnabled(),
  );

  const [settings, setSettings] = useState<ChatbotWidgetSettings>(
    settingsResponse.settings,
  );
  const [trainingStatus, setTrainingStatus] = useState<ChatbotTrainingStatus>(
    settingsResponse.faq_knowledge_base_status,
  );
  const [careerSiteParsingAbility, setCareerSiteParsingAbility] =
    useState<CareerSiteParsingAbility>(
      settingsResponse.career_site_parsing_ability,
    );
  const [careerSiteTrainingStatus, setCareerSiteTrainingStatus] =
    useState<ChatbotTrainingStatus>(
      settingsResponse.career_site_scraping_status,
    );

  const [isUpdatingSetting, setIsUpdatingSetting] = useState(false);
  const [isFaqOnlyWidget, setIsFaqOnlyWidget] = useState(false);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [disableCareerSiteSetting, setDisableCareerSiteSetting] =
    useState<boolean>(
      !['', 'FAILURE'].includes(
        settingsResponse.career_site_scraping_status ?? '',
      ),
    );
  const [disableFaqSection, setDisableFaqSection] = useState<boolean>(
    !['', 'FAILURE'].includes(settingsResponse.faq_knowledge_base_status ?? ''),
  );

  useEffect(() => {
    setIsFaqOnlyWidget(
      !isFeatureFountainAiEnabled && isFeatureFountainAiFaqEnabled,
    );
  }, [isFeatureFountainAiEnabled, isFeatureFountainAiFaqEnabled]);

  const codeSnippet = useMemo(
    () =>
      getEmbedCodeSnippet(
        selectedBrand.brandId,
        chatWidgetBaseUrl,
        settings.open_widget_by_default,
        selectedBrand.isAllBrands,
        isFaqOnlyWidget,
      ),
    [
      selectedBrand,
      chatWidgetBaseUrl,
      settings.open_widget_by_default,
      isFaqOnlyWidget,
    ],
  );

  useEffect(
    () =>
      setWidgetUrl(
        getWidgetUrl(
          selectedBrand.brandId,
          chatWidgetBaseUrl,
          selectedBrand.isAllBrands,
          isFaqOnlyWidget,
        ),
      ),
    [selectedBrand, chatWidgetBaseUrl, setWidgetUrl, isFaqOnlyWidget],
  );

  const handleSettingChange: HandleSettingChange = (key, value) => {
    if (key === 'career_site_url' || key === 'no_of_jobs_for_career_site') {
      setIsSaveButtonEnabled(false);
    } else {
      setIsSaveButtonEnabled(true);
    }
    setSettings({ ...settings, [key]: value });
  };

  const saveSettings = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const selectedFunnelIds =
      settings.selected_funnels?.map(
        (funnel: ChatbotWidgetSettingFunnel) => funnel.external_id,
      ) ?? [];

    setIsUpdatingSetting(true);
    ChatbotService.putInternalApiChatbotSettings(
      selectedBrand.brandId,
      selectedBrand.isAllBrands,
      {
        widget_setting: {
          ...settings,
          selected_funnel_ids: selectedFunnelIds,
          career_site_parsing_ability: careerSiteParsingAbility,
        },
      },
    )
      .then(result => {
        setIsSaveButtonEnabled(false);
        setSettings(result.settings);
        setTrainingStatus(result.faq_knowledge_base_status);
        setCareerSiteParsingAbility(result.career_site_parsing_ability);
        setCareerSiteTrainingStatus(result.career_site_scraping_status);
        setDisableCareerSiteSetting(
          !['', 'FAILURE'].includes(result.career_site_scraping_status ?? ''),
        );
        setDisableFaqSection(
          !['', 'FAILURE'].includes(result.faq_knowledge_base_status ?? ''),
        );
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.settingUpdateSuccess),
            'success',
          ),
        );
      })
      .catch(() => {
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.settingUpdateFailed),
            'error',
          ),
        );
      })
      .finally(() => {
        setIsUpdatingSetting(false);
      });
  };

  return (
    <form onSubmit={event => saveSettings(event)}>
      <GeneralSettings
        brands={brands}
        selectedBrandOption={selectedBrand.option}
        handleBrandChange={handleBrandChange}
        settings={settings}
        handleSettingChange={handleSettingChange}
      />
      {isFeatureFountainAiFaqEnabled && (
        <>
          <Divider className={styles.divider} />
          <FaqSettings
            settings={settings}
            handleSettingChange={handleSettingChange}
            selectedBrand={selectedBrand}
            trainingStatus={trainingStatus}
            setTrainingStatus={setTrainingStatus}
            faqSectionDisabled={disableFaqSection}
            setDisableFaqSection={setDisableFaqSection}
          />
        </>
      )}
      {isFeatureFountainAiEnabled && (
        <>
          <Divider className={styles.divider} />
          <ApplyToJobSettings
            settings={settings}
            selectedBrand={selectedBrand}
            handleSettingChange={handleSettingChange}
          />
        </>
      )}
      {isFeatureFountainAiCarreerSiteSettingEnabled && (
        <>
          <Divider className={styles.divider} />
          <CareerSiteSettings
            settings={settings}
            handleSettingChange={handleSettingChange}
            selectedBrand={selectedBrand}
            careerSiteParsingAbility={careerSiteParsingAbility}
            setCareerSiteParsingAbility={setCareerSiteParsingAbility}
            careerSiteTrainingStatus={careerSiteTrainingStatus}
            setCareerSiteTrainingStatus={setCareerSiteTrainingStatus}
            disableCareerSiteSetting={disableCareerSiteSetting}
            setDisableCareerSiteSetting={setDisableCareerSiteSetting}
          />
        </>
      )}
      <Divider className={styles.divider} />
      <EmbedCode codeSnippet={codeSnippet} />
      <Footer
        codeSnippet={codeSnippet}
        widgetUrl={widgetUrl}
        isUpdaing={isUpdatingSetting}
        isSaveButtonEnabled={isSaveButtonEnabled}
      ></Footer>
    </form>
  );
};
