import { Theme } from '@material-ui/core';
import { makeStyles, StyleRules } from '@material-ui/core/styles';

const generateStyles = (theme: Theme) => {
  const styles: StyleRules = {
    typeCell: {
      padding: theme.spacing(0, 1),
    },
    sourceTypeCell: {
      width: 50,
    },
    logo: {
      maxWidth: 50,
      maxHeight: 27,
    },
  };
  return styles;
};

const useStyles: () => Record<keyof ReturnType<typeof generateStyles>, string> =
  makeStyles(generateStyles);

export default useStyles;
