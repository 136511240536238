import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    locationContainer: {
      marginTop: theme.spacing(3),
    },
    description: {
      marginTop: theme.spacing(1),
    },
    selectLocationContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(2),
    },
    selectLocation: {
      marginTop: theme.spacing(0.4),
      width: '50%',
    },
    gapBetween: {
      gap: theme.spacing(2),
    },
    label: {
      marginBottom: theme.spacing(0.5),
    },
    nestedRequired: {
      '& p': {
        '&::after': {
          content: "'*'",
          color: theme.palette.common.red400,
          margin: theme.spacing(0.5),
        },
      },
    },
    select: {
      '& .MuiSelect-root': {
        padding: theme.spacing(2),
      },
    },
    addNewOption: {
      justifyContent: 'flex-start',
    },
  }),
);
