import { Grid, Typography } from '@material-ui/core';
import { BotType } from 'api-clients/monolith';
import { ChatbotModelMode } from 'api-clients/monolith/models/ChatbotModelMode';
import React, { VFC } from 'react';
import { useSelector } from 'react-redux';

import { makeSelectFeatureChatbotAdminEnabled } from 'containers/Auth_old/selectors';
import { FAQ_BOT } from 'containers/Chatbot/constants';

import { useStyles } from './styles';

export interface TopLabelsProps {
  botType: BotType;
  modelName?: string;
  modelMode?: ChatbotModelMode;
}

export const TopLabels: VFC<TopLabelsProps> = ({
  botType,
  modelName,
  modelMode,
}) => {
  const styles = useStyles();
  const isFeatureChatbotAdminEnabled = useSelector(
    makeSelectFeatureChatbotAdminEnabled(),
  );

  return (
    <Grid
      item
      container
      direction="row"
      spacing={1}
      className={styles.labelsWrapper}
    >
      <Grid item>
        <Typography variant="body2" className={styles.label}>
          {botType}
        </Typography>
      </Grid>
      {modelName && isFeatureChatbotAdminEnabled && botType === FAQ_BOT && (
        <Grid item>
          <Typography variant="body2" className={styles.label}>
            {modelName}
          </Typography>
        </Grid>
      )}
      {modelMode && isFeatureChatbotAdminEnabled && botType === FAQ_BOT && (
        <Grid item>
          <Typography variant="body2" className={styles.label}>
            {modelMode}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
