import { Button, ModalHeader } from '@fountain/fountain-ui-components';
import { Drawer, Grid, Typography } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  FormBuilderProvider,
  QuestionDrawerProvider,
  useQuestionDrawerContextState,
} from './context';
import { useWorkflowReviewStage } from './hooks';
import { formBuilderMessages } from './messages';
import { QuestionnaireDisplay } from './QuestionnaireDisplay';
import { CreateEditQuestionForm } from './QuestionTypeForms/CreateEditQuestion/CreateEditQuestionForm';
import { useFormBuilderStyles } from './styles';

const { header, addQuestionText, editQuestionText } = formBuilderMessages;

const InnerFormBuilder = () => {
  const { activeDataEntries } = useWorkflowReviewStage();
  const classes = useFormBuilderStyles();
  const { isDrawerOpen, isEdit, setCreateQuestion, closeDrawer } =
    useQuestionDrawerContextState();
  const intl = useIntl();

  const modalHeaderText = isEdit ? editQuestionText : addQuestionText;
  return (
    <Grid container xs={12} direction="column">
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        classes={{
          paperAnchorRight: classes.drawer,
        }}
        onClose={closeDrawer}
      >
        {/* DrawerHeader doesnt have the built in close and they look pretty interchangable */}
        <ModalHeader
          ariaLabelledBy={intl.formatMessage(modalHeaderText)}
          onClose={closeDrawer}
          showIcon={false}
          className={classes.drawerHeader}
        >
          <FormattedMessage {...modalHeaderText} />
        </ModalHeader>
        <FormBuilderProvider>
          <CreateEditQuestionForm />
        </FormBuilderProvider>
      </Drawer>
      <Grid item className={classes.container} alignItems="center">
        <Typography className={classes.header}>
          <FormattedMessage {...header} />
        </Typography>
        <Button
          type="secondary"
          size="small"
          onClick={setCreateQuestion}
          autoWidth
        >
          <Typography
            variant="body2"
            color="primary"
            className={classes.addQuesitonButtonText}
          >
            <Add className={classes.addIcon} />
            <FormattedMessage {...addQuestionText} />
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <ul className={classes.questionsContainer}>
          {activeDataEntries.map(dataField => {
            return (
              <li key={dataField.key}>
                <QuestionnaireDisplay dataField={dataField} />
              </li>
            );
          })}
        </ul>
      </Grid>
    </Grid>
  );
};

export const FormBuilder = () => (
  <QuestionDrawerProvider>
    <InnerFormBuilder />
  </QuestionDrawerProvider>
);
