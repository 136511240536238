/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PartnerOptionDataUpdateRequest } from '../models/PartnerOptionDataUpdateRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PartnerOptionDatumService {

    /**
     * Update Partner Option Data
     * Updates a single PartnerOptionData for an applicant and stage
     * @param applicantId Applicant external ID
     * @param partnerOptionDataId Stage external ID
     * @param requestBody
     * @returns any successful
     * @throws ApiError
     */
    public static putInternalApiApplicantsPartnerOptionData(
        applicantId: string,
        partnerOptionDataId: string,
        requestBody: {
            /**
             * The key value pairs to update
             */
            fields?: PartnerOptionDataUpdateRequest;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/internal_api/applicants/{applicant_id}/partner_option_data/{partner_option_data_id}',
            path: {
                'applicant_id': applicantId,
                'partner_option_data_id': partnerOptionDataId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                302: `Redirect`,
            },
        });
    }

    /**
     * Delete Partner Option Data entry
     * Deletes a single PartnerOptionData key-value pair for an applicant and stage
     * @param applicantId Applicant external ID
     * @param partnerOptionDataId Stage external ID
     * @param requestBody
     * @returns any successful
     * @throws ApiError
     */
    public static deleteInternalApiApplicantsPartnerOptionData(
        applicantId: string,
        partnerOptionDataId: string,
        requestBody: {
            /**
             * The key value pairs to delete
             */
            fields?: PartnerOptionDataUpdateRequest;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/internal_api/applicants/{applicant_id}/partner_option_data/{partner_option_data_id}',
            path: {
                'applicant_id': applicantId,
                'partner_option_data_id': partnerOptionDataId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                302: `Redirect`,
            },
        });
    }

}