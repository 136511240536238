import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import { setApplicantNotesAction } from 'containers/ApplicantTableV2/actions';
import { addDefaultErrorMessageAction } from 'containers/FlashMessage/actions';
import { apiGet } from 'utils/axios';

export default function useApplicantNotes(applicantId) {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/notes`;
      const res = await apiGet(url);
      if (res.data) {
        dispatch(setApplicantNotesAction(applicantId, res.data.notes));
        setError(null);
      }
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (applicantId) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicantId]);

  useEffect(() => {
    if (error) {
      dispatch(addDefaultErrorMessageAction());
    }
  }, [dispatch, error]);

  return { isLoading };
}
