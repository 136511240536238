import { ValueTypeEnum } from './constants';

/* eslint-disable valid-typeof */
export const getType = value => {
  if (typeof value === ValueTypeEnum.BOOLEAN) {
    return ValueTypeEnum.BOOLEAN;
  }

  if (typeof value === ValueTypeEnum.STRING) {
    return ValueTypeEnum.STRING;
  }

  if (Array.isArray(value)) {
    return ValueTypeEnum.ARRAY;
  }

  if (typeof value === ValueTypeEnum.OBJECT && !value) {
    return ValueTypeEnum.NULL;
  }

  if (typeof value === ValueTypeEnum.OBJECT) {
    return ValueTypeEnum.OBJECT;
  }

  if (typeof value === ValueTypeEnum.NUMBER) {
    return ValueTypeEnum.NUMBER;
  }

  return value;
};
/* eslint-enable valid-typeof */
