import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React, { FC } from 'react';

export const RuleStageIcon: FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.233 3.03333C11.139 2.95434 10.9997 3.02464 10.9997 3.15107V4.375C10.9997 4.44404 10.9438 4.49966 10.8748 4.50084C10.5345 4.50663 9.90192 4.55259 9.1378 4.91668C8.26931 5.33051 7.29286 6.12432 6.3647 7.60085C5.56418 8.87432 4.79064 9.45551 4.21629 9.72918C3.74336 9.95453 3.36362 9.99238 3.12822 9.99872C3.05934 10.0006 3.00356 10.0558 3.00337 10.1247L3 11.3747C2.99981 11.4438 3.05576 11.5003 3.12492 11.4992C3.46525 11.4933 4.09767 11.4473 4.86153 11.0833C5.73001 10.6695 6.70647 9.87568 7.63463 8.39915C8.43514 7.12568 9.20868 6.54449 9.78303 6.27082C10.256 6.04545 10.6358 6.00762 10.8712 6.00128C10.94 5.99942 10.9958 6.05573 10.996 6.12462L10.9997 7.34893C10.9997 7.47537 11.139 7.54566 11.233 7.46667L13.5919 5.4855C13.7356 5.36477 13.7356 5.13523 13.5919 5.0145L11.233 3.03333Z" />
    <path d="M4.86153 4.91668C5.17921 5.06806 5.51135 5.27028 5.85028 5.53816C5.90451 5.58102 5.9119 5.66 5.86858 5.71386C5.62854 6.01233 5.37976 6.35957 5.14926 6.7172C5.10783 6.78148 5.01913 6.79471 4.95966 6.74662C4.68884 6.52759 4.43776 6.37634 4.21629 6.27082C3.74336 6.04547 3.36362 6.00763 3.12822 6.00128C3.05934 5.99942 3.00356 5.94424 3.00337 5.87533L3 4.62534C2.99981 4.55617 3.05576 4.49966 3.12492 4.50084C3.46525 4.50666 4.09767 4.55271 4.86153 4.91668Z" />
    <path d="M9.1378 11.0833C8.82011 10.9319 8.48798 10.7297 8.14904 10.4618C8.09481 10.419 8.08743 10.34 8.13075 10.2861C8.37078 9.98767 8.61957 9.64043 8.85006 9.2828C8.8915 9.21852 8.9802 9.20529 9.03967 9.25338C9.31049 9.47241 9.56157 9.62366 9.78303 9.72919C10.256 9.95455 10.6358 9.99238 10.8712 9.99872C10.94 10.0006 10.9958 9.94427 10.996 9.87538L10.9997 8.65107C10.9997 8.52464 11.139 8.45434 11.233 8.53333L13.5919 10.5145C13.7356 10.6352 13.7356 10.8648 13.5919 10.9855L11.233 12.9667C11.139 13.0457 10.9997 12.9754 10.9997 12.8489V11.625C10.9997 11.556 10.9438 11.5003 10.8748 11.4992C10.5345 11.4934 9.90192 11.4474 9.1378 11.0833Z" />
  </SvgIcon>
);
