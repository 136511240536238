/* eslint-disable react/prop-types, react/jsx-handler-names */

import { Grid, TextField, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { AutoSizer, List } from 'react-virtualized';

import CheckmarkIcon from 'images/checkmark_blue.svg';

import styles from './styles';

class AccountsFilterMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredAccounts: [...props.accounts],
      selectedAccountId: props.selectedAccountId,
    };
  }

  handleTextOnChange = e => {
    this.setState({
      filteredAccounts: this.props.accounts.filter(
        item =>
          item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1,
      ),
    });
  };

  handleAccountChange = index => {
    const selectedAccountId = this.state.filteredAccounts[index].external_id;
    this.props.handleAccountChange(selectedAccountId);
  };

  isSelected = index =>
    this.state.filteredAccounts[index].external_id ===
    this.state.selectedAccountId;

  render() {
    const { classes } = this.props;
    const { filteredAccounts } = this.state;
    return (
      <div className={classes.root}>
        <TextField
          fullWidth
          onChange={this.handleTextOnChange}
          autoFocus
          className={classes.input}
          placeholder="Search Account..."
        />
        <AutoSizer>
          {({ width, height }) => (
            <List
              width={width}
              height={height}
              rowCount={filteredAccounts.length}
              rowHeight={50}
              rowRenderer={({ key, index, style }) => (
                <div key={key} style={style}>
                  <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    justify="center"
                    alignItems="center"
                    className={classes.menuItem}
                    onClick={() => {
                      this.handleAccountChange(index);
                    }}
                  >
                    {this.isSelected(index) ? (
                      <div className={classes.checkmarkIconWrapper}>
                        <img
                          src={CheckmarkIcon}
                          className={classes.checkmarkIcon}
                          alt="Check icon"
                        />
                      </div>
                    ) : null}
                    <Grid
                      container
                      direction="column"
                      className={
                        this.isSelected(index)
                          ? classes.selected
                          : classes.notSelected
                      }
                    >
                      <Typography variant="h4">
                        {filteredAccounts[index].name}
                      </Typography>
                      <Typography variant="body2" color="secondary">
                        {filteredAccounts[index].slug}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              )}
              style={{ outline: 'none' }}
            />
          )}
        </AutoSizer>
      </div>
    );
  }
}

AccountsFilterMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  accounts: PropTypes.array.isRequired,
  handleAccountChange: PropTypes.func.isRequired,
  selectedAccountId: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(AccountsFilterMenu);
