import axios from 'axios';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import {
  shouldFetchDisabledAction,
  shouldFetchEnabledAction,
} from 'containers/ApplicantUploadFileModal/actions';
import { addMessageAction } from 'containers/FlashMessage/actions';
import globalMessages from 'shared/global/messages';
import { apiGet, apiPost } from 'utils/axios';

export default function useUploadFileToApplicant(options = {}) {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState('');

  // eslint-disable-next-line consistent-return
  const updateFileToApplicant = async ({ applicantId, key, file }) => {
    setIsUploading(true);
    const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/document_uploads`;
    try {
      const response = await apiGet(
        `${url}/presigned_blob?filename=${file.name}`,
      );
      const data = response.data.form_data;

      const fileUploadBody = new FormData();
      /* Figure out if we are using AzureBlob for file storage */
      let isUsingAzureBlob = false;
      if (response.data && response.data.storage_provider === 'azure') {
        isUsingAzureBlob = true;
      }
      if (isUsingAzureBlob) {
        await axios.put(response.data.url, file, {
          headers: {
            'x-ms-blob-type': 'Blockblob',
            'Content-Type': 'binary/octet-stream',
          },
        });
      } else {
        Object.keys(data).forEach(dataKey =>
          fileUploadBody.append(dataKey, data[dataKey]),
        );
        fileUploadBody.append('Content-Type', file.type);
        fileUploadBody.append('file', file);
        await axios.post(response.data.url, fileUploadBody, {
          headers: { 'Content-Type': '' },
        });
      }

      const finalBody = new FormData();
      finalBody.append(
        'document_upload[blob_meta]',
        JSON.stringify({
          blob_id: data.key,
          blob_size: file.size,
        }),
      );

      finalBody.append('document_upload[key]', key);
      const finalresponse = await apiPost(url, finalBody);
      if (!options.disableMessage) {
        dispatch(
          addMessageAction(
            formatMessage(globalMessages.updateFieldSuccess),
            'success',
          ),
        );
      }
      setError('');
      dispatch(shouldFetchEnabledAction());
      return finalresponse;
    } catch (e) {
      setError(e);
    } finally {
      dispatch(shouldFetchDisabledAction());
      setIsUploading(false);
    }
  };

  useEffect(() => {
    if (error) {
      dispatch(addMessageAction(error, 'error'));
    }
  }, [dispatch, error]);

  return { updateFileToApplicant, isUploading };
}
