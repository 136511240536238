/*
 *
 * ApplicantUploadFileModal constants
 *
 */

export const SET_FILE = 'app/ApplicantUploadFileModal/SET_FILE';
export const DELETE_FILE = 'app/ApplicantUploadFileModal/DELETE_FILE';
export const SUBMIT_UPLOAD = 'app/ApplicantUploadFileModal/SUBMIT_UPLOAD';
export const SUBMIT_UPLOAD_SUCCESS =
  'app/ApplicantUploadFileModal/SUBMIT_UPLOAD_SUCCESS';
export const SUBMIT_UPLOAD_ERROR =
  'app/ApplicantUploadFileModal/SUBMIT_UPLOAD_ERROR';
export const SHOULD_FETCH_ENABLED =
  'app/ApplicantUploadFileModal/SET_SHOULD_FETCH_ENABLED';
export const SHOULD_FETCH_DISABLED =
  'app/ApplicantUploadFileModal/SET_SHOULD_FETCH_DISABLED';
