import { ConfirmationModal } from '@fountain/fountain-ui-components';
import { IconButton, TableCell } from '@material-ui/core';
import {
  CancelablePromise,
  OpeningApprovalsService,
} from 'api-clients/monolith';
import { useSimpleToggle } from 'hooks';
import React, { useState, VFC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addMessageAction } from 'containers/FlashMessage/actions';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';
import EditIcon from 'images/edit-applicant.svg';
import TrashIcon from 'images/trashcan.svg';
import globalMessages from 'shared/global/messages';

import { ApproverGroupsModal } from './ApproverGroupsModal';
import messages from './messages';
import { useStyles } from './styles';

export interface ApprovedGroupRowsProps {
  manageOpeningApprovers: boolean;
  approverGroupId: string;
  refetchApproverGroupData: () => void;
}

export const RowActions: VFC<ApprovedGroupRowsProps> = ({
  manageOpeningApprovers,
  approverGroupId,
  refetchApproverGroupData,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState<boolean>(false);
  const [selectedApproverGroupId, setSelectedApproverGroupId] =
    useState<string>();

  const {
    showContent: showEditApproverGroupModal,
    on: setEditApproverGroupModalOpen,
    off: setEditApproverGroupModalClosed,
  } = useSimpleToggle();

  const { mutation: deleteApproverGroup } = useApiServiceMutation<
    void,
    (approverGroupId: string) => CancelablePromise<void>
  >(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    OpeningApprovalsService.deleteInternalApiOpeningApprovalApproverGroups,
    {
      onSuccess: () => {
        refetchApproverGroupData();
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.deleteSuccessMessage),
            'success',
          ),
        );
      },
      onError: () => {
        dispatch(addMessageAction('Error', 'error', false));
      },
    },
  );

  const handleEditApproverGroup = (approverGroupId: string) => {
    setSelectedApproverGroupId(approverGroupId);
    setEditApproverGroupModalOpen();
  };

  const handleDeleteApproverGroup = (approverGroupId: string) => {
    setSelectedApproverGroupId(approverGroupId);
    setShowDeleteConfirmationModal(true);
  };

  const handleDeleteConfirmationModalSubmit = () => {
    if (selectedApproverGroupId) deleteApproverGroup(selectedApproverGroupId);
    setShowDeleteConfirmationModal(false);
  };

  const handleCloseDeleteConfirmationModal = () => {
    setShowDeleteConfirmationModal(false);
  };

  return (
    <>
      {manageOpeningApprovers && (
        <>
          <TableCell className={styles.tableCell} style={{ width: '20px' }}>
            <IconButton
              onClick={() => handleEditApproverGroup(approverGroupId)}
            >
              <img src={EditIcon} alt="edit" role="presentation" />
            </IconButton>
          </TableCell>
          <TableCell className={styles.tableCell} style={{ width: '20px' }}>
            <IconButton
              onClick={() => handleDeleteApproverGroup(approverGroupId)}
            >
              <img src={TrashIcon} alt="trash" role="presentation" />
            </IconButton>
          </TableCell>
        </>
      )}

      {showEditApproverGroupModal && (
        <ApproverGroupsModal
          refreshApproversData={refetchApproverGroupData}
          selectedApproverGroupId={selectedApproverGroupId}
          isOpen={showEditApproverGroupModal}
          onClose={setEditApproverGroupModalClosed}
        />
      )}

      {showDeleteConfirmationModal && (
        <ConfirmationModal
          negative
          actionButtonText={intl.formatMessage(globalMessages.delete)}
          handleClose={handleCloseDeleteConfirmationModal}
          handleSubmit={handleDeleteConfirmationModalSubmit}
          modalContentText={intl.formatMessage(
            messages.deleteConfirmationModalContent,
          )}
          modalTitle={intl.formatMessage(messages.deleteConfirmationModalTitle)}
        />
      )}
    </>
  );
};
