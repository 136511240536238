import { createStyles, makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(theme =>
  createStyles({
    rosterContainer: {
      '& .MuiTableCell-root': {
        padding: theme.spacing(1),
      },
      '& $mobileContentColumn.MuiTableCell-root': {
        padding: theme.spacing(0, 1),
      },
      '& $rosterRow $mobileContentColumn.MuiTableCell-root': {
        padding: theme.spacing(0, 1, 1, 1),
      },
    },
    mobileContainer: {
      overflowX: 'hidden',
    },
    selectedCount: {
      margin: theme.spacing(1.5, 0, 0, 0),
    },
    tableHead: {
      color: theme.palette.common.gray800,
      padding: theme.spacing(0, 1),
      height: 40,
      backgroundColor: theme.palette.common.gray100,
      whiteSpace: 'nowrap',
    },
    bulkActions: {
      margin: theme.spacing(1, 0),
      minHeight: theme.spacing(5),
      justifyContent: 'space-between',
    },
    caretIcon: {
      margin: theme.spacing(0, 0, 0, 1),
      transform: 'rotate(90deg)',
    },
    caretIconCollapsed: {
      transform: 'rotate(270deg)',
    },
    customizeColumnsButton: {
      alignSelf: 'end',
    },
    customizeColumnsIcon: {
      fill: `${theme.palette.common.gray800} !important`,
    },
    skeletonCard: {
      width: '100%',
      height: '210px',
    },
    fullWidth: {
      width: 'auto',
    },

    // Row
    phone: {
      color: theme.palette.common.gray600,
      textDecoration: 'none',
      font: 'inherit',
      lineHeight: '0.5',
      whiteSpace: 'nowrap',
    },

    neverflow: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 'calc(100vw - 240px)',
    },
    nameCutoff: {
      maxWidth: 'calc(100vw - 192px)',
    },
    mediumColumnCutoff: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: '240px',
      textOverflow: 'ellipsis',
    },
    largeColumnCutoff: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: '400px',
      textOverflow: 'ellipsis',
    },
    megaColumnCutoff: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    autoMargin: {
      margin: 'auto',
      display: 'flex',
    },
    actionsColumn: {
      flexWrap: 'nowrap',
      display: 'inline-flex',
    },
    mobileContentColumn: {
      padding: theme.spacing(0, 1),
      display: 'flex',
      justifyContent: 'space-between',
    },
    attendButton: {
      maxWidth: theme.spacing(7),
    },
    disableButtonUnderline: {
      '& .MuiButton-label': {
        textDecoration: 'none',
      },
    },
    disableUnderline: {
      textDecoration: 'none',
    },
    rosterRow: {
      borderBottom: theme.customBorders.gray200,
    },
  }),
);
