import { Input, StyledReactSelect } from '@fountain/fountain-ui-components';
import { Box, Grid, Tooltip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {
  CancelablePromise,
  DistributeRule,
  RuleStageDistributionBasis,
  SidebarStage,
  WorkflowEditorService,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import produce from 'immer';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { classNames } from 'react-extras';
import { useIntl } from 'react-intl';

import JobSearch from 'containers/JobSearch';
import { Funnel } from 'containers/Messenger/types';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';
import TrashSvg from 'images/trashV2';

import messages from './messages';
import useStyles from './styles';

import type { SidebarStageResponse } from 'api-clients/monolith/models/SidebarStageResponse';

export interface Location {
  id: string;
  name: string;
}

interface DistributeRuleProps {
  locations: Location[];
  defaultLocation?: Location;
  index: number;
  distributionBasis?: RuleStageDistributionBasis;
  distributeRule: DistributeRule;
  allowDeletingRule: boolean;
  stageExternalId: string;
  isLastRule: boolean;
  jobExternalId?: string | null;
}

export const DistributeRuleRow: FC<DistributeRuleProps> = ({
  locations,
  defaultLocation,
  index,
  distributionBasis,
  distributeRule,
  allowDeletingRule,
  stageExternalId,
  isLastRule,
  jobExternalId,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const sortedLocations = useMemo(
    () => locations.sort((a, b) => a.name.localeCompare(b.name)),
    [locations],
  );

  const [selectedLocation, setSelectedLocation] = useState<
    Location | undefined
  >(defaultLocation);
  const [selectedOpening, setSelectedOpening] = useState<Funnel | undefined>();
  const [selectedStage, setSelectedStage] = useState<
    SidebarStage | undefined
  >();

  const { result: fetchFunnelStagesResult, mutation: fetchFunnelStages } =
    useApiServiceMutation<
      SidebarStageResponse,
      (funnelSlug: string) => CancelablePromise<SidebarStageResponse>
    >(
      // eslint-disable-next-line @typescript-eslint/unbound-method
      WorkflowEditorService.getInternalApiWorkflowEditorFunnelsStages,
    );
  let selectedFunnelStages: SidebarStage[] = [];
  let hiringGoal;
  if (selectedOpening && fetchFunnelStagesResult.status === 'ready') {
    selectedFunnelStages = fetchFunnelStagesResult.data.stages.filter(
      stage => stage.external_id !== stageExternalId,
    );
    hiringGoal = fetchFunnelStagesResult.data.hiring_goal;
  }
  // Add 'Start of Workflow' option for funnels that are not the current one
  if (
    selectedFunnelStages?.length > 0 &&
    distributeRule.to_funnel_id !== jobExternalId
  ) {
    selectedFunnelStages.unshift({
      external_id: 'start_of_workflow',
      title: intl.formatMessage(messages.startOfWorkflow),
      slug: '',
      short_type: 'Custom',
      type: 'CustomStage',
      applicants_count: null,
      position: -1,
      extra: null,
      additional_info: null,
      move_rule: null,
    });
  }

  const { setStage, updateStageResult } = useContext(StageContext);

  const handleDeleteDistributeRule = () => {
    if (!allowDeletingRule) {
      return;
    }
    setStage(
      produce((draftStage: WorkflowStageDetail) => {
        if (
          draftStage.type === 'DistributeApplicantsRuleStage' &&
          draftStage.additional_info.distribute_rules
        ) {
          const ruleToDelete = draftStage.additional_info.distribute_rules.find(
            ({ id }) => id === distributeRule.id,
          );
          if (!ruleToDelete) {
            return;
          }
          // Client-only construct: can delete the object from the array
          if (typeof ruleToDelete.id === 'string') {
            draftStage.additional_info.distribute_rules =
              draftStage.additional_info.distribute_rules.filter(
                rule => rule.id !== ruleToDelete.id,
              );
          } else {
            // DB-persisted; add _destroy to delete via the API
            // eslint-disable-next-line no-underscore-dangle
            ruleToDelete._destroy = true;
          }
        }
      }),
    );
  };

  const handleChangeLocation = (location: Location) => {
    setSelectedLocation(location);
    setStage(
      produce((draftStage: WorkflowStageDetail) => {
        if (
          draftStage.type === 'DistributeApplicantsRuleStage' &&
          draftStage.additional_info.distribute_rules
        ) {
          const draftDistributeRules =
            draftStage.additional_info.distribute_rules;

          const idx = draftDistributeRules.findIndex(
            dr => dr.id === distributeRule.id,
          );

          draftDistributeRules[idx].to_location_id = location.id;
          draftDistributeRules[idx].to_funnel_id = null;
          draftDistributeRules[idx].to_stage_id = null;
          draftDistributeRules[idx].extra = null;
        }
      }),
    );

    setSelectedOpening(undefined);
    setSelectedStage(undefined);
  };

  const handleChangeOpening = (_location: unknown, funnel: Funnel) => {
    setSelectedOpening(funnel);
    setStage(
      produce((draftStage: WorkflowStageDetail) => {
        if (
          draftStage.type === 'DistributeApplicantsRuleStage' &&
          draftStage.additional_info.distribute_rules
        ) {
          const draftDistributeRules =
            draftStage.additional_info.distribute_rules;

          const idx = draftDistributeRules.findIndex(
            dr => dr.id === distributeRule.id,
          );

          draftDistributeRules[idx].to_funnel_id = funnel.job_id;
          draftDistributeRules[idx].to_stage_id = null;
          draftDistributeRules[idx].extra = null;
        }
      }),
    );

    setSelectedStage(undefined);
  };

  const handleChangeStage = (stage: SidebarStage) => {
    setSelectedStage(stage);
    setStage(
      produce((draftStage: WorkflowStageDetail) => {
        if (
          draftStage.type === 'DistributeApplicantsRuleStage' &&
          draftStage.additional_info.distribute_rules
        ) {
          const draftDistributeRules =
            draftStage.additional_info.distribute_rules;

          const idx = draftDistributeRules.findIndex(
            dr => dr.id === distributeRule.id,
          );

          draftDistributeRules[idx].to_stage_id = stage.external_id;
          draftDistributeRules[idx].extra = null;
        }
      }),
    );
  };

  const handleChangePercentage = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { target } = event;
    const intValue = parseInt(target.value, 10);

    if (intValue > 100) {
      return;
    }

    setStage(
      produce((draftStage: WorkflowStageDetail) => {
        if (
          draftStage.type === 'DistributeApplicantsRuleStage' &&
          draftStage.additional_info.distribute_rules
        ) {
          const draftDistributeRules =
            draftStage.additional_info.distribute_rules;

          const idx = draftDistributeRules.findIndex(
            dr => dr.id === distributeRule.id,
          );

          draftDistributeRules[idx].percentage = intValue;
        }
      }),
    );
  };

  const onKeyDownPercentage = (event: React.KeyboardEvent) =>
    event.key === '.' && event.preventDefault();

  useEffect(() => {
    if (selectedOpening?.slug) {
      fetchFunnelStages(selectedOpening?.slug ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOpening?.slug]);

  useEffect(() => {
    if (
      selectedFunnelStages?.[0] &&
      !distributeRule.extra?.stage_title &&
      !selectedStage
    ) {
      handleChangeStage(selectedFunnelStages[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFunnelStages]);

  useEffect(() => {
    // JobSearch component doesn't populate default values unless we trigger an update which causes a load of options
    if (distributeRule.to_location_id && distributeRule.extra?.location_title) {
      setSelectedLocation(defaultLocation);
    }

    if (distributeRule.to_funnel_id && distributeRule.extra?.funnel_title) {
      setSelectedOpening({
        job_id: distributeRule.to_funnel_id,
        title: distributeRule.extra.funnel_title,
        slug: distributeRule.extra.funnel_slug,
      } as Funnel);
    } else if (!distributeRule.to_funnel_id && selectedOpening) {
      // Handling Edge Case when only DEFAULT_DISTRIBUTE_RULE exists and changes are discarded
      setSelectedOpening(undefined);
    }

    // Stages are loaded after an update to selectedOpening so we set that default here
    if (distributeRule.to_stage_id && distributeRule.extra?.stage_title) {
      setSelectedStage({
        external_id: distributeRule.to_stage_id,
        title: distributeRule.extra.stage_title,
      } as SidebarStage);
    } else if (!distributeRule.to_stage_id && selectedStage) {
      // Handling Edge Case when only DEFAULT_DISTRIBUTE_RULE exists and changes are discarded
      setSelectedStage(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributeRule.extra, defaultLocation]);

  const isOpeningsDropdownDisabled = !selectedLocation;
  const isStagesDropdownDisabled = !selectedOpening;
  const isPercentageDisabled = !selectedStage;

  const errors =
    (updateStageResult.isError && updateStageResult.error?.errors) || undefined;
  const locationError =
    errors && errors['distribute_rules.to_location_id']?.[0];
  const openingError = errors && errors['distribute_rules.to_funnel_id']?.[0];
  const percentageError = errors && errors['distribute_rules.percentage']?.[0];
  const isPercentageBasedRule = distributionBasis === 'percentage';

  return (
    <Grid
      container
      spacing={2}
      alignContent="flex-end"
      className={classNames(classes.distributeRule, {
        allowDeletingRule,
        hasErrors: Boolean(locationError ?? openingError),
      })}
      data-testid="distribute-rule-row"
    >
      <Grid item xs={3}>
        <StyledReactSelect
          key={distributeRule.id}
          label={index === 0 ? intl.formatMessage(messages.location) : null}
          getOptionLabel={(option: Location) => option.name}
          getOptionValue={(option: Location) => option.id}
          onChange={handleChangeLocation}
          options={sortedLocations}
          placeholder={`${intl.formatMessage(
            messages.search,
          )} ${intl.formatMessage(messages.locations)}`}
          value={selectedLocation}
          error={
            !selectedLocation &&
            locationError &&
            intl.formatMessage(messages.requiredFieldMessage)
          }
        />
      </Grid>
      <Grid item xs={4}>
        <JobSearch
          locationId={selectedLocation && selectedLocation.id}
          disabled={isOpeningsDropdownDisabled}
          label={intl.formatMessage(messages.opening)}
          placeholder={`${intl.formatMessage(
            messages.search,
          )} ${intl.formatMessage(messages.openings)}`}
          noLabel={index > 0}
          onSelected={handleChangeOpening}
          variant="select"
          clearInputOnClick={false}
          value={selectedOpening && selectedOpening.title}
          error={
            !selectedOpening && openingError
              ? intl.formatMessage(messages.requiredFieldMessage)
              : undefined
          }
          isUrl={false}
        />
      </Grid>

      <Grid item xs={3}>
        <Tooltip
          arrow
          title={
            isStagesDropdownDisabled ? (
              <Typography className={classes.smallTitle} key="stageTooltip">
                {intl.formatMessage(messages.disabledFields)}
              </Typography>
            ) : (
              ''
            )
          }
        >
          <div>
            <StyledReactSelect
              key={distributeRule.id}
              isDisabled={isStagesDropdownDisabled}
              label={index === 0 ? intl.formatMessage(messages.stage) : null}
              getOptionLabel={(option: SidebarStage) => option.title}
              getOptionValue={(option: SidebarStage) => option.external_id}
              isLoading={fetchFunnelStagesResult.isLoading}
              onChange={handleChangeStage}
              options={selectedFunnelStages ?? []}
              placeholder={`${intl.formatMessage(
                messages.search,
              )} ${intl.formatMessage(messages.stages)}`}
              value={
                selectedFunnelStages?.find(
                  stage => stage.external_id === selectedStage?.external_id,
                ) ?? null
              }
              className={classNames(classes.dropdown, {
                disabled: isStagesDropdownDisabled,
              })}
              // selectedStage on the BE can be nil,
              //   so we couple this error with the opening select since we force a selection when an opening is selected
              error={
                !selectedStage &&
                openingError &&
                intl.formatMessage(messages.requiredFieldMessage)
              }
            />
          </div>
        </Tooltip>
      </Grid>
      <Grid item xs={1}>
        <div className={classNames(classes.hiringGoalsContainer)}>
          {isPercentageBasedRule ? (
            <Tooltip
              arrow
              title={
                isPercentageDisabled ? (
                  <Typography
                    className={classes.smallTitle}
                    key="percentageTooltip"
                  >
                    {intl.formatMessage(messages.disabledFields)}
                  </Typography>
                ) : (
                  ''
                )
              }
            >
              <div>
                <Input
                  label={
                    index === 0
                      ? intl.formatMessage(messages.targetDistribution)
                      : ''
                  }
                  placeholder="e.g. 100"
                  disabled={isPercentageDisabled}
                  type="number"
                  className={classNames(classes.percentageField, {
                    disabled: isPercentageDisabled,
                  })}
                  onChange={handleChangePercentage}
                  onKeyDown={onKeyDownPercentage}
                  value={distributeRule.percentage}
                  hideErrorMessage
                  error={Boolean(percentageError)}
                  endAdornment={
                    <Typography variant="body2" color="textSecondary">
                      %
                    </Typography>
                  }
                />
                {isLastRule && Boolean(percentageError) && (
                  <Typography
                    variant="caption"
                    align="right"
                    color="error"
                    className={classes.percentageErrorMsg}
                  >
                    {!distributeRule.percentage
                      ? intl.formatMessage(messages.requiredFieldMessage)
                      : percentageError}
                  </Typography>
                )}
              </div>
            </Tooltip>
          ) : (
            <>
              {index === 0 && (
                <Box mb={0.5}>
                  <Typography variant="body2" color="textSecondary">
                    {intl.formatMessage(messages.hiringGoals)}
                  </Typography>
                </Box>
              )}
              <Box px={2} py={1.5}>
                <Typography variant="body2" color="textPrimary">
                  {distributeRule.goal ?? hiringGoal ?? 'N/A'}
                </Typography>
              </Box>
            </>
          )}
          {allowDeletingRule && (
            <div
              className={classNames(classes.deleteRuleContainer, {
                isFirstRule: index === 0,
              })}
            >
              <button
                type="button"
                className={classNames(classes.deleteRule)}
                onClick={handleDeleteDistributeRule}
                aria-label={intl.formatMessage(messages.deleteDistRuleNumber, {
                  number: index + 1,
                })}
              >
                <TrashSvg />
              </button>
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
