import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import { schedulingResendSchedulerEmailAction } from 'containers/ApplicantActions/actions';
import { messages } from 'containers/ApplicantActions/messages';
import { addMessageAction } from 'containers/FlashMessage/actions';
import { openSendEmailPopup } from 'containers/GlobalPopup/actions';
import { apiDelete, apiPatch, apiPost, apiPut } from 'utils/axios';

export default function useHandleHttpAction() {
  const dispatch = useDispatch();

  const { formatMessage } = useIntl();

  const [error, setError] = useState(null);
  const [isMakingHttpRequest, setIsMakingHttpRequest] = useState(false);

  const handleHttpAction = (
    status,
    action,
    fetchApplicantDetail,
    callback = () => {},
    // eslint-disable-next-line consistent-return
  ) => {
    setIsMakingHttpRequest(true);
    try {
      if (action.http_verb === 'PUT') {
        if (
          action.url ===
          `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${status.external_id}/resend_scheduler_email`
        ) {
          return dispatch(
            schedulingResendSchedulerEmailAction(status.external_id, {
              success: formatMessage(
                messages.schedulingResendSchedulerEmailSuccess,
              ),
              error: formatMessage(
                messages.schedulingResendSchedulerEmailError,
              ),
            }),
          );
        }
        return apiPut(action.url, { scheduler_stage_id: status.stage_id }).then(
          setIsMakingHttpRequest(false),
        );
      }

      if (action.http_verb === 'POST') {
        /* eslint-disable-next-line consistent-return */
        return apiPost(action.url, action.data).then(res => {
          setIsMakingHttpRequest(false);

          if (res.data && res.data.message) {
            fetchApplicantDetail();
            if (action.action_key === 'messaging.email') {
              dispatch(openSendEmailPopup(status.external_id));
            }
            return dispatch(addMessageAction(res.data.message, 'success'));
          }
        });
      }

      if (action.http_verb === 'PATCH') {
        return apiPatch(action.url, action.params || {})
          .then(res => {
            setIsMakingHttpRequest(false);

            if (res.data && res.data.message) {
              fetchApplicantDetail();
              dispatch(addMessageAction(res.data.message, 'success'));
              if (action.redirect_url) {
                window.open(action.redirect_url, '_top');
              }
            }
          })
          .catch(e => {
            dispatch(addMessageAction(e, 'error'));
          });
      }

      if (action.http_verb === 'DELETE') {
        return apiDelete(action.url, action.params || {})
          .then(res => {
            setIsMakingHttpRequest(false);

            if (res.status === 204) {
              fetchApplicantDetail();
              if (res.data && res.data.message) {
                dispatch(addMessageAction(res.data.message, 'success'));
              }
              if (action.redirect_url) {
                window.open(action.redirect_url, '_top');
              }
            }
          })
          .catch(e => {
            dispatch(addMessageAction(e, 'error'));
          });
      }
    } catch (e) {
      setError(e);
      dispatch(addMessageAction(e, 'error'));
    } finally {
      setIsMakingHttpRequest(false);
      if (callback) {
        callback();
      }
    }
  };

  return { error, isMakingHttpRequest, handleHttpAction };
}
