import { Button } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import EditingField from 'components/ApplicantDrawerDetailsList/components/EditingField';
import {
  onAddressChange,
  onAvailabilityChange,
  onCheckboxChange,
  onDateChange,
  onDropdownChange,
  onValueChange,
} from 'containers/DataValidationModal/components/helpers';
import messages from 'shared/global/messages';

import { DetailType, DetailValueType, IdType } from '../../props';
import useStyles from './styles';

const EditingFieldContainer = ({
  applicantId,
  detail,
  onCancel,
  onConfirm,
  onSetValue,
  value,
}) => {
  const styles = useStyles();
  const { formatMessage } = useIntl();

  const cancel = formatMessage(messages.cancel);
  const save = formatMessage(messages.save);

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onConfirm();
    }
  };

  return (
    <Grid
      container
      direction="column"
      justify="space-between"
      spacing={2}
      wrap="nowrap"
    >
      <Grid className={styles.input} item>
        <EditingField
          applicantId={applicantId}
          value={value}
          onDropdownChange={onDropdownChange}
          onValueChange={onValueChange}
          onCheckboxChange={onCheckboxChange}
          onAvailabilityChange={onAvailabilityChange}
          onDateChange={onDateChange}
          onAddressChange={onAddressChange}
          detail={detail}
          handleKeyPress={handleKeyPress}
          setValue={onSetValue}
        />
      </Grid>
      <Grid container item justify="flex-end" spacing={1}>
        <Grid item>
          <Button
            className={styles.editingFieldButton}
            onClick={onCancel}
            size="small"
            type="secondary"
          >
            {cancel}
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={styles.editingFieldButton}
            onClick={onConfirm}
            size="small"
          >
            {save}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

EditingFieldContainer.propTypes = {
  applicantId: IdType.isRequired,
  detail: DetailType.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onSetValue: PropTypes.func.isRequired,
  value: DetailValueType,
};

export default EditingFieldContainer;
