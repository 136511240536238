import { WorkflowEditorService } from 'api-clients/monolith';
import { useApiService } from 'hooks';
import React from 'react';

export function useStages({ funnelSlug }: { funnelSlug: string }) {
  const fetchStages = React.useCallback(
    () =>
      WorkflowEditorService.getInternalApiWorkflowEditorFunnelsStages(
        funnelSlug,
      ),
    [funnelSlug],
  );

  return useApiService(fetchStages);
}
