export const getChatWidgetBaseUrl = (hostname: string) => {
  if (hostname === 'localhost') {
    return 'http://localhost:5555';
  }
  return `https://widget.${hostname.slice(4)}`;
};

export const getEmbedCodeSnippet = (
  brandId: string,
  chatWidgetBaseUrl: string,
  isOpenChatWindow: boolean,
  isAllBrandsSelected = false,
  isFaqOnlyWidget = false,
) => {
  const embedCode = `
  <!-- Start of Fountain AI widget code -->
    <script type="text/javascript">
      window.fountainChatWidgetId = "${brandId}";
      window.fountainChatWidgetBaseUrl = "${chatWidgetBaseUrl}";
      window.isAllBrandsSelected = ${isAllBrandsSelected.toString()};
      if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        window.fountainAIWidgetOpen = ${isOpenChatWindow ? 'true' : 'false'}; }
      window.fountainAi = { isFaqOnlyWidget: ${
        isFaqOnlyWidget ? 'true' : 'false'
      } };
      (function(){
        var js=document.createElement('script'); js.type="text/javascript"; js.async=true;
        js.src="${chatWidgetBaseUrl}/ChatWidgetShim.js";
        var before=document.getElementsByTagName('script')[0];
        before.parentNode.insertBefore(js, before);
      }());
    </script>
  <!-- End of Fountain AI widget code -->
  `;

  return embedCode;
};

const setSearchParams = (
  url: string,
  isAllBrandsSelected: boolean,
  isFaqOnlyWidget: boolean,
) => {
  const widgetUrl = new URL(url);

  if (isAllBrandsSelected)
    widgetUrl.searchParams.set(
      'isAllBrandsSelected',
      isAllBrandsSelected.toString(),
    );

  if (isFaqOnlyWidget)
    widgetUrl.searchParams.set('isFaqOnlyWidget', isFaqOnlyWidget.toString());

  return widgetUrl.href;
};

export const getWidgetUrl = (
  brandId: string,
  chatWidgetBaseUrl: string,
  isAllBrandsSelected = false,
  isFaqOnlyWidget = false,
) => {
  const widgetUrl = `${chatWidgetBaseUrl}/chatbot_widget/${brandId}`;
  const previewUrl = `${widgetUrl}/preview`;

  return {
    widgetUrl: setSearchParams(widgetUrl, isAllBrandsSelected, isFaqOnlyWidget),
    previewUrl: setSearchParams(
      previewUrl,
      isAllBrandsSelected,
      isFaqOnlyWidget,
    ),
  };
};
