/*
 * BulkActionsQueue Messages
 *
 * This contains all the text for the BulkActionsQueue component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.BulkActionsQueue';

export default defineMessages({
  bulkJobs: {
    id: `${scope}.bulkJobs`,
    defaultMessage: 'Bulk Jobs',
  },
});
