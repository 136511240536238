import { WorkflowStageDetail } from 'api-clients/monolith';
import produce from 'immer';
import React, { FC } from 'react';

import { SettingSwitch } from '../../../SettingSwitch';

type AutoAdvanceOnCompletionProps = {
  stage: WorkflowStageDetail;
  setStage: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
  label: React.ReactNode;
};

export const AutoAdvanceOnCompletion: FC<AutoAdvanceOnCompletionProps> = ({
  stage,
  setStage,
  label,
}) => {
  const onToggleAutoAdvanceOnCompletion = () => {
    setStage(
      produce(stage, draftStage => {
        draftStage.extra.auto_advance_on_completion =
          !draftStage.extra.auto_advance_on_completion;
      }),
    );
  };

  return (
    <SettingSwitch
      dataKey="auto_advance_on_completion"
      checked={Boolean(stage.extra.auto_advance_on_completion)}
      onChange={onToggleAutoAdvanceOnCompletion}
      label={label}
    />
  );
};
