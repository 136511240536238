import { Grid, Link } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import {
  makeSelectEnforceBrandBasedPermissions,
  makeSelectPolicies,
} from 'containers/Auth_old/selectors';
import { addMessageAction } from 'containers/FlashMessage/actions';
import LeftArrow from 'images/arrow-left.svg';
import globalMessages from 'shared/global/messages';

import messages from './messages';
import { useStyles } from './styles';

export const BackToCompanySettings = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();

  const { accountSlug } = useParams<{ accountSlug: string }>();

  const policies = useSelector(makeSelectPolicies());
  const { manage_account: manageAccount } = policies;

  const redirectUnauthorizedUser = useCallback(() => {
    history.push(`/${accountSlug}/openings`);
    dispatch(addMessageAction(intl.formatMessage(messages.noAccess), 'error'));
  }, [accountSlug, dispatch, history, intl]);

  useEffect(() => {
    if (!manageAccount) {
      redirectUnauthorizedUser();
    }
  }, [manageAccount, redirectUnauthorizedUser]);

  const enforceBrandBasedPermissions = useSelector(
    makeSelectEnforceBrandBasedPermissions(),
  );

  return (
    <Grid item className={styles.backToSettings}>
      <Link
        color="secondary"
        href={
          !manageAccount && enforceBrandBasedPermissions
            ? `${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/brands`
            : `${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/account/edit`
        }
      >
        <img src={LeftArrow} alt="back arrow" className={styles.leftArrow} />
        <FormattedMessage {...globalMessages.back} />
      </Link>
    </Grid>
  );
};
