/*
 *
 * Account reducer
 *
 */

import produce from 'immer';

import {
  CLOSE_MOBILE_DRAWER,
  RESET_ACCOUNT_STATUS,
  TOGGLE_MOBILE_DRAWER,
  UPDATE_ACCOUNT_FAILED,
  UPDATE_ACCOUNT_INIT,
  UPDATE_ACCOUNT_SUCCESS,
} from './constants';

export const initialState = {
  saved: false,
  error: false,
  loading: false,
  mobileDrawerOpen: false,
};

const accountReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case UPDATE_ACCOUNT_INIT:
        draft.loading = true;
        break;
      case UPDATE_ACCOUNT_SUCCESS:
        draft.loading = false;
        draft.saved = true;
        break;
      case UPDATE_ACCOUNT_FAILED:
        draft.loading = false;
        draft.error = true;
        break;
      case RESET_ACCOUNT_STATUS:
        draft.saved = false;
        draft.error = false;
        break;
      case TOGGLE_MOBILE_DRAWER:
        draft.mobileDrawerOpen = !draft.mobileDrawerOpen;
        break;
      case CLOSE_MOBILE_DRAWER:
        draft.mobileDrawerOpen = false;
        break;
      // no default
    }
  });

export default accountReducer;
