import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useSimpleToggle } from 'hooks';
import React, { FC, useEffect, useState } from 'react';

import { FilterDropdownButton } from 'components/FilterDropdown/Button';
import { FilterOpen } from 'components/FilterDropdown/FilterOpen';
import { FilterDropdownProps } from 'components/FilterDropdown/index';
import { Filterable, mapFilterable } from 'components/FilterDropdown/util';
import { useDebounce } from 'hooks/useDebounce';
import { useStageTitles } from 'hooks/useStageTitles';

export const FilterStageDropdown: FC<FilterDropdownProps<Filterable>> = ({
  selected,
  setSelected,
  showTitle,
  enterprise,
  error,
}) => {
  const { off, showContent: open, toggle: openUp } = useSimpleToggle();
  const [query, setQuery] = useState('');
  const [titles, setTitles] = useState<Filterable[]>([]);
  const debounceSetQuery = useDebounce(setQuery, 500);
  const { result } = useStageTitles({ query, enterprise });
  const name = 'stage';

  useEffect(() => {
    if (result.status === 'ready') {
      setTitles(mapFilterable(result.data.titles));
    }
  }, [result, setTitles]);

  return (
    <ClickAwayListener onClickAway={off}>
      <div>
        <FilterDropdownButton
          name={name}
          open={open}
          openUp={openUp}
          count={selected.length}
          clearFilter={() => setSelected([])}
          titles={selected.map(filterable => filterable.title)}
          showTitle={showTitle}
          setSelected={setSelected}
          selected={selected}
          error={error}
        />
        {open && (
          <FilterOpen
            name={name}
            selected={selected}
            setSelected={setSelected}
            options={titles}
            setQuery={debounceSetQuery}
          />
        )}
      </div>
    </ClickAwayListener>
  );
};
