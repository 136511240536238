import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
import { classNames } from 'react-extras';
import { generatePath, Link, useLocation, useParams } from 'react-router-dom';

import type { MessengerPathParams } from 'containers/Account/components';
import { MESSENGER_LOCAL_STORAGE_KEY } from 'containers/Messenger/constants';
import ChatSvg from 'images/ChatSvg';
import { getLocalStorageItem } from 'utils/storageUtils';

import useStyles from './styles';

export interface MessageIconProps {
  messageClass: string;
  children: React.Component;
}

const MessageIcon: FC<MessageIconProps> = ({ messageClass, children }) => {
  const { accountSlug } = useParams<MessengerPathParams>();
  const classes = useStyles();
  const { pathname, search } = useLocation();

  return (
    <Grid
      item
      container
      component={Link}
      to={
        /\/messenger\//g.test(pathname)
          ? `${pathname}${search}`
          : getLocalStorageItem(MESSENGER_LOCAL_STORAGE_KEY) ??
            generatePath('/:accountSlug/messenger', { accountSlug })
      }
      direction="row"
      justify="space-between"
      className={classNames(classes.messageLockup, messageClass)}
    >
      <Grid item className={classes.messageIcon}>
        <ChatSvg />
      </Grid>
      {children}
    </Grid>
  );
};

export default MessageIcon;
