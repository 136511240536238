import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  questionInputContainer: {
    maxWidth: '412px',
  },
  questionInput: {
    maxWidth: '412px',
    minHeight: '72px',
    marginBottom: theme.spacing(2),
  },
  lengthInput: {
    width: '140px',
  },
  questionContainer: {
    marginBottom: theme.spacing(4),
  },
  questionHeader: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  questionHeaderWithDeleteButton: {
    marginBottom: theme.spacing(1.05),
  },
  deleteButton: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    marginLeft: theme.spacing(1),
  },
  trashcanIcon: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    '& path': {
      fill: theme.palette.common.gray600,
    },
  },
  questionError: {
    marginTop: '0',
  },
}));
