import { Reason, WorkflowRejectedStage } from 'api-clients/monolith';
import React, { VFC } from 'react';

import { IdleMoveRule } from 'containers/WorkflowEditor/components/IdleMoveRule';
import { StageSection } from 'containers/WorkflowEditor/components/StageSection';
import { StageSettingMessageCard } from 'containers/WorkflowEditor/components/StageSettingMessageCard';
import { defaultReason } from 'containers/WorkflowEditor/components/StageSettingMessageCard/components/MessageRow/MessageRow.fixtures';
import { useRejectionReasons } from 'containers/WorkflowEditor/hooks/useRejectionReasons';

export interface RejectedStageProps {
  stage: WorkflowRejectedStage;
}

export const RejectedStage: VFC<RejectedStageProps> = ({ stage }) => {
  const { result: rejectedReasonResult } = useRejectionReasons();

  let reasons: Reason[] = [defaultReason];
  if (rejectedReasonResult.status === 'ready') {
    reasons = reasons.concat(rejectedReasonResult.data.rejection_reasons);
  }

  return (
    <div data-testid="rejectedStage">
      <StageSection>
        <IdleMoveRule stage={stage} />
      </StageSection>
      <StageSection>
        <StageSettingMessageCard
          messageType="rejection"
          stageExternalId={stage.external_id}
          reasons={reasons}
        />
      </StageSection>
      <StageSection>
        <StageSettingMessageCard
          messageType="trigger"
          stageExternalId={stage.external_id}
        />
      </StageSection>
    </div>
  );
};
