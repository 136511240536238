import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addCustomRule: {
    id: 'app.containers.WorkflowEditor.components.Rules.addCustomRule',
    defaultMessage: 'Add Custom Rule',
  },
  addRule: {
    id: `app.containers.WorkflowEditor.components.Rules.addRule`,
    defaultMessage: 'Add rule',
  },
  rule: {
    id: `app.containers.WorkflowEditor.components.Rules.rule`,
    defaultMessage: 'Rule',
  },
  value: {
    id: 'app.containers.WorkflowEditor.components.Rules.value',
    defaultMessage: 'Value',
  },
});
