import { Grid } from '@material-ui/core';
import {
  CancelablePromise,
  WebhookNotification,
  WebhooksService,
} from 'api-clients/monolith';
import React, { useEffect, useState, VFC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import {
  addDefaultErrorMessageActionI18n,
  addMessageAction,
} from 'containers/FlashMessage/actions';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';

import { useWebhookNotifications } from './api';
import { messages } from './messages';
import { Notifications, NotificationUpdate } from './Notifications';
import { useStyles } from './styles';

export const Webhooks: VFC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [notifications, setNotifications] = useState<WebhookNotification>();

  const { result } = useWebhookNotifications();
  const { mutation: setNotification } = useApiServiceMutation<
    NotificationUpdate,
    (requestBody: WebhookNotification) => CancelablePromise<NotificationUpdate>
    // eslint-disable-next-line @typescript-eslint/unbound-method
  >(WebhooksService.postInternalApiWebhooksNotifications, {
    onSuccess: notification => {
      dispatch(
        addMessageAction(intl.formatMessage(messages.updateSuccess), 'success'),
      );
      setNotifications(notification.notifications);
    },
    onError: () => {
      dispatch(addDefaultErrorMessageActionI18n(intl));
    },
  });

  useEffect(() => {
    if (result.status === 'ready' && result.data) {
      setNotifications(result.data.notifications);
    }
  }, [result]);

  return (
    <div className={styles.contentWrapper}>
      <Grid container className={styles.notificationContent}>
        {notifications && (
          <Notifications
            notifications={notifications}
            setNotification={setNotification}
          />
        )}
      </Grid>
    </div>
  );
};
