/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * Requests Users
     * Returns users within the account to assign users to
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiUsersPackUserOptions(): CancelablePromise<Array<{
        external_id: string;
        name: string;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/users_pack/user_options',
        });
    }

    /**
     * Requests User
     * Returns user information
     * @param id user id
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiUsersPack(
        id: number,
    ): CancelablePromise<{
        external_id: string;
        name: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/users_pack/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List All Accessible Opening IDs
     * @param userId The User ID
     * @param xAccessToken
     * @returns any OK
     * @throws ApiError
     */
    public static getInternalApiWxUsersAccessibleOpenings(
        userId: string,
        xAccessToken: string,
    ): CancelablePromise<{
        accessible_funnels: Array<string>;
        /**
         * Determines whether the user has access to all funnels on account. When true, `accessible_funnels` will be empty.
         */
        funnels_admin: boolean;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/wx/users/{user_id}/accessible_openings',
            path: {
                'user_id': userId,
            },
            headers: {
                'X-ACCESS-TOKEN': xAccessToken,
            },
        });
    }

}