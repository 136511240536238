import { defineMessages } from 'react-intl';

export const scope = 'app.containers.TemplateMenu';

const messages = defineMessages({
  dialogAction: {
    id: `${scope}.dialogAction`,
    defaultMessage: 'Manage Templates',
  },
  dialogTitle: {
    id: `${scope}.dialogTitle`,
    defaultMessage: 'Templates',
  },
  empty: {
    defaultMessage: 'No templates',
    id: `${scope}.empty`,
  },
  emptySearch: {
    defaultMessage: 'No match found for {searchString}',
    id: `${scope}.emptySearch`,
  },
  iconTooltip: {
    id: `${scope}.iconTooltip`,
    defaultMessage: 'Templates',
  },
  recentlyUsedTemplates: {
    id: `${scope}.recentlyUsedMessages`,
    defaultMessage: 'RECENTLY USED TEMPLATES',
  },
  searchPlaceholder: {
    id: `${scope}.searchPlaceholder`,
    defaultMessage: 'Search templates',
  },
  loading: {
    defaultMessage: 'Loading templates...',
    id: `${scope}.loading`,
  },
});

export default messages;
