import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  faqSettings: {
    id: 'app.containers.ChatbotWidget.faqSettings',
    defaultMessage: 'FAQ Chatbot Settings',
  },
  botNameTitle: {
    id: 'app.containers.ChatbotWidget.botNameTitle',
    defaultMessage: 'Customize chatbot name',
  },
  botNameDesc: {
    id: 'app.containers.ChatbotWidget.botNameDesc',
    defaultMessage:
      'Personalize and brand the applicant experience by customizing the chatbot name.',
  },
  botNameError: {
    id: 'app.containers.ChatbotWidget.botNameError',
    defaultMessage: 'Should be a valid name',
  },
  trainingSourceTitle: {
    id: 'app.containers.ChatbotWidget.trainingSourceTitle',
    defaultMessage: 'Chatbot training source',
  },
  trainingSourceDesc: {
    id: 'app.containers.ChatbotWidget.trainingSourceDesc',
    defaultMessage:
      'Enter URL to your product documentation or upload .CSV file to train the chatbot to automate answers to applicant’s most frequently asked questions.',
  },
  trainingSourceError: {
    id: 'app.containers.ChatbotWidget.trainingSourceError',
    defaultMessage: 'Please provide a valid url',
  },
  fallbackTitle: {
    id: 'app.containers.ChatbotWidget.fallbackTitle',
    defaultMessage: 'Fallback response',
  },
  fallbackPhoneNumber: {
    id: 'app.containers.ChatbotWidget.fallbackPhoneNumber',
    defaultMessage: 'Phone Number',
  },
  fallbackDesc: {
    id: 'app.containers.ChatbotWidget.fallbackDesc',
    defaultMessage:
      "Provide contact details for an applicant to reach out to for further assistance if the chatbot can't answer the applicant's question.",
  },
  email: {
    id: 'app.containers.ChatbotWidget.email',
    defaultMessage: 'Email',
  },
  emailError: {
    id: 'app.containers.ChatbotWidget.emailError',
    defaultMessage: 'Please provide a valid email',
  },
  phone: {
    id: 'app.containers.ChatbotWidget.phone',
    defaultMessage: 'Phone',
  },
  phoneError: {
    id: 'app.containers.ChatbotWidget.phoneError',
    defaultMessage: 'Please provide a valid phone number',
  },
  enableAxWidgetTitle: {
    id: 'app.containers.ChatbotWidget.enableAxWidgetTitle',
    defaultMessage: 'Enable FAQ chatbot in Applicant Portal',
  },
  enableAxWidgetDesc: {
    id: 'app.containers.ChatbotWidget.enableAxWidgetDesc',
    defaultMessage:
      'FAQ bot will be automatically embedded in the widget experience during the application process.',
  },
  enableFaqTitle: {
    id: 'app.containers.ChatbotWidget.enableFaqTitle',
    defaultMessage: 'Launch FAQ chatbot ',
  },
  enableFaqDesc: {
    id: 'app.containers.ChatbotWidget.enableFaqDesc',
    defaultMessage:
      'Ensure the FAQ chatbot settings including name, training source and fallback response have been correctly set up before launching FAQ chatbot.',
  },
  pendingStatus: {
    id: 'app.containers.ChatbotWidget.pendingStatus',
    defaultMessage: 'Training in Progress',
  },
  successStatus: {
    id: 'app.containers.ChatbotWidget.successStatus',
    defaultMessage: 'Ready to Launch',
  },
  errorStatus: {
    id: 'app.containers.ChatbotWidget.errorStatus',
    defaultMessage: 'Error',
  },
  fetchPendingStatus: {
    id: 'app.containers.ChatbotWidget.fetchPendingStatus',
    defaultMessage:
      'Training is still in progress. Please retry after sometime.',
  },
  fetchErrorStatus: {
    id: 'app.containers.ChatbotWidget.fetchErrorStatus',
    defaultMessage: 'Unable to retrieve status. Please try after sometime.',
  },
  tooltip: {
    id: 'app.containers.ChatbotWidget.tooltip',
    defaultMessage: 'Click to fetch status',
  },
  editFaqChatbotSettings: {
    id: 'app.containers.ChatbotWidget.editFaqChatbotSettings',
    defaultMessage: 'Edit',
  },
});
