import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { VFC } from 'react';

export const WidgetIconScreenshot: VFC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 80 80">
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_531_4616)">
        <rect x="10" y="10" width="60" height="60" rx="30" fill="white" />
        <rect
          x="10.5"
          y="10.5"
          width="59"
          height="59"
          rx="29.5"
          stroke="white"
        />
      </g>
      <path
        d="M52 38.5007C52 32.7013 46.627 28 40 28C33.373 28 28 32.7013 28 38.5007C28 44.3 33.373 49.0013 40 49.0013C40.8985 49.0013 41.7715 48.9083 42.6145 48.7433L47.8645 51.8935C47.983 51.964 48.1165 52 48.25 52C48.3775 52 48.505 51.9685 48.619 51.9025C48.8545 51.769 49 51.52 49 51.25V45.4281C50.8615 43.5785 52 41.1588 52 38.5007Z"
        fill="#5E44FF"
      />
      <defs>
        <filter
          id="filter0_d_531_4616"
          x="0"
          y="0"
          width="80"
          height="80"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_531_4616"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0752941 0 0 0 0 0.119216 0 0 0 0 0.25098 0 0 0 0.161058 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_531_4616"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_531_4616"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </SvgIcon>
);
