import { WorkflowI9FormStage } from 'api-clients/monolith';
import React, { VFC } from 'react';

import { AdvancedSettings } from 'containers/WorkflowEditor/components/AdvancedSettings';
import { IdleMoveRule } from 'containers/WorkflowEditor/components/IdleMoveRule';
import { StageSection } from 'containers/WorkflowEditor/components/StageSection';
import { StageSettingMessageCard } from 'containers/WorkflowEditor/components/StageSettingMessageCard';
import { EmployerInformation } from 'containers/WorkflowEditor/components/WorkflowEditorContent/StageDetail/I9FormStage/EmployerInformation';
import { I9Form } from 'containers/WorkflowEditor/components/WorkflowEditorContent/StageDetail/I9FormStage/I9Form';

export interface I9FormStageProps {
  stage: WorkflowI9FormStage;
}

export const I9FormStage: VFC<I9FormStageProps> = ({ stage }) => {
  const isSubstage = stage.parent_stage_id;

  return (
    <div data-testid="i9FormStage">
      <StageSection>
        <I9Form stage={stage} />
      </StageSection>
      <StageSection>
        <EmployerInformation stage={stage} />
      </StageSection>
      {!isSubstage && (
        <>
          <StageSection>
            <IdleMoveRule stage={stage} />
          </StageSection>
          <StageSection>
            <StageSettingMessageCard
              messageType="i9_form"
              stageExternalId={stage.external_id}
            />
          </StageSection>
          <StageSection>
            <StageSettingMessageCard
              messageType="trigger"
              stageExternalId={stage.external_id}
            />
          </StageSection>
        </>
      )}
      {stage.account_go_enabled && (
        <StageSection>
          <AdvancedSettings stage={stage} />
        </StageSection>
      )}
    </div>
  );
};
