import {
  ConfirmationModal,
  EditPencil,
  Trashcan,
} from '@fountain/fountain-ui-components';
import CustomTooltip from '@fountain/fountain-ui-components/dist/CustomTooltip';
import { IconButton, TableCell, TableRow, Typography } from '@material-ui/core';
import {
  AutomatedResponsesService,
  CancelablePromise,
} from 'api-clients/monolith';
import classnames from 'classnames';
import React, { useState, VFC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import {
  makeSelectAccountSlug,
  makeSelectFeatureChatbotAdminEnabled,
} from 'containers/Auth_old/selectors';
import { addMessageAction } from 'containers/FlashMessage/actions';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';
import globalMessages from 'shared/global/messages';

import { messages } from './messages';
import { useStyles } from './styles';

interface AutomatedResponseRowProps {
  id: number;
  question: string;
  intent: string;
  message: string;
  label: string;
  // eslint-disable-next-line camelcase
  message_template_id: number;
  handleEditAutomatedResponse: (id: number) => void;
  refetchAutomatedResponses: () => void;
}

export const AutomatedResponseRow: VFC<AutomatedResponseRowProps> = ({
  id,
  question,
  intent,
  message,
  label,
  message_template_id: messageTemplateId,
  handleEditAutomatedResponse,
  refetchAutomatedResponses,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const accountSlug = useSelector(makeSelectAccountSlug());
  const isFeatureChatbotAdminEnabled = useSelector(
    makeSelectFeatureChatbotAdminEnabled(),
  );

  const [showActions, setShowActions] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null);

  // Post enable FAQ bot
  const { mutation: deleteSelectedRow } = useApiServiceMutation<
    void,
    (id: number) => CancelablePromise<void>
  >(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    AutomatedResponsesService.deleteInternalApiChatbotAutomatedResponses,
    {
      onSuccess: () => {
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.deleteAutomatedResponseSuccess),
            'success',
          ),
        );
        refetchAutomatedResponses();
      },
      onError: () => {
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.deleteAutomatedResponseError),
            'error',
            false,
          ),
        );
      },
    },
  );

  const handleRowClick = (rowId: number) => {
    handleEditAutomatedResponse(rowId);
  };

  const handleRowDelete = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    rowId: number,
  ) => {
    event.stopPropagation();
    setSelectedRowId(rowId);
    setShowDeleteConfirmationModal(true);
  };

  const handleCloseDeleteConfirmationModal = () => {
    setShowDeleteConfirmationModal(false);
  };

  const handleDeleteConfirmationModalSubmit = () => {
    if (selectedRowId) deleteSelectedRow(selectedRowId);
    setShowDeleteConfirmationModal(false);
  };

  return (
    <>
      <TableRow
        className={styles.tableRow}
        onMouseEnter={() => setShowActions(true)}
        onMouseLeave={() => setShowActions(false)}
        onClick={() => handleRowClick(id)}
      >
        <TableCell className={styles.tableCell}>
          <Typography className={styles.cellContent}>{question}</Typography>
        </TableCell>
        {isFeatureChatbotAdminEnabled && (
          <TableCell className={styles.tableCell}>
            <Typography className={styles.cellContent}>{intent}</Typography>
          </TableCell>
        )}
        <TableCell className={styles.tableCell}>
          <Typography className={styles.cellContent}>{message}</Typography>
        </TableCell>
        {isFeatureChatbotAdminEnabled && (
          <>
            <TableCell className={styles.tableCell}>
              <Typography className={styles.cellContent}>{label}</Typography>
            </TableCell>
            <TableCell
              className={classnames(styles.tableCell, styles.actionCell)}
            >
              {/* Only super users have access to delete a automated response */}
              {showActions && (
                <IconButton onClick={event => handleRowDelete(event, id)}>
                  <Trashcan className={styles.actionIcon} />
                </IconButton>
              )}
            </TableCell>
          </>
        )}
        <TableCell className={classnames(styles.tableCell, styles.actionCell)}>
          {showActions && accountSlug && (
            <CustomTooltip
              title={intl.formatMessage(messages.editMessageTemplate)}
              dense
            >
              <IconButton
                href={`${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/message_templates/${messageTemplateId}/edit`}
                target="_blank"
                onClick={event => event.stopPropagation()}
              >
                <EditPencil className={styles.actionIcon} />
              </IconButton>
            </CustomTooltip>
          )}
        </TableCell>
      </TableRow>
      {showDeleteConfirmationModal && (
        <ConfirmationModal
          negative
          actionButtonText={intl.formatMessage(globalMessages.delete)}
          handleClose={handleCloseDeleteConfirmationModal}
          handleSubmit={handleDeleteConfirmationModalSubmit}
          modalContentText={intl.formatMessage(
            messages.deleteConfirmationModalContent,
          )}
          modalTitle={intl.formatMessage(messages.deleteConfirmationModalTitle)}
        />
      )}
    </>
  );
};
