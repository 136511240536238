import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  headerDescription: {
    id: 'app.containers.Chatbot.Upsell.headerDescription',
    defaultMessage:
      'Fountain enables a seamless applicant experience backed by automation and AI that is specific to a business’ hiring needs.',
  },
  conversationHeader: {
    id: 'app.containers.Chatbot.Upsell.conversationHeader',
    defaultMessage: 'Smarter conversations',
  },
  conversationDescription1: {
    id: 'app.containers.Chatbot.Upsell.conversationDescription1',
    defaultMessage: 'Embed a web widget on any career/hiring website',
  },
  conversationDescription2: {
    id: 'app.containers.Chatbot.Upsell.conversationDescription2',
    defaultMessage: 'Automate recruiter tasks to keep applicants engaged',
  },
  conversationDescription3: {
    id: 'app.containers.Chatbot.Upsell.conversationDescription3',
    defaultMessage: 'Qualify candidates 24/7',
  },
  customizeHeader: {
    id: 'app.containers.Chatbot.Upsell.customizeHeader',
    defaultMessage: 'Customize to your brand',
  },
  customizeDescription1: {
    id: 'app.containers.Chatbot.Upsell.customizeDescription1',
    defaultMessage: 'No code, easy installation',
  },
  customizeDescription2: {
    id: 'app.containers.Chatbot.Upsell.customizeDescription2',
    defaultMessage:
      'Applicants can resume application from anywhere, with a consistent brand experience',
  },
  humanHandoffHeader: {
    id: 'app.containers.Chatbot.Upsell.humanHandoffHeader',
    defaultMessage: 'Human Handoff',
  },
  humanHandoffDescription1: {
    id: 'app.containers.Chatbot.Upsell.humanHandoffDescription1',
    defaultMessage: 'Easy identification to speak with a recruiter',
  },
  humanHandoffDescription2: {
    id: 'app.containers.Chatbot.Upsell.humanHandoffDescription2',
    defaultMessage: 'Delight applicants with a seamless experience',
  },
  humanHandoffDescription3: {
    id: 'app.containers.Chatbot.Upsell.humanHandoffDescription3',
    defaultMessage: 'Qualify candidates 24/7',
  },
  collectDataHeader: {
    id: 'app.containers.Chatbot.Upsell.collectDataHeader',
    defaultMessage: 'Collect data with ease',
  },
  collectDataDescription1: {
    id: 'app.containers.Chatbot.Upsell.collectDataDescription1',
    defaultMessage: 'Embed a web widget on any career/hiring website',
  },
  collectDataDescription2: {
    id: 'app.containers.Chatbot.Upsell.collectDataDescription2',
    defaultMessage: 'Automate recruiter tasks to keep applicants engaged',
  },
  collectDataDescription3: {
    id: 'app.containers.Chatbot.Upsell.collectDataDescription3',
    defaultMessage: 'Qualify candidates 24/7',
  },
  contactSalesHeader: {
    id: 'app.containers.Chatbot.Upsell.contactSalesHeader',
    defaultMessage: 'Upgrade to enable Fountain AI across your hiring flows',
  },
  getAccessNow: {
    id: 'app.containers.Chatbot.Upsell.getAccessNow',
    defaultMessage: 'Get access now',
  },
  contactSalesSuccess: {
    id: 'app.containers.Chatbot.Upsell.contactSalesSuccess',
    defaultMessage:
      'Thank you for your interest in Fountain AI. We will get back to you soon!',
  },
  pressPlay: {
    id: 'app.containers.Chatbot.Upsell.pressPlay',
    defaultMessage: 'Press play to see how it works',
  },
});
