import Route from 'route-parser';

import {
  setSelectedBrandAction,
  setSelectedLocationAction,
} from 'containers/Auth_old/actions';
import { WHOAMI_SUCCESS } from 'containers/Auth_old/constants';
import { initialState } from 'containers/Auth_old/reducer';

/**
 * This middleware captures WHOAMI_SUCCESS action to update the
 * selected brand based on the url. This will ensure that the url and
 * the store stays in sync
 */

const urlToStoreMiddleware = store => next => action => {
  if (action.type === WHOAMI_SUCCESS) {
    // Let the original reducer do its thing
    next(action);

    // Now check if there is a mismatch between the brand in the url
    // and the selected brand in the store
    const brands = store.getState().auth.whoami.brands || initialState;

    const path = store.getState().router.location.pathname;

    // If we want to parse everything, use this:
    // const route = Route(`/:accountSlug((/brands/:brandSlug(/(locations(/:locationId)))(/jobs)(/:jobId(/stages/:stageName(/applicant/:applicantId)))(/detail)(/application)))(/team)(/settings/*rest)`);
    // For now, we are parsing only the brandSlug and locationId from the URL
    const route = Route(
      `/:accountSlug(/brands/:brandSlug(/locations(/:locationId))(/*rest1))(/*rest2)`,
    );
    const parsed = route.match(path);
    if (!parsed) {
      /* eslint-disable-next-line no-console */
      console.error('Unable to parse route.  Check urlToStoreMiddleware.');
    }

    if (parsed.brandSlug) {
      const newBrand = brands.find(
        brand => brand.slug === parsed.brandSlug && !brand.isAccount,
      );
      if (newBrand) {
        store.dispatch(setSelectedBrandAction(newBrand));
        // Set the selected location based on what's in the url
        if (parsed.locationId) {
          const newLocation = newBrand.locations.find(
            l => l.id === parsed.locationId,
          );
          if (newLocation) {
            store.dispatch(setSelectedLocationAction(newLocation));
          }
        }
      }
    } else {
      const account = brands.find(
        brand => brand.slug === parsed.accountSlug && brand.isAccount,
      );
      store.dispatch(setSelectedBrandAction(account));
    }
  } else {
    next(action);
  }
};

export default urlToStoreMiddleware;
