import { defineMessages } from 'react-intl';

export const scope = 'app.components.globalNav.sourcingNavLink';

export default defineMessages({
  sourcing: {
    id: `${scope}.sourcing`,
    defaultMessage: 'Sourcing',
  },
});
