import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  inputTopPadding: {
    padding: theme.spacing(3, 0, 0),
  },
  descriptionWrapper: {
    padding: theme.spacing(1, 0, 0),
  },
  descriptionText: {
    lineHeight: '1.25rem',
  },
}));
