import {
  EmptySearchState,
  EmptyState,
  ErrorState,
  LoadingState,
} from '@fountain/fountain-ui-components';
import { Collapse, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Choose } from 'react-extras';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import SearchInput from 'components/SearchInput';
import useRefFocus from 'hooks/useRefFocus';
import useRefMeasurements from 'hooks/useRefMeasurements';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import { fetchMessageTemplatesAction } from './actions';
import {
  MessageTemplatesType,
  MessageTemplatesVariant,
  REDUCER_KEY,
} from './constants';
import messages from './messages';
import RecentlyUsedTemplatesList from './RecentlyUsedTemplatesList';
import reducer from './reducer';
import saga from './saga';
import makeSelectTemplateMenuState from './selectors';
import useStyles from './styles';
import TemplatesList from './TemplatesList';

const TemplateMenuContent = ({
  intl,
  onSelected,
  templateType,
  variant,
  listWhatsAppTemplatesOnly,
  sourceAplicantId,
}) => {
  useInjectReducer({ key: REDUCER_KEY, reducer });
  useInjectSaga({ key: REDUCER_KEY, saga });
  const {
    isFetchMessageTemplateError: isError = false,
    isLoadingMessageTemplates: isLoading = false,
    messageTemplates = [],
    whatsAppTemplates = [],
  } = useSelector(makeSelectTemplateMenuState());

  const templates = useMemo(
    () =>
      listWhatsAppTemplatesOnly
        ? whatsAppTemplates
        : [...messageTemplates, ...whatsAppTemplates],
    [listWhatsAppTemplatesOnly, messageTemplates, whatsAppTemplates],
  );
  const dispatch = useDispatch();
  const searchInputRef = useRefFocus(true);
  const {
    offsetHeight: searchInputContainerHeight,
    ref: searchInputContainerRef,
  } = useRefMeasurements();
  const [filteredTemplates, setFilteredTemplates] = useState(templates);
  const [inputValue, setInputValue] = useState('');
  const [isEmptySearchState, setIsEmptySearchState] = useState(false);
  const [isEmptyState, setIsEmptyState] = useState(false);
  const styles = useStyles({ searchInputContainerHeight });

  useEffect(() => {
    dispatch(
      fetchMessageTemplatesAction(
        templateType || MessageTemplatesType.GENERAL,
        variant,
        sourceAplicantId,
      ),
    );
  }, [dispatch, templateType, variant, sourceAplicantId]);

  useEffect(() => {
    if (!inputValue) {
      setFilteredTemplates(templates);
      return;
    }

    const updatedFilteredTemplates = templates.filter(({ name }) =>
      name.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()),
    );
    setFilteredTemplates(updatedFilteredTemplates);
  }, [inputValue, setFilteredTemplates, templates]);

  useEffect(() => {
    if (filteredTemplates.length === 0 && inputValue) {
      setIsEmptySearchState(true);
      setIsEmptyState(false);
    }

    if (filteredTemplates.length === 0 && !inputValue) {
      setIsEmptyState(true);
      setIsEmptySearchState(false);
    }

    if (filteredTemplates.length > 0) {
      setIsEmptySearchState(false);
      setIsEmptyState(false);
    }
  }, [filteredTemplates, inputValue, setIsEmptySearchState, setIsEmptyState]);

  const handleChangeInput = event => setInputValue(event.target.value);

  const handleClearInput = () => {
    setInputValue('');
    if (searchInputRef && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  const handleClickError = () =>
    dispatch(
      fetchMessageTemplatesAction(
        MessageTemplatesType.GENERAL,
        variant,
        sourceAplicantId,
      ),
    );

  const sharedProps = { onSelected, searchString: inputValue, variant };
  const templatesListProps = { ...sharedProps, templates: filteredTemplates };

  return (
    <>
      <Grid
        className={styles.searchInputContainer}
        ref={searchInputContainerRef}
      >
        <SearchInput
          fullWidth
          inputRef={searchInputRef}
          onChange={handleChangeInput}
          onClear={handleClearInput}
          placeholder={intl.formatMessage(messages.searchPlaceholder)}
          value={inputValue}
        />
      </Grid>
      <Grid className={styles.listStatesContainer}>
        <Choose>
          <Choose.When condition={isError}>
            <ErrorState onClick={handleClickError} />
          </Choose.When>
          <Choose.When condition={isLoading}>
            <LoadingState message={intl.formatMessage(messages.loading)} />
          </Choose.When>
          <Choose.When condition={isEmptySearchState}>
            <EmptySearchState
              message={intl.formatMessage(messages.emptySearch, {
                searchString: `'${inputValue}'`,
              })}
            />
          </Choose.When>
          <Choose.When condition={isEmptyState}>
            <EmptyState message={intl.formatMessage(messages.empty)} />
          </Choose.When>
          <Choose.Otherwise>
            <Collapse in={!inputValue}>
              <RecentlyUsedTemplatesList {...sharedProps} />
            </Collapse>
            <TemplatesList {...templatesListProps} />
          </Choose.Otherwise>
        </Choose>
      </Grid>
    </>
  );
};

TemplateMenuContent.propTypes = {
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired })
    .isRequired,
  onSelected: PropTypes.func.isRequired,
  templateType: PropTypes.string,
  variant: PropTypes.oneOf([
    MessageTemplatesVariant.EMAIL,
    MessageTemplatesVariant.SMS,
    MessageTemplatesVariant.WHATSAPP,
  ]).isRequired,
  listWhatsAppTemplatesOnly: PropTypes.bool,
  sourceAplicantId: PropTypes.string,
};

export default injectIntl(TemplateMenuContent);
