/*
 *
 * ApplicantsV2 reducer
 *
 */
import produce from 'immer';

import {
  FETCH_JOB_DETAIL_FAILED,
  FETCH_JOB_DETAIL_INIT,
  FETCH_JOB_DETAIL_SUCCESS,
  RESET_STAGE_APPLICANTS_COUNT,
  SET_SEARCH_QUERY,
} from './constants';

interface Stage {
  id: string;
  name: string;
  // eslint-disable-next-line camelcase
  applicants_count: number;
  // eslint-disable-next-line camelcase
  short_type: string;
  type: string;
  slug: string;
}

interface Job {
  id: string;
  stages: Stage[];
}

export interface State {
  job?: Job;
  isLoading?: boolean;
  error?: object;
  searchQuery?: string;
}

export const initialState: State = {};

interface DetailInit {
  type: typeof FETCH_JOB_DETAIL_INIT;
}

interface DetailSuccess {
  type: typeof FETCH_JOB_DETAIL_SUCCESS;
  job: Job;
}

interface DetailFailed {
  type: typeof FETCH_JOB_DETAIL_FAILED;
  error: object;
}

interface ResetApplicantsCount {
  type: typeof RESET_STAGE_APPLICANTS_COUNT;
  numberOfApplicants: number;
  fromStageId: string;
  toStageId: string;
}

interface SetQuery {
  type: typeof SET_SEARCH_QUERY;
  searchQuery: string;
}

export type Action =
  | DetailInit
  | DetailSuccess
  | DetailFailed
  | ResetApplicantsCount
  | SetQuery;

const applicantsV2Reducer = (state = initialState, action: Action) =>
  produce(state, draft => {
    switch (action.type) {
      case FETCH_JOB_DETAIL_INIT:
        draft.isLoading = true;
        break;
      case FETCH_JOB_DETAIL_SUCCESS:
        draft.job = action.job;
        draft.isLoading = false;
        break;
      case FETCH_JOB_DETAIL_FAILED:
        draft.error = action.error;
        draft.isLoading = false;
        break;
      case RESET_STAGE_APPLICANTS_COUNT:
        if (!draft.job) {
          break;
        }
        for (let index = 0; index < draft.job.stages.length; index++) {
          const stage = draft.job.stages[index];
          if (stage.id === action.fromStageId) {
            stage.applicants_count -= action.numberOfApplicants;
          } else if (stage.id === action.toStageId) {
            stage.applicants_count += action.numberOfApplicants;
          }
        }
        break;
      case SET_SEARCH_QUERY:
        draft.searchQuery = action.searchQuery;
        break;
      // no default
    }
  });

export default applicantsV2Reducer;
