import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { VFC } from 'react';

export const ErrorIcon: VFC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2009 13.4998L8.90094 1.6998C8.50094 0.999805 7.50094 0.999805 7.10094 1.6998L0.800937 13.4998C0.400937 14.1998 0.900937 14.9998 1.70094 14.9998H14.4009C15.1009 14.9998 15.6009 14.1998 15.2009 13.4998ZM8.00094 12.9998C7.50094 12.9998 7.00094 12.4998 7.00094 11.9998C7.00094 11.4998 7.50094 10.9998 8.00094 10.9998C8.50094 10.9998 9.00094 11.4998 9.00094 11.9998C9.00094 12.4998 8.50094 12.9998 8.00094 12.9998ZM9.00094 8.9998C9.00094 9.4998 8.50094 9.9998 8.00094 9.9998C7.50094 9.9998 7.00094 9.4998 7.00094 8.9998V6.2998C7.00094 5.7998 7.50094 5.2998 8.00094 5.2998C8.50094 5.2998 9.00094 5.7998 9.00094 6.2998V8.9998Z"
        fill="#E51F58"
      />
    </svg>
  </SvgIcon>
);
