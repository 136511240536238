import { Trashcan } from '@fountain/fountain-ui-components';
import { Grid, IconButton } from '@material-ui/core';
import cx from 'classnames';
import React, { FC } from 'react';

import { useStyles } from './styles';

export interface RowDeleteButtonProps {
  allowDelete: boolean;
  ariaLabel: string;
  className?: string;
  onDelete: () => void;
}

export const RowDeleteButton: FC<RowDeleteButtonProps> = ({
  allowDelete,
  ariaLabel,
  className,
  onDelete,
}) => {
  const styles = useStyles();

  return (
    <Grid item className={cx(styles.trashIconContainer, className)}>
      {allowDelete && (
        <IconButton onClick={onDelete} aria-label={ariaLabel}>
          <Trashcan viewBox="0 0 16 16" className={styles.trashcanIcon} />
        </IconButton>
      )}
    </Grid>
  );
};
