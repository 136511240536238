import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  bodyText: {
    color: theme.palette.common.gray800,
    marginBottom: theme.spacing(3),
  },
  toggleGroupContainer: {
    marginLeft: '11px',
  },
}));
