import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React, { FC } from 'react';

export const JobSwitcherStageIcon: FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.5 5.25C11.5 4.55964 10.9403 4 10.25 4C9.55962 4 8.99998 4.55964 8.99998 5.25L8.99997 10.75C8.99997 12.5449 7.5449 14 5.74997 14C3.95505 14 2.49997 12.5449 2.49997 10.75L2.49997 9.25C2.49997 9.11193 2.38805 9 2.24998 9H1.26078C1.02848 9 0.912144 8.69474 1.07641 8.51621L3.31514 6.08301C3.41696 5.97233 3.58206 5.97233 3.68389 6.08301L5.92262 8.51621C6.08688 8.69474 5.97054 9 5.73824 9H4.74997C4.6119 9 4.49997 9.11193 4.49997 9.25L4.49997 10.75C4.49997 11.4404 5.05962 12 5.74997 12C6.44033 12 6.99997 11.4404 6.99997 10.75L6.99998 5.25C6.99998 3.45507 8.45505 2 10.25 2C12.0449 2 13.5 3.45507 13.5 5.25V6.75C13.5 6.88807 13.6119 7 13.75 7H14.7387C14.971 7 15.0873 7.30526 14.9231 7.48379L12.6844 9.91699C12.5825 10.0277 12.4174 10.0277 12.3156 9.91699L10.0769 7.48379C9.91261 7.30526 10.0289 7 10.2612 7H11.25C11.388 7 11.5 6.88807 11.5 6.75V5.25Z"
      fill="white"
    />
  </SvgIcon>
);
