/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AutomatedResponsesDetail } from '../models/AutomatedResponsesDetail';
import type { CreateAutomatedResponseParams } from '../models/CreateAutomatedResponseParams';
import type { MessageTemplateDetail } from '../models/MessageTemplateDetail';
import type { UpdateAutomatedResponseParams } from '../models/UpdateAutomatedResponseParams';
import type { UpdateFaqBotFlagParams } from '../models/UpdateFaqBotFlagParams';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AutomatedResponsesService {

    /**
     * Retrieves Available Automated Responses
     * Returns a list of automated responses of an account
     * @param page Pagination defaults to 0
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiChatbotAutomatedResponses(
        page?: number,
    ): CancelablePromise<{
        automated_responses: Array<AutomatedResponsesDetail>;
        pagination?: {
            current_page: number;
            next_page: number | null;
            per_page: number;
            total_count: number;
            total_pages: number;
        };
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/chatbot/automated_responses',
            query: {
                'page': page,
            },
        });
    }

    /**
     * Create Automated Response
     * @param requestBody Automated Response
     * @returns any created
     * @throws ApiError
     */
    public static postInternalApiChatbotAutomatedResponses(
        requestBody: CreateAutomatedResponseParams,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/chatbot/automated_responses',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Retrieves Automated Response Details
     * @param id Automated Response ID
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiChatbotAutomatedResponses1(
        id: number,
    ): CancelablePromise<{
        automated_response: AutomatedResponsesDetail;
        message_templates: Array<MessageTemplateDetail>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/chatbot/automated_responses/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update Automated Response
     * @param id Automated Response ID
     * @param requestBody Update
     * @returns any successful
     * @throws ApiError
     */
    public static patchInternalApiChatbotAutomatedResponses(
        id: number,
        requestBody: UpdateAutomatedResponseParams,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/internal_api/chatbot/automated_responses/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Automated Response
     * @param id Automated Response ID
     * @returns any successful
     * @throws ApiError
     */
    public static deleteInternalApiChatbotAutomatedResponses(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/internal_api/chatbot/automated_responses/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update FAQ Bot Flag
     * @param id Account ID
     * @param requestBody Update FAQ Bot Flag
     * @returns any successful
     * @throws ApiError
     */
    public static patchInternalApiChatbotAutomatedResponsesUpdateFaqBot(
        id: string,
        requestBody: UpdateFaqBotFlagParams,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/internal_api/chatbot/automated_responses/{id}/update_faq_bot',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}