import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '&$selected': {
      '& span': {
        color: theme.palette.common.blue400,
      },
      '&:hover': {
        backgroundColor: theme.palette.common.blue100,
      },
      '& p': {
        color: theme.palette.common.blue400,
      },
      backgroundColor: theme.palette.common.blue100,
      borderRadius: '5px',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.gray100,
    },
  },
  menuListItem: {
    borderRadius: theme.misc.borderRadius,
    '&:hover': {
      backgroundColor: '#F2F3F7',
    },
    '&.Mui-selected': {
      fontWeight: 500,
      color: theme.palette.common.blue400,
      backgroundColor: theme.palette.common.blue50,
      '&:hover': {
        color: theme.palette.common.blue400,
        backgroundColor: theme.palette.common.blue50,
      },
    },
    '&.Mui-disabled': {
      opacity: 1,
    },
  },
}));

export default useStyles;
