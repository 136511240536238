/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import produce from 'immer';
import { AnyAction } from 'redux';

import { ActionType } from 'containers/MoveApplicantDialog/actions';
import { Reason } from 'containers/MoveApplicantDialog/constants';
import { initialState } from 'containers/MoveApplicantDialog/selectors';

const reducer = (state = initialState, action: AnyAction) =>
  produce(state, draft => {
    switch (action.type) {
      case ActionType.RESET_STATE:
        return initialState;
      case ActionType.SET_DATA:
        draft.data = {
          ...state.data,
          ...action.payload,
        };
        break;
      case ActionType.SET_AUTOMATED_ACTIONS_RUN_DISABLED:
        draft.dialogContent.automatedActions.shouldRun = false;
        break;
      case ActionType.SET_AUTOMATED_ACTIONS_RUN_ENABLED:
        draft.dialogContent.automatedActions.shouldRun = true;
        break;
      case ActionType.SET_AUTOMATED_ACTIONS_TO_STAGE:
        draft.dialogContent.automatedActions.toStage = action.payload.to_stage
          ? action.payload.to_stage
          : {};
        draft.dialogContent.automatedActions.destinationStage = action.payload
          .destination_stage
          ? action.payload.destination_stage
          : {};
        break;
      case ActionType.SET_AUTOMATED_ACTIONS_VISIBLE:
        draft.dialogContent.automatedActions.isVisible = true;
        break;
      case ActionType.SET_JOB_STAGE_SELECTORS_HIDDEN:
        draft.dialogContent.jobStageSelectors.isVisible = false;
        break;
      case ActionType.SET_JOB_STAGE_SELECTORS_JOB:
        draft.dialogContent.jobStageSelectors.job = action.payload;
        break;
      case ActionType.SET_JOB_STAGE_SELECTORS_STAGE:
        draft.dialogContent.jobStageSelectors.stage = action.payload;
        break;
      case ActionType.SET_JOB_STAGE_SELECTORS_VISIBLE:
        draft.dialogContent.jobStageSelectors.isVisible = true;
        break;
      case ActionType.SET_REASON_SELECTOR_DEFAULT_REASON:
        draft.dialogContent.reasonSelector.defaultReason = action.payload;
        break;
      case ActionType.SET_REASON_SELECTOR_REASON:
        draft.dialogContent.reasonSelector.reason = action.payload;
        break;
      case ActionType.SET_REASON_SELECTOR_TYPE:
        draft.dialogContent.reasonSelector.type = action.payload;
        break;
      case ActionType.SET_REASON_SELECTOR_VISIBLE:
        draft.dialogContent.reasonSelector.isVisible = true;
        break;
      case ActionType.SET_REASON_SELECTOR_HIDDEN:
        draft.dialogContent.reasonSelector = {
          defaultReason: {},
          isVisible: false,
          reason: {},
          type: '' as Reason,
        };
        break;
      case ActionType.SET_DIALOG_TYPE:
        draft.dialogType = action.payload;
        break;
      case ActionType.SET_DIALOG_CLOSE:
        draft.isDialogOpen = false;
        break;
      case ActionType.SET_DIALOG_OPEN:
        draft.isDialogOpen = true;
        break;
      case ActionType.SET_LOADING_DISABLED:
        draft.isLoading = false;
        break;
      case ActionType.SET_LOADING_ENABLED:
        draft.isLoading = true;
        break;
      case ActionType.SET_SUBMIT_DISABLED:
        draft.submitStatus.isEnabled = false;
        break;
      case ActionType.SET_SUBMIT_ENABLED:
        draft.submitStatus.isEnabled = true;
        break;
      case ActionType.SET_SUBMIT_LOADING_DISABLED:
        draft.submitStatus.isLoading = false;
        break;
      case ActionType.SET_SUBMIT_LOADING_ENABLED:
        draft.submitStatus.isLoading = true;
        break;
      default:
        break;
    }
    return draft;
  });

export default reducer;
