import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import {
  clearApplicantCache,
  updateApplicant,
  updateApplicantDataFieldColumn,
} from 'containers/ApplicantTableV2/actions';
import {
  addDefaultErrorMessageAction,
  addMessageAction,
} from 'containers/FlashMessage/actions';
import { apiPut } from 'utils/axios';

export default function useApplicantUpdateDetail(
  applicantId,
  dataType,
  preventStoreUpdates = false,
  options = {},
) {
  const dispatch = useDispatch();

  const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/info/${dataType}`;

  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState('');

  const dispatchUpdateSelectedApplicant = data => {
    if (!preventStoreUpdates) {
      dispatch(updateApplicant(applicantId, dataType, data));
    }
  };

  const dispatchUpdateApplicantDataFieldColumn = dataField => {
    dispatch(updateApplicantDataFieldColumn(applicantId, dataField));
  };

  const updateApplicantDetail = async (
    params,
    successMessage,
    dataField = {},
  ) => {
    setIsFetching(true);
    try {
      const res = await apiPut(url, params);

      if (!res || !res.data) {
        return null;
      }

      if (!options.disableMessage) {
        dispatch(addMessageAction(successMessage, 'success'));
      }
      const data = res.data[dataType];
      dispatchUpdateSelectedApplicant(data);
      dispatchUpdateApplicantDataFieldColumn(dataField);
      setError('');
    } catch (e) {
      setError(e);
    } finally {
      setIsFetching(false);
      dispatch(clearApplicantCache(applicantId));
    }
    return null;
  };

  useEffect(() => {
    if (error) {
      dispatch(addDefaultErrorMessageAction());
    }
  }, [dispatch, error]);

  return { updateApplicantDetail, isFetching };
}
