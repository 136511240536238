import { Grid, Menu, MenuItem, Typography } from '@material-ui/core';
import { FiberManualRecord, OpenInNew } from '@material-ui/icons';
import { Opening } from 'api-clients/monolith';
import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useParams } from 'react-router-dom';

import SearchInput from 'components/SearchInput';
import { useWorkflowOpenings } from 'containers/WorkflowEditor/hooks/useWorkflowOpenings';
import { useDebounce } from 'hooks/useDebounce';

import { messages } from './messages';
import { useStyles } from './styles';

export interface JobSearchMenuProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  workflowExternalId: string;
}

export const OpeningsMenu: FC<JobSearchMenuProps> = ({
  anchorEl,
  onClose,
  workflowExternalId,
}) => {
  const styles = useStyles();
  const [searchInput, setSearchInput] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearch = useDebounce(setSearchQuery, 500);
  const [workflowOpenings, setWorkflowOpenings] = useState<Opening[]>([]);
  const { result: workflowOpeningsResult } = useWorkflowOpenings({
    title: searchQuery,
    workflowId: workflowExternalId,
    page: 1,
  });
  const intl = useIntl();

  useEffect(() => {
    if (workflowOpeningsResult.status === 'ready') {
      setWorkflowOpenings(workflowOpeningsResult.data.openings);
    }
  }, [workflowOpeningsResult, setWorkflowOpenings]);

  useEffect(() => {
    debouncedSearch(searchInput);
  }, [searchInput, debouncedSearch]);

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };
  const { accountSlug } = useParams<{
    accountSlug: string;
  }>();

  const navigateToOpening = (opening: Opening) => {
    const path = generatePath('/:accountSlug/jobs/:funnelId/v2/stages?page=1', {
      accountSlug,
      funnelId: opening.funnel_external_id,
    });
    window.open(path, '_blank');
  };

  const applicantCount = (opening: Opening) => {
    if (opening.pipeline_count === undefined) {
      return opening.applicant_counts?.in_progress;
    }
    return opening.pipeline_count;
  };

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      classes={{
        paper: classNames(styles.menuPaper),
      }}
      getContentAnchorEl={null}
      open={!!anchorEl}
      onClose={onClose}
    >
      <Grid container>
        <SearchInput
          // @ts-expect-error this will go away when converting this component to typescript
          value={searchInput}
          onChange={onSearchInputChange}
          className={styles.searchBar}
          onClear={() => setSearchInput('')}
          color="secondary"
          type="search"
          placeholder={intl.formatMessage(messages.search)}
          fullWidth
        />
        {workflowOpenings.map(opening => {
          const applicantMessage =
            applicantCount(opening) === 1
              ? messages.applicant
              : messages.applicants;
          return (
            <MenuItem
              key={opening.funnel_name}
              className={styles.menuItem}
              onClick={() => navigateToOpening(opening)}
            >
              <FiberManualRecord
                fontSize="small"
                className={classNames(styles.activeIndicator, {
                  active: opening.active,
                })}
              />
              <Grid container className={styles.menuChild}>
                <Grid item>
                  <Typography className={styles.openingTitle}>
                    {opening.funnel_name}
                  </Typography>
                </Grid>
                <Grid item className={styles.ownerApplicants}>
                  <Typography variant="body2">{opening.owner_name}</Typography>
                  <Typography variant="body2" className={styles.separator}>
                    ·
                  </Typography>
                  <Typography variant="body2">
                    <FormattedMessage
                      {...applicantMessage}
                      values={{ count: applicantCount(opening) }}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <OpenInNew fontSize="small" className={styles.newTabIcon} />
            </MenuItem>
          );
        })}
      </Grid>
    </Menu>
  );
};
