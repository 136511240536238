// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Callback<Args extends any[] = any[]> = (...args: Args) => void;

interface Debounced<F extends Callback> extends Callback<Parameters<F>> {
  clear(): void;
}

/**
 * Simple debounce function. Executes `fn` after `delay`.
 *
 * @param {function} fn Function to execute after debounce period
 * @param {number} delay How long to wait before executing `fn`
 *
 * @returns {function} Returns function to be called with arguments to pass to `fn`
 */
export const debounce = <F extends Callback>(
  fn: F,
  delay: number,
): Debounced<F> => {
  let timeout: NodeJS.Timeout | undefined;
  const clear = () => timeout !== undefined && clearTimeout(timeout);
  const wrapped = (...args: Parameters<F>) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => fn(...args), delay);
  };

  return Object.assign(wrapped, { clear });
};
