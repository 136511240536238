import { StyledReactSelect } from '@fountain/fountain-ui-components';
import {
  Stage,
  WorkflowPartnerStage,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import produce from 'immer';
import React, { useContext, VFC } from 'react';
import { useIntl } from 'react-intl';

import { useWorkflowWithStages } from 'components/ChangeWorkflowModal/useWorkflowWithStages';
import { SettingSwitch } from 'containers/WorkflowEditor/components/WorkflowEditorContent/SettingSwitch';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { DELAY_SENDING_WEBHOOK } from '../constants';
import { useStyles } from '../styles';
import { messages } from './messages';

interface DelaySendingWebhookSettingsProps {
  stage: WorkflowPartnerStage;
  workflowId: string;
}

export const DelaySendingWebhookSettings: VFC<
  DelaySendingWebhookSettingsProps
> = ({ stage, workflowId }) => {
  const styles = useStyles();
  const intl = useIntl();
  const { setStage } = useContext(StageContext);
  const { additional_info: additionalInfo } = stage;

  const webhookDelaySettings = additionalInfo.webhook_delay_settings;
  const delaySendingWebhook = webhookDelaySettings?.delay_sending_webhook;
  const delayUntilStageId = webhookDelaySettings?.delay_until_stage_id;

  const { result: sourceStagesResult } = useWorkflowWithStages(workflowId);

  const onTargetStageChange = (option?: Stage) => {
    setStage(
      produce(stage, draftStage => {
        if (draftStage.type === 'PartnerStage') {
          if (draftStage.additional_info.webhook_delay_settings) {
            draftStage.additional_info.webhook_delay_settings.delay_until_stage_id =
              option?.id;
          }
        }
      }),
    );
  };

  const onToggleDelaySendingWebhook = () => {
    setStage(
      produce((draftStage: WorkflowStageDetail) => {
        if (draftStage.type === 'PartnerStage') {
          if (draftStage.additional_info.webhook_delay_settings) {
            const newValue =
              !draftStage.additional_info.webhook_delay_settings
                .delay_sending_webhook;

            draftStage.additional_info.webhook_delay_settings.delay_sending_webhook =
              newValue;
          }
        }
      }),
    );
  };

  const selectedStage =
    sourceStagesResult.status === 'ready'
      ? sourceStagesResult.data.stages.find(
          stage => stage.id === String(delayUntilStageId),
        )
      : null;

  return (
    <>
      <SettingSwitch
        dataKey={DELAY_SENDING_WEBHOOK}
        checked={!!delaySendingWebhook}
        onChange={onToggleDelaySendingWebhook}
        label={intl.formatMessage(messages.delaySendingWebook, {
          partnerName: stage.title,
        })}
      />

      {sourceStagesResult.status === 'ready' && (
        <StyledReactSelect
          aria-label={intl.formatMessage(messages.targetStage)}
          className={styles.selectOptionDropdownForToggle}
          getOptionLabel={(option: Stage) => option.title}
          getOptionValue={(option: Stage) => option.id}
          label={intl.formatMessage(messages.targetStage)}
          onChange={onTargetStageChange}
          options={sourceStagesResult.data.stages}
          value={selectedStage}
          isDisabled={!delaySendingWebhook}
        />
      )}
    </>
  );
};
