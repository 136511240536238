import {
  CancelablePromise,
  Stage,
  StagesService,
  Workflow,
  WorkflowsService,
} from 'api-clients/monolith';
import { useApiService } from 'hooks';
import { useCallback } from 'react';

interface WorkflowWithStages extends Workflow {
  stages: Stage[];
}

export function useWorkflowWithStages(workflowId: string) {
  const loadData = useCallback(() => {
    const stagesRequest = StagesService.getInternalApiStages(
      workflowId,
      300,
      true,
    );
    const workflowRequest =
      WorkflowsService.getInternalApiWorkflows1(workflowId);
    return new CancelablePromise<WorkflowWithStages>(
      (resolve, reject, onCancel) => {
        onCancel(() => {
          stagesRequest.cancel();
          workflowRequest.cancel();
        });
        Promise.all([workflowRequest, stagesRequest])
          .then(([workflow, stageReply]) => {
            resolve({ ...workflow, stages: stageReply.stages });
          })
          .catch(error => reject(error));
      },
    );
  }, [workflowId]);
  return useApiService(loadData);
}
