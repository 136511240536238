import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  updateFeedbackSuccess: {
    id: 'app.containers.Chatbot.updateFeedbackSuccess',
    defaultMessage: `Feedback updated successfully.`,
  },
  updateFeedbackError: {
    id: 'app.containers.Chatbot.updateFeedbackError',
    defaultMessage: `Update feedback failed. Please try again.`,
  },
});
