import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  label: {
    height: 'auto',
    border: theme.customBorders.gray300,
    boxSizing: 'border-box',
    borderRadius: 100,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0.5, 1.5),
    marginRight: theme.spacing(1),
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    cursor: 'pointer',
    color: theme.palette.common.gray600,

    '&:hover': {
      backgroundColor: theme.palette.common.gray100,
    },

    '&.hidden': {
      position: 'absolute',
      opacity: 0,
    },

    '&.completed': {
      border: `1px solid ${theme.palette.common.green400}`,
      color: theme.palette.common.green800,

      '&:hover': {
        backgroundColor: theme.palette.common.green100,
      },
    },

    '&.addMarginBottom': {
      marginBottom: theme.spacing(1),
    },
  },
  checkmark: {
    marginRight: theme.spacing(1),
  },
  labelTitle: {
    fontSize: theme.typography.h4.fontSize,
  },
  labelContentActions: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  button: {
    padding: 0,
    fontSize: theme.typography.body2.fontSize,
  },
}));

export default useStyles;
