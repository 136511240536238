import {
  CustomTooltip,
  IconButton,
  Loader,
  Refresh,
  StyledReactSelect,
} from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  CancelablePromise,
  WorkflowEditorService,
  WorkflowPartnerStage,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import { PartnerIntegrationOption } from 'api-clients/monolith/models/PartnerIntegrationOption';
import produce from 'immer';
import React, { useContext, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addMessageAction } from 'containers/FlashMessage/actions';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';

import { messages } from './messages';
import { useStyles } from './styles';

interface PartnerIntegrationConnectedCardProps {
  stage: WorkflowPartnerStage;
}

export const PartnerIntegrationConnectedCard: VFC<
  PartnerIntegrationConnectedCardProps
> = ({ stage }) => {
  const styles = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const { setStage } = useContext(StageContext);

  const partnerIntegrationOptions =
    stage.additional_info.partner_integration_options ?? [];
  const partnerName = stage.additional_info.partner_name ?? 'partner';

  const { mutation: updateStage, result } = useApiServiceMutation<
    WorkflowStageDetail,
    (stageExternalId: string) => CancelablePromise<WorkflowStageDetail>,
    { errors: string[] }
  >(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    WorkflowEditorService.putInternalApiWorkflowEditorStagesPartnerIntegrations,
    {
      onSuccess: () => {
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.success, {
              partnerName,
            }),
            'success',
          ),
        );
      },
    },
  );

  const partnerStageIsDataCollection =
    stage.additional_info.applicant_portal_action === 'data_collection';

  const localPartnerOptions =
    (result.status === 'ready' && result.data.type === 'PartnerStage'
      ? result.data.additional_info?.partner_integration_options
      : partnerIntegrationOptions) ?? [];

  const refreshOptions = () => {
    updateStage(stage.external_id);
  };

  const selectedPartnerOption =
    localPartnerOptions?.find(
      option => option.id === stage.partner_option_id,
    ) ?? null;

  const onPartnerOptionTypeChange = (
    partnerIntegrationOption: PartnerIntegrationOption,
  ) => {
    const { id } = partnerIntegrationOption;

    setStage(
      produce(stage, draftStage => {
        draftStage.partner_option_id = id;
      }),
    );
  };

  const showStageOptionError =
    partnerStageIsDataCollection && selectedPartnerOption?.id == null;

  return (
    <>
      {showStageOptionError && (
        <div className={styles.partnerDataCollectionPackageWarning}>
          <Alert severity="error">
            <FormattedMessage
              {...messages.partnerDataCollectionPackageWarning}
            />
          </Alert>
        </div>
      )}
      <Grid container alignItems="center" wrap="nowrap" item>
        <StyledReactSelect
          aria-label={intl.formatMessage(messages.stageOptionType)}
          className={styles.selectOptionDropdown}
          getOptionValue={(option: PartnerIntegrationOption) => option.id}
          label={intl.formatMessage(messages.stageOptionType)}
          onChange={onPartnerOptionTypeChange}
          options={localPartnerOptions}
          error={
            showStageOptionError
              ? intl.formatMessage(messages.selectStageOptionTypeError)
              : undefined
          }
          value={selectedPartnerOption}
        />
        {!result.isLoading && (
          <CustomTooltip
            dense
            title={intl.formatMessage(messages.refreshOptions, {
              partnerName,
            })}
          >
            <IconButton
              className={styles.refreshIcon}
              onClick={refreshOptions}
              aria-label={intl.formatMessage(
                messages.ariaRefreshPartnerOptions,
              )}
            >
              <Refresh />
            </IconButton>
          </CustomTooltip>
        )}
        {result.isLoading && (
          <>
            <Loader
              className={styles.loadingIcon}
              aria-label={intl.formatMessage(messages.ariaLoadingSpinner)}
            />
            <Typography variant="body2" className={styles.refreshText}>
              {intl.formatMessage(messages.refreshing)}
            </Typography>
          </>
        )}
        {result.isError && (
          <Typography variant="body2" className={styles.refreshFailed}>
            {intl.formatMessage(messages.refreshFailed)}
          </Typography>
        )}
      </Grid>
    </>
  );
};
