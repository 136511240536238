import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import { setApplicantBlobAction } from 'containers/ApplicantTableV2/actions';
import { addMessageAction } from 'containers/FlashMessage/actions';
import { apiPost } from 'utils/axios';

export default function useApplicantRequestFileRecollection(
  applicantId,
  accountSlug,
  onClose,
) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const params = queryString.stringify({
    account_slug: accountSlug,
    external_id: applicantId,
  });

  const requestFileRecollection = useCallback(
    async formData => {
      const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/file_upload_requests?${params}`;
      setIsLoading(true);
      try {
        const res = await apiPost(url, {
          ...formData,
        });
        dispatch(setApplicantBlobAction(applicantId, res.data.blob));
        onClose();
        setError('');
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [applicantId, accountSlug],
  );

  useEffect(() => {
    if (error) {
      dispatch(
        addMessageAction('Whoops, something went wrong. Please try again.'),
      );
    }
  }, [dispatch, error]);

  return { requestFileRecollection, isLoading };
}
