import { WorkflowEditorService } from 'api-clients/monolith';
import { useApiService } from 'hooks';
import React from 'react';

export function useAvailableStages({
  funnelSlug,
  subStageTypes,
}: {
  funnelSlug: string;
  subStageTypes?: boolean;
}) {
  const fetchAvailableStageTypes = React.useCallback(
    () =>
      WorkflowEditorService.getInternalApiWorkflowEditorFunnelsStageTypes(
        funnelSlug,
        subStageTypes,
      ),
    [funnelSlug, subStageTypes],
  );

  return useApiService(fetchAvailableStageTypes);
}
