import { WorkflowsService } from 'api-clients/monolith';
import { useApiService } from 'hooks';
import React from 'react';

export function useWorkflows({
  title,
  stageId,
  page,
  sortBy,
  sortDirection,
}: {
  title?: string;
  stageId?: string;
  page?: number;
  sortBy?: string;
  sortDirection?: 'asc' | 'desc';
}) {
  const fetchWorkflows = React.useCallback(
    () =>
      WorkflowsService.getInternalApiWorkflows(
        title,
        stageId,
        page,
        sortBy,
        sortDirection,
      ),
    [title, stageId, page, sortBy, sortDirection],
  );

  return useApiService(fetchWorkflows);
}
