import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useSimpleToggle } from 'hooks';
import React, { FC } from 'react';

import { FilterDropdownButton } from 'components/FilterDropdown/Button';
import { FilterOpen } from 'components/FilterDropdown/FilterOpen';
import { FilterDropdownProps } from 'components/FilterDropdown/index';
import { Filterable } from 'components/FilterDropdown/util';
import { useDebounce } from 'hooks/useDebounce';

import { useLocationGroups } from './hooks/useLocationGroups';

export const FilterLocationGroupDropdown: FC<
  FilterDropdownProps<Filterable>
> = ({ selected, setSelected, showTitle, enterprise, error }) => {
  const { off, showContent: open, toggle: openUp } = useSimpleToggle();

  const { locationGroups, setQuery } = useLocationGroups(enterprise);
  const debounceSetQuery = useDebounce(setQuery, 500);

  const name = 'locationGroup';

  return (
    <ClickAwayListener onClickAway={off}>
      <div>
        <FilterDropdownButton
          name={name}
          open={open}
          openUp={openUp}
          count={selected.length}
          clearFilter={() => setSelected([])}
          titles={selected.map(filterable => filterable.title)}
          showTitle={showTitle}
          setSelected={setSelected}
          selected={selected}
          error={error}
        />
        {open && (
          <FilterOpen
            name={name}
            selected={selected}
            setSelected={setSelected}
            options={locationGroups}
            setQuery={debounceSetQuery}
          />
        )}
      </div>
    </ClickAwayListener>
  );
};
