import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  filterContainer: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    overflowX: 'auto',
    paddingBottom: theme.spacing(2),
  },
  selectInput: {
    width: '150px',
  },
  selectSortInput: {
    width: '225px',
    padding: theme.spacing(0, 1, 0, 1),
  },
  selectModelInput: {
    width: '430px',
    padding: theme.spacing(0, 1, 0, 1),
    '& > *:nth-child(2)': {
      maxHeight: '100px',
      minHeight: '40px',
      '& > *': {
        minHeight: '40px',
        '& > *:first-child': {
          overflowY: 'scroll',
          maxHeight: 71,
        },
      },
    },
  },
  selectIntentInput: {
    width: '370px',
    padding: theme.spacing(0, 1, 0, 1),
    '& > *:nth-child(2)': {
      maxHeight: '100px',
      minHeight: '40px',
      '& > *': {
        minHeight: '40px',
        '& > *:first-child': {
          overflowY: 'scroll',
          maxHeight: 71,
        },
      },
    },
  },
}));
