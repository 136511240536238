import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { addDefaultErrorMessageAction } from 'containers/FlashMessage/actions';
import reducer from 'containers/FlashMessage/reducer';
import saga from 'containers/FlashMessage/saga';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import ErrorBoundary from './ErrorBoundaryComponent';

const WrappedErrorBoundary = ({ children }) => {
  useInjectReducer({ key: 'flashMessage', reducer });
  useInjectSaga({ key: 'flashMessage', saga });

  const dispatch = useDispatch();
  const dispatchErrorMessage = () => {
    dispatch(addDefaultErrorMessageAction());
  };
  return (
    <ErrorBoundary dispatchErrorMessage={dispatchErrorMessage}>
      {children}
    </ErrorBoundary>
  );
};

WrappedErrorBoundary.propTypes = {
  children: PropTypes.any,
};

export default WrappedErrorBoundary;
