/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Stage } from '../models/Stage';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StagesService {

    /**
     * Requests Stages
     * Returns stages for the logged in user
     * @param workflowId filter query for workflow external id
     * @param perPage paginate query per_page
     * @param includingSubStages whether to include sub stages in results
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiStages(
        workflowId?: string,
        perPage?: number,
        includingSubStages?: boolean,
    ): CancelablePromise<{
        stages: Array<Stage>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/stages',
            query: {
                'workflow_id': workflowId,
                'per_page': perPage,
                'including_sub_stages': includingSubStages,
            },
        });
    }

    /**
     * Finds a Stage
     * Returns a stage for the logged in user
     * @param id
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiStages1(
        id: string,
    ): CancelablePromise<{
        stage: Stage;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/stages/{id}',
            path: {
                'id': id,
            },
        });
    }

}