import { Warning } from '@fountain/fountain-ui-components';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { WorkflowJobMatcherStage } from 'api-clients/monolith';
import isNil from 'lodash/isNil';
import React, { VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { AdvancedSettings } from 'containers/WorkflowEditor/components/AdvancedSettings';
import { IdleMoveRule } from 'containers/WorkflowEditor/components/IdleMoveRule';
import { StageSection } from 'containers/WorkflowEditor/components/StageSection';
import { StageSettingCard } from 'containers/WorkflowEditor/components/StageSettingCard';
import { StageSettingMessageCard } from 'containers/WorkflowEditor/components/StageSettingMessageCard';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { AdvancedOptions } from '../AdvancedOptions';
import { JobRouterConditions } from '../JobRouterConditions';
import { JobRouterConditionRow } from '../JobRouterConditions/JobRouterConditionSet/JobRouterCondition';
import { messages } from './messages';

const useStyles = makeStyles(theme => ({
  conditionSubHeader: {
    marginBottom: theme.spacing(2),
  },
  description: {
    marginBottom: theme.spacing(3),
    lineHeight: '20px',
  },
  errorContent: {
    backgroundColor: theme.palette.common.red100,
    borderRadius: theme.spacing(0.625),
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    maxWidth: '600px',
  },
  errorIcon: {
    height: theme.spacing(2),
    marginRight: theme.spacing(0.25),
  },
  errorText: {
    color: theme.palette.common.red800,
  },
}));

export interface JobSwitcherStageProps {
  stage: WorkflowJobMatcherStage;
  workflowId?: number;
}

export const JobSwitcherStage: VFC<JobSwitcherStageProps> = ({
  stage,
  workflowId,
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const { setStage } = React.useContext(StageContext);

  if (isNil(workflowId)) {
    return (
      <div data-testid="jobSelectorStage">
        <div className={styles.errorContent}>
          <Warning color="error" className={styles.errorIcon} />
          <Typography variant="h4" className={styles.errorText}>
            <FormattedMessage {...messages.workflowRequired} />
          </Typography>
        </div>
      </div>
    );
  }

  const noWorkflowHiringOpenings =
    stage.additional_info.associated_workflow_funnels_hiring_count === 0;

  const conditions: JobRouterConditionRow[] = [
    {
      id: `Workflow-${workflowId}`,
      resource: 'Workflow',
      resource_ids: [workflowId],
      fixed: true,
      tooltipText: intl.formatMessage(messages.workflowTooltipDescription),
    },
    ...(stage.additional_info.conditions.operands ?? []),
  ];

  return (
    <div data-testid="jobSelectorStage">
      <StageSection>
        <StageSettingCard
          title={<FormattedMessage {...messages.selectOpenings} />}
          variant="default"
        >
          <Typography
            className={styles.description}
            color="textPrimary"
            variant="body2"
          >
            <FormattedMessage {...messages.selectConditionsDescription} />
          </Typography>
          <Typography
            className={styles.conditionSubHeader}
            color="textPrimary"
            variant="h4"
          >
            <FormattedMessage {...messages.selectionConditions} />
          </Typography>
          {noWorkflowHiringOpenings && (
            <Grid className={styles.errorContent}>
              <Warning color="error" className={styles.errorIcon} />
              <Typography variant="h4" className={styles.errorText}>
                <FormattedMessage {...messages.noWorkflowHiringOpenings} />
              </Typography>
            </Grid>
          )}
          <JobRouterConditions conditions={conditions} stage={stage} />
          <AdvancedOptions stage={stage} setStage={setStage} />
        </StageSettingCard>
      </StageSection>

      <StageSection>
        <IdleMoveRule stage={stage} />
      </StageSection>

      <StageSection>
        <StageSettingMessageCard
          messageType="job_switcher"
          stageExternalId={stage.external_id}
        />
      </StageSection>

      <StageSection>
        <StageSettingMessageCard
          messageType="trigger"
          stageExternalId={stage.external_id}
        />
      </StageSection>
      {stage.account_go_enabled && (
        <StageSection>
          <AdvancedSettings stage={stage} />
        </StageSection>
      )}
    </div>
  );
};
