import { ReloadableResultStatus } from 'api/resultStatus';
import { useEffect } from 'react';

export const useLocationResultEffect = (
  locationResult: ReloadableResultStatus<
    // eslint-disable-next-line camelcase
    { onsite_addresses: { onsite_address: string }[] },
    unknown
  >,
  setLocations: (
    locations: Array<{
      // eslint-disable-next-line camelcase
      onsite_address: string;
    }>,
  ) => void,
  setInputLocation: (location: string) => void,
  // eslint-disable-next-line camelcase
  setSelectedLocation: (location: { onsite_address: string }) => void,
  // eslint-disable-next-line camelcase
  emptyLocation: { onsite_address: string },
  inputLocation: string,
  isAddingLocationFromInput: boolean,
) => {
  useEffect(() => {
    if (locationResult.status === 'ready') {
      if (locationResult.data.onsite_addresses.length === 1) {
        if (
          locationResult.data.onsite_addresses[0].onsite_address !==
            inputLocation &&
          !isAddingLocationFromInput
        ) {
          setLocations([
            ...locationResult.data.onsite_addresses,
            { onsite_address: inputLocation },
          ]);
        } else {
          setLocations(locationResult.data.onsite_addresses);
        }
        if (inputLocation.length === 0 && !isAddingLocationFromInput) {
          setInputLocation(
            locationResult.data.onsite_addresses[0].onsite_address,
          );
          setSelectedLocation(emptyLocation);
        }
      } else {
        setLocations(locationResult.data.onsite_addresses);
        setInputLocation(inputLocation);
        setSelectedLocation(emptyLocation);
      }
    }
  }, [
    locationResult,
    setLocations,
    setInputLocation,
    setSelectedLocation,
    emptyLocation,
    inputLocation,
    isAddingLocationFromInput,
  ]);
};
