import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  dayDelay: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.MessageRow.dayDelay`,
    defaultMessage: 'Delay (days)',
  },
  delayInput: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.MessageRow.delayInput`,
    defaultMessage: 'Delay Input',
  },
  delete: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.MessageRow.delete`,
    defaultMessage: 'Delete',
  },
  deleteMessage: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.MessageRow.deleteMessage`,
    defaultMessage: 'Delete Message',
  },
  deleteMessageConfirmationText: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.MessageRow.deleteMessageConfirmationText`,
    defaultMessage: `Are you sure you want to delete the {friendlyMessageType}?`,
  },
  deliveryMethod: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.MessageRow.deliveryMethod`,
    defaultMessage: 'Delivery Method',
  },
  deliveryMethodBoth: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.MessageRow.deliveryMethodBoth`,
    defaultMessage: 'Text + Email',
  },
  deliveryMethodSms: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.MessageRow.deliveryMethodSms`,
    defaultMessage: 'Text',
  },
  disable: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.MessageRow.disable`,
    defaultMessage: 'Disable',
  },
  edit: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.MessageRow.edit`,
    defaultMessage: 'Edit',
  },
  email: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.MessageRow.email`,
    defaultMessage: 'Email',
  },
  hourDelay: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.MessageRow.hourDelay`,
    defaultMessage: 'Delay (hours)',
  },
  messageTitle: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.MessageRow.messageTitle`,
    defaultMessage: 'Message Title',
  },
  minDelay: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.MessageRow.minDelay`,
    defaultMessage: 'Delay (mins)',
  },
  minPrior: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.MessageRow.minPrior`,
    defaultMessage: 'Minutes Prior',
  },
  reason: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.MessageRow.reason`,
    defaultMessage: 'Reason',
  },
  cancel: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.MessageRow.cancel`,
    defaultMessage: 'Cancel',
  },
  linkedToTemplate: {
    id: `app.containers.WorkflowEditor.components.StageSettingMessageCard.MessageRow.linkedToTemplate`,
    defaultMessage: 'Linked to Template',
  },
});
