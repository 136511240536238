// Initial Message Types
export const ARCHIVED = 'archived';
export const ASSESSMENT = 'assessment';
export const AUTOMATED = 'automated';
export const AVAILABILITY = 'availability';
export const AVAILABILITY_TEMPLATE = 'availability_template';
export const CANCELLATION = 'cancellation';
export const CANCELLATION_TEMPLATE = 'cancellation_template';
export const CANCELLATION_V2 = 'cancellation_v2';
export const CONFIRMATION = 'confirmation';
export const CONFIRMATION_TEMPLATE = 'confirmation_template';
export const CONSENT = 'consent';
export const DATA_COLLECTION = 'data_collection';
export const DOCUMENT_SIGNING = 'document_signing';
export const HIRED = 'hired';
export const I9FORM = 'i9_form';
export const JOB_SELECTOR = 'job_selector';
export const JOB_SWITCHER = 'job_switcher';
export const LANDING_TEMPLATE = 'landing_template';
export const LEARNING = 'learning';
export const MULTI_STAGE = 'multi_stage';
export const REASON_SETTINGS = 'reason_settings';
export const REJECTION = 'rejection';
export const REMINDER = 'reminder';
export const REMINDER_SETTINGS = 'reminder_settings';
export const SCHEDULER = 'scheduler';
export const SCHEDULER_V2 = 'scheduler_v2';
export const SCHOOLKEEP = 'schoolkeep';
export const TECH_CHECK = 'tech_check';
export const TRIGGER = 'trigger';
export const TRIGGER_SETTINGS = 'trigger_settings';
export const VIDEO_RECORDING = 'video_recording';
export const W4_FEDERAL_FORM = 'w4_federal_form';
export const W4_STATE_FORM = 'w4_state_form';

export const IDLE_DAYS = 'idle_days';
export const MINUTES_TO_NOTIFY = 'minutes_to_notify';
export const DELAY_HOURS = 'delay_hours';
export const DELAY_MINUTES = 'delay_minutes';

export type MessageTypes =
  | typeof ARCHIVED
  | typeof ASSESSMENT
  | typeof AUTOMATED
  | typeof AVAILABILITY
  | typeof CANCELLATION
  | typeof CANCELLATION_V2
  | typeof CONFIRMATION
  | typeof CONSENT
  | typeof DATA_COLLECTION
  | typeof DOCUMENT_SIGNING
  | typeof HIRED
  | typeof I9FORM
  | typeof JOB_SELECTOR
  | typeof JOB_SWITCHER
  | typeof MULTI_STAGE
  | typeof REJECTION
  | typeof REMINDER
  | typeof SCHEDULER
  | typeof SCHEDULER_V2
  | typeof TRIGGER
  | typeof SCHOOLKEEP
  | typeof TECH_CHECK
  | typeof VIDEO_RECORDING
  | typeof LEARNING
  | typeof W4_FEDERAL_FORM
  | typeof W4_STATE_FORM;

type SettingTypeKeys =
  | typeof REASON_SETTINGS
  | typeof TRIGGER_SETTINGS
  | typeof REMINDER_SETTINGS
  | null;

type TemplateTypeKeys =
  | typeof LANDING_TEMPLATE
  | typeof CONFIRMATION_TEMPLATE
  | typeof CANCELLATION_TEMPLATE
  | typeof AVAILABILITY_TEMPLATE
  | null;

type DelayTypeKeys =
  | typeof IDLE_DAYS
  | typeof MINUTES_TO_NOTIFY
  | typeof DELAY_HOURS
  | typeof DELAY_MINUTES
  | null;

type SettingAndTemplateTypeKeys =
  | typeof REASON_SETTINGS
  | typeof TRIGGER_SETTINGS
  | typeof REMINDER_SETTINGS
  | typeof LANDING_TEMPLATE
  | typeof AVAILABILITY_TEMPLATE
  | typeof CONFIRMATION_TEMPLATE
  | typeof CANCELLATION_TEMPLATE;

export const messageTypesThatAllowAddingMoreMessages: Record<
  MessageTypes,
  boolean
> = {
  [ARCHIVED]: true,
  [ASSESSMENT]: false,
  [AVAILABILITY]: false,
  [AUTOMATED]: false,
  [CANCELLATION]: false,
  [CANCELLATION_V2]: false,
  [CONFIRMATION]: false,
  [CONSENT]: false,
  [DATA_COLLECTION]: false,
  [DOCUMENT_SIGNING]: false,
  [HIRED]: false,
  [JOB_SELECTOR]: false,
  [JOB_SWITCHER]: false,
  [I9FORM]: false,
  [MULTI_STAGE]: false,
  [REJECTION]: true,
  [REMINDER]: true,
  [SCHEDULER]: false,
  [SCHEDULER_V2]: false,
  [TRIGGER]: true,
  [SCHOOLKEEP]: false,
  [LEARNING]: false,
  [TECH_CHECK]: false,
  [VIDEO_RECORDING]: false,
  [W4_FEDERAL_FORM]: false,
  [W4_STATE_FORM]: false,
};

export const allowDeleteByMessageType: Record<MessageTypes, boolean> = {
  [ARCHIVED]: true,
  [ASSESSMENT]: false,
  [AUTOMATED]: true,
  [AVAILABILITY]: true,
  [CANCELLATION]: false,
  [CANCELLATION_V2]: false,
  [CONFIRMATION]: false,
  [CONSENT]: false,
  [DATA_COLLECTION]: false,
  [DOCUMENT_SIGNING]: true,
  [HIRED]: true,
  [I9FORM]: false,
  [JOB_SELECTOR]: true,
  [JOB_SWITCHER]: true,
  [MULTI_STAGE]: false,
  [REJECTION]: true,
  [REMINDER]: true,
  [SCHEDULER]: false,
  [SCHEDULER_V2]: false,
  [TRIGGER]: true,
  [SCHOOLKEEP]: false,
  [TECH_CHECK]: false,
  [VIDEO_RECORDING]: true,
  [LEARNING]: false,
  [W4_FEDERAL_FORM]: false,
  [W4_STATE_FORM]: false,
};

export const stageSettingsKeyByMessageType: Record<
  MessageTypes,
  SettingTypeKeys
> = {
  [ARCHIVED]: REASON_SETTINGS,
  [ASSESSMENT]: null,
  [AUTOMATED]: null,
  [AVAILABILITY]: null,
  [CANCELLATION]: null,
  [CANCELLATION_V2]: null,
  [CONFIRMATION]: null,
  [CONSENT]: null,
  [DATA_COLLECTION]: null,
  [DOCUMENT_SIGNING]: null,
  [HIRED]: null,
  [I9FORM]: null,
  [JOB_SELECTOR]: null,
  [JOB_SWITCHER]: null,
  [MULTI_STAGE]: null,
  [REJECTION]: REASON_SETTINGS,
  [REMINDER]: REMINDER_SETTINGS,
  [SCHEDULER]: null,
  [SCHEDULER_V2]: null,
  [TECH_CHECK]: null,
  [VIDEO_RECORDING]: null,
  [TRIGGER]: TRIGGER_SETTINGS,
  [SCHOOLKEEP]: null,
  [LEARNING]: null,
  [W4_FEDERAL_FORM]: null,
  [W4_STATE_FORM]: null,
};

export const stageMessageKeyByMessageType: Record<
  MessageTypes,
  TemplateTypeKeys
> = {
  [ARCHIVED]: null,
  [ASSESSMENT]: LANDING_TEMPLATE,
  [AUTOMATED]: LANDING_TEMPLATE,
  [AVAILABILITY]: AVAILABILITY_TEMPLATE,
  [CANCELLATION]: CANCELLATION_TEMPLATE,
  [CANCELLATION_V2]: CANCELLATION_TEMPLATE,
  [CONFIRMATION]: CONFIRMATION_TEMPLATE,
  [CONSENT]: LANDING_TEMPLATE,
  [DATA_COLLECTION]: LANDING_TEMPLATE,
  [DOCUMENT_SIGNING]: LANDING_TEMPLATE,
  [HIRED]: LANDING_TEMPLATE,
  [I9FORM]: LANDING_TEMPLATE,
  [JOB_SELECTOR]: LANDING_TEMPLATE,
  [JOB_SWITCHER]: LANDING_TEMPLATE,
  [MULTI_STAGE]: LANDING_TEMPLATE,
  [REJECTION]: null,
  [REMINDER]: null,
  [SCHEDULER]: LANDING_TEMPLATE,
  [SCHEDULER_V2]: LANDING_TEMPLATE,
  [TECH_CHECK]: LANDING_TEMPLATE,
  [VIDEO_RECORDING]: LANDING_TEMPLATE,
  [TRIGGER]: null,
  [SCHOOLKEEP]: LANDING_TEMPLATE,
  [LEARNING]: LANDING_TEMPLATE,
  [W4_FEDERAL_FORM]: LANDING_TEMPLATE,
  [W4_STATE_FORM]: LANDING_TEMPLATE,
};

export const messageTypesWithDelayCell: Record<MessageTypes, boolean> = {
  [ARCHIVED]: true,
  [ASSESSMENT]: true,
  [AUTOMATED]: true,
  [AVAILABILITY]: false,
  [CANCELLATION]: false,
  [CANCELLATION_V2]: false,
  [CONFIRMATION]: false,
  [CONSENT]: true,
  [DATA_COLLECTION]: true,
  [DOCUMENT_SIGNING]: true,
  [HIRED]: true,
  [I9FORM]: true,
  [JOB_SELECTOR]: true,
  [JOB_SWITCHER]: true,
  [MULTI_STAGE]: true,
  [REJECTION]: true,
  [REMINDER]: true,
  [SCHEDULER]: true,
  [SCHEDULER_V2]: true,
  [TECH_CHECK]: true,
  [VIDEO_RECORDING]: true,
  [TRIGGER]: true,
  [SCHOOLKEEP]: true,
  [LEARNING]: true,
  [W4_FEDERAL_FORM]: true,
  [W4_STATE_FORM]: true,
};

export const settingAndTemplateTypesByMessageType: Record<
  MessageTypes,
  | typeof REASON_SETTINGS
  | typeof LANDING_TEMPLATE
  | typeof REMINDER_SETTINGS
  | typeof TRIGGER_SETTINGS
  | typeof AVAILABILITY_TEMPLATE
  | typeof CONFIRMATION_TEMPLATE
  | typeof CANCELLATION_TEMPLATE
> = {
  [ARCHIVED]: REASON_SETTINGS,
  [ASSESSMENT]: LANDING_TEMPLATE,
  [AUTOMATED]: LANDING_TEMPLATE,
  [AVAILABILITY]: AVAILABILITY_TEMPLATE,
  [CANCELLATION]: CANCELLATION_TEMPLATE,
  [CANCELLATION_V2]: CANCELLATION_TEMPLATE,
  [CONFIRMATION]: CONFIRMATION_TEMPLATE,
  [CONSENT]: LANDING_TEMPLATE,
  [DATA_COLLECTION]: LANDING_TEMPLATE,
  [DOCUMENT_SIGNING]: LANDING_TEMPLATE,
  [HIRED]: LANDING_TEMPLATE,
  [I9FORM]: LANDING_TEMPLATE,
  [JOB_SELECTOR]: LANDING_TEMPLATE,
  [JOB_SWITCHER]: LANDING_TEMPLATE,
  [MULTI_STAGE]: LANDING_TEMPLATE,
  [REJECTION]: REASON_SETTINGS,
  [REMINDER]: REMINDER_SETTINGS,
  [SCHEDULER]: LANDING_TEMPLATE,
  [SCHEDULER_V2]: LANDING_TEMPLATE,
  [TECH_CHECK]: LANDING_TEMPLATE,
  [VIDEO_RECORDING]: LANDING_TEMPLATE,
  [TRIGGER]: TRIGGER_SETTINGS,
  [SCHOOLKEEP]: LANDING_TEMPLATE,
  [LEARNING]: LANDING_TEMPLATE,
  [W4_FEDERAL_FORM]: LANDING_TEMPLATE,
  [W4_STATE_FORM]: LANDING_TEMPLATE,
};

export const delayBySettingType: Record<
  SettingAndTemplateTypeKeys,
  DelayTypeKeys
> = {
  [REASON_SETTINGS]: DELAY_HOURS,
  [LANDING_TEMPLATE]: DELAY_MINUTES,
  [REMINDER_SETTINGS]: MINUTES_TO_NOTIFY,
  [TRIGGER_SETTINGS]: IDLE_DAYS,
  [CONFIRMATION_TEMPLATE]: null,
  [CANCELLATION_TEMPLATE]: null,
  [AVAILABILITY_TEMPLATE]: null,
};
