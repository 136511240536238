import { CustomTooltip } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';
import React, { VFC } from 'react';
import { useIntl } from 'react-intl';

import { BotAvatar } from './BotAvatar';
import { messages } from './messages';
import { useStyles } from './styles';

export interface BotMessageProps {
  message: string;
}

export const BotMessage: VFC<BotMessageProps> = ({ message }) => {
  const styles = useStyles();
  const intl = useIntl();

  return (
    <Grid item container direction="column" alignItems="flex-end">
      <Grid item container direction="row" justify="flex-end" wrap="nowrap">
        <Grid
          item
          className={classNames(styles.messageBubble, styles.botBubble)}
        >
          <Typography
            className={classNames(styles.botMessage, styles.messageContent)}
          >
            {message}
          </Typography>
        </Grid>
        <Grid item className={styles.avatar}>
          <CustomTooltip
            title={intl.formatMessage(messages.botAvatarTooltip)}
            dense
          >
            <BotAvatar />
          </CustomTooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};
