import { EventAvailableSlot } from 'api-clients/monolith';
import { useEffect } from 'react';

export const useTitlesEffect = (
  availableSlot: EventAvailableSlot,
  setStageTitles: (titles: string[]) => void,
  setOpeningTitles: (titles: string[]) => void,
  setLocationGroupTitles: (titles: string[]) => void,
) => {
  useEffect(() => {
    setStageTitles(availableSlot?.stage_titles ?? []);
  }, [availableSlot.stage_titles, setStageTitles]);

  useEffect(() => {
    setOpeningTitles(availableSlot?.opening_titles ?? []);
  }, [availableSlot.opening_titles, setOpeningTitles]);

  useEffect(() => {
    setLocationGroupTitles(availableSlot?.location_group_titles ?? []);
  }, [availableSlot.location_group_titles, setLocationGroupTitles]);
};
