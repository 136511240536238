import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addDocument: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Documents.addDocument`,
    defaultMessage: 'Add Document',
  },
  remove: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Documents.remove`,
    defaultMessage: 'Remove',
  },
  removeDocument: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Documents.removeDocument`,
    defaultMessage: 'Remove Document',
  },
  removeDocumentConfirmationText: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Documents.removeDocumentConfirmationText`,
    defaultMessage: `Are you sure you want to remove the "{title}" document?`,
  },
  documents: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Documents.documents`,
    defaultMessage: 'Documents',
  },
  noDocuments: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Documents.noDocuments`,
    defaultMessage: 'No documents added yet.',
  },
  noDocumentsError: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Documents.noDocumentsError`,
    defaultMessage:
      'At least one document is required for this stage to work, please add.',
  },
  templateTitleMissing: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Documents.templateTitleMissing`,
    defaultMessage: 'Template title missing',
  },
  uploadDocumentTemplates: {
    id: `app.containers.WorkflowEditor.components.StageDetail.DocumentSigningStage.Documents.uploadDocumentTemplates`,
    defaultMessage:
      'Upload document templates in <link>company settings</link> to use in this stage.',
  },
});
