import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';

import { BackToCompanySettings } from 'components/BackToCompanySettings';

import { Dashboard } from './Dashboard';
import { messages } from './messages';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    maxWidth: '808px',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(0, 10, 0, 10),
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    padding: theme.spacing(2, 0, 2),
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

export const WidgetAnalytics = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage(messages.analytics)}</title>
        <meta name="description" content="Widget Insights" />
      </Helmet>
      <div className={classes.container}>
        <header className={classes.header}>
          <BackToCompanySettings />
          <Typography display="inline" variant="h2">
            <FormattedMessage {...messages.analytics} />
          </Typography>
        </header>
        <Dashboard />
      </div>
    </>
  );
};
