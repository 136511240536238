import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  circularProgressbar: {
    height: 16,
    width: 27.45,
  },
}));

export default useStyles;
