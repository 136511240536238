import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  applicantAnswer: {
    paddingTop: theme.spacing(0.125),
    '&.Mui-checked': {
      color: theme.palette.common.blue400,
    },
  },
  container: {
    marginTop: theme.spacing(2.5),
  },
  otherAnswer: {
    paddingTop: theme.spacing(0.125),
  },
  position: {
    width: theme.spacing(1.5),
  },
  questionContainer: {
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
  questionText: {
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  radioOption: {
    alignItems: 'flex-start',
    marginLeft: theme.spacing(-0.75),
    marginBottom: theme.spacing(1),
    '& svg': {
      height: theme.spacing(2),
    },
  },
  applicantAnswerValueHint: {
    marginLeft: theme.spacing(1),
  },
}));
