/* eslint-disable no-underscore-dangle */
import { Alert, Button } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import {
  DocumentSignatureStagesTemplateSetting,
  DocumentSigningStagesTemplateSetting,
} from 'api-clients/monolith';
import { useSimpleToggle } from 'hooks';
import produce from 'immer';
import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';

import { AddItemRows } from 'containers/WorkflowEditor/components/AddItemRows';
import { ItemRow } from 'containers/WorkflowEditor/components/AddItemRows/ItemRow';
import { RowTag } from 'containers/WorkflowEditor/components/Rules/RowTag';
import { DEFAULT_DOCUMENT } from 'containers/WorkflowEditor/components/WorkflowEditorContent/StageDetail/DocumentSigningStage/Documents/constants';
import { DocumentModal } from 'containers/WorkflowEditor/components/WorkflowEditorContent/StageDetail/DocumentSigningStage/Documents/DocumentModal/DocumentModal';
import { messages as documentsMessages } from 'containers/WorkflowEditor/components/WorkflowEditorContent/StageDetail/DocumentSigningStage/Documents/messages';

import {
  DocumentSigningRuleProps,
  SharedTemplateSettingsProps,
} from '../types';
import { messages } from './messages';
import { useStyles } from './styles';

export const TemplateSettings: FC<SharedTemplateSettingsProps> = ({
  availableEmbeddedSignatureTemplates,
  errors,
  ruleId,
  setDocumentSigningRules,
  setStage,
  stage,
  templateSettings,
}) => {
  const intl = useIntl();
  const styles = useStyles();

  const [selectedTemplateForModal, setSelectedTemplateForModal] = useState<
    | DocumentSigningStagesTemplateSetting
    | DocumentSignatureStagesTemplateSetting
  >();
  const {
    showContent: isModalOpen,
    on: openModal,
    off: closeModal,
  } = useSimpleToggle();

  const availableTemplates =
    availableEmbeddedSignatureTemplates?.filter(
      temp =>
        // Filter current templateSettings
        !templateSettings.some(
          selected =>
            selected.template_id ===
            (temp.document_signature_template_id ?? temp.id),
        ) ||
        // Add back selectedTemplate if editing
        selectedTemplateForModal?.template_id ===
          (temp.document_signature_template_id ?? temp.id),
    ) ?? [];

  const maybeEmbeddedSignatureTemplateStages =
    stage.type === 'DocumentSigningStage'
      ? stage.additional_info.embedded_signature_templates_stages_attributes
      : undefined;
  const maybeAccountUsers =
    stage.type === 'DocumentSigningStage'
      ? stage.additional_info.account_users
      : undefined;

  const handleEditDocument =
    (
      template:
        | DocumentSignatureStagesTemplateSetting
        | DocumentSigningStagesTemplateSetting,
    ) =>
    () => {
      setSelectedTemplateForModal(template);
      openModal();
    };

  const handleAddDocument = () => {
    setSelectedTemplateForModal(DEFAULT_DOCUMENT);
    openModal();
  };

  const handleCloseDocumentModal = () => {
    setSelectedTemplateForModal(undefined);
    closeModal();
  };

  const handleDeleteDocument = (id: string | number) => {
    setDocumentSigningRules(
      produce((draftRules: DocumentSigningRuleProps) => {
        const draftTemplateSettings = (
          (draftRules[ruleId] || {}).template_set_attributes ?? {}
        ).template_settings_attributes;

        if (draftTemplateSettings) {
          const idx = draftTemplateSettings.findIndex(
            ts => ts.template_id === id && !ts._destroy,
          );

          draftTemplateSettings[idx]._destroy = true;
        }
      }),
    );

    // Checking that it's not in the default documents
    const isDefaultTemplateIdAlreadyUsed =
      stage.additional_info.else_template_set_attributes?.template_settings_attributes?.some(
        setting => !setting._destroy && setting.template_id === id,
      );

    if (
      stage.type === 'DocumentSigningStage' &&
      !isDefaultTemplateIdAlreadyUsed
    ) {
      setStage(
        produce(stage, draftStage => {
          if (
            draftStage.additional_info
              .embedded_signature_templates_stages_attributes
          ) {
            const draftEmbeddedSignatureTemplatesStages =
              draftStage.additional_info
                .embedded_signature_templates_stages_attributes;

            const idx = draftEmbeddedSignatureTemplatesStages.findIndex(
              ests => ests.embedded_signature_template_id === id,
            );

            if (draftEmbeddedSignatureTemplatesStages[idx]) {
              draftEmbeddedSignatureTemplatesStages[idx]._destroy = true;
            }
          }
        }),
      );
    }
  };

  const templateError =
    (errors &&
      errors['template_rule_set.rules.template_set.template_settings.template']
        ?.length > 0 &&
      errors[
        'template_rule_set.rules.template_set.template_settings.template'
      ].join(', ')) ||
    undefined;

  return (
    <Grid container className={styles.actionRow}>
      <Grid
        container
        justify="space-between"
        wrap="nowrap"
        alignItems="center"
        className={styles.tagContainer}
      >
        <RowTag
          classes={{ tag: styles.rowTag }}
          label={intl.formatMessage(messages.action)}
        />
      </Grid>
      {templateError && (
        <Alert fullWidth type="danger" className={styles.errorsAlert}>
          {/* TODO: this needs to be translated! */}
          <span>{templateError}</span>
        </Alert>
      )}
      <AddItemRows
        className={styles.addItemRows}
        emptyContentDescription={intl.formatMessage(
          documentsMessages.noDocuments,
        )}
        isEmpty={templateSettings.length === 0}
        buttonPlacement="inside"
        addButton={
          <Button
            type="secondary"
            size="small"
            autoWidth
            className={styles.addButton}
            onClick={handleAddDocument}
            disabled={
              availableTemplates.length === 0 && templateSettings.length > 0
            }
          >
            <FormattedMessage {...documentsMessages.addDocument} />
          </Button>
        }
        errorText={intl.formatMessage(documentsMessages.noDocumentsError)}
      >
        <Grid container direction="column">
          {templateSettings.map(template => {
            const {
              embedded_signature_template_title: title,
              template_id: templateId,
              document_signature_template_id: documentSignatureTemplateId,
            } = template;

            return (
              <Grid className={styles.row} key={templateId}>
                <ItemRow
                  title={
                    title ??
                    intl.formatMessage(documentsMessages.templateTitleMissing)
                  }
                  id={documentSignatureTemplateId ?? templateId ?? 0}
                  key={`${templateId ?? ''}`}
                  onDelete={handleDeleteDocument}
                  onEdit={handleEditDocument(template)}
                  confirmDeleteModalTitle={intl.formatMessage(
                    documentsMessages.removeDocument,
                  )}
                  confirmDeleteModalDescription={intl.formatMessage(
                    documentsMessages.removeDocumentConfirmationText,
                    {
                      title:
                        title ??
                        intl.formatMessage(
                          documentsMessages.templateTitleMissing,
                        ),
                    },
                  )}
                />
              </Grid>
            );
          })}
        </Grid>
      </AddItemRows>
      {isModalOpen && (
        <DocumentModal
          onClose={handleCloseDocumentModal}
          ruleId={ruleId}
          availableTemplates={availableTemplates}
          existingTemplate={selectedTemplateForModal}
          embeddedSignatureTemplateStages={maybeEmbeddedSignatureTemplateStages}
          accountUsers={maybeAccountUsers}
          setStage={setStage}
          setDocumentSigningRules={setDocumentSigningRules}
          key={
            selectedTemplateForModal?.embedded_signature_template_title ??
            uuid()
          }
        />
      )}
    </Grid>
  );
};
