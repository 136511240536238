import { Button } from '@fountain/fountain-ui-components';
import {
  Checkbox,
  Grid,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {
  attendButtons,
  RosterApplicant,
  rowActions,
} from 'Calendar/EventManager/ManageRoster/util';
import { messages } from 'Calendar/EventManager/messages';
import cx from 'classnames';
import React, { VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { QuickActions } from 'containers/QuickActions';
import useUserPermissions from 'hooks/useUserPermissions';

import { useStyles } from './styles';

export interface MobileRowProps {
  applicant: RosterApplicant;
  check: (applicant: RosterApplicant) => void;
  perform: (action: rowActions, applicant: RosterApplicant) => void;
}
export const MobileRow: VFC<MobileRowProps> = ({
  applicant,
  check,
  perform,
}) => {
  const styles = useStyles();
  const { manageApplicantsPermission: canManageApplicants } =
    useUserPermissions();

  return (
    <>
      <TableRow>
        <TableCell>
          <Grid container>
            <Checkbox
              checked={applicant.checked}
              onClick={() => check(applicant)}
            />
          </Grid>
        </TableCell>
        <TableCell className={styles.mobileContentColumn}>
          <Grid container direction="column">
            <Grid item>
              <Button
                type="text"
                disableUnderline
                autoWidth
                onClick={() => perform('open', applicant)}
                className={styles.disableButtonUnderline}
              >
                <Typography className={cx(styles.neverflow, styles.nameCutoff)}>
                  {applicant.name}
                </Typography>
              </Button>
            </Grid>
            <Grid item>
              <a
                href={`tel:${applicant.phone_number ?? ''}`}
                className={styles.phone}
              >
                <Typography>{applicant.phone_number}</Typography>
              </a>
            </Grid>
          </Grid>
          {canManageApplicants && (
            <span className={styles.autoMargin}>
              <QuickActions
                handleClickNextStage={() => perform('advance', applicant)}
                handleClickReject={() => perform('reject', applicant)}
                variant="icon"
              />
            </span>
          )}
        </TableCell>
      </TableRow>
      <TableRow className={styles.rosterRow}>
        <TableCell></TableCell>
        <TableCell className={styles.mobileContentColumn}>
          <Grid container direction="column">
            <Grid item>
              <Typography className={styles.neverflow}>
                {applicant.funnel?.title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={styles.neverflow}>
                {applicant.stage ? applicant.stage.name : ''}
              </Typography>
            </Grid>
          </Grid>
          <span className={styles.autoMargin}>
            <ButtonGroup>
              {attendButtons.map(value => (
                <Button
                  variant="text"
                  size="small"
                  key={value}
                  type={applicant.attended === value ? 'primary' : 'secondary'}
                  onClick={() =>
                    perform(value === 'Yes' ? 'attended' : 'absent', applicant)
                  }
                  className={styles.attendButton}
                >
                  <FormattedMessage {...messages[value]} />
                </Button>
              ))}
            </ButtonGroup>
          </span>
        </TableCell>
      </TableRow>
    </>
  );
};
