import { CircularProgress } from '@material-ui/core';
import React, { useState, VFC } from 'react';

import { useIphoneFrameStyles } from './styles';

interface IPhoneFrameProps {
  src: string;
}

export const IPhoneFrame: VFC<IPhoneFrameProps> = ({ src }) => {
  const classes = useIphoneFrameStyles();
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <div className={classes.iphone}>
      <div className={classes.topNotch}></div>
      {loading && (
        <div className={classes.loader}>
          <CircularProgress color="inherit" />
        </div>
      )}
      <iframe
        src={src}
        title="iPhone Preview"
        className={classes.screen}
        onLoad={handleLoad}
        style={{
          display: loading ? 'none' : 'block',
          height: 'calc(100% - 40px)',
        }}
      />
    </div>
  );
};
