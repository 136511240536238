import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  allSessionsAffected: {
    id: 'app.Calendar.CreateEvent.UpdateModeConfirmationModal.allSessionsAffected',
    defaultMessage:
      'There are {bookedSessions}/{totalSessions} session(s) in the series that are booked.\n<b>All future sessions in this series</b> will be {negative, select, true {deleted} other {affected}}.',
    values: {
      bookedSessions: {
        id: 'app.Calendar.CreateEvent.UpdateModeConfirmationModal.bookedSessions',
        defaultMessage: '0',
        description: 'Number of booked sessions in the series',
      },
      totalSessions: {
        id: 'app.Calendar.CreateEvent.UpdateModeConfirmationModal.totalSessions',
        defaultMessage: '0',
        description: 'Total number of sessions in the series',
      },
      negative: {
        id: 'app.Calendar.CreateEvent.UpdateModeConfirmationModal.negative',
        defaultMessage: 'false',
        description: 'Whether it is deleting or updating',
      },
    },
  },
  onlyFutureUnbookedSessionsAffected: {
    id: 'app.Calendar.CreateEvent.UpdateModeConfirmationModal.onlyFutureUnbookedSessionsAffected',
    defaultMessage:
      '<b>Only future unbooked sessions in this series (recommended)</b>\nSessions that’s been booked by applicants will not be affected.',
  },
  allFutureSessionsIncludingBookedAffected: {
    id: 'app.Calendar.CreateEvent.UpdateModeConfirmationModal.allFutureSessionsAffected',
    defaultMessage:
      '<b>All future sessions in this series</b>\nAll sessions including <b>{bookedSessions} session(s)</b> that’s been booked by <b>{totalApplicants} applicant(s)</b> will be affected.',
    values: {
      bookedSessions: {
        id: 'app.Calendar.CreateEvent.UpdateModeConfirmationModal.bookedSessions',
        defaultMessage: '0',
        description: 'Number of booked sessions in the series',
      },
      totalApplicants: {
        id: 'app.Calendar.CreateEvent.UpdateModeConfirmationModal.totalApplicants',
        defaultMessage: '0',
        description: 'Total number of applicants in the series',
      },
    },
  },
  fetchBookingsError: {
    id: 'app.Calendar.CreateEvent.UpdateModeConfirmationModal.fetchBookingsError',
    defaultMessage: 'Failed to fetch booked slots information',
  },
  confirmDelete: {
    id: 'app.Calendar.CreateEvent.UpdateModeConfirmationModal.confirmDelete',
    defaultMessage: 'Are you sure you want to delete this series?',
  },
  confirmEditButton: {
    id: 'app.Calendar.CreateEvent.UpdateModeConfirmationModal.confirmEditButton',
    defaultMessage: 'Edit series',
  },
  confirmDeleteButton: {
    id: 'app.Calendar.CreateEvent.UpdateModeConfirmationModal.confirmDeleteButton',
    defaultMessage: 'Delete series',
  },
  cancel: {
    id: 'app.Calendar.CreateEvent.UpdateModeConfirmationModal.cancel',
    defaultMessage: 'Cancel',
  },
  editTitle: {
    id: 'app.Calendar.CreateEvent.UpdateModeConfirmationModal.editTitle',
    defaultMessage: 'Edit Series',
  },
  deleteTitle: {
    id: 'app.Calendar.CreateEvent.UpdateModeConfirmationModal.deleteTitle',
    defaultMessage: 'Delete Series',
  },
});
