import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  sms_bot_enabled: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BotPlatformToggleGroup.smsBotEnabled`,
    defaultMessage: 'SMS/WhatsApp',
  },
  widget_bot_enabled: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BotPlatformToggleGroup.widgetBotEnabled`,
    defaultMessage: 'Web Widget',
  },
  t2a_bot_enabled: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BotPlatformToggleGroup.t2aBotEnabled`,
    defaultMessage: 'Text to Apply',
  },
});
