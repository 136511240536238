import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { produce } from 'immer';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { getConditionErrorMessage } from 'containers/WorkflowEditor/components/Rules/Condition/constants';
import { messages } from 'containers/WorkflowEditor/components/Rules/Condition/messages';

import { DocumentSigningRuleProps } from '../../DocumentSigningRules/types';
import { RulesProps, SharedConditionProps } from '../../types';

export const DuplicateApplicant: FC<SharedConditionProps> = ({
  condition,
  ruleId,
  setRules,
  setDocumentSigningRules,
  errors,
}) => {
  const intl = useIntl();

  const { extra } = condition;

  const duplicateStatuses = [
    { label: intl.formatMessage(messages.isDuplicate), value: true },
    { label: intl.formatMessage(messages.isNotDuplicate), value: false },
  ];

  const selectedStatus =
    duplicateStatuses?.find(option => option.value === extra.duplicate) ?? null;

  const onChangeStatus = (newStatus: { label: string; value: boolean }) => {
    const rulesSetter = setRules ?? setDocumentSigningRules;

    if (rulesSetter) {
      rulesSetter(
        produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];
          const idx = draftConditions.findIndex(
            cond => cond.id === condition.id,
          );

          draftConditions[idx].extra.duplicate = newStatus.value;
        }),
      );
    }
  };

  const duplicateErrorMessage =
    extra?.duplicate === undefined &&
    getConditionErrorMessage('duplicate', errors);

  return (
    <Grid item xs={6}>
      <StyledReactSelect
        options={duplicateStatuses}
        label={intl.formatMessage(messages.status)}
        aria-label={intl.formatMessage(messages.status)}
        onChange={onChangeStatus}
        value={selectedStatus}
        error={duplicateErrorMessage}
        placeholder=""
        required
      />
    </Grid>
  );
};
