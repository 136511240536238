import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    marginLeft: theme.spacing(1.5),
  },
  stateDropdown: {
    marginLeft: theme.spacing(-1.5),
    marginBottom: theme.spacing(3),
  },
}));
