import { FileUploadButton } from '@fountain/fountain-ui-components';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

import FormikInput from 'components/FormikInput';

function AuthorizedAgentFields({
  values,
  setFieldValue,
  errors,
  setFieldError,
  schema,
}) {
  if (!values.authorized_agent) {
    return null;
  }

  return (
    <Box mt={3}>
      <Box mb={3}>
        <Typography variant="h2">Authorized Agent Form</Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormikInput
            name="authorized_agent.first_name"
            label="First Name"
            value={values.authorized_agent.first_name}
            error={
              errors.authorized_agent && errors.authorized_agent.first_name
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikInput
            name="authorized_agent.last_name"
            label="Last Name"
            value={values.authorized_agent.last_name}
            error={errors.authorized_agent && errors.authorized_agent.last_name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikInput
            name="authorized_agent.email"
            label="Email"
            value={values.authorized_agent.email}
            error={errors.authorized_agent && errors.authorized_agent.email}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikInput
            name="authorized_agent.relationship_with_consumer"
            label="Relationship"
            value={values.authorized_agent.relationship_with_consumer}
            error={
              errors.authorized_agent &&
              errors.authorized_agent.relationship_with_consumer
            }
          />
        </Grid>

        <Grid item>
          <Box mb={1}>
            <Typography variant="body2">Upload</Typography>
          </Box>
          <Box mb={2}>
            <Typography variant="body2" color="textPrimary">
              Please upload power of attorney or a signed form allowing you to
              submit the request on the consumer’s behalf
            </Typography>
          </Box>
          <FileUploadButton
            handleChange={e => {
              const fileUploadFieldName =
                'authorized_agent.proof_of_authorization_file';
              setFieldValue(fileUploadFieldName, e.target.files[0]);

              // TODO: Refactor. This is a temporary solution to validate this field onChange.
              // We want to be using `validateField` in formik but currently it doesn't work.
              // This is the only field we want to validate onChange before submit at the moment while
              // all other fields will validate after submit (then fields will validate onChange after first submit)
              const uploadValidation = schema.validateAt(fileUploadFieldName, {
                authorized_agent: {
                  proof_of_authorization_file: e.target.files[0],
                },
              });

              uploadValidation
                .then(() => setFieldError(fileUploadFieldName, null)) // Success
                .catch(err => setFieldError(fileUploadFieldName, err.errors)); // Failed
            }}
            fileType="png/*"
            fileName={
              values.authorized_agent.proof_of_authorization_file &&
              values.authorized_agent.proof_of_authorization_file.name
            }
            error={
              errors.authorized_agent &&
              errors.authorized_agent.proof_of_authorization_file
            }
          />
        </Grid>
      </Grid>

      <Box my={5}>
        <Divider />
      </Box>
    </Box>
  );
}

AuthorizedAgentFields.defaultProps = {
  errors: {},
};

AuthorizedAgentFields.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
  setFieldError: PropTypes.func,
  schema: PropTypes.object,
};

export default AuthorizedAgentFields;
