import { CustomTooltip, Input } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import {
  TechCheckStageOperatingSystem,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import { compare, validate } from 'compare-versions';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Error } from 'components/Error';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import {
  MAC_OS,
  SUPPORTED_OPERATING_SYSTEM,
  SUPPORTED_OPERATING_SYSTEM_LIST,
} from './constants';
import { messages } from './messages';
import { useStyles } from './styles';

export interface OperatingSystemsInputsProps {
  operatingSystems?: Array<TechCheckStageOperatingSystem>;
  settingId: number | string;
}

export const OperatingSystemsInputs: FC<OperatingSystemsInputsProps> =
  React.memo(({ operatingSystems, settingId }) => {
    const intl = useIntl();
    const styles = useStyles();

    const { updateStageResult, setStage } = useContext(StageContext);
    const errors =
      (updateStageResult?.isError && updateStageResult.error?.errors) ||
      undefined;

    const operatingSystemVersionsExist = operatingSystems?.some(
      os => os.version,
    );

    const onChangeOperatingSystemVersion =
      (type: SUPPORTED_OPERATING_SYSTEM) =>
      (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = event.target;
        setStage(
          produce((draftStage: WorkflowStageDetail) => {
            if (
              draftStage.type === 'TechCheckStage' &&
              draftStage.additional_info?.settings
            ) {
              const draftSetting = draftStage.additional_info.settings.find(
                s => s.id === settingId,
              );
              if (draftSetting) {
                if (draftSetting.operating_systems_info) {
                  const { operating_systems: operatingSystems = [] } =
                    draftSetting.operating_systems_info;
                  const operatingSystem = operatingSystems.find(
                    os => os.name === type,
                  );
                  if (operatingSystem) {
                    operatingSystem.version = value;
                  } else {
                    operatingSystems.push({ name: type, version: value });
                  }
                } else {
                  draftSetting.operating_systems_info = {
                    operating_systems: [{ name: type, version: value }],
                  };
                }
              }
            }
          }),
        );
      };

    return (
      <>
        <Grid>
          <Typography variant="body2" className={styles.heading}>
            <FormattedMessage {...messages.operatingSystemHeading} />{' '}
            <span className={styles.requiredAsterix}>*</span>
            <Error
              error={
                !operatingSystemVersionsExist &&
                Boolean(errors?.['settings.operating_systems_info']) &&
                intl.formatMessage(messages.operatingSystemVersionMustExist)
              }
            />
          </Typography>
        </Grid>
        <Grid>
          {SUPPORTED_OPERATING_SYSTEM_LIST.map(osType => {
            const os = operatingSystems?.find(os => os.name === osType);
            const inputLabel =
              osType === MAC_OS ? intl.formatMessage(messages.macOS) : osType;
            const validOSVersion = Boolean(os?.version && validate(os.version));
            /*
              DB values for operating_systems_info are stored in a JSON format an require a different
              error structure to associate errors to the correct input
            */
            const errorMessageForType = (
              errors?.['settings.operating_systems_info']?.find(error => {
                const message = (error as unknown as Record<string, string>)?.[
                  osType
                ];
                return (
                  (!validOSVersion &&
                    Boolean(message) &&
                    !message?.includes('10.15.7')) ||
                  // Mac OS specifically has a maximum allow version that has it's own validation
                  (osType === MAC_OS &&
                    os?.version &&
                    validate(os.version) &&
                    compare(os.version, '10.15.7', '>') &&
                    Boolean(message?.includes('10.15.7')))
                );
              }) as unknown as Record<string, string>
            )?.[osType];

            return (
              <Grid
                key={`${settingId}-${osType}`}
                className={styles.browserRow}
              >
                <CustomTooltip
                  dense
                  title={
                    osType === MAC_OS
                      ? intl.formatMessage(messages.macOSTooltip)
                      : ''
                  }
                >
                  <div>
                    <Input
                      aria-label={inputLabel}
                      label={inputLabel}
                      value={os?.version}
                      onChange={onChangeOperatingSystemVersion(osType)}
                      error={
                        (!operatingSystemVersionsExist &&
                          Boolean(
                            errors?.['settings.operating_systems_info'],
                          )) ||
                        Boolean(os?.version && errorMessageForType)
                      }
                    />
                  </div>
                </CustomTooltip>
                <Error
                  error={
                    (!operatingSystemVersionsExist &&
                      Boolean(errors?.['settings.operating_systems_info']) &&
                      intl.formatMessage(messages.addVersion)) ||
                    (os?.version && errorMessageForType)
                  }
                  align="right"
                />
              </Grid>
            );
          })}
        </Grid>
      </>
    );
  });
