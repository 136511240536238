import { IconButton, Trashcan } from '@fountain/fountain-ui-components';
import {
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from '../messages';
import { useStyles } from '../styles';

export interface User {
  name?: string;
  id?: number;
  _destroy?: boolean;
  available?: boolean;
}

interface SchedulerUsersTableProps {
  users: User[];
  removeUser: (id?: number) => void;
  toggleUser?: (id?: number) => void;
}

export const SchedulerUsersTable: VFC<SchedulerUsersTableProps> = ({
  removeUser,
  toggleUser,
  users,
}) => {
  const styles = useStyles();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow className={styles.availableRow}>
            <TableCell
              classes={{ root: styles.tableHeaderRoot }}
              className={styles.availableColumn}
            >
              <Typography variant="h4" className={styles.tableHeader}>
                <FormattedMessage {...messages.available} />
              </Typography>
            </TableCell>
            <TableCell
              classes={{ root: styles.tableHeaderRoot }}
              className={styles.interviewerColumn}
            >
              <Typography variant="h4" className={styles.tableHeader}>
                <FormattedMessage {...messages.interviewer} />
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map(user => (
            <>
              {
                // eslint-disable-next-line no-underscore-dangle
                !user._destroy && (
                  <TableRow
                    className={styles.tableRow}
                    classes={{ root: styles.TableRow }}
                    key={user?.id}
                  >
                    {toggleUser && (
                      <TableCell
                        classes={{ root: styles.tableCellRoot }}
                        component="th"
                        scope="row"
                      >
                        <Switch
                          checked={user.available}
                          onClick={() => toggleUser(user.id)}
                        />
                      </TableCell>
                    )}
                    <TableCell classes={{ root: styles.tableCellRoot }}>
                      <Typography>{user.name}</Typography>
                    </TableCell>
                    <TableCell classes={{ root: styles.tableCellRoot }}>
                      <IconButton
                        onClick={() => removeUser(user.id)}
                        size="small"
                        data-testid="table-trashcan"
                      >
                        <Trashcan className={styles.icon} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              }
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
