import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addEmployerInformation: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.W4Stage.EmployerInformation.addEmployerInformation`,
    defaultMessage: 'Add Employer Information',
  },
  employerName: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.W4Stage.EmployerInformation.employerName`,
    defaultMessage: 'Employer Name',
  },
  city: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.W4Stage.EmployerInformation.city`,
    defaultMessage: 'City',
  },
  delete: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.W4Stage.EmployerInformation.delete`,
    defaultMessage: 'Delete',
  },
  employerInformation: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.W4Stage.EmployerInformation.employerInformation`,
    defaultMessage: 'Employer Information',
  },
  employerAddress: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.W4Stage.EmployerInformation.employerAddress`,
    defaultMessage: 'Employer Address',
  },
  employerAddress2: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.W4Stage.EmployerInformation.employerAddress2`,
    defaultMessage: 'Floor, Suite, PO Box, etc. (optional)',
  },
  state: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.W4Stage.EmployerInformation.state`,
    defaultMessage: 'State',
  },
  employerIdentificationNumber: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.W4Stage.EmployerInformation.employerIdentificationNumber`,
    defaultMessage: 'Employer Identification Number',
  },
  zipcode: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.W4Stage.EmployerInformation.zipcode`,
    defaultMessage: 'Zip',
  },
});
