import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  paddingBottom: {
    paddingBottom: theme.spacing(3),
  },
  paddingTop: {
    paddingTop: theme.spacing(3),
  },
  ruleCardContainer: {
    borderBottom: theme.customBorders.gray300,
  },
  documentSetHeader: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  trashcanIcon: {
    fontSize: theme.spacing(1.5),
  },
  logicButton: {
    marginLeft: theme.spacing(2),
  },
  documentSetTitleContainer: {
    display: 'flex',
  },
}));
