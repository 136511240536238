import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StageType, WorkflowBuilderFunnelStage } from 'api-clients/monolith';
import React from 'react';

import { StageIconByType } from 'containers/WorkflowEditor/components/StageColumn/StageCard/StageIconByType';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    padding: theme.spacing(1.5, 2),
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    background: `${theme.palette.common.white} !important`,
    border: `1px solid ${theme.palette.common.gray300}`,
    borderRadius: '4px',
    gap: '16px',
    textAlign: 'left',
  },
  title: {
    color: theme.palette.common.gray600,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 450,
    lineHeight: '20px',
    gap: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    color: theme.palette.common.gray800,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16px',
  },
}));

export interface CardProps {
  stage: WorkflowBuilderFunnelStage;
  handleClick: (stage: WorkflowBuilderFunnelStage) => void;
}

export const Card: React.VFC<CardProps> = ({ stage, handleClick }) => {
  const styles = useStyles();

  return (
    <button
      className={styles.container}
      type="button"
      style={{
        textDecoration: 'none',
        cursor: 'pointer',
        background: 'none',
      }}
      onClick={() => handleClick(stage)}
    >
      <Typography className={styles.title}>
        <StageIconByType type={stage.type as StageType} />
        <span>{stage.short_type}</span>
      </Typography>
      <Typography className={styles.header}>
        <span>{stage.title}</span>
      </Typography>
    </button>
  );
};
