import { WorkflowStageDetail } from 'api-clients/monolith';
import produce from 'immer';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { SettingSwitch } from '../../../SettingSwitch';
import { messages } from '../messages';

type AutoAdvanceWhenLabelsCompleteProps = {
  stage: WorkflowStageDetail;
  setStage: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
};

export const AutoAdvanceWhenLabelsComplete: FC<
  AutoAdvanceWhenLabelsCompleteProps
> = ({ stage, setStage }) => {
  const intl = useIntl();

  const onToggleShouldAdvanceWhenLabelsComplete = () => {
    setStage(
      produce(stage, draftStage => {
        draftStage.advanced_settings.should_auto_advance_when_labels_complete =
          !draftStage.advanced_settings
            .should_auto_advance_when_labels_complete;
      }),
    );
  };

  return (
    <SettingSwitch
      dataKey="auto_advance_when_labels_complete"
      checked={
        stage.advanced_settings.should_auto_advance_when_labels_complete ??
        false
      }
      onChange={onToggleShouldAdvanceWhenLabelsComplete}
      label={intl.formatMessage(messages.shouldAutoAdvanceWhenLabelsComplete)}
    />
  );
};
