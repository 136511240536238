import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import {
  WorkflowAccurateStage,
  WorkflowBackgroundCheckerStage,
  WorkflowSterlingStage,
} from 'api-clients/monolith';
import { CheckrGeo } from 'api-clients/monolith/models/CheckrGeo';
import { PackageOption } from 'api-clients/monolith/models/PackageOption';
import produce from 'immer';
import React, { useContext, VFC } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { StageSettingCard } from '../../../../StageSettingCard';
import { SettingSwitch } from '../../../SettingSwitch';
import {
  AutoAdvanceWhenLabelsComplete,
  IsHiddenFromPortal,
} from '../../SettingsSwitches';
import { messages } from './messages';
import { useStyles } from './styles';

export interface IntegrationCardProps {
  stage:
    | WorkflowAccurateStage
    | WorkflowBackgroundCheckerStage
    | WorkflowSterlingStage;
}

export const IntegrationCard: VFC<IntegrationCardProps> = ({ stage }) => {
  const styles = useStyles();
  const intl = useIntl();
  const { accountSlug } = useParams<{
    accountSlug: string;
  }>();

  const { setStage } = useContext(StageContext);

  const onPackageChange = (packageOption: PackageOption) => {
    const { value } = packageOption;

    setStage(
      produce(stage, draftStage => {
        if (draftStage.type === 'SterlingStage') {
          draftStage.additional_info.package_id = value;
        } else {
          draftStage.additional_info.package_name = value;
        }

        if (draftStage.type === 'BackgroundCheckerStage') {
          if (packageOption.supports_mvr_first) {
            draftStage.additional_info.mvr_first = true;
          } else {
            draftStage.additional_info.mvr_first = false;
          }
        }
      }),
    );
  };
  const hasStageLabels = stage.has_stage_labels;

  const onCheckrGeoChange = (geoOption: CheckrGeo) => {
    setStage(
      produce(stage, draftStage => {
        if (draftStage.type !== 'BackgroundCheckerStage') {
          return;
        }
        draftStage.additional_info.checkr_geo = geoOption;
        draftStage.additional_info.checkr_geo_id = geoOption.checkr_geo_id;
        draftStage.additional_info.checkr_geo_name = geoOption.checkr_geo_name;
      }),
    );
  };

  const onToggleMvrFirst = () => {
    setStage(
      produce(stage, draftStage => {
        if (draftStage.type !== 'BackgroundCheckerStage') {
          return;
        }
        draftStage.additional_info.mvr_first =
          !draftStage.additional_info.mvr_first;
      }),
    );
  };

  const packageName =
    stage.type === 'AccurateStage' || stage.type === 'BackgroundCheckerStage'
      ? stage.additional_info.package_name
      : stage.additional_info.package_id;

  const selectedPackage = stage.additional_info.package_options?.find(
    option => option.value === packageName,
  );

  const cardTitleByStageType: Record<
    'BackgroundCheckerStage' | 'AccurateStage' | 'SterlingStage' | string,
    string
  > = {
    AccurateStage: intl.formatMessage(messages.accurateIntegration),
    BackgroundCheckerStage: intl.formatMessage(messages.checkrIntegration),
    SterlingStage: intl.formatMessage(messages.sterlingIntegration),
  };

  let manageIntegrationHref = `${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/integrations/screening`;

  if (stage.type === 'BackgroundCheckerStage') {
    manageIntegrationHref += '?activeProvider=checkr';
  }

  return (
    <StageSettingCard
      title={cardTitleByStageType[stage.type]}
      variant="button"
      buttonText={intl.formatMessage(messages.manageIntegration)}
      buttonTextColor="primary"
      href={manageIntegrationHref}
      target="_blank"
    >
      <Grid container className={styles.cardBody} direction="column">
        <Grid className={styles.packageSelect} item xs={5}>
          <StyledReactSelect
            aria-label={intl.formatMessage(messages.reportPackage)}
            options={stage.additional_info.package_options}
            value={selectedPackage}
            onChange={onPackageChange}
            label={intl.formatMessage(messages.reportPackage)}
          />
        </Grid>
        {stage.type === 'BackgroundCheckerStage' && (
          <>
            <Grid className={styles.packageSelect} item xs={5}>
              <StyledReactSelect
                aria-label={intl.formatMessage(messages.checkrGeo)}
                options={stage.additional_info.checkr_geos}
                value={stage.additional_info.checkr_geo}
                onChange={onCheckrGeoChange}
                getOptionLabel={(option: CheckrGeo) =>
                  option.checkr_geo_name ?? intl.formatMessage(messages.none)
                }
                getOptionValue={(option: CheckrGeo) =>
                  option.checkr_geo_id ?? intl.formatMessage(messages.none)
                }
                label={intl.formatMessage(messages.checkrGeo)}
              />
            </Grid>
            {selectedPackage?.supports_mvr_first && (
              <SettingSwitch
                dataKey="mvr_first"
                checked={!!stage.additional_info.mvr_first}
                onChange={onToggleMvrFirst}
                label={intl.formatMessage(messages.progressiveScreening)}
              />
            )}
          </>
        )}
        <IsHiddenFromPortal stage={stage} setStage={setStage} />
        {hasStageLabels && (
          <AutoAdvanceWhenLabelsComplete stage={stage} setStage={setStage} />
        )}
      </Grid>
    </StageSettingCard>
  );
};
