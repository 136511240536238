import {
  Alert,
  Button,
  CustomTooltip,
  EditPencil,
} from '@fountain/fountain-ui-components';
import { Box, Grid, Typography } from '@material-ui/core';
import ExternalLink from '@material-ui/core/Link';
import { WorkflowAssessmentStage } from 'api-clients/monolith';
import produce from 'immer';
import React, { useContext, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';
import useSimpleToggle from 'hooks/useSimpleToggle';

import { StageSettingCard } from '../../../../StageSettingCard';
import { SettingSwitch } from '../../../SettingSwitch';
import AssessmentModal from './AssessmentModal';
import {
  IS_HIDDEN_FROM_PORTAL,
  SHOULD_AUTO_ADVANCE_WHEN_LABELS_COMPLETE,
  SHOW_ONE_QUESTION_AT_A_TIME,
} from './constants';
import { messages } from './messages';
import { useStyles } from './styles';

type DataKeyTypes =
  | typeof IS_HIDDEN_FROM_PORTAL
  | typeof SHOULD_AUTO_ADVANCE_WHEN_LABELS_COMPLETE
  | typeof SHOW_ONE_QUESTION_AT_A_TIME;

type SubstageDataKeyTypes = typeof SHOW_ONE_QUESTION_AT_A_TIME;

export interface AssessmentCardProps {
  stage: WorkflowAssessmentStage;
}

export const AssessmentCard: VFC<AssessmentCardProps> = ({ stage }) => {
  const { assessment_data_field_sets: assessmentDataFieldSet } =
    stage.additional_info;
  const { accountSlug } = useParams<{ accountSlug: string }>();
  const styles = useStyles();
  const intl = useIntl();
  const { setStage } = useContext(StageContext);

  const onToggleChange = (key: DataKeyTypes) => () => {
    setStage(
      produce(stage, draftStage => {
        if (
          key === IS_HIDDEN_FROM_PORTAL ||
          key === SHOULD_AUTO_ADVANCE_WHEN_LABELS_COMPLETE
        ) {
          draftStage.advanced_settings[key] =
            !draftStage.advanced_settings[key];
        } else {
          draftStage.additional_info[key] = !draftStage.additional_info[key];
        }
      }),
    );
  };

  const onAssessmentChange = (newAssessmentDataFieldSetId: number) => {
    setStage(
      produce(stage, draftStage => {
        draftStage.additional_info.assessment_data_field_set_id =
          newAssessmentDataFieldSetId;
      }),
    );
  };

  const labelsByDataKey: Record<DataKeyTypes, string> = {
    [IS_HIDDEN_FROM_PORTAL]: intl.formatMessage(
      messages.showStageInApplicantPortal,
    ),
    [SHOULD_AUTO_ADVANCE_WHEN_LABELS_COMPLETE]: intl.formatMessage(
      messages.shouldAutoAdvanceWhenLabelsComplete,
    ),
    [SHOW_ONE_QUESTION_AT_A_TIME]: intl.formatMessage(
      messages.showOneQuestionAtATime,
    ),
  };

  const substageLabelsByDataKey: Record<SubstageDataKeyTypes, string> = {
    [SHOW_ONE_QUESTION_AT_A_TIME]: intl.formatMessage(
      messages.showOneQuestionAtATime,
    ),
  };

  const isMissingAssessment =
    !stage.additional_info.assessment_data_field_set_id;
  const isSubStage = Boolean(stage.parent_stage_id);

  const hasStageLabels = Boolean(stage.has_stage_labels);
  const labelsToRender = isSubStage ? substageLabelsByDataKey : labelsByDataKey;

  const { showContent: isAssessmentModalOpen, toggle: toggleAssessmentModal } =
    useSimpleToggle();

  const assessmentHref = `${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/assessment_data_field_sets`;
  const currentAssessment = assessmentDataFieldSet.find(
    set => set.id === stage.additional_info.assessment_data_field_set_id,
  );

  return (
    <StageSettingCard
      variant="default"
      title={intl.formatMessage(messages.assessmentForm)}
    >
      <Grid>
        {isMissingAssessment ? (
          <Alert type="danger" fullWidth className={styles.questionAlert}>
            <Grid className={styles.alertBodyContainer}>
              <Grid>{intl.formatMessage(messages.missingAssessment)}</Grid>
              <Box display="flex" alignItems="center" height={16}>
                <Button
                  autoWidth
                  onClick={toggleAssessmentModal}
                  type="text"
                  disableRipple
                >
                  {intl.formatMessage(messages.createOrManageAssessments)}
                </Button>
              </Box>
            </Grid>
          </Alert>
        ) : (
          <Grid
            container
            alignItems="center"
            className={styles.assessmentContainer}
          >
            <Grid>
              <Typography variant="body2" className={styles.assessmentHeader}>
                {intl.formatMessage(messages.assessment)}
              </Typography>
              <CustomTooltip
                dense
                title={<FormattedMessage {...messages.manageOrAssign} />}
              >
                <Typography
                  variant="h4"
                  className={styles.assessmentTitle}
                  onClick={toggleAssessmentModal}
                >
                  {currentAssessment?.title}
                </Typography>
              </CustomTooltip>
            </Grid>
            <CustomTooltip
              dense
              title={<FormattedMessage {...messages.editAssessment} />}
            >
              <Grid>
                <ExternalLink
                  aria-label="edit"
                  className={styles.editMessageButton}
                  href={`${assessmentHref}/${
                    currentAssessment!.external_id
                  }/edit?stage_id=${stage.external_id}`}
                  target="_blank"
                >
                  <EditPencil
                    viewBox="0 0 16 16"
                    className={styles.editMessageIcon}
                  />
                </ExternalLink>
              </Grid>
            </CustomTooltip>
          </Grid>
        )}
        <Grid className={styles.cardBody}>
          {Object.keys(labelsToRender).map(dataKey => {
            const key = dataKey as DataKeyTypes;

            let checked = false;
            const disabled = false;
            let tooltip;

            if (
              key === SHOULD_AUTO_ADVANCE_WHEN_LABELS_COMPLETE &&
              !hasStageLabels
            ) {
              return null;
            }

            if (key === IS_HIDDEN_FROM_PORTAL) {
              checked = !stage.advanced_settings[key];
            } else if (key === SHOULD_AUTO_ADVANCE_WHEN_LABELS_COMPLETE) {
              checked = !!stage.advanced_settings[key];
            } else {
              checked = !!stage.additional_info[key];
            }

            return (
              <SettingSwitch
                key={key}
                dataKey={key}
                checked={checked}
                onChange={onToggleChange(key)}
                label={labelsByDataKey[key]}
                tooltipDescription={tooltip}
                disabled={disabled}
              />
            );
          })}
        </Grid>
        <Grid>
          <Button
            type="secondary"
            className={styles.manageAssessmentsButton}
            aria-label={intl.formatMessage(messages.manageAssessments)}
            onClick={toggleAssessmentModal}
          >
            <Typography variant="h4" color="primary">
              {intl.formatMessage(messages.manageAssessments)}
            </Typography>
          </Button>
        </Grid>
      </Grid>
      {isAssessmentModalOpen && (
        <AssessmentModal
          currentAssessmentId={currentAssessment?.id}
          assessments={assessmentDataFieldSet}
          assessmentHref={assessmentHref}
          stageId={stage.external_id}
          closeModal={toggleAssessmentModal}
          onAssessmentChange={onAssessmentChange}
        />
      )}
    </StageSettingCard>
  );
};
