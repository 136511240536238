import { CustomTooltip, IconButton } from '@fountain/fountain-ui-components';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import CopyIcon from 'images/applicant_detail/copy';
import PenIcon from 'images/applicant_detail/pen';
import TrashcanIcon from 'images/applicant_detail/trashcan';

import { ActionTypeEnum } from './constants';
import useStyles from './styles';

const ActionButton = ({ actionType, onClick, tooltipTitle }) => {
  const styles = useStyles();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const Icon = () => {
    switch (actionType) {
      case ActionTypeEnum.COPY:
        return <CopyIcon />;
      case ActionTypeEnum.DELETE:
        return <TrashcanIcon />;
      case ActionTypeEnum.EDIT:
        return <PenIcon />;
      default:
        return null;
    }
  };

  const handleOpen = () => setIsTooltipVisible(true);
  const handleClose = () => setIsTooltipVisible(false);
  const handleClick = event => {
    handleClose();
    onClick(event);
  };

  return (
    <CustomTooltip
      dense
      onClose={handleClose}
      onOpen={handleOpen}
      open={isTooltipVisible}
      title={tooltipTitle}
    >
      <IconButton className={styles.hoverButton} onClick={handleClick}>
        <Icon />
      </IconButton>
    </CustomTooltip>
  );
};

ActionButton.propTypes = {
  actionType: PropTypes.oneOf([
    ActionTypeEnum.COPY,
    ActionTypeEnum.DELETE,
    ActionTypeEnum.EDIT,
  ]),
  onClick: PropTypes.func.isRequired,
  tooltipTitle: PropTypes.string.isRequired,
};

export default ActionButton;
