import { theme } from '@fountain/fountain-ui-components';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme>(() => ({
  select: {
    width: '100%',
    marginTop: theme.spacing(2.5),
  },
  wrapper: {
    display: 'grid',
    gap: theme.spacing(2),
    zIndex: 1000,
  },
  labelCompressed: {
    color: theme.palette.common.gray600,
    padding: theme.spacing(0, 0, 2, 0),
  },
  subheading: {
    color: theme.palette.common.gray800,
    padding: theme.spacing(4, 0, 0, 0),
  },
  durationWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    width: '50%',
  },
  lengthInput: {},
  minuteLabel: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  suggestionPopper: {
    margin: theme.spacing(1, 0, 0, 0),
    border: '1px solid',
    borderColor: theme.palette.common.gray300,
    boxShadow: '0px 16px 32px rgba(19, 30, 64, 0.161058)',
    borderRadius: 5,
    zIndex: 1000,
  },
  suggestionPrimary: {
    color: theme.palette.common.gray800,
    fontWeight: 'bold',
  },
  required: {
    color: '#FF0100',
  },
}));
