import { CustomTooltip } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import InfoGrey from 'images/info-grey.svg';

import {
  StageSettingCard,
  StageSettingCardProps,
} from '../StageSettingCard/StageSettingCard';
import messages from './messages';
import { useStyles } from './styles';

export type BaseStageSettingCardEmptyStateProps = {
  stageSettingCardProps: StageSettingCardProps;
  buttonComponent: React.ReactNode;
};

export type BodyTextStageSettingCardEmptyStateProps = {
  stageSettingCardProps: StageSettingCardProps;
  buttonComponent: React.ReactNode;
  bodyText: string;
};

export type TooltipStageSettingCardEmptyStateProps = {
  stageSettingCardProps: StageSettingCardProps;
  buttonComponent: React.ReactNode;
  bodyText: string;
  tooltipText: string;
};

export type StageSettingCardEmptyStateProps =
  | BaseStageSettingCardEmptyStateProps
  | BodyTextStageSettingCardEmptyStateProps
  | TooltipStageSettingCardEmptyStateProps;

type TextWithTooltipProps = {
  bodyText: string;
  tooltipText: string;
};

const TextWithTooltip: FC<TextWithTooltipProps> = props => {
  const { bodyText, tooltipText } = props;
  const styles = useStyles();
  const intl = useIntl();

  if (tooltipText) {
    return (
      <Grid>
        <Typography variant="body2" component="div">
          <Grid className={styles.text}>
            {bodyText}
            <CustomTooltip
              className={styles.infoTooltip}
              title={tooltipText}
              dense
            >
              <img
                src={InfoGrey}
                alt={intl.formatMessage(messages.infoIconAlt)}
              />
            </CustomTooltip>
          </Grid>
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid>
      <Typography variant="body2" className={styles.text}>
        {bodyText}
      </Typography>
    </Grid>
  );
};

export const StageSettingCardEmptyState: FC<
  StageSettingCardEmptyStateProps
> = props => {
  const { buttonComponent, stageSettingCardProps } = props;
  const styles = useStyles();

  if ('bodyText' in props && 'tooltipText' in props) {
    const { bodyText, tooltipText } = props;

    return (
      <StageSettingCard {...stageSettingCardProps}>
        <Grid>
          <TextWithTooltip
            bodyText={bodyText}
            tooltipText={tooltipText}
          ></TextWithTooltip>
          {buttonComponent}
        </Grid>
      </StageSettingCard>
    );
  }
  if ('bodyText' in props) {
    const { bodyText } = props;

    return (
      <StageSettingCard {...stageSettingCardProps}>
        <Grid>
          <Grid className={styles.text}>{bodyText}</Grid>
          {buttonComponent}
        </Grid>
      </StageSettingCard>
    );
  }

  return (
    <StageSettingCard {...stageSettingCardProps}>
      <Grid>{buttonComponent}</Grid>
    </StageSettingCard>
  );
};
