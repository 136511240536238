/**
 *
 * SuperAdmin Modal Component
 *
 */

import {
  InnerContent,
  SimpleModal,
  SimpleModalHeader,
  SimpleModalTitle,
} from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import AccountChooser from 'containers/AccountChooser';
import {
  makeSelectIsImpersonating,
  makeSelectRole,
  makeSelectSelectedUser,
  makeSelectSuperAdminUserEmail,
} from 'containers/Auth_old/selectors';
import UserImpersonateChooser from 'containers/UserImpersonateChooser';

class SuperAdminModal extends React.PureComponent {
  render() {
    const {
      props: { superAdminUserEmail, handleClose },
    } = this;
    return (
      <SimpleModal onClose={handleClose} maxWidth={512}>
        <SimpleModalHeader onClose={handleClose}>
          <SimpleModalTitle>Impersonate Account/User</SimpleModalTitle>
        </SimpleModalHeader>

        <InnerContent>
          <Typography variant="body2" align="center">
            {'Account email: '}
            {superAdminUserEmail}
          </Typography>
          <Grid container>
            <Grid item sm={6} container justify="flex-start">
              <AccountChooser />
            </Grid>
            <Grid item sm={6} container justify="flex-start">
              <UserImpersonateChooser />
            </Grid>
          </Grid>
        </InnerContent>
      </SimpleModal>
    );
  }
}

SuperAdminModal.propTypes = {
  superAdminUserEmail: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  role: makeSelectRole(),
  isImpersonating: makeSelectIsImpersonating(),
  selectedUser: makeSelectSelectedUser(),
  superAdminUserEmail: makeSelectSuperAdminUserEmail(),
});

function mapDispatchToProps() {
  return {};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(SuperAdminModal);
