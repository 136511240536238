import { ResultStatus } from 'api/resultStatus';
import {
  SidebarStage,
  WorkflowEditorService,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import React, { Dispatch, SetStateAction } from 'react';

import { DocumentSigningRuleProps } from 'containers/WorkflowEditor/components/Rules/DocumentSigningRules/types';
import { PartnerRuleProps } from 'containers/WorkflowEditor/components/Rules/PartnerRules/types';
import { RulesProps } from 'containers/WorkflowEditor/components/Rules/types';

type StageContextExtras = {
  setStage: Dispatch<SetStateAction<WorkflowStageDetail>>;
  refetchStage: () => void;
  isDirty: boolean;
  isStageTitleDirty: boolean;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  rules: RulesProps;
  partnerRules: PartnerRuleProps;
  documentSigningRules: DocumentSigningRuleProps;
  setRules: Dispatch<SetStateAction<RulesProps>>;
  setPartnerRules: Dispatch<SetStateAction<PartnerRuleProps>>;
  setDocumentSigningRules: Dispatch<SetStateAction<DocumentSigningRuleProps>>;
  onSaveChanges: () => void;
  onDiscardChanges: () => void;
  funnelStages: SidebarStage[];
  resetUpdateStageResult: () => void;
};

export type StageDetailResponse = Awaited<
  ReturnType<
    typeof WorkflowEditorService.getInternalApiWorkflowEditorFunnelsStages1
  >
>;

export type StageDetailPatchResponse = ResultStatus<
  WorkflowStageDetail,
  { errors: Record<string, Array<string>> }
>;

export type StageContextData = {
  stage: StageDetailResponse;
  updateStageResult: StageDetailPatchResponse;
} & StageContextExtras;

// Disabling because we know that we will use stage data only on the result 'ready' status.
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const StageContext = React.createContext<StageContextData>(undefined!);
