import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  button: {
    '&:hover': {
      backgroundColor: theme.palette.common.gray300,
    },
  },
  dialog: {
    height: '506px',
    width: '512px',
  },
  dialogActions: {
    backgroundColor: theme.palette.common.gray100,
    flexDirection: 'row-reverse',
    padding: theme.spacing(1.25, 2),
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  dialogTitle: {
    alignItems: 'center',
    color: theme.palette.common.gray800,
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: '500',
    padding: theme.spacing(2),
    width: '100%',
  },
  divider: {
    backgroundColor: theme.palette.common.gray300,
  },
  exportIcon: {
    fontSize: theme.spacing(1.5),
    margin: theme.spacing(0, 0, -0.25, 0.5),
  },
  highlight: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.common.orange200,
  },
  link: {
    alignItems: 'center',
    color: theme.palette.common.blue400,
    display: 'flex',
    fontWeight: '500',
    textDecoration: 'none',
    '&:visited': {
      color: theme.palette.common.blue400,
    },
  },
  listItem: {
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.common.gray100,
    },
  },
  listStatesContainer: {
    height: ({ searchInputContainerHeight }) =>
      `calc(100% - ${searchInputContainerHeight - 16}px)`,
  },
  listSubheader: {
    color: theme.palette.common.gray800,
    padding: theme.spacing(0.5, 0),
  },
  searchInputContainer: {
    backgroundColor: theme.palette.common.white,
    margin: theme.spacing(-2, 0, 0, -2),
    padding: theme.spacing(2),
    position: 'sticky',
    top: theme.spacing(-2),
    width: `calc(100% + ${theme.spacing(4)}px)`,
    zIndex: '1',
  },
  templateIcon: {
    verticalAlign: 'middle',
    padding: theme.spacing(0, 1, 0, 0),
    '& > path': {
      fill: ({ isTemplateSelected }) =>
        isTemplateSelected
          ? theme.palette.common.blue400
          : theme.palette.common.gray800,
    },
    fontSize: theme.spacing(2),
  },
}));

export default useStyles;
