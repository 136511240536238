import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  unsupportedStage: {
    id: `app.containers.WorkflowEditor.components.StageDetail.UnsupportedStage.unsupportedStage`,
    defaultMessage: 'Unsupported stage type',
  },
  contactSupport: {
    id: `app.containers.WorkflowEditor.components.StageDetail.UnsupportedStage.contactSupport`,
    defaultMessage:
      'For additional assitance, please <link>contact support</link>.',
  },
});
