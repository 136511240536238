import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  info: {
    fontWeight: 450,
    lineHeight: '20px',
    color: theme.palette.common.gray800,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  inputContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  table: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  tableHead: {
    backgroundColor: theme.palette.common.gray100,
    height: 40,
  },
  tableRow: {
    cursor: 'pointer',
    borderBottom: theme.customBorders.gray200,
    height: 48,
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
    '& > th': {
      padding: theme.spacing(0, 2),
    },
  },
  tableCell: {
    padding: theme.spacing(1, 2),
    borderBottom: 'none',
    '& > svg': {
      fontSize: 'inherit',
    },
    '&:last-child': {
      padding: theme.spacing(1, 2),
    },
  },
  cellContent: {
    fontSize: theme.typography.caption.fontSize,
  },
  alertContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
    display: 'inline-block',
  },
  exceptionContainer: {
    height: '70%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalButton: {
    padding: theme.spacing(1.5, 3),
    marginLeft: theme.spacing(2),
  },
}));
