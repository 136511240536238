import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  activeAssessment: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.AssessmentStage.AssessmentCard.AssessmentModal.activeAssessment',
    defaultMessage: '(active assessment)',
  },
  createNewAssessment: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.AssessmentStage.AssessmentCard.AssessmentModal.createNewAssessment',
    defaultMessage: 'Create New Assessment',
  },
  editAssessment: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.AssessmentStage.AssessmentCard.AssessmentModal.editAssessment',
    defaultMessage: 'Edit assessment',
  },
  instructions: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.AssessmentStage.AssessmentCard.AssessmentModal.instructions',
    defaultMessage:
      'Please create an assessment by clicking "Create New Assessment" below.',
  },
  manage: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.AssessmentStage.AssessmentCard.AssessmentModal.manage',
    defaultMessage: 'Manage',
  },
  modalHeader: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.AssessmentStage.AssessmentCard.AssessmentModal.modalHeader',
    defaultMessage: 'Manage all assessments',
  },
  modalInstructions: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.AssessmentStage.AssessmentCard.AssessmentModal.modalInstructions',
    defaultMessage:
      'Click on any assessment to assign it to the stage or click on the edit icon to update the question set.',
  },
  title: {
    id: 'app.containers.WorkflowEditor.components.StageDetail.AssessmentStage.AssessmentCard.AssessmentModal.title',
    defaultMessage: 'Assessment',
  },
});
