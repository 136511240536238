import { defineMessages } from 'react-intl';

export const scope = 'app.components.globalNav.applicantsNavMenu';

export default defineMessages({
  applicants: {
    id: `${scope}.applicants`,
    defaultMessage: 'Applicants',
  },
  MAV: {
    id: `${scope}.MAV`,
    defaultMessage: 'MAV',
  },
  applicantTable: {
    id: `${scope}.applicantTable`,
    defaultMessage: 'Applicant Table',
  },
  universalApplicantView: {
    id: `${scope}.universalApplicantView`,
    defaultMessage: 'Universal Applicant View',
  },
});
