import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React, { FC } from 'react';

export const CustomStageIcon: FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.75 13.5C8.50736 13.5 7.5 12.4926 7.5 11.25C7.5 10.0074 8.50736 9 9.75 9C10.6671 9 11.4561 9.54874 11.8065 10.3358C11.8496 10.4326 11.9428 10.5 12.0488 10.5H13.25C13.6642 10.5 14 10.8358 14 11.25C14 11.6642 13.6642 12 13.25 12H12.0488C11.9428 12 11.8496 12.0674 11.8065 12.1642C11.4561 12.9513 10.6671 13.5 9.75 13.5Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.99953 11.25C5.99953 10.8358 5.66375 10.5 5.24953 10.5H2.75C2.33579 10.5 2 10.8358 2 11.25C2 11.6642 2.33579 12 2.75 12H5.24953C5.66375 12 5.99953 11.6642 5.99953 11.25Z"
    />
    <path d="M6.25 7C7.49264 7 8.5 5.99264 8.5 4.75C8.5 3.50736 7.49264 2.5 6.25 2.5C5.33286 2.5 4.54389 3.04874 4.19348 3.8358C4.15039 3.9326 4.05719 4 3.95123 4H2.77002C2.35581 4 2.02002 4.33579 2.02002 4.75C2.02002 5.16421 2.35581 5.5 2.77002 5.5H3.95124C4.05719 5.5 4.15039 5.5674 4.19348 5.6642C4.54389 6.45126 5.33286 7 6.25 7Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 4.75C10 4.33579 10.3358 4 10.75 4H13.25C13.6642 4 14 4.33579 14 4.75C14 5.16421 13.6642 5.5 13.25 5.5H10.75C10.3358 5.5 10 5.16421 10 4.75Z"
    />
  </SvgIcon>
);
