export const getCareerSiteUrl = (accountSlug: string, brandId: string) => {
  const { hostname } = window.location;
  const baseUrl =
    hostname === 'localhost'
      ? `http://localhost:5666`
      : `https://careers.${hostname.slice(4)}`;

  if (brandId === 'All') {
    return `${baseUrl}/${accountSlug}`;
  }

  return `${baseUrl}/${accountSlug}/${brandId}`;
};
