export const RELATIONSHIP_RADIO_OPTIONS = [
  {
    label: 'Applied for a job using Fountain',
    value: 'applied_to_job_through_fountain',
  },
  {
    label: 'Provided information to Fountain as a customer/employer',
    value: 'provided_information',
  },
  {
    label: 'Applied for a job or has worked for Fountain',
    value: 'worked_for_fountain',
  },
  {
    label: "Is a business to business contact of Fountain's",
    value: 'business_to_business',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const REQUEST_TYPE_CHECKBOX_OPTIONS = [
  {
    value: 'information_request',
    label:
      'Information about how you collect, use, and share my personal information',
  },
  {
    value: 'access_request',
    label: 'Access to the personal information you store about me',
  },
  {
    value: 'deletion_request',
    label: 'Deletion of the personal information you collect from me',
  },
];
