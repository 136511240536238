import { Button, StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import {
  ChatbotFeedback,
  FaqbotUpdateFeedbackParams,
  IntentsWithBotReply,
} from 'api-clients/monolith';
import React, { useEffect, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { INCORRECT } from 'containers/Chatbot/constants';

import { messages } from './messages';
import { useStyles } from './styles';

export interface IncorrectIntentFeedbackProps {
  logId: string;
  newIntent?: string | null;
  ignoreIntent?: boolean | null;
  setIgnoreIntent: React.Dispatch<
    React.SetStateAction<boolean | null | undefined>
  >;
  currentStatus: ChatbotFeedback;
  updateIntent: (
    logId: string,
    requestBody: FaqbotUpdateFeedbackParams,
  ) => void;
  intentWithBotReply?: IntentsWithBotReply[];
  selectedIntent?: IntentsWithBotReply;
  setSelectedIntent: React.Dispatch<
    React.SetStateAction<IntentsWithBotReply | undefined>
  >;
}

export const IncorrectIntentFeedback: VFC<IncorrectIntentFeedbackProps> = ({
  logId,
  newIntent,
  ignoreIntent,
  setIgnoreIntent,
  currentStatus,
  updateIntent,
  intentWithBotReply,
  selectedIntent,
  setSelectedIntent,
}) => {
  const styles = useStyles();
  const intl = useIntl();

  useEffect(() => {
    if (intentWithBotReply && newIntent) {
      setSelectedIntent(
        intentWithBotReply.find(obj => obj.value === newIntent),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intentWithBotReply, newIntent]);

  const handleIntentChange = (option: IntentsWithBotReply) => {
    updateIntent(logId, {
      faqbot_log: {
        new_intent: option.value,
        ignore_intent: false,
      },
    });
    setSelectedIntent(option);
  };

  const handleIgnoreIntent = () => {
    updateIntent(logId, {
      faqbot_log: {
        ignore_intent: true,
      },
    });
    setIgnoreIntent(true);
  };

  return (
    <Grid item container direction="row" justify="space-between">
      <Button
        color="secondary"
        className={styles.ignoreIntentBtn}
        onClick={handleIgnoreIntent}
        disabled={
          (Boolean(ignoreIntent) || Boolean(selectedIntent)) &&
          currentStatus === INCORRECT
        }
      >
        {ignoreIntent ? (
          <FormattedMessage {...messages.answerIgnored} />
        ) : (
          <FormattedMessage {...messages.ignoringAnswer} />
        )}
      </Button>

      {!(ignoreIntent && currentStatus === INCORRECT) && (
        <StyledReactSelect
          className={styles.intentAnswer}
          value={selectedIntent}
          options={intentWithBotReply}
          getOptionLabel={(option: IntentsWithBotReply) => option.label}
          getOptionValue={(option: IntentsWithBotReply) => option.value}
          placeholder={intl.formatMessage(messages.selectAnswerForQuestion)}
          isDisabled={Boolean(selectedIntent) && currentStatus === INCORRECT}
          onChange={handleIntentChange}
        />
      )}
    </Grid>
  );
};
