import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  discardChangesWarning: {
    id: 'app.containers.WorkflowEditor.components.StageColumn.discardChangesWarning',
    defaultMessage: `Stage will be permanently deleted and can't be recovered. Are you sure you want to delete the stage {stageTitle}.`,
  },
  reorderStagesError: {
    id: 'app.containers.WorkflowEditor.components.StageColumn.reorderStagesError',
    defaultMessage: 'An error occurred. Please refresh and try again.',
  },
  stageColumn: {
    id: 'app.containers.WorkflowEditor.components.StageColumn.stageColumn',
    defaultMessage: 'Stage Column',
  },
});
