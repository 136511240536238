import { theme as muiTheme } from '@fountain/fountain-ui-components';
import PropTypes from 'prop-types';
import React from 'react';

const AddSvg = ({ className, color }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 1V15"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 8H15"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

AddSvg.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

AddSvg.defaultProps = {
  color: muiTheme.palette.common.gray500,
};

export default AddSvg;
