import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import { getConditionErrorMessage } from 'containers/WorkflowEditor/components/Rules/Condition/constants';
import { messages as conditionMessages } from 'containers/WorkflowEditor/components/Rules/Condition/messages';
import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';

import { DocumentSigningRuleProps } from '../../DocumentSigningRules/types';
import { RulesProps, SharedConditionProps } from '../../types';
import { messages } from './messages';

export const TechCheckStatus: FC<SharedConditionProps> = ({
  condition,
  ruleId,
  setRules,
  setDocumentSigningRules,
  errors,
}) => {
  const intl = useIntl();

  const { extra, id } = condition;

  const {
    condition_statuses: conditionStatuses,
    tech_check_tiers: techCheckTiers,
  } = useContext(RulesEditDataContext);

  const statusMessages = {
    advance_with_data_missing: intl.formatMessage(messages.passWithDataMissing),
    advance: intl.formatMessage(messages.passAll),
    reject: intl.formatMessage(messages.fail),
  };

  const tierMessages = {
    baseline: intl.formatMessage(messages.baseline),
    tier_1: intl.formatMessage(messages.tier1),
    tier_2: intl.formatMessage(messages.tier2),
  };

  const statusOptions = conditionStatuses?.tech_check?.map(status => ({
    label: statusMessages[status],
    value: status,
  }));

  const tierOptions = techCheckTiers?.map(tier => ({
    label: tierMessages[tier],
    value: tier,
  }));

  const onChangeStatus = (newStatus: { label: string; value: string }) => {
    const rulesSetter = setRules ?? setDocumentSigningRules;

    if (rulesSetter) {
      rulesSetter(
        produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];
          const idx = draftConditions.findIndex(cond => cond.id === id);

          draftConditions[idx].extra.status = newStatus.value;
        }),
      );
    }
  };

  const onChangeTier = (newTier: { label: string; value: string }) => {
    const rulesSetter = setRules ?? setDocumentSigningRules;

    if (rulesSetter) {
      rulesSetter(
        produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];
          const idx = draftConditions.findIndex(cond => cond.id === id);

          draftConditions[idx].extra.tier_name = newTier.value;
        }),
      );
    }
  };

  const selectedStatus =
    statusOptions?.find(option => option.value === extra.status) ?? null;

  const selectedTierName =
    tierOptions?.find(option => option.value === extra.tier_name) ?? null;

  const statusErrorMessage =
    !extra?.status && getConditionErrorMessage('status', errors);

  const tierNameErrorMessage =
    !extra?.tier_name && getConditionErrorMessage('tier_name', errors);

  return (
    <>
      <Grid item xs={6}>
        <StyledReactSelect
          options={statusOptions}
          label={intl.formatMessage(conditionMessages.status)}
          aria-label={intl.formatMessage(conditionMessages.status)}
          onChange={onChangeStatus}
          value={selectedStatus}
          error={statusErrorMessage}
          placeholder=""
          required
        />
      </Grid>
      <Grid item xs={6}>
        {/* TODO: Make these tier options dynamic so if they don't have Tier 2 setup in their tech check, do not show that option here */}
        <StyledReactSelect
          options={tierOptions}
          label={intl.formatMessage(messages.tierName)}
          aria-label={intl.formatMessage(messages.tierName)}
          onChange={onChangeTier}
          value={selectedTierName}
          error={tierNameErrorMessage}
          placeholder=""
          required
        />
      </Grid>
    </>
  );
};
