/*
 *
 * SourcingPurchaseNew constants
 *
 */
export const SET_BUDGET = 'app/SourcingPurchaseNew/SET_BUDGET';
export const SET_ADVERTISER_BUDGET =
  'app/SourcingPurchaseNew/SET_ADVERTISER_BUDGET';
export const SET_DURATION = 'app/SourcingPurchaseNew/SET_DURATION';
export const SET_PURCHASED = 'app/SourcingPurchaseNew/SET_PURCHASED';
export const SET_JOB = 'app/SourcingPurchaseNew/SET_JOB';
export const SET_SUCCESS = 'app/SourcingPurchaseNew/SET_SUCCESS';
export const SET_COUPON = 'app/SourcingPurchaseNew/SET_COUPON';
export const SET_SUBSCRIBE = 'app/SourcingPurchaseNew/SET_SUBSCRIBE';
export const SET_PAYMENT_SOURCE = 'app/SourcingPurchaseNew/SET_PAYMENT_SOURCE';
export const SET_POSTING_TITLE = 'app/SourcingPurchaseNew/SET_POSTING_TITLE';
export const SET_POST_TO_FREE_BOARDS =
  'app/SourcingPurchaseNew/SET_POST_TO_FREE_BOARDS';
export const RESET_SOURCING_PURCHASE_NEW =
  'app/SourcingPurchaseNew/RESET_SOURCING_PURCHASE_NEW';
export const SET_STARTS_AT = 'app/SourcingPurchaseNew/SET_STARTS_AT';
export const SET_SCHEDULED = 'app/SourcingPurchaseNew/SET_SCHEDULED';
export const UNSCHEDULE = 'app/SourcingPurchaseNew/UNSCHEDULE';
export const SET_DATE = 'app/SourcingPurchaseNew/SET_DATE';
export const SET_TIME = 'app/SourcingPurchaseNew/SET_TIME';
export const SET_TIME_ZONE = 'app/SourcingPurchaseNew/SET_TIME_ZONE';
export const SET_IS_INVOICED = 'app/SourcingPurchaseNew/SET_IS_INVOICED';
export const FETCH_SETUP_INTENT_INIT =
  'app/SourcingPurchaseNew/FETCH_SETUP_INTENT_INIT';
export const FETCH_SETUP_INTENT_SUCCESS =
  'app/SourcingPurchaseNew/FETCH_SETUP_INTENT_SUCCESS';
export const FETCH_SETUP_INTENT_ERROR =
  'app/SourcingPurchaseNew/FETCH_SETUP_INTENT_ERROR';
export const CAREER_BUILDER_COST = 250;
export const CAREER_BUILDER_DURATION_DAYS = 30;
export const CAREER_BUILDER_DURATION_WEEKS = 4;
export const CRAIGSLIST_MIN_BUDGET = 200;
export const INDEED_MIN_BUDGET = 140;
export const ALL_TO_INDEED_THRESHOLD = 300;

export const INDEED_DURATION_DAYS = 14;
export const MIN_BUDGET = 150;
export const MIN_AMAZON_INDIA_BUDGET = 12000;

export const DEFAULT_BUDGET = 250;
export const DEFAULT_BUDGET_INDIA = 12000;
export const MAX_BUDGET = 10000;
export const MAX_BUDGET_AMAZON_INDIA = 740000;
export const DURATIONS_FOR_BUDGET_LESS_THAN_300 = [2];
export const DURATIONS_WITHOUT_STANDARD_PACKAGE = [4];
export const DURATIONS_FOR_BUDGET_LESS_THAN_500 = [2, 4];
export const DURATIONS_FOR_BUDGET_GREATER_THAN_EQUAL_500 = [4, 6];
export const USA = 'USA';
export const ADVANCED = 'advanced';
export const SIMPLE = 'simple';
export const TOOLTIP_TOUCH_DELAY = 100;

// https://fountain.atlassian.net/browse/APPL-488
// https://www.x-rates.com/average/?from=USD&to=INR&amount=1&year=2021
// TODO: We should update exchange rate through external services
// rather than defining it as a constant. Just picked May, 2021 average
// for now
export const USD_INR_RATE = 73.41;
export const SLIDER_STEP_US = 25;
export const SLIDER_STEP_INDIA = 1000;
export const MAX_SLIDER_BUDGET_US = 1000;
export const MAX_SLIDER_BUDGET_INDIA = 74000; // 1000 * 73.41 = 73410
export const MID_SLIDER_BUDGET_US = 500;
export const MID_SLIDER_BUDGET_INDIA = 37000;
export const BOOST_TYPE_VONQ = 'vonq';
export const BOOST_TYPE_STANDARD = 'standard';
export const BOOST_TYPE_INDEED = 'indeed';
