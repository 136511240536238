import { Button } from '@fountain/fountain-ui-components';
import { Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import { AddItemRows } from 'containers/WorkflowEditor/components/AddItemRows';

import { messages } from './messages';
import { useStyles } from './styles';

interface ParamProps {
  accountSlug: string;
  funnelSlug: string;
  stageSlug: string;
}

interface PartnerStageProps {
  partnerName: string;
}

export const PartnerIntegrationNotConnectedCard: FC<PartnerStageProps> = ({
  partnerName,
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const { accountSlug } = useParams<ParamProps>();

  const manageIntegrationHref = `${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/integrations/screening`;

  return (
    <AddItemRows
      emptyContentDescription={intl.formatMessage(messages.connectToPartner, {
        partnerName,
      })}
      isEmpty
      addButton={
        <Button
          type="secondary"
          size="small"
          autoWidth
          className={styles.addButton}
          href={manageIntegrationHref}
        >
          <Typography variant="h4">
            <FormattedMessage {...messages.connect} />
          </Typography>
        </Button>
      }
    />
  );
};
