import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  stageCardContainer: {
    textDecoration: 'none',
    color: theme.palette.common.gray600,
  },
  cardWrapper: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(0.5),
    border: theme.customBorders.gray300,
    padding: theme.spacing(2, 1, 2, 2.5),

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.common.gray100,
      border: theme.customBorders.gray400,
    },

    '&.selected': {
      '&:hover': {
        border: theme.customBorders.primary,
      },

      backgroundColor: theme.palette.common.blue50,

      '& .MuiIconButton-root': {
        '&:hover': {
          backgroundColor: theme.palette.common.blue100,
        },
      },
    },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    minWidth: theme.spacing(3),
  },
  deleteContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    paddingLeft: theme.spacing(2),
  },
  text: {
    color: theme.palette.common.gray800,
  },
  typeRow: {
    height: theme.spacing(2.5),
    marginBottom: theme.spacing(1),
    alignContent: 'space-around',
  },
  title: {
    wordBreak: 'break-word',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(0.75),
  },
  ruleType: {
    marginRight: theme.spacing(0.75),
    color: theme.palette.common.gray600,
  },
  type: {
    marginRight: theme.spacing(2),
    color: theme.palette.common.gray600,
  },
  applicantIcon: {
    fill: theme.palette.common.gray600,
    marginRight: theme.spacing(1),
    fontSize: theme.spacing(2),
  },
  dragIcon: {
    fill: theme.palette.common.gray500,
    fontSize: theme.spacing(1.5),
    marginTop: theme.spacing(0.5),

    '&:hover': {
      cursor: 'grab',
    },
  },
  trashCan: {
    fontSize: theme.spacing(1.5),
    fill: theme.palette.common.gray600,
  },
  calendar: {
    margin: theme.spacing(0, 1, 0, 1.5),
    fill: theme.palette.common.gray600,
    fontSize: theme.spacing(2),
  },
  ruleCardInnerContents: {
    display: 'flex',
    alignItems: 'center',
  },
  ruleStageTrashIcon: {
    height: '23px',
    width: '23px',
    marginRight: theme.spacing(1),
  },
}));
