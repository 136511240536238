/*
 *
 * Auth actions
 *
 */

import * as constants from './constants';

export function whoamiInitAction(brandSlug = null) {
  return {
    type: constants.WHOAMI_INIT,
    brandSlug,
  };
}
export function whoamiSuccessAction(whoami) {
  return {
    type: constants.WHOAMI_SUCCESS,
    whoami,
  };
}
export function whoamiErrorAction(error) {
  return {
    type: constants.WHOAMI_ERROR,
    error,
  };
}
export function allBrandsInitAction() {
  return {
    type: constants.FETCH_ALL_BRANDS_INIT,
  };
}
export function allBrandsSuccessAction(brands) {
  return {
    type: constants.FETCH_ALL_BRANDS_SUCCESS,
    brands,
  };
}
export function allBrandsErrorAction(error) {
  return {
    type: constants.FETCH_ALL_BRANDS_ERROR,
    error,
  };
}
export function setSelectedBrandAction(brand) {
  return {
    type: constants.SET_SELECTED_BRAND,
    brand,
  };
}
export function setSelectedLocationAction(location) {
  return {
    type: constants.SET_SELECTED_LOCATION,
    location,
  };
}
export function signoutInitAction() {
  return {
    type: constants.SIGN_OUT_INIT,
  };
}
export function signoutSuccessAction() {
  return {
    type: constants.SIGN_OUT_SUCCESS,
  };
}
export function signoutErrorAction() {
  return {
    type: constants.SIGN_OUT_ERROR,
  };
}
export function accountsInitAction() {
  return {
    type: constants.ACCOUNTS_INIT,
  };
}
export function accountsSuccessAction(accounts) {
  return {
    type: constants.ACCOUNTS_SUCCESS,
    accounts,
  };
}
export function accountsErrorAction(error) {
  return {
    type: constants.ACCOUNTS_ERROR,
    error,
  };
}
export function usersInitAction(account) {
  return {
    type: constants.USERS_INIT,
    account,
  };
}
export function usersSuccessAction(users) {
  return {
    type: constants.USERS_SUCCESS,
    users,
  };
}
export function usersErrorAction(error) {
  return {
    type: constants.USERS_ERROR,
    error,
  };
}
export function setSelectedAccountAction(account) {
  return {
    type: constants.SET_SELECTED_ACCOUNT,
    account,
  };
}
export function setSelectedUserAction(user) {
  return {
    type: constants.SET_SELECTED_USER,
    user,
  };
}
export function impersonateUserInitAction(
  userId,
  accountSlug,
  reloadPage = false,
) {
  return {
    type: constants.IMPERSONATE_USER_INIT,
    userId,
    accountSlug,
    reloadPage,
  };
}
export function impersonateUserSuccessAction() {
  return {
    type: constants.IMPERSONATE_USER_SUCCESS,
  };
}
export function impersonateUserErrorAction(error) {
  return {
    type: constants.IMPERSONATE_USER_ERROR,
    error,
  };
}

export function registerMobileDeviceInitAction(deviceId) {
  return {
    type: constants.REGISTER_MOBILE_DEVICE_INIT,
    deviceId,
  };
}
export function registerMobileDeviceSuccessAction() {
  return {
    type: constants.REGISTER_MOBILE_DEVICE_SUCCESS,
  };
}
export function registerMobileDeviceErrorAction(error) {
  return {
    type: constants.REGISTER_MOBILE_DEVICE_ERROR,
    error,
  };
}

export function updateClientSettingsAction(view, columns, quickActions) {
  return {
    type: constants.UPDATE_CLIENT_SETTINGS,
    view,
    columns,
    quickActions,
  };
}

export function updateClientSettingsSuccess(data) {
  return {
    type: constants.UPDATE_CLIENT_SETTINGS_SUCCESS,
    data,
  };
}

export function updateClientSettingsError() {
  return {
    type: constants.UPDATE_CLIENT_SETTINGS_ERROR,
  };
}

export function updateIsFaqBotEnabled() {
  return {
    type: constants.UPDATE_IS_FAQ_BOT_ENABLED,
  };
}
