import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { VFC } from 'react';

export const EditIcon: VFC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 12 12">
    <path
      d="M10.121 0.292786C9.93347 0.105315 9.67916 0 9.414 0C9.14884 0 8.89453 0.105315 8.707 0.292786L1 7.99979L0 11.9998L4 10.9998L11.707 3.29279C11.8945 3.10526 11.9998 2.85095 11.9998 2.58579C11.9998 2.32062 11.8945 2.06631 11.707 1.87879L10.121 0.292786Z"
      fill="#666F89"
    />
  </SvgIcon>
);
