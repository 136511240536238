import ApplicantActionsReducer from 'containers/ApplicantActions/reducer';
import ApplicantActionsSaga from 'containers/ApplicantActions/saga';
import ApplicantTableV2Reducer from 'containers/ApplicantTableV2/reducer';
import DataValidationReducer from 'containers/DataValidationModal/reducer';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

export const Reducer = {
  APPLICANT_ACTIONS: 'applicantActions' as const,
  APPLICANT_TABLE_V2: 'applicantTableV2' as const,
} as const;

export type ReducerName =
  | 'applicantActions'
  | 'applicantTableV2'
  | 'dataValidation';

const useApplicantReducer = () => {
  useInjectReducer({
    key: 'applicantActions',
    reducer: ApplicantActionsReducer,
  });
  useInjectSaga({
    key: 'applicantActions',
    mode: undefined,
    saga: ApplicantActionsSaga,
  });
};

const useApplicantTableV2Reducer = () => {
  useInjectReducer({
    key: 'applicantTableV2',
    reducer: ApplicantTableV2Reducer,
  });
};

const useDataValidationReducer = () => {
  useInjectReducer({
    key: 'dataValidation',
    reducer: DataValidationReducer,
  });
};

export const useReducer = (reducerName: ReducerName) => {
  switch (reducerName) {
    case 'applicantActions':
      return useApplicantReducer;
    case 'applicantTableV2':
      return useApplicantTableV2Reducer;
    case 'dataValidation':
      return useDataValidationReducer;
    default:
      return () => {};
  }
};
