/* eslint-disable no-underscore-dangle */
import { Trashcan } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { PartnerRuleAttributes } from 'api-clients/monolith';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';

import { StageSettingCard } from 'containers/WorkflowEditor/components/StageSettingCard';

import { PartnerAction } from '../PartnerAction';
import { PartnerCondition } from '../PartnerCondition';
import { PartnerRuleProps } from '../types';
import { messages } from './messages';
import { useStyles } from './styles';

export interface RuleCardProps {
  ruleId: string;
  index: number;
  rule: PartnerRuleAttributes;
  setPartnerRules: React.Dispatch<React.SetStateAction<PartnerRuleProps>>;
  errors?: {
    [key: string]: string[];
  };
  onDeleteRule: (ruleId: string | number) => void;
}

export const PartnerRuleCard: FC<RuleCardProps> = React.memo(
  ({ rule, index, ruleId, setPartnerRules, onDeleteRule, errors }) => {
    const styles = useStyles();

    const intl = useIntl();

    const action = rule.action_attributes;
    const condition = rule.condition_attributes;

    return (
      <Grid className={styles.ruleCardContainer}>
        <StageSettingCard
          title={intl.formatMessage(messages.ruleNumber, { number: index + 1 })}
          icon={
            <Trashcan
              color="secondary"
              viewBox="0 0 16 16"
              className={styles.trashcanIcon}
              aria-label={intl.formatMessage(messages.delete)}
            />
          }
          buttonText={intl.formatMessage(messages.deleteRule)}
          variant="button"
          onClick={() => onDeleteRule(ruleId)}
        >
          <Grid>
            <div className={styles.paddingBottom}>
              <Grid>
                <PartnerCondition
                  key={uuid()}
                  ruleId={ruleId}
                  condition={condition}
                  setPartnerRules={setPartnerRules}
                  errors={errors}
                />
              </Grid>
            </div>

            <Grid>
              <PartnerAction
                key={uuid()}
                action={action}
                ruleId={ruleId}
                setPartnerRules={setPartnerRules}
                errors={errors}
              />
            </Grid>
          </Grid>
        </StageSettingCard>
      </Grid>
    );
  },
);
