import { createSelector } from 'reselect';

import { initialState } from './reducer';
import { ApplicantTableV2 } from './types';

/**
 * Direct selector to the applicantTableV2 state domain
 */

const selectApplicantTableV2Domain = (state: {
  applicantTableV2: ApplicantTableV2;
}) => state.applicantTableV2 || initialState;

const findNextApplicant = (currentApplicantId: string) =>
  createSelector(selectApplicantTableV2Domain, substate => {
    const applicantIds = Object.keys(substate.applicants);
    const currentIndex = applicantIds.indexOf(currentApplicantId);
    return applicantIds[currentIndex + 1] || null;
  });

const findPreviousApplicant = (currentApplicantId: string) =>
  createSelector(selectApplicantTableV2Domain, substate => {
    const applicantIds = Object.keys(substate.applicants);
    const currentIndex = applicantIds.indexOf(currentApplicantId);
    return applicantIds[currentIndex - 1] || null;
  });

const makeSelectShouldShowOverlay = () =>
  createSelector(
    selectApplicantTableV2Domain,
    substate => substate.shouldShowOverlay,
  );

const makeSelectApplicantTableV2 = () =>
  createSelector(selectApplicantTableV2Domain, substate => substate);

const makeSelectApplicants = () =>
  createSelector(selectApplicantTableV2Domain, substate => substate.applicants);

const makeSelectIsFetchingApplicants = () =>
  createSelector(
    selectApplicantTableV2Domain,
    substate => substate.isFetchingApplicants,
  );

const makeSelectDrawerApplicant = () =>
  createSelector(
    selectApplicantTableV2Domain,
    substate => substate.selectedApplicant,
  );

const makeSelectSelectedApplicantIds = () =>
  createSelector(
    selectApplicantTableV2Domain,
    substate => substate.selectedApplicantIds,
  );

const makeSelectSelectByCount = () =>
  createSelector(
    selectApplicantTableV2Domain,
    substate => substate.selectByCount,
  );

const makeSelectTotalRows = () =>
  createSelector(selectApplicantTableV2Domain, substate => substate.totalRows);

const makeSelectApplicantTableApplicant = (applicantId: string) =>
  createSelector(
    [makeSelectApplicants()],
    applicants => applicants[applicantId],
  );

const makeSelectSingleApplicant = () =>
  createSelector([makeSelectDrawerApplicant()], applicant => applicant);

/**
 * Create selector for applicant notes.
 * Providing a valid `Array.sort` method will return an appropriately sorted array.
 * Otherwise; returns applicant notes as defined on `redux` state.
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort#syntax
 *
 * @param {string} applicantId
 * @param {?function} [sort=undefined] - `Array.sort` method
 *
 * @returns {array} applicant.notes[]
 */
const makeSelectNotes = () =>
  createSelector([makeSelectSingleApplicant()], applicant => applicant.notes);

const makeSelectApplicantMessages = () =>
  createSelector(
    [makeSelectSingleApplicant()],
    applicant => applicant.messages,
  );

const makeSelectedSmsUnsubscribed = () =>
  createSelector(
    [makeSelectSingleApplicant()],
    applicant => applicant.sms_unsubscribed,
  );

const makeSelectSelectedFilterStageIdsWithFunnel = () =>
  createSelector(
    selectApplicantTableV2Domain,
    substate => substate.selectedFilterStageIdsWithFunnel,
  );

const makeSelectSelectedFilterJobIdsCount = () =>
  createSelector(
    selectApplicantTableV2Domain,
    substate => substate.selectedFilterJobIdsCount,
  );

const makeSelectTableColumnWidths = () =>
  createSelector(
    selectApplicantTableV2Domain,
    substate => substate.tableColumnWidths,
  );

const makeSelectIsDeletingApplicants = () =>
  createSelector(
    selectApplicantTableV2Domain,
    substate => substate.isDeletingApplicants,
  );

const makeSelectBulkActionSubmitting = () =>
  createSelector(
    selectApplicantTableV2Domain,
    substate => substate.bulkActionSubmitting,
  );

const makeSelectApplicantDetail = (applicantId: string) =>
  createSelector(
    selectApplicantTableV2Domain,
    substate => substate.details && substate.details[applicantId],
  );

export default makeSelectApplicantTableV2;
export {
  findNextApplicant,
  findPreviousApplicant,
  makeSelectApplicantDetail,
  makeSelectApplicantMessages,
  makeSelectApplicants,
  makeSelectApplicantTableApplicant,
  makeSelectBulkActionSubmitting,
  makeSelectedSmsUnsubscribed,
  makeSelectIsDeletingApplicants,
  makeSelectIsFetchingApplicants,
  makeSelectNotes,
  makeSelectSelectByCount,
  makeSelectSelectedApplicantIds,
  makeSelectSelectedFilterJobIdsCount,
  makeSelectSelectedFilterStageIdsWithFunnel,
  makeSelectShouldShowOverlay,
  makeSelectSingleApplicant,
  makeSelectTableColumnWidths,
  makeSelectTotalRows,
};
