import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginLeft: theme.spacing(1.5), // MUI FormControlLabels have negative margins by default
  },
  fieldRow: {
    paddingBottom: theme.spacing(3),

    '&:last-child': {
      paddingBottom: 0,
    },
  },
  switchLabel: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.common.gray800,
  },
}));
