import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Helmet } from 'react-helmet';

import GlobalNav from 'components/GlobalNav';

import { ContactSalesHeader } from './ContactSalesHeader';
import { Content } from './Content';
import { Preview } from './Preview';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexFlow: 'wrap',
    alignContent: 'flex-start',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
  header: {
    padding: theme.spacing(2, 0, 2),
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

export const Upsell = () => {
  const styles = useStyles();

  return (
    <>
      <Helmet>
        <title>Fountain AI</title>
        <meta name="description" content="Chatbot Widget" />
      </Helmet>
      <GlobalNav />
      <div className={styles.container}>
        <ContactSalesHeader />
        <div className={styles.contentContainer}>
          <Content />
          <Preview />
        </div>
      </div>
    </>
  );
};
