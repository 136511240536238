import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import useStyles from './styles';

function SuccessContent() {
  const classes = useStyles();

  return (
    <Grid>
      <Typography variant="h2" className={classes.successContentContainer}>
        CCPA Request Confirmation
      </Typography>
      <Typography variant="body1" color="textPrimary">
        Thank you for providing this information. We are currently working to
        process your request in accordance with our legal requirements, and will
        reach out to you or the individual on whose behalf you have made the
        request.
      </Typography>
    </Grid>
  );
}

export default SuccessContent;
