import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@fountain/fountain-ui-components';
import { TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  CancelablePromise,
  OpeningApprovalsService,
} from 'api-clients/monolith';
import { OpeningApprovalsParams } from 'api-clients/monolith/models/OpeningApprovalsParams';
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addMessageAction } from 'containers/FlashMessage/actions';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';
import globalMessages from 'shared/global/messages';

import messages from './messages';

const useStyles = makeStyles(theme => ({
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  info: {
    fontWeight: 450,
  },
  input: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  modalButton: {
    padding: theme.spacing(1.5, 3),
    marginLeft: theme.spacing(2),
  },
}));

export interface ApprovalsModalProps {
  selectedApprovals: string[];
  setSelectedApprovals: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedApproval: React.Dispatch<React.SetStateAction<string>>;
  status: string;
  refreshApprovalsData: () => void;
  isOpen: boolean;
  onClose: () => void;
}

export const ApprovalsModal: FC<ApprovalsModalProps> = ({
  selectedApprovals,
  setSelectedApprovals,
  setSelectedApproval,
  status,
  refreshApprovalsData,
  isOpen,
  onClose,
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [reason, setReason] = useState('');

  const { mutation: updateApprovals } = useApiServiceMutation<
    void,
    (requestBody: OpeningApprovalsParams) => CancelablePromise<void>
  >(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    OpeningApprovalsService.postInternalApiOpeningApprovalApprovalsAction,
    {
      onSuccess: () => {
        onClose();
        refreshApprovalsData();
        setSelectedApprovals([]);
        setSelectedApproval('');
        dispatch(
          addMessageAction(`Opening ${status} successfully.`, 'success'),
        );
      },
      onError: () => {
        dispatch(
          addMessageAction('Unable to process the request.', 'error', false),
        );
      },
    },
  );

  const handleReasonChange = (reason: string) => {
    setReason(reason);
  };

  const onSaveEditsAndPreview = () => {
    updateApprovals({
      opening_approvals: {
        approval_ids: selectedApprovals,
        status,
        reason,
      },
    });
  };

  const modalTitle =
    status === 'approved'
      ? messages.approvalModalTitle
      : messages.rejectionModalTitle;
  const modalSubTitle =
    status === 'approved'
      ? messages.approvalModalSubtitle
      : messages.rejectionModalSubtitle;
  const buttonText =
    status === 'approved'
      ? intl.formatMessage(messages.approve)
      : intl.formatMessage(globalMessages.save);

  return (
    <>
      <Modal
        ariaLabelledBy={intl.formatMessage(modalTitle)}
        disableBackdropClick
        fullScreenOnMobile
        onClose={onClose}
        open={isOpen}
      >
        <ModalHeader
          ariaLabelledBy={intl.formatMessage(modalTitle)}
          onClose={onClose}
          showIcon={false}
        >
          {intl.formatMessage(modalTitle)}
        </ModalHeader>
        <ModalContent dividers>
          <div className={styles.modalContent}>
            <Typography variant="body2" className={styles.info}>
              {intl.formatMessage(modalSubTitle)}
            </Typography>

            {status === 'rejected' && (
              <div className={styles.input}>
                <Typography variant="body2" className={styles.info}>
                  {intl.formatMessage(messages.rejectionReason)}
                </Typography>
                <TextField
                  variant="outlined"
                  onChange={event => handleReasonChange(event.target.value)}
                  fullWidth
                  multiline
                  rows={3}
                  rowsMax={10}
                  data-testid="messagebody-textfield"
                  placeholder={intl.formatMessage(
                    messages.rejectionPlaceholder,
                  )}
                />
              </div>
            )}
          </div>
        </ModalContent>
        <ModalFooter>
          <Button
            aria-label={intl.formatMessage(globalMessages.cancel)}
            onClick={onClose}
            type="secondary"
            autoWidth
            className={styles.modalButton}
          >
            {intl.formatMessage(globalMessages.cancel)}
          </Button>
          <Button
            aria-label={buttonText}
            onClick={onSaveEditsAndPreview}
            disabled={status === 'rejected' ? !(reason?.length > 0) : false}
            autoWidth
            className={styles.modalButton}
          >
            {buttonText}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
