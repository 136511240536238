import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  questionsContainer: {
    marginTop: theme.spacing(4),
  },
  questionsContainerWithDeleteButton: {
    marginTop: theme.spacing(3.05),
  },
  addButton: {
    color: theme.palette.common.blue400,
  },
}));
