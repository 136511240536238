import { defineMessages } from 'react-intl';

export const scope =
  'app.containers.AutomatedResponse.AutomatedResponseBulkUpload';

export default defineMessages({
  downloadSampleFile: {
    id: `${scope}.downloadSampleFile`,
    defaultMessage: 'Download Sample File',
  },
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Import Automated Responses with CSV',
  },
  uploadCsvFile: {
    id: `${scope}.uploadCsvFile`,
    defaultMessage: 'Upload CSV file',
  },
  modelName: {
    id: `${scope}.modelName`,
    defaultMessage: 'Model Name',
  },
  modelType: {
    id: `${scope}.modelType`,
    defaultMessage: 'Model Type',
  },
  mode: {
    id: `${scope}.mode`,
    defaultMessage: 'Mode',
  },
  threshold: {
    id: `${scope}.threshold`,
    defaultMessage: 'Thresold',
  },
  confidenceThresholdErrorMessage: {
    id: `${scope}.confidenceThresholdErrorMessage`,
    defaultMessage: 'Must be in the range {min} - {max}',
  },
});
