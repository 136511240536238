import { Tooltip as ConditionalTooltip } from '@fountain/fountain-ui-components';
import {
  Divider,
  List,
  ListItem,
  ListSubheader,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { makeSelectExternalId } from 'containers/Auth_old/selectors';
import SmsIcon from 'images/sms-blue-icon.svg';
import WhatsappIcon from 'images/whatsapp-logo.svg';

import { MessageTemplatesVariant } from './constants';
import messages from './messages';
import makeSelectTemplateMenuState from './selectors';
import useStyles from './styles';
import {
  getRecentlyUsedTemplates,
  updateRecentlyUsedTemplateIds,
} from './utils';

const RecentlyUsedTemplatesList = ({ onSelected, variant }) => {
  const styles = useStyles();
  const { messageTemplates = [], whatsAppTemplates = [] } = useSelector(
    makeSelectTemplateMenuState(),
  );
  const templates =
    variant === MessageTemplatesVariant.WHATSAPP
      ? whatsAppTemplates
      : messageTemplates;
  const userId = useSelector(makeSelectExternalId());
  const [recentlyUsedTemplates, setRecentlyUsedTemplates] = useState([]);
  useEffect(() => {
    setRecentlyUsedTemplates(
      getRecentlyUsedTemplates(templates, userId, variant),
    );
  }, [setRecentlyUsedTemplates, templates, userId, variant]);

  const handleClickListItem = template => () => {
    updateRecentlyUsedTemplateIds(template, templates, userId, variant);
    onSelected(template);
  };

  if (
    !recentlyUsedTemplates ||
    (recentlyUsedTemplates && recentlyUsedTemplates.length === 0)
  ) {
    return null;
  }

  return (
    <>
      {recentlyUsedTemplates && recentlyUsedTemplates.length > 0 && (
        <>
          <List disablePadding>
            <ListSubheader className={styles.listSubheader} disableSticky>
              <Typography variant="h5">
                <FormattedMessage {...messages.recentlyUsedTemplates} />
              </Typography>
            </ListSubheader>
            {recentlyUsedTemplates.map(template => (
              <ListItem
                button
                className={styles.listItem}
                key={template.id}
                onClick={handleClickListItem(template)}
              >
                <ConditionalTooltip
                  dense
                  enterDelay={333}
                  text={template.name}
                  title={template.name}
                >
                  {[
                    MessageTemplatesVariant.SMS,
                    MessageTemplatesVariant.WHATSAPP,
                  ].includes(variant) &&
                    (template.sms_enabled ? (
                      <img
                        src={SmsIcon}
                        alt={MessageTemplatesVariant.sms}
                        className={styles.templateIcon}
                      />
                    ) : (
                      <img
                        src={WhatsappIcon}
                        alt={MessageTemplatesVariant.whats_app}
                        className={styles.templateIcon}
                      />
                    ))}
                  <Typography noWrap variant="caption">
                    {template.name}
                  </Typography>
                </ConditionalTooltip>
              </ListItem>
            ))}
          </List>
          <Divider className={styles.divider} />
        </>
      )}
    </>
  );
};

RecentlyUsedTemplatesList.propTypes = {
  onSelected: PropTypes.func.isRequired,
  variant: PropTypes.oneOf([
    MessageTemplatesVariant.EMAIL,
    MessageTemplatesVariant.SMS,
    MessageTemplatesVariant.WHATSAPP,
  ]).isRequired,
};

export default RecentlyUsedTemplatesList;
