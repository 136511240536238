import { makeStyles } from '@material-ui/core/styles';
/**
 *
 * Material UI styles for EditingField
 *
 *
 * @see https://material-ui.com/styles/basics
 *
 */

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3),
  },
  checkboxLabel: {
    fontSize: theme.typography.h4.fontSize,
    lineHeight: theme.typography.h4.lineHeight,
  },
  radio: {
    marginBottom: theme.spacing(2),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  radioError: {
    marginTop: theme.spacing(1.5),
  },
  workAvailabilityErrorMessage: {
    marginTop: '6px',
  },
}));

export default useStyles;
