import { defineMessages } from 'react-intl';

const scope = 'app.components.LicenseDataContainer';

export const messages = defineMessages({
  typeField: {
    id: `${scope}.typeField`,
    defaultMessage: 'Type',
  },
  numberField: {
    id: `${scope}.numberField`,
    defaultMessage: 'Number',
  },
  regionField: {
    id: `${scope}.regionField`,
    defaultMessage: 'Region',
  },
  regionIsoField: {
    id: `${scope}.regionIsoField`,
    defaultMessage: 'Region ISO',
  },
  countryCodeField: {
    id: `${scope}.countryCodeField`,
    defaultMessage: 'Country code',
  },
  countryCodeIsoField: {
    id: `${scope}.countryCodeIsoField`,
    defaultMessage: 'Country code ISO',
  },
});
