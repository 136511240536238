import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  textEditorContainer: {
    position: 'relative',

    '&.error': {
      '& > .ql-toolbar': {
        borderLeft: `1px solid ${theme.palette.error.main}`,
        borderTop: `1px solid ${theme.palette.error.main}`,
        borderRight: `1px solid ${theme.palette.error.main}`,
      },
      '& > .quill': {
        borderLeft: `1px solid ${theme.palette.error.main}`,
        borderBottom: `1px solid ${theme.palette.error.main}`,
        borderRight: `1px solid ${theme.palette.error.main}`,
      },
    },
    '& > .ql-toolbar': {
      borderLeft: theme.customBorders.gray300,
      borderTop: theme.customBorders.gray300,
      borderRight: theme.customBorders.gray300,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
    '& > .quill': {
      width: '100%',
      borderBottom: theme.customBorders.gray300,
      borderLeft: theme.customBorders.gray300,
      borderRight: theme.customBorders.gray300,
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4,

      '& > .ql-toolbar': {
        border: 'none',
      },

      '& > .ql-container': {
        '&.ql-snow': {
          border: 'none',
        },

        '& > .ql-editor': {
          height: 180,

          [theme.breakpoints.down('xs')]: {
            height: 240,
          },
        },
      },
    },
    '&.toolbarDisabled > .quill': {
      border: theme.customBorders.gray300,
      borderRadius: 4,
    },
  },
  errorMessage: {
    textAlign: 'right',
  },
  textarea: {
    minHeight: 180,
    width: '100%',
    marginBottom: -4,
    borderRadius: 4,
    border: theme.customBorders.gray300,
    padding: theme.spacing(2),
    fontSize: 14,
    resize: 'none',

    '&.error': {
      border: `1px solid ${theme.palette.common.error}`,
    },
  },
  textareaLength: {
    position: 'absolute',
    left: 16,
    bottom: 16,
    color: '#737C97',

    '&.limitReached': {
      color: theme.palette.common.error,
    },
  },
  htmlEditor: {
    width: '100%',
    border: theme.customBorders.gray300,
    borderRadius: 4,
    minHeight: 432,
    fontSize: theme.typography.fontSize,
    marginBottom: -5,

    '& > .CodeMirror': {
      minHeight: 432,
      zIndex: 0,
    },

    [theme.breakpoints.down('sm')]: {
      minHeight: 282,
      maxWidth: 400,

      '& > .CodeMirror': {
        minHeight: 282,
        zIndex: 0,
      },
    },

    [theme.breakpoints.down('xs')]: {
      maxWidth: 300,
    },
  },
  bottomButtons: {
    width: '100%',
    zIndex: 4,

    '&.increaseZIndex': {
      zIndex: 10,
    },
  },
  messageTemplates: {
    position: 'relative',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    width: '100%',
  },
}));

export default useStyles;
