import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { CheckrPackage } from 'api-clients/monolith';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import {
  generateHashStatusMessages,
  getConditionErrorMessage,
} from 'containers/WorkflowEditor/components/Rules/Condition/constants';
import { messages } from 'containers/WorkflowEditor/components/Rules/Condition/messages';
import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';

import { DocumentSigningRuleProps } from '../../../DocumentSigningRules/types';
import { RulesProps, SharedConditionProps } from '../../../types';

export const CheckrStatus: FC<SharedConditionProps> = ({
  condition,
  ruleId,
  setRules,
  errors,
  setDocumentSigningRules,
}) => {
  const intl = useIntl();

  const {
    condition_statuses: conditionStatuses,
    checkr_packages: checkrPackages,
  } = useContext(RulesEditDataContext);
  const { extra } = condition;

  const onChangePackage = (newPackage: CheckrPackage) => {
    const rulesSetter = setRules ?? setDocumentSigningRules;

    if (!rulesSetter) {
      return;
    }

    rulesSetter(
      produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
        const draftConditions =
          draftRules[ruleId]?.condition_set_attributes?.conditions_attributes ??
          [];

        const idx = draftConditions.findIndex(cond => cond.id === condition.id);

        draftConditions[idx].extra.package_name = newPackage.slug;
      }),
    );
  };

  const onChangeStatus = (newStatus: { label: string; value: string }) => {
    const rulesSetter = setRules ?? setDocumentSigningRules;

    if (rulesSetter) {
      rulesSetter(
        produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];

          const idx = draftConditions.findIndex(
            cond => cond.id === condition.id,
          );

          draftConditions[idx].extra.status = newStatus.value;
        }),
      );
    }
  };

  const selectedCheckrPackage =
    checkrPackages?.find(
      checkrPackage => checkrPackage.slug === extra?.package_name,
    ) ?? null;

  const messageByStatus = generateHashStatusMessages(intl);

  const statusOptions = conditionStatuses?.checkr?.map(status => ({
    label: messageByStatus[status],
    value: status,
  }));

  const selectedStatusOption =
    statusOptions?.find(option => option.value === extra.status) ?? null;

  const packageNameErrorMessage =
    !extra.package_name && getConditionErrorMessage('package_name', errors);
  const statusErrorMessage =
    !extra.status && getConditionErrorMessage('status', errors);

  return (
    <>
      <Grid item xs={6}>
        <StyledReactSelect
          options={checkrPackages}
          value={selectedCheckrPackage}
          onChange={onChangePackage}
          label={intl.formatMessage(messages.package)}
          aria-label={intl.formatMessage(messages.package)}
          getOptionLabel={(option: CheckrPackage) => option.name}
          getOptionValue={(option: CheckrPackage) => option.slug}
          isSearchable
          error={packageNameErrorMessage}
          placeholder=""
          required
        />
      </Grid>
      <Grid item xs={6}>
        <StyledReactSelect
          options={statusOptions}
          value={selectedStatusOption}
          onChange={onChangeStatus}
          label={intl.formatMessage(messages.status)}
          aria-label={intl.formatMessage(messages.status)}
          isSearchable
          error={statusErrorMessage}
          placeholder=""
          required
        />
      </Grid>
    </>
  );
};
