import {
  Button,
  Grid,
  Input,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import React, { FocusEvent, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { TestableTypography } from 'components/_shared/TestableTypography';
import { addMessageAction } from 'containers/FlashMessage/actions';

import messages from './messages';
import { useStyles } from './styles';

const ROWS_PER_PAGE = [10, 20, 50, 100];

export interface PaginationProps {
  perPage: number;
  currentPage: number;
  lastPage: number;
  setPage: (value: number) => void;
  setPerPage: (value: number) => void;
  hidePerPage?: boolean;
}

export const Pagination: VFC<PaginationProps> = ({
  perPage,
  currentPage,
  lastPage,
  setPage,
  setPerPage,
  hidePerPage = false,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const classes = useStyles();

  const setDefaultPage = (e: FocusEvent<HTMLInputElement>) => {
    if (Number(e.target.value) > 0 && Number(e.target.value) <= lastPage) {
      setPage(Number(e.target.value));
    } else {
      setPage(1);
      dispatch(addMessageAction('Page number is not valid', 'error'));
    }
  };

  return (
    <Grid data-testid="pagination" className={classes.pagination}>
      <Grid container justify="center" className={classes.paginationContent}>
        {!hidePerPage && (
          <Grid item className={classes.paginationLeftContent}>
            <Grid classes={{ root: classes.rowsPerPage }}>
              <Typography variant="body2">
                <FormattedMessage {...messages.perPageCaption} />
              </Typography>
              <Select
                classes={{ root: classes.rowsPerPageSelect }}
                disableUnderline
                value={perPage}
                onChange={e => setPerPage(Number(e.target.value))}
                inputProps={{
                  name: 'rowsPerPage',
                  id: 'rows-per-page',
                }}
              >
                {ROWS_PER_PAGE.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        )}
        <Grid item xs={6} className={classes.pageSelectContainer}>
          <Grid
            container
            direction="row"
            justify="space-between"
            className={classes.verticalCenter}
          >
            <Button
              onClick={() => setPage(currentPage - 1)}
              aria-label={intl.formatMessage(messages.prev)}
              disabled={Number(currentPage) === 1 || !currentPage}
            >
              <ChevronLeft />
              <Typography>
                <FormattedMessage {...messages.prev} />
              </Typography>
            </Button>
            <Grid item>
              <Grid container className={classes.pageSelect}>
                <Typography>
                  <FormattedMessage {...messages.page} />
                </Typography>
                <Input
                  onKeyDown={e =>
                    e.keyCode === 13 && (e.target as HTMLElement).blur()
                  }
                  defaultValue={currentPage}
                  onBlur={setDefaultPage}
                  disableUnderline
                  key={currentPage}
                  data-testid="paginationCurrentPage"
                  classes={{
                    root: classes.pageSelectInputRoot,
                    input: classes.pageSelectInput,
                  }}
                />
                <Typography>of</Typography>
                <TestableTypography className={classes.lastPage}>
                  {lastPage}
                </TestableTypography>
              </Grid>
            </Grid>
            <Button
              onClick={() => setPage(currentPage + 1)}
              aria-label={intl.formatMessage(messages.next)}
              // eslint-disable-next-line eqeqeq
              disabled={currentPage == lastPage || !currentPage}
            >
              <Typography>
                <FormattedMessage {...messages.next} />
              </Typography>
              <ChevronRight />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
