// Needed for redux-saga es6 generator support
import '@babel/polyfill';
// Load the favicon and the .htaccess file
import '!file-loader?name=[name].[ext]!./images/favicon.png';
import 'file-loader?name=.htaccess!./.htaccess'; // eslint-disable-line import/extensions

import { ThemeProvider } from '@fountain/fountain-ui-components';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ConnectedRouter } from 'connected-react-router';
import { setupOpenApiClient } from 'openapi';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import ErrorBoundary from 'components/ErrorBoundary';
import App from 'containers/App';
import LanguageProvider from 'containers/LanguageProvider';
import { initializeDatadog } from 'utils/datadog';
import { domainMeta } from 'utils/domainMeta';
import history from 'utils/history';

import configureStore from './configureStore';
import { translationMessages } from './i18n';
import {
  IMAGE_TAG,
  REACT_APP_DATADOG_APPLICATION_ID,
  REACT_APP_DATADOG_CLIENT_TOKEN,
  REACT_APP_DATADOG_SAMPLE_RATE,
  REACT_APP_DATADOG_SITE,
} from './runtimeEnvVars';

if (
  typeof REACT_APP_DATADOG_APPLICATION_ID === 'string' &&
  typeof REACT_APP_DATADOG_CLIENT_TOKEN === 'string'
) {
  initializeDatadog({
    allowedTracingOrigins: [domainMeta.monolithOrigin],
    applicationId: REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: REACT_APP_DATADOG_CLIENT_TOKEN,
    env: domainMeta.environment,
    sampleRate:
      Number.isNaN(Number(REACT_APP_DATADOG_SAMPLE_RATE)) === false
        ? Number(REACT_APP_DATADOG_SAMPLE_RATE)
        : 100, // Default to sampling all
    service: 'recruiter-ui',
    site: REACT_APP_DATADOG_SITE ?? 'datadoghq.com',
    tenant: domainMeta.tenant,
    version: IMAGE_TAG,
  });
}

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history, {});
const MOUNT_NODE = document.getElementById('app');

setupOpenApiClient();

const render = messages => {
  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider>
        <CssBaseline>
          <LanguageProvider messages={messages}>
            <ConnectedRouter history={history}>
              <ErrorBoundary>
                <App />
              </ErrorBoundary>
            </ConnectedRouter>
          </LanguageProvider>
        </CssBaseline>
      </ThemeProvider>
    </Provider>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

render(translationMessages);

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
// if (NODE_ENV === 'production') {
//   require('offline-plugin/runtime').install(); // eslint-disable-line global-require
// }
